import type Account from 'data/account/model';
import type { AccountSubscription } from 'data/account/model';
import type Plan from 'data/plan/model';
import type User from 'data/user/model';
import Monolith from 'shared/api/monolith';
import type { WiwDispatch } from 'store';

import Cookies from 'js-cookie';
import type { LDContext, LDMultiKindContext } from 'launchdarkly-js-client-sdk';
import { init as ldReduxInit } from 'ld-redux';
import { round } from 'lodash';
import moment from 'moment-timezone';

export const LD_COOKIE_NAME = 'ldCookie';

export type MetricImportArgs = {
  key: string;
  contextKeys?: object;
  creationDate: number;
  metricValue?: number;
};

export type MetricContextKeys = {
  'anonymous-user'?: string;
  user?: string;
  account?: string;
};

export class LaunchDarkly {
  static init(user: User, account: Account, plan: Plan, dispatch: WiwDispatch) {
    const userId = user.get('id');

    // Make sure we don't keep old caches around.
    if (userId !== null) {
      this.clearOldKeys(userId);
    }

    const LDMultiContext = this.buildMultiContext(user, account, plan);
    return ldReduxInit({
      dispatch,
      contexts: LDMultiContext,
    });
  }

  // clearOldKeys will remove any launch darkly cache items
  // from local storage that are present for any user in the
  // current environment other than the currently signed in
  // one. It will not remove any other keys.
  static clearOldKeys = (currentUserId: number) => {
    const prefix = `ld:${CONFIG.LAUNCH_DARKLY_TOKEN}:`;
    const currentKey = `${prefix}${currentUserId}`;

    for (let i = 0; i < window.localStorage.length; i++) {
      const key = window.localStorage.key(i);
      if (!key) {
        continue;
      }

      // If the key has the LD prefix for the current environment
      // but does not match the key for the current user then we
      // need to remove the old local storage item.
      if (key.startsWith(prefix) && key !== currentKey) {
        window.localStorage.removeItem(key);
      }
    }
  };

  // Format a datetime string to a number suitable for LaunchDarkly.
  static formatDatetime(datetime: string | boolean | null): number | boolean {
    return datetime && typeof datetime === 'string' && moment(new Date(datetime)).isValid()
      ? moment.utc(datetime).valueOf()
      : false;
  }

  // "How many days ago?" as a number or boolean suitable for LaunchDarkly.
  static daysAgo(datetime: string | boolean | null): number | boolean {
    return datetime && typeof datetime === 'string' && moment(new Date(datetime)).isValid()
      ? round(moment().diff(moment(datetime), 'seconds') / 86400, 4)
      : false;
  }

  static buildMultiContext(user: User, account: Account, plan: Plan): LDMultiKindContext {
    const multiContext: LDMultiKindContext = { kind: 'multi' };

    const anonLDContext = Cookies.get('ld_anon_context');
    if (anonLDContext) {
      multiContext['anonymous-user'] = this.buildAnonymousUserContext(anonLDContext);
    }

    multiContext.user = this.buildUserContext(user, account, plan);
    multiContext.account = this.buildAccountContext(account);

    return multiContext;
  }

  static buildAnonymousUserContext(ldAnonContext: string): LDContext {
    return {
      kind: 'anonymous-user',
      key: ldAnonContext,
    };
  }

  static buildAccountContext(account: Account): LDContext {
    const accountId = account && account.id !== null && account.id !== undefined ? account.id.toString() : undefined;

    return {
      key: `account:${accountId}`,
      kind: 'account',
      accountCreatedAt: this.formatDatetime(account.created_at),
      planId: account.plan_id,
      expectedEmployees: account.ref_employees,
      signupOrigin: account.signup_origin,
    };
  }

  static buildUserContext(user: User, account: Account, plan: Plan): LDContext {
    const userId = user && user.id !== null && user.id !== undefined ? user.id.toString() : undefined;
    const accountId = account && account.id !== null && account.id !== undefined ? account.id.toString() : undefined;
    const masterAccountId = account.isChild() ? account.master_id.toString() : accountId;
    const activeAccountSubscriptions = account.subscriptions.filter(
      (subscription: AccountSubscription) => subscription.is_active,
    );
    const isMobile = this.isMobile();

    return {
      kind: 'user',
      key: `user:${userId}`,
      secondary: `account:${masterAccountId}`,
      country: account.country.iso2,
      email: user.email,
      name: user.fullName,
      firstName: user.first_name,
      lastName: user.last_name,
      anonymous: false,
      accountBillingType: account.billing_type,
      accountConvertedAt: this.formatDatetime(account.converted_at),
      accountConvertedDaysAgo: this.daysAgo(account.converted_at),
      accountCreatedAt: this.formatDatetime(account.created_at),
      accountCreatedAtDaysAgo: this.daysAgo(account.created_at),
      accountHasAttendanceFreemium: account.attendance_freemium,
      accountHasMaster: account.isChild(),
      accountId,
      accountIsActive: account.isActive(),
      accountIsMaster: account.isParent(),
      accountName: account.company,
      accountPlanCustom: account.plan_custom,
      accountPlanExpires: this.formatDatetime(account.plan_expires),
      accountRefEmployees: account.ref_employees,
      accountTrialCreatedAt: this.formatDatetime(account.trial_created_at),
      accountType: account.typeName(),
      accountSubscriptionTypes: activeAccountSubscriptions.map(
        (subscription: AccountSubscription) => subscription.type,
      ),
      accountSubscriptionPlanIds: activeAccountSubscriptions.map(
        (subscription: AccountSubscription) => subscription.plan.id,
      ),
      accountUserCount: account.user_count,
      attendanceTrialExpiresAt: this.formatDatetime(account.wb_expires),
      ldCookie: Cookies.get(LD_COOKIE_NAME) || '',
      // send null marketingFunnel as a string so that is/is not one of etc matchers may be used
      marketingFunnel: `${account.getSettings('marketing.funnel', null)}`,
      masterAccountId,
      masterPlanId: account.isChild() ? account.master_plan_id : account.plan_id,
      // api response for accountType does not match between react and monolith
      // add computed planAccountType which is computed the same way in both.
      planAccountType: plan.accountType(account),
      planEmployeeMax: plan.employees,
      planId: account.plan_id,
      planIsDemo: plan.isTrial(),
      planIsFreemium: plan.isFreemium(),
      planIsPaid: plan.isPaid(),
      planName: plan.name,
      planTier: plan.sortTier(),
      planType: plan.type,
      userCanBilling: user.canBilling(),
      userId: user.id,
      userIsActivated: user.activated,
      userIsDeleted: user.is_deleted,
      userIsHidden: user.is_hidden,
      userPhoneNumber: user.phone_number,
      userRole: user.roleName,
      isMobile,
    };
  }

  /*
  Used to send custom metric events to LD via a monolith endpoint that sends the event to their API endpoint for
  importing metric events. Can't be done via SDK. Really only used for experiments and data analysis

  The contextKeys will be set to match the identified context (multi or user)
   */
  static importMetric(metric: MetricImportArgs, user: User, account: Account) {
    const { key, creationDate } = metric;

    if (key && creationDate && user && account) {
      const anonLDContext = Cookies.get('ld_anon_context');
      const contextKeys: MetricContextKeys = {};

      if (anonLDContext) {
        contextKeys['anonymous-user'] = anonLDContext;
      }
      contextKeys.user = `user:${user.id}`;
      contextKeys.account = `account:${account.id}`;

      metric.contextKeys = contextKeys;
      Monolith.url('/launchdarkly/metric').post(metric);
    }
  }

  // Determine if user is on a mobile device based on user agent
  static isMobile() {
    let isMobile = false;
    (a => {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a,
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4),
        )
      ) {
        isMobile = true;
      }
    })(navigator.userAgent || navigator.vendor);
    return isMobile;
  }
}

export default LaunchDarkly;
