import { getCustomMetadata } from 'data/reporting/selectors/getCustomMetadata';
import { CustomMetadataState } from 'data/reporting/state/CustomMetadataState';
import { getReportAccountId } from 'reporting/selectors/getReportAccountId';

import { createSelector } from '@reduxjs/toolkit';

// This selector produces a function that lets you get the CustomMetadataState by dataset name (for the current
// reportAccountId). Hence the "getGet" naming which also prevents the import from colliding with the usage.
export const getGetReportMetadataByDataset = createSelector(
  getCustomMetadata,
  getReportAccountId,
  (metadata, reportAccountId) => {
    return (dataset: string): CustomMetadataState => {
      const customMetadataState = metadata.getIn([`${reportAccountId}`, dataset]);
      if (!customMetadataState) {
        return new CustomMetadataState();
      }
      return customMetadataState as CustomMetadataState;
    };
  },
);
