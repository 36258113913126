import type { Dataset } from 'data/reporting/models/Dataset';
import type { ReportMetadataColumnDetailFields } from 'data/reporting/models/ReportMetadataColumnDetail';
import { getCustomMetadata } from 'data/reporting/selectors/getCustomMetadata';
import type { CustomMetadataState } from 'data/reporting/state/CustomMetadataState';
import { request } from 'shared/auth/request';
import { withMatcher } from 'shared/util/withMatcher';
import type { WiwDispatch, WiwGetState, WiwState } from 'store';
import { FETCH_CURRENT_STATE_COLUMNS } from 'store/action-types';

import { DateTime } from 'luxon';

type Params = {
  accountId: string;
  dataset: Dataset;
};

export const fetchCurrentStateColumnsFailure = withMatcher((params: Params, error: unknown) => ({
  type: FETCH_CURRENT_STATE_COLUMNS.FAILURE,
  params,
  error,
}));

export const fetchCurrentStateColumnsRequested = withMatcher((params: Params) => ({
  type: FETCH_CURRENT_STATE_COLUMNS.REQUEST,
  params,
}));

export const fetchCurrentStateColumnsSuccess = withMatcher(
  (params: Params, response: ReportMetadataColumnDetailFields[]) => ({
    type: FETCH_CURRENT_STATE_COLUMNS.SUCCESS,
    params,
    response,
  }),
);

function shouldFetch({
  state,
  accountId,
  dataset,
  minsStale,
}: {
  state: WiwState;
  accountId: string;
  dataset: string;
  minsStale: number;
}) {
  const customMetadata = getCustomMetadata(state);
  const customMetadataState = customMetadata.getIn([`${accountId}`, dataset]) as CustomMetadataState;

  // Fetching because never loaded
  if (!customMetadataState || (!customMetadataState.loading && !customMetadataState.loaded)) {
    return true;
  }

  // Fetch if stale and not currently loading
  if (
    minsStale !== Number.POSITIVE_INFINITY &&
    customMetadataState.get('receivedAt') != null &&
    !customMetadataState.loading &&
    DateTime.now().minus({ minutes: minsStale }) > DateTime.fromMillis(customMetadataState.get('receivedAt'))
  ) {
    return true;
  }

  return false;
}

export function fetchCurrentStateColumnsIfNeeded(params: Params, minsStale = 30) {
  return (dispatch: WiwDispatch, getState: WiwGetState) => {
    if (!params.accountId) {
      return Promise.reject('missing accountId');
    }
    if (!params.dataset) {
      return Promise.reject('missing dataset');
    }

    if (
      !shouldFetch({
        state: getState(),
        accountId: params.accountId,
        dataset: params.dataset,
        minsStale,
      })
    ) {
      return Promise.resolve();
    }

    dispatch(fetchCurrentStateColumnsRequested(params));
    return request(CONFIG.REPORTS_API)
      .get(`/current_state/${params.dataset}/columns`, {
        query: {
          accountId: params.accountId,
        },
      })
      .then(response => dispatch(fetchCurrentStateColumnsSuccess(params, response)))
      .catch(error => {
        dispatch(fetchCurrentStateColumnsFailure(params, error));
        throw error;
      });
  };
}
