import type { Conversation } from '@twilio/conversations';
import { List, Set } from 'immutable';
import { useRef, useState } from 'react';

import type { EntityMap } from 'data/types';
import type User from 'data/user/model';
import { getAuthAccount, getAuthUser } from 'shared/auth/selectors';
import FontIcon from 'shared/ui/FontIcon';
import { useWiwDispatch, useWiwSelector } from 'store';
import HeaderV2, { type HeaderButton } from 'workchat/v2/components/HeaderV2';
import { VIEW_LOADING } from 'workchat/v2/components/LoadingV2';
import { VIEW_START_CONVERSATION } from 'workchat/v2/components/StartConversationV2';
import { UserPickerV2 } from 'workchat/v2/components/UserPickerV2';
import { VIEW_CONVERSATION } from 'workchat/v2/components/ViewConversationV2';
import { useTwilioProvider } from 'workchat/v2/providers/TwilioProvider';
import { checkForConversation, trackFilterUse } from 'workchat/v2/store/actions';
import { setConversation, setPendingConversation, viewStackPop, viewStackPush } from 'workchat/v2/store/reducer';
import { getWorkchatUsers } from 'workchat/v2/store/users/usersSelectors';

import 'workchat/styles/NewConversation.scss';

export const VIEW_NEW_CONVERSATION = 'VIEW_NEW_CONVERSATION';

export default function NewConversationV2() {
  const dispatch = useWiwDispatch();
  const account = useWiwSelector(getAuthAccount);
  const user = useWiwSelector(getAuthUser);
  const users: EntityMap<User> = useWiwSelector(getWorkchatUsers);

  const { client } = useTwilioProvider();

  const [title, setTitle] = useState('');
  const [selectedUsers, setSelectedUsers] = useState(List<number>());
  const [locationFilters, setLocationFilters] = useState(Set<number>());
  const [positionFilters, setPositionFilters] = useState(Set<number>());
  const titleInput = useRef<HTMLInputElement>(null);

  const startConversation = async () => {
    if (!selectedUsers.size || !client) {
      return;
    }

    dispatch(viewStackPop());
    dispatch(viewStackPush(VIEW_LOADING));

    const userIds = selectedUsers.toArray();
    const result = await checkForConversation(userIds, client);

    if (result) {
      const { exists, channel }: { exists: boolean; channel: Conversation } = result;

      dispatch(viewStackPop());

      if (exists) {
        dispatch(setConversation(channel.sid));
        dispatch(viewStackPush(VIEW_CONVERSATION));
        dispatch(
          trackFilterUse({
            channelCreated: false,
            workflow: 'CreateChannel',
            locations: locationFilters,
            positions: positionFilters,
          }),
        );
      } else {
        dispatch(setPendingConversation({ userIds, title }));
        dispatch(viewStackPush(VIEW_START_CONVERSATION));
      }
    }
  };

  const back = () => {
    dispatch(viewStackPop());
  };

  const titleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const disabled = !selectedUsers.size;

  const buttons: HeaderButton[] = [
    {
      title: 'Back',
      action: back,
      label: [<FontIcon className="mr-1" key="back" icon="chevron-left" />, 'Back'],
      side: 'left',
      color: 'secondary',
    },
    { action: startConversation, label: 'Next', disabled, side: 'right', color: 'primary' },
  ];

  return (
    <div className="new-conversation">
      <HeaderV2 buttons={buttons}>New Channel</HeaderV2>
      <div className="conversation-name">
        <div className="name-container">
          <label htmlFor="channelTitle" className="sr-only">
            Channel Name
          </label>
          <input
            name="channelTitle"
            type="text"
            placeholder="Name this channel (optional)"
            onChange={titleChange}
            value={title}
            ref={titleInput}
          />
        </div>
      </div>
      <UserPickerV2
        user={user}
        account={account}
        users={users.filter(user => user.is_active)}
        setUsers={selectedUsers => setSelectedUsers(selectedUsers)}
        enableFilters={true}
        updateTrackedFilters={(locations, positions) => {
          setLocationFilters(locations);
          setPositionFilters(positions);
        }}
      />
    </div>
  );
}
