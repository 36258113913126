import { SCHEDULER_HIDE_UNSCHEDULED_EMPLOYEES } from 'scheduler/util/cookies';
import { TOGGLE_HIDE_UNSCHEDULED_EMPLOYEES } from 'store/action-types';

import Cookie from 'js-cookie';

export function toggleHideUnscheduledEmployees() {
  return (dispatch, getState) => {
    const currentValue = getState().scheduler.get('hideUnscheduledEmployees');

    Cookie.set(SCHEDULER_HIDE_UNSCHEDULED_EMPLOYEES, !currentValue);
    return dispatch({
      type: TOGGLE_HIDE_UNSCHEDULED_EMPLOYEES,
    });
  };
}
