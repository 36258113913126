import { OrderedMap } from 'immutable';

import attendanceAutoDeductBreaksImage from 'shared/upsell/assets/img/attendance/auto-deduct-breaks-graphic.svg';
import attendanceClockinRulesImage from 'shared/upsell/assets/img/attendance/kiosks.svg';
import attendanceCustomExportImage from 'shared/upsell/assets/img/attendance/mgr-at-kiosk.svg';
import attendanceManagerNotificationsImage from 'shared/upsell/assets/img/attendance/mgr-at-kiosk.svg';
import attendanceGeoFenceImage from 'shared/upsell/assets/img/attendance/multiple-job-sites.svg';
import attendanceLocationsImage from 'shared/upsell/assets/img/attendance/multiple-locations.svg';
import OtAlertsImage from 'shared/upsell/assets/img/attendance/ot-alert.svg';

export const ATTENDANCE_CLOCKIN_RULES_UPGRADE_DIALOG = 'ATTENDANCE_CLOCKIN_RULES_UPGRADE_DIALOG';
export const ATTENDANCE_CUSTOM_EXPORT_UPGRADE_DIALOG = 'ATTENDANCE_CUSTOM_EXPORT_UPGRADE_DIALOG';
export const ATTENDANCE_MANAGER_NOTIFICATIONS_UPGRADE_DIALOG = 'ATTENDANCE_MANAGER_NOTIFICATIONS_UPGRADE_DIALOG';
export const ATTENDANCE_GEO_FENCE_UPGRADE_DIALOG = 'ATTENDANCE_GEO_FENCE_UPGRADE_DIALOG';
export const ATTENDANCE_MOBILE_UPGRADE_DIALOG = 'ATTENDANCE_MOBILE_UPGRADE_DIALOG';
export const ATTENDANCE_FREEMIUM_DIALOG = 'ATTENDANCE_FREEMIUM_DIALOG';
export const ATTENDANCE_OT_ALERTS_UPGRADE_DIALOG = 'ATTENDANCE_OT_ALERTS_UPGRADE_DIALOG';
export const ATTENDANCE_AUTO_DEDUCT_UPGRADE_DIALOG = 'ATTENDANCE_AUTO_DEDUCT_UPGRADE_DIALOG';

export const attendanceCustomExport = 'attendanceCustomExport';
export const attendanceLocations = 'attendanceLocations';
export const attendanceClockinRules = 'attendanceClockinRules';
export const attendanceManagerNotifications = 'attendanceManagerNotifications';
export const attendanceMobileClockin = 'attendanceMobileClockin';
export const attendanceOtAlerts = 'attendanceOtAlerts';
export const attendanceAutoDeduct = 'attendanceAutoDeduct';

export const defaultAttendanceUpsells = new OrderedMap({
  attendanceCustomExport: {
    label: 'Custom Payroll Reports',
    headline: 'Custom Payroll Reports',
    body:
      'Custom payroll reports give you a detailed break down of your payroll cost.' +
      ' Upgrade your plan for Custom Exports and more great features.',
    unavailableBody: 'Your account administrator needs to upgrade so that you can use these great features.',
    image: attendanceCustomExportImage,
  },
  attendanceLocations: {
    label: 'Create Multiple Schedules',
    headline: 'Create Multiple Schedules',
    body:
      'Using Schedules makes it easy to share and group employees.' +
      ' Upgrade your plan for multiple Schedules and more great features.',
    unavailableBody: 'Your account administrator needs to upgrade so that you can use these great features.',
    image: attendanceLocationsImage,
  },
  attendanceClockinRules: {
    label: 'Early Clock In Prevention',
    headline: 'Early Clock In Prevention',
    body:
      'Early clock in prevention saves you time and money when running payroll.' +
      ' Upgrade your plan to prevent excess payroll cost.',
    unavailableBody: 'Your account administrator needs to upgrade so that you can use these great features.',
    image: attendanceClockinRulesImage,
  },
  attendanceManagerNotifications: {
    label: 'Missed Clock In/Out Alerts',
    headline: 'Missed Clock In/Out Alerts',
    body:
      'Get peace of mind by knowing your staff is there when you scheduled them.' +
      ' Upgrade your plan for clock in/out notifications and more.',
    unavailableBody: 'Your account administrator needs to upgrade so that you can use these great features.',
    image: attendanceManagerNotificationsImage,
  },
  attendanceMobileClockin: {
    label: 'Geo Fence Mobile Clock In',
    headline: 'Geo Fence Mobile Clock In',
    body:
      'Know your employees are at the right location with GPS tracking for clocking in and out.' +
      ' Upgrade your plan for Geo Fence Mobile Clock In.',
    unavailableBody: 'Your account administrator needs to upgrade so that you can use these great features.',
    image: attendanceGeoFenceImage,
  },
  attendanceOtAlerts: {
    label: 'Overtime Alerts',
    headline: 'Prevent Overtime Spend',
    body:
      'Upgrade your plan to get alerted when employees are approaching overtime and help prevent ' +
      'unnecessary overtime costs.',
    unavailableBody: 'Your account administrator needs to upgrade so that you can use these great features.',
    image: OtAlertsImage,
  },
  attendanceAutoDeduct: {
    label: 'Auto-Deduct Breaks',
    headline: 'Automatically Deduct Breaks',
    body:
      "Don't require your team to clock in or out for breaks?" +
      ' Enable auto deduct so scheduled breaks are removed from their worked hours.',
    unavailableBody: 'Your account administrator needs to upgrade so that you can use these great features.',
    image: attendanceAutoDeductBreaksImage,
  },
});
