import EntityBase from 'data/EntityBase';
import type StripeCustomer from 'data/stripe/customers/model';

import { Map } from 'immutable';

export class StripeCustomersState extends EntityBase({
  items: Map<StripeCustomer['id'], StripeCustomer>(),
  loading: false,
  loaded: false,
  receivedAt: 0,
  error: null,
}) {
  // nothing
}
