import { fetchAccountBillingSummary } from 'data/stripe/actions/fetchAccountSummary';
import { fetchStripeInvoices } from 'data/stripe/invoices/actions/fetchStripeInvoices';
import StripeInvoice from 'data/stripe/invoices/model';
import { StripeInvoicesState } from 'data/stripe/state/StripeInvoicesState';
import generateThunkReducers from 'data/util/generateThunkReducers';
import { mergeEntityItems } from 'data/util/sliceHelpers';

import { createSlice } from '@reduxjs/toolkit';

const initialState = new StripeInvoicesState();

export const StripeInvoicesSlice = createSlice({
  name: 'stripe/invoices',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchAccountBillingSummary.fulfilled, (state, action) => {
        return mergeEntityItems(state, action.payload.upcomingInvoices, StripeInvoice);
      })
      .addCase(fetchStripeInvoices.fulfilled, (state, action) => {
        return mergeEntityItems(state, action.payload.data, StripeInvoice);
      });
    generateThunkReducers(builder, 'stripe/invoices');
  },
});

export default StripeInvoicesSlice.reducer;
