import { lazy } from 'react';

const BreakDialog = lazy(() => import('./BreakDialog'));
const ClockInDialog = lazy(() => import('./ClockInDialog'));

// Identifiers for each dialog
export const CLOCK_IN_DIALOG = 'CLOCK_IN_DIALOG';
export const PUNCH_BREAK_DIALOG = 'PUNCH_BREAK_DIALOG';

export default {
  [CLOCK_IN_DIALOG]: ClockInDialog,
  [PUNCH_BREAK_DIALOG]: BreakDialog,
};
