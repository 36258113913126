import { fetchAbsence, fetchAbsences } from 'data/absence/actions/fetchAbsence';
import { applyTemplate } from 'data/shift/actions/applyTemplate';
import { confirmShifts } from 'data/shift/actions/confirmShifts';
import { copyShifts } from 'data/shift/actions/copyShifts';
import { createShift } from 'data/shift/actions/createShift';
import { deleteShift, deleteShifts, deleteShiftsInRange } from 'data/shift/actions/deleteShift';
import { fetchShiftById, fetchShifts, fetchShiftsMerge } from 'data/shift/actions/fetchShifts';
import { persistShifts } from 'data/shift/actions/persistShift';
import { publishShifts } from 'data/shift/actions/publishShifts';
import { splitShift } from 'data/shift/actions/splitShift';
import { takeShift } from 'data/shift/actions/takeShift';
import { unassignShifts } from 'data/shift/actions/unassignShifts';
import { unpublishShifts } from 'data/shift/actions/unpublishShifts';
import { updateShift } from 'data/shift/actions/updateShift';
import Shift, { type ShiftFields } from 'data/shift/model';
import deleteShiftChain from 'data/shiftChain/actions/deleteShiftChain';
import { deleteSite } from 'data/site/actions/deleteSite';
import deleteUser from 'data/user/actions/deleteUser';
import createNoopAction from 'data/util/createNoopAction';
import forwardActionValue from 'data/util/forwardActionValue';
import generateThunkReducers from 'data/util/generateThunkReducers';
import { type FulfilledAction, isInSlice } from 'data/util/sliceHelpers';
import { toEntityArray } from 'shared/util/toEntityMap';
import {
  CONFIRM_OPENSHIFT_ASSIGNMENT,
  FETCH_OPENSHIFT_ASSIGNMENT,
  RESET_SCHEDULER_STATE,
  REVERT_OPENSHIFT_ASSIGNMENT,
  REVERT_UPDATE_SHIFT,
} from 'store/action-types';
import ShiftState from './state';

import { type PayloadAction, createSlice, isAnyOf, isRejected } from '@reduxjs/toolkit';

const initialState = new ShiftState();

export const shiftSlice = createSlice({
  name: 'shift',
  initialState,
  reducers: {
    receiveShift: (state, action) => {
      return state.mergeDeep({
        items: state.items.clear().add(toEntityArray(action.payload.shifts, Shift)),
      });
    },
    receiveShiftMerge: (state, action) => {
      return state.mergeDeep({
        items: state.items.add(toEntityArray(action.payload.shifts, Shift)),
      });
    },
    updateShifts: (state, action) => {
      return state.mergeDeep({
        items: state.items.delete(null).add(toEntityArray(action.payload.shifts, Shift)),
        updating: state.updating === 0 && action.payload.length === 1 ? action.payload[0].id : 0,
      });
    },
    deletedShifts: (state, action) => {
      return state.merge({
        items: state.items.delete(action.payload.ids),
        updating: state.updating === 0 && action.payload.length === 1 ? action.payload[0].id : 0,
      });
    },
    updateShiftsFields: (state, action) => {
      if (!action.payload.ids || !action.payload.fields) {
        return state;
      }
      return state.merge({
        items: state.items.add(
          state.items
            .getMany(action.payload.ids)
            .map(shift => shift.merge(action.payload.fields))
            .toArray(),
        ),
      });
    },
    setAcceptedMissingEntries: (state, action: PayloadAction<{ missingEntries: Array<number> }>) => {
      return state.merge({
        items: state.items.add(
          state.items
            .getMany(action.payload.missingEntries)
            .map(shift => shift.set('is_approved_without_time', true))
            .toArray(),
        ),
      });
    },
    moveShiftTimezones: (state, action) => {
      return state.merge({
        items: state.items.add(
          state.items
            .map(shift => {
              return shift.merge({
                start_time: shift.date('start_time')!.tz(action.payload).format(),
                end_time: shift.date('end_time')!.tz(action.payload).format(),
              });
            })
            .toArray(),
        ),
      });
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchShifts.fulfilled, (state, action) => {
        return shiftSlice.caseReducers.receiveShift(state, action);
      })
      .addCase(fetchShiftsMerge.fulfilled, (state, action) => {
        return shiftSlice.caseReducers.receiveShiftMerge(state, action);
      })
      .addCase(fetchShiftById.fulfilled, (state, action) => {
        return shiftSlice.caseReducers.receiveShiftMerge(state, forwardActionValue(action, 'shift', 'shifts'));
      })
      .addCase(publishShifts.fulfilled, (state, action) => {
        return state.mergeDeep({
          items: state.items.add(
            state.items
              .getMany(action.meta.arg)
              .map(shift => shift.set('published', true))
              .toArray(),
          ),
        });
      })
      .addCase(persistShifts.fulfilled, (state, action) => {
        return shiftSlice.caseReducers.updateShifts(state, action);
      })
      .addCase(createShift.fulfilled, (state, action) => {
        if (
          action.meta.arg.includeRepeating &&
          action.payload.repeating_shifts &&
          action.payload.repeating_shifts.length
        ) {
          return shiftSlice.caseReducers.receiveShiftMerge(
            state,
            forwardActionValue(action, 'repeating_shifts', 'shifts'),
          );
        }

        return shiftSlice.caseReducers.receiveShiftMerge(state, forwardActionValue(action, 'shift', 'shifts'));
      })
      .addCase(updateShift.fulfilled, (state, action) => {
        if (
          action.meta.arg.includeRepeating &&
          action.payload.repeating_shifts &&
          action.payload.repeating_shifts.length
        ) {
          const assignedShifts = action.payload.assigned_shift_instances ?? [];
          return state.mergeDeep({
            items: state.items
              .delete(action.payload.deleted ?? [])
              .add(toEntityArray([...action.payload.repeating_shifts, ...assignedShifts], Shift)),
            updating: 0,
          });
        }
        if (action.payload.taken_shift) {
          return state.mergeDeep({
            items: state.items.add(toEntityArray([action.payload.shift, action.payload.taken_shift], Shift)),
            updating: 0,
          });
        }
        return state.mergeDeep({
          items: state.items.add(toEntityArray(action.payload.shift, Shift)),
          updating: 0,
        });
      })
      .addCase(applyTemplate.fulfilled, (state, action) => {
        return state.mergeDeep({
          items: state.items.add(toEntityArray(action.payload.shifts, Shift)),
        });
      })
      .addCase(confirmShifts.fulfilled, (state, action) => {
        return state.mergeDeep({
          items: state.items.add(
            state.items
              .getMany(action.meta.arg.ids)
              .map(shift =>
                shift.merge({
                  saving: false,
                  acknowledged: 1,
                }),
              )
              .toArray(),
          ),
        });
      })
      .addCase(copyShifts.fulfilled, (state, action) => {
        return state.mergeDeep({
          items: state.items.delete(action.payload.deleted).add(toEntityArray(action.payload.shifts, Shift)),
        });
      })
      .addCase(splitShift.fulfilled, (state, action) => {
        return shiftSlice.caseReducers.updateShifts(state, action);
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        if (action.payload.modified_shifts) {
          return shiftSlice.caseReducers.updateShiftsFields(
            state,
            createNoopAction({
              ids: action.payload.modified_shifts,
              fields: { user_id: 0, published: false },
            }),
          );
        }
        return state;
      })
      .addCase(takeShift.fulfilled, (state, action) => {
        let stateMuts: any = state;
        if (action.payload.taken_shift && action.payload.taken_shift.user_id !== 0) {
          stateMuts = shiftSlice.caseReducers
            .updateShifts(state, forwardActionValue(action, 'taken_shift', 'shifts'))
            .mergeDeep({
              items: state.items.delete(action.meta.arg),
            });
        }
        return shiftSlice.caseReducers.updateShifts(stateMuts, forwardActionValue(action, 'shift', 'shifts'));
      })
      .addCase(unassignShifts.fulfilled, (state, action) => {
        return shiftSlice.caseReducers.updateShifts(state, action);
      })
      .addCase(unpublishShifts.fulfilled, (state, action) => {
        return shiftSlice.caseReducers.updateShiftsFields(
          state,
          createNoopAction({
            ids: action.meta.arg,
            fields: { published: false },
          }),
        );
      })
      .addCase(deleteShift.fulfilled, (state, action) => {
        return state.mergeDeep({
          items: state.items.delete(action.meta.arg.shift.id),
        });
      })
      .addCase(deleteShifts.fulfilled, (state, action) => {
        return state.mergeDeep({
          items: state.items.delete(action.payload.deleted),
        });
      })
      .addCase(deleteShiftsInRange.fulfilled, (state, action) => {
        return state.mergeDeep({
          items: state.items.delete(action.payload.deleted),
        });
      })
      .addCase(deleteShiftChain.fulfilled, (state, action) => {
        return state.mergeDeep({
          items: state.items.delete(action.payload.deleted),
        });
      })
      .addCase(deleteSite.fulfilled, (state, action) => {
        // Update the shifts in state to reflect the backend reassigning site_id 0
        // Updating shifts from the API often returns shifts with their old data
        return state.merge({
          items: state.items.add(
            state.items
              .findWhere('site_id', action.meta.arg.id)
              .map(shift => shift.set('site_id', 0))
              .toArray(),
          ),
        });
      })
      .addCase(RESET_SCHEDULER_STATE, () => initialState)
      .addCase(FETCH_OPENSHIFT_ASSIGNMENT.SUCCESS, (state, action: any) => {
        return state.mergeDeep({
          items: state.items.add(
            action.items.map((shiftData: ShiftFields) =>
              state.items.get(shiftData.id, new Shift(shiftData)).merge({
                user_id: shiftData.user_id || 0,
                notes: shiftData.notes || '',
                instances: shiftData.instances,
              }),
            ),
          ),
        });
      })
      .addCase(CONFIRM_OPENSHIFT_ASSIGNMENT.SUCCESS, (state, action: any) => {
        return state.mergeDeep({
          items: state.items
            .add(toEntityArray(action.payload.shifts, Shift))
            .deleteWhere('isTemporaryInstance', true)
            .delete(action.payload.removed),
        });
      })
      .addCase(FETCH_OPENSHIFT_ASSIGNMENT.FAILURE, (state, action: any) => {
        return state.mergeDeep({
          items: state.items
            .add(
              state.items
                .getMany(action.items)
                .map(shift => shift.set('user_id', 0))
                .toArray(),
            )
            .deleteWhere('isTemporaryInstance', true),
        });
      })
      .addCase(REVERT_OPENSHIFT_ASSIGNMENT, (state, action: any) => {
        return state.mergeDeep({
          items: state.items
            .add(
              state.items
                .getMany(action.items)
                .map(shift => shift.set('user_id', 0))
                .toArray(),
            )
            .deleteWhere('isTemporaryInstance', true),
        });
      })
      .addCase(REVERT_UPDATE_SHIFT, (state, action: any) => {
        return state.mergeDeep({
          items: state.items.add(toEntityArray(action.payload, Shift)),
          updating: 0,
        });
      })
      .addMatcher(isAnyOf(fetchAbsences.fulfilled, fetchAbsence.fulfilled), (state, action) => {
        if (action.payload.data.shifts) {
          return state.mergeDeep({
            items: state.items.add(
              action.payload.data.shifts.map((shift: any) => {
                // TODO: This monstrosity exists here because the Scheduling service is hamstrung by needing to have
                //       all fields in the related objects structs (such as a shift in this case) be strings. Once we
                //       alleviate that concern, we can yeet this awfulness into the sun where it belongs.
                return new Shift({
                  ...shift,
                  id: Number.parseInt(shift.id, 10),
                  account_id: Number.parseInt(shift.account_id, 10),
                  user_id: Number.parseInt(shift.user_id, 10),
                  creator_id: Number.parseInt(shift.creator_id, 10),
                  site_id: Number.parseInt(shift.site_id, 10),
                  location_id: Number.parseInt(shift.location_id, 10),
                  position_id: Number.parseInt(shift.position_id, 10),
                });
              }),
            ),
          });
        }
        return state;
      })
      .addMatcher<FulfilledAction>(
        action => (isInSlice('shift', action) && isRejected(action)) as boolean,
        state => {
          return state.mergeDeep({
            updating: 0,
          });
        },
      );
    generateThunkReducers(builder, 'shift');
  },
});
export const { receiveShift, updateShifts, updateShiftsFields, setAcceptedMissingEntries, moveShiftTimezones } =
  shiftSlice.actions;
export default shiftSlice.reducer;
