import { useMemo } from 'react';
import type React from 'react';
import type { ToastOptions } from 'react-toastify';

import { errorNotice, infoNotice, removeNotice, successNotice, warningNotice } from 'notices/actions';
import { useWiwDispatch } from 'store';

export interface NoticeOptions extends ToastOptions {
  noticeId?: string;
  duration?: number;
  showDismiss?: boolean;
  isPersistent?: boolean;
  displayOnNextPage?: boolean;
  target?: 'dialog' | 'page';
}

export default function useNotice() {
  const dispatch = useWiwDispatch();

  return useMemo(
    () => ({
      successNotice: (message: string | React.ReactElement, options?: NoticeOptions) =>
        dispatch(successNotice(message, options)),
      errorNotice: (message: string | React.ReactElement, options?: NoticeOptions) =>
        dispatch(errorNotice(message, options)),
      infoNotice: (message: string | React.ReactElement, options?: NoticeOptions) =>
        dispatch(infoNotice(message, options)),
      warningNotice: (message: string | React.ReactElement, options?: NoticeOptions) =>
        dispatch(warningNotice(message, options)),
      removeNotice: (noticeId: string) => dispatch(removeNotice(noticeId)),
    }),
    [dispatch],
  );
}
