import type { TagListFields } from 'data/tag/TagList';
import Worktags from 'shared/api/worktags';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type FetchShiftTagsResponse = {
  data: TagListFields[];
};

export const fetchShiftTags = createWiwAsyncThunk<FetchShiftTagsResponse, any>(
  'tags/shiftTags/fetch',
  async (ids, { rejectWithValue }) => {
    const shiftIds = ids.map((id: number) => `${id}`);
    try {
      return (await Worktags.url('/shifts').post({ ids: shiftIds })) as FetchShiftTagsResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
