import { SCHEDULER_POSITIONS_FILTER } from 'scheduler/util/cookies';
import { getPersistedFilter, persistFilter } from 'scheduler/util/persistFilter';
import { UPDATE_POSITION_FILTER } from 'store/action-types';

import { uniq } from 'lodash';

export function updatePositionFilter(payload) {
  return dispatch => {
    const deduped = uniq(payload);
    persistFilter(SCHEDULER_POSITIONS_FILTER, deduped);

    return dispatch({
      type: UPDATE_POSITION_FILTER,
      payload: deduped,
    });
  };
}

export const getKey = (location, userId) => `${SCHEDULER_POSITIONS_FILTER}-${userId}-${location}`;

export const getInitialPositionFilter = () => getPersistedFilter(SCHEDULER_POSITIONS_FILTER, ['all']);
