import { getReportSize } from 'reporting/selectors/getReportSize';
import type { WiwState } from 'store';

import { createSelector } from '@reduxjs/toolkit';

export const getCustomReportBuilderTempReportSize = (state: WiwState): number | undefined =>
  state.reporting.customReportBuilder.tempReportSize;

export const getTempReportSize = createSelector(
  [getReportSize, getCustomReportBuilderTempReportSize],
  (reportSize, tempReportSize) => {
    if (tempReportSize == null) {
      if (reportSize == null) {
        return 0;
      }
      return reportSize;
    }

    return tempReportSize;
  },
);
