import { createSelector } from '@reduxjs/toolkit';

import type { EntityMap } from 'data/types';
import User from 'data/user/model';
import type { WiwState } from 'store';
import type { ReduxParticipant } from 'workchat/v2/store/participants/participantsReducer';
import { getWorkchatSelectedConversationId } from 'workchat/v2/store/selectors';
import { getWorkchatUsers } from 'workchat/v2/store/users/usersSelectors';

export const getStateWorkchat = (state: WiwState) => state.workchatv2;

export class UserParticipant extends User {
  participant: ReduxParticipant; // Twilio Participant sid

  constructor(user: User, participant: ReduxParticipant) {
    super(user);
    this.participant = participant;
  }
}

export const getParticipantsEntityMap = createSelector(
  [getStateWorkchat],
  state => state.participants.items as EntityMap<ReduxParticipant>,
);

export const getPartcipantById = createSelector(
  [getParticipantsEntityMap, (_state, participantId) => participantId],
  (participants, participantId) => participants.get(participantId),
);

export const getParticipantsByConversationId = createSelector([getParticipantsEntityMap], participants =>
  participants.groupBy(participant => participant.cid),
);

export const getParticipantsForSelectedConversation = createSelector(
  [getWorkchatSelectedConversationId, getParticipantsByConversationId],
  (selectedConversationId, participantsByConversationId) =>
    selectedConversationId ? participantsByConversationId.get(selectedConversationId)?.toList() : null,
);

export const getParticipantsForConversationId = createSelector(
  [getParticipantsByConversationId, (_state, conversationId) => conversationId],
  (participantsByConversationId, conversationId) => participantsByConversationId.get(conversationId),
);

export const getParticipantsForConversationIdAsList = createSelector(
  [getParticipantsForConversationId, (_state, conversationId) => conversationId],
  participantsForConversationId => participantsForConversationId?.toList(),
);

export const getUserParticipantsForConversationId = createSelector(
  [getWorkchatUsers, getParticipantsForConversationIdAsList, (_state, conversationId) => conversationId],
  (users, participantsForConversationIdAsList): User[] | undefined => {
    return participantsForConversationIdAsList?.toArray().map(participant => {
      if (!participant.userId) {
        // Zombie participant - could happen when a User is deleted from WiW DB but not from Twilio's
        return new User({ id: -9999 });
      }
      return users.get(participant.userId) || new User({ id: participant.userId });
    });
  },
);
