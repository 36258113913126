import type { FetchAccountResponse } from 'data/account/actions/fetchAccounts';
import type Account from 'data/account/model';
import { fetchUsers } from 'data/user/actions/fetchAllUsers';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { setGlobalTimezone } from 'shared/util/time';
import { createWiwAsyncThunk } from 'store';

export default createWiwAsyncThunk<FetchAccountResponse, Account>(
  'account/persistAccount',
  async (account, { rejectWithValue, dispatch }) => {
    try {
      const accountRequest = { ...account.toJSON(), allow_duplicate_place: true };
      const response = (await Monolith.url(`/account/${account.id}`).put(accountRequest)) as FetchAccountResponse;
      const responseData = response.account || response;

      setGlobalTimezone(responseData.timezone_name);

      // Updating the users timezone when they use the account's timezone... There needs to be a better way to do this
      if (account.timezone_id !== responseData.timezone_id) {
        dispatch(fetchUsers());
      }

      return { account: responseData };
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
