import { getStepUpToken, getStepUpTokenInfo } from 'shared/auth/auth';

import type { FetchLike, WretchOptions } from 'wretch';

export const setStepUpHeaders = (next: FetchLike) => (url: string, opts: WretchOptions) => {
  const stepUpToken = getStepUpTokenInfo();
  // If we have a valid step up auth token, use it as the W-Token and Authorization
  // If api requires a step up auth token, then let the api handle the validation/error
  if (stepUpToken?.isValidStepUpToken()) {
    // @ts-ignore
    opts.headers['W-Token'] = getStepUpToken();
    // @ts-ignore
    opts.headers.Authorization = getStepUpToken();
  }

  return next(url, opts);
};
