import { PropTypes } from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Location from 'data/location/model';
import { getLocations } from 'data/location/selectors';
import { ActionButton } from 'notifications/components/ActionButton';
import { NotifyItem } from 'notifications/components/NotifyItem';
import SpriteIcon from 'shared/ui/SpriteIcon';

import pluralize from 'pluralize';

export class OpenShiftApprovalNotification extends Component {
  static propTypes = {
    request: PropTypes.object.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    hideNotificationsDrawer: PropTypes.func.isRequired,
    locations: PropTypes.object.isRequired,
  };

  getActions = () => {
    return [<ActionButton key="view-openshift-request" title="View Request" type="green" click={this.handleClick} />];
  };

  handleClick = () => {
    this.props.hideNotificationsDrawer();
    this.props.history.push(`/requests/openshift/${this.props.request.id}`);
  };

  renderTitle = () => {
    const { request, locations } = this.props;
    const requestCount = request.user_requests.filter(userRequest => userRequest.deleted_at === null).length;
    const location = locations.get(request.shift.location_id, new Location()).name;
    return `${pluralize('user', requestCount, true)} ${pluralize(
      'has',
      requestCount,
    )} requested an OpenShift for ${request.shiftModel.date('start_time').format('MMMM D')} at ${location}`;
  };

  render() {
    return (
      <NotifyItem
        title={this.renderTitle()}
        tag={{ name: 'Pending' }}
        icon={<SpriteIcon icon="requests-openshifts" className="lg" />}
        actions={this.getActions()}
      />
    );
  }
}

export default connect(state => ({
  locations: getLocations(state),
}))(withRouter(OpenShiftApprovalNotification));
