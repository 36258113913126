import { useClickOutside } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { useWiwDispatch, useWiwSelector } from 'store';

import { providerInstance as provider } from 'workchat/TwilioProvider';
import { hideWorkchat, markConversationRead, startWorkchat, track, updateReadStatus } from 'workchat/actions';
import Loading from 'workchat/components/Loading';
import ConfirmModal from 'workchat/containers/ConfirmModal';

import { useLDFlag } from 'data/LD/selectors/getLDFlag';
import { getAccountPlan } from 'data/account/selectors';
import { fetchLocations } from 'data/location/actions/fetchLocations';
import { fetchPositions } from 'data/position/actions/fetchPositions';
import { getToken } from 'shared/auth/auth';
import { getAuthAccount, getAuthUser } from 'shared/auth/selectors';
import FontIcon from 'shared/ui/FontIcon';
import { WORKCHAT_TOGGLE } from 'workchat/action-types';
import ViewConversation from 'workchat/containers/ViewConversation';

import 'workchat/styles/global.scss';

const DEFAULT_MAX_UNREAD_COUNT = 9;

export default function WorkChat() {
  const dispatch = useWiwDispatch();

  const user = useWiwSelector(getAuthUser);
  const account = useWiwSelector(getAuthAccount);
  const accountPlan = useWiwSelector(getAccountPlan);

  const show = useWiwSelector(state => state.workchat.show || false);
  const unread = useWiwSelector(state => state.workchat.unread || 0);
  const Screen = useWiwSelector(state => state.workchat.screens.peek() || Loading);
  const modal = useWiwSelector(state => state.workchat.modal);
  const flags = useWiwSelector(state => state.workchat.flags);

  const fdt837 = useLDFlag('fdt-837-work-chat-delete');
  const fdt837debug = useLDFlag('fdt-837-1-work-chat-info-logging');

  const ref = useClickOutside(() => dispatch(hideWorkchat()));

  const [initialized, setInitialized] = useState(false);

  const token = getToken();
  const enableWorkchat = account.settings.workchat.enabled && user.id > 0 && !fdt837;

  useEffect(() => {
    if (enableWorkchat && !initialized) {
      dispatch(startWorkchat(token, user, fdt837debug));
      dispatch(fetchLocations());
      dispatch(fetchPositions());

      setInitialized(true);
    } else if (!enableWorkchat) {
      setInitialized(false);
    }
  }, [enableWorkchat, initialized]);

  const toggleWorkchat = () => {
    if (!provider) {
      // Not far enough along with initialization to render correctly yet
      return;
    }

    const newShow = !show;
    dispatch({
      type: WORKCHAT_TOGGLE,
      newShow,
    });

    if (newShow) {
      dispatch(markConversationRead());
      dispatch(updateReadStatus());

      const hasConversations = flags?.get('conversations');
      if (!hasConversations || Screen === ViewConversation) {
        dispatch(track('WorkChat: Viewed Channel', { accessType: 'Channel List' }));
      } else {
        dispatch(track('WorkChat: Accessed', { accessType: 'Nav Bar' }));
      }
    }
  };

  return (
    <div ref={ref} className="h-100">
      <button
        type="button"
        aria-expanded={show}
        aria-haspopup="true"
        className={`toggle-dropdown ${show ? 'open' : ''}`}
        onClick={toggleWorkchat}
      >
        <FontIcon icon="workchat" />
        {unread > 0 && (
          <div className="unread-count">
            <div>{unread <= DEFAULT_MAX_UNREAD_COUNT ? unread : `${DEFAULT_MAX_UNREAD_COUNT}+`}</div>
          </div>
        )}
        <span className="nav-item-label hide-label-tablet">WorkChat</span>
      </button>
      {show && (
        <div className="workchat-main dropdown-menu">
          <Screen account={account} user={user} plan={accountPlan} />
        </div>
      )}
      {modal && (
        <ConfirmModal
          title={modal.title!}
          text={modal.text!}
          confirm={modal.confirm}
          onConfirm={modal.onConfirm}
          onCancel={modal.onCancel}
        />
      )}
    </div>
  );
}
