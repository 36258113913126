import { Fragment } from 'react';

import useIntl from 'shared/i18n/useIntl';
import { formatCurrency, formatNumber } from 'shared/i18n/utils/numbers';

type Props = {
  value: number;
  currency?: boolean;
  symbolOnly?: boolean;
  options?: object;
};

const defaultProps = {
  value: 0,
};
export default function I18nNumber(props: Props) {
  props = { ...defaultProps, ...props };

  const { i18n } = useIntl();

  const getCurrency = (value: number) => {
    const { options } = props;
    const formatted = formatCurrency(value, i18n, options);
    if (props.symbolOnly) {
      return formatted.replace(/[0-9.,]/g, '');
    }
    return formatted;
  };

  if (props.currency) {
    return <Fragment>{getCurrency(props.value)}</Fragment>;
  }

  return <Fragment>{formatNumber(props.value, i18n, props.options)}</Fragment>;
}
