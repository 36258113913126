import Environment from 'shared/util/environment';
import LaunchDarkly from 'shared/vendor/launchDarkly';
import ElevioHelper from 'shared/vendor/util/elevio';
import GoogleTagManagerHelper from 'shared/vendor/util/googleTagManager';

import { LicenseManager } from '@ag-grid-enterprise/core';
import { loadStripe } from '@stripe/stripe-js';
import { client as Elevio } from 'elevio';
import { get, isFunction } from 'lodash';
import { INDUSTRIES, INDUSTRIES_EXT, INDUSTRIES_FOCUSED_ONBOARDING } from 'scheduler/modules/onboarding/util/constants';
import ChatHelper from 'shared/util/chat';
import { posthogIdentify, setPosthogSuperProperties } from 'shared/vendor/posthog';

export default class Identify {
  /**
   *
   * @param {User} user
   * @param {Account} account
   * @param {Plan} plan
   * @param {Login} login
   * @param {AccountMetadata} accountMetadata
   * @param {UserMetadata} userMetadata
   * @param dispatch
   */
  static identifyThirdParty(user, account, plan, login, dispatch, accountMetadata, userMetadata) {
    this.identifyLaunchDarkly(user, account, plan, dispatch);
    this.identifyElevio(user, account, plan);
    this.identifyZendesk(user, account, plan);
    this.identifyMercury(user);
    this.identifyGoogleTagManager(user, account, plan);
    this.identifyAgGrid();
    this.identifyPosthog(user, account, login, plan);
    this.identifyStripe(account);
    this.identifyIntercom(user, account, plan);
  }

  static identifyLaunchDarkly(user, account, plan, dispatch) {
    try {
      LaunchDarkly.init(user, account, plan, dispatch);
    } catch (e) {
      console.warn('Launch Darkly identification issue: ', e);
    }
  }

  static identifyMercury(user) {
    try {
      if (typeof window.mercury !== 'undefined') {
        //Mercury expects 'development' rather than 'dev'
        const env = Environment.get() === 'dev' ? 'development' : Environment.get();
        window.mercury.identify(user.account_id, user.id, user.login_id, 'wheniwork-js', env);
      }
    } catch (e) {
      console.warn('Mercury identification issue: ', e);
    }
  }

  static identifyGoogleTagManager(user, account, plan) {
    try {
      // No need to wait for GTM async library to load.
      // GTM gracefully handles data being pushed to data layer before library is loaded
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        user: GoogleTagManagerHelper.userData(user),
        account: GoogleTagManagerHelper.accountData(account, plan),
      });
      window.dataLayer.push({ event: 'userAttributesSet' });
    } catch (e) {
      console.warn('Google Tag Manager identification issue: ', e);
    }
  }

  static identifyElevio(user, account, plan) {
    try {
      // require elevio only after we've booted the app and have sufficient data about the user.
      // and after we've waited a bit. elevio is slow.
      setTimeout(() => {
        Elevio.load('56cc6a1ee2ffa').then(() => {
          if (isFunction(get(window, '_elev.changeUser'))) {
            // Prevent spurious console errors when domain is blocked (e.g., NoScript users)
            window._elev.changeUser(ElevioHelper.identifyUser(user));
          }
          if (isFunction(get(window, '_elev.disableModules'))) {
            // Prevent spurious console errors when domain is blocked (e.g., NoScript users)
            if (window.zE?.widget === 'messenger') {
              window._elev.disableModules(ElevioHelper.getDisabledModules(user, account, plan, true));
            } else {
              window._elev.disableModules(ElevioHelper.getDisabledModules(user, account, plan));
            }
          }
          if (isFunction(get(window, '_elev.on'))) {
            window._elev.on('module:opened', function (moduleId) {
              if (moduleId === 4) {
                window.zE?.('webWidget', 'show');
                window.zE?.('webWidget', 'open');
              }
              if (moduleId === 19) {
                window.zE?.('messenger', 'show');
                window.zE?.('messenger', 'open');
              }
            });
          }
        });
      }, 5000);
    } catch (e) {
      console.warn('Elevio identification issue: ', e);
    }
  }

  static identifyZendesk(user, account, plan) {
    try {
      if (ChatHelper.canChat(user, plan) && !ChatHelper.chatWithSalesNotCare(plan, account)) {
        import('./zendesk').then(zendesk => {
          zendesk.initializeZendeskMessengerWidget(user, account, plan);
        });
      }
    } catch (e) {
      console.warn('Zendesk identification issue: ', e);
    }
  }

  static identifyAgGrid() {
    try {
      LicenseManager.setLicenseKey(CONFIG.AG_GRID_LICENSE_KEY);
    } catch (e) {
      console.warn('AG Grid identification issue: ', e);
    }
  }

  static identifyPosthog(user, account, login, plan) {
    try {
      posthogIdentify(user.id, {
        Name: user.fullName,
        Email: user.email,
        'Account ID': account.id,
        'Person ID': login.id,
        'User role': user.roleName,
        'Plan ID': plan.id,
        Environment: Environment.get(),
      });
      setPosthogSuperProperties(user, account, login);
    } catch (e) {
      console.warn('Posthog identification issue: ', e);
    }
  }

  // TODO: remove this when all components are moved to useWiwStripe hook
  static async identifyStripe(account) {
    try {
      const stripeKey = account.isV5Billing() ? CONFIG.STRIPE.KEY : CONFIG.STRIPE_KEY;
      window.stripe = await loadStripe(stripeKey);
    } catch (e) {
      console.warn('Stripe identification issue: ', e);
    }
  }

  static identifyIntercom(user, account, plan) {
    try {
      if (!user || !account || !plan || plan.isPaid()) {
        return;
      }
      if (ChatHelper.canChat(user, plan) && ChatHelper.chatWithSalesNotCare(plan, account)) {
        const moment = require('moment-timezone');

        const allIndustries = INDUSTRIES.concat(INDUSTRIES_EXT).concat(INDUSTRIES_FOCUSED_ONBOARDING);
        const industryName = allIndustries.find(industry => industry.id === account.industry_id)?.label || 'Unknown';

        const companyAttributes = {
          id: account.id?.toString(),
          name: account.company,
          createdAt: account.created_at ? moment(account.created_at).unix() : null,
          industry: industryName,
          planName: plan.name,
          trialCreatedAt: account.trial_created_at ? moment(account.trial_created_at).unix() : null,
        };

        const intercomSettings = {
          app_id: CONFIG.VENDORS.INTERCOM.appId,
          email: user.email,
          created_at: user.created_at ? moment(user.created_at).unix() : null,
          name: user.fullName,
          user_id: user.id,
          'User ID': user.id?.toString(),
          userId: user.id?.toString(),
          userUpdatedAt: user.updated_at ? moment(user.updated_at).unix() : null,
          Role: user.role,
          company: companyAttributes,
          accountId: account.id?.toString(),
          attendance: account.hasAttendance(),
          convertedAt: account.converted_at ? moment(account.converted_at).unix() : null,
          convertedDate: account.converted_at ? moment(account.converted_at).format() : null,
          employees: account.userCount(),
          expectedEmployees: account.ref_employees,
          firstName: user.first_name,
          lastName: user.last_name,
          isFree: plan.isFree(),
          masterAccountId: account.master_id?.toString(),
          'Number of Employees': account.userCount(),
          planEmployees: plan.employees,
          planId: plan.id?.toString(),
          planName: plan.name,
          role: user.roleName,
          title: user.roleName,
        };

        import('./intercom').then(() => {
          Intercom('boot', intercomSettings);
        });
      }
    } catch (e) {
      console.warn('Intercom identification issue: ', e);
    }
  }
}
