import * as chrono from 'chrono-node';
import type { BaseModelType } from 'data/BaseModel';
import type Account from 'data/account/model';
import { memoize } from 'lodash';
import { DateTime, Duration, Settings } from 'luxon';
import moment, { type unitOfTime, type Moment, type MomentInput, tz } from 'moment-timezone';
import pluralize from 'pluralize';

// Monkey-patch moment's add to get around a timezone bug. This is a great idea.
// TODO: Delete this abomination if https://github.com/moment/moment/issues/4785
// is ever fixed.
// @ts-ignore
moment.fn._oldAdd = moment.fn.add;
moment.fn.add = function () {
  const hourBefore = this.hour();
  // @ts-ignore
  // biome-ignore lint/style/noArguments: Dawg even i have my limits...
  this._oldAdd(...arguments);
  const hourAfter = this.hour();
  // biome-ignore lint/style/noArguments: Dawg even i have my limits...
  if (['d', 'day', 'days'].includes(arguments[1]) && hourBefore === 0 && hourAfter === 23) {
    // wow good one moment, you're so good at timezones
    // @ts-ignore
    this._oldAdd(1, 'hour');
  }

  return this;
};

export const formatTime = (time: Moment | null) => {
  if (!time) {
    return '';
  }
  if (!time.minutes()) {
    return time.format('ha').slice(0, -1);
  }

  return time.format('h:mma').slice(0, -1);
};

export const formatTimeLong = (time: Moment | null) => {
  if (!time) {
    return '';
  }
  return time.format('LT').replace(/\s/, '').toLowerCase().slice(0, -1);
};

export const shortTimeRange = (start: Moment | null, end: Moment | null) => {
  return { startString: formatTime(start), endString: formatTime(end) };
};

/**
 * Formats the hours and minutes of a duration.
 *
 * NOTE: When passing in a Luxon duration, you MUST specify the 'hours' and 'minutes' units in the diff method!
 *
 *   luxonDate1.diff(luxonDate2, ['hours', 'minutes']);
 *
 */
export const formatDurationLong = (duration: Duration | moment.Duration) => {
  let hours: number;
  let minutes: number;
  if (Duration.isDuration(duration)) {
    hours = duration.hours;
    minutes = Math.floor(duration.minutes);
  } else {
    hours = Math.floor(duration.asHours());
    minutes = duration.minutes();
  }

  let result = '';
  if (hours > 0 && minutes > 0) {
    result = `${hours} ${pluralize('hour', hours)} and ${minutes} ${pluralize('minute', minutes)}`;
  } else if (hours > 0) {
    result = `${hours} ${pluralize('hour', hours)}`;
  } else {
    result = `${minutes} ${pluralize('minute', minutes)}`;
  }
  return result;
};

export function setMomentDateTime(dayInstance: Moment | MomentInput, timeInstance: Moment) {
  return moment(dayInstance).set({
    hour: timeInstance.hour(),
    minute: timeInstance.minute(),
    second: timeInstance.second(),
  });
}

/**
 * Builds updated start and end times for an moment instance
 *
 * @param entity {Object}
 * @param data {{start_date?: moment.Moment, end_date?: moment.Moment, time: {start: string|moment.Moment, end: string|moment.Moment}}}
 * @param startAccessor {string}
 * @return {{start: moment.Moment, end: moment.Moment}}
 */
export function setMomentTimes(entity: any, data: any, startAccessor = 'start_date') {
  const startInstance = data[startAccessor]
    ? moment(data[startAccessor], 'MMM DD YYYY')
    : moment(entity[startAccessor]);

  const startTime = moment(data.time.start, ['h:mma', 'hmma', 'HHmm', 'HH:mm']);
  const startDate = moment(startInstance).set({
    hour: startTime.hour(),
    minute: startTime.minute(),
    second: startTime.second(),
  });

  const endTime = moment(data.time.end, ['h:mma', 'hmma', 'HHmm', 'HH:mm']);
  const endDate = moment(startInstance);
  if (endTime.isSameOrBefore(startTime)) {
    endDate.add(1, 'day');
  }
  endDate.set({
    hour: endTime.hour(),
    minute: endTime.minute(),
    second: endTime.second(),
  });

  return {
    start: startDate,
    end: endDate,
  };
}

/**
 * Builds updated start and end luxon times from moment instances
 */
export function setMomentTimeRange(baseDate: Moment, timeRange: { start: string; end: string }) {
  const updateDateTime = (baseDate: Moment, timeString: string): Moment => {
    const parsedHours = moment(timeString, ['h:mma', 'hmma', 'HHmm', 'HH:mm', 'HH:mm:ss']);
    if (!parsedHours) {
      return baseDate;
    }
    return baseDate.clone().set({
      hour: parsedHours.hour(),
      minute: parsedHours.minute(),
      second: parsedHours.second(),
    });
  };

  const startDate = updateDateTime(baseDate, timeRange.start);
  let endDate = updateDateTime(baseDate, timeRange.end);

  if (endDate <= startDate) {
    // Recreate the endDate using the startDate's day value +1, to avoid carrying forward any DST-boundary artifacts
    endDate = updateDateTime(baseDate.clone().add(1, 'day'), timeRange.end);
  }

  return { start: startDate, end: endDate };
}

/**
 * Gets a moment instance for whatever you throw at it. It uses chrono to parse strings.
 *
 * @param  datetime
 * @return moment.Moment
 */
const memoizedParse = memoize(datetime => {
  if (typeof datetime === 'string') {
    const parsedDate = moment(datetime, moment.ISO_8601);

    if (parsedDate.isValid()) {
      return parsedDate;
    }
    return moment(chrono.strict.parseDate(datetime));
  }
  return moment(datetime);
});

/**
 *
 * @param datetime
 * @return moment.Moment
 */
export function getMomentDate(datetime?: moment.MomentInput | DateTime, checkTimezone = false) {
  let result: Moment;
  if (!datetime) {
    return moment();
  }

  if (datetime instanceof moment) {
    result = datetime as Moment;
  } else if (DateTime.isDateTime(datetime)) {
    // Some components require moments, so until we've completely transitioned
    // to Luxon, this is a nice helper to make code a little less verbose.
    result = moment(datetime.toJSDate());
  } else {
    result = memoizedParse(datetime);
  }

  const clonedResult = result.clone();
  // until we switch over to luxon or somehthing else that does not hate timezone as much as moment does
  // this will probably come in handy when wanting to know what the global timezone setting is vs what
  // an existing moment time thinks it is, also clone does not get the updated global timezone everytime :0
  if (checkTimezone) {
    const momentTz = moment(datetime).tz();
    if (momentTz !== clonedResult.tz()) {
      return result.clone().tz(momentTz!);
    }
  }

  if (clonedResult.utcOffset() !== result.utcOffset()) {
    return clonedResult.utcOffset(result.utcOffset());
  }

  return result.clone();
}

/**
 * Converts a time to the account's timezone.
 * @return moment.Moment
 */
export function toAccountTime(datetime: MomentInput, account: Account) {
  return getMomentDate(datetime).tz(account.timezone_name);
}

/**
 * Gets whether two ranges of time overlap. Note that if one ends at the
 * same time another starts, that is NOT considered an overlap, unless
 * `inclusive` is true.
 *
 */
export function rangesOverlap(
  start1: Moment | MomentInput,
  end1: Moment | MomentInput,
  start2: Moment | MomentInput,
  end2: Moment | MomentInput,
  inclusive = false,
) {
  if (inclusive) {
    return (
      getMomentDate(start1).isSameOrBefore(getMomentDate(end2)) &&
      getMomentDate(end1).isSameOrAfter(getMomentDate(start2))
    );
  }
  return getMomentDate(start1).isBefore(getMomentDate(end2)) && getMomentDate(end1).isAfter(getMomentDate(start2));
}

/**
 * Gets the intersection of two time ranges. The moment instances returned
 * by this function are already cloned and ready to mutate without side
 * effects.
 */
export function rangeIntersect(
  start1: Moment | MomentInput,
  end1: Moment | MomentInput,
  start2: Moment | MomentInput,
  end2: Moment | MomentInput,
) {
  return {
    start: moment.max(getMomentDate(start1), getMomentDate(start2)),
    end: moment.min(getMomentDate(end1), getMomentDate(end2)),
  };
}

/**
 * Gets whether a time range is contained within another. Note that if the ranges start or end at the same time, that
 * is NOT considered containment, unless `inclusive` is true.
 *
 */
export function rangeIsContained(
  insideStart: Moment | MomentInput,
  insideEnd: Moment | MomentInput,
  outsideStart: Moment | MomentInput,
  outsideEnd: Moment | MomentInput,
  inclusive = false,
) {
  if (inclusive) {
    return (
      getMomentDate(insideStart).isSameOrAfter(getMomentDate(outsideStart)) &&
      getMomentDate(insideEnd).isSameOrBefore(getMomentDate(outsideEnd))
    );
  }
  return (
    getMomentDate(insideStart).isAfter(getMomentDate(outsideStart)) &&
    getMomentDate(insideEnd).isBefore(getMomentDate(outsideEnd))
  );
}

/**
 * For those times when you need to group by day, but you're dealing with data
 * spanning more than a month. It's not an accurate count of days since the year 0,
 * but it's good enough to keep things unique.
 *
 * @param  datetime
 * @return number   More or less the number of days since the year 0
 */
export function uniqueDay(datetime: string | Moment) {
  const dt = getMomentDate(datetime);
  return 366 * dt.year() + dt.dayOfYear();
}

export function uniqueHour(datetime: string | Moment) {
  const dt = getMomentDate(datetime);
  return 24 * uniqueDay(datetime) + dt.hour();
}

/**
 * Set the date portion of a datetime to match another. Useful for when you have just
 * a time and need to place that time on a specific day.
 */
export function setYearMonthDay(time: Moment, date: Moment) {
  return getMomentDate(date).set({
    hour: time.hour(),
    minute: time.minute(),
    second: time.second(),
  });
}

/**
 * Convert a string representing a given time into a Date object.
 *
 * The Date object will have attributes others than hours, minutes and
 * seconds set to current local time values. The function will return
 * false if given string can't be converted.
 *
 * If there is an 'a' in the string we set am to true, if there is a 'p'
 * we set pm to true, if both are present only am is setted to true.
 *
 * All non-digit characters are removed from the string before trying to
 * parse the time.
 *
 * ''       can't be converted and the function returns false.
 * '1'      is converted to     01:00:00 am
 * '11'     is converted to     11:00:00 am
 * '111'    is converted to     01:11:00 am
 * '1111'   is converted to     11:11:00 am
 * '11111'  is converted to     01:11:11 am
 * '111111' is converted to     11:11:11 am
 *
 * Only the first six (or less) characters are considered.
 *
 * Special case:
 *
 * When hours is greater than 24 and the last digit is less or equal than 6, and minutes
 * and seconds are less or equal than 60, we append a trailing zero and
 * start parsing process again. Examples:
 *
 * '95' is treated as '950' and converted to 09:50:00 am
 * '46' is treated as '460' and converted to 05:00:00 am
 */
export function parseTextToTime(str: string): moment.Moment | null {
  // Return null if input is not a valid string
  if (!str || typeof str !== 'string') {
    return null;
  }

  // Normalize the input string: remove non-digit and non-'ap' characters
  const normalizedStr = str.toLowerCase().replace(/[^0-9ap]/g, '');
  // Determine if the time is AM or PM
  const isAM = normalizedStr.includes('a');
  const isPM = !isAM && normalizedStr.includes('p');
  // Remove 'a' and 'p' from the time string
  const timeStr = normalizedStr.replace(/[ap]/g, '');

  // Return null if no digits are left after normalization
  if (timeStr.length === 0) {
    return null;
  }

  // Initialize time components
  let hour = 0;
  let minute = 0;
  let second = 0;

  // Parse time components based on the length of the time string
  switch (timeStr.length) {
    case 1:
    case 2:
      // For 1 or 2 digits, treat as hour
      hour = Number.parseInt(timeStr, 10);
      break;
    case 3:
      // For 3 digits, treat as 1 digit hour and 2 digit minute
      hour = Number.parseInt(timeStr.slice(0, 1), 10);
      minute = Number.parseInt(timeStr.slice(1), 10);
      break;
    case 4:
      // For 4 digits, treat as 2 digit hour and 2 digit minute
      hour = Number.parseInt(timeStr.slice(0, 2), 10);
      minute = Number.parseInt(timeStr.slice(2), 10);
      break;
    case 5:
      // For 5 digits, treat as 1 digit hour, 2 digit minute, 2 digit second
      hour = Number.parseInt(timeStr.slice(0, 1), 10);
      minute = Number.parseInt(timeStr.slice(1, 3), 10);
      second = Number.parseInt(timeStr.slice(3), 10);
      break;
    default:
      // For 6 or more digits, treat as 2 digit hour, 2 digit minute, 2 digit second
      hour = Number.parseInt(timeStr.slice(0, 2), 10);
      minute = Number.parseInt(timeStr.slice(2, 4), 10);
      second = Number.parseInt(timeStr.slice(4, 6), 10);
  }

  // Validate parsed time components
  if (Number.isNaN(hour) || Number.isNaN(minute) || Number.isNaN(second) || minute >= 60 || second >= 60) {
    return null;
  }

  // Handle special cases like '46', '95'
  if (hour > 24 && timeStr.length <= 3) {
    minute = (hour % 10) * 10;
    hour = Math.floor(hour / 10);
  }

  // Ensure hour is always 0-23
  hour = hour % 24;

  // Adjust hour for AM/PM
  if (isAM && hour === 12) {
    hour = 0;
  } else if (isPM && hour < 12) {
    hour += 12;
  }

  // Create and return the moment object with parsed time
  const time = moment();
  time.set({ hour, minute, second });
  return time;
}

export function accountStartTime(account: Account) {
  const workdayStart = account.getSettings('payroll.work_day_start') || '00:00';
  const [hour, minute] = workdayStart.split(':');

  return moment()
    .tz(account.timezone_name)
    .hour(Number.parseInt(hour, 10))
    .minute(Number.parseInt(minute, 10))
    .second(0);
}

export function crossesWorkday(shift: BaseModelType<{ start_time: string; end_time: string }>, account?: Account) {
  const shiftStart = shift.mustDate('start_time');
  const shiftEnd = shift.mustDate('end_time');
  const startTime = account ? accountStartTime(account).day(shiftEnd.day()) : shiftStart.clone().endOf('day');

  return startTime.isBetween(shiftStart, shiftEnd);
}

export function roundToNearestHour(time: Moment) {
  if (time.minutes() === 59) {
    return time.clone().add(1, 'hour').startOf('hour');
  }

  return time;
}

export function setGlobalTimezone(timezoneName: string) {
  //allow us to change the default tz - real fun
  tz.setDefault(timezoneName);
  Settings.defaultZone = timezoneName;
}

/**
 * Round the given date to the nearest minute in the given increment.
 *
 * The type trickery here is so that TypeScript can know that the type that you pass in
 * is the type that you'll get out.
 */
export function roundToMinuteIncrement<TDate extends Moment | DateTime | Date>(
  date: TDate,
  increment: 5 | 6 | 15,
): TDate extends Moment ? Moment : TDate extends DateTime ? DateTime : Date {
  if (DateTime.isDateTime(date)) {
    const decimalMinutes = date.minute + date.second / 60;
    return date.set({
      minute: Math.round(decimalMinutes / increment) * increment,
      second: 0,
      millisecond: 0,
    }) as any;
  }

  if (moment.isMoment(date)) {
    const decimalMinutes = date.minutes() + date.seconds() / 60;
    return date.clone().set({
      minutes: Math.round(decimalMinutes / increment) * increment,
      seconds: 0,
      milliseconds: 0,
    }) as any;
  }

  const decimalMinutes = date.getMinutes() + date.getSeconds() / 60;
  const clone = new Date(date.valueOf());
  clone.setMinutes(Math.round(decimalMinutes / increment) * increment, 0, 0);
  return clone as any;
}

/**
 * Format decimal hours in a nice way. This could probably apply to more than just hours.
 *
 * @param {number} hours
 * @param {number} digits
 * @returns {string}
 */
export function formatDecimalHours(hours: number, digits = 2) {
  const mult = 10 ** digits;
  const rounded = Math.round(hours * mult) / mult;
  return `${rounded} ${pluralize('hour', rounded)}`;
}

/**
 * Create an iterable range between two moments. Ripped from the `moment-range` library.
 *
 * @param unit What unit you want to iterate by. Accepts all units of moment.add. Defaults to day.
 * @param step What amount to iterate by. Defaults to 1.
 */
export function range(start: Moment, end: Moment, unit: unitOfTime.DurationConstructor = 'day', step = 1) {
  if (!Number.isInteger(step)) {
    throw new Error('Step must be an integer');
  }

  if (step <= 0) {
    throw new Error('Step must be greater than 0');
  }

  if (!start.isSameOrBefore(end)) {
    throw new Error('Start must be before end');
  }

  return {
    [Symbol.iterator]() {
      const diff = end.diff(start, unit) / step;
      let iteration = 0;

      return {
        next() {
          const current = start.clone().add(iteration * step, unit);
          const done = iteration > diff;

          iteration += 1;

          return {
            done,
            value: done ? undefined : current,
          };
        },
      };
    },
  };
}
