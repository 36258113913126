import { lazy } from 'react';

const CheckEnrollmentDialog = lazy(() => import('payrollCheck/modules/marketing/dialogs/CheckEnrollmentDialog'));
const AddressNotEligibleDialog = lazy(() => import('payrollCheck/modules/marketing/dialogs/AddressNotEligibleDialog'));
const NotEligibleDialog = lazy(() => import('payrollCheck/modules/marketing/dialogs/NotAvailableDialog'));
const AdminOnlyDialog = lazy(() => import('payrollCheck/modules/marketing/dialogs/AdminOnlyDialog'));

export const CHECK_ENROLLMENT_DIALOG = 'CHECK_ENROLLMENT_DIALOG';
export const NOT_ELIGIBLE_DIALOG = 'NOT_ELIGIBLE_DIALOG';
export const ADMIN_ONLY_DIALOG = 'ADMIN_ONLY_DIALOG';
export const ADDRESS_NOT_ELIGIBLE_DIALOG = 'ADDRESS_NOT_ELIGIBLE_DIALOG';

const dialogs = {
  [CHECK_ENROLLMENT_DIALOG]: CheckEnrollmentDialog,
  [NOT_ELIGIBLE_DIALOG]: NotEligibleDialog,
  [ADMIN_ONLY_DIALOG]: AdminOnlyDialog,
  [ADDRESS_NOT_ELIGIBLE_DIALOG]: AddressNotEligibleDialog,
  // ...
};

export default dialogs;
