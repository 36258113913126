import PropTypes from 'prop-types';
import { Component, Fragment, createRef } from 'react';
import { connect } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import Account from 'data/account/model';
import {
  displayNotificationsDrawer,
  fetchNotifications,
  hideNotificationsDrawer,
  updateUserNotifiedAt,
} from 'data/notifications/actions';
import {
  getNotificationsCount,
  loadedSelector,
  loadingSelector,
  notificationsSelector,
  showSelector,
} from 'data/notifications/selectors';
import Request from 'data/request/model';
import User from 'data/user/model';
import { errorNotice } from 'notices/actions';
import { getAuthAccount, getAuthUser } from 'shared/auth/selectors';
import Features from 'shared/features';
import { formatQueryString, trackClick } from 'shared/util/mercury';

import NotificationsDrawer from './containers/NotificationsDrawer';

import { bindActionCreators } from '@reduxjs/toolkit';
import classnames from 'classnames';

import 'notifications/styles/Bell.scss';

const REFRESH_NOTIFICATIONS_SECONDS = 300;

export class Bell extends Component {
  linkRef = createRef();
  receivedError = false;
  refreshTimeout = null;
  collapsing = false;

  state = {
    error: false,
  };

  static propTypes = {
    showNotifications: PropTypes.bool,
    container: PropTypes.string,
    count: PropTypes.number,
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
    notifications: PropTypes.object.isRequired,
    authAccount: PropTypes.instanceOf(Account).isRequired,
    currentUser: PropTypes.instanceOf(User).isRequired,
    fetchNotifications: PropTypes.func.isRequired,
    updateUserNotifiedAt: PropTypes.func.isRequired,
    displayNotificationsDrawer: PropTypes.func.isRequired,
    hideNotificationsDrawer: PropTypes.func.isRequired,
    errorNotice: PropTypes.func.isRequired,
    maxUnreadCount: PropTypes.number,
    dialogs: PropTypes.number.isRequired,
  };

  static defaultProps = {
    showNotifications: false,
    maxUnreadCount: 9,
  };

  componentDidMount() {
    if (this.props.count === null) {
      this.fetchNotifications();
    }
    this.refreshTimeout = setInterval(this.fetchNotifications, REFRESH_NOTIFICATIONS_SECONDS * 1000);
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.closeOnEsc, false);
    clearInterval(this.refreshTimeout);
  }

  componentDidUpdate(prevProps) {
    if (this.props.showNotifications !== prevProps.showNotifications) {
      if (this.props.showNotifications) {
        document.addEventListener('keyup', this.closeOnEsc, false);
      } else {
        document.removeEventListener('keyup', this.closeOnEsc, false);
      }
    }

    const nowShowing = this.props.showNotifications && this.props.loaded;
    const prevHiding = !prevProps.loaded || !prevProps.showNotifications;
    if (nowShowing && prevHiding) {
      if (this.props.notifications?.size) {
        this.props.updateUserNotifiedAt(this.props.notifications.first().created_at);
      }
    }
  }

  componentDidCatch() {
    this.setErrorState();
  }

  setErrorState() {
    if (this.receivedError) {
      return;
    }
    this.props.errorNotice('There was an error loading the notifications.');
    this.receivedError = true;
    this.setState({ error: true });
  }

  clearErrorState() {
    this.receivedError = false;
    this.setState({ error: false });
  }

  fetchNotifications = () => {
    const { authAccount, fetchNotifications, showNotifications } = this.props;

    if (showNotifications) {
      return;
    }

    let swapsRequest = false;
    let locationsRequest = false;
    let timeoffRequest = false;

    if (authAccount.hasScheduling() && authAccount.hasFeature(Features.SWAPS_DROPS)) {
      swapsRequest = { open_only: true, limit: 200 };
    }

    if (authAccount.hasFeature(Features.TIME_OFF_REQUESTS)) {
      timeoffRequest = { status: Request.Status.PENDING, limit: 200 };
    }

    if (authAccount.hasFeature(Features.LOCATIONS)) {
      locationsRequest = { only_unconfirmed: true };
    }

    return fetchNotifications({}, timeoffRequest, swapsRequest, locationsRequest);
  };

  closeOnEsc = e => {
    if (this.props.dialogs) {
      return;
    }
    if (this.props.showNotifications && (e.key === 'Escape' || e.key === 'Esc')) {
      e.stopPropagation();
      this.props.hideNotificationsDrawer();
      if (this.linkRef?.current) {
        this.linkRef.current.focus();
      }
    }
  };

  trackInteraction = () => {
    if (this.props.showNotifications) {
      return;
    }
    trackClick('openBell', {
      userRole: this.props.currentUser.mercuryRoleName(),
      queryParams: formatQueryString(window.location.search),
      appComponent: 'Bell',
    });
  };

  displayDrawer = e => {
    e.preventDefault();

    if (this.props.showNotifications || this.collapsing) {
      return;
    }

    this.trackInteraction();

    if (this.state.error) {
      this.clearErrorState();
    }

    if (!this.props.loading && !this.props.loaded) {
      this.fetchNotifications();
    }

    this.props.displayNotificationsDrawer();
  };

  renderCount() {
    const { count, maxUnreadCount } = this.props;

    if (!count) {
      return null;
    }
    return <span className="callout-count">{count <= maxUnreadCount ? count : `${maxUnreadCount}+`}</span>;
  }

  onExit = () => {
    this.collapsing = true;
  };

  onExited = () => {
    this.collapsing = false;
  };

  renderNotifications() {
    if (this.state.error) {
      return null;
    }

    if (!this.props.showNotifications) {
      return null;
    }

    return (
      <CSSTransition
        classNames="display-notifications"
        onExit={this.onExit}
        onExited={this.onExited}
        timeout={{ enter: 250, exit: 250 }}
      >
        <NotificationsDrawer
          container={this.props.container}
          outsideClickIgnoreClass="skip-click-collapse"
          stopPropagation={true}
        />
      </CSSTransition>
    );
  }

  render() {
    const classname = classnames('notifications-bell', {
      'has-new': this.props.count,
      active: this.props.showNotifications,
    });
    return (
      <Fragment>
        <button className={classname} type="button" onClick={this.displayDrawer}>
          <i className="wiw-icon wiw-notification" />
          {this.renderCount()}
        </button>
        <TransitionGroup component={null}>{this.renderNotifications()}</TransitionGroup>
      </Fragment>
    );
  }
}

export default connect(
  state => ({
    count: getNotificationsCount(state),
    authAccount: getAuthAccount(state),
    currentUser: getAuthUser(state),
    loaded: loadedSelector(state),
    loading: loadingSelector(state),
    notifications: notificationsSelector(state),
    showNotifications: showSelector(state),
    dialogs: state.dialogs.length,
  }),
  dispatch =>
    bindActionCreators(
      {
        displayNotificationsDrawer,
        hideNotificationsDrawer,
        fetchNotifications,
        updateUserNotifiedAt,
        errorNotice,
      },
      dispatch,
    ),
)(Bell);
