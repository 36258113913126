import type CompanyStatus from 'data/payrollCheck/models/companyStatus';
import Payroll from 'shared/api/payroll';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

export const fetchCompanyStatus = createWiwAsyncThunk<CompanyStatus>(
  'payrollCheck/fetchCompanyStatus',
  async (_, { rejectWithValue }) => {
    try {
      return await Payroll.get('/companies/status');
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
