import { getCurrentPlan } from 'data/account/selectors';
import { getPlans } from 'data/plan/selectors/plans';
import { getAuthAccount } from 'shared/auth/selectors';

import { createSelector } from '@reduxjs/toolkit';
import { difference } from 'lodash';

export const findUpgradePlan = (account, currentPlan, plans) => {
  if (!currentPlan || !plans || !account) {
    return;
  }

  const available = plans
    .toList()
    .filter(plan => {
      //Skip legacy plans and free plans
      if (plan.isLegacy() || plan.isFree()) {
        return false;
      }
      //Skip plans with fewer than required employees or the current plan
      if (currentPlan.id === plan.id || plan.get('employees') < account.user_count) {
        return false;
      }

      //Skip plans missing required features
      if (difference(account.get('uses_features'), plan.get('features')).length > 0) {
        return false;
      }

      // Skip enterprise plans
      if (currentPlan.name.toLowerCase().includes('enterprise')) {
        return false;
      }

      return true;
    })
    .sortBy(plan => plan.price);

  return available.first();
};

export const getUpgradeToPlan = createSelector([getAuthAccount, getCurrentPlan, getPlans], findUpgradePlan);
