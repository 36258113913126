import Absence, { type AbsenceTabNames } from 'data/absence/model';
import OpenShiftRequests, {
  type OpenShiftRequestsStatus,
  type OpenShiftRequestsTabNames,
} from 'data/openshiftRequests/model';
import Request, { type RequestStatus, type RequestTabNames } from 'data/request/model';
import Swap, { type SwapTabNames, type SwapTypes } from 'data/swap/model';

import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Map } from 'immutable';

const FILTERS = 'filters';

type RequestsFilterMap = Map<
  string,
  | number[]
  | RequestStatus[]
  | SwapTypes[]
  | RequestTabNames
  | OpenShiftRequestsTabNames
  | SwapTabNames
  | OpenShiftRequestsStatus[]
  | AbsenceTabNames
>;

type RequestsState = Map<string, RequestsFilterMap>;

export const initialState: RequestsState = Map({
  filters: Map({
    statuses: Object.values(Request.Status),
    types: [0],
    schedules: [0],
    swap_statuses: [0], // default to pending since swap tab defaults to pending
    swap_schedules: [0],
    absence_schedules: [0],
    swap_types: Object.values(Swap.Types),
    timeoff_tab: Request.TabNames.PENDING,
    openshift_tab: OpenShiftRequests.TabNames.PENDING,
    swap_tab: Swap.TabNames.PENDING,
    openshift_statuses: Object.values(OpenShiftRequests.Status),
    openshift_schedules: [0],
    absence_tab: Absence.TabNames.TODAY,
  }),
});

export const requestsSlice = createSlice({
  name: 'requests',
  initialState,
  reducers: {
    updateRequestFilterStatuses(state, action: PayloadAction<any>) {
      return state.setIn([FILTERS, 'statuses'], action.payload);
    },
    updateSwapFilterStatuses(state, action: PayloadAction<any>) {
      return state.setIn([FILTERS, 'swap_statuses'], action.payload);
    },
    updateOpenshiftFilterStatuses(state, action: PayloadAction<any>) {
      return state.setIn([FILTERS, 'openshift_statuses'], action.payload);
    },
    updateRequestFilterTypes(state, action: PayloadAction<any>) {
      return state.setIn([FILTERS, 'types'], action.payload);
    },
    updateSwapFilterTypes(state, action: PayloadAction<any>) {
      return state.setIn([FILTERS, 'swap_types'], action.payload);
    },
    updateRequestFilterSchedules(state, action: PayloadAction<any>) {
      return state.setIn([FILTERS, 'schedules'], action.payload);
    },
    updateSwapFilterSchedules(state, action: PayloadAction<any>) {
      return state.setIn([FILTERS, 'swap_schedules'], action.payload);
    },
    updateOpenshiftFilterSchedules(state, action: PayloadAction<any>) {
      return state.setIn([FILTERS, 'openshift_schedules'], action.payload);
    },
    updateAbsenceFilterSchedules(state, action: PayloadAction<any>) {
      return state.setIn([FILTERS, 'absence_schedules'], action.payload);
    },
    updateTimeOffTab(state, action: PayloadAction<any>) {
      return state.setIn([FILTERS, 'timeoff_tab'], action.payload);
    },
    updateSwapTab(state, action: PayloadAction<any>) {
      return state.setIn([FILTERS, 'swap_tab'], action.payload);
    },
    updateOpenshiftTab(state, action: PayloadAction<any>) {
      return state.setIn([FILTERS, 'openshift_tab'], action.payload);
    },
    updateAbsenceTab(state, action: PayloadAction<any>) {
      return state.setIn([FILTERS, 'absence_tab'], action.payload);
    },
  },
});

export const {
  updateRequestFilterStatuses,
  updateSwapFilterStatuses,
  updateOpenshiftFilterStatuses,
  updateRequestFilterTypes,
  updateSwapFilterTypes,
  updateRequestFilterSchedules,
  updateSwapFilterSchedules,
  updateOpenshiftFilterSchedules,
  updateAbsenceFilterSchedules,
  updateTimeOffTab,
  updateSwapTab,
  updateOpenshiftTab,
  updateAbsenceTab,
} = requestsSlice.actions;

export default requestsSlice.reducer;
