import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { Conversation, Message } from '@twilio/conversations';
import { List, Map } from 'immutable';

import BaseModel from 'data/BaseModel';
import EntityBase from 'data/EntityBase';
import generateThunkReducers from 'data/util/generateThunkReducers';
import { mergeEntityItems } from 'data/util/sliceHelpers';
import { isNil, uniq } from 'lodash';
import { loadConversationPermissions } from 'workchat/v2/store/conversations/conversationsActions';
import { conversationsSdkMap } from 'workchat/v2/twilio-objects';

interface ConversationAttributes {
  external_id: number;
  external_type: string;
}

interface ConversationMessage {
  index: number;
  sid: string;
}

export type ReduxConversation = {
  sid: string;
  dateCreated?: string | null;
  dateUpdated?: string | null;
  attributes: ConversationAttributes;
  friendlyName: string | null;
  notificationLevel: 'default' | 'muted';
  lastReadMessageIndex: number | null;
  lastMessage?: {
    index?: number;
    dateCreated?: string;
  };
  messages: List<ConversationMessage>;
  participantIds: string[];
};

class ReduxConversationModel extends BaseModel<ReduxConversation>({
  sid: '',
  dateCreated: null,
  dateUpdated: null,
  attributes: {
    external_id: 0,
    external_type: '',
  },
  friendlyName: null,
  notificationLevel: 'default',
  lastReadMessageIndex: null,
  lastMessage: undefined,
  messages: List<ConversationMessage>(),
  participantIds: [],
}) {}

export const reduxifyConversation = (conversation: Conversation): ReduxConversation => {
  const { sid, friendlyName, dateCreated, dateUpdated, notificationLevel, lastReadMessageIndex, lastMessage } =
    conversation;

  return {
    sid,
    friendlyName,
    dateCreated: dateCreated?.toISOString(),
    dateUpdated: dateUpdated?.toISOString(),
    notificationLevel,
    lastReadMessageIndex,
    lastMessage: {
      ...lastMessage,
      dateCreated: lastMessage?.dateCreated?.toISOString(),
    },
    attributes: conversation.attributes as unknown as ConversationAttributes,
    messages: List<ConversationMessage>(),
    participantIds: [],
  };
};

export class WorkchatConversationInitialState extends EntityBase<
  ReduxConversation,
  {
    unreadCountByConversationId: Map<string, number | null>;
    typingUsersByConversationId: Map<string, string[]>;
    permissions: Map<string, string[]>;
    permissionsLoading: boolean;
    permissionsError: string | null;
    loaded: boolean;
  }
>({
  unreadCountByConversationId: Map<string, number | null>(),
  typingUsersByConversationId: Map<string, string[]>(),
  permissions: Map<string, string[]>(),
  permissionsLoading: false,
  permissionsError: null,
  loaded: false,
}) {}

export const workchatConversationsSliceName = 'workchatv2/conversations';

export const workchatConversationsSlice = createSlice({
  name: workchatConversationsSliceName,
  initialState: new WorkchatConversationInitialState(),
  reducers: {
    conversationsLoadCompleted: state => {
      return state.set('loaded', true);
    },
    incrementUnreadCountForConversation: (state, action: PayloadAction<string>) => {
      return state.setIn(
        ['unreadCountByConversationId', action.payload],
        (state.getIn(['unreadCountByConversationId', action.payload], 0) as number) + 1,
      );
    },
    decrementUnreadCountForConversationMessageDelete: (state, action: PayloadAction<Message>) => {
      const deletedMessage = action.payload;
      const conversationId = deletedMessage.conversation.sid;
      const conversation = state.items.get(conversationId);
      const currCount = state.getIn(['unreadCountByConversationId', conversationId], 0) as number;

      if (
        conversation &&
        (isNil(conversation.lastReadMessageIndex) || conversation.lastReadMessageIndex < deletedMessage.index) &&
        currCount > 0
      ) {
        return state.setIn(['unreadCountByConversationId', conversationId], currCount - 1);
      }
      return state;
    },
    setUnreadCountForConversation: (state, action: PayloadAction<{ conversationId: string; count: number | null }>) => {
      return state.setIn(['unreadCountByConversationId', action.payload.conversationId], action.payload.count);
    },
    upsertConversation: (state, action: PayloadAction<Conversation>) => {
      const reduxConversation = reduxifyConversation(action.payload);
      conversationsSdkMap.set(action.payload.sid, action.payload);

      return mergeEntityItems(state, reduxConversation, ReduxConversationModel, 'sid');
    },
    deleteConversation: (state, action: PayloadAction<string>) => {
      conversationsSdkMap.delete(action.payload);

      return state
        .set(
          'items',
          state.items.filter(conversation => conversation.sid !== action.payload),
        )
        .set(
          'unreadCountByConversationId',
          state.get('unreadCountByConversationId').filter((_, key) => key !== action.payload),
        );
    },
    userStartTyping: (state, action: PayloadAction<{ conversationId: string; userId: number }>) => {
      const existingUserIds = state.getIn(
        ['typingUsersByConversationId', action.payload.conversationId],
        [action.payload.userId],
      ) as number[] | undefined;

      return state.setIn(
        ['typingUsersByConversationId', action.payload.conversationId],
        existingUserIds ? uniq([...existingUserIds, action.payload.userId]) : [action.payload.userId],
      );
    },
    userEndTyping: (state, action: PayloadAction<{ conversationId: string; userId: number }>) => {
      const existingUserIds = state.getIn(
        ['typingUsersByConversationId', action.payload.conversationId],
        [],
      ) as number[];

      return state.setIn(
        ['typingUsersByConversationId', action.payload.conversationId],
        existingUserIds?.filter(userid => userid !== action.payload.userId),
      );
    },
  },
  extraReducers: builder => {
    builder.addCase(loadConversationPermissions.pending, state => {
      return state.set('permissionsLoading', true);
    });
    builder.addCase(
      loadConversationPermissions.fulfilled,
      (state, action: PayloadAction<{ conversationId: string; permissions: string[] }>) =>
        state
          .set(
            'permissions',
            state.get('permissions').merge(Map({ [action.payload.conversationId]: action.payload.permissions })),
          )
          .set('permissionsLoading', false),
    );
    builder.addCase(loadConversationPermissions.rejected, (state, action: PayloadAction<unknown>) => {
      return state.set('permissionsLoading', false).set('permissionsError', action.payload as string);
    });

    generateThunkReducers(builder, workchatConversationsSliceName);
  },
});

export const {
  conversationsLoadCompleted,
  incrementUnreadCountForConversation,
  decrementUnreadCountForConversationMessageDelete,
  setUnreadCountForConversation,
  upsertConversation,
  deleteConversation,
  userStartTyping,
  userEndTyping,
} = workchatConversationsSlice.actions;

export default workchatConversationsSlice.reducer;
