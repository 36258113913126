import { addBranchSwitchingHeaders } from 'shared/api/util/addBranchSwitchingHeaders';
import { getToken, getUserId } from 'shared/auth/identifiers';

import Cookies from 'js-cookie';

function defaultRequestConfig() {
  const options: any = {};
  const headers: { [key: string]: any } = {
    'Content-Type': 'application/json',
    'W-Date-Format': 'iso',
  };

  addBranchSwitchingHeaders(headers);

  if (CONFIG.DEVPANEL.passXdebugCookie && Cookies.get('XDEBUG_SESSION')) {
    const xdebugSession = Cookies.get('XDEBUG_SESSION');
    options.query = {
      ...options.query,
      XDEBUG_SESSION_START: xdebugSession,
    };
  }

  if (getToken()) {
    headers['W-Token'] = getToken();
    headers.Authorization = getToken();
  }

  if (getUserId()) {
    headers['W-UserId'] = getUserId();
  }

  options.headers = headers;

  return options;
}

export default defaultRequestConfig;
