import bowser from 'bowser';

// Custom drag previews may prevent you from scrolling while dragging.
// That's why this exists.
export function allowNativeDragPreviews() {
  return bowser.mac && bowser.chrome && Number.parseInt(bowser.version, 10) < 72;
}

export function useTouchBackend() {
  return (
    (bowser.chrome && Number.parseInt(bowser.version, 10) > 71) ||
    (!allowNativeDragPreviews() && bowser.msie) /* || whatever, put other browsers here */
  );
}

export function canCustomizeDragPreview() {
  return !(bowser.msie || bowser.msedge);
}
