import BaseModel from 'data/BaseModel';
import type { Dataset } from 'data/reporting/models/Dataset';

import type { ColumnState } from '@ag-grid-community/core';

export interface SavedReportFields {
  id: number;
  accountId: number;
  creatorId: number;
  dataset: Dataset | '';
  name: string;
  description: string;
  createdAt: string | null;
  updatedAt: string | null;
  updaterId: number;
  definition?: ReportDefinitionFields;
  version: number;
}

export interface ReportDefinitionFields {
  columns: ColumnState[];
  filters: { [key: string]: any };
  groups: { groupId: string; open: boolean }[];
  isPivotMode: boolean;
}

export class SavedReport extends BaseModel<SavedReportFields>({
  id: 0,
  accountId: 0,
  creatorId: 0,
  dataset: '',
  name: '',
  description: '',
  createdAt: null,
  updatedAt: null,
  updaterId: 0,
  definition: undefined,
  version: 0,
}) {}
