import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import PanelBody from './body';
import PanelTitle from './title';

import classnames from 'classnames';

class Panel extends PureComponent {
  static propTypes = {
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    children: PropTypes.node,
    className: PropTypes.string,
    stickyTitle: PropTypes.bool,
  };

  static defaultProps = {};

  render() {
    const { children, className, title, stickyTitle } = this.props;
    return (
      <div className={classnames('panel', { 'panel-sticky-title': stickyTitle }, className)}>
        {title && <PanelTitle>{title}</PanelTitle>}
        {children}
      </div>
    );
  }
}

export { Panel, PanelBody, PanelTitle };
export default Panel;
