import type { ShiftFields } from 'data/shift/model';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type TakeShiftArgs = ShiftFields['id'];
type TakeShiftResponse = {
  shift: ShiftFields;
  taken_shift: ShiftFields;
};

export const takeShift = createWiwAsyncThunk<TakeShiftResponse, TakeShiftArgs>(
  'shift/takeShift',
  async (shiftId, { rejectWithValue }) => {
    try {
      return (await Monolith.url(`/shifts/${shiftId}/take`).post()) as TakeShiftResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
