import type { ShiftBreakAudit } from 'data/shiftBreak/models';
import { ShiftBreakAuditSchema } from 'data/shiftBreak/models/shiftBreakAudit';
import V3 from 'shared/api/v3';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { makeGoDataSchema } from 'shared/zod-schemas';
import { createWiwAsyncThunk } from 'store';

import { ZodError, type z } from 'zod';

type CreateShiftBreakAuditArgs = ShiftBreakAudit;

export const createShiftBreakAudit = createWiwAsyncThunk<
  z.infer<typeof CreateShiftBreakAuditResponse>,
  CreateShiftBreakAuditArgs
>('shiftBreakAudit/create', async (request, { rejectWithValue }) => {
  try {
    const response = await V3.post(request.toJSON(), '/shift-break-audits');
    return CreateShiftBreakAuditResponse.parse(response);
  } catch (error) {
    if (error instanceof ZodError) {
      return rejectWithValue(error);
    }

    return rejectWithValue(parseAPIError(error));
  }
});

const CreateShiftBreakAuditResponse = makeGoDataSchema(ShiftBreakAuditSchema);
