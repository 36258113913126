import BaseModel from 'data/BaseModel';
import type { LocationFields } from 'data/location/model';

export interface AnnotationFields {
  id: number;
  account_id: number;
  creator_id: number;
  all_locations: boolean;
  start_date: string;
  end_date: string;
  title: string;
  message: string; // Nullable in DB but the monolith converts NULL to empty string
  color: string; // Nullable in DB but the monolith converts NULL to 'cccccc'
  business_closed: boolean;
  no_time_off: boolean;
  announcement: boolean;
  is_deleted: boolean;
  created_at: string;
  updated_at: string;
  locations: LocationFields[];
}

class Annotation extends BaseModel<AnnotationFields>({
  id: 0,
  account_id: 0,
  creator_id: 0,
  all_locations: false,
  start_date: '',
  end_date: '',
  title: '',
  message: '',
  color: 'cccccc',
  business_closed: false,
  no_time_off: false,
  announcement: false,
  is_deleted: false,
  created_at: '',
  updated_at: '',
  locations: [], //used during save
}) {
  static defaultColor = 'cccccc';

  static colors: { [key: string]: { dark: string; light: string } } = {
    '2090DB': { dark: '2090DB', light: 'EEF6FC' },
    D54637: { dark: 'D54637', light: 'FBEBE9' },
    '158282': { dark: '158282', light: 'EDF5F5' },
    CF8E12: { dark: 'CF8E12', light: 'FFF8EB' },
    '5A4B86': { dark: '5A4B86', light: 'F0EBFF' },
    '93C31F': { dark: '93C31F', light: 'F9FFEB' },
    '4E66DE': { dark: '4E66DE', light: 'EBEEFF' },
    CCCCCC: { dark: 'CCCCCC', light: 'FFFFFF' },
  };

  /** Return an array of colors */
  get baseColors() {
    return Object.keys(Annotation.colors).map(key => `#${Annotation.colors[key].dark}`);
  }

  get lightColor() {
    const pair = Annotation.colors[this.color.toUpperCase()];

    return pair ? pair.light : Annotation.defaultColor;
  }

  /**
   * adjustedStartDate and adjustedEndDate help you figure out what day the annotation
   * probably belongs to in the case of timezone changes. Basically, if you go inward
   * by 12 hours from the start and end of the annotation, a small timezone change won't
   * throw the annotation onto completely different days of the week.
   *
   * All of this can be removed if/when the API returns just the date for the
   * annotation, rather than a timezone-adjusted date and time.
   */
  adjustedStartDate() {
    // We assert that the `date` function will in fact return a Moment since we know that
    // this model has `start_date` and `end_date` properties.
    return this.date('start_date')!.add(12, 'hours');
  }

  /**
   * We add one second so that the end time is rounded up to the nearest hour, because
   * the API returns a string like "01-02-2018 23:59:59 -0500". This helps ensure that
   * the end date never comes before the start date.
   *
   * Yes, this is fragile and some API change in the future will probably break this.
   */
  adjustedEndDate() {
    // We assert that the `date` function will in fact return a Moment since we know that
    // this model has `start_date` and `end_date` properties.
    return this.date('end_date')!.subtract(12, 'hours').add(1, 'second');
  }

  isMultiDay() {
    // We assert that the `date` function will in fact return a Moment since we know that
    // this model has `start_date` and `end_date` properties.
    return this.date('end_date')!.diff(this.date('start_date'), 'days') > 0;
  }
}

export default Annotation;
