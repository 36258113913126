import memoize from 'memoize-one';

export const filterOpenShiftsByLocation = memoize((location, shifts) => {
  if (!location) {
    return shifts;
  }

  return shifts.filter(shift => {
    if (shift.isOpen()) {
      return shift.location_id === location;
    }
    return true;
  });
});
