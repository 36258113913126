import { createSelector } from '@reduxjs/toolkit';

import type Plan from 'data/plan/model';
import { getAuthAccount } from 'shared/auth/selectors';
import Features from 'shared/features';
import type { WiwState } from 'store';

import { Map } from 'immutable';

export const getStateLocationFilter = (state: WiwState): number => state.scheduler.getIn(['filters', 'location'], 0);
export const getStatePositionFilter = (state: WiwState) => state.scheduler.getIn(['filters', 'positions'], []);
export const getStateSiteFilter = (state: WiwState) => state.scheduler.getIn(['filters', 'sites'], []);
export const getStateUserFilter = (state: WiwState): number => state.scheduler.getIn(['filters', 'user'], -1);
export const getStateTagFilter = (state: WiwState) => state.scheduler.getIn(['filters', 'tags'], []);

export const getStateSiteFilterMap = createSelector([getStateSiteFilter], siteFilterState => {
  return siteFilterState.reduce(
    (filterMap: Map<number, boolean>, siteId: number) => filterMap.set(siteId, true),
    Map(),
  );
});

export const getStatePositionFilterMap = createSelector([getStatePositionFilter], positionFilterState => {
  return positionFilterState.reduce(
    (filterMap: Map<number, boolean>, positionId: number) => filterMap.set(positionId, true),
    Map(),
  );
});

export const getEnabledTagFilter = createSelector([getAuthAccount, getStateTagFilter], (account, tags) => {
  if (account && !account.getSettings('schedule.worktags')) {
    return [];
  }

  return tags;
});

export const filterDependenciesLoaded = (state: WiwState, accountPlan: Plan) =>
  state.data.position.loaded &&
  state.data.location.loaded &&
  (!accountPlan.hasFeature(Features.SITES) || state.data.sites.loaded) &&
  (state.data.metadata.userLoaded || state.data.metadata.error !== null);

export { filterOpenShiftsByLocation } from './openShiftsByLocation';
export { filterByDate } from './shiftsByDate';
export { filterShiftsByLocation } from './shiftsByLocation';
export { filterShiftsByPosition } from './shiftsByPosition';
export { filterShiftsBySite } from './shiftsBySite';
export { filterUsersByLocation } from './usersByLocation';
export { filterUsersByPosition } from './usersByPosition';
