import type { CSSProperties, ReactNode } from 'react';

import { Modal } from '@mantine/core';
import classnames from 'classnames';

type Props = {
  className?: string;
  titleClassName?: string;
  children?: ReactNode;
  onClose?: () => void;
  hideClose?: boolean;
  style?: CSSProperties;
  setFocus?: boolean;
};

export default function Header(props: Props) {
  const { children, className, titleClassName, onClose, hideClose, style } = props;

  const closeDialogProps = onClose ? { onClick: onClose } : {};

  return (
    <Modal.Header className={classnames('dialog-header', className)} style={style}>
      <Modal.Title className={classnames('title', titleClassName)}>{children}</Modal.Title>
      {!hideClose && <Modal.CloseButton data-testid="dialog-close-button" {...closeDialogProps} />}
    </Modal.Header>
  );
}
