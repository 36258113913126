import { type ForwardRefRenderFunction, type ReactNode, forwardRef } from 'react';

export interface Props {
  children: ReactNode;
}

const DialogSplit: ForwardRefRenderFunction<HTMLDivElement, Props> = (props, ref) => {
  return (
    <div className="dialog-split" ref={ref}>
      {props.children}
    </div>
  );
};

export default forwardRef(DialogSplit);
