export interface ServiceConfig {
  name: string;
  gitlabProjectID: number;
  serviceUrl: string;
  branchCookieName?: string;
}
export const ServicesConfig: ServiceConfig[] = [
  {
    name: 'Attendance Service',
    gitlabProjectID: 1178,
    serviceUrl: CONFIG.ATTENDANCE_API,
    branchCookieName: 'branch-attendance',
  },
  {
    name: 'Forecasting API',
    gitlabProjectID: 962,
    serviceUrl: CONFIG.FORECAST_TOOLS,
  },
  {
    name: 'Login API',
    gitlabProjectID: 22,
    serviceUrl: CONFIG.LOGIN_API_ROOT,
  },
  {
    name: 'Platform API',
    gitlabProjectID: 442,
    serviceUrl: CONFIG.PLATFORM_SERVICE,
  },
  {
    name: 'Scheduling API',
    gitlabProjectID: 1246,
    serviceUrl: '',
  },
  {
    name: 'Reporting API',
    gitlabProjectID: 808,
    serviceUrl: CONFIG.REPORTS_API,
  },
  {
    name: 'Tasks API',
    gitlabProjectID: 380,
    serviceUrl: CONFIG.TASKS_API,
  },
  {
    name: 'Workchat API',
    gitlabProjectID: 740,
    serviceUrl: CONFIG.WORKCHAT_ROOT,
  },
  {
    name: 'Billing API',
    gitlabProjectID: 906,
    serviceUrl: CONFIG.BILLING_SERVICE_API_ROOT,
    branchCookieName: 'branch-billing',
  },
  {
    name: 'Payroll API',
    gitlabProjectID: 1894,
    serviceUrl: CONFIG.PAYROLL_API,
  },
];
