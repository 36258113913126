import { type ReactNode, createContext, useEffect, useState } from 'react';

import { getAuthAccount, getAuthUser } from 'shared/auth/selectors';
import { useWiwSelector } from 'store';

import { getLocale } from './utils/locale';
import { getCurrency } from './utils/numbers';
import { use24hour as is24Hour } from './utils/time';

export interface IntlContextType {
  locale: string;
  use24hour: boolean;
  timezone: string;
  currency: string;
}

export const IntlContext = createContext<IntlContextType>({
  locale: 'en_US',
  use24hour: false,
  timezone: 'UTC',
  currency: 'USD',
});

type Props = {
  children: ReactNode;
};

export default function Provider({ children }: Props) {
  const account = useWiwSelector(getAuthAccount);
  const user = useWiwSelector(getAuthUser);

  const [use24HourTime, setUse24Hour] = useState(false);
  const [timezone, setTimezone] = useState(getTimezone());
  const [currency, setCurrency] = useState(getCurrency(account));

  useEffect(() => {
    setCurrency(getCurrency(account));
    setUse24Hour(is24Hour(account));
  }, [account]);

  useEffect(() => {
    setTimezone(getTimezone());
  }, [account, user]);

  function getTimezone() {
    return user?.timezone_name || account?.timezone_name || Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';
  }

  return (
    <IntlContext.Provider
      value={{
        locale: getLocale(),
        use24hour: use24HourTime,
        timezone,
        currency,
      }}
    >
      {children}
    </IntlContext.Provider>
  );
}
