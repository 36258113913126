import type CreateModel from 'data/request/createModel';
import type Request from 'data/request/model';
import type { RequestFields } from 'data/request/model';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

import { omit } from 'lodash';

type CreateRequestArgs = Request | CreateModel;

type CreateRequestResponse = { request: RequestFields };

export const createRequest = createWiwAsyncThunk<CreateRequestResponse, CreateRequestArgs>(
  'request/create',
  async (request, { rejectWithValue }) => {
    try {
      return (await Monolith.url('/requests').post(omit(request.toJSON(), ['status']))) as CreateRequestResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
