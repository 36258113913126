import type { Dataset } from 'data/reporting/models/Dataset';
import { getIncludeChildAccounts } from 'reporting/selectors';
import { request } from 'shared/auth/request';
import { withMatcher } from 'shared/util/withMatcher';
import type { WiwDispatch, WiwGetState } from 'store';
import { FETCH_CURRENT_STATE_COUNTS } from 'store/action-types';

type Params = {
  accountId: string;
  dataset: Dataset;
  tz?: string;
};

/*
Response is like:
{
    "2022-12-12T15:00:00Z": 11,
    "2022-12-13T15:00:00Z": 20,
    "2022-12-14T15:00:00Z": 11
}
 */
type CountResponse = {
  [key: string]: number;
};
export const fetchCurrentStateCountsFailure = withMatcher((params: Params, error: unknown) => ({
  type: FETCH_CURRENT_STATE_COUNTS.FAILURE,
  params,
  error,
}));

export const fetchCurrentStateCountsRequested = withMatcher((params: Params) => ({
  type: FETCH_CURRENT_STATE_COUNTS.REQUEST,
  params,
}));

export const fetchCurrentStateCountsSuccess = withMatcher((params: Params, response: CountResponse) => ({
  type: FETCH_CURRENT_STATE_COUNTS.SUCCESS,
  params,
  response,
}));

export function fetchCurrentStateCounts(params: Params) {
  return (dispatch: WiwDispatch, getState: WiwGetState) => {
    dispatch(fetchCurrentStateCountsRequested(params));
    return request(CONFIG.REPORTS_API)
      .get(`/current_state/${params.dataset}/count`, {
        query: {
          accountId: params.accountId,
          tz: params.tz,
          includeChildAccounts: getIncludeChildAccounts(getState()) ?? true,
        },
      })
      .then(response => dispatch(fetchCurrentStateCountsSuccess(params, response)))
      .catch(error => {
        dispatch(fetchCurrentStateCountsFailure(params, error));
        throw error;
      });
  };
}
