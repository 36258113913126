import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';
import type Transaction from './model';

type FetchTransactionsResponse = {
  transactions: Transaction[];
};

export const fetchTransactions = createWiwAsyncThunk<FetchTransactionsResponse>(
  'transactions/fetch',
  async (_, { rejectWithValue }) => {
    try {
      return (await Monolith.url('/account/transactions').get()) as FetchTransactionsResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
