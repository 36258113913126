import FontIcon from 'shared/ui/FontIcon';

export type ErrorBannerProps = {
  error: string | null;
  clearError: () => void;
};

export default function ErrorBanner({ error, clearError }: ErrorBannerProps) {
  if (!error) {
    return null;
  }

  return (
    <div className="error-message">
      <button type="button" onClick={clearError}>
        <FontIcon icon="close-circled" />
      </button>
      {error}
    </div>
  );
}
