import type { DashboardCard } from 'dashboard/util';
import type { DayOrWeek } from 'dashboard/util/constants';
import { withMatcher } from 'shared/util/withMatcher';
import { SET_DAY_OR_WEEK_BY_CARD } from 'store/action-types';

export const setDayOrWeekByCard = withMatcher((card: DashboardCard, dayOrWeek: DayOrWeek) => ({
  card,
  dayOrWeek,
  type: SET_DAY_OR_WEEK_BY_CARD,
}));
