import { CONFIRMATION_DIALOG, openDialog } from 'dialogs';

export const confirmDelete = (name, onConfirm) => {
  return openDialog(
    CONFIRMATION_DIALOG,
    {
      title: `Delete ${name}?`,
      body: `Deleting a tag will permanently remove it from all users, shifts, schedules it's linked to.`,
      onConfirm,
      confirmClass: 'btn-danger',
      confirmLabel: 'Delete',
    },
    { overlay: true },
  );
};

export const confirmMultiDelete = (name, onConfirm) => {
  return openDialog(
    CONFIRMATION_DIALOG,
    {
      title: 'Delete Tags?',
      body: 'Deleting tags will remove them from all users, shifts, schedules they’re linked to. This is a permanent action.',
      onConfirm,
      confirmClass: 'btn-danger',
      confirmLabel: 'Delete',
    },
    { overlay: true },
  );
};
