import { fetchCurrentAccount } from 'data/account/actions/fetchCurrentAccount';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

export const removeAccount = createWiwAsyncThunk<void, number>(
  'account/removeAccount',
  async (accountId, { rejectWithValue, dispatch }) => {
    try {
      await Monolith.delete(`/account/${accountId}`);
      dispatch(fetchCurrentAccount());
      return;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
