import { UserRole } from 'data/user/model';
import ChatHelper from 'shared/util/chat';

import { toPairs } from 'lodash';

export default class ElevioHelper {
  static ELEVIO_GROUP_ADMIN = 'Account_Holder';
  static ELEVIO_GROUP_MANAGER = 'Manager';
  static ELEVIO_GROUP_SUPERVISOR = 'Supervisor';
  static ELEVIO_GROUP_EMPLOYEE = 'Employee';

  // elevio module ids
  static ELEVIO_DOCUMENTATION = 5640;
  static ELEVIO_SUBMIT_TICKET = 9827;
  static ELEVIO_ZENDESK_CHAT = 4;
  static ELEVIO_INTERCOM = 3;
  static ELEVIO_ZENDESK_MESSENGER = 19;

  static identifyUser(user) {
    return {
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      phone_number: user.phone_number,
      groups: ElevioHelper.elevioDocumentationGroups(user),
    };
  }

  /**
   * Provide a list of modules that should be block listed
   *
   * @param {User} user
   * @param {Account} account
   * @param {Plan} plan
   * @param zendeskMessenger boolean to control which chat module to show
   * @returns {Array}
   */
  static getDisabledModules(user, account, plan, zendeskMessenger = false) {
    let canChat = ChatHelper.canChat(user, plan);
    const salesChat = ChatHelper.chatWithSalesNotCare(plan, account);

    if (account.isReverseTrial()) {
      canChat = false;
    }

    // true = always on, false = always off
    const criteria = {
      [this.ELEVIO_DOCUMENTATION]: true,
      [this.ELEVIO_SUBMIT_TICKET]: true,
      [this.ELEVIO_ZENDESK_CHAT]: canChat && !salesChat && !zendeskMessenger,
      [this.ELEVIO_INTERCOM]: canChat && salesChat,
      [this.ELEVIO_ZENDESK_MESSENGER]: canChat && !salesChat && zendeskMessenger,
    };
    // filter criteria and return an array of only the false valued keys
    return toPairs(criteria)
      .filter(([, value]) => !value)
      .map(([key]) => parseInt(key));
  }

  static elevioDocumentationGroups(user) {
    const elevioDocGroupMapping = {
      [UserRole.Admin]: [this.ELEVIO_GROUP_ADMIN, this.ELEVIO_GROUP_MANAGER, this.ELEVIO_GROUP_SUPERVISOR],
      [UserRole.Manager]: [this.ELEVIO_GROUP_MANAGER, this.ELEVIO_GROUP_SUPERVISOR],
      [UserRole.Supervisor]: [this.ELEVIO_GROUP_SUPERVISOR],
      [UserRole.Employee]: [this.ELEVIO_GROUP_EMPLOYEE],
    };

    return elevioDocGroupMapping[user.role] || [];
  }
}

export function openElevioCustomerCare() {
  const elevio = window._elev;
  if (elevio) {
    elevio.openModule(ElevioHelper.ELEVIO_ZENDESK_CHAT.toString());
    elevio.openModule(ElevioHelper.ELEVIO_ZENDESK_MESSENGER.toString());
  }
}
