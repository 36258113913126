import classNames from 'classnames';
import type User from 'data/user/model';
import { isNil } from 'lodash';
import moment from 'moment-timezone';

import { getAuthAccount, getAuthUser } from 'shared/auth/selectors';
import FontIcon from 'shared/ui/FontIcon';
import { useWiwSelector } from 'store';
import { ConversationDateFormat } from 'workchat/formats';
import { getWorkchatUserShortName } from 'workchat/utils';
import ConversationTitleV2 from 'workchat/v2/components/ConversationTitleV2';
import { CONVERSATION_TYPE } from 'workchat/v2/constants';
import type { ReduxConversation } from 'workchat/v2/store/conversations/conversationsReducer';
import { getUnreadCountForConversationId } from 'workchat/v2/store/conversations/conversationsSelectors';
import { getMessagesForConversation } from 'workchat/v2/store/messages/messageSelectors';
import { getUserParticipantsForConversationId } from 'workchat/v2/store/participants/participantsSelectors';

import AvatarPlaceholder from 'styles/assets/img/avatar-2.png';

interface ConversationListRowProps {
  conversation: ReduxConversation;
  connected: boolean;
  onConversationClick: (conversation: ReduxConversation) => void;
}

const MAX_UNREAD_COUNT = 9;

export default function ConversationListRow({
  conversation,
  connected,
  onConversationClick,
}: ConversationListRowProps) {
  const authUser = useWiwSelector(getAuthUser);
  const account = useWiwSelector(getAuthAccount);
  const usersForConversation = useWiwSelector(state => getUserParticipantsForConversationId(state, conversation.sid));
  const messagesForConversation = useWiwSelector(state => getMessagesForConversation(state, conversation.sid));
  const unread = useWiwSelector(state => getUnreadCountForConversationId(state, conversation.sid));
  const lastMessageFromConversationMessages = messagesForConversation.last();

  const renderAvatarPlaceholder = (conversationType: CONVERSATION_TYPE) => {
    const placeholderAvatar = (
      <div key="wiw-avatar" className="avatar">
        <img src={AvatarPlaceholder} alt="Avatar" />
      </div>
    );

    if (conversationType === CONVERSATION_TYPE.GROUP) {
      return (
        <div className="avatars">
          {placeholderAvatar}
          {placeholderAvatar}
        </div>
      );
    }

    return placeholderAvatar;
  };

  const renderAvatarOrInitials = (user: User) => {
    const avatarUrl = user?.getAvatar('small');

    return (
      <div className="avatar" key={`avatar-${conversation.sid}-${user.id}`}>
        {avatarUrl ? (
          <img src={avatarUrl} alt="Avatar" />
        ) : (
          <div className="initials">{[user.first_name.charAt(0), user.last_name.charAt(0)].join('').toUpperCase()}</div>
        )}
      </div>
    );
  };

  const renderAvatars = () => {
    const conversationType = conversation.attributes.external_type as CONVERSATION_TYPE;
    const otherUsers = usersForConversation?.filter(user => user.id !== authUser.id);
    const firstTwoUsers = otherUsers?.slice(0, 2);

    switch (conversation.attributes.external_type) {
      case CONVERSATION_TYPE.ACCOUNT: {
        return (
          <div key="wiw-avatar" className="avatar">
            {account.logo ? <img src={account.logo} alt="Logo" /> : <div className="wiw" />}
          </div>
        );
      }
      default: {
        if (!usersForConversation || otherUsers?.length === 0) {
          return renderAvatarPlaceholder(conversationType);
        }

        if (firstTwoUsers?.length === 1) {
          return renderAvatarOrInitials(firstTwoUsers?.[0]);
        }
        return <div className="avatars">{firstTwoUsers?.map(user => renderAvatarOrInitials(user))}</div>;
      }
    }
  };

  const renderConversationPreview = () => {
    const lastMessage = lastMessageFromConversationMessages;

    if (!isNil(conversation?.lastMessage?.index) && !lastMessage) {
      // We haven't loaded Messages for this conversation yet
      return <span className="conversation-preview-placeholder">View to load messages</span>;
    }

    if (!lastMessage) {
      return <span className="conversation-preview-placeholder">No messages</span>;
    }

    if (
      conversation.attributes.external_type === CONVERSATION_TYPE.ACCOUNT ||
      conversation.attributes.external_type === CONVERSATION_TYPE.GROUP
    ) {
      const lastMessageAuthor = usersForConversation?.find(user => user.id === lastMessage.author);
      const authorName = lastMessageAuthor ? getWorkchatUserShortName(lastMessageAuthor) : '???';

      if (lastMessage.body) {
        return (
          <span>
            {authorName}: {lastMessage?.body}
          </span>
        );
      }
      if (lastMessage.attributes?.thumbnail) {
        return <span>{authorName}: Image</span>;
      }
    }

    if (lastMessage.body) {
      return <span>{lastMessage?.body}</span>;
    }
    if (lastMessage.attributes?.thumbnail) {
      return <span>Image</span>;
    }
  };

  const renderLastMessageDate = () => {
    const date = lastMessageFromConversationMessages?.dateCreated || conversation.lastMessage?.dateCreated;

    if (date) {
      return <time dateTime={date || undefined}>{date && moment(date).calendar(null, ConversationDateFormat)}</time>;
    }
  };

  return (
    <div key={`conversation-list-${conversation.sid}`} className="conversation ConversationListRow">
      <button
        type="button"
        aria-label="Go to conversation"
        onClick={() => onConversationClick(conversation)}
        className={classNames({ disabled: !connected })}
      >
        <div className="callout">
          {renderAvatars()}
          {!!unread && (
            <div className="unread-count">
              <div>{unread <= MAX_UNREAD_COUNT ? unread : `${MAX_UNREAD_COUNT}+`}</div>
            </div>
          )}
        </div>
        <div className="conversation">
          <h3>
            <ConversationTitleV2 conversation={conversation} />
          </h3>
          {renderConversationPreview()}
        </div>
        <div className="time">
          {renderLastMessageDate()}
          <FontIcon icon="chevron-right" srText={'Go to conversation'} />
        </div>
      </button>
    </div>
  );
}
