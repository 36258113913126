import { experimentAssignments } from 'shared/mercury/experimentAssignments';
import { trackExperiment } from 'shared/util/mercury';

import { getLdClient } from 'ld-redux';

export const trackExperimentAssignments = () => next => action => {
  if (action.type === 'UPSERT_LD_FLAGS' && action.payload) {
    experimentAssignments.forEach(experiment => {
      const reason = getLdClient().variationDetail(experiment.ldFlag).reason?.kind;
      const evaluation = action.payload[experiment.ldFlag];
      if (!evaluation || evaluation.current === 'none' || reason !== 'RULE_MATCH') {
        return next(action);
      }

      trackExperiment(experiment.jira, evaluation.current, experiment.eventNames, true);
    });
  }

  return next(action);
};
