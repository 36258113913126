import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getOnboardingSchedulingFullScreenClosed } from 'onboarding/selectors';

export default function canViewSchedulerOnboarding(ComposedComponent) {
  class canViewSchedulerOnboarding extends Component {
    static propTypes = {
      onboardingSchedulingFullScreenClosed: PropTypes.bool.isRequired,
      history: PropTypes.shape({
        push: PropTypes.func.isRequired,
      }).isRequired,
    };

    componentDidMount() {
      this.redirectIfNeeded();
    }

    componentDidUpdate() {
      this.redirectIfNeeded();
    }

    shouldRedirect() {
      return this.props.onboardingSchedulingFullScreenClosed;
    }

    redirectIfNeeded() {
      if (this.shouldRedirect()) {
        this.props.history.push('/scheduler');
      }
    }

    render() {
      if (this.shouldRedirect()) {
        // avoid an unnecessary extra rendering of full screen onboarding and subsequent firing of step viewed events
        return null;
      }
      // draw the full screen onboarding
      return <ComposedComponent {...this.state} {...this.props} />;
    }
  }

  return connect(state => ({
    onboardingSchedulingFullScreenClosed: getOnboardingSchedulingFullScreenClosed(state),
  }))(withRouter(canViewSchedulerOnboarding));
}
