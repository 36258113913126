import { clearError } from 'workchat/actions/error';
import { connected, disconnected, updateReadStatus } from 'workchat/actions/index';
import { failedMessage, receiveMessage, sendingMessage, sentMessage } from 'workchat/actions/messages';
import { loadUsers, updateParticipant } from 'workchat/actions/users';

import { TwilioProvider, providerInstance as provider } from 'workchat/TwilioProvider';
import { WORKCHAT_START } from 'workchat/action-types';
import {
  createdConversation,
  leftConversation,
  loadConversations,
  setConversation,
  updateConversation,
  updateParticipants,
} from 'workchat/actions/conversations';

export function startWorkchat(token, user, logDebug) {
  return dispatch => {
    if (provider?.isReady()) {
      return;
    }

    TwilioProvider.globalInit(token, user, logDebug);

    provider.on('update.read', () => dispatch(updateReadStatus()));
    provider.on('conversation.created', (convo, participants) => dispatch(createdConversation(convo, participants)));
    provider.on('conversation.update', convo => dispatch(updateConversation(convo)));
    provider.on('conversation.leave', convo => dispatch(leftConversation(convo)));
    provider.on('conversation.set', convo => dispatch(setConversation(convo, true)));
    provider.on('message.create', message => dispatch(receiveMessage(message)));
    provider.on('message.sending', (convo, message) => dispatch(sendingMessage(convo, message)));
    provider.on('message.sent', (convo, message) => dispatch(sentMessage(convo, message)));
    provider.on('message.failed', (convo, message) => dispatch(failedMessage(convo, message)));
    provider.on('member.update', update => dispatch(updateParticipant(update.participant)));
    provider.on('member.joined', member => dispatch(updateParticipants(member.conversation, token, user.id)));
    provider.on('member.left', member => dispatch(updateParticipants(member.conversation, token, user.id)));
    provider.on('disconnected', () => dispatch(disconnected()));
    provider.on('reconnected', () => dispatch(connected()));

    return provider.start().then(() => {
      dispatch(clearError());

      dispatch(loadUsers(token, user.id));

      dispatch({
        type: WORKCHAT_START,
        user,
        flags: {
          conversations: true,
          broadcast: user.canSupervise(),
        },
      });

      dispatch(loadConversations(user));
    });
  };
}
