import EntityState from 'data/EntityState';
import { createShift } from 'data/shift/actions/createShift';
import { fetchShifts, fetchShiftsMerge } from 'data/shift/actions/fetchShifts';
import { updateShift } from 'data/shift/actions/updateShift';
import deleteShiftChain from 'data/shiftChain/actions/deleteShiftChain';
import ShiftChain from 'data/shiftChain/model';
import forwardActionValue from 'data/util/forwardActionValue';
import generateThunkReducers from 'data/util/generateThunkReducers';
import { toEntityMap } from 'shared/util/toEntityMap';

import { createSlice } from '@reduxjs/toolkit';

const initialState = new EntityState();

export const shiftChainSlice = createSlice({
  name: 'shiftChain',
  initialState,
  reducers: {
    receiveShiftChain: (state, action) => {
      return state.mergeDeep({
        loading: false,
        loaded: true,
        error: null,
        receivedAt: Date.now(),
        items: state.items.merge(toEntityMap(action.payload, ShiftChain, 'key')),
      });
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createShift.fulfilled, (state, action) => {
        if (action.payload.shiftchains) {
          return shiftChainSlice.caseReducers.receiveShiftChain(state, forwardActionValue(action, 'shiftchains'));
        }
        return state;
      })
      .addCase(deleteShiftChain.fulfilled, (state, action) => {
        return state.merge({
          items: state.items.filter(item => item.key !== action.meta.arg.shift.shiftchain_key),
        });
      })
      .addCase(fetchShifts.fulfilled, (state, action) => {
        if (action.payload.shiftchains) {
          return shiftChainSlice.caseReducers.receiveShiftChain(state, forwardActionValue(action, 'shiftchains'));
        }
        return state;
      })
      .addCase(updateShift.fulfilled, (state, action) => {
        if (action.payload.shiftchains) {
          return shiftChainSlice.caseReducers.receiveShiftChain(state, forwardActionValue(action, 'shiftchains'));
        }
        return state;
      })
      .addCase(fetchShiftsMerge.fulfilled, (state, action) => {
        if (action.payload.shiftchains) {
          return shiftChainSlice.caseReducers.receiveShiftChain(state, forwardActionValue(action, 'shiftchains'));
        }
        return state;
      });
    generateThunkReducers(builder, 'shiftChain');
  },
});

export const { receiveShiftChain } = shiftChainSlice.actions;
export default shiftChainSlice.reducer;
