import { lazy } from 'react';
const AddUserToScheduleDialog = lazy(() => import('scheduler/dialogs/AddUserToScheduleDialog/AddUserToScheduleDialog'));
const AddMultipleUsersDialog = lazy(() => import('scheduler/dialogs/AddMultipleUsersDialog/AddMultipleUsersDialog'));
const AnnotationDialog = lazy(() => import('scheduler/views/shared/containers/AnnotationDialog'));
const AnnotationShiftOptionsDialog = lazy(() => import('scheduler/views/shared/dialogs/AnnotationShiftOptionsDialog'));
const AutoScheduleDialog = lazy(() => import('scheduler/dialogs/AutoScheduleDialog'));
const BulkEditShiftDialog = lazy(() => import('scheduler/views/shared/containers/BulkEditShiftDialog'));
const ClearShiftsDialog = lazy(() => import('scheduler/views/shared/containers/ClearShiftsDialog'));
const CopyShiftsDialog = lazy(() => import('scheduler/containers/CopyShiftsDialog'));
const CreateTemplateDialog = lazy(() => import('scheduler/containers/template/CreateTemplateDialog'));
const EditShiftDialog = lazy(() => import('scheduler/dialogs/EditShiftDialog/EditShiftDialog'));
const EmployeeSortDialog = lazy(() => import('scheduler/dialogs/EmployeeSortDialog'));
const ExportShiftDialog = lazy(() => import('scheduler/containers/ExportShiftDialog'));
const FindReplacementDialog = lazy(() => import('scheduler/views/shared/containers/FindReplacementDialog'));
const ForecastCustomUnitDialog = lazy(() => import('./ForecastCustomUnitDialog'));
const ForecastImportDialog = lazy(() => import('scheduler/dialogs/ForecastImportDialog/ForecastImportDialog'));
const GuidedScheduleImportDialog = lazy(
  () => import('scheduler/dialogs/GuidedScheduleImportDialog/ScheduleImportDialog'),
);
const GuidedScheduleImportSuccessDialog = lazy(
  () => import('scheduler/dialogs/GuidedScheduleImportDialog/ScheduleImportSuccessDialog'),
);
const GuidedScheduleSetupDialog = lazy(() => import('scheduler/dialogs/GuidedScheduleSetupDialog'));
const LoadTemplatesDialog = lazy(() => import('scheduler/containers/template/LoadTemplatesDialog'));
const RequestViewDialog = lazy(() => import('./RequestViewDialog'));
const SchedulingConcernsDialog = lazy(() => import('./SchedulingConcernsDialog'));
const UserContactInformationDialog = lazy(
  () => import('scheduler/views/shared/containers/UserContactInformationDialog'),
);
const ViewAllShiftsDialog = lazy(() => import('scheduler/views/month/components/ViewAllShiftsDialog'));
const ShiftHistoryDialog = lazy(() => import('scheduler/dialogs/ShiftHistoryDialog'));
const DeleteShiftDialog = lazy(() => import('scheduler/dialogs/DeleteShiftDialog'));
const OfferOpenShiftDialog = lazy(() => import('scheduler/dialogs/OfferOpenShiftDialog/OfferOpenShiftDialog'));
const ShiftRepeatConfirmationDialog = lazy(() => import('scheduler/dialogs/ShiftRepeatConfirmationDialog'));
const SetupSuccessDialog = lazy(() => import('scheduler/dialogs/SetupSuccessDialog'));

// Identifiers for each dialog
export const ADD_USER_TO_SCHEDULE_DIALOG = 'ADD_USER_TO_SCHEDULE_DIALOG';
export const ADD_MULTIPLE_USERS_DIALOG = 'ADD_MULTIPLE_USERS_DIALOG';
export const ANNOTATION_DIALOG = 'ANNOTATION_DIALOG';
export const ANNOTATION_SHIFT_OPTIONS_DIALOG = 'ANNOTATION_SHIFT_OPTIONS_DIALOG';
export const AUTO_SCHEDULE_DIALOG = 'AUTO_SCHEDULE_DIALOG';
export const BULK_EDIT_SHIFT_DIALOG = 'BULK_EDIT_SHIFT_DIALOG';
export const CLEAR_SHIFTS_DIALOG = 'CLEAR_SHIFTS_DIALOG';
export const COPY_SHIFTS_DIALOG = 'COPY_SHIFTS_DIALOG';
export const CREATE_TEMPLATE_DIALOG = 'CREATE_TEMPLATE_DIALOG';
export const EDIT_SHIFT_DIALOG = 'EDIT_SHIFT_DIALOG';
export const EMPLOYEE_SORT_DIALOG = 'EMPLOYEE_SORT_DIALOG';
export const EXPORT_SHIFT_DIALOG = 'EXPORT_SHIFT_DIALOG';
export const FIND_REPLACEMENT_DIALOG = 'FIND_REPLACEMENT_DIALOG';
export const FORECAST_CUSTOM_UNIT_DIALOG = 'FORECAST_CUSTOM_UNIT_DIALOG';
export const FORECAST_IMPORT_DIALOG = 'FORECAST_IMPORT_DIALOG';
export const GUIDED_SCHEDULE_SETUP_DIALOG = 'GUIDED_SCHEDULE_SETUP_DIALOG';
export const GUIDED_SCHEDULE_IMPORT_DIALOG = 'GUIDED_SCHEDULE_IMPORT_DIALOG';
export const GUIDED_SCHEDULE_IMPORT_SUCCESS_DIALOG = 'GUIDED_SCHEDULE_IMPORT_SUCCESS_DIALOG';
export const SETUP_SUCCESS_DIALOG = 'SETUP_SUCCESS_DIALOG';
export const LOAD_TEMPLATES_DIALOG = 'LOAD_TEMPLATES_DIALOG';
export const REQUEST_VIEW_DIALOG = 'REQUEST_VIEW_DIALOG';
export const SCHEDULING_CONCERNS_DIALOG = 'SCHEDULING_CONCERNS_DIALOG';
export const USER_CONTACT_INFORMATION_DIALOG = 'USER_CONTACT_INFORMATION_DIALOG';
export const VIEW_ALL_SHIFTS_DIALOG = 'VIEW_ALL_SHIFTS_DIALOG';
export const VIEW_SHIFT_HISTORY = 'VIEW_SHIFT_HISTORY';
export const DELETE_SHIFT_DIALOG = 'DELETE_SHIFT_DIALOG';
export const OFFER_OPEN_SHIFT_DIALOG = 'OFFER_OPEN_SHIFT_DIALOG';
export const SHIFT_REPEAT_CONFIRMATION_DIALOG = 'SHIFT_REPEAT_CONFIRMATION_DIALOG';

const dialogs = {
  [ADD_USER_TO_SCHEDULE_DIALOG]: AddUserToScheduleDialog,
  [ADD_MULTIPLE_USERS_DIALOG]: AddMultipleUsersDialog,
  [ANNOTATION_DIALOG]: AnnotationDialog,
  [ANNOTATION_SHIFT_OPTIONS_DIALOG]: AnnotationShiftOptionsDialog,
  [AUTO_SCHEDULE_DIALOG]: AutoScheduleDialog,
  [BULK_EDIT_SHIFT_DIALOG]: BulkEditShiftDialog,
  [CLEAR_SHIFTS_DIALOG]: ClearShiftsDialog,
  [COPY_SHIFTS_DIALOG]: CopyShiftsDialog,
  [CREATE_TEMPLATE_DIALOG]: CreateTemplateDialog,
  [EDIT_SHIFT_DIALOG]: EditShiftDialog,
  [EMPLOYEE_SORT_DIALOG]: EmployeeSortDialog,
  [EXPORT_SHIFT_DIALOG]: ExportShiftDialog,
  [FIND_REPLACEMENT_DIALOG]: FindReplacementDialog,
  [FORECAST_CUSTOM_UNIT_DIALOG]: ForecastCustomUnitDialog,
  [GUIDED_SCHEDULE_IMPORT_DIALOG]: GuidedScheduleImportDialog,
  [GUIDED_SCHEDULE_IMPORT_SUCCESS_DIALOG]: GuidedScheduleImportSuccessDialog,
  [FORECAST_IMPORT_DIALOG]: ForecastImportDialog,
  [GUIDED_SCHEDULE_SETUP_DIALOG]: GuidedScheduleSetupDialog,
  [LOAD_TEMPLATES_DIALOG]: LoadTemplatesDialog,
  [REQUEST_VIEW_DIALOG]: RequestViewDialog,
  [SCHEDULING_CONCERNS_DIALOG]: SchedulingConcernsDialog,
  [USER_CONTACT_INFORMATION_DIALOG]: UserContactInformationDialog,
  [VIEW_ALL_SHIFTS_DIALOG]: ViewAllShiftsDialog,
  [VIEW_SHIFT_HISTORY]: ShiftHistoryDialog,
  [DELETE_SHIFT_DIALOG]: DeleteShiftDialog,
  [OFFER_OPEN_SHIFT_DIALOG]: OfferOpenShiftDialog,
  [SHIFT_REPEAT_CONFIRMATION_DIALOG]: ShiftRepeatConfirmationDialog,
  [SETUP_SUCCESS_DIALOG]: SetupSuccessDialog,
  // Add more here ...
};

export default dialogs;
