function _getStreamType(conv) {
  const defaultStream = 'default';
  if (!conv) {
    return defaultStream;
  }
  return { individual: '1:1', group: 'Group', account: defaultStream }[conv.attributes.external_type] || defaultStream;
}

export function track(_event, _attrs = {}) {
  return (_dispatch, _getState) => {
    // TODO Analytics: Add tracking
  };
}
