import Workchat from 'shared/api/workchat';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

export type AuthenticateWorkchatResponse = {
  identity: number;
  token: string;
};

export const authenticateWorkchat = createWiwAsyncThunk<AuthenticateWorkchatResponse, undefined>(
  'workchatv2/auth',
  async (_params = undefined, { rejectWithValue }) => {
    try {
      return await Workchat.url('/token').post(JSON.stringify({ device: 'browser' }));
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
