import { lazy } from 'react';

const CreateAbsenceDialog = lazy(() => import('requests/modules/absence/dialogs/CreateAbsenceDialog'));
const DeleteAbsenceDialog = lazy(() => import('requests/modules/absence/dialogs/DeleteAbsenceDialog'));
const ViewAbsenceDetailDialog = lazy(() => import('requests/modules/absence/dialogs/ViewAbsenceDetailDialog'));
const ExportAbsenceDialog = lazy(() => import('requests/modules/absence/dialogs/ExportAbsenceDialog'));

// Identifiers for each dialog, MUST be unique among all dialogs
export const CREATE_ABSENCE_DIALOG = 'CREATE_ABSENCE_DIALOG';
export const DELETE_ABSENCE_DIALOG = 'DELETE_ABSENCE_DIALOG';
export const VIEW_ABSENCE_DIALOG = 'VIEW_ABSENCE_DIALOG';
export const EXPORT_ABSENCE_DIALOG = 'EXPORT_ABSENCE_DIALOG';

const dialogs = {
  [CREATE_ABSENCE_DIALOG]: CreateAbsenceDialog,
  [DELETE_ABSENCE_DIALOG]: DeleteAbsenceDialog,
  [VIEW_ABSENCE_DIALOG]: ViewAbsenceDetailDialog,
  [EXPORT_ABSENCE_DIALOG]: ExportAbsenceDialog,
};

export default dialogs;
