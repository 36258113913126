import { getAccountMetadata, getUserMetadata } from 'data/metadata/selectors';
import type Shift from 'data/shift/model';
import { getStateShifts } from 'data/shift/selectors/getStateShifts';
import type User from 'data/user/model';
import { getActiveUsers } from 'data/user/selectors';
import {
  STEP_ATTENDANCE_ADD_EMPLOYEES,
  STEP_ATTENDANCE_EDIT_TIMESHEET,
  STEP_ATTENDANCE_EXPORT_TIMESHEET,
  STEP_SCHEDULING_ADD_EMPLOYEES,
  STEP_SCHEDULING_CREATE_SHIFT,
  STEP_SCHEDULING_PUBLISH_SCHEDULE,
} from 'onboarding/constants';
import type { EmployeeOnboardingStatus } from 'onboarding/constants';
import {
  FOCUSED_ONBOARDING_REDIRECT,
  ONBOARDING_ATTENDANCE_CHECKLIST_CLOSED,
  ONBOARDING_ATTENDANCE_CHECKLIST_MINIMIZED,
  ONBOARDING_PRODUCT_ATTENDANCE_DESIRED,
  ONBOARDING_PRODUCT_SCHEDULING_DESIRED,
  ONBOARDING_SCHEDULE_PUBLISHED,
  ONBOARDING_SCHEDULING_CHECKLIST_CLOSED,
  ONBOARDING_SCHEDULING_CHECKLIST_MINIMIZED,
  ONBOARDING_SCHEDULING_FULL_SCREEN_CLOSED,
  ONBOARDING_SHIFT_CREATED,
  ONBOARDING_SHIFT_REASSIGNED,
  ONBOARDING_TIMESHEET_EDITED,
  ONBOARDING_TIMESHEET_EXPORTED,
  ONBOARDING_USER_CREATED,
} from 'onboarding/metadataConstants';
import { getAccountId, getAuthAccount, getAuthUser } from 'shared/auth/selectors';
import type { WiwState } from 'store';

import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment-timezone';

function booleanifyAccountMetadata(state: WiwState, key: string, defaultWhenNotLoaded: boolean): boolean {
  if (!state.data.metadata.get('accountLoaded')) {
    return defaultWhenNotLoaded;
  }
  return getAccountMetadata(state).get(key) === true;
}

function booleanifyUserMetadata(state: WiwState, key: string, defaultWhenNotLoaded: boolean): boolean {
  if (!state.data.metadata.get('userLoaded')) {
    return defaultWhenNotLoaded;
  }
  return getUserMetadata(state).get(key) === true;
}

// "Add Employees Step" completed, but only according to metadata only.
// Used by saveUser action creator to avoid excess metadata updates.
export function getOnboardingUserCreatedAccordingToMetadata(state: WiwState): boolean {
  return booleanifyAccountMetadata(state, ONBOARDING_USER_CREATED, false);
}

/**
 * Some state we can derive (such as number of users created) and also look at metadata.
 */
export const getOnboardingUserCreated = createSelector(
  getOnboardingUserCreatedAccordingToMetadata,
  getActiveUsers,
  (userCreatedAccordingToMetadata, users) =>
    userCreatedAccordingToMetadata || users.count((user: User) => !!user.id) > 1,
);

/**
 * These selectors are for the "new" metadata service. The idea is to have onboarding state kept
 * in "new" metadata long-term, rather than cookies, local storage or the "old" metadata service.
 *
 * Use of these selectors should still call fetchUserMetadataIfNeeded or fetchAccountMetadataIfNeeded in
 * componentDidMount() lifecycle methods to ensure the appropriate metadata is loaded.
 */
export function getOnboardingAttendanceChecklistClosed(state: WiwState): boolean {
  return booleanifyUserMetadata(state, ONBOARDING_ATTENDANCE_CHECKLIST_CLOSED, true);
}

export function getOnboardingAttendanceChecklistMinimized(state: WiwState): boolean {
  return booleanifyUserMetadata(state, ONBOARDING_ATTENDANCE_CHECKLIST_MINIMIZED, true);
}

export function getOnboardingTimesheetEdited(state: WiwState): boolean {
  return booleanifyAccountMetadata(state, ONBOARDING_TIMESHEET_EDITED, true);
}

export function getOnboardingTimesheetExported(state: WiwState): boolean {
  return booleanifyAccountMetadata(state, ONBOARDING_TIMESHEET_EXPORTED, true);
}

export const getOnboardingAttendanceChecklistActiveStep = createSelector(
  [getOnboardingUserCreated, getOnboardingTimesheetEdited, getOnboardingTimesheetExported],
  (userCreated, timesheetEdited, timesheetExported) => {
    let activeStep = null;
    if (!userCreated) {
      activeStep = STEP_ATTENDANCE_ADD_EMPLOYEES;
    } else if (userCreated && !timesheetEdited) {
      activeStep = STEP_ATTENDANCE_EDIT_TIMESHEET;
    } else if (timesheetEdited && userCreated && !timesheetExported) {
      activeStep = STEP_ATTENDANCE_EXPORT_TIMESHEET;
    }

    return activeStep;
  },
);

export const getOnboardingAttendanceChecklistAllStepsCompleted = createSelector(
  [getOnboardingUserCreated, getOnboardingTimesheetEdited, getOnboardingTimesheetExported],
  (addedEmployees, editedTimesheet, exportedTimesheet) => {
    return addedEmployees && editedTimesheet && exportedTimesheet;
  },
);

/*
Example onboarding shape state.scheduler.get('onboarding')
See the scheduler/combineReducers.ts for more detail

{
  currentStepIndex: 0
  employeeCount: null
  industryId: null
  previousStepIndex: null
  highlightVisible: {
    addEmployee: false,
    createShift: false,
    publishSchedule: false,
    editTimesheet: false,
    exportTimesheet: false,
  }
}
*/

export function getOnboardingFullScreenCurrentStepIndex(state: WiwState): number {
  return state.scheduler.getIn(['onboarding', 'currentStepIndex']);
}

export function getOnboardingFullScreenPreviousStepIndex(state: WiwState): number {
  return state.scheduler.getIn(['onboarding', 'previousStepIndex']);
}

export function getOnboardingSchedulingChecklistClosed(state: WiwState): boolean {
  return booleanifyAccountMetadata(state, ONBOARDING_SCHEDULING_CHECKLIST_CLOSED, true);
}

export function getOnboardingSchedulingChecklistMinimized(state: WiwState): boolean {
  return booleanifyUserMetadata(state, ONBOARDING_SCHEDULING_CHECKLIST_MINIMIZED, true);
}

// Only used to update metadata
export function getOnboardingShiftCreatedAccordingToMetadata(state: WiwState): boolean {
  return booleanifyAccountMetadata(state, ONBOARDING_SHIFT_CREATED, false);
}

export const getOnboardingShiftCreated = createSelector(
  [getStateShifts, getOnboardingShiftCreatedAccordingToMetadata],
  (shifts, metadataShiftCreated) => metadataShiftCreated || shifts.count((shift: Shift) => !!shift.id) > 0,
);

// Only used to update metadata
export function getOnboardingSchedulePublishedAccordingToMetadata(state: WiwState): boolean {
  return booleanifyAccountMetadata(state, ONBOARDING_SCHEDULE_PUBLISHED, false);
}

export const getOnboardingSchedulePublished = createSelector(
  [getStateShifts, getOnboardingSchedulePublishedAccordingToMetadata],
  (shifts, metadataSchedulePublished) =>
    metadataSchedulePublished || shifts.filter((shift: Shift) => shift.published === true).size > 0,
);

export const getOnboardingSchedulingChecklistActiveStep = createSelector(
  [getOnboardingUserCreated, getOnboardingShiftCreated, getOnboardingSchedulePublished],
  (addedEmployees, shiftCreated, schedulePublished) => {
    let activeStep = null;
    if (!addedEmployees) {
      activeStep = STEP_SCHEDULING_ADD_EMPLOYEES;
    } else if (addedEmployees && !shiftCreated) {
      activeStep = STEP_SCHEDULING_CREATE_SHIFT;
    } else if (addedEmployees && shiftCreated && !schedulePublished) {
      activeStep = STEP_SCHEDULING_PUBLISH_SCHEDULE;
    }

    return activeStep;
  },
);

export const getOnboardingSchedulingChecklistAllStepsCompleted = createSelector(
  [getOnboardingUserCreated, getOnboardingShiftCreated, getOnboardingSchedulePublished],
  (addedEmployees, shiftCreated, schedulePublished) => {
    return addedEmployees && shiftCreated && schedulePublished;
  },
);

export function getOnboardingProductAttendanceDesired(state: WiwState): boolean {
  return booleanifyAccountMetadata(state, ONBOARDING_PRODUCT_ATTENDANCE_DESIRED, false);
}

export function getOnboardingProductSchedulingDesired(state: WiwState): boolean {
  return booleanifyAccountMetadata(state, ONBOARDING_PRODUCT_SCHEDULING_DESIRED, false);
}

const ONBOARDING_ACCOUNT_DAY_LIMIT = 30;
export const getOnboardingIsRecentAccount = createSelector(
  [getAuthAccount],
  currentAccount =>
    currentAccount && moment().diff(currentAccount.date('created_at'), 'days') <= ONBOARDING_ACCOUNT_DAY_LIMIT,
);

function onboardingSchedulingFullScreenClosedAccordingToMetadata(state: WiwState): boolean {
  return booleanifyAccountMetadata(state, ONBOARDING_SCHEDULING_FULL_SCREEN_CLOSED, true);
}

export function getIsLocalStorageMigrated(state: WiwState): boolean {
  return localStorage.getItem(`checklist_state${getAccountId(state)}`) == null;
}

// Helper to avoid flashing onboarding components when they shouldn't be displayed
export const getOnboardingMigratedAndLoaded = createSelector(
  [
    getIsLocalStorageMigrated,
    (state: WiwState) => state.data.metadata.get('accountLoaded') && state.data.metadata.get('userLoaded'),
  ],
  (migrated, loaded) => migrated && loaded,
);

// Is Full Screen Scheduling onboarding effectively closed?
// Closed if not AH / metadata marked closed / account is 30 days older so as to not bother anymore with onboarding
export const getOnboardingSchedulingFullScreenClosed = createSelector(
  [
    getAuthUser,
    getOnboardingMigratedAndLoaded,
    onboardingSchedulingFullScreenClosedAccordingToMetadata,
    getOnboardingIsRecentAccount,
    getIsLocalStorageMigrated,
  ],
  (currentUser, loaded, fullScreenClosed, isRecentAccount, isMigrated) =>
    (currentUser && !currentUser.isAdmin()) || !loaded || fullScreenClosed || !isRecentAccount || !isMigrated,
);

export function getOnboardingShiftReassigned(state: WiwState): boolean {
  return booleanifyAccountMetadata(state, ONBOARDING_SHIFT_REASSIGNED, true);
}

export function getFocusedOnboardingRedirect(state: WiwState): boolean {
  return booleanifyAccountMetadata(state, FOCUSED_ONBOARDING_REDIRECT, false);
}

export function getEmployeeOnboardingStatus(state: WiwState): EmployeeOnboardingStatus | undefined {
  return state.onboarding.employeeOnboardingStatus;
}
