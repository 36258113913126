import { SCHEDULER_SITES_FILTER } from 'scheduler/util/cookies';
import { getPersistedFilter, persistFilter } from 'scheduler/util/persistFilter';
import { UPDATE_SITE_FILTER } from 'store/action-types';

import { uniq } from 'lodash';

export function updateSiteFilter(payload) {
  return dispatch => {
    const deduped = uniq(payload);
    persistFilter(SCHEDULER_SITES_FILTER, deduped);

    return dispatch({
      type: UPDATE_SITE_FILTER,
      payload: deduped,
    });
  };
}

export const getKey = (location, userId) => `${SCHEDULER_SITES_FILTER}-${userId}-${location}`;

export const getInitialSitesFilter = () => getPersistedFilter(SCHEDULER_SITES_FILTER, ['all']);
