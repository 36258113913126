import PropTypes from 'prop-types';
import { Component } from 'react';
import ReactDOM from 'react-dom';

import 'styles/loaders/overlay.scss';

export const GLOBAL_LOADER_ROOT_ID = 'global-loader-root';

class Overlay extends Component {
  static propTypes = {
    backdrop: PropTypes.bool,
    text: PropTypes.string,
    subtext: PropTypes.string,
  };

  static defaultProps = {
    backdrop: true,
    text: 'Loading...',
    subtext: '',
  };

  render() {
    const { backdrop, text, subtext } = this.props;

    return ReactDOM.createPortal(
      <div className="global-loader">
        {backdrop && <div className="global-loader-backdrop" />}
        <div className={`loading-app default global-loader-body ${subtext.length > 0 ? 'loader-with-subtext' : ''}`}>
          <div className="loader-background" />
          <div className="loader-segment light" />
          <div className="loader-segment medium" />
          <div className="loader-segment dark" />
          <span>{text}</span>
          {subtext.length > 0 && <p>{subtext}</p>}
        </div>
      </div>,
      document.getElementById(GLOBAL_LOADER_ROOT_ID),
    );
  }
}

export default Overlay;
