import EntityState from 'data/EntityState';
import { mergeEntityItems } from 'data/util/sliceHelpers';
import { createSalariedSettings, fetchSalariedSettings } from './actions';
import { SalariedSettings, type SalariedSettingsType } from './model';

import { createSlice } from '@reduxjs/toolkit';

const initialState = new EntityState<SalariedSettingsType>();

export const salariedSettingsReducer = createSlice({
  name: 'salariedSettings',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchSalariedSettings.fulfilled, (state, action) => {
        return mergeEntityItems(state, action.payload.settings, SalariedSettings, 'userId');
      })
      .addCase(createSalariedSettings.fulfilled, (state, action) => {
        return mergeEntityItems(state, action.payload.settings, SalariedSettings, 'userId');
      });
  },
});

export default salariedSettingsReducer.reducer;
