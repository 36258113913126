import { useEffect, useState } from 'react';
import { getToken } from 'shared/auth/identifiers';
import { getAuthUser } from 'shared/auth/selectors';
import { useWiwSelector } from 'store';
import TwilioProvider, { providerInstance } from 'workchat/TwilioProvider';

const useTwilioProvider = () => {
  const [provider, setProvider] = useState<TwilioProvider | null>(providerInstance);
  const user = useWiwSelector(getAuthUser);

  useEffect(() => {
    if (!provider) {
      console.log('Initializing TwilioProvider');
      setProvider(new TwilioProvider(getToken(), user));
    }
  }, [user]);

  return provider;
};

export default useTwilioProvider;
