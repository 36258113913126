import EntityBase from 'data/EntityBase';

import { Map } from 'immutable';

export type AccountMetadata = MetadataState['account'];
export type UserMetadata = MetadataState['user'];

export default class MetadataState extends EntityBase({
  account: Map<string, any>(),
  accountLoading: false,
  accountLoaded: false,
  user: Map<string, any>(),
  userLoading: false,
  userLoaded: false,
}) {}
