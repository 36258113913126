import type Shift from 'data/shift/model';
import type { ShiftFields } from 'data/shift/model';
import type { ShiftChainFields } from 'data/shiftChain/model';
import type { TagFields } from 'data/tag/Tag';
import { replaceShiftTaskListsStore } from 'data/tasks/actions/persistScheduledShiftTaskList';
import type { TaskFields } from 'data/tasks/models/TaskModel';
import { updateSchedulerOnboardingForShift } from 'onboarding/actions';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

export type CreateShiftParams = {
  shift: Shift;
  includeRepeating?: boolean | string;
};

export type CreateShiftResponse = {
  shift: ShiftFields;
  shiftchains: ShiftChainFields[];
  repeating_shifts: ShiftFields[];
  tags?: TagFields['id'][];
  tasks?: TaskFields[];
};

export const createShift = createWiwAsyncThunk<CreateShiftResponse, CreateShiftParams>(
  'shift/create',
  async (args, { dispatch, rejectWithValue }) => {
    try {
      const response = (await Monolith.url('/shifts')
        .query({ include_repeating_shifts_to: args.includeRepeating })
        .post(args.shift)) as CreateShiftResponse;

      const { shift, tasks } = response;

      dispatch(updateSchedulerOnboardingForShift(shift));

      if (tasks) {
        dispatch(replaceShiftTaskListsStore(tasks, shift.id));
      }

      return response;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
