import type Absence from 'data/absence/model';
import type { AbsenceFields } from 'data/absence/model';
import Scheduling from 'shared/api/scheduling';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

export interface CreateAbsenceEvent {
  id: string;
  type: string;
  action: string;
}

export type CreateAbsenceResponse = {
  data: {
    absence: AbsenceFields;
    events: CreateAbsenceEvent[];
  };
};

export const createAbsence = createWiwAsyncThunk<CreateAbsenceResponse, Absence>(
  'absence/create',
  async (absence, { rejectWithValue }) => {
    try {
      return (await Scheduling.url('/absences').post(absence.toJSON())) as CreateAbsenceResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
