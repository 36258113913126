import { lazy } from 'react';

const InterestedDialog = lazy(() => import('attendance/dialogs/InterestedDialog'));

export const INTERESTED_DIALOG = 'INTERESTED_DIALOG';

const dialogs = {
  [INTERESTED_DIALOG]: InterestedDialog,
  // ...
};

export default dialogs;
