import classNames from 'classnames';
import { memo } from 'react';

export interface ImageType {
  object?: HTMLImageElement;
  thumb?: string;
  loaded?: boolean;
  dataUrl?: string;
  full?: string;
  width?: number;
  height?: number;
}

interface MessageImageProps {
  image: ImageType;
  withText?: boolean;
}

export const MessageImage = memo(({ image, withText }: MessageImageProps) => {
  const renderImage = () => {
    if (image.dataUrl) {
      return <img src={image.dataUrl} alt="message-image" />;
    }
    return <img src={image.thumb} alt="thumb" />;
  };

  return (
    <a target="_blank" className={classNames('img-container', { 'mt-2': withText })} href={image.full} rel="noreferrer">
      {renderImage()}
    </a>
  );
});
