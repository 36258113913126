import { useDispatch } from 'react-redux';
import FontIcon from 'shared/ui/FontIcon';
import type { HeaderButton } from 'workchat/v2/components/HeaderV2';
import { viewStackPop } from 'workchat/v2/store/reducer';

import SpinningLoader from 'shared/ui/loaders/SpinningLoader';
import loading from 'workchat/assets/workchat-loading.svg';
import HeaderV2 from 'workchat/v2/components/HeaderV2';

export const VIEW_LOADING = 'VIEW_LOADING';

export default function LoadingV2() {
  const dispatch = useDispatch();

  const buttons: HeaderButton[] = [
    {
      title: 'Back',
      action: () => dispatch(viewStackPop()),
      label: [<FontIcon className="mr-1" key="back" icon="chevron-left" />, 'Back'],
      side: 'left',
      color: 'secondary',
    },
  ];

  return (
    <div className="loading-container">
      <HeaderV2 buttons={buttons}>WorkChat</HeaderV2>
      <div className="row no-gutters w-100 text-center">
        <div className="col-12 my-6">
          <img src={loading} alt="Loading" />
        </div>
        <div className="col-12 mt-6">
          <div className="row no-gutters w-100 align-items-center justify-content-center">
            <SpinningLoader className="spinning-loader" color="gray" height={25} width={25} />
            <span className="workchat-loading-label ml-2">Loading&hellip;</span>
          </div>
        </div>
      </div>
    </div>
  );
}
