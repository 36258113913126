import { Record } from 'immutable';

export interface TimezoneFields {
  id: any | null; // TODO(types)
  name: any | null; // TODO(types)
  offset: any | null; // TODO(types)
  olson_id: any | null; // TODO(types)
}

export default class Timezone extends Record<TimezoneFields>({
  id: null,
  name: null,
  offset: null,
  olson_id: null,
}) {
  humanOffset() {
    return `GMT${this.offset >= 0 ? `+${this.offset}` : this.offset}`;
  }

  humanFullTimezone() {
    return `(${this.humanOffset()}) ${this.name}`;
  }
}
