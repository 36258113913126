import TimeHistory from 'data/timeHistory/model';
import TimeHistoryState from 'data/timeHistory/state';
import { camelCaseToSnakeCaseObject } from 'shared/util/objectCaseUtilities';
import { FETCH_TIME_HISTORY } from 'store/action-types';

export default (state = new TimeHistoryState(), action) => {
  switch (action.type) {
    case FETCH_TIME_HISTORY.REQUEST:
      return state.merge({
        loading: true,
        receivedAt: 0,
        loaded: false,
      });
    case FETCH_TIME_HISTORY.SUCCESS:
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        receivedAt: Date.now(),
        items: state.items.merge(
          action.items.map(timeHistory => [timeHistory.id, new TimeHistory(camelCaseToSnakeCaseObject(timeHistory))]),
        ),
      });
    case FETCH_TIME_HISTORY.FAILURE:
      return state.merge({
        loaded: false,
        loading: false,
        error: action.payload,
      });
    default:
      return state;
  }
};
