import { formatQueryString } from 'shared/util/mercury';

export const MESSAGE_AUTHOR_SYSTEM_ID = -1337;

export enum CONVERSATION_TYPE {
  ACCOUNT = 'account',
  GROUP = 'group',
  INDIVIDUAL = 'individual',
}

export const EMOJI: InputType = 'emoji';
export const GIF: InputType = 'gif';
export type InputType = 'emoji' | 'gif';
export type Emoji = {
  emoji: string;
  native: string;
  unified: string;
};

/**
 * Mercury Event Base Payload
 * https://catalog.wiwdata.com/#/entry/entries-0a7cf07a-cb84-41bf-860f-193213b8d3a8
 */
export const WORKCHAT_ONINTERACTION = {
  hostName: window.location.host,
  path: window.location.pathname,
  queryParams: formatQueryString(window.location.search),
  hash: window.location.hash,
  annotations: null,
};

export enum WORKCHAT_ONINTERACTION_ACTIONS {
  messageMenuDeleteClick = 'messageMenuDeleteClick',
  messageDeleteSuccess = 'messageDeleteSuccess',
  messageMenuCopyClick = 'messageMenuCopyClick',
  messageMenuAddReactionClick = 'messageMenuAddReactionClick',
  messageAddReactionClick = 'messageAddReactionClick',
  messageReactionChipClick = 'messageReactionChipClick',
  messageInputAddPhotoClick = 'messageInputAddPhotoClick',
  messageInputShowEmojiPickerClick = 'messageInputShowEmojiPickerClick',
}

export enum WORKCHAT_ERRORS {
  failedToAuthenticate = 'failedToAuthenticate',
  failedToInitClient = 'failedToInitClient',
  failedToPreloadConversations = 'failedToPreloadConversations',
  failedToViewConversation = 'failedToViewConversation',
}
