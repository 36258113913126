import type { StripeInvoice } from 'data/stripe/types/invoice';
import Billing from 'shared/api/billing';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type FetchStripeInvoicesArgs = number | undefined;

type FetchStripeInvoicesResponse = {
  data: StripeInvoice[];
};

export const fetchStripeInvoices = createWiwAsyncThunk<FetchStripeInvoicesResponse, FetchStripeInvoicesArgs>(
  'stripe/invoices/fetch',
  async (limit = 10, { rejectWithValue }) => {
    try {
      return (await Billing.url('/invoices').query({ limit }).get()) as FetchStripeInvoicesResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
