import { request } from 'shared/auth/request';
import { FETCH_FREE_TRIAL_STATUS } from 'store/action-types';

export function fetchFreeTrialStatusRequest(params = {}) {
  return {
    type: FETCH_FREE_TRIAL_STATUS.REQUEST,
    params,
  };
}

export function fetchFreeTrialStatusFailed(error) {
  return {
    type: FETCH_FREE_TRIAL_STATUS.FAILURE,
    payload: error,
  };
}

export function fetchFreeTrialStatusSuccess(payload = {}) {
  return {
    type: FETCH_FREE_TRIAL_STATUS.SUCCESS,
    payload,
  };
}

export function fetchFreeTrialStatus() {
  return dispatch => {
    dispatch(fetchFreeTrialStatusRequest());
    return request()
      .get('/subscriptions/freetrial')
      .then(response => dispatch(fetchFreeTrialStatusSuccess(response)))
      .catch(error => dispatch(fetchFreeTrialStatusFailed(error)));
  };
}
