import { forEach, pick } from 'lodash';
import type { GeocodeResult } from 'use-places-autocomplete';

import type { AccountPlace } from 'data/account/model';
import { GOOGLE_MAPS_COUNTRY_CODE } from 'shared/util/googleMaps/model';

export default (data: GeocodeResult) => {
  const result: { [key: string]: any } = {};
  forEach(data.address_components, ({ types, ...rest }) => {
    forEach(types, type => {
      result[type] = pick(rest, ['long_name', 'short_name']);
    });
  });
  return result;
};

export const isAccountPlaceFullAddress = (accountPlace: AccountPlace) => {
  // Note: accountPlace.region can be undefined for international addresses,
  // i.e. Spyros Tsiknia 2, Agrinio 301 31, Greece
  const hasValidAddressComponents = accountPlace.country && (accountPlace.region || accountPlace.locality);
  const hasRequiredAddressComponentsForAvalara =
    accountPlace.street_number &&
    accountPlace.street_name &&
    accountPlace.locality &&
    accountPlace.region &&
    accountPlace.postal_code;

  let isFullAddress = hasValidAddressComponents;

  if (
    accountPlace.country === GOOGLE_MAPS_COUNTRY_CODE.US ||
    accountPlace.country === GOOGLE_MAPS_COUNTRY_CODE.CANADA
  ) {
    isFullAddress = isFullAddress && hasRequiredAddressComponentsForAvalara;
  }

  return !!isFullAddress;
};
