import { useEffect, useState } from 'react';

const useGetInstalledRelatedApps = () => {
  const [hasAppInstalled, setHasAppInstalled] = useState(false);
  const isAppInstalled = async () => {
    if ('getInstalledRelatedApps' in navigator) {
      // @ts-ignore
      const relatedApps = await navigator.getInstalledRelatedApps();
      // if the browser API is able to connect any installed related app, we should return true
      relatedApps.forEach((_app: any) => {
        setHasAppInstalled(true);
      });
    }
  };

  useEffect(() => {
    isAppInstalled();
  }, []);

  return hasAppInstalled;
};

export default useGetInstalledRelatedApps;
