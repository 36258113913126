import { getAuthData, getAuthUser } from 'shared/auth/selectors';
import { WIW_DEBUG_COOKIE_NAME } from 'shared/dev/DevPanel';
import { checkResponseStatus } from 'shared/middleware';
import { posthogUpdate } from 'shared/middleware/posthogUpdate';
import { trackExperimentAssignments } from 'shared/middleware/trackExperimentAssignments';
import { trackRolloutAssignments } from 'shared/middleware/trackRolloutAssignments';
import { updateAccountStatusOnDisabled } from 'shared/middleware/updateAccountStatusOnDisabled';
import Environment from 'shared/util/environment';
import type { WiwState } from 'store';
import rootReducer from 'store/rootReducer';

import type { Middleware, StoreEnhancer } from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit';
import type { Scope } from '@sentry/react';
import * as Sentry from '@sentry/react';
import Cookie from 'js-cookie';

export function createStore(initialState = {}) {
  const middleware: Middleware[] = [
    checkResponseStatus,
    posthogUpdate,
    trackRolloutAssignments,
    trackExperimentAssignments,
    updateAccountStatusOnDisabled,
  ];
  const enhancers: StoreEnhancer[] = [];

  if (!Environment.isDevelopment()) {
    const sentryEnhancer = Sentry.createReduxEnhancer({
      attachReduxState: false,
      configureScopeWithState(scope: Scope, state: WiwState) {
        const authData = getAuthData(state);
        const authUser = getAuthUser(state);

        if (authUser) {
          scope.setUser({
            id: authUser.id.toString(),
            personId: authData.loginId?.toString(),
            email: authUser.email,
            accountId: authUser.account_id.toString(),
            role: authUser.role,
          });
        }
      },
    });
    enhancers.unshift(sentryEnhancer);
  }

  // biome-ignore lint/complexity/noBannedTypes: <explanation>
  let devTools: {} | false = {
    maxAge: 150,
    trace: true,
    traceLimit: 25,
    serialize: true,
  };

  // disable redux devtools in production if cookie is not set
  if (Environment.isProduction() && !Cookie.get(WIW_DEBUG_COOKIE_NAME)) {
    devTools = false;
  }

  return configureStore({
    preloadedState: initialState,
    reducer: rootReducer,
    middleware: getDefaultMiddleware => {
      return getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
      }).concat(middleware);
    },
    enhancers: getDefaultEnhancers => getDefaultEnhancers().concat(enhancers),
    devTools,
  });
}

export type WiwStore = ReturnType<typeof createStore>;
export type WiwDispatch = WiwStore['dispatch'];
