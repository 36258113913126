import { getAccountPlan } from 'data/account/selectors/accountPlan';
import { getCustomPlan } from 'data/account/selectors/customPlan';
import { getPlans } from 'data/plan/selectors/plans';
import { StripePlanID } from 'data/stripe/plans/model';
import { getStripePlans } from 'data/stripe/plans/selectors/getStripePlans';

import { createSelector } from '@reduxjs/toolkit';

export const filterPlans = (plans, customPlan, accountPlan) =>
  plans.filter(plan => {
    if ((customPlan && plan.id === customPlan.id) || (accountPlan && plan.id === accountPlan.id && plan.isLegacy())) {
      return true;
    }

    if (plan.isLegacy() || plan.isAddon()) {
      return false;
    }

    return plan.isPaid() || plan.isFreemium();
  });

export const cheapestAvailablePlan = (plans, customPlan, accountPlan) => {
  return filterPlans(plans, customPlan, accountPlan)
    .filter(plan => plan.isPaid() && !plan.isLegacy())
    .sortBy(plan => {
      return plan.price;
    })
    .first();
};

export const getAvailablePlans = createSelector(
  [getPlans, getCustomPlan, getAccountPlan],
  (plans, customPlan, accountPlan) => {
    return filterPlans(plans, customPlan, accountPlan);
  },
);

export const getAvailablePlansWithFreePlan = createSelector(
  [getAvailablePlans, getStripePlans],
  (plans, stripePlans) => {
    const freePlan = stripePlans.get(StripePlanID.REVERSE_TRIAL)?.toLegacyPlan();

    return freePlan ? plans.set(freePlan.id, freePlan) : plans;
  },
);

export const getCheapestAvailablePlan = createSelector(
  [getPlans, getCustomPlan, getAccountPlan],
  (plans, customPlan, accountPlan) => {
    return cheapestAvailablePlan(plans, customPlan, accountPlan);
  },
);
