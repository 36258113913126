import type { ControllerFieldState } from 'react-hook-form';

export default function getFieldError(fieldState: ControllerFieldState) {
  const error = fieldState.error;
  if (error !== undefined) {
    if (error.message !== '') {
      return error.message;
    }
    return true;
  }
  return false;
}
