import { shiftSlice } from 'data/shift/reducer';
import type User from 'data/user/model';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type DeleteUserParams = {
  user: User;
  deleteShifts: boolean;
  terminatedAt?: string;
};

export type DeleteUserResponse = {
  id: number;
  modified_shifts?: number[];
  deleted_shifts?: number[];
};

export default createWiwAsyncThunk<DeleteUserResponse, DeleteUserParams>(
  'user/deleteUser',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = (await Monolith.query({
        delete_shifts: params.deleteShifts,
        terminated_at: params.terminatedAt,
      }).delete(`/users/${params.user.id}`)) as DeleteUserResponse;

      if (response.modified_shifts) {
        dispatch(
          shiftSlice.actions.updateShifts({
            ids: response.modified_shifts,
            fields: { user_id: 0, published: false },
          }),
        );
      }

      if (response.deleted_shifts) {
        dispatch(
          shiftSlice.actions.deletedShifts({
            ids: response.deleted_shifts,
            fields: { user_id: params.user.id },
          }),
        );
      }

      return response;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);

// export function deleteUser(user, deleteShifts = false) {
//   return dispatch => {
//     dispatch(deleteUserRequest());
//     return request().delete(`/users/${ user.id }`, {
//       query: {
//         delete_shifts: deleteShifts,
//       },
//     })
//       .then(resp => {
//         if (resp.modified_shifts) {
//           // reference in shift slice
//           dispatch(updateShiftsInStore(resp.modified_shifts, { user_id: 0 }));
//         }
//
//         //todo: reference in notifications slice
//         dispatch(deleteNotificationUser(user));
//         return dispatch(deleteUserSuccess(user));
//       })
//       .catch(error => dispatch(deleteUserFailed(error)));
//   };
// }
