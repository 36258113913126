import BaseModel from 'data/BaseModel';
import type { UserFields } from 'data/user/model';
import { getMomentDate, rangeIntersect } from 'shared/util/time';

import { uniq } from 'lodash';
import type { Moment } from 'moment-timezone';
import moment from 'moment-timezone';
import { type ByWeekday, type Frequency, type Options, RRule } from 'rrule';

interface AvailabilityEvent {
  start: string | null;
  end: string | null;
}

export interface AvailabilityFields {
  id: number | null;
  login_id: number | null;
  user_id: UserFields['id'] | null;
  all_day: boolean;
  start_time: string | null;
  end_time: string | null;
  recurrence: string;
  recurrence_end: string | null;
  type: AvailabilityType;
  notes: string | null;
  created_at: string | null;
  updated_at: string | null;
  events: AvailabilityEvent[];
  // the below keys are needed for for state tracking
  repeats: boolean;
  repeat_ends: boolean;
  every: number;
}

enum AvailabilityType {
  Unavailable = 1,
  Available = 2,
}

enum AvailabilityRepeat {
  Yearly = 0,
  Monthly = 1,
  Weekly = 2,
  BiWeekly = 7,
  Daily = 3,
  Hourly = 4,
  Minutely = 5,
  Secondly = 6,
}

type ModifiedRRuleOptions = Override<
  Options,
  {
    freq: Frequency | AvailabilityRepeat;
    until: Date | string | null;
  }
>;

class Availability extends BaseModel<AvailabilityFields>({
  id: null,
  login_id: null,
  user_id: null,
  all_day: false,
  start_time: null,
  end_time: null,
  recurrence: '',
  recurrence_end: null,
  type: AvailabilityType.Unavailable,
  notes: '',
  created_at: null,
  updated_at: null,
  events: [],
  // the below keys are needed for for state tracking
  repeats: false,
  repeat_ends: false,
  every: 2,
}) {
  static UNAVAILABLE = AvailabilityType.Unavailable as const;
  static AVAILABLE = AvailabilityType.Available as const;

  static REPEATS = {
    YEARLY: AvailabilityRepeat.Yearly,
    MONTHLY: AvailabilityRepeat.Monthly,
    WEEKLY: AvailabilityRepeat.Weekly,
    BIWEEKLY: AvailabilityRepeat.BiWeekly,
    DAILY: AvailabilityRepeat.Daily,
    HOURLY: AvailabilityRepeat.Hourly,
    MINUTELY: AvailabilityRepeat.Minutely,
    SECONDLY: AvailabilityRepeat.Secondly,
  } as const;

  static weekdays: { [key: number]: string } = {
    0: 'Monday',
    1: 'Tuesday',
    2: 'Wednesday',
    3: 'Thursday',
    4: 'Friday',
    5: 'Saturday',
    6: 'Sunday',
  };

  /**
   * Between availability V1->V2 and availability being an "MVP',
   * several properties have to be manually transformed from API
   */
  static fromData(data: AvailabilityFields): [AvailabilityFields['id'], Availability] {
    const normalized = {
      ...data,
      repeats: !!data.recurrence,
      repeat_ends: !!data.recurrence_end,
    };

    return [data.id, new Availability(normalized)];
  }

  static createFromDate(userId: UserFields['id'], date: Moment | null = null) {
    if (date === null) {
      date = moment();
    }

    return new Availability({
      user_id: userId,
      start_time: date.hours(9).minutes(0).toISOString(),
      end_time: date.clone().hours(17).minutes(0).toISOString(),
    });
  }

  weekdays = (): string[] => {
    // TYPE HACK ALERT. Shouldn't change any behavior ... not sure why this call doesn't
    // pass an argument for `today` when it appears to be necessary.
    const days = this.recurrenceDays(undefined);

    return uniq(days.map(day => Availability.weekdays[day]));
  };

  /**
   * Gets the start times of occurrences that fall within the specified range, optionally
   * including those that overlap the range but are not completely contained.
   *
   * @param start     The start of the range to search
   * @param end       The end of the range to search
   * @param inclusive Whether to include those occurrences that overlap
   *                  the range but are not completely contained.
   *
   * @return An array of start times of availability occurrences.
   */
  getEventsBetween(start: Moment | Date | string, end: Moment | Date | string, inclusive = true) {
    start = getMomentDate(start);
    end = getMomentDate(end);

    let events = this.events;

    // Fill out events for non-recurring availability only
    if (!this.recurrence && !events.length) {
      events = [{ start: this.start_time, end: this.end_time }];
    }

    return events
      .map(event => ({
        start: getMomentDate(event.start),
        end: this.normalizeEndDST(getMomentDate(event.start), getMomentDate(event.end)),
      }))
      .filter(({ start: eventStart, end: eventEnd }) => {
        if (inclusive) {
          return eventStart < end && eventEnd > start;
        }

        return eventStart >= start && eventEnd <= end;
      });
  }

  appearsOnDay(date: Moment) {
    const occurrences = this.getEventsBetween(date.clone().startOf('day'), date.clone().endOf('day'));

    return occurrences.length > 0;
  }

  getNumOccurrencesOnDay(date: Moment) {
    return this.getEventsBetween(date.clone().startOf('day'), date.clone().endOf('day')).length;
  }

  get eventRRuleParser() {
    if (!this.recurrence) {
      return {
        rrule: RRule.fromString(''),
      };
    }
    const { recurrenceParts, exclusions } = this.stripExdates();

    return {
      rrule: RRule.fromString(recurrenceParts.join(';') || ''),
      exclusions,
    };
  }

  get eventHumanTimes() {
    const startTime = moment(this.start_time).format('h:mm A');
    const endTime = moment(this.end_time).format('h:mm A');
    return `${startTime} - ${endTime}`;
  }

  get humanRecurrence() {
    switch (this.recurrenceLabel) {
      case AvailabilityRepeat.Weekly:
        return 'Weekly';
      case AvailabilityRepeat.BiWeekly:
        return 'Bi-Weekly';
      case AvailabilityRepeat.Daily:
        return 'Daily';
      default:
        // I chose 'Weekly' as the default because that is the default
        // repeat type from `recurrenceLabel`.
        return 'Weekly';
    }
  }

  get recurrenceLabel(): AvailabilityRepeat {
    const recurrences = this.eventRRuleParser.rrule.origOptions;
    const { freq, interval } = recurrences;
    if (!freq && this.every) {
      return this.every;
    }

    switch (freq) {
      case RRule.WEEKLY:
        if (interval) {
          return AvailabilityRepeat.BiWeekly;
        }

        return AvailabilityRepeat.Weekly;
      case RRule.DAILY:
        return AvailabilityRepeat.Daily;
      default:
        return AvailabilityRepeat.Weekly;
    }
  }

  stripExdates() {
    if (!this.recurrence) {
      return {
        recurrenceParts: [],
        exclusions: [],
      };
    }

    let exclusions: string[] = [];
    const recurrenceParts = this.recurrence.split(';').filter(part => {
      if (part.includes('EXDATE')) {
        exclusions = part.split(/[=,]/g);
        exclusions.shift();
      }

      return !part.includes('EXDATE');
    });

    return {
      recurrenceParts,
      exclusions,
    };
  }

  // TODO(types) the `today` propery has a certain shape, but it's not defined
  // in a TS file anywhere to reference
  recurrenceDays(today: any): number[] {
    if (!this.repeats) {
      return [];
    }

    const recurrences = this.eventRRuleParser.rrule.origOptions;
    const { byweekday } = recurrences;

    if (!byweekday) {
      // return current day by default
      return [today.weekDay === 0 ? 6 : today.weekDay - 1];
    }

    // this is the only way to get the types to work ...
    return (byweekday as ByWeekday[]).map(weekday => (weekday as any).weekday);
  }

  get typeLabel() {
    switch (this.type) {
      case AvailabilityType.Unavailable:
        return 'Unavailable';
      case AvailabilityType.Available:
        return 'Available';
      default:
        return 'Unknown';
    }
  }

  isAvailable() {
    return this.type === AvailabilityType.Available;
  }

  isUnavailable() {
    return this.type === AvailabilityType.Unavailable;
  }

  buildRRule(args: Partial<ModifiedRRuleOptions> = {}) {
    const origOptions = this.eventRRuleParser.rrule.origOptions;
    let ruleVal = {
      ...args,
    };

    if (this.repeats) {
      ruleVal.freq = args.freq || origOptions.freq || AvailabilityRepeat.Daily;
    }

    if (args.freq === AvailabilityRepeat.BiWeekly) {
      ruleVal = {
        ...ruleVal,
        freq: AvailabilityRepeat.Weekly,
        interval: 2,
      };
    }

    if (args.freq === AvailabilityRepeat.Weekly) {
      delete origOptions.interval;
    }

    ruleVal.until = null;

    if (this.repeats && (args.until || origOptions.until) && !(origOptions.until && !args.until)) {
      ruleVal.until = moment(args.until).toDate();
    }

    // We've apparently extended RRule's `freq` property with a biweekly frequency.
    // @ts-ignore
    const rruleString = new RRule({ ...origOptions, ...ruleVal }).toString().replace('RRULE:', '');
    const { exclusions } = this.stripExdates();

    if (!exclusions.length) {
      return rruleString;
    }

    return [rruleString, `EXDATE=${exclusions.join(',')}`].join(';');
  }

  updateRRule(args: Partial<ModifiedRRuleOptions>) {
    return this.set('recurrence', this.buildRRule(args));
  }

  getEventBoundaries(event: Moment) {
    const availabilityStart = this.date('start_time')!;
    const availabilityEnd = this.date('end_time')!;

    const lengthMs = availabilityEnd.diff(availabilityStart);

    const start = getMomentDate(event).set({
      hour: availabilityStart.hour(),
      minute: availabilityStart.minute(),
      second: availabilityStart.second(),
    });

    const end = start.clone().add(lengthMs, 'ms');

    return { start, end };
  }

  /**
   * Returns the adjusted range of an event to make checking if it's crossed midnight easier
   * the result is a range cut to the bounds of the current day
   *             |-----------Event-----------|
   * |---------Day----------|
   *             |--Result--|
   *
   * Given the diagram above this method would return the event's start time as it is and the end at the end of the day
   */
  trimToDay(
    date: Moment,
    recurrence = 0,
    timezone = '',
  ): { adjustedRange: { start: Moment; end: Moment }; adjustedDuration: number; crossedDayBoundaries: boolean } {
    let availabilityStart = this.date('start_time')!;
    let availabilityEnd = this.date('end_time')!;

    if (timezone !== '') {
      availabilityStart = availabilityStart.tz(timezone);
      availabilityEnd = availabilityEnd.tz(timezone);
    }

    if (this.repeats) {
      const currentEventRange = this.getEventsBetween(date.clone().startOf('day'), date.clone().endOf('day'));
      if (currentEventRange.length) {
        availabilityStart = currentEventRange[recurrence].start;
        availabilityEnd = currentEventRange[recurrence].end;

        if (timezone !== '') {
          availabilityStart = currentEventRange[recurrence].start.tz(timezone);
          availabilityEnd = currentEventRange[recurrence].end.tz(timezone);
        }
      }
    }

    availabilityEnd = this.normalizeEndDST(availabilityStart, availabilityEnd);

    const adjustedRange: { start: Moment; end: Moment } = rangeIntersect(
      date.clone().startOf('day'),
      date.clone().endOf('day'),
      availabilityStart,
      availabilityEnd,
    ) as { start: Moment; end: Moment };

    const originalDuration = availabilityEnd.diff(availabilityStart);
    const adjustedDuration = adjustedRange.end.diff(adjustedRange.start);

    const crossedDayBoundaries = adjustedDuration > 0 && adjustedDuration < originalDuration;

    return {
      adjustedRange,
      adjustedDuration,
      crossedDayBoundaries,
    };
  }

  normalizeEndDST(start: Moment, end: Moment): Moment {
    if (this.all_day) {
      return start.clone().startOf('day').add(1, 'day').subtract(1, 'second');
    }

    return end;
  }
}

export default Availability;
