import { SavedReport } from 'data/reporting/models/SavedReport';
import { SavedReportsState } from 'data/reporting/state/SavedReportsState';
import { DELETE_SAVED_REPORT, LIST_SAVED_REPORTS, UPDATE_SAVED_REPORT } from 'store/action-types';

import type { AnyAction } from '@reduxjs/toolkit';
import { Map } from 'immutable';

export const savedReports = (state = new SavedReportsState(), action: AnyAction) => {
  switch (action.type) {
    case LIST_SAVED_REPORTS.REQUEST:
      return state.merge({
        loading: true,
        receivedAt: 0,
        loaded: false,
      });
    case LIST_SAVED_REPORTS.SUCCESS:
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        receivedAt: Date.now(),
        items: Map<SavedReport['id'], SavedReport>(
          action.payload.map((report: SavedReport) => {
            const preserveLoadedDefinition = state.items.get(report.id)?.definition;
            return [
              report.id,
              new SavedReport({
                ...report,
                definition: report.definition ? report.definition : preserveLoadedDefinition,
              }),
            ];
          }),
        ),
      });
    case LIST_SAVED_REPORTS.FAILURE:
      return state.merge({
        loaded: false,
        loading: false,
        error: action.payload,
      });
    case UPDATE_SAVED_REPORT.REQUEST:
      return state.merge({
        loading: true,
        receivedAt: 0,
        loaded: false,
      });
    case UPDATE_SAVED_REPORT.SUCCESS:
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        receivedAt: Date.now(),
        items: state.items.set(action.payload.id, new SavedReport(action.payload)),
      });
    case UPDATE_SAVED_REPORT.FAILURE:
      return state.merge({
        loaded: false,
        loading: false,
        error: action.payload,
      });
    case DELETE_SAVED_REPORT.REQUEST:
      return state.merge({
        loading: true,
        receivedAt: 0,
        loaded: false,
      });
    case DELETE_SAVED_REPORT.SUCCESS:
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        items: state.items.delete(action.payload),
      });
    case DELETE_SAVED_REPORT.FAILURE:
      return state.merge({
        loaded: false,
        loading: false,
        error: action.payload,
      });
    default:
      return state;
  }
};
