import type Location from 'data/location/model';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type DeletedLocationResponse = {
  success: boolean;
  deleted_location: number;
};

export const deleteLocation = createWiwAsyncThunk<DeletedLocationResponse, Location>(
  'location/delete',
  async (location, { rejectWithValue }) => {
    try {
      return await Monolith.url(`/locations/${location.id}`).delete();
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);

export const deleteLocations = createWiwAsyncThunk<DeletedLocationResponse[], Location[]>(
  'location/deleteMulti',
  async (locations, { rejectWithValue }) => {
    try {
      return await Monolith.url('/locations')
        .query({
          ids: locations.map(location => location.id),
        })
        .delete();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
