import PropTypes from 'prop-types';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import ReactSelect, { components } from 'react-select';
import Async from 'react-select/async';
import AsyncCreatable from 'react-select/async-creatable';
import Creatable from 'react-select/creatable';

import SpriteIcon from 'shared/ui/SpriteIcon';
import UpsellFeatureLock from 'shared/upsell/UpsellFeatureLock';

import classnames from 'classnames';
import { Iterable } from 'immutable';
import { merge } from 'lodash';

const SelectType = {
  select: ReactSelect,
  creatable: Creatable,
  async: Async,
  asyncCreatable: AsyncCreatable,
};

// I will mail whoever converts this to TS a cookie -- Clark
const Select = forwardRef((props, ref) => {
  const selectRef = useRef(null);
  useImperativeHandle(ref, () => selectRef.current?.controlRef);
  const getValue = () => {
    const { value, options } = props;
    let selectOptions = options;

    if (Iterable.isIterable(options)) {
      selectOptions = options.toArray();
    }

    if (value === null || typeof value === 'undefined') {
      return '';
    }

    if (Array.isArray(value)) {
      return value.map(value =>
        selectOptions.find(option => {
          const optionValue = props.getOptionValue(option);
          // biome-ignore lint/suspicious/noDoubleEquals: has to be insensitive
          return optionValue == value || optionValue == value.value;
        }),
      );
    }

    if (typeof value === 'object') {
      return value;
    }

    // biome-ignore lint/suspicious/noDoubleEquals: has to be insensitive
    return props.options.find(option => option.value == value);
  };

  const renderOption = optionProps => {
    const newProps = {
      className: optionProps.data.className ? optionProps.data.className : null,
      ...optionProps,
    };

    if (optionProps.data.upsell && optionProps.data.upsellDialog) {
      return (
        <UpsellFeatureLock upsellDialog={optionProps.data.upsellDialog}>
          <components.Option {...newProps} />
        </UpsellFeatureLock>
      );
    }

    if (props.renderOptionAddOns) {
      return (
        <components.Option {...newProps}>
          {optionProps.label}
          {optionProps.data.addOn && <span className="pull-right">{optionProps.data.addOn}</span>}
        </components.Option>
      );
    }
    return <components.Option {...newProps} />;
  };

  const renderClearIndicator = props => {
    return (
      <components.ClearIndicator {...props}>
        <SpriteIcon icon="close" />
      </components.ClearIndicator>
    );
  };

  const SelectComponent = SelectType[props.type];
  const options = Iterable.isIterable(props.options) ? props.options.toArray() : props.options;

  const selectProps = { ...props };
  const dialogKit = [...document.querySelectorAll('.dialog-kit')].pop();

  if (dialogKit) {
    selectProps.menuPortalTarget = document.body;
    selectProps.styles = { ...selectProps.styles, menuPortal: base => ({ ...base, zIndex: 9999 }) };
  }
  return (
    <SelectComponent
      {...selectProps}
      ref={selectRef}
      options={options}
      components={merge(
        {
          Option: renderOption,
          ClearIndicator: renderClearIndicator,
        },
        props.components,
      )}
      classNamePrefix="Select"
      className={classnames('Select', props.className, {
        'Select--error': props.error,
      })}
      value={getValue()}
    />
  );
});

Select.propTypes = {
  ...ReactSelect.propTypes,
  type: PropTypes.string,
  selectRef: PropTypes.any,
  renderOptionAddOns: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  autoFocus: PropTypes.bool,
};

Select.defaultProps = {
  getOptionValue: option => option.value,
  type: 'select',
  renderOptionAddOns: false,
  error: false,
  autoFocus: false,
};

Select.displayName = 'Select';

/**
 *
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<any> & React.RefAttributes<unknown>>}
 */
export default Select;
