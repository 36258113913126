import { Redirect, useLocation } from 'react-router-dom';

import { authorized } from 'shared/auth/selectors';
import { useWiwSelector } from 'store';

export default function RedirectParameterHandler() {
  const location = useLocation();
  const isAuthed = useWiwSelector(authorized);
  const query = new URLSearchParams(location.search);

  if (isAuthed && query.has('redirect')) {
    return <Redirect to={query.get('redirect')} />;
  }

  return null;
}
