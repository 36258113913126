import type { PolicyResponse } from 'data/timeOffAccrual/types/common';
import type { DataResponse } from 'data/types';
import Scheduling from 'shared/api/scheduling';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

export const deletePolicyRate = createWiwAsyncThunk<DataResponse<PolicyResponse>, string>(
  'timeOff/policies/deletePolicyRate',
  async (policyRateId, { rejectWithValue }) => {
    try {
      return await Scheduling.delete(`/timeoff/policies/rates/${policyRateId}`);
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
