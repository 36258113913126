import type { ShiftFields } from 'data/shift/model';
import type { ShiftChainFields } from 'data/shiftChain/model';
import { receiveShiftChain } from 'data/shiftChain/reducer';
import type { QueryString } from 'data/types';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';
import { FETCH_SHIFTS_MERGE } from 'store/action-types';

export function receiveShiftsMerge(userID: number, payload = []) {
  return {
    type: FETCH_SHIFTS_MERGE,
    items: payload,
    receivedAt: Date.now(),
    userID,
  };
}

export interface ShiftResponse {
  shifts: ShiftFields[];
  shiftchains?: ShiftChainFields[];
}

export const fetchShifts = createWiwAsyncThunk<ShiftResponse, QueryString>(
  'shift/fetch',
  async (params = {}, { rejectWithValue }) => {
    try {
      return (await Monolith.query(params).get('/shifts')) as ShiftResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);

export const fetchShiftsMerge = createWiwAsyncThunk<ShiftResponse, QueryString>(
  'shift/merge',
  async (params = {}, { rejectWithValue }) => {
    try {
      return (await Monolith.query(params).get('/shifts')) as ShiftResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);

type FetchByShiftIdResponse = {
  shift: ShiftFields;
  shiftchains?: ShiftChainFields[];
};

export const fetchShiftById = createWiwAsyncThunk<FetchByShiftIdResponse, number>(
  'shift/getById',
  async (shiftId, { rejectWithValue, dispatch }) => {
    try {
      const response = (await Monolith.get(`/shifts/${shiftId}`)) as FetchByShiftIdResponse;

      if (response.shiftchains) {
        dispatch(receiveShiftChain(response.shiftchains));
      }

      return response;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);

export function fetchShiftsIfNeeded(params = {}) {
  return (dispatch: any) => {
    return dispatch(fetchShifts(params));
  };
}
