import { createSlice } from '@reduxjs/toolkit';
import { Record } from 'immutable';

import { authenticateWorkchat } from 'workchat/v2/store/auth/authActions';

export interface WorkchatAuthState {
  loading: boolean;
  loaded: boolean;
  token: string | null;
  receivedAt: number | null;
  error: any;
}

export class WorkchatAuthInitialState extends Record<WorkchatAuthState>({
  loading: false,
  loaded: false,
  token: null,
  receivedAt: null,
  error: null,
}) {}

export const workchatAuthSlice = createSlice({
  name: 'workchatv2/auth',
  initialState: new WorkchatAuthInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(authenticateWorkchat.pending, (state, _action) => {
      return state.set('loading', true);
    });
    builder.addCase(authenticateWorkchat.fulfilled, (state, { payload }) => {
      return state.withMutations(map => {
        map.set('token', payload.token);
        map.set('loading', false);
        map.set('loaded', true);
        map.set('receivedAt', Date.now());
      });
    });
  },
});

export default workchatAuthSlice.reducer;
