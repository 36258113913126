import { useMemo } from 'react';
import { getAuthUser } from 'shared/auth/selectors';
import Button from 'shared/ui/Button';
import { useWiwDispatch, useWiwSelector } from 'store';
import { openSendMessageDialog } from 'teamtxt/dialogs';
import { hideWorkchat } from 'workchat/actions';
import DisconnectedBanner from 'workchat/components/DisconnectedBanner';
import { getConnected } from 'workchat/selectors/getConnected';

export type HeaderButton = {
  action: () => void;
  className?: string;
  disabled?: boolean;
  label: (string | JSX.Element)[] | string | JSX.Element;
  title?: string;
  side: 'left' | 'right';
  color?: 'secondary' | 'primary';
  circle?: boolean;
};

export type HeaderProps = {
  showBroadcast?: boolean;
  buttons?: HeaderButton[];
  children?: React.ReactNode;
};

export default function Header({ showBroadcast, buttons, children }: HeaderProps) {
  const dispatch = useWiwDispatch();
  const authedUser = useWiwSelector(getAuthUser);
  const connected = useWiwSelector(getConnected);
  const canBroadcast = authedUser.canSupervise();

  const onTeamTxtClick = () => {
    dispatch(hideWorkchat());
    dispatch(openSendMessageDialog());
  };

  const leftButtons = useMemo(() => {
    return buttons?.filter(button => button.side === 'left');
  }, [buttons]);

  const rightButtons = useMemo(() => {
    return buttons?.filter(button => button.side === 'right');
  }, [buttons]);

  const createButton = useMemo(() => {
    return (button: HeaderButton, idx: number) => (
      <Button
        type="button"
        key={`head-button-${button.title}-${idx}`}
        title={button.title}
        onClick={button.action}
        className={button.className}
        disabled={button.disabled || false}
        size="sm"
        color={button.color}
        circle={button.circle}
      >
        {button.label}
      </Button>
    );
  }, [buttons, connected]);

  if (!(canBroadcast && showBroadcast) && !buttons?.length && !children) {
    return null;
  }

  return (
    <div className="row workchat-header">
      <div className="col-12">
        <div className="row justify-content-between align-items-center header">
          <div className="col-3">
            <div className="row no-gutters float-left align-items-center">
              {canBroadcast && showBroadcast && (
                <Button type="button" size="sm" onClick={onTeamTxtClick} color="secondary" className="btn-left-action">
                  Send Email/TeamTXT
                </Button>
              )}
              {leftButtons?.map((button, idx) => createButton(button, idx))}
            </div>
          </div>
          {children ? <h2 className="col-6 header">{children}</h2> : <div className="filler" />}
          <div className="col-3">
            <div className="row no-gutters float-right align-items-center">
              {rightButtons?.map((button, idx) => createButton(button, idx))}
            </div>
          </div>
        </div>
      </div>
      <div className="col-12">
        <DisconnectedBanner />
      </div>
    </div>
  );
}
