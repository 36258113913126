import PropTypes from 'prop-types';
import { Component, createRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import classnames from 'classnames';

import 'notifications/styles/NotifyItem.scss';

export const CONTAINER_CLASSNAME = 'notify-container';

export class NotifyItem extends Component {
  notifyRef = createRef();

  state = {
    actionsFocus: false,
  };

  static propTypes = {
    title: PropTypes.string,
    description: PropTypes.node,
    tag: PropTypes.object,
    avatar: PropTypes.element,
    actions: PropTypes.arrayOf(PropTypes.element),
    click: PropTypes.func,
    deleted: PropTypes.bool,
    remove: PropTypes.func,
    icon: PropTypes.node,
  };

  static defaultProps = {
    deleted: false,
    remove: () => null,
  };

  setFocus = () => {
    this.setState({ actionsFocus: true });
    if (this.notifyRef?.current) {
      let currentNode = this.notifyRef.current;
      while (currentNode) {
        if (currentNode.scrollLeft !== 0) {
          currentNode.scrollLeft = 0;
        }
        if (currentNode.classList.contains(CONTAINER_CLASSNAME)) {
          break;
        }
        currentNode = currentNode.parentNode;
      }
    }
  };

  clearFocus = () => {
    this.setState({ actionsFocus: false });
  };

  renderActions() {
    if (!this.props.actions) {
      return;
    }
    return (
      <div
        onFocus={this.setFocus}
        onBlur={this.clearFocus}
        className={classnames('action-box', { 'focus-within': this.state.actionsFocus })}
      >
        {this.props.actions}
      </div>
    );
  }

  submitAction = e => {
    if (this.notifyRef?.current && this.notifyRef.current === e.target) {
      if (e.key === 'Enter' && this.props.click) {
        this.props.click(e);
      }
    }
  };

  clickAttributes() {
    if (this.props.click) {
      return {
        onClick: this.props.click,
        tabIndex: 0,
        onKeyPress: this.submitAction,
      };
    }
  }

  getEntryClasses() {
    return classnames('notify-entry', { selection: !!this.props.click });
  }

  render() {
    return (
      <CSSTransition
        in={!this.props.deleted}
        onExited={this.props.remove}
        classNames="notify-entry"
        timeout={{ enter: 300, exit: 300 }}
      >
        <div ref={this.notifyRef} className={this.getEntryClasses()} {...this.clickAttributes()}>
          <div className="badge">{this.props.icon || this.props.avatar}</div>
          <div className="content">
            <strong>{this.props.title}</strong>
            {this.props.description}
            {this.props.tag && (
              <div className="status">
                <span className={`notif-status ${this.props.tag.style}`}>{this.props.tag.name}</span>
              </div>
            )}
          </div>
          {this.renderActions()}
        </div>
      </CSSTransition>
    );
  }
}
