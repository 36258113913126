import { getMasterAccount } from 'data/account/selectors/masterAccount';
import { getPlans } from 'data/plan/selectors/plans';
import { getAuthAccount } from 'shared/auth/selectors';

import { createSelector } from '@reduxjs/toolkit';

const filterMasterPlan = (plans, account, masterAccount) => {
  if (plans && account && account.isChild()) {
    if (masterAccount) {
      return plans.get(masterAccount.plan_id);
    }
    return plans.get(account.master_plan_id);
  }

  return null;
};

const getPropsOrStatePlans = (state, props) => {
  return props?.plans ? props.plans : getPlans(state, props);
};

const getPropsOrStateMasterAccount = (state, props) => {
  return props?.masterAccount ? props.masterAccount : getMasterAccount(state);
};

const getPropsOrStateAccount = (state, props) => {
  return props?.account ? props.account : getAuthAccount(state);
};

export const getMasterPlan = createSelector(
  [getPropsOrStatePlans, getPropsOrStateAccount, getPropsOrStateMasterAccount],
  filterMasterPlan,
);
