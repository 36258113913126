import { SalariedSettingsSchema } from 'data/salariedSettings/model';
import Platform from 'shared/api/platform';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

import { ZodError, z } from 'zod';

export const fetchSalariedSettings = createWiwAsyncThunk<SalariedSettingResponse, Params>(
  'salariedSettings/fetch',
  async ({ userId, ...query }, { rejectWithValue }) => {
    try {
      const response: unknown = await Platform.url(`/users/${userId}/salaried/settings`).query(query).get();
      return FetchSalariedSettingsResponse.parse(response);
    } catch (error) {
      if (error instanceof ZodError) {
        return rejectWithValue(error);
      }

      return rejectWithValue(parseAPIError(error));
    }
  },
);

type Params = {
  userId: string | number;
  effectiveOn?: string;
};

const FetchSalariedSettingsResponse = z.object({
  settings: SalariedSettingsSchema,
});

export type SalariedSettingResponse = z.infer<typeof FetchSalariedSettingsResponse>;
