export const ACTION_CLICKED_DATE_NAV_CALENDAR = 'clickedDateNavCalendar';
export const ACTION_CLICKED_DATE_NAV_NEXT = 'clickedDateNavNext';
export const ACTION_CLICKED_DATE_NAV_PREVIOUS = 'clickedDateNavPrevious';
export const ACTION_CLICKED_DATE_NAV_TODAY = 'clickedDateNavToday';
export const ACTION_CLICKED_FILTER_DAY_WEEK = 'clickedFilterDayWeek';
export const ACTION_CLICKED_FILTER_EMPTY_SHIFT = 'clickedFilterEmptyShift';
export const ACTION_CLICKED_FILTER_EMPTY_HOURS = 'clickedFilterEmptyHours';
export const ACTION_CLICKED_FILTER_SHIFT_BY = 'clickedFilterShiftBy';
export const ACTION_CLICKED_FILTER_HOURS_BY = 'clickedFilterHoursBy';
export const ACTION_CLICKED_SHIFT_TYPE = 'clickedShiftType';
export const ACTION_CLICKED_SORT_SHIFT = 'clickedSortShift';
export const ACTION_CLICKED_SORT_HOURS = 'clickedSortHours';
export const ACTION_OPENED_CONFIGURE_DASHBOARD = 'openedConfigureDashboard';
export const ACTION_CLOSED_CONFIGURE_DASHBOARD = 'closedConfigureDashboard';
export const ACTION_SAVED_CONFIGURE_DASHBOARD = 'savedConfigureDashboard';
export const CONFIGURE_DASHBOARD_CARD_COMPONENT = 'configureDashboard';

export const Actions = {
  CLICKED_DATE_NAV_CALENDAR: ACTION_CLICKED_DATE_NAV_CALENDAR,
  CLICKED_DATE_NAV_NEXT: ACTION_CLICKED_DATE_NAV_NEXT,
  CLICKED_DATE_NAV_PREVIOUS: ACTION_CLICKED_DATE_NAV_PREVIOUS,
  CLICKED_DATE_NAV_TODAY: ACTION_CLICKED_DATE_NAV_TODAY,
  CLICKED_FILTER_DAY_WEEK: ACTION_CLICKED_FILTER_DAY_WEEK,
  CLICKED_FILTER_EMPTY_SHIFT: ACTION_CLICKED_FILTER_EMPTY_SHIFT,
  CLICKED_FILTER_EMPTY_HOURS: ACTION_CLICKED_FILTER_EMPTY_HOURS,
  CLICKED_FILTER_SHIFT_BY: ACTION_CLICKED_FILTER_SHIFT_BY,
  CLICKED_FILTER_HOURS_BY: ACTION_CLICKED_FILTER_HOURS_BY,
  CLICKED_SHIFT_TYPE: ACTION_CLICKED_SHIFT_TYPE,
  CLICKED_SORT_SHIFT: ACTION_CLICKED_SORT_SHIFT,
  CLICKED_SORT_HOURS: ACTION_CLICKED_SORT_HOURS,
  OPENED_CONFIGURE_DASHBOARD: ACTION_OPENED_CONFIGURE_DASHBOARD,
  CLOSED_CONFIGURE_DASHBOARD: ACTION_CLOSED_CONFIGURE_DASHBOARD,
  SAVED_CONFIGURE_DASHBOARD: ACTION_SAVED_CONFIGURE_DASHBOARD,
};

export enum DayOrWeek {
  DAY = 'day',
  WEEK = 'week',
}

// These enum values are used as checkbox IDs/values and label display names.
// The strings are manipulated such that 'filter-name-here' becomes 'Name Here" when displayed.
export enum NoticeFilterType {
  WrongLocation = 'filter-wrong-location',
  ForgotClockOut = 'filter-forgot-clock-out',
  NotScheduled = 'filter-not-scheduled',
  NoShow = 'filter-no-show',
  Absent = 'filter-absent',
  EarlyClockIn = 'filter-early-clock-in',
  LateClockIn = 'filter-late-clock-in',
  EarlyClockOut = 'filter-early-clock-out',
  LateClockOut = 'filter-late-clock-out',
  MissedClockIn = 'filter-missed-clock-in',
}

// This is a constraint on the map type to ensure only valid enum values are acceptable keys.
// Wrapping them in Partial<...> allows the inclusion of any given key to be considered optional.
export type AttendanceNoticesFilters = Partial<Record<NoticeFilterType, boolean>>;

// DefaultNoticeFilters serves as the default state, inclusion, and display order of Notice Filters
export const DefaultNoticeFilters: AttendanceNoticesFilters = {
  [NoticeFilterType.NoShow]: false,
  [NoticeFilterType.EarlyClockIn]: false,
  [NoticeFilterType.LateClockIn]: false,
  [NoticeFilterType.EarlyClockOut]: false,
  [NoticeFilterType.LateClockOut]: false,
  [NoticeFilterType.ForgotClockOut]: false,
  [NoticeFilterType.NotScheduled]: false,
  [NoticeFilterType.WrongLocation]: false,
  [NoticeFilterType.Absent]: false,
  // TODO: Expand to support Absent and Missed Clock In notices when introduced
};
