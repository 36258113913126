import Environment from 'shared/util/environment';

import { Map, is } from 'immutable';
import { isEqual, memoize } from 'lodash';

/**
 * Creates a function that preserves references when getting the results
 * of `func`. In other words, if `func` returns the same value twice in
 * a row, this function will return the exact same value both times, down
 * to the reference.
 *
 * The comparison is done using Immutable.is, so even two different
 * immutable objects with the same structure will have references preserved.
 * If opts.deep is true, the comparison will be even deeper, using lodash.isEqual,
 * meaning that even normal JS objects will be compared by value.
 *
 * This function is designed to be used in selectors so that
 * shouldComponentUpdate can be much faster at the component level. For example,
 * say some component gets a huge map of shifts from a selector. If the two
 * maps are actually the same, down to the reference, then it's very quick
 * to determine that nothing has changed.
 *
 * @param  function func The function to wrap
 * @return function      A wrapped version of `func`
 */
export function preserveReferences<P extends any[], R>(
  func: (...args: P) => R,
  opts: {
    deep?: boolean;
  } = {},
): (...args: P) => R {
  let memo: R;

  // This is separately defined to make profiles easier to read.
  function equal(a: any, b: any) {
    return (opts.deep && isEqual(a, b)) || is(a, b);
  }

  return function preserveReferencesWrapper(...args: P): R {
    const result = func(...args);

    if (equal(result, memo)) {
      return memo;
    }

    memo = result;

    return result;
  };
}

/**
 * This function is much like `preserveReferences`, but it is designed specifically
 * for maps of immutable objects. This function can only be used on Maps, and it
 * preserves references for each of the map's keys, rather than for the entire map.
 *
 * This is designed for use in selectors that group resources. For example, if you
 * have a selector that groups shifts by user, you might use this function to ensure
 * that each schedule row receives the exact same map of shifts if only one user's
 * shifts have changed.
 *
 * `preserveReferences` alone would not work in that example, because changing just
 * one user's shifts would result in a completely different overall Map.
 *
 * @param  function func The function to wrap
 * @return function      A wrapped version of `func`
 */
export function preserveReferencesForKeys<P extends any[], R extends Map<any, any>>(
  func: (...args: P) => R,
): (...args: P) => R {
  let memo: any = Map();

  return function preserveReferencesForKeysWrapper(...args: P): R {
    const result = func(...args);

    if (!Map.isMap(result)) {
      return result;
    }

    const memoized = result.withMutations(map => {
      for (const key of map.keys()) {
        if (map.get(key).equals(memo.get(key))) {
          map.set(key, memo.get(key));
        }
      }
    });

    memo = memoized;

    return memoized as R;
  };
}

export function auditedMemoize<P extends any[], R>(name: string, func: (...args: P) => R): (...args: P) => R {
  if (!Environment.isDevelopment()) {
    return memoize(func);
  }

  const memo = memoize(func);
  let numCalls = 0;

  return function auditedMemoizeWrapper(...args: P): R {
    numCalls++;
    const result = memo(...args);
    const cacheSize = (memo.cache as any).size;

    if (_shouldWarn(numCalls, [50, 100, 200, 500], 1000) && cacheSize / numCalls > 0.5) {
      console.warn(
        new Error(
          `An instance of the memoized function '${name}' is missing its cache over 50% of the time. (Out of ${numCalls} evaluations, ${cacheSize} have missed the cache, and only ${
            numCalls - cacheSize
          } have hit, for a hit rate of ${Math.round((cacheSize / numCalls) * 100)}%.`,
        ),
      );
    }

    return result;
  };
}

export function printArgChanges<P extends any[], R>(func: (...args: P) => R) {
  let lastArgs: any[] | undefined;
  return function differencePrinter(...args: P): R {
    if (lastArgs) {
      const diff = [];
      for (let i = 0; i < lastArgs.length; i++) {
        if (lastArgs[i] !== args[i]) {
          diff.push({
            index: i,
            oldValue: lastArgs[i],
            newValue: args[i],
          });
        }
      }
      console.log(...diff);
    }

    lastArgs = args;
    return func(...args);
  };
}

export function _shouldWarn(n: number, thresholds: number[], every: number) {
  if (n === 0) {
    return false;
  }

  for (const threshold of thresholds) {
    if (n === threshold) {
      return true;
    }
  }

  return n % every === 0;
}
