import { lazy } from 'react';

import { closeDialog, openDialog } from 'dialogs';

const DocumentsAccessDialog = lazy(() => import('documents/dialogs/DocumentsAccessDialog'));

// Identifiers for each dialog
export const DOCUMENTS_ACCESS_DIALOG = 'DOCUMENTS_ACCESS_DIALOG';

// Actions for opening/closing dialogs
export const openDocumentsAccessDialog = openDialog.bind(null, DOCUMENTS_ACCESS_DIALOG);
export const closeDocumentsAccessDialog = closeDialog.bind(null, DOCUMENTS_ACCESS_DIALOG);

const documentsDialogs = {
  [DOCUMENTS_ACCESS_DIALOG]: DocumentsAccessDialog,
};

export default documentsDialogs;
