import BaseModel from 'data/BaseModel';
import Account, { type AccountBillingType } from 'data/account/model';
import Plan from 'data/plan/model';
import { mustGetPlans } from 'data/plan/selectors';
import type { StripePrice } from 'data/stripe/types/prices';
import { centsToDollars } from 'data/stripe/utils/converters';
import Features from 'shared/features';
import { getState } from 'store';

// Types lifted from here: https://gitlab.wheniwork.com/apps/billing/-/blob/master/services/billing/models/plan.go
export interface StripePlanFields {
  id: number;
  name: string;
  features: string[];
  units: {
    min: number;
    max: number;
    step: number;
  };
  stripe_prices: {
    [interval: string]: {
      ids: StripePrice['id'][];
      unit_amount: number;
    };
  };
  pricing_group: string;
}

type IntervalString = 'annual' | 'monthly';

export enum StripePlanID {
  REVERSE_TRIAL = 3160,
}

class StripePlan extends BaseModel<StripePlanFields>({
  id: 0,
  name: '',
  features: [],
  units: { max: 0, min: 0, step: 0 },
  stripe_prices: {},
  pricing_group: '',
}) {
  static Interval = {
    Monthly: 0,
    Annually: 1,
  } as const;

  static planIntervalText(interval: AccountBillingType): 'monthly' | 'annual' {
    return Account.BillingTypeMap[interval];
  }

  getInternalPlan(): Plan {
    const plans = mustGetPlans(getState());
    return plans.get(this.id)!;
  }

  isTrial() {
    return this.id < 0;
  }

  isFree() {
    return this.getPricesByIntervalString('monthly').unit_amount === 0;
  }

  isReverseTrial() {
    return this.id === StripePlanID.REVERSE_TRIAL;
  }

  is2022() {
    return [
      // 2022 plans and their trials
      2000, -2001, 2010, -2011, 2020, -2021, 2030, -2031, 2005, 2015, 2025, 2035,
    ].includes(this.id);
  }

  is2023() {
    return [
      // 2023 plans and their trials
      3000,
      3005,
      3010,
      3015,
      -3000,
      3020,
      3025,
      3030,
      3035,
      -3001,
      3040,
      3045,
      3050,
      3055,
      -3002,
      3060,
      3061,
      3062,
      3063,
      3064,
      3066,
      StripePlanID.REVERSE_TRIAL,
    ].includes(this.id);
  }

  is2024() {
    return [
      // 2024 plans and their trials
      3100,
      3105,
      3110,
      3115,
      -3100,
      3120,
      3125,
      3130,
      3135,
      -3101,
      3140,
      3145,
      3150,
      3155,
      -3102,
      StripePlanID.REVERSE_TRIAL,
    ].includes(this.id);
  }

  is2024V2() {
    return [
      // 2024V2 plans and their trials
      3200,
      3205,
      3210,
      3215,
      -3200,
      3220,
      3225,
      3230,
      3235,
      -3201,
      3240,
      3245,
      3250,
      3255,
      -3202,
      StripePlanID.REVERSE_TRIAL,
    ].includes(this.id);
  }

  is2025() {
    return [
      // 2025 plans and their trials
      4000, 4005, 4010, 4015, -4001, 4020, 4025,
    ].includes(this.id);
  }

  is2025V2() {
    return [
      // 2025V2 plans and their trials
      4100, 4105, 4110, 4115, -4101, 4120, 4125,
    ].includes(this.id);
  }

  isACurrentPlan() {
    return this.is2023() || this.is2024() || this.is2024V2() || this.is2025() || this.is2025V2();
  }

  // 2022
  isAdvanced() {
    return [2015, 2010, -2011].includes(this.id);
  }

  isStandard() {
    return [2035, 2030, -2031].includes(this.id);
  }

  // 2023
  isPro2023() {
    return [3020, 3025, 3030, 3035, 3062, 3063, -3001].includes(this.id);
  }

  // 2024
  isPro2024() {
    return [3120, 3125, 3130, 3135, -3101].includes(this.id);
  }

  // 2024-v2
  isPro2024V2() {
    return [3220, 3225, 3230, 3235, -3201].includes(this.id);
  }

  // 2025
  isSingleLocation2025() {
    return [4000, 4005].includes(this.id);
  }

  // 2025-v2
  isSingleLocation2025V2() {
    return [4100, 4105].includes(this.id);
  }

  isUpgradeable(currentPlan: Plan, billingType: number) {
    // Bottom tier plans can always upgrade
    if (
      currentPlan.isStandard() ||
      ((currentPlan.isEssentials2024() || currentPlan.isEssentials2023() || currentPlan.isEssentials2024V2()) &&
        !currentPlan.hasFeature(Features.ATTENDANCE))
    ) {
      return true;
    }

    // Determine if this plan costs more than the current plan
    const currentPlanPrice = billingType ? currentPlan.price - currentPlan.price * 0.05 : currentPlan.price;
    return billingType ? this.annualUnitPricePerMonth() > currentPlanPrice : this.monthlyUnitPrice() > currentPlanPrice;
  }

  hasFeature(feature: string) {
    return this.features.includes(feature);
  }

  getPricesByIntervalString(billingInterval: IntervalString) {
    return this.stripe_prices[billingInterval];
  }

  getPricesByIntervalId(billingInterval: AccountBillingType) {
    return this.stripe_prices[StripePlan.planIntervalText(billingInterval)];
  }

  getUnitPriceByIntervalString(billingInterval: IntervalString) {
    return centsToDollars(this.getPricesByIntervalString(billingInterval).unit_amount);
  }

  monthlyUnitPrice() {
    return centsToDollars(this.getPricesByIntervalString('monthly').unit_amount);
  }

  annualUnitPrice() {
    return centsToDollars(this.getPricesByIntervalString('annual').unit_amount);
  }

  annualUnitPricePerMonth() {
    return centsToDollars(this.getPricesByIntervalString('annual').unit_amount / 12);
  }

  annualSavingsPercentage() {
    if (this.monthlyUnitPrice() === 0) {
      return 0;
    }

    return Math.round((1 - this.annualUnitPrice() / (this.monthlyUnitPrice() * 12)) * 100);
  }

  calculateAnnualSavingsFromAnnualPrice(subtotal: number) {
    // subtotal must be the annual price after the discount is applied
    return subtotal * (1 / (1 - this.annualSavingsPercentage() / 100) - 1);
  }

  calculatePotentialSavingsFromMonthlyPrice(subtotal: number) {
    return subtotal * (this.annualSavingsPercentage() / 100) * 12;
  }

  toLegacyPlan(): Plan {
    const legacyPlan = new Plan({
      id: this.id,
      name: this.name,
      features: this.features,
      price: this.monthlyUnitPrice(),
      price_annual: this.annualUnitPrice(),
    });

    return legacyPlan;
  }
}

export default StripePlan;
