import { SCHEDULER_LOCATION_FILTER } from 'scheduler/util/cookies';
import { UPDATE_LOCATION_FILTER } from 'store/action-types';

import Cookies from 'js-cookie';

/**
 * The location filter  cannot be set using the persistFilter method because
 * it is part of the key used to store and retrieve the other settings.
 *
 * @param payload
 * @return {function(*): *}
 */
export function updateLocationFilter(payload) {
  Cookies.set(SCHEDULER_LOCATION_FILTER, payload);

  return dispatch => {
    return dispatch({
      type: UPDATE_LOCATION_FILTER,
      payload,
    });
  };
}

export const getInitialLocation = () => {
  return Number.parseInt(Cookies.get(SCHEDULER_LOCATION_FILTER), 10) || 0;
};
