import { lazy } from 'react';

import { CREATE_EXPORT_SWAPS_DIALOG } from './actions';

const ExportSwapsDialog = lazy(() => import('./ExportSwapsDialog'));

const dialogs = {
  [CREATE_EXPORT_SWAPS_DIALOG]: ExportSwapsDialog,
};

export default dialogs;
