import { Fragment, type HTMLProps, type MouseEventHandler, type Ref, forwardRef } from 'react';

import { VisuallyHidden } from '@mantine/core';

type FontIconProps = {
  className?: string;
  icon: string;
  srText?: string | null;
  label?: string | null;
  onClick?: MouseEventHandler<HTMLElement>;
  hasPopover?: boolean;
} & HTMLProps<HTMLElement>;

/**
 * Simple accessible font icon component
 *
 * @param {icon} string icon ( Icon names found here https://icons.wheniwork.com )
 * @param {className} string className
 * @param {srText} string srText ( optional screen reader text )
 * @param {label} string label ( optional text )
 * @param {hasPopover} boolean hasPopover ( optional text )
 *
 * @return {function(*): *} JSX.Element
 */
const FontIcon = forwardRef(function FontIcon(
  { icon, className, srText, label, hasPopover, ...props }: FontIconProps,
  ref: Ref<HTMLElement>,
) {
  return (
    <Fragment>
      <i
        ref={ref}
        className={`wiw-icon wiw-${icon} ${className || ''}`}
        title={hasPopover ? null : (srText as any)}
        {...props}
      />
      {srText && <VisuallyHidden>{srText}</VisuallyHidden>}
      {label && <span className="wiw-icon-label">{label}</span>}
    </Fragment>
  );
});

export default FontIcon;
