import BaseModel from 'data/BaseModel';

import { z } from 'zod';

export type ShiftBreakAuditFields = z.infer<typeof ShiftBreakAuditSchema>;

export class ShiftBreakAudit extends BaseModel<ShiftBreakAuditFields>({
  id: 0,
  timeId: 0,
  creatorId: 0,
  taken: true,
  note: null,
  paid: true,
}) {
  // Only prompt for a break audit after at least this many hours worked.
  static MIN_WORKED_HOURS_PAID = 3.5 as const;
  static MIN_WORKED_HOURS_UNPAID = 5.5 as const;
}

export const ShiftBreakAuditSchema = z.object({
  id: z.number(),
  timeId: z.number(),
  creatorId: z.number(),
  taken: z.boolean(),
  note: z.string().nullable(),
  paid: z.boolean(),
});
