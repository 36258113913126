import {
  Component,
  type ComponentProps,
  type FocusEvent,
  Fragment,
  type ReactNode,
  type RefObject,
  createRef,
  forwardRef,
} from 'react';
import { NumericFormat } from 'react-number-format';
import type { NumericFormatProps } from 'react-number-format/types/types';

import { withDefaultProps } from 'shared/components';
import InputWrapper from 'shared/components/form/wrappers/InputWrapper';

import classnames from 'classnames';
import { type FormsyInjectedProps, withFormsy } from 'formsy-react';
import { omit } from 'lodash';
import { UncontrolledTooltip } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';

type Props = FormsyInjectedProps & {
  label?: any;
  labelAddon: any;
  name: string;
  className?: string;

  /**
   * These classes get applied to the `fieldset` element that wraps the input.
   */
  classNames?: string;
  width: false | number | 'auto';
  value?: string | number | any[];
  mask?: string | ((value: string) => string);
  placeholder?: string;
  inputProps?: ComponentProps<'input'> | object;
  inputRef?: RefObject<HTMLInputElement>;
  disabled: boolean;
  newStyle: boolean;
  labelSmall: false | string;
  onChange: (name: any, value: any) => void;
  onFocus: (name: any, value: any) => void;
  onBlur: (name: any, value: any) => void;
  validateOnlyOnBlur: boolean;
  validatePristine: boolean;
  tooltip: false | string;
  setFocus: boolean;
  selectOnFocus?: boolean;
  isValid?: boolean;
  raw: boolean;
  maskProps: NumericFormatProps;

  errorMessage: any;
  afterAddon: ReactNode;
  options: { [key: string]: any };
};

type State = {
  focus: boolean;
  hasBlurred: boolean;
};

const Input = forwardRef(function Input(props: any, ref: any) {
  const inputProps = omit(props, ['inputFocus', 'inputBlur']);
  const onFocus = (e: any) => {
    props.inputFocus?.(e);
    props.onFocus?.(e);
  };

  const onBlur = (e: any) => {
    props.inputBlur?.(e);
    props.onBlur?.(e);
  };

  return (
    <input
      {...inputProps}
      // className={ inputClassname }
      onFocus={onFocus}
      onBlur={onBlur}
      ref={ref}
    />
  );
});

export const ValidatedMaskedInput = withDefaultProps(
  {
    width: false,
    disabled: false,
    newStyle: false,
    labelAddon: false,
    labelSmall: false,
    validateOnlyOnBlur: false,
    validatePristine: false,
    onChange: () => {},
    onFocus: () => {},
    onBlur: () => {},
    tooltip: false,
    setFocus: false,
    raw: false,
  },
  class ValidatedMaskedInput extends Component<Props, State> {
    _inputRef = createRef<HTMLInputElement>();
    get inputRef() {
      return this.props.inputRef || this._inputRef;
    }

    targetId = `w${uuidv4()}`;

    state = {
      focus: false,
      hasBlurred: false,
    };

    componentDidMount() {
      if (this.props.setFocus && this.inputRef.current) {
        this.inputRef.current.focus();
      }
    }

    onChange = (value: any) => {
      this.props.onChange(this.props.name, value);

      if (this.props.validateOnlyOnBlur) {
        return this.props.setValue(value, false);
      }

      return this.props.setValue(value);
    };

    onFocus = (e: FocusEvent<HTMLInputElement>) => {
      const value = e.target.value;
      this.setState({ focus: true });
      if (this.props.selectOnFocus) {
        e.target.select();
      }
      this.props.onFocus(this.props.name, value);
    };

    onBlur = (e: FocusEvent<HTMLInputElement>) => {
      const value = e.target.value;
      this.setState({
        hasBlurred: true,
        focus: false,
      });
      this.props.onBlur(this.props.name, value);
    };

    getInputId = () => {
      const { inputProps, name, label } = this.props;

      if (inputProps && typeof inputProps === 'object' && 'id' in inputProps) {
        return inputProps.id;
      }

      if (label) {
        //inputs with a label match for=id using name
        return name;
      }

      return undefined;
    };

    renderInput(invalid: boolean) {
      const inputClassname = classnames('form-control', this.props.className, {
        'is-invalid': invalid,
        newStyle: this.props.newStyle,
      });

      let value = this.props.value;
      if (value === null || typeof value === 'undefined') {
        value = '';
      }

      const inputProps = {
        ...this.props.inputProps,
        className: inputClassname,
      };

      return (
        // @ts-ignore
        <NumericFormat
          {...this.props.maskProps}
          inputFocus={this.onFocus}
          inputBlur={this.onBlur}
          value={value}
          getInputRef={this.inputRef}
          customInput={Input}
          onValueChange={values => {
            this.onChange(values.value);
          }}
          {...inputProps}
        />
      );
    }

    render() {
      const invalid =
        this.props.showError || (!this.props.isValid && (this.props.validatePristine || !this.props.isPristine));

      return (
        <Fragment>
          <InputWrapper focus={this.state.focus} {...this.props} id={this.targetId} inputId={this.getInputId()}>
            {this.renderInput(invalid)}
          </InputWrapper>
          {this.props.tooltip ? (
            <UncontrolledTooltip target={this.targetId} placement="bottom">
              {this.props.tooltip}
            </UncontrolledTooltip>
          ) : null}
        </Fragment>
      );
    }
  },
);
/**
 * @deprecated please migrate to hook-form from shared/form/inputs
 */
export default withFormsy(ValidatedMaskedInput);
