import PropTypes from 'prop-types';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { findRedirectRoute } from 'shared/util/redirect';
import Loading from './Loading';

export class Redirection extends Component {
  static propTypes = {
    location: PropTypes.object,
    dumb: PropTypes.bool,
    legacy: PropTypes.bool,
    to: PropTypes.string,
  };

  static defaultProps = {
    dumb: false,
    legacy: false,
  };

  UNSAFE_componentWillMount() {
    let { location } = this.props;
    if (this.props.location) {
      location = this.props.location;
    }

    const route = !this.props.legacy ? findRedirectRoute(location.pathname) : location.pathname;

    if (!this.props.dumb) {
      if (route !== false) {
        return this.redirect(route);
      }
    }

    return this.redirect(route);
  }

  redirect(location) {
    if (!location) {
      location = CONFIG.APP_LEGACY_ROOT;
    }
    if (this.props.legacy) {
      location = `${CONFIG.APP_LEGACY_ROOT}${location}`;
    }

    if (this.props.legacy && this.props.to) {
      location = `${CONFIG.APP_LEGACY_ROOT}/${this.props.to}`;
    }
    return window.location.assign(location + window.location.search);
  }

  render() {
    return <Loading />;
  }
}

export default withRouter(Redirection);
