import type Location from 'data/location/model';
import type { EntityMap } from 'data/types';
import { getAuthUser } from 'shared/auth/selectors';
import type { WiwState } from 'store';

import { createSelector } from '@reduxjs/toolkit';
import { Map } from 'immutable';

type Locations = EntityMap<Location, Location['id']>;

export function getStateLocations(state: WiwState): Locations {
  return state.data.location.items;
}

export const getLocations = getStateLocations;

export const getUsersLocations = createSelector([getAuthUser, getStateLocations], (user, locations) => {
  if (!user) {
    return Map<Location['id'], Location>();
  }
  return locations.filter(location => {
    if (user == null) {
      return false;
    }
    if (user.canManage()) {
      return true;
    }
    return location.id !== null && user.locations.includes(location.id);
  });
});

export const getUsersLocationsSortedByName = createSelector(getUsersLocations, locations =>
  locations.sortBy((location: Location) => location.name.toLowerCase()),
);

export const getUserLocationsLocaleCompare = createSelector([getUsersLocations], userLocations =>
  userLocations.sort((a, b) => a.name.localeCompare(b.name)),
);

export const locationsLoading = (state: WiwState) => state.data.location.loading;
export const locationsLoaded = (state: WiwState) => state.data.location.loaded && !locationsLoading(state);
