import type { SiteFields } from 'data/site/model';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

export type FetchSitesResponse = {
  sites: SiteFields[];
};

export const fetchSites = createWiwAsyncThunk<FetchSitesResponse>('site/fetch', async (params, { rejectWithValue }) => {
  try {
    return (await Monolith.url('/sites').get()) as FetchSitesResponse;
  } catch (error) {
    return rejectWithValue(parseAPIError(error));
  }
});
