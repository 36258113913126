import GustoCheck from 'integrations/modules/gusto/models/GustoCheck';
import GustoState from 'integrations/modules/gusto/state';
import type { GustoAction } from 'integrations/modules/gusto/types';
import { CHECK_GUSTO, CHECK_GUSTO_CONFIG, SEND_PAYROLL_GUSTO } from 'store/action-types';

export default (state = new GustoState(), action: GustoAction) => {
  switch (action.type) {
    // Gusto Configuration API.
    case CHECK_GUSTO_CONFIG.REQUEST:
      return state.merge({
        loaded: true,
        loading: false,
        receivedAt: 0,
      });
    case CHECK_GUSTO_CONFIG.FAILURE:
      return state.merge({
        error: action.payload,
        loaded: false,
        loading: false,
      });
    case CHECK_GUSTO_CONFIG.SUCCESS:
      return state.merge({
        configuration: action.payload as any,
        loaded: true,
        loading: false,
        receivedAt: Date.now(),
      });
    // Checking Payroll.
    case CHECK_GUSTO.FAILURE:
      return state.merge({
        error: action.payload,
        loaded: false,
        loading: false,
      });
    case CHECK_GUSTO.REQUEST:
      return state.merge({
        loaded: false,
        loading: true,
        receivedAt: 0,
      });
    case CHECK_GUSTO.SUCCESS:
      return state.merge({
        check: new GustoCheck(action.payload),
        error: null,
        loaded: true,
        loading: false,
        receivedAt: Date.now(),
      });

    // Sending payroll.
    case SEND_PAYROLL_GUSTO.FAILURE:
      return state.merge({
        error: action.payload,
        loaded: false,
        loading: false,
      });
    case SEND_PAYROLL_GUSTO.REQUEST:
      return state.merge({
        loaded: false,
        loading: true,
      });
    case SEND_PAYROLL_GUSTO.SUCCESS:
      return state.merge({
        error: null,
        loaded: true,
        loading: false,
      });
    default:
      return state;
  }
};
