import PropTypes from 'prop-types';
import { Component } from 'react';

import classnames from 'classnames';
import { propTypes, withFormsy } from 'formsy-react';

export class ValidatedRadioGroup extends Component {
  elements = {};

  static propTypes = {
    ...propTypes,
    label: PropTypes.any,
    disabled: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    className: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        disabled: PropTypes.bool,
        value: PropTypes.any,
        label: PropTypes.node,
      }),
    ).isRequired,
    type: PropTypes.oneOf(['inline', 'stacked']),
    width: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.oneOf(['auto'])]),
    newStyle: PropTypes.bool,
    onChange: PropTypes.func,
    name: PropTypes.string.isRequired,
    validatePristine: PropTypes.bool,
  };

  static defaultProps = {
    type: 'stacked',
    options: [],
    width: false,
    newStyle: false,
    validatePristine: false,
    onChange: () => {},
  };

  handleChange = event => {
    let value = event.target.value;
    const number = Number.parseInt(value);

    if (!Number.isNaN(number)) {
      value = number;
    }

    this.props.setValue(value);
    this.props.onChange(this.props.name, value);
  };

  renderElement = () => {
    return this.props.options.map(radio => {
      const checked = this.props.value === radio.value;
      const disabled = this.props.isFormDisabled || radio.disabled || this.props.disabled;
      const className = classnames('custom-control', 'custom-radio', {
        'custom-control-inline': this.props.type === 'inline',
        checked: checked,
      });
      const radioName = `${this.props.name}_${radio.value.toString().replaceAll(' ', '_')}`;

      return (
        <div className={className} key={radio.label}>
          <input
            ref={input => {
              this.elements[radio.value] = input;
            }}
            className="custom-control-input"
            checked={checked}
            type="radio"
            id={radioName}
            name={radioName}
            value={radio.value}
            onChange={this.handleChange}
            disabled={disabled}
          />
          <label htmlFor={radioName} className="custom-control-label">
            {radio.label}
          </label>
        </div>
      );
    });
  };

  render() {
    const invalid =
      (this.props.showError || !this.props.isValid) && (this.props.validatePristine || !this.props.isPristine);

    const className = classnames('form-group radio-group', this.props.className, {
      col: !this.props.width,
      [`col-${this.props.width}`]: this.props.width,
      'has-danger': invalid,
      newStyle: this.props.newStyle,
    });

    return (
      <div className={className}>
        {this.props.label && (
          <label>
            {this.props.label}
            {this.props.required && '*'}
          </label>
        )}
        {this.renderElement(invalid)}
        {(this.props.validatePristine || !this.props.isPristine) && this.props.showError && (
          <span className="form-control-feedback">{this.props.errorMessage}</span>
        )}
      </div>
    );
  }
}
/**
 * @deprecated please migrate to hook-form from shared/form/inputs
 */
export default withFormsy(ValidatedRadioGroup);
