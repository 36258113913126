import type { ReportRequestParams } from 'data/reporting/actions/reportRequestTypes';
import {
  getColumnDetails,
  getIncludeChildAccounts,
  getReportAccountId,
  getReportDataset,
  getReportDateRange,
} from 'reporting/selectors';
import { getDefaultFiltersSort } from 'reporting/selectors/util';
import { getAuthUser } from 'shared/auth/selectors';

import { createSelector } from '@reduxjs/toolkit';
import { memoize } from 'lodash';

export const getReportRequestParams = memoize((limit = 100000) =>
  createSelector(
    [getAuthUser, getReportAccountId, getColumnDetails, getReportDataset, getReportDateRange, getIncludeChildAccounts],
    (currentUser, accountId, columnMap, dataset, dateRange, includeChildAccounts) => {
      const defaultFiltersSort = getDefaultFiltersSort({ dateRange, dataset });
      const columns = columnMap?.keySeq().toList();

      return {
        ...defaultFiltersSort,
        accountId: `${accountId}`,
        columns,
        dataset,
        includeChildAccounts: includeChildAccounts,
        limit,
        timezoneName: currentUser.timezone_name,
      } as ReportRequestParams;
    },
  ),
);
