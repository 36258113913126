import HTML5Backend from 'react-dnd-html5-backend/lib/HTML5Backend';
import { TouchBackend } from 'react-dnd-touch-backend';

import { useTouchBackend } from 'shared/util/dnd';

class HTML5Modifiers extends HTML5Backend {
  handleTopDragStart(e) {
    super.handleTopDragStart(e);

    const doCopy = e.altKey || e.ctrlKey || e.metaKey;
    this.wiwDropEffect = doCopy ? 'copy' : 'move';
  }
}

class TouchModifiers extends TouchBackend {
  handleTopMoveStart(e) {
    super.handleTopMoveStart(e);

    const doCopy = e.altKey || e.ctrlKey || e.metaKey;
    this.wiwDropEffect = doCopy ? 'copy' : 'move';
  }
}

export default function createDndBackend(manager) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  if (useTouchBackend()) {
    return new TouchModifiers(manager, {
      enableMouseEvents: true,
      enableTouchEvents: false,
    });
  }
  return new HTML5Modifiers(manager);
}
