export const GOOGLE_MAPS_COUNTRY_CODE = {
  US: 'US',
  CANADA: 'CA',
};

export type Place = {
  country: string;
  address: string;
  subpremise: string;
  streetNumber: string;
  subLocality: string;
  postalCode: string;
  latitude: number;
  businessName: string;
  locality: string;
  placeId: string;
  streetName: string;
  placeType: string;
  region: string;
  longitude: number;
  formattedPhoneNumber: string;
  internationalPhoneNumber: string;
  website: string;
};
