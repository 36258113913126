import { lazy } from 'react';

import {
  ATTENDANCE_AUTO_DEDUCT_UPGRADE_DIALOG,
  ATTENDANCE_CLOCKIN_RULES_UPGRADE_DIALOG,
  ATTENDANCE_CUSTOM_EXPORT_UPGRADE_DIALOG,
  ATTENDANCE_GEO_FENCE_UPGRADE_DIALOG,
  ATTENDANCE_MANAGER_NOTIFICATIONS_UPGRADE_DIALOG,
  ATTENDANCE_MOBILE_UPGRADE_DIALOG,
  ATTENDANCE_OT_ALERTS_UPGRADE_DIALOG,
} from 'attendance/modules/freemium/constants';

const ClockinRulesUpgradeDialog = lazy(() => import('./ClockinRulesUpgradeDialog'));
const CustomExportUpgradeDialog = lazy(() => import('./CustomExportUpgradeDialog'));
const ManagerNotificationsUpgradeDialog = lazy(() => import('./ManagerNotificationsUpgradeDialog'));
const GeoFenceUpgradeDialog = lazy(() => import('./GeoFenceUpgradeDialog'));
const EnableMobileUpgradeDialog = lazy(() => import('./EnableMobileUpgradeDialog'));
const OtAlertsUpgradeDialog = lazy(() => import('./OtAlertsUpgradeDialog'));
const AutoDeductUpgradeDialog = lazy(() => import('./AutoDeductUpgradeDialog'));

export const dialogs = {
  [ATTENDANCE_CLOCKIN_RULES_UPGRADE_DIALOG]: ClockinRulesUpgradeDialog,
  [ATTENDANCE_CUSTOM_EXPORT_UPGRADE_DIALOG]: CustomExportUpgradeDialog,
  [ATTENDANCE_MANAGER_NOTIFICATIONS_UPGRADE_DIALOG]: ManagerNotificationsUpgradeDialog,
  [ATTENDANCE_GEO_FENCE_UPGRADE_DIALOG]: GeoFenceUpgradeDialog,
  [ATTENDANCE_MOBILE_UPGRADE_DIALOG]: EnableMobileUpgradeDialog,
  [ATTENDANCE_OT_ALERTS_UPGRADE_DIALOG]: OtAlertsUpgradeDialog,
  [ATTENDANCE_AUTO_DEDUCT_UPGRADE_DIALOG]: AutoDeductUpgradeDialog,
};

export default dialogs;
