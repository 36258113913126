import PropTypes from 'prop-types';
import { Component } from 'react';

import Button from 'shared/ui/Button';
import Dialog from './Dialog';
import Header from './Header';

import classnames from 'classnames';
import { Record } from 'immutable';
import { partial } from 'lodash';

import 'dialogs/assets/styles/StepDialog.scss';

export class Step extends Record({
  id: '',
  label: '',
  component: null, // function returning a React component
  onActivated: null, // callback function(dialog, step) for when the step is selected
  hidden: false,
  locked: false,
  nextStep: null,
  previousStep: null,
  data: null,
}) {}

export default class StepDialog extends Component {
  static propTypes = {
    className: PropTypes.string,
    loading: PropTypes.bool,
    steps: PropTypes.arrayOf(PropTypes.instanceOf(Step)).isRequired,
    title: PropTypes.string.isRequired,
    width: PropTypes.number,
    currentStep: PropTypes.number.isRequired,
    locked: PropTypes.bool,
    setStep: PropTypes.func.isRequired,
    onDismiss: PropTypes.func,
    closeDialog: PropTypes.func,
  };

  static defaultProps = {
    width: 720,
    locked: false,
  };

  getDefaultComponent = () => {
    if (this.props.closeDialog) {
      return (
        <Header hideClose>
          <Button size="sm" className="float-right" color="secondary" onClick={this.props.closeDialog}>
            <i className="fa fa-times" />
          </Button>
        </Header>
      );
    }

    return null;
  };

  render() {
    const { className, loading, steps, title, width, currentStep, setStep, locked, onDismiss } = this.props;
    const step = steps[currentStep];
    const numSteps = steps.reduce((accum, val) => accum + (val.hidden ? 0 : 1), 0);

    const childMethods = {
      setStep: setStep,
      steps: steps,
      closeDialog: this.props.closeDialog,
      ...step?.toJS(),
    };

    return (
      <Dialog
        className={classnames('step-dialog depricated-dialog', className, { 'no-steps': numSteps <= 1 })}
        loading={loading}
        width={width}
        onDismiss={onDismiss}
      >
        <div className="step-container">
          <div className="row">
            {numSteps > 1 ? (
              <div className="col-3 step-list">
                <div className="step-list-inner dialog-sticky">
                  <h2>{title}</h2>
                  <ul>
                    {steps.map((step, idx) => {
                      if (step.hidden) {
                        return null;
                      }

                      const isComplete = idx < currentStep;
                      const iconClass = classnames({
                        'fa-check': isComplete,
                        fa: isComplete,
                        locked: step.locked,
                      });

                      return (
                        <li key={step} className={classnames({ complete: isComplete, current: idx === currentStep })}>
                          <button
                            type="button"
                            onClick={isComplete && !step.locked && !locked ? partial(setStep, idx) : null}
                            disabled={step.locked || locked || !isComplete}
                          >
                            <i className={iconClass}>
                              <span>{idx + 1}</span>
                            </i>
                            {step.label}
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            ) : null}
            <div className="col step-dialog-body">
              {step?.component ? <step.component {...childMethods} /> : this.getDefaultComponent()}
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}
