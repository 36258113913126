import type { Conversation, Participant } from '@twilio/conversations';

import BaseModel from 'data/BaseModel';
import type { QueryString } from 'data/types';
import Workchat from 'shared/api/workchat';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

// Define the type for a Participant
export interface WorkchatParticipant {
  id: number;
  first_name: string;
  last_name: string;
  avatar_url: string;
  is_deleted: number;
  positions: string[];
  locations: string[];
}

export class WorkchatParticipantModel extends BaseModel<WorkchatParticipant>({
  id: 0,
  first_name: '',
  last_name: '',
  avatar_url: '',
  is_deleted: 0,
  positions: [],
  locations: [],
}) {}

export type LoadWorkchatParticipantsResponse = {
  participants: WorkchatParticipant[];
};

export const loadWorkchatParticipants = createWiwAsyncThunk<LoadWorkchatParticipantsResponse, QueryString>(
  'workchatv2/participants/load',
  async (_params = {}, { rejectWithValue }) => {
    try {
      const resp = await Workchat.url('/accounts/participants').get();
      return resp.participants;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);

export const loadWorkchatParticipantsForConversation = createWiwAsyncThunk<Participant[], Conversation>(
  'workchatv2/participants/forConversation/load',
  (conversation, { rejectWithValue }) => {
    return conversation.getParticipants().catch((error: any) => {
      rejectWithValue(new Error(error));
    }) as Promise<Participant[]>;
  },
);
