import { useEffect } from 'react';
import { type FieldValues, useFormContext } from 'react-hook-form';

import persistAccount from 'data/account/actions/persistAccount';
import useNotice from 'notices/hooks/useNotice';
import { AutoImportOnboardingStep } from 'scheduler/modules/auto-import-onboarding';
import { INDUSTRIES_FOCUSED_ONBOARDING } from 'scheduler/modules/onboarding/util/constants';
import { getAuthAccount } from 'shared/auth/selectors';
import { Select } from 'shared/form';
import { useLoading } from 'shared/hooks/useLoading';
import Button from 'shared/ui/Button';
import { useWiwDispatch, useWiwSelector } from 'store';

import { useRive } from '@rive-app/react-canvas';
import { merge } from 'lodash';

import 'scheduler/modules/auto-import-onboarding/styles/account-step.scss';
// @ts-ignore
import cleanScheduleAnimation from 'scheduler/modules/auto-import-onboarding/assets/clean_schedule.riv';

interface AccountStepProps {
  goToStep: (step: AutoImportOnboardingStep) => void;
}

const AccountStep = ({ goToStep }: AccountStepProps) => {
  const form = useFormContext();

  const dispatch = useWiwDispatch();
  let authAccount = useWiwSelector(getAuthAccount);
  const [isLoading, watch] = useLoading();
  const { errorNotice } = useNotice();
  const stateMachineName = 'Main State Machine';

  const { rive, RiveComponent } = useRive({
    src: cleanScheduleAnimation,
    stateMachines: stateMachineName,
    autoplay: true,
  });

  useEffect(() => {
    if (rive) {
      rive.setTextRunValue('Workplace Name', authAccount.company || '');
      rive.setNumberStateAtPath('Industry String', 45, 'Industry Icon Container');
    }
  }, [rive, authAccount.company]);

  const handleSubmit = (data: FieldValues) => {
    const newStartOfWeek = { schedule: { start_of_week: data.startOfWeek } };
    if (data.industry >= 0) {
      authAccount = authAccount.set('industry_id', data.industry);
    }
    authAccount = authAccount.set('settings', merge(authAccount.settings, newStartOfWeek));
    watch(
      dispatch(persistAccount(authAccount))
        .unwrap()
        .then(() => {
          goToStep(AutoImportOnboardingStep.FILE_UPLOAD);
        })
        .catch(err => {
          errorNotice(err.message);
        }),
    );
  };

  const createIndustryOptions = () => {
    return INDUSTRIES_FOCUSED_ONBOARDING.map(industry => ({
      label: industry.label,
      value: industry.id,
    }));
  };

  const onChangeIndustry = (_key: string, value: number) => {
    const currentSelection = value ? value : -1;
    const industry = INDUSTRIES_FOCUSED_ONBOARDING.find(industry => industry.id === currentSelection);
    if (industry) {
      rive?.setNumberStateAtPath('Industry String', industry.id, 'Industry Icon Container');
    }
  };

  return (
    <div className="container-fluid auto-import-onboarding-account">
      <div className="row">
        <div className="col-10 col-md-6 left-panel">
          <div className="title">Let's set up your work schedule!</div>
          <div className="subtitle">
            It only takes a couple minutes, and you'll save hours when building the schedule.
          </div>
          <div className="day-of-week">
            <Select
              className="day-of-week-input"
              name="startOfWeek"
              label="What day of the week does your schedule start?"
              defaultValue={0}
              options={[
                { label: 'Sunday', value: 0 },
                { label: 'Monday', value: 1 },
                { label: 'Tuesday', value: 2 },
                { label: 'Wednesday', value: 3 },
                { label: 'Thursday', value: 4 },
                { label: 'Friday', value: 5 },
                { label: 'Saturday', value: 6 },
              ]}
              data-testid="aio-schedule-start-dropdown"
            />
          </div>
          <div className="industry">
            <Select
              className="industry-input"
              name="industry"
              label="What industry best describes your business?"
              required
              clearable
              isSearchable={false}
              options={createIndustryOptions()}
              onChange={onChangeIndustry}
              validate={{
                required: 'An industry selection is required.',
              }}
              data-testid="aio-industry-selection-dropdown"
            />
          </div>
          <div className="continue-button">
            <Button
              onClick={form.handleSubmit(handleSubmit)}
              className="w-100"
              loading={isLoading}
              data-testid="aio-continue-button-account-step"
            >
              Continue
            </Button>
          </div>
        </div>
        <div className="right-panel col-6 container-fluid">
          <RiveComponent className="animation" />
        </div>
      </div>
    </div>
  );
};

export default AccountStep;
