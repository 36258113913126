import type { List } from 'immutable';
import { type ChangeEvent, useEffect, useState } from 'react';
import BigParticipantList from 'workchat/components/BigParticipantList';
import AddParticipants from 'workchat/containers/AddParticipants';

import { getAuthUser } from 'shared/auth/selectors';
import FontIcon from 'shared/ui/FontIcon';
import { useWiwDispatch, useWiwSelector } from 'store';
import { leaveConversation, modal, popScreen, pushScreen, renameConversation } from 'workchat/actions';
import Header, { type HeaderButton } from 'workchat/containers/Header';
import { getConnected } from 'workchat/selectors/getConnected';
import { getConversation } from 'workchat/selectors/getConversation';
import { getParticipants } from 'workchat/selectors/getParticipants';
import { getWorkchatUsers } from 'workchat/selectors/getWorkchatUsers';
import type { TwilioConversation } from 'workchat/types/TwilioConversation';
import type { TwilioUser } from 'workchat/types/TwilioUser';

import 'workchat/styles/ChannelDetail.scss';
export default function ChannelDetail() {
  const dispatch = useWiwDispatch();
  const user = useWiwSelector(getAuthUser);
  const conversation = useWiwSelector(getConversation);
  const participants = useWiwSelector(getParticipants);
  const users = useWiwSelector(getWorkchatUsers);
  const connected = useWiwSelector(getConnected);
  const [title, setTitle] = useState('');
  const [titleChanged, setTitleChanged] = useState(false);
  const [titleFocused, setTitleFocused] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (conversation?.name !== title && !titleChanged) {
      setTitle(conversation?.name || '');
    }
  });

  const back = () => {
    dispatch(popScreen());
  };

  const changeTitle = (e: ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
    setTitleChanged(true);
  };

  const focusTitle = () => {
    setTitleFocused(true);
  };

  const resetTitle = () => {
    setTitle(conversation?.name || '');
    setTitleChanged(false);
    setTitleFocused(false);
  };

  const saveTitle = () => {
    if (!titleChanged) {
      setTitleFocused(false);
      return;
    }

    setSaving(true);

    dispatch(renameConversation(title)).then((conv: TwilioConversation) => {
      setSaving(false);
      setTitle(conv.name);
      setTitleChanged(false);
      setTitleFocused(false);
    });
  };

  const addCoworkers = () => {
    dispatch(pushScreen(AddParticipants));
  };

  const leave = () => {
    dispatch(
      // @ts-ignore
      modal({
        title: 'Leave Conversation',
        text: 'Are you sure you want to leave the conversation?',
        confirm: 'Leave',
        onConfirm: doLeave,
      }),
    );
  };

  const doLeave = () => {
    dispatch(leaveConversation());
  };

  const selfId = `${user.id}`;
  // @ts-ignore
  const filteredParticipants: List<TwilioUser> = participants
    .toList()
    .filter(p => p.userId !== selfId)
    .map(p => users.get(p.userId));

  const defaultTitle = title ? title : 'Name this channel';

  const buttons: HeaderButton[] = [
    {
      title: 'Back',
      action: back,
      label: [<FontIcon className="mr-1" key="back" icon="chevron-left" />, 'Back'],
      side: 'left',
      color: 'secondary',
    },
  ];

  // const showList = conversation?.attributes.external_type !== 'individual';
  const canEdit = conversation?.permissions.includes('editChannelName');
  const canSave = titleChanged || titleFocused;
  const canAdd = conversation?.permissions.includes('addMember');
  const canLeave = conversation?.permissions.includes('leaveChannel');

  return (
    <div>
      <Header showBroadcast={false} buttons={buttons}>
        Details
      </Header>
      <div className="channel-details">
        {canEdit ? (
          <div className="name-container">
            <label htmlFor="channelTitle" className="sr-only">
              Channel name
            </label>
            <input
              type="text"
              name="channelTitle"
              className={canSave ? 'changed' : ''}
              onChange={changeTitle}
              value={title}
              onFocus={focusTitle}
              placeholder={defaultTitle}
            />
            {canSave ? (
              <div className="actions">
                <button type="button" className="cancel" onClick={resetTitle}>
                  Cancel
                </button>
                <button type="button" className="save" disabled={saving || !connected} onClick={saveTitle}>
                  Save
                </button>
              </div>
            ) : null}
          </div>
        ) : null}
        <div className="channel-members">
          <h3>Channel Members</h3>
          {canAdd ? (
            <button type="button" className="add" onClick={addCoworkers} disabled={saving || !connected}>
              Add
            </button>
          ) : null}
        </div>
        <BigParticipantList users={filteredParticipants} />
        {canLeave ? (
          <div className="leave-channel">
            <button type="button" className="leave" onClick={leave} disabled={saving || !connected}>
              Leave Channel
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
}
