import type { Conversation } from '@twilio/conversations';
import { useState } from 'react';

import type User from 'data/user/model';
import { getAuthUser } from 'shared/auth/selectors';
import FontIcon from 'shared/ui/FontIcon';
import { useWiwDispatch, useWiwSelector } from 'store';
import { setError } from 'workchat/actions';
import type { ImageInput } from 'workchat/types/ImageInput';
import HeaderV2, { type HeaderButton } from 'workchat/v2/components/HeaderV2';
import MessageInputV2 from 'workchat/v2/components/MessageInputV2';
import ParticipantListV2 from 'workchat/v2/components/ParticipantListV2';
import ReactionPicker from 'workchat/v2/components/ReactionPicker';
import { VIEW_CONVERSATION } from 'workchat/v2/components/ViewConversationV2';
import { CONVERSATION_TYPE } from 'workchat/v2/constants';
import useReactionPicker from 'workchat/v2/hooks/useReactionPickerProvider';
import { useTwilioProvider } from 'workchat/v2/providers/TwilioProvider';
import { createWorkchatConversation } from 'workchat/v2/store/actions';
import { sendWorkchatMessage, sendWorkchatMessageWithImage } from 'workchat/v2/store/messages/messagesActions';
import { setConversation, setPendingConversation, viewStackPop, viewStackPush } from 'workchat/v2/store/reducer';
import { getWorkchatPendingConversation } from 'workchat/v2/store/selectors';
import { getWorkchatUsers } from 'workchat/v2/store/users/usersSelectors';

import 'workchat/styles/StartConversation.scss';

export const VIEW_START_CONVERSATION = 'VIEW_START_CONVERSATION';

export default function StartConversationV2() {
  const dispatch = useWiwDispatch();
  const user = useWiwSelector(getAuthUser);
  const users = useWiwSelector(getWorkchatUsers);
  const pending = useWiwSelector(getWorkchatPendingConversation) || { userIds: [], title: null };
  const [sending, setSending] = useState(false);
  const { client } = useTwilioProvider();
  const reactionPicker = useReactionPicker();

  const back = () => {
    dispatch(viewStackPop());
  };

  const sendMessage = async (text: string, image: ImageInput) => {
    if (text && client && pending.userIds) {
      setSending(true);

      dispatch(
        createWorkchatConversation({
          conversationName: pending.title,
          participants: pending.userIds,
          client,
        }),
      )
        .unwrap()
        .then((joinedConversation: Conversation | undefined) => {
          if (!joinedConversation) {
            return Promise.reject();
          }

          dispatch(setConversation(joinedConversation.sid));

          if (!image) {
            dispatch(sendWorkchatMessage(joinedConversation.sid, text));
          } else {
            dispatch(sendWorkchatMessageWithImage(joinedConversation.sid, text, image));
          }

          dispatch(viewStackPop());
          dispatch(viewStackPush(VIEW_CONVERSATION));
          dispatch(setPendingConversation(null));
        })
        .catch(() => {
          dispatch(setError('Something went wrong starting the conversation.'));
        })
        .finally(() => {
          setSending(false);
        });
    }
  };

  const getTitle = (firstUser: User, convType: CONVERSATION_TYPE) => {
    if (pending.title) {
      return pending.title;
    }

    if (convType === CONVERSATION_TYPE.INDIVIDUAL) {
      return firstUser?.fullName || 'Unknown';
    }
    return 'Group WorkChat';
  };

  const buttons: HeaderButton[] = [
    {
      title: 'Back',
      action: back,
      label: [<FontIcon className="mr-1" key="back" icon="chevron-left" />, 'Back'],
      side: 'left',
      color: 'secondary',
    },
  ];
  const convType = pending.userIds?.length > 1 ? CONVERSATION_TYPE.GROUP : CONVERSATION_TYPE.INDIVIDUAL;
  const pendingUsers: User[] = pending.userIds
    .map((userId: number) => users.get(userId))
    .filter(user => !!user) as User[];
  const firstUser = pendingUsers[0];
  const title = getTitle(firstUser, convType);

  return (
    <div className="start-conversation">
      <HeaderV2 showBroadcast={false} buttons={buttons}>
        {title}
      </HeaderV2>
      {convType !== CONVERSATION_TYPE.INDIVIDUAL ? <ParticipantListV2 users={pendingUsers} user={user} /> : null}
      <div className="splash">
        <div className={convType} />
        <h4>Start chatting with {convType === 'group' ? 'the group' : firstUser?.fullName || 'Unknown'}!</h4>
      </div>
      <ReactionPicker ref={reactionPicker.bindRef} />
      <MessageInputV2 canAttach sending={sending} sendMessage={sendMessage} />
    </div>
  );
}
