import type { FetchAccountQueryArgs, FetchAccountResponse } from 'data/account/actions/fetchAccounts';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

export const fetchCurrentAccount = createWiwAsyncThunk<FetchAccountResponse, FetchAccountQueryArgs>(
  'account/fetchCurrentAccount',
  async (query = {}, { rejectWithValue }) => {
    try {
      return (await Monolith.query(query).get('/account')) as FetchAccountResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
