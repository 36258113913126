import { lazy } from "react";

const CheckComponentDialog = lazy(() => import("payrollCheck/dialogs/CheckComponentDialog"));
const CheckAddEditBenefitsDialog = lazy(() => import("payrollCheck/dialogs/CheckAddEditDeductions"));
const PayrollExportOptionsDialog = lazy(() => import("payrollCheck/dialogs/PayrollExportOptionsDialog"));
const PayrollJournalDialog = lazy(() => import("payrollCheck/dialogs/PayrollJournalDialog"));
const TaxLiabilitiesDialog = lazy(() => import("payrollCheck/dialogs/TaxLiabilitiesDialog"));
const PayrollSummaryDialog = lazy(() => import("payrollCheck/dialogs/PayrollSummaryDialog"));
const ContractorPaymentsReportDialog = lazy(() => import("payrollCheck/dialogs/ContractorPaymentsReportDialog"));

export const CHECK_COMPONENT_DIALOG = "CHECK_COMPONENT_DIALOG";
export const CHECK_ADD_EDIT_BENEFITS_DIALOG = "CHECK_ADD_EDIT_BENEFITS_DIALOG";
export const PAYROLL_EXPORT_OPTIONS_DIALOG = "PAYROLL_EXPORT_OPTIONS_DIALOG";
export const PAYROLL_JOURNAL_DIALOG = "PAYROLL_JOURNAL_DIALOG";
export const TAX_LIABILITIES_DIALOG = "TAX_LIABILITIES_DIALOG";
export const PAYROLL_SUMMARY_DIALOG = "PAYROLL_SUMMARY_DIALOG";
export const CONTRACTOR_PAYMENT_REPORT_DIALOG = "CONTRACTOR_PAYMENT_REPORT_DIALOG";

const dialogs = {
  [CHECK_COMPONENT_DIALOG]: CheckComponentDialog,
  [CHECK_ADD_EDIT_BENEFITS_DIALOG]: CheckAddEditBenefitsDialog,
  [PAYROLL_EXPORT_OPTIONS_DIALOG]: PayrollExportOptionsDialog,
  [PAYROLL_JOURNAL_DIALOG]: PayrollJournalDialog,
  [TAX_LIABILITIES_DIALOG]: TaxLiabilitiesDialog,
  [PAYROLL_SUMMARY_DIALOG]: PayrollSummaryDialog,
  [CONTRACTOR_PAYMENT_REPORT_DIALOG]: ContractorPaymentsReportDialog,
  // ...
};

export default dialogs;
