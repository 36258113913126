import PropTypes from 'prop-types';
import { Component, createRef } from 'react';
import { connect } from 'react-redux';

import { activeSectionSelector, loadingSelector, searchSelector } from 'data/notifications/selectors';
import { Loading } from 'notifications/components/Loading';
import NotifySection, { NOTIFICATION_HEADER_HEIGHT, NOTIFICATION_HEIGHT } from './NotifySection';

export class SectionManager extends Component {
  sectionsRef = createRef();

  state = {
    height: 100,
  };

  static propTypes = {
    actions: PropTypes.arrayOf(PropTypes.node),
    waiting: PropTypes.arrayOf(PropTypes.node),
    notifications: PropTypes.arrayOf(PropTypes.node),
    loading: PropTypes.bool.isRequired,
    search: PropTypes.string.isRequired,
    activeSection: PropTypes.string.isRequired,
  };

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  getWindowDistance() {
    let windowDistance = window.innerHeight;
    if (this.sectionsRef?.current) {
      windowDistance -= this.sectionsRef.current.getBoundingClientRect().top;
    }
    return windowDistance < 100 ? 100 : windowDistance;
  }

  updateDimensions = () => {
    const height = this.getWindowDistance();
    if (height !== this.state.height) {
      this.setState({ height: this.getWindowDistance() });
    }
    if (this.sectionsRef?.current) {
      this.sectionsRef.current.style.height = `${height}px`;
    }
  };

  getEntryDisplayCount() {
    let height = this.state.height;
    let actions = (this.props.actions || []).length;
    let waiting = (this.props.waiting || []).length;
    let notifications = (this.props.notifications || []).length;
    const counts = { actions: 0, waiting: 0, notifications: 0 };

    const decrement = (type, header = false) => {
      switch (type) {
        case 'actions':
          actions--;
          counts.actions++;
          break;
        case 'waiting':
          waiting--;
          counts.waiting++;
          break;
        case 'notifications':
          notifications--;
          counts.notifications++;
          break;
      }
      height -= NOTIFICATION_HEIGHT;
      if (header) {
        height -= NOTIFICATION_HEADER_HEIGHT;
      }
      return height > 0;
    };

    if (actions) {
      decrement('actions', true);
    }
    if (waiting) {
      decrement('waiting', true);
    }
    if (notifications) {
      decrement('notifications', true);
    }
    while ((height >= 0 && actions) || waiting || notifications) {
      if (notifications && !decrement('notifications')) {
        break;
      }
      if (actions && !decrement('actions')) {
        break;
      }
      if (waiting && !decrement('waiting')) {
        break;
      }
    }
    return counts;
  }

  renderNoNotifications() {
    return (
      <div key="no-notifications" className="placeholder">
        No notifications
      </div>
    );
  }

  renderNoResults() {
    return (
      <div key="no-results" className="placeholder">
        No results
      </div>
    );
  }

  renderLoading() {
    return <Loading />;
  }

  render() {
    const height = this.state.height;
    const counts = this.getEntryDisplayCount();
    let actions = this.props.actions || [];
    let waiting = this.props.waiting || [];
    let notifications = this.props.notifications || [];

    const noContent = !actions.length && !waiting.length && !notifications.length;
    const loading = noContent && this.props.loading;
    const noResults = !loading && noContent && this.props.search && !this.props.activeSection;

    if (this.props.search && this.props.activeSection) {
      switch (this.props.activeSection) {
        case 'actions':
          if (!actions.length) {
            actions = [this.renderNoResults()];
          }
          break;
        case 'waiting':
          if (!waiting.length) {
            waiting = [this.renderNoResults()];
          }
          break;
        case 'notifications':
          if (!notifications.length) {
            notifications = [this.renderNoResults()];
          }
          break;
      }
    }

    if (!loading && !this.props.search && noContent) {
      notifications = [this.renderNoNotifications()];
    }

    return (
      <div ref={this.sectionsRef} className="sections-container">
        <NotifySection
          icon="fa-check"
          label="Things Needing Your Action"
          section="actions"
          containerHeight={height}
          entries={counts.actions}
        >
          {actions}
        </NotifySection>
        <NotifySection
          icon="fa-user"
          label="Things Awaiting Actions from Others"
          section="waiting"
          containerHeight={height}
          entries={counts.waiting}
        >
          {waiting}
        </NotifySection>
        <NotifySection
          icon="fa-bell"
          label="Notifications"
          section="notifications"
          containerHeight={height}
          entries={counts.notifications}
        >
          {notifications}
        </NotifySection>
        {loading && this.renderLoading()}
        {noResults && this.renderNoResults()}
      </div>
    );
  }
}

export default connect(state => ({
  loading: loadingSelector(state),
  activeSection: activeSectionSelector(state),
  search: searchSelector(state),
}))(SectionManager);
