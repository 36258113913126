import { CLOSE_ADDITEM_POPOVER, OPEN_ADDITEM_POPOVER } from 'store/action-types';

export function openAddItemPopover(target: any, date: any, itemCount: any, isFutureTimeAllowed = false) {
  // TODO(types)
  return {
    type: OPEN_ADDITEM_POPOVER,
    target,
    date,
    itemCount,
    isFutureTimeAllowed,
  };
}

export function closeAddItemPopover() {
  return {
    type: CLOSE_ADDITEM_POPOVER,
  };
}
