import User from 'data/user/model';
import { getActiveUsers } from 'data/user/selectors/activeUsers';
import { getAuthAccount } from 'shared/auth/selectors';

import { createSelector } from '@reduxjs/toolkit';
import { List, Map } from 'immutable';

export const usersSelector = state => state.data.user.items;
export const userToMerge = (state, props) => props.user;

const userMatch = (a, b) => {
  return a.email && a.email === b.email;
};

const userMatchRecommended = (a, b) => {
  if (a.phone_number && a.phone_number === b.phone_number) {
    return true;
  }
  if (a.last_name.toLowerCase() === b.last_name.toLowerCase()) {
    return true;
  }
  return false;
};

const buildUserMergeOptions = (matchUser, users, account) => {
  let preferred = new List();
  let available = new List();
  let notRecommended = new List();
  let matchedUserId = 0;

  const sortPref = account.getSettings('schedule.sort_employees', 0);
  users
    .delete(matchUser.id)
    .sort((a, b) => User.compare(a, b, null, sortPref))
    .map(user => {
      if (user.login_id) {
        notRecommended = notRecommended.push(user);
      } else if (userMatch(matchUser, user)) {
        preferred = preferred.push(user);
        matchedUserId = user.id;
      } else if (userMatchRecommended(matchUser, user)) {
        preferred = preferred.push(user);
      } else {
        available = available.push(user);
      }
    });

  const possibleMatch = !preferred.isEmpty() || !available.isEmpty() || !notRecommended.isEmpty();

  return new Map({ preferred, available, notRecommended, matchedUserId, possibleMatch });
};

export const getUserMergeOptions = createSelector([userToMerge, getActiveUsers, getAuthAccount], buildUserMergeOptions);
