import type Account from 'data/account/model';
import { getCurrentPlan } from 'data/account/selectors/currentPlan';
import { getMasterPlan } from 'data/account/selectors/masterPlan';
import type Plan from 'data/plan/model';
import { getAuthAccount } from 'shared/auth/selectors';
import type { WiwState } from 'store';

import { createSelector } from '@reduxjs/toolkit';

const filterAccountPlan = (account: Account | undefined, currentPlan: Plan | null, masterPlan: Plan | null): Plan => {
  return (account?.isChild() ? masterPlan : currentPlan) as unknown as Plan;
};

const getPropsOrStateAccount = (state: WiwState, props?: { account?: Account }): Account | undefined => {
  return props?.account ? props.account : getAuthAccount(state);
};

export const getAccountPlan = createSelector(
  [getPropsOrStateAccount, getCurrentPlan, getMasterPlan],
  filterAccountPlan,
);

export function mustGetAccountPlan(state: WiwState): Plan {
  const maybePlan = getAccountPlan(state);
  if (!maybePlan) {
    throw new Error('no account plan');
  }
  return maybePlan;
}
