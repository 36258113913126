import type { FetchAccountResponse } from 'data/account/actions/fetchAccounts';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

export default createWiwAsyncThunk<FetchAccountResponse, number>(
  'account/hibernateAccount',
  async (deactivated_reason_id = 0, { rejectWithValue }) => {
    try {
      return (await Monolith.url('/account/hibernate').post({ deactivated_reason_id })) as FetchAccountResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
