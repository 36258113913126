import Worktags from 'shared/api/worktags';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type FetchUserTagsArgs = (string | number)[];
type FetchUserTagsResponse = {
  data: any;
};

export const fetchUserTags = createWiwAsyncThunk<FetchUserTagsResponse, FetchUserTagsArgs>(
  'tags/userTags/fetch',
  async (ids, { rejectWithValue }) => {
    try {
      // convert ids to strings because we're hateful
      const userIds = ids.map(id => `${id}`);

      return (await Worktags.url('/users').post({ ids: userIds })) as FetchUserTagsResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
