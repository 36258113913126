import { Fragment } from 'react';

import { fetchPunchState } from 'data/punchState/actions';
import { getPunchStateForAuthUser } from 'data/punchState/selectors/getPunchStateForAuthUser';
import useDialog from 'dialogs/hooks/useDialog';
import { errorNotice } from 'notices';
import ClockInLauncher from 'punch/components/ClockInLauncher';
import { CLOCK_IN_DIALOG, PUNCH_BREAK_DIALOG } from 'punch/dialogs';
import { MenuItem } from 'shared/components/nav/MenuItem';
import { useWiwDispatch, useWiwSelector } from 'store';

export default function ClockInMenu() {
  const dispatch = useWiwDispatch();
  const dialog = useDialog();
  const punchState = useWiwSelector(getPunchStateForAuthUser);

  async function openClockInDialog(evt: Event) {
    if (evt) {
      evt.preventDefault();
    }

    try {
      const prevState = punchState;
      const newState = await dispatch(fetchPunchState()).unwrap();

      if (newState.canClockIn !== prevState?.canClockIn || newState.canClockOut !== prevState?.canClockOut) {
        dispatch(errorNotice('The action you requested is no longer available.'));
      } else {
        dialog.open(CLOCK_IN_DIALOG);
      }
    } catch (err) {
      dispatch(errorNotice('An error occurred getting punch state.'));
      console.error(err);
    }
  }

  function openBreakDialog(evt: Event) {
    if (evt) {
      evt.preventDefault();
    }

    const prevState = punchState;
    dispatch(fetchPunchState())
      .unwrap()
      .then((newState: any) => {
        if (newState.canStartBreak !== prevState?.canStartBreak || newState.canEndBreak !== prevState?.canEndBreak) {
          dispatch(errorNotice('The action you requested is no longer available.'));
        } else {
          dialog.open(PUNCH_BREAK_DIALOG);
        }
      })
      .catch((err: any) => {
        dispatch(errorNotice('An error occurred getting punch state.'));
        console.error(err);
      });
  }

  if (!punchState) {
    return null;
  }

  const canBreak = punchState.canStartBreak || punchState.canEndBreak;
  const onBreak = punchState.canEndBreak;
  const clockedIn = punchState.canClockOut;

  return (
    <Fragment>
      <ClockInLauncher key="clock-in-launcher" />
      {canBreak && (
        <MenuItem
          data-testid="take-end-break-menu-button"
          key="take-end-break"
          icon="break-unpaid-filled"
          to="#"
          onClick={(evt: Event) => openBreakDialog(evt)}
          label={onBreak ? 'End Break' : 'Take Break'}
        />
      )}
      <MenuItem
        data-testid="clock-in-out-menu-button"
        key="clock-in"
        to="#"
        onClick={(evt: Event) => openClockInDialog(evt)}
        icon="clock-in"
        label={clockedIn ? 'Clock Out' : 'Clock In'}
        disabled={onBreak}
        tooltipText={onBreak ? "You can't clock out while on break." : ''}
        tooltipClassName="clock-out-disabled-tooltip"
      />
    </Fragment>
  );
}
