import BaseModel from 'data/BaseModel';
import type { TimeOffPolicyRateFields } from 'data/timeOffAccrual/models/timeOffPolicyRate';

export interface TimeOffPolicyFields {
  id: string;
  accountId: string;
  name: string;
  isActive: boolean;
  isArchived: boolean;
  requestTypeId: string;
  createdBy: string;
  createdAt: string;
  updatedBy: string;
  updatedAt: string;
  effectiveRate: TimeOffPolicyRateFields;
  rates: TimeOffPolicyRateFields[];
  userIds: string[];
}

const timeOffPolicyValues: TimeOffPolicyFields = {
  id: '',
  accountId: '',
  name: '',
  isActive: false,
  isArchived: false,
  requestTypeId: '',
  createdBy: '',
  createdAt: '',
  updatedBy: '',
  updatedAt: '',
  effectiveRate: {} as TimeOffPolicyRateFields,
  rates: [],
  userIds: [],
};

export default class TimeOffPolicy extends BaseModel<TimeOffPolicyFields>(timeOffPolicyValues) {}
