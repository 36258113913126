import type User from 'data/user/model';

import 'workchat/styles/BigParticipantList.scss';

interface BigParticipantListProps {
  users?: User[];
}

export default function BigParticipantListV2({ users }: BigParticipantListProps) {
  return (
    <div className="participants">
      {users?.map(user => {
        if (!user) {
          return null;
        }
        return (
          <div className="participant" key={`participant-list-${user.id}`}>
            <img src={user.getAvatarUrl('small')} alt="Avatar" />
            {user.fullName}
          </div>
        );
      })}
    </div>
  );
}
