export const getTextColor = backgroundColor => {
  if (!backgroundColor) {
    return 'white';
  }
  const r = Number.parseInt(backgroundColor.substr(0, 2), 16);
  const g = Number.parseInt(backgroundColor.substr(2, 2), 16);
  const b = Number.parseInt(backgroundColor.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 150 ? 'black' : 'white';
};
