import { Dialog, DialogBody, DialogFooter, DialogHeader, closeDialog } from 'dialogs';
import { REPORT_DATA_DOES_NOT_EXIST_DIALOG } from 'reporting/dialogs/index';
import setActiveDialog from 'reporting/modules/AgGridReports/actions/setActiveDialog';
import Form from 'shared/components/form';
import Button from 'shared/ui/Button';
import { useWiwDispatch } from 'store';

export default function ReportDataDoesNotExistDialog() {
  const dispatch = useWiwDispatch();

  const handleClose = () => {
    dispatch(closeDialog(REPORT_DATA_DOES_NOT_EXIST_DIALOG));
    dispatch(setActiveDialog(undefined));
  };

  const handleSubmit = () => {
    handleClose();
  };

  return (
    <Dialog className="" width={660} onDismiss={handleClose}>
      <DialogHeader onClose={handleClose}>Report Data Doesn't Exist</DialogHeader>
      <Form onSubmit={handleSubmit}>
        <DialogBody>
          No data can be found for custom reports. You can learn more about how report data is generated in{' '}
          <a target="_blank" rel="noopener noreferrer" href="https://help.wheniwork.com/articles/custom-reports/">
            this help article
          </a>
          .
        </DialogBody>
        <DialogFooter>
          <div className="float-right">
            <Button type="submit">Okay</Button>
          </div>
        </DialogFooter>
      </Form>
    </Dialog>
  );
}
