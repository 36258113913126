import type { PaginatedResponse, PaginationParameters, PoliciesResponse } from 'data/timeOffAccrual/types/common';
import type { DataResponse } from 'data/types';
import Scheduling from 'shared/api/scheduling';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

interface FetchPoliciesParameters extends Partial<PaginationParameters> {
  showDeleted?: boolean;
}

export const fetchPolicies = createWiwAsyncThunk<
  DataResponse<PoliciesResponse & PaginatedResponse>,
  FetchPoliciesParameters
>('timeOff/policies/fetchPolicies', async (params = {}, { rejectWithValue }) => {
  try {
    return await Scheduling.query(params).get('/timeoff/policies');
  } catch (error) {
    return rejectWithValue(parseAPIError(error));
  }
});
