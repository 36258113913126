import { MenuItem } from 'shared/components/nav/MenuItem';

type PayrollMenuItemProps = {
  payrollRoute: string;
};

const PayrollMenuItem = ({ payrollRoute }: PayrollMenuItemProps) => {
  const route = payrollRoute ? `/payroll/${payrollRoute}` : '/payroll';
  return <MenuItem icon="payroll" isMainNav label="Payroll" hideLabel="tablet" to={route} />;
};

export default PayrollMenuItem;
