import { lazy } from 'react';

import { openDialog } from 'dialogs';

const PurchaseCreditsDialogV1 = lazy(() => import('teamtxt/dialogs/PurchaseCreditsDialogV1'));
const PurchaseCreditsDialog = lazy(() => import('teamtxt/dialogs/PurchaseCreditsDialog'));
const SendMessageDialog = lazy(() => import('teamtxt/dialogs/SendMessageDialog'));

// Identifiers for each dialog
export const PURCHASE_CREDITS_DIALOG = 'PURCHASE_CREDITS_DIALOG';
export const SEND_MESSAGE_DIALOG = 'SEND_MESSAGE_DIALOG';
export const PURCHASE_CREDITS_DIALOG_V1 = 'PURCHSE_CREDITS_DIALOG_V1';

// Actions for opening/closing dialogs
export const openSendMessageDialog = openDialog.bind(null, SEND_MESSAGE_DIALOG);
export const openPurchaseCreditsDialog = (payload, options = {}) =>
  openDialog(PURCHASE_CREDITS_DIALOG, payload, { overlay: true, ...options });

const teamTxtDialogs = {
  [PURCHASE_CREDITS_DIALOG]: PurchaseCreditsDialog,
  [SEND_MESSAGE_DIALOG]: SendMessageDialog,
  [PURCHASE_CREDITS_DIALOG_V1]: PurchaseCreditsDialogV1,
};

export default teamTxtDialogs;
