import Cookies from 'js-cookie';
import type { Moment } from 'moment-timezone';
import { expandOpenShiftRow } from 'scheduler/actions/expandOpenShiftRow';
import { SCHEDULER_DATE } from 'scheduler/util/cookies';
import type { WiwDispatch } from 'store';
import { SET_SCHEDULE_CURRENT_DATE } from 'store/action-types';

/**
 * @param date Moment
 */
export function setCurrentDate(date: Moment) {
  return (dispatch: WiwDispatch) => {
    Cookies.set(SCHEDULER_DATE, date.format('x'));

    dispatch(expandOpenShiftRow());

    return dispatch({
      type: SET_SCHEDULE_CURRENT_DATE,
      payload: { currentDate: date },
    });
  };
}
