import { type Ref, forwardRef } from 'react';
import { type FieldValues, type UseControllerProps, useController } from 'react-hook-form';

import getFieldError from 'shared/form/helpers/getFieldError';
import useMapCustomValidators from 'shared/form/hooks/useMapCustomValidators';
import type { InputProps } from 'shared/form/types';

import { RadioGroup as MantineRadioGroup, type RadioGroupProps as MantineRadioGroupProps } from '@mantine/core';
import { useMergedRef } from '@mantine/hooks';

export type RadioGroupProps<T extends FieldValues> = InputProps<UseControllerProps<T>> &
  Omit<MantineRadioGroupProps, 'value' | 'defaultValue'>;

export const RadioGroup = forwardRef(function RadioGroup<T extends FieldValues>(
  { name, control, defaultValue, shouldUnregister, onChange, ...props }: RadioGroupProps<T>,
  ref: Ref<HTMLInputElement>,
) {
  const rules = useMapCustomValidators(props, true);
  const {
    field: { value, onChange: fieldOnChange, ref: insideRef, ...field },
    fieldState,
  } = useController<T>({
    name,
    control,
    defaultValue,
    rules,
    shouldUnregister,
  });
  const mergedRef = useMergedRef(insideRef, ref);

  return (
    <MantineRadioGroup
      ref={mergedRef}
      value={value}
      onChange={e => {
        fieldOnChange(e);
        onChange?.(name, e);
      }}
      error={getFieldError(fieldState)}
      mb="md"
      {...field}
      {...props}
    />
  );
});
