import { getReportDateRangeName } from 'reporting/selectors/getReportDateRangeName';
import type { WiwState } from 'store';

import { createSelector } from '@reduxjs/toolkit';

export const getCustomReportBuilderTempDateRangeName = (state: WiwState): string | undefined =>
  state.reporting.customReportBuilder.tempDateRangeName;

export const getReportTempDateRangeName = createSelector(
  [getReportDateRangeName, getCustomReportBuilderTempDateRangeName],
  (dateRangeName, tempDateRangeName) => {
    if (tempDateRangeName == null) {
      return dateRangeName;
    }

    return tempDateRangeName;
  },
);
