import ProgressMeter from 'onboarding/components/nav/ProgressMeter';

import 'onboarding/assets/styles/nav/ProgressButton.scss';

interface ProgressButtonProps {
  handleContinueClick: () => void;
  percentComplete: number;
  text: string;
}

const ProgressButton = ({ handleContinueClick, percentComplete, text }: ProgressButtonProps) => (
  <div className="onboarding-progress-menu-item">
    <button type="button" data-testid="continue-button" onClick={handleContinueClick}>
      <ProgressMeter percentComplete={percentComplete} dark={true} />
      <div className="text">
        <span className="nav-name">{text}</span>
        <br />
        <span className="continue">Continue</span>
      </div>
    </button>
  </div>
);
export default ProgressButton;
