import type { PositionFields } from 'data/position/model';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type FetchPositionsRequest =
  | {
      [key: string]: any; // update later with possible params
    }
  | undefined;

type FetchPositionsResponse = {
  positions: PositionFields[];
};

export const fetchPositions = createWiwAsyncThunk<FetchPositionsResponse, FetchPositionsRequest>(
  'position/fetch',
  async (params = {}, { rejectWithValue }) => {
    try {
      return (await Monolith.url('/positions').query(params).get()) as FetchPositionsResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
