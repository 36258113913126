import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { closeAllDialogs } from 'dialogs';
import { getStateDialogs } from 'dialogs/selectors';
import { usePrevious } from 'shared/hooks/usePrevious';
import { useWiwDispatch, useWiwSelector } from 'store';

export default function OpenDialogCloser() {
  const location = useLocation();
  const previousLocation = usePrevious(location);
  const dialogs = useWiwSelector(getStateDialogs);
  const dispatch = useWiwDispatch();

  useEffect(() => {
    if (!dialogs || dialogs.length === 0) {
      return;
    }

    if (location.pathname !== previousLocation?.pathname) {
      dispatch(closeAllDialogs());
    }
  }, [location.pathname]);

  return null;
}
