import PaychexEarnings from './PaychexEarnings';

import { Map, Record } from 'immutable';

export default class PaychexSettings extends Record({
  clientId: null,
  employees: new Map(),
  positions: new Map(),
  earnings: new PaychexEarnings(),
}) {
  constructor(args = {}) {
    super(
      Object.assign({}, args, {
        employees: new Map(args?.employees),
        positions: new Map(args?.positions),
        earnings: new PaychexEarnings(args?.earnings),
      }),
    );
  }
}
