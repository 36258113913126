/* eslint-disable no-console */
import { Component } from 'react';

import { Set, is } from 'immutable';
import { isEqual } from 'lodash';

type StringObject = { [key: string]: any };

/**
 * A base component class that implements shouldComponentUpdate with deeper comparisons.
 * Think of it like PureComponent, but it also understands immutable objects and has some
 * other helpful features.
 *
 * You can use it immediately by extending EqualComponent instead of React.Component. You
 * may configure the behavior of EqualComponent in your subclasses by simply overriding
 * the variables at the top of this file like `debug` and `deepCompareProps`. For example,
 * turning on debug output is as simple as just putting `debug = true` somewhere in
 * your class.
 */
export class EqualComponent<P extends StringObject = {}, S extends StringObject = {}, SS = any> extends Component<
  P,
  S,
  SS
> {
  state: S = {} as S;

  /**
   * Whether to log debug information to the console. This is helpful for identifying when
   * and why component updates are occurring.
   * @type {Boolean}
   */
  debug = false;

  /**
   * If a key from props or state is present in one of these arrays, EqualComponent will
   * do a deep comparison to see if that object should update. This is often useful if
   * the value is a plain old JS object or array.
   */
  deepCompareProps: string[] = [];
  deepCompareStateKeys: string[] = [];

  /**
   * If a key from props or state is present in one of these arrays, EqualComponent will
   * ignore that key when checking for changes.
   *
   * NOTE: All keys that begin with an underscore are ignored, regardless of whether they
   * are specified here.
   */
  ignoreProps: string[] = [];
  ignoreStateKeys: string[] = [];

  numDifferencesInObjects(oldObj: P | S, newObj: P | S, deepKeys: string[], ignoreKeys: string[], name: string) {
    const debugDifferent = (prop: any, oldVal: any, newVal: any) => {
      console.log(`${Object.getPrototypeOf(this).constructor.name}: '${prop}' is different in ${name}`, oldVal, newVal);
    };

    const allKeys = Set.of(...Object.keys(oldObj), ...Object.keys(newObj));
    return allKeys.reduce((reduction, prop) => {
      if (prop.charAt(0) === '_' || ignoreKeys.includes(prop)) {
        return reduction;
      }

      // if the key is missing from either one, it was added or removed
      if (!Object.hasOwn(oldObj, prop) || !Object.hasOwn(newObj, prop)) {
        if (this.debug) {
          debugDifferent(prop, oldObj[prop], newObj[prop]);
        }
        return reduction + 1;
      }

      const oldVal = oldObj[prop];
      const newVal = newObj[prop];

      if (!is(oldVal, newVal)) {
        // We might want to deeply compare the two values and give it a pass anyway
        if (deepKeys.includes(prop) && isEqual(oldVal, newVal)) {
          return reduction;
        }

        if (this.debug) {
          debugDifferent(prop, oldVal, newVal);
        }
        return reduction + 1;
      }

      return reduction;
    }, 0);
  }

  shouldComponentUpdate(nextProps: P, nextState: S) {
    const numChangedProps = this.numDifferencesInObjects(
      this.props,
      nextProps,
      this.deepCompareProps,
      this.ignoreProps,
      'props',
    );
    const numChangedStateKeys = this.numDifferencesInObjects(
      this.state,
      nextState,
      this.deepCompareStateKeys,
      this.ignoreStateKeys,
      'state',
    );

    return numChangedProps > 0 || numChangedStateKeys > 0;
  }
}

export default EqualComponent;
