import { getAuthAccount, getAuthUser } from 'shared/auth/selectors';
import Features from 'shared/features';

import { createSelector } from '@reduxjs/toolkit';

const filterViewDocStorage = (account, user) => {
  if (!account || !user) {
    return false;
  }

  if (
    account.hasFeature(Features.DOC_STORAGE) ||
    user.canManage() ||
    (user.canSupervise() && account.isReverseTrial())
  ) {
    return true;
  }
  return false;
};

// Is the user allowed to visit doc storage in the app
export const canViewDocStorage = createSelector([getAuthAccount, getAuthUser], filterViewDocStorage);
