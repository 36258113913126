import EntityState from 'data/EntityState';
import Tag from 'data/tag/Tag';
import { createTag } from 'data/tag/actions/tags/createTag';
import { deleteTag, deleteTags } from 'data/tag/actions/tags/deleteTag';
import { fetchTag } from 'data/tag/actions/tags/fetchTag';
import { fetchTags } from 'data/tag/actions/tags/fetchTags';
import { updateTag } from 'data/tag/actions/tags/updateTag';
import generateThunkReducers from 'data/util/generateThunkReducers';
import { optimisticallyDeleteEntity, optimisticallyUpdateEntity } from 'data/util/optimisticUpdate';
import { deleteEntityItems, mergeEntityItems } from 'data/util/sliceHelpers';

import { createSlice } from '@reduxjs/toolkit';

const initialState = new EntityState();

export const tagSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchTags.fulfilled, (state, action) => {
        return mergeEntityItems(state, action.payload.data, Tag);
      })
      .addCase(fetchTag.fulfilled, (state, action) => {
        return mergeEntityItems(state, [action.payload.data], Tag);
      })
      .addCase(deleteTags.fulfilled, (state, action) => {
        return deleteEntityItems(state, action, 'id');
      });
    optimisticallyDeleteEntity(builder, deleteTag);
    optimisticallyUpdateEntity(builder, createTag, Tag, { payloadPath: 'data' });
    optimisticallyUpdateEntity(builder, updateTag, Tag, { payloadPath: 'data' });
    generateThunkReducers(builder, 'tags');
  },
});

export default tagSlice.reducer;
