import PropTypes from 'prop-types';
import type { ComponentProps, HTMLProps } from 'react';
import { NavLink } from 'react-router-dom';

import { isEmpty } from 'lodash';

/**
 * route regex pattern => is monolith route
 */
export const MAPPING = {
  '/#attendance-trial': true,
  '/#scheduler-trial': true,
  '/#clock-in': true,
  '/#take-lunch': true,
  '/account': true,
  '/account/cancel': true,
  '/account/deactivate': true,
  '/attendance': false,
  '/attendance/setup': false,
  '/payroll': false,
  '/timeclock': false,

  '/locations': false,
  '/positions': false,
  '/employees': false,
  '/sites': false,
  '/blocks': false,

  '/requests': true,
  '/requests/time-off': false,
  '/requests/shift': false,
  '/requests/swap': false,
  '/requests/openshift': false,
  '/requests/absences': false,

  '/myschedule': false,
  '/availability': false,

  '/settings': false,
  '/settings/attendance': false,
  '/settings/scheduling': false,
  '/settings/integrations': false,
  '/settings/saml': false,

  '/login': true,
  '/login/profile': true,
  '/login/create/account': true,

  '/integration/adprun': true,
  '/integration/adprun/company': true,
  '/integration/adpworkforcenow': true,
  '/integration/adpworkforcenow/company': true,
  '/integration/qbo': true,
  '/integration/square': false,
  '/integration/zenpayroll': true,
};

type RedirectRoute = keyof typeof MAPPING;

const redirectRoutes = Object.keys(MAPPING) as RedirectRoute[];

/**
 * Looks up a route (or full URL) in our list of redirect mappings. If the
 * route should take the user outside the app, it returns the full URL to send
 * the user to. Otherwise, it returns false, indicating that it should be used
 * as a normal react-router link.
 */
export const findRedirectRoute = (route: string): string | false => {
  if (route === '#') {
    // If the link does not go anywhere do not force a redirect.
    return route;
  }

  const isExternal = /(http(s?)):\/\//i;
  if (isExternal.test(route)) {
    return route;
  }

  // Strip trailing slashes
  route = route.replace(/\/+$/, '');

  const matches = redirectRoutes.filter(pattern => route.match(new RegExp(pattern)));

  if (!isEmpty(matches)) {
    const key = matches.pop()!;
    const isMonolithRoute = MAPPING[key];

    if (isMonolithRoute) {
      return `${CONFIG.APP_LEGACY_ROOT}${route}`;
    }
  }

  return false;
};

type SmartLinkProps = { to: string } & Omit<ComponentProps<NavLink>, 'to'> & HTMLProps<HTMLAnchorElement>;

export const SmartLink = (props: SmartLinkProps) => {
  const { to } = props;

  const route = findRedirectRoute(to);
  if (route) {
    return <a {...props} href={route} />;
  }

  const newProps = { ...props };
  if (to !== '#') {
    newProps.activeClassName = 'active';
  }
  return <NavLink {...newProps} />;
};

SmartLink.propTypes = {
  to: PropTypes.string.isRequired, //TODO: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

SmartLink.displayName = 'SmartLink';
