import { bindActionCreators } from '@reduxjs/toolkit';
import { List, Map } from 'immutable';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import { getLocations } from 'data/location/selectors';
import { getPositions } from 'data/position/selectors';
import { TwilioProvider } from 'workchat/TwilioProvider';
import {
  clearTrackedFilters,
  popScreen,
  pushScreen,
  setPendingConversation,
  trackFilterUse,
  updateTrackedFilters,
} from 'workchat/actions';
import Loading from 'workchat/components/Loading';
import UserPicker from 'workchat/components/UserPicker';
import { withTwilioProvider } from 'workchat/components/withTwilioProvider';
import StartConversation from 'workchat/containers/StartConversation';
import ViewConversation from 'workchat/containers/ViewConversation';

import FontIcon from 'shared/ui/FontIcon';
import 'workchat/styles/NewConversation.scss';
import Header from 'workchat/containers/Header';

class NewConversation extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    provider: PropTypes.instanceOf(TwilioProvider).isRequired,
    users: PropTypes.instanceOf(Map).isRequired,
    locations: PropTypes.instanceOf(Map).isRequired,
    positions: PropTypes.instanceOf(Map).isRequired,
    pending: PropTypes.object,

    popScreen: PropTypes.func.isRequired,
    pushScreen: PropTypes.func.isRequired,
    setPendingConversation: PropTypes.func.isRequired,
    trackFilterUse: PropTypes.func.isRequired,
    updateTrackedFilters: PropTypes.func.isRequired,
    clearTrackedFilters: PropTypes.func.isRequired,
  };

  static defaultProps = {
    pending: null,
  };

  state = {
    title: '',
    selectedUsers: new List(),
  };

  componentDidMount() {
    const { pending } = this.props;
    if (pending?.title) {
      this.setState({ title: pending.title });
    }
  }

  startConversation = e => {
    if (e) {
      e.preventDefault();
    }

    const { selectedUsers } = this.state;
    if (!selectedUsers.size) {
      return;
    }

    const { provider, popScreen, pushScreen, setPendingConversation, trackFilterUse, clearTrackedFilters } = this.props;

    popScreen();
    pushScreen(Loading);

    const addedIds = selectedUsers.toJS();
    provider.checkForConversation(addedIds).then(result => {
      const { exists, channel } = result;

      popScreen();
      if (exists) {
        provider.setConversation(channel);
        trackFilterUse(false, 'CreateChannel');
        clearTrackedFilters();
        pushScreen(ViewConversation);
      } else {
        setPendingConversation(selectedUsers, this.state.title);
        pushScreen(StartConversation);
      }
    });
  };

  back = e => {
    if (e) {
      e.preventDefault();
    }

    const { popScreen, setPendingConversation, trackFilterUse, clearTrackedFilters } = this.props;

    popScreen();
    trackFilterUse(false, 'CreateChannel');
    clearTrackedFilters();
    setPendingConversation();
  };

  titleChange = e => {
    this.setState({ title: e.target.value });
  };

  setSelectedUsers = selectedUsers => {
    this.setState({ selectedUsers });
  };

  render() {
    const { user, account, users, locations, positions, updateTrackedFilters, pending } = this.props;
    const { title, selectedUsers } = this.state;
    const disabled = !selectedUsers.size;

    const buttons = [
      {
        title: 'Back',
        action: this.back,
        label: [<FontIcon className="mr-1" key="back" icon="chevron-left" />, 'Back'],
        side: 'left',
        color: 'secondary',
      },
      { className: 'big', action: this.startConversation, label: 'Next', disabled, side: 'right', color: 'primary' },
    ];

    return (
      <div className="new-conversation">
        <Header buttons={buttons}>New Channel</Header>
        <div className="conversation-name">
          <div className="name-container">
            <label htmlFor="channelTitle" className="sr-only">
              Channel Name
            </label>
            <input
              type="text"
              name="channelTitle"
              placeholder="Name this channel (optional)"
              onChange={this.titleChange}
              value={title}
              ref={input => {
                this.titleInput = input;
              }}
            />
          </div>
        </div>
        <UserPicker
          user={user}
          account={account}
          users={users}
          setUsers={this.setSelectedUsers}
          prefill={pending}
          locations={locations}
          positions={positions}
          enableFilters={true}
          updateTrackedFilters={updateTrackedFilters}
        />
      </div>
    );
  }
}

export default connect(
  // State to send to the Component as props
  state => ({
    users: state.workchat.get('users') || new Map(),
    locations: getLocations(state),
    positions: getPositions(state),
    pending: state.workchat.get('pendingConversation') || null,
  }),
  // Dispatch functions to send to the Component as props
  dispatch =>
    bindActionCreators(
      {
        popScreen,
        pushScreen,
        setPendingConversation,
        trackFilterUse,
        updateTrackedFilters,
        clearTrackedFilters,
      },
      dispatch,
    ),
)(withTwilioProvider(NewConversation));
