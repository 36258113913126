import type { DeviceFields } from 'data/devices/model';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

export interface DevicesResponse {
  devices: DeviceFields[];
}

export default createWiwAsyncThunk<DevicesResponse>('devices/fetchDevices', async (_, { rejectWithValue }) => {
  try {
    return await Monolith.get('/devices');
  } catch (error) {
    return rejectWithValue(parseAPIError(error));
  }
});
