import type { PlanFields } from 'data/plan/model';
import Monolith from 'shared/api/monolith';
import { createWiwAsyncThunk } from 'store';

type FetchPlansResponse = {
  plans: PlanFields[];
  features: string[];
};

export const fetchPlans = createWiwAsyncThunk<FetchPlansResponse, string | object | undefined>(
  'plan/fetchPlans',
  async (options = {}, { rejectWithValue }) => {
    try {
      return (await Monolith.options({ skipCache: true }).query(options).get('/plans')) as FetchPlansResponse;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export default fetchPlans;
