import { lazy } from 'react';

const UpdateAvailabilityConfirmationDialog = lazy(
  () => import('availability/dialogs/UpdateAvailabilityConfirmationDialog'),
);
const DeleteAvailabilityConfirmationDialog = lazy(
  () => import('availability/dialogs/DeleteAvailabilityConfirmationDialog'),
);

// Identifiers for each dialog
export const UPDATE_AVAILABILITY_CONFIRMATION_DIALOG = 'UPDATE_AVAILABILITY_CONFIRMATION_DIALOG';
export const DELETE_AVAILABILITY_CONFIRMATION_DIALOG = 'DELETE_AVAILABILITY_CONFIRMATION_DIALOG';

const dialogs = {
  [UPDATE_AVAILABILITY_CONFIRMATION_DIALOG]: UpdateAvailabilityConfirmationDialog,
  [DELETE_AVAILABILITY_CONFIRMATION_DIALOG]: DeleteAvailabilityConfirmationDialog,
  // Add more here ...
};

export default dialogs;
