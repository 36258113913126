import { HourStatsRequests } from 'data/hourStats/models';
import { HourStatsRequestsState } from 'data/hourStats/states';
import { DELETE_HOUR_STATS_REQUESTS, FETCH_HOUR_STATS_REQUESTS } from 'store/action-types';

export default (state = new HourStatsRequestsState(), action) => {
  switch (action.type) {
    case FETCH_HOUR_STATS_REQUESTS.REQUEST:
      return state.merge({
        loading: true,
        receivedAt: 0,
      });
    case FETCH_HOUR_STATS_REQUESTS.SUCCESS:
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        receivedAt: Date.now(),
        items: state.items.merge(
          action.payload.map(hourStats => {
            return [`${hourStats.id}-${hourStats.start}-${hourStats.end}`, new HourStatsRequests(hourStats)];
          }),
        ),
      });
    case FETCH_HOUR_STATS_REQUESTS.FAILURE:
      return state.merge({
        loading: false,
        error: action.payload,
      });
    case DELETE_HOUR_STATS_REQUESTS:
      return state.merge({
        items: state.items.filterNot(hourStats =>
          action.ids.includes(`${hourStats.id}-${hourStats.start}-${hourStats.end}`),
        ),
      });
    default:
      return state;
  }
};
