import { Map, Record } from 'immutable';

export default class IntegrationMap extends Record({
  accountId: null,
  kind: '',
  type: '',
  keyId: '',
  companyId: '',
  localId: '',
  data: new Map(),
  createdAt: null,
  updatedAt: null,
}) {}
