import stripeCustomers from 'data/stripe/customers';
import stripeInvoices from 'data/stripe/invoices';
import stripePlans from 'data/stripe/plans';
import stripeSubscriptions from 'data/stripe/subscriptions';

import { combineReducers } from '@reduxjs/toolkit';

export default combineReducers({
  plans: stripePlans,
  subscriptions: stripeSubscriptions,
  customers: stripeCustomers,
  invoices: stripeInvoices,
});
