import type Account from 'data/account/model';
import Monolith from 'shared/api/monolith';
import { switchWorkplace } from 'shared/auth/actions';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type CancelAccountParams = {
  reason: any;
  feedback: any;
  accountId: Account['id'];
};

type CancelAccountResponse = {
  hasAnother: boolean;
};

export const cancelAccount = createWiwAsyncThunk<void, CancelAccountParams>(
  'account/cancel',
  async (params, { rejectWithValue }) => {
    try {
      const { reason, feedback } = params;
      const response = (await Monolith.url('/account/cancel').post({ reason, feedback })) as CancelAccountResponse;
      if (response.hasAnother) {
        return switchWorkplace();
      }
      return window.location.assign('https://wheniwork.com/thank-you');
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
