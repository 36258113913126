import { type CountryCode, type NumberFormat, isValidNumberForRegion, parsePhoneNumber } from 'libphonenumber-js';

export class PhoneUtil {
  defaultCountry: CountryCode;
  defaultFormat: NumberFormat;

  constructor() {
    this.defaultCountry = 'US';
    this.defaultFormat = 'E.164';
  }

  /**
   *
   * @param country
   */
  set country(country: CountryCode) {
    this.defaultCountry = country;
  }

  /**
   *
   * @param format
   */
  set phoneFormat(format: PhoneUtil['defaultFormat']) {
    this.defaultFormat = format;
  }

  /**
   *
   * @param number
   * @param country
   * @returns {*}
   */
  parse(number: string, country = this.defaultCountry) {
    return parsePhoneNumber(number, country);
  }

  /**
   *
   * @param number
   * @param country
   * @returns {boolean}
   */
  isValidNumber(number: string, country = this.defaultCountry) {
    try {
      const parsed = parsePhoneNumber(number, country || this.defaultCountry);
      return parsed.isValid();
    } catch (e) {
      return false;
    }
  }

  /**
   *
   * @param number
   * @param format
   * @param country
   * @returns {*}
   */
  format(number: string, format = this.defaultFormat, country = this.defaultCountry) {
    try {
      const parsed = this.parse(number, country);
      return parsed.format(format);
    } catch (e) {
      return number;
    }
  }

  /**
   * Format number in local country format if both users are from the
   * same country, otherwise use international format.
   */
  formatLocal(number: string, country = this.defaultCountry) {
    try {
      const parsed = this.parse(number, country);

      if (isValidNumberForRegion(parsed.nationalNumber, country)) {
        return parsed.formatNational();
      }
      return parsed.formatInternational();
    } catch (e) {
      return number;
    }
  }

  formatServer(number: string, country = this.defaultCountry) {
    return this.format(number, 'E.164', country);
  }
}

const PhoneUtilObj = new PhoneUtil();

export default PhoneUtilObj;
