import PropTypes from 'prop-types';
import { Component, createRef } from 'react';
import { connect } from 'react-redux';

import { setSearchTerm } from 'data/notifications/actions';
import { searchSelector } from 'data/notifications/selectors';

import { bindActionCreators } from '@reduxjs/toolkit';
import { debounce } from 'lodash';

import 'notifications/styles/NotificationSearch.scss';

export class NotificationSearch extends Component {
  searchRef = createRef();

  static propTypes = {
    setSearchTerm: PropTypes.func.isRequired,
    search: PropTypes.string,
  };

  componentDidMount() {
    if (this.searchRef?.current) {
      this.searchRef.current.focus();
      this.searchRef.current.value = this.props.search;
    }
  }

  updateSearchTerm = debounce(value => {
    this.props.setSearchTerm(value);
  }, 250);

  handleSearchChange = e => {
    e.stopPropagation();
    this.updateSearchTerm(e.target.value);
  };

  render() {
    return (
      <div className="form-text notification-search">
        <i className="fa fa-search" />
        <input
          ref={this.searchRef}
          onChange={this.handleSearchChange}
          type="text"
          className="notif-search"
          placeholder="Search Recent Activity"
        />
      </div>
    );
  }
}

export default connect(
  state => ({
    search: searchSelector(state),
  }),
  dispatch =>
    bindActionCreators(
      {
        setSearchTerm,
      },
      dispatch,
    ),
)(NotificationSearch);
