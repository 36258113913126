import { createSelector } from '@reduxjs/toolkit';

import { getAuthUser } from 'shared/auth/selectors';
import type { WiwState } from 'store';
import { getConversationEntityMap } from 'workchat/v2/store/conversations/conversationsSelectors';
import type { WorkchatState } from 'workchat/v2/store/reducer';

export const getWorkchatState = (state: WiwState) => state.workchatv2;

export const getWorkchatAuthState = createSelector(
  [getWorkchatState],
  (workchatState: WorkchatState) => workchatState.auth,
);

export const getWorkchatInitError = createSelector([getWorkchatState], (state: WorkchatState) => state.initError);

export const getWorkchatConnected = createSelector([getWorkchatState], (state: WorkchatState) => state.connected);
export const getWorkchatShow = createSelector([getWorkchatState], (workchatState: WorkchatState) => workchatState.show);
export const getWorkchatError = createSelector(
  [getWorkchatState],
  (workchatState: WorkchatState) => workchatState.error,
);

export const getWorkchatFlags = createSelector(
  [getWorkchatState],
  (workchatState: WorkchatState) => workchatState.flags,
);

export const getMessageReaders = createSelector(
  [getWorkchatState],
  (workchatState: WorkchatState) => workchatState.messageReaders,
);

export const getWorkchatView = createSelector([getWorkchatState], (workchatState: WorkchatState) =>
  workchatState.views.first(),
);

export const getWorkchatSelectedConversationId = createSelector(
  [getWorkchatState],
  (workchatState: WorkchatState) => workchatState.conversation,
);

export const getWorkchatSelectedConversation = createSelector(
  [getConversationEntityMap, getWorkchatSelectedConversationId],
  (conversations, conversationId) => conversations.get(conversationId),
);

export const getWorkchatAuthToken = createSelector([getWorkchatAuthState, getAuthUser], (workchatAuth, user) => {
  if (workchatAuth.token) {
    return workchatAuth.token;
  }

  let session: string | null;
  let sessionObj: { token: string; user_id: number };
  let token: string | null;

  try {
    session = localStorage.getItem('twilio_session');
    sessionObj = session && JSON.parse(session);
    token = sessionObj && sessionObj.user_id === user.id ? sessionObj.token : null;

    if (token) {
      return token;
    }
  } catch (_error) {
    console.warn('Pre-existing Workchat token unavailable. Re-authenticating.');
  }

  return null;
});

export const getWorkchatPendingConversation = createSelector(
  [getWorkchatState],
  workchatState => workchatState.pendingConversation,
);
