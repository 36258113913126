import BaseModel from 'data/BaseModel';

interface MiscellaneousDeductionFields {
  total_amount: string;
  amount: string;
  percent: number;
  annual_limit: string;
}

interface ChildSupportDeductionFields {
  external_id: string;
  agency: string;
  fips_code: string;
  issue_date: string | null;
  amount: number;
  max_percent: string;
}

export interface PostTaxDeductionItem {
  id: string;
  type: string;
  employee: string;
  description: string;
  effective_start: string | null;
  effective_end: string | null;
  miscellaneous: MiscellaneousDeductionFields | null;
  child_support: ChildSupportDeductionFields | null;
  managed: boolean;
  metadata: {
    payroll_id?: string | undefined;
    applied_amount?: string | undefined;
  };
}

export enum PostTaxDeductionTypes {
  MISCELLANEOUS = 'miscellaneous',
  CHILD_SUPPORT = 'child_support',
}

export default class PostTaxDeduction extends BaseModel<PostTaxDeductionItem>({
  id: '',
  type: '',
  employee: '',
  description: '',
  effective_start: null,
  effective_end: null,
  miscellaneous: null,
  child_support: null,
  managed: false,
  metadata: {},
}) {
  employeeAmount() {
    if (this.miscellaneous) {
      if (this.miscellaneous.percent) {
        return `${this.miscellaneous.percent}%`;
      }
      return `$${this.miscellaneous.amount}`;
    }
    if (this.child_support) {
      return `$${this.child_support.amount}`;
    }

    return 'N/A';
  }
}
