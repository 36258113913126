import { lazy } from 'react';

import { closeDialog, openDialog } from 'dialogs';
import GeneralDialogs from 'settings/modules/general/dialogs';
import ConfirmRequestTypeAttendanceDialog from './ConfirmRequestTypeAttendanceDialog';
import ConfirmRequestTypeDeleteDialog from './ConfirmRequestTypeDeleteDialog';

const VerifyAccountLocationDialog = lazy(() => import('./VerifyAccountLocationDialog'));
const VerifyScheduleLocationDialog = lazy(() => import('./VerifyScheduleLocationDialog'));
const SetStartOfWeekDialog = lazy(() => import('./SetStartOfWeekDialog'));
const ConfirmLocationsDialog = lazy(() => import('./ConfirmLocationsDialog'));
const UpdateRoleSettingsExceptionsDialog = lazy(() => import('./UpdateRoleSettingsExceptionsDialog'));
const ConfirmPayPeriodChangeDialog = lazy(() => import('./ConfirmPayPeriodChangeDialog'));
const WebhooksFeedbackDialog = lazy(() => import('./WebhooksFeedbackDialog'));
const ConfirmRoundingDialog = lazy(() => import('./ConfirmRoundingDialog'));
const ConfirmTipsTrackingDialog = lazy(() => import('./ConfirmTipsTrackingDialog'));
const PositionsTrackingTipsDialog = lazy(() => import('./PositionsTrackingTipsDialog'));
const EditAccrualPolicyDialog = lazy(() => import('./EditAccrualPolicyDialog'));

export const VERIFY_ACCOUNT_LOCATION_DIALOG = 'VERIFY_ACCOUNT_LOCATION_DIALOG';
export const VERIFY_SCHEDULE_LOCATION_DIALOG = 'VERIFY_SCHEDULE_LOCATION_DIALOG';
export const SET_START_OF_WEEK_DIALOG = 'SET_START_OF_WEEK_DIALOG';
export const CONFIRM_LOCATIONS_DIALOG = 'CONFIRM_LOCATIONS_DIALOG';
export const CONFIRM_REQUEST_TYPE_DELETE_DIALOG = 'CONFIRM_REQUEST_TYPE_DELETE_DIALOG';
export const CONFIRM_REQUEST_TYPE_ATTENDANCE_DIALOG = 'CONFIRM_REQUEST_TYPE_ATTENDANCE_DIALOG';
export const UPDATE_ROLE_SETTINGS_EXCEPTIONS_DIALOG = 'UPDATE_ROLE_SETTINGS_EXCEPTIONS_DIALOG';
export const CONFIRM_PAYPERIOD_CHANGE_DIALOG = 'CONFIRM_PAYPERIOD_CHANGE_DIALOG';
export const WEBHOOKS_FEEDBACK_DIALOG = 'WEBHOOKS_FEEDBACK_DIALOG';
export const CONFIRM_ROUNDING_DIALOG = 'CONFIRM_ROUNDING_DIALOG';
export const CONFIRM_TIPS_TRACKING_DIALOG = 'CONFIRM_TIPS_TRACKING_DIALOG';
export const POSITIONS_TRACKING_TIPS_DIALOG = 'POSITIONS_TRACKING_TIPS_DIALOG';

export const EDIT_ACCRUAL_POLICY_DIALOG = 'EDIT_ACCRUAL_POLICY_DIALOG';

export const openConfirmLocationsDialog = openDialog.bind(null, CONFIRM_LOCATIONS_DIALOG);
export const closeConfirmLocationsDialog = closeDialog.bind(null, CONFIRM_LOCATIONS_DIALOG);

const dialogs = {
  [VERIFY_ACCOUNT_LOCATION_DIALOG]: VerifyAccountLocationDialog,
  [VERIFY_SCHEDULE_LOCATION_DIALOG]: VerifyScheduleLocationDialog,
  [SET_START_OF_WEEK_DIALOG]: SetStartOfWeekDialog,
  [CONFIRM_LOCATIONS_DIALOG]: ConfirmLocationsDialog,
  [CONFIRM_REQUEST_TYPE_DELETE_DIALOG]: ConfirmRequestTypeDeleteDialog,
  [CONFIRM_REQUEST_TYPE_ATTENDANCE_DIALOG]: ConfirmRequestTypeAttendanceDialog,
  [UPDATE_ROLE_SETTINGS_EXCEPTIONS_DIALOG]: UpdateRoleSettingsExceptionsDialog,
  [CONFIRM_PAYPERIOD_CHANGE_DIALOG]: ConfirmPayPeriodChangeDialog,
  [WEBHOOKS_FEEDBACK_DIALOG]: WebhooksFeedbackDialog,
  ...GeneralDialogs,
  [CONFIRM_ROUNDING_DIALOG]: ConfirmRoundingDialog,
  // ...
  [CONFIRM_TIPS_TRACKING_DIALOG]: ConfirmTipsTrackingDialog,
  [POSITIONS_TRACKING_TIPS_DIALOG]: PositionsTrackingTipsDialog,
  [EDIT_ACCRUAL_POLICY_DIALOG]: EditAccrualPolicyDialog,
};

export default dialogs;
