import Position from 'data/position/model';
import type { EntityMap } from 'data/types';
import type { WiwState } from 'store';

import { createSelector } from '@reduxjs/toolkit';

export const getStatePositions = (state: WiwState) => state.data.position.items as EntityMap<Position>;

export const getActivePositions = createSelector([getStatePositions], allPositions => {
  return allPositions.filter(position => !position.is_deleted) as EntityMap<Position>;
});

export const getOrderedPositions = createSelector([getActivePositions], allPositions => {
  return allPositions.sort((a, b) => a.name.localeCompare(b.name));
});

export const getPositions = getActivePositions;

export const getPositionsByLocation = createSelector(getActivePositions, positions =>
  positions.groupBy(position => position.location_id),
);

export const positionsLoading = (state: WiwState) => state.data.position.loading;
export const positionsLoaded = (state: WiwState) => state.data.position.loaded && !positionsLoading(state);

export const mustGetPosition = createSelector(
  [getPositions, (_: WiwState, positionId: number | undefined) => positionId],
  (positions: EntityMap<Position>, positionId: number | undefined): Position => {
    if (positionId) {
      return positions.get(positionId, new Position());
    }
    return new Position();
  },
);
