import EntityState from 'data/EntityState';
import FreeTrial from 'data/freetrial/model';
import { FETCH_FREE_TRIAL_STATUS } from 'store/action-types';

const defaultState = new EntityState({
  items: new FreeTrial(),
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_FREE_TRIAL_STATUS.REQUEST:
      return state.merge({
        loaded: false,
        loading: true,
        error: null,
      });
    case FETCH_FREE_TRIAL_STATUS.SUCCESS:
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        items: new FreeTrial(action.payload),
      });
    case FETCH_FREE_TRIAL_STATUS.FAILURE:
      return state.merge({
        loaded: false,
        loading: false,
        error: action.payload,
      });
    default:
      return state;
  }
};
