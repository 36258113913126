import { getLocations, getUsersLocationsSortedByName } from 'data/location/selectors';
import OpenShiftRequests from 'data/openshiftRequests/model';
import User from 'data/user/model';
import { getUsers } from 'data/user/selectors';
import { getAuthAccount, getAuthUser } from 'shared/auth/selectors';

import { createSelector } from '@reduxjs/toolkit';
import { Map } from 'immutable';

const getStateOpenShiftRequests = state => state.data.openShiftRequests.items;

export const getOpenShiftRequests = getStateOpenShiftRequests;

export const getLaborSharedOpenShiftLocations = createSelector(
  [
    getOpenShiftRequests,
    state => getAuthAccount(state),
    state => getAuthUser(state),
    state => getLocations(state),
    state => getUsersLocationsSortedByName(state),
  ],
  (osaRequests, account, authUser, locations, userLocations) => {
    let laborSharedLocs = new Map();
    if (account.getSettings('schedule.shared_openshifts') && !authUser.canManage()) {
      osaRequests.map(request => {
        if (request.userRequests.has(authUser.id)) {
          const loc = request.shiftModel.get('location_id', 0);
          if (loc && locations.has(loc) && !userLocations.has(loc)) {
            laborSharedLocs = laborSharedLocs.set(loc, locations.get(loc));
          }
        }
      });
    }

    return laborSharedLocs;
  },
);

export const getActionableOpenShiftRequests = createSelector([getOpenShiftRequests], requests =>
  requests.filter(request => {
    return (
      request.status === OpenShiftRequests.Status.PENDING &&
      request.user_requests.length > 0 &&
      request.user_requests.some(userRequest => userRequest.deleted_at === null)
    );
  }),
);

export const getFilteredActionableOpenShiftRequests = createSelector(
  [
    getActionableOpenShiftRequests,
    state => state.requests.getIn(['filters', OpenShiftRequests.ReduxDataNames.SCHEDULES]),
  ],
  (osaRequests, schedules) =>
    osaRequests.filter(request => {
      return schedules.includes(request.shiftModel.get('location_id', 0));
    }),
);

export const getFilteredOpenShiftRequests = createSelector(
  [
    getOpenShiftRequests,
    state => state.requests.getIn(['filters', OpenShiftRequests.ReduxDataNames.STATUSES]),
    state => state.requests.getIn(['filters', OpenShiftRequests.ReduxDataNames.SCHEDULES]),
    state => getAuthAccount(state),
    state => getAuthUser(state),
    state => getUsers(state),
  ],
  (osaRequests, statuses, schedules, account, authUser, users) =>
    osaRequests
      .map(request => {
        // hide user requests when the account setting is off.
        if (!account.getSettings('schedule.shared_openshifts')) {
          const userRequests = request.userRequests.filter(item => {
            if (!request.is_shared) {
              return true;
            }
            const user = users.get(item.user_id, new User());
            return user.locations.includes(request.shift.location_id);
          });

          request = request.set('user_requests', userRequests.toArray());
        }

        return request;
      })
      .filter(
        request => statuses.includes(request.status) && schedules.includes(request.shiftModel.get('location_id', 0)),
      ),
);
