import { type Ref, forwardRef } from 'react';
import { type FieldValues, type UseControllerProps, useController } from 'react-hook-form';

import getFieldError from 'shared/form/helpers/getFieldError';
import useMapCustomValidators from 'shared/form/hooks/useMapCustomValidators';
import type { InputProps } from 'shared/form/types';

import {
  CheckboxGroup as MantineCheckboxGroup,
  type CheckboxGroupProps as MantineCheckboxGroupProps,
} from '@mantine/core';
import { useMergedRef } from '@mantine/hooks';

export type CheckboxGroupProps<T extends FieldValues> = InputProps<UseControllerProps<T>> &
  Omit<MantineCheckboxGroupProps, 'checked' | 'defaultValue'>;

export const CheckboxGroup = forwardRef(function CheckboxGroup<T extends FieldValues>(
  { name, control, defaultValue, shouldUnregister, onChange, ...props }: CheckboxGroupProps<T>,
  ref: Ref<HTMLInputElement>,
) {
  const rules = useMapCustomValidators(props, true);

  const {
    field: { value, onChange: fieldOnChange, ref: insideRef, ...field },
    fieldState,
  } = useController<T>({
    name,
    control,
    defaultValue,
    rules,
    shouldUnregister,
  });

  const mergedRef = useMergedRef(insideRef, ref);

  return (
    <MantineCheckboxGroup
      ref={mergedRef}
      error={getFieldError(fieldState)}
      value={value}
      onChange={e => {
        fieldOnChange(e);
        onChange?.(name, e);
      }}
      mb="md"
      {...field}
      {...props}
    />
  );
});
