import EntityState from 'data/EntityState';
import { CREATE_TEAM_MESSAGE, PURCHASE_CREDITS } from 'store/action-types';

export default (state, action) => {
  if (state === undefined) {
    state = new EntityState();
  }

  switch (action.type) {
    case CREATE_TEAM_MESSAGE.REQUEST:
      return state.merge({
        loading: true,
        loaded: false,
      });
    case CREATE_TEAM_MESSAGE.SUCCESS:
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        receivedAt: Date.now(),
      });
    case CREATE_TEAM_MESSAGE.FAILURE:
      return state.merge({
        loaded: false,
        loading: false,
        error: action.payload,
      });
    case PURCHASE_CREDITS.REQUEST:
      return state.merge({
        loading: true,
        loaded: false,
      });
    case PURCHASE_CREDITS.SUCCESS:
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        items: action.items,
        receivedAt: Date.now(),
      });
    case PURCHASE_CREDITS.FAILURE:
      return state.merge({
        loaded: false,
        loading: false,
        error: action.payload,
      });
    default:
      return state;
  }
};
