import type { ValidationRule } from 'react-hook-form';

import getValueAndMessage from 'shared/form/util/getValueAndMessage';
import PhoneUtil from 'shared/util/phoneutil';

export default (config: ValidationRule) => (value: any) => {
  // @ts-ignore
  const { countryCode, message = '' } = getValueAndMessage(config);
  if (!value || !countryCode) {
    return true;
  }

  return PhoneUtil.isValidNumber(value, countryCode) || message;
};
