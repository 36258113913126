import type { ReactNode } from 'react';

import FontIcon from 'shared/ui/FontIcon';

import classnames from 'classnames';

import 'shared/ui/assets/styles/WarningBanner.scss';

type Props = {
  children: ReactNode;
  wrapperClass?: string;
  icon?: string;
  level?: 'warning' | 'error';
};

export default function WarningBanner({ children, wrapperClass, icon = 'alert', level = 'warning' }: Props) {
  const className = classnames('warning-banner', wrapperClass);
  return (
    <div className={className}>
      <FontIcon icon={icon} className={classnames('lg', level)} />
      <div className="notice">{children}</div>
    </div>
  );
}
