import type { DialogContextProps } from 'dialogs';
import useCheckComponentHandler from 'shared/hooks/useCheckComponent';

import 'payrollCheck/modules/onboarding/dialogs/assets/styles/OnboardingPageDialogs.scss';
import { useState } from 'react';
import { OnboardComponent } from '../PayrollCheckOnboarding';
import { CardState } from '../components/PayrollOnboardCard';

interface Props extends DialogContextProps {
  url: string;
  callback: (state: CardState, component: OnboardComponent) => void;
}

export default function CheckCompanyOnboardDialog({ closeDialog, url, callback: componentEvent }: Props) {
  const [loading, setLoading] = useState(true);

  const handleOnEvent = (event: string, _: any) => {
    switch (event) {
      case 'check-onboard-app-loaded': {
        if (loading) {
          setLoading(false);
          componentEvent(CardState.started, OnboardComponent.onboard);
        }
        break;
      }
      case 'check-onboard-app-completed': {
        componentEvent(CardState.completed, OnboardComponent.onboard);
      }
    }
  };

  const handleOnClose = () => {
    closeDialog();
  };

  const [setTarget] = useCheckComponentHandler({
    url,
    onEvent: handleOnEvent,
    onClose: handleOnClose,
    onboard: true,
  });

  return <div ref={setTarget} />;
}
