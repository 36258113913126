import defaultRequestConfig from 'shared/api/adapters/wretch/defaultRequestConfig';
import Login from 'shared/api/login';
import { getStepUpToken, getStepUpTokenInfo, removeStepUpToken, setStepUpToken } from 'shared/auth/auth';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';

interface Response {
  token: string;
}

export const fetchStepUpRefreshToken = async (): Promise<Response> => {
  try {
    const tokenInfo = getStepUpTokenInfo();
    if (!tokenInfo?.isValidStepUpToken()) {
      removeStepUpToken();
      throw parseAPIError('invalid token for this request');
    }

    const options = defaultRequestConfig();
    options.headers['W-Token'] = getStepUpToken();
    options.headers.Authorization = getStepUpToken();

    const { token } = await Login.options({ ...options })
      .url('/refresh')
      .post();
    setStepUpToken(token);

    return {
      token,
    };
  } catch (error) {
    throw parseAPIError(error);
  }
};
