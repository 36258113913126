import { getCustomMetadata } from 'data/reporting/selectors/getCustomMetadata';
import type { CustomMetadataState } from 'data/reporting/state/CustomMetadataState';
import { getReportTempAccountId } from 'reporting/selectors/getReportTempAccountId';
import { getReportTempDataset } from 'reporting/selectors/getReportTempDataset';

import { createSelector } from '@reduxjs/toolkit';
import type { DateTime } from 'luxon';

export const getReportingPeriodEndTemp = createSelector(
  [getCustomMetadata, getReportTempAccountId, getReportTempDataset],
  (metadata, reportAccountId, dataset): DateTime | null => {
    return (metadata.getIn([`${reportAccountId}`, dataset]) as CustomMetadataState)?.reportingPeriodEnd;
  },
);
