import type Plan from 'data/plan/model';
import type { PlanFields } from 'data/plan/model';
import type { EntityMap } from 'data/types';
import type { WiwState } from 'store';

import { createSelector } from '@reduxjs/toolkit';

export { getAvailablePlans, getCheapestAvailablePlan } from './availablePlans';
export { getPlans } from './plans';
export { getRecommendedPlanWithFeature } from './recommendedPlan';
export { getUpgradeToPlan } from './upgradeToPlan';

export const mustGetPlans: (state: WiwState) => EntityMap<Plan, PlanFields['id']> = createSelector(
  (state: WiwState) => state.data.plan.items,
  plans => {
    if (!plans) {
      throw new Error('Plans not found');
    }
    return plans;
  },
);
