import PropTypes from 'prop-types';
import { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import Account from 'data/account/model';
import Notification from 'data/notifications/model';
import Shift from 'data/shift/model';
import Swap from 'data/swap/model';
import User from 'data/user/model';
import { NotifyItem } from 'notifications/components/NotifyItem';
import { formatUserTime } from 'scheduler/util/formats';
import { getAuthAccount, getAuthUser } from 'shared/auth/selectors';
import AvatarImg from 'shared/ui/AvatarImg';

import { bindActionCreators } from '@reduxjs/toolkit';

export class SwapDrop extends Component {
  static propTypes = {
    creator: PropTypes.instanceOf(User),
    currentAccount: PropTypes.instanceOf(Account).isRequired,
    currentUser: PropTypes.instanceOf(User).isRequired,
    notification: PropTypes.instanceOf(Notification),
    shift: PropTypes.instanceOf(Shift),
    swap: PropTypes.instanceOf(Swap).isRequired,
    user: PropTypes.instanceOf(User),
  };

  getDescription() {
    const { user, shift, currentAccount, currentUser } = this.props;
    if (shift) {
      let displayName = '';
      if (user) {
        displayName =
          currentAccount.getSettings('privacy.enabled') &&
          !currentUser.canSupervise() &&
          !user.canSupervise() &&
          currentUser.id !== user.id
            ? 'A coworker'
            : user.shortName;
      }

      const name = displayName;
      const start = shift.date('start_time');
      const end = shift.date('end_time');
      return (
        <Fragment>
          {' '}
          {name} on {start.format('ddd, MMMM D')} from {formatUserTime(start, false)} to {formatUserTime(end, false)}
        </Fragment>
      );
    }
    return null;
  }

  getDateDetails() {
    const shift = this.props.shift;
    if (!shift) {
      return;
    }
    const start = shift.date('start_time');
    const end = shift.date('end_time');

    return (
      <Fragment>
        from <strong>{formatUserTime(start, false)}</strong>
        {' to '}
        <strong>{formatUserTime(end, false)}</strong>
        {' on '}
        <strong>{start.format('MMMM D')}</strong>
      </Fragment>
    );
  }

  getMessage() {
    const notification = this.props.notification;
    const swap = this.props.swap;
    const isOwner = this.props.currentUser.id === swap.user_id;
    switch (notification.action) {
      case Notification.Actions.CREATE:
        return (
          <span>
            {' '}
            initiated a shift {swap.typeName()} request {this.getDateDetails()}
          </span>
        );
      case Notification.Actions.CANCEL:
        return (
          <span>
            {' '}
            canceled {isOwner ? 'your' : 'a'} shift {swap.typeName()} {this.getDateDetails()}
          </span>
        );
      case Notification.Actions.ACCEPT:
        return (
          <span>
            {' '}
            accepted {isOwner ? 'your' : 'a'} shift {swap.typeName()} {this.getDateDetails()}
          </span>
        );
      case Notification.Actions.APPROVE:
        return (
          <span>
            {' '}
            approved {isOwner ? 'your' : 'a'} shift {swap.typeName()} {this.getDateDetails()}
          </span>
        );
    }
    return (
      <span>
        {' '}
        shift {swap.typeName()} {this.getDateDetails()}
      </span>
    );
  }

  getStatus() {
    const swap = this.props.swap;
    switch (swap.status) {
      case Swap.Status.PENDING:
        return { name: 'Pending', style: '' };
      case Swap.Status.APPROVED:
        return { name: 'Waiting', style: '' };
      case Swap.Status.DECLINED:
        return { name: 'Declined', style: 'error' };
      case Swap.Status.COMPLETED:
        return { name: 'Completed', style: 'success' };
      case Swap.Status.CANCELED:
        if (swap.wasDenied()) {
          return { name: 'Denied', style: 'error' };
        }
        return { name: 'Canceled', style: 'error' };
      case Swap.Status.EXPIRED:
        return { name: 'Expired', style: 'error' };
      default:
        return {};
    }
  }

  getAvatar() {
    if (this.props.notification && this.props.creator) {
      return <AvatarImg user={this.props.creator} size={Notification.AVATAR_SIZE} />;
    }
    if (!this.props.notification && this.props.user) {
      return <AvatarImg user={this.props.user} size={Notification.AVATAR_SIZE} />;
    }
  }

  getTitle() {
    const { notification, swap, currentUser, currentAccount, creator } = this.props;

    if (!notification) {
      switch (swap.type) {
        case Swap.Types.SWAP:
          return 'Swap Shifts';
        case Swap.Types.DROP:
          return 'Drop Shift';
        case Swap.Types.ALERT:
          return 'Shift Alert';
      }
    }

    if (notification && this.props.creator) {
      const displayName =
        currentAccount.getSettings('privacy.enabled') &&
        !currentUser.canSupervise() &&
        !creator.canSupervise() &&
        currentUser.id !== creator.id
          ? 'A coworker'
          : creator?.shortName;

      return creator.id === currentUser.id ? 'You' : displayName;
    }
  }

  redirect = () => {
    window.location.assign(`/requests/shift/${this.props.swap.id}`);
  };

  render() {
    return (
      <NotifyItem
        title={this.getTitle()}
        description={this.props.notification ? this.getMessage() : this.getDescription()}
        tag={this.getStatus()}
        avatar={this.getAvatar()}
        click={this.redirect}
      />
    );
  }
}

export default connect(
  state => ({
    currentUser: getAuthUser(state),
    currentAccount: getAuthAccount(state),
  }),
  dispatch => bindActionCreators({}, dispatch),
)(SwapDrop);
