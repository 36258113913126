import { bindActionCreators } from '@reduxjs/toolkit';
import classNames from 'classnames';
import { Map } from 'immutable';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import FontIcon from 'shared/ui/FontIcon';
import { TwilioProvider } from 'workchat/TwilioProvider';
import { pushScreen, track } from 'workchat/actions';
import ConversationTitle from 'workchat/components/ConversationTitle';
import Loading from 'workchat/components/Loading';
import { withTwilioProvider } from 'workchat/components/withTwilioProvider';
import Header from 'workchat/containers/Header';
import NewConversation from 'workchat/containers/NewConversation';
import Splash from 'workchat/containers/Splash';
import ViewConversation from 'workchat/containers/ViewConversation';
import { ConversationDateFormat } from 'workchat/formats';
import { getWorkchatUserShortName } from 'workchat/utils';

import 'workchat/styles/ConversationList.scss';
class ConversationList extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    provider: PropTypes.instanceOf(TwilioProvider).isRequired,
    conversations: PropTypes.instanceOf(Map),
    users: PropTypes.instanceOf(Map),
    maxUnreadIndicator: PropTypes.number,
    pushScreen: PropTypes.func.isRequired,
    track: PropTypes.func.isRequired,
    connected: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    maxUnreadIndicator: 9, // Max unread count to display in unread indicator(s)
  };

  state = {
    conversations: null,
  };

  componentDidMount() {
    const { conversations, users } = this.props;
    if (conversations && users) {
      this.updateConversations(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (
      props.users &&
      props.conversations &&
      (this.props.conversations !== props.conversations || this.props.users !== props.users)
    ) {
      this.updateConversations(props);
    }
  }

  newConversationAllowed = () => {
    const { account } = this.props;
    const custom = account.settings?.workchat?.customChannelsAllowed;
    if (custom) {
      return true;
    }
    return false;
  };

  updateConversations(props) {
    if (!props.conversations.size) {
      return;
    }

    const selfId = `${props.user.id}`;
    const conversations = props.conversations
      .toList()
      .sortBy(ch => {
        if (ch.attributes.external_type === 'account') {
          return moment().add(1, 'years'); //pin the account channel to the top
        }
        return ch.latestMessage ? ch.latestMessage.sentAt : ch.createdAt;
      })
      .toJS()
      .reverse();
    const avatars = [];
    const messages = [];
    const timestamps = [];

    conversations.forEach(c => {
      if (c.attributes.external_type !== 'account') {
        const participants = c.participants
          .toList()
          .filter(p => p.userId !== selfId)
          .sortBy(p => p.userId);
        const avatarUsers = [];
        participants.forEach(p => {
          if (avatarUsers.length < 2 && props.users.get(p.userId)) {
            avatarUsers.push(props.users.get(p.userId));
          }
        });

        avatars.push(
          avatarUsers.map(u => {
            if (u.avatar_url) {
              return (
                <div className="avatar" key={`avatar-${c.sid}-${u.id}`}>
                  <img src={u.avatar_url} alt="Avatar" />
                </div>
              );
            }
            return (
              <div className="avatar" key={`avatar-${c.sid}-${u.id}`}>
                <div className="initials">{[u.first_name.charAt(0), u.last_name.charAt(0)].join('').toUpperCase()}</div>
              </div>
            );
          }),
        );
      } else {
        avatars.push([
          <div key="wiw-avatar" className="avatar">
            {props.account.logo ? <img src={props.account.logo} alt="Logo" /> : <div className="wiw" />}
          </div>,
        ]);
      }

      let latestMessage = '';
      let timestamp = '';
      if (c.latestMessage) {
        latestMessage = c.latestMessage.text;
        if (c.attributes.external_type !== 'individual' && latestMessage) {
          const user = props.users.get(c.latestMessage.userId);
          if (user) {
            latestMessage = `${getWorkchatUserShortName(user)}: ${latestMessage}`;
          }
        }

        timestamp = c.latestMessage.sentAt.calendar(null, ConversationDateFormat);
      }
      messages.push(latestMessage);
      timestamps.push(timestamp);
    });

    this.setState({
      conversations,
      avatars,
      messages,
      timestamps,
    });
  }

  newConversation = () => {
    if (!this.props.connected) {
      return;
    }
    this.props.pushScreen(NewConversation);
  };

  chooseConversation(conversation) {
    if (!this.props.connected) {
      return;
    }
    this.props.provider.setConversation(conversation).then(() => {
      this.props.track('WorkChat: Viewed Channel', { accessType: 'Channel List' });
    });
    this.props.pushScreen(ViewConversation);
  }

  render() {
    const { provider, maxUnreadIndicator, connected } = this.props;
    const { conversations, avatars, messages, timestamps } = this.state;

    if (!conversations) {
      return <Loading />;
    }

    const buttons = this.newConversationAllowed()
      ? [
          {
            label: 'New Channel',
            className: 'green',
            action: this.newConversation,
            side: 'right',
            disabled: !connected,
          },
        ]
      : [];
    return (
      <div>
        <Header showBroadcast={true} buttons={buttons} />
        <div className="conversation-list">
          <Splash user={this.props.user} account={this.props.account} />
          {conversations.map((c, idx) => {
            const avatar = avatars[idx];
            const message = messages[idx];
            const timestamp = timestamps[idx];
            const unread = provider.unread(c);

            return (
              <div key={`conversation-list-${c.sid}`} className="conversation">
                <button
                  type="button"
                  onClick={this.chooseConversation.bind(this, c)}
                  className={classNames({ disabled: !connected })}
                >
                  <div className="callout">
                    {avatar.length !== 1 ? <div className="avatars">{avatar}</div> : avatar}
                    {unread > 0 && (
                      <div className="unread-count">
                        <div>{unread <= maxUnreadIndicator ? unread : `${maxUnreadIndicator}+`}</div>
                      </div>
                    )}
                  </div>
                  <div className="conversation">
                    <h3>
                      <ConversationTitle conversation={c} />
                    </h3>
                    <span>{message}</span>
                  </div>
                  <div className="time">
                    <span>{timestamp}</span>
                    <FontIcon icon="chevron-right" />
                  </div>
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default connect(
  // State to send to the Component as props
  state => ({
    connected: state.workchat.get('connected') || false,
    conversations: state.workchat.get('conversations') || null,
    users: state.workchat.get('users') || new Map({}),
  }),
  // Dispatch functions to send to the Component as props
  dispatch =>
    bindActionCreators(
      {
        pushScreen,
        track,
      },
      dispatch,
    ),
)(withTwilioProvider(ConversationList));
