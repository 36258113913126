export const SHIFT_TASK_INFO_DIALOG_DISMISSED = 'shiftTaskInfoDialogDismissed';
export const REPORTING_UPGRADE_DIALOG_DISMISSED = 'reportingUpgradeDialogDismissed';
export const TIMESHEETS_ANNOUNCEMENT_DISMISSED = 'timesheetsAnnouncementDismissed';
export const OVERTIME_ANNOUNCEMENT_DISMISSED = 'overtimeAnnouncementDismissed';
export const AUTO_SCHEDULE_MAX_HOURS = 'autoScheduleMaxHours';
export const AUTO_SCHEDULE_UNAVAILABILITY = 'autoScheduleUnavailability';
export const AUTO_SCHEDULE_PREFERRED_TIMES = 'autoSchedulePreferredTimes';
export const AUTO_SCHEDULE_MULTI_SHIFTS = 'autoScheduleMultiShifts';
export const AUTO_SCHEDULE_MAX_DAILY_HOURS = 'autoScheduleMaxDailyHours';
export const AUTO_SCHEDULE_WEEKEND_SHIFTS = 'autoScheduleWeekendShifts';
export const FORECAST_TOOLS_DIALOG_DISMISSED = 'forecastToolsDialogDismissed';
export const READ_RECEIPT = 'readReceipt';
export const RECEIPT_TYPES = {
  OPENSHIFT_REQUEST: 'openshiftRequest',
};
export const OSA_REQUEST_FILTERS = 'osaRequestFilters';
export const OSA_REQUEST_FILTER_SCHEDULES = 'schedules';
export const OSA_REQUEST_FILTER_STATUSES = 'statuses';
export const FEEDBACK_LAST_DISPLAYED_DATE = 'feedbackLastDisplayedDate';
export const FEEDBACK_LAST_PROMPT_TYPE = 'feedbackLastPromptType';
export const TAGS_HEADS_UP_PROMPT = 'tagsHeadsUpPrompt';
export const SCHEDULER_FILTER_METADATA = 'scheduler';
export const PLANS_VIEW_COUNT = 'plansViewCount';

// Dashboard Keys
export const DASHBOARD_METADATA = 'dashboard';
export const DASHBOARD_METADATA_CARDS_ORDER = 'cardsOrder';
export const DASHBOARD_METADATA_CARDS_ENABLED = 'cardsEnabled';
export const DASHBOARD_METADATA_GUIDE_CARD_DISMISSED = 'guideCardDismissed';
export const DASHBOARD_METADATA_GUIDE_CARD_GETMOBILE_VIEWED = 'guideCardGetMobileViewed';
export const DASHBOARD_METADATA_FOCUSED_ONBOARDING_SETUP_VIEWED = 'focusedOnboardingSetupDialogViewed';

// Onboarding Guides
export const ONBOARDING_GUIDES = 'onboardingGuides';
export const SCHEDULER_GUIDE = 'scheduler';
export const SCHEDULER_GUIDE_WELCOME = 'welcome';
export const SCHEDULER_GUIDE_PUBLISH = 'publish';
export const SCHEDULER_GUIDE_CREATE_SHIFT = 'createShift';
export const SCHEDULER_GUIDE_UNPUBLISHED_SHIFT = 'unpublishedShift';
export const SCHEDULER_GUIDE_PUBLISH_PREVIEW = 'publishPreview';
export const SCHEDULER_GUIDE_PUBLISH_CELEBRATE = 'publishCelebrate';
export const SCHEDULER_GUIDE_ADD_USER = 'addUser';
export const SCHEDULER_GUIDE_DRAG_COPY = 'dragCopy';

export const SCHEDULER_FOCUSED = 'focusedOnboarding';
export const SCHEDULER_FOCUSED_VIEW = 'focusedScheduler';
export const SCHEDULER_FOCUSED_SHIFT_CONFIRMATION = 'shiftConfirmation';
export const SCHEDULER_FOCUSED_PULSE = 'focusedPulse';
export const SCHEDULER_FOCUSED_TRUSTED_TEAMMATE = 'trustedTeammate';
export const SCHEDULER_FOCUSED_SIDEBAR_REENTRY = 'sidebarReentry';

export const EMPLOYEE_ONBOARDING = 'employeeOnboarding';
export const EMPLOYEE_AVAILABILITY_COMPLETED = 'employeeAvailabilityCompleted';
export const EMPLOYEE_ALERT_PREFERENCES_COMPLETED = 'employeeAlertPreferencesCompleted';
export const EMPLOYEE_TIME_OFF_COMPLETED = 'employeeTimeOffCompleted';

export const EMPLOYEE_ONBOARDING_STATUS = 'employeeOnboardingStatus';
export const EMPLOYEE_ONBOARDING_STATUS_IDLE = 'idle';
export const EMPLOYEE_ONBOARDING_STATUS_AVAILABILITY = 'availability';
export const EMPLOYEE_ONBOARDING_STATUS_ALERT_PREFERENCES = 'alert-preferences';
export const EMPLOYEE_ONBOARDING_STATUS_TIME_OFF = 'time-off';

// Account Metadata
export const ACCOUNT_MOTIVATIONS_METADATA = 'motivations';
export const ACCOUNT_ACTIVATIONS_METADATA = 'activation';
export const ACCOUNT_ACCEPTED_INVITE = 'acceptedInviteEventFired';
