import { dialogCallbacks } from 'dialogs/context';
import type { DialogType } from 'dialogs/dialogs';
import type { DialogOptions } from 'dialogs/reducer';
import type { ThunkAction } from 'redux-thunk';
import { DIALOG_CLOSE, DIALOG_CLOSE_ALL, DIALOG_OPEN, DIALOG_UPDATE } from 'store/action-types';

interface BaseDialogAction {
  type: string;
  dialog: DialogType;
}

interface DialogPayloadAction extends BaseDialogAction {
  payload: Record<string, unknown>;
  options: DialogOptions;
}

export interface DialogOpenAction extends DialogPayloadAction {
  type: typeof DIALOG_OPEN;
}

export interface DialogUpdateAction extends DialogPayloadAction {
  type: typeof DIALOG_UPDATE;
}

export interface DialogCloseAction extends BaseDialogAction {
  type: typeof DIALOG_CLOSE;
}

export interface DialogCloseAllAction extends Pick<BaseDialogAction, 'type'> {
  type: typeof DIALOG_CLOSE_ALL;
}

export type DialogAction = DialogOpenAction | DialogUpdateAction | DialogCloseAction | DialogCloseAllAction;

export function openDialog<T>(dialog: DialogType, payload = {}, options = {} as DialogOptions): ThunkAction<Promise<T>, any, any, any> {
  return dispatch => {
    if (!dialogCallbacks.has(dialog)) {
      let theResolve!: (payload?: unknown) => void;
      let theReject!: (payload?: unknown) => void;
      const promise = new Promise((resolve, reject) => {
        theResolve = resolve;
        theReject = reject;
      });
      dialogCallbacks.set(dialog, { resolve: theResolve, reject: theReject, promise });
    }
    // ignore payload if it is an event to avoid react crying about synthetic events
    // payload could be null, string, object, etc.
    if (
      payload !== null &&
      typeof payload === 'object' &&
      Object.prototype.hasOwnProperty.call(payload, 'currentTarget')
    ) {
      payload = {};
    }

    dispatch({
      type: DIALOG_OPEN,
      dialog,
      payload,
      options,
    });

    return dialogCallbacks.get(dialog)?.promise;
  };
}

export function updateDialog(dialog: DialogType, payload = {}, options = {}): DialogUpdateAction {
  return {
    type: DIALOG_UPDATE,
    dialog,
    payload,
    options,
  };
}

export function closeDialog(dialog: DialogType): DialogCloseAction {
  return {
    type: DIALOG_CLOSE,
    dialog,
  };
}

export function closeAllDialogs(): DialogCloseAllAction {
  return {
    type: DIALOG_CLOSE_ALL,
  };
}
