import WithParam from 'billing/util/WithParam';
import { ANNUAL_RENEWAL_WINDOW } from 'billing/util/constants';
import BaseModel from 'data/BaseModel';
import { getLDFlag } from 'data/LD/selectors/getLDFlag';
import type { AccountSettings, AccountSettingsPaths, AccountSettingsValues } from 'data/account/types/AccountSettings';
import { PayrollFrequency } from 'data/payroll/model';
import Plan from 'data/plan/model';
import { StripePlanID } from 'data/stripe/plans/model';
import Features from 'shared/features';

import type StripeCustomer from 'data/stripe/customers/model';
import { cloneDeep, get as getPath, includes, isEqual, set as setPath } from 'lodash';
import moment from 'moment-timezone';
import { getState } from 'store';
import type Stripe from 'stripe';

interface AccountCountry {
  id: number;
  dialing_code: number;
  is_deleted: boolean;
  is_enabled: number;
  iso2: string;
  iso3: string;
  name: string;
  sms_cost: number;
  sms_credit_cost: number;
  updated_by: string;
}

export interface AccountPlace {
  id: number;
  address: string;
  subpremise: string;
  business_name: string;
  country: string;
  latitude: number;
  locality: string;
  longitude: number;
  place_id: string;
  place_type: string[]; // different from api docs
  postal_code: string;
  region: string;
  street_name: string;
  street_number: string;
  sub_locality: string;
  updated_at: string;
  updated_by: string;
}

export interface AccountSubscription {
  id: number;
  created_at: string;
  is_active: number;
  type: string;
  plan: Plan;
  updated_at: string;
  updated_by: string;
}

interface AccountOwner {
  id: number;
  email: string | null;
  name: string | null;
}

export interface OdpProviderConfig {
  name: string;
  id: string;
  enabled: boolean;
  payroll_provider: string | null;
}

// todo: AccountSettings exists but implementing it causes a torrent of ts errors i dont have time to fix atm
export interface Settings {
  [key: string]: any;

  on_demand_pay: {
    enabled: boolean;
    providers: Array<OdpProviderConfig>;
  };
}

export interface AccountFields {
  id: number;
  additional_features: string[];
  attendance_alert_employee: number;
  attendance_alert_manager: number;
  attendance_freemium: boolean;
  billing_address: string | null;
  billing_city: string | null;
  billing_country: number | null;

  /**
   * Don't use billing_discount for anything other than display. The billing
   * discount is already factored into the prices returned by backend.
   */
  billing_discount: number;
  billing_phone: string | null;
  billing_state: string | null;

  /** 0 = monthly, 1 = annual */
  billing_type: AccountBillingType;
  billing_zip: string | null;
  billing_engine: number | null;
  business_address_confirmed: boolean;
  company: string | null;
  converted_at: string | boolean;
  country: AccountCountry;
  country_id: number | null;
  credit_attempts: number;
  credit_cvv: string | null;
  credit_email: string | null;
  credit_exp: string | null;
  credit_name: string | null;
  credit_number: string | null;
  credit_recurring_day: number | null;
  deleted_at: string | null;
  employee_count: number;
  enabled: boolean;
  features: string[];
  free_plan_adopted_at: string | null;
  has_billing: boolean | null;
  industry_id: number;
  is_deactivated: boolean;
  deactivated_at: string | null;
  is_deleted: boolean;
  is_demo: boolean;
  logo: string | null;
  master_id: number;
  master_plan_id: number | null; // TODO(types) -- verify this
  /** `false` if not set, a string ISO date if set */
  payroll_date: string | boolean;
  place: AccountPlace;
  place_confirmed: boolean;
  place_id: number;
  plan_custom: number;
  plan_expires: string | null;
  plan_id: number | null;
  plan_type: number;
  plan_units: number;
  signup_origin: string | null;
  stripe_id: null;
  subscriptions: AccountSubscription[];

  /** Because the backend doesn't return the real `accounts.type` value. */
  real_type: number;
  ref_employees: number;
  ref_page: any | null; // TODO(types)
  ref_status: string | null;
  referred_by: string | null;
  required_features: string[];
  // AccountSettings exists but implementing it will cause a torrent of ts issues i dont have time to fix right now
  settings: Settings;

  /** @deprecated */
  subdomain: string | null;
  text_credits: number;
  timezone_id: number;
  timezone_name: string; // Never null as monolith will throw if it can't find the timezone_name from the timezone_id
  toggles: string[];
  trial_created_at: string | null;
  trial_length: number;

  /** 1 = scheduling, 2 = attendance, 3 = both */
  type: number;
  user_count: number;
  user_count_local: number;
  uses_features: any[]; // TODO(types)
  wb_expires: boolean | null;
  created_at: string | null;
  updated_at: string | null;

  /** Only child accounts have this property. */
  owner: AccountOwner;
  primary_contact: null | number;
}

export enum AccountBillingType {
  Monthly = 0,
  Annual = 1,
}

export enum AccountType {
  Scheduling = 1,
  Attendance = 2,
  Both = 3,
}

export enum AccountPlanType {
  Bucket = 1,
  User = 2,
}

export enum AccountSchedulerSortSettings {
  ScheduleSortEmployeesFirstName = 0,
  ScheduleSortEmployeesLastName = 1,
}

export enum AccountBillingEngine {
  Legacy = 1,
  V2 = 2,
  External = 3,
  ADP = 4,
  V5 = 5,
}

class Account extends BaseModel<AccountFields>({
  id: 0,
  additional_features: [],
  attendance_alert_employee: 0,
  attendance_alert_manager: 0,
  attendance_freemium: false,
  billing_address: null,
  billing_city: null,
  billing_country: null,
  billing_discount: 0,
  billing_phone: null,
  billing_state: null,
  billing_type: 0, // 0 = monthly, 1 = annual
  billing_zip: null,
  billing_engine: null,
  business_address_confirmed: false,
  company: null,
  converted_at: false,
  country: {} as AccountCountry,
  country_id: null,
  credit_attempts: 0,
  credit_cvv: null,
  credit_email: null,
  credit_exp: null,
  credit_name: null,
  credit_number: null,
  credit_recurring_day: null,
  deleted_at: null,
  employee_count: 0,
  enabled: true,
  features: [],
  free_plan_adopted_at: null,
  has_billing: null,
  industry_id: 0,
  is_deactivated: false,
  deactivated_at: null,
  is_deleted: false,
  is_demo: false,
  logo: null,
  master_id: 0,
  master_plan_id: 0,
  payroll_date: false,
  place: {} as AccountPlace,
  place_confirmed: false,
  place_id: 0,
  plan_custom: 0,
  plan_expires: null,
  plan_id: -1,
  plan_type: 1,
  plan_units: 0,
  real_type: 0,
  ref_employees: 0,
  ref_page: null,
  ref_status: null,
  referred_by: null,
  required_features: [],
  // AccountSettings exists but implementing it causes a torrent of ts errors i dont have time to fix atm
  settings: {} as Settings,
  stripe_id: null,
  signup_origin: null,
  subdomain: null,
  subscriptions: [],
  text_credits: 0,
  timezone_id: 0,
  timezone_name: '',
  toggles: [],
  trial_created_at: null,
  trial_length: 0,
  type: 0,
  user_count: 1,
  user_count_local: 1,
  uses_features: [],
  wb_expires: null,
  created_at: null,
  updated_at: null,
  owner: {} as AccountOwner,
  primary_contact: null,
}) {
  static Billing = {
    MONTHLY: AccountBillingType.Monthly,
    ANNUAL: AccountBillingType.Annual,
  } as const;

  static BillingTypeMap = {
    [Account.Billing.MONTHLY]: 'monthly',
    [Account.Billing.ANNUAL]: 'annual',
  } as const;

  static TYPE = {
    SCHEDULING: AccountType.Scheduling,
    ATTENDANCE: AccountType.Attendance,
    BOTH: AccountType.Both,
  } as const;

  static PlanType = {
    BUCKET: AccountPlanType.Bucket,
    USER: AccountPlanType.User,
  } as const;

  // TODO(remove and replace uses with PayrollFrequency enum)
  static PayrollType = {
    EVERY_TWO_WEEKS: PayrollFrequency.EveryTwoWeeks,
    WEEKLY: PayrollFrequency.Weekly,
    TWICE_PER_MONTH: PayrollFrequency.TwicePerMonth,
    MONTHLY: PayrollFrequency.Monthly,
    CUSTOM: PayrollFrequency.Custom,
  } as const;

  static Settings = {
    Schedule: {
      SORT_EMPLOYEES_FIRST_NAME: AccountSchedulerSortSettings.ScheduleSortEmployeesFirstName,
      SORT_EMPLOYEES_LAST_NAME: AccountSchedulerSortSettings.ScheduleSortEmployeesLastName,
    },
  } as const;

  static BillingEngine = {
    LEGACY: AccountBillingEngine.Legacy,
    V2: AccountBillingEngine.V2,
    EXTERNAL: AccountBillingEngine.External,
    ADP: AccountBillingEngine.ADP,
    V5: AccountBillingEngine.V5,
  } as const;

  /**
   * @deprecated Prefer using numbers directly instead of converting strings for billing_type
   * @param billingType
   */
  static billingType(billingType: string | number) {
    if (typeof billingType === 'string') {
      return Number.parseInt(billingType, 10);
    }
    return billingType;
  }

  static billingTypeText(billingType: AccountBillingType): 'monthly' | 'annual' {
    return Account.BillingTypeMap[billingType];
  }

  isWorkchatFree() {
    return this.plan_id === Plan.WORKCHATFREE;
  }

  /**
   * @deprecated Use field billing_type directly
   */
  billingType() {
    return this.billing_type ?? Account.Billing.MONTHLY;
  }

  billingTypeName(type = this.billing_type) {
    return Account.BillingTypeMap[type];
  }

  isBilledMonthly() {
    return this.billing_type === Account.Billing.MONTHLY;
  }

  isBilledAnnually() {
    return this.billing_type === Account.Billing.ANNUAL;
  }

  /**
   * Returns if the account is within it's 30 day annual renewal window or false if monthly
   */
  canRenewAnnual(customer?: StripeCustomer) {
    // Check the test clock if it exists
    let now = moment();
    if (customer?.test_clock && typeof customer?.test_clock !== 'string') {
      const tc = customer.test_clock as Stripe.TestHelpers.TestClock;
      if (tc.created > 0) {
        now = moment.unix(tc.frozen_time);
      }
    }

    return this.isBilledAnnually() && this.mustDate('plan_expires').diff(now, 'days') <= ANNUAL_RENEWAL_WINDOW;
  }

  /**
   * TODO(types)
   * `wb_expires` comes through the API as a boolean and is stored in Redux as a boolean. At one
   * point it appears to have been a number(?). We should refactor to either remove `wb_expires`
   * if it's not needed or refactor the functions around it to treat it as a boolean.
   */
  isAttendanceTrial() {
    if (!this.wb_expires) {
      return false;
    }

    return this.hasAttendance() && !this.hasAttendance(true) && moment(this.wb_expires as any).isAfter(moment());
  }

  /**
   * checks if attendance freemium is on account
   * checks if subscriptions are active first since they remove freemiumness
   */
  isAttendanceFreemium() {
    for (const subscription of this.subscriptions) {
      if (subscription.is_active) {
        return subscription.plan.is_freemium;
      }
    }
    return this.attendance_freemium;
  }

  // This is to restrict features only from
  hasAttendanceNotFreemium() {
    return this.hasAttendance() && !this.attendance_freemium;
  }

  /**
   * Does the account have attendance? The `strict` parameter is deprecated.
   */
  hasAttendance(strict = false) {
    if (strict && this.real_type) {
      return !!(this.real_type & Account.TYPE.ATTENDANCE);
    }

    return this.hasFeature(Features.ATTENDANCE, true);
  }

  /*
   * Is the account in a Reverse Trial state
   */
  isReverseTrial() {
    return this.plan_id === StripePlanID.REVERSE_TRIAL;
  }

  /*
   * Does the account have Payroll feature enabled
   */
  hasPayrollFeature() {
    return this.hasFeature(Features.PAYROLL, true);
  }

  /*}*
   * Does the account have scheduling? The `strict` parameter is deprecated.
   */
  hasScheduling(strict = false) {
    if (strict && this.real_type) {
      return !!(this.real_type & Account.TYPE.SCHEDULING);
    }

    return this.hasFeature(Features.SCHEDULING, true);
  }

  hasPlace() {
    return this.place_id !== 0 && this.place_id !== null;
  }

  getPlace(): AccountPlace {
    if (this.hasPlace()) {
      return this.place;
    }

    return {
      id: 0,
      address: '',
      subpremise: '',
      business_name: '',
      country: '',
      latitude: 0,
      locality: '',
      longitude: 0,
      place_id: '',
      place_type: [],
      postal_code: '',
      region: '',
      street_name: '',
      street_number: '',
      sub_locality: '',
      updated_at: '',
      updated_by: '',
    };
  }

  /**
   * Does the account have Scheduling Rules feature
   * this is checked pretty often in the product.
   *
   * * @returns boolean
   */
  hasSchedulingRules() {
    return this.hasFeature(Features.SCHEDULING_RULES, true);
  }

  /**
   * Check if account is using scheduling rules
   */
  hasSchedulingRulesEnabled() {
    return this.hasFeature(Features.SCHEDULING_RULES, true) && this.getSettings('scheduling_rules.enabled', false);
  }

  /**
   * Is the account on an expired trial?
   * Typical usage: `const expired = accountPlan.isTrial() && account.isTrialExpired(accountPlan);`
   * Caller should always verify accountPlan.isTrial() before calling this method.
   *
   * @throws if the accountPlan is not a trial, since otherwise the ambiguity
   *   of the returned value would create confusion
   */
  isTrialExpired(accountPlan: Plan) {
    if (!accountPlan) {
      throw new TypeError("must supply the account's plan");
    }

    if (!accountPlan.isTrial()) {
      return false;
    }

    const expiry = this.date('plan_expires');

    if (!expiry) {
      return true;
    }

    return expiry.isValid() && expiry.isBefore(moment());
  }

  isPerUserBilling() {
    return this.plan_type === Account.PlanType.USER;
  }

  isBucket() {
    return this.plan_type === Account.PlanType.BUCKET;
  }

  /**
   * The return line `defaultValue === undefined && value === undefined ? false : value` is a concession to how we had
   * been using this method throughout the codebase. It causes a type error so I've ignored it for now. We should really
   * make the `defaultValue` paramter required.
   *
   * @param path
   * @param defaultValue
   */
  getSettings<K extends AccountSettingsPaths>(
    path: K,
    defaultValue?: AccountSettingsValues<K>,
  ): AccountSettingsValues<K> {
    const value = getPath(this.settings as AccountSettings, path, defaultValue);
    // @ts-ignore-next-line
    return defaultValue === undefined && value === undefined ? false : value;
  }

  // updates a setting value based on a dot-separated path i.e. "schedule.sort_employees_first_name"
  setSetting<K extends AccountSettingsPaths>(this: Account, path: K, value: AccountSettingsValues<K>) {
    return this.set('settings', setPath(cloneDeep(this.settings), path, value));
  }

  hasBillingInfo() {
    return !!this.credit_number;
  }

  getPricingGroup() {
    // Possible values: 'experimental-2022, pricing-2022', 'pricing-2023', 'pricing-2024', 'pricing-2024-v2'
    for (const feature of this.required_features) {
      if (feature.startsWith('pricing-') || feature.startsWith('experimental-')) {
        return feature;
      }
    }

    return 'experimental-2022';
  }

  isParent() {
    return this.master_id > 0 && this.id === this.master_id;
  }

  isChild() {
    return this.master_id > 0 && this.id !== this.master_id;
  }

  isActive() {
    return !(this.is_deleted || this.is_deactivated || !this.enabled);
  }

  isHibernated() {
    return this.is_deactivated;
  }

  /**
   * Because `type` is defined as `number` instead of `1 | 2 | 3`, it is technically
   * possible for this method to return undefined. This may cause some headaches where
   * this method is called (i.e., needing typeguards around the call).
   */
  typeName() {
    const accountTypes: { [key: number]: string | undefined } = {
      1: 'Scheduling',
      2: 'Attendance',
      3: 'Both',
    };

    return accountTypes[this.type];
  }

  /**
   * TODO(types)
   * We assert that `credit_recurring_day` is in fact a number whenever this method is
   * called. To remove the assertion, we should either 1) do some null checking and
   * handle cases where `credit_recurring_day` is null, or 2) remove the `| null` from
   * `credit_recurring_day` by verifying that it will never be null.
   */
  planExpires(plan: Plan) {
    if (this.isBilledAnnually() || plan.isTrial()) {
      return this.mustDate('plan_expires');
    }

    const today = moment();
    const expires = moment();
    expires.date(this.credit_recurring_day!);
    if (plan.isFree()) {
      expires.date(Math.min(today.date(), 28));
    } else if (today.date() >= this.credit_recurring_day!) {
      expires.add(1, 'month');
    }

    return expires;
  }

  getNextMonthlyBillingCycle() {
    return this.mustDate('plan_expires').add(1, 'month');
  }

  isPlanExpired(plan: Plan) {
    return this.planExpires(plan).isBefore(moment());
  }

  /**
   * Returns the first date on which you will be able to renew or make changes
   * to the given plan.
   */
  renewalWindowDate(plan: Plan, billingType?: AccountBillingType): moment.Moment {
    if ((billingType ?? this.billing_type) === AccountBillingType.Annual) {
      return this.planExpires(plan).subtract(ANNUAL_RENEWAL_WINDOW, 'days');
    }

    return moment();
  }

  /*
   * Is Payroll being requested but not yet onboarded
   */
  isPayrollRequested() {
    return (
      this.getSettings('payroll_check.requested', false) &&
      !this.getSettings('payroll_check.is_onboarded', false) &&
      this.hasPayrollFeature()
    );
  }

  /*
   * Is Payroll fully onboarded
   */
  isPayrollOnboarded() {
    return this.getSettings('payroll_check.is_onboarded', false) && this.hasPayrollFeature();
  }

  /*
   * Is Payroll enabled
   */
  isPayrollEnabled() {
    return this.getSettings('payroll_check.enabled', false) && this.hasPayrollFeature();
  }

  isWorkchatEnabled() {
    return this.getSettings('workchat.enabled', false);
  }

  isPlanRenewable(plan: Plan) {
    return (
      plan.isPaid() &&
      (this.isBilledMonthly() ||
        (this.isBilledAnnually() && this.planExpires(plan).diff(moment(), 'days') < ANNUAL_RENEWAL_WINDOW))
    );
  }

  canHibernate(plan: Plan) {
    return !this.isBilledAnnually() && plan && !plan.isFree() && !this.isHibernated() && !this.isReverseTrial();
  }

  /**
   * Checks if the account currently has a feature. If `strict` is true, 'all'
   * will not always return `true`.
   */
  hasFeature(feature: string, strict = false) {
    const features = this.features;

    if (features.includes('all') && !strict) {
      return true;
    }

    return features.includes(feature);
  }

  /**
   * Checks if the account currently has a toggle.
   */
  hasToggle(toggle: string) {
    return this.toggles.includes(toggle);
  }

  canSwap(): boolean {
    return (
      this.hasFeature(Features.SWAPS_DROPS) &&
      this.getSettings('swaps.enabled', false) &&
      this.getSettings('swaps.enable_swaps', false)
    );
  }

  canDrop(): boolean {
    return (
      this.hasFeature(Features.SWAPS_DROPS) &&
      this.getSettings('swaps.enabled', false) &&
      this.getSettings('swaps.enable_drops', false)
    );
  }

  canRelease(): boolean {
    return (
      this.hasFeature(Features.SWAPS_DROPS) &&
      this.getSettings('swaps.enabled', false) &&
      this.getSettings('swaps.enable_releases', false)
    );
  }

  privacyEnabled(): boolean {
    return this.hasFeature(Features.EMPLOYEE_CONTROL, true) && this.getSettings('privacy.enabled');
  }

  hasShiftAcknowledgement() {
    return (
      this.hasScheduling() &&
      this.hasFeature(Features.SHIFT_ACKNOWLEDGEMENT) &&
      this.getSettings('schedule.shift_acknowledgement', false) &&
      this.enabled
    );
  }

  userCount() {
    return this.user_count;
  }

  hasMaxUsers(plan: Plan) {
    if (this.isPerUserBilling()) {
      return this.employee_count >= (plan.isPaid() ? this.plan_units : plan.unit_max);
    }

    return this.employee_count >= plan.employees;
  }

  canViewCoWorkers() {
    return this.getSettings('schedule.is_visible');
  }

  canViewCoWorkersByPosition() {
    return this.getSettings('schedule.positions_only');
  }

  fromRefFeature(feature: string) {
    if (feature === Features.ATTENDANCE) {
      return includes(this.ref_page, 'time-clock');
    }

    return includes(this.ref_page, feature);
  }

  billingUrl() {
    return this.isPerUserBilling() ? '/billing' : `${CONFIG.APP_LEGACY_ROOT}/account`;
  }

  // TODO(types)
  plansUrl(featureOrWithParamOrString?: any) {
    const withParam = WithParam.fromString(featureOrWithParamOrString);
    const plansUrl = this.isPerUserBilling() ? '/billing/plans' : `${CONFIG.APP_LEGACY_ROOT}/account`;

    return withParam ? `${plansUrl}?with=${withParam}&date=${moment().format('MM/DD/YYYY')}` : `${plansUrl}`;
  }

  isBillingEngineReady() {
    return this.billing_engine != null;
  }

  isV2Billing(): boolean {
    return this.billing_engine === Account.BillingEngine.V2;
  }

  isV5Billing(): boolean {
    return this.billing_engine === Account.BillingEngine.V5;
  }

  isADPBilling(): boolean {
    return this.billing_engine === Account.BillingEngine.ADP;
  }

  getTzDate() {
    // We know that timezone_name will exist so we can assert that it will
    return moment().tz(this.timezone_name!).startOf('day');
  }

  totalSeats(plan: Plan): number {
    return plan.isFree() ? plan.unit_max : this.plan_units || plan.employees;
  }

  remainingSeats(plan: Plan): number {
    return this.totalSeats(plan) - this.userCount();
  }

  mustGetPlanId() {
    if (this.plan_id) {
      return this.plan_id;
    }

    throw new Error('no plan id');
  }

  // is port of the monolith's isEligibleForBillingEngineMigration
  // any changes here should also be made in the monolith
  isEligibleForBillingEngine5Migration(plan: Plan): boolean {
    if (this.billing_engine !== Account.BillingEngine.LEGACY) {
      return false;
    }

    if (this.isChild()) {
      return false;
    }

    const isMigrationCronEnabledForAccount = getLDFlag(getState(), 'fdt-570-billing-migration-cron');
    if (!isMigrationCronEnabledForAccount) {
      return false;
    }

    const isAnnualAccountEligible = getLDFlag(getState(), 'fdt-280-be-5-migration-eligible-accounts');
    if (this.isBilledAnnually() && !this.getSettings('payroll_check.is_onboarded', false) && !isAnnualAccountEligible) {
      return false;
    }

    // WAVE 1
    if (this.getSettings('payroll_check.is_onboarded', false) || plan.isStripeBillingMigrationWave1()) {
      return true;
    }

    // WAVE 2
    if (plan.isStripeBillingMigrationWave2(this.type)) {
      return true;
    }

    // WAVE 3
    if (plan.isStripeBillingMigrationWave3()) {
      return true;
    }

    return false;
  }

  hasDefaultRolePermissions() {
    return isEqual(this.getSettings('role_permissions'), {
      managers: {
        can_manage_account_settings: true,
        can_manage_workplace_objects: true,
      },
      supervisors: {
        can_manage_workplace_objects: true,
        can_edit_all_shift_details: true,
        can_view_employee_wages: false,
        can_edit_employee_timesheets: true,
        can_approve_own_timesheet: true,
      },
    });
  }
}

export default Account;
