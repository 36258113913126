import { lazy, memo } from 'react';
import { BrowserRouter, Redirect, Switch } from 'react-router-dom';

import User from 'data/user/model';
import hasBillingRole from 'shared/auth/components/hasBillingRole';
import Features from 'shared/features';
import FullPageLayout from 'shared/layouts/FullPageLayout';
import MainLayout from 'shared/layouts/MainLayout';
import LayoutContextProvider from 'shared/layouts/context/LayoutContext';
import {
  canAccessFullScheduler,
  canViewSchedulerOnboarding,
  hasAttendance,
  hasDocStorage,
  hasScheduling,
  shouldFocusedOnboarding,
  shouldPayrollOnboard,
  shouldSchedulerOnboard,
} from 'shared/router';
import BillingStatusGuard from 'shared/router/middleware/BillingStatusGuard';
import OpenDialogCloser from 'shared/router/middleware/openDialogCloser';
import PageViewTracker from 'shared/router/middleware/pageViewTracker';
import RedirectParameterHandler from 'shared/router/middleware/redirectParameter';
import GTMTracker from 'shared/vendor/GTMTracker';

import { compose } from '@reduxjs/toolkit';

function Router() {
  return (
    <BrowserRouter>
      <GTMTracker />
      <RedirectParameterHandler />
      <PageViewTracker />
      <OpenDialogCloser />
      <BillingStatusGuard />
      <LayoutContextProvider>
        <Switch>
          <MainLayout path="/" exact component={compose(shouldSchedulerOnboard)(lazy(() => import('./dashboard')))} />
          <MainLayout
            path="/myschedule/:employee?/:userId?"
            component={compose(hasScheduling)(lazy(() => import('./myschedule')))}
          />
          <MainLayout exact path="/profile" component={lazy(() => import('./profile'))} />
          <MainLayout
            exact
            path="/scheduler/shift/:id"
            component={lazy(() => import('./scheduler/containers/NavToShiftDetails'))}
          />
          <FullPageLayout
            path="/scheduler/focused-onboarding"
            hasRole={User.RoleMappings.MANAGE}
            component={compose(shouldFocusedOnboarding)(lazy(() => import('scheduler/modules/focused-onboarding')))}
          />
          <MainLayout
            exact
            path="/scheduler"
            component={compose(shouldSchedulerOnboard, canAccessFullScheduler)(lazy(() => import('./scheduler')))}
          />
          <FullPageLayout
            path="/scheduler/onboarding"
            hasRole={User.RoleMappings.MANAGE}
            component={compose(canViewSchedulerOnboarding)(lazy(() => import('scheduler/modules/onboarding')))}
          />

          <MainLayout
            path="/payroll/onboarding"
            hasRole={User.RoleMappings.MANAGE}
            hideFooter
            component={compose(shouldPayrollOnboard)(lazy(() => import('./payrollCheck/modules/onboarding/index')))}
          />

          <MainLayout
            exact
            path="/payroll/overview"
            hasRole={User.RoleMappings.MANAGE}
            hasUserCriteria={user => user.canPayroll()}
            hasAccountCriteria={account => account.getSettings('payroll_check.is_onboarded', false)}
            hideFooter
            component={lazy(() => import('./payrollCheck/modules/overview/PayrollCheckOverview'))}
          />

          <MainLayout
            path="/payroll/:id"
            hasRole={User.RoleMappings.MANAGE}
            hasUserCriteria={user => user.canPayroll()}
            hasAccountCriteria={account => account.getSettings('payroll_check.is_onboarded', false)}
            hideFooter
            requiresStepUpAuth
            elseRedirect="/"
            component={lazy(() => import('./payrollCheck/modules/details/PayrollCheckDetails'))}
          />

          <MainLayout
            exact
            path="/payroll/"
            hideFooter
            component={lazy(() => import('./payrollCheck/modules/marketing/PayrollCheckMarketing'))}
          />

          <MainLayout
            exact
            path="/paystubs"
            hideFooter
            hasAccountCriteria={account => account.getSettings('payroll_check.is_onboarded', false)}
            component={lazy(() => import('./paystubs'))}
          />

          <MainLayout
            path="/positions"
            hasRole={User.RoleMappings.SUPERVISE}
            component={lazy(() => import('./positions'))}
          />
          <MainLayout
            hasAccountCriteria={account => account.hasFeature(Features.SITES) || account.isReverseTrial()}
            hasAccountPlanCriteria={accountPlan =>
              accountPlan.isSingleLocation2025() || accountPlan.isSingleLocation2025V2()
            }
            path="/sites"
            hasRole={User.RoleMappings.SUPERVISE}
            component={lazy(() => import('./sites'))}
          />
          <Redirect from="/blocks" to="/shift-templates" />
          <MainLayout
            path="/shift-templates"
            hasRole={User.RoleMappings.SUPERVISE}
            component={lazy(() => import('./templates'))}
          />

          <Redirect from="/locations" to="/schedules" />
          <MainLayout
            path="/schedules"
            hasRole={User.RoleMappings.SUPERVISE}
            component={lazy(() => import('schedules'))}
          />
          <MainLayout path="/tasks" component={lazy(() => import('./tasks'))} />

          <FullPageLayout
            exact
            path="/employees/invite"
            hasRole={User.RoleMappings.SUPERVISE}
            component={lazy(() => import('./marketing/modules/productEntryPoint/manageMissingEmployees'))}
          />

          <Redirect from="/employees/archived" to="/users/archived" />
          <Redirect from="/employees" to="/users" />
          <MainLayout
            path="/users"
            exact
            hasRole={User.RoleMappings.SUPERVISE}
            component={lazy(() => import('./users'))}
          />
          <MainLayout
            path="/users/archived"
            hasRole={User.RoleMappings.SUPERVISE}
            component={lazy(() => import('./users/modules/archived'))}
          />

          {/* <MainLayout path="/workchat" exact component={lazy(() => import('./workchat/WorkChatMessenger'))} /> */}

          <MainLayout exact path="/billing" component={hasBillingRole(lazy(() => import('./billing')))} />
          <MainLayout path="/billing/plans" component={hasBillingRole(lazy(() => import('billing/modules/plans')))} />
          <FullPageLayout path="/deactivated" component={lazy(() => import('billing/modules/deactivated'))} />

          <Redirect exact from="/requests" to="/requests/time-off" />
          <MainLayout exact path="/requests/time-off" component={lazy(() => import('./requests/modules/timeoff'))} />
          <MainLayout
            path="/requests/time-off/:id"
            component={lazy(() => import('./requests/modules/timeoff/components/RequestDetails'))}
          />
          <MainLayout exact path="/requests/shift" component={lazy(() => import('./requests/modules/shifts'))} />
          <MainLayout
            path="/requests/shift/:id"
            component={lazy(() => import('./requests/modules/shifts/components/ShiftRequestDetails'))}
          />
          <MainLayout exact path="/requests/openshift" component={lazy(() => import('./requests/modules/openshift'))} />
          <MainLayout
            path="/requests/openshift/:id"
            component={lazy(() => import('./requests/modules/openshift/components/RequestDetails'))}
          />
          <MainLayout exact path="/requests/absences" component={lazy(() => import('./requests/modules/absence'))} />
          <MainLayout
            path="/requests/absences/:id"
            component={lazy(() => import('./requests/modules/absence/components/NavToAbsenceDetails'))}
          />

          {/* ===== Settings - Routes Start ===== */}
          <MainLayout
            exact
            path="/settings"
            hasRole={User.RoleMappings.MANAGE}
            component={lazy(() => import('./settings/modules/general'))}
          />
          <MainLayout
            path="/settings/timeoff"
            hasAccountCriteria={account => account.hasFeature(Features.TIME_OFF_REQUESTS, true)}
            hasRole={User.RoleMappings.MANAGE}
            component={lazy(() => import('./settings/modules/timeoff'))}
          />
          <MainLayout
            hasRole={User.RoleMappings.MANAGE}
            path="/settings/scheduling"
            component={compose(hasScheduling)(lazy(() => import('./settings/modules/scheduling')))}
          />
          <MainLayout
            hasRole={User.RoleMappings.MANAGE}
            path="/settings/attendance"
            component={hasAttendance(lazy(() => import('./settings/modules/attendance')))}
          />
          <MainLayout
            hasRole={User.RoleMappings.MANAGE}
            hasAccountCriteria={account => account.hasFeature(Features.WEBHOOKS, true) || account.isReverseTrial()}
            path="/settings/webhooks"
            component={lazy(() => import('./settings/modules/webhooks/WebhookSettings'))}
          />
          {/* ========== Settings: Integrations - Routes Start ========== */}
          <MainLayout
            path="/settings/integrations/go/:integration/:tab?"
            component={lazy(() => import('./integrations/modules/go'))}
          />
          <MainLayout
            path="/settings/integrations/workforcenow/:tab?"
            component={lazy(() => import('./integrations/modules/wfn'))}
          />
          <MainLayout
            path="/settings/integrations/gusto/:tab?"
            hasFlag="wiwjs-4265-gusto-react-frontend"
            component={lazy(() => import('./integrations/modules/gusto'))}
          />
          <MainLayout
            path="/settings/integrations/square/:tab?"
            component={lazy(() => import('./integrations/modules/square'))}
          />
          <MainLayout
            path="/settings/integrations/clover"
            component={lazy(() => import('./integrations/modules/pos/clover'))}
          />
          <MainLayout
            path="/settings/integrations/square-pos"
            component={lazy(() => import('./integrations/modules/pos/square-pos'))}
          />
          {/* <MainLayout
            path="/settings/integrations/godaddy-poynt"
            component={lazy(() => import('./integrations/modules/pos/godaddy-poynt'))}
          />
          <MainLayout
            path="/settings/integrations/lightspeed-restaurant-k-series"
            component={lazy(() => import('./integrations/modules/pos/lightspeed/lightspeed-restaurant-k-series'))}
          />
          {/* <MainLayout
            path="/settings/integrations/lightspeed-retail-r-series"
            component={lazy(() => import('./integrations/modules/pos/lightspeed/lightspeed-retail-r-series'))}
          />
          <MainLayout
            path="/settings/integrations/lightspeed-retail-x-series"
            component={lazy(() => import('./integrations/modules/pos/lightspeed/lightspeed-retail-x-series'))}
          />
          <MainLayout
            path="/settings/integrations/mindbody"
            component={lazy(() => import('./integrations/modules/pos/mindbody'))}
          />
          <MainLayout
            path="/settings/integrations/workday"
            component={lazy(() => import('./integrations/modules/pos/workday'))}
          /> */}
          <MainLayout
            path="/settings/integrations/next/:tab?"
            hasFlag="pay-579-next-workers-comp"
            hideFooter
            component={lazy(() => import('./integrations/modules/next'))}
          />
          <MainLayout
            path="/settings/integrations/paychex/:tab"
            component={lazy(() => import('./integrations/modules/paychex/containers/Configuration'))}
          />
          <MainLayout
            path="/settings/integrations/paychex"
            component={lazy(() => import('./integrations/modules/paychex/containers/Details'))}
          />
          <MainLayout
            exact
            path="/settings/integrations/qbo"
            component={lazy(() => import('./integrations/modules/qbo'))}
          />
          <MainLayout
            exact
            path="/settings/integrations/:marketplace?"
            component={lazy(() => import('./integrations/modules/marketplace'))}
          />
          {/* ========== Settings: Integrations - Routes End ========== */}
          <MainLayout
            path="/settings/saml"
            hasAccountCriteria={account => account.hasFeature(Features.SAML_SSO, true) || account.isReverseTrial()}
            component={lazy(() => import('./settings/modules/saml/SamlSettings'))}
          />
          {/* ===== Settings - Routes End ===== */}

          <MainLayout path="/timesheets/:payrollId?" component={lazy(() => import('./timesheets'))} hideFooter />

          <FullPageLayout
            exact
            path="/timeclock"
            hasRole={User.RoleMappings.SUPERVISE}
            allowLimited={true}
            component={lazy(() => import('./terminal'))}
          />

          <MainLayout exact path="/availability/:userId?" component={lazy(() => import('./availability'))} />

          <MainLayout
            path="/attendance"
            exact
            hideFooter
            hasRole={User.RoleMappings.SUPERVISE}
            component={lazy(() => import('./attendance/modules/tracker'))}
          />
          <FullPageLayout path="/attendance/setup" component={lazy(() => import('./attendance/modules/onboarding'))} />
          <MainLayout
            path="/attendance/get-started"
            hideFooter
            component={lazy(() => import('./attendance/components/AttendanceSplashPageRoute'))}
          />

          <MainLayout path="/documents/:docId?" component={hasDocStorage(lazy(() => import('./documents')))} />

          <MainLayout
            exact
            path="/reporting"
            hasRole={User.RoleMappings.MANAGE}
            component={lazy(() => import('./reporting'))}
          />
          <MainLayout
            path="/reporting/custom"
            hasRole={User.RoleMappings.MANAGE}
            component={lazy(() => import('./reporting/components/CustomReportSubstrate'))}
          />

          <MainLayout
            exact
            path="/tags"
            hasRole={User.RoleMappings.SUPERVISE}
            component={lazy(() => import('./tags'))}
          />

          <MainLayout
            path="/workchat/setup"
            hideFooter
            hasRole={User.RoleMappings.SUPERVISE}
            component={lazy(() => import('./workchat/WorkchatSetup'))}
          />
          <MainLayout
            path="/workchat/onboarding"
            hasRole={User.RoleMappings.SUPERVISE}
            component={lazy(() => import('./workchat/WorkchatOnboarding'))}
          />

          <MainLayout exact path="/coworkers" component={lazy(() => import('./coworkers'))} />
          <MainLayout exact path="/logs" component={lazy(() => import('./logs'))} />

          <FullPageLayout
            path="/test/launchdarkly"
            component={lazy(() => import('./diagnostics/components/LaunchDarklyDiagnostics'))}
          />

          <FullPageLayout path="/unsubscribe/:uuid?" component={lazy(() => import('./unsubscribe'))} />

          <FullPageLayout path="*" component={lazy(() => import('./shared/components/NotFound'))} />
        </Switch>
      </LayoutContextProvider>
    </BrowserRouter>
  );
}

export default memo(Router);
