import type { FetchAccountResponse } from 'data/account/actions/fetchAccounts';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

export default createWiwAsyncThunk<FetchAccountResponse, string>(
  'account/fetchAccountBySubdomain',
  async (subdomain, { rejectWithValue }) => {
    try {
      return (await Monolith.query({ subdomain })
        .headers({
          'w-key': CONFIG.W_KEY,
        })
        .get('/account/check/subdomain')) as FetchAccountResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
