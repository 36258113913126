import { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';

import { useLDFlag } from 'data/LD/selectors/getLDFlag';
import { isBootstrapped } from 'data/auth/selectors/isBootstrapped';
import Router from 'routes';
import { bootstrap } from 'shared/auth/actions';
import { useStepUpAuthInitialize } from 'shared/auth/hooks/useStepUpAuthInitialize';
import { getAuthUser } from 'shared/auth/selectors';
import { useContextMenuTracking } from 'shared/hooks/useContextMenuTracking';
import { Provider as Intl } from 'shared/i18n';
import MaintenancePage from 'shared/maintenance/MaintenancePage';
import { cssVariablesResolver, getTheme } from 'shared/theme/config';
import Overlay from 'shared/ui/loaders/Overlay';
import { useWiwDispatch, useWiwSelector } from 'store';
import { TwilioProvider } from 'workchat/v2/providers/TwilioProvider';

import { DndContext } from '@dnd-kit/core';
import { MantineProvider } from '@mantine/core';
import { DatesProvider } from '@mantine/dates';
import * as Sentry from '@sentry/react';

function Root() {
  const dispatch = useWiwDispatch();

  const isBootstrappingDone = useWiwSelector(isBootstrapped);
  const isLDReady = useWiwSelector(state => state.LD.isLDReady);
  const currentUser = useWiwSelector(getAuthUser);

  const set1228Accessibility = useLDFlag('set-1228-accessibility-one-offs');

  const [theme, setTheme] = useState('');

  useEffect(() => {
    dispatch(bootstrap());
  }, []);

  useEffect(() => {
    setTheme(set1228Accessibility ? 'high-contrast' : '');
  }, [set1228Accessibility]);

  useStepUpAuthInitialize();
  useContextMenuTracking();

  if (!isBootstrappingDone || !isLDReady) {
    return <Overlay />;
  }

  return (
    <MantineProvider theme={getTheme(theme)} classNamesPrefix="wiw" cssVariablesResolver={cssVariablesResolver}>
      <DatesProvider settings={{ firstDayOfWeek: 0, timezone: currentUser?.timezone_name }}>
        <MaintenancePage>
          <DndContext>
            <IntlProvider locale="en-US">
              <Intl>
                <TwilioProvider>
                  <Router />
                </TwilioProvider>
              </Intl>
            </IntlProvider>
          </DndContext>
        </MaintenancePage>
      </DatesProvider>
    </MantineProvider>
  );
}

export default Sentry.withProfiler(Root);
