import Platform from 'shared/api/platform';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

export const updateTosAcknowledgement = createWiwAsyncThunk(
  'termsOfService/updateTosAcknowledgement',
  async (_, { rejectWithValue }) => {
    try {
      return await Platform.url('/termsofservice/acknowledge').post();
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
