import { List } from 'immutable';

export default class HourTypes {
  static REGULAR = { id: 1, name: 'Regular' };
  static OVERTIME = { id: 2, name: 'Overtime' };
  static DOUBLE_OVERTIME = { id: 5, name: 'Double Overtime' };

  static PTO = { id: 3, name: 'Paid Time Off' };
  static HOLIDAY = { id: 4, name: 'Holiday' };
  static SICK = { id: 6, name: 'Sick' };
  static TIMEOFF = { id: 7, name: 'Time Off' };

  static EarningsTypes = new List([HourTypes.REGULAR, HourTypes.OVERTIME, HourTypes.DOUBLE_OVERTIME]);

  static TimeOffTypes = new List([HourTypes.PTO, HourTypes.HOLIDAY, HourTypes.SICK]);

  static size = HourTypes.EarningsTypes.size + HourTypes.TimeOffTypes.size;
}
