import EntityState from 'data/EntityState';
import Payroll from 'data/payroll/model';
import { toEntityMap } from 'shared/util/toEntityMap';
import {
  CLOSE_PAYROLL,
  CREATE_PAYROLL,
  FETCH_PAYROLLS,
  FINALIZE_PAYROLL,
  REOPEN_PAYROLL,
  UNFINALIZE_PAYROLL,
  UPDATE_PAYROLL,
} from 'store/action-types';

export default (state = new EntityState(), action) => {
  switch (action.type) {
    case FETCH_PAYROLLS.REQUEST:
      return state.merge({
        loading: true,
        loaded: false,
        receivedAt: 0,
      });
    case FETCH_PAYROLLS.SUCCESS:
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        items: state.items.merge(toEntityMap(action.payload.payrolls, Payroll)),
        receivedAt: Date.now(),
      });
    case FETCH_PAYROLLS.FAILURE:
      return state.merge({
        loaded: false,
        loading: false,
        error: action.payload,
      });
    case CLOSE_PAYROLL.REQUEST:
      return state.merge({
        loading: true,
        loaded: false,
        receivedAt: 0,
      });
    case CLOSE_PAYROLL.SUCCESS:
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        items: state.items.merge(toEntityMap([action.payload.payroll], Payroll)),
        receivedAt: Date.now(),
      });
    case FINALIZE_PAYROLL.FAILURE:
      return state.merge({
        loaded: false,
        loading: false,
        error: action.payload,
      });
    case FINALIZE_PAYROLL.REQUEST:
      return state.merge({
        loading: true,
        loaded: false,
        receivedAt: 0,
      });
    case FINALIZE_PAYROLL.SUCCESS:
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        items: state.items.merge(toEntityMap([action.payload.payroll], Payroll)),
        receivedAt: Date.now(),
      });
    case UNFINALIZE_PAYROLL.REQUEST:
      return state.merge({
        loading: true,
        loaded: false,
        receivedAt: 0,
      });
    case UNFINALIZE_PAYROLL.SUCCESS:
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        items: state.items.merge(toEntityMap([action.payload.payroll], Payroll)),
        receivedAt: Date.now(),
      });
    case UNFINALIZE_PAYROLL.FAILURE:
      return state.merge({
        loaded: false,
        loading: false,
        error: action.payload,
      });
    case CLOSE_PAYROLL.FAILURE:
      return state.merge({
        loaded: false,
        loading: false,
        error: action.payload,
      });
    case REOPEN_PAYROLL.REQUEST:
      return state.merge({
        loading: true,
        loaded: false,
        receivedAt: 0,
      });
    case REOPEN_PAYROLL.SUCCESS:
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        items: state.items.merge(toEntityMap([action.payload.payroll], Payroll)),
        receivedAt: Date.now(),
      });
    case REOPEN_PAYROLL.FAILURE:
      return state.merge({
        loaded: false,
        loading: false,
        error: action.payload,
      });
    case UPDATE_PAYROLL.REQUEST:
      return state.merge({
        loading: true,
        receivedAt: 0,
        loaded: false,
      });
    case UPDATE_PAYROLL.SUCCESS:
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        receivedAt: Date.now(),
        items: state.items.set(action.payload.payroll.id, new Payroll(action.payload.payroll)),
      });
    case UPDATE_PAYROLL.FAILURE:
      return state.merge({
        loaded: false,
        loading: false,
        error: action.payload,
      });
    case CREATE_PAYROLL.REQUEST:
      return state.merge({
        loading: true,
        receivedAt: 0,
        loaded: false,
      });
    case CREATE_PAYROLL.FAILURE:
      return state.merge({
        loaded: false,
        loading: false,
        error: action.payload,
      });
    case CREATE_PAYROLL.SUCCESS:
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        receivedAt: Date.now(),
        items: state.items.merge(toEntityMap([action.payload], Payroll)),
      });
    default:
      return state;
  }
};
