export function getAccountItem(key: string, accountId: number, defaultValue: any = null) {
  const itemName = `${accountId}:${key}`;
  // JSON.parsing the string 'null' produces null, which satisfies the type checker
  // (Even though JSON.parse(null) actually also produces null, but the type checker doesn't like that)
  const data = JSON.parse(localStorage.getItem(itemName) || 'null');

  return data !== null ? data : defaultValue;
}

export function setAccountItem(key: string, accountId: number, value: any) {
  const itemName = `${accountId}:${key}`;

  return localStorage.setItem(itemName, JSON.stringify(value));
}

export function removeAccountItem(key: string, accountId: number) {
  const itemName = `${accountId}:${key}`;

  return localStorage.removeItem(itemName);
}
