import { useEffect, useRef } from 'react';

import FontIcon from 'shared/ui/FontIcon';

type DialogCloseButtonProps = {
  onClose: () => void;
  className?: string;
  setFocus?: boolean;
};

export const DialogCloseButton = ({ onClose, setFocus, className }: DialogCloseButtonProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    setFocus && buttonRef.current?.focus();
  }, []);

  return (
    <button
      type="button"
      ref={buttonRef}
      className={`dialog-button icon button-close ${className || ''}`}
      aria-label="Close"
      onClick={onClose}
    >
      <FontIcon icon="close" srText="Close Modal" />
    </button>
  );
};
