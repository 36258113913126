import type { WiwState } from 'store';

import { createSelector } from '@reduxjs/toolkit';

export const getStateDialogs = (state: WiwState) => state.dialogs;

export function isDialogOpen(state: WiwState, dialogName: string) {
  const dialogs = getStateDialogs(state);
  return !!dialogs[0] && dialogs[0].dialog === dialogName;
}

export function isAnyDialogOpen(state: WiwState) {
  const dialogs = getStateDialogs(state);
  return !!dialogs[0];
}

export const getTopDialog = createSelector([getStateDialogs], dialogs => dialogs[dialogs.length - 1]);
