import { getToken, getUserId } from 'shared/auth/auth';
import Request, { type RequestOptions } from 'shared/util/request';
import { getAnonymousUserId } from './identifiers';

/**
 * @deprecated use shared/api/{service addapter} instead
 * @returns {Request}
 */
export function request(urlRoot = CONFIG.API_ROOT, options: RequestOptions = {}) {
  const headers: { 'W-Token': string | undefined; 'W-UserId': number; 'W-LDAnonymousId'?: string | undefined } = {
    'W-Token': getToken(),
    'W-UserId': getUserId(),
    ...options.headers,
  };

  if (urlRoot === CONFIG.API_ROOT || urlRoot === CONFIG.V3_API_ROOT) {
    headers['W-LDAnonymousId'] = getAnonymousUserId();
  }

  return new Request(
    {
      ...options,
      headers: headers,
    },
    urlRoot,
  );
}
