import defaultRequestConfig from 'shared/api/adapters/wretch/defaultRequestConfig';
import Environment from 'shared/util/environment';
import QueryStringAddon from './QueryStringAddon';

import wretch from 'wretch';
import AbortAddon from 'wretch/addons/abort';
import formData from 'wretch/addons/formData';
import PerformanceAddon from 'wretch/addons/perfs';
import { dedupe } from 'wretch/middlewares/dedupe';
import { throttlingCache } from 'wretch/middlewares/throttlingCache';

export default (baseUrl: string, requestConfig?: { [key: string]: any }) =>
  wretch(baseUrl, requestConfig || defaultRequestConfig())
    .errorType('json')
    .resolve(resolver => {
      return resolver.res(res => {
        if (res.status === 204) {
          return res;
        }
        return res.json();
      });
    })
    .addon(QueryStringAddon)
    .addon(formData)
    .addon(AbortAddon())
    .addon(PerformanceAddon())
    .middlewares([
      dedupe({
        skip: (_url, opts) => opts.skipDedupe || opts.method !== 'GET',
      }),
      throttlingCache({
        // Disable caching for acceptance because they too fast, and local because we don't want caching here
        throttle: Environment.isAcceptance() || Environment.isTesting() ? 0 : 3000,
      }),
    ]);
