import { Record } from 'immutable';

export interface LocationCalloutFields {
  type: string;
  model: any | null; // TODO(types)
}

export enum LocationCalloutTypes {
  VerifyLocation = 'verify-location',
  VerifySiteLocation = 'verify-site-location',
}

class LocationCallout extends Record<LocationCalloutFields>({
  type: '',
  model: null,
}) {
  static Types = {
    VERIFY_LOCATION: LocationCalloutTypes.VerifyLocation,
    VERIFY_SITE_LOCATION: LocationCalloutTypes.VerifySiteLocation,
  } as const;
}

export default LocationCallout;
