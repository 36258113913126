import type { DialogType } from 'dialogs/dialogs';
import { type ComponentType, type ErrorInfo, createContext, useContext } from 'react';

export interface CloseDialogOpts {
  keyEvent?: KeyboardEvent;
  force?: boolean;

  // This allows us to pass other objects instead of real options,
  // which commonly happens if you do stuff like:
  //
  //     onClick={ closeDialog }
  //
  [other: string]: any;
}
// biome-ignore lint/suspicious/noConfusingVoidType: to maintain compatibility with existing code
export type CloseDialogFunction = (opts?: CloseDialogOpts) => boolean | void;
export type OnDismissFunction = (e?: KeyboardEvent) => boolean | undefined;

export interface DialogContextType {
  closeDialog: CloseDialogFunction;
  err?: Error;
  errInfo?: ErrorInfo;
  name: string;
  inDialog: boolean;
}

export interface DialogContextProps {
  closeDialog: CloseDialogFunction;
  dialogErr?: Error;
  dialogErrInfo?: ErrorInfo;
}

export interface DialogDirectProps {
  closeDialog: CloseDialogFunction;
}

export const dialogCallbacks = new Map();
export const dialogOnCloseListeners = new Map<DialogType, CloseDialogFunction>();

export const DialogContext = createContext<DialogContextType>({
  closeDialog: () => false,
  name: '',
  inDialog: false,
});

export function useDialogContext() {
  return useContext(DialogContext);
}

export function withDialogContext<P extends DialogContextProps>(
  Component: ComponentType<P>,
): ComponentType<Omit<P, keyof DialogContextProps>> {
  return function DialogWithCtx(props) {
    return (
      <DialogContext.Consumer>
        {value => (
          <Component
            {...(props as P)}
            closeDialog={value.closeDialog}
            dialogErr={value.err}
            dialogErrInfo={value.errInfo}
          />
        )}
      </DialogContext.Consumer>
    );
  };
}
