/**
 * ❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗
 * ❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗
 * ❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗
 * ❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗
 * ! 🔥🔥🔥⚠️⚠️️🔥🔥🔥 DO NOT ADD TO THIS FILE! please use modern redux toolkit patterning instead 🔥🔥🔥⚠️️⚠️🔥🔥🔥
 * ❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗
 * ❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗
 * ❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗
 * ❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗
 */

// Store all the action type constants here
const AllAsyncActionTypes = ['REQUEST', 'REQUEST_PAGE', 'SUCCESS', 'SUCCESS_PAGE', 'FAILURE'] as const;
type AsyncActionType = (typeof AllAsyncActionTypes)[number];

/**
 * DO NOT EDIT THIS FUNC
 * @param {string} base the request base name i.e. LOAD_ACCOUNTS
 * @return {ReduxRequest}
 */
type AsyncActionTypes<T extends string> = { [K in AsyncActionType]: `${T}_${K}` };

export function createAsyncTypes<T extends string>(base: T): AsyncActionTypes<T> {
  return {
    REQUEST: `${base}_REQUEST`,
    REQUEST_PAGE: `${base}_REQUEST_PAGE`,
    SUCCESS: `${base}_SUCCESS`,
    SUCCESS_PAGE: `${base}_SUCCESS_PAGE`,
    FAILURE: `${base}_FAILURE`,
  };
}

export function assertActionType<T>(action: any): asserts action is T {}

// LaunchDarkly
export const SET_LD_FLAGS = 'SET_LD_FLAGS';
export const UPSERT_LD_FLAGS = 'UPSERT_LD_FLAGS';

// Freemium
export const SHOW_CONVERSION_SPLASH = 'SHOW_CONVERSION_SPLASH';

// Scheduler
export const SEND_SCHEDULE_IMAGE = createAsyncTypes('SEND_SCHEDULE_IMAGE');
export const RECEIVE_SCHEDULE_IMAGE = createAsyncTypes('RECEIVE_SCHEDULE_IMAGE');
export const SET_ACTIVE_SCHEDULE_PREVIEW = 'SET_ACTIVE_SCHEDULE_PREVIEW';
export const SET_SCHEDULE_CURRENT_DATE = 'SET_SCHEDULE_CURRENT_DATE';
export const SET_CURRENT_DATE_AND_VIEW = 'SET_CURRENT_DATE_AND_VIEW';
export const UPDATE_LOCATION_FILTER = 'UPDATE_LOCATION_FILTER';
export const UPDATE_SHIFT_BACKGROUND_FILTER = 'UPDATE_SHIFT_BACKGROUND_FILTER';
export const TOGGLE_SCHEDULE_SIDEBAR = 'TOGGLE_SCHEDULE_SIDEBAR';
export const TOGGLE_SHOW_FILTERED_EMPLOYEES = 'TOGGLE_SHOW_FILTERED_EMPLOYEES';
export const SET_USER_EDITOR_ID = 'SET_USER_EDITOR_ID';
export const LIST_WAGE_STATS = createAsyncTypes('LIST_WAGE_STATS');
export const LIST_LABOR_DATA = createAsyncTypes('LIST_LABOR_DATA');
export const LIST_FORECASTING_PROJECTIONS = createAsyncTypes('LIST_FORECASTING_PROJECTIONS');
export const SET_FORECASTING_PROJECTION = createAsyncTypes('SET_FORECASTING_PROJECTION');
export const LIST_FORECASTING_TARGETS = createAsyncTypes('LIST_FORECASTING_TARGETS');
export const SET_FORECASTING_TARGET = createAsyncTypes('SET_FORECASTING_TARGET');
export const TOGGLE_SCHEDULE_PROJECTIONS = 'TOGGLE_SCHEDULE_PROJECTIONS';
export const UPDATE_SCHEDULE_FORECAST_OPTIONS = 'UPDATE_SCHEDULE_FORECAST_OPTIONS';
export const TOGGLE_FORECAST_TOOLS = 'TOGGLE_FORECAST_TOOLS';
export const TOGGLE_TEMPLATE_OVERLAY = 'TOGGLE_TEMPLATE_OVERLAY';
export const CHANGE_STRATEGY = 'CHANGE_STRATEGY';
export const TOGGLE_PRINT_MODE = 'TOGGLE_PRINT_MODE';
export const TOGGLE_HIDE_UNSCHEDULED_EMPLOYEES = 'TOGGLE_HIDE_UNSCHEDULED_EMPLOYEES';
export const CHANGE_EMPLOYEE_SORT = 'CHANGE_EMPLOYEE_SORT';
export const TOGGLE_SHOW_OTHER_SCHEDULE_SHIFTS = 'TOGGLE_SHOW_OTHER_SCHEDULE_SHIFTS';
export const TOGGLE_SHOW_UNSCHEDULED_POSITIONS = 'TOGGLE_SHOW_UNSCHEDULED_POSITIONS';
export const TOGGLE_SHOW_UNSCHEDULED_JOBSITES = 'TOGGLE_SHOW_UNSCHEDULED_JOBSITES';
export const TOGGLE_OPENSHIFT_ROW = 'TOGGLE_OPENSHIFT_ROW';
export const EXPAND_OPENSHIFT_ROW = 'EXPAND_OPENSHIFT_ROW';
export const TOGGLE_HIGHLIGHT_OPENSHIFTS = 'TOGGLE_HIGHLIGHT_OPENSHIFTS';
export const TOGGLE_HIGHLIGHT_UNCONFIRMED_SHIFTS = 'TOGGLE_HIGHLIGHT_UNCONFIRMED_SHIFTS';
export const UPDATE_TAG_FILTER = 'UPDATE_TAG_FILTER';
export const HYDRATE_FILTERS_FROM_METADATA = 'HYDRATE_FILTERS_FROM_METADATA';
export const SET_SCHEDULER_STATUS = 'SET_SCHEDULER_STATUS';
export const SCHEDULER_INIT_LOADING = 'SCHEDULER_INIT_LOADING';
export const SCHEDULER_FIRST_COMPLETE_RENDER = 'SCHEDULER_FIRST_COMPLETE_RENDER';
export const TOGGLE_BULK_EDIT_MODE = 'TOGGLE_BULK_EDIT_MODE';
export const TOGGLE_PUBLISH_CONCERNS = 'TOGGLE_PUBLISH_CONCERNS';
export const SET_SELECTED_BULK_EDIT_SHIFTS = 'SET_SELECTED_BULK_EDIT_SHIFTS';
export const ADD_SELECTED_BULK_EDIT_SHIFTS = 'ADD_SELECTED_BULK_EDIT_SHIFTS';
export const REMOVE_SELECTED_BULK_EDIT_SHIFTS = 'REMOVE_SELECTED_BULK_EDIT_SHIFTS';
export const RESET_SCHEDULER_STATE = 'RESET_SCHEDULER_STATE';
export const MOVE_SHIFT_TIMEZONES = 'MOVE_SHIFT_TIMEZONES';
export const UPDATE_DRAG_DATA = 'UPDATE_DRAG_DATA';
export const UPDATE_ROLLUP_SHIFT_CONCERNS = 'UPDATE_ROLLUP_SHIFT_CONCERNS';
export const REMOVE_ROLLUP_USER_CONCERNS = 'REMOVE_ROLLUP_USER_CONCERNS';
export const SET_USERS_WITH_SCHEDULING_CONCERNS = 'SET_USERS_WITH_SCHEDULING_CONCERNS';
export const TIMEZONE_SWITCH = 'TIMEZONE_SWITCH';
export const TIMEZONE_USER = 'TIMEZONE_USER';
export const SET_SHIFT_TO_VIEW = 'SET_SHIFT_TO_VIEW';
export const SET_FILTERS_EXPANDED = 'SET_FILTERS_EXPANDED';

// Scheduler Onboarding
export const TOGGLE_SCHEDULER_ONBOARDING_HIGHLIGHT = 'TOGGLE_SCHEDULER_ONBOARDING_HIGHLIGHT';
export const CHANGE_ONBOARDING_STEP_ID = 'CHANGE_ONBOARDING_STEP_ID';
export const CHANGE_ONBOARDING_EMPLOYEE_COUNT = 'CHANGE_ONBOARDING_EMPLOYEE_COUNT';

// Invite Manager
export const INVITE_MANAGER = createAsyncTypes('INVITE_MANAGER');

// Invite Coworker
export const INVITE_COWORKER = createAsyncTypes('INVITE_COWORKER');

// Users
export const CHECK_AVATAR_EXISTS = createAsyncTypes('CHECK_AVATAR_EXISTS');
export const UPDATE_USER_ALERT_PREFS = createAsyncTypes('UPDATE_USER_ALERT_PREFS');
export const SET_PASSWORD = createAsyncTypes('SET_PASSWORD');
export const CHANGE_PASSWORD = createAsyncTypes('CHANGE_PASSWORD');
export const UPDATE_USER_FILTER = 'UPDATE_USER_FILTER';

export const UPDATE_USERS = 'UPDATE_USERS';
export const DELETE_USERS = 'DELETE_USERS';

// Account conversion
export const CONVERT_ACCOUNT = createAsyncTypes('CONVERT_ACCOUNT');

// Dialogs
export const DIALOG_OPEN = 'DIALOG_OPEN';
export const DIALOG_UPDATE = 'DIALOG_UPDATE';
export const DIALOG_CLOSE = 'DIALOG_CLOSE';
export const DIALOG_CLOSE_ALL = 'DIALOG_CLOSE_ALL';

// Notice
export const NOTICE_ADD = 'NOTICE_ADD';
export const NOTICE_REMOVE = 'NOTICE_REMOVE';
export const NOTICE_TOAST = 'NOTICE_TOAST';

export const ROUTE_SET = 'ROUTE_SET';

// Positions
export const GET_POSITION_FILTER = 'GET_POSITION_FILTER';
export const UPDATE_POSITION_FILTER = 'UPDATE_POSITION_FILTER';
export const UPDATE_SITE_FILTER = 'UPDATE_SITE_FILTER';

// Requests
export const FETCH_REQUESTS = createAsyncTypes('FETCH_REQUESTS');
export const CLEAR_REQUESTS = 'CLEAR_REQUESTS';
export const UPDATE_REQUEST = 'UPDATE_REQUEST';
export const REMOVE_REQUEST = 'REMOVE_REQUEST';
export const HYDRATE_REQUEST = 'HYDRATE_REQUESTS';
export const UPDATE_SWAP_SHIFT = 'UPDATE_SWAP_SHIFT';

//Request Types
export const FETCH_REQUEST_TYPES = createAsyncTypes('FETCH_REQUEST_TYPES');
export const UPDATE_REQUEST_TYPES = 'UPDATE_REQUEST_TYPES';

// Messages
export const FETCH_MESSAGES = createAsyncTypes('FETCH_MESSAGES');
export const UPDATE_MESSAGE = 'UPDATE_MESSAGE';

export const RECEIVE_LOGIN = createAsyncTypes('RECEIVE_LOGIN');
export const FETCH_PERSON = createAsyncTypes('FETCH_PERSON');

// Shifts
export const FETCH_SHIFTS = createAsyncTypes('FETCH_SHIFTS');
export const FETCH_SHIFTS_MERGE = 'FETCH_SHIFTS_MERGE';
export const UPDATE_SHIFT = 'UPDATE_SHIFT';
export const REVERT_UPDATE_SHIFT = 'REVERT_UPDATE_SHIFT';
export const UPDATE_SHIFTS = 'UPDATE_SHIFTS';
export const DELETE_SHIFT = createAsyncTypes('DELETE_SHIFT');
export const NOTIFY_SHIFT = createAsyncTypes('NOTIFY_SHIFT');
export const PUBLISH_SHIFTS = createAsyncTypes('PUBLISH_SHIFTS');
export const UNPUBLISH_SHIFTS = createAsyncTypes('UNPUBLISH_SHIFTS');
export const NOTIFY_SHIFTS = createAsyncTypes('NOTIFY_SHIFTS');
export const CONFIRM_SHIFTS = createAsyncTypes('CONFIRM_SHIFTS');
export const APPLY_TEMPLATE = createAsyncTypes('APPLY_TEMPLATE');
export const UNASSIGN_SHIFTS = createAsyncTypes('UNASSIGN_SHIFTS');
export const REMOVE_SHIFT_FROM_STATE = 'REMOVE_SHIFT_FROM_STATE';
export const UPDATE_COMPLETE = 'UPDATE_COMPLETE';

// OPENSHIFTS
export const FETCH_OPENSHIFT_ASSIGNMENT = createAsyncTypes('FETCH_OPENSHIFT_ASSIGNMENT');
export const CONFIRM_OPENSHIFT_ASSIGNMENT = createAsyncTypes('CONFIRM_OPENSHIFT_ASSIGNMENT');
export const REVERT_OPENSHIFT_ASSIGNMENT = 'REVERT_OPENSHIFT_ASSIGNMENT';
export const UPDATE_OPENSHIFT_TAB = 'UPDATE_OPENSHIFT_TAB';

export const RECEIVE_USERS = createAsyncTypes('RECEIVE_USERS');
export const RECEIVE_USER_SETTINGS = createAsyncTypes('RECEIVE_USER_SETTINGS');
export const REPLACE_USERS = 'REPLACE_USERS';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = createAsyncTypes('DELETE_USER');
export const INVITE_USER = createAsyncTypes('INVITE_USER');

export const RECEIVE_ACCOUNTS = createAsyncTypes('RECEIVE_ACCOUNTS');
export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export const RECEIVE_USERS_REQUEST = 'RECEIVE_USERS_REQUEST';
export const DELETE_ACCOUNT = createAsyncTypes('DELETE_ACCOUNT');

// Shift Chains
export const RECEIVE_SHIFT_CHAINS = 'RECEIVE_SHIFT_CHAINS';
export const DELETE_SHIFT_CHAIN = createAsyncTypes('DELETE_SHIFT_CHAIN');

// Blocks
export const LIST_BLOCKS = createAsyncTypes('LIST_BLOCKS');
export const REMOVE_BLOCK = createAsyncTypes('REMOVE_BLOCK');
export const REMOVE_BLOCKS = createAsyncTypes('REMOVE_BLOCKS');
export const UPDATE_BLOCK = 'UPDATE_BLOCK';
export const REVERT_BLOCKS = 'REVERT_BLOCKS';

// Transactions
export const LIST_TRANSACTIONS = createAsyncTypes('LIST_TRANSACTIONS');
export const ADD_TRANSACTION = 'ADD_TRANSACTION';

// Countries
export const LIST_COUNTRIES = createAsyncTypes('LIST_COUNTRIES');

// Timezones
export const LIST_TIMEZONES = createAsyncTypes('LIST_TIMEZONES');

// Industries
export const LIST_INDUSTRIES = createAsyncTypes('LIST_INDUSTRIES');

// Plans
export const LIST_PLANS = createAsyncTypes('LIST_PLANS');

//Auth
export const REQUEST_AUTH = 'REQUEST_AUTH';
export const RECEIVE_AUTH = 'RECEIVE_AUTH';
export const LOGOUT = 'LOGOUT';

// Billing - Info
export const UPDATE_BILLING = createAsyncTypes('UPDATE_BILLING');

// Billing - Plan
export const UPDATE_BILLING_PLAN = createAsyncTypes('UPDATE_BILLING_PLAN');

// Billing V2
export const FETCH_BILLING_ACCOUNT = createAsyncTypes('FETCH_BILLING_ACCOUNT');
export const LIST_BILLING_PRODUCTS = createAsyncTypes('LIST_BILLING_PRODUCTS');
export const FETCH_BILLING_PAYMENT_METHOD = createAsyncTypes('FETCH_BILLING_PAYMENT_METHOD');
export const CREATE_BILLING_PAYMENT_METHOD = createAsyncTypes('CREATE_BILLING_PAYMENT_METHOD');
export const FETCH_BILLING_TAX_RATE = createAsyncTypes('FETCH_BILLING_TAX_RATE');
export const LIST_BILLING_EVENTS = createAsyncTypes('LIST_BILLING_EVENTS');

// Integrations - Integration Marketplace
export const FETCH_INTEGRATIONS = createAsyncTypes('FETCH_INTEGRATIONS');

// General Integrations
export const FETCH_INTEGRATIONS_GO = createAsyncTypes('FETCH_INTEGRATIONS_GO');
export const UPDATE_INTEGRATION = 'UPDATE_INTEGRATION';
export const REVERT_INTEGRATION = 'ACTIVATE_INTEGRATION';

// Paychex
export const FETCH_PAYCHEX = createAsyncTypes('FETCH_PAYCHEX');
export const UPDATE_PAYCHEX = createAsyncTypes('UPDATE_PAYCHEX');

// Integrations - Xero
export const FETCH_XERO = createAsyncTypes('FETCH_XERO');
export const UPDATE_XERO = createAsyncTypes('UPDATE_XERO');
export const DISABLE_XERO = createAsyncTypes('DISABLE_XERO');
export const SYNC_XERO = createAsyncTypes('SYNC_XERO');

// Integrations - QuickBooks Online
export const CHECK_QUICKBOOKS = createAsyncTypes('CHECK_QUCIKBOOKS');
export const SEND_PAYROLL_QUICKBOOKS = createAsyncTypes('SEND_PAYROLL_QUICKBOOKS');

// Integrations - Gusto
export const CHECK_GUSTO = createAsyncTypes('CHECK_GUSTO');
export const CHECK_GUSTO_CONFIG = createAsyncTypes('CHECK_GUSTO_CONFIG');
export const SEND_PAYROLL_GUSTO = createAsyncTypes('SEND_PAYROLL_GUSTO');

// Integrations - Workforce Now
export const FETCH_WFN = createAsyncTypes('FETCH_WFN');
export const FETCH_WFN_SETTINGS = createAsyncTypes('FETCH_WFN_SETTINGS');
export const UPDATE_WFN_SETTINGS = createAsyncTypes('UPDATE_WFN_SETTINGS');
export const UPDATE_WFN_MAPPING = createAsyncTypes('UPDATE_WFN_MAPPING');
export const REMOVE_WFN_MAPPING = createAsyncTypes('REMOVE_WFN_MAPPING');
export const FETCH_REMOTE_WFN_SCHEDULES = createAsyncTypes('FETCH_REMOTE_WFN_SCHEDULES');
export const FETCH_REMOTE_WFN_POSITIONS = createAsyncTypes('FETCH_REMOTE_WFN_POSITIONS');
export const FETCH_REMOTE_WFN_HOUR_TYPES = createAsyncTypes('FETCH_REMOTE_WFN_HOUR_TYPES');
export const FETCH_REMOTE_WFN_EMPLOYEES = createAsyncTypes('FETCH_REMOTE_WFN_EMPLOYEES');
export const FETCH_WFN_CHILD_ACCOUNTS = createAsyncTypes('FETCH_WFN_CHILD_ACCOUNTS');
export const UPDATE_WFN_CHILD_ACCOUNT = createAsyncTypes('UPDATE_WFN_CHILD_ACCOUNT');
export const UPDATE_WFN_SHOW_DELETED_MATCHED_USERS = 'UPDATE_WFN_SHOW_DELETED_MATCHED_USERS';

// Integrations - Square
export const FETCH_SQUARE = createAsyncTypes('FETCH_SQUARE');
export const SAVE_LAST_SYNCED_PAY_PERIOD = createAsyncTypes('SAVE_LAST_SYNCED_PAY_PERIOD');
export const FETCH_SQUARE_SCHEDULES = createAsyncTypes('FETCH_SQUARE_SCHEDULES');
export const FETCH_SQUARE_EMPLOYEES = createAsyncTypes('FETCH_SQUARE_EMPLOYEES');
export const SAVE_SQUARE_MAPPINGS = createAsyncTypes('SAVE_SQUARE_MAPPINGS');
export const UPDATE_SQUARE_MAPPING = createAsyncTypes('UPDATE_SQUARE_MAPPING');
export const FETCH_SQUARE_SETTINGS = createAsyncTypes('FETCH_SQUARE_SETTINGS');
export const SAVE_SQUARE_SETTINGS = createAsyncTypes('SAVE_SQUARE_SETTINGS');

export const CLOSE_AVAILABILITY_DIALOG = 'CLOSE_AVAILABILITY_DIALOG';
export const OPEN_AVAILABILITY_DIALOG = 'OPEN_AVAILABILITY_DIALOG';

// Timesheets
export const SET_CURRENT_PAYROLL = 'SET_CURRENT_PAYROLL';
export const SET_PAYROLL_USER = 'SET_PAYROLL_USER';
export const SET_PAYROLL_LOCATION = 'SET_PAYROLL_LOCATION';
export const FETCH_USER_TIMESHEET = createAsyncTypes('FETCH_USER_TIMESHEET');
export const FETCH_PAYROLLS = createAsyncTypes('FETCH_PAYROLLS');
export const CREATE_PAYROLL = createAsyncTypes('CREATE_PAYROLL');
export const UPDATE_PAYROLL = createAsyncTypes('UPDATE_PAYROLL');
export const CLOSE_PAYROLL = createAsyncTypes('CLOSE_PAYROLL');
export const FINALIZE_PAYROLL = createAsyncTypes('FINALIZE_PAYROLL');
export const UNFINALIZE_PAYROLL = createAsyncTypes('UNFINALIZE_PAYROLL');
export const REOPEN_PAYROLL = createAsyncTypes('REOPEN_PAYROLL');
export const FETCH_TIMESHEETS = createAsyncTypes('FETCH_TIMESHEETS');
export const TOGGLE_SHOW_SHIFTS = 'TOGGLE_SHIFTS';
export const OPEN_ADDITEM_POPOVER = 'OPEN_ADDITEM_POPOVER';
export const CLOSE_ADDITEM_POPOVER = 'CLOSE_ADDITEM_POPOVER';
export const CLOSE_DETAILS_MENU_POPOVER = 'CLOSE_DETAILS_MENU_POPOVER';
export const OPEN_DETAILS_MENU_POPOVER = 'OPEN_DETAILS_MENU_POPOVER';
export const CLOSE_NOTES_POPOVER = 'CLOSE_NOTES_POPOVER';
export const OPEN_NOTES_POPOVER = 'OPEN_NOTES_POPOVER';
export const FETCH_SUMMARY_DATA = createAsyncTypes('FETCH_SUMMARY_DATA');
export const SET_ACCEPTED_MISSING_ENTRIES = 'SET_ACCEPTED_MISSING_ENTRIES';
export const TOGGLE_FLAG_USERS = 'TOGGLE_FLAG_USERS';
export const SET_TIMESHEET_VIEW = 'SET_TIMESHEET_VIEW';
export const FETCH_PAYROLL_DATA = createAsyncTypes('FETCH_PAYROLL_DATA');
export const FETCH_PAYROLL_DATA_FOR_USER = createAsyncTypes('FETCH_PAYROLL_DATA_FOR_USER');
export const TIMESHEET_APPROVAL_MODE = 'TIMESHEET_APPROVAL_MODE';
export const LEAVE_APPROVAL_MODE = 'LEAVE_APPROVAL_MODE';
export const ADD_NEW_TIMESHEETS_ENTRY = 'ADD_NEW_TIMESHEETS_ENTRY';
export const APPROVE_TIMES = createAsyncTypes('APPROVE_TIMES');
export const SET_NEW_TIMESHEETS_ENTRY_PENDING = 'SET_NEW_TIMESHEETS_ENTRY_PENDING';
export const FETCH_PAYROLL_NOTICES = createAsyncTypes('FETCH_PAYROLL_NOTICES');
export const CLEAR_PAYROLL_NOTICES = createAsyncTypes('CLEAR_PAYROLL_NOTICES');
export const REMOVE_PAYROLL_NOTICE = 'REMOVE_PAYROLL_NOTICE';

// WorkProps
export const FETCH_WORK_PROP = createAsyncTypes('FETCH_WORK_PROP');
export const UPDATE_WORK_PROP = 'UPDATE_WORK_PROP';
export const DELETE_WORK_PROP = createAsyncTypes('DELETE_WORK_PROP');

//Task Lists
export const FETCH_TASK_LISTS = createAsyncTypes('FETCH_TASK_LISTS');
export const UPDATE_TASK_LISTS = 'UPDATE_TASK_LISTS';
export const DELETE_TASK_LIST = createAsyncTypes('DELETE_TASK_LIST');
export const DELETE_TASK_LIST_BULK = createAsyncTypes('DELETE_TASK_LIST_BULK');

//Scheduled Task Lists
export const FETCH_SCHEDULED_TASK_LISTS = createAsyncTypes('FETCH_SCHEDULED_TASK_LISTS');
export const UPDATE_SCHEDULED_TASK_LISTS = 'UPDATE_SCHEDULED_TASK_LISTS';
export const UPDATE_SCHEDULED_TASK = 'UPDATE_SCHEDULED_TASK';
export const DELETE_SCHEDULED_TASK_LIST = createAsyncTypes('DELETE_SCHEDULED_TASK_LIST');
export const REPLACE_SHIFT_TASK_LISTS = 'REPLACE_SHIFT_TASK_LISTS';

// Annotation
export const CREATE_ANNOTATION = 'CREATE_ANNOTATION';
export const DELETE_ANNOTATION = createAsyncTypes('DELETE_ANNOTATION');
export const UPDATE_ANNOTATION = 'UPDATE_ANNOTATION';
export const FETCH_ANNOTATIONS = createAsyncTypes('FETCH_ANNOTATIONS');

export const FETCH_SHIFT_HISTORY = createAsyncTypes('FETCH_SHIFT_HISTORY');
export const SHIFT_EXPORT = createAsyncTypes('SHIFT_EXPORT');

// Attendance Setup
export const START_ATTENDANCE_TRIAL = createAsyncTypes('START_ATTENDANCE_TRIAL');

// Templates
export const LIST_TEMPLATES = createAsyncTypes('LIST_TEMPLATES');
export const CREATE_TEMPLATE = createAsyncTypes('CREATE_TEMPLATE');
export const DELETE_TEMPLATE = createAsyncTypes('DELETE_TEMPLATE');
export const LIST_TEMPLATE_SHIFTS = createAsyncTypes('LIST_TEMPLATE_SHIFTS');

// Upsell Modal
export const OPEN_UPSELL_DIALOG = 'OPEN_UPSELL_DIALOG';
export const CLOSE_UPSELL_DIALOG = 'CLOSE_UPSELL_DIALOG';

// Swaps
export const LIST_SWAPS = createAsyncTypes('LIST_SWAPS');
export const CREATE_SWAP = createAsyncTypes('CREATE_SWAP');
export const UPDATE_SWAP = createAsyncTypes('UPDATE_SWAP');
export const FETCH_SWAP_USERS = createAsyncTypes('FETCH_SWAP_USERS');
export const CLEAR_SWAPS = 'CLEAR_SWAPS';

// Notifications
export const FETCH_NOTIFICATIONS = createAsyncTypes('FETCH_NOTIFICATIONS');
export const NOTIFICATIONS_DRAWER = 'NOTIFICATIONS_DRAWER';
export const SEARCH_NOTIFICATIONS = 'SEARCH_NOTIFICATIONS';
export const SET_NOTIFICATION_SECTION = 'SET_NOTIFICATION_SECTION';
export const UPDATE_NOTIFICATION_LOCATION = 'UPDATE_NOTIFICATION_LOCATION';
export const UPDATE_NOTIFICATION_NEW_COUNT = 'UPDATE_NOTIFICATION_NEW_COUNT';
export const UPDATE_NOTIFICATION_REQUEST = 'UPDATE_NOTIFICATION_REQUEST';
export const UPDATE_NOTIFICATION_USER = 'UPDATE_NOTIFICATION_USER';
export const DELETE_NOTIFICATION_USER = 'DELETE_NOTIFICATION_USER';

// Times
export const FETCH_TIMES = createAsyncTypes('FETCH_TIMES');
export const CREATE_TIMES = createAsyncTypes('CREATE_TIMES');
export const UPDATE_TIMES = createAsyncTypes('UPDATE_TIMES');
export const UPDATE_TIMES_STORE = 'UPDATE_TIMES_STORE';
export const FETCH_TIMES_HOURS_FOR_USER = createAsyncTypes('FETCH_TIMES_HOURS_FOR_USER');
export const REMOVE_TIME = createAsyncTypes('REMOVE_TIME');
export const FETCH_TIME_HISTORY = createAsyncTypes('FETCH_TIME_HISTORY');

// Punches
export const FETCH_PUNCHES = createAsyncTypes('FETCH_PUNCHES');
export const FETCH_PUNCH_STATE_FOR_USER = createAsyncTypes('FETCH_PUNCH_STATE_FOR_USER');

// Shift Breaks
export const FETCH_SHIFT_BREAKS_FOR_PUNCH = createAsyncTypes('FETCH_SHIFT_BREAKS_FOR_PUNCH');
export const FETCH_SHIFT_BREAKS = createAsyncTypes('FETCH_SHIFT_BREAKS');
export const ADD_SHIFT_BREAK = createAsyncTypes('ADD_SHIFT_BREAK');
export const UPDATE_SHIFT_BREAK = createAsyncTypes('UPDATE_SHIFT_BREAK');
export const DELETE_SHIFT_BREAK = createAsyncTypes('DELETE_SHIFT_BREAK');
export const FETCH_SHIFT_BREAK_AUDITS = createAsyncTypes('FETCH_SHIFT_BREAK_AUDITS');
export const ADD_SHIFT_BREAK_AUDIT = createAsyncTypes('ADD_SHIFT_BREAK_AUDIT');

//overtime
export const FETCH_OVERTIME = createAsyncTypes('FETCH_OVERTIME');

// raw cached overtime (for Dashboard Forecasting cards)
export const FETCH_RAW_CACHED_OVERTIME = createAsyncTypes('FETCH_RAW_CACHED_OVERTIME');

// Dashboard
export const SET_DAY_OR_WEEK_BY_CARD = 'SET_DAY_OR_WEEK_BY_CARD';
export const SET_TODAYS_SCHEDULE_LOCATION_ID = 'SET_TODAYS_SCHEDULE_LOCATION_ID';
export const SET_INTERVAL_BY_CARD = 'SET_INTERVAL_BY_CARD';
export const SET_ATTENDANCE_NOTICES_FILTERS = 'SET_ATTENDANCE_NOTICES_FILTERS';

//overtime scheduled
export const FETCH_OVERTIME_SCHEDULED = createAsyncTypes('FETCH_OVERTIME_SCHEDULED');

//overtime labor cost
export const FETCH_OVERTIME_LABOR = createAsyncTypes('FETCH_OVERTIME_LABOR');
export const FETCH_OVERTIME_LABOR_REPLACE = 'FETCH_OVERTIME_LABOR_REPLACE';
export const FETCH_OVERTIME_LABOR_REPLACE_USER_IDS = 'FETCH_OVERTIME_LABOR_REPLACE_USER_IDS';

// OpenShift Requests
export const FETCH_OPENSHIFT_REQUESTS = createAsyncTypes('FETCH_OPENSHIFT_REQUESTS');
export const DELETE_OPENSHIFT_USER_REQUEST = createAsyncTypes('DELETE_OPENSHIFT_USER_REQUEST');

// Metadata
export const FETCH_ACCOUNT_METADATA = createAsyncTypes('FETCH_ACCOUNT_METADATA');
export const FETCH_USER_METADATA = createAsyncTypes('FETCH_USER_METADATA');
export const UPDATE_ACCOUNT_METADATA = 'UPDATE_ACCOUNT_METADATA';
export const UPDATE_USER_METADATA = createAsyncTypes('UPDATE_USER_METADATA');

// Documents
export const FETCH_DOCUMENTS = createAsyncTypes('FETCH_DOCUMENTS');
export const UPLOAD_DOCUMENTS = createAsyncTypes('UPLOAD_DOCUMENTS');
export const DELETE_DOCUMENT = createAsyncTypes('DELETE_DOCUMENT');
export const DELETE_DOCUMENTS_BULK = createAsyncTypes('DELETE_DOCUMENTS_BULK');

// Reporting Data
export const REPORTS_FETCH = createAsyncTypes('REPORTS_FETCH');
export const REPORT_UPDATE = 'REPORT_UPDATE';
export const REPORT_REMOVE = 'REPORT_REMOVE';

// Saved Report Definitions
export const LIST_SAVED_REPORTS = createAsyncTypes('LIST_SAVED_REPORTS');
export const UPDATE_SAVED_REPORT = createAsyncTypes('UPDATE_SAVED_REPORT');
export const DELETE_SAVED_REPORT = createAsyncTypes('DELETE_SAVED_REPORT');

// Custom Reporting Data
export const FETCH_CUSTOM_REPORT_DATA = createAsyncTypes('FETCH_CUSTOM_REPORT_DATA');
export const FETCH_CUSTOM_REPORT_METADATA = createAsyncTypes('FETCH_CUSTOM_REPORT_METADATA');
export const FETCH_CURRENT_STATE_COLUMNS = createAsyncTypes('FETCH_CURRENT_STATE_COLUMNS');
export const FETCH_CURRENT_STATE_COUNTS = createAsyncTypes('FETCH_CURRENT_STATE_COUNTS');

// Custom Report Builder
export const CUSTOM_REPORT_BUILDER_SET_ACCOUNT_ID = 'CUSTOM_REPORT_BUILDER_SET_ACCOUNT_ID';
export const CUSTOM_REPORT_BUILDER_SET_ACTIVE_DIALOG = 'CUSTOM_REPORT_BUILDER_SET_ACTIVE_DIALOG';
export const CUSTOM_REPORT_BUILDER_SET_DATASET = 'CUSTOM_REPORT_BUILDER_SET_DATASET';
export const CUSTOM_REPORT_BUILDER_SET_DATE_RANGE = 'CUSTOM_REPORT_BUILDER_SET_DATE_RANGE';
export const CUSTOM_REPORT_BUILDER_SET_DATE_RANGE_NAME = 'CUSTOM_REPORT_BUILDER_SET_DATE_RANGE_NAME';
export const CUSTOM_REPORT_BUILDER_SET_INCLUDE_CHILD_ACCOUNTS = 'CUSTOM_REPORT_BUILDER_SET_INCLUDE_CHILD_ACCOUNTS';
export const CUSTOM_REPORT_BUILDER_SET_NEXT_DIALOG = 'CUSTOM_REPORT_BUILDER_SET_NEXT_DIALOG';
export const CUSTOM_REPORT_BUILDER_SET_REPORT_SIZE = 'CUSTOM_REPORT_BUILDER_SET_REPORT_SIZE';
export const CUSTOM_REPORT_BUILDER_SET_TEMP_ACCOUNT_ID = 'CUSTOM_REPORT_BUILDER_SET_TEMP_ACCOUNT_ID';
export const CUSTOM_REPORT_BUILDER_SET_TEMP_COLUMNS = 'CUSTOM_REPORT_BUILDER_SET_TEMP_COLUMNS';
export const CUSTOM_REPORT_BUILDER_SET_TEMP_DATASET = 'CUSTOM_REPORT_BUILDER_SET_TEMP_DATASET';
export const CUSTOM_REPORT_BUILDER_SET_TEMP_DATE_RANGE = 'CUSTOM_REPORT_BUILDER_SET_TEMP_DATE_RANGE';
export const CUSTOM_REPORT_BUILDER_SET_TEMP_DATE_RANGE_NAME = 'CUSTOM_REPORT_BUILDER_SET_TEMP_DATE_RANGE_NAME';
export const CUSTOM_REPORT_BUILDER_SET_TEMP_REPORT_SIZE = 'CUSTOM_REPORT_BUILDER_SET_TEMP_REPORT_SIZE';
export const CUSTOM_REPORT_BUILDER_SET_WIZARD_COMPLETED_ONCE = 'CUSTOM_REPORT_BUILDER_SET_WIZARD_COMPLETED_ONCE';
export const CUSTOM_REPORT_BUILDER_SET_SELECTED_DEFINITION_ID = 'CUSTOM_REPORT_BUILDER_SET_SELECTED_DEFINITION_ID';
export const CUSTOM_REPORT_BUILDER_LAST_FETCH = 'CUSTOM_REPORT_BUILDER_LAST_FETCH';

// Hour Stats
export const FETCH_HOUR_STATS_ACTUAL = createAsyncTypes('FETCH_HOUR_STATS_ACTUAL');
export const FETCH_HOUR_STATS_SCHEDULED = createAsyncTypes('FETCH_HOUR_STATS_SCHEDULED');
export const FETCH_HOUR_STATS_REQUESTS = createAsyncTypes('FETCH_HOUR_STATS_REQUESTS');
export const FETCH_HOUR_STATS_OPENSHIFTS = createAsyncTypes('FETCH_HOUR_STATS_OPENSHIFTS');
export const DELETE_HOUR_STATS_ACTUAL = 'DELETE_HOUR_STATS_ACTUAL';
export const DELETE_HOUR_STATS_SCHEDULED = 'DELETE_HOUR_STATS_SCHEDULED';
export const DELETE_HOUR_STATS_REQUESTS = 'DELETE_HOUR_STATS_REQUESTS';
export const DELETE_HOUR_STATS_OPENSHIFTS = 'DELETE_HOUR_STATS_OPENSHIFTS';

// Payroll Hours
export const FETCH_PAYROLL_HOURS = createAsyncTypes('FETCH_PAYROLL_HOURS');

// Forecast Items
export const FETCH_FORECAST_ITEM = createAsyncTypes('FETCH_FORECAST_ITEM');
export const UPDATE_FORECAST_ITEM = createAsyncTypes('UPDATE_FORECAST_ITEM');

// Forecast Custom Units
export const FETCH_FORECAST_CUSTOM_UNIT = createAsyncTypes('FETCH_FORECAST_CUSTOM_UNIT');
export const UPDATE_FORECAST_CUSTOM_UNIT = createAsyncTypes('UPDATE_FORECAST_CUSTOM_UNIT');
export const DELETE_FORECAST_CUSTOM_UNIT = createAsyncTypes('DELETE_FORECAST_CUSTOM_UNIT');

// Email Blacklist
export const FETCH_BLACKLIST = createAsyncTypes('FETCH_BLACKLIST');
export const UPDATE_BLACKLIST = 'UPDATE_BLACKLIST';
export const REMOVE_FROM_BLACKLIST = 'REMOVE_FROM_BLACKLIST';

// TeamTxt Items
export const CREATE_TEAM_MESSAGE = createAsyncTypes('CREATE_TEAM_MESSAGE');
export const PURCHASE_CREDITS = createAsyncTypes('PURCHASE_CREDITS');

// Work Tags
export const FETCH_SHIFT_TAGS = createAsyncTypes('FETCH_SHIFT_TAGS');
export const FETCH_TAG = createAsyncTypes('FETCH_TAG');
export const FETCH_TAGS = createAsyncTypes('FETCH_TAGS');
export const REMOVE_TAG = createAsyncTypes('REMOVE_TAG');
export const REMOVE_TAGS = createAsyncTypes('REMOVE_TAGS');
export const PERSIST_TAG = createAsyncTypes('PERSIST_TAG');
export const UPDATE_SHIFT_TAG = 'UPDATE_SHIFT_TAG';

export const FETCH_USER_TAGS = createAsyncTypes('FETCH_USER_TAGS');
export const FETCH_SHIFT_TEMPLATE_TAGS = createAsyncTypes('FETCH_SHIFT_TEMPLATE_TAGS');
export const UPDATE_SHIFT_TEMPLATE_TAG = 'UPDATE_SHIFT_TEMPLATE_TAG';

// Schedule Rules
export const FETCH_USER_RULES = createAsyncTypes('FETCH_USER_RULES');
export const FETCH_USER_SCHEDULER_INFO_RULES = createAsyncTypes('FETCH_USER_SCHEDULER_INFO_RULES');
export const FETCH_RULE_SUMMARY = createAsyncTypes('FETCH_RULE_SUMMARY');
export const FETCH_SHIFT_RULES = createAsyncTypes('FETCH_SHIFT_RULES');
export const FETCH_SHIFT_SWAP_RULES = createAsyncTypes('FETCH_SHIFT_SWAP_RULES');
export const FETCH_SHIFT_DROP_RULES = createAsyncTypes('FETCH_SHIFT_DROP_RULES');

// Free Trial
export const FETCH_FREE_TRIAL_STATUS = createAsyncTypes('FETCH_FREE_TRIAL_STATUS');

// Group Settings
export const FETCH_GROUP_SETTINGS = createAsyncTypes('FETCH_GROUP_SETTINGS');
export const CREATE_GROUP_SETTING = createAsyncTypes('CREATE_GROUP_SETTING');
export const UPDATE_GROUP_SETTINGS = createAsyncTypes('UPDATE_GROUP_SETTINGS');
export const DELETE_GROUP_SETTINGS = createAsyncTypes('DELETE_GROUP_SETTINGS');
export const RECEIVE_GROUP_SETTINGS = 'RECEIVE_GROUP_SETTINGS';

// Absences
export const SET_ABSENCE_TO_VIEW = 'SET_ABSENCE_TO_VIEW';

// payroll check service
export const FETCH_PAYROLL_STATUS = createAsyncTypes('FETCH_PAYROLL_STATUS');
export const FETCH_PAYROLL_DETAILS = createAsyncTypes('FETCH_PAYROLL_DETAILS');
