import PropTypes from 'prop-types';
import { Component, Fragment } from 'react';
import { GithubPicker } from 'react-color';

import Button from 'shared/ui/Button';
import { colors } from 'shared/ui/ColorPicker';
import SpriteIcon from 'shared/ui/SpriteIcon';
import { getTextColor } from 'shared/util/color';

import classnames from 'classnames';
import { propTypes, withFormsy } from 'formsy-react';
import { Popover, PopoverBody } from 'reactstrap';

export class ColorPicker extends Component {
  state = {
    colorPickerOpen: false,
  };

  static DEFAULT_COLOR = 'cccccc';

  static propTypes = {
    ...propTypes,
    label: PropTypes.any,
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.oneOf(['auto'])]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    inputProps: PropTypes.object,
    newStyle: PropTypes.bool,
    onDayClick: PropTypes.func,
    valueFormat: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    onClickOnModifier: PropTypes.func,
    colors: PropTypes.arrayOf(PropTypes.string),
    validatePristine: PropTypes.bool,
  };

  static defaultProps = {
    width: false,
    newStyle: false,
    valueFormat: 'MMM D YYYY',
    validatePristine: false,
    onChange: () => {},
    onClickOnModifier: () => {},
  };

  toggleColorPicker = () => {
    if (this.props.disabled) {
      return;
    }

    this.setState(prevState => ({ colorPickerOpen: !prevState.colorPickerOpen }));
  };

  showTooltip = (color, event) => {
    const target = event.target;
    const colorName = colors[color.hex];
    if (!colorName) {
      return null;
    }
    // IE-11 doesn't support adding multiple classes in a single .add() call
    target.classList.add('hint--bottom');
    target.classList.add(`hint--${colorName}`);
    target.classList.add(`hint--text-${getTextColor(color.hex.replace('#', ''))}`);
    target.setAttribute('aria-label', colorName);
    target.removeAttribute('title');
  };

  clearColor = () => {
    this.modifyValue({ hex: `#${ColorPicker.DEFAULT_COLOR}` });
  };

  modifyValue = color => {
    // color code with # sliced off
    const value = color.hex.slice(1);
    this.props.setValue(value);
    this.props.onChange(this.props.name, value);
    this.toggleColorPicker();
  };

  renderColorPicker() {
    const width = this.props.colors ? this.props.colors.length * 27 : 337;

    return (
      <div>
        {this.state.colorPickerOpen ? (
          <Popover
            target="color-popover-target"
            className="color-picker-popover"
            isOpen
            placement="bottom"
            trigger="legacy"
            toggle={this.toggleColorPicker}
            delay={0}
          >
            <PopoverBody>
              <GithubPicker
                triangle="hide"
                width={width}
                color={`#${this.props.value || ColorPicker.DEFAULT_COLOR}`}
                colors={this.props.colors || Object.keys(colors)}
                onChange={this.modifyValue}
                onSwatchHover={this.showTooltip}
              />
              <div className="clear-color-wrapper">
                <Button color="secondary" size="sm" className="clear-color-btn" onClick={this.clearColor}>
                  Clear Color
                </Button>
              </div>
            </PopoverBody>
          </Popover>
        ) : null}
      </div>
    );
  }

  backgroundColor() {
    if (this.hasSelectedValue() && this.props.value) {
      return `#${this.props.value}`;
    }

    return 'transparent';
  }

  hasSelectedValue() {
    return this.props.value && this.props.value !== ColorPicker.DEFAULT_COLOR;
  }

  renderInput(invalid) {
    const inputClassname = classnames('form-control', this.props.className, {
      'form-control-danger': invalid,
      newStyle: this.props.newStyle,
    });

    return (
      <Fragment>
        <div
          className={classnames(
            'color-wheel-container',
            { selected: this.hasSelectedValue() },
            `text-${getTextColor(this.props.value || 'FFFFFF')}`,
          )}
          id="color-popover-target"
          onClick={this.toggleColorPicker}
        >
          <div
            key="color-swatch"
            className={classnames('circle', 'color-swatch')}
            style={{ backgroundColor: `${this.backgroundColor()}` }}
          />
          <div className="circle color-wheel" />
          <div className="circle icon-container">
            <SpriteIcon icon="color-picker" />
          </div>
        </div>
        <input
          key={this.props.name}
          type="hidden"
          id={this.props.name}
          name={this.props.name}
          className={inputClassname}
          value={this.props.value || ''}
          disabled={this.props.isFormDisabled || this.props.disabled}
          onClick={this.toggleColorPicker}
          readOnly
          {...this.props.inputProps}
        />
      </Fragment>
    );
  }

  render() {
    const invalid =
      (this.props.showError || !this.props.isValid) && (this.props.validatePristine || !this.props.isPristine);

    const className = classnames('form-group color-picker', this.props.className, {
      col: !this.props.width,
      [`col-${this.props.width}`]: this.props.width,
      'has-danger': invalid,
      newStyle: this.props.newStyle,
      'color-picker--is-disabled': this.props.disabled,
    });

    return (
      <fieldset className={className}>
        {this.renderColorPicker()}
        {this.props.label && (
          <label className="form-control-label" htmlFor={this.props.name}>
            {this.props.label}
          </label>
        )}
        {this.renderInput(invalid)}
        {(this.props.validatePristine || !this.props.isPristine) && this.props.showError && (
          <span className="form-control-feedback">{this.props.errorMessage}</span>
        )}
      </fieldset>
    );
  }
}
/**
 * @deprecated please migrate to hook-form from shared/form/inputs
 */
export default withFormsy(ColorPicker);
