import EntityState from 'data/EntityState';
import TagList from 'data/tag/TagList';
import { fetchUserTags } from 'data/tag/actions/userTags/fetchUserTags';
import { fetchUserTagsForSchedulableUsers } from 'data/tag/actions/userTags/fetchUserTagsForSchedulableUsers';
import { updateUserTags } from 'data/tag/actions/userTags/updateUserTags';
import generateThunkReducers from 'data/util/generateThunkReducers';
import { mergeEntityItems } from 'data/util/sliceHelpers';

import { createSlice } from '@reduxjs/toolkit';

const initialState = new EntityState();

export const userTagsSlice = createSlice({
  name: 'tags/userTags',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchUserTags.fulfilled, (state, action) => {
        return mergeEntityItems(state, action.payload.data, TagList, 'id', Number.parseInt);
      })
      .addCase(updateUserTags.fulfilled, (state, action) => {
        return mergeEntityItems(state, [action.payload.data], TagList, 'id', Number.parseInt);
      })
      .addCase(fetchUserTagsForSchedulableUsers.fulfilled, (state, action) => {
        return mergeEntityItems(state, action.payload.data, TagList, 'id', Number.parseInt);
      });
    generateThunkReducers(builder, 'tags/userTags');
  },
});

export default userTagsSlice.reducer;
