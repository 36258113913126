import { useState } from 'react';

import { BranchPickerInput } from 'shared/dev/components/BranchPickerInput';
import { DeploymentBadge } from 'shared/dev/components/DeploymentBadge';
import type { ServiceConfig } from 'shared/dev/serviceConfig';
import type { Option } from 'shared/dev/types/types';
import Environment from 'shared/util/environment';

import classNames from 'classnames';
import Cookies from 'js-cookie';

export interface ServiceItemProps {
  config: ServiceConfig;
  onBranchChanged: () => void;
}

export function ServiceItem({ config, onBranchChanged }: ServiceItemProps) {
  const [currentBranch, setCurrentBranch] = useState(() => getBranch());

  function getDefaultBranch() {
    return Environment.isDevelopment() || Environment.isStaging() ? 'develop' : 'main';
  }

  function getBranch() {
    if (!config.branchCookieName) {
      return '<Default>';
    }
    return Cookies.get(config?.branchCookieName) ?? '<Default>';
  }

  function changeBranch(branch: Option) {
    if (!config.branchCookieName) {
      return;
    }

    if (!branch) {
      Cookies.remove(config.branchCookieName, { domain: CONFIG.COOKIE_DOMAIN });
      setCurrentBranch('<Default>');
      onBranchChanged();
      return;
    }

    Cookies.set(config?.branchCookieName!, branch.value, { domain: CONFIG.COOKIE_DOMAIN });
    setCurrentBranch(branch.value);
    onBranchChanged();
  }

  return (
    <li className="list-group-item d-flex align-items-center justify-content-between">
      <div className="d-flex flex-column">
        <a
          className="text-body"
          href={`https://workbot.api.wheniwork-staging.com/gitlab/projects/${config.gitlabProjectID}`}
          target="_blank"
          rel="noreferrer"
        >
          {config.name}
        </a>

        <DeploymentBadge projectId={config.gitlabProjectID} />
      </div>

      <div
        className={classNames('flex-column', {
          'd-flex': config.branchCookieName,
          'd-none': !config.branchCookieName,
        })}
      >
        <BranchPickerInput
          changeBranch={changeBranch}
          currentBranch={currentBranch}
          defaultBranch={getDefaultBranch()}
          replaceSlashes={true}
          projectId={config.gitlabProjectID}
        />
      </div>
    </li>
  );
}
