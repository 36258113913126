import {
  type ComponentProps,
  type FocusEvent,
  Fragment,
  type LegacyRef,
  type RefObject,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';

import InputWrapper from 'shared/components/form/wrappers/InputWrapper';
import GoogleMapsAutocomplete from 'shared/ui/inputs/GoogleMapsAutocomplete';
import isCheckAddress from 'shared/util/IsCheckAddress';
import { simplifyGeocodeResult } from 'shared/util/googleMaps/locations';

import classnames from 'classnames';
import { type FormsyInjectedProps, withFormsy } from 'formsy-react';
import type { GeocodeResult } from 'use-places-autocomplete';

interface Props extends FormsyInjectedProps {
  label?: any;
  labelAddon: any;
  name: string;
  className?: string;

  /**
   * These classes get applied to the `fieldset` element that wraps the input.
   */
  classNames?: string;
  width: boolean | number | 'auto';
  value?: any;
  mask?: string | ((value: string) => string);
  inputProps?: ComponentProps<'input'> | object;
  inputRef?: RefObject<HTMLInputElement>;
  disabled: boolean;
  labelSmall: boolean | string;
  onChange?: (name: string, value: any) => void;
  onSelect?: (name: string, value: GeocodeResult, checkValid: boolean) => void;
  onFocus?: (name: string, value: any) => void;
  onBlur?: (name: string, value: any) => void;
  validateOnlyOnBlur: boolean;
  validatePristine: boolean;
  setFocus: boolean;
  shortAddress: boolean;
  limitResults?: string[]; // see https://developers.google.com/maps/documentation/places/web-service/supported_types#table3

  errorMessage: any;
  validateCheck: boolean;
  resetOnBlur?: boolean;
  originalAddress?: string;
}

interface DefaultProps {
  width: boolean | number | 'auto';
  disabled: boolean;
  labelAddon: boolean;
  labelSmall: boolean | string;
  validateOnlyOnBlur: boolean;
  validatePristine: boolean;
  setFocus: boolean;
  shortAddress: boolean;
  validateCheck: boolean;
}

const defaultProps: DefaultProps = {
  width: false,
  disabled: false,
  labelAddon: false,
  labelSmall: false,
  validateOnlyOnBlur: false,
  validatePristine: false,
  setFocus: false,
  shortAddress: false,
  validateCheck: false,
};

type NewProps = Omit<Props, keyof DefaultProps> & Partial<DefaultProps>;

function GoogleMapsAutoSuggest(props: NewProps, ref: LegacyRef<HTMLInputElement>) {
  props = { ...defaultProps, ...props } as NewProps;

  const innerRef = useRef(ref);

  const [focus, setFocus] = useState(false);
  const [checkValid, setCheckValid] = useState(true);
  const [checkMessage, setCheckMessage] = useState('');
  const [originalAddress, setOriginalAddress] = useState('');

  useEffect(() => {
    if (props.setFocus && innerRef.current) {
      // @ts-ignore
      innerRef.current.focus();
    }
    setOriginalAddress(props.value);
  }, []);

  const onChange = (name: string, value: any) => {
    if (props.onChange) {
      props.onChange(name, value);
    }

    if (value === '') {
      setCheckValid(true);
    }

    props.setValue(value);
  };

  const onSelect = async (_name: string, location: GeocodeResult) => {
    props.onSelect?.(props.name, location, true);

    if (props.validateCheck) {
      await isCheckAddress(location).then(result => {
        if (result !== '') {
          setCheckValid(false);
          setCheckMessage(result);
          props.onSelect?.(props.name, location, false);
          return;
        }

        setCheckValid(true);
      });
    }

    let value = location.formatted_address;
    if (props.shortAddress) {
      const simplified = simplifyGeocodeResult(location);
      value = simplified.shortAddress;
    }

    if (props.validateOnlyOnBlur) {
      return props.setValue(value, false);
    }

    return props.setValue(value);
  };

  const onFocus = (e: FocusEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFocus(true);
    props.onFocus?.(props.name, value);
  };

  const onBlur = (e: FocusEvent<HTMLInputElement>) => {
    const value = e.target.value;
    props.onBlur?.(props.name, value);
    setFocus(false);
    return props.setValue(value);
  };

  const getInputId = () => {
    const { inputProps, name, label } = props;

    if (inputProps && typeof inputProps === 'object' && 'id' in inputProps) {
      return inputProps.id;
    }

    if (label) {
      //inputs with a label match for=id using name
      return name;
    }

    return undefined;
  };

  const renderInput = (invalid: boolean) => {
    const inputClassname = classnames('form-control', props.className, {
      'is-invalid': invalid,
    });

    let value = props.value;
    if (value === null || typeof value === 'undefined') {
      value = '';
    }

    return (
      <GoogleMapsAutocomplete
        type="text"
        name={props.name}
        id={getInputId()}
        className={inputClassname}
        //@ts-ignore
        value={value}
        disabled={props.isFormDisabled || props.disabled}
        onFocus={onFocus}
        //@ts-ignore
        onBlur={onBlur}
        originalAddress={originalAddress}
        resetOnBlur={props.resetOnBlur}
        //@ts-ignore
        onChange={onChange}
        // @ts-ignore
        onSelect={onSelect}
        //@ts-ignore
        ref={innerRef}
        inputOnly
        limitResults={props.limitResults}
        shortAddress={props.shortAddress}
        {...props.inputProps}
      />
    );
  };

  const renderReturn = () => {
    if (props.validateCheck && !checkValid) {
      return (
        <InputWrapper
          focus={focus}
          {...props}
          showError={true}
          errorMessage={checkMessage}
          isValid={false}
          inputId={getInputId()}
        >
          {renderInput(invalid)}
        </InputWrapper>
      );
    }

    return (
      <InputWrapper focus={focus} {...props} inputId={getInputId()}>
        {renderInput(invalid)}
      </InputWrapper>
    );
  };

  const invalid = props.showError || (!props.isValid && (props.validatePristine || !props.isPristine)) || !checkValid;

  props.setValidations({
    checkErr: () => {
      return checkValid;
    },
  });

  return <Fragment>{renderReturn()}</Fragment>;
}
/**
 * @deprecated please migrate to hook-form from shared/form/inputs
 */
export default withFormsy<NewProps>(forwardRef<HTMLInputElement, NewProps>(GoogleMapsAutoSuggest));
