import { DateTime } from 'luxon';
import moment, { type Moment } from 'moment-timezone';

// ========================= Luxon / Luxon ==============================

/**
 * Adjusts the underlying Luxon time so that the local time stays the same numeric value, but indicated relative
 *  to the Device's timezone (eg: Midnight GMT+10 becomes Midnight in the Device's timezone)
 */
export function luxonToDeviceRelative(time: DateTime): DateTime {
  if (!time.isValid) {
    return time;
  }
  // Get the JSDate offset as told by the browser for the given Luxon datetime (this accounts for DST)
  const jsoffset = time.toJSDate().getTimezoneOffset();

  /**
   * Determine if the Luxon is already represented locally JSDate offset and Luxon offset are represented in
   *  opposite forms. JSDate UTC+10 is -600, while Luxon UTC-10 is 600 so if we add them together, they should
   *  negate if they are both in the same timezone.
   *  see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getTimezoneOffset
   */
  const isLocal = time.offset + jsoffset === 0;

  let local = time;
  if (!isLocal) {
    // Apply the difference between the browser offset and the Luxon account-relative offset
    local = DateTime.fromMillis(time.valueOf() - time.offset * 60000).toUTC(jsoffset, { keepLocalTime: true });
  }
  // Return a UTC luxon of the relative time
  return local;
}

/**
 * Adjusts the underlying Luxon time so that the local time stays the same numeric value, but indicated relative
 *  to the Account's timezone (eg: Midnight GMT+10 becomes Midnight in the Account's timezone)
 */
export function luxonToAccountRelative(time: DateTime): DateTime {
  if (!time.isValid) {
    return time;
  }

  return time.toUTC(time.toLocal().offset, { keepLocalTime: true });
}

// ========================= Luxon / JSDate ==============================

// Null safe conversion of a Luxon to a JSDate
export function luxonToJSDate(time: DateTime | null | undefined): Date | null {
  if (!time || !time.isValid) {
    return null;
  }

  return time.toJSDate();
}

/**
 * Convert a Luxon to a JSDate adjusted relatively to the Device's timezone, but keeping only the calendar date.
 * (eg: Noon on Feb 12th GMT+10 becomes Midnight on Feb 12th in the Device's timezone)
 */
export function luxonToJSDateDeviceDateOnly(time: DateTime | null | undefined): Date | null {
  if (!time || !time.isValid) {
    return null;
  }
  return convertUTCToLocalDate(time.toUTC(0, { keepLocalTime: true }).toJSDate());
}

/**
 * Convert a Luxon to a JSDate with the underlying time adjusted so that the local time stays the same numeric
 * value, but indicated relative to the Device's timezone
 */
export function luxonToJSDateDeviceRelative(time: DateTime | null | undefined): Date | null {
  if (!time) {
    return null;
  }
  const jslocaloffset = time.toJSDate().getTimezoneOffset();
  const relative = DateTime.fromMillis(time.valueOf() - (time.offset - jslocaloffset) * 60000);
  return relative.toUTC(0, { keepLocalTime: true }).toJSDate();
}

/**
 * Convert a JSDate to a Luxon with the underlying time adjusted so that the local time stays the same numeric
 * value, but indicated relative to the Account's timezone
 */
export function jsDateToLuxonAccountRelative(time: Date | null | undefined): DateTime | null {
  if (!time) {
    return null;
  }
  const jsoffset = time.getTimezoneOffset();
  const relative = DateTime.fromJSDate(time).toUTC(jsoffset, { keepLocalTime: true });

  return luxonToAccountRelative(relative);
}

/**
 * Convert a JSDate to a Luxon with the underlying time adjusted so that the local time stays the same numeric
 * value, but indicated relative to the Device's timezone
 */
export function jsDateToLuxonDeviceRelative(time: Date | null | undefined): DateTime | null {
  if (!time) {
    return null;
  }

  return luxonToDeviceRelative(DateTime.fromJSDate(time));
}

// ========================= Luxon / Moment ==============================

// Null safe conversion of a Luxon to a Moment
export function luxonToMoment(time: DateTime | null | undefined): Moment | null {
  if (!time) {
    return null;
  }

  return moment(time.valueOf());
}

/**
 * Convert a Luxon to a Moment with the underlying time adjusted so that the local time stays the same numeric
 *  value, but indicated relative to the Account's timezone
 */
export function luxonToMomentAccountRelative(time: DateTime | null | undefined): Moment | null {
  if (!time) {
    return null;
  }

  return moment(luxonToAccountRelative(time).valueOf());
}

/**
 * Convert a Moment to a Luxon with the underlying time adjusted so that the local time stays the same numeric
 * value, but indicated relative to the Account's timezone
 */
export function momentToLuxonAccountRelative(time: Moment | null | undefined): DateTime | null {
  if (!time) {
    return null;
  }

  return luxonToAccountRelative(DateTime.fromMillis(time.valueOf()));
}

/**
 * Convert a Moment to a Luxon with the underlying time adjusted so that the local time stays the same numeric
 * value, but indicated relative to the Device's timezone
 */
export function momentToLuxonDeviceRelative(time: Moment | null | undefined): DateTime | null {
  if (!time) {
    return null;
  }

  return luxonToDeviceRelative(DateTime.fromMillis(time.valueOf()));
}

// ========================= Utils ==============================

// Format a Luxon, Moment, or JSDate using Luxon text formatter.
export function formatAnyTime(time: DateTime | Moment | Date, format: Intl.DateTimeFormatOptions): string {
  let converted = time;
  if (time instanceof moment) {
    converted = DateTime.fromMillis((time as Moment).valueOf());
  } else if (time instanceof Date) {
    converted = DateTime.fromMillis((time as Date).getTime());
  }
  return converted.toLocaleString(format);
}

/**
 * Format a Luxon, Moment, or JSDate using Luxon text formatter with the underlying time adjusted so that the local
 * time stays the same numeric value, but indicated relative to the Account's timezone
 */
export function formatAnyTimeAccountRelative(time: DateTime | Moment | Date, format: Intl.DateTimeFormatOptions) {
  if (time instanceof moment) {
    return momentToLuxonAccountRelative(time as Moment)!.toLocaleString(format);
  }
  if (time instanceof Date) {
    return jsDateToLuxonAccountRelative(time as Date)!.toLocaleString(format);
  }
  return luxonToAccountRelative(time as DateTime).toLocaleString(format);
}

/**
 *  Format a Luxon, Moment, or JSDate using Luxon text formatter with the underlying time adjusted so that the
 *  local time stays the same numeric value, but indicated relative to the Device's timezone
 */
export function formatAnyTimeDeviceRelative(time: DateTime | Moment | Date, format: Intl.DateTimeFormatOptions) {
  if (time instanceof moment) {
    return momentToLuxonDeviceRelative(time as Moment)!.toLocaleString(format);
  }
  if (time instanceof Date) {
    return jsDateToLuxonDeviceRelative(time as Date)!.toLocaleString(format);
  }
  return luxonToDeviceRelative(time as DateTime).toLocaleString(format);
}

// Given a Luxon, Moment, or JSDate return a Luxon keeping only the calendar date.
export function getDateOnly(time: DateTime | Moment | Date | null): DateTime {
  if (!time) {
    return DateTime.invalid('undefined');
  }
  if (time instanceof moment) {
    const converted = time as Moment;
    return DateTime.local(converted.year(), converted.month() + 1, converted.date(), 0, 0, 0, 0);
  }
  if (time instanceof Date) {
    const converted = time as Date;
    return DateTime.local(converted.getFullYear(), converted.getMonth() + 1, converted.getDate(), 0, 0, 0, 0);
  }
  const converted = (time as DateTime).toUTC();
  return DateTime.local(converted.year, converted.month, converted.day, 0, 0, 0, 0);
}

/**
 * Convert a Moment to a JSDate with the underlying time adjusted so that the local time stays the same numeric
 * value, but indicated relative to the Device's timezone.
 */
export function momentToJSDateDeviceRelative(time: Moment | null | undefined): Date | null {
  if (!time) {
    return null;
  }

  // Convert our Luxon to JavaScript Date
  return luxonToJSDateDeviceRelative(DateTime.fromMillis(time.valueOf()));
}

// Convert a UTC JSDate to Device Local JSDate keeping only the calendar date
export function convertUTCToLocalDate(time: Date): Date {
  if (!time) {
    return time;
  }
  time = new Date(time);
  time = new Date(time.getUTCFullYear(), time.getUTCMonth(), time.getUTCDate());
  return time;
}

// Convert a Device Local JSDate to UTC Local JSDate keeping only the calendar date
export function convertLocalToUTCDate(time: Date): Date {
  if (!time) {
    return time;
  }
  time = new Date(time);
  time = new Date(Date.UTC(time.getFullYear(), time.getMonth(), time.getDate()));
  return time;
}
