import { lazy } from 'react';

const AddSquareSchedulesDialog = lazy(() => import('./AddSchedulesDialog'));
const MatchSquareSchedulesDialog = lazy(() => import('./MatchSchedulesDialog'));
const EditSquareSchedulesDialog = lazy(() => import('./EditSchedulesDialog'));

export const ADD_SQUARE_SCHEDULES_DIALOG = 'ADD_SQUARE_SCHEDULES_DIALOG';
export const MATCH_SQUARE_SCHEDULES_DIALOG = 'MATCH_SQUARE_SCHEDULES_DIALOG';
export const EDIT_SQUARE_SCHEDULES_DIALOG = 'EDIT_SQUARE_SCHEDULES_DIALOG';

const AddSquareTeamMemberDialog = lazy(() => import('./AddEmployeesDialog'));
const MatchSquareTeamMembersDialog = lazy(() => import('./MatchEmployeesDialog'));
const EditSquareTeamMembersDialog = lazy(() => import('./EditEmployeesDialog'));
const DisableSquareDialog = lazy(() => import('./DisableSquareDialog'));

export const ADD_SQUARE_TEAM_MEMBERS_DIALOG = 'ADD_SQUARE_TEAM_MEMBERS_DIALOG';
export const MATCH_SQUARE_TEAM_MEMBERS_DIALOG = 'MATCH_SQUARE_TEAM_MEMBERS_DIALOG';
export const EDIT_SQUARE_TEAM_MEMBERS_DIALOG = 'EDIT_SQUARE_TEAM_MEMBERS_DIALOG';
export const DISABLE_SQUARE_DIALOG = 'DISABLE_SQUARE_DIALOG';

const SyncSquareDialog = lazy(() => import('./SyncDialog'));

export const SYNC_SQUARE_DIALOG = 'SYNC_SQUARE_DIALOG';

const dialogs = {
  [ADD_SQUARE_SCHEDULES_DIALOG]: AddSquareSchedulesDialog,
  [MATCH_SQUARE_SCHEDULES_DIALOG]: MatchSquareSchedulesDialog,
  [EDIT_SQUARE_SCHEDULES_DIALOG]: EditSquareSchedulesDialog,
  [DISABLE_SQUARE_DIALOG]: DisableSquareDialog,

  [ADD_SQUARE_TEAM_MEMBERS_DIALOG]: AddSquareTeamMemberDialog,
  [MATCH_SQUARE_TEAM_MEMBERS_DIALOG]: MatchSquareTeamMembersDialog,
  [EDIT_SQUARE_TEAM_MEMBERS_DIALOG]: EditSquareTeamMembersDialog,

  [SYNC_SQUARE_DIALOG]: SyncSquareDialog,
};

export default dialogs;
