import { updateAccountMetadata } from 'data/metadata/actions';
import {
  STEP_PRELOADED_REASSIGN_SHIFT,
  STEP_SCHEDULING_CREATE_SHIFT,
  STEP_SCHEDULING_PUBLISH_SCHEDULE,
} from 'onboarding/constants';
import {
  ONBOARDING_SCHEDULE_PUBLISHED,
  ONBOARDING_SHIFT_CREATED,
  ONBOARDING_SHIFT_REASSIGNED,
} from 'onboarding/metadataConstants';
import {
  getOnboardingSchedulePublishedAccordingToMetadata,
  getOnboardingShiftCreatedAccordingToMetadata,
  getOnboardingShiftReassigned,
} from 'onboarding/selectors';
import { track as trackOnboarding } from 'onboarding/utils/tracking';
import { Actions } from 'shared/experiment/constants';

export function updateSchedulerOnboardingForShift(shift, reassign = false) {
  return (dispatch, getState) => {
    const state = getState();

    if (!reassign) {
      const shiftAlreadyCreatedAccordingToMetadata = getOnboardingShiftCreatedAccordingToMetadata(state);
      const scheduleAlreadyPublishedAccordingToMetadata = getOnboardingSchedulePublishedAccordingToMetadata(state);

      if (
        (shiftAlreadyCreatedAccordingToMetadata && scheduleAlreadyPublishedAccordingToMetadata) ||
        (shiftAlreadyCreatedAccordingToMetadata && !shift.published && !scheduleAlreadyPublishedAccordingToMetadata)
      ) {
        // avoid excessive metadata updates
        return Promise.resolve();
      }

      const metadata = {
        [ONBOARDING_SHIFT_CREATED]: true,
      };

      trackOnboarding({
        action: Actions.COMPLETED,
        currentStep: STEP_SCHEDULING_CREATE_SHIFT,
        target: 'schedulerChecklist',
      });

      if (shift.published) {
        metadata[ONBOARDING_SCHEDULE_PUBLISHED] = true;

        trackOnboarding({
          action: Actions.COMPLETED,
          currentStep: STEP_SCHEDULING_PUBLISH_SCHEDULE,
          target: 'schedulerChecklist',
        });
      }

      return dispatch(updateAccountMetadata(metadata));
    }
    const shiftAlreadyReassigned = getOnboardingShiftReassigned(state);

    if (shiftAlreadyReassigned) {
      // avoid excessive metadata updates
      return Promise.resolve();
    }

    const metadata = {
      [ONBOARDING_SHIFT_REASSIGNED]: true,
    };

    trackOnboarding({
      action: Actions.COMPLETED,
      currentStep: STEP_PRELOADED_REASSIGN_SHIFT,
      target: 'schedulerChecklist',
    });

    return dispatch(updateAccountMetadata(metadata));
  };
}
