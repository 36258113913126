import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { JSONValue, Participant, ParticipantType } from '@twilio/conversations';

import BaseModel from 'data/BaseModel';
import EntityState from 'data/EntityState';
import generateThunkReducers from 'data/util/generateThunkReducers';
import { mergeEntityItems } from 'data/util/sliceHelpers';
import { loadWorkchatParticipantsForConversation } from 'workchat/v2/store/participants/participantsActions';
import { participantsSdkMap } from 'workchat/v2/twilio-objects';

export const workchatParticipantsSliceName = 'workchatv2/participants';

export type ReduxParticipant = {
  sid: string;
  cid: string;
  userId: number | null;
  attributes: JSONValue | null;
  identity: string | null;
  type: ParticipantType;
  lastReadMessageIndex: number | null;
  lastReadTimestamp: string | null;
};

class ReduxParticipantModel extends BaseModel<ReduxParticipant>({
  sid: '',
  cid: '', // conversation.sid
  userId: null,
  attributes: null,
  identity: null,
  type: 'chat',
  lastReadMessageIndex: null,
  lastReadTimestamp: null,
}) {}

export const reduxifyParticipant = (participant: Participant): ReduxParticipant => {
  const { sid, identity, type, lastReadMessageIndex, lastReadTimestamp, conversation } = participant;

  return {
    sid,
    cid: conversation.sid,
    attributes: participant.attributes as JSONValue,
    type,
    identity,
    lastReadMessageIndex,
    lastReadTimestamp: lastReadTimestamp ? lastReadTimestamp.toString() : null,
    userId: identity ? +identity : null,
  };
};

export const workchatParticipantsSlice = createSlice({
  name: workchatParticipantsSliceName,
  initialState: new EntityState<ReduxParticipant>(),
  reducers: {
    upsertParticipant: (state, action: PayloadAction<Participant>) => {
      const participant = action.payload;
      participantsSdkMap.set(participant.sid, participant);
      return mergeEntityItems(state, reduxifyParticipant(participant), ReduxParticipantModel, 'sid');
    },
    deleteParticipant: (state, action: PayloadAction<Participant>) => {
      participantsSdkMap.delete(action.payload.sid);
      return state.set(
        'items',
        state.get('items').filter(participant => participant.sid !== action.payload.sid),
      );
    },
  },
  extraReducers: builder => {
    builder.addCase(
      loadWorkchatParticipantsForConversation.fulfilled,
      (state, action: PayloadAction<Participant[]>) => {
        const reduxParticipants = action.payload.map(participant => {
          participantsSdkMap.set(participant.sid, participant);
          return reduxifyParticipant(participant);
        });

        return mergeEntityItems(state, reduxParticipants, ReduxParticipantModel, 'sid');
      },
    );

    generateThunkReducers(builder, workchatParticipantsSliceName);
  },
});

export const { upsertParticipant, deleteParticipant } = workchatParticipantsSlice.actions;

export default workchatParticipantsSlice.reducer;
