import Mercury from 'shared/util/mercury';

interface MessageTextProps {
  text: string;
}

export const MessageText = ({ text }: MessageTextProps) => {
  const trackLinkClicks = (text: string) => () => {
    const domain = window.location.hostname.split(/\.(.+)/)[1];
    const isDocsLink = text.includes(`${domain}/open`);
    let docId = null;

    if (isDocsLink) {
      docId = text.split('open/').pop();
    }

    Mercury.track('docs::onInteraction', {
      action: 'documentLinkClicked',
      documentId: docId,
      hash: location.hash,
      hostName: location.hostname,
      path: location.pathname,
      queryParams: location.search,
      annotations: JSON.stringify({
        linkOrigin: 'workchat',
        docsLink: isDocsLink,
      }),
    });
  };

  const urls: string[] = [];
  const placeholder = '<<<url>>>';

  const textContent: string | JSX.Element[] = text.replace(/(https?:\/\/\S+\.\S+)/gi, match => {
    urls.push(match);
    return placeholder;
  });

  let resultTextContent: string = textContent;

  if (urls.length > 0) {
    const parts = textContent.split(placeholder);
    const transformedContent: any[] = [];
    urls.forEach(url => {
      transformedContent.push(
        parts.shift(),
        <a target="_blank" href={url} onClick={trackLinkClicks(url)} key={url} rel="noreferrer">
          {url}
        </a>,
      );
    });

    resultTextContent = transformedContent.concat(...parts) as any;
  }
  const emojiRegex = /\p{Emoji}/u;
  const textRegex = /\b/;

  return (
    <p
      className={`message-text ${emojiRegex.test(resultTextContent) && !textRegex.test(resultTextContent) ? 'fs-larger' : ''}`}
    >
      {resultTextContent}
    </p>
  );
};
