import { type Ref, forwardRef } from 'react';
import { type FieldValues, type UseControllerProps, useController } from 'react-hook-form';

import getFieldError from 'shared/form/helpers/getFieldError';
import useMapCustomValidators from 'shared/form/hooks/useMapCustomValidators';
import type { InputProps } from 'shared/form/types';

import { RadioGroup } from './RadioGroup/RadioGroup';

import { Radio as MantineRadio, type RadioProps as MantineRadioProps } from '@mantine/core';
import { useMergedRef } from '@mantine/hooks';

export type RadioProps<T extends FieldValues> = InputProps<UseControllerProps<T>> &
  Omit<MantineRadioProps, 'value' | 'defaultValue'>;

const Radio = forwardRef(function Radio<T extends FieldValues>(
  { name, control, defaultValue, shouldUnregister, onChange, ...props }: RadioProps<T>,
  ref: Ref<HTMLInputElement>,
) {
  const rules = useMapCustomValidators(props, true);
  const {
    field: { value, onChange: fieldOnChange, ref: insideRef, ...field },
    fieldState,
  } = useController<T>({
    name,
    control,
    defaultValue,
    rules,
    shouldUnregister,
  });

  const mergedRefs = useMergedRef(insideRef, ref);

  return (
    <MantineRadio
      ref={mergedRefs}
      value={value}
      onChange={e => {
        fieldOnChange(e);
        onChange?.(name, e.currentTarget.checked);
      }}
      mb="md"
      error={getFieldError(fieldState)}
      data-testid={name}
      {...field}
      {...props}
    />
  );
});

const RadioNamespace = Object.assign(Radio, {
  Group: RadioGroup,
  Item: MantineRadio,
});

export { RadioNamespace as Radio };
