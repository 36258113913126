import BaseModel from 'data/BaseModel';
import { shortTimeRange } from 'shared/util/time';

// TODO(types): These are just copied from the `time` endpoint, we should verify
export interface TimeHistoryFields {
  id: number | null;
  account_id: number | null;
  break_hours: any | null; // TODO(types)
  created_at: string | null;
  end_time: string | null;
  hourly_rate: number | null;
  is_alerted: boolean | null;
  is_approved: boolean | null;
  length: number | null;
  location_id: number;
  modified_by: number;
  notes: string | null;
  position_id: number;
  shift_id: number | null;
  site_id: number;
  start_time: string | null;
  time_id: number | null; // from the time model
  updated_at: string | null;
  user_id: number | null;
}

class TimeHistory extends BaseModel<TimeHistoryFields>({
  id: null,
  account_id: null,
  break_hours: null,
  created_at: null,
  end_time: null,
  hourly_rate: null,
  is_alerted: null,
  is_approved: null,
  length: null,
  location_id: 0,
  modified_by: 0,
  notes: null,
  position_id: 0,
  shift_id: null,
  site_id: 0,
  start_time: null,
  time_id: null,
  updated_at: null,
  user_id: null,
}) {
  shortTimeRange() {
    return shortTimeRange(this.mustDate('start_time'), this.mustDate('end_time'));
  }
}

export default TimeHistory;
