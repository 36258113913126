import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import { getAuthAccount } from 'shared/auth/selectors';

export default function hasScheduling(ComposedComponent) {
  class hasScheduling extends Component {
    static propTypes = {
      account: PropTypes.shape({
        hasScheduling: PropTypes.func.isRequired,
      }),
    };

    render() {
      if (!this.props.account.hasScheduling()) {
        window.location.assign(CONFIG.APP_LEGACY_ROOT);
        return null;
      }

      return <ComposedComponent {...this.state} {...this.props} />;
    }
  }

  return connect(
    state => ({
      account: getAuthAccount(state),
    }),
    null,
  )(hasScheduling);
}
