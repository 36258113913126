import type { ReportMetadataRowsPerDay } from 'data/reporting/models/ReportMetadataRowsPerDay';
import { luxonToDeviceRelative, luxonToMomentAccountRelative } from 'shared/util/luxonHelpers';

import type { DatePickerValue } from '@mantine/dates';
import type { Map } from 'immutable';
import { DateTime, Interval } from 'luxon';
import type { DurationUnit } from 'luxon/src/duration';
import moment from 'moment-timezone';

export const DATE_RANGE_NAME = 'date_range_name';
export const DATE_RANGE_PICKER = 'date_range_picker';

// rangeValueNames
export const ONE_WEEK = 'week';
export const TWO_WEEKS = 'two_weeks';
export const ONE_MONTH = 'month';
export const TWO_MONTHS = 'two_months';
export const THREE_MONTHS = 'three_months';
export const CUSTOM = 'custom';

// getIntervals Units
export const WEEKS = 'weeks';
export const MONTHS = 'months';

const defaultLimit = 100000;
const searchParams = new URLSearchParams(document.location.search);
const searchParamLimit = Number.parseInt(searchParams.get('limit') || defaultLimit.toString(), 10);
export const REPORT_MAX_ROW_LIMIT = Number.isNaN(searchParamLimit) ? defaultLimit : searchParamLimit;

export enum ReportDialogStep {
  ChangeReportType = 0,
  ReportType = 1,
  DateRange = 2,
  SelectAccount = 3,
  Completed = 4,
  Restart = 5,
}

export type SelectOption = {
  label: string;
  value: string;
};

export type DateRangePickerValue = DatePickerValue;

export const getIntervals = (from: DateTime, to: DateTime, unit: DurationUnit) => {
  if (!from.isValid || !to.isValid) {
    return null;
  }

  const range = Interval.fromDateTimes(from, to);
  if (!range.isValid) {
    return null;
  }

  const length = Math.trunc(range.length(unit));
  const past = Math.abs(Math.trunc(from.diffNow().as(unit)));
  const future = Math.trunc(to.diffNow().as(unit));

  return { length, past, future };
};

export const getSelectorOptions = (reportingPeriodStart: DateTime | null, reportingPeriodEnd: DateTime | null) => {
  const baseOpts: SelectOption[] = [
    { value: ONE_WEEK, label: 'Last Week' },
    { value: TWO_WEEKS, label: 'Last Two Weeks' },
    { value: ONE_MONTH, label: 'Last Month' },
    { value: TWO_MONTHS, label: 'Last Two Months' },
    { value: THREE_MONTHS, label: 'Last Three Months' },
    { value: CUSTOM, label: 'Custom' },
  ];

  if (!reportingPeriodStart || !reportingPeriodEnd) {
    return baseOpts;
  }

  const vals = getIntervals(reportingPeriodStart, reportingPeriodEnd, WEEKS);

  if (!vals) {
    return baseOpts;
  }

  let { past: weeks } = vals;
  if (weeks === 0) {
    weeks = 1;
  }

  const intervals = [1, 2, 4, 8, 12]; // Intervals in weeks

  // Round to nearest containing interval (eg: 3 weeks = Last Month, 6 Weeks = Last Two Months)
  weeks = weeks > 4 ? Math.ceil(weeks / 4) * 4 : weeks > 1 ? Math.ceil(weeks / 2) * 2 : weeks;

  const selectedOpts = intervals.reduce((acc: SelectOption[], interval, idx) => {
    if (interval <= weeks) {
      acc.push(baseOpts[idx]);
      return acc;
    }
    return acc;
  }, []);

  selectedOpts.push(baseOpts[baseOpts.length - 1]);

  return selectedOpts;
};

export const renderLimitNotice = (from: DateTime | null, to: DateTime | null) => {
  if (!from?.isValid || !to?.isValid) {
    return '';
  }

  return (
    <div className="col range-disclaimer" aria-label="disclaimer">
      Report data is available from {from.toLocaleString(DateTime.DATE_MED)} - {to.toLocaleString(DateTime.DATE_MED)}
    </div>
  );
};

export const getDisabledDays = (start: DateTime | null, end: DateTime | null) => (date: Date) => {
  // The reason we're using a JSDate here is that this eventually winds up in the underlying
  // React DatePicker component which expects a Javascript Date object passed in as props
  // in app/shared/ui/DatePicker.js
  // Underlying component can be found @ node_modules/react-day-picker/types/DayPicker.d.ts
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date
  const momentDate = moment(date);
  const momentStart = luxonToMomentAccountRelative(start);
  const momentEnd = luxonToMomentAccountRelative(end);
  return momentDate.isBetween(momentStart, momentEnd, 'day', '[]');
};

export const getIntervalFromDateRangeName = (
  dateRangeName: string | undefined,
  reportingPeriodStart: DateTime | null,
) => {
  let startDate = luxonToDeviceRelative(DateTime.now().startOf('day'));
  const endDate = luxonToDeviceRelative(DateTime.now().endOf('day'));

  switch (dateRangeName) {
    case ONE_WEEK:
      startDate = startDate.minus({ weeks: 1 });
      break;
    case TWO_WEEKS:
      startDate = startDate.minus({ weeks: 2 });
      break;
    case ONE_MONTH:
      startDate = startDate.minus({ months: 1 });
      break;
    case TWO_MONTHS:
      startDate = startDate.minus({ months: 2 });
      break;
    case THREE_MONTHS:
      startDate = startDate.minus({ months: 3 });
      break;
    case CUSTOM:
      return;
    default:
      throw new TypeError(`Unexpected rangeValueName: ${dateRangeName}`);
  }

  if (reportingPeriodStart && startDate < reportingPeriodStart) {
    startDate = reportingPeriodStart;
  }
  return Interval.fromDateTimes(startDate, endDate);
};

export const getRowCountForRange = (rowsPerDay: Map<string, ReportMetadataRowsPerDay>, dateRange: Interval): number => {
  let rowCount = 0;

  for (const [, value] of rowsPerDay) {
    if (dateRange.contains(value.time)) {
      rowCount += value.count;
    }
  }

  return rowCount;
};

/**
 * The fields as defined by https://catalog.wiwdata.com/#/entry/entries-769d342f-e34e-4301-b7f3-0feb602bd720
 * Provides default values or null so that all required fields will be present in validation
 *
 * Callable function so that fields like `window.location.pathname` are retrieved at execution time.
 */
export function getReportingInteractionDefaults() {
  return {
    action: null,
    dataset: null,
    dataTooLarge: false,
    endDate: null,
    filterColumns: null,
    path: window.location.pathname,
    pivotColumnLabels: null,
    pivotGroupedColumns: null,
    pivotMode: null,
    pivotValueColumns: null,
    reportSize: null,
    rowGroups: null,
    sortColumn: null,
    startDate: null,
  };
}
