import type { ShiftBreak, ShiftBreakFields } from 'data/shiftBreak/models/shiftBreak';
import type { DataResponse, QueryString, TerminalAuth } from 'data/types';
import V3 from 'shared/api/v3';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type UpdateShiftBreakArgs = {
  shiftBreak: ShiftBreakFields;
  params?: QueryString;
} & TerminalAuth;

export const updateShiftBreak = createWiwAsyncThunk<
  DataResponse<ShiftBreak>,
  UpdateShiftBreakArgs,
  { fulfilledMeta: { userId: string | number | null } }
>(
  'shiftBreak/update',
  async ({ shiftBreak, userId, token, params = {} }, { fulfillWithValue, rejectWithValue, getState }) => {
    const reqUserId = userId ?? getState().auth.userId;
    params = { userId: reqUserId, ...params };
    let headers: HeadersInit = {};
    if (!!userId && !!token) {
      headers = {
        'W-Token': token,
        'W-UserId': userId.toString(),
        Authorization: `Bearer ${token}`,
      };
    }

    try {
      const result = (await V3.url(`/shift-breaks/${shiftBreak.id}`)
        .query(params)
        .headers(headers)
        .patch(shiftBreak)) as DataResponse<ShiftBreak>;
      return fulfillWithValue(result, {
        userId: reqUserId,
      });
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
