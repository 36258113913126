import BaseModel from 'data/BaseModel';
import type Account from 'data/account/model';
import { AccountType } from 'data/account/model';
import { StripePlanID } from 'data/stripe/plans/model';
import Features from 'shared/features';

import { findLast } from 'lodash';

interface PlanDetailConfig {
  base: number;
  sms: number;
  subtotal: number;
  tax: number;
  tax_rate: number;
  total: number;
  discount: number;
  cycle: number;
  charge: {
    base: number;
    sms: number;
    subtotal: number;
    tax: number;
    total: number;
    renewing: boolean;
  };
}

export interface PlanDetailByUnitConfig {
  units: number;
  prorated: {
    cycle: number;
    amount: number;
    tax: number;
  };
  cycle: number;
  amount: number;
  tax: number;
}

export interface PlanDetails {
  current: 'annual' | 'monthly';
  monthly: PlanDetailConfig;
  annual: PlanDetailConfig;
  by_unit: {
    monthly: PlanDetailByUnitConfig;
    annual: PlanDetailByUnitConfig;
  };
}

export interface PlanFields {
  id: number;
  type: number;
  name: string | null;
  employees: number;
  price: number;
  price_annual: number;
  unit_min: number;
  unit_max: number;
  unit_by: number;
  combo_discount: number;
  features: string[];
  description: string | null;
  is_hidden: boolean;
  is_legacy: boolean;
  is_public: boolean; // not in the api
  is_freemium: boolean;
  sort: number;
  tier: any | null; // TODO(types)
  legacy_at: string | null;
  details: PlanDetails;
}

export enum PlanType {
  Bucket = 1,
  User = 2,
  AddonFlat = 4,
  AddonUser = 5,
}

export enum PlanTier {
  Freemium = 0,
  Basic = 1,
  Pro = 2,
  Enterprise = 4,
  Custom = 1000,
  Legacy = 10000,
}

export enum PlanProductLine {
  Scheduling = 'scheduling',
  Attendance = 'attendance',
}

export const ESSENTIALS_PLAN_ID = 2000;
export const PAYROLL_PLAN_ID = 3170;

class Plan extends BaseModel<PlanFields>({
  id: 0,
  type: 0,
  name: null,
  employees: 0,
  price: 0,
  price_annual: 0,
  unit_min: 5,
  unit_max: 100,
  unit_by: 5,
  combo_discount: 0,
  features: [],
  description: null,
  is_hidden: false,
  is_legacy: false,
  is_public: true,
  is_freemium: false,
  sort: 0,
  tier: null,
  legacy_at: null,
  details: {} as PlanDetails,
}) {
  static TRIAL = -1;
  static TRIAL_TIERED = -100;
  static WORKCHATFREE = -2;

  // Default plan ids for scheduler freemium
  // Try not to use them, use the `isFreemium()` helper
  static SCHEDULER_FREEMIUM = -4;
  static SCHEDULER_FREEMIUM_TIERED = -401;

  static Type = {
    BUCKET: PlanType.Bucket,
    USER: PlanType.User,
    ADDON_FLAT: PlanType.AddonFlat,
    ADDON_USER: PlanType.AddonUser,
  } as const;

  static Tier = {
    FREEMIUM: PlanTier.Freemium,
    BASIC: PlanTier.Basic,
    PRO: PlanTier.Pro,
    ENTERPRISE: PlanTier.Enterprise,
    CUSTOM: PlanTier.Custom,
    LEGACY: PlanTier.Legacy,
  } as const;

  static ProductLine = {
    SCHEDULING: PlanProductLine.Scheduling,
    ATTENDANCE: PlanProductLine.Attendance,
  } as const;

  isTrial() {
    return (
      !this.isFreemium() &&
      // We will always have an id, even if it's the default
      (this.id === Plan.TRIAL || this.id! <= -10)
    );
  }

  isPaid() {
    return !this.isFree() && !this.isTrial();
  }

  isFree() {
    return !this.price || this.isFreemium();
  }

  isHidden() {
    return !!this.is_hidden;
  }

  isLegacy() {
    return !!this.is_legacy;
  }

  isPublic() {
    return !!this.is_public;
  }

  isFreemium() {
    return !!this.is_freemium;
  }

  isAddon() {
    return this.type === PlanType.AddonFlat || this.type === PlanType.AddonUser;
  }

  sortTier() {
    if (this.isLegacy()) {
      return PlanTier.Legacy;
    }

    return this.sort;
  }

  previousTier() {
    return this.sortTier() <= PlanTier.Enterprise ? findLast(Plan.Tier, tier => tier < this.sortTier()) : null;
  }

  isTier(tier: PlanTier) {
    return this.sortTier() === tier;
  }

  // Freemium means the plan row's "sort" column is 0
  isFreemiumTier() {
    return this.isTier(PlanTier.Freemium);
  }

  // Pro means the plan row's "sort" column is 2
  isProTier() {
    return this.isTier(PlanTier.Pro);
  }

  // Enterprise means the plan row's "sort" column is 4
  isEnterpriseTier() {
    return this.isTier(PlanTier.Enterprise);
  }

  is2022() {
    return [
      // 2022 plans and their trials
      2000, -2001, 2010, -2011, 2020, -2021, 2030, -2031, 2005, 2015, 2025, 2035,
    ].includes(this.id);
  }

  is2023() {
    return [
      // 2023 plans and their trials
      3000,
      3005,
      3010,
      3015,
      -3000,
      3020,
      3025,
      3030,
      3035,
      -3001,
      3040,
      3045,
      3050,
      3055,
      -3002,
      3060,
      3061,
      3062,
      3063,
      3064,
      3066,
      StripePlanID.REVERSE_TRIAL,
    ].includes(this.id);
  }

  is2024() {
    return [
      // 2024 plans and their trials
      3100,
      3105,
      3110,
      3115,
      -3100,
      3120,
      3125,
      3130,
      3135,
      -3101,
      3140,
      3145,
      3150,
      3155,
      -3102,
      StripePlanID.REVERSE_TRIAL,
    ].includes(this.id);
  }

  is2024V2() {
    return [
      // 2024V2 plans and their trials
      3200,
      3205,
      3210,
      3215,
      -3200,
      3220,
      3225,
      3230,
      3235,
      -3201,
      3240,
      3245,
      3250,
      3255,
      -3202,
      StripePlanID.REVERSE_TRIAL,
    ].includes(this.id);
  }

  is2025() {
    return [
      // 2025 plans and their trials
      4000, 4005, 4010, 4015, -4001, 4020, 4025,
    ].includes(this.id);
  }

  is2025V2() {
    return [
      // 2025V2 plans and their trials
      4100, 4105, 4110, 4115, -4101, 4120, 4125,
    ].includes(this.id);
  }

  isReverseTrial() {
    return this.id === StripePlanID.REVERSE_TRIAL;
  }

  isStripeBillingMigrationWave1() {
    return [2000, 2030, 3000, 3010, 3020, 3030, 3040, 3050].includes(this.id);
  }

  isStripeBillingMigrationWave2(accountType: AccountType): boolean {
    if (accountType === AccountType.Scheduling) {
      return [
        4, 10, 11, 12, 13, 15, 111, 112, 113, 114, 116, 117, 118, 119, 120, 121, 122, 400, 1157, 1160, 1163, 1166, 1169,
        1172, 1218, 1221,
      ].includes(this.id);
    }

    if (accountType === AccountType.Attendance) {
      return [502, 1157, 1163].includes(this.id);
    }

    if (accountType === AccountType.Both) {
      return [
        111, 112, 117, 118, 119, 120, 121, 405, 427, 428, 430, 541, 543, 1157, 1160, 1163, 1166, 1169, 1172, 1218, 1221,
      ].includes(this.id);
    }

    return false;
  }

  isStripeBillingMigrationWave3(): boolean {
    return [404, 441].includes(this.id);
  }

  isACurrentPlan() {
    return this.is2023() || this.is2024() || this.is2024V2() || this.is2025() || this.is2025V2();
  }

  // 2022
  isAdvanced() {
    return [2015, 2010, -2011].includes(this.id);
  }

  isStandard() {
    return [2035, 2030, -2031].includes(this.id);
  }

  // 2023
  isEssentials2023() {
    return [3000, 3005, 3010, 3015, 3060, 3061, -3000].includes(this.id);
  }

  isPro2023() {
    return [3020, 3025, 3030, 3035, 3062, 3063, -3001].includes(this.id);
  }

  // 2024
  isEssentials2024() {
    return [3100, 3105, 3110, 3115, -3100].includes(this.id);
  }

  isPro2024() {
    return [3120, 3125, 3130, 3135, -3101].includes(this.id);
  }

  // 2024-v2
  isEssentials2024V2() {
    return [3200, 3205, 3210, 3215, -3200].includes(this.id);
  }

  isPro2024V2() {
    return [3220, 3225, 3230, 3235, -3201].includes(this.id);
  }

  // 2025
  isSingleLocation2025() {
    return [4000, 4005].includes(this.id);
  }

  isMultiLocation2025() {
    return [4010, 4015, -4001].includes(this.id);
  }

  isAdvanced2025() {
    return [4020, 4025].includes(this.id);
  }

  // 2025-v2
  isSingleLocation2025V2() {
    return [4100, 4105].includes(this.id);
  }

  isMultiLocation2025V2() {
    return [4110, 4115, -4101].includes(this.id);
  }

  isAdvanced2025V2() {
    return [4120, 4125].includes(this.id);
  }

  hasLegacyAttendance(account: Account) {
    return this.isLegacy() && (this.hasFeature(Features.ATTENDANCE) || account?.hasAttendance(true));
  }

  getRoundedUnits(units: number) {
    const ceilUnits = Math.ceil(units);
    const roundBy = this.unit_by;
    if (ceilUnits % roundBy === 0) {
      return ceilUnits;
    }

    return Math.round((units + roundBy / 2) / roundBy) * roundBy;
  }

  isCurrent(account: Account) {
    return account && account.plan_id === this.id;
  }

  hasFeature(feature: string) {
    return this.features.includes(feature);
  }

  accountType(account: Account | null = null) {
    let type = account && this.isTrial() ? account.real_type : 0;
    if (account && this.isLegacy() && this.hasFeature(Features.LEGACY_BUCKET_MIGRATION)) {
      // Keep whatever account type they had before.
      // To change account type, they need to select a non-legacy tiered pricing plan
      type = account.real_type;
    }

    //this needs to go at the end, because a plan can have the features above, and we
    //don't want to add them in the bitwise operator
    if (account && !this.isLegacy() && !account.isPerUserBilling()) {
      return account.real_type;
    }

    if (this.hasFeature(Features.SCHEDULING)) {
      type |= 1;
    }

    if (this.hasFeature(Features.ATTENDANCE)) {
      type |= 2;
    }

    return !!type || !account ? type : account.real_type;
  }
}

export default Plan;
