import { ShiftBreakAuditSchema } from 'data/shiftBreak/models/shiftBreakAudit';
import V3 from 'shared/api/v3';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { makeGoDataSchema } from 'shared/zod-schemas';
import { createWiwAsyncThunk } from 'store';

import { ZodError, z } from 'zod';

type FetchShiftBreakAuditArgs = {
  startTime: string;
  endTime: string;
  userId: number;
};

export const fetchShiftBreakAudits = createWiwAsyncThunk<
  z.infer<typeof FetchShiftBreakAuditResponse>,
  FetchShiftBreakAuditArgs
>('shiftBreakAudit/fetch', async (params, { rejectWithValue }) => {
  try {
    const response = await V3.query(params).get('/shift-break-audits');
    return FetchShiftBreakAuditResponse.parse(response);
  } catch (error) {
    if (error instanceof ZodError) {
      return rejectWithValue(error);
    }

    return rejectWithValue(parseAPIError(error));
  }
});

const FetchShiftBreakAuditResponse = makeGoDataSchema(z.array(ShiftBreakAuditSchema));
