import PhoneUtil from 'shared/util/phoneutil';

export default function isPhone(values, value, country) {
  if (value?.length) {
    if (typeof country !== 'string' || country === 'undefined') {
      country = undefined;
    }
    return PhoneUtil.isValidNumber(value, country);
  }
  return true;
}
