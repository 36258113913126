import BaseModel from 'data/BaseModel';
import type { ConcernsFields } from './Concerns';

export interface ShiftConcernsListFields {
  shiftId: string | null;
  rules: ConcernsFields[];
}

class ShiftConcernsList extends BaseModel<ShiftConcernsListFields>({
  shiftId: null,
  rules: [],
}) {}

export default ShiftConcernsList;
