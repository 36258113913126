import Splash from 'workchat/containers/Splash';

import { getAuthAccount, getAuthUser } from 'shared/auth/selectors';
import { posthogCapture } from 'shared/vendor/posthog';
import { useWiwDispatch, useWiwSelector } from 'store';
import Loading from 'workchat/components/Loading';
import ConversationListRow from 'workchat/v2/components/ConversationListV2/ConversationListRow';
import HeaderV2, { type HeaderButton } from 'workchat/v2/components/HeaderV2';
import { VIEW_NEW_CONVERSATION } from 'workchat/v2/components/NewConversationV2';
import { VIEW_CONVERSATION } from 'workchat/v2/components/ViewConversationV2';
import { useTwilioProvider } from 'workchat/v2/providers/TwilioProvider';
import type { ReduxConversation } from 'workchat/v2/store/conversations/conversationsReducer';
import { getConversationsAsList, getConversationsLoaded } from 'workchat/v2/store/conversations/conversationsSelectors';
import { setConversation, viewStackPush } from 'workchat/v2/store/reducer';
import { getWorkchatConnected, getWorkchatInitError } from 'workchat/v2/store/selectors';

export const VIEW_CONVERSATION_LIST = 'VIEW_CONVERSATION_LIST';

export default function ConversationListV2() {
  const dispatch = useWiwDispatch();
  const user = useWiwSelector(getAuthUser);

  const account = useWiwSelector(getAuthAccount);
  const connected = useWiwSelector(getWorkchatConnected);
  const conversations = useWiwSelector(getConversationsAsList);
  const conversationsLoaded = useWiwSelector(getConversationsLoaded);
  const initError = useWiwSelector(getWorkchatInitError);

  const { init } = useTwilioProvider();

  const canCreateNewConversation = !!account.settings?.workchat?.customChannelsAllowed;

  const newConversation = () => {
    if (!connected) {
      return;
    }
    dispatch(viewStackPush(VIEW_NEW_CONVERSATION));
  };

  const chooseConversation = (conversation: ReduxConversation) => {
    if (!connected) {
      return;
    }

    dispatch(setConversation(conversation.sid));
    dispatch(viewStackPush(VIEW_CONVERSATION));
    posthogCapture('WorkChat: Viewed Channel', { accessType: 'Channel List' });
  };

  const renderConversationListContent = () => {
    if (!conversationsLoaded || !conversations || initError) {
      return (
        <Loading
          hideHeader
          hasError={!!initError}
          errorLabel="Something went wrong loading WorkChat"
          onErrorRetry={() => init()}
        />
      );
    }

    return conversations.toArray().map(conversation => {
      return (
        <ConversationListRow
          key={conversation.sid}
          conversation={conversation}
          connected={connected}
          onConversationClick={() => chooseConversation(conversation)}
        />
      );
    });
  };

  const buttons: HeaderButton[] = canCreateNewConversation
    ? [
        {
          label: 'New Channel',
          className: 'green',
          action: newConversation,
          side: 'right',
          disabled: !connected || !conversationsLoaded || !!initError,
        },
      ]
    : [];

  return (
    <div>
      <HeaderV2 showBroadcast={true} buttons={buttons} />
      <div className="conversation-list">
        <Splash user={user} account={account} />
        {renderConversationListContent()}
      </div>
    </div>
  );
}
