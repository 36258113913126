import EntityState from 'data/EntityState';
import { createLocation } from 'data/location/actions/createLocation';
import { deleteLocation, deleteLocations } from 'data/location/actions/deleteLocation';
import { fetchLocations } from 'data/location/actions/fetchLocations';
import { sortLocations } from 'data/location/actions/sortLocations';
import { updateLocation } from 'data/location/actions/updateLocation';
import Location from 'data/location/model';
import generateThunkReducers from 'data/util/generateThunkReducers';
import { optimisticallyUpdateEntity } from 'data/util/optimisticUpdate';
import { mergeEntityItems } from 'data/util/sliceHelpers';

import { createSlice } from '@reduxjs/toolkit';

const initialState = new EntityState();

export const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    clearLocations: () => {
      return new EntityState();
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchLocations.fulfilled, (state, action) => {
        return mergeEntityItems(state, action.payload.locations, Location);
      })
      .addCase(sortLocations.fulfilled, (state, action) => {
        return mergeEntityItems(state, action.payload.locations, Location);
      })
      .addCase(deleteLocation.fulfilled, (state, action) => {
        return state.merge({
          items: state.items.delete(action.meta.arg.id),
        });
      })
      .addCase(deleteLocations.fulfilled, (state, action) => {
        return state.merge({
          items: state.items.withMutations(maps => {
            action.meta.arg.map(location => {
              maps.delete(location.id);
            });
          }),
        });
      })
      .addCase(deleteLocations.rejected, (state, action: any) => {
        // if a user attempts to delete ALL locations the request will fail but we still need to delete the locations
        // that were successfully deleted... please god clean this up in the future
        return state.merge({
          items: state.items.withMutations(map => {
            action.payload.json.map((result: any) => {
              if (result.deleted_location) {
                map.delete(result.deleted_location);
              }
            });
          }),
        });
      });
    optimisticallyUpdateEntity(builder, createLocation, Location, { payloadPath: 'location' });
    optimisticallyUpdateEntity(builder, updateLocation, Location, { payloadPath: 'location' });
    generateThunkReducers(builder, 'location');
  },
});

export const { clearLocations } = locationSlice.actions;

export default locationSlice.reducer;
