import EntityBase from 'data/EntityBase';
import type { ReportMetadataColumnDetail } from 'data/reporting/models/ReportMetadataColumnDetail';
import type { ReportMetadataRowsPerDay } from 'data/reporting/models/ReportMetadataRowsPerDay';

import { Map, OrderedMap } from 'immutable';
import type { DateTime } from 'luxon';

const metadataStateDefaults = {
  countsLoading: false,
  countsLoaded: false,
  countsReceivedAt: 0,
  loading: false,
  loaded: false,
  receivedAt: 0,
  error: null,
  columns: OrderedMap<string, ReportMetadataColumnDetail>(),
  generatedAt: null,
  reportingPeriodEnd: null,
  reportingPeriodStart: null,
  rowsPerDay: Map<string, ReportMetadataRowsPerDay>(),
  version: 0,
};

interface CustomMetadataStateFields {
  countsLoading: boolean;
  countsLoaded: boolean;
  countsReceivedAt: number;
  columns: OrderedMap<string, ReportMetadataColumnDetail>;
  generatedAt: DateTime | null;
  reportingPeriodEnd: DateTime | null;
  reportingPeriodStart: DateTime | null;
  rowsPerDay: Map<string, ReportMetadataRowsPerDay>;
  version: number;
}

export class CustomMetadataState extends EntityBase<
  null /* we don't use `items` here */,
  CustomMetadataStateFields | typeof metadataStateDefaults
>(metadataStateDefaults) {
  // nothing
}

export type CustomMetadataStateByDatasetByAccountId = Map<string, Map<string, CustomMetadataState>>;
