import EntityState from 'data/EntityState';
import {
  FETCH_OVERTIME_LABOR,
  FETCH_OVERTIME_LABOR_REPLACE,
  FETCH_OVERTIME_LABOR_REPLACE_USER_IDS,
  RESET_SCHEDULER_STATE,
} from 'store/action-types';
import OvertimeLaborCost from './model';

import { Map } from 'immutable';

export default function (state = new EntityState(), action) {
  switch (action.type) {
    case FETCH_OVERTIME_LABOR.FAILURE:
      return state.merge({
        loaded: false,
        loading: false,
        error: action.payload,
      });
    case FETCH_OVERTIME_LABOR.REQUEST:
      return state.merge({
        loaded: false,
        loading: true,
        error: null,
      });
    case FETCH_OVERTIME_LABOR.SUCCESS:
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        receivedAt: Date.now(),
        items: state.items.merge(action.payload.map(ot => [ot.id + ot.start, new OvertimeLaborCost(ot)])),
      });
    case FETCH_OVERTIME_LABOR_REPLACE:
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        receivedAt: Date.now(),
        items: new Map(action.payload.map(ot => [ot.id + ot.start, new OvertimeLaborCost(ot)])),
      });
    case FETCH_OVERTIME_LABOR_REPLACE_USER_IDS:
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        receivedAt: Date.now(),
        items: state.items
          .filter(otItem => !action.userId.includes(otItem.userId))
          .merge(action.payload.map(ot => [ot.id + ot.start, new OvertimeLaborCost(ot)])),
      });
    case RESET_SCHEDULER_STATE:
      return new EntityState();
    default:
      return state;
  }
}
