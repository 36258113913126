import type { StripePlan } from 'data/stripe/types/plans';
import type { StripePrice } from 'data/stripe/types/prices';
import Billing from 'shared/api/billing';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type FetchPlansArgs = string | undefined;

type FetchPlansResponse = {
  data: {
    plans: StripePlan[];
    prices: Map<StripePrice['id'], StripePrice>;
  };
};

export const fetchStripePlans = createWiwAsyncThunk<FetchPlansResponse, FetchPlansArgs>(
  'stripe/plans/fetch',
  async (pricingGroup, { rejectWithValue }) => {
    let url = '/plans';
    if (pricingGroup) {
      url = `${url}/${pricingGroup}`;
    }
    try {
      return (await Billing.get(url)) as FetchPlansResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
