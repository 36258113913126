import { type PropsWithChildren, createContext, useCallback, useContext, useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export interface LayoutContextType {
  showFooter(): void;

  hideFooter(): void;

  footerVisible: boolean;

  showNav(): void;

  hideNav(): void;

  headerVisible: boolean;
}

const LayoutContext = createContext<LayoutContextType | null>(null);

type LayoutContextProviderProps = {};

export default function LayoutContextProvider(props: PropsWithChildren<LayoutContextProviderProps>) {
  const location = useLocation();

  const [headerVisible, setHeaderVisible] = useState(true);
  const [footerVisible, setFooterVisible] = useState(false);
  const showFooter = useCallback(() => setFooterVisible(true), []);
  const hideFooter = useCallback(() => setFooterVisible(false), []);
  const showNav = useCallback(() => setHeaderVisible(true), []);
  const hideNav = useCallback(() => setHeaderVisible(false), []);

  useLayoutEffect(() => {
    setFooterVisible(false);
    setHeaderVisible(true);
  }, [location.pathname]);

  const contextValues: LayoutContextType = {
    footerVisible,
    headerVisible,
    showFooter,
    hideFooter,
    showNav,
    hideNav,
  };

  return <LayoutContext.Provider value={contextValues}>{props.children}</LayoutContext.Provider>;
}

export function useLayout() {
  const context = useContext(LayoutContext);
  if (!context) {
    throw new Error('useLayout must be used within a LayoutContextProvider');
  }
  return context;
}
