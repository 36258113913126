import type PostTaxDeduction from 'data/payrollTaxDeductions/model';
import Payroll from 'shared/api/payroll';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';

import { createAsyncThunk } from '@reduxjs/toolkit';

type DeletePayrollDeductionResponse = {
  message: string;
};

export const deletePayrollDeductions = createAsyncThunk<DeletePayrollDeductionResponse, PostTaxDeduction>(
  'taxDeduction/delete',
  async (postTaxDeduction, { rejectWithValue }) => {
    try {
      return (await Payroll.url('/tax-deduction')
        .query({ tax_deduction_id: postTaxDeduction.id })
        .delete()) as DeletePayrollDeductionResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
