import { UPDATE_USER_FILTER } from 'store/action-types';

export function updateUserFilter(payload) {
  return dispatch => {
    return dispatch({
      type: UPDATE_USER_FILTER,
      payload,
    });
  };
}
