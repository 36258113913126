import BaseModel from 'data/BaseModel';

export interface TaskFields {
  name: string | null;
  order: number;
}

export class TaskModel extends BaseModel<TaskFields>({
  name: null,
  order: 0,
}) {}
