import go from './modules/go/store/reducer';
import gusto from './modules/gusto/reducer';
import paychex from './modules/paychex/reducer';
import quickbooks from './modules/qbo/reducer';
import square from './modules/square/reducer';
import wfn from './modules/wfn/reducer';

import { combineReducers } from '@reduxjs/toolkit';

export default combineReducers({
  paychex,
  go,
  quickbooks,
  gusto,
  wfn,
  square,
});
