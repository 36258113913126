import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { openDialog } from 'dialogs';
import { CLOCK_IN_DIALOG, PUNCH_BREAK_DIALOG } from 'punch/dialogs';

export class ClockInLauncher extends PureComponent {
  static propTypes = {
    openDialog: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const hash = window.location.hash.substring(1);
    if (hash === 'clockin' || hash === 'clockout') {
      this.props.openDialog(CLOCK_IN_DIALOG);
    } else if (hash === 'start-break' || hash === 'end-break') {
      this.props.openDialog(PUNCH_BREAK_DIALOG);
    }
  }

  render() {
    return null;
  }
}

export default connect(null, {
  openDialog,
})(ClockInLauncher);
