/* eslint-disable @typescript-eslint/naming-convention */
import { DO_NOT_RENEW, DO_RENEW } from 'billing/util/constants';
import Account, { type AccountBillingType } from 'data/account/model';
import Plan, { type PlanDetails } from 'data/plan/model';

import { differenceBy } from 'lodash';

export interface PaymentOverrides {
  newPlan?: Plan;
  newUnits?: number;
  newBillingType?: AccountBillingType;
  newSubscriptionPlans?: Plan[];
}

export default class Payment {
  private account: Account = new Account();
  private accountPlan: Plan = new Plan();
  private accountSubscriptionPlans: Plan[];

  /**
   * Creates a new Payment object for the current state of the account. You
   * must provide all data that _currently_ applies to the account. Other
   * methods may then provide options to override or modify this data for
   * preview purposes.
   *
   * @param account The account to evaluate payments for.
   * @param accountPlan The account's full plan info.
   * @param accountSubscriptionPlans All subscription plans currently on the account.
   */
  constructor(account: Account, accountPlan: Plan, accountSubscriptionPlans: Plan[]) {
    this.account = account;
    this.accountPlan = accountPlan;
    this.accountSubscriptionPlans = accountSubscriptionPlans;
  }

  private _handleOverrides(overrides: PaymentOverrides) {
    return {
      plan: overrides.newPlan ?? this.accountPlan,
      units: overrides.newUnits ?? this.account.plan_units,
      billingType: overrides.newBillingType ?? this.account.billing_type,
      subscriptionPlans: overrides.newSubscriptionPlans ?? this.accountSubscriptionPlans,
    };
  }

  needsToUpdateBillingInfo() {
    return (
      this.accountPlan?.isPaid() &&
      (!this.account.enabled || !!this.account.credit_attempts || !this.account.hasBillingInfo())
    );
  }

  doRenew(nextBillingType: AccountBillingType = this.account.billing_type) {
    return this.account.billingType() !== nextBillingType || this.account.isPlanRenewable(this.accountPlan)
      ? DO_RENEW
      : DO_NOT_RENEW;
  }

  totalPerUnit(overrides: PaymentOverrides = {}) {
    const overridden = this._handleOverrides(overrides);

    const numUnits = overridden.units || 1;
    const totalPerUnit = this.getSubTotal({ ...overrides, newUnits: numUnits }) / numUnits;

    if (overridden.billingType === Account.Billing.ANNUAL) {
      return totalPerUnit / 12;
    }

    return totalPerUnit;
  }

  recommendedPlansCostDiff(
    currentPlans: { [x: string]: Plan },
    recommendedPlans: { [x: string]: Plan } = {},
    overrides: PaymentOverrides = {},
  ) {
    // default to accounts existing plans
    const newPlans = {
      [Plan.ProductLine.SCHEDULING]: currentPlans[Plan.ProductLine.SCHEDULING],
      [Plan.ProductLine.ATTENDANCE]: currentPlans[Plan.ProductLine.ATTENDANCE],
      ...recommendedPlans,
    };

    // since scheduler and attendance plans are coupled, just look at scheduler plan price
    const perUserPerPeriod = newPlans[Plan.ProductLine.SCHEDULING]
      ? this.totalPerUnit({ ...overrides, newPlan: newPlans[Plan.ProductLine.SCHEDULING] }) -
        this.totalPerUnit({ ...overrides, newPlan: currentPlans[Plan.ProductLine.SCHEDULING] })
      : 0;

    return {
      perPeriod: 0,
      perUserPerPeriod: Math.max(perUserPerPeriod, 0),
      total: Math.max(perUserPerPeriod, 0),
    };
  }

  _requiresFullCharge(newPlan: Plan) {
    if (this.accountPlan.isFree()) {
      const billingTypeText = Account.billingTypeText(this.account.billing_type);
      const planTotal = newPlan.details[billingTypeText].total;
      const byUnit = newPlan.details.by_unit[billingTypeText];

      const proratedTotal = byUnit.prorated.amount + byUnit.prorated.tax;
      if (proratedTotal !== planTotal) {
        // The this.account has downgraded to freemium from paid in the billing cycle
        // Use prorated charge if it exists
        return false;
      }
      return true;
    }
    return this.account.isPlanExpired(this.accountPlan);
  }

  _getChargeData(
    field: 'amount' | 'tax',
    prorate: boolean,
    plan: Plan,
    units: number,
    billingType: AccountBillingType,
    subscriptionPlans: Plan[],
  ): number {
    const billingTypeText = Account.billingTypeText(this.account.billing_type);
    const nextBillingTypeText = Account.billingTypeText(billingType);

    const shouldProrateCost = prorate && !this._requiresFullCharge(plan);

    function extractUnitData(details: PlanDetails) {
      if (shouldProrateCost) {
        const data = details.by_unit[billingTypeText];
        const prorated = data.prorated[field];

        return prorated + data[field] * (units - data.units) * data.prorated.cycle;
      }
      const data = details.by_unit[nextBillingTypeText];
      return data[field] * units;
    }

    // Start with the base plan price
    let chargeTotal = extractUnitData(plan.details);

    // Add any additional subscription plan pricing
    for (const plan of subscriptionPlans) {
      if (plan === null) {
        continue;
      }

      // Handle legacy plan pairs gracefully
      const newPlan = Array.isArray(plan) ? plan[1] || plan[0] : plan;

      const details = newPlan.details;

      if (details.by_unit) {
        // If this additional plan is billed per unit, extract that
        chargeTotal += extractUnitData(details);
      } else {
        // Else extract the flat charge data
        if (shouldProrateCost) {
          const data = details[billingTypeText];
          chargeTotal += data.charge[field];
        } else {
          const data = details[nextBillingTypeText];
          chargeTotal += data[field];
        }
      }
    }

    return chargeTotal;
  }

  getChargeTotal(overrides: PaymentOverrides = {}) {
    const overridden = this._handleOverrides(overrides);

    const total = this.getTotal(overrides);
    const proratedTotal = this.getProratedTotal(overrides);
    const isPlanRenewable = this.account.isPlanRenewable(this.accountPlan);

    if (this._requiresFullCharge(overridden.plan)) {
      return total;
    }
    if (proratedTotal <= 0 && overridden.billingType === Account.Billing.MONTHLY) {
      return 0;
    }
    if (
      isPlanRenewable &&
      (proratedTotal < 0 ||
        (overridden.billingType === Account.Billing.ANNUAL && (isPlanRenewable || this.account.isBilledMonthly())))
    ) {
      return proratedTotal + total;
    }

    if (proratedTotal !== 0) {
      return proratedTotal;
    }
    return total;
  }

  _getProratedAmount(overrides: PaymentOverrides = {}) {
    const overridden = this._handleOverrides(overrides);
    const proratedAmount = this._getChargeData(
      'amount',
      true,
      overridden.plan,
      overridden.units,
      overridden.billingType,
      overridden.subscriptionPlans,
    );
    // Monthly this.accounts may not have a negative prorated amount due to previous plan info
    return proratedAmount < 0 && overridden.billingType === Account.Billing.MONTHLY ? 0 : proratedAmount;
  }

  _getProratedTax(overrides: PaymentOverrides = {}) {
    const overridden = this._handleOverrides(overrides);
    const proratedTax = this._getChargeData(
      'tax',
      true,
      overridden.plan,
      overridden.units,
      overridden.billingType,
      overridden.subscriptionPlans,
    );
    // Monthly this.accounts may not have a negative prorated amount due to previous plan info
    return proratedTax < 0 && overridden.billingType === Account.Billing.MONTHLY ? 0 : proratedTax;
  }

  getProratedTotal(overrides: PaymentOverrides = {}) {
    const overridden = this._handleOverrides(overrides);

    let proratedTotal = this._getProratedAmount(overrides) + this._getProratedTax(overrides);

    const oldPlans = differenceBy(this.accountSubscriptionPlans, overridden.subscriptionPlans, 'id');
    const oldPlansCost = oldPlans.reduce((total, plan) => {
      return total + this._getAddonCost(plan);
    }, 0);
    proratedTotal -= oldPlansCost;

    return proratedTotal;
  }

  private _getAddonCost(plan: Plan) {
    // Get the current amount based on monthly vs annual
    const details = plan.details[plan.details.current];

    // Calculate the total that hasn't already been "spent"
    const amount = details.total * details.cycle;

    return amount;
  }

  getSubTotal(overrides: PaymentOverrides = {}) {
    const overridden = this._handleOverrides(overrides);
    return this._getChargeData(
      'amount',
      false,
      overridden.plan,
      overridden.units,
      overridden.billingType,
      overridden.subscriptionPlans,
    );
  }

  getTaxTotal(overrides: PaymentOverrides = {}) {
    const overridden = this._handleOverrides(overrides);
    return this._getChargeData(
      'tax',
      false,
      overridden.plan,
      overridden.units,
      overridden.billingType,
      overridden.subscriptionPlans,
    );
  }

  getTotal(overrides: PaymentOverrides = {}) {
    return this.getSubTotal(overrides) + this.getTaxTotal(overrides);
  }

  _getFailedSubTotal() {
    if (this.account.isBilledMonthly() && this.account.credit_attempts) {
      return this._getChargeData(
        'amount',
        false,
        this.accountPlan,
        this.account.plan_units,
        this.account.billing_type,
        this.accountSubscriptionPlans,
      );
    }
    return 0;
  }

  _getFailedTaxTotal() {
    if (this.account.isBilledMonthly() && this.account.credit_attempts) {
      const value = this._getChargeData(
        'tax',
        false,
        this.accountPlan,
        this.account.plan_units,
        this.account.billing_type,
        this.accountSubscriptionPlans,
      );
      return value;
    }
    return 0;
  }

  getFailedTotal() {
    return this._getFailedSubTotal() + this._getFailedTaxTotal();
  }

  getAnnualSavings(overrides: PaymentOverrides = {}) {
    const monthly = this.getTotal({ ...overrides, newBillingType: Account.Billing.MONTHLY });
    const annually = this.getTotal({ ...overrides, newBillingType: Account.Billing.ANNUAL });
    return Math.max(monthly * 12 - annually, 0);
  }
}
