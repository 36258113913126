import { batch } from 'react-redux';

import { updateAccountMetadata } from 'data/metadata/actions';
import { updateShiftsFields } from 'data/shift/reducer';
import User from 'data/user/model';
import { updateUser } from 'data/user/reducer';
import { ONBOARDING_USER_CREATED } from 'onboarding/metadataConstants';
import { getOnboardingUserCreatedAccordingToMetadata } from 'onboarding/selectors';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { type WiwState, createWiwAsyncThunk } from 'store';

import { createAsyncThunk } from '@reduxjs/toolkit';
import { difference } from 'lodash';
import moment from 'moment-timezone';

type SaveUserParams = {
  user: User;
  options?: {
    invite?: boolean | 'force';
  };
};

export const saveUser = createWiwAsyncThunk<any, SaveUserParams>(
  'user/saveUser',
  async ({ user, options = { invite: true } }, { rejectWithValue }) => {
    try {
      if (!user.isNew()) {
        return await Monolith.url(`/users/${user.id}`).put({ ...user.toJSON(), ...options });
      }
      return await Monolith.url('/users').post({ ...user.toJSON(), ...options });
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);

export const saveUserProfile = createAsyncThunk('user/saveUserProfile', async (user: User, { rejectWithValue }) => {
  try {
    return await Monolith.url('/users/profile').post(user.toJSON());
  } catch (error) {
    return rejectWithValue(parseAPIError(error));
  }
});

// this is fuckkked up
function saveUsersOrProfiles(users: User[], asProfile: boolean, options = {}, singleUser: boolean) {
  return (dispatch: any, getState: () => WiwState) => {
    const state = getState();
    const userLookup = users.map(user => ({
      original: state.data.user.items.get(user.id, new User()),
      updated: user,
    }));

    batch(() => {
      // calling `updateUser` on a new user will create an empty, dummy record
      // in the redux store which we want to avoid
      users
        .filter(user => user.id !== 0)
        .forEach(user => {
          dispatch(updateUser(user.set('saving', true)));
        });
    });

    const rejected: any[] = [];
    return Promise.allSettled(
      asProfile ? users.map(user => user.saveProfile(options)) : users.map(user => user.save(options)),
    )
      .then(results => {
        const updates: any[] = [];

        batch(() => {
          results.forEach((result, i) => {
            // `originalUser` will be null for new users
            const originalUser = userLookup[i].original;
            if (result.status === 'fulfilled') {
              const updatedUser = userLookup[i].updated;
              if (originalUser) {
                // TODO(we may be able to eliminate this because originalUser should always be truthy)
                const deletedLocations = difference(originalUser.locations, updatedUser.locations);
                // `updatedUser.id` will be null for new users
                if (updatedUser.id !== null && deletedLocations.length) {
                  const userShiftIds = state.data.shift.items
                    .filter(
                      shift =>
                        // Backend will reassign all future shifts for this user at this location to openshifts
                        shift.user_id === updatedUser.id &&
                        deletedLocations.includes(shift.location_id) &&
                        shift.date('start_time')!.isAfter(moment()),
                    )
                    .keySeq()
                    .toArray();

                  dispatch(updateShiftsFields({ ids: userShiftIds, fields: { user_id: 0 } }));
                }
              }

              const onboardingUserCreatedAccordingToMetadata = getOnboardingUserCreatedAccordingToMetadata(getState());
              const updateUserDispatch = dispatch(updateUser(new User(result.value.user)));

              // `updatedUser.id` will be null for new users
              if (updatedUser.id === 0 && !onboardingUserCreatedAccordingToMetadata) {
                updates.push(
                  Promise.all([
                    dispatch(
                      updateAccountMetadata({
                        [ONBOARDING_USER_CREATED]: true,
                      }),
                    ),
                    updateUserDispatch,
                  ]).then(promiseResults => promiseResults[1]), //discard the promise results from updateAccountMetadata
                );
              } else {
                updates.push(updateUserDispatch);
              }
            } else {
              // calling `updateUser` on a new user will create an empty, dummy
              // record in the redux store which we want to avoid
              if (originalUser.id !== 0) {
                // clean up!
                dispatch(updateUser(originalUser));
              }

              rejected.push({
                user: originalUser,
                error: result.reason,
              });
            }
          });
        });

        return Promise.all(updates);
      })
      .then(actions => {
        if (rejected.length > 0) {
          if (users.length === 1 && singleUser) {
            throw rejected[0].error;
          }

          throw rejected;
        }

        // some consumers use the resolved action(s), so we pass it through if
        // there are no errors to throw
        if (users.length === 1 && singleUser) {
          return actions[0];
        }

        return actions;
      });
  };
}

// export const saveUser = (user, options = { invite: true }) =>
//   saveUsersOrProfiles([user], false, options, true);
//
// export const saveUserProfile = (user, options = {}) =>
//   saveUsersOrProfiles([user], true, options, true);

export const saveUsers = (users: any, options = { invite: true }) => saveUsersOrProfiles(users, false, options, false);

export const saveUserProfiles = (users: any, options = {}) => saveUsersOrProfiles(users, true, options, false);
