import { getPlans } from 'data/plan/selectors/plans';
import { getAuthAccount } from 'shared/auth/selectors';

import { createSelector } from '@reduxjs/toolkit';

const filterCurrentPlan = (plans, account) => {
  if (plans && account) {
    return plans.get(account.plan_id);
  }

  return null;
};

const getPropsOrStatePlans = (state, props) => {
  return props?.plans ? props.plans : getPlans(state, props);
};

const getPropsOrStateAccount = (state, props) => {
  return props?.account ? props.account : getAuthAccount(state);
};

export const getCurrentPlan = createSelector([getPropsOrStatePlans, getPropsOrStateAccount], filterCurrentPlan);
