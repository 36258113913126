import type { TagListFields } from 'data/tag/TagList';
import type User from 'data/user/model';
import Worktags from 'shared/api/worktags';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type UpdateUserTagsArgs = {
  user: User;
  tagIds: string[];
  noAccountId?: boolean;
};

type UpdateUserTagsResponse = {
  data: TagListFields;
};

export const updateUserTags = createWiwAsyncThunk<UpdateUserTagsResponse, UpdateUserTagsArgs>(
  'tags/userTags/update',
  async ({ user, tagIds, noAccountId = false }, { rejectWithValue }) => {
    try {
      const body: { tags: string[]; accountId?: string } = {
        tags: tagIds,
      };
      if (!noAccountId) {
        body.accountId = `${user.account_id}`;
      }
      return (await Worktags.url(`/users/${user.id}`).put(body)) as UpdateUserTagsResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
