import SquareState from 'integrations/modules/square/state';
import {
  FETCH_SQUARE,
  FETCH_SQUARE_EMPLOYEES,
  FETCH_SQUARE_SCHEDULES,
  FETCH_SQUARE_SETTINGS,
  SAVE_SQUARE_MAPPINGS,
  SAVE_SQUARE_SETTINGS,
  UPDATE_SQUARE_MAPPING,
} from 'store/action-types';

import SquareSettings from './models/SquareSettings';

export default (state = new SquareState(), action) => {
  switch (action.type) {
    case FETCH_SQUARE.REQUEST:
      return state.merge({
        loaded: false,
        loading: true,
        receivedAt: 0,
      });
    case FETCH_SQUARE.FAILURE:
      return state.merge({
        error: action.payload,
        loaded: false,
        loading: false,
      });
    case FETCH_SQUARE.SUCCESS:
      return state.merge({
        integration: action.payload,
        integrationLoaded: true,
        error: null,
        loaded: true,
        loading: false,
        receivedAt: Date.now(),
      });

    // ----------------------------------------
    case FETCH_SQUARE_SCHEDULES.REQUEST:
      return state.merge({
        loaded: false,
        loading: true,
        receivedAt: 0,
      });
    case FETCH_SQUARE_SCHEDULES.FAILURE:
      return state.merge({
        error: action.payload,
        loaded: false,
        loading: false,
      });
    case FETCH_SQUARE_SCHEDULES.SUCCESS:
      return state.merge({
        schedules: action.payload,
        schedulesLoaded: true,
        error: null,
        loaded: true,
        loading: false,
        receivedAt: Date.now(),
      });

    // ----------------------------------------
    case FETCH_SQUARE_EMPLOYEES.REQUEST:
      return state.merge({
        loaded: false,
        loading: true,
        receivedAt: 0,
      });
    case FETCH_SQUARE_EMPLOYEES.FAILURE:
      return state.merge({
        error: action.payload,
        loaded: false,
        loading: false,
      });
    case FETCH_SQUARE_EMPLOYEES.SUCCESS:
      return state.merge({
        employees: action.payload,
        employeesLoaded: true,
        error: null,
        loaded: true,
        loading: false,
        receivedAt: Date.now(),
      });

    // ---------------------------------------
    case UPDATE_SQUARE_MAPPING.REQUEST:
      return state.merge({
        loaded: false,
        loading: true,
        receivedAt: 0,
      });
    case UPDATE_SQUARE_MAPPING.FAILURE:
      return state.merge({
        error: action.payload,
        loaded: false,
        loading: false,
      });
    case UPDATE_SQUARE_MAPPING.SUCCESS:
      return state.merge({
        integration: state.integration.set('maps', state.integration.maps.mergeDeep(action.payload)),
        error: null,
        loaded: true,
        loading: false,
        receivedAt: Date.now(),
      });

    // ---------------------------------------
    case SAVE_SQUARE_MAPPINGS.REQUEST:
      return state.merge({
        loaded: false,
        loading: true,
        receivedAt: 0,
      });
    case SAVE_SQUARE_MAPPINGS.FAILURE:
      return state.merge({
        error: action.payload,
        loaded: false,
        loading: false,
      });
    case SAVE_SQUARE_MAPPINGS.SUCCESS: {
      const withRemovedMaps = state.integration.maps.withMutations(map => {
        action.payload.removed.forEach((mappings, mapType) => {
          mappings.forEach((_, localId) => {
            map = map.deleteIn([mapType, localId]);
          });
        });
      });
      return state.merge({
        integration: state.integration.set('maps', withRemovedMaps.mergeDeep(action.payload.updated)),
        error: null,
        loaded: true,
        loading: false,
        receivedAt: Date.now(),
      });
    }

    // ----------------------------------------
    case FETCH_SQUARE_SETTINGS.REQUEST:
    case SAVE_SQUARE_SETTINGS.REQUEST:
      return state.merge({
        settings: new SquareSettings(),
        settingsError: null,
        settingsLoaded: false,
        settingsLoading: true,
        settingsReceivedAt: 0,
      });
    case FETCH_SQUARE_SETTINGS.FAILURE:
    case SAVE_SQUARE_SETTINGS.FAILURE:
      return state.merge({
        settings: new SquareSettings(),
        settingsError: action.payload,
        settingsLoaded: false,
        settingsLoading: false,
        settingsReceivedAt: 0,
      });
    case FETCH_SQUARE_SETTINGS.SUCCESS:
    case SAVE_SQUARE_SETTINGS.SUCCESS:
      return state.merge({
        settings: new SquareSettings(action.payload),
        settingsError: null,
        settingsLoaded: true,
        settingsLoading: false,
        settingsReceivedAt: Date.now(),
      });

    default:
      return state;
  }
};
