import BaseModel from 'data/BaseModel';
import { ScheduledTaskModel } from 'data/tasks/models/ScheduledTaskModel';
import TaskListModel from 'data/tasks/models/TaskListModel';
import type { ScheduledTaskFields } from './ScheduledTaskModel';

import { Map } from 'immutable';
import map from 'lodash/map';

export interface ScheduledTaskListFields {
  id: string | null;
  taskList: TaskListModel | null; // This should be a TaskList model object.
  scheduledTasks: { [taskId: string]: ScheduledTaskFields } | null;
  locationId: string | null;
  dueDate: string | null;
  shiftId: string | null;
  type: number;
}

export class ScheduledTaskListModel extends BaseModel<ScheduledTaskListFields>({
  id: null,
  taskList: null, // This should be a TaskList model object.
  scheduledTasks: null,
  locationId: null,
  dueDate: null,
  shiftId: null,
  type: 0,
}) {
  constructor(args: Partial<ScheduledTaskListFields> = {}) {
    let tasks = Map<string, ScheduledTaskModel>();
    if (args.scheduledTasks) {
      map(args.scheduledTasks, (task, id) => {
        tasks = tasks.set(id, new ScheduledTaskModel(task));
      });
    }

    super(
      Object.assign({}, args, {
        scheduledTasks: tasks,
        taskList: args.taskList && new TaskListModel(args.taskList),
      }),
    );
  }
}
