import { useEffect } from 'react';

import { openDialog } from 'dialogs/actions';
import { TRIAL_REACTIVATION_DIALOG } from 'marketing/dialogs';
import { getAuthUser } from 'shared/auth/selectors';
import Mercury, { formatQueryString } from 'shared/util/mercury';
import { useWiwDispatch, useWiwSelector } from 'store';

import Cookies from 'js-cookie';

const TrialReactivation = () => {
  const dispatch = useWiwDispatch();
  const loginCookie = Cookies.get('login-message');
  const userRole = useWiwSelector(getAuthUser);

  useEffect(() => {
    const queryParams =
      window.location.search === '' ? window.location.search : formatQueryString(window.location.search);

    if (loginCookie) {
      Mercury.track(
        'web::onInteraction',
        {
          annotations: JSON.stringify({ reactivationDialog: 'open', reactivationName: loginCookie }),
          appComponent: 'TrialReactivateDialog',
          hash: window.location.hash,
          hostName: window.location.hostname,
          path: window.location.pathname,
          queryParams: queryParams,
          target: 'trialReactivateDialog',
          userRole: userRole.mercuryRoleName(),
        },
        {},
      );

      if (loginCookie === 'reactivate14') {
        dispatch(openDialog(TRIAL_REACTIVATION_DIALOG));
      }

      if (loginCookie === 'reactivate30') {
        dispatch(openDialog(TRIAL_REACTIVATION_DIALOG));
      }
    }
  }, []);

  return null;
};

export default TrialReactivation;
