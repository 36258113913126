import Payment from 'billing/util/payment';
import TierPlanMapping from 'billing/util/tierPlanMapping';
import Plan from 'data/plan/model';
import Features from 'shared/features';

import { Map } from 'immutable';
import { camelCase } from 'lodash';

export class WithParam {
  static attendance = {
    selectAttendance: true,
    expandAttendance: true,
  };

  static basic = {
    selectBasic: true,
    expandScheduler: true,
  };

  static pro = {
    selectPro: true,
    expandScheduler: true,
  };

  static keyToConfig = Map({
    '4for14trial': WithParam.pro,
    attendance: WithParam.attendance,
    attendanceAutoDeduct: WithParam.attendance,
    attendanceClockinRules: WithParam.attendance,
    attendanceCustomExport: WithParam.attendance,
    attendanceGeoFence: WithParam.attendance,
    attendanceLocations: WithParam.attendance,
    attendanceManagerNotifications: WithParam.attendance,
    attendanceMobileClockin: WithParam.attendance,
    attendanceOtAlerts: WithParam.attendance,
    autoAssign: WithParam.pro,
    docStorage: WithParam.pro,
    historicalScheduling: WithParam.basic,
    laborReports: { ...WithParam.pro, ...WithParam.attendance },
    laborReportsAttendanceNotices: { ...WithParam.pro, ...WithParam.attendance },
    laborReportsOvertime: { ...WithParam.pro, ...WithParam.attendance },
    locations: WithParam.basic,
    month: WithParam.basic,
    openshiftRequests: WithParam.pro,
    payrollExport: WithParam.attendance,
    payrollIntegrationsAdpWfn: WithParam.attendance,
    payrollIntegrationsGusto: WithParam.attendance,
    payrollIntegrationsPaychex: WithParam.attendance,
    payrollIntegrationsQbo: WithParam.attendance,
    payrollIntegrationsSquare: WithParam.attendance,
    payrollIntegrationsXero: WithParam.attendance,
    repeatingShifts: WithParam.basic,
    scheduling: WithParam.basic,
    shiftAcknowledgement: WithParam.basic,
    sites: WithParam.basic,
    tasks: WithParam.pro,
  });

  static featureMapping = Map({
    [Features.MONTH_VIEW]: 'month',
    [Features.OPENSHIFT_APPROVAL]: 'openshiftRequests',
    [Features.REPORTING]: 'laborReports',
    [Features.SHIFT_ACKNOWLEDGEMENT]: 'shiftAcknowledgement',
  });

  static reportSpecToWithParamMap = Map({
    'attendance-notices': 'laborReportsAttendanceNotices',
    extTimesheets: 'attendanceCustomExport',
    laborReports: 'laborReports',
    overtime: 'laborReportsOvertime',
  });

  get(key, notSetValue) {
    return WithParam.keyToConfig.get(key, notSetValue);
  }

  fromString(value) {
    if (WithParam.keyToConfig.has(value)) {
      return value;
    }

    return WithParam.featureMapping.get(value) || WithParam.reportSpecToWithParamMap.get(value) || camelCase(value);
  }

  fromFeature(feature, notSetValue) {
    return WithParam.featureMapping.get(feature, notSetValue || camelCase(feature));
  }

  fromReportSpec(specKey, notSetValue) {
    return WithParam.reportSpecToWithParamMap.get(specKey, notSetValue || 'laborReports');
  }

  toRecommendedPlans = (
    key,
    account,
    accountPlan,
    units,
    nextBillingType,
    forceAttendance,
    availablePlans,
    tierPlanMapping,
  ) => {
    tierPlanMapping = tierPlanMapping || new TierPlanMapping();
    const withParamConfig = this.get(this.fromString(key));
    const result = {
      [Plan.ProductLine.ATTENDANCE]: accountPlan,
      [Plan.ProductLine.SCHEDULING]: accountPlan,
      costDifference: {
        PER_PERIOD: 0,
        PER_USER_PER_PERIOD: 0,
      },
    };

    if (!withParamConfig || account.isBucket()) {
      return result;
    }
    const selectAttendance = forceAttendance || withParamConfig.selectAttendance || account.hasAttendanceNotFreemium();

    if (withParamConfig.selectBasic) {
      result[Plan.ProductLine.SCHEDULING] = tierPlanMapping.getTierPlan(
        Plan.Tier.BASIC,
        selectAttendance,
        availablePlans,
      );
      result[Plan.ProductLine.ATTENDANCE] = selectAttendance ? result[Plan.ProductLine.SCHEDULING] : null;
    }

    if (withParamConfig.selectPro) {
      result[Plan.ProductLine.SCHEDULING] = tierPlanMapping.getTierPlan(
        Plan.Tier.PRO,
        selectAttendance,
        availablePlans,
      );
      result[Plan.ProductLine.ATTENDANCE] = selectAttendance ? result[Plan.ProductLine.SCHEDULING] : null;
    } else if (withParamConfig.selectAttendance) {
      // only selected attendance
      if (accountPlan?.isTrial()) {
        result[Plan.ProductLine.SCHEDULING] = tierPlanMapping.getTierPlan(
          Plan.Tier.PRO,
          selectAttendance,
          availablePlans,
        );
      } else {
        result[Plan.ProductLine.SCHEDULING] = tierPlanMapping.getTierPlanWithAttendance(accountPlan, availablePlans);
      }
      result[Plan.ProductLine.ATTENDANCE] = result[Plan.ProductLine.SCHEDULING];
    }

    const payment = new Payment(account, accountPlan, []);
    result.costDifference = payment.recommendedPlansCostDiff(
      {
        [Plan.ProductLine.ATTENDANCE]: accountPlan,
        [Plan.ProductLine.SCHEDULING]: accountPlan,
      },
      result,
      { newUnits: units, newBillingType: nextBillingType },
    );

    return result;
  };
}

export default new WithParam();
