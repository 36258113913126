import { SCHEDULER_TAG_FILTER } from 'scheduler/util/cookies';
import { getPersistedFilter, persistFilter } from 'scheduler/util/persistFilter';
import { UPDATE_TAG_FILTER } from 'store/action-types';

export function updateTagFilter(payload) {
  return dispatch => {
    persistFilter(SCHEDULER_TAG_FILTER, payload);
    return dispatch({
      type: UPDATE_TAG_FILTER,
      payload,
    });
  };
}

export const getInitialTagFilter = () => {
  return getPersistedFilter(SCHEDULER_TAG_FILTER, []) || [];
};
