import { preserveReferencesForKeys } from 'shared/util/memo';

import { createSelector } from '@reduxjs/toolkit';
import { Map } from 'immutable';
import moment from 'moment-timezone';

const getStateScheduledTaskLists = state => state.data.scheduledTaskLists.items;

export const getScheduledTaskLists = getStateScheduledTaskLists;

export const getOrderedScheduledTaskLists = createSelector(
  getStateScheduledTaskLists,
  preserveReferencesForKeys(scheduledTaskLists =>
    scheduledTaskLists.sortBy(scheduledTaskList => scheduledTaskList.taskList.name),
  ),
);

export const getScheduledTaskListsForDay = (scheduledTaskLists, accountTz, day, locationId) => {
  return scheduledTaskLists.filter(scheduledTaskList => {
    const dueDate = moment.tz(scheduledTaskList.dueDate, accountTz);
    return dueDate.isSame(day, 'day') && scheduledTaskList.locationId === `${locationId}`;
  });
};

export const getScheduledTaskListsByType = createSelector(
  getStateScheduledTaskLists,
  preserveReferencesForKeys(scheduledTaskLists =>
    scheduledTaskLists.groupBy(scheduledTaskList => scheduledTaskList.taskList.type),
  ),
);

export const getScheduledTaskListsForType = type => state => {
  return getScheduledTaskListsByType(state).get(type, new Map());
};

export const getScheduledTaskListsByShiftId = createSelector(
  getOrderedScheduledTaskLists,
  preserveReferencesForKeys(scheduledTaskLists =>
    scheduledTaskLists.groupBy(scheduledTaskList => scheduledTaskList.shiftId),
  ),
);

export const getScheduledTaskListsForShiftId = shiftId => state => {
  return getScheduledTaskListsByShiftId(state).get(`${shiftId}`, new Map());
};

export const getTaskListIdsForShiftId = shiftId => state => {
  return getScheduledTaskListsByShiftId(state)
    .get(`${shiftId}`, new Map())
    .map(scheduledTaskList => scheduledTaskList.taskList.id)
    .toArray();
};
