import { getPlans } from 'data/plan/selectors/plans';
import { getAuthAccount } from 'shared/auth/selectors';

import { createSelector } from '@reduxjs/toolkit';

const filterCustomPlan = (plans, account) => {
  if (plans && account) {
    return plans.get(account.plan_custom);
  }

  return null;
};

const getPropsOrStateAccount = (state, props) => {
  return props?.account ? props.account : getAuthAccount(state);
};

export const getCustomPlan = createSelector([getPlans, getPropsOrStateAccount], filterCustomPlan);
