import BaseModel from 'data/BaseModel';
import { shortTimeRange } from 'shared/util/time';

export interface TimeFields {
  id: number;
  account_id: number | null;
  user_id: number | null;
  creator_id: number | null;
  position_id: number;
  location_id: number;
  shift_id: number | null;
  site_id: number | null;
  start_time: string | null;
  end_time: string | null;
  break_time: null; // TODO(types)
  notes: string | null;
  length: number | null;
  hourly_rate: number | null;
  alert_type: number | null;
  is_approved: boolean | null;
  modified_by: number | null;
  sync_id: string | null;
  sync_hash: string | null;
  updated_at: string | null;
  created_at: string | null;
  combinable: null; // TODO(types)
  split_time: string | null;
  break_hours: null; // TODO(types)
  is_alerted: boolean | null;
  deleted_at: string | null;
  rounded_length: number | null;
  rounded_start_time: string | null;
  rounded_end_time: string | null;
  cash_tips: string | null;
}

export enum TimeAlertType {
  LateClock = 1,
  EarlyClock = 2,
  WrongLocation = 4,
  ManuallyEntered = 8,
  NoScheduledShift = 16,
}

class Time extends BaseModel<TimeFields>({
  id: 0,
  account_id: null,
  user_id: null,
  creator_id: null,
  position_id: 0,
  location_id: 0,
  shift_id: null,
  site_id: 0,
  start_time: null,
  end_time: null,
  break_time: null,
  notes: null,
  length: null,
  hourly_rate: null,
  alert_type: null,
  is_approved: null,
  modified_by: null,
  sync_id: null,
  sync_hash: null,
  updated_at: null,
  created_at: null,
  combinable: null,
  split_time: null,
  break_hours: null,
  is_alerted: null,
  deleted_at: null,
  rounded_length: null,
  rounded_start_time: null,
  rounded_end_time: null,
  cash_tips: null,
}) {
  static AlertType = {
    LATE_CLOCK: TimeAlertType.LateClock,
    EARLY_CLOCK: TimeAlertType.EarlyClock,
    WRONG_LOCATION: TimeAlertType.WrongLocation,
    MANUALLY_ENTERED: TimeAlertType.ManuallyEntered,
    NO_SCHEDULED_SHIFT: TimeAlertType.NoScheduledShift,
  } as const;

  isIncomplete() {
    return this.id > 0 && (this.start_time === null || this.end_time === null);
  }

  shortTimeRange() {
    return shortTimeRange(this.mustDate('start_time'), this.mustDate('end_time'));
  }
}

export default Time;
