import { useCallback, useState } from 'react';

type Watchable = <T = unknown>(promise: Promise<T> | (Promise<T> & { unwrap: () => Promise<T> })) => Promise<T>;

export const useLoading = () => {
  const [loading, setLoading] = useState(false);

  const watch = useCallback<Watchable>(promise => {
    if ('unwrap' in promise) {
      promise = promise.unwrap();
    }

    setLoading(true);
    return promise.finally(() => setLoading(false));
  }, []);

  return [loading, watch] as const;
};
