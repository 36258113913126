import type { FC } from 'react';
import { Redirect } from 'react-router-dom';

import type Account from 'data/account/model';
import { getAuthAccount } from 'shared/auth/selectors';
import { useWiwSelector } from 'store';

export default function HasAttendance(ComposedComponent: FC) {
  return function HasAttendance(props?: any) {
    const account = useWiwSelector<Account>(getAuthAccount);
    if (!account.hasAttendance() || !account.getSettings('payroll.is_onboarded', false)) {
      return <Redirect to={CONFIG.APP_LEGACY_ROOT} />;
    }

    return <ComposedComponent {...props} />;
  };
}
