import { Record } from 'immutable';
import type { JwtPayload } from 'jwt-decode';
import moment from 'moment-timezone';

export interface TokenClaims extends JwtPayload {
  // Custom claims
  exp: number; // Expiration
  iat: number; // Issued at
  scopes: string[];
  pid: string; // Person ID
  app: string; // App ID
  uid: string; // User ID
  user: string | string[]; // User IDs (ONLY SAML)
  aid: string; // Account ID
  act: string; // Actor (who is acting on behalf of the user)
  limited?: boolean; // Legacy limited token
}

export default class Token extends Record<TokenClaims>({
  scopes: [],
  pid: '',
  app: '',
  uid: '',
  user: '',
  aid: '',
  act: '',
  limited: false,
  iss: '',
  sub: '',
  exp: 0,
  nbf: 0,
  iat: 0,
  jti: '',
}) {
  hasScope(scope: string): boolean {
    return !!this.scopes?.includes(scope);
  }

  isLimited() {
    return this.limited || this.hasScope('limited');
  }

  expiresAt() {
    return moment.unix(this.exp);
  }

  isExpired() {
    if (this.exp === 0) {
      return false;
    }
    return this.expiresAt().isBefore(moment());
  }

  issuedAt() {
    return moment.unix(this.iat);
  }

  needsRefresh() {
    if (this.isLimited()) {
      return false;
    }

    if (!this.issuedAt().add(CONFIG.TOKEN_REFRESH_INTERVAL, 'seconds').isSameOrBefore(moment())) {
      return false;
    }

    return true;
  }

  accountId() {
    return this.aid;
  }

  userId() {
    return this.uid;
  }

  isValidStepUpToken() {
    return !this.isExpired() && this.hasScope('secure-access');
  }
}
