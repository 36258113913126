import { createShiftBreakAudit, fetchShiftBreakAudits } from 'data/shiftBreak/actions';
import { bulkCreateShiftBreakAudit } from 'data/shiftBreak/actions/bulkCreateShiftBreakAudits';
import { ShiftBreakAudit } from 'data/shiftBreak/models';
import generateThunkReducers from 'data/util/generateThunkReducers';
import { optimisticallyUpdateEntity } from 'data/util/optimisticUpdate';
import { mergeEntityItems } from 'data/util/sliceHelpers';

import { createSlice } from '@reduxjs/toolkit';
import EntityState from 'data/EntityState';

const initialState = new EntityState<ShiftBreakAudit>();

export const ShiftBreakAuditSlice = createSlice({
  name: 'shiftBreakAudit',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchShiftBreakAudits.fulfilled, (state, action) => {
      return mergeEntityItems(state, action.payload.data, ShiftBreakAudit);
    });
    builder.addCase(bulkCreateShiftBreakAudit.fulfilled, (state, action) => {
      return mergeEntityItems(state, action.payload.data, ShiftBreakAudit);
    });
    optimisticallyUpdateEntity(builder, createShiftBreakAudit, ShiftBreakAudit, { payloadPath: 'data' });
    generateThunkReducers(builder, 'shiftBreakAudit');
  },
});

export default ShiftBreakAuditSlice.reducer;
