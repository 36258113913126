import type Availability from 'data/availability/model';
import type { AvailabilityFields } from 'data/availability/model';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type CreateAvailabilityArgs = Availability;

type CreateAvailabilityResponse = {
  availabilityevent: AvailabilityFields;
};

export const createAvailability = createWiwAsyncThunk<CreateAvailabilityResponse, CreateAvailabilityArgs>(
  'availability/create',
  async (availability, { rejectWithValue }) => {
    try {
      return (await Monolith.url('/availabilityevents').post(availability.toJSON())) as CreateAvailabilityResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
