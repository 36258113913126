import { useHotkeys } from '@mantine/hooks';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useClickOutside } from 'shared/hooks/useClickOutside';
import Button from 'shared/ui/Button';
import FontIcon from 'shared/ui/FontIcon';
import EmojiPicker from 'workchat/v2/components/partials/EmojiPicker';
import { EMOJI, type Emoji, GIF, type InputType } from 'workchat/v2/constants';

export interface ReactionPickerApi {
  pickReaction: () => Promise<Emoji | null>;
}

export interface ReactionPickerProps {
  showHeader?: boolean;
  inputType?: InputType;
}

/**
 * ReactionPicker Component
 *
 * # Usage with ReactionPickerProvider and useReactionHook:
 * If you want to share the same ReactionPicker instance, you can use it with the useReactionPicker hook() and then bind its ref:
 * <ReactionPickerProvider>
 *    <ViewComponent />
 * </ReactionPickerProvider>
 *
 * -- ViewComponent:
 *
 * const reactionPicker = useReactionPicker();
 * return (
 *  <div>
 *    <ReactionPicker ref={reactionPicker.bindRef}>
 *    <CallingComponentA />
 *    <CallingComponentB />
 *  </div>
 * )
 *
 * -- CallingComponentA / CallingComponentB
 *
 * const reactionPicker = useReactionPicker();
 * return <button onClick={() => reactionPicker.pickReaction().then((reaction) => { console.log(reaction) } )} />
 *
 * # Usage without ReactionPickerProvider;
 *
 * const ref = useRef();
 *
 * const handleClick = () => ref.current?.pickReaction().then((reaction) => console.log(reaction));
 *
 * return <ReactionPicker ref={ref} />
 *
 */
const ReactionPicker = forwardRef<ReactionPickerApi, ReactionPickerProps>(
  ({ showHeader, inputType = EMOJI }: ReactionPickerProps, ref: any) => {
    const [activeType, setActiveType] = useState<InputType>(inputType);
    const [showPicker, setShowPicker] = useState(false);
    const pickerPromise = useRef<{ resolve?: (value: Emoji | null) => void }>();

    const reactionButton = document.querySelector('.btn-reaction-picker') as HTMLButtonElement;

    const innerRef = useClickOutside(
      () => {
        setShowPicker(false);
      },
      ['touchstart', 'mousedown'],
      [reactionButton],
    );

    const handleEmojiPick = (emoji: Emoji) => {
      pickerPromise.current?.resolve?.(emoji);
      setShowPicker(false);
    };

    const handleClose = () => {
      pickerPromise.current?.resolve?.(null);
      setShowPicker(false);
    };

    useImperativeHandle(
      ref,
      () => {
        return {
          pickReaction() {
            let resolve: (value: Emoji | string | null) => void = () => {};
            const promise = new Promise(resolver => {
              resolve = resolver;
            });

            pickerPromise.current = { resolve };

            setShowPicker(true);
            return promise;
          },
        };
      },
      [setShowPicker],
    );

    useHotkeys([['Escape', handleClose]]);

    useEffect(() => {
      setActiveType(inputType);
    }, [inputType]);

    if (!showPicker) {
      return null;
    }

    return (
      <div ref={innerRef} className="reaction-picker" data-ignore-outside-clicks>
        {showHeader && (
          <header className="reaction-picker-header">
            <div className="btn-group">
              <Button className="m-2" size="sm" onClick={() => setActiveType(EMOJI)}>
                Emoji
              </Button>
              <Button className="m-2" size="sm" onClick={() => setActiveType(GIF)}>
                Gif
              </Button>
            </div>
            <Button size="sm" onClick={handleClose}>
              <FontIcon icon="close" srText="Close react picker" />
            </Button>
          </header>
        )}

        {activeType === EMOJI && <EmojiPicker onEmojiSelect={handleEmojiPick} />}
      </div>
    );
  },
);

export default ReactionPicker;
