//V3 endpoints return objects with fields in camelCase, but our Redux models are all snake case
//This function will take an object and convert camelCase properties to snake case
import { camelCase, snakeCase } from 'lodash';

export function camelCaseToSnakeCaseObject(object) {
  const newObject = {};

  Object.keys(object).forEach(oldPropertyName => {
    const newPropertyName = snakeCase(oldPropertyName);
    newObject[newPropertyName] = object[oldPropertyName];
  });

  return newObject;
}

export function snakeCaseToCamelCaseObject(object) {
  const newObject = {};

  Object.keys(object).forEach(oldPropertyName => {
    const newPropertyName = camelCase(oldPropertyName);
    newObject[newPropertyName] = object[oldPropertyName];
  });

  return newObject;
}
