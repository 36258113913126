import { useMemo, useState } from 'react';

import type Account from 'data/account/model';
import { getToken } from 'shared/auth/auth';
import { getAuthAccount, getAuthUser } from 'shared/auth/selectors';
import AppBranches from 'shared/dev/branchSwitchers/AppBranches';
import LoginBranches from 'shared/dev/branchSwitchers/LoginBranches';
import ReactBranches from 'shared/dev/branchSwitchers/ReactBranches';
import { ServiceItem } from 'shared/dev/components/ServiceItem';
import { ServicesConfig } from 'shared/dev/serviceConfig';
import { Features } from 'shared/features';
import Button from 'shared/ui/Button';
import FontIcon from 'shared/ui/FontIcon';
import Environment from 'shared/util/environment';
import { isWebview } from 'shared/util/isWebview';
import { type WiwState, useWiwSelector } from 'store';

import { Chip, Modal } from '@mantine/core';
import Cookie from 'js-cookie';

import 'shared/dev/styles/DevPanel.scss';

type Copyables = 'loginid' | 'userid' | 'userrole' | 'accountid' | 'planid' | 'authtoken' | 'stripe_id';

export const WIW_DEBUG_COOKIE_NAME = 'wiwDebug';

export default function DevPanel() {
  const currentUser = useWiwSelector(getAuthUser);
  const currentAccount = useWiwSelector(getAuthAccount);
  const loginId = useWiwSelector((state: WiwState) => state.auth.loginId);

  const [isDevPanelOpen, setIsDevPanelOpen] = useState(false);
  const [isCopied, setIsCopied] = useState<Record<Copyables, boolean>>({} as Record<Copyables, boolean>);
  const [reloadOnClose, setReloadOnClose] = useState(false);

  const features = useMemo(() => {
    const features = Object.values(Features).reduce(
      (acc, feat) => {
        acc[feat] = false;
        return acc;
      },
      {} as Record<string, boolean>,
    );

    currentAccount.features.forEach(feat => {
      features[feat] = true;
    });

    return features;
  }, [currentAccount]);

  const toggleDevPanel = () => {
    if (reloadOnClose) {
      window.location.reload();
    }
    setIsDevPanelOpen(!isDevPanelOpen);
  };

  const devPanelShouldShow = () => {
    return !Environment.isProduction() || Cookie.get(WIW_DEBUG_COOKIE_NAME);
  };

  const onBranchChanged = () => {
    setReloadOnClose(true);
  };

  const buildVersionReporters = () => {
    return ServicesConfig.sort((a, b) => a.name.localeCompare(b.name)).map(serviceConfig => {
      return <ServiceItem key={serviceConfig.name} config={serviceConfig} onBranchChanged={onBranchChanged} />;
    });
  };

  const handleCopy = (label: Copyables, value: any) => {
    return async () => {
      await navigator.clipboard.writeText(value);
      setIsCopied({ ...isCopied, [label]: true });
      setTimeout(() => setIsCopied({ ...isCopied, [label]: false }), 1500);
    };
  };

  const checkBillingEngine = (account: Account) => {
    switch (true) {
      case account.isV5Billing():
        return '5 ( Stripe Billing )';
      case account.isADPBilling():
        return '4 ( ADP Billing )';
      default:
        return '1 ( WIW Billing )';
    }
  };

  const renderPanel = () => {
    const xrayLink = `https://x-ray.wiwdatastage.com/#/accounts/${currentAccount.id}/`;
    const stripeCustomerLink = Environment.isProduction()
      ? `https://dashboard.stripe.com/customers/${currentAccount.stripe_id}`
      : `https://dashboard.stripe.com/test/customers/${currentAccount.stripe_id}`;

    return (
      <Modal opened={isDevPanelOpen} onClose={toggleDevPanel} title="Dev Panel" className="dev-panel-dialog">
        {isWebview() && (
          <Button color="primary" className="mb-3" onClick={() => window.location.reload()}>
            Refresh Webview
          </Button>
        )}
        <div className="row">
          <div className="col-md branchPickers">
            <div className="row">
              {!Environment.isProduction() && [
                <ReactBranches key="react" label="WIWJS Branch" onBranchChanged={onBranchChanged} />,
                <LoginBranches key="login" label="Login-JS Branch" onBranchChanged={onBranchChanged} />,
                <AppBranches key="monolith" label="App Branch" onBranchChanged={onBranchChanged} />,
              ]}
            </div>
          </div>
        </div>

        <h3 className="list-title badge mt-3">Session Info</h3>
        <ul className="list-group">
          {Environment.isStaging() && (
            <li className="list-group-item">
              X-Ray Link:&nbsp;
              <a href={xrayLink} target="_blank" rel="noreferrer">
                {xrayLink}
              </a>
            </li>
          )}

          <li className="list-group-item d-flex align-items-baseline justify-content-between">
            <div>
              Current Login ID:
              <span className="badge badge-default">{loginId}</span>
            </div>

            <Button color="secondary" onClick={handleCopy('loginid', loginId)} size="sm">
              <FontIcon icon="copy" /> {isCopied.loginid ? 'Copied!' : 'Copy Login ID'}
            </Button>
          </li>

          <li className="list-group-item d-flex align-items-baseline justify-content-between">
            <div>
              Current User ID:
              <span className="badge badge-default">{currentUser.id}</span>
            </div>

            <Button color="secondary" onClick={handleCopy('userid', currentUser.id)} size="sm">
              <FontIcon icon="copy" /> {isCopied.userid ? 'Copied!' : 'Copy User ID'}
            </Button>
          </li>

          <li className="list-group-item d-flex align-items-baseline justify-content-between">
            <div>
              Current User Role:
              <span className="badge badge-default">{currentUser.roleName}</span>
            </div>

            <Button color="secondary" onClick={handleCopy('userrole', currentUser.role)} size="sm">
              <FontIcon icon="copy" /> {isCopied.userrole ? 'Copied!' : 'Copy User Role'}
            </Button>
          </li>

          <li className="list-group-item d-flex align-items-baseline justify-content-between">
            <div>
              Current Account ID:
              <span className="badge badge-default">{currentAccount.id}</span>
            </div>

            <Button color="secondary" onClick={handleCopy('accountid', currentAccount.id)} size="sm">
              <FontIcon icon="copy" /> {isCopied.accountid ? 'Copied!' : 'Copy Account ID'}
            </Button>
          </li>

          <li className="list-group-item d-flex align-items-baseline justify-content-between">
            <div>
              Current Account Plan:
              <span className="badge badge-default">{currentAccount.plan_id}</span>
            </div>

            <Button color="secondary" onClick={handleCopy('planid', currentAccount.plan_id)} size="sm">
              <FontIcon icon="copy" /> {isCopied.planid ? 'Copied!' : 'Copy Account Plan'}
            </Button>
          </li>

          <li className="list-group-item d-flex align-items-baseline justify-content-between">
            <div>
              Current Auth Token:
              <span className="badge badge-default">TL;DR</span>
            </div>

            <Button color="secondary" onClick={handleCopy('authtoken', getToken())} size="sm">
              <FontIcon icon="copy" /> {isCopied.authtoken ? 'Copied!' : 'Copy Auth Token'}
            </Button>
          </li>

          <li className="list-group-item d-flex align-items-baseline justify-content-between">
            <div>
              Current Billing Engine:
              <span className="badge badge-default">{checkBillingEngine(currentAccount)}</span>
            </div>
          </li>

          <li className="list-group-item d-flex align-items-baseline justify-content-between">
            <div>
              Current Stripe ID:
              <span className="badge badge-default">
                {currentAccount.stripe_id ? (
                  <a target="_blank" href={stripeCustomerLink} rel="noopener noreferrer">
                    {currentAccount.stripe_id}
                  </a>
                ) : (
                  'N/A'
                )}
              </span>
            </div>

            {currentAccount.stripe_id && (
              <Button color="secondary" onClick={handleCopy('stripe_id', currentAccount.stripe_id)} size="sm">
                <FontIcon icon="copy" /> {isCopied.stripe_id ? 'Copied!' : 'Copy Stripe ID'}
              </Button>
            )}
          </li>

          {CONFIG.DEPLOY.SHA && (
            <li className="list-group-item justify-content-between">
              Current Deploy:
              <span className="badge badge-default">
                <a
                  target="_blank"
                  href={`${CONFIG.DEVPANEL.repositoryBase}/commit/${CONFIG.DEPLOY.SHA}`}
                  rel="noreferrer"
                >
                  {CONFIG.DEPLOY.SHA.substring(0, 8)}
                </a>
              </span>
            </li>
          )}
        </ul>

        <h3 className="list-title badge mt-3">Account Features</h3>
        <ul className="list-group d-flex">
          <li className="list-group-item">
            <div className="d-flex flex-wrap justify-content-center" style={{ gap: '4px' }}>
              <Chip.Group multiple>
                {Object.entries(features)
                  .toSorted(([a], [b]) => a.localeCompare(b))
                  .map(([feature, has]) => (
                    <Chip key={feature} checked={has}>
                      {feature}
                    </Chip>
                  ))}
              </Chip.Group>
            </div>
          </li>
        </ul>

        <h3 className="list-title badge mt-3">Services</h3>
        <ul className="list-group d-flex" data-testid="servicesList">
          {buildVersionReporters()}
        </ul>
      </Modal>
    );
  };

  const renderButton = () => {
    return (
      <div
        className="dev-panel-link"
        onClick={toggleDevPanel}
        style={{
          position: 'absolute',
          top: 56,
          right: 0,
          padding: 10,
        }}
      >
        <button type="button" className="border-0 bg-transparent">
          <FontIcon icon="settings" />
        </button>
      </div>
    );
  };

  return (
    <div className="container dev-panel">
      {devPanelShouldShow() && isDevPanelOpen && renderPanel()}
      {devPanelShouldShow() && renderButton()}
    </div>
  );
}
