import EntityBase from 'data/EntityBase';
import { fetchTosAckRequired } from 'data/tos/actions/fetchTosAckRequired';
import { updateTosAcknowledgement } from 'data/tos/actions/updateTosAcknowledgement';
import generateThunkReducers from 'data/util/generateThunkReducers';

import { createSlice } from '@reduxjs/toolkit';

export class TermsOfServiceState extends EntityBase({
  tosAckRequired: false,
}) {}

const initialState = new TermsOfServiceState();

export const termsOfServiceSlice = createSlice({
  name: 'termsOfService',
  initialState,
  reducers: {},
  extraReducers: builder => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchTosAckRequired.fulfilled, (state, action) => {
        return state.setIn(['tosAckRequired'], action.payload.data.tosAckRequired);
      })
      .addCase(updateTosAcknowledgement.fulfilled, (state, _) => {
        return state.setIn(['tosAckRequired'], false);
      });
    generateThunkReducers(builder, 'termsOfService');
  },
});

export default termsOfServiceSlice.reducer;
