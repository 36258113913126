import type { Method } from 'data/auth/actions/fetchStepUpAuthStatus';
import generateThunkReducers from 'data/util/generateThunkReducers';

import { createSlice } from '@reduxjs/toolkit';

export interface AuthState {
  loaded: boolean;
  loading: boolean;
  bootstrapped: boolean;
  userId: number | null;
  accountId: number | null;
  loginId: number | null;
  isStepUpAuthActive: boolean;
  isStepUpAuthIdle: boolean;
  stepUpAuthMethod: Method | null;
}

const initialState: AuthState = {
  loaded: false,
  loading: false,
  bootstrapped: false,
  userId: null,
  accountId: null,
  loginId: null,
  isStepUpAuthActive: false,
  isStepUpAuthIdle: false,
  stepUpAuthMethod: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    receiveAuth: (state, action) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        bootstrapped: true,
        userId: action.payload.userId,
        accountId: action.payload.accountId,
        loginId: action.payload.loginId,
      };
    },
    setIsStepUpAuthActive: (state, action) => {
      state.isStepUpAuthActive = action.payload;
    },
    setIsStepUpAuthIdle: (state, action) => {
      state.isStepUpAuthIdle = action.payload;
    },
    setStepUpAuthMethod: (state, action) => {
      state.stepUpAuthMethod = action.payload;
    },
  },
  extraReducers: builder => {
    generateThunkReducers(builder, 'auth');
  },
});

export const { setIsStepUpAuthActive, setIsStepUpAuthIdle, setStepUpAuthMethod } = authSlice.actions;

export default authSlice.reducer;
