import PropTypes from 'prop-types';
import { Component } from 'react';

import WithParam from 'billing/util/WithParam';
import { Actions } from 'shared/experiment/constants';
import Button from 'shared/ui/Button';
import Mercury, { GROWTH_UPSELL } from 'shared/util/mercury';
import { SmartLink } from 'shared/util/redirect';
import { posthogCapture } from 'shared/vendor/posthog';

import classnames from 'classnames';
import { DateTime } from 'luxon';

export default class CountDown extends Component {
  static propTypes = {
    className: PropTypes.string,
    expiredCopy: PropTypes.string.isRequired,
    expiresAt: PropTypes.instanceOf(DateTime),
    feature: PropTypes.string,
    maximum: PropTypes.number,
    minimum: PropTypes.number.isRequired,
    now: PropTypes.instanceOf(DateTime), // only used when expiresAt is set
    onClick: PropTypes.func,
    onClickAction: PropTypes.string.isRequired,
    onTrackEvent: PropTypes.string.isRequired,
    onTrack: PropTypes.func,
    remaining: PropTypes.number,
    remainingCopy: PropTypes.string.isRequired,
    remainingShow: PropTypes.number, // only show when remaining is <= this amount
    remainingUrgent: PropTypes.number.isRequired,
    remainingWarn: PropTypes.number,
    to: PropTypes.string.isRequired,
    upsellCopy: PropTypes.string.isRequired,
  };

  static defaultProps = {
    expiredCopy: 'trial expired',
    expiresAt: null,
    maximum: null,
    minimum: 0,
    now: DateTime.now(),
    onClickAction: Actions.CLICKED_TRIAL_EXPIRATION,
    onTrackEvent: GROWTH_UPSELL,
    remaining: null,
    remainingCopy: 'left on trial',
    remainingShow: null,
    remainingUrgent: 0,
    remainingWarn: null,
    upsellCopy: 'Upgrade Today',
  };

  remaining() {
    const { expiresAt, maximum, minimum, now, remaining } = this.props;

    let result = 0;

    if (remaining !== null) {
      result = remaining;
    } else if (expiresAt !== null) {
      result = expiresAt.diff(now, ['days']).days;
    }

    result = Math.floor(Math.max(result, minimum));

    if (maximum !== null) {
      result = Math.min(result, maximum);
    }

    return result;
  }

  track = (action, attributes) => {
    const { featureRequested, ...otherAttributes } = attributes || {};
    const { feature, onTrackEvent, onTrack } = this.props;

    const fields = {
      featureRequested: WithParam.fromString(featureRequested || feature),
      ...otherAttributes,
    };

    if (onTrack) {
      onTrack(action, fields);
    } else {
      Mercury.track(onTrackEvent, {
        action: action,
        ...fields,
      });
    }
  };

  handleClick = e => {
    const { onClick, onClickAction } = this.props;

    this.track(onClickAction);
    posthogCapture('View time left on trial', { 'Trial days remaining': this.remaining() });

    if (onClick) {
      onClick(e);
    }
  };

  render() {
    const {
      className,
      expiredCopy,
      minimum,
      remainingCopy,
      remainingShow,
      remainingUrgent,
      remainingWarn,
      to,
      upsellCopy,
    } = this.props;

    const remaining = this.remaining();

    if (remainingShow !== null && remaining > remainingShow) {
      return null;
    }

    return (
      <div
        className={classnames('count-down', className, {
          'count-down-warning': remaining > remainingUrgent && remaining <= remainingWarn,
          'count-down-urgent': remaining <= remainingUrgent,
        })}
      >
        <SmartLink onClick={this.handleClick} to={to} className="btn btn-primary btn-sm">
          {upsellCopy}
        </SmartLink>
        <Button color="secondary" size="sm">
          {remaining > minimum ? ` ${remaining === 1 ? 'day' : 'days'} ${remainingCopy}` : ` ${expiredCopy}`}
        </Button>
        <ins>{remaining}</ins>
      </div>
    );
  }
}
