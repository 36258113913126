import type { BalancesResponse } from 'data/timeOffAccrual/types/common';
import type { DataResponse, QueryString } from 'data/types';
import Scheduling from 'shared/api/scheduling';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

export type BalanceUpdate = {
  userId: string;
  requestTypeId: string;
  balance: number;
};
interface BulkUpdateBalancesArgs {
  balances: BalanceUpdate[];
  params?: QueryString;
}

export const bulkUpdateBalances = createWiwAsyncThunk<DataResponse<BalancesResponse>, BulkUpdateBalancesArgs>(
  'timeOff/balances/bulkUpdateBalances',
  async ({ balances, params = {} }, { rejectWithValue }) => {
    try {
      return await Scheduling.query(params).url('/timeoff/balances/users').put(balances);
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
