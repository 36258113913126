import EntityState from 'data/EntityState';
import { persistOnboardingImport } from 'data/account/actions/persistOnboardingImport';
import { bulkPositions } from 'data/position/actions/bulkPositions';
import { createPosition } from 'data/position/actions/createPosition';
import { deletePosition, deletePositions } from 'data/position/actions/deletePosition';
import { fetchPositions } from 'data/position/actions/fetchPositions';
import { replacePositions } from 'data/position/actions/replacePositions';
import { sortPositions } from 'data/position/actions/sortPositions';
import { updatePosition } from 'data/position/actions/updatePosition';
import Position from 'data/position/model';
import generateThunkReducers from 'data/util/generateThunkReducers';
import { optimisticallyUpdateEntity } from 'data/util/optimisticUpdate';
import { mergeEntityItems, replaceEntityItems } from 'data/util/sliceHelpers';

import { createSlice } from '@reduxjs/toolkit';
import type { Map } from 'immutable';

const initialState = new EntityState<Position>();

export const positionSlice = createSlice({
  name: 'position',
  initialState,
  reducers: {
    receivePositions: (state, action) => {
      return mergeEntityItems(
        state,
        (action.payload.positions as Position[]).filter(position => !position.is_deleted),
        Position,
      );
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchPositions.fulfilled, (state, action) => {
        return mergeEntityItems(state, action.payload.positions, Position);
      })
      .addCase(sortPositions.fulfilled, (state, action) => {
        return mergeEntityItems(state, action.payload.positions, Position);
      })
      .addCase(bulkPositions.fulfilled, (state, action) => {
        return mergeEntityItems(state, action.payload.positions, Position);
      })
      .addCase(replacePositions.fulfilled, (state, action) => {
        return replaceEntityItems(state, action.payload.positions, Position);
      })
      .addCase(deletePosition.fulfilled, (state, action) => {
        return state.merge({
          items: state.items.delete(action.meta.arg.id),
        });
      })
      .addCase(deletePositions.fulfilled, (state, action) => {
        return state.merge({
          items: state.items.withMutations((maps: Map<number, Position>) => {
            action.meta.arg.map(position => {
              maps.delete(position.id);
            });
          }),
        });
      })
      .addCase(persistOnboardingImport.fulfilled, (state, action) => {
        return mergeEntityItems(state, action.payload.positions, Position);
      });
    optimisticallyUpdateEntity(builder, createPosition, Position, { payloadPath: 'position' });
    optimisticallyUpdateEntity(builder, updatePosition, Position, { payloadPath: 'position' });
    generateThunkReducers(builder, 'position');
  },
});

export default positionSlice.reducer;
