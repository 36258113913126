import { lazy } from 'react';

import { openDialog } from 'dialogs';

const LinkUsersDialog = lazy(() => import('./LinkUsersDialog'));
const WorkplaceAlertsDialog = lazy(() => import('./WorkplaceAlertsDialog'));
export const CHANGE_PASSWORD_DIALOG = 'CHANGE_PASSWORD_DIALOG';
export const LINK_USERS_DIALOG = 'LINK_USERS_DIALOG';
export const WORKPLACE_ALERTS_DIALOG = 'WORKPLACE_ALERTS_DIALOG';

export const openWorkplaceAlertsDialog = (payload, options = {}) =>
  openDialog(WORKPLACE_ALERTS_DIALOG, payload, { overlay: true, ...options });

export default {
  [LINK_USERS_DIALOG]: LinkUsersDialog,
  [WORKPLACE_ALERTS_DIALOG]: WorkplaceAlertsDialog,
};
