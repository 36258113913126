import BaseModel from 'data/BaseModel';

type ShiftWithoutTime = {
  id: number;
  user_id: number;
  start_time: string;
};

export interface PayrollDataFields {
  user_id: number;
  alert_type: number;
  incomplete_times: number;
  missing_times: number;
  time_entries: number;
  times_approved: number;
  shifts_missing_times: ShiftWithoutTime[];
}

class PayrollData extends BaseModel<PayrollDataFields>({
  user_id: 0,
  alert_type: 0,
  incomplete_times: 0,
  missing_times: 0,
  time_entries: 0,
  times_approved: 0,
  shifts_missing_times: [],
}) {}

export default PayrollData;
