type IndustryItem = {
  id: number;
  label: string;
  positions: string[];
};

export const guidedSteps = [
  {
    index: 1,
    target: 'positionsScreen',
    nameLong: 'selectPositions',
    nameShort: 'positions',
    title: 'Add Positions',
    description: 'Choose the positions you schedule in your workplace. You can always edit or add positions later.',
  },
  {
    index: 2,
    target: 'employeeScreen',
    nameLong: 'addEmployee',
    nameShort: 'users',
    title: 'Add Users',
    description:
      'Add and invite users to When I Work so you can start building the schedule and share it when you’re done.',
  },
  {
    index: 3,
    target: 'schedulerSetup',
    nameLong: 'setUpScheduler',
    nameShort: 'schedule',
    title: 'Scheduler Setup',
    question1: 'When does your work week start?',
    description1: "our schedule will start on this day and we'll use it to calculate weekly overtime.",
    question2: 'Create Shift Templates',
    description2: 'What are your commonly scheduled shifts? We will save these for you to use quickly again and again.',
  },
];

export const INDUSTRIES: IndustryItem[] = [
  {
    id: 38,
    label: 'Call Center / Customer Support',
    positions: ['Customer Service', 'Manager', 'Call Center', 'Sales', 'Supervisor'],
  },
  {
    id: 9,
    label: 'Education',
    positions: ['Manager', 'Teacher', 'Director', 'Customer Service', 'Supervisor'],
  },
  {
    id: 1,
    label: 'Food Services',
    positions: ['Manager', 'Server', 'Cook', 'Bartender', 'Cashier'],
  },
  {
    id: 58,
    label: 'Government',
    positions: ['Manager', 'Supervisor', 'Lifeguard', 'Training', 'Customer Service'],
  },
  {
    id: 16,
    label: 'Healthcare',
    positions: ['Manager', 'Nurse', 'RN', 'CNA', 'LPN'],
  },
  {
    id: 8,
    label: 'Hospitality / Entertainment',
    positions: ['Manager', 'Front Desk', 'Bartender', 'General Manager', 'Supervisor'],
  },
  {
    id: 59,
    label: 'Logistics',
    positions: ['Driver', 'Manager', 'Dispatcher', 'Warehouse', 'Delivery'],
  },
  {
    id: 60,
    label: 'Manufacturing',
    positions: ['Warehouse', 'Manager', 'Customer Service', 'Sales', 'Delivery'],
  },
  {
    id: 51,
    label: 'On-demand / Gig',
    positions: ['Manager', 'Driver', 'Delivery', 'Customer Service', 'Warehouse'],
  },
  {
    id: 28,
    label: 'Retail',
    positions: ['Manager', 'Sales Associate', 'Cashier', 'Assistant Manager', 'Store Manager'],
  },
  {
    id: 15,
    label: 'Services',
    positions: ['Manager', 'Customer Service', 'Owner', 'Sales', 'Front Desk'],
  },
  {
    id: 48,
    label: 'Technology',
    positions: ['Manager', 'Engineer', 'Customer Service', 'Sales', 'Technician'],
  },
  {
    id: 45,
    label: 'Something Else',
    positions: ['Manager', 'Customer Service', 'Owner', 'Supervisor', 'Driver'],
  },
];
export const INDUSTRIES_EXT: IndustryItem[] = [
  {
    id: 92,
    label: 'Transportation & Warehousing',
    positions: ['Driver', 'Manager', 'Dispatcher', 'Warehouse', 'Delivery'],
  },
  {
    id: 103,
    label: 'Construction & Trades',
    positions: ['Owner', 'Manager', 'Customer Service', 'Technician', 'Sales'],
  },
  {
    id: 23,
    label: 'Arts & Entertainment & Recreation',
    positions: ['Customer Service', 'Program Coordinator', 'Guest assistant', 'Trainer', 'Manager'],
  },
];
export const INDUSTRIES_FOCUSED_ONBOARDING: IndustryItem[] = [
  {
    id: 16,
    label: 'Healthcare / Medical',
    positions: ['Manager', 'Nurse', 'RN', 'CNA', 'LPN'],
  },
  {
    id: 1,
    label: 'Food & Beverage',
    positions: ['Manager', 'Server', 'Cook', 'Bartender', 'Cashier'],
  },
  {
    id: 8,
    label: 'Hotels & Hospitality',
    positions: ['Manager', 'Front Desk', 'Bartender', 'General Manager', 'Supervisor'],
  },
  {
    id: 28,
    label: 'Retail',
    positions: ['Manager', 'Sales Associate', 'Cashier', 'Assistant Manager', 'Store Manager'],
  },
  {
    id: 23,
    label: 'Arts & Entertainment & Recreation',
    positions: ['Customer Service', 'Program Coordinator', 'Guest assistant', 'Trainer', 'Manager'],
  },
  {
    id: 9,
    label: 'Education',
    positions: ['Manager', 'Teacher', 'Director', 'Customer Service', 'Supervisor'],
  },
  {
    id: 103,
    label: 'Construction & Trades',
    positions: ['Owner', 'Manager', 'Customer Service', 'Technician', 'Sales'],
  },
  {
    id: 60,
    label: 'Manufacturing',
    positions: ['Warehouse', 'Manager', 'Customer Service', 'Sales', 'Delivery'],
  },
  {
    id: 92,
    label: 'Transportation & Warehousing',
    positions: ['Driver', 'Manager', 'Dispatcher', 'Warehouse', 'Delivery'],
  },
  {
    id: 45,
    label: 'Other Industry',
    positions: ['Manager', 'Customer Service', 'Owner', 'Supervisor', 'Driver'],
  },
];
