import { pick } from 'lodash';
import moment from 'moment-timezone';

export function normalizeProjection(projection) {
  const date = moment(projection.day).format('YYYY-MM-DD');
  return pick({ ...projection, date }, ['id', 'date', 'estimate', 'target_percentage', 'location_id']);
}

export function normalizeProjections(projections) {
  return projections.map(projection => normalizeProjection(projection));
}
