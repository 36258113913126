import EntityBase from 'data/EntityBase';
import IndexedMap from 'data/IndexedMap';
import Shift from 'data/shift/model';

export const shiftIndices = [
  // Single property keys found via .findWhere('key', 'value')
  'location_id',
  'position_id',
  'site_id',
  'startDateKey',
  'user_id',
  'isTemporaryInstance',
  'shiftchain_key',
  // composite keys found via .findWhere(['key1', 'key2'], ['valueForKey1', 'valueForKey2'])
  ['location_id', 'published'],
  ['user_id', 'position_id'],
] as const;

export class ShiftState extends EntityBase({
  updating: 0,
  deleting: 0,
  items: new IndexedMap(Shift, 'id', shiftIndices),
}) {}

export default ShiftState;
