import type { PunchStateFields } from 'data/punchState/models/punchState';
import { PunchStateSchema } from 'data/punchState/schema';
import type { QueryString, TerminalAuth } from 'data/types';
import Monolith from 'shared/api/monolith';
import V3 from 'shared/api/v3';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type FetchPunchStateRequest = {
  deviceType: 'web' | 'terminal';
  params?: QueryString;
} & TerminalAuth;

export const fetchPunchState = (
  deviceType: 'web' | 'terminal' = 'web',
  userId?: string | number,
  token?: string,
  params: QueryString = {},
  apiVersion: 'v2' | 'v3' = 'v2',
) => {
  if (apiVersion === 'v3') {
    return fetchPunchStateV3({ deviceType, userId, token, params });
  }
  return fetchPunchStateV2({ deviceType, userId, token, params });
};

export const fetchPunchStateV2 = createWiwAsyncThunk<
  PunchStateFields,
  FetchPunchStateRequest,
  { fulfilledMeta: { userId: string | number | null } }
>(
  'punchState/fetch',
  async ({ deviceType, userId, token, params = {} }, { rejectWithValue, fulfillWithValue, getState }) => {
    const reqUserId = userId ?? getState().auth.userId;
    params = { userId: reqUserId, deviceType, ...params };

    let headers: HeadersInit = {};
    if (!!userId && !!token) {
      headers = {
        'W-Token': token,
        'W-UserId': userId.toString(),
        Authorization: `Bearer ${token}`,
      };
    }
    try {
      const result = await Monolith.url('/punch/state').query(params).headers(headers).get();
      const parsed = PunchStateSchema.parse(result);
      return fulfillWithValue(parsed, {
        userId: reqUserId,
      });
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);

export const fetchPunchStateV3 = createWiwAsyncThunk<
  PunchStateFields,
  FetchPunchStateRequest,
  { fulfilledMeta: { userId: string | number | null } }
>(
  'punchState/fetchV3',
  async ({ deviceType = 'web', userId, token, params = {} }, { rejectWithValue, fulfillWithValue, getState }) => {
    const reqUserId = userId ?? getState().auth.userId;
    params = { userId: reqUserId, deviceType, ...params };
    let headers: HeadersInit = {};
    if (!!userId && !!token) {
      headers = {
        'W-Token': token,
        'W-UserId': userId.toString(),
        Authorization: `Bearer ${token}`,
      };
    }
    try {
      const result = await V3.url('/punch/state').query(params).headers(headers).get();
      const parsed = PunchStateSchema.parse(result);
      return fulfillWithValue(parsed, {
        userId: reqUserId,
      });
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
