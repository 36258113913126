import { createSelector } from '@reduxjs/toolkit';

import type User from 'data/user/model';
import { getUsers } from 'data/user/selectors/getUsers';
import { Map } from 'immutable';
import type { WiwState } from 'store';

export const getStateWorkchat = (state: WiwState) => state.workchatv2;

export const getWorkchatUsersState = createSelector([getStateWorkchat], workchatState => workchatState.users);

export const getWorkchatUsers = createSelector([getUsers, getWorkchatUsersState], (users, workchatUsersState) => {
  let userMap = Map<number, User>();
  userMap = userMap.merge(users);

  const workchatAPIUsers = workchatUsersState.items;

  workchatAPIUsers.forEach((apiUser: User) => {
    if (!userMap.get(apiUser.id)) {
      userMap = userMap.set(apiUser.id, apiUser);
    }
  });

  return userMap;
});
