import BaseModel from 'data/BaseModel';
import StripeInvoice from 'data/stripe/invoices/model';
import type StripePrice from 'data/stripe/prices/model';
import type { StripeSubscription as StripeSubscriptionI } from 'data/stripe/types/subscriptions';

import type Stripe from 'stripe';

export interface StripeSubscriptionFields extends Partial<Omit<StripeSubscriptionI, 'latest_invoice'>> {
  payment_data: Pick<Stripe.PaymentMethod, 'id' | 'billing_details' | 'card'>;
  latest_invoice: StripeInvoice;
}

export default class StripeSubscription extends BaseModel<StripeSubscriptionFields>({
  id: '0',
  status: 'incomplete',
  discount: null,
  metadata: {} as StripeSubscriptionI['metadata'],
  items: {} as StripeSubscriptionI['items'],
  schedule: null,
  billing_cycle_anchor: 0,
  cancel_at: 0,
  current_period_start: 0,
  current_period_end: 0,
  days_until_due: 0,
  ended_at: 0,
  payment_data: {} as StripeSubscriptionFields['payment_data'],
  latest_invoice: new StripeInvoice({}),
  pending_setup_intent: '',
  test_clock: null,
}) {
  constructor(args: Partial<StripeSubscriptionFields>) {
    super({ ...args, latest_invoice: new StripeInvoice(args.latest_invoice!) });
  }

  // get subscription line items
  getLineItems() {
    return this.items!.data;
  }

  getLineItemByPriceId($priceId: StripePrice['id']) {
    return this.getLineItems().find(lineItem => lineItem.price.id === $priceId);
  }

  hasPriceId($priceId: StripePrice['id']) {
    return !!this.getLineItemByPriceId($priceId);
  }

  getLineItemBySubscriptionLineID($sid: Stripe.SubscriptionItem['id']) {
    return this.getLineItems().find(lineItem => lineItem.id === $sid);
  }

  isTrial() {
    return this.status === 'trialing';
  }

  isExpiredTrial() {
    return this.status === 'incomplete_expired';
  }

  isUnpaid() {
    return this.status === 'unpaid';
  }

  isExpired() {
    return ['incomplete_expired', 'unpaid', 'canceled'].includes(this.status!);
  }

  getTotal() {
    return this.latest_invoice.getTotal();
  }

  getAmountDue() {
    return this.latest_invoice.getAmountDue();
  }

  hasPromoCode() {
    return !!this.discount?.promotion_code;
  }
  hasCoupon() {
    return !!this.discount?.coupon;
  }
}
