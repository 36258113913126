import { Fragment } from 'react';

import { MenuItem } from 'shared/components/nav/MenuItem';

type SchedulingMenuItemProps = {
  workChatFree?: boolean;
  schedulerTrial?: boolean;
  isEmployee?: boolean;
  showTeamSchedule?: boolean | undefined;
  defaultMenuItem?: boolean | undefined;
};

const SchedulingMenuItem = ({
  workChatFree,
  schedulerTrial,
  isEmployee,
  showTeamSchedule,
  defaultMenuItem,
}: SchedulingMenuItemProps) => (
  <Fragment>
    {workChatFree && <MenuItem icon="schedule" isMainNav label="Schedule" to="/scheduler" />}
    {schedulerTrial && (
      <MenuItem className="disabled upsell" icon="schedule" isMainNav label="Scheduling" to="/#scheduler-trial" />
    )}
    {isEmployee && <MenuItem icon="my-schedule" hideLabel="tablet" isMainNav label="My Schedule" to="/myschedule" />}
    {showTeamSchedule && typeof defaultMenuItem === 'undefined' && (
      <MenuItem icon="schedule" hideLabel="tablet" isMainNav label="Team Schedule" to="/scheduler" />
    )}
    {defaultMenuItem && typeof showTeamSchedule === 'undefined' && (
      <MenuItem icon="schedule" isMainNav hideLabel="tablet" label="Scheduler" to="/scheduler" />
    )}
  </Fragment>
);

export default SchedulingMenuItem;
