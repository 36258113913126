import BaseModel from 'data/BaseModel';

export interface SiteFields {
  id: number;
  account_id: number;
  location_id: number;
  name: string;
  color: string;
  sort: number;
  description: string;
  address: string;
  postal_code: string;
  latitude: number | null;
  longitude: number | null;
  coordinates: [number, number] | [];
  place_id: string;
  is_deleted: boolean;
  deleted_at: string | null;
  created_at: string;
  updated_at: string;
}

class Site extends BaseModel<SiteFields>({
  id: 0,
  account_id: 0,
  location_id: 0,
  name: '',
  color: 'cccccc',
  sort: 0,
  description: '',
  address: '',
  postal_code: '',
  latitude: null,
  longitude: null,
  coordinates: [],
  place_id: '',
  is_deleted: false,
  deleted_at: null,
  created_at: '',
  updated_at: '',
}) {
  atLocation(locationID: number) {
    return !this.location_id || this.location_id === locationID;
  }
}

export default Site;
