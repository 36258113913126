import { Fragment } from 'react';

import { roundToNearestHour as luxonRoundToNearestHour } from 'shared/util/luxonTime';
import { roundToNearestHour } from 'shared/util/time';
import Time from './Time';

import { DateTime } from 'luxon';
import moment, { type Moment } from 'moment-timezone';

type DateLike = Date | Moment | DateTime;
type TimeRangeProps = {
  start: DateLike | null;
  end: DateLike | null;
  long?: boolean;
  medium?: boolean;
  isOvernight?: boolean;
  roundToHour?: boolean;
  showWeekDay?: boolean;
};

export default function TimeRange({ start, end, long, medium, isOvernight, roundToHour, showWeekDay }: TimeRangeProps) {
  let endTime = end;
  if (roundToHour && DateTime.isDateTime(end)) {
    endTime = luxonRoundToNearestHour(end);
  }

  if (roundToHour && moment.isMoment(end)) {
    endTime = roundToNearestHour(end);
  }

  return (
    <Fragment>
      <Time date={start} long={long} medium={medium} displayWeekDay={showWeekDay} />
      &nbsp;{isOvernight ? '~' : '–'}&nbsp;
      <Time date={endTime} long={long} medium={medium} />
    </Fragment>
  );
}
