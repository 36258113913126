import {
  ADD_NEW_TIMESHEETS_ENTRY,
  CLOSE_ADDITEM_POPOVER,
  CLOSE_DETAILS_MENU_POPOVER,
  CLOSE_NOTES_POPOVER,
  LEAVE_APPROVAL_MODE,
  OPEN_ADDITEM_POPOVER,
  OPEN_DETAILS_MENU_POPOVER,
  OPEN_NOTES_POPOVER,
  SET_ACCEPTED_MISSING_ENTRIES,
  SET_CURRENT_PAYROLL,
  SET_NEW_TIMESHEETS_ENTRY_PENDING,
  SET_PAYROLL_LOCATION,
  SET_PAYROLL_USER,
  SET_TIMESHEET_VIEW,
  TIMESHEET_APPROVAL_MODE,
  TOGGLE_FLAG_USERS,
  TOGGLE_SHOW_SHIFTS,
} from 'store/action-types';
import { getInitialLocation, getInitialPayroll, getInitialPayrollUser } from 'timesheets/actions';
import { getAcceptedMissingEntries } from 'timesheets/actions/acceptedMissingEntries';
import { GRID_VIEW } from 'timesheets/util/view';

import { Map } from 'immutable';

const currentPayroll = getInitialPayroll();

export const initialState = new Map({
  currentDate: null,
  currentUser: getInitialPayrollUser(),
  currentPayroll: currentPayroll,
  currentLocation: getInitialLocation(),
  showShifts: false,
  popovers: new Map({
    addItem: new Map({
      open: false,
      target: 'body',
      date: null,
      itemCount: 0,
      isFutureTimeAllowed: false,
    }),
    detailsMenu: new Map({
      open: false,
      target: null,
      data: {},
    }),
    notes: new Map({
      open: false,
      target: null,
      data: {},
    }),
  }),
  newEntryRow: '',
  newEntryPending: false,
  flagUsers: false,
  acceptedMissingEntries: getAcceptedMissingEntries(currentPayroll),
  view: GRID_VIEW,
  isApproving: false,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case TOGGLE_SHOW_SHIFTS:
      return state.set('showShifts', !state.get('showShifts'));
    case TOGGLE_FLAG_USERS:
      return state.set('flagUsers', !state.get('flagUsers'));
    case SET_CURRENT_PAYROLL:
      return state.set('currentPayroll', action.payload);
    case SET_PAYROLL_LOCATION:
      return state.set('currentLocation', action.payload);
    case SET_PAYROLL_USER:
      return state.set('currentUser', action.payload);
    case OPEN_ADDITEM_POPOVER:
      return state.updateIn(['popovers', 'addItem'], value => {
        return new Map({
          open: true,
          target: action.target,
          date: action.date,
          itemCount: action.itemCount,
          isFutureTimeAllowed: action.isFutureTimeAllowed,
        });
      });
    case CLOSE_ADDITEM_POPOVER:
      return state.updateIn(['popovers', 'addItem'], value => {
        return new Map({
          open: false,
          target: 'body',
        });
      });
    case CLOSE_DETAILS_MENU_POPOVER:
      return state.updateIn(['popovers', 'detailsMenu'], value => {
        return new Map({
          open: false,
          target: 'body',
        });
      });
    case OPEN_DETAILS_MENU_POPOVER:
      return state.updateIn(['popovers', 'detailsMenu'], value => {
        return new Map({
          open: true,
          target: action.target,
          data: action.data,
        });
      });
    case CLOSE_NOTES_POPOVER:
      return state.updateIn(['popovers', 'notes'], value => {
        return new Map({
          open: false,
          target: 'body',
        });
      });
    case OPEN_NOTES_POPOVER:
      return state.updateIn(['popovers', 'notes'], value => {
        return new Map({
          open: true,
          target: action.target,
          data: action.data,
        });
      });
    case SET_ACCEPTED_MISSING_ENTRIES:
      return state.set('acceptedMissingEntries', action.payload);
    case SET_TIMESHEET_VIEW:
      return state.set('view', action.payload);
    case ADD_NEW_TIMESHEETS_ENTRY:
      return state.set('newEntryRow', action.payload);
    case SET_NEW_TIMESHEETS_ENTRY_PENDING:
      return state.set('newEntryPending', action.payload);
    case TIMESHEET_APPROVAL_MODE:
      return state.set('isApproving', true);
    case LEAVE_APPROVAL_MODE:
      return state.set('isApproving', false);
    default:
      return state;
  }
}
