import shifts from './reducers/shiftTagReducer';
import shiftTemplates from './reducers/shiftTemplateTagReducer';
import tags from './reducers/tagReducer';
import users from './reducers/userTagReducer';

import { combineReducers } from '@reduxjs/toolkit';

export default combineReducers({
  tags,
  shifts,
  shiftTemplates,
  users,
});
