import type BenefitRecord from 'data/payrollBenefits/model';
import type { BenefitItem } from 'data/payrollBenefits/model';
import Payroll from 'shared/api/payroll';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';

import { createAsyncThunk } from '@reduxjs/toolkit';

type CreateBenefitResponse = {
  benefits: BenefitItem;
};

export type CreateBenefitArgs = {
  benefit: BenefitRecord;
  user_id: number;
};

export const createPayrollBenefit = createAsyncThunk<CreateBenefitResponse, CreateBenefitArgs>(
  'benefits/create',
  async (params, { rejectWithValue }) => {
    try {
      return (await Payroll.url('/benefits')
        .query({ user_id: params.user_id })
        .post(params.benefit.toJSON())) as CreateBenefitResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
