import EntityBase from 'data/EntityBase';
import type StripeInvoice from 'data/stripe/invoices/model';

import { Map } from 'immutable';

export class StripeInvoicesState extends EntityBase({
  items: Map<StripeInvoice['id'], StripeInvoice>(),
  loading: false,
  loaded: false,
  receivedAt: 0,
  error: null,
}) {
  // nothing
}
