import { Map, Record } from 'immutable';

export interface IndustryFields {
  id: number | null;
  name: string | null;
  parent: number;
  sort: number;
  sic_code: string | null;
  naics_code: string | null;
  children: IndustryFields[] | Map<number, Industry>;
}

export default class Industry extends Record<IndustryFields>({
  id: null,
  name: null,
  parent: 0,
  sort: 0,
  sic_code: null,
  naics_code: null,
  children: [],
}) {
  constructor({ children, ...fields }: Partial<IndustryFields> = {}) {
    if (children && Array.isArray(children) && children.length) {
      children = Map(children.map((industry: IndustryFields) => [industry.id, new Industry(industry)]));
    }

    super({ children, ...fields });
  }
}
