const getLocale = () => {
  if (navigator.languages) {
    return navigator.languages[0];
  }
  return navigator.userLanguage || navigator.language;
};

/**
 * Note: Converts a moment to a JS date and will discard custom user
 *       or account timezone settings in favor of the browsers.
 **/
export const formatTime = time => {
  const display = time.toDate().toLocaleString(getLocale(), { hour: 'numeric', minute: '2-digit' }).toLowerCase();

  return display.replace(/(:00\s?(.*)m|m| )+/g, '$2');
};

/**
 * Note: Converts a moment to a JS date and will discard custom user
 *       or account timezone settings in favor of the browsers.
 **/
export const formatTimeLong = time => {
  return time.toDate().toLocaleString(getLocale(), { hour: 'numeric', minute: '2-digit' }).replace(/\s/, '');
};

export const formatUserTime = (time, short = true) => {
  if (short && !time.minutes()) {
    return time.format('ha').slice(0, -1);
  }
  return time.format('h:mma').slice(0, -1);
};

export const shortTimeRange = (start, end) => {
  return { startString: formatTime(start), endString: formatTime(end) };
};

export const roundToDigits = (num, digits) => {
  const mult = 10 ** digits;
  return Math.round(num * mult) / mult;
};
