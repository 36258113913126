import { closeDialog, openDialog } from 'dialogs/actions';

export const FUTURE_UPGRADE = 'FUTURE_UPGRADE';
export const openFutureUpgradeDialog = originalDate => openDialog(FUTURE_UPGRADE, { originalDate });
export const closeFutureUpgradeDialog = closeDialog.bind(null, FUTURE_UPGRADE);

export const MONTH_VIEW_UPGRADE = 'MONTH_VIEW_UPGRADE';
export const openMonthViewUpgradeDialog = openDialog.bind(null, MONTH_VIEW_UPGRADE);
export const closeMonthViewUpgradeDialog = closeDialog.bind(null, MONTH_VIEW_UPGRADE);

export const MULTIPLE_SCHEDULES_UPGRADE_DIALOG = 'MULTIPLE_SCHEDULES_UPGRADE_DIALOG';

export const JOB_SITES_UPGRADE = 'JOB_SITES_UPGRADE';
export const openJobSitesUpgradeDialog = openDialog.bind(null, JOB_SITES_UPGRADE);

export const HISTORY_UPGRADE = 'HISTORY_UPGRADE';
export const openHistoryUpgradeDialog = originalDate => openDialog(HISTORY_UPGRADE, { originalDate });
export const closeHistoryUpgradeDialog = closeDialog.bind(null, HISTORY_UPGRADE);
export const UPGRADE_POPOVER = 'UPGRADE_POPOVER';
export const openUpgradePopover = payload => openDialog(UPGRADE_POPOVER, payload, { style: 'popover' });
export const closeUpgradePopover = closeDialog.bind(null, UPGRADE_POPOVER);

export const AUTO_ASSIGN_UPGRADE = 'AUTO_ASSIGN_UPGRADE';

export const BULK_EDIT_UPGRADE = 'BULK_EDIT_UPGRADE';

export const PAYROLL_INTEGRATIONS_UPGRADE_DIALOG = 'PAYROLL_INTEGRATIONS_UPGRADE_DIALOG';
export const openPayrollIntegrationsUpgradeDialog = integrationRequested =>
  openDialog(PAYROLL_INTEGRATIONS_UPGRADE_DIALOG, { integrationRequested });
export const closePayrollIntegrationsUpgradeDialog = () => closeDialog(PAYROLL_INTEGRATIONS_UPGRADE_DIALOG);

export const DOC_STORAGE_UPGRADE_DIALOG = 'DOC_STORAGE_UPGRADE_DIALOG';
export const openDocStorageUpgradeDialog = () => openDialog(DOC_STORAGE_UPGRADE_DIALOG);

export const THIRTY_DAY_FREE_TRIAL_DIALOG = 'THIRTY_DAY_FREE_TRIAL_DIALOG';
export const openThirtyDayFreeTrialDialog = payload => openDialog(THIRTY_DAY_FREE_TRIAL_DIALOG, payload);

export const SCHEDULING_RULES_UPGRADE_DIALOG = 'SCHEDULING_RULES_UPGRADE_DIALOG';

export const SHIFT_RELEASING_UPGRADE_DIALOG = 'SHIFT_RELEASING_UPGRADE_DIALOG';

export const SWAP_DROP_RESTRICTIONS_UPGRADE_DIALOG = 'SWAP_DROP_RESTRICTIONS_UPGRADE_DIALOG';

export const OPENSHIFT_APPROVAL_UPGRADE_DIALOG = 'OPENSHIFT_APPROVAL_UPGRADE_DIALOG';

export const ABSENCES_UPGRADE_DIALOG = 'ABSENCES_UPGRADE_DIALOG';

export const CUSTOM_TIME_OFF_UPGRADE_DIALOG = 'CUSTOM_TIME_OFF_UPGRADE_DIALOG';

export const FORECAST_TOOLS_UPGRADE_DIALOG = 'FORECAST_TOOLS_UPGRADE_DIALOG';

export const MAX_HOURS_UPGRADE_DIALOG = 'MAX_HOURS_UPGRADE_DIALOG';

export const REPEAT_SHIFTS_UPGRADE_DIALOG = 'REPEAT_SHIFTS_UPGRADE_DIALOG';

export const SCHEDULE_TEMPLATE_UPGRADE_DIALOG = 'SCHEDULE_TEMPLATE_UPGRADE_DIALOG';

export const SHIFT_HISTORY_UPGRADE_DIALOG = 'SHIFT_HISTORY_UPGRADE_DIALOG';

export const LABOR_SHARE_UPGRADE_DIALOG = 'LABOR_SHARE_UPGRADE_DIALOG';

export const SHIFT_COLORS_UPGRADE_DIALOG = 'SHIFT_COLORS_UPGRADE_DIALOG';

export const EXTENDED_VIEW_UPGRADE_DIALOG = 'EXTENDED_VIEW_UPGRADE_DIALOG';

export const TASKS_UPGRADE_DIALOG = 'TASKS_UPGRADE_DIALOG';

export const TAGS_UPGRADE_DIALOG = 'TAGS_UPGRADE_DIALOG';

export const MANAGER_PERMS_UPGRADE_DIALOG = 'MANAGER_PERMS_UPGRADE_DIALOG';

export const SUPERVISOR_PERMS_UPGRADE_DIALOG = 'SUPERVISOR_PERMS_UPGRADE_DIALOG';

export const USER_PRIVACY_UPGRADE_DIALOG = 'USER_PRIVACY_UPGRADE_DIALOG';

export const EXPORTS_UPGRADE_DIALOG = 'EXPORTS_UPGRADE_DIALOG';

export const OVERLAPPING_OPENSHIFTS_UPGRADE_DIALOG = 'OVERLAPPING_OPENSHIFTS_UPGRADE_DIALOG';
