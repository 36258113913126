import { type Ref, forwardRef } from 'react';
import { type FieldValues, type UseControllerProps, useController } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import type { NumericFormatProps } from 'react-number-format/types/types';

import getFieldError from 'shared/form/helpers/getFieldError';
import useMapCustomValidators from 'shared/form/hooks/useMapCustomValidators';
import type { InputProps } from 'shared/form/types';

import { Input } from '@mantine/core';
import type { TextInputProps as MantineTextInputProps } from '@mantine/core';
import { useMergedRef } from '@mantine/hooks';

interface MaskedNumberInputProps<T extends FieldValues>
  extends InputProps<UseControllerProps<T>>,
    Omit<MantineTextInputProps, 'name' | 'value' | 'defaultValue' | 'onChange'> {
  decimalScale?: number;
  thousandSeparator?: string;
  prefix?: string;
  numericFormatProps?: NumericFormatProps;
}

const MaskedNumberInput = forwardRef(function MaskedNumberInput<T extends FieldValues>(
  { name, control, defaultValue, shouldUnregister, onChange, numericFormatProps, ...props }: MaskedNumberInputProps<T>,
  ref: Ref<HTMLInputElement>,
) {
  const rules = useMapCustomValidators(props, true);

  const {
    field: { value, onChange: fieldOnChange, ref: insideRef, ...field },
    fieldState,
  } = useController<T>({
    name,
    control,
    defaultValue,
    rules,
    shouldUnregister,
  });
  const mergedRef = useMergedRef(ref, insideRef);

  return (
    // @ts-ignore
    <Input.Wrapper {...props} error={getFieldError(fieldState)}>
      <NumericFormat
        {...field}
        {...numericFormatProps}
        value={value}
        getInputRef={mergedRef}
        data-testid={name}
        // @ts-ignore
        customInput={Input}
        onValueChange={values => {
          fieldOnChange(values.value);
          onChange?.(name, values.value);
        }}
      />
    </Input.Wrapper>
  );
});

export default MaskedNumberInput;
