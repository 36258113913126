import { lazy } from 'react';

const EmployeeImportDialog = lazy(() => import('./EmployeeImport'));
const ShiftTemplateImportDialog = lazy(() => import('./ShiftTemplateImport'));

// Identifiers for each dialog
export const EMPLOYEE_IMPORT_DIALOG = 'EMPLOYEE_IMPORT_DIALOG';
export const SHIFT_TEMPLATE_IMPORT_DIALOG = 'SHIFT_TEMPLATE_IMPORT_DIALOG';

const dialogs = {
  [EMPLOYEE_IMPORT_DIALOG]: EmployeeImportDialog,
  [SHIFT_TEMPLATE_IMPORT_DIALOG]: ShiftTemplateImportDialog,
  // ...
};

export default dialogs;

/* To open a dialog, do this
import { openDialog, closeDialog } from 'dialogs';
import { SHIFT_TEMPLATE_DIALOG } from 'templates/dialogs';
*/
