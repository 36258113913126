import EntityBase from 'data/EntityBase';
import type StripeSubscription from 'data/stripe/subscriptions/model';

import { Map } from 'immutable';

export class StripeSubscriptionsState extends EntityBase({
  items: Map<StripeSubscription['id'], StripeSubscription>(),
  loading: false,
  loaded: false,
  receivedAt: 0,
  error: null,
}) {
  // nothing
}
