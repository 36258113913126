import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import classnames from 'classnames';

class PanelTitle extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    size: PropTypes.string,
    bottomBorder: PropTypes.bool,
  };

  static defaultProps = {
    bottomBorder: false,
  };

  render() {
    const { children, className, size, bottomBorder } = this.props;
    const classes = classnames('panel-title', className, {
      [`title-${size}`]: !!size,
      bottomBorder: bottomBorder,
    });
    return <div className={classes}>{children}</div>;
  }
}

export default PanelTitle;
