import type Location from 'data/location/model';
import type { LocationFields } from 'data/location/model';
import type { EntityMap } from 'data/types';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type SortLocationsResponse = {
  locations: LocationFields[];
};

export const sortLocations = createWiwAsyncThunk<SortLocationsResponse, EntityMap<Location>>(
  'location/sort',
  async (locations, { rejectWithValue }) => {
    try {
      return (await Monolith.url('/locations/sort').post({
        ids: locations
          .sortBy(location => location.sort)
          .keySeq()
          .toArray(),
      })) as SortLocationsResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
