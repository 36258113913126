import EntityState from 'data/EntityState';
import PayrollHours from 'data/payrollHours/model';
import { toEntityMap } from 'shared/util/toEntityMap';
import { FETCH_PAYROLL_HOURS } from 'store/action-types';

export default (state = new EntityState(), action) => {
  switch (action.type) {
    case FETCH_PAYROLL_HOURS.REQUEST:
      return state.merge({
        loading: true,
        loaded: false,
        receivedAt: 0,
      });
    case FETCH_PAYROLL_HOURS.SUCCESS:
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        items: state.items.clear().merge(toEntityMap(action.payload.payrollhours, PayrollHours)),
        receivedAt: Date.now(),
      });
    case FETCH_PAYROLL_HOURS.FAILURE:
      return state.merge({
        loaded: false,
        loading: false,
        error: action.payload,
      });
    default:
      return state;
  }
};
