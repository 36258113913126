import PropTypes from 'prop-types';
import { PureComponent, createRef, forwardRef } from 'react';
import VirtualList from 'react-tiny-virtual-list';

import withScrolling, { createVerticalStrength } from 'shared/util/react-dnd-scrollzone';

import { debounce, omit } from 'lodash';
import memoizeOne from 'memoize-one';

const SCROLL_DEBOUNCE_MS = 200;

// drag and drop scrolling will only occur 50px from the top and bottom
const vStrength = createVerticalStrength(50);

const ListWithScrolling = withScrolling(VirtualList);

export class ScrollingVirtualList extends PureComponent {
  static propTypes = {
    onScroll: PropTypes.func,
    onScrollbarPresenceChange: PropTypes.func,

    onScrollStart: PropTypes.func,
    onScrollEnd: PropTypes.func,
    forwardedRef: PropTypes.any,

    itemSize: PropTypes.oneOfType([PropTypes.number, PropTypes.array, PropTypes.func]).isRequired,
    renderItem: PropTypes.func.isRequired,
    canScroll: PropTypes.bool,
  };

  static defaultProps = {
    canScroll: true,
  };

  state = {
    isScrolling: false,
  };

  myRef = createRef();

  getRef = () => this.props.forwardedRef || this.myRef;

  startScroll = debounce(
    () => {
      this.setState({ isScrolling: true });

      if (this.props.onScrollStart) {
        this.props.onScrollStart();
      }
    },
    SCROLL_DEBOUNCE_MS,
    { leading: true, trailing: false },
  );

  endScroll = debounce(() => {
    this.setState({ isScrolling: false });

    if (this.props.onScrollEnd) {
      this.props.onScrollEnd();
    }
  }, SCROLL_DEBOUNCE_MS);

  handleScroll = (scrollTop, event) => {
    this.startScroll();
    this.endScroll();

    if (this.props.onScroll) {
      this.props.onScroll(scrollTop, event);
    }
  };

  scrollbarData = {
    horizontal: false,
    vertical: false,
    size: 0,
  };

  updateScrollbarData = () => {
    if (!this.props.onScrollbarPresenceChange || !this.props.canScroll) {
      return;
    }

    if (this.getRef().current) {
      const domList = this.getRef().current.container;
      const scrollbarSize = domList.offsetWidth - domList.clientWidth;

      if (this.scrollbarData.size !== scrollbarSize) {
        this.scrollbarData = {
          horizontal: false,
          vertical: !!scrollbarSize,
          size: scrollbarSize,
        };

        this.props.onScrollbarPresenceChange(this.scrollbarData);
      }
    }
  };

  getItemSizeFunc = itemSize => {
    switch (typeof itemSize) {
      case 'function':
        return itemSize;
      case 'object': // actually array, but you know
        return index => itemSize[index];
      default:
        return () => itemSize;
    }
  };

  // Weirdly, it's important that the itemSize and renderItem functions actually change
  // when the props they are based on change. That's because VirtualList is a PureComponent
  // and sometimes changing functions is the only thing that will trigger an update.

  itemSizeMemo = memoizeOne(itemSize => {
    const itemSizeFunc = this.getItemSizeFunc(itemSize);
    return index => itemSizeFunc(index, { isScrolling: this.state.isScrolling });
  });
  itemSize = () => this.itemSizeMemo(this.props.itemSize);

  renderItemMemo = memoizeOne(renderItemFunc => opts => renderItemFunc(opts, { isScrolling: this.state.isScrolling }));
  renderItem = () => this.renderItemMemo(this.props.renderItem);

  componentDidUpdate() {
    this.updateScrollbarData();
  }

  render() {
    const passthroughProps = omit(this.props, [
      'onScrollbarPresenceChange',
      'onScrollStart',
      'onScrollEnd',
      'forwardedRef',
      'canScroll',
    ]);
    if (this.props.canScroll) {
      passthroughProps.verticalStrength = vStrength;
    }
    const List = this.props.canScroll ? ListWithScrolling : VirtualList;
    return (
      <List
        ref={this.getRef()}
        {...passthroughProps}
        data-is-scrolling={this.state.isScrolling}
        onScroll={this.handleScroll}
        itemSize={this.itemSize()}
        renderItem={this.renderItem()}
      />
    );
  }
}

export default forwardRef((props, ref) => <ScrollingVirtualList {...props} forwardedRef={ref} />);
