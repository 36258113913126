import type Account from 'data/account/model';
import type Shift from 'data/shift/model';
import { memoize } from 'lodash';
import { DateTime, Interval } from 'luxon';
import moment from 'moment-timezone';

/**
 * Replacement for our moment memoizedParse
 * Gets a luxon instance for whatever you throw at it. It uses chrono to parse strings.
 *
 * @param datetime
 * @returns DateTime
 */
const memoizedParse = memoize(datetime => {
  if (typeof datetime === 'string') {
    const parseDate = DateTime.fromISO(datetime);

    if (parseDate.isValid) {
      return parseDate;
    }

    return DateTime.fromRFC2822(datetime);
  }
  return DateTime.fromISO(datetime);
});

/**
 * @param datetime
 * @return DateTime
 */
export function getDate(datetime: any) {
  if (!datetime) {
    return DateTime.local();
  }

  if (isLuxonInstance(datetime)) {
    return datetime;
  }

  if (isMomentInstance(datetime)) {
    return memoizedParse(datetime.toISOString());
  }

  return memoizedParse(datetime);
}

function isLuxonInstance(datetime: any): datetime is DateTime {
  return datetime instanceof DateTime;
}

function isMomentInstance(datetime: any): datetime is moment.Moment {
  return datetime instanceof moment;
}

export function accountStartTime(account: Account) {
  const workdayStart = account.getSettings('payroll.work_day_start') || '00:00';
  const [hour, minute] = workdayStart.split(':');

  return DateTime.fromObject(
    {
      hour: Number.parseInt(hour, 10),
      minute: Number.parseInt(minute, 10),
      second: 0,
    },
    {
      zone: account.timezone_name,
    },
  );
}

export function crossesWorkday(shift: Shift, account?: Account) {
  const shiftStart = shift.mustLuxonDate('start_time');
  const shiftEnd = shift.mustLuxonDate('end_time');
  // @ts-ignore
  const startTime = account ? accountStartTime(account).fromObject({ day: shiftEnd.weekday }) : shiftStart.endOf('day');

  return isBetween(shiftStart, shiftEnd, startTime);
}

export function roundToNearestHour(time: DateTime) {
  if (time.minute === 59) {
    return time.plus({ hours: 1 }).startOf('hour');
  }

  return time;
}

/**
 * Luxon does not have a nice isBetween like moment() this is to help lessen the confusion
 *
 * @param start
 * @param end
 * @param contains
 * @returns bool
 */
export function isBetween(start: DateTime, end: DateTime, contains: DateTime) {
  return Interval.fromDateTimes(start, end).contains(contains);
}

/**
 * Builds updated start and end luxon times from moment instances
 */
export function setLuxonTimeRange(baseDate: DateTime, timeRange: { start: string; end: string }) {
  const updateDateTime = (baseDate: DateTime, timeString: string): DateTime => {
    const parsedHours = parseHoursInput(timeString);
    if (!parsedHours) {
      return baseDate;
    }
    const { hour, minute, second } = parsedHours;
    return baseDate.set({ hour, minute, second });
  };

  const startDate = updateDateTime(baseDate, timeRange.start);
  let endDate = updateDateTime(baseDate, timeRange.end);

  if (endDate <= startDate) {
    // Recreate the endDate using the startDate's day value +1, to avoid carrying forward any DST-boundary artifacts
    endDate = updateDateTime(baseDate.plus({ days: 1 }), timeRange.end);
  }

  return { start: startDate, end: endDate };
}

const isValid24HourFormat = (input: string): boolean => {
  const regex = /^([01]\d|2[0-3]):([0-5]\d)(:([0-5]\d))?$/;
  return regex.test(input);
};

const memoizedParseHoursInput = memoize(hoursInput => {
  //Luxons fromFormat cannot handle a luxon instance so pass it as a string
  if (isLuxonInstance(hoursInput)) {
    hoursInput = hoursInput.toISO();
  }
  // it also cannot handle moment instances
  if (isMomentInstance(hoursInput)) {
    hoursInput = getDate(hoursInput).toISO();
  }

  // if hours input is null return null
  if (!hoursInput) {
    return null;
  }

  // check if the input is a valid 24 hour format which comes from our TimeInputs
  if (isValid24HourFormat(hoursInput)) {
    const formats = ['HH:mm', 'HH:mm:ss'];

    let parseDate = DateTime.local();
    for (const format of formats) {
      const tempDate = DateTime.fromFormat(hoursInput, format);
      if (tempDate.isValid) {
        parseDate = tempDate;
        break;
      }
    }
    return parseDate;
  }

  // remove extra whitespace and non digit characters (except am/pm) and convert a/p to am/pm
  // because luxon is v strict with parsing and always parses in EN-US
  if (typeof hoursInput === 'string') {
    hoursInput = hoursInput
      .replace(/[^apm\d]/gi, '')
      .replace(/a(?![m.])/gi, 'am')
      .replace(/p(?![m.])/gi, 'pm');
  }

  const formats = [
    'h:mma',
    'hh:mm',
    'HH:mm',
    'hmma',
    'HHmm',
    'hhmm',
    'H:mm',
    'hha',
    'hmm',
    'h:mm',
    'Hmm',
    'HH',
    'ha',
    'H',
    'h',
  ];

  let parseDate = DateTime.local();
  for (const format of formats) {
    const tempDate = DateTime.fromFormat(hoursInput, format);
    if (tempDate.isValid) {
      parseDate = tempDate;
      break;
    }
  }
  return parseDate;
});

export function parseHoursInput(hoursInput: string | DateTime | moment.Moment): DateTime | null {
  return memoizedParseHoursInput(hoursInput);
}
