import { fetchAccounts } from 'data/account/actions/fetchAccounts';
import { fetchCurrentAccount } from 'data/account/actions/fetchCurrentAccount';
import { authSlice } from 'data/auth/reducer';
import fetchLogin from 'data/login/actions/fetchLogin';
import { fetchAccountMetadataIfNeeded, fetchUserMetadataIfNeeded } from 'data/metadata/actions';
import fetchPerson from 'data/person/actions/fetchPerson';
import fetchPlans from 'data/plan/actions/fetchPlans';
import { fetchUsers } from 'data/user/actions/fetchAllUsers';
import { replaceUsers } from 'data/user/actions/replaceUsers';
import { migrateSchedulerOnboardingLocalStorage } from 'onboarding/actions';
import { getUserId, removeStepUpToken, removeToken, removeUserId } from 'shared/auth/auth';
import refreshToken from 'shared/auth/refreshToken';
import PhoneUtil from 'shared/util/phoneutil';
import { setGlobalTimezone } from 'shared/util/time';
import { SEEN_VERIFY_ACCOUNT_LOCATION_DIALOG_SESSION_KEY, removeUserItem } from 'shared/util/userSessionStorage';
import Identify from 'shared/vendor/identify';
import GoogleTagManagerHelper from 'shared/vendor/util/googleTagManager';
import type { WiwDispatch, WiwState } from 'store';
import { LOGOUT, type RECEIVE_AUTH } from 'store/action-types';

import type { CountryCode } from 'libphonenumber-js';
import posthog from 'posthog-js';

export interface ReceiveAuth {
  type: typeof RECEIVE_AUTH;
  payload: {
    loginId: number;
    userId: number;
    accountId: number;
  };
}

export interface Logout {
  type: typeof LOGOUT;
}

export type AuthAction = ReceiveAuth | Logout;

function receiveAuthFailed(forceLogoutRedirect = false) {
  return (dispatch: WiwDispatch) => dispatch(logout(forceLogoutRedirect));
}

export function receiveAuth() {
  return (dispatch: WiwDispatch, getState: () => WiwState) => {
    const { data } = getState();

    const login = data.login.items.first();
    const loginId = login ? login.id : null;

    const userId = getUserId();

    if (Number.isNaN(userId)) {
      return dispatch(receiveAuthFailed());
    }
    // fetchBasicData should ensure that these are set
    const user = data.user.items.get(userId)!;
    const account = data.account.items.get(user.account_id)!;
    const planId = account.isChild() ? account.master_plan_id : account.plan_id;
    const plan = data.plan.items.get(planId);
    const accountMetadata = data.metadata?.account;
    const userMetadata = data.metadata?.user;

    Identify.identifyThirdParty(user, account, plan, login, dispatch, accountMetadata, userMetadata);

    if (plan.isTrial() && user.canSupervise()) {
      GoogleTagManagerHelper.gtmAcceptedInvite(data.user.items, accountMetadata, dispatch);
    }
    // default moments timezone to user/account's
    setGlobalTimezone(user.timezone_name);

    // Set the default country code
    PhoneUtil.country = user.country_code as CountryCode;

    return dispatch(
      authSlice.actions.receiveAuth({
        loginId: loginId,
        userId: userId,
        accountId: user.account_id,
      }),
    );
  };
}

export function switchWorkplace(): void {
  removeUserItem(SEEN_VERIFY_ACCOUNT_LOCATION_DIALOG_SESSION_KEY);
  removeUserId();
  removeStepUpToken();
  if (posthog.__loaded) {
    posthog.reset();
  }

  window.location.assign(`${CONFIG.LOGIN_FRONTEND_ROOT}/accounts`);
}

export function logout(forceLogoutRedirect = true): Logout {
  removeUserItem(SEEN_VERIFY_ACCOUNT_LOCATION_DIALOG_SESSION_KEY);
  removeToken();
  removeUserId();
  removeStepUpToken();
  if (posthog.__loaded) {
    posthog.reset();
  }

  if (forceLogoutRedirect) {
    const redirect = document.location.href;
    window.location.assign(`${CONFIG.APP_LEGACY_ROOT}/login?redirect=${encodeURIComponent(redirect)}`);
  } else {
    window.location.assign(`${CONFIG.APP_LEGACY_ROOT}/login`);
  }

  return {
    type: LOGOUT,
  };
}

function fetchBasicData() {
  const fetchDataViaLogin = async (dispatch: WiwDispatch) => {
    await dispatch(fetchLogin()).unwrap();
    return Promise.all([
      dispatch(fetchPerson()),
      dispatch(replaceUsers()), // grab other users on the account - data.users doesn't have them all
      dispatch(fetchAccounts()), // grab child accounts data.accounts does not have them all
    ]);
  };

  const fetchDataWithoutLogin = (dispatch: WiwDispatch) => {
    return Promise.all([
      // Each of these will fire the appropriate `receive`
      dispatch(fetchUsers()),
      dispatch(fetchCurrentAccount()),
    ]);
  };

  return (dispatch: WiwDispatch) => {
    return Promise.all([
      dispatch(fetchDataViaLogin).catch(() => {
        return dispatch(fetchDataWithoutLogin);
      }),
      dispatch(fetchPlans()),
    ]);
  };
}

export function bootstrap() {
  return async (dispatch: WiwDispatch) => {
    try {
      await refreshToken();
      await dispatch(fetchBasicData());
      await dispatch(fetchAccountMetadataIfNeeded());
      await dispatch(fetchUserMetadataIfNeeded());
      dispatch(receiveAuth());
    } catch (err) {
      console.error(err);
      if (CONFIG.FORCE_LOGOUT_REDIRECT) {
        dispatch(receiveAuthFailed(CONFIG.FORCE_LOGOUT_REDIRECT));
      }
    }
    dispatch(migrateSchedulerOnboardingLocalStorage());
  };
}
