import BaseModel from 'data/BaseModel';

export type TimeOffBalanceFields = {
  accountId: string;
  userId: string;
  requestTypeId: string;
  timeOffLedgerId: string;
  balance: number;
  availableBalance: number;
  pendingRequests: number;
  updatedAt: string;
};

const timeOffBalanceValues: TimeOffBalanceFields = {
  accountId: '',
  userId: '',
  requestTypeId: '',
  timeOffLedgerId: '',
  balance: 0,
  availableBalance: 0,
  pendingRequests: 0,
  updatedAt: '',
};

export default class TimeOffBalance extends BaseModel<TimeOffBalanceFields>(timeOffBalanceValues) {}
