import { WORKCHAT_SET_FILTERS } from 'workchat/action-types';
import { track } from 'workchat/actions/tracking';

export function clearTrackedFilters() {
  return {
    type: WORKCHAT_SET_FILTERS,
    filters: { locations: null, positions: null },
  };
}

export function updateTrackedFilters(locations, positions) {
  if (locations && !locations.size) {
    locations = null;
  }
  if (positions && !positions.size) {
    positions = null;
  }

  return {
    type: WORKCHAT_SET_FILTERS,
    filters: { locations, positions },
  };
}

export function trackFilterUse(channelCreated, workflow) {
  return (dispatch, getState) => {
    const { locations, positions } = getState().workchat.get('filters');
    const locationCount = locations ? locations.size : 0;
    const positionCount = positions ? positions.size : 0;
    if (!locationCount && !positionCount) {
      return;
    }

    dispatch(
      track('WorkChat: Used Filter', {
        locationCount,
        positionCount,
        channelCreated,
        workflow,
        opened: true,
      }),
    );
  };
}
