import { List, Map } from 'immutable';
import { difference, isEqual } from 'lodash';
/**
 * Compare two objects to determine if their values are the same for a given key. Using Immutable.equals
 * where it's possible, otherwise using lodash.isEqual where { a: 'a' } == { a: 'a' } and ['a'] == ['a']
 * Function is designed to work with a plain object of values, objects, arrays, or immutable objects,
 * e.g. { user: User(), positions: [], boundaries: { start: moment() }, style: { width: '' } }
 * The ignoreKeys is checked against top-level key, e.g. `user`^
 */
const areValuesDifferent = (compare1, compare2, ignoreKeys = [], debugName = false) => {
  if ((!compare1 && compare2) || (compare1 && !compare2)) {
    return true;
  }
  if (!compare1 && !compare2) {
    return false;
  }

  if (isImmutable(compare1)) {
    compare1 = compare1.toJS();
  }

  if (isImmutable(compare2)) {
    compare2 = compare2.toJS();
  }

  let compare1Keys = Object.keys(compare1);
  let compare2Keys = Object.keys(compare2);

  if (ignoreKeys.length) {
    compare1Keys = difference(Object.keys(compare1), ignoreKeys);
    compare2Keys = difference(Object.keys(compare2), ignoreKeys);
  }

  if (!isEqual(compare1Keys, compare2Keys)) {
    return true;
  }

  for (const key of compare1Keys) {
    const current = compare1[key];
    const next = compare2[key];
    if ((!current && next) || (current && !next)) {
      return true;
    }
    // If item has immutable.equals check that first, if equal move on
    if (ignoreKeys.includes(key) || (current && next && current.equals && next.equals && current.equals(next))) {
      continue;
    }
    // If there was an empty list and a new empty list we consider them equal
    if (current && next && current.size === 0 && next.size === 0) {
      continue;
    }
    // avoid comparing immutable properties if possible
    if (current && next && current.toJS && next.toJS && !isEqual(current.toObject(), next.toObject())) {
      if (debugName) {
        console.log(debugName, key, current, next);
      }
      return true;
    }

    const equals = isEqual(current, next);
    if (!equals) {
      if (debugName) {
        console.log(debugName, key, current, next);
      }
      return true;
    }
  }

  return false;
};

const isImmutable = object => Map.isMap(object) || List.isList(object);

export default areValuesDifferent;
