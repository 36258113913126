import type { ShiftBreakAudit } from 'data/shiftBreak/models';
import { ShiftBreakAuditSchema } from 'data/shiftBreak/models/shiftBreakAudit';
import { omit } from 'lodash';
import Attendance from 'shared/api/attendance';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { makeGoDataSchema } from 'shared/zod-schemas';
import { createWiwAsyncThunk } from 'store';

import { ZodError, z } from 'zod';

type BulkCreateShiftBreakAuditArgs = {
  audits: ShiftBreakAudit[];
  timeId: number;
};

export const bulkCreateShiftBreakAudit = createWiwAsyncThunk<
  z.infer<typeof BulkCreateShiftBreakAuditResponse>,
  BulkCreateShiftBreakAuditArgs
>('shiftBreakAudit/bulkCreate', async (request, { rejectWithValue }) => {
  try {
    const audits = request.audits.map(audit => omit(audit.toJSON(), ['creatorId', 'saving']));
    const response = await Attendance.post({ audits }, `/times/${request.timeId}/break-attestations/bulk`);
    return BulkCreateShiftBreakAuditResponse.parse(response);
  } catch (error) {
    if (error instanceof ZodError) {
      return rejectWithValue(error);
    }

    return rejectWithValue(parseAPIError(error));
  }
});

const BulkCreateShiftBreakAuditResponse = makeGoDataSchema(z.array(ShiftBreakAuditSchema));
