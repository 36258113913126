import EntityBase from 'data/EntityBase';
import generateThunkReducers from 'data/util/generateThunkReducers';
import { mergeEntityItems } from 'data/util/sliceHelpers';
import { fetchTransactions } from './actions';
import Transaction from './model';

import { createSlice } from '@reduxjs/toolkit';
import { Map } from 'immutable';

export class TransactionsState extends EntityBase({
  items: Map<Transaction['id'], Transaction>(),
  loading: false,
  loaded: false,
  receivedAt: 0,
  error: null,
}) {
  // nothing
}

const initialState = new TransactionsState();

export const transactionSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    addTransaction: (state, action) => {
      return state.mergeDeep({
        items: state.items.set(action.payload.id, new Transaction(action.payload)),
      });
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchTransactions.fulfilled, (state, action) => {
      return mergeEntityItems(state, action.payload.transactions, Transaction);
    });
    generateThunkReducers(builder, 'transactions');
  },
});

export default transactionSlice.reducer;
export const { addTransaction } = transactionSlice.actions;
