import EntityState from 'data/EntityState';
import { LIST_COUNTRIES } from 'store/action-types';
import Country from './model';

import { Map } from 'immutable';

export default function (state = new EntityState(), action) {
  switch (action.type) {
    case LIST_COUNTRIES.FAILURE:
      return state.merge({
        loaded: false,
        loading: false,
        error: action.payload,
      });
    case LIST_COUNTRIES.REQUEST:
      return state.merge({
        loaded: false,
        loading: true,
        error: null,
      });
    case LIST_COUNTRIES.SUCCESS:
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        items: new Map(action.payload.map(country => [country.id, new Country(country)])),
      });
    default:
      return state;
  }
}
