import type { DashboardCard } from 'dashboard/util';
import { withMatcher } from 'shared/util/withMatcher';
import { SET_INTERVAL_BY_CARD } from 'store/action-types';

import type { Interval } from 'luxon';

export const setIntervalByCard = withMatcher((card: DashboardCard, interval: Interval) => ({
  card,
  interval,
  type: SET_INTERVAL_BY_CARD,
}));
