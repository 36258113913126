import PaychexSettings from './PaychexSettings';

import { Record } from 'immutable';

export default class PaychexState extends Record({
  loading: false,
  loaded: false,
  updating: false,
  updated: false,
  disabling: false,
  disabled: false,
  error: null,
  accountId: null,
  enabled: false,
  createdAt: null,
  updatedAt: null,
  settings: new PaychexSettings(),
}) {}
