import { Record } from 'immutable';

export interface CountryFields {
  id: number | null;
  name: string | null;
  dialing_code: number | null;
  iso3: string | null;
  iso2: string | null;
  sms_cost: number;
  sms_credit_cost: number;
  is_deleted: boolean;
  is_enabled: number;
}

// We have to add Readonly to the type because we're not using the BaseModel class which
// includes that already.
class Country extends Record<CountryFields>({
  id: null,
  name: null,
  dialing_code: null,
  iso3: null,
  iso2: null,
  sms_cost: 0,
  sms_credit_cost: 1,
  is_deleted: false,
  is_enabled: 0,
}) {
  static UNITED_STATES = 233 as const;
}

export default Country;
