import { type Method, fetchStepUpAuthStatus } from 'data/auth/actions/fetchStepUpAuthStatus';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';

interface Response {
  method: Method;
}

export async function fetchStepUpAuthMethod(): Promise<Response> {
  try {
    const { methods } = await fetchStepUpAuthStatus();
    const method = getStepUpAuthMethod(methods);
    return {
      method,
    };
  } catch (error) {
    throw parseAPIError(error);
  }
}

export function getStepUpAuthMethod(methods: Method[]): Method {
  // If methods contains authorized-impersonator, then this takes precedent over all others
  if (methods.includes('authorized-impersonator')) {
    return 'authorized-impersonator';
  }
  // If methods contains password, then ignore the rest and use password
  if (methods.includes('password')) {
    return 'password';
  }

  if (methods.includes('email-code')) {
    return 'email-code';
  }

  return 'password';
}
