import type { FieldValues, UseControllerProps } from 'react-hook-form';

import TimeInput from 'shared/form/inputs/TimeInput';
import type { InputProps } from 'shared/form/types';

import type { TimeInputProps as MantineTimeInputProps } from '@mantine/dates';

export type TimeInputProps<T extends FieldValues> = UseControllerProps<T> &
  Omit<MantineTimeInputProps, 'value' | 'defaultValue'> & {
    value?: {
      start: string;
      end: string;
    };
    divider?: string;
  } & InputProps;

export default function TimeRangeInput<T extends FieldValues>({
  name,
  value,
  divider = 'to',
  ...props
}: TimeInputProps<T>) {
  return (
    <div className="mb-3">
      <div className="row align-items-end no-gutters">
        <div className="col">
          {/*@ts-ignore*/}
          <TimeInput name={`${name}.start` as any} defaultValue={value?.start} {...props} mb={0} />
        </div>
        <div className="col-auto mb-2 px-3">{divider}</div>
        <div className="col">
          {/*@ts-ignore*/}
          <TimeInput
            name={`${name}.end` as any}
            defaultValue={value?.end}
            {...props}
            label=" "
            withAsterisk={false}
            mb={0}
          />
        </div>
      </div>
    </div>
  );
}
