import EntityBase from 'data/EntityBase';
import Integration from 'integrations/modules/go/store/models/integration';

import SquareSettings from './models/SquareSettings';

import { Map } from 'immutable';

export default class SquareState extends EntityBase({
  schedules: new Map(),
  schedulesLoaded: false,
  employees: new Map(),
  employeesLoaded: false,
  integration: new Integration(),
  integrationLoaded: false,
  settings: new SquareSettings(),
  settingsError: null,
  settingsLoaded: false,
  settingsLoading: false,
  settingsReceivedAt: 0,
}) {}
