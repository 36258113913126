import type { AccountFields } from 'data/account/model';
import type { UserFields } from 'data/user/model';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

import type { Map } from 'immutable';

export interface NewAccountResponse {
  account: AccountFields;
  admins: UserFields[];
}

export const createChildAccount = createWiwAsyncThunk<NewAccountResponse, Map<string, Partial<AccountFields>>>(
  'account/createChild',
  async (data, { rejectWithValue }) => {
    try {
      return (await Monolith.url('/account').post(data.toJSON())) as NewAccountResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
