import { Fragment, type MouseEvent, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useLDFlag } from 'data/LD/selectors/getLDFlag';
import { getAccountPlan } from 'data/account/selectors';
import { shouldBeUpsold } from 'data/account/selectors/shouldBeUpsold';
import { fetchPunchState } from 'data/punchState/actions';
import { openDialog } from 'dialogs';
import { canViewDocStorage as getCanViewDocStorage } from 'documents/selectors/canViewDocStorage';
import Notifications from 'notifications';
import { canOnboardPayroll } from 'payrollCheck/modules/onboarding';
import { openCreateRequestDialog } from 'requests/modules/timeoff/dialogs/actions';
import { getAuthAccount, getAuthUser } from 'shared/auth/selectors';
import BulletinBoard from 'shared/components/nav/BulletinBoard';
import { AccountNotifications } from 'shared/components/nav/BulletinBoard/AccountNotifications';
import { MenuItem } from 'shared/components/nav/MenuItem';
import MenuItems from 'shared/components/nav/MenuItems';
import AppSettingsMenuItem from 'shared/components/nav/menu-items/AppSettingsMenuItem';
import AttendanceMenuItem from 'shared/components/nav/menu-items/AttendanceMenu';
import AvatarMenuItem from 'shared/components/nav/menu-items/AvatarMenuItem';
import EmployeeMenuItem from 'shared/components/nav/menu-items/EmployeeMenuItem';
import PayrollMenuItem from 'shared/components/nav/menu-items/PayrollMenuItem';
import ReportsMenuItem from 'shared/components/nav/menu-items/ReportsMenuItem';
import RequestsMenuItem from 'shared/components/nav/menu-items/RequestsMenuItem';
import SchedulingMenuItem from 'shared/components/nav/menu-items/SchedulingMenuItem';
import WorkchatMenuItem from 'shared/components/nav/menu-items/WorkchatMenuItem';
import WorkplaceMenuItem from 'shared/components/nav/menu-items/WorkplaceMenuItem';
import Features from 'shared/features';
import useGetInstalledRelatedApps from 'shared/hooks/useGetInstalledRelatedApps';
import { MULTIPLE_SCHEDULES_UPGRADE_DIALOG, openUpgradePopover } from 'shared/upsell/actions/dialogs';
import { isWebview } from 'shared/util/isWebview';
import Mercury, { DOCS_INTERACTION } from 'shared/util/mercury';
import { useWiwDispatch, useWiwSelector } from 'store';
import { openSendMessageDialog } from 'teamtxt/dialogs';
import DownloadApp from './menu-items/DownloadApp';

import FocusTrap from 'focus-trap-react';
import { useMediaQuery } from 'usehooks-ts';

import 'shared/components/nav/assets/styles/MainNav.scss';

export function MainNav() {
  const location = useLocation();
  const dispatch = useWiwDispatch();

  const authAccount = useWiwSelector(getAuthAccount);
  const accountPlan = useWiwSelector(getAccountPlan);
  const authUser = useWiwSelector(getAuthUser);
  const shouldOpenShiftRequestsBeUpsold = useWiwSelector(state => shouldBeUpsold(state, Features.OPENSHIFT_APPROVAL));
  const canViewDocStorage = useWiwSelector(getCanViewDocStorage);

  const showReactTeamTxt = useLDFlag('react-team-txt');
  const pt121WorkplaceObjects = useLDFlag('pt-121-finding-workplace-objects');
  const pay1077SunsetPayroll = useLDFlag('pay-1077-sunsetting-payroll');

  const [mobileNavigation, setMobileNavigation] = useState(false);
  const useLargerBreakpoint = authUser.isAdmin();
  const isMdBreakpoint = useMediaQuery('(min-width: 768px)');
  const isLgBreakpoint = useMediaQuery('(min-width: 992px)');
  const isDesktop = useLargerBreakpoint ? isLgBreakpoint : isMdBreakpoint;
  const toggleMobileMenu = () => setMobileNavigation(!mobileNavigation);
  const naviconId = 'navicon';
  const hasAppInstalled = useGetInstalledRelatedApps();
  const isAndroid = /Android/i.test(navigator.userAgent);
  const shouldShowPlayStoreAd = !isDesktop && isAndroid && !hasAppInstalled;
  const hideWCFreePlan = (authUser.isManager() || authUser.isSupervisor()) && authAccount.isReverseTrial();

  function childClickedCloseMenu(event: MouseEvent) {
    const target = event.target as HTMLAnchorElement;
    !isDesktop && typeof target.href !== 'undefined' && setMobileNavigation(false);
    //if user clicks the span or icon inside the anchor
    !isDesktop && target.closest('a') && setMobileNavigation(false);
  }

  // mount effects
  useEffect(() => {
    dispatch(openUpgradePopover());

    if (location.hash === '#request-timeoff') {
      dispatch(openCreateRequestDialog());
      return;
    }
  }, [dispatch, location.hash]);

  useEffect(() => {
    if (showReactTeamTxt && location.hash === '#send-message') {
      dispatch(openSendMessageDialog());
    }
  }, [dispatch, location.hash, showReactTeamTxt]);

  useEffect(() => {
    if (authAccount.hasAttendance() && authAccount.getSettings('clockin.work.enabled')) {
      dispatch(fetchPunchState());
    }
  }, [dispatch, authAccount]);

  function upsellMultipleSchedules(event: Event) {
    event.preventDefault();
    dispatch(openDialog(MULTIPLE_SCHEDULES_UPGRADE_DIALOG));
  }

  function trackDocumentsNav() {
    Mercury.track(
      DOCS_INTERACTION,
      {
        action: 'documentNavClicked',
        documentId: null,
        hash: location.hash,
        // the react-router Location object doesn't have hostname :(
        hostName: window.location.hostname,
        path: location.pathname,
        queryParams: location.search,
        annotations: null,
      },
      {},
    );
  }

  function renderSchedulingMenu() {
    const hideMenu = !authUser.canSupervise() && !authAccount.hasScheduling();

    if (hideMenu) {
      return null;
    }

    if (authAccount.isWorkchatFree()) {
      return <SchedulingMenuItem workChatFree />;
    }

    if (!authAccount.hasScheduling()) {
      return <SchedulingMenuItem schedulerTrial />;
    }

    if (!authUser.canSupervise()) {
      const showTeamSchedule =
        authAccount.getSettings('schedule.is_visible', true) ||
        authAccount.getSettings('schedule.positions_only', false);

      return (
        <Fragment>
          <SchedulingMenuItem isEmployee />
          <SchedulingMenuItem showTeamSchedule={showTeamSchedule} />
        </Fragment>
      );
    }

    return <SchedulingMenuItem defaultMenuItem />;
  }

  function renderAttendanceMenu() {
    return <AttendanceMenuItem />;
  }

  function renderPayrollMenu() {
    if (pay1077SunsetPayroll && !authAccount.getSettings('payroll_check.enabled', false)) {
      return null;
    }

    if (authAccount.referred_by === 'adp' && !authAccount.getSettings('payroll_check.enabled', false)) {
      return null;
    }

    if (authUser.canPayroll()) {
      if (authAccount.getSettings('payroll_check.is_onboarded', false)) {
        return <PayrollMenuItem payrollRoute="overview" />;
      }

      if (authAccount.getSettings('payroll_check.enabled', false)) {
        return <PayrollMenuItem payrollRoute="onboarding" />;
      }

      return <PayrollMenuItem payrollRoute="" />;
    }

    if (!authAccount.hasPayrollFeature()) {
      // Feature won't be in list unless LD flag is on
      return null;
    }

    const isRequested = authAccount.getSettings('payroll_check.requested', false);
    // If requested and is a manager who can manage account settings and payroll, show menu.
    const showMenu = isRequested && canOnboardPayroll(authAccount, authUser) && !authAccount.isChild();

    if (!showMenu || accountPlan.isAdvanced() || accountPlan.isStandard()) {
      return null;
    }

    const payrollRoute = authAccount.isPayrollOnboarded() ? 'overview' : 'onboarding';
    return <PayrollMenuItem payrollRoute={payrollRoute} />;
  }

  function renderMessagingMenu() {
    if (authAccount.getSettings('workchat.enabled', false)) {
      return <WorkchatMenuItem isDesktop={isDesktop} workchat />;
    }

    if (!authAccount.getSettings('workchat.toggled') && authUser.canManage() && !hideWCFreePlan) {
      return <WorkchatMenuItem isDesktop={isDesktop} setup />;
    }
    if (authUser.canSupervise() && !hideWCFreePlan) {
      return <WorkchatMenuItem isDesktop={isDesktop} teamText />;
    }
  }

  function renderReportsButton() {
    if (!authUser.canManage()) {
      return null;
    }

    return <ReportsMenuItem />;
  }

  function renderBulletinBoard() {
    return (
      <BulletinBoard authUser={authUser} authAccount={authAccount} accountPlan={accountPlan} isDesktop={isDesktop} />
    );
  }

  function renderRequestsMenu() {
    const shouldHideMenu =
      authAccount.isWorkchatFree() ||
      (!authUser.canSupervise() &&
        !authAccount.getSettings('timeoff.enabled') &&
        !authAccount.getSettings('swaps.enabled') &&
        !authAccount.hasFeature(Features.OPENSHIFT_APPROVAL, true) &&
        !shouldOpenShiftRequestsBeUpsold);

    if (shouldHideMenu) {
      return null;
    }

    return (
      <RequestsMenuItem
        authUser={authUser}
        authAccount={authAccount}
        featureOpenShiftApproval={Features.OPENSHIFT_APPROVAL}
        openCreateRequestDialog={() => dispatch(openCreateRequestDialog())}
        requestsEnabled={authAccount.getSettings('timeoff.enabled')}
        schedulerEnabled={authAccount.hasScheduling()}
        swapsEnabled={authAccount.getSettings('swaps.enabled')}
      />
    );
  }

  function renderWorkplaceMenu() {
    if (authUser.canSupervise()) {
      return (
        <WorkplaceMenuItem
          authUser={authUser}
          authAccount={authAccount}
          accountPlan={accountPlan}
          trackDocumentsNav={trackDocumentsNav}
          pt121WorkplaceObjects={pt121WorkplaceObjects}
          upsellMultipleSchedules={upsellMultipleSchedules}
          canViewDocStorage={canViewDocStorage}
        />
      );
    }
  }

  function renderAppSettingsMenu() {
    const canManageSettings =
      authUser.isAdmin() ||
      (authUser.isManager() && authAccount.getSettings('role_permissions.managers.can_manage_account_settings', true));
    const isSetup = authAccount.getSettings('payroll.is_onboarded', false);
    if (canManageSettings) {
      return (
        <AppSettingsMenuItem
          authUser={authUser}
          authAccount={authAccount}
          accountPlan={accountPlan}
          isSetup={isSetup}
        />
      );
    }
  }

  function renderEmployeeMenu() {
    if (!authUser.canSupervise()) {
      return <EmployeeMenuItem trackDocumentsNav={trackDocumentsNav} canViewDocStorage={canViewDocStorage} />;
    }
  }

  function renderAvatarMenu() {
    return <AvatarMenuItem authUser={authUser} authAccount={authAccount} />;
  }

  function renderDownloadApp() {
    return <DownloadApp />;
  }

  // hide the nav if we're onboarding scheduler or user is on a webview
  if (location.pathname.match(/^\/scheduler\/onboarding(\/)?$/i) || isWebview()) {
    return null;
  }

  const menuText = hasAppInstalled ? 'App' : 'Menu';
  return (
    <nav
      id="main-header"
      className={`main-navigation navbar d-print-none ${useLargerBreakpoint ? 'navbar-expand-lg' : 'navbar-expand-md'}`}
    >
      <div className="message-group">
        {!authAccount.isWorkchatFree() && <Notifications container="main-header" />}
        {!isDesktop && renderMessagingMenu()}
      </div>

      {!isDesktop && (
        <MenuItems className="navbar-account-notifications">
          <AccountNotifications authUser={authUser} authAccount={authAccount} accountPlan={accountPlan} />
        </MenuItems>
      )}

      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded={mobileNavigation}
        aria-label="Toggle navigation"
        id={naviconId}
        onClick={toggleMobileMenu}
      >
        <i className="wiw-icon wiw-more-horizontal-lines" /> {menuText}
      </button>

      <FocusTrap
        active={mobileNavigation}
        focusTrapOptions={{
          onDeactivate: () => setMobileNavigation(false),
          allowOutsideClick: true,
          initialFocus: () => !isDesktop && (document.querySelector('.navbar-nav') as any),
          clickOutsideDeactivates: event => (event.target as Element).id !== naviconId,
        }}
      >
        <div
          className={`collapse navbar-collapse ${mobileNavigation ? 'show' : ''}`}
          id="navbarSupportedContent"
          onClick={(event: MouseEvent) => childClickedCloseMenu(event)}
        >
          <MenuItems className="navbar-nav" countChildren>
            {!isDesktop && renderBulletinBoard()}
            {authAccount.hasScheduling() && authUser.canSupervise() && (
              <MenuItem icon="dashboard" exact hideLabel="tablet" isMainNav label="Dashboard" to="/" />
            )}

            {renderSchedulingMenu()}
            {renderAttendanceMenu()}
            {renderPayrollMenu()}
            {isDesktop && renderMessagingMenu()}
            {renderReportsButton()}
            {isDesktop && renderBulletinBoard()}
            {renderRequestsMenu()}
            {renderWorkplaceMenu()}
            {renderAppSettingsMenu()}
            {renderEmployeeMenu()}
            {renderAvatarMenu()}
            {shouldShowPlayStoreAd && renderDownloadApp()}
          </MenuItems>
        </div>
      </FocusTrap>
    </nav>
  );
}
