import type { ReportMetadataRequestParams } from 'data/reporting/actions/reportMetadataRequestType';
import type { Dataset } from 'data/reporting/models/Dataset';
import { getReportAccountId } from 'reporting/selectors/getReportAccountId';
import { getReportDataset } from 'reporting/selectors/getReportDataset';

import { createSelector } from '@reduxjs/toolkit';
import { memoize } from 'lodash';

export const getMetadataRequestParams = createSelector([getReportAccountId, getReportDataset], (accountId, dataset) => {
  return {
    accountId: `${accountId}`,
    dataset,
    limit: 1,
  } as ReportMetadataRequestParams;
});

export const getMetadataRequestParamsForDataset = memoize((dataset: Dataset) =>
  createSelector(getReportAccountId, (accountId: number | undefined) => {
    return {
      accountId: `${accountId}`,
      dataset,
      limit: 1,
    } as ReportMetadataRequestParams;
  }),
);
