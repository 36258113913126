import type Site from 'data/site/model';
import type { SiteFields } from 'data/site/model';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

export type CreateSiteResponse = {
  site: SiteFields;
};

export const createSite = createWiwAsyncThunk<CreateSiteResponse, Site>(
  'site/create',
  async (site, { rejectWithValue }) => {
    try {
      return (await Monolith.url('/sites').post(site.toJSON())) as CreateSiteResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
