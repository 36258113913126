import { fetchAccountBillingSummary } from 'data/stripe/actions/fetchAccountSummary';
import { StripeSubscriptionsState } from 'data/stripe/state/StripeSubscriptionsState';
import { hibernateSubscription } from 'data/stripe/subscriptions/actions/hibernateSubscription';
import { updateSubscription } from 'data/stripe/subscriptions/actions/updateSubscription';
import StripeSubscription from 'data/stripe/subscriptions/model';
import generateThunkReducers from 'data/util/generateThunkReducers';
import { replaceEntityItems } from 'data/util/sliceHelpers';

import { createSlice } from '@reduxjs/toolkit';

const initialState = new StripeSubscriptionsState();

export const stripeSubscriptionsSlice = createSlice({
  name: 'stripe/subscriptions',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchAccountBillingSummary.fulfilled, (state, action) => {
        return replaceEntityItems(state, action.payload.subscriptions, StripeSubscription).set('loaded', true);
      })
      .addCase(updateSubscription.fulfilled, (state, action) => {
        return replaceEntityItems(state, action.payload.subscription, StripeSubscription);
      })
      .addCase(hibernateSubscription.fulfilled, (state, action) => {
        return replaceEntityItems(state, action.payload.subscription, StripeSubscription);
      });
    generateThunkReducers(builder, 'stripe/subscriptions');
  },
});

export default stripeSubscriptionsSlice.reducer;
