import type Location from 'data/location/model';
import type { LocationFields } from 'data/location/model';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type UpdateLocationResponse = {
  location: LocationFields;
};

export const updateLocation = createWiwAsyncThunk<UpdateLocationResponse, Location>(
  'location/update',
  async (location, { rejectWithValue }) => {
    try {
      return (await Monolith.url(`/locations/${location.id}`).put(location.toJSON())) as UpdateLocationResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
