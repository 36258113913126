import { createAbsence } from 'data/absence/actions/createAbsence';
import { deleteAbsence } from 'data/absence/actions/deleteAbsence';
import {
  fetchAbsence,
  fetchAbsences,
  fetchAbsencesByShiftId,
  replaceAbsencesByUserId,
} from 'data/absence/actions/fetchAbsence';
import { updateAbsence } from 'data/absence/actions/updateAbsence';
import Absence, { type AbsenceFields } from 'data/absence/model';
import AbsenceState from 'data/absence/state';
import generateThunkReducers from 'data/util/generateThunkReducers';
import { mergeEntityItems } from 'data/util/sliceHelpers';
import { toEntityMap } from 'shared/util/toEntityMap';

import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState = new AbsenceState();

export const absenceSlice = createSlice({
  name: 'absence',
  initialState,
  reducers: {
    absenceNotifications(state, action: PayloadAction<AbsenceFields[]>) {
      return state.mergeDeep({
        items: state.items.merge(toEntityMap(action.payload, Absence)),
      });
    },
    setAbsenceToView(state, action: PayloadAction<Absence['id']>) {
      return state.set('absenceToView', action.payload ? action.payload : '');
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAbsence.fulfilled, (state, action) => {
        return mergeEntityItems(state, action.payload.data.absences, Absence);
      })
      .addCase(fetchAbsences.fulfilled, (state, action) => {
        return mergeEntityItems(state, action.payload.data.absences, Absence);
      })
      .addCase(fetchAbsencesByShiftId.fulfilled, (state, action) => {
        return mergeEntityItems(state, action.payload.data.absences, Absence);
      })
      .addCase(replaceAbsencesByUserId.fulfilled, (state, action) => {
        return state.merge({
          items: toEntityMap(action.payload.data.absences, Absence),
        });
      })
      .addCase(createAbsence.fulfilled, (state, action) => {
        return mergeEntityItems(state, action.payload.data.absence, Absence);
      })
      .addCase(updateAbsence.fulfilled, (state, action) => {
        return mergeEntityItems(state, action.payload.data, Absence);
      })
      .addCase(deleteAbsence.fulfilled, (state, action) => {
        return state.merge({
          items: state.items.delete(action.meta.arg.id),
        });
      });
    generateThunkReducers(builder, 'absence');
  },
});

export const { absenceNotifications, setAbsenceToView } = absenceSlice.actions;

export default absenceSlice.reducer;
