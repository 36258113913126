import PropTypes from 'prop-types';
import { Component } from 'react';

import LottieAnimation from 'shared/ui/LottieAnimation';
import spinner from 'styles/assets/lottie/wiw-spinner.lottie';

import classnames from 'classnames';

import loaderBackground from 'styles/assets/img/loader/loader-background.svg';
import loaderDarkSegment from 'styles/assets/img/loader/loader-segment-dark.svg';
import loaderLightSegment from 'styles/assets/img/loader/loader-segment-light.svg';
import loaderMediumSegment from 'styles/assets/img/loader/loader-segment-medium.svg';

export class Loading extends Component {
  static propTypes = {
    background: PropTypes.bool,
    className: PropTypes.string,
    lottie: PropTypes.bool,
    width: PropTypes.number,
    height: PropTypes.number,
  };

  static defaultProps = {
    background: true,
    className: 'default',
    width: 100,
    height: 100,
  };

  render() {
    const { background, className, lottie, width, height } = this.props;
    if (lottie) {
      return <LottieAnimation className={className} height={height} width={width} animationData={spinner} />;
    }

    return (
      <div className={classnames('loading-app', className)}>
        {background && <img className="loader-background" src={loaderBackground} />}
        <img className="loader-segment light" src={loaderLightSegment} />
        <img className="loader-segment medium" src={loaderMediumSegment} />
        <img className="loader-segment dark" src={loaderDarkSegment} />
      </div>
    );
  }
}

export default Loading;
