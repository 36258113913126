import { Record } from 'immutable';

/**
 * See related SyncDirection declarations at {@link https://gitlab.wheniwork.com/apps/integrations-go/-/blob/develop/pkg/square/postgres.go#L89}
 */
export enum SyncDirection {
  NoSyncing = 0,
  TwoWaySync = 1,
}

interface SquareSettingsFields {
  syncDirection: SyncDirection;
}

class SquareSettings extends Record<SquareSettingsFields>({
  syncDirection: SyncDirection.NoSyncing,
}) {}

export default SquareSettings;
