import type { TagListFields } from 'data/tag/TagList';
import type User from 'data/user/model';
import { getUsersToSchedule } from 'scheduler/selectors/getFilteredLocationUsers';
import Worktags from 'shared/api/worktags';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type FetchUserTagsForSchedulableUsersArgs = {};

type FetchUserTagsForSchedulableUsersResponse = {
  data: TagListFields[];
};

export const fetchUserTagsForSchedulableUsers = createWiwAsyncThunk<
  FetchUserTagsForSchedulableUsersResponse,
  FetchUserTagsForSchedulableUsersArgs
>('tags/userTags/fetchSchedulable', async (_, { rejectWithValue, getState }) => {
  try {
    const userIds = getUsersToSchedule(getState())
      .map((user: User) => `${user.id}`)
      .toArray();
    return (await Worktags.url('/users').post({ ids: userIds })) as FetchUserTagsForSchedulableUsersResponse;
  } catch (error) {
    return rejectWithValue(parseAPIError(error));
  }
});
