import BaseModel from 'data/BaseModel';

export interface PositionFields {
  id: number;
  account_id: number;
  location_id: number;
  name: string;
  color: string;
  sort: number;
  created_at: string;
  updated_at: string;
  tips_tracking: boolean;
  is_deleted: boolean;
}

class Position extends BaseModel<PositionFields>({
  id: 0,
  account_id: 0,
  location_id: 0,
  name: '',
  color: 'cccccc',
  sort: 0,
  created_at: '',
  updated_at: '',
  tips_tracking: false,
  is_deleted: false,
}) {}

export default Position;
