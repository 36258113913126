import { Component } from 'react';
import { providerInstance } from 'workchat/TwilioProvider';

export function withTwilioProvider(WrappedComponent: any) {
  class WithTwilioProvider extends Component {
    static displayName = `WithTwilioProvider(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

    render() {
      return <WrappedComponent provider={providerInstance} {...this.props} />;
    }
  }

  return WithTwilioProvider;
}

export default withTwilioProvider;
