import { useState } from 'react';

import BranchPicker from 'shared/dev/components/BranchPicker';
import type { Option } from 'shared/dev/types/types';

import Cookies from 'js-cookie';

type Props = {
  label: string;
  onBranchChanged: () => void;
};

const ProjectID = 25;
const EnvironmentIDs = [129];
const CookieName = 'X-Login-Branch';

export default function LoginBranches({ label, onBranchChanged }: Props) {
  // @ts-ignore
  const [currentBranch, setCurrentBranch] = useState<string>(Cookies.get(CookieName, { domain: CONFIG.COOKIE_DOMAIN }));

  const changeBranch = (branch: Option) => {
    if (branch === null) {
      Cookies.remove(CookieName, { domain: CONFIG.COOKIE_DOMAIN });
      setCurrentBranch('<Default>');
      onBranchChanged();
    }

    if (!branch) {
      return;
    }

    Cookies.set(CookieName, branch.value, { domain: CONFIG.COOKIE_DOMAIN });
    setCurrentBranch(branch.value);
    onBranchChanged();
  };

  return (
    <BranchPicker
      label={label}
      projectId={ProjectID}
      environmentIds={EnvironmentIDs}
      currentBranch={currentBranch}
      changeBranch={changeBranch}
      branchLink={
        <a
          href={encodeURI(`https://login.wheniwork.net/cmd/choose?serverBranch=${currentBranch}&redirect=/`)}
          className="mb-1"
        >
          Link to branch
        </a>
      }
    />
  );
}
