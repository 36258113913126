import type { Filters, SortParam } from 'data/reporting/actions/reportRequestTypes';
import { luxonToAccountRelative } from 'shared/util/luxonHelpers';

import type { Interval } from 'luxon';

export function getDefaultFiltersSort({ dateRange, dataset }: { dateRange: Interval; dataset?: string | undefined }) {
  const filters: Filters = {
    shiftStartTime: undefined,
    timeStartTime: undefined,
    eventOccurredAt: undefined,
  };
  const sort: SortParam[] = [];

  switch (dataset) {
    case 'shifts':
      filters.shiftStartTime = [
        {
          operator: 'between',
          args: [
            luxonToAccountRelative(dateRange.start).startOf('day').toUTC().toISO(),
            luxonToAccountRelative(dateRange.end).endOf('day').toUTC().toISO(),
          ],
        },
      ];
      // Had been: sort.push({ 'column': 'shiftStartTime', 'order': 'desc' });
      // Now relies on the dataset's natural sort for greater efficiency
      break;
    case 'times':
      filters.timeStartTime = [
        {
          operator: 'between',
          args: [
            luxonToAccountRelative(dateRange.start).startOf('day').toUTC().toISO(),
            luxonToAccountRelative(dateRange.end).endOf('day').toUTC().toISO(),
          ],
        },
      ];
      // Had been: sort.push({ 'column': 'timeStartTime', 'order': 'desc' });
      // Now relied on the dataset's natural sort for efficiency
      break;
    case 'shift-history':
      filters.eventOccurredAt = [
        {
          operator: 'between',
          args: [
            luxonToAccountRelative(dateRange.start).startOf('day').toUTC().toISO(),
            luxonToAccountRelative(dateRange.end).endOf('day').toUTC().toISO(),
          ],
        },
      ];
      // Relies on the dataset's natural sort for efficiency
      break;
  }

  return {
    sort,
    filters,
  };
}
