import type { AvailabilityFields } from 'data/availability/model';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

import moment from 'moment-timezone';

type FetchAvailabilityArgs = {
  data?: any;
  start?: string;
  end?: string;
  user_id?: number | number[];
};

type FetchAvailabilityResponse = {
  availabilityevents: AvailabilityFields[];
};

export const fetchAvailability = createWiwAsyncThunk<FetchAvailabilityResponse, FetchAvailabilityArgs>(
  'availability/fetch',
  async (data, { rejectWithValue }) => {
    try {
      const today = moment().startOf('day');
      data.start = !data.start ? today.toISOString() : data.start;
      data.end = !data.end ? today.add('95', 'days').toISOString() : data.end;

      return (await Monolith.url('/availabilityevents/list').post({
        include_all: true,
        ...data,
      })) as FetchAvailabilityResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);

type FetchReplaceAvailabilityArgs = {
  data?: any;
};

export const fetchReplaceAvailability = createWiwAsyncThunk<FetchAvailabilityResponse, FetchReplaceAvailabilityArgs>(
  'availability/fetchReplace',
  async (data, { rejectWithValue }) => {
    try {
      const today = moment().startOf('day');
      return (await Monolith.url('/availabilityevents/list').post({
        start: today.toISOString(),
        end: today.add('95', 'days').toISOString(),
        include_all: true,
        ...data,
      })) as FetchAvailabilityResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
