import type Shift from 'data/shift/model';
import type { EntityMap } from 'data/types';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { getMomentDate } from 'shared/util/time';
import { createWiwAsyncThunk } from 'store';

type DeleteShiftsArgs = {
  shifts: EntityMap<Shift>;
  message?: string;
};

export const deleteShifts = createWiwAsyncThunk<any, DeleteShiftsArgs>(
  'shift/multiDelete',
  async ({ shifts, message }, { rejectWithValue }) => {
    try {
      return await Monolith.url('/shifts')
        .query({
          ids: shifts.keySeq().toArray(),
          message,
        })
        .delete();
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);

export type DeleteShiftArgs = {
  shift: Shift;
  message?: string;
};

export const deleteShift = createWiwAsyncThunk<any, DeleteShiftArgs>(
  'shift/delete',
  async ({ shift, message }, { rejectWithValue }) => {
    try {
      return await Monolith.url(`/shifts/${shift.id}`).query({ message }).delete();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

type DeleteShiftsInRangeArgs = {
  start: string;
  end: string;
  locationId: number;
  userId?: number;
  message?: string;
  filters: any;
};

export const deleteShiftsInRange = createWiwAsyncThunk<any, DeleteShiftsInRangeArgs>(
  'shift/deleteInRange',
  async (args, { rejectWithValue }) => {
    const formatDate = (datetime: string) => {
      return getMomentDate(datetime).format();
    };

    try {
      const data: any = {
        start: formatDate(args.start),
        end: formatDate(args.end),
        location_id: args.locationId,
      };

      if (args.userId !== null) {
        data.user_id = args.userId;
      }

      if (args.filters.positionIds?.length) {
        data.position_id = args.filters.positionIds;
      }

      if (args.filters.siteIds?.length) {
        data.site_id = args.filters.siteIds;
      }

      return await Monolith.url('/shifts').query({ message: args.message }).json(data).delete();
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
