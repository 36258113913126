import { getCustomMetadata } from 'data/reporting/selectors/getCustomMetadata';
import type { CustomMetadataState } from 'data/reporting/state/CustomMetadataState';
import { getReportTempAccountId } from 'reporting/selectors/getReportTempAccountId';
import { getReportTempDataset } from 'reporting/selectors/getReportTempDataset';

import { createSelector } from '@reduxjs/toolkit';

export const getReportMetadataLoadingTemp = createSelector(
  getCustomMetadata,
  getReportTempAccountId,
  getReportTempDataset,
  (metadata, accountId, dataset) => (metadata.getIn([`${accountId}`, dataset]) as CustomMetadataState)?.loading,
);
