import type { WiwDispatch } from 'store';
import { SET_LD_FLAGS, UPSERT_LD_FLAGS } from 'store/action-types';

import type { AnyAction } from '@reduxjs/toolkit';
import {
  type LDClient,
  type LDContext,
  type LDFlagChangeset,
  type LDFlagSet,
  initialize,
} from 'launchdarkly-js-client-sdk';

// add `ldverbose` to the URL search to see console messages related to LD flag init & changes
const VERBOSE = window?.location.search.includes('ldverbose');

export const isVerbose = () => VERBOSE;

let ldclient: LDClient | null = null;

// Should be used as a last resort, e.g. diagnostics component at /test/launchdarkly, LogRocketWrapper, etc.
// Components should prefer to use Redux state.
export const getLdClient = () => ldclient;

let removeLastChangeListener: () => void;

// Proper ordering of async operations is important in the initialization logic here
export const init = (opts: { dispatch: WiwDispatch; contexts: LDContext }): Promise<void> => {
  const { dispatch, contexts } = opts;

  ldclient = initialize(CONFIG.LAUNCH_DARKLY_TOKEN, contexts, {
    // https://docs.launchdarkly.com/sdk/client-side/javascript#customizing-your-client
    bootstrap: 'localStorage',
    evaluationReasons: true,
    sendEvents: true,
    sendEventsOnlyForVariation: true,
  });

  const initPromise = ldclient.waitForInitialization().then(() => {
    // eslint-disable-next-line no-console
    VERBOSE && console.log('LDUser identified: ', contexts);
    // eslint-disable-next-line no-console
    VERBOSE && console.log('Initial LD flags: ', ldclient!.allFlags());
    dispatch({
      type: SET_LD_FLAGS,
      payload: ldclient!.allFlags(),
    });
  });

  removeLastChangeListener?.();

  const changeListener = (changes: LDFlagChangeset) => {
    // eslint-disable-next-line no-console
    VERBOSE && console.log('Streaming LD flag change', changes);

    dispatch({
      type: UPSERT_LD_FLAGS,
      payload: changes,
    });
  };

  removeLastChangeListener = () => {
    ldclient!.off('change', changeListener);
  };

  ldclient.on('change', changeListener);

  return initPromise;
};

export interface LDReduxState extends LDFlagSet {
  isLDReady: boolean;
  // eslint-disable-next-line max-len
  // TODO(types): In the future, we can and should declare all possible flags and their types here or elsewhere. This would not only give us better types in components that use these flags, but it would also make it very, very easy to find where each flag is used. We should also integrate that type data into the ld-redux-components like <Feature>.
}

export function reducer(
  state: LDReduxState = {
    isLDReady: false,
  },
  action: AnyAction,
): LDReduxState {
  const update: Partial<LDReduxState> = {};

  switch (action.type) {
    case SET_LD_FLAGS:
      Object.keys(action.payload as LDFlagSet)
        .sort()
        .forEach(key => {
          update[key] = action.payload[key];
        });
      // Clears (any) existing flags
      return {
        ...update,
        isLDReady: true,
      };
    case UPSERT_LD_FLAGS:
      Object.keys(action.payload as LDFlagChangeset)
        .sort()
        .forEach(key => {
          update[key] = action.payload[key].current;
        });
      // Upserts flags
      return {
        ...state,
        ...update,
      };
    default:
      return state;
  }
}
