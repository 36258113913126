import { lazy } from 'react';

const WorkchatDisableConfirmation = lazy(() => import('./WorkchatDisableConfirmationDialog'));
const MaxHoursResetConfirmationDialog = lazy(() => import('./MaxHoursResetConfirmationDialog'));
const StartOfWeekConfirmationDialog = lazy(() => import('./StartOfWeekConfirmationDialog'));
const TimezoneChangeConfirmationDialog = lazy(() => import('./TimezoneChangeConfirmationDialog'));

export const WORKCHAT_DISABLE_CONFIRMATION = 'WORKCHAT_DISABLE_CONFIRMATION';
export const MAX_HOURS_RESET_CONFIRMATION = 'MAX_HOURS_RESET_CONFIRMATION';
export const START_OF_WEEK_CONFIRMATION = 'START_OF_WEEK_CONFIRMATION';
export const TIMEZONE_CHANGE_CONFIRMATION = 'TIMEZONE_CHANGE_CONFIRMATION';

export default {
  WORKCHAT_DISABLE_CONFIRMATION: WorkchatDisableConfirmation,
  MAX_HOURS_RESET_CONFIRMATION: MaxHoursResetConfirmationDialog,
  START_OF_WEEK_CONFIRMATION: StartOfWeekConfirmationDialog,
  TIMEZONE_CHANGE_CONFIRMATION: TimezoneChangeConfirmationDialog,
};
