import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';

function setCookie(name, value) {
  return Cookies.set(name, value, {
    domain: `.${CONFIG.ROOT_DOMAIN}`,
    secure: true,
    sameSite: 'Lax',
  });
}

export function removeCookie(name) {
  return Cookies.remove(name, { domain: `.${CONFIG.ROOT_DOMAIN}` });
}

export const getToken = () => Cookies.get('W-Token');

export const setToken = token => setCookie('W-Token', token);

export const deleteToken = () => removeCookie('W-Token');

export const getTokenInfo = () => jwtDecode(getToken() || {});

export const getUserId = () => Cookies.get('W-UserId');

export const getAnonymousUserId = () => Cookies.get('ld_anon_context');

export const setUserId = userId => setCookie('W-UserId', userId);

export const deleteUserId = () => removeCookie('W-UserId');
