import { type PropsWithChildren, createContext, useContext, useRef } from 'react';

import type { ReactionPickerApi } from 'workchat/v2/components/ReactionPicker';
import type { Emoji } from 'workchat/v2/constants';

type ReactionPickerContextApi = {
  bindRef?: (ref: ReactionPickerApi) => void;
  pickReaction: () => Promise<Emoji | null>;
};

const ReactionPickerContext = createContext<ReactionPickerContextApi>({
  pickReaction: () => Promise.resolve(null),
});

/**
 * ReactionPickerProvider allows for sharing a single instance of ReactionPicker between multiple child / sibling components
 * See: ReactionPicker.tsx for usage examples.
 */
export const ReactionPickerProvider = ({ children }: PropsWithChildren<any>) => {
  const pickerRef = useRef<ReactionPickerApi>();

  return (
    <ReactionPickerContext.Provider
      value={{
        bindRef: (ref: ReactionPickerApi) => {
          pickerRef.current = ref;
        },
        pickReaction: () => pickerRef.current?.pickReaction() || Promise.resolve(null),
      }}
    >
      {children}
    </ReactionPickerContext.Provider>
  );
};

const useReactionPicker = () => {
  const { pickReaction, bindRef } = useContext(ReactionPickerContext);
  return { pickReaction, bindRef };
};

export default useReactionPicker;
