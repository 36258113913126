import { List, Map } from 'immutable';
import PropTypes from 'prop-types';
import { Component } from 'react';

import InfiniteScroll from 'react-infinite-scroll-component';
import Message from 'workchat/containers/Message/Message';
import { DateFormat } from 'workchat/formats';

import 'workchat/styles/MessageLog.scss';
import SpinningLoader from 'shared/ui/loaders/SpinningLoader';

export default class MessageLog extends Component {
  static propTypes = {
    connected: PropTypes.bool.isRequired,
    conversation: PropTypes.object.isRequired,
    participants: PropTypes.instanceOf(Map).isRequired,
    provider: PropTypes.object.isRequired,
    messages: PropTypes.instanceOf(List).isRequired,
    pending: PropTypes.instanceOf(List).isRequired,
    failed: PropTypes.instanceOf(List).isRequired,
    users: PropTypes.instanceOf(Map).isRequired,
    user: PropTypes.object.isRequired,
    updateConversation: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  state = {
    entries: [],
  };

  componentDidMount() {
    this.updateMessages(this.props);
  }

  componentWillReceiveProps(props) {
    if (
      props.messages !== this.props.messages ||
      props.users !== this.props.users ||
      props.participants !== this.props.participants
    ) {
      this.updateMessages(props);
    }
  }

  updateMessages(props) {
    const selfId = `${props.user.id}`;
    const { conversation, messages, users } = props;
    const participants = props.participants.toList();
    const entries = [];
    const lastIdx = messages.size - 1;

    let lastDate = null;

    messages.forEach((msg, idx) => {
      const date = msg.sentAt.calendar(null, DateFormat);
      const ownMsg = msg.userId === selfId;
      const msgClass = (ownMsg ? 'sent-line' : 'recv-line') + (conversation.individual ? ' individual' : '');
      let user = users.get(msg.userId) || { fullName: '???' };
      if (msg.author === 'system') {
        user = { fullName: 'When I Work', system: true };
      }

      if (date !== lastDate) {
        lastDate = date;
        entries.push(
          <div key={`msg-date-${+msg.sentAt}`} className="date-line">
            <hr />
            <span>{date}</span>
          </div>,
        );
      }

      let readers = null;
      let readByAll = false;
      if (idx === lastIdx) {
        let unread = 0;
        readers = [];
        participants.forEach(p => {
          if (p.userId !== msg.userId && p.lastReadMessageIndex !== null && p.lastReadMessageIndex >= msg.index) {
            const u = users.get(p.userId);
            u.lastReadTimestamp = p.lastReadTimestamp;
            readers.push(u);
          } else if (p.userId !== msg.userId) {
            unread++;
          }
        });

        if (readers.length === 1 && readers[0].id === selfId) {
          // Show nothing when we're the only reader
          readers = null;
        } else {
          readers = readers.sort((a, b) => {
            return a.lastReadTimestamp - b.lastReadTimestamp;
          });
        }
        readByAll = unread === 0;
      }

      if (msg.image && msg.text) {
        entries.push(
          <Message
            ownMessage={ownMsg}
            className={msgClass}
            key={`msg-img-${msg.sid}`}
            individual={conversation.individual}
            user={user}
            skipByline={true}
            image={msg.image}
            sentAt={msg.sentAt}
          />,
        );
        entries.push(
          <Message
            ownMessage={ownMsg}
            className={msgClass}
            key={`msg-${msg.sid}`}
            individual={conversation.individual}
            user={user}
            text={msg.text}
            sentAt={msg.sentAt}
            readers={readers}
            readByAll={readByAll}
          />,
        );
      } else {
        entries.push(
          <Message
            ownMessage={ownMsg}
            className={msgClass}
            key={`msg-${msg.sid}`}
            individual={conversation.individual}
            user={user}
            image={msg.image}
            text={msg.text}
            sentAt={msg.sentAt}
            readers={readers}
            readByAll={readByAll}
          />,
        );
      }
    });

    this.setState({ entries });
  }

  loadMore = e => {
    if (e) {
      e.preventDefault();
    }

    this.props.provider.loadMessages(true).then(this.props.updateConversation);
  };

  render() {
    const { conversation, connected } = this.props;
    const { entries } = this.state;
    return (
      <div
        id="scrollable"
        className="message-log"
        ref={div => {
          this.div = div;
        }}
      >
        <InfiniteScroll
          dataLength={entries?.length ?? 0}
          next={this.loadMore}
          hasMore={conversation.more && connected}
          loader={
            <div className="text-center my-2">
              <SpinningLoader color="gray" height={30} width={30} />
            </div>
          }
          scrollableTarget="scrollable"
          style={{
            display: 'flex',
            overflow: 'hidden',
            flexDirection: 'column-reverse',
          }}
          inverse={true}
          scrollThreshold="20px"
        >
          <div
            id="messages"
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {entries}
          </div>
        </InfiniteScroll>
      </div>
    );
  }
}
