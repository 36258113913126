import { useCallback, useMemo } from 'react';

import { closeAllDialogs, openDialog } from 'dialogs/actions';
import type { DialogType } from 'dialogs/dialogs';
import type { DialogOptions } from 'dialogs/reducer';
import { useWiwDispatch } from 'store';

export default function useDialog() {
  const dispatch = useWiwDispatch();

  const openDialogCallback = useCallback(
    <TResolve>(dialog: DialogType, payload: Record<string, unknown> = {}, options: DialogOptions = {}) => {
      return dispatch(openDialog<TResolve>(dialog, payload, options));
    },
    [],
  );

  const closeAllDialogsCallback = useCallback(() => dispatch(closeAllDialogs()), []);

  return useMemo(
    () => ({
      open: openDialogCallback,
      openDialog: openDialogCallback,
      closeAll: closeAllDialogsCallback,
    }),
    [openDialogCallback],
  );
}
