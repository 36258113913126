import { SHOW_UNSCHEDULED_POSITIONS } from 'scheduler/util/cookies';
import { TOGGLE_SHOW_UNSCHEDULED_POSITIONS } from 'store/action-types';

import Cookie from 'js-cookie';

export const toggleHideUnscheduledPositions = value => {
  Cookie.set(SHOW_UNSCHEDULED_POSITIONS, value);

  return {
    type: TOGGLE_SHOW_UNSCHEDULED_POSITIONS,
    payload: value,
  };
};
