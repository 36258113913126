import { openUserEditDialog } from 'users/dialogs';

export function toggleUserEditorModal(
  userID: number | null = null,
  setDefaultLocation = false,
  setTab: boolean | string = false,
  openedFrom = 'undefined',
) {
  return openUserEditDialog({ userID, setDefaultLocation, setTab, openedFrom });
}
