import type { RequestFields } from 'data/request/model';
import type { UserFields } from 'data/user/model';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

import { omit } from 'lodash';

type FetchRequestsArgs = {
  page?: number;
  clearStore?: boolean;
  [key: string]: any;
};

type FetchRequestsResponse = {
  requests: RequestFields[];
  messages: [];
  more: boolean;
  page: number;
  total: number;
};

const defaultParams = {
  page: 0,
  clearStore: false,
};

export const fetchRequests = createWiwAsyncThunk<FetchRequestsResponse, FetchRequestsArgs>(
  'request/fetch',
  async (params = defaultParams, { dispatch, rejectWithValue }) => {
    params = { ...defaultParams, ...params };
    try {
      const response = (await Monolith.url('/requests')
        .query(omit(params, ['clearStore']))
        .get()) as FetchRequestsResponse;
      if (response.more) {
        dispatch(fetchRequests({ ...params, page: (params.page ?? 0) + 1, clearStore: false }));
      }
      return response;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);

type FetchRequestArgs = number;

type FetchRequestResponse = {
  messages: [];
  request: RequestFields;
  users: UserFields[];
};

export const fetchRequest = createWiwAsyncThunk<FetchRequestResponse, FetchRequestArgs>(
  'request/fetchSingle',
  async (requestId, { rejectWithValue }) => {
    try {
      return (await Monolith.url(`/requests/${requestId}`).get()) as FetchRequestResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
