import { List, Map, Set } from 'immutable';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import FontIcon from 'shared/ui/FontIcon';
import Sifter from 'sifter';

import 'workchat/styles/UserPicker.scss';

export default class UserPicker extends PureComponent {
  static propTypes = {
    user: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    users: PropTypes.instanceOf(Map).isRequired,
    locations: PropTypes.instanceOf(Map),
    positions: PropTypes.instanceOf(Map),
    participants: PropTypes.instanceOf(Map),
    prefill: PropTypes.object,
    enableFilters: PropTypes.bool,

    setUsers: PropTypes.func,
    updateTrackedFilters: PropTypes.func,
  };

  static defaultProps = {
    participants: new Map(),
    prefill: null,
    enableFilters: false,
    locations: new Map(),
    positions: new Map(),
  };

  state = {
    term: '',
    added: new List(),
    matches: new List(),
    expanded: true,
    selected: -1,
    showFilters: false,
    filterLocations: new Set(),
    filterPositions: new Set(),
  };

  componentDidMount() {
    this.updatePrefill(this.props);
    this.updateUsers(this.props);
  }

  componentWillReceiveProps(props) {
    if (props.prefill !== this.props.prefill) {
      this.updatePrefill(props);
    } else if (props.users !== this.props.users) {
      this.updateUsers(props);
    }
  }

  componentDidUpdate(_prevProps, prevState) {
    if (prevState.added !== this.state.added && this.props.setUsers) {
      this.props.setUsers(this.state.added);
    }

    if (
      prevState.filterLocations !== this.state.filterLocations ||
      prevState.filterPositions !== this.state.filterPositions
    ) {
      this.props.updateTrackedFilters(this.state.filterLocations, this.state.filterPositions);
    }
  }

  updatePrefill(props) {
    const { prefill } = props;

    if (prefill) {
      this.setState({ added: prefill.userIds }, () => this.updateUsers(props));
    }
  }

  updateUsers(props) {
    const { users } = props;

    this.sifter = new Sifter(users.toJS());
    this.updateFilter();
  }

  updateFilter() {
    const { term, filterLocations, filterPositions, added } = this.state;
    const { user, users, participants } = this.props;
    const selfId = `${user.id}`;

    if (term) {
      const results = this.sifter.search(term, {
        fields: ['first_name', 'last_name'],
        sort: [
          { field: 'first_name', direction: 'asc' },
          { field: 'last_name', direction: 'asc' },
        ],
      });
      const matches = new List(
        results.items
          .map(item => {
            const user = users.get(`${item.id}`);
            if (
              !user ||
              user.id === selfId ||
              user.is_deleted ||
              added.includes(user.id) ||
              participants.has(`${user.id}`)
            ) {
              return false;
            }
            if (
              (filterLocations.size || filterPositions.size) &&
              !filterLocations.intersect(user.locations).size &&
              !filterPositions.intersect(user.positions).size
            ) {
              return false;
            }
            return user;
          })
          .filter(match => !!match),
      ).sortBy(user => user.fullName.toLowerCase());
      this.setState({ matches });
    } else {
      const matches = users
        .filter(user => {
          if (user.id === selfId || user.is_deleted || added.includes(user.id) || participants.has(`${user.id}`)) {
            return false;
          }
          if (
            (filterLocations.size || filterPositions.size) &&
            !filterLocations.intersect(user.locations).size &&
            !filterPositions.intersect(user.positions).size
          ) {
            return false;
          }
          return true;
        })
        .sortBy(user => user.fullName.toLowerCase());
      this.setState({ matches });
    }
  }

  onToggle = () => {
    if (e) {
      e.stopPropagation();
    }
    this.setState({ expanded: !this.state.expanded });
  };

  onKey = e => {
    const { selected, matches } = this.state;
    const { input, div } = this;
    let { added } = this.state;
    const key = e.which || e.keyCode;

    if (key === 37) {
      // left arrow
      if (document.activeElement === input) {
        if (input.selectionStart !== 0 && input.selectionEnd !== 0) {
          return; // ignore this and let the cursor be moved
        }
        if (added.size) {
          // going from the input to the last participant, move focus to the containing div
          div.focus();
          this.setState({ selected: added.size - 1 });
          return false;
        }
      } else if (selected > 0) {
        this.setState({ selected: selected - 1 });
        return false;
      }
    } else if (key === 39) {
      // right arrow
      if (document.activeElement === input) {
        return; // ignore this in the input
      }
      if (selected < added.size - 1) {
        this.setState({ selected: selected + 1 });
        return false;
      }
      // going from last participant to the input
      this.setState({ selected: -1 });
      input.select();
      return false;
    } else if (key === 8 || key === 46) {
      // backspace or delete
      if (document.activeElement !== input && selected >= 0 && selected < added.size) {
        added = added.splice(selected, 1);
        if (added.size === 0) {
          this.setState({ added, selected: -1 }, () => this.updateFilter());
          input.select();
        } else if (selected >= added.size) {
          this.setState({ added, selected: added.size - 1 }, () => this.updateFilter());
        } else {
          this.setState({ added }, () => this.updateFilter());
        }
        return false;
      }
    } else if (key === 9 || key === 10 || key === 13) {
      // tab or enter (linefeed or carriage return)
      if (matches.size === 1) {
        added = added.push(matches.get(0).id);
        this.setState({ added, term: '' }, () => this.updateFilter());
      }
      return false;
    }
  };

  onClickPicked = _e => {
    this.setState({ expanded: true, selected: -1 });
    this.input.focus();
  };

  onClickName(idx, e) {
    this.setState({ selected: idx, expanded: true });
    setTimeout(() => this.div.focus(), 10);

    if (e) {
      e.stopPropagation();
    }
  }

  onClickInput = () => {
    this.setState({ expanded: true });
  };

  termChange = e => {
    this.setState({ term: e.target.value }, () => this.updateFilter());
  };

  toggleFilters = e => {
    if (e) {
      e.preventDefault();
    }
    this.setState({ showFilters: !this.state.showFilters });
  };

  addUser(user, e) {
    if (e) {
      e.preventDefault();
    }

    this.setState({ added: this.state.added.push(user.id), term: '' }, () => this.updateFilter());
  }

  render() {
    const { term, filterLocations, filterPositions, added, matches, expanded, selected, showFilters } = this.state;
    const { users, account, enableFilters } = this.props;
    const shown = expanded ? added : added.slice(0, 2);
    const truncated = shown.size !== added.size;
    const numFilters = filterLocations.size + filterPositions.size;
    const userContents = [];

    let letter = null;

    matches.forEach(user => {
      const initial = user.first_name.charAt(0).toUpperCase();
      if (initial !== letter) {
        letter = initial;
        userContents.push(
          <div key={`letter-${letter}`} className="letter-separator">
            {letter}
          </div>,
        );
      }

      const avatarImgSrc = user.avatar_url ? `${user.avatar_url}/32` : user.getAvatar();

      userContents.push(
        <button type="button" key={`user-${user.id}`} onClick={this.addUser.bind(this, user)}>
          <img src={avatarImgSrc} alt="Avatar" />
          <span>{user.fullName}</span>
        </button>,
      );
    });

    let idx = 0;
    return (
      <div className="user-picker">
        <div
          className={`picked-box${truncated ? ' truncated' : ''}`}
          tabIndex="-1"
          onKeyDown={this.onKey}
          ref={div => {
            this.div = div;
          }}
        >
          <div className="picked" onClick={this.onClickPicked}>
            <span className="to">To: </span>
            {added.size > 2 ? (
              <button type="button" onClick={this.onToggle}>
                {expanded ? (
                  <FontIcon icon="chevron-up" />
                ) : (
                  [added.size - shown.size, ' more', <FontIcon key="collapseything" icon="chevron-down" />]
                )}
              </button>
            ) : null}
            {shown.map(uId => {
              const user = users.get(`${uId}`);
              const currentIndex = idx++;
              return (
                <span className="name" key={`added-${user.id}`} onClick={this.onClickName.bind(this, currentIndex)}>
                  {currentIndex > 0 ? ', ' : null}
                  <span className={selected === currentIndex ? 'selected' : ''}>{user.fullName}</span>
                </span>
              );
            })}
            {added.size ? ', ' : null}
            <input
              type="text"
              onChange={this.termChange}
              value={term}
              className={term ? 'has-text' : ''}
              onClick={this.onClickInput}
              ref={input => {
                this.input = input;
              }}
            />
          </div>
          {enableFilters ? (
            <button type="button" onClick={this.toggleFilters}>
              <FontIcon icon="preferences" srText="Toggle Filters" className="filter" />
            </button>
          ) : null}
          {numFilters ? <span className="filter-count">{numFilters}</span> : null}
          {showFilters ? this.renderFilters() : null}
        </div>
        <div className="user-list">
          {matches.size || !term ? (
            userContents
          ) : (
            <div className="no-results">Sorry, it appears this person is not a part of {account.company}</div>
          )}
        </div>
      </div>
    );
  }

  clearFilters = e => {
    if (e) {
      e.preventDefault();
    }

    this.setState({ filterLocations: new Set(), filterPositions: new Set() }, () => this.updateFilter());
  };

  togglePosition(id, _e) {
    let { filterPositions } = this.state;
    if (filterPositions.has(id)) {
      filterPositions = filterPositions.delete(id);
    } else {
      filterPositions = filterPositions.add(id);
    }
    this.setState({ filterPositions }, () => this.updateFilter());
  }

  toggleLocation(id, _e) {
    let { filterLocations } = this.state;
    if (filterLocations.has(id)) {
      filterLocations = filterLocations.delete(id);
    } else {
      filterLocations = filterLocations.add(id);
    }
    this.setState({ filterLocations }, () => this.updateFilter());
  }

  renderFilters() {
    const { positions, locations } = this.props;
    const { filterPositions, filterLocations } = this.state;

    return (
      <div className="filters">
        <div className="arrow" />
        <h3>
          <button type="button" onClick={this.clearFilters}>
            Clear All
          </button>
          Filters
        </h3>
        <h4 className="positions">Positions</h4>
        {positions
          .map(position => {
            if (position.is_deleted) {
              return null;
            }

            return (
              <button
                key={`filter-pos-${position.id}`}
                type="button"
                className={filterPositions.has(position.id) ? 'selected' : ''}
                onClick={this.togglePosition.bind(this, position.id)}
              >
                <span style={{ borderColor: `#${position.color}` }} />
                {position.name}
              </button>
            );
          })
          .valueSeq()}
        <h4 className="locations">Schedules</h4>
        {locations
          .map(location => {
            if (location.is_deleted) {
              return null;
            }

            return (
              <button
                key={`filter-loc-${location.id}`}
                type="button"
                className={filterLocations.has(location.id) ? 'selected' : ''}
                onClick={this.toggleLocation.bind(this, location.id)}
              >
                <span />
                {location.name}
              </button>
            );
          })
          .valueSeq()}
      </div>
    );
  }
}
