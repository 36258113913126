import EntityBase from 'data/EntityBase';
import { IndexedMap } from 'data/IndexedMap';
import Message from 'data/message/model';
import { FETCH_MESSAGES, UPDATE_MESSAGE, assertActionType } from 'store/action-types';
import type { MessageAction } from './actions';

import type { FetchMessagesFailure, FetchMessagesRequest, FetchMessagesSuccess } from './actions/fetchMessages';
import type { UpdateMessage } from './actions/updateMessageStore';

const stateDefaults = {
  items: new IndexedMap(Message, 'id', ['request_id', 'swap_id']),
};

export class MessageState extends EntityBase({
  items: new IndexedMap(Message, 'id', ['request_id', 'swap_id']),
}) {
  declare readonly items: (typeof stateDefaults)['items'];
}

export default (state = new MessageState(), action: MessageAction): MessageState => {
  switch (action.type) {
    case FETCH_MESSAGES.REQUEST:
      assertActionType<FetchMessagesRequest>(action);
      return state.merge({
        loading: true,
        receivedAt: 0,
        loaded: false,
      });
    case FETCH_MESSAGES.SUCCESS:
      assertActionType<FetchMessagesSuccess>(action);
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        receivedAt: Date.now(),
        items: state.items.add(action.payload.map(message => new Message(message))),
      });
    case FETCH_MESSAGES.FAILURE:
      assertActionType<FetchMessagesFailure>(action);
      return state.merge({
        loaded: false,
        loading: false,
        error: action.payload,
      });
    case UPDATE_MESSAGE:
      assertActionType<UpdateMessage>(action);
      return state.merge({
        items: state.items.delete(null).add(new Message(action.item)),
      });
    default:
      return state;
  }
};
