import type Account from 'data/account/model';
import type Plan from 'data/plan/model';
import type User from 'data/user/model';
import { MenuItem } from 'shared/components/nav/MenuItem';
import MenuItems from 'shared/components/nav/MenuItems';
import Features from 'shared/features';

type AppSettingsMenuProps = {
  authUser: User;
  authAccount: Account;
  accountPlan: Plan;
  isSetup: boolean;
};

const AppSettingsMenuItem = ({ authUser, authAccount, accountPlan, isSetup }: AppSettingsMenuProps) => {
  const billingUrl = accountPlan.isTrial() ? authAccount.plansUrl() : authAccount.billingUrl();

  return (
    <MenuItem className="menu-icon" hideLabel="desktop" icon="gear" label="Settings" dropdown>
      <MenuItems dropdownMenu>
        <MenuItem exact icon="settings" label="General Settings" show={!authAccount.isWorkchatFree()} to="/settings" />

        <MenuItem
          icon="no-time-off"
          label="Time Off Settings"
          show={authAccount.hasFeature(Features.TIME_OFF_REQUESTS, true)}
          to="/settings/timeoff"
        />

        <MenuItem
          icon="scheduling-settings"
          label="Scheduling Settings"
          show={authAccount.hasScheduling()}
          to="/settings/scheduling"
        />

        <MenuItem
          icon="attendance-settings"
          label="Attendance Settings"
          // only show the attendance settings menu if attendance is onboarded
          show={authAccount.hasAttendance() && isSetup}
          to="/settings/attendance"
        />

        <MenuItem icon="integrations" label="Integrations" to="/settings/integrations" />

        <MenuItem icon="billing" label="Account & Billing" show={authUser.canBilling()} to={billingUrl} />
      </MenuItems>
    </MenuItem>
  );
};

export default AppSettingsMenuItem;
