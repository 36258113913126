import { useEffect } from 'react';

export default () => {
  useEffect(() => {
    if (!document.body.classList.contains('bg-white')) {
      // Change the single non-full width page background to white
      document.body.classList.add('bg-white');
    }
    return () => {
      // Ensure we remove the class when we leave the page so the rest of the app isn't wonky
      document.body.classList.remove('bg-white');
    };
  }, []);
};
