import FontIcon from 'shared/ui/FontIcon';
import { useWiwSelector } from 'store';
import { getWorkchatConnected } from 'workchat/v2/store/selectors';

import 'workchat/v2/components/MessageV2/MessageMenu/MessageMenu.scss';

interface MessageMenuProps {
  expanded: boolean;
  canReact?: boolean;
  canCopy?: boolean;
  canEdit?: boolean;
  canDelete?: boolean;
  onReact?: () => void;
  onCopy?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  onExpand?: () => void;
  onClose?: () => void;
}

export default function MessageMenu({
  expanded = false,
  canReact,
  canCopy,
  canEdit,
  canDelete,
  onCopy,
  onEdit,
  onReact,
  onDelete,
  onExpand,
  onClose,
}: MessageMenuProps) {
  const connected = useWiwSelector(getWorkchatConnected);

  const renderMenuItems = () => {
    if (expanded) {
      return (
        <div className="expanded-menu">
          {canEdit && (
            <button
              type="button"
              disabled={!connected}
              role="menuitem"
              aria-roledescription="Edit button (Edit message)"
              className="message-menu-btn"
              onClick={() => {
                onEdit?.();
                onClose?.();
              }}
            >
              <FontIcon icon="edit" />
            </button>
          )}
          {canDelete && (
            <button
              type="button"
              disabled={!connected}
              role="menuitem"
              aria-roledescription="Delete button (Delete message)"
              className="message-menu-btn"
              onClick={() => {
                onDelete?.();
                onClose?.();
              }}
            >
              <FontIcon icon="delete" />
            </button>
          )}
          {canReact && (
            <button
              type="button"
              disabled={!connected}
              role="menuitem"
              aria-roledescription="Reaction button (React message)"
              className="message-menu-btn"
              onClick={() => {
                onReact?.();
                onClose?.();
              }}
            >
              <FontIcon icon="add-smiling-face" />
            </button>
          )}
          {canCopy && (
            <button
              type="button"
              role="menuitem"
              aria-roledescription="Copy button (Copy message)"
              className="message-menu-btn"
              onClick={() => {
                onCopy?.();
                onClose?.();
              }}
            >
              <FontIcon icon="copy" />
            </button>
          )}
          <button
            type="button"
            role="menuitem"
            aria-roledescription="Close button (Dismiss message actions)"
            className="message-menu-btn"
            onClick={() => onClose?.()}
          >
            <FontIcon icon="close" />
          </button>
        </div>
      );
    }

    if (canCopy || canEdit || canDelete || canReact) {
      return (
        <button
          type="button"
          role="menuitem"
          aria-roledescription="Options button (Show message actions)"
          className="message-menu-btn"
          onClick={() => onExpand?.()}
        >
          <FontIcon icon="more-horizontal-dots" />
        </button>
      );
    }

    return;
  };

  return (
    <div role="menu" className="MessageMenu">
      {renderMenuItems()}
    </div>
  );
}
