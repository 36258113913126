import { useEffect, useRef, useState } from 'react';

import { useLDFlag } from 'data/LD/selectors/getLDFlag';
import FontIcon from 'shared/ui/FontIcon';

import classNames from 'classnames';

import './assets/styles/SupportButton.scss';

export function SupportButton() {
  const [isVisible, setIsVisible] = useState(true); // Controls both opacity and transform
  const [unreadCount, setUnreadCount] = useState(0);
  const [hasZendesk, setHasZendesk] = useState(!!window.zE);
  const isAnimating = useRef(false);

  const zendeskCustomLauncherFlag = useLDFlag('zendesk-custom-launcher');

  // Check for Zendesk availability
  useEffect(() => {
    if (hasZendesk) {
      return;
    }

    const checkInterval = setInterval(() => {
      if (window.zE) {
        setHasZendesk(true);
        clearInterval(checkInterval);
      }
    }, 1000);

    return () => clearInterval(checkInterval);
  }, [hasZendesk]);

  useEffect(() => {
    // Don't setup listeners if Zendesk is MIA
    if (!hasZendesk || !zendeskCustomLauncherFlag) {
      return;
    }

    const zE = window.zE!; // We know it exists when hasZendesk is true

    // Make fully visible anytime the Messenger closes
    zE('messenger:on', 'close', () => {
      setIsVisible(true);
    });

    // Make hidden anytime the Messenger opens
    zE('messenger:on', 'open', () => {
      if (!isAnimating.current) {
        setIsVisible(false);
      }
    });

    // Look out for unread messages and update
    zE('messenger:on', 'unreadMessages', (count = 0) => {
      setUnreadCount(count);
    });
  }, [zendeskCustomLauncherFlag, hasZendesk]);

  const handleClick = () => {
    isAnimating.current = true;
    setIsVisible(false);
    setUnreadCount(0);
    // Wait for animation to complete before opening Zendesk
    setTimeout(() => {
      if (window?.zE) {
        window.zE('messenger', 'open');
      }
      // Reset animation flag after animation completes
      setTimeout(() => {
        isAnimating.current = false;
      }, 100);
    }, 300);
  };

  // Don't render if Zendesk doesn't exist
  if (!hasZendesk || !zendeskCustomLauncherFlag) {
    return null;
  }

  return (
    <div
      className={classNames('support-button', {
        'is-visible': isVisible,
        'has-unread': unreadCount > 0,
      })}
      onClick={handleClick}
    >
      <FontIcon icon="chat" />
      {unreadCount > 0 && <div className="notification-bubble">{unreadCount > 9 ? '9+' : unreadCount}</div>}
    </div>
  );
}

export default SupportButton;
