export default class ChatHelper {
  static canChat(user, plan) {
    if (plan.isFreemium()) {
      return false;
    }
    return user.canSupervise();
  }

  /**
   * Whether to use livechat with sales (via Intercom) instead of with Care (via Zendesk Chat).
   */
  static chatWithSalesNotCare(plan, account) {
    //We are assuming here that all child accounts will have a paid parent
    if (account.isChild()) {
      return false;
    }

    return plan.isTrial();
  }
}
