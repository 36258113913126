import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { canViewDocStorage } from 'documents/selectors/canViewDocStorage';
import Redirection from 'shared/components/Redirection';

export default function hasDocStorage(ComposedComponent) {
  class HasDocStorage extends PureComponent {
    static propTypes = {
      canViewDocStorage: PropTypes.bool.isRequired,
    };

    render() {
      if (this.props.canViewDocStorage) {
        return <ComposedComponent {...this.state} {...this.props} />;
      }

      // Default to redirect
      return <Redirection legacy location={{ pathname: '/' }} />;
    }
  }

  return connect(
    state => ({
      canViewDocStorage: canViewDocStorage(state),
    }),
    null,
  )(HasDocStorage);
}
