const DateFormat = {
  sameDay: '[Today]',
  nextDay: '[Tomorrow]',
  nextWeek: '[Next] dddd',
  lastDay: '[Yesterday]',
  lastWeek: 'dddd',
  sameElse: 'MMM D, YYYY',
};

const TimeFormat = {
  sameDay: 'h:mm A',
  nextDay: '[Tomorrow]',
  nextWeek: '[Next] dddd',
  lastDay: '[Yesterday]',
  lastWeek: 'dddd',
  sameElse: 'MMM D, YYYY',
};

const ConversationDateFormat = {
  sameDay: 'h:mm A',
  nextDay: '[Tomorrow]',
  nextWeek: '[Next] dddd',
  lastDay: '[Yesterday]',
  lastWeek: 'MMM D',
  sameElse: 'MMM D',
};

export { DateFormat, TimeFormat, ConversationDateFormat };
