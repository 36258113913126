import EntityState from 'data/EntityState';
import fetchLogin from 'data/login/actions/fetchLogin';
import Login from 'data/login/model';
import generateThunkReducers from 'data/util/generateThunkReducers';
import { toEntityMap } from 'shared/util/toEntityMap';

import { createSlice } from '@reduxjs/toolkit';

const initialState = new EntityState<Login>();

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchLogin.fulfilled, (state, action) => {
      return state.mergeDeep({ items: toEntityMap(action.payload.login, Login) });
    });
    generateThunkReducers(builder, 'login');
  },
});

export default loginSlice.reducer;
