import { WORKCHAT_SCREEN_POP, WORKCHAT_SCREEN_PUSH, WORKCHAT_SCREEN_UNWIND } from 'workchat/action-types';

export function pushScreen(screen) {
  return {
    type: WORKCHAT_SCREEN_PUSH,
    screen,
  };
}

export function popScreen() {
  return {
    type: WORKCHAT_SCREEN_POP,
  };
}

export function unwindToScreen(screen) {
  return {
    type: WORKCHAT_SCREEN_UNWIND,
    screen,
  };
}
