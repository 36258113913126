import { setDayOrWeekByCard } from 'dashboard/actions/setDayOrWeekByCard';
import { setIntervalByCard } from 'dashboard/actions/setIntervalByCard';
import { setTodaysScheduleLocationId } from 'dashboard/actions/setTodaysScheduleLocationId';
import { DashboardCard } from 'dashboard/util';
import { DayOrWeek, NoticeFilterType } from 'dashboard/util/constants';
import type Location from 'data/location/model';
import { SET_ATTENDANCE_NOTICES_FILTERS } from 'store/action-types';

import type { AnyAction } from '@reduxjs/toolkit';
import { Map as IMap } from 'immutable';
import type { Interval } from 'luxon';

interface ImmutableMap<T> extends IMap<string, any> {
  get<K extends keyof T>(name: K): T[K];
}

export type IntervalByCard = IMap<DashboardCard, Interval>;
export type DayOrWeekByCard = IMap<DashboardCard, DayOrWeek>;

export type DashboardState = ImmutableMap<{
  attendanceNoticesFilters: Map<string, boolean>;
  dayOrWeekByCard: DayOrWeekByCard;
  intervalByCard: IntervalByCard;
  todaysScheduleLocationId: Location['id'];
}>;

export const DefaultNoticeFilters = IMap({
  [NoticeFilterType.NoShow]: false,
  [NoticeFilterType.EarlyClockIn]: false,
  [NoticeFilterType.LateClockIn]: false,
  [NoticeFilterType.EarlyClockOut]: false,
  [NoticeFilterType.LateClockOut]: false,
  [NoticeFilterType.ForgotClockOut]: false,
  [NoticeFilterType.NotScheduled]: false,
  [NoticeFilterType.WrongLocation]: false,
});

export const initialState: DashboardState = IMap({
  attendanceNoticesFilters: DefaultNoticeFilters,
  dayOrWeekByCard: IMap({
    [DashboardCard.ShiftCoverage]: DayOrWeek.WEEK,
    [DashboardCard.LaborCost]: DayOrWeek.WEEK,
  }),
  intervalByCard: IMap(),
  todaysScheduleLocationId: 0,
});

export default function (state = initialState, action: AnyAction) {
  switch (action.type) {
    case SET_ATTENDANCE_NOTICES_FILTERS:
      return state.set('attendanceNoticesFilters', action.payload);
  }
  switch (true) {
    case setDayOrWeekByCard.match(action):
      return state.set('dayOrWeekByCard', state.get('dayOrWeekByCard').set(action.card, action.dayOrWeek));
    case setIntervalByCard.match(action):
      return state.set('intervalByCard', state.get('intervalByCard').set(action.card, action.interval));
    case setTodaysScheduleLocationId.match(action):
      return state.set('todaysScheduleLocationId', action.payload);
  }
  return state;
}
