import type { EnvMapping, EnvironmentsMapping, GitlabDeploymentsList } from 'shared/dev/types/types';
import Environment from 'shared/util/environment';
import Gitlab from './gitlab';

const service = new Gitlab({
  url: 'https://workbot.api.wheniwork-staging.com/gitlab',
  apiVersion: 'v4',
  admin: false,
});
export default service;

export const wiwjsProjectID = 3;

export const getBranches = (
  projectID = wiwjsProjectID,
  query: string,
  replaceSlashes = false,
  defaultBranch = 'master',
) => {
  return service.send(`/projects/${projectID}/repository/branches?search=${query}`).then(branches => {
    if (!branches.filter((branch: { name: string }) => branch.name === defaultBranch).length) {
      branches.push({ name: defaultBranch });
    }
    return branches.map((branch: { name: string }) => ({
      label: branch.name,
      value: replaceSlashes ? branch.name.replace('/', '-') : branch.name,
    }));
  });
};

export const getCurrentVersion = async (projectID: number = wiwjsProjectID, envIDs: number[]) => {
  if (!envIDs.length) {
    return Promise.resolve(null);
  }
  const response = await Promise.all(
    envIDs.map(envID => {
      return service.send(`/projects/${projectID}/environments/${envID}`);
    }),
  );

  const result: EnvironmentsMapping = {};

  response.forEach(res => {
    // split the env name by / to get prefix
    const name = res.name.split('/')[0];
    result[name] = res;
  });

  return result;
};

export const getLatestDeploymentForEnv = async (projectId: number, envIDs?: EnvMapping) => {
  const env: keyof EnvMapping = Environment.get();
  const defaultEnvs: EnvMapping = {
    production: 'production',
    staging: 'staging',
    acceptance: 'acceptance',
  };

  if (!envIDs) {
    envIDs = defaultEnvs;
  }

  const encodedEnv = encodeURI(`${envIDs[env] || defaultEnvs.staging}`);
  try {
    const params = new URLSearchParams({
      environment: encodedEnv,
      status: 'success',
      sort: 'desc',
    });

    const deployments: GitlabDeploymentsList = await service.send(
      `/projects/${projectId}/deployments?${params.toString()}`,
    );
    const latestDeployment = deployments[0];

    return Promise.resolve({
      environment: latestDeployment.environment,
      deploy: latestDeployment.deployable,
      status: latestDeployment.status,
    });
  } catch (e) {
    console.error('Failed to find gitlab environment for,', encodedEnv);
    return Promise.reject(`Failed to find gitlab environment for, ${encodedEnv}`);
  }
};
