import { bindActionCreators } from '@reduxjs/toolkit';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import FontIcon from 'shared/ui/FontIcon';
import { TwilioProvider } from 'workchat/TwilioProvider';
import { clearTrackedFilters, popScreen, pushScreen, setPendingConversation, trackFilterUse } from 'workchat/actions';
import MessageInput from 'workchat/components/MessageInput';
import ParticipantList from 'workchat/components/ParticipantList';
import { withTwilioProvider } from 'workchat/components/withTwilioProvider';
import Header from 'workchat/containers/Header';
import NewConversation from 'workchat/containers/NewConversation';
import ViewConversation from 'workchat/containers/ViewConversation';

import 'workchat/styles/StartConversation.scss';
class StartConversation extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    provider: PropTypes.instanceOf(TwilioProvider).isRequired,
    users: PropTypes.instanceOf(Map).isRequired,
    pending: PropTypes.object.isRequired,

    pushScreen: PropTypes.func.isRequired,
    popScreen: PropTypes.func.isRequired,
    setPendingConversation: PropTypes.func.isRequired,
  };

  state = {
    sending: false,
  };

  back = () => {
    this.props.popScreen();
    this.props.pushScreen(NewConversation);
  };

  sendMessage = (text, image) => {
    if (text || image) {
      const { provider, pending, trackFilterUse, clearTrackedFilters, setPendingConversation, pushScreen, popScreen } =
        this.props;

      this.setState({ sending: true });
      const addedIds = pending.userIds.toJS();
      provider.createConversation(addedIds, text, pending.title, image).then(_data => {
        popScreen();
        pushScreen(ViewConversation);
        trackFilterUse(true, 'CreateChannel');
        clearTrackedFilters();
        setPendingConversation();
      });
    }
  };

  render() {
    const { user, users, pending } = this.props;
    const { sending } = this.state;
    const buttons = [
      {
        title: 'Back',
        action: this.back,
        label: [<FontIcon className="mr-1" key="back" icon="chevron-left" />, 'Back'],
        side: 'left',
        color: 'secondary',
      },
    ];
    const convType = pending.userIds.size > 1 ? 'group' : 'individual';
    const firstUser = users.get(`${pending.userIds.first()}`) || { fullName: '???' };
    const participants = new Map().withMutations(map => {
      pending.userIds.forEach(id => map.set(`${id}`, id));
    });
    const conversation = {
      participants,
      attributes: { external_type: 'group' },
    };
    const title = pending.title ? pending.title : convType === 'individual' ? firstUser.fullName : 'Group WorkChat';

    return (
      <div className="start-conversation">
        <Header showBroadcast={false} buttons={buttons}>
          {title}
        </Header>
        {convType !== 'individual' ? <ParticipantList conversation={conversation} users={users} user={user} /> : null}
        <div className="splash">
          <div className={convType} />
          <h4>Start chatting with {convType === 'group' ? 'the group' : firstUser.fullName}!</h4>
        </div>
        <MessageInput canAttach sending={sending} sendMessage={this.sendMessage} />
      </div>
    );
  }
}

export default connect(
  // State to send to the Component as props
  state => ({
    users: state.workchat.get('users') || new Map(),
    pending: state.workchat.get('pendingConversation') || null,
  }),
  // Dispatch functions to send to the Component as props
  dispatch =>
    bindActionCreators(
      {
        pushScreen,
        popScreen,
        setPendingConversation,
        trackFilterUse,
        clearTrackedFilters,
      },
      dispatch,
    ),
)(withTwilioProvider(StartConversation));
