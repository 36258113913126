import { fetchAccountMetadataIfNeeded, fetchUserMetadataIfNeeded, updateAccountMetadata } from 'data/metadata/actions';
import {
  ONBOARDING_PRODUCT_ATTENDANCE_DESIRED,
  ONBOARDING_PRODUCT_SCHEDULING_DESIRED,
  ONBOARDING_SCHEDULE_PUBLISHED,
  ONBOARDING_SCHEDULING_CHECKLIST_CLOSED,
  ONBOARDING_SCHEDULING_FULL_SCREEN_CLOSED,
  ONBOARDING_SHIFT_CREATED,
  ONBOARDING_USER_CREATED,
} from 'onboarding/metadataConstants';
import { getIsLocalStorageMigrated } from 'onboarding/selectors';
import { SCHEDULER_ONBOARDING_LEGACY_CHECKLIST_DISMISSED } from 'scheduler/util/cookies';
import { getAccountId } from 'shared/auth/selectors';

import Cookies from 'js-cookie';

/*

This function migrateSchedulerOnboardingLocalStorage now migrates any existing onboarding state from
localstorage to metadata, then deletes the localstorage key.

Example checklist_state:

{
    "steps": {
        "addEmployee": false,
        "createShift": false,
        "publishSchedule": false
    },
    "checklistDismissed": false,
    "dialogDismissed": true,
    "employeeCount": null,
    "industryName": "support",
    "selectedIndustry": "38",
    "wantsAttendance": true
}

*/

function checklistDismissedByCookie() {
  try {
    return !!JSON.parse(Cookies.get(SCHEDULER_ONBOARDING_LEGACY_CHECKLIST_DISMISSED, { domain: CONFIG.COOKIE_DOMAIN }));
  } catch (e) {
    return false;
  }
}

export function migrateSchedulerOnboardingLocalStorage() {
  return (dispatch, getState) => {
    if (getIsLocalStorageMigrated(getState())) {
      return Promise.resolve();
    }

    const localStorageKey = `checklist_state${getAccountId(getState())}`;
    const promises = [];

    return Promise.all([dispatch(fetchAccountMetadataIfNeeded()), dispatch(fetchUserMetadataIfNeeded())]).then(() => {
      let stateValues = null;
      try {
        stateValues = JSON.parse(localStorage.getItem(localStorageKey));
      } catch (e) {
        // Would only happen if value tampered with
        console.warn(`Removing non parsable localStorage item with key: ${localStorageKey}`, e);
        localStorage.removeItem(localStorageKey);
      }

      const accountMetadataUpdate = {};

      // Migrate LocalStorage values to Account Metadata
      if (stateValues) {
        accountMetadataUpdate[ONBOARDING_PRODUCT_SCHEDULING_DESIRED] = true;

        if (stateValues.dialogDismissed || stateValues.checklistDismissed) {
          accountMetadataUpdate[ONBOARDING_SCHEDULING_FULL_SCREEN_CLOSED] = true;
        }

        if (stateValues.wantsAttendance) {
          accountMetadataUpdate[ONBOARDING_PRODUCT_ATTENDANCE_DESIRED] = true;
        }

        if (stateValues.steps) {
          const { addEmployee, createShift, publishSchedule } = stateValues.steps;

          if (addEmployee) {
            accountMetadataUpdate[ONBOARDING_USER_CREATED] = true;
          }
          if (createShift) {
            accountMetadataUpdate[ONBOARDING_SHIFT_CREATED] = true;
          }
          if (publishSchedule) {
            accountMetadataUpdate[ONBOARDING_SCHEDULE_PUBLISHED] = true;
          }

          if (addEmployee && createShift && publishSchedule) {
            accountMetadataUpdate[ONBOARDING_SCHEDULING_CHECKLIST_CLOSED] = true;
          }
        }
      }

      // Migrate cookie or LocalStorage values to User Metadata
      if (checklistDismissedByCookie() || stateValues?.checklistDismissed) {
        accountMetadataUpdate[ONBOARDING_SCHEDULING_CHECKLIST_CLOSED] = true;
      }

      if (Object.keys(accountMetadataUpdate).length > 0) {
        promises.push(dispatch(updateAccountMetadata(accountMetadataUpdate)));
      }

      return promises.length
        ? Promise.all(promises).then(() => {
            localStorage.removeItem(localStorageKey);
            Cookies.remove(SCHEDULER_ONBOARDING_LEGACY_CHECKLIST_DISMISSED, { domain: CONFIG.COOKIE_DOMAIN });
          })
        : Promise.resolve();
    });
  };
}
