import type { Dataset } from 'data/reporting/models/Dataset';
import { getCustomMetadata } from 'data/reporting/selectors/getCustomMetadata';
import type { CustomMetadataState } from 'data/reporting/state/CustomMetadataState';
import { getReportAccountId } from 'reporting/selectors/getReportAccountId';
import { getReportDataset } from 'reporting/selectors/getReportDataset';

import { createSelector } from '@reduxjs/toolkit';

export const getColumnDetails = createSelector(
  getCustomMetadata,
  getReportAccountId,
  getReportDataset,
  (metadata, reportAccountId, dataset) => {
    return (metadata.getIn([`${reportAccountId}`, dataset]) as CustomMetadataState)?.columns;
  },
);

export function getColumnDetailsForDataset(dataset: Dataset) {
  return createSelector(getCustomMetadata, getReportAccountId, (metadata, reportAccountId) => {
    return (metadata.getIn([`${reportAccountId}`, dataset]) as CustomMetadataState)?.columns;
  });
}
