import type Site from 'data/site/model';
import type { SiteFields } from 'data/site/model';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

export type UpdateSiteResponse = {
  site: SiteFields;
};

export const updateSite = createWiwAsyncThunk<UpdateSiteResponse, Site>(
  'site/update',
  async (site, { rejectWithValue }) => {
    try {
      return (await Monolith.url(`/sites/${site.id}`).put(site.toJSON())) as UpdateSiteResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
