import { useClipboard } from '@mantine/hooks';
import moment from 'moment-timezone';

import classnames from 'classnames';
import { useLDFlag } from 'data/LD/selectors/getLDFlag';
import type User from 'data/user/model';
import { openDialog } from 'dialogs';
import { successNotice } from 'notices';
import { getAuthUser } from 'shared/auth/selectors';
import Mercury, { WORKCHAT_INTERACTION } from 'shared/util/mercury';
import { useWiwDispatch, useWiwSelector } from 'store';
import TruncatedList from 'workchat/components/TruncatedList';
import { DELETE_MESSAGE_DIALOG } from 'workchat/dialogs';
import { emojimartUnifiedToUnicodeHex, getWorkchatUserShortName } from 'workchat/utils';
import { type ImageType, MessageImage } from 'workchat/v2/components/MessageV2/MessageImage';
import MessageMenu from 'workchat/v2/components/MessageV2/MessageMenu/MessageMenu';
import MessageReactions, {
  type MessageReaction,
} from 'workchat/v2/components/MessageV2/MessageReactions/MessageReactions';
import { MessageText } from 'workchat/v2/components/MessageV2/MessageText';
import { VIEW_RECEIPTS } from 'workchat/v2/components/ViewReceiptsV2';
import { type Emoji, WORKCHAT_ONINTERACTION, WORKCHAT_ONINTERACTION_ACTIONS } from 'workchat/v2/constants';
import useMessageProvider from 'workchat/v2/hooks/useMessageProvider';
import useReactionPicker from 'workchat/v2/hooks/useReactionPickerProvider';
import { reactWorkchatMessage } from 'workchat/v2/store/messages/messagesActions';
import { setMessageReaders, viewStackPush } from 'workchat/v2/store/reducer';

import 'workchat/v2/components/MessageV2/MessageV2.scss';

interface MessageProps {
  id: string;
  conversationId: string;
  user?: User | null;
  individual: boolean;
  sentAt: any;
  skipByline?: boolean;
  image?: ImageType;
  text?: string | null;
  reactions?: MessageReaction[];
  readers?: User[] | null;
  readByAll?: boolean;
  isSystemMessage?: boolean;
}

export default function MessageV2({
  id,
  conversationId,
  user,
  individual,
  sentAt,
  skipByline = false,
  image = undefined,
  text = undefined,
  reactions,
  readers,
  readByAll = false,
  isSystemMessage = false,
}: MessageProps) {
  const { menuOpenedForMessage, setMenuOpenedForMessage, getMessageActionsForAuthorRole } = useMessageProvider();
  const dispatch = useWiwDispatch();
  const myUser = useWiwSelector(getAuthUser);
  const clipboard = useClipboard();
  const fdt876 = useLDFlag('fdt-876-work-chat-reactions-to-messages');
  const reactionPicker = useReactionPicker();

  const ownMessage = user?.id === myUser.id;

  const viewReceipts = () => {
    dispatch(setMessageReaders(readers?.map(reader => reader.id) || []));
    dispatch(viewStackPush(VIEW_RECEIPTS));
  };

  const getReceiptPrefix = () => {
    if (readByAll) {
      return individual ? 'Read' : 'Read by everyone';
    }
    if (readers?.length) {
      return 'Read by ';
    }
    return 'Delivered';
  };

  const renderReceiptButton = () => {
    const hasReaders = readByAll || readers;

    if ((!individual || ownMessage) && hasReaders) {
      const truncatedList = (
        <TruncatedList
          prefix={getReceiptPrefix()}
          items={readers && !readByAll ? readers?.map(user => getWorkchatUserShortName(user)) : []}
          width={175}
          formatOverflow={(extra: string) => ` +${extra}`}
        />
      );

      if (individual) {
        return <div className="read-receipt">{truncatedList}</div>;
      }

      return (
        <button className="read-receipt" type="button" onClick={viewReceipts}>
          {truncatedList}
        </button>
      );
    }
  };

  const handleCopy = async () => {
    if (!text) {
      return;
    }

    Mercury.track(WORKCHAT_INTERACTION, {
      ...WORKCHAT_ONINTERACTION,
      action: WORKCHAT_ONINTERACTION_ACTIONS.messageMenuCopyClick,
      annotations: JSON.stringify({
        messageSid: id,
        messageUserId: user?.id,
        conversationSid: conversationId,
      }),
    });

    clipboard.copy(text);
    dispatch(successNotice('Copied to Clipboard', { duration: 1 }));
  };

  const handleAddReaction = () => {
    return reactionPicker.pickReaction().then(emoji => {
      if (!emoji || !(emoji as Emoji).unified) {
        return;
      }

      const emojiUnicodeHex = emojimartUnifiedToUnicodeHex((emoji as Emoji).unified);
      const foundReaction = reactions?.find(reaction => reaction.unicode === emojiUnicodeHex);
      const foundUser = foundReaction?.userIds.find(userId => userId === myUser?.id);

      if (!foundUser) {
        dispatch(reactWorkchatMessage({ conversationId, messageId: id, reaction: (emoji as Emoji).native }));
      }
    });
  };

  const handleDelete = () => {
    Mercury.track(WORKCHAT_INTERACTION, {
      ...WORKCHAT_ONINTERACTION,
      action: WORKCHAT_ONINTERACTION_ACTIONS.messageMenuDeleteClick,
      annotations: JSON.stringify({
        messageSid: id,
        messageUserId: user?.id,
        conversationSid: conversationId,
      }),
    });

    return dispatch(
      openDialog(DELETE_MESSAGE_DIALOG, {
        conversationId,
        messageId: id,
        messageUserId: user?.id,
        authorName: user ? getWorkchatUserShortName(user) : '???',
        sentAt,
        text,
        image,
      }),
    );
  };

  const renderAvatar = () => {
    if (individual) {
      return;
    }

    if (isSystemMessage) {
      return <div className="wiw avatar" />;
    }
    return <img className="avatar" src={user?.getAvatarUrl('small') || ''} alt="Avatar" />;
  };

  const renderHeader = () => {
    if (isSystemMessage || skipByline) {
      return;
    }

    return (
      <div className="byline">
        <span className="by-description">
          {individual ? null : `${user ? getWorkchatUserShortName(user) : '???'} - `}
          {moment(sentAt).format('MMM Do [at] h:mma')}
        </span>
        <div className="message-menu-mobile-spacer" />
        <div className="message-menu-container">
          <MessageMenu
            expanded={menuOpenedForMessage === id}
            canCopy={!!text && text.length > 0}
            canReact={fdt876}
            canEdit={false}
            canDelete={ownMessage || getMessageActionsForAuthorRole(user?.role).canDelete}
            onCopy={handleCopy}
            onEdit={() => console.log('🍔 Burgers are cool to edit!')}
            onReact={handleAddReaction}
            onDelete={handleDelete}
            onExpand={() => setMenuOpenedForMessage(id)}
            onClose={() => setMenuOpenedForMessage(null)}
          />
        </div>
      </div>
    );
  };

  const renderMessageReactions = () => {
    if (!fdt876) {
      return null;
    }

    return (
      <MessageReactions
        conversationId={conversationId}
        messageId={id}
        reactions={reactions}
        onAddReaction={handleAddReaction}
        alignRight={ownMessage}
      />
    );
  };

  return (
    <div
      className={classnames('MessageV2', { 'sent-line': ownMessage, 'recv-line': !ownMessage, individual: individual })}
    >
      <div className="message-with-avatar-container">
        {renderAvatar()}
        <div className="message-container">
          {renderHeader()}
          {text && <MessageText text={text} />}
          {image && <MessageImage withText={!!text} image={image} />}
        </div>
      </div>
      {renderMessageReactions()}
      {renderReceiptButton()}
    </div>
  );
}
