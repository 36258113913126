import EntityState from 'data/EntityState';
import { deleteLocation, deleteLocations } from 'data/location/actions/deleteLocation';
import { createSite } from 'data/site/actions/createSite';
import { deleteSite, deleteSites } from 'data/site/actions/deleteSite';
import { fetchSites } from 'data/site/actions/fetchSites';
import { updateSite } from 'data/site/actions/updateSite';
import Site from 'data/site/model';
import forwardActionValue from 'data/util/forwardActionValue';
import generateThunkReducers from 'data/util/generateThunkReducers';
import { optimisticallyDeleteEntity, optimisticallyUpdateEntity } from 'data/util/optimisticUpdate';
import { deleteEntityItems, mergeEntityItems } from 'data/util/sliceHelpers';

import { createSlice } from '@reduxjs/toolkit';

export const initialState = new EntityState();

export const siteSlice = createSlice({
  name: 'site',
  initialState,
  reducers: {
    removeSitesForLocation: (state, action) => {
      return state.merge({
        items: state.items.filter(item => item.location_id !== action.payload),
      });
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSites.fulfilled, (state, action) => {
        return mergeEntityItems(state, action.payload.sites, Site);
      })
      .addCase(deleteSites.fulfilled, (state, action) => {
        return deleteEntityItems(state, action);
      })
      .addCase(deleteLocation.fulfilled, (state, action) => {
        return siteSlice.caseReducers.removeSitesForLocation(state, forwardActionValue(action, 'deleted_location'));
      })
      .addCase(deleteLocations.fulfilled, (state, action) => {
        return state.merge({
          items: state.items.withMutations(maps => {
            action.payload.map(item => {
              maps.delete(maps.findKey(site => site.location_id === item.deleted_location));
            });
          }),
        });
      });
    optimisticallyDeleteEntity(builder, deleteSite);
    optimisticallyUpdateEntity(builder, updateSite, Site, { payloadPath: 'site' });
    optimisticallyUpdateEntity(builder, createSite, Site, { payloadPath: 'site' });
    generateThunkReducers(builder, 'site');
  },
});

export default siteSlice.reducer;
