import { TOGGLE_TEMPLATE_OVERLAY } from 'store/action-types';

export function toggleTemplateOverlay(templateID = null) {
  return dispatch => {
    return dispatch({
      type: TOGGLE_TEMPLATE_OVERLAY,
      payload: templateID,
    });
  };
}
