import Plan from 'data/plan/model';
import PlanState from 'data/plan/state';
import generateThunkReducers from 'data/util/generateThunkReducers';

import fetchPlans from './actions/fetchPlans';

import { createSlice } from '@reduxjs/toolkit';
import { fromJS } from 'immutable';

const initialState = new PlanState();

export const planSlice = createSlice({
  name: 'plan',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchPlans.fulfilled, (state, action) => {
      return state.merge({
        features: state.features.merge(fromJS(action.payload.features)),
        items: state.items.merge(action.payload.plans.map(plan => [plan.id, new Plan(plan)])),
      });
    });
    generateThunkReducers(builder, 'plan');
  },
});

export default planSlice.reducer;
