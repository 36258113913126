// @ts-nocheck
// !todo: for the love of god remove this when appropriate
import { createSlice } from '@reduxjs/toolkit';
import User from 'data/user/model';
import { List, Map, Record, Stack } from 'immutable';
import {
  WORKCHAT_CONFIRM_MODAL,
  WORKCHAT_CONNECTED,
  WORKCHAT_DISCONNECTED,
  WORKCHAT_ERROR,
  WORKCHAT_LEFT_CONVERSATION,
  WORKCHAT_LOADING_CONVERSATIONS,
  WORKCHAT_LOADING_USERS,
  WORKCHAT_LOAD_CONVERSATIONS,
  WORKCHAT_LOAD_USERS,
  WORKCHAT_MARK_UNREAD,
  WORKCHAT_MESSAGE_RECEIPTS,
  WORKCHAT_RETRY_ERROR,
  WORKCHAT_SCREEN_POP,
  WORKCHAT_SCREEN_PUSH,
  WORKCHAT_SCREEN_UNWIND,
  WORKCHAT_SEND_MESSAGE,
  WORKCHAT_SET_CONVERSATION,
  WORKCHAT_SET_FILTERS,
  WORKCHAT_SET_PENDING_CONVERSATION,
  WORKCHAT_START,
  WORKCHAT_TOGGLE,
  WORKCHAT_UPDATE_CONVERSATION,
} from 'workchat/action-types';
import type { TwilioConversation } from 'workchat/types/TwilioConversation';
import type { TwilioParticipant } from 'workchat/types/TwilioParticipant';
import type { TwilioUser } from 'workchat/types/TwilioUser';

export interface WorkchatModel {
  title?: string;
  text?: string;
  confirm?: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export interface WorkchatState {
  connected: boolean;
  conversation?: TwilioConversation;
  conversations: Map<string, TwilioConversation>;
  conversationsLoading: false;
  error: null;
  filters: {
    positions: null;
    locations: null;
  };
  flags: Map<string, boolean>;
  messages: List<any>;
  modal?: WorkchatModel;
  pendingConversation: null;
  screens: Stack<any>;
  sending: false;
  show: false;
  unread: 0;
  users: Map<string, TwilioUser>;
  usersLoading: false;
  user: User;
  participants: Map<string, TwilioParticipant>;
  pending: List<any>;
  failed: List<any>;
  messageReaders: [];
}

const initialState: WorkchatState = Record({
  connected: false,
  conversation: null,
  conversations: Map(),
  conversationsLoading: false,
  error: null,
  filters: {
    positions: null,
    locations: null,
  },
  flags: Map(),
  messages: List(),
  modal: null,
  pendingConversation: null,
  screens: Stack(),
  sending: false,
  show: false,
  unread: 0,
  users: Map(),
  usersLoading: false,
  user: new User(),
  participants: Map(),
  pending: List(),
  failed: List(),
  messageReaders: [],
});

let screens: any;

export const workchatSlice = createSlice({
  name: 'workchat',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(WORKCHAT_START, (state, action) => {
        return state.withMutations(map => {
          map.set('user', action.user).merge({ flags: action.flags });
        });
      })
      .addCase(WORKCHAT_CONNECTED, state => {
        return state.set('connected', true);
      })
      .addCase(WORKCHAT_DISCONNECTED, state => {
        return state.set('connected', false);
      })
      .addCase(WORKCHAT_TOGGLE, (state, action) => {
        const show = action.show !== undefined ? action.show : !state.get('show');
        return state.set('show', show);
      })
      .addCase(WORKCHAT_MARK_UNREAD, (state, action) => {
        return state.set('unread', action.unread);
      })
      .addCase(WORKCHAT_ERROR, (state, action) => {
        return state.set('error', action.error);
      })
      .addCase(WORKCHAT_RETRY_ERROR, state => {
        return state;
      })
      .addCase(WORKCHAT_CONFIRM_MODAL, (state, action) => {
        return state.set('modal', action.modal);
      })
      .addCase(WORKCHAT_SCREEN_PUSH, (state, action) => {
        screens = state.screens.push(action.screen);
        return state.set('screens', screens);
      })
      .addCase(WORKCHAT_SCREEN_POP, state => {
        screens = state.screens;
        return state.set('screens', screens.pop());
      })
      .addCase(WORKCHAT_SCREEN_UNWIND, (state, action) => {
        screens = state.screens;
        while (screens.size > 1 && screens.peek() !== action.screen) {
          screens = screens.pop();
        }
        return state.set('screens', screens);
      })
      .addCase(WORKCHAT_LOADING_CONVERSATIONS, state => {
        return state.set('conversationsLoading', true);
      })
      .addCase(WORKCHAT_LOAD_CONVERSATIONS, (state, action) => {
        return state.withMutations(map => {
          map.set('conversationsLoading', false).set('conversations', action.conversations);
        });
      })
      .addCase(WORKCHAT_UPDATE_CONVERSATION, (state, action) => {
        return state.withMutations(map => {
          // Force it to update the conversation
          map.setIn(['conversations', action.id], null).setIn(['conversations', action.id], action.conversation);
        });
      })
      .addCase(WORKCHAT_LEFT_CONVERSATION, (state, action) => {
        return state.deleteIn(['conversations', action.id]);
      })
      .addCase(WORKCHAT_SET_CONVERSATION, (state, action) => {
        return state.withMutations(map => {
          map
            .set('conversation', action.conversation)
            .set('participants', action.participants)
            .set('messages', action.messages)
            .set('pending', action.pending)
            .set('failed', action.failed);
        });
      })
      .addCase(WORKCHAT_SET_PENDING_CONVERSATION, (state, action) => {
        return state.set('pendingConversation', action.pending);
      })
      .addCase(WORKCHAT_SET_FILTERS, (state, action) => {
        return state.set('filters', action.filters);
      })
      .addCase(WORKCHAT_SEND_MESSAGE, state => {
        return state;
      })
      .addCase(WORKCHAT_MESSAGE_RECEIPTS, (state, action) => {
        return state.set('messageReaders', action.readers);
      })
      .addCase(WORKCHAT_LOADING_USERS, state => {
        return state.set('usersLoading', false);
      })
      .addCase(WORKCHAT_LOAD_USERS, (state, action) => {
        return state.withMutations(map => {
          map.set('usersLoading', false).set('users', action.users);
        });
      })
      .addDefaultCase(state => {
        return state;
      });
  },
});

export default workchatSlice.reducer;
