import PaychexState from 'integrations/modules/paychex/state';
import { FETCH_PAYCHEX, UPDATE_PAYCHEX } from 'store/action-types';
import PaychexSettings from './PaychexSettings';

export default function (state = new PaychexState(), action) {
  switch (action.type) {
    case FETCH_PAYCHEX.FAILURE:
      return state.merge({
        loading: false,
        loaded: false,
        error: action.error.error,
      });

    case FETCH_PAYCHEX.REQUEST:
      return state.merge({
        loading: true,
        loaded: false,
        error: null,
      });

    case FETCH_PAYCHEX.SUCCESS:
      return state.merge({
        loading: false,
        loaded: true,
        error: null,
        enabled: action.response.data.enabled,
        settings: new PaychexSettings(action.response.data.settings),
      });

    case UPDATE_PAYCHEX.FAILURE:
      return state.merge({
        updating: false,
        updated: false,
        error: action.error.error,
      });

    case UPDATE_PAYCHEX.REQUEST:
      return state.merge({
        updating: true,
        updated: false,
        error: null,
      });

    case UPDATE_PAYCHEX.SUCCESS:
      return state.merge({
        updating: false,
        updated: true,
        error: null,
        enabled: action.response.data.enabled,
        settings: new PaychexSettings(action.response.data.settings),
      });

    default:
      return state;
  }
}
