import type Position from 'data/position/model';
import type { PositionFields } from 'data/position/model';
import type { EntityMap } from 'data/types';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type SortPositionsResponse = {
  positions: PositionFields[];
};

export const sortPositions = createWiwAsyncThunk<SortPositionsResponse, EntityMap<Position>>(
  'location/sort',
  async (locations, { rejectWithValue }) => {
    try {
      return (await Monolith.url('/locations/sort').post({
        ids: locations
          .sortBy(location => location.sort)
          .keySeq()
          .toArray(),
      })) as SortPositionsResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
