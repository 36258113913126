import BaseModel from 'data/BaseModel';

interface ForecastCustomUnitPositionsFields {
  id: string;
  count: number;
}

export interface ForecastCustomUnitFields {
  id: string | null;
  accountId: string | null;
  name: string | null;
  type: number | null;
  unitCount: number | null;
  positions: Array<ForecastCustomUnitPositionsFields>;
  rounding: number | null;
}

type ForecastCustomUnitFieldsApi = Override<ForecastCustomUnitFields, { data: object }>;

export enum ForecastCustomUnitTypes {
  Shifts = 1, //shifts per position
  Positions = 2, //hours per position
}

export enum ForecastCustomUnitCalcCoverage {
  RoundUp = 1,
  RoundNearest = 2,
  RoundDown = 3,
}

export enum ForecastCustomUnitPositionKeys {
  ID = 'id',
  COUNT = 'count',
}

export default class ForecastCustomUnit extends BaseModel<ForecastCustomUnitFields>({
  id: null,
  accountId: null,
  name: null,
  type: null,
  unitCount: null,
  positions: [],
  rounding: null,
}) {
  static Type = {
    SHIFTS: ForecastCustomUnitTypes.Shifts,
    POSITIONS: ForecastCustomUnitTypes.Positions,
  } as const;

  static CoverageKeys = {
    ROUNDUP: ForecastCustomUnitCalcCoverage.RoundUp,
    ROUNDNEAREST: ForecastCustomUnitCalcCoverage.RoundNearest,
    ROUNDDOWN: ForecastCustomUnitCalcCoverage.RoundDown,
  } as const;

  static PositionsKeys = {
    ID: ForecastCustomUnitPositionKeys.ID,
    COUNT: ForecastCustomUnitPositionKeys.COUNT,
  };

  static MaxValue = 99999 as const;
  static MinValue = 1 as const;

  /**
   * @param args
   */
  constructor(args: Partial<ForecastCustomUnitFieldsApi | ForecastCustomUnitFields> = {}) {
    super({ ...args });
  }

  getPositionCount(positionId: string) {
    const position = this.positions.filter(position => position.id === positionId);
    return position.length > 0 ? position[0].count : 0;
  }

  hasPosition(positionId: string) {
    const position = this.positions.filter(position => position.id === positionId);
    return position.length > 0;
  }
}
