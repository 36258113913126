// User-level Metadata
export const ONBOARDING_ATTENDANCE_CHECKLIST_MINIMIZED = 'onboardingAttendanceChecklistMinimized';
export const ONBOARDING_SCHEDULING_CHECKLIST_MINIMIZED = 'onboardingSchedulingChecklistMinimized';
export const ONBOARDING_ATTENDANCE_CHECKLIST_CLOSED = 'onboardingAttendanceChecklistClosed';

// Account-level Metadata
export const ONBOARDING_SCHEDULING_CHECKLIST_CLOSED = 'onboardingSchedulingChecklistClosed'; // account level to prevent other manager from getting checklist after it's done by AH

export const ONBOARDING_TIMESHEET_EDITED = 'onboardingAttendanceTimesheetEdited'; // could have been onboardingTimesheetEdited, but checklist code already shipped
export const ONBOARDING_TIMESHEET_EXPORTED = 'onboardingAttendanceTimesheetExported'; // could have been onboardingTimesheetExported, but checklist code already shipped

export const ONBOARDING_USER_CREATED = 'onboardingUserCreated';

export const ONBOARDING_SHIFT_CREATED = 'onboardingShiftCreated';
export const ONBOARDING_SHIFT_REASSIGNED = 'onboardingShiftReassigned';
export const ONBOARDING_SCHEDULE_PUBLISHED = 'onboardingSchedulePublished';

export const ONBOARDING_PRODUCT_ATTENDANCE_DESIRED = 'onboardingProductAttendanceDesired';
export const ONBOARDING_PRODUCT_SCHEDULING_DESIRED = 'onboardingProductSchedulingDesired';

export const ONBOARDING_SCHEDULING_FULL_SCREEN_CLOSED = 'onboardingSchedulingFullScreenClosed';

export const FOCUSED_ONBOARDING_REDIRECT = 'focusedOnboardingRedirect';
