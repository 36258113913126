import { getActiveReportDefinition } from 'reporting/selectors/getActiveReportDefinition';
import type { WiwState } from 'store';

import { createSelector } from '@reduxjs/toolkit';

export const getReportDataset = createSelector(
  [getActiveReportDefinition, (state: WiwState) => state.reporting.customReportBuilder.dataset],
  (savedReport, dataset) => {
    if (savedReport) {
      return savedReport.dataset;
    }

    return dataset;
  },
);
