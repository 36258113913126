import type { PropsWithChildren } from 'react';

import classnames from 'classnames';

type Props = {
  className?: string;
};

export default function Footer(props: PropsWithChildren<Props>) {
  return <div className={classnames('dialog-footer', props.className)}>{props.children}</div>;
}
