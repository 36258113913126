import { lazy } from 'react';
import CheckCompanyOnboardDialog from './CheckCompanyOnboardDialog';

const CheckPreviousProviderDialog = lazy(
  () => import('payrollCheck/modules/onboarding/dialogs/CheckPreviousProviderDialog'),
);
const UpgradeYourPlanDialog = lazy(() => import('payrollCheck/modules/onboarding/dialogs/UpgradeYourPlanDialog'));

export const CHECK_COMPANY_ONBOARD_DIALOG = 'CHECK_COMPANY_ONBOARD_DIALOG';
export const CHECK_PREVIOUS_PROVIDER_DIALOG = 'CHECK_PREVIOUS_PROVIDER_DIALOG';
export const UPGRADE_YOUR_PLAN_DIALOG = 'UPGRADE_YOUR_PLAN_DIALOG';

const dialogs = {
  [CHECK_COMPANY_ONBOARD_DIALOG]: CheckCompanyOnboardDialog,
  [CHECK_PREVIOUS_PROVIDER_DIALOG]: CheckPreviousProviderDialog,
  [UPGRADE_YOUR_PLAN_DIALOG]: UpgradeYourPlanDialog,
  // ...
};

export default dialogs;
