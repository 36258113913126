import { Children, type ReactNode } from 'react';

import classNames from 'classnames';

type MenuProps = {
  className?: string;
  children?: ReactNode;
  show?: boolean;
  dropdownMenu?: boolean;
  countChildren?: boolean;
};

const MenuItems = ({ className, children, show, dropdownMenu, countChildren }: MenuProps) => {
  let listItems = 0;
  if (countChildren) {
    // removing one list item for .navbar-bulletin-board since we want to count all other items
    listItems = Children.toArray(children).length - 1;
  }

  if (show === false) {
    return null;
  }

  return (
    <ul className={classNames(className, `list-items-${listItems}`, { 'dropdown-menu': dropdownMenu })}>{children}</ul>
  );
};

export default MenuItems;
