import type { ShiftTemplateFields } from 'data/shiftTemplate/model';
import type { QueryString } from 'data/types';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type FetchShiftTemplatesArgs = QueryString;

type FetchShiftTemplatesResponse = {
  blocks: ShiftTemplateFields[];
};

export const fetchShiftTemplates = createWiwAsyncThunk<FetchShiftTemplatesResponse, FetchShiftTemplatesArgs>(
  'shiftTemplate/fetch',
  async (params = {}, { rejectWithValue }) => {
    try {
      return (await Monolith.url('/blocks').query(params).get()) as FetchShiftTemplatesResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
