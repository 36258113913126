import { FETCH_INTEGRATIONS_GO, REVERT_INTEGRATION, UPDATE_INTEGRATION } from 'store/action-types';
import IntegrationState from './state';

import Integration from './models/integration';

import { Map } from 'immutable';

export default (state = new IntegrationState(), action) => {
  switch (action.type) {
    case FETCH_INTEGRATIONS_GO.REQUEST:
      return state.merge({
        loading: true,
        receivedAt: 0,
        loaded: false,
      });
    case FETCH_INTEGRATIONS_GO.SUCCESS: {
      const { integrations = [] } = action.payload;
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        receivedAt: Date.now(),
        items: state.items.merge(integrations.map(int => [int.key, new Integration(int)])),
      });
    }
    case FETCH_INTEGRATIONS_GO.FAILURE:
      return state.merge({
        loaded: false,
        loading: false,
        error: action.error,
      });
    case UPDATE_INTEGRATION:
      return state.merge({
        prevItems: new Map([[action.item.key, state.items.get(action.item.key)]]),
        items: state.items.delete(null).set(action.item.key, new Integration(action.item)),
      });
    case REVERT_INTEGRATION:
      return state.merge({
        prevItems: new Map(),
        items: state.items.merge(state.prevItems).delete(null),
      });
    default:
      return state;
  }
};
