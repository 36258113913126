import { posthogCapture } from 'shared/vendor/posthog';

import { useWindowEvent } from '@mantine/hooks';

export function useContextMenuTracking() {
  useWindowEvent('contextmenu', e => {
    if (e.target instanceof Element) {
      let element = e.target;
      const maybeButton = e.target.closest('button');
      if (maybeButton) {
        element = maybeButton;
      }

      const tag = element.tagName.toLowerCase();
      const id = element.id;
      const classes = element.classList.value;

      let target = tag;
      if (id) {
        target += `#${id}`;
      }

      if (classes !== '') {
        target += `.${classes.split(' ').join('.')}`;
      }

      posthogCapture('Opened context menu', {
        // can be helpful in figuring out what they were trying to find a context menu for
        'Click target': target,
      });
    }
  });
}
