import BaseModel from 'data/BaseModel';
import StripeCoupon from 'data/stripe/coupons/model';
import StripePromoCode from 'data/stripe/promoCode/model';
import type { StripeCustomer } from 'data/stripe/types/customer';
import type { StripeDiscount } from 'data/stripe/types/discount';
import type { StripeInvoice as StripeInvoiceI } from 'data/stripe/types/invoice';
import { centsToDollars } from 'data/stripe/utils/converters';

export interface StripeInvoiceFields extends Partial<StripeInvoiceI> {
  next_invoice: Omit<StripeInvoiceFields, 'next_invoice' | 'unprorated_invoice'> | null;
  unprorated_invoice: Omit<StripeInvoiceFields, 'next_invoice' | 'unprorated_invoice'> | null;
  total: number;
  tax: number;
  total_excluding_tax: number;
  subtotal_excluding_tax: number;
}

export default class StripeInvoice extends BaseModel<StripeInvoiceFields>({
  id: '',
  object: 'invoice',
  account_country: null,
  account_name: null,
  account_tax_ids: null,
  amount_due: 0,
  amount_paid: 0,
  amount_remaining: 0,
  application: null,
  application_fee_amount: null,
  attempt_count: 0,
  attempted: false,
  automatic_tax: {} as StripeInvoiceI['automatic_tax'],
  billing_reason: null,
  charge: null,
  collection_method: 'charge_automatically',
  created: 0,
  currency: '',
  custom_fields: null,
  customer: null,
  customer_address: null,
  customer_email: null,
  customer_name: null,
  customer_phone: null,
  customer_shipping: null,
  customer_tax_exempt: null,
  default_payment_method: null,
  default_source: null,
  default_tax_rates: [],
  description: null,
  discount: null,
  discounts: null,
  due_date: null,
  ending_balance: null,
  footer: null,
  from_invoice: null,
  hosted_invoice_url: null,
  last_finalization_error: null,
  latest_revision: null,
  lines: {} as StripeInvoiceI['lines'],
  livemode: false,
  metadata: null,
  next_invoice: null as StripeInvoiceFields['next_invoice'],
  next_payment_attempt: null,
  number: null,
  on_behalf_of: null,
  paid: false,
  paid_out_of_band: false,
  payment_intent: null,
  payment_settings: {} as StripeInvoiceI['payment_settings'],
  period_end: 0,
  period_start: 0,
  post_payment_credit_notes_amount: 0,
  pre_payment_credit_notes_amount: 0,
  quote: null,
  receipt_number: null,
  rendering_options: null,
  starting_balance: 0,
  statement_descriptor: null,
  status: null,
  status_transitions: {} as StripeInvoiceI['status_transitions'],
  subscription: null,
  subtotal: 0,
  subtotal_excluding_tax: 0,
  tax: 0,
  test_clock: null,
  total: 0,
  total_discount_amounts: null,
  unprorated_invoice: null as StripeInvoiceFields['unprorated_invoice'],
  total_excluding_tax: 0,
  total_tax_amounts: [],
  transfer_data: null,
  webhooks_delivered_at: null,
  invoice_pdf: '',
}) {
  constructor(args: Partial<StripeInvoiceFields>) {
    super({
      ...args,
      next_invoice: args?.next_invoice ? new StripeInvoice(args.next_invoice) : null,
      unprorated_invoice: args?.unprorated_invoice ? new StripeInvoice(args.unprorated_invoice) : null,
    });
  }

  isNilInvoice() {
    return this.customer == null && this.subscription == null && this.total === 0;
  }

  hasNextInvoice() {
    return !!this.next_invoice;
  }

  getSubtotalPreDiscounts() {
    const coupon = this.getCoupon();
    const couponDiscountAmount = coupon.getCouponAmount(this.subtotal_excluding_tax);
    return centsToDollars(this.subtotal_excluding_tax - couponDiscountAmount);
  }

  getUnproratedInvoice(): StripeInvoice {
    return this.unprorated_invoice as StripeInvoice;
  }

  getNextInvoice(): StripeInvoice {
    return this.next_invoice as StripeInvoice;
  }

  getSubtotal() {
    if (this.isNilInvoice() || this.subtotal_excluding_tax < 0) {
      return centsToDollars(this.getUnproratedInvoice().subtotal_excluding_tax);
    }
    return centsToDollars(this.subtotal_excluding_tax);
  }

  getSubtotalWithoutTax() {
    return centsToDollars(this.subtotal_excluding_tax);
  }

  getPreviousBalance() {
    return this.starting_balance;
  }

  getTax() {
    if (this.isNilInvoice() || this.tax < 0) {
      return centsToDollars(this.getUnproratedInvoice().tax);
    }
    return centsToDollars(this.tax);
  }

  getTotal() {
    return centsToDollars(this.total);
  }

  getAmountDue() {
    return centsToDollars(this.amount_due);
  }

  isProrated() {
    if (!this.lines!.data) {
      return false;
    }

    let foundProration = false;
    this.lines!.data.forEach(line => {
      // If any line item is marked as a proration, then we know prorations are occuring
      if (line.proration) {
        foundProration = true;
      }
    });

    return foundProration;
  }

  proratedAmount() {
    if (!this.isProrated()) {
      return 0.0;
    }
    return centsToDollars(this.total_excluding_tax - this.getUnproratedInvoice().total_excluding_tax);
  }

  proratedAmountNextInvoice() {
    if (!this.isProrated() || !this.hasNextInvoice()) {
      return 0.0;
    }
    return centsToDollars(this.getNextInvoice().total_excluding_tax - this.getUnproratedInvoice().total_excluding_tax);
  }

  proratedAmountPreDiscounts() {
    if (!this.isProrated()) {
      return 0.0;
    }
    const coupon = this.getCoupon();
    const couponDiscountAmount = coupon.getCouponAmount(this.subtotal_excluding_tax);
    return centsToDollars(
      this.total_excluding_tax - this.getUnproratedInvoice().total_excluding_tax + couponDiscountAmount,
    );
  }

  unproratedSubTotal() {
    return centsToDollars(this.getUnproratedInvoice().subtotal_excluding_tax);
  }

  unproratedAmountDue() {
    return centsToDollars(this.getUnproratedInvoice().amount_due);
  }

  getPerSeatAmount() {
    if (!this.lines!.data) {
      return 0;
    }
    return centsToDollars(
      this.lines!.data.reduce((total, line) => {
        if (line.amount > 0) {
          return total + (line.price!.unit_amount || 0);
        }
        return total;
      }, 0),
    );
  }

  getSeatCount() {
    const data = this.lines!.data;
    if (!data || data.length === 0) {
      return 0;
    }
    // Invoices lines that have credited_items are the previous plan's seats prorated back, not the current seat count
    const firstValidLine = data.find(el => !el?.proration_details?.credited_items);
    if (firstValidLine) {
      return firstValidLine.quantity;
    }
    return 0;
  }

  hasCoupon() {
    return !!this.discount?.coupon;
  }

  getCoupon() {
    if (!this.hasCoupon()) {
      return new StripeCoupon();
    }
    return new StripeCoupon(this.discount!.coupon);
  }

  hasPromoCode() {
    let foundPromoCode = false;
    this.discounts?.forEach(discount => {
      const stripeDiscount = discount as StripeDiscount;
      if (stripeDiscount.promotion_code) {
        foundPromoCode = true;
      }
    });
    return foundPromoCode;
  }

  getPromoCode() {
    if (!this.hasPromoCode()) {
      return new StripePromoCode({});
    }
    let promoCode = new StripePromoCode({});
    this.discounts!.forEach(discount => {
      const stripeDiscount = discount as StripeDiscount;
      if (stripeDiscount.promotion_code) {
        promoCode = stripeDiscount.promotion_code! as StripePromoCode;
      }
    });
    return promoCode;
  }

  getTotalDiscountAmount() {
    if (!this.lines!.data) {
      return 0.0;
    }

    let discountAmount = 0.0;
    this.lines!.data.forEach(line => {
      if (line.discount_amounts) {
        line.discount_amounts.forEach(discount => {
          discountAmount += discount.amount;
        });
      }
    });

    return discountAmount;
  }

  hasCustomerLevelDiscount() {
    return !!(this.customer! as StripeCustomer).discount;
  }

  getAnnualSubtotalWithCoupon(isAnnual: boolean) {
    // I don't know why stripe does this differently but coupons for annual are applied differently
    if (isAnnual && this.hasCoupon()) {
      return (
        this.getSubtotal() -
        this.getCoupon().getCouponAmount(centsToDollars(this.getUnproratedInvoice().subtotal_excluding_tax))
      );
    }
    return this.getSubtotal();
  }

  toDollars<K extends keyof StripeInvoiceFields>(prop: K): number {
    return centsToDollars(this.asInt(prop));
  }

  showSubtotal() {
    return this.getAmountDue() > 0 || this.isProrated() || this.hasCoupon();
  }
}
