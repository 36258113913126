import { capitalize } from 'lodash';

// Please add all features to the `Features` object at the end of this file
// sorted alphanumeric

// General (sorted alphanumeric)
export const ABSENCE_TRACKING = 'absence-tracking';
export const ALL = 'all';
export const ANNOTATIONS = 'annotations';
export const ATTENDANCE = 'attendance';
export const ATTENDANCE_TRACKING = 'attendance-tracking';
export const AUTO_ASSIGN = 'auto-assign';
export const CLOCK_IN = 'clockin';
export const COPY_PREVIOUS = 'copy-previous';
export const COVERAGE_VIEW = 'coverage-view';
export const CUSTOM_REPORTS = 'custom-reports';
export const CUSTOM_TIMEOFF_TYPES = 'custom-timeoff-types';
export const TIMEOFF_ACCRUAL = 'timeoff-accrual';
export const TIMEZONE_TOGGLE = 'timezone-toggle';
export const DOC_STORAGE = 'doc-storage';
export const EMPLOYEE_CONTROL = 'employee-control';
export const FORECAST_TOOLS_PRO = 'forecast-tools-pro';
export const GLOBAL_PRIVACY = 'global-privacy';
export const INTERNATIONAL_SMS = 'international-sms';
export const JOB_SITES = 'sites';
export const JOB_SITE_VIEW = 'job-site-view';
// used to denote plan used to be a bucket pricing plan (i.e. plan.type was equal to 1)
export const LEGACY_BUCKET_MIGRATION = 'legacy-bucket-tiered-pricing-migration-v1';
export const LIVE_CHAT = 'live-chat';
export const LOCATIONS = 'locations';
export const LONG_TERM_SCHEDULING = 'long-term-scheduling';
export const LONG_TERM_SCHEDULING_14 = 'long-term-scheduling-14';
export const LONG_TERM_SCHEDULING_21 = 'long-term-scheduling-21';
export const MANAGER_ROLE_PERMISSIONS = 'mgr-role-permissions';
export const MAX_HOURS_ENFORCEMENT = 'max-hours-enforcement';
export const MOBILE_ALERTS = 'mobile-alerts';
export const MONTH_VIEW = 'month-view';
export const OPENSHIFT_APPROVAL = 'openshift-approval';
export const OPEN_SHIFTS = 'open-shifts';
export const OVERLAPPING_OPENSHIFTS = 'overlapping-openshifts';
export const OVERTIME_ALERTS = 'ot-alerts';
export const OVERTIME_VISIBILITY = 'ot-visibility';
export const PAYROLL_EXPORT = 'payroll-export';
export const POSITION_VIEW = 'position-view';
export const REPEATING_SHIFTS = 'repeating-shifts';
export const REPORTING = 'reporting';
export const ROLE_PERMISSIONS = 'role-permissions';
export const SAML_SSO = 'saml-sso';
export const SCHEDULE_BUILDER = 'schedule-builder';
export const SCHEDULE_TEMPLATES = 'schedule-templates';
export const SCHEDULED_BREAKS = 'scheduled-breaks';
export const SCHEDULING = 'scheduling';
export const SCHEDULING_RULES = 'scheduling-rules';
export const SHARED_OPENSHIFTS = 'shared-openshifts';
export const SHIFT_ACKNOWLEDGEMENT = 'acknowledgement';
export const SHIFT_RELEASE = 'shift-release';
export const SITES = 'sites';
export const SMS_ALERTS = 'sms-alerts';
export const SQUARE_PLAN = 'square-plan';
export const SSO = 'sso';
export const SWAPS_DROPS = 'swaps-drops';
export const SWAP_RESTRICTIONS = 'swap-restrictions';
export const TIME_OFF_REQUESTS = 'time-off-requests';
export const TWO_WEEK_RANGE = 'two-week-range';
export const UNCLAIMED = 'unclaimed';
export const UNLIMITED_USER_CAPACITY = 'unlimited-user-capacity';
export const WEBHOOKS = 'webhooks';
export const WORKALYTICS = 'reports-workalytics';
export const WORKCHAT = 'workchat';

// Workforce management (sorted alphanumeric)
export const TASKS = 'tasks';
export const SHIFT_TASKS = 'shift-tasks';
export const WORKTAGS = 'worktags';

// Integrations (sorted alphanumeric)
export const INTEGRATIONS_ADP = 'int-adp';
export const INTEGRATIONS_ADP_WFN = 'int-adp-wfn';
export const INTEGRATIONS_ADVANCED = 'int-advanced';
export const INTEGRATIONS_BASIC = 'int-basic';
export const INTEGRATIONS_GUSTO = 'int-gusto';
export const INTEGRATIONS_PAYCHEX = 'int-paychex';
export const INTEGRATIONS_QUICKBOOKS = 'int-quickbooks';
export const INTEGRATIONS_SQUARE = 'int-square';

// Enterprise (sorted alphanumeric)
export const ACCOUNT_REP = 'account-rep';
export const API = 'api';
export const MASTER_CHILD_ACCOUNT = 'master-child-account';

// pricing-2021
export const PRICING_2021 = 'pricing-2021';
export const PRICING_ENT_2021 = 'pricing-ent-2021';
export const PRICING_ENTL_2021 = 'pricing-entl-2021';
export const PAYROLL = 'payroll';

// Handy object for importing (sorted alphanumeric)
export const Features = {
  ABSENCE_TRACKING,
  ACCOUNT_REP,
  ALL,
  ANNOTATIONS,
  API,
  ATTENDANCE,
  ATTENDANCE_TRACKING,
  AUTO_ASSIGN,
  CLOCK_IN,
  COPY_PREVIOUS,
  COVERAGE_VIEW,
  CUSTOM_REPORTS,
  CUSTOM_TIMEOFF_TYPES,
  TIMEOFF_ACCRUAL,
  TIMEZONE_TOGGLE,
  DOC_STORAGE,
  EMPLOYEE_CONTROL,
  GLOBAL_PRIVACY,
  INTEGRATIONS_ADP,
  INTEGRATIONS_ADP_WFN,
  INTEGRATIONS_ADVANCED,
  INTEGRATIONS_BASIC,
  INTEGRATIONS_GUSTO,
  INTEGRATIONS_PAYCHEX,
  INTEGRATIONS_QUICKBOOKS,
  INTEGRATIONS_SQUARE,
  INTERNATIONAL_SMS,
  JOB_SITES,
  JOB_SITE_VIEW,
  LEGACY_BUCKET_MIGRATION,
  LIVE_CHAT,
  LOCATIONS,
  LONG_TERM_SCHEDULING,
  LONG_TERM_SCHEDULING_14,
  LONG_TERM_SCHEDULING_21,
  MANAGER_ROLE_PERMISSIONS,
  MASTER_CHILD_ACCOUNT,
  MAX_HOURS_ENFORCEMENT,
  MOBILE_ALERTS,
  MONTH_VIEW,
  OPENSHIFT_APPROVAL,
  OPEN_SHIFTS,
  OVERLAPPING_OPENSHIFTS,
  OVERTIME_ALERTS,
  OVERTIME_VISIBILITY,
  PAYROLL_EXPORT,
  POSITION_VIEW,
  PRICING_2021,
  PRICING_ENTL_2021,
  PRICING_ENT_2021,
  REPEATING_SHIFTS,
  REPORTING,
  ROLE_PERMISSIONS,
  SAML_SSO,
  SCHEDULE_BUILDER,
  SCHEDULE_TEMPLATES,
  SCHEDULED_BREAKS,
  SCHEDULING,
  SCHEDULING_RULES,
  SHARED_OPENSHIFTS,
  SHIFT_ACKNOWLEDGEMENT,
  SHIFT_RELEASE,
  SHIFT_TASKS,
  SITES,
  SMS_ALERTS,
  SQUARE_PLAN,
  SSO,
  SWAPS_DROPS,
  SWAP_RESTRICTIONS,
  TASKS,
  TIME_OFF_REQUESTS,
  TWO_WEEK_RANGE,
  UNCLAIMED,
  UNLIMITED_USER_CAPACITY,
  WEBHOOKS,
  WORKALYTICS,
  WORKCHAT,
  WORKTAGS,
  PAYROLL,
};

const displayNames = {
  [Features.AUTO_ASSIGN]: 'Auto Scheduling',
  [Features.DOC_STORAGE]: 'Documents',
  [Features.OPENSHIFT_APPROVAL]: 'OpenShift Requests',
  [Features.TASKS]: 'Task Management',
  [Features.OVERTIME_VISIBILITY]: 'Overtime Visibility',
  [Features.SHARED_OPENSHIFTS]: 'Labor sharing',
};

/**
 * Friendly display names
 * @param feature
 * @return string
 */
export const displayFeature = (feature: string) => {
  return displayNames[feature] || feature.split('-').map(capitalize).join(' ');
};

export default Features;
