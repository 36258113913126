import Environment from 'shared/util/environment';

import { List, Map } from 'immutable';
import { isFunction } from 'lodash';

type KeyMutator<Key> = (key: Key) => any;

export function toEntityMap<Key, Value>(items: any = [], model: any, key = 'id', keyMutator?: KeyMutator<Key>) {
  if (!items) {
    return Map<Key, Value>();
  }

  if (!Array.isArray(items) && !List.isList(items)) {
    items = [items];
  }
  const getItemKey = (item: any) => {
    let mutatedKey = item?.[key];
    if (isFunction(keyMutator)) {
      mutatedKey = keyMutator(item?.[key]);
    }
    if (mutatedKey === undefined && Environment.isDevelopment()) {
      throw new Error(`Could not find key ${key} on provided objects`);
    }
    return mutatedKey;
  };
  return Map<Key, Value>(items.map((item: any) => [getItemKey(item), new model(item)]));
}

export function toEntityArray(items: any = [], model: any) {
  if (!items) {
    return [];
  }

  if (!Array.isArray(items) && !List.isList(items) && !Map.isMap(items)) {
    items = [items];
  }

  if (typeof items.toArray === 'function') {
    items = items.toArray();
  }

  return items.map((item: any) => new model(item));
}
