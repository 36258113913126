import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

export const unknownError = 'There was an error resetting preferences';

type ResetAvailabilityArgs = {};

type ResetAvailabilityResponse = {};

export const resetAvailability = createWiwAsyncThunk<ResetAvailabilityResponse, ResetAvailabilityArgs>(
  'availability/reset',
  async (userId, { rejectWithValue }) => {
    try {
      return (await Monolith.url('/availabilityevents/reset').post()) as ResetAvailabilityResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
