import type User from 'data/user/model';
import type { UserFields } from 'data/user/model';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type MergeUserParams = {
  pending: User;
  target: User;
};

type MergeUserResponse = {
  user: UserFields;
};

export const mergeUser = createWiwAsyncThunk<MergeUserResponse, MergeUserParams>(
  'user/mergeUser',
  async (params, { rejectWithValue }) => {
    try {
      return (await Monolith.url('/users/merge').post({
        pending_id: params.pending.id,
        user_id: params.target.id,
      })) as MergeUserResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
