import type Shift from 'data/shift/model';
import { fetchScheduledTaskLists } from 'data/tasks/actions/fetchScheduledTaskLists';
import type { EntityMap } from 'data/types';
import { getBoundaries } from 'scheduler/selectors/getBoundaries';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';
import { deleteShifts } from './deleteShift';

type ApplyTemplateArgs = {
  shifts: Shift[];
  toDelete: EntityMap<Shift>;
};

export const applyTemplate = createWiwAsyncThunk<any, ApplyTemplateArgs>(
  'shift/applyTemplate',
  async ({ shifts, toDelete }, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await Monolith.url('/shifts').query({ check_id: 'f' }).post(shifts);

      if (toDelete.size) {
        // @ts-ignore
        dispatch(deleteShifts({ shifts }));
      }

      const { start, end } = getBoundaries(getState());

      dispatch(fetchScheduledTaskLists(start.format(), end.format()));
      return response;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
