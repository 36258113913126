import type { Client, Conversation, CreateConversationOptions } from '@twilio/conversations';
import type { Set } from 'immutable';
import type { WretchError, WretchResponse } from 'wretch';

import workchat from 'shared/api/workchat';
import mercury from 'shared/util/mercury';
import { type WiwDispatch, createWiwAsyncThunk } from 'store';
import { track } from 'workchat/actions';
import { upsertConversation } from 'workchat/v2/store/conversations/conversationsReducer';
import { loadWorkchatParticipantsForConversation } from 'workchat/v2/store/participants/participantsActions';
import { getSdkConversationObject } from 'workchat/v2/twilio-objects';

export function trackFilterUse({
  channelCreated,
  workflow,
  locations,
  positions,
}: { channelCreated: boolean; workflow: string; locations?: Set<number>; positions?: Set<number> }) {
  return (dispatch: WiwDispatch) => {
    const locationCount = locations ? locations.size : 0;
    const positionCount = positions ? positions.size : 0;

    if (!locationCount && !positionCount) {
      return;
    }

    dispatch(
      track('WorkChat: Used Filter', {
        locationCount,
        positionCount,
        channelCreated,
        workflow,
        opened: true,
      }),
    );
  };
}

export const checkForConversation = (participants: number[], client: Client) => {
  if (!client) {
    return;
  }

  return workchat
    .url('/channels')
    .post({ participants })
    .then((resp: WretchResponse) => {
      if (resp.channel) {
        return { exists: false, channel: resp.channel };
      }
      return Promise.reject(resp);
    })
    .catch((resp: WretchError) => {
      const channel = resp.json?.channel;

      // A Conversation already exists (409 Conflict)
      if (resp.status === 409 && channel) {
        return client.getConversationBySid(channel.sid).then(channel => ({ exists: true, channel }));
      }
      return Promise.reject(resp);
    });
};

export const createWorkchatConversation = createWiwAsyncThunk<
  Conversation | undefined,
  { participants: number[]; conversationName?: string | null; client: Client }
>(
  'workchatv2/createConversation',
  async ({ participants, conversationName, client }, { dispatch, rejectWithValue }) => {
    try {
      const result = await checkForConversation(participants, client);

      if (result?.exists && result?.channel) {
        return getSdkConversationObject(result.channel.sid);
      }

      if (result?.channel) {
        const channel = result.channel;

        let conversationAttributes = {};
        try {
          conversationAttributes = JSON.parse(channel.attributes || {});
        } catch (e) {
          console.error(e);
        }

        const opts: CreateConversationOptions = {
          attributes: {
            ...conversationAttributes,
            mercuryMetadata: mercury.identity,
          },
        };

        if (conversationName) {
          opts.friendlyName = conversationName;
        }

        const createdConversation = await client.createConversation(opts);
        const joinedConversation = await createdConversation.join();
        await participants.map(userId => joinedConversation.add(`${userId}`, { mercuryMetadata: mercury.identity }));

        dispatch(upsertConversation(joinedConversation));
        dispatch(loadWorkchatParticipantsForConversation(joinedConversation));

        return joinedConversation;
      }
    } catch (error) {
      rejectWithValue(error);
    }
  },
);
