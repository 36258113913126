import type Availability from 'data/availability/model';
import type { AvailabilityFields } from 'data/availability/model';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type SaveAvailabilityExclusionArgs = {
  availability: Availability;
  seriesItem: any;
};

type SaveAvailabilityExclusionResponse = {
  availabilityevents: AvailabilityFields[];
};

export const saveAvailabilityExclusion = createWiwAsyncThunk<
  SaveAvailabilityExclusionResponse,
  SaveAvailabilityExclusionArgs
>('availability/addExclusion', async ({ availability, seriesItem }, { rejectWithValue }) => {
  try {
    return (await Monolith.url(`/availabilityevents/${seriesItem.id}/exceptions`).post(
      availability.toJSON(),
    )) as SaveAvailabilityExclusionResponse;
  } catch (error) {
    return rejectWithValue(parseAPIError(error));
  }
});
