import type { PositionFields } from 'data/position/model';
import type { ShiftTemplateFields } from 'data/shiftTemplate/model';
import type { UserFields } from 'data/user/model';
import type { PositionData, ShiftTemplateData, UserData } from 'scheduler/dialogs/GuidedScheduleImportDialog/types';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type ImportData = {
  users: UserData[];
  positions: PositionData[];
  shiftTemplates: ShiftTemplateData[];
  inviteUsers: boolean;
};

type persistOnboardingImportResponse = {
  users: UserFields[];
  positions: PositionFields[];
  shiftTemplates: ShiftTemplateFields[];
  success: boolean;
};

export const persistOnboardingImport = createWiwAsyncThunk<persistOnboardingImportResponse, ImportData>(
  'account/persistOnboardingImport',
  async (data, { rejectWithValue }) => {
    try {
      return await Monolith.url('/account/onboardingimport').post(data);
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
