import BaseModel from 'data/BaseModel';

export interface MessageFields {
  id: number | null;
  account_id: number;
  user_id: number;
  request_id: number | null;
  swap_id: number | null;
  content: string;
  title: string;
  conversation_id: number | null;
  type: MessageType;
  created_at: string | null;
  updated_at: string | null;
  actor: MessageActor;
}

export enum MessageType {
  Basic = 0,
  Error = 1,
  Success = 2,
  Alert = 3,
  System = 4,
  Update = 5,
}

export enum MessageActor {
  User = 0,
  Automatic = 1,
  ShiftModification = 2,
  AllRecipients = 3,
}

class Message extends BaseModel<MessageFields>({
  id: null,
  account_id: 0,
  user_id: 0,
  request_id: null,
  swap_id: null,
  content: '',
  title: '',
  conversation_id: 0,
  type: 0,
  created_at: null,
  updated_at: null,
  actor: 0,
}) {
  static Types = {
    BASIC: MessageType.Basic,
    ERROR: MessageType.Error,
    SUCCESS: MessageType.Success,
    ALERT: MessageType.Alert,
    SYSTEM: MessageType.System,
    UPDATE: MessageType.Update,
  } as const;

  static Actor = {
    USER: MessageActor.User,
    AUTOMATIC: MessageActor.Automatic,
    SHIFT_MODIFICATION: MessageActor.ShiftModification,
    ALL_RECIPIENTS: MessageActor.AllRecipients,
  } as const;
}

export default Message;
