import { SCHEDULER_SHIFT_BACKGROUND } from 'scheduler/util/cookies';
import { UPDATE_SHIFT_BACKGROUND_FILTER } from 'store/action-types';

import Cookie from 'js-cookie';

export function updateShiftBackgroundFilter(payload) {
  Cookie.set(SCHEDULER_SHIFT_BACKGROUND, payload);
  return dispatch => {
    return dispatch({
      type: UPDATE_SHIFT_BACKGROUND_FILTER,
      payload,
    });
  };
}
