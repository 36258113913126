import { CustomReportingState } from 'data/reporting/state/CustomReportingState';
import { FETCH_CUSTOM_REPORT_DATA } from 'store/action-types';

import type { AnyAction } from '@reduxjs/toolkit';

export const custom = (state = new CustomReportingState(), action: AnyAction) => {
  switch (action.type) {
    case FETCH_CUSTOM_REPORT_DATA.SUCCESS: {
      let items = [];
      switch (action.payload.dataset) {
        case 'shifts':
        case 'times':
        case 'shift-history':
          if (action.payload.data != null) {
            items = action.payload.data;
          }
          break;

        default:
          throw new TypeError(`Unexpected dataset: ${action.payload.dataset}`);
      }

      return state.merge({
        error: null,
        items,
        loaded: true,
        loading: false,
        receivedAt: Date.now(),
      });
    }
    case FETCH_CUSTOM_REPORT_DATA.REQUEST:
      return state.merge({
        error: null,
        items: [],
        loaded: false,
        loading: true,
      });
    case FETCH_CUSTOM_REPORT_DATA.FAILURE:
      return state.merge({
        error: action.payload,
        items: [],
        loaded: false,
        loading: false,
      });
    default:
      return state;
  }
};
