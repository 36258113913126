import { Portal } from '@mantine/core';
import { isNil } from 'lodash';
import { Fragment, type MouseEventHandler, type PropsWithChildren, useEffect, useRef, useState } from 'react';

import { openDialog } from 'dialogs';
import { getAuthAccount } from 'shared/auth/selectors';
import FontIcon from 'shared/ui/FontIcon';
import { Tooltip } from 'shared/ui/Tooltip';
import { useWiwDispatch, useWiwSelector } from 'store';

import 'shared/upsell/assets/styles/upsellFeatureLock.scss';

type Props = {
  isActivated?: boolean;
  upsellDialog: string;
  hideLockIcon?: boolean;
  upsellCrown?: boolean;
};

export default function UpsellFeatureLock({
  isActivated,
  upsellDialog,
  hideLockIcon,
  upsellCrown,
  children,
}: PropsWithChildren<Props>) {
  const dispatch = useWiwDispatch();
  const lockRef = useRef<HTMLDivElement>(null);

  const account = useWiwSelector(getAuthAccount);
  const isReverseTrial = !isNil(isActivated) ? isActivated : account.isReverseTrial();

  const [target, setTarget] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (!upsellDialog) {
      console.error('UpsellFeatureLock: upsellDialog prop is required');
    }
    findTarget();
  }, []);

  /**
   * Find the target element to attach the lock icon to.
   * This is a bit of a hack, but it's the best we can do without
   *  adding a ton of edits everywhere else.
   *
   * Iterates through a list of possible targets and uses the first one it finds.
   */
  const findTarget = () => {
    const possibleTargets = [
      '.lock-target',
      '.setting-title',
      '.nav-item-label',
      '.custom-control-label .label-text',
      'button div',
      'button',
      ':first-child',
    ];

    if (target !== null || !isReverseTrial) {
      return;
    }

    // use a for loop so we can bail out early.
    for (const targetSelector of possibleTargets) {
      const el = lockRef.current!.querySelector(targetSelector);
      if (el) {
        // @ts-ignore
        setTarget(el);
        return;
      }
    }
  };
  const handleUpsellClick: MouseEventHandler = e => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(openDialog(upsellDialog));
  };

  if (!isReverseTrial) {
    return <Fragment>{children}</Fragment>;
  }

  const icon = upsellCrown ? 'upgrade' : 'lock';

  return (
    <Fragment>
      {target && !hideLockIcon && (
        <Portal target={target}>
          <Tooltip label="Unavailable on this plan. Click to learn more.">
            <FontIcon
              icon={icon}
              className="upsell-locker-icon ml-2"
              srText="Unavailable on this plan. Click to learn more."
              onClick={handleUpsellClick as any}
              hasPopover
            />
          </Tooltip>
        </Portal>
      )}
      <Tooltip label="Unavailable on this plan. Click to learn more." disabled={!hideLockIcon}>
        <div className="upsell-locker" data-testid="upsell-container" ref={lockRef} onClick={handleUpsellClick}>
          {children}
        </div>
      </Tooltip>
    </Fragment>
  );
}
