import { Map } from 'immutable';

import { WORKCHAT_LOADING_USERS, WORKCHAT_LOAD_USERS } from 'workchat/action-types';

export function loadUsers(token, userId) {
  return (dispatch, getState) => {
    if (getState().workchat.get('usersLoading')) {
      return;
    }

    dispatch({ type: WORKCHAT_LOADING_USERS });

    return fetch(`${CONFIG.WORKCHAT_ROOT}/accounts/participants`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'W-Token': token,
        'W-UserId': userId,
      },
    }).then(resp => {
      resp.json().then(data => {
        const users = new Map().withMutations(map => {
          data.participants.forEach(u => {
            u.fullName = [u.first_name, u.last_name].join(' ');
            u.shortName = [u.first_name, u.last_name.charAt(0)].join(' ');
            u.locations = u.locations.map(id => Number.parseInt(id)).filter(id => !Number.isNaN(id));
            u.positions = u.positions.map(id => Number.parseInt(id)).filter(id => !Number.isNaN(id));
            // This key is a string because that's the type Twilio uses for their identities
            map.set(`${u.id}`, u);
          });
        });
        const locations = new Map().withMutations(map => {
          data.locations.forEach(loc => map.set(loc.id, loc));
        });
        const positions = new Map().withMutations(map => {
          data.positions.forEach(pos => map.set(pos.id, pos));
        });

        dispatch({
          type: WORKCHAT_LOAD_USERS,
          users,
          locations,
          positions,
        });
      });
    });
  };
}

export function updateParticipant(participant) {
  return (_, getState) => {
    const conv = getState().workchat.get('conversation');
    if (conv && conv.sid === participant.conversation.sid) {
      participant.conversation.participants = participant.conversation.participants.withMutations(map => {
        // Hack to force the participants Map to update instead of a single set being optimized out
        map.set(participant.userId, null).set(participant.userId, participant);
      });
    }
  };
}
