import type Account from 'data/account/model';
import { preserveReferences } from 'shared/util/memo';
import type { WiwState } from 'store';
import { getUserId } from './auth';

import { createSelector } from '@reduxjs/toolkit';

type UserType = WiwState['data']['user']['items'];
type AccountType = WiwState['data']['account']['items'];
type AuthType = WiwState['auth'];
type PeopleType = WiwState['data']['person']['items'];
const getStateAuth = (state: WiwState): AuthType => state.auth;
const getStateUsers = (state: WiwState): UserType => state.data.user.items;
const getStateAccounts = (state: WiwState): AccountType => state.data.account.items;
const getStatePeople = (state: WiwState): PeopleType => state.data.person.items;

const filterStateUsers = (users: WiwState['data']['user']['items']) => {
  const id = getUserId();
  return users.get(id)!;
};

const filterStateAccounts = (accounts: WiwState['data']['account']['items'], id: Account['id']): Account => {
  return accounts.get(id)! as Account;
};

export const getAccountId = (state: WiwState) => state.auth.accountId;

export function mustGetAccountId(state: WiwState): Account['id'] {
  const maybeId = getAccountId(state);
  if (!maybeId) {
    throw new Error('no account is authorized');
  }
  return maybeId;
}

export const getPersonId = (state: WiwState) => state.auth.loginId;
export const getAuthUser = createSelector(getStateUsers, preserveReferences(filterStateUsers, { deep: true }));

export const getAuthAccount = createSelector(
  [getStateAccounts, getAccountId],
  (accounts, id): Account => filterStateAccounts(accounts, id!),
);

export const getAuthPerson = createSelector([getStatePeople, getPersonId], (people: PeopleType, id: number | null) => {
  return people.get(id?.toString());
});

export const getAuthData = createSelector(
  [getStateUsers, getStateAccounts, getStateAuth],
  (users: UserType, accounts: AccountType, auth: AuthType) => {
    const user = filterStateUsers(users);
    if (!user) {
      return {
        loaded: auth.loaded,
      };
    }
    return {
      loginId: auth.loginId,
      user: user,
      account: filterStateAccounts(accounts, user.account_id),
      loaded: auth.loaded,
      isStepUpAuthActive: auth.isStepUpAuthActive,
      isStepUpAuthIdle: auth.isStepUpAuthIdle,
      stepUpAuthMethod: auth.stepUpAuthMethod,
    };
  },
);

export const authorized = createSelector(
  (state: WiwState) => state.auth,
  (auth: AuthType) => !!(auth?.loaded && auth.userId),
);
