import type CreateModel from 'data/request/createModel';
import type Request from 'data/request/model';
import type { RequestFields } from 'data/request/model';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

import { omit } from 'lodash';

type CreateBulkRequestsArgs = Request | CreateModel;

type CreateBulkRequestsResponse = {
  errors:
    | {
        [key: number]: {
          body: string;
          code: number;
          request: RequestFields;
        };
      }
    | [];
  requests: RequestFields[];
};

export const createBulkRequests = createWiwAsyncThunk<CreateBulkRequestsResponse, CreateBulkRequestsArgs>(
  'request/createBulk',
  async (request, { rejectWithValue }) => {
    try {
      return (await Monolith.url('/requests/bulk').post(
        omit(request.toJSON(), ['status']),
      )) as CreateBulkRequestsResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
