import { type Ref, forwardRef } from 'react';
import { type FieldValues, type UseControllerProps, useController } from 'react-hook-form';

import getFieldError from 'shared/form/helpers/getFieldError';
import useMapCustomValidators from 'shared/form/hooks/useMapCustomValidators';
import type { InputProps } from 'shared/form/types';
import FontIcon from 'shared/ui/FontIcon';

import {
  type DatePickerInputStylesNames,
  type DatePickerType,
  DatePickerInput as MantineDatePickerInput,
  type DatePickerInputProps as MantineDatePickerInputProps,
} from '@mantine/dates';
import { useMergedRef } from '@mantine/hooks';
import cx from 'classnames';

import 'shared/form/styles/DatePicker.scss';

export type DatePickerInputProps<T extends FieldValues> = UseControllerProps<T> &
  Omit<MantineDatePickerInputProps<DatePickerType>, 'value' | 'defaultValue' | 'classNames'> &
  InputProps & {
    classNames?: Partial<Record<DatePickerInputStylesNames, string>>;
  };

export default forwardRef(function DatePicker<T extends FieldValues>(
  { name, control, defaultValue, shouldUnregister, onChange, classNames, ...props }: DatePickerInputProps<T>,
  ref: Ref<HTMLInputElement>,
) {
  const rules = useMapCustomValidators(props, true);

  const {
    field: { value, onChange: fieldOnChange, ref: insideRef, ...field },
    fieldState,
  } = useController<T>({
    name,
    control,
    defaultValue,
    rules,
    shouldUnregister,
  });

  const mergedRef = useMergedRef(insideRef, ref);

  return (
    <MantineDatePickerInput
      ref={mergedRef}
      error={getFieldError(fieldState)}
      value={value}
      onChange={e => {
        fieldOnChange(e);
        onChange?.(name, e);
      }}
      classNames={{
        ...classNames,
        root: cx('date-picker-root', classNames?.root),
        required: cx('date-picker-required', classNames?.required),
      }}
      leftSection={<FontIcon icon="date" />}
      leftSectionPointerEvents="none"
      clearButtonProps={{
        // Mantine says these should only be props that apply to the `button` HTML element, but if you look at the
        // source the props go through a slew of Mantine components and eventually end up at the CloseButton component,
        // which takes an icon property for which we can specify our own icon.
        // @ts-ignore
        icon: <FontIcon icon="close-circled" />,
        className: 'date-picker-clear',
      }}
      data-testid={name}
      mb="md"
      {...field}
      {...props}
    />
  );
});
