import BaseModel from 'data/BaseModel';

export interface AbsenceAction {
  id: string | null;
  actionId: string | null;
  actionType: AbsenceActionType | null;
  createBy: string | null;
  createdAt: string | null;
}

export type AbsenceFields = {
  id: string | null;
  accountId: string;
  userId: string;
  shiftId: string;
  shiftStartTime: string | null;
  shiftEndTime: string | null;
  locationId: string;
  siteId: string;
  positionId: string;
  creatorId: string;
  notes: string | null;
  createdAt: string | null;
  updatedBy: string | null;
  updatedAt: string | null;
  actions: AbsenceAction[];
};

export enum AbsenceReduxFilters {
  Schedules = 'absence_schedules',
}

export enum AbsenceTabNames {
  Today = 'today',
  Tomorrow = 'tomorrow',
  Older = 'older',
}

export enum AbsenceActionType {
  TimeOff = 'timeoff',
  Drop = 'drop',
  Swap = 'swap',
  MovedToOpen = 'moved-to-open',
}

const absenceValues = {
  id: null,
  accountId: '',
  userId: '',
  shiftId: '',
  shiftStartTime: null,
  shiftEndTime: null,
  locationId: '',
  siteId: '',
  positionId: '',
  creatorId: '',
  notes: null,
  createdAt: null,
  updatedBy: null,
  updatedAt: null,
  actions: [],
};
class Absence extends BaseModel<AbsenceFields>(absenceValues) {
  static AbsenceReduxFilters = {
    SCHEDULES: AbsenceReduxFilters.Schedules,
  } as const;

  static TabNames = {
    TODAY: AbsenceTabNames.Today,
    TOMORROW: AbsenceTabNames.Tomorrow,
    OLDER: AbsenceTabNames.Older,
  } as const;
}

export default Absence;
