import { bindActionCreators } from '@reduxjs/toolkit';
import { is } from 'immutable';
import Mercury from 'mercury-js';
import PropTypes from 'prop-types';
import { Component, PureComponent } from 'react';
import { connect } from 'react-redux';

import { pushScreen, setMessageReceipts } from 'workchat/actions';
import TruncatedList from 'workchat/components/TruncatedList';
import ViewReceipts from 'workchat/containers/ViewReceipts';
import { getWorkchatUserShortName } from 'workchat/utils';

class Message extends PureComponent {
  static propTypes = {
    ownMessage: PropTypes.bool.isRequired,
    className: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    individual: PropTypes.bool.isRequired,
    sentAt: PropTypes.object.isRequired,
    skipByline: PropTypes.bool,
    image: PropTypes.object,
    text: PropTypes.string,
    messageReaders: PropTypes.array.isRequired,
    readers: PropTypes.array,
    readByAll: PropTypes.bool,

    pushScreen: PropTypes.func.isRequired,
    setMessageReceipts: PropTypes.func.isRequired,
  };

  static defaultProps = {
    skipByline: false,
    image: null,
    text: null,
    readers: [],
    readByAll: false,
  };

  state = {
    imageLoaded: false,
    receiptList: null,
  };

  componentDidMount() {
    this.updateReceipts(this.props, true, true);
  }

  componentWillReceiveProps(props) {
    if (this.props.readByAll !== props.readByAll || !is(this.props.readers, props.readers)) {
      this.updateReceipts(props, true, false);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.readByAll !== prevProps.readByAll || !is(this.props.readers, prevProps.readers)) {
      this.updateReceipts(this.props, false, true);
    }
  }

  updateReceipts(props, doState, doProp) {
    const { individual, readByAll, messageReaders, setMessageReceipts, ownMessage } = props;

    if ((!individual || ownMessage) && (readByAll || props.readers)) {
      let prefix = 'Delivered';
      let readers;

      if (readByAll) {
        if (individual) {
          prefix = 'Read';
          readers = [];
        } else {
          prefix = 'Read by everyone';
          readers = props.readers;
        }
      } else if (props.readers.length) {
        prefix = 'Read by ';
        readers = props.readers;
      }

      if (doState) {
        this.setState({
          receiptList: (
            <TruncatedList
              prefix={prefix}
              items={readers && !readByAll ? readers.map(user => getWorkchatUserShortName(user)) : []}
              width={175}
              formatOverflow={extra => ` +${extra}`}
            />
          ),
        });
      }
      if (doProp && !is(readers, messageReaders)) {
        setMessageReceipts(readers);
      }
    } else if (doState) {
      this.setState({ receiptList: null });
    }
  }

  viewReceipts = e => {
    if (e) {
      e.preventDefault();
    }

    this.props.pushScreen(ViewReceipts);
  };

  render() {
    const { className, individual, user, skipByline, image, text, sentAt } = this.props;
    const { receiptList } = this.state;

    if (image && !image.object) {
      image.object = new Image();
      const img = image.object;
      img.onload = () => {
        image.loaded = true;
        image.width = img.width;
        image.height = img.height;
        this.setState({ imageLoaded: true }); // just need to make it redraw
      };
      img.src = image.thumb;
    }

    return (
      <div className={className}>
        {individual ? null : user.system ? <div className="wiw" /> : <img src={user?.avatar_url || ''} alt="Avatar" />}
        <div className="box">
          <div className="arrow" />
          {user.system || skipByline ? null : (
            <div className="byline">
              {individual ? null : `${user?.shortName || '???'} - `}
              {sentAt.format('MMM Do [at] h:mma')}
            </div>
          )}
          {image ? <MessageImage image={image} /> : null}
          {text ? (
            <p className="message-text">
              <MessageText text={text} />
            </p>
          ) : null}
        </div>
        {receiptList ? (
          individual ? (
            receiptList
          ) : (
            <button type="button" onClick={this.viewReceipts}>
              {receiptList}
            </button>
          )
        ) : null}
      </div>
    );
  }
}

export default connect(
  // State to send to the Component as props
  state => {
    return {
      messageReaders: state.workchat.get('messageReaders') || [],
    };
  },
  // Dispatch functions to send to the Component as props
  dispatch =>
    bindActionCreators(
      {
        pushScreen,
        setMessageReceipts,
      },
      dispatch,
    ),
)(Message);

export class MessageText extends PureComponent {
  static propTypes = {
    text: PropTypes.string.isRequired,
  };

  getMercuryData() {
    if (window.mercury?.identity) {
      return window.mercury;
    }
    return null;
  }

  trackLinkClicks = text => () => {
    const domain = window.location.hostname.split(/\.(.+)/)[1];
    const isDocsLink = text.includes(`${domain}/open`);
    let docId = null;

    if (isDocsLink) {
      docId = text.split('open/').pop();
    }

    const data = this.getMercuryData();
    const mercury = new Mercury();

    mercury.identify(
      data.identity.accountId,
      data.identity.userId,
      data.identity.personId,
      data.identity.origin,
      data.config.environment,
    );
    mercury.track(
      'docs::onInteraction',
      {
        action: 'documentLinkClicked',
        documentId: docId,
        hash: location.hash,
        hostName: location.hostname,
        path: location.pathname,
        queryParams: location.search,
        annotations: JSON.stringify({
          linkOrigin: 'workchat',
          docsLink: isDocsLink,
        }),
      },
      {},
      true,
    );
  };

  render() {
    const urls = [];
    const placeholder = '<<<url>>>';

    let text = this.props.text.replace(/(https?:\/\/\S+\.\S+)/gi, match => {
      urls.push(match);
      return placeholder;
    });

    if (urls.length > 0) {
      const parts = text.split(placeholder);
      text = [];
      urls.forEach(url => {
        text.push(
          parts.shift(),
          <a target="_blank" href={url} onClick={this.trackLinkClicks(url)} key={url} rel="noreferrer">
            {url}
          </a>,
        );
      });
      text = text.concat(parts);
    }

    return text;
  }
}

export class MessageImage extends Component {
  static propTypes = {
    image: PropTypes.object.isRequired,
  };

  render() {
    const { image } = this.props;

    let img = null;
    if (!image.loaded && image.dataUrl) {
      img = <img src={image.dataUrl} alt="message-image" />;
    } else if (image.loaded) {
      img = <img src={image.thumb} alt="thumb" />;
    }

    return (
      <a target="_blank" href={image.full} rel="noreferrer">
        {img}
      </a>
    );
  }
}
