import type { QueryString } from 'data/types';
import type { UserFields } from 'data/user/model';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type FetchUsersResponse = {
  users: UserFields[];
  num_pages?: number;
};

export const fetchUsers = createWiwAsyncThunk<FetchUsersResponse, QueryString>(
  'user/fetchUsers',
  async (params = {}, { rejectWithValue }) => {
    try {
      return (await Monolith.query(params).get('/users')) as FetchUsersResponse;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchUsersPaginated = createWiwAsyncThunk<FetchUsersResponse['users'], QueryString>(
  'user/fetchPaginated',
  async (params = {}, { rejectWithValue }) => {
    try {
      let page = 1;
      let numPages = 1;
      const users = [];
      while (page <= numPages) {
        const response = (await Monolith.query({ ...params, page: page }).get('/users')) as FetchUsersResponse;
        users.push(...response.users);
        numPages = response.num_pages!;
        page += 1;
      }
      return users as UserFields[];
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
