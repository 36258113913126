import EntityState from 'data/EntityState';
import { persistOnboardingImport } from 'data/account/actions/persistOnboardingImport';
import { createShiftTemplate } from 'data/shiftTemplate/actions/createShiftTemplate';
import { deleteShiftTemplate, deleteShiftTemplates } from 'data/shiftTemplate/actions/deleteShiftTemplate';
import { fetchShiftTemplates } from 'data/shiftTemplate/actions/fetchShiftTemplates';
import { updateShiftTemplate } from 'data/shiftTemplate/actions/updateShiftTemplate';
import ShiftTemplate from 'data/shiftTemplate/model';
import generateThunkReducers from 'data/util/generateThunkReducers';
import { optimisticallyDeleteEntity, optimisticallyUpdateEntity } from 'data/util/optimisticUpdate';
import { deleteEntityItems, mergeEntityItems } from 'data/util/sliceHelpers';

import { createSlice } from '@reduxjs/toolkit';

const initialState = new EntityState();

export const shiftTemplateSlice = createSlice({
  name: 'shiftTemplate',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchShiftTemplates.fulfilled, (state, action) => {
        return mergeEntityItems(state, action.payload.blocks, ShiftTemplate);
      })
      .addCase(deleteShiftTemplates.fulfilled, (state, action) => {
        return deleteEntityItems(state, action, 'id');
      })
      .addCase(persistOnboardingImport.fulfilled, (state, action) => {
        return mergeEntityItems(state, action.payload.shiftTemplates, ShiftTemplate);
      });
    optimisticallyUpdateEntity(builder, createShiftTemplate, ShiftTemplate, { payloadPath: 'block' });
    optimisticallyUpdateEntity(builder, updateShiftTemplate, ShiftTemplate, { payloadPath: 'block' });
    optimisticallyDeleteEntity(builder, deleteShiftTemplate);
    generateThunkReducers(builder, 'shiftTemplate');
  },
});

export default shiftTemplateSlice.reducer;
