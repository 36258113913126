import type PostTaxDeduction from 'data/payrollTaxDeductions/model';
import type { PostTaxDeductionItem } from 'data/payrollTaxDeductions/model';
import Payroll from 'shared/api/payroll';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';

import { createAsyncThunk } from '@reduxjs/toolkit';

type CreateDeductionResponse = {
  tax_deductions: PostTaxDeductionItem;
};

export type CreateDeductionArgs = {
  tax_deduction: PostTaxDeduction;
  user_id: number;
};

export const createPayrollDeduction = createAsyncThunk<CreateDeductionResponse, CreateDeductionArgs>(
  'taxDeduction/create',
  async (params, { rejectWithValue }) => {
    try {
      return (await Payroll.url('/tax-deduction')
        .query({ user_id: params.user_id })
        .post(params.tax_deduction.toJSON())) as CreateDeductionResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
