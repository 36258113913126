import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import Account from 'data/account/model';
import Message from 'data/message/model';
import Notification from 'data/notifications/model';
import Request from 'data/request/model';
import Swap from 'data/swap/model';
import User from 'data/user/model';
import { NotifyItem } from 'notifications/components/NotifyItem';
import { getAuthAccount, getAuthUser } from 'shared/auth/selectors';
import AvatarImg from 'shared/ui/AvatarImg';

export class MessageAlert extends Component {
  static propTypes = {
    creator: PropTypes.instanceOf(User),
    message: PropTypes.instanceOf(Message).isRequired,
    accessory: PropTypes.oneOfType([
      PropTypes.instanceOf(Request),
      PropTypes.instanceOf(Swap),
      PropTypes.instanceOf(User),
    ]),
    currentUser: PropTypes.instanceOf(User).isRequired,
    currentAccount: PropTypes.instanceOf(Account).isRequired,
  };

  getMessage() {
    if (!this.props.message.content) {
      return ' posted a message.';
    }

    if (this.props.message.content.length > 80) {
      return ` posted a message: "${this.props.message.content.substr(0, 80)}..."`;
    }
    return ` posted a message: "${this.props.message.content}"`;
  }

  getAvatar() {
    if (this.props.creator) {
      return <AvatarImg user={this.props.creator} size={Notification.AVATAR_SIZE} />;
    }
  }

  getTitle() {
    const { creator, currentUser, currentAccount } = this.props;

    if (this.props.creator) {
      const displayName =
        currentAccount.getSettings('privacy.enabled') &&
        !currentUser.canSupervise() &&
        !creator.canSupervise() &&
        currentUser.id !== creator.id
          ? 'A coworker'
          : creator.shortName;

      return this.props.creator.id === this.props.currentUser.id ? 'You' : displayName;
    }
  }

  redirect = () => {
    if (this.props.accessory instanceof User) {
      window.location.assign(`../../users/#edit${this.props.accessory.id}`);
    } else if (this.props.accessory instanceof Request) {
      // TODO: Update this to the new request detail page for time-offs (probably /requests/time-off/[ID])
      window.location.assign(`${CONFIG.APP_LEGACY_ROOT}/requests/request/${this.props.accessory.id}`);
    } else if (this.props.accessory instanceof Swap) {
      // TODO: Update this to the new detail page for swap-drops (probably /requests/shifts/[ID])
      window.location.assign(`${CONFIG.APP_LEGACY_ROOT}/requests/swap/${this.props.accessory.id}`);
    }
  };

  render() {
    return (
      <NotifyItem
        title={this.getTitle()}
        avatar={this.getAvatar()}
        description={this.getMessage()}
        click={this.props.accessory ? this.redirect : null}
      />
    );
  }
}

export default connect(
  state => ({
    currentUser: getAuthUser(state),
    currentAccount: getAuthAccount(state),
  }),
  null,
)(MessageAlert);
