import type { TagListFields } from 'data/tag/TagList';
import { getUserId } from 'shared/auth/auth';
import { getDate as getLuxonDate } from 'shared/util/luxonTime';

import { DateTime } from 'luxon';

const SHIFT_TAGS_KEY = 'session-shift-tags';

export const updateShiftTagsSessionStore = (shiftId: string, tagData: TagListFields) => {
  const userShiftTagsKey = `${SHIFT_TAGS_KEY}-${getUserId()}`;
  const localShiftTags = JSON.parse(sessionStorage.getItem(userShiftTagsKey) || '{}');

  // Clear out expired tags
  for (const shiftId in localShiftTags) {
    if (getLuxonDate(localShiftTags[shiftId].expiration) <= DateTime.now()) {
      delete localShiftTags[shiftId];
    }
  }

  // Add the new tag data
  localShiftTags[shiftId] = {
    tagData: {
      // @ts-ignore
      tags: [], // worktags shift tags update endpoint does not include tags field if the array is empty
      ...tagData,
    },
    expiration: DateTime.now().plus({ seconds: 15 }),
  };
  sessionStorage.setItem(userShiftTagsKey, JSON.stringify(localShiftTags));
};

export const getShiftTagsFromSessionStore = () => {
  return JSON.parse(sessionStorage.getItem(`${SHIFT_TAGS_KEY}-${getUserId()}`) || '{}');
};
