import { updateUserMetadata } from 'data/metadata/actions';
import { EMPLOYEE_ONBOARDING } from 'data/metadata/keys';
import type { EmployeeOnboardingItems } from 'onboarding/constants';
import type { WiwDispatch, WiwGetState } from 'store';

export const setEmployeeOnboardingItemCompleted = (item: EmployeeOnboardingItems) => {
  return (dispatch: WiwDispatch, getState: WiwGetState) => {
    const employeeOnboarding = getState().data.metadata.user.get(EMPLOYEE_ONBOARDING);

    if (employeeOnboarding) {
      dispatch(
        updateUserMetadata({
          [EMPLOYEE_ONBOARDING]: {
            ...employeeOnboarding,
            [item]: true,
          },
        }),
      );
    }
  };
};
