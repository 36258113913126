import type User from 'data/user/model';
import Platform from 'shared/api/platform';
import { createWiwAsyncThunk } from 'store';

type DeleteAvatarParams = {
  user: User;
};

type DeleteAvatarResponse = {
  // empty response
};

export default createWiwAsyncThunk<DeleteAvatarResponse, DeleteAvatarParams>(
  'user/deleteAvatar',
  async (params, { rejectWithValue }) => {
    try {
      return await Platform.url(`/avatar/${params.user.id}`).delete();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
