import { Report } from 'data/reporting/models/Report';
import { ReportsState } from 'data/reporting/state/ReportsState';
import { REPORTS_FETCH, REPORT_REMOVE, REPORT_UPDATE } from 'store/action-types';

import type { AnyAction } from '@reduxjs/toolkit';
import { Map } from 'immutable';

export const reports = (state = new ReportsState(), action: AnyAction) => {
  switch (action.type) {
    case REPORTS_FETCH.REQUEST:
      return state.merge({
        loading: true,
        receivedAt: 0,
        loaded: false,
      });
    case REPORTS_FETCH.SUCCESS:
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        receivedAt: Date.now(),
        items: Map(action.payload.map((report: Report) => [report.id, new Report(report)])),
      });
    case REPORTS_FETCH.FAILURE:
      return state.merge({
        loaded: false,
        loading: false,
        error: action.payload,
      });
    case REPORT_UPDATE:
      return state.merge({
        items: state.items.set(action.item.id, new Report(action.item)),
      });
    case REPORT_REMOVE:
      return state.merge({
        items: state.items.delete(action.id),
      });
    default:
      return state;
  }
};
