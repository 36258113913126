import { updateAccountMetadata } from 'data/metadata/actions';
import { ACCOUNT_ACCEPTED_INVITE } from 'data/metadata/keys';

export default class GoogleTagManagerHelper {
  /**
   * Generate user data for Google Tag Manager (GTM)
   *
   * @param {User} user
   * @returns {Object}
   */
  static userData(user) {
    return {
      id: Number(user.id),
      email: user.email,
      title: user.roleName,
      firstName: user.first_name,
      lastName: user.last_name,
      phoneNumber: user.phone_number,
    };
  }

  /**
   * Generate account data for Google Tag Manager (GTM)
   *
   * @param {Account} account
   * @returns {Object}
   */
  static accountData(account, plan) {
    // commented out fields are not easily obtained given the account model data
    return {
      id: Number(account.id) || null,
      name: account.company || null,
      billingType: Number(account.billing_type) || 0,
      expectedEmployees: Number(account.ref_employees) || 0,
      // 'industry': account.industry || null,
      // 'isDemo': Boolean(account.is_demo) || false,
      isConverted: !!account.converted_at,
      isDeleted: Boolean(account.is_deleted) || false,
      isDeactivated: Boolean(account.is_deactivated) || false,
      createdAt: account.created_at || null,
      convertedAt: account.converted_at || null,
      deletedAt: account.deleted_at || null,
      // 'deactivatedAt': account.deactivated_at || null,
      plan: {
        id: Number(plan.id) || null,
        name: plan.name || null,
        type: Number(plan.type) || null,
      },
    };
  }

  static gtmEvent(eventName) {
    try {
      window.dataLayer && window.dataLayer.push({
        event: eventName,
        method: 'WIW',
      });
    } catch {
      // failures here aren't important
    }
  }

  static gtmAcceptedInvite(users, accountMetadata, dispatch) {
    const alreadyAcceptedInvite = accountMetadata?.get(ACCOUNT_ACCEPTED_INVITE);
    if (!alreadyAcceptedInvite) {
      const usersWithLogin = users.filter((user) => user.login_id !== 0);
      if (usersWithLogin.size > 1) {
        this.gtmEvent('accepted_invite');
        dispatch(updateAccountMetadata({ [ACCOUNT_ACCEPTED_INVITE]: true }));
      }
    }
  }
}
