import { openDialog } from 'dialogs/actions';
import { DialogContext, dialogCallbacks } from 'dialogs/context';
import type { DialogType } from 'dialogs/dialogs';
import type { DialogOptions } from 'dialogs/reducer';
import { useCallback, useContext, useMemo } from 'react';
import { useWiwDispatch } from 'store';

export default function useInDialog() {
  const dispatch = useWiwDispatch();
  const dialogContext = useContext(DialogContext);
  const callbacks = dialogCallbacks.get(dialogContext.name);

  const resolveCallback = useCallback(
    (args?: unknown) => {
      callbacks?.resolve(args);
      dialogCallbacks.delete(dialogContext.name);
      dialogContext.closeDialog();
    },
    [dialogContext.name],
  );

  const rejectCallback = useCallback(
    (args?: unknown) => {
      callbacks?.reject(args);
      dialogCallbacks.delete(dialogContext.name);
      dialogContext.closeDialog();
    },
    [dialogContext.name],
  );

  const openDialogCallback = useCallback(
    (dialog: DialogType, payload: Record<string, unknown> = {}, options: DialogOptions = {}) => {
      dispatch(openDialog(dialog, payload, options));
    },
    [dialogContext.name],
  );

  return useMemo(
    () => ({
      name: dialogContext.name,
      open: openDialogCallback,
      close: dialogContext.closeDialog,
      resolve: resolveCallback,
      reject: rejectCallback,
    }),
    [dialogContext.name, openDialogCallback, dialogContext.closeDialog, resolveCallback, rejectCallback],
  );
}
