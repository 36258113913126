import { fetchAccountBillingSummary } from 'data/stripe/actions/fetchAccountSummary';
import { linkPaymentMethod } from 'data/stripe/customers/actions/linkPaymentMethod';
import StripeCustomer from 'data/stripe/customers/model';
import { detachPaymentMethod } from 'data/stripe/paymentMethod/actions/detachPaymentMethod';
import { StripeCustomersState } from 'data/stripe/state/StripeCustomersState';
import generateThunkReducers from 'data/util/generateThunkReducers';
import { toEntityMap } from 'shared/util/toEntityMap';

import { createSlice } from '@reduxjs/toolkit';
import { Map } from 'immutable';

const initialState = new StripeCustomersState();

export const StripeCustomersSlice = createSlice({
  name: 'StripeCustomers',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchAccountBillingSummary.fulfilled, (state, action) => {
        // Customer is a new field on the response that we use going forward,
        // but check if it exists for backwards compatibility
        if (action.payload.customer) {
          return state.mergeDeep({
            loaded: true,
            items: toEntityMap(action.payload.customer, StripeCustomer),
          });
        }

        return state.mergeDeep({
          loaded: true,
          items: toEntityMap(
            action.payload.subscriptions.map(sub => sub.customer),
            StripeCustomer,
          ),
        });
      })
      .addCase(linkPaymentMethod.fulfilled, (state, action) => {
        return state.mergeDeep({
          loaded: true,
          items: toEntityMap(action.payload.data, StripeCustomer),
        });
      })
      .addCase(detachPaymentMethod.fulfilled, (state, action) => {
        return state.update('items', items => {
          return items.map(item => {
            // Only clear billing details if payment method id was attached and being used
            if (action.payload.data.id === item.billing_details.id) {
              return item.setIn(['billing_details'], Map({}));
            }
            return item;
          });
        });
      }),
      generateThunkReducers(builder, 'StripeCustomers');
  },
});

export default StripeCustomersSlice.reducer;
