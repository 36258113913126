import absence from 'data/absence/reducer';
import account from 'data/account/reducer';
import availability from 'data/availability/reducer';
import country from 'data/country/reducer';
import devices from 'data/devices/reducer';
import documents from 'data/documents/reducers/documentsReducer';
import integration from 'data/integration/reducer';
import location from 'data/location/reducer';
import login from 'data/login/reducer';
import overtime from 'data/overtime/reducers/overtime';
import overtimeRawCached from 'data/overtime/reducers/overtimeRawCached';
import person from 'data/person/reducer';
import plan from 'data/plan/reducer';
import position from 'data/position/reducer';
import request from 'data/request/reducer';
import shift from 'data/shift/reducer';
import shiftChain from 'data/shiftChain/reducer';
import shiftTemplate from 'data/shiftTemplate/reducer';
import sites from 'data/site/reducer';
import timeOffAccrual from 'data/timeOffAccrual/reducer';
import termsOfService from 'data/tos/reducer';
import user from 'data/user/reducer';

import annotation from './annotation/reducer';
import blacklist from './blacklist/reducer';
import forecast from './forecast/reducer';
import forecastCustomUnit from './forecastCustomUnit/reducer';
import freeTrialStatus from './freetrial/reducer';
import hourStats from './hourStats/reducers';
import industry from './industry/reducer';
import messages from './message/reducer';
import metadata from './metadata/reducer';
import notification from './notifications/reducer';
import openShiftRequests from './openshiftRequests/reducer';
import overtimeLabor from './overtimeLaborCost/reducer';
import overtimeScheduled from './overtimeScheduled/reducer';
import payPeriod from './payPeriod/reducers';
import payPeriodData from './payPeriodData/reducers';
import payroll from './payroll/reducer';
import benefits from './payrollBenefits/reducer';
import payrollCheck from './payrollCheck/reducers';
import payrollData from './payrollData/reducer';
import payrollHours from './payrollHours/reducer';
import payrollNotice from './payrollNotice/reducer';
import taxDeduction from './payrollTaxDeductions/reducer';
import { punch } from './punch/reducers';
import punchState from './punchState/reducer';
import reporting from './reporting/reducer';
import requestTypes from './request-type/reducer';
import salariedSettings from './salariedSettings/reducer';
import salariedWages from './salariedWages/reducer';
import scheduleRules from './scheduleRule/reducer';
import shiftBreak from './shiftBreak/reducers';
import shiftHistory from './shiftHistory/reducer';
import stripe from './stripe/reducer';
import swap from './swap/reducer';
import swapShift from './swapShift/reducer';
import tag from './tag/reducer';
import scheduledTaskLists from './tasks/scheduledTaskListReducer';
import taskLists from './tasks/taskListReducer';
import teamTxt from './teamtxt/reducer';
import template from './template/reducer';
import { time } from './time/reducers';
import timeHistory from './timeHistory/reducer';
import timezone from './timezone/reducer';
import transaction from './transaction/reducer';

import { combineReducers } from '@reduxjs/toolkit';

export default combineReducers({
  absence,
  account,
  annotation,
  availability,
  blacklist,
  country,
  devices,
  documents,
  forecast,
  forecastCustomUnit,
  freeTrialStatus,
  hourStats,
  industry,
  integration,
  location,
  login,
  messages,
  metadata,
  notification,
  openShiftRequests,
  overtime,
  overtimeLabor,
  overtimeRawCached,
  overtimeScheduled,
  payPeriod,
  payPeriodData,
  payroll,
  benefits,
  payrollCheck,
  payrollData,
  taxDeduction,
  payrollHours,
  payrollNotice,
  person,
  plan,
  position,
  punch,
  punchState,
  reporting,
  request,
  requestTypes,
  salariedSettings,
  salariedWages,
  scheduledTaskLists,
  scheduleRules,
  shift,
  shiftBreak,
  shiftChain,
  shiftHistory,
  shiftTemplate,
  sites,
  stripe,
  swap,
  swapShift,
  tag,
  taskLists,
  teamTxt,
  template,
  termsOfService,
  time,
  timeHistory,
  timeOffAccrual,
  timezone,
  transaction,
  user,
});
