import type { ShiftBreak } from 'data/shiftBreak/models';
import type { DataResponse, QueryString } from 'data/types';
import V3 from 'shared/api/v3';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

export const fetchShiftBreaks = createWiwAsyncThunk<DataResponse<ShiftBreak[]>, QueryString>(
  'shiftBreak/fetch',
  async (params = {}, { rejectWithValue }) => {
    try {
      return await V3.url('/shift-breaks').query(params).get();
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
