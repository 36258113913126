import PropTypes from 'prop-types';
import { Component } from 'react';

import classnames from 'classnames';
import { propTypes, withFormsy } from 'formsy-react';
import { without } from 'lodash';

export class CheckboxGroup extends Component {
  elements = {};

  static propTypes = {
    ...propTypes,
    label: PropTypes.any,
    disabled: PropTypes.bool,
    value: PropTypes.any,
    name: PropTypes.string,
    className: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        disabled: PropTypes.bool,
        value: PropTypes.any,
        label: PropTypes.node,
        name: PropTypes.string,
        'data-testid': PropTypes.string,
      }),
    ),
    type: PropTypes.oneOf(['inline', 'stacked']),
    width: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.oneOf(['auto'])]),
    newStyle: PropTypes.bool,
    onChange: PropTypes.func,
    lockInitialValue: PropTypes.any,
    lockMessage: PropTypes.string,
    validatePristine: PropTypes.bool,
  };

  static defaultProps = {
    type: 'stacked',
    name: 'test',
    options: [],
    width: false,
    newStyle: false,
    lockInitialValue: false,
    validatePristine: false,
    onChange: () => {},
  };

  handleChange = event => {
    if (event.currentTarget.readOnly) {
      return;
    }
    const oldValues = this.props.value;
    const value = Number.parseInt(event.currentTarget.value);
    let newValue;
    if (oldValues.includes(value)) {
      newValue = without(oldValues, value);
    } else {
      oldValues.push(value);
      newValue = oldValues;
    }

    this.props.setValue(newValue);
    this.props.onChange(this.props.name, newValue);

    // force an update since values can get lost in the hoc on rapid changes :eyeroll:
    this.forceUpdate();
  };

  renderElement = () => {
    return this.props.options.map(checkbox => {
      const checked = this.props.value.includes(checkbox.value);
      const disabled = this.props.isFormDisabled || checkbox.disabled || this.props.disabled;
      const readOnly = checkbox.value === this.props.lockInitialValue;
      const wrapperClasses = classnames('custom-checkbox custom-control custom-control-inline', {
        'hint--top hint--rounded hint--error': readOnly,
      });
      const checkboxName = `${this.props.name}_${checkbox.value}`;
      return (
        <div className={wrapperClasses} key={checkbox.value} aria-label={readOnly && this.props.lockMessage}>
          <input
            id={checkboxName}
            name={checkboxName}
            ref={input => {
              this.elements[checkbox.value] = input;
            }}
            className="custom-control-input"
            checked={checked}
            type="checkbox"
            autoComplete="off"
            readOnly={readOnly}
            value={checkbox.value}
            onChange={this.handleChange}
            disabled={disabled}
            data-testid={checkbox['data-testid']}
          />
          <label className="custom-control-label" htmlFor={checkboxName}>
            {checkbox.label}
          </label>
        </div>
      );
    });
  };

  render() {
    const className = classnames('form-group checkbox-group', {
      col: !this.props.width,
      [`col-${this.props.width}`]: this.props.width,
      'has-danger': this.props.showError && (this.props.validatePristine || !this.props.isPristine),
      newStyle: this.props.newStyle,
    });

    return (
      <div className={className}>
        <label htmlFor={this.props.name}>{this.props.label}</label>
        <div>{this.renderElement()}</div>
        {(this.props.validatePristine || !this.props.isPristine) && this.props.showError && (
          <span className="form-control-feedback">{this.props.errorMessage}</span>
        )}
      </div>
    );
  }
}
/**
 * @deprecated please migrate to hook-form from shared/form/inputs
 */
export default withFormsy(CheckboxGroup);
