import { getAuthAccount } from 'shared/auth/selectors';

import { createSelector } from '@reduxjs/toolkit';

export const getAccountSettings = state => {
  const account = getAuthAccount(state);
  return account.settings;
};

export const getAccountScheduleSettings = createSelector([getAccountSettings], settings => settings.schedule);

export const getAccountBillingSettings = createSelector([getAccountSettings], settings => settings.billing || {});
