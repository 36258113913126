import { addBranchSwitchingHeaders } from 'shared/api/util/addBranchSwitchingHeaders';
import Environment from 'shared/util/environment';

import Cookies from 'js-cookie';
import { defaultsDeep } from 'lodash';

function buildQueryString(obj: { [key: string]: string }) {
  return Object.keys(obj)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join('&')
    .replace(/%20/g, '+');
}

type ErrorCode = string | number;

type ErrorBody = {
  message: string;
  code?: ErrorCode;
};

/*
 * Extends https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error
 * This class enriches the base ES6 Error object with additional fields like code, body and Response, and attempts to
 * construct a reasonable "message" property for some definition of reasonable.
 */
export class ErrorResponse extends Error {
  response: Response;
  body: any;
  message: string; // Inherited from Error; here for clarity
  code?: ErrorCode;

  constructor(response: Response, body: any) {
    super(response.statusText);
    this.name = 'ErrorResponse';
    this.response = response;
    this.body = body;
    this.code = body?.code;
    this.message = body?.message;
  }

  hasErrorCode(code: string): boolean {
    return this.code === code;
  }
}

/**
 * Some endpoints (usually but not always in the monolith) return a JSON body with a single "error" string property
 *
 * Access with: errorResponseOne.body.error
 */
export class ErrorResponseOne extends ErrorResponse {
  constructor(response: Response, body: { error: string; code?: ErrorCode }) {
    super(response, body);
    this.name = 'ErrorResponseOne';
    this.message = body.error;
  }
}

/**
 * Some endpoints (particularly Golang, some monolith) return a JSON body with an "errors" property containing an
 * array of { code: "string", message: "string" } shaped objects
 *
 * Access with: errorResponseMany.body.errors
 */
export class ErrorResponseMany extends ErrorResponse {
  constructor(response: Response, body: { errors: ErrorBody[] }) {
    super(response, body);
    this.name = 'ErrorResponseMany';
    this.message = body.errors.map(err => (err.message ? err.message : err.toString())).join('\n');
  }

  hasErrorCode(code: string): boolean {
    return this.body.errors.some((err: ErrorBody) => err.code === code);
  }
}

export interface RequestOptions {
  method?: string;
  url?: string;
  headers?: {};
  query?: {};
  body?: any;
  rawResponse?: boolean;
  rawError?: boolean;
  signal?: AbortSignal;
}

export default class Request {
  root: string;
  defaultOptions: RequestOptions;

  constructor(options: RequestOptions = {}, root: string = CONFIG.API_ROOT) {
    this.defaultOptions = {
      ...options,
      headers: {
        'W-Date-Format': 'iso',
        ...options.headers,
      },
    };
    this.root = root;
  }

  /**
   * Try to build a helpful error object if response not ok, and rawResponse option is not set
   */
  checkStatus(response: Response) {
    if (response.ok) {
      // https://fetch.spec.whatwg.org/#ok-status
      return response;
    }

    // body could be anything that can be represented by JSON — an object, an array, a string, a number…
    // https://developer.mozilla.org/en-US/docs/Web/API/Response/json
    return response.json().then(body => {
      if (body === null || body === undefined) {
        throw new ErrorResponse(response, body);
      }

      if (typeof body === 'object') {
        if (Array.isArray(body.errors)) {
          throw new ErrorResponseMany(response, body);
        }
        if (typeof body.error === 'string') {
          throw new ErrorResponseOne(response, body);
        }
      }

      throw new ErrorResponse(response, body);
    });
  }

  get(url: string, opts: RequestOptions = {}) {
    opts.method = 'GET';
    opts.url = url;
    return this.send(opts);
  }

  post(url: string, data = {}, opts: RequestOptions = {}) {
    opts.url = url;
    opts.method = 'POST';
    opts.body = JSON.stringify(data);
    return this.send(opts);
  }

  put(url: string, data = {}, opts: RequestOptions = {}) {
    opts.url = url;
    opts.method = 'PUT';
    opts.body = JSON.stringify(data);
    return this.send(opts);
  }

  patch(url: string, data = {}, opts: RequestOptions = {}) {
    opts.url = url;
    opts.method = 'PATCH';
    opts.body = JSON.stringify(data);
    return this.send(opts);
  }

  delete(url: string, opts: RequestOptions & { data?: any } = {}) {
    opts.url = url;
    opts.method = 'DELETE';
    if (opts.data) {
      opts.body = JSON.stringify(opts.data);
    }
    return this.send(opts);
  }

  upload(url: string, data = {}, opts: RequestOptions = {}) {
    opts.url = url;
    opts.method = 'POST';
    opts.body = data;
    return this.send(opts);
  }

  send(opts: RequestOptions = {}) {
    const options = defaultsDeep({}, opts, this.defaultOptions);

    if (!Environment.isTesting()) {
      options.query = {
        ...options.query,
        _v: CONFIG.DEPLOY.TAG || 'development',
      };
    }

    addBranchSwitchingHeaders(options.headers);

    if (CONFIG.DEVPANEL.passXdebugCookie && Cookies.get('XDEBUG_SESSION')) {
      const xdebugSession = Cookies.get('XDEBUG_SESSION');
      options.query = {
        ...options.query,
        XDEBUG_SESSION_START: xdebugSession,
      };
    }

    let url = options.url;
    if (!url) {
      throw new Error('Please include a `url` option for each request.');
    }
    if (url[0] !== '/') {
      throw new Error(`Please prefix your 'url' option with '/' (got '${url}')`);
    }
    delete options.url;

    if (url.search(/^http/i) !== 0) {
      url = this.root + url;
    }

    if (options.query) {
      let prefix = '?';
      if (url.indexOf('?') > -1) {
        prefix = '&';
      }
      url += `${prefix}${buildQueryString(options.query)}`;
    }

    return fetch(url, options)
      .then(response => {
        if (!options.rawError) {
          return this.checkStatus(response);
        }
        return response;
      })
      .then(response => {
        // If the caller wants the raw response then we should just give it to them. This can happen if the caller is
        // trying to derive a blob from the response.
        if (options.rawResponse) {
          return response;
        }

        // If not then just do what we've always done and parse the response as json.
        return this.parseResponse(response);
      });
  }

  parseResponse(response: Response) {
    if (response.status === 204) {
      return response.text();
    }
    return response.json();
  }
}
