import BaseModel, { type C } from 'data/BaseModel';
import type Account from 'data/account/model';
import type { Payroll as PayPeriodSettings } from 'data/account/types/AccountSettings';
import type User from 'data/user/model';
import { getMomentDate } from 'shared/util/time';
import { isTimezoneOffsetDifferent } from 'timesheets/util/isTimezoneOffsetDifferent';

import { get as _get } from 'lodash';
import { DateTime } from 'luxon';
import moment, { type Duration, type Moment } from 'moment-timezone';

export enum PayPeriodFrequency {
  EveryTwoWeeks = 0,
  Weekly = 1,
  TwicePerMonth = 2,
  Monthly = 3,
  Custom = 4,
}

export interface PayPeriodFields {
  id: number | null;
  payroll_id: number;
  creator_id: number | null;
  start_date: string | null;
  end_date: string | null;
  notes: string | null;
  is_closed: boolean | null;
  is_finalized: boolean | null;
  finalized_at: string | null;
  created_at: string | null;
  updated_at: string | null;
  settings: PayPeriodSettings | null;
}

class PayPeriod extends BaseModel<PayPeriodFields>({
  id: null,
  payroll_id: 0,
  creator_id: null,
  start_date: null,
  end_date: null,
  notes: null,
  is_closed: null,
  is_finalized: null,
  finalized_at: null,
  created_at: null,
  updated_at: null,
  settings: null,
}) {
  /**
   * Gives you the start and end dates of the next payroll.
   *
   * @param payrollDT The account's `payroll_date`. Used to determine payroll
   *                  end dates for the monthly frequency. It should be in a
   *                  local timezone.
   * @param frequency The payroll frequency: weekly, bi-weekly, semi-monthly,
   *                  monthly, or custom.
   * @param lastEndDT The end date of the payroll you're trying to generate a
   *                  "next" one for. Should be in a local timezone.
   * @returns An object with `start` and `end` properties in the local timezone.
   */
  static getNextPayPeriod(
    payrollDT: DateTime,
    frequency: PayPeriodFrequency,
    lastEndDT: DateTime,
  ): { start: DateTime; end: DateTime | null } {
    const start = lastEndDT.plus({ seconds: 1 });

    let end = null;
    switch (frequency) {
      case PayPeriodFrequency.EveryTwoWeeks:
        end = lastEndDT.plus({ days: 14 });
        break;
      case PayPeriodFrequency.Weekly:
        end = lastEndDT.plus({ days: 7 });
        break;
      case PayPeriodFrequency.TwicePerMonth:
        end = start.day >= 1 && start.day < 16 ? start.set({ day: 15 }).endOf('day') : start.endOf('month');

        break;
      case PayPeriodFrequency.Monthly:
        end = lastEndDT.plus({ months: 1 });
        if (payrollDT.day === payrollDT.daysInMonth) {
          end = end.endOf('month');
        } else if (payrollDT.day > end.day && payrollDT.day <= end.daysInMonth) {
          end = end.set({ day: payrollDT.day });
        }

        break;
      case PayPeriodFrequency.Custom:
        // do nothing as the custom type cannot have an end automatically determined
        console.warn('Tried to get start and end dates for the next payroll with a custom frequency.');
        break;
    }

    return { start, end };
  }

  date<K extends keyof (C & PayPeriodFields)>(
    prop: K,
    authAccount: Account | null = null,
    authUser: User | null = null,
  ): Moment | null {
    if (prop === 'end_date' && this.end_date === null && authAccount) {
      return getMomentDate(this.getEndDateFromNow(authAccount, authUser));
    }
    return super.date(prop);
  }

  duration() {
    if (this.end_date == null || this.start_date == null) {
      console.warn('Either the start or the end date was null and a duration could not be calculated.');
      return 0;
    }
    // Use Math.round to for pay periods that have a non midnight start and the first shifted pay period.
    // Luxon doesnt return a whole number when using diff like Momentjs does
    return Math.round(
      DateTime.fromISO(this.end_date)
        // we add a second to the end date to bump the time from 23:59:59 to 00:00:00 to make the diff a whole number
        .plus({ seconds: 1 })
        .diff(DateTime.fromISO(this.start_date), 'days')
        .get('days'),
    );
  }

  getDurationFromNow(account: Account, user: User | null = null) {
    if (this.end_date !== null) {
      return this.duration();
    }

    return Math.round(
      this.getEndDateFromNow(account, user)
        .plus({ seconds: 1 })
        .diff(DateTime.fromISO(this.start_date!), 'days')
        .get('days'),
    );
  }

  getEndDateFromNow(account: Account, user: User | null = null) {
    if (this.end_date !== null) {
      return DateTime.fromISO(this.end_date);
    }

    const workDayStart = account.getSettings('payroll.work_day_start');
    const start = DateTime.fromISO(this.start_date!).setZone(account.timezone_name!);
    let end = DateTime.now().setZone(account.timezone_name!);

    // the end date is the same date as the start date, add a day since minimum payroll length is two days
    if (end.hasSame(start, 'day')) {
      end =
        workDayStart === '00:00'
          ? start.plus({ days: 1 }).endOf('day').set({ millisecond: 0 })
          : start.plus({ days: 2 }).minus({ seconds: 1 });
    } else if (end < start) {
      end =
        workDayStart === '00:00'
          ? start.plus({ days: 1 }).endOf('day').set({ millisecond: 0 })
          : start.plus({ days: 2 }).minus({ seconds: 1 });
    } else {
      if (workDayStart === '00:00') {
        end = end.endOf('day').set({ millisecond: 0 });
      } else {
        const [hour, minute] = workDayStart.split(':');
        end = end
          .startOf('day')
          .set({ hour: Number(hour), minute: Number(minute) })
          .minus({ seconds: 1 });
      }
    }

    if (user && isTimezoneOffsetDifferent(end, user.timezone_name, account.timezone_name)) {
      end = end.setZone(user.timezone_name!);
    }

    return end;
  }

  hasEnded() {
    return this.date('end_date')?.isBefore(moment());
  }

  // Rounds the date to the nearest duration interval
  round(date: keyof (C & PayPeriodFields), duration: Duration) {
    return moment(Math.round(+this.date(date)! / +duration) * +duration);
  }

  isClosedAndFinalized() {
    return this.is_closed && this.is_finalized;
  }

  isClosedNotFinalized() {
    return this.is_closed && !this.is_finalized;
  }

  getSettings(path?: string, defaultValue?: any) {
    if (!path) {
      return this.settings;
    }

    return _get(this.settings, path, defaultValue);
  }
}

export default PayPeriod;
