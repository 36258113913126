import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useLDFlag } from 'data/LD/selectors/getLDFlag';
import type User from 'data/user/model';
import { getUsers } from 'data/user/selectors';
import { openDialog } from 'dialogs/actions';
import { MISSING_EMPLOYEES_DIALOG } from 'marketing/dialogs';
import { MISSING_EMPLOYEES_CLOSED_KEY } from 'marketing/dialogs/MissingEmployeesDialog/constants';
import { getAccountItem } from 'shared/util/accountLocalStorage';
import Mercury, { formatQueryString } from 'shared/util/mercury';
import { useWiwDispatch, useWiwSelector } from 'store';

import { DateTime } from 'luxon';

const MISSING_CONTACT_HOURS_THRESHOLD = 48;
const PENDING_INVITE_HOURS_THRESHOLD = 48;

type MissingEmployeeProps = {
  user: User;
  isTrial: boolean;
  planExpires: DateTime | null;
};

const MissingEmployees = ({ user, isTrial, planExpires }: MissingEmployeeProps) => {
  const dispatch = useWiwDispatch();

  const location = useLocation();
  const users = useWiwSelector(getUsers);

  const missingEmployeeLdFlag = useLDFlag('cat-1863-missing-employee-dialog');

  useEffect(() => {
    if (showDialog()) {
      dispatch(openDialog(MISSING_EMPLOYEES_DIALOG));
      const queryParams =
        window.location.search === '' ? window.location.search : formatQueryString(window.location.search);

      Mercury.track(
        'web::onInteraction',
        {
          annotations: JSON.stringify({ missingEmployeesDialog: 'open' }),
          appComponent: 'MissingEmployeesDialog',
          hash: window.location.hash,
          hostName: window.location.hostname,
          path: window.location.pathname,
          queryParams: queryParams,
          target: 'missingEmployeesDialog',
          userRole: user.mercuryRoleName(),
        },
        {},
      );
    }
  }, [location, missingEmployeeLdFlag]);

  const showDialog = () => {
    const isScheduler = !!location.pathname.match(/^\/scheduler(\/)?$/i);
    if (!isScheduler) {
      return false;
    }

    if (!missingEmployeeLdFlag) {
      return false;
    }

    const isActiveTrial = isTrial && planExpires && planExpires > DateTime.now();
    if (!isActiveTrial) {
      return false;
    }

    if (!user.canSupervise) {
      return false;
    }

    if (getAccountItem(MISSING_EMPLOYEES_CLOSED_KEY, user.account_id)) {
      return false;
    }

    if (!hasMissingEmployees()) {
      return false;
    }

    return true;
  };

  const hasMissingEmployees = () => {
    const missingEmployees = users.find(user => {
      const createdAtHoursDiff = DateTime.now().diff(user.mustLuxonDate('created_at'), 'hours').hours;
      const invitedAtHoursDiff = DateTime.now().diff(user.mustLuxonDate('invited_at'), 'hours').hours;

      // missing contact info for too long OR pending invite for too long
      return (
        (!user.hasContactInformation() && createdAtHoursDiff > MISSING_CONTACT_HOURS_THRESHOLD) ||
        (!user.login_id && invitedAtHoursDiff > PENDING_INVITE_HOURS_THRESHOLD)
      );
    });

    return !!missingEmployees;
  };

  return null;
};

export default MissingEmployees;
