import { UPDATE_BILLING } from 'store/action-types';

import { Map } from 'immutable';

const initialState = new Map({
  loading: false,
  success: false,
  hasError: false,
  error: null,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_BILLING.FAILURE:
      return state.merge({
        loading: false,
        success: false,
        hasError: true,
        error: action.payload.error,
      });

    case UPDATE_BILLING.REQUEST:
      return state.merge({
        loading: true,
        success: false,
        hasError: false,
        error: null,
      });

    case UPDATE_BILLING.SUCCESS:
      return state.merge({
        loading: false,
        success: true,
        hasError: false,
        error: null,
      });

    default:
      return state;
  }
}
