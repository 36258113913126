import { lazy } from 'react';

import type { Dialogs } from 'dialogs/dialogs';

const LockTerminalDialog = lazy(() => import('./LockTerminalDialog'));
const UnlockTerminalDialog = lazy(() => import('./UnlockTerminalDialog'));
const TerminalSelectActionDialog = lazy(() => import('./TerminalSelectActionDialog'));

export const LOCK_TERMINAL_DIALOG = 'LOCK_TERMINAL_DIALOG';
export const UNLOCK_TERMINAL_DIALOG = 'UNLOCK_TERMINAL_DIALOG';
export const TERMINAL_SELECT_ACTION_DIALOG = 'TERMINAL_SELECT_ACTION_DIALOG';

const dialogs: Dialogs = {
  [LOCK_TERMINAL_DIALOG]: LockTerminalDialog,
  [UNLOCK_TERMINAL_DIALOG]: UnlockTerminalDialog,
  [TERMINAL_SELECT_ACTION_DIALOG]: TerminalSelectActionDialog,
};

export default dialogs;
