import { useEffect } from 'react';

import NoAccess from 'billing/components/noAccess';
import { getAuthAccount, getAuthUser } from 'shared/auth/selectors';
import { useWiwSelector } from 'store';

export default function HasBillingRole(ComposedComponent) {
  const HasBillingRole = () => {
    const user = useWiwSelector(getAuthUser);
    const account = useWiwSelector(getAuthAccount);

    useEffect(() => {
      if (!user.canBilling()) {
        window.location.assign(CONFIG.APP_LEGACY_ROOT);
      }

      // Redirect users of legacy billing to old billing page
      if (!account.isPerUserBilling()) {
        window.location.assign(`${CONFIG.APP_LEGACY_ROOT}/account`);
      }
    }, []);

    if (account?.isChild()) {
      return <NoAccess />;
    }

    return <ComposedComponent />;
  };
  return HasBillingRole;
}
