import { StringDateTimeSchema } from 'shared/zod-schemas';
import { z } from 'zod';

export const BREAK_TYPE = {
  Paid: 1,
  Unpaid: 2,
} as const;

// just a limited set of fields that punch-related things care about
export const ShiftBreakSchema = z.object({
  id: z.number(),
  start: StringDateTimeSchema.nullish().or(z.string().max(0)),
  end: StringDateTimeSchema.nullish().or(z.string().max(0)),
  length: z.number(),
  type: z.nativeEnum(BREAK_TYPE),
  scheduledbreaks_id: z.number().nullish(),
});

export const ScheduledBreakSchema = z.object({
  id: z.number(),
  start_time: StringDateTimeSchema.nullish().or(z.string().max(0)),
  end_time: StringDateTimeSchema.nullish().or(z.string().max(0)),
  length: z.number(),
  paid: z.boolean(),
  sort: z.number(),
  shift_id: z.number(),
  shift_break: ShiftBreakSchema.nullish(),
});

export const PunchStateSchema = z.object({
  id: z.number().optional(),
  canClockIn: z.boolean(),
  canClockOut: z.boolean(),
  canStartBreak: z.boolean(),
  canEndBreak: z.boolean(),
  needsUnpaidBreakConfirmation: z.boolean().optional(),
  recordingUnpaidBreaksAllowed: z.boolean().optional(),
  recordingPaidBreaksAllowed: z.boolean().optional(),
  needsBreakConfirmation: z.boolean().optional(),
  punchStartTime: z.string().optional(),
  punchTimeId: z.number().optional(),

  /**
   * If string, the value is a URL you should fetch to get the set of resources.
   * If number, the value is the ID of the resource you must use.
   */
  schedules: z.union([z.string().url(), z.number()]).optional(),

  /**
   * If string, the value is a URL you should fetch to get the set of resources.
   * If number, the value is the ID of the resource you must use.
   */
  positions: z.union([z.string(), z.number()]).optional(),

  /**
   * If string, the value is a URL you should fetch to get the set of resources.
   * If number, the value is the ID of the resource you must use.
   */
  jobSites: z.union([z.string(), z.number()]).optional(),
  shift: z.number().optional(),
  break: ShiftBreakSchema.optional(),
  availableShifts: z.array(z.number()).optional(),
  errorCode: z.number().optional(),
  scheduledBreaks: z.array(ScheduledBreakSchema).nullish(),
  unscheduledBreaks: z.array(ShiftBreakSchema).nullish(),
});
