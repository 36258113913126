import type { QueryString } from 'data/types';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

export const replaceUsers = createWiwAsyncThunk<{ users: any[] }, QueryString>(
  'user/replaceUsers',
  async (params = {}, { rejectWithValue }) => {
    try {
      return (await Monolith.query(params).get('/users')) as { users: any[] };
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
