import { useLDFlag } from 'data/LD/selectors/getLDFlag';
import { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { MenuItem } from 'shared/components/nav/MenuItem';
import FontIcon from 'shared/ui/FontIcon';
import { useWiwDispatch } from 'store';
import { openSendMessageDialog } from 'teamtxt/dialogs';
import WorkChat from 'workchat/WorkChat';
import WorkChatV2 from 'workchat/v2/components/WorkChatV2';

type WorkchatMenuItemProps = {
  workchat?: boolean;
  setup?: boolean;
  teamText?: boolean;
  isDesktop?: boolean;
};

const WorkchatMenuItem = ({ workchat, setup, teamText, isDesktop }: WorkchatMenuItemProps) => {
  const [showDropdown, updateShowDropdown] = useState(false);
  const dispatch = useWiwDispatch();

  const fdt837 = useLDFlag('fdt-837-work-chat-delete');
  const WorkChatComponent = fdt837 ? WorkChatV2 : WorkChat;

  return (
    <Fragment>
      {workchat && isDesktop && (
        <li
          id="workchat-container"
          className={`nav-item workchat hide-label-tablet dropdown-starts-left ${
            !isDesktop && showDropdown ? 'show' : ''
          } dropdown`}
          onClick={() => updateShowDropdown(!showDropdown)}
          data-testid="workchat-menu"
        >
          <WorkChatComponent />
        </li>
      )}

      {workchat && !isDesktop && (
        <div
          id="workchat-container"
          className={`workchat hide-label-tablet dropdown-starts-left ${
            !isDesktop && showDropdown ? 'show' : ''
          } dropdown`}
          onClick={() => updateShowDropdown(!showDropdown)}
          data-testid="workchat-menu"
        >
          <WorkChatComponent />
        </div>
      )}

      {setup && isDesktop && (
        <MenuItem icon="workchat" isMainNav label="WorkChat" hideLabel="tablet" show={true} to="/workchat/setup" />
      )}

      {setup && !isDesktop && (
        <Link className="workchat-nav-item" to="/workchat/setup">
          <FontIcon icon="workchat" />
          <span>WorkChat</span>
        </Link>
      )}

      {teamText && isDesktop && (
        <MenuItem
          icon="workchat"
          isMainNav
          label="Message Staff"
          hideLabel="tablet"
          onClick={() => dispatch(openSendMessageDialog())}
          show={true}
        />
      )}

      {teamText && !isDesktop && (
        <button type="button" className="workchat-nav-item" onClick={() => dispatch(openSendMessageDialog())}>
          <FontIcon icon="workchat" />
          <span>Message Staff</span>
        </button>
      )}
    </Fragment>
  );
};

export default WorkchatMenuItem;
