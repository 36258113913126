import type { MilestoneThresholdUnit } from 'data/timeOffAccrual/models/timeOffPolicyRate';
import type { PolicyResponse } from 'data/timeOffAccrual/types/common';
import type { DataResponse } from 'data/types';
import Scheduling from 'shared/api/scheduling';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

export interface RateInput {
  id: string;
  accrueHours: number;
  hoursWorked: number;
  milestoneThreshold: number;
  milestoneThresholdUnit: MilestoneThresholdUnit;
  balanceLimit: number;
  balanceLimitEnabled: boolean;
}

export interface UpdatePolicyRatesArgs {
  id: string;
  rates: RateInput[];
}

export const updatePolicyRates = createWiwAsyncThunk<DataResponse<PolicyResponse>, UpdatePolicyRatesArgs>(
  'timeOff/policies/updatePolicyRates',
  async ({ id, ...ratesData }, { rejectWithValue }) => {
    try {
      return await Scheduling.post(ratesData, `/timeoff/policies/${id}/rates`);
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
