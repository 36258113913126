import BaseModel from 'data/BaseModel';

export interface PostTaxDeduction {
  id: string;
  type: string;
  employee: string;
  description: string;
  effective_start: string;
  effective_end: string;
  miscellaneous: {
    total_amount: string;
    amount: string;
    percent: number;
    annual_limit: string;
  };
  child_support: {
    external_id: string;
    agency: string;
    fips_code: string;
    issue_date: string;
    amount: string;
    max_percent: number;
  };
  managed: boolean;
  metadata: {
    payroll_id: string | undefined;
    applied_amount: string | undefined;
  };
}

export default class PostTaxDeductionRecord extends BaseModel<PostTaxDeduction>({
  id: '',
  type: '',
  employee: '',
  description: '',
  effective_start: '',
  effective_end: '',
  miscellaneous: {
    total_amount: '',
    amount: '',
    percent: 0,
    annual_limit: '',
  },
  child_support: {
    external_id: '',
    agency: '',
    fips_code: '',
    issue_date: '',
    amount: '',
    max_percent: 0,
  },
  managed: false,
  metadata: {
    payroll_id: '',
    applied_amount: '0',
  },
}) {
  static Type = {
    ONE_TIME: 'miscellaneous',
  };

  isFullyDeducted() {
    return Number.parseFloat(this.miscellaneous.amount) === Number.parseFloat(this.metadata?.applied_amount || '0');
  }

  isOneTimeDeduction() {
    return this.type === PostTaxDeductionRecord.Type.ONE_TIME;
  }
}
