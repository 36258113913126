import BaseModel from 'data/BaseModel';
import type { ConcernsFields } from './Concerns';

export interface ShiftSwapConcernsListFields {
  swapShiftId: string | null;
  dropperRules: ConcernsFields[];
  swapperRules: ConcernsFields[];
}

class ShiftSwapConcernsList extends BaseModel<ShiftSwapConcernsListFields>({
  swapShiftId: null,
  dropperRules: [],
  swapperRules: [],
}) {}

export default ShiftSwapConcernsList;
