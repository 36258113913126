export function isGt(values, value, min) {
  if (!value && value !== 0) {
    return true;
  }
  return value > min;
}

/**
 *
 * @param values
 * @param value
 * @param min
 * @return {boolean}
 */
export function isGte(values, value, min) {
  if (!value && value !== 0) {
    return true;
  }
  return value >= min;
}

export function isLt(values, value, max) {
  if (!value && value !== 0) {
    return true;
  }
  return value < max;
}

/**
 *
 * @param values
 * @param value
 * @param max
 * @return {boolean}
 */
export function isLte(values, value, max) {
  if (!value && value !== 0) {
    return true;
  }
  return value <= max;
}

export function maxPrecision(values, value, max) {
  if (!value && value !== 0) {
    return true;
  }
  const numParts = value.toString().split('.');
  return numParts.length < 2 || numParts[1].length <= max;
}
