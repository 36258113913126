import type ShiftTemplate from 'data/shiftTemplate/model';
import type { ShiftTemplateFields } from 'data/shiftTemplate/model';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type DeleteShiftTemplateArgs = ShiftTemplate;

type DeleteShiftTemplateResponse = {
  success: boolean;
};

export const deleteShiftTemplate = createWiwAsyncThunk<DeleteShiftTemplateResponse, DeleteShiftTemplateArgs>(
  'shiftTemplate/delete',
  async (shiftTemplate, { rejectWithValue }) => {
    try {
      return (await Monolith.url(`/blocks/${shiftTemplate.id}`).delete()) as DeleteShiftTemplateResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);

type DeleteShiftTemplatesArgs = ShiftTemplateFields[];

type DeleteShiftTemplatesResponse = {
  success: boolean;
}[];

export const deleteShiftTemplates = createWiwAsyncThunk<DeleteShiftTemplatesResponse, DeleteShiftTemplatesArgs>(
  'shiftTemplate/deleteMulti',
  async (templates, { rejectWithValue }) => {
    try {
      const templateIds = templates.map(template => template.id);
      return (await Monolith.url('/blocks').query({ ids: templateIds }).delete()) as DeleteShiftTemplatesResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
