import type Position from 'data/position/model';
import type { PositionFields } from 'data/position/model';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type UpdatePositionResponse = {
  position: PositionFields;
};

export const updatePosition = createWiwAsyncThunk<UpdatePositionResponse, Position>(
  'position/update',
  async (position, { rejectWithValue }) => {
    try {
      return (await Monolith.url(`/positions/${position.id}`).put(position.toJSON())) as UpdatePositionResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
