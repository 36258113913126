import { HourStatsScheduled } from 'data/hourStats/models';
import { HourStatsScheduledState } from 'data/hourStats/states';
import { DELETE_HOUR_STATS_SCHEDULED, FETCH_HOUR_STATS_SCHEDULED, RESET_SCHEDULER_STATE } from 'store/action-types';

import { Map } from 'immutable';

export default (state = new HourStatsScheduledState(), action) => {
  switch (action.type) {
    case FETCH_HOUR_STATS_SCHEDULED.REQUEST:
      return state.merge({
        loading: true,
        receivedAt: 0,
      });
    case FETCH_HOUR_STATS_SCHEDULED.SUCCESS:
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        receivedAt: Date.now(),
        items: new Map(
          action.payload.map(hourStats => {
            return [`${hourStats.id}-${hourStats.start}`, new HourStatsScheduled(hourStats)];
          }),
        ),
      });
    case FETCH_HOUR_STATS_SCHEDULED.FAILURE:
      return state.merge({
        loading: false,
        error: action.payload,
      });
    case DELETE_HOUR_STATS_SCHEDULED:
      return state.merge({
        items: state.items.filterNot(hourStats => action.ids.includes(`${hourStats.id}-${hourStats.start}`)),
      });
    case RESET_SCHEDULER_STATE:
      return new HourStatsScheduledState();
    default:
      return state;
  }
};
