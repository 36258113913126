import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { authorized, getAuthUser } from 'shared/auth/selectors';
import { trackPage } from 'shared/util/mercury';
import { useWiwSelector } from 'store';

let referrer = null;

export default function PageViewTracker() {
  const location = useLocation();
  const isAuthed = useWiwSelector(authorized);
  const authUser = useWiwSelector(getAuthUser);
  const isLDReady = useWiwSelector(state => state.LD.isLDReady);

  const isSMA = !!authUser?.isAdmin() || !!authUser?.isManager() || !!authUser?.isSupervisor();

  useEffect(() => {
    if (!isAuthed) {
      return;
    }

    const fields = {};
    if (referrer !== null) {
      fields.referrer = referrer;
    }

    trackPage(fields);
    referrer = location.pathname;
  }, [location, isAuthed]);

  useEffect(() => {
    if (!isSMA || !isLDReady) {
      return;
    }
  }, [location, isSMA, isLDReady]);

  return null;
}
