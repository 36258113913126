import EntityBase from 'data/EntityBase';
import type EntityState from 'data/EntityState';
import { fetchFilesAvailable } from 'data/payrollCheck/actions/fetchFilesAvailable';
import Files from 'data/payrollCheck/models/filesModels';

import { createSlice } from '@reduxjs/toolkit';

class FilesAvailableState extends EntityBase<EntityState, { data: Files }>({
  data: new Files(),
}) {}

const initialState = new FilesAvailableState();

const payrollDetails = createSlice({
  name: 'files',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchFilesAvailable.fulfilled, (state, action) => {
      return state.setIn(['data'], new Files(action.payload));
    });
  },
});

export default payrollDetails.reducer;
