import type { ComponentClass } from 'react';

export type WithDefaults<Props, DefaultProps extends Partial<Props>> = Omit<Props, keyof DefaultProps> &
  Partial<DefaultProps>;

export function withDefaultProps<DefaultProps extends Partial<Props>, Props, State>(
  defaultProps: DefaultProps,
  component: ComponentClass<Props, State>,
): ComponentClass<WithDefaults<Props, DefaultProps>, State> {
  const result = class extends component {
    static defaultProps = defaultProps;
  } as unknown as ComponentClass<WithDefaults<Props, DefaultProps>, State>;
  result.displayName = `WithDefaultProps(${component.displayName || component.name || 'Component'})`;

  return result;
}
