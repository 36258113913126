import { getDate as getLuxonDate } from 'shared/util/luxonTime';
import { snakeCaseToCamelCaseObject } from 'shared/util/objectCaseUtilities';
import { getMomentDate } from 'shared/util/time';

import { type Map, Record } from 'immutable';
import type { DateTime } from 'luxon';
import type { Moment, MomentInput } from 'moment-timezone';

const commonValues = {
  saving: false,
};

// First, let's create a type that represents the structure of BaseModel
export type BaseModelType<T> = {
  date<K extends keyof (C & T)>(prop: K): Moment | null;
  mustDate<K extends keyof (C & T)>(prop: K): Moment;
  luxonDate<K extends keyof (C & T)>(prop: K): DateTime | null;
  mustLuxonDate<K extends keyof (C & T)>(prop: K): DateTime;
  asInt<K extends keyof (C & T)>(prop: K): number;
  toCamelCase(): any;
};

export type C = typeof commonValues;

function BaseModel<T>(defaultValues: T) {
  type Combined = C & T;
  return class BaseModel extends Record<Combined>({ ...commonValues, ...defaultValues }) {
    constructor(defaults?: Partial<Combined>) {
      defaults ? super(defaults) : super();
    }

    /* istanbul ignore next */
    date<K extends keyof Combined>(prop: K): Moment | null {
      const date = getMomentDate(this.get(prop) as MomentInput);
      if (date.isValid()) {
        return date;
      }

      return null;
    }

    /* istanbul ignore next */
    mustDate<K extends keyof Combined>(prop: K): Moment {
      const date = getMomentDate(this.get(prop) as MomentInput);
      if (date.isValid()) {
        return date;
      }

      throw new Error(`Invalid date: ${String(prop)}`);
    }

    /* istanbul ignore next */
    luxonDate<K extends keyof Combined>(prop: K): DateTime | null {
      const date = getLuxonDate(this.get(prop));
      if (date.isValid) {
        return date;
      }

      return null;
    }

    /* istanbul ignore next */
    mustLuxonDate<K extends keyof Combined>(prop: K): DateTime {
      const date = getLuxonDate(this.get(prop));
      if (date.isValid) {
        return date;
      }

      throw new Error(`Invalid date: ${String(prop)}`);
    }

    asInt<K extends keyof Combined>(prop: K): number {
      const tryParse = this.get(prop);

      if (!tryParse) {
        return 0;
      }

      if (!Number.isNaN(Number(String(tryParse)))) {
        return Number(String(tryParse));
      }

      return 0;
    }

    // Function to be used when sorting immutable maps
    static stringSortBy = (key: string) => (a: Map<string, any>, b: Map<string, any>) =>
      a.get(key, '').toLowerCase().localeCompare(b.get(key, '').toLowerCase());

    toCamelCase() {
      return snakeCaseToCamelCaseObject(this.toJS());
    }
  };
}

export default BaseModel;
