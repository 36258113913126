import BaseModel from 'data/BaseModel';

export interface HourStatsScheduledFields {
  start: any | null; // TODO(types)
  end: any | null; // TODO(types)
  hourlyRate: any | null; // TODO(types)
  userId: any | null; // TODO(types)
  id: any | null; // TODO(types)
  positionId: number;
  locationId: number;
  siteId: number;
  regular: number;
  dailyOt: number;
  weeklyOt: number;
  dailyDoubleOt: number;
  breakTime: number;
  otRate: any | null; // TODO(types)
  doubleOtRate: any | null; // TODO(types)
  instances: number;
}

class HourStatsScheduled extends BaseModel<HourStatsScheduledFields>({
  start: null,
  end: null,
  hourlyRate: null,
  userId: null,
  id: null,
  positionId: 0,
  locationId: 0,
  siteId: 0,
  regular: 0,
  dailyOt: 0,
  weeklyOt: 0,
  dailyDoubleOt: 0,
  breakTime: 0,
  otRate: null,
  doubleOtRate: null,
  instances: 1,
}) {
  duration() {
    return this.regular + this.dailyOt + this.dailyDoubleOt - this.breakTime;
  }

  getCost() {
    return (
      this.regular * this.hourlyRate +
      this.dailyOt * this.otRate +
      this.weeklyOt * this.otRate +
      this.dailyDoubleOt * this.doubleOtRate
    );
  }

  getTotalRegularHours() {
    return this.regular * this.instances;
  }
}

export default HourStatsScheduled;
