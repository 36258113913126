import { useMemo, useState } from 'react';

import type User from 'data/user/model';
import FontIcon from 'shared/ui/FontIcon';

import 'workchat/styles/ParticipantList.scss';

interface ParticipantListProps {
  users: User[] | undefined;
  user: User;
}

const DEFAULT_NUM_NAMES = 2;

export default function ParticipantsListV2({ user, users }: ParticipantListProps) {
  const [expanded, setExpanded] = useState(false);

  const names = useMemo(() => {
    return (
      users
        ?.filter((participantUser: User) => participantUser.id !== user.id)
        .map(user => user.fullName || '[Not Found]')
        .sort() || []
    );
  }, [users]);

  if (!users || !user) {
    return null;
  }

  const truncated = names.length > DEFAULT_NUM_NAMES;
  const excessUsers = truncated ? names.length - DEFAULT_NUM_NAMES : 0;
  const listState = expanded ? 'open' : truncated ? 'closed' : '';
  const displayNames = expanded ? names.join(', ') : names.slice(0, 2).join(', ');

  const renderChevron = () => {
    if (expanded) {
      return (
        <div className="right row">
          <FontIcon className="mt-1" icon="chevron-up" />
        </div>
      );
    }

    if (truncated) {
      return (
        <div className="right row">
          <span>{excessUsers} more</span>
          <FontIcon className="mt-1" icon="chevron-down" />
        </div>
      );
    }
  };

  return (
    <div className={`participant-list ${listState}`} onClick={() => truncated && setExpanded(!expanded)}>
      <label>To:</label>
      {renderChevron()}
      <div className="list">{displayNames}</div>
    </div>
  );
}
