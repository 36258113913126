import { persistOnboardingImport } from 'data/account/actions/persistOnboardingImport';
import { bulkDeleteUsers } from 'data/user/actions/bulkDeleteUsers';
import { bulkUpdateUsers } from 'data/user/actions/bulkUpdateUsers';
import deleteAvatar from 'data/user/actions/deleteAvatar';
import deleteUser from 'data/user/actions/deleteUser';
import { fetchUsers, fetchUsersPaginated } from 'data/user/actions/fetchAllUsers';
import { mergeUser } from 'data/user/actions/mergeUser';
import { replaceUsers } from 'data/user/actions/replaceUsers';
import { saveUser, saveUserProfile } from 'data/user/actions/saveUser';
import updateAlertPreferences from 'data/user/actions/updateAlertPreferences';
import uploadAvatar from 'data/user/actions/uploadAvatar';
import User, { type UserFields } from 'data/user/model';
import UserState from 'data/user/state';
import forwardActionValue from 'data/util/forwardActionValue';
import generateThunkReducers from 'data/util/generateThunkReducers';
import { toEntityArray } from 'shared/util/toEntityMap';

import { createSlice } from '@reduxjs/toolkit';

const initialState = new UserState();

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    receiveUsers: (state, action) =>
      state.mergeDeep({
        items: state.items.add(toEntityArray(action.payload, User)),
      }),
    updateUser: (state, action) =>
      state.mergeDeep({
        items: state.items.add(new User(action.payload)),
      }),
    removeUsers: (state, action) => {
      return state.mergeDeep({
        items: state.items.delete(action.payload),
      });
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchUsers.fulfilled, (state, action) => {
        return userSlice.caseReducers.receiveUsers(state, forwardActionValue(action, 'users'));
      })
      .addCase(fetchUsersPaginated.fulfilled, (state, action) => {
        return userSlice.caseReducers.receiveUsers(state, action);
      })
      .addCase(replaceUsers.fulfilled, (state, action) => {
        return state.mergeDeep({
          items: state.items.clear().add(toEntityArray(action.payload.users, User)),
        });
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        return state.mergeDeep({
          items: state.items.delete(action.meta.arg.user.id),
        });
      })
      .addCase(mergeUser.fulfilled, (state, action) => {
        return state.mergeDeep({
          items: state.items.delete(action.meta.arg.pending.id).add(new User(action.payload.user)),
        });
      })
      .addCase(saveUser.fulfilled, (state, action) => {
        return userSlice.caseReducers.receiveUsers(state, forwardActionValue(action, 'user'));
      })
      .addCase(bulkUpdateUsers.fulfilled, (state, action) => {
        const validUsers = action.payload.validRows.map(row => row.user);
        return userSlice.caseReducers.receiveUsers(state, { ...action, payload: validUsers });
      })
      .addCase(bulkDeleteUsers.fulfilled, (state, action) => {
        const userIds = action.payload.map(res => res.id);
        return userSlice.caseReducers.removeUsers(state, { ...action, payload: userIds });
      })
      .addCase(saveUserProfile.fulfilled, (state, action) => {
        return userSlice.caseReducers.receiveUsers(state, forwardActionValue(action, 'user'));
      })
      .addCase(updateAlertPreferences.fulfilled, (state, action) => {
        const { userId, data } = action.payload;
        return state.mergeDeep({
          items: state.items.add(state.items.get(userId, new User()).set('alert_settings', data)),
        });
      })
      .addCase(uploadAvatar.fulfilled, (state, action) => {
        const user = action.meta.arg.user;
        const updateAvatar = (): User => {
          const update: Partial<UserFields['avatar']> = {};
          const { payload } = action;

          if ('cacheUrl' in payload) {
            update.cacheUrl = payload.cacheUrl;
            update.url = payload.url;
          } else {
            update.cacheUrl = undefined;
            update.url = user.avatar.url.replace(/(\?[^?]+)?$/, `?${Date.now()}`);
          }
          return user.update('avatar', avatar => ({ ...avatar, ...update }));
        };

        return userSlice.caseReducers.updateUser(state, {
          type: action.type,
          payload: updateAvatar(),
        });
      })
      .addCase(deleteAvatar.fulfilled, (state, action) => {
        return userSlice.caseReducers.updateUser(state, {
          type: action.type,
          payload: action.meta.arg.user.update('avatar', avatar => {
            avatar.cacheUrl = undefined;
            avatar.url = avatar.url.replace(/(\?[^?]+)?$/, `?${Date.now()}`);
            return avatar;
          }),
        });
      })
      .addCase(persistOnboardingImport.fulfilled, (state, action) => {
        return userSlice.caseReducers.receiveUsers(state, forwardActionValue(action, 'users'));
      });

    generateThunkReducers(builder, 'user');
  },
});

export const { receiveUsers, updateUser } = userSlice.actions;
export default userSlice.reducer;
