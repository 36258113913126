import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

export default createWiwAsyncThunk<{ userId: number; data: any }, any>(
  'user/updateAlerts',
  async (data, { rejectWithValue }) => {
    try {
      return (await Monolith.url('/users/alerts').post(data)) as { userId: number; data: any };
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
