import Worktags from 'shared/api/worktags';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type CreateTagResponse = {};

export const createTag = createWiwAsyncThunk<CreateTagResponse, any>(
  'tags/create',
  async (tag, { rejectWithValue }) => {
    try {
      return (await Worktags.url('/tags').post(tag.toJSON())) as CreateTagResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
