import type { AccountFields } from 'data/account/model';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

export type FetchAccountQueryArgs =
  | {
      [key: string]: any;
    }
  | undefined;

export type FetchAccountResponse = {
  account: AccountFields;
  accounts?: AccountFields[];
};

export const fetchAccounts = createWiwAsyncThunk<FetchAccountResponse, FetchAccountQueryArgs>(
  'account/fetchAccounts',
  async (query = {}, { rejectWithValue }) => {
    try {
      return (await Monolith.query(query).get('/account')) as FetchAccountResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
