import { type ForwardedRef, type ImgHTMLAttributes, forwardRef } from 'react';

import type User from 'data/user/model';
import type { AvatarSize } from 'data/user/model';

import 'shared/ui/assets/styles/Avatar.scss';

type ComponentProps = {
  size?: AvatarSize;
  user?: User;
};

type Props = ImgHTMLAttributes<Element> & ComponentProps;

/**
 * A lightweight wrapper for Avatars when you want to just reach for an <img /> element, rather than the <Avatar />
 * component which has extra div wrapping. This component will set an alt text, and default to the "small" size.
 */
const avatarImg = forwardRef(function AvatarImg(props: Props, ref: ForwardedRef<HTMLImageElement>) {
  const { size, user } = props;

  const imgProps = {
    ...props,
    size: undefined,
    user: undefined,
  };

  if (!user) {
    return null;
  }

  return <img {...imgProps} ref={ref} alt={props.alt ?? `${user.fullName}'s Avatar`} src={user.getAvatar(size)} />;
});

avatarImg.defaultProps = {
  size: 'small',
};
export default avatarImg;
