import type EntityState from 'data/EntityState';
import { createAvailability } from 'data/availability/actions/createAvailability';
import { deleteAvailability } from 'data/availability/actions/deleteAvailability';
import { fetchAvailability, fetchReplaceAvailability } from 'data/availability/actions/fetchAvailability';
import { resetAvailability } from 'data/availability/actions/resetAvailability';
import { saveAvailabilityExclusion } from 'data/availability/actions/saveAvailabilityExclusion';
import { updateAvailability } from 'data/availability/actions/updateAvailability';
import Availability from 'data/availability/model';
import AvailabilityState from 'data/availability/state';
import generateThunkReducers from 'data/util/generateThunkReducers';
import { deleteEntityItems } from 'data/util/sliceHelpers';
import { CLOSE_AVAILABILITY_DIALOG, OPEN_AVAILABILITY_DIALOG, RESET_SCHEDULER_STATE } from 'store/action-types';

import { createSlice } from '@reduxjs/toolkit';
import { Map } from 'immutable';

const initialState = new AvailabilityState() as unknown as EntityState<Availability>;

export const availabilitySlice = createSlice({
  name: 'availability',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchAvailability.fulfilled, (state, action) => {
        return state.mergeDeep({
          items: state.items.merge(
            action.payload.availabilityevents.map(availability => Availability.fromData(availability)),
          ),
        });
      })
      .addCase(fetchReplaceAvailability.fulfilled, (state, action) => {
        return state.mergeDeep({
          items: Map(action.payload.availabilityevents.map(availability => Availability.fromData(availability))),
        });
      })
      .addCase(deleteAvailability.fulfilled, (state, action) => {
        return deleteEntityItems(state, action);
      })
      .addCase(updateAvailability.fulfilled, (state, action) => {
        return state.mergeDeep({
          items: state.items
            .delete(null)
            .merge(action.payload.availabilityevents.map(availability => Availability.fromData(availability))),
        });
      })
      .addCase(saveAvailabilityExclusion.fulfilled, (state, action) => {
        return state.mergeDeep({
          items: state.items
            .delete(null)
            .merge(action.payload.availabilityevents.map(availability => Availability.fromData(availability))),
        });
      })
      .addCase(createAvailability.fulfilled, (state, action) => {
        return state.mergeDeep({
          items: state.items.delete(null).merge([Availability.fromData(action.payload.availabilityevent)]),
        });
      })
      .addCase(resetAvailability.fulfilled, () => {
        return initialState;
      })
      .addCase(CLOSE_AVAILABILITY_DIALOG, (state, action) => {
        return state.merge({
          // @ts-ignore
          dialogs: state.dialogs.delete(action.payload.name),
        });
      })
      .addCase(OPEN_AVAILABILITY_DIALOG, (state, action) => {
        return state.merge({
          // @ts-ignore
          dialogs: state.dialogs.merge([[action.payload.name, action.payload]]),
        });
      })
      .addCase(RESET_SCHEDULER_STATE, () => {
        return initialState;
      });
    generateThunkReducers(builder, 'availability');
  },
});

export default availabilitySlice.reducer;
