import BaseModel from 'data/BaseModel';

export interface PunchFields {
  account_id: number;
  accuracy: number;
  alert_type: PunchAlertType;
  altitude: number;
  created_at: string | null;
  id: number;
  ip_address: string | null;
  is_alerted: number;
  latitude: number;
  location_id: number;
  longitude: number;
  method: number;
  notes: string | null;
  site_id: number;
  time_id: number;
  type: PunchType;
  updated_at: string | null;
  user_id: number;
}

export enum PunchType {
  General = 0,
  In = 1,
  Out = 2,
}

export enum PunchAlertType {
  General = 0,
  LateClock = 1,
  EarlyClock = 2,
  WrongLocation = 4,
  ManuallyEntered = 8,
  NoScheduledShift = 16,
  Forgot = 32,
}

export enum PunchMethod {
  General = 0,
  iOS = 1,
  Android = 2,
  Web = 4,
  Terminal = 8,
  Computer = 32,
}

class Punch extends BaseModel<PunchFields>({
  account_id: 0,
  accuracy: 0,
  alert_type: PunchAlertType.General,
  altitude: 0,
  created_at: null,
  id: 0,
  ip_address: null,
  is_alerted: 0,
  latitude: 0,
  location_id: 0,
  longitude: 0,
  method: -16, // arbitrary negative number that will default methodName to "unknown" (and also not "terminal"), see unit test
  notes: null,
  site_id: 0,
  time_id: 0,
  type: PunchType.General,
  updated_at: null,
  user_id: 0,
}) {
  static Type = {
    GENERAL: PunchType.General,
    IN: PunchType.In,
    OUT: PunchType.Out,
  } as const;

  static AlertType = {
    GENERAL: PunchAlertType.General,
    LATE_CLOCK: PunchAlertType.LateClock,
    EARLY_CLOCK: PunchAlertType.EarlyClock,
    WRONG_LOCATION: PunchAlertType.WrongLocation,
    MANUALLY_ENTERED: PunchAlertType.ManuallyEntered,
    NO_SCHEDULED_SHIFT: PunchAlertType.NoScheduledShift,
    FORGOT: PunchAlertType.Forgot,
  } as const;

  static Method = {
    GENERAL: PunchMethod.General,
    IOS: PunchMethod.iOS,
    ANDROID: PunchMethod.Android,
    WEB: PunchMethod.Web,
    TERMINAL: PunchMethod.Terminal,
    COMPUTER: PunchMethod.Computer,
  } as const;

  get methodName() {
    switch (this.method) {
      case Punch.Method.GENERAL:
        return 'third party app';
      case Punch.Method.IOS:
      case Punch.Method.ANDROID:
        return 'mobile';
      case Punch.Method.COMPUTER:
      case Punch.Method.WEB:
        return 'computer';
      default:
        return this.method & Punch.Method.TERMINAL ? 'terminal' : 'unknown';
    }
  }
}

export default Punch;
