import { createSlice } from '@reduxjs/toolkit';
import EntityState from 'data/EntityState';
import { deleteShiftBreak, fetchShiftBreaks } from 'data/shiftBreak/actions';
import { createShiftBreak } from 'data/shiftBreak/actions/createShiftBreak';
import { updateShiftBreak } from 'data/shiftBreak/actions/updateShiftBreak';
import { ShiftBreak } from 'data/shiftBreak/models/shiftBreak';
import generateThunkReducers from 'data/util/generateThunkReducers';
import { optimisticallyDeleteEntity, optimisticallyUpdateEntity } from 'data/util/optimisticUpdate';
import { mergeEntityItems } from 'data/util/sliceHelpers';

const initialState = new EntityState<ShiftBreak>();

export const ShiftBreakSlice = createSlice({
  name: 'shiftBreak',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchShiftBreaks.fulfilled, (state, action) => {
      return mergeEntityItems(state, action.payload.data, ShiftBreak);
    });

    optimisticallyDeleteEntity(builder, deleteShiftBreak);
    optimisticallyUpdateEntity(builder, createShiftBreak, ShiftBreak, {
      payloadPath: 'data',
      parameterPath: 'shiftBreak',
    });
    optimisticallyUpdateEntity(builder, updateShiftBreak, ShiftBreak, {
      payloadPath: 'data',
      parameterPath: 'shiftBreak',
    });
    generateThunkReducers(builder, 'shiftBreak');
  },
});

export default ShiftBreakSlice.reducer;
