import type { BalancesResponse } from 'data/timeOffAccrual/types/common';
import type { DataResponse, QueryString } from 'data/types';
import Scheduling from 'shared/api/scheduling';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type UpdateUserBalance = {
  requestTypeId: string;
  balance: number;
};
interface UpdateBalancesForUserArgs {
  userId: string;
  balances: UpdateUserBalance[];
  params: QueryString;
}

export const updateBalancesForUser = createWiwAsyncThunk<DataResponse<BalancesResponse>, UpdateBalancesForUserArgs>(
  'timeOff/balances/updateBalancesForUser',
  async ({ userId, balances, params = {} }, { rejectWithValue }) => {
    try {
      return await Scheduling.query(params).url(`/timeoff/balances/${userId}`).put(balances);
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
