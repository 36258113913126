export const environments = {
  PRODUCTION: 'production',
  STAGING: 'staging',
  DEVELOPMENT: 'development',
  ACCEPTANCE: 'acceptance',
  TESTING: 'test',
};

export default class Environment {
  static isProduction() {
    return Environment.isEnv(environments.PRODUCTION);
  }

  static isStaging() {
    return Environment.isEnv(environments.STAGING);
  }

  static isAcceptance() {
    return Environment.isEnv(environments.ACCEPTANCE);
  }

  static isDevelopment() {
    return Environment.isEnv(environments.DEVELOPMENT);
  }

  static isTesting() {
    return Environment.isEnv(environments.TESTING);
  }

  static isEnv(env) {
    return CONFIG.ENV === env;
  }

  static get() {
    return CONFIG.ENV;
  }
}
