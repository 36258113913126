import type User from 'data/user/model';
import { getStateUsers } from 'data/user/selectors/getStateUsers';
import { getAuthAccount } from 'shared/auth/selectors';

import { createSelector } from '@reduxjs/toolkit';

export const getAccountHolder = createSelector([getStateUsers, getAuthAccount], (users, account) =>
  users
    .filter((user: User) => {
      return user.account_id === account.id && user.is_active && user.isAdmin();
    })
    .first(),
);
