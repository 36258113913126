import type { HomeAddress } from 'data/payrollCheck/models/checkEmployee';
import Payroll from 'shared/api/payroll';
import { simplifyGeocodeResult } from 'shared/util/googleMaps/locations';

import type { GeocodeResult } from 'use-places-autocomplete';

interface ResponseError {
  type: string;
  message: string;
  suggested_alternative: HomeAddress;
}

export interface SubmitResponse {
  error: ResponseError;
}

export const validateCheckAddress = async (address: HomeAddress) => {
  try {
    return await Payroll.url('/validate-address').post(JSON.stringify(address));
  } catch (error: any) {
    const errorBody: SubmitResponse = error.json;
    return errorBody;
  }
};

export default async function isCheckAddress(value: GeocodeResult): Promise<string> {
  if (!value) {
    return '';
  }
  const simple = simplifyGeocodeResult(value);

  const address = {
    line1: `${simple.streetNumber} ${simple.streetName}`,
    city: simple.city,
    state: simple.stateShort,
    country: simple.country,
    postal_code: simple.postalCode,
  };

  const response = await validateCheckAddress(address);
  if (response?.error) {
    return response.error.message;
  }

  return '';
}
