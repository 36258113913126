import EntityState from 'data/EntityState';
import { fetchPostTaxDeductions } from 'data/payrollCheck/actions/fetchPostTaxDeductions';
import PostTaxDeductionRecord from 'data/payrollCheck/models/postTaxDeductions';
import generateThunkReducers from 'data/util/generateThunkReducers';
import { mergeEntityItems } from 'data/util/sliceHelpers';

import { createSlice } from '@reduxjs/toolkit';

const initialState = new EntityState<PostTaxDeductionRecord>();

const payrollsSummaries = createSlice({
  name: 'payrollCheck/postTaxDeductions',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchPostTaxDeductions.fulfilled, (state, action) => {
      return mergeEntityItems(state, action.payload.tax_deductions, PostTaxDeductionRecord);
    });
    generateThunkReducers(builder, 'payrollCheck/summaries');
  },
});

export default payrollsSummaries.reducer;
