import {
  type ChangeEvent,
  Component,
  type ComponentProps,
  type FocusEvent,
  Fragment,
  type ReactNode,
  type RefObject,
  createRef,
} from 'react';

import { withDefaultProps } from 'shared/components';
import InputWrapper from 'shared/components/form/wrappers/InputWrapper';

import classnames from 'classnames';
import { type FormsyInjectedProps, withFormsy } from 'formsy-react';
import type { Value } from 'formsy-react/dist/interfaces';
import { isEmpty, isFunction } from 'lodash';
import { UncontrolledTooltip } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';

type InputType =
  | 'color'
  | 'date'
  | 'datetime'
  | 'datetime-local'
  | 'email'
  | 'hidden'
  | 'month'
  | 'number'
  | 'password'
  | 'range'
  | 'search'
  | 'tel'
  | 'text'
  | 'time'
  | 'url'
  | 'week';

type Props = FormsyInjectedProps & {
  label?: any;
  labelAddon: any;
  name: string;
  type: InputType;
  className?: string;

  /**
   * These classes get applied to the `fieldset` element that wraps the input.
   */
  classNames?: string;
  width: false | number | 'auto' | string;
  value?: string | number | any[];
  mask?: string | ((value: string) => string);
  placeholder?: string;
  inputProps?: ComponentProps<'input'> | object;
  inputRef?: RefObject<HTMLInputElement>;
  disabled: boolean;
  newStyle: boolean;
  labelSmall: false | string;
  onChange: (name: any, value: any) => void;
  onFocus: (name: any, value: any) => void;
  onBlur: (name: any, value: any) => void;
  validateOnlyOnBlur: boolean;
  validatePristine: boolean;
  tooltip: false | string;
  setFocus: boolean;
  isValid?: boolean;
  raw: boolean;

  errorMessage: any;
  afterAddon: ReactNode;
  beforeAddon: ReactNode;
  options: { [key: string]: any };
};

type State = {
  focus: boolean;
  hasBlurred: boolean;
};

export const ValidatedInput = withDefaultProps(
  {
    type: 'text',
    width: false,
    disabled: false,
    newStyle: false,
    labelAddon: false,
    labelSmall: false,
    validateOnlyOnBlur: false,
    validatePristine: false,
    onChange: () => {},
    onFocus: () => {},
    onBlur: () => {},
    tooltip: false,
    setFocus: false,
    raw: false,
  },
  class ValidatedInput extends Component<Props, State> {
    _inputRef = createRef<HTMLInputElement>();
    get inputRef() {
      return this.props.inputRef || this._inputRef;
    }

    targetId = `w${uuidv4()}`;

    state = {
      focus: false,
      hasBlurred: false,
    };

    componentDidMount() {
      if (this.props.setFocus && this.inputRef.current) {
        this.inputRef.current.focus();
      }
    }
    onChange = (e: ChangeEvent<HTMLInputElement>) => {
      const value = this.applyMask(e.target.value);

      this.props.onChange(this.props.name, value);

      if (this.props.validateOnlyOnBlur) {
        return this.props.setValue(value, false);
      }

      return this.props.setValue(value);
    };

    onFocus = (e: FocusEvent<HTMLInputElement>) => {
      const value = this.applyMask(e.target.value);
      this.setState({ focus: true });
      this.props.onFocus(this.props.name, value);
    };

    onBlur = (e: FocusEvent<HTMLInputElement>) => {
      let value = this.applyMask(e.target.value);

      //trim whitespace before and after content, helps with email validation
      if ((this.props.type === 'text' || this.props.type === 'email') && !isEmpty(value) && typeof value === 'string') {
        value = value.trim();
      }

      this.setState({
        hasBlurred: true,
        focus: false,
      });
      this.props.onBlur(this.props.name, value);
      return this.props.setValue(value);
    };

    applyMask(value: Value) {
      if (this.props.type === 'number' && !isEmpty(value)) {
        // if your input is a number... you obviously ya know... want a NUMBER
        value = Number.parseInt(value, 10);

        if (Number.isNaN(value)) {
          return 0;
        }
      }

      if (isFunction(this.props.mask) && this.props.value !== value) {
        value = this.props.mask(value);
      }

      return value;
    }

    getInputId = () => {
      const { inputProps, name, label } = this.props;

      if (inputProps && typeof inputProps === 'object' && 'id' in inputProps) {
        return inputProps.id;
      }

      if (label) {
        //inputs with a label match for=id using name
        return name;
      }

      return undefined;
    };

    renderInput(invalid: boolean) {
      const inputClassname = classnames('form-control', this.props.className, {
        'is-invalid': invalid,
        newStyle: this.props.newStyle,
      });

      let value = this.props.value;
      if (value === null || typeof value === 'undefined') {
        value = '';
      }

      return (
        <input
          type={this.props.type}
          name={this.props.name}
          id={this.getInputId()}
          className={inputClassname}
          value={value}
          disabled={this.props.isFormDisabled || this.props.disabled}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onChange={this.onChange}
          ref={this.inputRef}
          placeholder={this.props.placeholder}
          data-autofocus={!!this.props.setFocus}
          {...this.props.inputProps}
        />
      );
    }

    render() {
      const invalid =
        (this.props.showError || !this.props.isValid) && (this.props.validatePristine || !this.props.isPristine);

      if (this.props.type === 'hidden' || this.props.raw) {
        return this.renderInput(invalid);
      }

      return (
        <Fragment>
          <InputWrapper focus={this.state.focus} {...this.props} id={this.targetId} inputId={this.getInputId()}>
            {this.renderInput(invalid)}
          </InputWrapper>
          {this.props.tooltip ? (
            <UncontrolledTooltip target={this.targetId} placement="bottom">
              {this.props.tooltip}
            </UncontrolledTooltip>
          ) : null}
        </Fragment>
      );
    }
  },
);
/**
 * @deprecated please migrate to hook-form from shared/form/inputs
 */
export default withFormsy(ValidatedInput);
