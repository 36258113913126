import { fetchAccountBillingSummary } from 'data/stripe/actions/fetchAccountSummary';
import { fetchStripePlans } from 'data/stripe/plans/actions/fetchStripePlans';
import StripePlan from 'data/stripe/plans/model';
import { StripePlansState } from 'data/stripe/state/StripePlansState';
import type { StripePlan as StripePlanI } from 'data/stripe/types/plans';
import generateThunkReducers from 'data/util/generateThunkReducers';
import { toEntityMap } from 'shared/util/toEntityMap';

import { type Draft, createSlice } from '@reduxjs/toolkit';

const initialState = new StripePlansState();

const parsePlanResponse = (rawResponse: StripePlanI[], state: Draft<StripePlansState>) => {
  // flatten the plans from action.payload.data.plans into a map of id => plan along with the trial plan
  const plans = rawResponse.reduce((acc, plan) => {
    if (!plan.trial_plan) {
      return acc.concat(plan);
    }
    const trialPlan = {
      ...plan.trial_plan,
      units: plan.units,
      stripe_prices: plan.stripe_prices,
      pricing_group: plan.pricing_group,
    };
    return acc.concat(plan, trialPlan);
  }, [] as StripePlanI[]);

  return state.mergeDeep({
    loaded: true,
    items: toEntityMap(plans, StripePlan),
  });
};

export const PlansSlice = createSlice({
  name: 'stripe/plans',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchStripePlans.fulfilled, (state, action) => {
        return parsePlanResponse(action.payload.data.plans, state);
      })
      .addCase(fetchAccountBillingSummary.fulfilled, (state, action) => {
        return parsePlanResponse(action.payload.plans.plans, state);
      });
    generateThunkReducers(builder, 'stripe/plans');
  },
});

export default PlansSlice.reducer;
