import classnames from 'classnames';

import 'onboarding/assets/styles/nav/ProgressMeter.scss';

type ProgressMeterProps = {
  percentComplete: number;
  dark: boolean;
};

const ProgressMeter = ({ percentComplete, dark }: ProgressMeterProps) => (
  <div className="onboarding-progress-meter">
    <span className={classnames(`percent-${percentComplete}`, { dark })}>{percentComplete}%</span>
  </div>
);

export default ProgressMeter;
