import { getAccountPlan } from 'data/account/selectors/accountPlan';
import { getCustomPlan } from 'data/account/selectors/customPlan';
import { filterPlans } from 'data/plan/selectors/availablePlans';
import { getPlans } from 'data/plan/selectors/plans';

import { createSelector } from '@reduxjs/toolkit';
import { every, isArray } from 'lodash';

export const withFeature = (plans, feature) => {
  const features = isArray(feature) ? feature : [feature];
  return plans.filter(plan => plan && every(features, feature => plan.hasFeature(feature)));
};

export const nonLegacy = plans => {
  return plans.filter(plan => plan && !plan.isLegacy());
};

export const cheapest = plans => {
  return plans
    .filter(plan => plan?.isPaid())
    .sortBy(plan => plan?.price)
    .first();
};

export const recommendedPlan = (plans, customPlan, accountPlan) => {
  return cheapest(nonLegacy(filterPlans(plans, customPlan, accountPlan)));
};

export const recommendedPlanWithFeature = (plans, feature, customPlan, accountPlan) => {
  return cheapest(nonLegacy(withFeature(filterPlans(plans, customPlan, accountPlan), feature)));
};

export const getFeature = (state, props) => {
  return props?.feature || state?.feature;
};

export const getRecommendedPlan = createSelector([getPlans, getCustomPlan, getAccountPlan], recommendedPlan);

export const getRecommendedPlanWithFeature = createSelector(
  [getPlans, getFeature, getCustomPlan, getAccountPlan],
  recommendedPlanWithFeature,
);
