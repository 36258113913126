import EntityState from 'data/EntityState';
import { LIST_TIMEZONES } from 'store/action-types';
import Timezone from './model';

import { Map } from 'immutable';

export default function (state = new EntityState(), action) {
  switch (action.type) {
    case LIST_TIMEZONES.FAILURE:
      return state.merge({
        loaded: false,
        loading: false,
        error: action.payload,
      });
    case LIST_TIMEZONES.REQUEST:
      return state.merge({
        loaded: false,
        loading: true,
        error: null,
      });
    case LIST_TIMEZONES.SUCCESS:
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        items: new Map(action.payload.map(timezone => [timezone.id, new Timezone(timezone)])),
      });
    default:
      return state;
  }
}
