import type Account from 'data/account/model';
import type User from 'data/user/model';
import { setEmployeeOnboardingItemCompleted } from 'onboarding/actions/setEmployeeOnboardingItemCompleted';
import { EmployeeOnboardingItems, EmployeeOnboardingStatus } from 'onboarding/constants';
import GuideHighlight from 'onboarding/modules/guides/components/GuideHighlight';
import { setEmployeeOnboardingStatus } from 'onboarding/reducer';
import { getEmployeeOnboardingStatus } from 'onboarding/selectors';
import { SCHEDULER_ONINTERACTION } from 'scheduler/util/constants';
import { MenuItem } from 'shared/components/nav/MenuItem';
import MenuItems from 'shared/components/nav/MenuItems';
import Features from 'shared/features';
import Mercury from 'shared/util/mercury';
import { posthogCapture } from 'shared/vendor/posthog';
import { useWiwDispatch, useWiwSelector } from 'store';

type RequestsMenuItemProps = {
  // biome-ignore lint/complexity/noBannedTypes: <explanation>
  openCreateRequestDialog: () => {};
  requestsEnabled: boolean;
  schedulerEnabled: boolean;
  swapsEnabled: boolean;
  authUser: User;
  authAccount: Account;
  featureOpenShiftApproval: string;
};

type OpenDialogType = {
  persist?: () => void;
  preventDefault: () => void;
};

const RequestsMenuItem = ({
  authUser,
  authAccount,
  featureOpenShiftApproval,
  openCreateRequestDialog,
  requestsEnabled,
  schedulerEnabled,
  swapsEnabled,
}: RequestsMenuItemProps) => {
  const dispatch = useWiwDispatch();
  const employeeOnboardingStatus = useWiwSelector(getEmployeeOnboardingStatus);
  const isTimeOffGuidedStep = employeeOnboardingStatus === EmployeeOnboardingStatus.TimeOff;

  const dismissGuide = () => {
    dispatch(setEmployeeOnboardingStatus(EmployeeOnboardingStatus.Idle));
  };

  const completeGuide = () => {
    dispatch(setEmployeeOnboardingStatus(EmployeeOnboardingStatus.Idle));
    dispatch(setEmployeeOnboardingItemCompleted(EmployeeOnboardingItems.TimeOffCompleted));
    posthogCapture('employee_onboarding.time_off_requests.click.request_timeoff');
  };

  const handleClick = (event: OpenDialogType) => {
    if (event?.persist) {
      event.persist();
    }

    event.preventDefault();
    openCreateRequestDialog();
  };

  const trackClick = () => {
    Mercury.track(
      'scheduler::onInteraction',
      {
        ...SCHEDULER_ONINTERACTION,
        target: 'openshiftRequestsNav',
        category: 'navigation',
        userRole: authUser.mercuryRoleName(),
      },
      {},
    );
  };

  const renderAbsenceMenuItem = () => {
    if (
      !authAccount.hasFeature(Features.ABSENCE_TRACKING, true) ||
      !authAccount.getSettings('schedule.track_absences', false)
    ) {
      return null;
    }

    return <MenuItem to="/requests/absences" icon="requests-absences" label="Absences" />;
  };

  const withDialog = () => {
    return (
      <GuideHighlight
        isActive={isTimeOffGuidedStep}
        highlightId="timeoff-guide-highlight"
        onHighlightClick={completeGuide}
        showPopover
        popoverText="Click “Request Time-off” to create a time off request."
        popoverPosition="left-start"
        popoverOffset={{ mainAxis: 10, crossAxis: 10 }}
        hideOverlay
        dismissOnOutsideClick
        onDismiss={dismissGuide}
      >
        <MenuItem to="#" icon="add" label="Request Time-Off" onClick={(event: Event) => handleClick(event)} />
      </GuideHighlight>
    );
  };

  return (
    <MenuItem
      className="menu-icon"
      icon="requests"
      label="Requests"
      hideLabel="desktop"
      dropdown
      forceActive={isTimeOffGuidedStep}
    >
      <MenuItems dropdownMenu>
        {(requestsEnabled || authUser.canSupervise()) && withDialog()}
        <MenuItem to="/requests/time-off" icon="requests-timeoff" label="Time Off Requests" />
        {schedulerEnabled && swapsEnabled && (
          <MenuItem to="/requests/shift" icon="requests-shifts" label="Shift Requests" />
        )}
        {authAccount.hasFeature(featureOpenShiftApproval, true) && (
          <MenuItem
            icon="requests-openshifts"
            label="OpenShift Requests"
            onClick={trackClick}
            to="/requests/openshift"
          />
        )}
        {renderAbsenceMenuItem()}
      </MenuItems>
    </MenuItem>
  );
};

export default RequestsMenuItem;
