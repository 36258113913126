import 'shared/vendor/GoogleMaps';

import PropTypes from 'prop-types';
import { Component, Suspense } from 'react';
import { DragDropContext } from 'react-dnd';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

import { getAccountPlan } from 'data/account/selectors';
import Dialogs from 'dialogs/containers/DialogContainer';
import Notices from 'notices/components/list';
import { logout } from 'shared/auth/actions';
import { getTokenInfo } from 'shared/auth/auth';
import { getAuthData } from 'shared/auth/selectors';
import NotAllowed from 'shared/components/NotAllowed';
import DevPanel from 'shared/dev/DevPanel';
import { withTimezoneSwitcherCleanup } from 'shared/hooks/useTimezoneSwitcherCleanup';
import BrowserGate from 'shared/ui/BrowserGate';
import Overlay from 'shared/ui/loaders/Overlay';
import DndBackend from 'shared/util/DndBackend';

export class FullPageLayout extends Component {
  static propTypes = {
    auth: PropTypes.object,
    accountPlan: PropTypes.object,
    component: PropTypes.any,
    location: PropTypes.object.isRequired,
    hasRole: PropTypes.array,
    hasFlag: PropTypes.string,
    LDFlags: PropTypes.object,
    ldRedirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    allowLimited: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    ldRedirect: false,
    allowLimited: false,
  };

  componentDidMount() {
    const { allowLimited } = this.props;

    if (getTokenInfo().isLimited() && !allowLimited) {
      logout();
    }
  }

  componentDidUpdate() {
    if (this.props.auth.loggingOut) {
      return logout();
    }
  }

  userHasRole(role) {
    if (role && !this.props.auth.user.can(role)) {
      return false;
    }
    return true;
  }

  userHasFlag(flag) {
    if (flag && this.props.LDFlags.isLDReady && this.props.LDFlags[flag] !== true) {
      return false;
    }
    return true;
  }

  loaded() {
    const { loaded, account } = this.props.auth;
    const { accountPlan } = this.props;
    return loaded && accountPlan && account;
  }

  renderBody() {
    const { component: Component, hasRole, hasFlag, ldRedirect, ...rest } = this.props;

    if (!this.userHasRole(hasRole) || !this.userHasFlag(hasFlag)) {
      if (ldRedirect) {
        window.location.assign(ldRedirect);
        return false;
      }
      return <NotAllowed />;
    }

    return <Route {...rest} render={props => <Component {...props} />} />;
  }

  render() {
    if (!this.loaded()) {
      return <Overlay />;
    }

    return (
      <BrowserGate>
        <div className="container-fluid notice-container px-0">
          <div className="row no-gutters">
            <div className="col">
              <Notices maxVisible={5} followWindow />
            </div>
          </div>
        </div>
        <Suspense fallback={<Overlay />}>{this.renderBody()}</Suspense>
        <Dialogs />
        <DevPanel />
      </BrowserGate>
    );
  }
}

export default connect(
  (state, props) => ({
    auth: getAuthData(state),
    accountPlan: getAccountPlan(state, props),
    LDFlags: state.LD,
  }),
  {},
)(DragDropContext(DndBackend)(withTimezoneSwitcherCleanup(FullPageLayout)));
