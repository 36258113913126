import 'shared/util/dayjsSetup';
import 'shared/util/moment-relative';
import 'shared/vendor';
import './wdyr';
import { PostHogProvider } from 'posthog-js/react';

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import deployInfo from 'shared/dev/deployInfo';
import Environment from 'shared/util/environment';
import { initSentry } from 'shared/vendor/Sentry';
import { posthogClient } from 'shared/vendor/posthog';
import { globalizeStore } from 'store';
import { createStore } from 'store/configureStore';
import Root from './root';

import Mercury from 'mercury-js';

import 'styles/app.scss';
import 'styles/assets/img/favicon.ico';

window.deploy = deployInfo;
window.mercury = new Mercury({ environment: Environment.get() });
const store = createStore();

globalizeStore(store);

initSentry();

const container = document.getElementById('wheniwork-app');
const root = createRoot(container);
const content = (
  <PostHogProvider client={posthogClient}>
    <Provider store={store}>
      <Root />
    </Provider>
  </PostHogProvider>
);
root.render(content);
