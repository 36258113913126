import BaseModel from 'data/BaseModel';
import HourTypes from 'data/HourTypes';

export interface PayrollHoursFields {
  id: number | null;
  payroll_id: number | null;
  type: number;
  type_label: string | null;
  user_id: number;
  position_id: number;
  hours: number | null;
  rate: number | null;
  total: number | null;
  cash_tips: number | null;
}

class PayrollHours extends BaseModel<PayrollHoursFields>({
  id: null,
  payroll_id: null,
  type: 0,
  type_label: null,
  user_id: 0,
  position_id: 0,
  hours: null,
  rate: null,
  total: null,
  cash_tips: null,
}) {
  static Types = {
    REGULAR: HourTypes.REGULAR.id,
    OVERTIME: HourTypes.OVERTIME.id,
    DOUBLE_OVERTIME: HourTypes.DOUBLE_OVERTIME.id,
    PTO: HourTypes.PTO.id,
    HOLIDAY: HourTypes.HOLIDAY.id,
    SICK: HourTypes.SICK.id,
    TIMEOFF: HourTypes.TIMEOFF.id,
  } as const;
}

export default PayrollHours;
