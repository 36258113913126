import type { ShiftFields } from 'data/shift/model';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type UnassignShiftArgs = ShiftFields['id'];
type UnassignShiftResponse = {};

export const unassignShifts = createWiwAsyncThunk<UnassignShiftResponse, UnassignShiftArgs>(
  'shift/unassign',
  async (shiftIds, { rejectWithValue }) => {
    try {
      return (await Monolith.url('/shifts/unassign').post({ shift_ids: shiftIds })) as UnassignShiftResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
