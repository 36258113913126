import type Account from 'data/account/model';
import type Plan from 'data/plan/model';
import type User from 'data/user/model';
import AttendanceProgressMenuItem from 'onboarding/containers/nav/AttendanceProgressMenuItem';
import { AccountNotifications } from 'shared/components/nav/BulletinBoard/AccountNotifications';

type BulletinBoardProps = {
  authUser: User;
  authAccount: Account;
  accountPlan: Plan;
  isDesktop: boolean;
};

const BulletinBoard = ({ authUser, authAccount, accountPlan, isDesktop }: BulletinBoardProps) => {
  return (
    <li className="navbar-bulletin-board">
      {authUser.canManage() && <AttendanceProgressMenuItem />}
      {isDesktop && <AccountNotifications authUser={authUser} authAccount={authAccount} accountPlan={accountPlan} />}
    </li>
  );
};

export default BulletinBoard;
