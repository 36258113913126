import {
  Fragment,
  type KeyboardEvent,
  type PropsWithChildren,
  type ReactChild,
  type ReactNode,
  type RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import GoogleStore from 'onboarding/assets/images/google-play-store.svg';
import FontIcon from 'shared/ui/FontIcon';
import { SmartLink } from 'shared/util/redirect';

import classnames from 'classnames';
import FocusTrap from 'focus-trap-react';
/* biome-ignore lint/nursery/noRestrictedImports: Import */
import { UncontrolledTooltip } from 'reactstrap';
import { useMediaQuery } from 'usehooks-ts';

type MenuItemProps = {
  className?: string;
  id?: string;
  exact?: boolean;
  label?: string | ReactNode;
  decorate?: string;
  dropdown?: boolean;
  icon?: string;
  show?: boolean | string;
  disabled?: boolean;
  hideLabel?: 'no' | 'sm' | 'md' | 'lg' | 'tablet' | 'desktop';
  to?: string | boolean;
  alert?: boolean;
  hintTooltipLabel?: string | null;
  isMainNav?: boolean;
  onClick?: (e: Event | MouseEvent) => void;
  tooltipText?: string;
  tooltipClassName?: string;
  target?: string | HTMLLIElement | null | RefObject<HTMLElement>;
  showPlayStore?: boolean;
  forceActive?: boolean;
  postIcon?: string;
  postIconColor?: string;
  'data-testid'?: string;
};

type MenuLinkProps = {
  children: ReactNode | undefined | Element;
  to?: string | boolean;
  onClick?: (e: Event | MouseEvent) => void;
  exact?: boolean;
  disabled?: boolean;
};

type ListItemFocusTrapProps = {
  children: ReactChild | HTMLLIElement | null;
};

export const MenuItem = ({
  id,
  show,
  disabled,
  hideLabel,
  className,
  label,
  decorate,
  icon,
  children,
  alert,
  hintTooltipLabel,
  isMainNav,
  tooltipText,
  tooltipClassName,
  target,
  dropdown,
  exact,
  to,
  onClick,
  showPlayStore,
  postIcon,
  postIconColor,
  'data-testid': dataTestId,
  forceActive = false,
}: PropsWithChildren<MenuItemProps>) => {
  const [elevioOpen, updateElevioOpen] = useState(false);
  const [tooltipsEnabled, updateTooltipsEnabled] = useState(false);
  const [showDropdown, updateShowDropdown] = useState(forceActive || false);
  const itemRef = useRef<HTMLLIElement>(null);
  const showLabel = label && ((elevioOpen && !children && !isMainNav) || !elevioOpen);
  const isDesktop = useMediaQuery('(min-width: 992px)');

  const handleMenuToggleKeyPress = (event: KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === ' ' || event.key === 'Enter') {
      updateShowDropdown(cur => !cur);
    }
  };

  const handleMenuToggleClick = () => updateShowDropdown(cur => !cur);

  const enableTooltips = useCallback(() => {
    if (!tooltipsEnabled && tooltipText && itemRef.current) {
      updateTooltipsEnabled(true);
    }
  }, [tooltipsEnabled, tooltipText]);

  useEffect(() => {
    updateShowDropdown(forceActive);
  }, [forceActive]);

  useEffect(() => {
    window._elev.on('widget:opened', () => updateElevioOpen(true));
    window._elev.on('widget:closed', () => updateElevioOpen(false));
    enableTooltips();
  }, [enableTooltips]);

  if (show === false) {
    return null;
  }

  if (!to) {
    to = '#';
  }

  const MenuLink = ({ to, exact, onClick, disabled, children }: PropsWithChildren<MenuLinkProps>) => {
    if (disabled) {
      // biome-ignore lint/a11y/useValidAnchor: testing new playstore Icon but existing code running into error, suppressing for now
      return <a>{children}</a>;
    }
    if (dropdown) {
      return (
        <button
          type="button"
          data-testid={dataTestId}
          aria-expanded={showDropdown}
          id={id}
          aria-haspopup="true"
          className="toggle-dropdown"
          onKeyUp={event => isDesktop && handleMenuToggleKeyPress(event)}
          onClick={() => !isDesktop && handleMenuToggleClick()}
        >
          {children}
        </button>
      );
    }
    return (
      <SmartLink
        exact={exact}
        to={to as string}
        onClick={onClick as any}
        target={target as string}
        data-testid={dataTestId}
      >
        {children}
      </SmartLink>
    );
  };

  const ListItemFocusTrap = ({ children }: PropsWithChildren<ListItemFocusTrapProps>) => {
    if (dropdown) {
      return (
        <FocusTrap
          active={showDropdown}
          focusTrapOptions={{
            onDeactivate: () => updateShowDropdown(forceActive),
            tabbableOptions: {
              displayCheck: 'none',
            },
            allowOutsideClick: true,
            clickOutsideDeactivates: true,
          }}
        >
          {children}
        </FocusTrap>
      );
    }
    return <Fragment>{children}</Fragment>;
  };

  return (
    <ListItemFocusTrap>
      <li
        ref={itemRef}
        className={classnames(
          'nav-item',
          className,
          { disabled: disabled },
          `hide-label-${hideLabel || 'lg'}`,
          { show: showDropdown },
          { dropdown: dropdown },
        )}
        title={tooltipText}
        aria-label={hintTooltipLabel ? hintTooltipLabel : ''} // used by Hint.css
      >
        <MenuLink disabled={disabled} to={to} exact={exact} onClick={onClick}>
          {icon && <FontIcon icon={icon} />}
          {alert ? <i className="wiw-icon wiw-circle msg-alert" /> : null}

          {showLabel && (
            <span className={typeof label === 'string' ? 'nav-item-label' : ''}>
              {label}
              {decorate && <sup className="beta"> {decorate}</sup>}
            </span>
          )}

          {showPlayStore && (
            <div className={typeof label === 'string' ? 'nav-item-label' : ''}>
              <img
                src={GoogleStore}
                role="presentation"
                style={{ height: '53.33px', paddingTop: '5px' }}
                alt="GoogleStore"
              />
            </div>
          )}

          {postIcon && (
            <div className="wiw-icon">
              <FontIcon icon={postIcon} className="pl-2" style={{ color: postIconColor }} />
            </div>
          )}
        </MenuLink>

        {children}

        {tooltipsEnabled && tooltipText && (
          <UncontrolledTooltip
            placement="right"
            target={itemRef.current as HTMLLIElement}
            delay={0}
            innerClassName={tooltipClassName}
          >
            {tooltipText}
          </UncontrolledTooltip>
        )}
      </li>
    </ListItemFocusTrap>
  );
};
