import { useHistory } from 'react-router-dom';

import type Absence from 'data/absence/model';
import Notification from 'data/notifications/model';
import type User from 'data/user/model';
import { ActionButton } from 'notifications/components/ActionButton';
import { NotifyItem } from 'notifications/components/NotifyItem';
import AvatarImg from 'shared/ui/AvatarImg';

type Props = {
  absence: Absence;
  creator: User;
  actionable: boolean;
};

export default function AbsenceNotification(props: Props) {
  const { absence, creator, actionable } = props;

  const history = useHistory();

  const getDescription = () => {
    const shiftDate = absence.mustLuxonDate('shiftStartTime').toFormat('LLL d');
    const shiftStart = absence.mustLuxonDate('shiftStartTime').toFormat('h:mma').slice(0, -1).toLowerCase();
    const shiftEnd = absence.mustLuxonDate('shiftEndTime').toFormat('h:mma').slice(0, -1).toLowerCase();

    return (
      <span>
        {' '}
        <strong>reported absent</strong> for their <strong>{shiftDate}</strong> shift from <strong>{shiftStart}</strong>{' '}
        to <strong>{shiftEnd}</strong>
      </span>
    );
  };

  const absenceRedirect = () => {
    history.push(`/requests/absences/${absence.id}`);
  };

  const shiftRedirect = () => {
    history.push(`/scheduler/shift/${absence.shiftId}`);
  };

  const getActions = () => {
    return [
      <ActionButton key="viewAbsence" title="View Absence" type="green" click={absenceRedirect} />,
      <ActionButton key="viewShift" title="View Shift" type="green" click={shiftRedirect} />,
    ];
  };

  return (
    <NotifyItem
      title={creator.shortName}
      description={getDescription()}
      avatar={<AvatarImg user={creator} size={Notification.AVATAR_SIZE} />}
      {...(actionable ? { actions: getActions() } : { click: absenceRedirect })}
    />
  );
}
