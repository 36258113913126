import { lazy } from 'react';

const StepUpAuthDialog = lazy(() => import('shared/auth/dialogs/StepUpAuthDialog'));

export const STEP_UP_AUTH_DIALOG = 'STEP_UP_AUTH_DIALOG';

const dialogs = {
  [STEP_UP_AUTH_DIALOG]: StepUpAuthDialog,
};

export default dialogs;
