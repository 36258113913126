import type User from 'data/user/model';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type UploadAvatarParams = {
  user: User;
  file: File;
};

export type UploadAvatarResponse = UploadAvatarNewResponse | UploadAvatarLegacyResponse;

interface UploadAvatarLegacyResponse {
  data: {
    hash: string;
    type: string;
    sizes: string[];
    createdAt: string;
    updatedAt: string;
  };
  error: unknown;
}

interface UploadAvatarNewResponse {
  avatarKey: string;
  cacheUrl: string;
  url: string;
}

export default createWiwAsyncThunk<UploadAvatarResponse, UploadAvatarParams>(
  'user/uploadAvatar',
  async (params, { rejectWithValue }) => {
    try {
      return (await Monolith.url(`/users/${params.user.id}/avatar`)
        .headers({
          'Content-Type': params.file.type,
        })
        .post(params.file)) as UploadAvatarResponse | UploadAvatarLegacyResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
