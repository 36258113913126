import { getToken, getTokenInfo, setToken } from 'shared/auth/auth';
import { request } from 'shared/auth/request';

export default function refreshToken() {
  return new Promise((resolve, reject) => {
    const currentToken = getToken();
    if (!currentToken) {
      return reject('No token was found.');
    }

    let token;
    try {
      token = getTokenInfo();
    } catch (error) {
      return reject('Unable to decode token.');
    }

    const shouldRefresh = token.needsRefresh();

    if (shouldRefresh) {
      return request()
        .post('/login/refresh')
        .then(data => {
          setToken(data.token);
          return resolve('Token refreshed.');
        })
        .catch(() => {
          return reject('Unable to refresh token.');
        });
    }
    return resolve('Token is up to date.');
  });
}
