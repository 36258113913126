import EntityBase from 'data/EntityBase';

import { Map } from 'immutable';

export default class NotificationState extends EntityBase({
  loaded: false,
  loading: false,
  error: false,
  show: false,
  new: 0,
  absences: new Map(),
  users: new Map(),
  requests: new Map(),
  swaps: new Map(),
  shifts: new Map(),
  locations: new Map(),
  messages: new Map(),
  activeSection: '',
  search: '',
}) {}
