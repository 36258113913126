import type { AccountFields } from 'data/account/model';
import type { LoginFields } from 'data/login/model';
import type { UserFields } from 'data/user/model';
import Monolith from 'shared/api/monolith';
import { createWiwAsyncThunk } from 'store';

type LoginResponse = {
  login: LoginFields;
  accounts: AccountFields[];
  users: UserFields[];
};

export default createWiwAsyncThunk<LoginResponse>('login/fetchLogin', async (_, { rejectWithValue }) => {
  try {
    return (await Monolith.query({ account_include: 'ref_page' }).get('/login')) as LoginResponse;
  } catch (error) {
    return rejectWithValue(error);
  }
});
