import { parseTextToTime } from 'shared/util/time';

export default function isTime(values, value) {
  if (!value || value === 'TIME' || value === 'OFF') {
    return true;
  }
  const parsedDate = parseTextToTime(value);
  if (parsedDate) {
    return parsedDate.isValid();
  }

  return false;
}
