import PropTypes from 'prop-types';
import { Component, createRef } from 'react';
import { addToRange } from 'react-day-picker';

import FontIcon from 'shared/ui/FontIcon';
import { getMomentDate } from 'shared/util/time';

import { DatePickerInput } from '@mantine/dates';
import classnames from 'classnames';
import { propTypes, withFormsy } from 'formsy-react';
import moment from 'moment-timezone';

import 'shared/form/styles/DatePicker.scss';
/**
 * This DatePicker should be used anytime you need to persist a date value in a form (or state)
 */
export class DatePicker extends Component {
  state = {
    dayPickerOpen: false,
    confirmation: false,
    from: null,
    to: null,
    enteredTo: null,
  };

  inputRef = createRef();

  static propTypes = {
    ...propTypes,
    label: PropTypes.any,
    labelStart: PropTypes.any,
    labelEnd: PropTypes.any,
    disableLabel: PropTypes.bool,
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    /** cf. https://mantine.dev/dates/date-picker-input/?t=styles-api */
    classNames: PropTypes.object,
    width: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.oneOf(['auto'])]),
    value: PropTypes.any,
    initialMonth: PropTypes.any,
    mask: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    inputProps: PropTypes.object,
    onChange: PropTypes.func,
    valueFormat: PropTypes.string,
    onClear: PropTypes.func,
    disabled: PropTypes.bool,
    onClickOnModifier: PropTypes.func,
    closeOnOutside: PropTypes.bool,
    validatePristine: PropTypes.bool,
    container: PropTypes.any,
    clearable: PropTypes.bool,
    clearFunction: PropTypes.func,
    clearValue: PropTypes.string,
    clearOnReset: PropTypes.bool, // default of true. If false, do not clear when "reset" button is clicked.
    rangePicker: PropTypes.bool,
    rangePickerHover: PropTypes.bool,
    rangePickerDualInput: PropTypes.bool,
    setValue: PropTypes.func.isRequired,
    checkValidity: PropTypes.bool,
    popperModifiers: PropTypes.object,
    setFocus: PropTypes.bool,
    changeOnApply: PropTypes.bool,
    maxDate: PropTypes.any,
    minDate: PropTypes.any,
    placeholder: PropTypes.string,
  };

  static defaultProps = {
    type: 'text',
    width: false,
    valueFormat: 'MMMM D, YYYY',
    onChange: () => {},
    clearOnReset: true,
    closeOnOutside: false,
    validatePristine: false,
    clearable: false,
    clearValue: 'Select...',
    rangePicker: false,
    rangePickerHover: false,
    rangePickerDualInput: false,
    changeOnApply: false,
    checkValidity: true,
    setFocus: false,
    initialMonth: null,
    excludeCol: false,
    classNames: {},
  };

  constructor(props) {
    super(props);
    // Initialize state for range picker hover if enabled and provided
    if (
      (props.rangePicker || props.rangePickerHover) &&
      props.changeOnApply &&
      props.value &&
      'from' in props.value &&
      'to' in props.value
    ) {
      this.state.from = props.value.from.toDate();
      this.state.to = props.value.to.toDate();
      this.state.enteredTo = props.value.to.toDate();
      this.state.value = props.value;
    }
  }

  componentDidUpdate(prevProps) {
    const propValue = this.props.value;
    // Update state for range picker hover if value is set from parent component
    if (
      (this.props.rangePicker || this.props.rangePickerHover) &&
      this.props.changeOnApply &&
      prevProps.value !== propValue &&
      'from' in propValue &&
      'to' in propValue
    ) {
      this.setState({
        from: propValue.from.toDate(),
        to: propValue.to.toDate(),
        enteredTo: propValue.to.toDate(),
        value: propValue,
      });
    }
  }

  componentDidMount() {
    if (this.props.setFocus) {
      this.inputRef.current.focus();
    }
  }

  toggleDatePicker = () => {
    if (this.props.disabled) {
      return;
    }
    this.setState(prevState => ({ dayPickerOpen: !prevState.dayPickerOpen }));
  };

  toggleConfirmDialog = () => {
    this.setState(prevState => ({ confirmation: !prevState.confirmation }));
  };

  modifyValue = value => {
    if (Array.isArray(value)) {
      const [start, end] = value;
      if (!getMomentDate(start).isValid() || !getMomentDate(end).isValid()) {
        return;
      }

      this.props.setValue(value);
      this.props.onChange(this.props.name, value);
      return;
    }

    if (!getMomentDate(value).isValid()) {
      return;
    }

    const date = getMomentDate(value);
    this.props.setValue(date);
    this.props.onChange(this.props.name, value);
  };

  setRangeClickValue = date => {
    let parsedStart;
    if (!this.props.value) {
      parsedStart = {
        from: moment().toDate(),
        to: moment().toDate(),
      };
    } else {
      parsedStart = {
        from: this.props.value.to?.toDate(),
        to: this.props.value.from?.toDate(),
      };
    }
    if (this.props.changeOnApply && this.state.from && this.state.enteredTo) {
      parsedStart = {
        from: this.state.from,
        to: this.state.enteredTo,
      };
    }
    let value = addToRange(date, parsedStart);

    if (value.to < value.from) {
      [value.from, value.to] = [value.to, value.from]; // Swap date range if backwards
    }
    value = {
      from: moment(value.from),
      to: moment(value.to),
    };

    this.setRangeValue(value);
  };

  setRangeHoverValue = date => {
    const { from, to } = this.state;
    if (from && to && date >= from && date <= to) {
      this.setState({
        from: date,
        to: null,
        enteredTo: null,
      });
      return;
    }
    if (this.isSelectingFirstDay(from, to, date)) {
      this.setState({
        from: date,
        to: null,
        enteredTo: null,
      });
    } else {
      const value = {
        to: moment(date),
        from: moment(from),
      };

      this.setRangeValue(value);
    }
  };

  setRangeValue = value => {
    if (this.props.changeOnApply) {
      this.setState({
        value,
        from: value.from.toDate(),
        to: value.to.toDate(),
        enteredTo: value.to.toDate(),
      });
    } else {
      this.props.setValue(value);
      this.props.onChange(this.props.name, value);
    }
  };

  isSelectingFirstDay = (from, to, day) => {
    const isBeforeFirstDay = from && moment(day).isBefore(from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
  };

  handleDayMouseEnter = date => {
    // Destructure from and to from state
    const { from, to } = this.state;

    // If rangePickerHover prop is true and the day being selected is not the first day
    if (this.props.rangePickerHover && !this.isSelectingFirstDay(from, to, date)) {
      // Update the enteredTo state with the date being hovered over
      this.setState({
        enteredTo: date,
      });
    }
  };

  handleApply = () => {
    if (this.props.changeOnApply) {
      this.props.setValue(this.state.value);
      this.props.onChange(this.props.name, this.state.value);
    }
    this.toggleDatePicker();
  };

  handleCancel = () => {
    if (this.props.changeOnApply && this.props.value) {
      // Reset state holding temp value
      this.setState({
        value: this.props.value,
        from: this.props.value.from.toDate(),
        to: this.props.value.to.toDate(),
        enteredTo: this.props.value.to.toDate(),
      });
    }
    this.toggleDatePicker();
  };

  render() {
    const propValue = moment.isMoment(this.props.value) ? this.props.value.toDate() : this.props.value;

    const invalid =
      (this.props.checkValidity &&
        (this.props.showError || !this.props.isValid) &&
        (this.props.validatePristine || !this.props.isPristine)) ||
      (this.props.required && this.props.value === null && !this.props.isPristine);

    const className = classnames('datepicker form-group', this.props.className, {
      col: !this.props.width && !this.props.excludeCol,
      [`col-${this.props.width}`]: this.props.width,
      'range-picker': this.props.rangePicker || this.props.rangePickerHover,
      disabled: this.props.disabled,
    }); // there

    let classNames = {
      root: 'date-picker-root',
      required: 'date-picker-required',
    };

    if (this.props.classNames) {
      classNames = {
        ...classNames,
        ...this.props.classNames,
      };
    }

    const setInitialMonth = moment.isMoment(this.props.initialMonth)
      ? moment()
          .set({
            month: this.props.initialMonth.month(),
            year: this.props.initialMonth.year(),
          })
          .toDate()
      : null;

    return (
      <DatePickerInput
        defaultDate={setInitialMonth}
        {...this.props}
        className={className}
        classNames={classNames}
        error={invalid ? this.props.errorMessage : null}
        label={this.props.label}
        valueFormat={this.props.valueFormat}
        type={this.props.rangePicker ? 'range' : 'default'}
        numberOfColumns={this.props.rangePicker ? 2 : 1}
        onChange={this.modifyValue}
        value={propValue}
        leftSection={<FontIcon icon="date" />}
        clearButtonProps={{
          icon: <FontIcon icon="close-circled" />,
          className: 'date-picker-clear',
        }}
        leftSectionPointerEvents="none"
        data-testid={this.props.name}
        {...this.props.inputProps}
      />
    );
  }
}
/**
 * @deprecated please migrate to hook-form from shared/form/inputs
 */
export default withFormsy(DatePicker);
