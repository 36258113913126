import Scheduling from 'shared/api/scheduling';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

export const deletePolicy = createWiwAsyncThunk<null, string>(
  'timeOff/policies/deletePolicy',
  async (policyId, { rejectWithValue }) => {
    try {
      return await Scheduling.delete(`/timeoff/policies/${policyId}`);
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
