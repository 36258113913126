import Payment from 'billing/util/payment';
import Plan from 'data/plan/model';
import Features from 'shared/features';

import { get, invert, isFinite } from 'lodash';

/**
 * In order for the 'Additional Products' section to be able to fire a SELECT_PLAN action when
 * additional products are toggled, we need some kind of plan mapping.
 * Example, selected plan is Basic without attendance, user toggled on attendance, so we need to know
 * which plan is Basic with Attendance.
 * @todo refactor to use db columns
 */
export class TierPlanMapping {
  static SCHEDULING_FREEMIUM_WITH_ATTENDANCE_PLAN_ID = 428;

  getSchedulerToAttendanceMap() {
    return {
      '-401': TierPlanMapping.SCHEDULING_FREEMIUM_WITH_ATTENDANCE_PLAN_ID,
      400: 402,
      404: 405, // basic => basic + attendance
      406: 407,
      408: 409,
      431: 432,
      410: 411, // pro => pro + attendance
      422: 423,
      424: 425,
      426: 427,
      433: 434,
      440: 441, // [pricing-2021] Small Business => Small Business + Attendance
      501: 500, // Scheduling Enterprise => Scheduling Enterprise + Attendance
      504: 503, // Scheduling Enterprise => Scheduling Enterprise + Attendance
      540: 541, // [pricing-2021] Enterprise => Enterprise + Attendance
      542: 543, // [pricing-2021] Enterprise Lite => Enterprise Lite + Attendance
    };
  }

  isPaired(plan) {
    return !!(
      plan &&
      (this.getSchedulerToAttendanceMap()[`${plan.id}`] || this.getAttendanceToSchedulerMap()[plan.id])
    );
  }

  getAttendanceToSchedulerMap() {
    return invert(this.getSchedulerToAttendanceMap());
  }

  getPreviousTierPlan(plan, availablePlans) {
    const previousTier = plan.previousTier();

    return availablePlans.findLast(availablePlan => {
      return (
        availablePlan.sortTier() === previousTier &&
        availablePlan.hasFeature(Features.ATTENDANCE) === plan.hasFeature(Features.ATTENDANCE)
      );
    });
  }

  getFreemiumTierPlan(plan, availablePlans, account) {
    return availablePlans.findLast(availablePlan => {
      return (
        availablePlan.isFreemiumTier() &&
        !availablePlan.isLegacy() &&
        availablePlan.hasFeature(Features.ATTENDANCE) ===
          (plan.hasFeature(Features.ATTENDANCE) || plan.hasLegacyAttendance(account))
      );
    });
  }

  getPaidAttendancePlan(availablePlans) {
    return availablePlans.findLast(availablePlan => {
      return (
        availablePlan.isPaid() &&
        !availablePlan.isFreemiumTier() &&
        !availablePlan.isLegacy() &&
        availablePlan.hasFeature(Features.ATTENDANCE)
      );
    });
  }

  getTierPlan(tier, withAttendance = false, availablePlans) {
    return availablePlans.findLast(availablePlan => {
      return (
        availablePlan.sortTier() === tier &&
        !availablePlan.isLegacy() &&
        availablePlan.hasFeature(Features.ATTENDANCE) === withAttendance
      );
    });
  }

  static stringifyAvailablePlans(availablePlans) {
    return JSON.stringify(availablePlans.map(plan => plan.id));
  }

  /**
   * This method is really confusing.
   * Ideally, this method would have thrown errors, but introducing that now might break plan selection
   * Returning the previousPlan on bad inputs or state is extremely confusing behavior
   *
   * @param previousPlan
   * @param availablePlans
   * @returns {Plan|*}
   */
  getTierPlanWithAttendance(previousPlan, availablePlans) {
    if (!(previousPlan instanceof Plan)) {
      return previousPlan;
    }

    if (previousPlan.hasFeature(Features.ATTENDANCE)) {
      return previousPlan;
    }

    const previousPlanId = get(previousPlan, 'id');
    const nextPlanId = get(this.getSchedulerToAttendanceMap(), previousPlanId);
    if (!isFinite(nextPlanId) || nextPlanId === null || nextPlanId === undefined) {
      return previousPlan;
    }

    const nextPlan = availablePlans.get(nextPlanId);
    if (nextPlan === null || !(nextPlan instanceof Plan)) {
      // Developer: If you get this warning, check if the /rest/plans API gave you the attendance version of the plan
      // and if the plan IDs are mapped in getSchedulerToAttendanceMap above
      // Ideally, this would be a throw, but a lot of legacy code relies on this function
      return previousPlan;
    }

    return nextPlan;
  }

  /**
   * Ideally, this method would have thrown errors, but introducing that now might break plan selection
   *
   * @param previousPlan
   * @param availablePlans
   * @returns {Plan|*}
   */
  getTierPlanWithoutAttendance(previousPlan, availablePlans) {
    if (!(previousPlan instanceof Plan)) {
      return previousPlan;
    }

    if (!previousPlan.hasFeature(Features.ATTENDANCE)) {
      return previousPlan;
    }

    const previousPlanId = get(previousPlan, 'id');
    const nextPlanId = Number.parseInt(get(this.getAttendanceToSchedulerMap(), previousPlanId), 10);
    if (!isFinite(nextPlanId) || nextPlanId === null || nextPlanId === undefined) {
      return previousPlan;
    }

    const nextPlan = availablePlans.get(nextPlanId);
    if (nextPlan === null || !(nextPlan instanceof Plan)) {
      // Developer: If you get this warning, check if the /rest/plans API gave you the attendance version of the plan
      // and if the plan IDs are mapped in getSchedulerToAttendanceMap above
      // Ideally, this would be a throw, but a lot of legacy code relies on this function
      return previousPlan;
    }

    return nextPlan;
  }

  schedulingCost(plan, availablePlans, nextBillingType, account) {
    if (!(plan instanceof Plan)) {
      return 0;
    }

    const payment = new Payment(account, plan, []);

    const withoutAttendance = plan.hasFeature(Features.ATTENDANCE)
      ? this.getTierPlanWithoutAttendance(plan, availablePlans)
      : plan;
    const withoutAttendanceCost = payment.totalPerUnit({ newPlan: withoutAttendance, newBillingType: nextBillingType });

    return Math.round(100 * withoutAttendanceCost) / 100;
  }

  attendanceCost(plan, availablePlans, nextBillingType, account) {
    if (!(plan instanceof Plan)) {
      return 0;
    }

    const payment = new Payment(account, plan, []);

    const withoutAttendance = !plan.hasFeature(Features.ATTENDANCE)
      ? plan
      : this.getTierPlanWithoutAttendance(plan, availablePlans);
    const withoutAttendanceCost = payment.totalPerUnit({ newPlan: withoutAttendance, newBillingType: nextBillingType });

    const withAttendance = plan.hasFeature(Features.ATTENDANCE)
      ? plan
      : this.getTierPlanWithAttendance(plan, availablePlans);
    const withAttendanceCost = payment.totalPerUnit({ newPlan: withAttendance, newBillingType: nextBillingType });

    return Math.round(100 * (withAttendanceCost - withoutAttendanceCost)) / 100;
  }

  baseAttendanceCost(attendancePlan, availablePlans, nextBillingType, account) {
    // todo find a better way to deal with legacy scheduler freemium + paid attendance
    return this.attendanceCost(
      availablePlans.get(TierPlanMapping.SCHEDULING_FREEMIUM_WITH_ATTENDANCE_PLAN_ID),
      availablePlans,
      nextBillingType,
      account,
    );
  }

  isBundled(schedulerPlan, attendancePlan) {
    return (
      schedulerPlan &&
      attendancePlan &&
      !schedulerPlan.isLegacy() &&
      schedulerPlan.hasFeature(Features.LONG_TERM_SCHEDULING) &&
      attendancePlan.hasFeature(Features.ATTENDANCE)
    );
  }

  bundleSavingsPercent(schedulerPlan, attendancePlan, availablePlans, account, billingType) {
    const schedulerCost = this.schedulingCost(schedulerPlan, availablePlans, billingType, account);
    const attendanceCost = this.attendanceCost(attendancePlan, availablePlans, billingType, account);
    const baseAttendanceCost = this.baseAttendanceCost(attendancePlan, availablePlans, billingType, account);

    return Math.round(100 * (1 - (schedulerCost + attendanceCost) / (schedulerCost + baseAttendanceCost)));
  }

  // If the plan row's "sort" column == 4, and the account is not on a trial
  isEnterpriseEnabled(accountPlan, customPlan) {
    return (
      (accountPlan?.isEnterpriseTier() && !accountPlan.isTrial()) ||
      (customPlan?.isEnterpriseTier() && !customPlan.isTrial())
    );
  }
}

export default TierPlanMapping;
