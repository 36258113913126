import PropTypes from 'prop-types';
import { Component } from 'react';
import Autosuggest from 'react-autosuggest';

import InputWrapper from 'shared/components/form/wrappers/InputWrapper';
import { withIntl } from 'shared/i18n';

import classnames from 'classnames';
import { propTypes, withFormsy } from 'formsy-react';
import { escapeRegExp } from 'lodash';
import moment from 'moment-timezone';

export class TimePicker extends Component {
  static propTypes = {
    ...propTypes,
    label: PropTypes.any,
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    value: PropTypes.shape({
      start: PropTypes.any,
      end: PropTypes.any,
    }),
    inputProps: PropTypes.object,
    disabled: PropTypes.bool,
    newStyle: PropTypes.bool,
    divider: PropTypes.node,
    formatTimeLong: PropTypes.func,
    onChange: PropTypes.func,
    validatePristine: PropTypes.bool,
    validateOnBlur: PropTypes.bool,
    placeholder: PropTypes.string,
    shouldReset: PropTypes.bool,
    updateReset: PropTypes.func,
    singleValue: PropTypes.bool,
    showSuggestions: PropTypes.bool,
  };

  static defaultProps = {
    disabled: false,
    newStyle: false,
    divider: '-',
    onChange: () => {},
    onBlur: () => {},
    validatePristine: false,
    validateOnBlur: false,
    placeholder: 'Enter time (e.g. 9a – 5p)…',
    shouldReset: false,
    updateReset: () => {},
    singleValue: false,
    showSuggestions: true,
  };

  today = moment();
  _hours = null;

  state = {
    part: 'start',
    input: '',
    hasBlurred: false,
  };

  UNSAFE_componentWillMount() {
    this.parseDateProps(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.parseDateProps(nextProps);
  }

  parseDateProps = props => {
    if (!Object.keys(props.value).length) {
      //adding a clear for bulk edit
      this.setState({ input: '' });
      return;
    }
    const { start, end } = props.value;
    const startString = this.getTimeValue(start);
    const endString = this.getTimeValue(end);
    if (start !== startString || end !== endString) {
      props.setValue({
        start: startString,
        end: endString,
      });
    }

    //for bulkedit when reset it hit we need a way to reset the times if they are not blank
    if (
      this.state.input !== `${props.value.start} - ${props.value.end}` &&
      start === startString &&
      props.shouldReset
    ) {
      this.props.updateReset(false);
      //someitmes the prop is not update if the user does not leave the input when clicking reset changes
      //if modified in that instance example 1:00PM - 2:00PM delete 2 the input will update to 1:00PM - 12:00PM
      props.setValue({ shouldReset: false });
      this.setState({ input: this.getValue({ start: props.value.start, end: props.value.end }) });
    }

    if (!this.state.input) {
      this.setState({ input: this.getValue({ start: startString, end: endString }) });
    }
  };

  getTimeValue = val => {
    if (val instanceof moment) {
      val = val.toDate();
    }
    if (val instanceof Date) {
      return this.props.formatTimeLong(val);
    }
    return val;
  };

  getValue = (value = this.props.value) => {
    return [value.start, value.end].filter(time => time && time.length > 0).join(` ${this.props.divider} `);
  };

  cleanInput = str => {
    return str
      .toLowerCase()
      .replace(/[^0-9:apm ]+/g, '')
      .trim();
  };

  getHours() {
    if (this._hours) {
      return this._hours;
    }
    const times = [];
    const today = moment();
    // DST all 2 am and 3 am times are the same timestamp, so change the base date
    // to a regular day so we still have 2, 2:15, 2:30, 2:45 in the hours list
    if (today.hour(2).format('x') === today.hour(3).format('x')) {
      today.add(1, 'd');
    }
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 4; minute++) {
        today.set({ hour, minute: minute * 15 });
        const formattedTime = this.props.formatTimeLong(today);
        times.push({ label: formattedTime, value: formattedTime });
      }
    }

    return (this._hours = times);
  }

  findPart = passthru => e => {
    const pos = e.target.selectionStart;
    const dash = (e.target.value || this.state.input).indexOf(this.props.divider);

    // Find if the cursor is before or after the divider (-)
    const part = dash <= 0 || pos <= dash || this.props.singleValue ? 'start' : 'end';
    this.setState({ part });

    if (passthru) {
      passthru(e);
    }
  };

  getCurrentInput = (input, part = this.state.part) => {
    // Get the full input...
    const parts = (input || this.state.input).split(this.props.divider);

    // And split it based on the cursor position
    if (part === 'start' || this.props.singleValue) {
      return parts[0].trim();
    }
    if (parts.length > 1) {
      return parts[1].trim();
    }
    return '';
  };

  filter = input => option => {
    const term = this.cleanInput(input);
    const search = `0?${escapeRegExp(term)}`;
    let item = option.label.toLowerCase();
    const searchPosition =
      !!window.MSInputMethodContext && !!document.documentMode
        ? 1 // IE-11
        : 0; // All others

    // If a colon, do a more strict match
    if (term.match(':')) {
      return item.search(search) === searchPosition;
    }
    // Otherwise match generically without the symbols
    // i.e. 114 matches 11:45a/pm
    if (term.match(/(a|p)m?$/)) {
      item = item.replace(':00', '');
    }
    return item.replace(':', '').search(search) === searchPosition;
  };

  filteredHours = value => {
    const input = this.getCurrentInput(value);
    return this.getHours().filter(this.filter(input));
  };

  renderInput = invalid => props => {
    const { onClick, onFocus, onChange, className, ...moreProps } = props;
    const inputClassname = classnames(
      'form-control',
      'time-picker-input',
      {
        'form-control-danger': invalid,
        newStyle: this.props.newStyle,
      },
      className,
    );

    return (
      <input
        className={inputClassname}
        onClick={this.findPart(onClick)}
        onFocus={this.findPart(onFocus)}
        onChange={this.findPart(onChange)}
        {...moreProps}
        {...this.props.inputProps}
      />
    );
  };

  onInputChange = (e, data) => {
    const { showSuggestions, singleValue } = this.props;
    if (!showSuggestions || (showSuggestions && data.method === 'type')) {
      const input = showSuggestions ? data.newValue : e.target.value;
      this.setState({ input }, () => {
        const value = {
          start: this.getCurrentInput(input, 'start'),
          end: !singleValue ? this.getCurrentInput(input, 'end') : undefined,
        };

        this.props.onChange(value);
        this.props.setValue(value);
      });
    }
  };

  onInputBlur = () => {
    this.setState({
      hasBlurred: true,
    });
    this.props.setValue(this.props.value);
    this.props.onBlur(this.props.value);
  };

  onSelected = (event, { suggestionValue }) => {
    event.preventDefault();
    const value = { ...this.props.value, [this.state.part]: suggestionValue };
    this.props.setValue(value);
    this.props.onChange(value);
    let input = this.getValue(value);
    if (this.props.singleValue) {
      // Take only first value if provided a range via divider
      input = input.split(this.props.divider)[0];
    } else if (input.indexOf(this.props.divider) === -1) {
      input += ` ${this.props.divider} `;
    }
    this.setState({ input });
  };

  render() {
    const invalid =
      (this.props.showError || !this.props.isValid) &&
      (this.props.validatePristine || !this.props.isPristine) &&
      !(this.props.validateOnBlur && !this.props.hasBlurred);

    const inputProps = {
      placeholder: this.props.placeholder,
      value: this.state.input,
      onBlur: this.onInputBlur,
      onChange: this.onInputChange,
      disabled: this.props.disabled,
    };

    return (
      <InputWrapper
        {...this.props}
        validateOnBlur={this.props.validateOnBlur}
        hasBlurred={this.state.hasBlurred}
        classNames={classnames(this.props.className, 'time-picker')}
      >
        {this.props.showSuggestions ? (
          <Autosuggest
            suggestions={this.filteredHours()}
            onSuggestionsFetchRequested={/* istanbul ignore next */ () => {}}
            onSuggestionsClearRequested={/* istanbul ignore next */ () => {}}
            onSuggestionSelected={this.onSelected}
            renderInputComponent={this.renderInput(invalid)}
            renderSuggestion={item => <div>{item.label}</div>}
            highlightFirstSuggestion
            getSuggestionValue={item => item.value}
            inputProps={inputProps}
          />
        ) : (
          this.renderInput(invalid)(inputProps)
        )}
      </InputWrapper>
    );
  }
}
/**
 * @deprecated please migrate to hook-form from shared/form/inputs
 */
export default withFormsy(withIntl(TimePicker));
