import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import LottieAnimation from 'shared/ui/LottieAnimation';
import * as animationData from 'shared/ui/assets/img/wiw-spinner-white-transparent.json';
import grayShiftsSpinner from 'styles/assets/lottie/wiw-spinner-shifts-gray.json';
import graySpinner from 'styles/assets/lottie/wiw-spinner-small-gray.json';
import wiwSpinner from 'styles/assets/lottie/wiw-spinner-small-green.json';

export default class SpinningLoader extends PureComponent {
  static propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    spinner: PropTypes.string,
    color: PropTypes.string,
    className: PropTypes.string,
  };

  static defaultProps = {
    width: '100%',
    height: '100%',
    color: 'white',
  };

  getSpinner() {
    const color = this.props.spinner || this.props.color;

    switch (color) {
      case 'green':
        return wiwSpinner;
      case 'gray':
        return graySpinner;
      case 'gray-shifts':
        return grayShiftsSpinner;
      default:
        return animationData.default;
    }
  }

  render() {
    return (
      <LottieAnimation
        animationData={this.getSpinner()}
        height={this.props.height}
        width={this.props.width}
        className={this.props.className}
      />
    );
  }
}
