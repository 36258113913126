import EntityBase from 'data/EntityBase';
import type { ReportDatum } from 'data/reporting/models/ReportDatum';

export class CustomReportingState extends EntityBase({
  items: Array<ReportDatum>(),
  loading: false,
  loaded: false,
  receivedAt: 0,
  error: null,
}) {
  // nothing
}
