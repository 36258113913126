import BaseModel from 'data/BaseModel';
import type User from 'data/user/model';
import type { UserFields } from 'data/user/model';

import type { Map } from 'immutable';

export interface SwapStatus {
  shift_id: number;
  status: number;
  user_id: number;
  user_status: number;
}

export interface SwapFields {
  id: number | null;
  account_id: number;
  location_id: number;
  user_id: number;
  type: number;
  shift_id: number;
  status: number;
  allowed_statuses: number[];

  /**
   * I don't think this field ever actually gets set or used. `shifts` is
   * not on the `swap` portion of the response body for fetching swaps, and
   * no where in the actions or reducers is it set.
   *
   * I'm sure this is supposed to be the Shift model, but I'm going to
   * leave it as `any` since I don't think it's being used.
   */
  shifts: any[]; // TODO(types)
  statuses: SwapStatus[];
  accepted_id: number;
  creator_id: number;
  updater_id: number;
  user_status: number;
  canceled_id: number;
  created_at: string | null;
  updated_at: string | null;
}

export enum SwapTypes {
  Swap = 1,
  Drop = 2,
  Alert = 3,
}

export enum SwapReduxDataNames {
  Statuses = 'swap_statuses',
  Types = 'swap_types',
  Schedules = 'swap_schedules',
}

export enum SwapStatuses {
  Pending = 0,
  Approved = 1,
  Declined = 2,
  Completed = 3,
  Canceled = 4,
  Expired = 5,
  Denied = 6,
}

export enum SwapUserShiftStatuses {
  Pending = 0,
  Accepted = 1,
  Declined = 2,
  AutoDeclined = 3,
}

export enum SwapTabNames {
  Pending = 'pending',
  Accept = 'accept',
  Mine = 'mine',
  All = 'all',
}

class Swap extends BaseModel<SwapFields>({
  id: null,
  account_id: 0,
  location_id: 0,
  user_id: 0,
  type: 0,
  shift_id: 0,
  status: 0,
  allowed_statuses: [],
  shifts: [],
  statuses: [],
  accepted_id: 0,
  creator_id: 0,
  updater_id: 0,
  user_status: 0,
  canceled_id: 0,
  created_at: null,
  updated_at: null,
}) {
  static Types = {
    ALERT: SwapTypes.Alert,
    DROP: SwapTypes.Drop,
    SWAP: SwapTypes.Swap,
  } as const;

  // sorted for filters to display correctly
  static Status = {
    PENDING: SwapStatuses.Pending,
    APPROVED: SwapStatuses.Approved,
    COMPLETED: SwapStatuses.Completed,
    EXPIRED: SwapStatuses.Expired,
    DENIED: SwapStatuses.Denied,
    DECLINED: SwapStatuses.Declined,
    CANCELED: SwapStatuses.Canceled,
  } as const;

  static ReduxDataNames = {
    STATUSES: SwapReduxDataNames.Statuses,
    TYPES: SwapReduxDataNames.Types,
    SCHEDULES: SwapReduxDataNames.Schedules,
  } as const;

  static UserShiftStatus = {
    PENDING: SwapUserShiftStatuses.Pending,
    ACCEPTED: SwapUserShiftStatuses.Accepted,
    DECLINED: SwapUserShiftStatuses.Declined,
    AUTO_DECLINED: SwapUserShiftStatuses.AutoDeclined,
  } as const;

  static TabNames = {
    PENDING: SwapTabNames.Pending,
    ACCEPT: SwapTabNames.Accept,
    MINE: SwapTabNames.Mine,
    ALL: SwapTabNames.All,
  } as const;

  static statusNameOption = (status: SwapStatuses) => {
    switch (status) {
      case Swap.Status.PENDING:
        return { name: 'Pending Approval', color: 'C59A27' };
      case Swap.Status.APPROVED:
        return { name: 'Pending Acceptance', color: '9B9B9B' };
      case Swap.Status.COMPLETED:
        return { name: 'Accepted', color: '51A33D' };
      case Swap.Status.CANCELED:
        return { name: 'Canceled', color: 'D0404B' };
      case Swap.Status.DECLINED:
        return { name: 'Declined', color: 'D0404B' };
      case Swap.Status.EXPIRED:
        return { name: 'Expired', color: 'D0404B' };
      case Swap.Status.DENIED:
        return { name: 'Denied', color: 'D0404B' };
    }
  };

  get statusName() {
    switch (this.status) {
      case SwapStatuses.Pending:
        return 'pending';
      case SwapStatuses.Approved:
        return 'approved';
      case SwapStatuses.Declined:
        return 'declined';
      case SwapStatuses.Completed:
        return 'completed';
      case SwapStatuses.Canceled:
        return 'canceled';
      case SwapStatuses.Expired:
        return 'expired';
      case SwapStatuses.Denied:
        return 'denied';
      default:
        return null;
    }
  }

  isSwap() {
    return this.type === SwapTypes.Swap;
  }

  isDrop() {
    return this.type === SwapTypes.Drop;
  }

  isAlert() {
    return this.type === SwapTypes.Alert;
  }

  typeName() {
    switch (this.type) {
      case SwapTypes.Drop:
        return 'drop';
      case SwapTypes.Alert:
        return 'alert';
      default:
        return 'swap';
    }
  }

  statusColor() {
    switch (this.status) {
      case SwapStatuses.Pending:
        return 'yellow';
      case SwapStatuses.Approved:
        return 'gray';
      case SwapStatuses.Completed:
        return 'green';
      default:
        return 'red';
    }
  }

  requestType() {
    switch (this.status) {
      case SwapStatuses.Pending:
        return 'pending approval';
      case SwapStatuses.Approved:
        return 'pending acceptance';
      case SwapStatuses.Completed:
        return 'accepted';
      case SwapStatuses.Canceled:
        return this.canceled_id === this.user_id || this.canceled_id === 0 ? this.statusName : 'denied';
      default:
        return this.statusName;
    }
  }

  displayRequestStatus(users: Map<UserFields['id'], User>) {
    const updated_date = `on ${this.date('updated_at')?.format('MMM D')}`;
    const who = 'by';

    switch (this.status) {
      case SwapStatuses.Pending:
        return 'from management';
      case SwapStatuses.Approved:
        return 'from recipients';
      case SwapStatuses.Canceled:
        return this.canceled_id === 0
          ? 'shift modified'
          : `${who} ${users.get(this.canceled_id)?.fullName || ''} ${updated_date}`;
      case SwapStatuses.Completed:
        return `${who} ${users.get<Partial<User>>(this.updater_id, {})?.fullName || ''} ${updated_date}`;
      case SwapStatuses.Declined:
        return 'by all recipients';
      case SwapStatuses.Expired:
        return updated_date;
      case SwapStatuses.Denied:
        return this.canceled_id === 0 ? null : `${who} ${users.get(this.canceled_id)?.fullName || ''} ${updated_date}`;
    }
  }

  typeNameDisplay() {
    switch (this.typeName()) {
      case 'alert':
        return 'drop';
      default:
        return this.typeName();
    }
  }

  wasDenied() {
    if (this.status !== SwapStatuses.Canceled) {
      return false;
    }
    return (
      this.user_id !== this.canceled_id && !(this.type === SwapTypes.Alert && this.canceled_id === this.creator_id)
    );
  }

  isOpen() {
    return this.status === SwapStatuses.Pending || this.status === SwapStatuses.Approved;
  }
}

export default Swap;
