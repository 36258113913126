import { lazy } from 'react';

/* istanbul ignore file */

const ClosePayPeriodDialog = lazy(() => import('./ClosePayPeriodDialog'));
const ReopenPayPeriodDialog = lazy(() => import('./ReopenPayPeriodDialog'));
const ExportTimesheetsDialog = lazy(() => import('./ExportTimesheetsDialog'));
const ExportPayrollDialog = lazy(() => import('./ExportPayrollDialog'));
const EditPayPeriodDialog = lazy(() => import('./EditPayPeriodDialog'));
const TimeOffDialog = lazy(() => import('./TimeOffDialog'));
const BreakPromptDialog = lazy(() => import('./BreakPromptDialog'));
const BreakDialog = lazy(() => import('./BreakDialog'));
const FeedbackDialog = lazy(() => import('./TimesheetsFeedbackDialog'));
const PositionHoursDialog = lazy(() => import('./PositionHoursDialog/PositionHoursDialog'));
const EntryHistoryDialog = lazy(() => import('./EntryHistoryDialog'));
const EntryHistoryDialogV2 = lazy(() => import('./EntryHistoryDialogV2'));
const CustomPayPeriodDialog = lazy(() => import('./CustomPayPeriodDialog'));
const CashTipsDialog = lazy(() => import('./CashTipsDialog'));

/*
Add your dialogs below. Import, add a const, then add
to the dialogs list.

To open a dialog, do this
import { openDialog, closeDialog } from 'dialogs';
import { REQUEST_VIEW_DIALOG } from 'timesheets/dialogs';

// Bind these with redux
openDialog(REQUEST_VIEW_DIALOG, { request, otherProp, payload });
closeDialog(REQUEST_VIEW_DIALOG);
*/
// Identifiers for each dialog
export const EXPORT_TIMESHEETS_DIALOG = 'EXPORT_TIMESHEETS_DIALOG';
export const EXPORT_PAYROLL_DIALOG = 'EXPORT_PAYROLL_DIALOG';
export const EDIT_PAYPERIOD_DIALOG = 'EDIT_PAYPERIOD_DIALOG';
export const CLOSE_PAYPERIOD_DIALOG = 'CLOSE_PAYPERIOD_DIALOG';
export const REOPEN_PAYPERIOD_DIALOG = 'REOPEN_PAYPERIOD_DIALOG';
export const TIMESHEETS_TIME_OFF_DIALOG = 'TIMESHEETS_TIME_OFF_DIALOG';
export const BREAK_DIALOG = 'BREAK_DIALOG';
export const BREAK_PROMPT_DIALOG = 'BREAK_PROMPT_DIALOG';
export const FEEDBACK_DIALOG = 'FEEDBACK_DIALOG';
export const POSITION_HOURS_DIALOG = 'POSITION_HOURS_DIALOG';
export const ENTRY_HISTORY_DIALOG = 'ENTRY_HISTORY_DIALOG';
export const ENTRY_HISTORY_DIALOG_V2 = 'ENTRY_HISTORY_DIALOG_V2';
export const CUSTOM_PAY_PERIOD_DIALOG = 'CUSTOM_PAY_PERIOD_DIALOG';
export const CASH_TIPS_DIALOG = 'CASH_TIPS_DIALOG';

const dialogs = {
  [EXPORT_TIMESHEETS_DIALOG]: ExportTimesheetsDialog,
  [EXPORT_PAYROLL_DIALOG]: ExportPayrollDialog,
  [EDIT_PAYPERIOD_DIALOG]: EditPayPeriodDialog,
  [CLOSE_PAYPERIOD_DIALOG]: ClosePayPeriodDialog,
  [TIMESHEETS_TIME_OFF_DIALOG]: TimeOffDialog,
  [BREAK_DIALOG]: BreakDialog,
  [BREAK_PROMPT_DIALOG]: BreakPromptDialog,
  [REOPEN_PAYPERIOD_DIALOG]: ReopenPayPeriodDialog,
  [FEEDBACK_DIALOG]: FeedbackDialog,
  [POSITION_HOURS_DIALOG]: PositionHoursDialog,
  [ENTRY_HISTORY_DIALOG]: EntryHistoryDialog,
  [ENTRY_HISTORY_DIALOG_V2]: EntryHistoryDialogV2,
  [CUSTOM_PAY_PERIOD_DIALOG]: CustomPayPeriodDialog,
  [CASH_TIPS_DIALOG]: CashTipsDialog,
  // Add more here ...
};

export default dialogs;
