import type { TwilioUser } from 'workchat/types/TwilioUser';
import 'workchat/styles/BigParticipantList.scss';
import type { List } from 'immutable';

export type BigParticipantListProps = {
  users: TwilioUser[] | List<TwilioUser>;
};

export default function BigParticipantList({ users }: BigParticipantListProps) {
  return (
    <div className="participants">
      {users.map(user => {
        if (!user) {
          return null;
        }
        return (
          <div className="participant" key={`participant-list-${user.id}`}>
            <img src={user.avatar_url} alt="Avatar" />
            {user.fullName}
          </div>
        );
      })}
    </div>
  );
}
