import Absence from 'data/absence/model';
import LocationCallout from 'data/notifications/callouts/location';
import Notification from 'data/notifications/model';
import OpenShiftRequests from 'data/openshiftRequests/model';
import Request from 'data/request/model';
import Swap from 'data/swap/model';
import User from 'data/user/model';
import { getActionNotifications, getWaitingNotifications } from './interactiveNotifications';
import {
  absencesSelector,
  notificationsSelector,
  requestsSelector,
  searchSelector,
  swapsSelector,
  usersSelector,
} from './storeSelectors';

import { createSelector } from '@reduxjs/toolkit';
import Fuse from 'fuse.js';
import { List, Map, Set } from 'immutable';

const FUZE_SEARCH_OPTIONS = {
  useExtendedSearch: true,
  maxPatternLength: 32,
  minMatchCharLength: 2,
  shouldSort: false,
  keys: ['meta'],
};

const addUserDetails = (meta, user) => {
  if (user) {
    meta.push(user.first_name, user.last_name);
  }
};

const formatSearch = search => {
  if (search?.meta && Array.isArray(search.meta)) {
    search.meta = new Set(search.meta).join(' ');
  }
  return search;
};

const getRequestSearch = (request, users) => {
  const meta = ['timeoff', 'request'];
  if (request.user_id) {
    addUserDetails(meta, users.get(request.user_id));
  }
  return {
    meta,
    item: request,
  };
};

const getSwapSearch = (swap, users) => {
  const meta = ['swap', 'drop'];
  if (swap.user_id) {
    addUserDetails(meta, users.get(swap.user_id));
  }
  return {
    meta,
    item: swap,
  };
};

const getAbsenceSearch = (absence, users) => {
  const meta = ['absence', 'absent'];
  if (absence.userId) {
    addUserDetails(meta, users.get(absence.asInt('userId')));
  }
  return {
    meta,
    item: absence,
  };
};

const getUserSearch = user => {
  const meta = ['account', 'registered'];
  addUserDetails(meta, user);
  return {
    meta,
    item: user,
  };
};

const getLocationSearch = location => {
  return {
    meta: 'verify location',
    item: location,
  };
};

const getOpenShiftRequestSearch = request => {
  return {
    meta: ['openshift', 'request', 'approval'],
    item: request,
  };
};

const getNotificationSearch = (notification, users, requests, swaps, absences) => {
  const search = {
    meta: [],
    item: notification,
  };
  if (notification.creator_id) {
    addUserDetails(search.meta, users.get(notification.creator_id));
  }
  if (notification.message_id) {
    search.meta.unshift('message');
  } else if (notification.request_id) {
    search.meta.unshift('timeoff', 'request');
    if (requests) {
      const request = requests.get(notification.request_id);
      if (request?.user_id) {
        addUserDetails(search.meta, users.get(request.user_id));
      }
    }
  } else if (notification.swap_id) {
    search.meta.unshift('swap', 'drop');
    if (swaps) {
      const swap = swaps.get(notification.swap_id);
      if (swap?.user_id) {
        addUserDetails(search.meta, users.get(swap.user_id));
      }
    }
  } else if (notification.absence_id) {
    search.meta.unshift('absent', 'absence');
    if (absences) {
      const absence = absences.get(`${notification.absence_id}`);
      if (absence?.userId) {
        addUserDetails(search.meta, users.get(absence.asInt('userId')));
      }
    }
  } else if (notification.user_id) {
    search.meta.unshift('account', 'registered');
  }
  return search;
};

const getSearchable = (entries, users, requests, swaps, absences) => {
  return entries
    .map(entry => {
      if (entry instanceof Request) {
        return formatSearch(getRequestSearch(entry, users));
      }
      if (entry instanceof Swap) {
        return formatSearch(getSwapSearch(entry, users));
      }
      if (entry instanceof Absence) {
        return formatSearch(getAbsenceSearch(entry, users));
      }
      if (entry instanceof User) {
        return formatSearch(getUserSearch(entry));
      }
      if (entry instanceof Notification) {
        return formatSearch(getNotificationSearch(entry, users, requests, swaps, absences));
      }
      if (entry instanceof LocationCallout) {
        return formatSearch(getLocationSearch(entry));
      }
      if (entry instanceof OpenShiftRequests) {
        return formatSearch(getOpenShiftRequestSearch(entry));
      }
    })
    .filter(entry => entry?.meta);
};

const searchNotifications = (search, entries, users, requests = null, swaps = null, absences = null) => {
  search = search.trim();
  if (!search) {
    return entries;
  }

  const terms = getSearchable(entries, users, requests, swaps, absences);
  const searchable = Array.isArray(terms) ? terms : terms.toArray();
  const fuse = new Fuse(searchable, FUZE_SEARCH_OPTIONS);
  const results = fuse.search(search);

  if (entries instanceof Map) {
    return entries.clear().merge(results.map(result => [result.item.item.id, result.item.item]));
  }
  if (entries instanceof List) {
    return entries.clear().merge(results.map(result => result.item.item));
  }
  return results.map(result => result.item.item);
};

export const getSearchedActionNotifications = createSelector(
  [searchSelector, getActionNotifications, usersSelector],
  searchNotifications,
);

export const getSearchedWaitingNotifications = createSelector(
  [searchSelector, getWaitingNotifications, usersSelector],
  searchNotifications,
);

export const getSearchedNotifications = createSelector(
  [searchSelector, notificationsSelector, usersSelector, requestsSelector, swapsSelector, absencesSelector],
  searchNotifications,
);
