import type Position from 'data/position/model';
import type { PositionFields } from 'data/position/model';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type BulkPositionsResponse = {
  positions: PositionFields[];
};

export const bulkPositions = createWiwAsyncThunk<BulkPositionsResponse, Position[]>(
  'positions/bulk',
  async (positions, { rejectWithValue }) => {
    try {
      return (await Monolith.url('/positions/bulk').post(JSON.stringify(positions))) as BulkPositionsResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
