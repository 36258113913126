import EntityBase from 'data/EntityBase';
import IndexedMap from 'data/IndexedMap';
import User from 'data/user/model';

export const userIndices = [
  // Single property keys found via .findWhere('key', 'value')
  // composite keys found via .findWhere(['key1', 'key2'], ['valueForKey1', 'valueForKey2'])
  ['is_active', 'is_deleted'],
] as const;

export type Users = UserState['items'];

const defaultItems = new IndexedMap(User, 'id', userIndices);

export default class UserState extends EntityBase({
  items: new IndexedMap(User, 'id', userIndices),
}) {
  declare readonly items: typeof defaultItems;
}
