import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

import EntityState from 'data/EntityState';
import User from 'data/user/model';
import generateThunkReducers from 'data/util/generateThunkReducers';
import { type WorkchatAPIUser, loadWorkchatUsers } from 'workchat/v2/store/users/usersActions';

export const workchatUsersSliceName = 'workchatv2/users';

export const workchatUsersSlice = createSlice({
  name: workchatUsersSliceName,
  initialState: new EntityState<User>(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadWorkchatUsers.fulfilled, (state, action: PayloadAction<WorkchatAPIUser[]>) => {
      const userMap = new Map();
      // Convert WC API User into normalized User model
      action.payload.forEach(user => {
        userMap.set(
          user.id,
          new User({
            ...user,
            avatar: { url: user.avatar_url, size: '%s' },
            positions: user.positions.map((positionId: string) => +positionId),
            locations: user.locations.map((locationId: string) => +locationId),
            is_deleted: !!user.is_deleted,
            is_active: !user.is_deleted,
          }),
        );
      });

      return state.set('items', state.get('items').merge(userMap));
    });

    generateThunkReducers(builder, workchatUsersSliceName);
  },
});

export default workchatUsersSlice.reducer;
