import { lazy } from 'react';

const SchedulerGuidePublishCelebrateDialog = lazy(
  () => import('onboarding/modules/guides/scheduler/PublishCelebrateDialog'),
);
const SchedulerGuideWelcomeDialog = lazy(() => import('onboarding/modules/guides/scheduler/WelcomeDialog'));
const DashboardGetMobileAppDialog = lazy(() => import('onboarding/dialogs/DashboardGetMobileAppDialog'));
const SchedulerGuidePublishPreviewDialog = lazy(
  () => import('onboarding/modules/guides/scheduler/PublishPreviewDialog'),
);
const InviteTrustedUserDialog = lazy(
  () => import('onboarding/dialogs/InviteTrustedUserDialog/InviteTrustedUserDialog'),
);
const SetupYourScheduleDialog = lazy(() => import('onboarding/dialogs/SetupYourScheduleDialog'));

// Identifiers for each dialog
export const CLEAR_SAMPLE_DATA_DIALOG = 'CLEAR_SAMPLE_DATA_DIALOG';
export const SCHEDULER_GUIDE_WELCOME_DIALOG = 'SCHEDULER_GUIDE_WELCOME_DIALOG';
export const SCHEDULER_GUIDE_PUBLISH_CELEBRATE_DIALOG = 'SCHEDULER_GUIDE_PUBLISH_CELEBRATE_DIALOG';
export const DASHBOARD_GET_MOBILE_APP_DIALOG = 'DASHBOARD_GET_MOBILE_APP_DIALOG';
export const SCHEDULER_GUIDE_PUBLISH_PREVIEW_DIALOG = 'SCHEDULER_GUIDE_PUBLISH_PREVIEW_DIALOG';
export const INVITE_TRUSTED_USER_DIALOG = 'INVITE_TRUSTED_USER_DIALOG';
export const SETUP_YOUR_SCHEDULE_DIALOG = 'SETUP_YOUR_SCHEDULE_DIALOG';

const onboardingDialogs = {
  [SCHEDULER_GUIDE_WELCOME_DIALOG]: SchedulerGuideWelcomeDialog,
  [SCHEDULER_GUIDE_PUBLISH_CELEBRATE_DIALOG]: SchedulerGuidePublishCelebrateDialog,
  [DASHBOARD_GET_MOBILE_APP_DIALOG]: DashboardGetMobileAppDialog,
  [SCHEDULER_GUIDE_PUBLISH_PREVIEW_DIALOG]: SchedulerGuidePublishPreviewDialog,
  [INVITE_TRUSTED_USER_DIALOG]: InviteTrustedUserDialog,
  [SETUP_YOUR_SCHEDULE_DIALOG]: SetupYourScheduleDialog,
};

export default onboardingDialogs;
