import { updateAccount } from 'data/account/reducer';
import { addTransaction } from 'data/transaction/reducer';
import { request } from 'shared/auth/request';
import { UPDATE_BILLING } from 'store/action-types';

function updateBillingFailed(error) {
  return {
    type: UPDATE_BILLING.FAILURE,
    payload: error,
  };
}

function updateBillingRequested() {
  return {
    type: UPDATE_BILLING.REQUEST,
  };
}

function updateBillingSuccess(json) {
  return {
    type: UPDATE_BILLING.SUCCESS,
    payload: json,
  };
}

export function updateBilling(data) {
  return dispatch => {
    dispatch(updateBillingRequested());
    return request()
      .post('/billing', data)
      .then(
        response => {
          dispatch(updateBillingSuccess(response));
          dispatch(updateAccount(response.account));
          if (response.transactions) {
            dispatch(addTransaction(response.transactions[0]));
          }
        },
        error => {
          dispatch(updateBillingFailed(error.body));
          throw error;
        },
      );
  };
}
