import { DEFAULT_SORT, type EmployeeSortableType } from 'attendance/modules/tracker/util/constants';
import {
  ATTENDANCE_DATE,
  ATTENDANCE_LOCATION_FILTER,
  ATTENDANCE_POSITIONS_FILTER,
} from 'attendance/modules/tracker/util/cookies';
import {
  getInitialLocation,
  getInitialPositionFilter,
  persistFilter,
} from 'attendance/modules/tracker/util/persistFilter';
import { authSlice } from 'data/auth/reducer';

import { createSlice } from '@reduxjs/toolkit';
import { Record } from 'immutable';
import Cookies from 'js-cookie';
import { uniq } from 'lodash';
import moment, { type Moment } from 'moment-timezone';

interface AttendanceFiltersState {
  location: number;
  positions?: string[];
}

interface AttendanceState {
  sidebarVisible: boolean;
  showFilteredEmployees: boolean;
  currentDate: Moment;
  filters: AttendanceFiltersState;
  employeeSort: EmployeeSortableType;
  showWages: boolean;
}

export class AttendanceFiltersInitialState extends Record<AttendanceFiltersState>({
  location: getInitialLocation(),
  positions: getInitialPositionFilter(),
}) {}

export class AttendanceInitialState extends Record<AttendanceState>({
  sidebarVisible: true,
  showFilteredEmployees: false,
  currentDate: moment(Cookies.get(ATTENDANCE_DATE)).startOf('day'),
  filters: new AttendanceFiltersInitialState(),
  employeeSort: DEFAULT_SORT,
  showWages: false,
}) {}

export const attendanceTrackerSlice = createSlice({
  name: 'timeTracker',
  initialState: new AttendanceInitialState(),
  reducers: {
    setCurrentDate: (state, { payload }: { payload: Moment }) => {
      Cookies.set(ATTENDANCE_DATE, payload.toISOString());
      return state.set('currentDate', payload);
    },
    toggleSidebar: state => {
      return state.update('sidebarVisible', value => !value);
    },
    updateLocationFilter: (state, { payload }: { payload: number }) => {
      Cookies.set(ATTENDANCE_LOCATION_FILTER, String(payload));
      return state.setIn(['filters', 'location'], payload);
    },
    updatePositionFilter: (state, { payload }: { payload: number[] }) => {
      const deduped = uniq(payload);
      persistFilter(ATTENDANCE_POSITIONS_FILTER, deduped);
      return state.setIn(['filters', 'positions'], payload);
    },
    toggleShowFilteredEmployees: state => {
      return state.update('showFilteredEmployees', value => !value);
    },
    changeEmployeeSort: (state, { payload }: { payload: EmployeeSortableType }) =>
      state.update('employeeSort', () => payload),
    toggleShowWages: state => {
      return state.update('showWages', value => !value);
    },
  },
  extraReducers: builder => {
    builder.addCase(authSlice.actions.receiveAuth, state => {
      return state.set('currentDate', moment(Cookies.get(ATTENDANCE_DATE)).startOf('day'));
    });
  },
});

export const {
  setCurrentDate,
  toggleSidebar,
  updateLocationFilter,
  updatePositionFilter,
  toggleShowFilteredEmployees,
  changeEmployeeSort,
  toggleShowWages,
} = attendanceTrackerSlice.actions;
export default attendanceTrackerSlice.reducer;
