import PropTypes from 'prop-types';
import { Component, createRef } from 'react';
import { createPortal } from 'react-dom';
import onClickOutside from 'react-onclickoutside';
import { connect } from 'react-redux';

import Absence from 'data/absence/model';
import { fetchUserMetadataIfNeeded, updateUserMetadata } from 'data/metadata/actions';
import { getUserMetadata } from 'data/metadata/selectors/selectors';
import { hideNotificationsDrawer } from 'data/notifications/actions';
import LocationCallout from 'data/notifications/callouts/location';
import {
  absencesSelector,
  getSearchedActionNotifications,
  getSearchedNotifications,
  getSearchedWaitingNotifications,
  messagesSelector,
  requestsSelector,
  shiftsSelector,
  swapsSelector,
  usersSelector,
} from 'data/notifications/selectors';
import { fetchOpenShiftRequests } from 'data/openshiftRequests/actions';
import OpenShiftRequests from 'data/openshiftRequests/model';
import Request from 'data/request/model';
import Swap from 'data/swap/model';
import User from 'data/user/model';
import AbsenceNotification from 'notifications/components/AbsenceNotification';
import OpenShiftApprovalNotification from 'notifications/components/OpenShiftApprovalNotification';
import VerifyLocation from 'notifications/containers/VerifyLocation';
import MessageAlert from './MessageAlert';
import NotificationSearch from './NotificationSearch';
import SectionManager from './SectionManager';
import SwapDrop from './SwapDrop';
import TimeoffRequest from './TimeoffRequest';
import UserApproval from './UserApproval';

import { DateTime } from 'luxon';

import 'notifications/styles/NotificationsDrawer.scss';

export class NotificationsDrawer extends Component {
  drawerRef = createRef();

  static propTypes = {
    hideNotificationsDrawer: PropTypes.func.isRequired,
    container: PropTypes.string,
    fetchUserMetadataIfNeeded: PropTypes.func.isRequired,
    notifications: PropTypes.object.isRequired,
    users: PropTypes.object.isRequired,
    requests: PropTypes.object.isRequired,
    absences: PropTypes.object.isRequired,
    swaps: PropTypes.object.isRequired,
    shifts: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    waiting: PropTypes.object.isRequired,
    messages: PropTypes.object.isRequired,
    updateUserMetadata: PropTypes.func.isRequired,
    fetchOpenShiftRequests: PropTypes.func.isRequired,
  };

  handleClickOutside = () => {
    this.props.hideNotificationsDrawer();
  };

  componentDidMount() {
    document.body.style.overflow = 'hidden';
    this.props.fetchUserMetadataIfNeeded();
    this.props.fetchOpenShiftRequests();
  }

  componentWillUnmount() {
    document.body.style.overflow = '';
  }

  renderTimeoffAction(request) {
    return (
      <TimeoffRequest key={`timeoff-${request.id}`} request={request} user={this.props.users.get(request.user_id)} />
    );
  }

  renderAbsenceAction(absence) {
    return (
      <AbsenceNotification
        key={`absence-${absence.id}`}
        absence={absence}
        creator={this.props.users.get(absence.asInt('creatorId'))}
        actionable={true}
      />
    );
  }

  renderSwapAction(swap) {
    return (
      <SwapDrop
        key={`swap-drop-${swap.id}`}
        swap={swap}
        shift={this.props.shifts.get(swap.shift_id)}
        user={this.props.users.get(swap.user_id)}
      />
    );
  }

  renderUserApprovalAction(user) {
    return <UserApproval key={`user-approval-${user.id}`} user={user} />;
  }

  renderMessageNotification(notification) {
    const message = this.props.messages.get(notification.message_id);
    if (!message) {
      return;
    }
    let accessory = null;
    if (notification.user_id) {
      accessory = this.props.users.get(notification.user_id);
    } else if (notification.request_id) {
      accessory = this.props.requests.get(notification.request_id);
    } else if (notification.swap_id) {
      accessory = this.props.requests.get(notification.swap_id);
    }
    return (
      <MessageAlert
        key={notification.id}
        message={message}
        accessory={accessory}
        creator={this.props.users.get(notification.creator_id)}
      />
    );
  }

  renderTimeoffNotification(notification) {
    const request = this.props.requests.get(notification.request_id);
    if (!request) {
      return;
    }
    return (
      <TimeoffRequest
        key={notification.id}
        notification={notification}
        request={request}
        user={this.props.users.get(request.user_id)}
        creator={this.props.users.get(notification.creator_id)}
      />
    );
  }

  renderSwapDropNotification(notification) {
    const swap = this.props.swaps.get(notification.swap_id);
    if (!swap) {
      return;
    }
    return (
      <SwapDrop
        key={notification.id}
        notification={notification}
        swap={swap}
        user={this.props.users.get(swap.user_id)}
        shift={this.props.shifts.get(swap.shift_id)}
        creator={this.props.users.get(notification.creator_id)}
      />
    );
  }

  renderUserApprovalNotification(notification) {
    const user = this.props.users.get(notification.user_id);
    if (!user) {
      return;
    }
    return <UserApproval key={notification.id} notification={notification} user={user} />;
  }

  renderOpenShiftApprovalNotification(request) {
    return (
      <OpenShiftApprovalNotification
        key={request.id}
        request={request}
        hideNotificationsDrawer={this.props.hideNotificationsDrawer}
      />
    );
  }

  renderAbsenceNotification(notification) {
    const absence = this.props.absences.get(notification.absence_id.toString());
    const shift = this.props.shifts.get(absence.asInt('shiftId'));

    if (shift?.mustLuxonDate('end_time') >= DateTime.now() && shift?.user_id === absence.asInt('userId')) {
      return null;
    }

    return (
      <AbsenceNotification
        key={notification.id}
        actionable={false}
        absence={absence}
        creator={this.props.users.get(notification.creator_id)}
      />
    );
  }

  renderVerifyLocation(callout) {
    return <VerifyLocation key="verify-location" type={callout.type} reference={callout.model} />;
  }

  getEntityComponents(entries) {
    return entries
      .map(entry => {
        switch (entry.constructor) {
          case Request:
            return this.renderTimeoffAction(entry);
          case Swap:
            return this.renderSwapAction(entry);
          case User:
            return this.renderUserApprovalAction(entry);
          case LocationCallout:
            return this.renderVerifyLocation(entry);
          case OpenShiftRequests:
            return this.renderOpenShiftApprovalNotification(entry);
          case Absence:
            return this.renderAbsenceAction(entry);
        }
      })
      .toArray();
  }

  renderActionItems() {
    return this.getEntityComponents(this.props.actions).filter(item => !!item);
  }

  renderWaitingItems() {
    return this.getEntityComponents(this.props.waiting).filter(item => !!item);
  }

  renderNotificationItems() {
    return this.props.notifications
      .map(notification => {
        if (notification.message_id) {
          return this.renderMessageNotification(notification);
        }
        if (notification.request_id) {
          return this.renderTimeoffNotification(notification);
        }
        if (notification.swap_id) {
          return this.renderSwapDropNotification(notification);
        }
        if (notification.user_id) {
          return this.renderUserApprovalNotification(notification);
        }
        if (notification.absence_id) {
          return this.renderAbsenceNotification(notification);
        }
      })
      .filter(item => !!item)
      .toArray();
  }

  render() {
    const container = document.getElementById(this.props.container);
    const actionItems = this.renderActionItems();
    const waitingItems = this.renderWaitingItems();
    const notificationItems = this.renderNotificationItems();

    const drawer = (
      <div ref={this.drawerRef} id="notifications-drawer" className="notifications-drawer">
        <div className="drawer-body">
          <div className="drawer-header">
            <NotificationSearch />
          </div>
          <SectionManager actions={actionItems} waiting={waitingItems} notifications={notificationItems} />
        </div>
      </div>
    );
    return container ? createPortal(drawer, container) : drawer;
  }
}

export default connect(
  state => ({
    notifications: getSearchedNotifications(state),
    users: usersSelector(state),
    requests: requestsSelector(state),
    absences: absencesSelector(state),
    swaps: swapsSelector(state),
    shifts: shiftsSelector(state),
    messages: messagesSelector(state),
    actions: getSearchedActionNotifications(state),
    waiting: getSearchedWaitingNotifications(state),
    userMetadata: getUserMetadata(state),
  }),
  {
    fetchUserMetadataIfNeeded,
    hideNotificationsDrawer,
    updateUserMetadata,
    fetchOpenShiftRequests,
  },
)(onClickOutside(NotificationsDrawer));
