import { fetchCurrentAccount } from 'data/account/actions/fetchCurrentAccount';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';

import { type Middleware, isAsyncThunkAction, isRejectedWithValue } from '@reduxjs/toolkit';

export const updateAccountStatusOnDisabled: Middleware = api => next => action => {
  if (!isAsyncThunkAction(action)) {
    return next(action);
  }
  if (isRejectedWithValue(action) || action.type.includes('_FAILURE')) {
    const normalizedError = parseAPIError(action.payload);
    if (normalizedError.code === 5551) {
      // @ts-ignore - this is a thunk
      api.dispatch(fetchCurrentAccount());
    }
  }

  return next(action);
};
