import { getAuthAccount } from 'shared/auth/selectors';
import type { WiwState } from 'store';

import { createSelector } from '@reduxjs/toolkit';

export const getCustomReportBuilderAccountId = (state: WiwState): number | undefined =>
  state.reporting.customReportBuilder.accountId;

export const getReportAccountId = createSelector(
  [getAuthAccount, getCustomReportBuilderAccountId],
  (authAccount, reportAccountId) => {
    if (reportAccountId == null) {
      return authAccount.id;
    }

    return reportAccountId;
  },
);
