import { Fragment, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { fetchAccountMetadataIfNeeded, fetchUserMetadataIfNeeded, updateUserMetadata } from 'data/metadata/actions';
import ProgressButton from 'onboarding/components/nav/ProgressButton';
import { ATTENDANCE_CHECKLIST_STEPS } from 'onboarding/constants';
import { ONBOARDING_ATTENDANCE_CHECKLIST_CLOSED } from 'onboarding/metadataConstants';
import {
  getOnboardingAttendanceChecklistActiveStep,
  getOnboardingAttendanceChecklistAllStepsCompleted,
  getOnboardingAttendanceChecklistClosed,
  getOnboardingIsRecentAccount,
  getOnboardingMigratedAndLoaded,
  getOnboardingTimesheetEdited,
  getOnboardingTimesheetExported,
  getOnboardingUserCreated,
} from 'onboarding/selectors';
import { track } from 'onboarding/utils/tracking';
import { Actions } from 'shared/experiment/constants';
import { mobileMaxWidth } from 'shared/ui/variables';
import { useWiwDispatch, useWiwSelector } from 'store';

import { useMediaQuery } from 'usehooks-ts';

const AttendanceProgressMenuItem = () => {
  const dispatch = useWiwDispatch();
  const location = useLocation();
  const mobile = useMediaQuery(mobileMaxWidth);

  const activeStep = useWiwSelector(getOnboardingAttendanceChecklistActiveStep);
  const allStepsCompleted = useWiwSelector(getOnboardingAttendanceChecklistAllStepsCompleted);
  const checklistClosed = useWiwSelector(getOnboardingAttendanceChecklistClosed);
  const isRecentAccount = useWiwSelector(getOnboardingIsRecentAccount);
  const migratedAndLoaded = useWiwSelector(getOnboardingMigratedAndLoaded);
  const timesheetEdited = useWiwSelector(getOnboardingTimesheetEdited);
  const timesheetExported = useWiwSelector(getOnboardingTimesheetExported);
  const userCreated = useWiwSelector(getOnboardingUserCreated);
  const isTimesheets = location.pathname.match(/^\/timesheets(\/)?$/i);
  const toggleProgressMenu = migratedAndLoaded && isRecentAccount && checklistClosed && isTimesheets;

  useEffect(() => {
    dispatch(fetchAccountMetadataIfNeeded());
    dispatch(fetchUserMetadataIfNeeded());
  }, []);

  const percentComplete = () => {
    const count = (timesheetEdited ? 1 : 0) + (timesheetExported ? 1 : 0) + (userCreated ? 1 : 0);

    return Math.round((count / Object.entries(ATTENDANCE_CHECKLIST_STEPS).length) * 100);
  };

  const shouldShow = () => {
    if (mobile) {
      return false;
    }
    if (toggleProgressMenu) {
      return !allStepsCompleted;
    }
    return false;
  };

  const handleContinueClick = () => {
    dispatch(
      updateUserMetadata({
        [ONBOARDING_ATTENDANCE_CHECKLIST_CLOSED]: false,
      }),
    );

    track({
      action: Actions.CLICKED_PROGRESS_MENU,
      currentStep: activeStep,
      target: 'navBarAttendance',
    });
  };

  return (
    <Fragment>
      {shouldShow() ? (
        <ProgressButton
          handleContinueClick={handleContinueClick}
          percentComplete={percentComplete()}
          text="Build Your First Timesheet"
        />
      ) : null}
    </Fragment>
  );
};

export default AttendanceProgressMenuItem;
