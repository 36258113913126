import { Fragment, type ReactNode, useEffect, useState } from 'react';

import { useLDFlag } from 'data/LD/selectors/getLDFlag';
import { fetchTosAckRequired } from 'data/tos/actions/fetchTosAckRequired';
import { getTosAckRequired } from 'data/tos/selectors/getTosAckRequired';
import { getTosLoaded } from 'data/tos/selectors/getTosLoaded';
import { openDialog } from 'dialogs';
import { getAuthUser } from 'shared/auth/selectors';
import { TOS_ACKNOWLEDGEMENT_DIALOG } from 'shared/dialogs';
import { useWiwDispatch, useWiwSelector } from 'store';

export default function TermsOfServiceGate({ children }: { children: ReactNode }) {
  const dispatch = useWiwDispatch();

  const pay553TermsOfService = useLDFlag('pay-553-terms-of-service');
  const authUser = useWiwSelector(getAuthUser);
  const tosLoaded = useWiwSelector(getTosLoaded);
  const tosAckRequired = useWiwSelector(getTosAckRequired);
  const [acceptClicked, setAcceptClicked] = useState(false);

  useEffect(() => {
    if (!!authUser.login_id && pay553TermsOfService && !tosLoaded) {
      dispatch(fetchTosAckRequired());
    }
  }, [authUser, pay553TermsOfService, tosLoaded, tosAckRequired]);

  // Only show dialog if the ToS Acknowledgement is required
  if (!!authUser.login_id && pay553TermsOfService && tosAckRequired && tosLoaded && !acceptClicked) {
    dispatch(
      openDialog(TOS_ACKNOWLEDGEMENT_DIALOG, {
        acceptCallback: () => setAcceptClicked(true),
      }),
    );
    return <Fragment />;
  }

  return <Fragment>{children}</Fragment>;
}
