import BaseModel from 'data/BaseModel';

export interface LocationFields {
  id: number;
  account_id: number;
  name: string;
  address: string | null;
  coordinates: [number, number] | null;
  is_default: number;
  is_deleted: boolean;
  latitude: number | null;
  longitude: number | null;
  ip_address: string | null;
  max_hours: number;
  place: object | null;
  place_confirmed: boolean;
  place_id: number; // 0 is "null"
  sort: number;
  created_at: string;
  updated_at: string;
}

class Location extends BaseModel<LocationFields>({
  id: 0,
  account_id: 0,
  name: '',
  address: null,
  coordinates: null,
  is_default: 0,
  is_deleted: false,
  latitude: null,
  longitude: null,
  ip_address: null,
  max_hours: 0, // Cannot be null.
  place: null,
  place_confirmed: false,
  place_id: 0,
  sort: 0,
  created_at: '',
  updated_at: '',
}) {}

export default Location;
