import AnnotationState from 'data/annotation/state';
import { DELETE_ANNOTATION, FETCH_ANNOTATIONS, RESET_SCHEDULER_STATE, UPDATE_ANNOTATION } from 'store/action-types';
import Annotation from './model';

export default function (state = new AnnotationState(), action) {
  switch (action.type) {
    case FETCH_ANNOTATIONS.FAILURE:
      return state.merge({
        loaded: false,
        loading: false,
        error: action.payload,
      });
    case FETCH_ANNOTATIONS.REQUEST:
      return state.merge({
        loaded: false,
        loading: true,
        error: null,
      });
    case FETCH_ANNOTATIONS.SUCCESS:
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        items: state.items.merge(action.items.map(annotation => [annotation.id, new Annotation(annotation)])),
      });
    case UPDATE_ANNOTATION:
      return state.merge({
        items: state.items
          .delete(null)
          .merge(action.payload.map(annotation => [annotation.id, new Annotation(annotation)])),
      });
    case DELETE_ANNOTATION.SUCCESS:
      return state.deleteIn(['items', action.payload.id]);
    case RESET_SCHEDULER_STATE:
      return new AnnotationState();
    default:
      return state;
  }
}
