import classNames from 'classnames';
import { type Ref, forwardRef } from 'react';
import { type ColorResult, GithubPicker } from 'react-color';
import { type FieldValues, type UseControllerProps, useController } from 'react-hook-form';

import useMapCustomValidators from 'shared/form/hooks/useMapCustomValidators';
import type { InputProps } from 'shared/form/types';
import Button from 'shared/ui/Button';
import { colors } from 'shared/ui/ColorPicker';
import FontIcon from 'shared/ui/FontIcon';
import Popover from 'shared/ui/Popover';
import { getTextColor } from 'shared/util/color';

import { ColorSwatch, type InputBaseProps as MantineBaseInputProps, TextInput } from '@mantine/core';
import { useDisclosure, useMergedRef } from '@mantine/hooks';

import 'shared/form/styles/ColorPicker.scss';

export type ColorInputProps<T extends FieldValues> = InputProps<UseControllerProps<T>> &
  Omit<MantineBaseInputProps, 'value' | 'defaultValue'>;

const DEFAULT_COLOR = 'cccccc';

export default forwardRef(function ColorInput<T extends FieldValues>(
  { name, control, defaultValue, shouldUnregister, onChange, ...props }: ColorInputProps<T>,
  ref: Ref<HTMLInputElement>,
) {
  const rules = useMapCustomValidators(props, true);
  const [opened, { close, toggle }] = useDisclosure(false);

  const {
    field: { value, onChange: fieldOnChange, ref: insideRef, ...field },
  } = useController<T>({
    name,
    control,
    defaultValue,
    rules,
    shouldUnregister,
  });
  const mergedRef = useMergedRef(insideRef, ref);

  const showTooltip = (color: ColorResult, event: any) => {
    const target = event.target!;
    // @ts-ignore
    const colorName = colors[color.hex];
    if (!colorName) {
      return null;
    }
    // IE-11 doesn't support adding multiple classes in a single .add() call
    target.classList.add('hint--bottom');
    target.classList.add(`hint--${colorName}`);
    target.classList.add(`hint--text-${getTextColor(color.hex.replace('#', ''))}`);
    target.setAttribute('aria-label', colorName);
    target.removeAttribute('title');
  };

  const backgroundColor = () => {
    return `#${value}`;
  };

  return (
    <Popover withArrow width={340} opened={opened} onChange={toggle}>
      <Popover.Target>
        <TextInput
          autoComplete="off"
          type="text"
          {...field}
          {...props}
          className={classNames('color-picker-input', props.className)}
          readOnly
          onClick={toggle}
          ref={mergedRef}
          // @ts-ignore
          value={colors[backgroundColor()]}
          leftSection={<ColorSwatch withShadow={false} color={backgroundColor()} size="20px" />}
          rightSection={<FontIcon icon="chevron-down" />}
          rightSectionPointerEvents="none"
        />
      </Popover.Target>
      <Popover.Dropdown className="color-picker-pallet">
        <GithubPicker
          triangle="hide"
          color={`#${value || DEFAULT_COLOR}`}
          colors={Object.keys(colors).slice(0, -1)} // Remove the last color which is the default color
          width="337px"
          onChange={({ hex }) => {
            const value = hex.slice(1);
            fieldOnChange(value);
            onChange?.(name, value);
            close();
          }}
          onSwatchHover={showTooltip}
        />
        <Button
          color="secondary"
          className="full-width col-12 mt-1"
          size="sm"
          onClick={() => {
            fieldOnChange(DEFAULT_COLOR);
            close();
          }}
        >
          Clear Color
        </Button>
      </Popover.Dropdown>
    </Popover>
  );
});
