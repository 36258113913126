import type { WiwDispatch } from 'store';
import { TOGGLE_SCHEDULER_ONBOARDING_HIGHLIGHT } from 'store/action-types';

export function toggleSchedulerOnboardingHighlight(element: string, highlight: boolean | null = null) {
  return (dispatch: WiwDispatch) => {
    return dispatch({
      type: TOGGLE_SCHEDULER_ONBOARDING_HIGHLIGHT,
      payload: {
        element: element,
        highlight: highlight,
      },
    });
  };
}
