import type { BalancesResponse } from 'data/timeOffAccrual/types/common';
import type { DataResponse, QueryString } from 'data/types';
import Scheduling from 'shared/api/scheduling';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

interface FetchBalancesForUserArgs {
  userId: string;
  params?: QueryString;
}

export const fetchBalancesForUser = createWiwAsyncThunk<DataResponse<BalancesResponse>, FetchBalancesForUserArgs>(
  'timeOff/balances/fetchBalancesForUser',
  async ({ userId, params = {} }, { rejectWithValue }) => {
    try {
      return await Scheduling.query(params).get(`/timeoff/balances/${userId}`);
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
