import emojis from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { useEffect, useRef } from 'react';
import type { Emoji } from 'workchat/v2/constants';

type EmojiPicker = {
  onEmojiSelect?: (emoji: Emoji) => void;
};

export default function EmojiPicker({ onEmojiSelect }: EmojiPicker) {
  const ref = useRef(null);

  const findShadowRootElement = (element: Element): ShadowRoot | null => {
    if (!element) {
      return null;
    }

    // Check if the current element has a shadow root
    if (element.shadowRoot) {
      return element.shadowRoot;
    }

    // Recursively search for shadow root in children
    for (const child of Array.from(element.children)) {
      const shadowRoot = findShadowRootElement(child);
      if (shadowRoot) {
        return shadowRoot;
      }
    }

    return null;
  };

  useEffect(() => {
    // Emoji-mart renders in an open shadow root, so we need to find the
    // shadow root and add the styles
    const shadowRoot: any = ref.current && findShadowRootElement(ref.current);

    if (!shadowRoot) {
      return;
    }

    // Add styles to the shadow root for font weight and padding
    const styles = document.createElement('style');
    styles.textContent = `
         #root {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            --font-family: "Source Sans Pro", sans-serif !important;
        }

        [data-theme="dark"] {
          & #nav button[aria-selected] {
            color: white;
          }

          & #nav .bar {
            background-color: white !important;
          }

          & .search input[type="search"]:focus {
            box-shadow: inset 0 0 0 1px white, 0 1px 3px rgba(65, 69, 73, .2);
          }

          & .menu input[type="radio"]:checked + .option {
            box-shadow: 0 0 0 2px white;
          }

          & .menu input[type="radio"] + .option:hover {
            background-color: #333;
          }
        }

        .category > .sticky.padding-small:first-child {
            padding-left: 0;
            font-weight: 600;
        }`;
    shadowRoot?.appendChild(styles);
  }, [ref]);

  return (
    <div ref={ref}>
      <Picker
        data={emojis}
        onEmojiSelect={onEmojiSelect}
        previewPosition="none"
        emojiButtonSize={32}
        emojiSize={28}
        maxFrequentRows={1}
        theme="dark"
        icons={'oultine'}
      />
    </div>
  );
}
