import type { TagListFields } from 'data/tag/TagList';
import Worktags from 'shared/api/worktags';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

import type { Moment } from 'moment-timezone';

type FetchShiftTagsBetweenArgs = {
  start: Moment;
  end: Moment;
};

type FetchShiftTagsBetweenResponse = {
  data:
    | {
        shifts?: TagListFields[];
      }
    | TagListFields[];
};

export const fetchShiftTagsBetween = createWiwAsyncThunk<FetchShiftTagsBetweenResponse, FetchShiftTagsBetweenArgs>(
  'tags/shiftTags/fetchBetween',
  async ({ start, end }, { rejectWithValue }) => {
    try {
      return (await Worktags.url('/shifts-range')
        .query({
          StartDate: start.clone().subtract(1, 'day').format(),
          EndDate: end.clone().add(1, 'day').format(),
        })
        .get()) as FetchShiftTagsBetweenResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
