import isStringWithSpecial from 'shared/form/validators/isStringWithSpecial';
import isEmail from './isEmail';
import isFullName from './isFullName';
import isInt from './isInt';
import isPhoneNumber from './isPhoneNumber';
import maxPrecision from './maxPrecision';

export default {
  isEmail,
  isFullName,
  isPhoneNumber,
  maxPrecision,
  isInt,
  isStringWithSpecial,
};
