import { OSA_REQUEST_FILTERS } from 'data/metadata/keys';
import { getUserMetadata } from 'data/metadata/selectors/selectors';

import { createSelector } from '@reduxjs/toolkit';
import { Map } from 'immutable';

export const getMetadataRequestFilters = createSelector(getUserMetadata, userMetadata => {
  const requestFilters = userMetadata.get(OSA_REQUEST_FILTERS);

  if (typeof requestFilters === 'string') {
    return new Map(JSON.parse(requestFilters));
  }

  return requestFilters && new Map(requestFilters);
});
