import { useDispatch } from 'react-redux';
import FontIcon from 'shared/ui/FontIcon';
import { popScreen } from 'workchat/actions';
import Header, { type HeaderButton } from 'workchat/containers/Header';

import { Fragment } from 'react';
import Button from 'shared/ui/Button';
import SpinningLoader from 'shared/ui/loaders/SpinningLoader';
import loading from 'workchat/assets/workchat-loading.svg';

interface LoadingProps {
  hideHeader?: boolean;
  errorLabel?: string;
  hasError?: boolean;
  onErrorRetry?: () => void;
}

export default function Loading({ hideHeader, errorLabel, hasError, onErrorRetry }: LoadingProps) {
  const dispatch = useDispatch();

  const buttons: HeaderButton[] = [
    {
      title: 'Back',
      action: () => dispatch(popScreen()),
      label: [<FontIcon className="mr-1" key="back" icon="chevron-left" />, 'Back'],
      side: 'left',
      color: 'secondary',
    },
  ];

  const renderBody = () => {
    if (hasError) {
      return (
        <Fragment>
          <div className="workchat-error-label mx-2 row align-items-center justify-content-center">{errorLabel}</div>
          {onErrorRetry && (
            <div className="mt-4 row align-items-center justify-content-center">
              <Button color="primary" className="workchat-error-retry-btn" onClick={() => onErrorRetry()}>
                Retry
              </Button>
            </div>
          )}
        </Fragment>
      );
    }

    return (
      <div className="row no-gutters w-100 align-items-center justify-content-center">
        <SpinningLoader className="spinning-loader" color="gray" height={25} width={25} />
        <span className="workchat-loading-label ml-2">Loading&hellip;</span>
      </div>
    );
  };

  return (
    <div className="loading-container">
      {!hideHeader && <Header buttons={buttons}>WorkChat</Header>}
      <div className="row no-gutters w-100 text-center">
        <div className="col-12 my-6">
          <img src={loading} alt="Loading" />
        </div>
        <div className="col-12 mt-6">{renderBody()}</div>
      </div>
    </div>
  );
}
