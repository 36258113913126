import { Fragment, memo, useEffect, useState } from 'react';

import { getAuthAccount, getAuthUser } from 'shared/auth/selectors';
import { useClickOutside } from 'shared/hooks/useClickOutside';
import FontIcon from 'shared/ui/FontIcon';
import { useWiwDispatch, useWiwSelector } from 'store';
import { track } from 'workchat/actions';
import AddParticipantsV2, { VIEW_ADD_PARTICIPANTS } from 'workchat/v2/components/AddParticipantsV2';
import ConversationDetailV2, { VIEW_CONVERSATION_DETAIL } from 'workchat/v2/components/ConversationDetailV2';
import ConversationListV2, {
  VIEW_CONVERSATION_LIST,
} from 'workchat/v2/components/ConversationListV2/ConversationListV2';
import LoadingV2, { VIEW_LOADING } from 'workchat/v2/components/LoadingV2';
import NewConversationV2, { VIEW_NEW_CONVERSATION } from 'workchat/v2/components/NewConversationV2';
import StartConversationV2, { VIEW_START_CONVERSATION } from 'workchat/v2/components/StartConversationV2';
import ViewConversationV2, { VIEW_CONVERSATION } from 'workchat/v2/components/ViewConversationV2';
import ViewReceiptsV2, { VIEW_RECEIPTS } from 'workchat/v2/components/ViewReceiptsV2';
import { ReactionPickerProvider } from 'workchat/v2/hooks/useReactionPickerProvider';
import { getUnreadCountForAllConversations } from 'workchat/v2/store/conversations/conversationsSelectors';
import { toggleShow } from 'workchat/v2/store/reducer';
import {
  getWorkchatFlags,
  getWorkchatSelectedConversationId,
  getWorkchatShow,
  getWorkchatView,
} from 'workchat/v2/store/selectors';

import 'workchat/styles/global.scss';

const DEFAULT_MAX_UNREAD_COUNT = 9;

export default memo(function WorkChatV2() {
  const dispatch = useWiwDispatch();
  const user = useWiwSelector(getAuthUser);
  const account = useWiwSelector(getAuthAccount);
  const view = useWiwSelector(getWorkchatView);

  const show = useWiwSelector(getWorkchatShow);
  const unread = useWiwSelector(getUnreadCountForAllConversations);
  const flags = useWiwSelector(getWorkchatFlags);
  const selectConversation = useWiwSelector(getWorkchatSelectedConversationId);

  // As per https://mantine.dev/hooks/use-click-outside/#multiple-nodes: will work only with useState, not useRef
  const [workchatContainer, setWorkchatContainer] = useState<HTMLDivElement | null>(null);
  const toastContainer = document.querySelector('.Toastify') as HTMLElement;
  const workchatButton = document.querySelector('#workchat-container > .toggle-dropdown') as HTMLElement;
  const elementsToIgnore = [workchatContainer, toastContainer, workchatButton];

  useClickOutside(() => dispatch(toggleShow(false)), null, elementsToIgnore);

  const enableWorkchat = account.settings.workchat.enabled && user.id > 0;

  useEffect(() => {
    if (!enableWorkchat) {
      return;
    }
  }, []);

  const toggleWorkchat = () => {
    const newShow = !show;
    dispatch(toggleShow(newShow));

    if (newShow) {
      const hasConversations = flags?.get('conversations');
      if (!hasConversations || selectConversation) {
        dispatch(track('WorkChat: Viewed Channel', { accessType: 'Channel List' }));
      } else {
        dispatch(track('WorkChat: Accessed', { accessType: 'Nav Bar' }));
      }
    }
  };

  const renderView = () => {
    switch (view) {
      case VIEW_CONVERSATION_LIST: {
        return <ConversationListV2 />;
      }
      case VIEW_NEW_CONVERSATION:
        return <NewConversationV2 />;
      case VIEW_START_CONVERSATION:
        return (
          <ReactionPickerProvider>
            <StartConversationV2 />
          </ReactionPickerProvider>
        );
      case VIEW_CONVERSATION: {
        return (
          <ReactionPickerProvider>
            <ViewConversationV2 showOpenInMessenger={false} />
          </ReactionPickerProvider>
        );
      }
      case VIEW_CONVERSATION_DETAIL: {
        return <ConversationDetailV2 />;
      }
      case VIEW_ADD_PARTICIPANTS: {
        return <AddParticipantsV2 />;
      }
      case VIEW_RECEIPTS: {
        return <ViewReceiptsV2 />;
      }
      case VIEW_LOADING: {
        return <LoadingV2 />;
      }
    }
  };

  return (
    <Fragment>
      <button
        type="button"
        aria-expanded={show}
        aria-haspopup="true"
        className={`toggle-dropdown ${show ? 'open' : ''}`}
        onClick={toggleWorkchat}
      >
        <FontIcon icon="workchat" srText={show ? 'Hide WorkChat' : 'Show WorkChat'} />
        {!!unread && (
          <div className="unread-count">
            <div>{unread <= DEFAULT_MAX_UNREAD_COUNT ? unread : `${DEFAULT_MAX_UNREAD_COUNT}+`}</div>
          </div>
        )}
        <span className="nav-item-label hide-label-tablet">WorkChat</span>
      </button>
      {show && (
        <div className="workchat-main dropdown-menu" ref={setWorkchatContainer}>
          {renderView()}
        </div>
      )}
    </Fragment>
  );
});
