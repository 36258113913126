import Store from 'workchat/store';

import { Map } from 'immutable';
import moment from 'moment-timezone';

function getUserData(userId, token) {
  return new Promise((resolve, reject) => {
    fetch(`${CONFIG.PLATFORM_SERVICE}/meta/user`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'W-Token': token,
        'W-UserId': userId,
      },
    })
      .then(response => {
        response
          .json()
          .then(parsed => resolve(parsed.data))
          .catch(err => reject(err));
      })
      .catch(err => reject(err));
  });
}

function setUserData(userId, token, values) {
  return new Promise((resolve, reject) => {
    fetch(`${CONFIG.PLATFORM_SERVICE}/meta/user`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'W-Token': token,
        'W-UserId': userId,
      },
      body: JSON.stringify(new Map(values).map(value => JSON.stringify(value)).toJSON()),
    })
      .then(response => {
        response
          .json()
          .then(parsed => resolve(parsed.data))
          .catch(err => reject(err));
      })
      .catch(err => reject(err));
  });
}

function trackLocalStorage(userId, accountId) {
  const storeKey = `workchatSplash${accountId}`;
  const splashInfo = Store.get(storeKey) || {};
  splashInfo[userId] = moment().format();
  Store.set(storeKey, splashInfo);
}

function isTrackedLocalStorage(userId, accountId) {
  const storeKey = `workchatSplash${accountId}`;
  const splashInfo = Store.get(storeKey) || {};
  return splashInfo?.[userId];
}

export function markSplashDisplayed(user, account, token) {
  // Save in local storage
  trackLocalStorage(user.id, account.id);

  // Persist in the metadata service
  return setUserData(user.id, token, { workChatWelcomeShown: true }).catch(err =>
    console.error('workchat error saving user metadata', err),
  );
}

export function shouldDisplaySplash(user, account, token) {
  if (!user.canManage()) {
    return Promise.resolve(false);
  }

  if (isTrackedLocalStorage(user.id, account.id)) {
    return Promise.resolve(false);
  }

  return getUserData(user.id, token)
    .then(data => {
      if (!(data instanceof Object)) {
        console.error('failed to load user metadata from metadata service, skipping workchat splash');
        return false;
      }
      if (data.user && 'workChatWelcomeShown' in data.user && JSON.parse(data.user.workChatWelcomeShown) !== false) {
        trackLocalStorage(user.id, account.id);
        return false;
      }
      return true;
    })
    .catch(err => {
      console.error('workchat error loading user metadata', err);
      return false;
    });
}
