import BaseModel from 'data/BaseModel';
import Shift from 'data/shift/model';
import type User from 'data/user/model';

import { Map } from 'immutable';
import moment from 'moment-timezone';

export interface OpenShiftRequestsUserRequest {
  id: number;
  user_id: number;
  created_at: string;
  deleted_at: string;
  start_time: string | null;
  end_time: string | null;
  is_trimmed: boolean | null;
  is_eligible: boolean | null;
  coverage_percent: number;
}

export interface OpenShiftRequestsFields {
  id: number | null;
  account_id: number | null;
  shift_id: number | null;
  creator_id: number | null;
  updater_id: number | null;
  status: number | null;
  created_at: string | null;
  updated_at: string | null;
  user_requests: OpenShiftRequestsUserRequest[];
  shift: Shift;
  is_shared: boolean | null;
}

export enum OpenShiftRequestsStatus {
  Pending = 0,
  Approved = 1,
  Canceled = 4,
  Expired = 5,
  Denied = 6,
}

export enum OpenShiftRequestsReduxDataNames {
  Statuses = 'openshift_statuses',
  Schedules = 'openshift_schedules',
}

export enum OpenShiftRequestsTabNames {
  Pending = 'pending',
  All = 'all',
}

class OpenShiftRequests extends BaseModel<OpenShiftRequestsFields>({
  id: null,
  account_id: null,
  shift_id: null,
  creator_id: null,
  updater_id: null,
  status: null,
  created_at: null,
  updated_at: null,
  user_requests: [],
  shift: {} as Shift,
  is_shared: null,
}) {
  static Status = {
    PENDING: OpenShiftRequestsStatus.Pending,
    APPROVED: OpenShiftRequestsStatus.Approved,
    CANCELED: OpenShiftRequestsStatus.Canceled,
    EXPIRED: OpenShiftRequestsStatus.Expired,
    DENIED: OpenShiftRequestsStatus.Denied,
  } as const;

  static ReduxDataNames = {
    STATUSES: OpenShiftRequestsReduxDataNames.Statuses,
    SCHEDULES: OpenShiftRequestsReduxDataNames.Schedules,
  } as const;

  static TabNames = {
    PENDING: OpenShiftRequestsTabNames.Pending,
    ALL: OpenShiftRequestsTabNames.All,
  } as const;

  _userRequests: Map<OpenShiftRequestsUserRequest['user_id'], OpenShiftRequestsUserRequest>;

  constructor(args: Partial<OpenShiftRequestsFields>) {
    super(args);

    this._userRequests = args.user_requests
      ? Map(args.user_requests.map(request => [request.user_id, request]))
      : Map();
  }

  get statusName() {
    switch (this.status) {
      case OpenShiftRequests.Status.PENDING:
        return 'pending approval';
      case OpenShiftRequests.Status.APPROVED:
        return 'approved';
      case OpenShiftRequests.Status.CANCELED:
        return 'canceled';
      case OpenShiftRequests.Status.DENIED:
        return 'denied';
      case OpenShiftRequests.Status.EXPIRED:
        return 'expired';
      default:
        return 'expired';
    }
  }

  get statusColor() {
    switch (this.status) {
      case OpenShiftRequestsStatus.Pending:
        return 'yellow';
      case OpenShiftRequestsStatus.Approved:
        return 'green';
      default:
        return 'red';
    }
  }

  displayRequestStatus(actor: User, viewer: User) {
    switch (this.status) {
      case OpenShiftRequestsStatus.Pending:
        return 'from management';
      case OpenShiftRequestsStatus.Canceled:
        return '';
      case OpenShiftRequestsStatus.Denied:
        return viewer?.isEmployee() ? 'by management' : `by ${actor ? actor.fullName : 'management'}`;
      case OpenShiftRequestsStatus.Approved:
        return `by ${actor ? actor.fullName : 'management'}`;
      case OpenShiftRequestsStatus.Expired:
        return this.shift?.start_time ? `on ${moment(this.shift.start_time).format('dddd, MMMM D')}` : '';
      default:
        return 'by management';
    }
  }

  get userRequests(): Map<OpenShiftRequestsUserRequest['user_id'], OpenShiftRequestsUserRequest> {
    return this._userRequests || Map();
  }

  get shiftModel() {
    return new Shift({
      ...this.shift,
      requires_openshift_approval: true,
      // A shift has to be published before employees can ask to take it,
      // so we're safe asserting that the ID is not null.
      openshift_approval_request_id: this.id!,
    });
  }

  static statusNameOption(statusId: OpenShiftRequestsStatus) {
    switch (statusId) {
      case OpenShiftRequestsStatus.Pending:
        return { name: 'Pending Approval', color: 'C59A27' };
      case OpenShiftRequestsStatus.Approved:
        return { name: 'Approved', color: '51A33D' };
      case OpenShiftRequestsStatus.Expired:
        return { name: 'Expired', color: 'D0404B' };
      case OpenShiftRequestsStatus.Denied:
        return { name: 'Denied', color: 'D0404B' };
      case OpenShiftRequestsStatus.Canceled:
        return { name: 'Canceled', color: 'D0404B' };
    }
  }
}

export default OpenShiftRequests;
