import { createBulkRequests } from 'data/request/actions/createBulkRequests';
import { createRequest } from 'data/request/actions/createRequest';
import { fetchRequest, fetchRequests } from 'data/request/actions/fetchRequests';
import { updateRequest } from 'data/request/actions/updateRequest';
import Request from 'data/request/model';
import RequestState from 'data/request/state';
import generateThunkReducers from 'data/util/generateThunkReducers';
import { optimisticallyUpdateEntity } from 'data/util/optimisticUpdate';
import { mergeEntityItems, replaceEntityItems } from 'data/util/sliceHelpers';
import { MOVE_SHIFT_TIMEZONES } from 'store/action-types';

import { createSlice } from '@reduxjs/toolkit';

const initialState = new RequestState();

export const RequestSlice = createSlice({
  name: 'request',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(createRequest.fulfilled, (state, action) => {
      return mergeEntityItems(state, action.payload.request, Request);
    });
    builder.addCase(createBulkRequests.fulfilled, (state, action) => {
      return mergeEntityItems(state, action.payload.requests, Request);
    });
    builder.addCase(fetchRequests.fulfilled, (state, action) => {
      if (action.meta.arg.clearStore) {
        return replaceEntityItems(state, action.payload.requests, Request);
      }
      return mergeEntityItems(state, action.payload.requests, Request);
    });
    builder.addCase(fetchRequest.fulfilled, (state, action) => {
      return mergeEntityItems(state, action.payload.request, Request);
    });
    // for timezone toggling
    builder.addCase(MOVE_SHIFT_TIMEZONES, (state, action) => {
      return state.merge({
        items: state.items.merge(
          state.items.map(request => {
            return request.merge({
              // @ts-ignore
              start_time: request.date('start_time').tz(action.payload).format(),
              // @ts-ignore
              end_time: request.date('end_time').tz(action.payload).format(),
            });
          }),
        ),
      });
    });
    optimisticallyUpdateEntity(builder, updateRequest, Request, { payloadPath: 'request' });
    generateThunkReducers(builder, 'request');
  },
});

export default RequestSlice.reducer;
