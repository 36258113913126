import EntityState from 'data/EntityState';
import { fetchSalariedWages } from 'data/salariedWages/actions/fetchSalariedWages';
import SalariedWageRecord from 'data/salariedWages/models';
import { mergeEntityItems } from 'data/util/sliceHelpers';

import { createSlice } from '@reduxjs/toolkit';

const initialState = new EntityState<SalariedWageRecord>();

export const salariedWagesReducer = createSlice({
  name: 'salariedWages',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchSalariedWages.fulfilled, (state, action) => {
      return mergeEntityItems(state, action.payload.rate, SalariedWageRecord, 'userId');
    });
  },
});

export default salariedWagesReducer.reducer;
