import type {
  FetchRequestTypesFailure,
  FetchRequestTypesRequest,
  FetchRequestTypesSuccess,
  RequestTypeAction,
  UpdateRequestTypes,
} from 'data/request-type/actions';
import RequestType from 'data/request-type/model';
import RequestTypeState from 'data/request-type/state';
import { FETCH_REQUEST_TYPES, UPDATE_REQUEST_TYPES, assertActionType } from 'store/action-types';

export default (state = new RequestTypeState(), action: RequestTypeAction) => {
  switch (action.type) {
    case FETCH_REQUEST_TYPES.REQUEST:
      assertActionType<FetchRequestTypesRequest>(action);
      return state.merge({
        loading: true,
        loaded: false,
        receivedAt: 0,
      });
    case FETCH_REQUEST_TYPES.SUCCESS:
      assertActionType<FetchRequestTypesSuccess>(action);
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        items: state.items.clear().add(action.items.map(type => new RequestType(type))),
        receivedAt: Date.now(),
      });
    case FETCH_REQUEST_TYPES.FAILURE:
      assertActionType<FetchRequestTypesFailure>(action);
      return state.merge({
        loaded: false,
        loading: false,
        error: action.payload,
      });
    case UPDATE_REQUEST_TYPES:
      assertActionType<UpdateRequestTypes>(action);
      return state.merge({
        loading: true,
      });
    default:
      return state;
  }
};
