import { Record } from 'immutable';

export interface TransactionFields {
  id: any | null; // TODO(types)
  account_id: number;
  plan_id: number;
  type: number;
  ident: any | null; // TODO(types)
  credit_name: any | null; // TODO(types)
  credit_number: any | null; // TODO(types)
  account_type: number;
  account_country_id: number;
  description: any | null; // TODO(types)
  amount: number;
  amount_taxable: number;
  tax_rate: number;
  tax_amount: number;
  trx_range: number;
  billing_address: any | null; // TODO(types)
  billing_state: any | null; // TODO(types)
  billing_country: number;
  billing_postcode: any | null; // TODO(types)
  referral_code: any | null; // TODO(types)
  ref_page: any | null; // TODO(types)
  coupon_code: any | null; // TODO(types)
  created_at: string | null;
  updated_at: string | null;
}

class Transaction extends Record<TransactionFields>({
  id: null,
  account_id: 0,
  plan_id: 0,
  type: 0,
  ident: null,
  credit_name: null,
  credit_number: null,
  account_type: 0,
  account_country_id: 0,
  description: null,
  amount: 0,
  amount_taxable: 0,
  tax_rate: 0,
  tax_amount: 0,
  trx_range: 0,
  billing_address: null,
  billing_state: null,
  billing_country: 0,
  billing_postcode: null,
  referral_code: null,
  ref_page: null,
  coupon_code: null,
  created_at: null,
  updated_at: null,
}) {}

export default Transaction;
