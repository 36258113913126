import type { ComponentResponse } from 'data/payrollCheck/models/components';
import Payroll from 'shared/api/payroll';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';

import { createAsyncThunk } from '@reduxjs/toolkit';

type FetchCompanyComponentArgs = {
  type: 'early_enrollment' | 'onboard' | 'previous_provider_access';
};

export const fetchEnrollmentComponent = createAsyncThunk<ComponentResponse, FetchCompanyComponentArgs>(
  'payrollCheck/fetchEmployeeComponent',
  async ({ type }, { rejectWithValue }) => {
    try {
      return await Payroll.url('/components/company').query({ type }).get();
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
