import type Shift from 'data/shift/model';
import type { WiwState } from 'store';

import { createSelector } from '@reduxjs/toolkit';
import type { Map, OrderedMap } from 'immutable';

export type ShiftsById = OrderedMap<number, Shift>;

export const shiftsLoaded = (state: WiwState) => state.data.shift.loaded && !state.data.shift.loading;

const getShiftItemsMainMapBase = (state: WiwState) => state.data.shift.items._mainMap;

// While there shouldn't be a null keyed shift in this Map, .delete(null) guarantees that in order to safely cast
// to Map<number, Shift>
export const getShiftItemsMainMap = createSelector(getShiftItemsMainMapBase, shifts => {
  return shifts.delete(null) as Map<number, Shift>;
});

export const getStateShifts = createSelector(getShiftItemsMainMap, shifts =>
  shifts.sortBy(shift => shift?.mustDate('start_time').unix()).toOrderedMap(),
);

export const getStateShiftIds = createSelector(getStateShifts, shifts => shifts.keySeq().toArray());
