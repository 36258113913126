import Plan from 'data/plan/model';
import Features from 'shared/features';

import { List, Map } from 'immutable';

export const defaultAttendanceFeatures = new List([
  {
    name: 'Mobile clock in with GPS enforcement',
    description: '',
    help_link: 'https://help.wheniwork.com/articles/clocking-in-and-out-computer/',
    keys: [Features.ATTENDANCE],
    available: true,
  },
  {
    name: 'Early clock in prevention',
    description: '',
    help_link: 'https://help.wheniwork.com/articles/restricting-when-employees-can-clock-in-computer/',
    keys: [Features.ATTENDANCE],
    available: true,
  },
  {
    name: 'Scheduling vs. Actual labor cost reporting',
    description: '',
    help_link: '',
    keys: [Features.ATTENDANCE],
    available: true,
  },
  {
    name: 'Overtime alerts',
    description: '',
    help_link: 'https://help.wheniwork.com/articles/setting-overtime-alerts-computer/',
    keys: [Features.ATTENDANCE],
    available: true,
  },
  {
    name: 'Auto-deduct unpaid breaks',
    description: '',
    help_link: 'https://help.wheniwork.com/articles/automatically-deduct-scheduled-breaks/',
    keys: [Features.ATTENDANCE],
    available: true,
  },
  {
    name: 'Clock in/out reminders',
    description: '',
    help_link: 'https://help.wheniwork.com/articles/adjusting-when-clock-in-out-reminders-are-sent-computer/',
    keys: [Features.ATTENDANCE],
    available: true,
  },
  {
    name: 'Custom timesheet export',
    description: '',
    help_link: 'https://help.wheniwork.com/articles/exporting-time-sheets-computer/',
    keys: [Features.ATTENDANCE],
    available: true,
  },
  {
    name: 'Integrations with Gusto, Paychex and more',
    description: '',
    help_link: 'https://help.wheniwork.com/articles/integrating-with-a-payroll-processor-computer/',
    keys: [Features.ATTENDANCE],
    available: true,
  },
  {
    name: 'Labor Reports',
    description:
      'Understand your business better by comparing scheduled to actual hours ' +
      'including compensation and overtime pay by employee, position and schedule.',
    help_link: 'https://help.wheniwork.com/articles/scheduling-tasks-computer/',
    keys: [Features.REPORTING],
    requires: ({ attendancePlan, schedulerPlan }) => {
      /* istanbul ignore next */
      return (
        attendancePlan?.hasFeature(Features.ATTENDANCE) &&
        (schedulerPlan.isProTier() || schedulerPlan.isEnterpriseTier())
      );
    },
    requires_copy: '(Requires Scheduling Pro or Enterprise)',
    new: true,
    available: false,
  },
]);

export const defaultAttendanceValueProps = new Map({
  [Plan.Tier.FREEMIUM]: new List([
    {
      name: 'Track time, control costs, and increase payroll efficiency.',
      description: '',
      help_link: '',
      keys: [Features.ATTENDANCE],
      available: true,
    },
  ]),
  [Plan.Tier.BASIC]: new List([
    {
      name: 'Track time, control costs, and increase payroll efficiency.',
      description: '',
      help_link: '',
      keys: [Features.ATTENDANCE],
      available: true,
    },
  ]),
  [Plan.Tier.PRO]: new List([
    {
      name: 'Track time, control costs, and increase payroll efficiency.',
      description: '',
      help_link: '',
      keys: [Features.ATTENDANCE],
      available: true,
    },
  ]),
  [Plan.Tier.ENTERPRISE]: new List([
    {
      name: 'Track time, control costs, and increase payroll efficiency.',
      description: '',
      help_link: '',
      keys: [Features.ATTENDANCE],
      available: true,
    },
  ]),
  [Plan.Tier.CUSTOM]: new List([
    {
      name: 'Track time, control costs, and increase payroll efficiency.',
      description: '',
      help_link: '',
      keys: [Features.ATTENDANCE],
      available: true,
    },
  ]),
  [Plan.Tier.LEGACY]: new List([
    {
      name: 'Track time, control costs, and increase payroll efficiency.',
      description: '',
      help_link: '',
      keys: [Features.ATTENDANCE],
      available: true,
    },
  ]),
});
