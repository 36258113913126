import type { TagFields } from 'data/tag/Tag';
import Worktags from 'shared/api/worktags';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type FetchTagResponse = {
  data: TagFields;
};

export const fetchTag = createWiwAsyncThunk<FetchTagResponse, any>(
  'tags/fetchSingle',
  async (tagId, { rejectWithValue }) => {
    try {
      return (await Worktags.url(`/tags/${tagId}`).get()) as FetchTagResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
