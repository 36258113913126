import type Shift from 'data/shift/model';
import type { ShiftFields } from 'data/shift/model';
import type { TagFields } from 'data/tag/Tag';
import { replaceShiftTaskListsStore } from 'data/tasks/actions/persistScheduledShiftTaskList';
import { updateSchedulerOnboardingForShift } from 'onboarding/actions';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
// todo: for the love of god please untangle this rats test as more things get migrated because wtf
import { createWiwAsyncThunk } from 'store';
import type ShiftTaskLists from 'tasks/components/ShiftTaskLists';

type UpdateShiftArgs = {
  shift: Shift;
  includeRepeating?: boolean | string;
};

type UpdateShiftResponse = {
  shift: ShiftFields;
  shiftchains?: ShiftFields[];
  tags?: TagFields['id'][];
  tasks?: ShiftTaskLists[];
  repeating_shifts?: ShiftFields[];
  taken_shift?: ShiftFields;
  assigned_shift_instances?: ShiftFields[];
  deleted?: number[];
};

export const updateShift = createWiwAsyncThunk<UpdateShiftResponse, UpdateShiftArgs>(
  'shift/update',
  async ({ shift, includeRepeating = false }, { rejectWithValue, dispatch }) => {
    try {
      const handleOpenShiftInstanceBackend = shift.user_id > 0 && shift.instances > 1;

      let query = {};

      if (includeRepeating) {
        query = { ...query, include_repeating_shifts_to: includeRepeating };
      }

      if (handleOpenShiftInstanceBackend) {
        query = { ...query, assign_openshift_instances: true };
      }
      const response = (await Monolith.url(`/shifts/${shift.id}`)
        .query(query)
        .put(shift.toJSON())) as UpdateShiftResponse;

      const { shift: resShift, tasks, taken_shift } = response;

      dispatch(updateSchedulerOnboardingForShift(resShift, true));

      if (tasks) {
        // If we're assigning an openshift instance via the backend, the tasks returned are for the taken_shift in the response
        if (handleOpenShiftInstanceBackend && taken_shift) {
          dispatch(replaceShiftTaskListsStore(tasks, taken_shift.id));
        } else {
          dispatch(replaceShiftTaskListsStore(tasks, shift.id));
        }
      }

      return response;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
