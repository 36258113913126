import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { getAuthAccount, getAuthUser } from 'shared/auth/selectors';
import { useWiwSelector } from 'store';

export default function GTMTracker() {
  window.dataLayer = window.dataLayer || [];

  const location = useLocation();
  const user = useWiwSelector(getAuthUser);
  const account = useWiwSelector(getAuthAccount);

  useEffect(() => {
    if (!user || !account) {
      return;
    }
    // no need to wait for GTM to load
    // GTM gracefully handles data being pushed to dataLayer before it's loaded.
    window.dataLayer.push({
      event: 'userAttributesSet',
      user: {
        id: user.id,
        title: user.roleName,
      },
      account: {
        id: account.id,
        name: account.name,
        expectedEmployees: account.refEmployees,
        plan: {
          id: account.plan_id,
        },
      },
    });
  }, [user?.id, account?.id, location]);

  return null;
}
