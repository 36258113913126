import { useState } from 'react';

import Tag from 'data/tag/Tag';
import { deleteTag } from 'data/tag/actions/tags/deleteTag';
import { persistTag } from 'data/tag/actions/tags/persistTag';
import { getTags } from 'data/tag/selectors';
import { Body, Dialog, Footer, Header } from 'dialogs';
import { useDialogContext } from 'dialogs/context';
import useNotice from 'notices/hooks/useNotice';
import { DEFAULT_ERROR_MESSAGE } from 'shared/api/util/errors';
import Form, { Input, useForm } from 'shared/form';
import Button from 'shared/ui/Button';
import { useWiwDispatch, useWiwSelector } from 'store';
import { confirmDelete } from 'tags/actions';

export const TAG_DIALOG = 'TAG_DIALOG';

type Props = {
  tagId?: string;
};

export default function TagDialog({ tagId }: Props) {
  const dispatch = useWiwDispatch();

  const tags = useWiwSelector(getTags);

  const { closeDialog } = useDialogContext();
  const { successNotice, errorNotice } = useNotice();

  const [tag, setTag] = useState(tags.get(tagId, new Tag()));

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      name: tag.name,
    },
  });

  const handleSubmit = (data: { name: string }) => {
    const finalData = tag.merge(data);

    return dispatch(persistTag(finalData))
      .unwrap()
      .then(() => {
        setTag(new Tag());
        closeDialog();
        const verb = tagId === null ? 'saved' : 'updated';
        successNotice(`Tag ${verb}.`);
      })
      .catch(err => {
        console.error(err);
        errorNotice(DEFAULT_ERROR_MESSAGE);
      });
  };

  const handleDelete = () => {
    dispatch(
      confirmDelete(tag.name, () => {
        dispatch(deleteTag(tag))
          .unwrap()
          .then(() => {
            closeDialog();
            successNotice('Tag deleted.');
          })
          .catch(() => {
            errorNotice(DEFAULT_ERROR_MESSAGE);
          });
      }),
    );
  };

  const isValid = form.formState.isValid;
  const isSubmitting = form.formState.isSubmitting;
  return (
    <Dialog>
      <Header onClose={closeDialog}>{tagId ? 'Edit' : 'Add'} Tag</Header>
      <div className="tag-form">
        <Form form={form} onSubmit={handleSubmit}>
          <Body>
            <div className="row">
              <div className="col">
                <Input
                  name="name"
                  label="Name"
                  width={12}
                  data-autofocus
                  validate={{
                    noDuplicateTagNames: (value: string) => {
                      const isDupe = tags.some(
                        (tag: Tag) => tag.name.toLowerCase() === value.toLowerCase() && tag.id !== tagId,
                      );
                      if (isDupe) {
                        return 'This tag already exists.';
                      }
                      return true;
                    },
                  }}
                  required
                />
              </div>
            </div>
          </Body>
          <Footer>
            {!!tagId && (
              <Button color="danger" disabled={!isValid || isSubmitting} onClick={handleDelete}>
                Delete
              </Button>
            )}
            <Button type="submit" disabled={!isValid} loading={isSubmitting}>
              {tagId ? 'Save' : 'Add'}
            </Button>
          </Footer>
        </Form>
      </div>
    </Dialog>
  );
}
