import BaseModel from 'data/BaseModel';
import type IndexedMap from 'data/IndexedMap';
import type Account from 'data/account/model';
import type Position from 'data/position/model';
import type { PositionFields } from 'data/position/model';
import type { ShiftChainFields } from 'data/shiftChain/model';
import type Site from 'data/site/model';
import type TemplateShift from 'data/templateshift/model';
import User, { type UserFields } from 'data/user/model';
import hash from 'object-hash';
import { DAY_RANGE, WEEK_RANGE } from 'scheduler/util/view';
import { getMomentDate, setYearMonthDay, shortTimeRange } from 'shared/util/time';

import { Map } from 'immutable';
import { DateTime } from 'luxon';
import moment, { type Moment } from 'moment-timezone';

export const DEFAULT_START = { hour: 9, minute: 0 };
export const DEFAULT_END = { hour: 17, minute: 0 };

export const SCHEDULED_BREAK_ERROR = {
  INVALID_RANGE: 'Invalid Range',
  INVALID_LENGTH: 'Invalid Length',
};

export const SHIFT_TYPE = {
  OPEN_SHIFTS: 'OpenShifts',
  ASSIGNED_SHIFTS: 'Assigned Shifts',
};

export type ScheduledBreak = {
  id: number | null;
  account_id: number;
  shift_id: number;
  start_time: string | null;
  end_time: string | null;
  length: number;
  paid: boolean;
  created_by: number;
  created_at: string;
  updated_by: string;
  updated_at: string;
  sort: number;

  // for possible custom break types in the future?
  // type_id: number | null;
};

export interface ShiftFields {
  id: number | null;
  account_id: number;
  user_id: UserFields['id'];
  creator_id: number;
  position_id: PositionFields['id'];
  location_id: number;
  site_id: number;
  repeats: number | boolean;
  chain: Partial<ShiftChainFields> | boolean | null;
  linked_users: number[] | null;
  shiftchain_key: string | boolean | null;
  start_time: Moment | string | null;
  end_time: Moment | string | null;
  color: any | null; // TODO(types)
  notes: any | null; // TODO(types)
  break_time: number;
  instances: number;
  is_open: any | null; // TODO(types)
  published: boolean;
  published_date: any | null; // TODO(types)
  created_at: string | null;
  updated_at: string | null;
  acknowledged: number;
  isPristine: boolean;
  requires_openshift_approval: boolean;
  openshift_approval_request_id: number;
  fromTemplate: any | null; // TODO(types)
  is_approved_without_time: boolean;
  is_shared: boolean;
  is_trimmed: number;
  original_start_time: Moment | string | null;
  original_end_time: Moment | string | null;

  parent_id: number | null; // Not persisted in DB, used only for auto-assign

  /** @deprecated */
  actionable: boolean;

  tasks: any | null; // TODO(types)
  tags: any | null; // TODO(types)
  breaks: ScheduledBreak[];
}

class Shift extends BaseModel<ShiftFields>({
  id: null,
  account_id: 0,
  user_id: 0,
  creator_id: 0,
  position_id: 0,
  location_id: 0,
  site_id: 0,
  repeats: 0,
  chain: null,
  linked_users: [],
  shiftchain_key: null,
  start_time: null,
  end_time: null,
  color: 'cccccc',
  notes: null,
  break_time: 0,
  instances: 1,
  is_open: null,
  published: false,
  published_date: null,
  created_at: null,
  updated_at: null,
  acknowledged: 0,
  isPristine: true,
  requires_openshift_approval: false,
  openshift_approval_request_id: 0,
  is_approved_without_time: false,
  fromTemplate: null,
  is_shared: false,
  is_trimmed: 0,
  actionable: false,
  tasks: null,
  tags: null,
  parent_id: null,
  original_start_time: null,
  original_end_time: null,
  breaks: [],
}) {
  // used to differentiate between schedule template shifts and normal shifts
  isTemplate(): this is TemplateShift {
    return false;
  }

  shortTimeRange() {
    return shortTimeRange(this.date('start_time'), this.date('end_time'));
  }

  formattedTimeRange() {
    const { startString, endString } = this.shortTimeRange();

    return `${startString} - ${endString}`;
  }

  isOpen() {
    return this.user_id === 0;
  }

  isAssigned() {
    return this.user_id !== 0;
  }

  isNew() {
    return this.id === null;
  }

  duration() {
    return Number.parseFloat((this.date('end_time')!.diff(this.date('start_time'), 'minutes', true) / 60).toFixed(3));
  }

  isRepeating() {
    return !!this.shiftchain_key || !!this.repeats;
  }

  isReplaceable() {
    if (this.isOpen()) {
      return false;
    }

    return this.published && this.date('end_time')!.isAfter(moment());
  }

  hasMultipleInstances() {
    return this.isOpen() && this.instances >= 2;
  }

  hash(tags: string[] = [], tasks: string[] = []) {
    return hash({
      start: this.date('start_time')!.unix(),
      end: this.date('end_time')!.unix(),
      pid: this.position_id,
      lid: this.location_id,
      sid: this.site_id,
      notes: this.notes,
      color: this.color,
      requires_openshift_approval: this.requires_openshift_approval,
      is_shared: this.is_shared,
      tags,
      tasks,
      breaks: this.breaks
        .toSorted((a, b) => a.sort - b.sort)
        .map(scheduledBreak => ({
          length: scheduledBreak.length,
          paid: scheduledBreak.paid,
          // this ensures that the start times will always be in the same format for hashing
          start_time: scheduledBreak.start_time && moment(scheduledBreak.start_time).toISOString(),
        })),
    });
  }

  /**
   * Checks whether this shift pretty much equals another shift. (Ignores
   * things like updated_at, parses dates where necessary, etc.)
   * @param  Shift shift
   * @return boolean
   */
  weakEquals(shift: Shift, skipFields: string[] = []) {
    const skip = ['created_at', 'updated_at', ...skipFields];
    const dates = ['start_time', 'end_time'];

    for (const field of this) {
      const fieldName = field[0];

      if (skip.includes(fieldName)) {
        continue;
      }

      const thisValue = field[1];
      const thatValue = shift.get(fieldName);

      if (dates.includes(field[0])) {
        // @ts-ignore
        if (!getMomentDate(thisValue).isSame(getMomentDate(thatValue))) {
          return false;
        }
      } else if (thisValue !== thatValue) {
        return false;
      }
    }

    return true;
  }

  /**
   * Get break time in minutes
   */
  getBreakTimeMinutes(): number | undefined {
    if (!this.break_time) {
      return;
    }

    return Math.round(this.break_time * 60);
  }

  getInstanceCount() {
    return this.instances || 1;
  }

  inProgress() {
    return this.mustDate('start_time').isBefore(moment()) && this.mustDate('end_time').isAfter(moment());
  }

  isShared(users: Map<UserFields['id'], User> | IndexedMap<User, 'id', readonly ['is_active', 'is_deleted']> = Map()) {
    if (this.isOpen()) {
      return this.is_shared;
    }

    return this.is_shared && !(users.get(this.user_id)?.get('locations') || []).includes(this.location_id);
  }

  isPrivate(currentUser: User, account: Account, users: Map<UserFields['id'], User> = Map()) {
    // If both privacy and shared openshifts are off, we can't have private shifts
    if (!account.getSettings('privacy.enabled', false) && !account.getSettings('schedule.shared_openshifts', false)) {
      return false;
    }

    if (currentUser.canManage()) {
      return false;
    }
    if (account.privacyEnabled() && !this.isShared(users)) {
      return !currentUser.canSupervise(this.location_id);
    }

    if (this.isShared(users)) {
      const shiftUser = users.get(this.user_id, new User());

      if (shiftUser.id !== 0 && shiftUser.id !== -1) {
        return currentUser.locations.some((locationId: number) => shiftUser.locations.includes(locationId));
      }
      // If there's no user assignd to the shift, check whether currentUser
      return !currentUser.locations.some((locationId: number) => this.location_id === locationId);
    }

    return false;
  }

  is24HoursOrPast() {
    if (this.mustLuxonDate('start_time') <= DateTime.now().plus({ days: 1 })) {
      return true;
    }

    return false;
  }

  isPast() {
    if (this.mustLuxonDate('end_time') <= DateTime.now()) {
      return true;
    }

    return false;
  }

  /**
   * @param action wiwDropEffect ('copy' or 'move')
   */
  createShiftFromDrag(
    action: string,
    start: Moment,
    user: User,
    position: Position | null = null,
    site: Site | null = null,
    currentRange = WEEK_RANGE,
  ) {
    let end: Moment;
    const diff = start.diff(this.date('start_time'));
    if (currentRange === DAY_RANGE) {
      end = this.date('end_time')!.clone().add(diff);
    } else {
      end = setYearMonthDay(this.mustDate('end_time'), start);
      if (end.isSameOrBefore(start)) {
        end.add(1, 'day');
      }
    }

    const newShiftData: Partial<ShiftFields> = {
      start_time: start.format(),
      end_time: end.format(),
      user_id: user.id,
      published: false,
    };

    newShiftData.is_open = user.id !== 0;

    if (position) {
      newShiftData.position_id = position.id;
    }

    if (site) {
      newShiftData.site_id = site.id;
    }

    if (action === 'copy') {
      // We need to create a new shift, not just update the existing one.
      // Setting the ID to null will make that happen when we persist it.
      newShiftData.id = null;
      newShiftData.instances = 1;
      newShiftData.openshift_approval_request_id = 0;
    } else if (this.hasMultipleInstances()) {
      newShiftData.is_open = true;
    }

    let merged = this.merge(newShiftData);

    // we have to do breaks after because of how `merge` changes JavaScript objects into Immutable.js objects which
    // causes issues when comparing to OpenShifts for merging purposes
    if (this.breaks.length > 0) {
      let updatedBreaks: { length: number; paid: boolean; start_time: string | null }[] = [];
      // During a drag and drop in day range, apply the same diff to any of the shifts scheduled breaks with a start
      if (currentRange === DAY_RANGE) {
        updatedBreaks = this.breaks
          .toSorted((a, b) => a.sort - b.sort)
          .map(scheduledBreak => {
            let start_time = scheduledBreak.start_time;
            if (start_time) {
              const breakStartTime = moment(scheduledBreak.start_time).add(diff);

              start_time = breakStartTime.toISOString();
            }

            return {
              length: scheduledBreak.length,
              paid: scheduledBreak.paid,
              start_time,
            };
          });
      } else {
        // whenever we move/copy a shift, we need to update the breaks so the date matches for validation to work
        updatedBreaks = this.breaks
          .toSorted((a, b) => a.sort - b.sort)
          .map(scheduledBreak => {
            let start_time = scheduledBreak.start_time;
            if (start_time) {
              const breakStartTime = moment(scheduledBreak.start_time).set({
                year: start.year(),
                month: start.month(),
                date: start.date(),
              });

              // if the break time is before the shift start, that likely means that the shift crosses midnight and the break
              // is on the other side of midnight
              if (breakStartTime.isBefore(start)) {
                breakStartTime.add(1, 'day');
              }

              start_time = breakStartTime.toISOString();
            }

            return {
              length: scheduledBreak.length,
              paid: scheduledBreak.paid,
              start_time,
            };
          });
      }

      // @ts-ignore we're technically breaking the type, but the shift is immediately persisted and hydrated after this
      merged = merged.set('breaks', updatedBreaks);
    }

    return merged;
  }

  // validate that the shifts scheduled breaks do not extend beyond the start and end time, or length of the shift
  validateScheduledBreaks() {
    if (this.breaks) {
      let totalBreakLength = 0;

      const shiftStart = this.mustDate('start_time');
      const shiftEnd = this.mustDate('end_time');

      for (const scheduledBreak of this.breaks) {
        totalBreakLength += scheduledBreak.length;

        if (scheduledBreak.start_time) {
          const breakStart = moment(scheduledBreak.start_time);
          const breakEnd = breakStart.clone().add(scheduledBreak.length, 'seconds');

          if (breakStart.isBefore(shiftStart) || breakEnd.isAfter(shiftEnd)) {
            return SCHEDULED_BREAK_ERROR.INVALID_RANGE;
          }
        }
      }

      if (totalBreakLength > shiftEnd.diff(shiftStart, 'seconds')) {
        return SCHEDULED_BREAK_ERROR.INVALID_LENGTH;
      }
    }

    return '';
  }

  get startDateKey() {
    return this.date('start_time')?.format('YYYY-MM-DD');
  }

  get isTemporaryInstance(): boolean {
    return this.id !== null && this.id < 0;
  }
}

export default Shift;
