import { setMomentTimeRange } from 'shared/util/time';

export const errorMessage = 'Please enter an unpaid break shorter than or equal to the shift duration.';

export default function isBreakTimeShorterThanOrEqualToShiftDuration(shift, values) {
  if (!values || !values.time || !values.time.start || !values.time.end) {
    return true;
  }
  // Set start and end times
  const { start, end } = setMomentTimeRange(shift.mustDate('start_time'), {
    start: values.time.start,
    end: values.time.end,
  });

  return !(start.isValid() && end.isValid() && end.diff(start, 'minutes') < values.break_time);
}
