import EntityState from 'data/EntityState';
import { applyTemplate } from 'data/shift/actions/applyTemplate';
import { createShift } from 'data/shift/actions/createShift';
import { updateShift } from 'data/shift/actions/updateShift';
import Shift from 'data/shift/model';
import TagList from 'data/tag/TagList';
import { fetchShiftTags } from 'data/tag/actions/shiftTags/fetchShiftTags';
import { fetchShiftTagsBetween } from 'data/tag/actions/shiftTags/fetchShiftTagsBetween';
import { getShiftTagsFromSessionStore } from 'data/tag/actions/shiftTags/shiftTagSessionStorage';
import { updateShiftTag } from 'data/tag/actions/shiftTags/updateShiftTag';
import generateThunkReducers from 'data/util/generateThunkReducers';
import { getDate as getLuxonDate } from 'shared/util/luxonTime';
import { toEntityMap } from 'shared/util/toEntityMap';

import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';

const initialState = new EntityState();

export const shiftTagSlice = createSlice({
  name: 'shiftTag',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchShiftTags.fulfilled, (state, action) => {
        return state.mergeDeep({
          items: state.items.merge(toEntityMap(action.payload.data, TagList, 'id', Number.parseInt)),
        });
      })
      .addCase(fetchShiftTagsBetween.fulfilled, (state, action) => {
        const data = action.payload.data;
        let payload = data;
        // @ts-ignore
        if (Array.isArray(data.shifts)) {
          // @ts-ignore
          payload = data.shifts;
        }
        let shiftTagsMap = toEntityMap<any, TagList>(payload, TagList, 'id', Number.parseInt);

        const localShiftTags = getShiftTagsFromSessionStore();
        for (const shiftId in localShiftTags) {
          if (getLuxonDate(localShiftTags[shiftId].expiration) > DateTime.now()) {
            shiftTagsMap = shiftTagsMap.set(Number.parseInt(shiftId), localShiftTags[shiftId].tagData);
          }
        }
        return state.mergeDeep({
          items: state.items.merge(shiftTagsMap),
        });
      })
      .addCase(updateShiftTag.fulfilled, (state, action) => {
        return state.mergeDeep({
          items: state.items.merge(toEntityMap(action.payload.data, TagList, 'id', Number.parseInt)),
        });
      })
      .addCase(applyTemplate.fulfilled, (state, action) => {
        if (!action.payload.tagIdsMap) {
          return state;
        }

        const newTagLists = [];
        for (const shift of action.payload.shifts) {
          newTagLists.push([
            shift.id,
            new TagList({
              id: shift.id.toString(),
              accountId: shift.account_id.toString(),
              tags: action.payload.tagIdsMap[shift.id],
              startDate: shift.start_time as string,
              createdAt: shift.created_at as string,
              updatedAt: shift.updated_at as string,
              deletedAt: shift.deleted_at,
            }),
          ]);
        }

        return state.mergeDeep({
          items: state.items.merge(newTagLists),
        });
      })
      .addMatcher(isAnyOf(createShift.fulfilled, updateShift.fulfilled), (state, action) => {
        if (action.payload.tags) {
          // @ts-ignore
          const shift = new Shift(action.payload.shift || action.payload.taken_shift);

          return state.mergeDeep({
            items: state.items.set(
              shift.id,
              new TagList({
                id: shift.id,
                accountId: shift.account_id.toString(),
                tags: action.payload.tags,
                startDate: shift.start_time as string,
              }),
            ),
          });
        }
        return state;
      });
    generateThunkReducers(builder, 'tags/shiftTags');
  },
});

export default shiftTagSlice.reducer;
