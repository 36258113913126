import { lazy } from 'react';

const ShiftTemplateDialog = lazy(() => import('./ShiftTemplateDialog'));

// Identifiers for each dialog
export const SHIFT_TEMPLATE_DIALOG = 'SHIFT_TEMPLATE_DIALOG';

const dialogs = {
  [SHIFT_TEMPLATE_DIALOG]: ShiftTemplateDialog,
  // ...
};

export default dialogs;

/* To open a dialog, do this
import { openDialog, closeDialog } from 'dialogs';
import { SHIFT_TEMPLATE_DIALOG } from 'templates/dialogs';
*/
