import Plan from 'data/plan/model';
import Features from 'shared/features';

import { List, Map } from 'immutable';

export const defaultSchedulerFeatures = new List([
  {
    name: 'Team Scheduling',
    description:
      'Easily create, manage, and share the work schedule with everyone in your workplace. ' +
      "Put the schedule in everyone's pocket with our iOS and Android apps.",
    help_link: 'https://help.wheniwork.com/article-categories/scheduling-your-team/',
    keys: [Features.SCHEDULING, Features.LONG_TERM_SCHEDULING],
    available: true,
  },
  {
    name: 'Team Member Availability',
    description: '',
    help_link: 'https://help.wheniwork.com/articles/setting-your-availability-computer/',
    keys: [Features.SCHEDULING, Features.LONG_TERM_SCHEDULING],
    available: true,
  },
  {
    name: 'Team Communication',
    description: '',
    help_link: 'https://help.wheniwork.com/article-categories/communicating-with-your-team/',
    keys: [Features.SCHEDULING, Features.LONG_TERM_SCHEDULING, Features.WORKCHAT],
    available: true,
  },
  {
    name: 'Remote Job Sites',
    description: '',
    help_link: 'https://help.wheniwork.com/articles/scheduling-shifts-at-job-sites-computer/',
    keys: [Features.SITES],
    available: true,
  },
  {
    name: '10+ Day Scheduling',
    description: '',
    help_link: '',
    keys: [Features.LONG_TERM_SCHEDULING],
    available: true,
  },
  {
    name: 'Schedule Multiple Teams',
    description: '',
    help_link: 'https://help.wheniwork.com/articles/creating-and-managing-schedules-computer/',
    keys: [Features.LOCATIONS],
    available: true,
  },
  {
    name: 'Unlimited User Cap',
    description: '',
    help_link: '',
    keys: [Features.UNLIMITED_USER_CAPACITY],
    available: true,
  },
  {
    name: 'Repeating Shifts',
    description: '',
    help_link: '',
    keys: [Features.LONG_TERM_SCHEDULING],
    available: true,
  },
  {
    name: 'Qualification Tags',
    description:
      'Make sure employees are qualified for the shifts they work by tracking additional eligibility requirements.',
    help_link: 'https://help.wheniwork.com/articles/creating-and-managing-tags-computer/',
    keys: [Features.WORKTAGS],
    available: true,
    new: true,
  },
  {
    name: 'Auto Schedule Shifts',
    description:
      'Schedule faster by auto scheduling shifts to employees based on availability,' +
      ' time off, and qualifications.',
    help_link: 'https://help.wheniwork.com/articles/scheduling-shifts-at-job-sites-computer/',
    keys: [Features.AUTO_ASSIGN],
    available: true,
  },
  {
    name: 'Flexible Self-Scheduling',
    description: 'Allow employees to pickup and release shifts to fill the shifts needed based on their preferences.',
    help_link: 'https://help.wheniwork.com/articles/shift-coverage-computer/',
    keys: [Features.SHIFT_RELEASE],
    new: true,
    available: true,
  },
  {
    name: 'OpenShift Requests',
    description:
      'Employees request to work OpenShifts they want to pick up;' +
      ' the manager reviews the requests and approves the most qualified user to work the shift.',
    help_link: 'https://help.wheniwork.com/articles/shift-bidding/',
    keys: [Features.OPENSHIFT_APPROVAL],
    available: true,
  },
  {
    name: 'Task Management',
    description: '',
    help_link: 'https://help.wheniwork.com/articles/scheduling-tasks-computer/',
    keys: [Features.TASKS],
    available: true,
  },
  {
    name: 'Document Storage',
    description: 'Keep the team on the same page with up to 50 documents, all accessible from the When I Work apps.',
    help_link: '#',
    keys: [Features.DOC_STORAGE],
    available: true,
  },
  {
    name: 'Labor Reports',
    description:
      'Understand your business better by comparing scheduled to actual hours ' +
      'including compensation and overtime pay by employee, position and schedule.',
    help_link: 'https://help.wheniwork.com/articles/scheduling-tasks-computer/',
    keys: [Features.REPORTING],
    requires: ({ attendancePlan, schedulerPlan }) => {
      return (
        attendancePlan?.hasFeature(Features.ATTENDANCE) &&
        (schedulerPlan.isProTier() || schedulerPlan.isEnterpriseTier())
      );
    },
    requires_copy: '(Requires Attendance)',
    available: true,
  },
  {
    name: 'Overtime Visibility',
    description: 'Manage planned overtime costs when reviewing the schedule, assigning shifts, or approving requests.',
    help_link: '',
    keys: [Features.OVERTIME_VISIBILITY],
    available: true,
  },
  {
    name: 'Access to API',
    description: '',
    help_link: 'https://help.wheniwork.com/articles/getting-access-to-the-when-i-work-api-computer/',
    keys: [Features.API],
    available: true,
  },
  {
    name: 'Dedicated Account Manager',
    description: '',
    help_link: 'https://help.wheniwork.com/articles/enabling-privacy-for-all-employees-web/',
    keys: [Features.ACCOUNT_REP],
    available: true,
  },
  {
    name: 'Labor sharing',
    description: '',
    help_link: 'https://help.wheniwork.com/articles/labor-sharing-reference-guide/',
    keys: [Features.SHARED_OPENSHIFTS],
    available: true,
    new: true,
  },
  {
    name: 'Single Sign On',
    description: '',
    help_link: '',
    keys: [Features.SSO, Features.SAML_SSO],
    available: true,
  },
  {
    name: 'Global Privacy',
    description: '',
    help_link: 'https://help.wheniwork.com/articles/enabling-privacy-for-all-employees-web/',
    keys: [Features.GLOBAL_PRIVACY],
    available: true,
  },
  {
    name: 'Personalized Onboarding',
    description: '',
    help_link: 'https://help.wheniwork.com/articles/enabling-privacy-for-all-employees-web/',
    keys: [Features.ACCOUNT_REP],
    available: true,
  },
]);

export const defaultSchedulerValueProps = new Map({
  [Plan.Tier.FREEMIUM]: new List([
    {
      name: 'Create your work schedule in minutes.',
      description: '',
      help_link: '',
      keys: [Features.SCHEDULING],
      available: true,
    },
  ]),
  [Plan.Tier.BASIC]: new List([
    {
      name: 'Create your work schedule in minutes.',
      description: '',
      help_link: '',
      keys: [Features.SCHEDULING],
      available: true,
    },
    {
      name: 'Communicate across multiple locations and remote job sites.',
      description: '',
      help_link: '',
      keys: [Features.SCHEDULING],
      available: true,
    },
    {
      name: 'Simplify getting shifts covered and managing time-off requests.',
      description: '',
      help_link: '',
      keys: [Features.SCHEDULING],
      available: true,
    },
  ]),
  [Plan.Tier.PRO]: new List([
    {
      name: 'Includes everything in Scheduling Basic.',
      description: '',
      help_link: '',
      keys: [Features.SCHEDULING],
      available: true,
    },
    {
      name: 'Automatically create your work schedule with one click.',
      description: '',
      help_link: '',
      keys: [Features.SCHEDULING],
      available: true,
    },
    {
      name: 'Boost productivity by assigning tasks and sharing documents.',
      description: '',
      help_link: '',
      keys: [Features.SCHEDULING],
      available: true,
    },
  ]),
  [Plan.Tier.ENTERPRISE]: new List([
    {
      name: 'Includes everything in Scheduling Pro.',
      description: '',
      help_link: '',
      keys: [Features.SCHEDULING],
      available: true,
    },
    {
      name: 'Get up and running quickly with personalized account onboarding.',
      description: '',
      help_link: '',
      keys: [Features.SCHEDULING],
      available: true,
    },
    {
      name: 'Get the most from When I Work with a dedicated account manager.',
      description: '',
      help_link: '',
      keys: [Features.SCHEDULING],
      available: true,
    },
  ]),
  [Plan.Tier.CUSTOM]: new List([
    {
      name: 'Create your work schedule in minutes.',
      description: '',
      help_link: '',
      keys: [Features.SCHEDULING],
      available: true,
    },
    {
      name: 'Communicate with your team.',
      description: '',
      help_link: '',
      keys: [Features.SCHEDULING],
      available: true,
    },
    {
      name: 'Simplify getting shifts covered and managing time-off requests.',
      description: '',
      help_link: '',
      keys: [Features.SCHEDULING],
      available: true,
    },
  ]),
  [Plan.Tier.LEGACY]: new List([
    {
      name: 'Create your work schedule in minutes.',
      description: '',
      help_link: '',
      keys: [Features.SCHEDULING],
      available: true,
    },
    {
      name: 'Communicate with your team.',
      description: '',
      help_link: '',
      keys: [Features.SCHEDULING],
      available: true,
    },
    {
      name: 'Simplify getting shifts covered and managing time-off requests.',
      description: '',
      help_link: '',
      keys: [Features.SCHEDULING],
      available: true,
    },
  ]),
});
