import Mercury from 'mercury-js';
import queryString from 'query-string';

export const ATTENDANCE_INTERACTION = 'attendance::onInteraction';
export const ATTENDANCE_ONBOARDING = 'attendance::onboarding';
export const ATTENDANCE_ONBOARDING_SETTINGS_UPDATE = 'attendance::onboardingSettingsUpdate';
export const ATTENDANCE_PUNCH_INTERACTION = 'engineering::punchInteraction';
export const DASHBOARD_INTERACTION = 'dashboard::onInteraction';
export const DOCS_INTERACTION = 'docs::onInteraction';
export const EMPLOYEES_INTERACTION = 'employees::onInteraction';
export const FEEDBACK_NON_RENEWALS = 'feedback::nonRenewals';
export const FEEDBACK_PRODUCT_SURVEY = 'feedback::productSurvey';
export const GROWTH_CONTACT_REQUESTS = 'growth::contactRequests';
export const GROWTH_ONBOARDING = 'growth::onboarding';
export const GROWTH_UPSELL = 'growth::upsellModal';
export const PEP_PROGRESSION_STATE = 'pep::progressionState';
export const PEP_INTERACTION = 'pep::onInteraction';
export const PRODUCT_ANNOUNCEMENT = 'product::announcement';
export const REPORTING_EXPORT = 'reporting::export';
export const REPORTING_INTERACTION = 'reporting::onInteraction';
export const REQUESTS_INTERACTION = 'requests::onInteraction';
export const ROLLOUT_ASSIGNMENTS = 'rollout::assignments';
export const EXPERIMENT_ASSIGNMENTS = 'experiment::assignments';
export const SCHEDULER_INTERACTION = 'scheduler::onInteraction';
export const WORKCHAT_INTERACTION = 'workchat::onInteraction';
export const TASKS_ONBOARDING = 'tasks::onboarding';
export const TASKS_USAGE = 'tasks::usage';
export const TEAMTXT_INTERACTION = 'teamTxt::onInteraction';
export const TIMESHEETS_INTERACTION = 'timesheets::onInteraction';
export const WEB_NON_RENEWAL_ACTIONS = 'web::nonRenewalActions';

export const track = (eventName, fields = {}, data = {}) => {
  if (typeof window.mercury !== 'undefined') {
    return window.mercury.track(eventName, fields, data);
  }
  return new Promise().resolve();
};

export const trackClick = (target = '', fields = {}, data = {}, eventName = 'web::onInteraction') => {
  if (typeof window.mercury !== 'undefined') {
    fields.target = target;
    return window.mercury.interaction(fields, data, eventName);
  }
  return new Promise().resolve();
};

export const trackPage = (fields = {}, data = {}, eventName = 'web::pageView') => {
  if (typeof window.mercury !== 'undefined') {
    window.mercury.page(fields, data, eventName);
  }
};

export const trackRollout = (jiraIssue, ldFlag, variation, data = {}, eventName = ROLLOUT_ASSIGNMENTS) => {
  if (typeof window.mercury !== 'undefined') {
    return window.mercury.track(
      eventName,
      {
        rolloutName: ldFlag,
        rolloutId: jiraIssue,
        variation: JSON.stringify(variation),
      },
      data,
    );
  }
  return new Promise().resolve();
};

export const trackExperiment = (jiraIssue, variation, eventNames = []) => {
  if (variation === undefined || variation === null || variation === '') {
    variation = 'variation missing';
  }

  if (typeof window.mercury !== 'undefined') {
    return window.mercury.track(
      EXPERIMENT_ASSIGNMENTS,
      {
        experimentId: jiraIssue,
        variation: JSON.stringify(variation),
        eventNames: JSON.stringify(eventNames),
      },
      {},
    );
  }
  return new Promise().resolve();
};

export const formatQueryString = search => {
  return search ? JSON.stringify(queryString.parse(search)) : null;
};

export default {
  track,
  trackClick,
  trackPage,
  identity: Mercury.identity, // (typeof window.mercury !== 'undefined') ? window.mercury.identity : Mercury.identity,
};
