import BaseModel from 'data/BaseModel';
import type { UserFields } from 'data/user/model';
import type { TagFields } from './Tag';

export interface TagListFields {
  id: UserFields['id'] | null; // userId
  accountId: string | null;
  tags: TagFields['id'][]; // tagIds
  startDate: string | null; //only used by shift tags
  createdAt: string | null;
  deletedAt: string | null;
  updatedAt: string | null;
}

// TagList is a list of Tags assigned to a User, Shift, or ShiftTemplate
class TagList extends BaseModel<TagListFields>({
  id: null, // userId
  accountId: null,
  tags: [], // tagIds
  startDate: null, //only used by shift tags
  createdAt: null,
  deletedAt: null,
  updatedAt: null,
}) {}

export default TagList;
