import { ATTENDANCE_LOCATION_FILTER, ATTENDANCE_POSITIONS_FILTER } from 'attendance/modules/tracker/util/cookies';
import { getUserId } from 'shared/auth/auth';

import Cookies from 'js-cookie';

export const getInitialLocation = () => {
  return Number.parseInt(Cookies.get(ATTENDANCE_LOCATION_FILTER)!, 10) || 0;
};
export const getInitialPositionFilter = () => getPersistedFilter(ATTENDANCE_POSITIONS_FILTER);

// Helpers for persisting filters
export const persistFilter = (filterName: string, payload: number[]) => {
  const serializedPayload = JSON.stringify(payload);
  return localStorage.setItem(makeStorageKey(filterName), serializedPayload);
};

export const makeStorageKey = (filterName: string) => {
  const userId = getUserId();
  const locationId = getInitialLocation();

  return `${filterName}-${userId ? userId : ''}-${locationId ? locationId : ''}`;
};

export const getPersistedFilter = (filterName: string, notSetValue?: string[]): string[] | undefined => {
  const fromStorage = localStorage.getItem(makeStorageKey(filterName)) || false;
  // Use newest storage option
  if (fromStorage) {
    try {
      return JSON.parse(fromStorage);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }
  // Not found, return default
  return notSetValue;
};
