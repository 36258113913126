import { lazy } from 'react';

const AddWorkforceNowSchedulesDialog = lazy(() => import('./AddSchedulesDialog'));
const MatchWorkforceNowSchedulesDialog = lazy(() => import('./MatchSchedulesDialog'));
const EditWorkforceNowSchedulesDialog = lazy(() => import('./EditSchedulesDialog'));

const AddWorkforceNowPositionsDialog = lazy(() => import('./AddPositionsDialog'));
const MatchWorkforceNowPositionsDialog = lazy(() => import('./MatchPositionsDialog'));
const EditWorkforceNowPositionsDialog = lazy(() => import('./EditPositionsDialog'));

const MatchWorkforceNowHourTypesDialog = lazy(() => import('./MatchHourTypesDialog'));
const EditWorkforceNowHourTypesDialog = lazy(() => import('./EditHourTypesDialog'));

const AddWorkforceNowEmployeesDialog = lazy(() => import('./AddEmployeesDialog'));
const MatchWorkforceNowEmployeesDialog = lazy(() => import('./MatchEmployeesDialog'));
const EditWorkforceNowEmployeesDialog = lazy(() => import('./EditEmployeesDialog'));

const ExportPayrollWarningsDialog = lazy(() => import('./ExportPayrollWarningsDialog'));

const ConfirmCascadeDialog = lazy(() => import('./ConfirmCascadeDialog'));
const AdvancedConfigurationDialog = lazy(() => import('./AdvancedConfigurationDialog'));

export const ADD_WORKFORCENOW_SCHEDULES_DIALOG = 'ADD_WORKFORCENOW_SCHEDULES_DIALOG';
export const MATCH_WORKFORCENOW_SCHEDULES_DIALOG = 'MATCH_WORKFORCENOW_SCHEDULES_DIALOG';
export const EDIT_WORKFORCENOW_SCHEDULES_DIALOG = 'EDIT_WORKFORCENOW_SCHEDULES_DIALOG';

export const ADD_WORKFORCENOW_POSITIONS_DIALOG = 'ADD_WORKFORCENOW_POSITIONS_DIALOG';
export const MATCH_WORKFORCENOW_POSITIONS_DIALOG = 'MATCH_WORKFORCE_NOW_POSITIONS_DIALOG';
export const EDIT_WORKFORCENOW_POSITIONS_DIALOG = 'EDIT_WORKFORCENOW_POSITIONS_DIALOG';

export const MATCH_WORKFORCENOW_HOUR_TYPES_DIALOG = 'MATCH_WORKFORCENOW_HOUR_TYPES_DIALOG';
export const EDIT_WORKFORCENOW_HOUR_TYPES_DIALOG = 'EDIT_WORKFORCENOW_HOUR_TYPES_DIALOG';

export const ADD_WORKFORCENOW_EMPLOYEES_DIALOG = 'ADD_WORKFORCENOW_EMPLOYEES_DIALOG';
export const MATCH_WORKFORCENOW_EMPLOYEES_DIALOG = 'MATCH_WORKFORCENOW_EMPLOYEES_DIALOG';
export const EDIT_WORKFORCENOW_EMPLOYEES_DIALOG = 'EDIT_WORKFORCENOW_EMPLOYEES_DIALOG';
export const EXPORT_PAYROLL_WARNINGS_DIALOG = 'EXPORT_PAYROLL_WARNINGS_DIALOG';
export const CONFIRM_CASCADE_DIALOG = 'CONFIRM_CASCADE_DIALOG';
export const ADVANCED_CONFIGURATION_DIALOG = 'ADVANCED_CONFIGURATION_DIALOG';

const dialogs = {
  [ADD_WORKFORCENOW_SCHEDULES_DIALOG]: AddWorkforceNowSchedulesDialog,
  [MATCH_WORKFORCENOW_SCHEDULES_DIALOG]: MatchWorkforceNowSchedulesDialog,
  [EDIT_WORKFORCENOW_SCHEDULES_DIALOG]: EditWorkforceNowSchedulesDialog,

  [ADD_WORKFORCENOW_POSITIONS_DIALOG]: AddWorkforceNowPositionsDialog,
  [MATCH_WORKFORCENOW_POSITIONS_DIALOG]: MatchWorkforceNowPositionsDialog,
  [EDIT_WORKFORCENOW_POSITIONS_DIALOG]: EditWorkforceNowPositionsDialog,

  [MATCH_WORKFORCENOW_HOUR_TYPES_DIALOG]: MatchWorkforceNowHourTypesDialog,
  [EDIT_WORKFORCENOW_HOUR_TYPES_DIALOG]: EditWorkforceNowHourTypesDialog,

  [ADD_WORKFORCENOW_EMPLOYEES_DIALOG]: AddWorkforceNowEmployeesDialog,
  [MATCH_WORKFORCENOW_EMPLOYEES_DIALOG]: MatchWorkforceNowEmployeesDialog,
  [EDIT_WORKFORCENOW_EMPLOYEES_DIALOG]: EditWorkforceNowEmployeesDialog,

  [EXPORT_PAYROLL_WARNINGS_DIALOG]: ExportPayrollWarningsDialog,

  [CONFIRM_CASCADE_DIALOG]: ConfirmCascadeDialog,
  [ADVANCED_CONFIGURATION_DIALOG]: AdvancedConfigurationDialog,
};

export default dialogs;
