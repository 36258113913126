import type ShiftTemplate from 'data/shiftTemplate/model';
import type { ShiftTemplateFields } from 'data/shiftTemplate/model';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type CreateShiftTemplateArgs = ShiftTemplate;

type CreateShiftTemplateResponse = {
  block: ShiftTemplateFields;
};

export const createShiftTemplate = createWiwAsyncThunk<CreateShiftTemplateResponse, CreateShiftTemplateArgs>(
  'shiftTemplate/create',
  async (shiftTemplate, { rejectWithValue }) => {
    try {
      return (await Monolith.url('/blocks').post(shiftTemplate.toJSON())) as CreateShiftTemplateResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
