import BaseModel from 'data/BaseModel';

export interface IntegrationFields {
  name: any | null; // TODO(types)
  description: any | null; // TODO(types)
  feature: any | null; // TODO(types)
  details_url: any | null; // TODO(types)
  logo_class: any | null; // TODO(types)
  manage_url: any | null; // TODO(types)
  status: any | null; // TODO(types)
  upcoming_key: any | null; // TODO(types)
  is_new: boolean;
  is_legacy: boolean;
  banner_text: string | null;
  url: any | null; // TODO(types)
  external: any | null; // TODO(types)
  requires_login: any | null; // TODO(types)
  info_tooltip: boolean;
}

export class Integration extends BaseModel<IntegrationFields>({
  name: null,
  description: null,
  feature: null,
  details_url: null,
  logo_class: null,
  manage_url: null,
  status: null,
  upcoming_key: null,
  is_new: false,
  is_legacy: false,
  banner_text: null,
  url: null,
  external: null,
  requires_login: null,
  info_tooltip: false,
}) {
  static STATUS = {
    TO: 'to',
    AVAILABLE: 'available',
    ENABLED: 'enabled',
    PLANNED: 'planned',
    SUGGESTED: 'suggested',
    UNAVAILABLE: 'unavailable',
    PROVIDER_MANAGED: 'provider-managed',
    REQUESTABLE: 'requestable',
  } as const;
}

export default Integration;
