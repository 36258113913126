import { SCHEDULER_CURRENT_VIEW } from 'scheduler/util/cookies';
import { persistFilter } from 'scheduler/util/persistFilter';
import { SET_CURRENT_DATE_AND_VIEW } from 'store/action-types';

export function setCurrentDateAndView(date, view, range) {
  return (dispatch, getState) => {
    view = view || getState().scheduler.get('view');
    range = range || getState().scheduler.get('range');

    view && persistFilter(SCHEDULER_CURRENT_VIEW, view);

    //dispatch(expandOpenShiftRow()); todo should this be dispatched here? it's dispatched in setCurrentDate()

    return dispatch({
      type: SET_CURRENT_DATE_AND_VIEW,
      payload: {
        date,
        view,
        range,
      },
    });
  };
}
