/* istanbul ignore file */
import { type ComponentType, lazy } from 'react';

import attendanceDialogs from 'attendance/dialogs';
import freemiumAttendanceDialogs from 'attendance/modules/freemium/dialogs';
import timeTrackerDialogs from 'attendance/modules/tracker/dialogs';
import availabilityDialogs from 'availability/dialogs';
import billingDialogs from 'billing/dialogs';
import dashboardDialogs from 'dashboard/dialogs';
import documentsDialogs from 'documents/dialogs';
import integrationDialogs from 'integrations/dialogs';
import exportGustoDialog from 'integrations/modules/gusto/dialogs';
import exportQboDialogs from 'integrations/modules/qbo/dialogs';
import squareDialogs from 'integrations/modules/square/dialogs';
import workforceNowDialogs from 'integrations/modules/wfn/dialogs';
import marketingDialogs from 'marketing/dialogs';
import myscheduleDialogs from 'myschedule/dialogs';
import onboardingDialogs from 'onboarding/dialogs';
import payrollCheckDialogs from 'payrollCheck/dialogs';
import payrollCheckDetailsDialogs from 'payrollCheck/modules/details/dialogs';
import payrollCheckMarketingDialogs from 'payrollCheck/modules/marketing/dialogs';
import payrollCheckOnboardingDialogs from 'payrollCheck/modules/onboarding/dialogs';
import positionDialogs from 'positions/dialogs';
import profileDialogs from 'profile/dialogs';
import punchDialogs from 'punch/dialogs';
import reportingDialogs from 'reporting/dialogs';
import requestDialogs from 'requests/dialogs';
import schedulerDialogs from 'scheduler/dialogs';
import locationDialogs from 'schedules/dialogs';
import settingsDialogs from 'settings/dialogs';
import authDialogs from 'shared/auth/dialogs';
// Import dialog groups
import sharedDialogs from 'shared/dialogs';
import FeedbackPromptDialog, { FEEDBACK_PROMPT_DIALOG } from 'shared/feedback/components/FeedbackDialog';
import importDialogs from 'shared/imports/dialogs';
import {
  DROP_SHIFT_DIALOG,
  SHIFT_CONFIRMATION_DIALOG,
  SHIFT_VIEW_DIALOG,
  SWAP_SHIFT_DIALOG,
} from 'shared/modal/actions';
import {
  ABSENCES_UPGRADE_DIALOG,
  AUTO_ASSIGN_UPGRADE,
  BULK_EDIT_UPGRADE,
  CUSTOM_TIME_OFF_UPGRADE_DIALOG,
  DOC_STORAGE_UPGRADE_DIALOG,
  EXPORTS_UPGRADE_DIALOG,
  EXTENDED_VIEW_UPGRADE_DIALOG,
  FORECAST_TOOLS_UPGRADE_DIALOG,
  FUTURE_UPGRADE,
  HISTORY_UPGRADE,
  JOB_SITES_UPGRADE,
  LABOR_SHARE_UPGRADE_DIALOG,
  MANAGER_PERMS_UPGRADE_DIALOG,
  MAX_HOURS_UPGRADE_DIALOG,
  MONTH_VIEW_UPGRADE,
  MULTIPLE_SCHEDULES_UPGRADE_DIALOG,
  OPENSHIFT_APPROVAL_UPGRADE_DIALOG,
  OVERLAPPING_OPENSHIFTS_UPGRADE_DIALOG,
  PAYROLL_INTEGRATIONS_UPGRADE_DIALOG,
  REPEAT_SHIFTS_UPGRADE_DIALOG,
  SCHEDULE_TEMPLATE_UPGRADE_DIALOG,
  SCHEDULING_RULES_UPGRADE_DIALOG,
  SHIFT_COLORS_UPGRADE_DIALOG,
  SHIFT_HISTORY_UPGRADE_DIALOG,
  SHIFT_RELEASING_UPGRADE_DIALOG,
  SUPERVISOR_PERMS_UPGRADE_DIALOG,
  SWAP_DROP_RESTRICTIONS_UPGRADE_DIALOG,
  TAGS_UPGRADE_DIALOG,
  TASKS_UPGRADE_DIALOG,
  THIRTY_DAY_FREE_TRIAL_DIALOG,
  UPGRADE_POPOVER,
  USER_PRIVACY_UPGRADE_DIALOG,
} from 'shared/upsell/actions/dialogs';
import upsellDialogs from 'shared/upsell/dialogs';
// At the time of this writing, this dialog is extremely silly - it opens on
// every page load and then closes itself if the conditions are not met. You
// would think we could just...not open the dialog unless the conditions were
// met. Anyway, it made a loading state show up so we had to statically import
// this one.
import UpgradePopover from 'shared/upsell/dialogs/UpgradePopover';
import siteDialogs from 'sites/dialogs';
import TagDialog, { TAG_DIALOG } from 'tags/dialogs/TagDialog';
import tasksDialogs from 'tasks/dialogs';
import teamTxtDialogs from 'teamtxt/dialogs';
import shiftTemplateDialogs from 'templates/dialogs';
import terminalDialogs from 'terminal/dialogs';
import timesheetsDialogs from 'timesheets/dialogs';
import employeeDialogs from 'users/dialogs';
import workchatDialogs from 'workchat/dialogs';

// Global, Reusable Dialogs
import ConfirmationDialog, { CONFIRMATION_DIALOG } from './components/ConfirmationDialog';

// Upgrades
const FutureUpgradeDialog = lazy(() => import('shared/upsell/dialogs/FutureUpgradeDialog'));
const MonthUpgradeDialog = lazy(() => import('shared/upsell/dialogs/MonthUpgradeDialog'));
const MultipleSchedulesUpgradeDialog = lazy(() => import('shared/upsell/dialogs/MultipleSchedulesUpgradeDialog'));
const JobSitesUpgradeDialog = lazy(() => import('shared/upsell/dialogs/JobSitesUpgradeDialog'));
const HistoryUpgradeDialog = lazy(() => import('shared/upsell/dialogs/HistoryUpgradeDialog'));
const AutoAssignUpgradeDialog = lazy(() => import('shared/upsell/dialogs/AutoAssignUpgradeDialog'));
const SchedulingRulesUpgradeDialog = lazy(() => import('shared/upsell/dialogs/SchedulingRulesUpgradeDialog'));
const BulkEditUpgradeDialog = lazy(() => import('shared/upsell/dialogs/BulkEditUpgradeDialog'));
const ShiftReleasingUpgradeDialog = lazy(() => import('shared/upsell/dialogs/ShiftReleasingUpgradeDialog'));
const SwapDropRestrictionsUpgradeDialog = lazy(() => import('shared/upsell/dialogs/SwapDropRestrictionsUpgradeDialog'));
const OpenShiftApprovalUpgradeDialog = lazy(() => import('shared/upsell/dialogs/OpenShiftApprovalUpgradeDialog'));
const AbsencesUpgradeDialog = lazy(() => import('shared/upsell/dialogs/AbsencesUpgradeDialog'));
const CustomTimeOffUpgradeDialog = lazy(() => import('shared/upsell/dialogs/CustomTimeOffUpgradeDialog'));
const ForecastToolsUpgradeDialog = lazy(() => import('shared/upsell/dialogs/ForecastToolsUpgradeDialog'));
const MaxHoursUpgradeDialog = lazy(() => import('shared/upsell/dialogs/MaxHoursUpgradeDialog'));
const RepeatShiftsUpgradeDialog = lazy(() => import('shared/upsell/dialogs/RepeatShiftsUpgradeDialog'));
const ScheduleTemplateUpgradeDialog = lazy(() => import('shared/upsell/dialogs/ScheduleTemplateUpgradeDialog'));
const ShiftHistoryUpgradeDialog = lazy(() => import('shared/upsell/dialogs/ShiftHistoryUpgradeDialog'));
const LaborShareUpgradeDialog = lazy(() => import('shared/upsell/dialogs/LaborShareUpgradeDialog'));
const ShiftColorsUpgradeDialog = lazy(() => import('shared/upsell/dialogs/ShiftColorsUpgradeDialog'));
const ExtendedViewUpgradeDialog = lazy(() => import('shared/upsell/dialogs/ExtendedViewUpgradeDialog'));
const TagsUpgradeDialog = lazy(() => import('shared/upsell/dialogs/TagsUpgradeDialog'));
const TasksUpgradeDialog = lazy(() => import('shared/upsell/dialogs/TasksUpgradeDialog'));
const ManagerPermsUpgradeDialog = lazy(() => import('shared/upsell/dialogs/ManagerPermsUpgradeDialog'));
const SupervisorPermsUpgradeDialog = lazy(() => import('shared/upsell/dialogs/SupervisorPermsUpgradeDialog'));
const UserPrivacyUpgradeDialog = lazy(() => import('shared/upsell/dialogs/UserPrivacyUpgradeDialog'));
const ExportsUpgradeDialog = lazy(() => import('shared/upsell/dialogs/ExportsUpgradeDialog'));
const OverlappingOpenShiftsUpgradeDialog = lazy(
  () => import('shared/upsell/dialogs/OverlappingOpenShiftsUpgradeDialog'),
);

// eslint-disable-next-line max-len
const PayrollIntegrationsUpgradeDialog = lazy(() => import('shared/upsell/dialogs/PayrollIntegrationsUpgradeDialog'));
const DocStorageUpgradeDialog = lazy(() => import('shared/upsell/dialogs/DocStorageUpgradeDialog'));
const ThirtyDayFreeTrialDialog = lazy(() => import('shared/upsell/dialogs/ThirtyDayFreeTrialDialog'));
const DropShiftDialog = lazy(() => import('shared/modal/dropShift'));
const ShiftConfirmationDialog = lazy(() => import('shared/modal/shiftConfirmation'));
const SwapShiftDialog = lazy(() => import('shared/modal/swapShift'));
const ShiftViewDialog = lazy(() => import('shared/modal/shiftView'));

// these are because istanbul has issues with this object of components they are in fact covered by snapshots however
/* istanbul ignore next */
const dialogs: Dialogs = {
  // Global, Reusable Dialogs
  [CONFIRMATION_DIALOG]: ConfirmationDialog,
  ...sharedDialogs,

  // Add dialogs to be displayed here
  ...attendanceDialogs,
  ...billingDialogs,
  ...importDialogs,
  ...employeeDialogs,
  ...payrollCheckDetailsDialogs,
  ...positionDialogs,
  ...profileDialogs,
  ...authDialogs,
  ...requestDialogs,
  ...schedulerDialogs,
  ...timesheetsDialogs,
  ...timeTrackerDialogs,
  ...settingsDialogs,
  ...siteDialogs,
  ...shiftTemplateDialogs,
  ...freemiumAttendanceDialogs,
  ...locationDialogs,
  ...documentsDialogs,
  ...reportingDialogs,
  ...myscheduleDialogs,
  ...punchDialogs,
  [DROP_SHIFT_DIALOG]: DropShiftDialog,
  [SHIFT_CONFIRMATION_DIALOG]: ShiftConfirmationDialog,
  [SWAP_SHIFT_DIALOG]: SwapShiftDialog,
  [SHIFT_VIEW_DIALOG]: ShiftViewDialog,
  ...availabilityDialogs,
  ...tasksDialogs,
  ...upsellDialogs,
  ...exportQboDialogs,
  ...workforceNowDialogs,
  ...squareDialogs,
  ...teamTxtDialogs,
  ...exportGustoDialog,
  ...dashboardDialogs,
  ...workchatDialogs,
  ...payrollCheckDialogs,
  ...payrollCheckMarketingDialogs,
  ...payrollCheckOnboardingDialogs,
  ...terminalDialogs,
  ...integrationDialogs,
  [FEEDBACK_PROMPT_DIALOG]: FeedbackPromptDialog,
  [TAG_DIALOG]: TagDialog,
  ...marketingDialogs,
  [THIRTY_DAY_FREE_TRIAL_DIALOG]: ThirtyDayFreeTrialDialog,
  ...onboardingDialogs,

  // Upgrade dialogs
  [FUTURE_UPGRADE]: FutureUpgradeDialog,
  [MONTH_VIEW_UPGRADE]: MonthUpgradeDialog,
  [MULTIPLE_SCHEDULES_UPGRADE_DIALOG]: MultipleSchedulesUpgradeDialog,
  [JOB_SITES_UPGRADE]: JobSitesUpgradeDialog,
  [HISTORY_UPGRADE]: HistoryUpgradeDialog,
  [UPGRADE_POPOVER]: UpgradePopover,
  [AUTO_ASSIGN_UPGRADE]: AutoAssignUpgradeDialog,
  [ABSENCES_UPGRADE_DIALOG]: AbsencesUpgradeDialog,
  [BULK_EDIT_UPGRADE]: BulkEditUpgradeDialog,
  [CUSTOM_TIME_OFF_UPGRADE_DIALOG]: CustomTimeOffUpgradeDialog,
  [PAYROLL_INTEGRATIONS_UPGRADE_DIALOG]: PayrollIntegrationsUpgradeDialog,
  [DOC_STORAGE_UPGRADE_DIALOG]: DocStorageUpgradeDialog,
  [SCHEDULING_RULES_UPGRADE_DIALOG]: SchedulingRulesUpgradeDialog,
  [SHIFT_RELEASING_UPGRADE_DIALOG]: ShiftReleasingUpgradeDialog,
  [SWAP_DROP_RESTRICTIONS_UPGRADE_DIALOG]: SwapDropRestrictionsUpgradeDialog,
  [OPENSHIFT_APPROVAL_UPGRADE_DIALOG]: OpenShiftApprovalUpgradeDialog,
  [FORECAST_TOOLS_UPGRADE_DIALOG]: ForecastToolsUpgradeDialog,
  [MAX_HOURS_UPGRADE_DIALOG]: MaxHoursUpgradeDialog,
  [REPEAT_SHIFTS_UPGRADE_DIALOG]: RepeatShiftsUpgradeDialog,
  [SCHEDULE_TEMPLATE_UPGRADE_DIALOG]: ScheduleTemplateUpgradeDialog,
  [SHIFT_HISTORY_UPGRADE_DIALOG]: ShiftHistoryUpgradeDialog,
  [LABOR_SHARE_UPGRADE_DIALOG]: LaborShareUpgradeDialog,
  [SHIFT_COLORS_UPGRADE_DIALOG]: ShiftColorsUpgradeDialog,
  [EXTENDED_VIEW_UPGRADE_DIALOG]: ExtendedViewUpgradeDialog,
  [TAGS_UPGRADE_DIALOG]: TagsUpgradeDialog,
  [TASKS_UPGRADE_DIALOG]: TasksUpgradeDialog,
  [MANAGER_PERMS_UPGRADE_DIALOG]: ManagerPermsUpgradeDialog,
  [SUPERVISOR_PERMS_UPGRADE_DIALOG]: SupervisorPermsUpgradeDialog,
  [USER_PRIVACY_UPGRADE_DIALOG]: UserPrivacyUpgradeDialog,
  [EXPORTS_UPGRADE_DIALOG]: ExportsUpgradeDialog,
  [OVERLAPPING_OPENSHIFTS_UPGRADE_DIALOG]: OverlappingOpenShiftsUpgradeDialog,
};

export type DialogType = string;

export interface Dialogs {
  [type: string]: ComponentType<any>;
}

export default dialogs as Dialogs;
