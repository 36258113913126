import EntityState from 'data/EntityState';
import Overtime from 'data/overtime/model';
import { FETCH_RAW_CACHED_OVERTIME } from 'store/action-types';

export default function (state = new EntityState(), action) {
  switch (action.type) {
    case FETCH_RAW_CACHED_OVERTIME.FAILURE:
      return state.merge({
        loaded: false,
        loading: false,
        error: action.payload,
      });
    case FETCH_RAW_CACHED_OVERTIME.REQUEST:
      return state.merge({
        loaded: false,
        loading: true,
        error: null,
      });
    case FETCH_RAW_CACHED_OVERTIME.SUCCESS:
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        receivedAt: Date.now(),
        items: state.items.merge(action.payload.map(ot => [ot.id, new Overtime(ot)])),
      });
    default:
      return state;
  }
}
