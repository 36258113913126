import { type Ref, forwardRef } from 'react';
import { type FieldValues, type UseControllerProps, useController } from 'react-hook-form';

import getFieldError from 'shared/form/helpers/getFieldError';
import useMapCustomValidators from 'shared/form/hooks/useMapCustomValidators';
import { CheckboxGroup } from 'shared/form/inputs/CheckboxGroup/CheckboxGroup';
import type { InputProps } from 'shared/form/types';

import { Checkbox as MantineCheckbox, type CheckboxProps as MantineCheckboxProps } from '@mantine/core';
import { useMergedRef } from '@mantine/hooks';

export type CheckboxProps<T extends FieldValues> = InputProps<UseControllerProps<T>> &
  Omit<MantineCheckboxProps, 'checked' | 'defaultValue' | 'onChange'>;

const Checkbox = forwardRef(function Checkbox<T extends FieldValues>(
  { name, control, defaultValue, shouldUnregister, onChange, ...props }: CheckboxProps<T>,
  ref: Ref<HTMLInputElement>,
) {
  const rules = useMapCustomValidators(props, true);
  const {
    field: { value, onChange: fieldOnChange, ref: insideRef, ...field },
    fieldState,
  } = useController<T>({
    name,
    control,
    defaultValue,
    rules,
    shouldUnregister,
  });
  const mergedRef = useMergedRef(ref, insideRef);

  return (
    <MantineCheckbox
      error={getFieldError(fieldState)}
      ref={mergedRef}
      value={value}
      checked={value}
      onChange={e => {
        fieldOnChange(e);
        onChange?.(name, e.target.checked);
      }}
      mb="md"
      size="xs"
      wrapperProps={{ ...props.wrapperProps, 'data-testid': name }}
      data-testid={`${name}-checkbox`}
      {...field}
      {...props}
    />
  );
});
const CheckboxNamespace = Object.assign(Checkbox, {
  Group: CheckboxGroup,
  Item: MantineCheckbox,
});

export { CheckboxNamespace as Checkbox };
