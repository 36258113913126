/**
 * This middleware updates the Super Properties for posthog eventing
 * Other Super Properties are set in the identify step
 * We target specific actions that update the properties we care about
 * Super Properties recorded here are:
 * - Pay type
 * - Plan ID
 * - Product features
 * - User role
 * - CAT LD Flags
 * - FDT LD Flags
 * - Industry ID
 */

import type Account from 'data/account/model';
import type Plan from 'data/plan/model';
import type { PlanFields } from 'data/plan/model';
import type { EntityMap } from 'data/types';
import User from 'data/user/model';
import { getUserId } from 'shared/auth/auth';
import { posthogRegister } from 'shared/vendor/posthog';
import type { RootState } from 'store';

import type { Dispatch, Middleware, UnknownAction } from '@reduxjs/toolkit';
import type { LDFlagChangeset, LDFlagSet } from 'launchdarkly-js-client-sdk';
import { find } from 'lodash';

type AccountSuperPropertiesTypes = {
  'Plan ID'?: number;
  'Pay type'?: string;
  'Industry ID'?: number | null;
};

type UserSuperPropertiesTypes = {
  'User role'?: string;
};

type UserPayload = {
  user?: User;
  users?: User[];
};

const userActions = [
  'user/fetchUsers/fulfilled',
  'user/fetchPaginated/fulfilled',
  'user/mergeUser/fulfilled',
  'user/replaceUsers/fulfilled',
  'user/saveUser/fulfilled',
];

const accountActions = [
  'account/fetchAccounts/fulfilled',
  'account/fetchCurrentAccount/fulfilled',
  'account/persistAccount/fulfilled',
];

const isAccountAction = (type: string) => {
  return accountActions.includes(type);
};

const isUserAction = (type: string) => {
  return userActions.includes(type);
};

const getAccountSuperProperties = (account: Account, plans: EntityMap<Plan, PlanFields['id']>) => {
  const accountSuperProperties: AccountSuperPropertiesTypes = {};

  if (account.plan_id) {
    const accountPlan = plans.get(account.plan_id);
    let payType = 'Unknown';

    if (accountPlan) {
      if (accountPlan.isTrial()) {
        payType = 'Trial';
      } else if (accountPlan.isFreemium()) {
        payType = 'Freemium';
      } else if (accountPlan.isPaid()) {
        payType = 'Paid';
      }
    }
    accountSuperProperties['Plan ID'] = account.plan_id;
    accountSuperProperties['Pay type'] = payType;
  }

  accountSuperProperties['Industry ID'] = account.industry_id || null;

  return accountSuperProperties;
};

const getAuthUser = (payload: UserPayload) => {
  const authUserId = getUserId();
  let authUser = null;

  if (payload.users) {
    authUser = find(payload.users, user => user.id === authUserId);
  } else if (payload.user) {
    if (payload.user.id === authUserId) {
      authUser = payload.user;
    }
  }

  return authUser;
};

const getUserSuperProperties = (user: User) => {
  const userSuperProperties: UserSuperPropertiesTypes = {};
  if (user.role) {
    userSuperProperties['User role'] = User.roleNameFor(user.role);
  }

  return userSuperProperties;
};

const isSuperPropertyLDFlag = (key: string, substring: string) => {
  return key.includes(substring);
};

export const posthogUpdate: Middleware<Dispatch, RootState> = store => next => actionn => {
  const action = actionn as UnknownAction;
  if (isAccountAction(action.type)) {
    // @ts-ignore - this is a thunk
    const superProperties = getAccountSuperProperties(action.payload.account, store.getState().data.plan.items);
    posthogRegister(superProperties);
  } else if (isUserAction(action.type)) {
    // @ts-ignore - this is a thunk
    const authUser = getAuthUser(action.payload);
    if (authUser) {
      const superProperties = getUserSuperProperties(authUser);
      posthogRegister(superProperties);
    }
  } else if (action.type === 'UPSERT_LD_FLAGS' && action.payload) {
    const ldFlags: LDFlagSet | LDFlagChangeset = {
      ...store.getState().LD, // LDFlagSet
      ...action.payload, // LDFlagChangeset
    };

    const CATLDFlags = [];
    const FDTLDFlags = [];

    for (const [key, value] of Object.entries(ldFlags)) {
      if (isSuperPropertyLDFlag(key, 'cat-')) {
        CATLDFlags.push(`${key}: ${typeof value === 'object' ? value?.current : value}`);
      } else if (isSuperPropertyLDFlag(key, 'fdt-')) {
        FDTLDFlags.push(`${key}: ${typeof value === 'object' ? value?.current : value}`);
      }
    }

    posthogRegister({ 'CAT LD Flags': CATLDFlags, 'FDT LD Flags': FDTLDFlags });
  }

  return next(action);
};
