import BaseModel from 'data/BaseModel';
import type { Users } from 'data/user/state';
import { shortTimeRange } from 'shared/util/time';

export interface RequestFields {
  id: number | null;
  account_id: number;
  allowed_statuses: RequestStatus[] | null;
  canceled_by: number | null;
  creator_id: number | null;
  hours: number;
  start_time: string | null;
  end_time: string | null;
  status: RequestStatus;
  user_status: RequestStatus;
  type: RequestLegacyType | null;
  type_id: number;
  type_label: string | null;
  paid: boolean;
  updater_id: number | null;
  user_id: number;
  created_at: string | null;
  updated_at: string | null;
  locations: null; // TODO(types)
}

export enum RequestPermissions {
  Approve = 'approve',
  Cancel = 'cancel',
  View = 'view',
}

export enum RequestReduxDataNames {
  Statuses = 'statuses',
  Schedules = 'schedules',
}

export enum RequestTabNames {
  Pending = 'pending',
  Mine = 'mine',
  All = 'all',
}

export enum RequestLegacyType {
  TimeOff = 0,
  PTO = 1,
  Sick = 2,
  Holiday = 3,
}

export enum RequestStatus {
  Pending = 0,
  Accepted = 2,
  Expired = 3,
  Denied = 4,
  Canceled = 1,
}

class Request extends BaseModel<RequestFields>({
  id: null,
  account_id: 0,
  allowed_statuses: null,
  canceled_by: null,
  creator_id: null,
  hours: 0,
  start_time: null,
  end_time: null,
  status: RequestStatus.Pending,
  user_status: RequestStatus.Pending,
  type: null,
  type_id: 0,
  type_label: '',
  paid: false,
  updater_id: null,
  user_id: 0,
  created_at: null,
  updated_at: null,
  locations: null,
}) {
  static Permissions = {
    APPROVE: RequestPermissions.Approve,
    CANCEL: RequestPermissions.Cancel,
    VIEW: RequestPermissions.View,
  } as const;

  static Types = {
    TIMEOFF: RequestLegacyType.TimeOff,
    PTO: RequestLegacyType.PTO,
    SICK: RequestLegacyType.Sick,
    HOLIDAY: RequestLegacyType.Holiday,
  } as const;

  static ReduxDataNames = {
    STATUSES: RequestReduxDataNames.Statuses,
    SCHEDULES: RequestReduxDataNames.Schedules,
  } as const;

  //sorted for filters
  static Status = {
    PENDING: RequestStatus.Pending,
    ACCEPTED: RequestStatus.Accepted,
    EXPIRED: RequestStatus.Expired,
    DENIED: RequestStatus.Denied,
    CANCELED: RequestStatus.Canceled,
  } as const;

  static TabNames = {
    PENDING: RequestTabNames.Pending,
    MINE: RequestTabNames.Mine,
    ALL: RequestTabNames.All,
  } as const;

  shortTimeRange() {
    return shortTimeRange(this.date('start_time'), this.date('end_time'));
  }

  get formattedTimeRange() {
    const { startString, endString } = this.shortTimeRange();

    return `${startString} - ${endString}`;
  }

  get duration() {
    return this.date('end_time')!.add(1, 'second').diff(this.date('start_time'), 'days');
  }

  get isMultiDay() {
    return this.duration ? this.duration > 1 : false;
  }

  get typeName() {
    switch (this.type) {
      case RequestLegacyType.PTO:
        return 'paid time off';
      case RequestLegacyType.Sick:
        return 'sick time';
      case RequestLegacyType.Holiday:
        return 'holiday';
      default:
        return 'time off';
    }
  }

  displayTypeLabel(ucFirst = false) {
    if (!this.type_id) {
      return this.displayTypeName();
    }

    const paid = ucFirst ? '(Paid)' : '(paid)';
    const unpaid = ucFirst ? '(Unpaid)' : '(unpaid)';

    if (this.paid) {
      return `${this.type_label} ${paid}`;
    }
    return `${this.type_label} ${unpaid}`;
  }

  displayTypeName() {
    switch (this.type) {
      case RequestLegacyType.TimeOff:
        return 'unpaid time off';
      case RequestLegacyType.PTO:
        return 'paid time off';
      case RequestLegacyType.Sick:
        return 'sick';
      case RequestLegacyType.Holiday:
        return 'holiday';
      default:
        return 'time off';
    }
  }

  get statusName() {
    switch (this.user_status) {
      case RequestStatus.Pending:
        return 'pending';
      case RequestStatus.Canceled:
        return 'canceled';
      case RequestStatus.Accepted:
        return 'approved';
      case RequestStatus.Denied:
        return 'denied';
      default:
        return 'expired';
    }
  }

  get statusColor() {
    switch (this.user_status) {
      case RequestStatus.Pending:
        return 'yellow';
      case RequestStatus.Accepted:
        return 'green';
      default:
        return 'red';
    }
  }

  get noonStart() {
    return this.date('start_time')!.clone().add(12, 'h');
  }

  get noonEnd() {
    return this.date('end_time')!.clone().subtract(12, 'h');
  }

  allDay(relative = false, adjustTz = null) {
    if (relative) {
      const start = this.date('start_time');
      const end = this.date('end_time');
      return start!.isSame(start!.clone().startOf('day'), 'second') && end!.isSame(end!.clone().endOf('day'), 'second');
    }

    if (adjustTz) {
      const duration = this.mustDate('end_time')
        .tz(adjustTz)
        .add(1, 'second')
        .diff(this.mustDate('start_time').tz(adjustTz), 'days');
      return duration ? duration >= 1 : false;
    }

    return this.duration ? this.duration >= 1 : false;
  }

  isType(type: RequestLegacyType) {
    return this.type === type;
  }

  // See data/annotation/model.js
  adjustedStartTime(adjustTz = null) {
    const startTime = !adjustTz ? this.mustDate('start_time') : this.mustDate('start_time').tz(adjustTz);

    // We only want to adjust if the request goes all day.
    if (this.allDay(false, adjustTz)) {
      return startTime.add(12, 'hours');
    }
    return startTime;
  }

  adjustedEndTime(adjustTz = null) {
    const endTime = !adjustTz ? this.date('end_time') : this.mustDate('end_time').tz(adjustTz);

    // We only want to adjust if the request goes all day.
    if (this.allDay()) {
      return endTime!.subtract(12, 'hours').add(1, 'second');
    }
    return endTime;
  }

  //display person on status
  displayRequestStatus(users: Users) {
    const updated_date = `on ${this.date('updated_at')!.format('MMM D')}`;
    const who = 'by';

    switch (this.user_status) {
      case RequestStatus.Pending:
        return 'from management';
      case RequestStatus.Accepted:
        if (this.updater_id && users.get(this.updater_id)) {
          return `${who} ${users.get(this.updater_id)?.fullName ?? ''} ${updated_date}`;
        }
        return null;
      case RequestStatus.Expired:
        return updated_date;
      case RequestStatus.Denied:
      case RequestStatus.Canceled:
        if (this.canceled_by && users.get(this.canceled_by)) {
          return `${who} ${users.get(this.canceled_by)?.fullName ?? ''} ${updated_date}`;
        }
        return null;
    }
  }

  static statusNameOption(statusId: RequestStatus) {
    switch (statusId) {
      case RequestStatus.Pending:
        return { name: 'Pending Approval', color: 'C59A27' };
      case RequestStatus.Canceled:
        return { name: 'Canceled', color: 'D0404B' };
      case RequestStatus.Accepted:
        return { name: 'Approved', color: '51A33D' };
      case RequestStatus.Denied:
        return { name: 'Denied', color: 'D0404B' };
      default:
        return { name: 'Expired', color: 'D0404B' };
    }
  }
}

export default Request;
