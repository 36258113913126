import { OptionKeys, ValidationKeys } from 'shared/form/hooks/useInputRegister';
import type { InputProps, TextareaProps } from 'shared/form/types';
import validators from 'shared/form/validators';

type ValidateObject = {
  validate: {
    [key: string]: any;
  };
} & { [key: string]: boolean };

const mapCustomValidator = (validateKey: string, rules: ValidateObject) => {
  //@ts-ignore
  const validator = validators[validateKey];
  if (typeof validator === 'function') {
    rules.validate[validateKey] = validator(rules.validate[validateKey]);
    return true;
  }
  return false;
};

export default function useMapCustomValidators(
  props: Record<string, InputProps<any> | TextareaProps<any>>,
  controlledInput = false,
) {
  // @ts-ignore
  const rules: ValidateObject = {
    validate: {},
  };

  if (controlledInput) {
    const validationKeysProps = Object.keys(props).filter(key => ValidationKeys.includes(key));
    validationKeysProps.forEach(key => {
      rules[key] = props[key];
    });
  }

  if (!props.validate) {
    return rules;
  }

  if (typeof props.validate === 'string' && mapCustomValidator(props.validate, rules)) {
    return rules;
  }

  Object.keys(props.validate).forEach((validateKey: string): void => {
    if (!OptionKeys.includes(validateKey)) {
      rules.validate[validateKey] = props.validate[validateKey];
    }
    if (!mapCustomValidator(validateKey, rules)) {
      rules[validateKey] = props.validate[validateKey];
    }
  });

  return rules;
}
