import Payroll from 'shared/api/payroll';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';
import type CompanyStatus from '../models/companyStatus';

export interface PatchCompanyOnboardStatusParams {
  onboarding_started?: string;
  onboarding_completed?: string;
  previous_provider_access_started?: string;
  previous_provider_access_completed?: string;
}

export const patchCompanyOnboardStatus = createWiwAsyncThunk<CompanyStatus, PatchCompanyOnboardStatusParams>(
  'payrollCheck/patchCompanyOnboardingStatus',
  async (params, { rejectWithValue }) => {
    try {
      return await Payroll.url('/companies/onboarding-statuses').patch({
        ...params,
      });
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
