import BaseModel from 'data/BaseModel';
import { rangesOverlap } from 'shared/util/time';

import moment, { type Moment } from 'moment-timezone';

interface RawHoursData {
  regular: number;
  double: number;
  type: 'actual' | 'scheduled';
  filtered: boolean;
}

interface HoursData {
  doubleOvertimeHours: number;
  overtimeHours: number;
  regularHours: number;
  totalHours: number;
  weeklyOvertimeHours: number;
}

export interface OvertimeScheduledFields {
  id: number | null;
  maxHours: number | null;
  days: {
    [key: string]: {
      scheduled?: HoursData;
      normalized: HoursData;
      actual?: HoursData;
      rawhours?: RawHoursData[];
    };
  };
  weeks: {
    [key: string]: {
      normalized: HoursData;
    };
  };

  /** I don't think this property is ever used, but I'm afraid to take it out. */
  actual: unknown; // TODO(types)

  /** I don't think this property is ever used, but I'm afraid to take it out. */
  scheduled: unknown; // TODO(types)
}

class OvertimeScheduled extends BaseModel<OvertimeScheduledFields>({
  id: null,
  actual: {},
  days: {},
  maxHours: null,
  scheduled: {},
  weeks: {},
}) {
  getWeekHours(date: Moment | null) {
    for (const weekKey in this.weeks) {
      const overtimeWeek = moment(weekKey);
      if (
        date &&
        rangesOverlap(
          date,
          date.clone().add(1, 'days'),
          overtimeWeek.clone(),
          overtimeWeek.clone().add(6, 'days').endOf('day'),
          true,
        )
      ) {
        return this.weeks[weekKey].normalized;
      }
    }

    return null;
  }
}

export default OvertimeScheduled;
