import { getActiveUsers } from './activeUsers';

import { createSelector } from '@reduxjs/toolkit';

const currentUserSelector = state => state.auth.userId;

export const getActiveSupervisors = createSelector([getActiveUsers], users =>
  users.filter(user => user.isSupervisor()),
);

export const getActiveManagers = createSelector([getActiveUsers, currentUserSelector], (users, activeUserId) =>
  users.filter(user => user.canManage() && user.id !== activeUserId),
);
