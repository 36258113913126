import { formatQueryString } from 'shared/util/mercury';

export const DATE_SHORT_PRETTY = 'ddd, MMM Do';

/**
 * The fields as defined by https://catalog.wiwdata.com/#/entry/entries-e1b6ecac-633b-4e59-bdb3-b7c26eb900f0
 * Provides default values or null so that all required fields will be present in validation
 */
export const SCHEDULER_ONINTERACTION = {
  action: 'onClick',
  annotations: null,
  appComponent: 'Scheduler',
  category: null,
  hash: window.location.hash,
  hostName: window.location.host,
  module: null,
  path: window.location.pathname,
  queryParams: formatQueryString(window.location.search),
  target: null,
  type: 'click',
  userRole: null,
};

export const SORT_BY_FIRST_NAME = 'sort_by_first_name';
export const SORT_BY_LAST_NAME = 'sort_by_last_name';
export const SORT_BY_START_TIME = 'sort_by_start_time';
export const SORT_BY_CUSTOM = 'sort_by_custom';
export const DEFAULT_SORT = SORT_BY_CUSTOM;

export const STATUS = {
  IDLE: 'IDLE',
  INITIAL_LOAD: 'INITIAL_LOAD',
  DATE_CHANGE_LOAD: 'DATE_CHANGE_LOAD',
  SCHEDULE_CHANGE_LOAD: 'SCHEDULE_CHANGE_LOAD',
};

export const NO_JOB_SITE_FILTER_ID = 0;
