// Variations (sorted alphanumeric)
export const VARIATION_CONTROL = 'control';
export const VARIATION_FALSE = false;
export const VARIATION_NONE = 'none';
export const VARIATION_TREATMENT = 'treatment';
export const VARIATION_TREATMENT1 = 'treatment1';
export const VARIATION_TREATMENT2 = 'treatment2';
export const VARIATION_TRUE = true;

// Handy Variations object for import (sorted alphanumeric)
export const Variations = {
  CONTROL: VARIATION_CONTROL,
  FALSE: VARIATION_FALSE,
  NONE: VARIATION_NONE,
  TREATMENT1: VARIATION_TREATMENT1,
  TREATMENT2: VARIATION_TREATMENT2,
  TREATMENT: VARIATION_TREATMENT,
  TRUE: VARIATION_TRUE,
};

// Actions (sorted alphanumeric)
export const ACTION_ASSIGNED = 'assigned';
export const ACTION_CLICKED = 'clicked';
export const ACTION_CLICKED_ACKNOWLEDGE = 'clickedAcknowledge';
export const ACTION_CLICKED_ADD_FEATURE = 'addFeatureClicked';
export const ACTION_CLICKED_ANNUALLY = 'clickedAnnually';
export const ACTION_CLICKED_BACK = 'clickedBack';
export const ACTION_CLICKED_CANCEL_SETUP = 'clickedCancelSetup';
export const ACTION_CLICKED_CHECKBOX = 'checkboxClicked';
export const ACTION_CLICKED_CHECKOUT = 'clickedCheckout';
export const ACTION_CLICKED_CLOSE = 'closeClicked';
export const ACTION_CLICKED_COMPLETE = 'clickedComplete';
export const ACTION_CLICKED_CONTACT_US = 'contactUsClicked';
export const ACTION_CLICKED_CONTINUE = 'clickedContinue';
export const ACTION_CLICKED_FINISH = 'clickedFinish';
export const ACTION_CLICKED_GET_STARTED = 'getStartedClicked';
export const ACTION_CLICKED_HELP = 'clickedHelp';
export const ACTION_CLICKED_LEARN_MORE = 'clickedLearnMore';
export const ACTION_CLICKED_MONTHLY = 'clickedMonthly';
export const ACTION_CLICKED_NAV_BAR = 'clickedNavBar';
export const ACTION_CLICKED_NEXT = 'clickedNext';
export const ACTION_CLICKED_PLAN = 'clickedPlan';
export const ACTION_CLICKED_PROGRESS_MENU = 'clickedProgressMenu';
export const ACTION_CLICKED_SETTINGS = 'clickedSettings';
export const ACTION_CLICKED_SKIP_SETUP = 'clickedSkipSetupButton';
export const ACTION_CLICKED_TESTIMONIAL_CTA_BUTTON = 'clickedTestimonialCtaButton';
export const ACTION_CLICKED_TRIAL_EXPIRATION = 'trialExpirationMenuItemClicked';
export const ACTION_CLICKED_UPGRADE = 'upgradeClicked';
export const ACTION_CLICKED_VIDEO_CTA_BUTTON = 'clickedVideoCtaButton';
export const ACTION_CLICKED_VIEW_PLANS = 'clickedViewPlans';
export const ACTION_COLLAPSED = 'collapsed';
export const ACTION_COMPLETED = 'completed';
export const ACTION_COMPLETED_STEP = 'completedStep';
export const ACTION_CREATED_FIRST_JOB_POST = 'createdFirstJobPost';
export const ACTION_DECREMENT = 'decrement';
export const ACTION_EMPTY_STATE_SHOWN = 'emptyStateShown';
export const ACTION_EXPANDED = 'expanded';
export const ACTION_INCREMENT = 'increment';
export const ACTION_MINIMIZED = 'minimized';
export const ACTION_MODAL_CLOSED = 'modalClosed';
export const ACTION_MODAL_SHOWN = 'modalShown';
export const ACTION_MODAL_UNMOUNTED = 'modalUnmounted';
export const ACTION_POPOVER_CLOSED = 'popoverClosed';
export const ACTION_POPOVER_SHOWN = 'popoverShown';
export const ACTION_POSITION_CREATED = 'positionCreated';
export const ACTION_POSITION_TOGGLED = 'positionToggled';
export const ACTION_SKIPPED = 'skipped';
export const ACTION_START_TRIAL_CLICKED = 'startTrialClicked';
export const ACTION_TRIAL_STARTED = 'trialStarted';
export const ACTION_UPDATE_PLAN = 'updatePlan';
export const ACTION_UPSELL_CLOSED = 'upsellClosed';
export const ACTION_UPSELL_SHOWN = 'upsellShown';
export const ACTION_VIEWED = 'viewed';
export const ACTION_VIEWED_ACCOUNT_PAGE = 'viewedAccountPage';
export const ACTION_VIEWED_PAGE = 'viewedPage';
export const ACTION_VIEWED_PLANS_PAGE = 'viewedPlansPage';
export const ACTION_VIEWED_STEP = 'viewedStep';

// Handy Actions object for import (sorted alphanumeric)
export const Actions = {
  ASSIGNED: ACTION_ASSIGNED,
  CLICKED: ACTION_CLICKED,
  CLICKED_ACKNOWLEDGE: ACTION_CLICKED_ACKNOWLEDGE,
  CLICKED_ADD_FEATURE: ACTION_CLICKED_ADD_FEATURE,
  CLICKED_ANNUALLY: ACTION_CLICKED_ANNUALLY,
  CLICKED_BACK: ACTION_CLICKED_BACK,
  CLICKED_CANCEL_SETUP: ACTION_CLICKED_CANCEL_SETUP,
  CLICKED_CHECKBOX: ACTION_CLICKED_CHECKBOX,
  CLICKED_CHECKOUT: ACTION_CLICKED_CHECKOUT,
  CLICKED_CLOSE: ACTION_CLICKED_CLOSE,
  CLICKED_COMPLETE: ACTION_CLICKED_COMPLETE,
  CLICKED_CONTACT_US: ACTION_CLICKED_CONTACT_US,
  CLICKED_CONTINUE: ACTION_CLICKED_CONTINUE,
  CLICKED_FINISH: ACTION_CLICKED_FINISH,
  CLICKED_GET_STARTED: ACTION_CLICKED_GET_STARTED,
  CLICKED_HELP: ACTION_CLICKED_HELP,
  CLICKED_LEARN_MORE: ACTION_CLICKED_LEARN_MORE,
  CLICKED_MONTHLY: ACTION_CLICKED_MONTHLY,
  CLICKED_NAV_BAR: ACTION_CLICKED_NAV_BAR,
  CLICKED_NEXT: ACTION_CLICKED_NEXT,
  CLICKED_PLAN: ACTION_CLICKED_PLAN,
  CLICKED_PROGRESS_MENU: ACTION_CLICKED_PROGRESS_MENU,
  CLICKED_SETTINGS: ACTION_CLICKED_SETTINGS,
  CLICKED_SKIP_SETUP: ACTION_CLICKED_SKIP_SETUP,
  CLICKED_TESTIMONIAL_CTA_BUTTON: ACTION_CLICKED_TESTIMONIAL_CTA_BUTTON,
  CLICKED_TRIAL_EXPIRATION: ACTION_CLICKED_TRIAL_EXPIRATION,
  CLICKED_UPGRADE: ACTION_CLICKED_UPGRADE,
  CLICKED_VIDEO_CTA_BUTTON: ACTION_CLICKED_VIDEO_CTA_BUTTON,
  CLICKED_VIEW_PLANS: ACTION_CLICKED_VIEW_PLANS,
  COLLAPSED: ACTION_COLLAPSED,
  COMPLETED: ACTION_COMPLETED,
  COMPLETED_STEP: ACTION_COMPLETED_STEP,
  CREATED_FIRST_JOB_POST: ACTION_CREATED_FIRST_JOB_POST,
  DECREMENT: ACTION_DECREMENT,
  EMPTY_STATE_SHOWN: ACTION_EMPTY_STATE_SHOWN,
  EXPANDED: ACTION_EXPANDED,
  INCREMENT: ACTION_INCREMENT,
  MINIMIZED: ACTION_MINIMIZED,
  MODAL_CLOSED: ACTION_MODAL_CLOSED,
  MODAL_SHOWN: ACTION_MODAL_SHOWN,
  MODAL_UNMOUNTED: ACTION_MODAL_UNMOUNTED,
  POPOVER_CLOSED: ACTION_POPOVER_CLOSED,
  POPOVER_SHOWN: ACTION_POPOVER_SHOWN,
  POSITION_CREATED: ACTION_POSITION_CREATED,
  POSITION_TOGGLED: ACTION_POSITION_TOGGLED,
  SKIPPED: ACTION_SKIPPED,
  START_TRIAL_CLICKED: ACTION_START_TRIAL_CLICKED,
  TRIAL_STARTED: ACTION_TRIAL_STARTED,
  UPDATE_PLAN: ACTION_UPDATE_PLAN,
  UPSELL_CLOSED: ACTION_UPSELL_CLOSED,
  UPSELL_SHOWN: ACTION_UPSELL_SHOWN,
  VIEWED: ACTION_VIEWED,
  VIEWED_ACCOUNT_PAGE: ACTION_VIEWED_ACCOUNT_PAGE,
  VIEWED_PAGE: ACTION_VIEWED_PAGE,
  VIEWED_PLANS_PAGE: ACTION_VIEWED_PLANS_PAGE,
  VIEWED_STEP: ACTION_VIEWED_STEP,
};
