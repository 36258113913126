import EntityBase from 'data/EntityBase';
import { bulkUpdateBalances } from 'data/timeOffAccrual/actions/bulkUpdateBalances';
import { fetchBalances } from 'data/timeOffAccrual/actions/fetchBalances';
import { fetchBalancesForUser } from 'data/timeOffAccrual/actions/fetchBalancesForUser';
import { updateBalancesForUser } from 'data/timeOffAccrual/actions/updateBalancesForUser';
import TimeOffBalance, { type TimeOffBalanceFields } from 'data/timeOffAccrual/models/timeOffBalance';
import generateThunkReducers from 'data/util/generateThunkReducers';

import { createSlice } from '@reduxjs/toolkit';
import { Map } from 'immutable';

export type BalancesByUserMap = Map<TimeOffBalance['userId'], Map<TimeOffBalance['requestTypeId'], TimeOffBalance>>;

const defaultItems = Map<TimeOffBalance['userId'], Map<TimeOffBalance['requestTypeId'], TimeOffBalance>>();

const toBalanceMap = (balances: TimeOffBalanceFields[]): BalancesByUserMap => {
  if (!balances || !balances.length) {
    return defaultItems;
  }

  let items: BalancesByUserMap = Map<TimeOffBalance['userId'], Map<TimeOffBalance['requestTypeId'], TimeOffBalance>>();

  balances.map(balance => {
    items = items.setIn([balance.userId, balance.requestTypeId], new TimeOffBalance(balance));
  });

  return items;
};

export class TimeOffBalanceState extends EntityBase({
  items: defaultItems,
}) {
  declare readonly items: typeof defaultItems;
}

const initialState = new TimeOffBalanceState();

export const timeOffBalances = createSlice({
  name: 'timeOff/balances',
  initialState,
  reducers: {},
  extraReducers: builder => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchBalances.fulfilled, (state, action) => {
        return state.mergeDeep({
          items: state.items.mergeDeep(toBalanceMap(action.payload.data.balances)),
        });
      })
      .addCase(fetchBalancesForUser.fulfilled, (state, action) => {
        return state.mergeDeep({
          items: state.items.mergeDeep(toBalanceMap(action.payload.data.balances)),
        });
      })
      .addCase(updateBalancesForUser.fulfilled, (state, action) => {
        return state.mergeDeep({
          items: state.items.mergeDeep(toBalanceMap(action.payload.data.balances)),
        });
      })
      .addCase(bulkUpdateBalances.fulfilled, (state, action) => {
        return state.mergeDeep({
          items: state.items.mergeDeep(toBalanceMap(action.payload.data.balances)),
        });
      });
    generateThunkReducers(builder, 'timeOff/balances');
  },
});

export default timeOffBalances.reducer;
