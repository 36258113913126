import { bindActionCreators } from '@reduxjs/toolkit';
import { List, Map } from 'immutable';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import { getLocations } from 'data/location/selectors';
import { getPositions } from 'data/position/selectors';
import FontIcon from 'shared/ui/FontIcon';
import {
  addParticipants,
  clearTrackedFilters,
  popScreen,
  pushScreen,
  trackFilterUse,
  updateTrackedFilters,
} from 'workchat/actions';
import Loading from 'workchat/components/Loading';
import UserPicker from 'workchat/components/UserPicker';
import Header from 'workchat/containers/Header';

import 'workchat/styles/NewConversation.scss';
class AddParticipants extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    participants: PropTypes.instanceOf(Map).isRequired,
    users: PropTypes.instanceOf(Map).isRequired,
    locations: PropTypes.instanceOf(Map).isRequired,
    positions: PropTypes.instanceOf(Map).isRequired,

    popScreen: PropTypes.func.isRequired,
    pushScreen: PropTypes.func.isRequired,
    trackFilterUse: PropTypes.func.isRequired,
    updateTrackedFilters: PropTypes.func.isRequired,
    clearTrackedFilters: PropTypes.func.isRequired,
    addParticipants: PropTypes.func.isRequired,
  };

  state = {
    selectedUsers: new List(),
  };

  addParticipants = e => {
    if (e) {
      e.preventDefault();
    }

    const { selectedUsers } = this.state;
    if (!selectedUsers.size) {
      return;
    }

    const { popScreen, pushScreen, trackFilterUse, clearTrackedFilters, addParticipants } = this.props;

    popScreen();
    pushScreen(Loading);

    const addedIds = selectedUsers.toJS();
    addParticipants(addedIds).then(() => {
      popScreen();
      trackFilterUse(false, 'AddUser');
      clearTrackedFilters();
    });
  };

  back = e => {
    if (e) {
      e.preventDefault();
    }

    const { popScreen, trackFilterUse, clearTrackedFilters } = this.props;

    popScreen();
    trackFilterUse(false, 'AddUser');
    clearTrackedFilters();
  };

  setSelectedUsers = selectedUsers => {
    this.setState({ selectedUsers });
  };

  render() {
    const { user, account, users, participants, locations, positions, updateTrackedFilters, _pending } = this.props;
    const { selectedUsers } = this.state;
    const disabled = !selectedUsers.size;

    const buttons = [
      {
        title: 'Back',
        action: this.back,
        label: [<FontIcon className="mr-1" key="back" icon="chevron-left" />, 'Back'],
        side: 'left',
        color: 'secondary',
      },
      { className: 'green big', action: this.addParticipants, label: 'Done', disabled, side: 'right' },
    ];

    return (
      <div className="new-conversation">
        <Header buttons={buttons}>Add Coworkers</Header>
        <UserPicker
          user={user}
          account={account}
          users={users}
          participants={participants}
          setUsers={this.setSelectedUsers}
          locations={locations}
          positions={positions}
          enableFilters={false}
          updateTrackedFilters={updateTrackedFilters}
        />
      </div>
    );
  }
}

export default connect(
  // State to send to the Component as props
  state => ({
    participants: state.workchat.get('participants'),
    users: state.workchat.get('users') || new Map(),
    locations: getLocations(state),
    positions: getPositions(state),
  }),
  // Dispatch functions to send to the Component as props
  dispatch =>
    bindActionCreators(
      {
        popScreen,
        pushScreen,
        trackFilterUse,
        updateTrackedFilters,
        clearTrackedFilters,
        addParticipants,
      },
      dispatch,
    ),
)(AddParticipants);
