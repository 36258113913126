import type User from 'data/user/model';
import type Compensations from 'integrations/modules/gusto/models/Compensation';
import type Employee from 'integrations/modules/gusto/models/Employee';
import type GustoCompany from 'integrations/modules/gusto/models/GustoCompany';
import type MatchedUsers from 'integrations/modules/gusto/models/MatchedUsers';
import type UnmatchedLocalUser from 'integrations/modules/gusto/models/UnmatchedLocalUser';
import type UnmatchedRemoteUser from 'integrations/modules/gusto/models/UnmatchedRemoteUser';
import type { Local } from 'integrations/modules/gusto/types';

import { Map, Record } from 'immutable';

interface TGustoConfig {
  zenPayroll: Map<GustoCompany['uuid'], GustoCompany>;
  employees: Map<Employee['uuid'], Employee>;
  compensations: Map<Compensations['type'], Compensations>;
  users: Map<User['id'], User>;
  userMap: Map<string, string>;
  matched: Map<Local['id'], MatchedUsers>;
  unmatchedLocal: Map<UnmatchedLocalUser['id'], UnmatchedLocalUser>;
  unmatchedRemote: Map<UnmatchedRemoteUser['uuid'], UnmatchedRemoteUser>;
  unmatchedIgnored: Map<UnmatchedLocalUser['id'], UnmatchedLocalUser>;
  multiplePayrates: boolean;
  company: string;
}

export default class GustoConfiguration extends Record<TGustoConfig>({
  zenPayroll: Map(),
  employees: Map(),
  compensations: Map(),
  users: Map(),
  userMap: Map(),
  matched: Map(),
  unmatchedLocal: Map(),
  unmatchedRemote: Map(),
  unmatchedIgnored: Map(),
  multiplePayrates: true,
  company: '0',
}) {}
