import { getUserId } from 'shared/auth/auth';

export function getUserItem(key: string, defaultValue: any = null) {
  const itemName = `${getUserId()}:${key}`;
  // JSON.parsing the string 'null' produces null, which satisfies the type checker
  // (Even though JSON.parse(null) actually also produces null, but the type checker doesn't like that)
  const data = JSON.parse(localStorage.getItem(itemName) || 'null');
  return data !== null ? data : defaultValue;
}

export function setUserItem(key: string, value: any) {
  const itemName = `${getUserId()}:${key}`;
  return localStorage.setItem(itemName, JSON.stringify(value));
}
