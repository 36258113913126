import type Tag from 'data/tag/Tag';
import { createTag } from 'data/tag/actions/tags/createTag';
import { updateTag } from 'data/tag/actions/tags/updateTag';

export const persistTag = (tag: Tag) => {
  if (tag.id) {
    return updateTag(tag);
  }
  return createTag(tag);
};
