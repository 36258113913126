import { type ReactNode, useEffect, useState } from 'react';
import type { CSSObjectWithLabel } from 'react-select';
import Async from 'react-select/async';

import { getBranches, getCurrentVersion } from 'shared/dev/api/actions';
import { DeploymentBadge } from 'shared/dev/components/DeploymentBadge';
import type { EnvironmentsMapping, Option } from 'shared/dev/types/types';

type Props = {
  label: string;
  branchLink: ReactNode;
  currentBranch: string;
  changeBranch: (branch: Option) => void;
  projectId: number;
  environmentIds: number[];
  replaceSlashes?: boolean;
};

export default function BranchPicker({
  label,
  projectId,
  changeBranch,
  environmentIds = [],
  branchLink = null,
  currentBranch = '<Default>',
  replaceSlashes = false,
}: Props) {
  const [versions, setVersions] = useState<EnvironmentsMapping | null>({});

  useEffect(() => {
    getCurrentVersion(projectId, environmentIds).then((environments: EnvironmentsMapping | null) =>
      setVersions(environments),
    );
  }, [environmentIds]);

  const loadBranches = (query: string) => {
    return getBranches(projectId, query, replaceSlashes);
  };

  return (
    <form className="col-12 col-lg-4" data-testid="branchPickerForm">
      <div className="form-group">
        <label htmlFor={`branch-picker-${projectId}`}>{label}</label>
        <Async
          inputId={`branch-picker-${projectId}`}
          defaultOptions
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base: CSSObjectWithLabel) => ({ ...base, zIndex: 9999 }) }}
          cacheOptions
          isClearable
          name="branch"
          classNamePrefix="Select"
          value={{ label: currentBranch, value: currentBranch }}
          loadOptions={loadBranches}
          onChange={changeBranch}
          className="mb-1"
        />
        {branchLink} <DeploymentBadge projectId={projectId} />
      </div>
    </form>
  );
}
