import type { Conversation, Media, Message, Paginator, Participant } from '@twilio/conversations';

import type { ReduxParticipant } from 'workchat/v2/store/participants/participantsReducer';

export const conversationsSdkMap = new Map<string, Conversation>();
export const messagesSdkMap = new Map<string, Message>();
export const messagePaginatorsMap = new Map<string, Paginator<Message> | null>();
export const mediaSdkMap = new Map<string, Media>();
export const participantsSdkMap = new Map<string, Participant>();

const capitalize = (string: string): string => `${string[0].toUpperCase()}${string.substring(1)}`;

const getSdkObject = <T>(
  objectMap: Map<string, T>,
  sid: string,
  type: 'conversation' | 'message' | 'media' | 'participant' | 'user',
): T => {
  const sdkObject = objectMap.get(sid);

  if (!sdkObject) {
    throw new Error(`${capitalize(type)} with SID ${sid} was not found.`);
  }

  return sdkObject;
};

export const getSdkConversationObject = (conversationSid: string): Conversation =>
  getSdkObject(conversationsSdkMap, conversationSid, 'conversation');

export const getSdkMessageObject = (messageSid: string): Message => getSdkObject(messagesSdkMap, messageSid, 'message');

export const getSdkParticipantObject = (reduxParticipant: ReduxParticipant): Participant =>
  getSdkObject(participantsSdkMap, reduxParticipant.sid, 'participant');
