import 'shared/vendor/GoogleMaps';

import { type ComponentType, type Ref, Suspense, forwardRef, useEffect } from 'react';
// TODO(types): react-dnd 2.6 doesn't have any TypeScript types available.
// Version 3.0 onward does, and I think many of those early releases should be
// backwards-compatible, but I don't have time right now to change and
// verify that.
// @ts-ignore
import { DragDropContext } from 'react-dnd';
import { Route, useHistory } from 'react-router-dom';

import { useLDFlag } from 'data/LD/selectors/getLDFlag';
import type Account from 'data/account/model';
import { getAccountPlan } from 'data/account/selectors';
import type User from 'data/user/model';
import type { UserRole } from 'data/user/model';
import { openDialog } from 'dialogs';
import Dialogs from 'dialogs/containers/DialogContainer';
import Notices from 'notices/components/list';
import useNotice from 'notices/hooks/useNotice';
import { logout } from 'shared/auth/actions';
import { getStepUpTokenInfo, getTokenInfo } from 'shared/auth/auth';
import { STEP_UP_AUTH_DIALOG } from 'shared/auth/dialogs';
import { getAuthAccount, getAuthData, getAuthUser } from 'shared/auth/selectors';
import Footer from 'shared/components/Footer';
import NotAllowed from 'shared/components/NotAllowed';
import SupportButton from 'shared/components/SupportButton';
import { MainNav } from 'shared/components/nav';
import DevPanel from 'shared/dev/DevPanel';
import { useTimezoneSwitcherCleanup } from 'shared/hooks/useTimezoneSwitcherCleanup';
import { useLayout } from 'shared/layouts/context/LayoutContext';
import BrowserGate from 'shared/ui/BrowserGate';
import ErrorBoundary from 'shared/ui/ErrorBoundary';
import TermsOfServiceGate from 'shared/ui/TermsOfServiceGate';
import VerifyAccountLocationGate from 'shared/ui/VerifyAccountLocationGate';
import Overlay from 'shared/ui/loaders/Overlay';
import DndBackend from 'shared/util/DndBackend';
import { useWiwDispatch, useWiwSelector } from 'store';

import classNames from 'classnames';
import type Plan from 'data/plan/model';

export interface Props {
  component: ComponentType<any>;
  hasRole?: UserRole[];
  hasAccountCriteria?: (account: Account) => boolean;
  hasAccountPlanCriteria?: (accountPlan: Plan) => boolean;
  hasUserCriteria?: (user: User) => boolean;
  hasFlag?: string;
  elseRedirect?: string;
  allowLimited?: boolean;
  hideFooter?: boolean;
  hideNav?: boolean;
  requiresStepUpAuth?: boolean;
}

const defaultProps = {
  allowLimited: false,
};

export function MainLayout(props: Props, ref: Ref<any>) {
  props = { ...defaultProps, ...props };

  const { hideNav, showFooter, footerVisible, headerVisible } = useLayout();
  const dispatch = useWiwDispatch();
  const auth = useWiwSelector(getAuthData);
  const account = useWiwSelector(getAuthAccount);
  const accountPlan = useWiwSelector(getAccountPlan);
  const history = useHistory();
  const user = useWiwSelector(getAuthUser);
  const LDFlags = useWiwSelector(state => state.LD);
  const pay536StepUpAuthentication = useLDFlag('pay-536-step-up-authentication');
  const { warningNotice } = useNotice();

  useTimezoneSwitcherCleanup();

  useEffect(() => {
    if (props.hideNav) {
      hideNav?.();
    }
    if (!props.hideFooter) {
      showFooter?.();
    }
  }, [props.hideNav, props.hideFooter, showFooter, hideNav]);

  useEffect(() => {
    if (getTokenInfo().isLimited() && !props.allowLimited) {
      logout();
    }
  }, [props.allowLimited]);

  useEffect(() => {
    if (auth.isStepUpAuthIdle && props.requiresStepUpAuth) {
      warningNotice('Your authorization expired while accessing sensitive information.', { duration: 0 });
      history.push('/');
    }
  }, [auth.isStepUpAuthIdle, props.requiresStepUpAuth]);

  const userHasRole = (role: UserRole[] | undefined) => {
    if (role && !auth.user!.can(role)) {
      return false;
    }
    return true;
  };

  const userHasFlag = (flag: string | undefined) => {
    if (flag && LDFlags.isLDReady && LDFlags[flag] !== true) {
      return false;
    }
    return true;
  };

  const checkAccountCriteria = () => {
    const { hasAccountCriteria } = props;
    if (!hasAccountCriteria) {
      return true;
    }

    return hasAccountCriteria(account);
  };

  const checkAccountPlanCriteria = () => {
    const { hasAccountPlanCriteria } = props;
    if (!hasAccountPlanCriteria) {
      return false;
    }

    return hasAccountPlanCriteria(accountPlan);
  };

  const checkUserCriteria = () => {
    const { hasUserCriteria } = props;
    if (!hasUserCriteria) {
      return true;
    }

    return hasUserCriteria(user);
  };

  const stepUpAuthConfirmed = () => {
    const { component: Component, elseRedirect, ...rest } = props;
    return <Route ref={ref} {...rest} render={props => <Component {...props} />} />;
  };

  const checkRequiresStepUpAuth = () => {
    const { component: Component, elseRedirect, ...rest } = props;

    const stepUpTokenInfo = getStepUpTokenInfo();
    // Only show dialog if the token is inValid and you are not idle
    // If you were idle, then no reason to show the dialog. This happens on a redirect when user timesout
    if (!stepUpTokenInfo?.isValidStepUpToken() && !auth.isStepUpAuthIdle) {
      dispatch(
        openDialog(STEP_UP_AUTH_DIALOG, {
          successCallback: () => stepUpAuthConfirmed(),
          onCancelRedirect: elseRedirect,
        }),
      );
      return false;
    }
    return <Route ref={ref} {...rest} render={props => <Component {...props} />} />;
  };

  const renderBody = () => {
    const { component: Component, hasRole, hasFlag, elseRedirect, requiresStepUpAuth, ...rest } = props;

    // we want the plan to override the account criteria
    let meetsAccountCriteria = false;
    if (checkAccountPlanCriteria()) {
      meetsAccountCriteria = true;
    } else if (checkAccountCriteria()) {
      meetsAccountCriteria = true;
    }

    if (!userHasRole(hasRole) || !userHasFlag(hasFlag) || !checkUserCriteria() || !meetsAccountCriteria) {
      if (elseRedirect) {
        window.location.assign(elseRedirect);
        return false;
      }
      return <NotAllowed />;
    }

    if (pay536StepUpAuthentication && requiresStepUpAuth) {
      return checkRequiresStepUpAuth();
    }

    return <Route ref={ref} {...rest} render={props => <Component {...props} />} />;
  };

  return (
    <ErrorBoundary>
      <BrowserGate>
        <div id="wheniwork-is-awesome">
          <TermsOfServiceGate>
            <VerifyAccountLocationGate>
              <div id="content" className="content">
                <a href="#main-content" className="sr-only">
                  Skip to main content
                </a>
                {headerVisible && <MainNav />}
                <div className="container-fluid notice-container px-0">
                  <div className="row no-gutters">
                    <div className="col">
                      <Notices maxVisible={5} followWindow />
                    </div>
                  </div>
                </div>
                <div className={classNames('content--inside', { 'no-nav': !headerVisible })} id="main-content">
                  <Suspense fallback={<Overlay />}>{renderBody()}</Suspense>
                  {footerVisible && <Footer />}
                </div>
              </div>
              <SupportButton />
            </VerifyAccountLocationGate>
          </TermsOfServiceGate>
          <Dialogs />
          <DevPanel />
        </div>
      </BrowserGate>
    </ErrorBoundary>
  );
}

export default DragDropContext(DndBackend)(forwardRef(MainLayout));
