import type { ReportMetadataRequestParams } from 'data/reporting/actions/reportMetadataRequestType';
import type { ReportMetadataResponse } from 'data/reporting/models/ReportMetadataResponse';
import { getCustomMetadata } from 'data/reporting/selectors/getCustomMetadata';
import type { CustomMetadataState } from 'data/reporting/state/CustomMetadataState';
import { request } from 'shared/auth/request';
import { withMatcher } from 'shared/util/withMatcher';
import type { WiwDispatch, WiwGetState, WiwState } from 'store';
import { FETCH_CUSTOM_REPORT_METADATA } from 'store/action-types';

import { DateTime } from 'luxon';

const reportRequest = request(CONFIG.REPORTS_API, { headers: { 'Content-Type': 'application/json' } });

export const fetchCustomReportMetadataFailure = withMatcher((params: ReportMetadataRequestParams, error: unknown) => ({
  type: FETCH_CUSTOM_REPORT_METADATA.FAILURE,
  params,
  error,
}));

export const fetchCustomReportMetadataRequested = withMatcher((params: ReportMetadataRequestParams) => ({
  type: FETCH_CUSTOM_REPORT_METADATA.REQUEST,
  params,
}));

export const fetchCustomReportMetadataSuccess = withMatcher(
  (params: ReportMetadataRequestParams, response: ReportMetadataResponse) => ({
    type: FETCH_CUSTOM_REPORT_METADATA.SUCCESS,
    params,
    response,
  }),
);

function shouldFetch({
  state,
  accountId,
  dataset,
  minsStale,
}: {
  state: WiwState;
  accountId: string;
  dataset: string;
  minsStale: number;
}) {
  const customMetadata = getCustomMetadata(state);
  const metadata = customMetadata.getIn([`${accountId}`, dataset]) as CustomMetadataState;

  // Fetching because never loaded
  if (!metadata || (!metadata.loading && !metadata.loaded)) {
    return true;
  }

  // Fetch if stale and not currently loading
  if (
    minsStale !== Number.POSITIVE_INFINITY &&
    metadata.get('receivedAt') != null &&
    !metadata.loading &&
    DateTime.now().minus({ minutes: minsStale }) > DateTime.fromMillis(metadata.get('receivedAt'))
  ) {
    return true;
  }

  return false;
}

export function fetchCustomReportMetadataIfNeeded(params: ReportMetadataRequestParams, minsStale = 30) {
  return (dispatch: WiwDispatch, getState: WiwGetState) => {
    if (!params.accountId) {
      return Promise.reject('missing accountId');
    }
    if (!params.dataset) {
      return Promise.reject('missing dataset');
    }

    if (
      !shouldFetch({
        state: getState(),
        accountId: params.accountId,
        dataset: params.dataset,
        minsStale,
      })
    ) {
      return Promise.resolve();
    }

    dispatch(fetchCustomReportMetadataRequested(params));
    return reportRequest
      .post('/custom/get_metadata', params)
      .then(response => dispatch(fetchCustomReportMetadataSuccess(params, response)))
      .catch(error => {
        dispatch(fetchCustomReportMetadataFailure(params, error));
        throw error;
      });
  };
}
