import { type Ref, forwardRef } from 'react';
import { type FieldValues, type UseControllerProps, useController } from 'react-hook-form';

import getFieldError from 'shared/form/helpers/getFieldError';
import useMapCustomValidators from 'shared/form/hooks/useMapCustomValidators';
import type { InputProps } from 'shared/form/types';

import { NumberInput as MantineNumberInput, type NumberInputProps as MantineNumberInputProps } from '@mantine/core';
import { useMergedRef } from '@mantine/hooks';

export type NumberInputProps<T extends FieldValues> = InputProps<UseControllerProps<T>> &
  Omit<MantineNumberInputProps, 'value' | 'defaultValue' | 'onChange'>;

export default forwardRef(function NumberInput<T extends FieldValues>(
  { name, control, defaultValue, shouldUnregister, onChange, ...props }: NumberInputProps<T>,
  ref: Ref<HTMLInputElement>,
) {
  const rules = useMapCustomValidators(props, true);

  const {
    field: { value, onChange: fieldOnChange, ref: insideRef, ...field },
    fieldState,
  } = useController<T>({
    name,
    control,
    defaultValue,
    rules,
    shouldUnregister,
  });

  const mergedRefs = useMergedRef(insideRef, ref);

  return (
    <MantineNumberInput
      ref={mergedRefs}
      value={value}
      onChange={number => {
        fieldOnChange(number);
        onChange?.(name, number);
      }}
      error={getFieldError(fieldState)}
      data-testid={name}
      mb="md"
      {...field}
      {...props}
    />
  );
});
