import { lazy } from 'react';

const EditTimeDialog = lazy(() => import('./EditTimeDialog'));

export const EDIT_TIME_TRACKER_DIALOG = 'EDIT_TIME_TRACKER_DIALOG';

export default {
  [EDIT_TIME_TRACKER_DIALOG]: EditTimeDialog,
  // Add more here ...
};
