import type { FocusedOnboardingItems, SchedulerOnboardingItems } from 'onboarding/constants';
import type { EmployeeOnboardingStatus } from 'onboarding/constants';
import OnboardingState from 'onboarding/state';

import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

export const initialState = new OnboardingState();

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setOpenGuideItem(state, action: PayloadAction<SchedulerOnboardingItems | FocusedOnboardingItems | undefined>) {
      return state.withMutations(item => {
        item.set('openGuideItem', action.payload);
      });
    },

    setForceGuideItem(state, action: PayloadAction<SchedulerOnboardingItems | FocusedOnboardingItems | undefined>) {
      return state.withMutations(item => {
        item.set('forceGuideItem', action.payload);
      });
    },

    setNewlyCreatedShiftId(state, action: PayloadAction<number>) {
      return state.withMutations(item => {
        item.set('newlyCreatedShiftId', action.payload);
      });
    },

    setEmployeeOnboardingStatus(state, action: PayloadAction<EmployeeOnboardingStatus>) {
      return state.withMutations(item => {
        item.set('employeeOnboardingStatus', action.payload);
      });
    },
  },
});

export const { setOpenGuideItem, setNewlyCreatedShiftId, setForceGuideItem, setEmployeeOnboardingStatus } =
  onboardingSlice.actions;

export default onboardingSlice.reducer;
