import {
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';

import { UserRole } from 'data/user/model';
import { getAuthUser } from 'shared/auth/selectors';
import { useWiwSelector } from 'store';

type RoleBasedMessageActions = {
  canDelete: boolean;
};

const baseActions: RoleBasedMessageActions = { canDelete: false };

type MessageContextApi = {
  menuOpenedForMessage: string | null;
  setMenuOpenedForMessage: Dispatch<SetStateAction<string | null>>;
  getMessageActionsForAuthorRole: (authorRole?: UserRole) => RoleBasedMessageActions;
};

const MessageContext = createContext<MessageContextApi>({
  menuOpenedForMessage: null,
  setMenuOpenedForMessage: () => {},
  getMessageActionsForAuthorRole: () => baseActions,
});

export const MessageProvider = ({ children }: PropsWithChildren<any>) => {
  const [menuOpenedForMessage, setMenuOpenedForMessage] = useState<string | null>(null);

  const user = useWiwSelector(getAuthUser);
  const myRole = user.role;

  const getMessageActionsForAuthorRole = useMemo(
    () =>
      (authorRole?: UserRole): RoleBasedMessageActions => {
        switch (myRole) {
          case UserRole.Admin: {
            return { ...baseActions, canDelete: true };
          }
          case UserRole.Manager: {
            return {
              ...baseActions,
              canDelete: !!(authorRole && [UserRole.Supervisor, UserRole.Employee].includes(authorRole)),
            };
          }
          default:
            return baseActions;
        }
      },
    [myRole],
  );

  return (
    <MessageContext.Provider
      value={{
        menuOpenedForMessage,
        setMenuOpenedForMessage,
        getMessageActionsForAuthorRole,
      }}
    >
      {children}
    </MessageContext.Provider>
  );
};

const useMessageProvider = () => {
  const { menuOpenedForMessage, setMenuOpenedForMessage, getMessageActionsForAuthorRole } = useContext(MessageContext);

  return { menuOpenedForMessage, setMenuOpenedForMessage, getMessageActionsForAuthorRole };
};

export default useMessageProvider;
