import filesAvailable from 'data/payrollCheck/reducers/filesAvailableReducer';
import payrollDetails from 'data/payrollCheck/reducers/payrollsDetailsReducer';
import payrollsSummaries from 'data/payrollCheck/reducers/payrollsSummaryReducer';
import postTaxDeductionsReducer from 'data/payrollCheck/reducers/postTaxDeductionsReducer';

import { combineReducers } from '@reduxjs/toolkit';

export default combineReducers({
  summaries: payrollsSummaries,
  details: payrollDetails,
  files: filesAvailable,
  postTaxDeductions: postTaxDeductionsReducer,
});
