import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import { updateNotificationUser } from 'data/notifications/actions';
import Notification from 'data/notifications/model';
import User from 'data/user/model';
import { openDialog } from 'dialogs/actions';
import { ActionButton } from 'notifications/components/ActionButton';
import { NotifyItem } from 'notifications/components/NotifyItem';
import AvatarImg from 'shared/ui/AvatarImg';
import { USER_MERGE_DIALOG } from 'users/dialogs';

import { bindActionCreators } from '@reduxjs/toolkit';

export class UserApproval extends Component {
  state = {
    mergedUser: null,
  };

  static propTypes = {
    user: PropTypes.instanceOf(User).isRequired,
    notification: PropTypes.instanceOf(Notification),
    openDialog: PropTypes.func.isRequired,
    updateNotificationUser: PropTypes.func.isRequired,
  };

  approveUser = e => {
    e.stopPropagation();
    return this.props.openDialog(USER_MERGE_DIALOG, { user: this.props.user, onConfirm: this.userApproved });
  };

  getActions() {
    return [
      <ActionButton key="approve" title="Approve" icon="fa-check-circle" type="green" click={this.approveUser} />,
    ];
  }

  getDescription() {
    return ' has registered for the schedule.';
  }

  getMessage() {
    return ' created an account.';
  }

  getStatus() {
    if (!this.props.user.activated) {
      return { name: 'Pending', style: '' };
    }
  }

  getTitle() {
    return this.props.user.shortName;
  }

  getAvatar() {
    return <AvatarImg user={this.props.user} size={Notification.AVATAR_SIZE} />;
  }

  userApproved = user => {
    this.setState({ mergedUser: user });
  };

  redirect = () => {
    window.location.assign('../../users');
  };

  updateUser = () => {
    if (this.state.mergedUser) {
      this.props.updateNotificationUser(this.state.mergedUser);
    }
  };

  render() {
    return (
      <NotifyItem
        title={this.getTitle()}
        description={this.props.notification ? this.getMessage() : this.getDescription()}
        tag={this.getStatus()}
        avatar={this.getAvatar()}
        click={this.redirect}
        deleted={!!this.state.mergedUser}
        remove={this.updateUser}
        {...(this.props.notification
          ? {}
          : {
              actions: this.getActions(),
            })}
      />
    );
  }
}

export default connect(null, dispatch =>
  bindActionCreators(
    {
      openDialog,
      updateNotificationUser,
    },
    dispatch,
  ),
)(UserApproval);
