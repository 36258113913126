import { Fragment, type ReactNode } from 'react';

import Button from 'shared/ui/Button';
import { Panel, PanelBody } from 'shared/ui/Panel';

import * as Sentry from '@sentry/react';

import 'shared/ui/assets/styles/ErrorBoundary.scss';

type ErrorBoundaryProps = {
  children: ReactNode;
};

export default function ErrorBoundary({ children }: ErrorBoundaryProps) {
  const ErrorComponent = () => {
    return (
      <div className="error-boundary-aligner d-flex justify-content-center align-items-center">
        <Panel className="error-boundary">
          <PanelBody>
            <div className="row">
              <div className="col">
                <div className="row justify-content-md-center">
                  <h1 className="error-title" id="error-title">
                    Well this is embarrassing!
                  </h1>
                </div>
                <div className="row justify-content-md-center">
                  <h6 className="error-text">
                    It appears we have encountered an error. Please try reloading the page.
                  </h6>
                </div>
                <div className="row justify-content-md-center">
                  <Button className="mt-3 error-button" size="lg" onClick={() => window.location.reload()}>
                    Refresh
                  </Button>
                </div>
              </div>
            </div>
          </PanelBody>
        </Panel>
      </div>
    );
  };
  return (
    <Sentry.ErrorBoundary fallback={<ErrorComponent />}>
      <Fragment>{children}</Fragment>
    </Sentry.ErrorBoundary>
  );
}
