import { lazy } from 'react';

const TalkToASpecialistDialog = lazy(() => import('marketing/dialogs/TalkToASpecialistDialog'));
const MissingEmployeeDialog = lazy(() => import('marketing/dialogs/MissingEmployeesDialog'));
const ScheduleDemoDialog = lazy(() => import('marketing/dialogs/ScheduleDemoDialog'));
const TrialReactivationDialog = lazy(() => import('marketing/dialogs/TrialReactivationDialog'));

// Identifiers for each dialog
export const MISSING_EMPLOYEES_DIALOG = 'MISSING_EMPLOYEES_DIALOG';
export const SCHEDULE_DEMO_DIALOG = 'SCHEDULE_DEMO_DIALOG';
export const TRIAL_REACTIVATION_DIALOG = 'TRIAL_REACTIVATION_DIALOG';
export const TALK_TO_A_SPECIALIST_DIALOG = 'TALK_TO_A_SPECIALIST_DIALOG';

const marketingDialogs = {
  [MISSING_EMPLOYEES_DIALOG]: MissingEmployeeDialog,
  [SCHEDULE_DEMO_DIALOG]: ScheduleDemoDialog,
  [TRIAL_REACTIVATION_DIALOG]: TrialReactivationDialog,
  [TALK_TO_A_SPECIALIST_DIALOG]: TalkToASpecialistDialog,
};

export default marketingDialogs;
