import { lazy } from 'react';

const SiteDialog = lazy(() => import('./SiteDialog'));
const ImportSitesDialog = lazy(() => import('./ImportSitesDialog'));

// Identifiers for each dialog
export const SITE_DIALOG = 'SITE_DIALOG';
export const IMPORT_SITES_DIALOG = 'IMPORT_SITES_DIALOG';

const dialogs = {
  [SITE_DIALOG]: SiteDialog,
  [IMPORT_SITES_DIALOG]: ImportSitesDialog,
  // ...
};

export default dialogs;

/* To open a dialog, do this
import { openDialog, closeDialog } from 'dialogs';
import { SITE_DIALOG } from 'sites/dialogs';

// Bind these with redux
openDialog(SITE_DIALOG, { swap, otherProp, payload });
closeDialog(SITE_DIALOG);
*/
