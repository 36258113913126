import {
  type HTMLProps,
  type MouseEventHandler,
  type ReactNode,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';

import SpinningLoader from 'shared/ui/loaders/SpinningLoader';

import classNames from 'classnames';
import { omit } from 'lodash';

export interface ButtonProps extends Omit<HTMLProps<HTMLButtonElement>, 'size'> {
  active?: boolean;
  children?: ReactNode;
  circle?: boolean;
  className?: string;
  color?:
    | 'primary'
    | 'primary-gold'
    | 'secondary'
    | 'info'
    | 'warning'
    | 'danger'
    | 'link'
    | 'custom'
    | 'outline-danger'
    | 'dark'
    | 'light';
  disabled?: boolean;
  icon?: string;
  id?: string;
  loading?: boolean;
  newStyle?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  setFocus?: boolean;
  size?: 'sm' | 'md' | 'lg';
  type?: 'button' | 'reset' | 'submit';
  ariaDisabled?: boolean;
}

const Button = forwardRef((props: ButtonProps, ref) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  useImperativeHandle(ref, () => buttonRef.current);

  useEffect(() => {
    if (props.setFocus && buttonRef.current) {
      buttonRef.current.focus();
    }
  }, []);

  const renderLoadingState = () => {
    return (
      <div className="button-loader">
        <span>{props.children}</span> <SpinningLoader width={14} height={17} />
      </div>
    );
  };

  const classes = classNames('btn', `btn-${props.color}`, props.className, {
    'btn-circle': props.circle,
    [`btn-${props.size}`]: !!props.size,
    working: props.loading,
    newStyle: props.newStyle,
    active: props.active,
  });
  return (
    <button
      ref={buttonRef}
      {...omit(props, ['loading', 'icon', 'children', 'circle', 'color', 'size', 'newStyle', 'active', 'setFocus'])}
      id={props.id}
      type={props.type}
      className={classes}
      disabled={props.disabled || props.loading}
      aria-disabled={props.ariaDisabled || props.disabled || props.loading}
      onClick={props.onClick}
    >
      {props.loading ? (
        renderLoadingState()
      ) : (
        <div>
          {props.icon && <img className="button-icon" src={props.icon} alt="icon" />}
          {props.children}
        </div>
      )}
    </button>
  );
});

Button.displayName = 'Button';

Button.defaultProps = {
  active: false,
  disabled: false,
  type: 'button',
  color: 'primary',
  size: 'md',
  loading: false,
  circle: false,
  newStyle: false,
  setFocus: false,
};

export default Button;
