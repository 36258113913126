import PropTypes from 'prop-types';
/* istanbul ignore file */
import { Component } from 'react';

import { Panel, PanelBody, PanelTitle } from 'shared/ui/Panel';

import browser from 'bowser';

import chrome from 'shared/ui/assets/img/browsers/chrome.svg';
import edge from 'shared/ui/assets/img/browsers/edge-icon.svg';
import firefox from 'shared/ui/assets/img/browsers/firefox.svg';
import safari from 'shared/ui/assets/img/browsers/safari.svg';
import 'shared/ui/assets/styles/BrowserGate.scss';
import wiwLogo from 'styles/assets/img/wiw-logo.svg';

class BrowserGate extends Component {
  static propTypes = {
    children: PropTypes.node,
  };

  minimumSupportedBrowserVersion = {
    chrome: '50',
    firefox: '46',
    safari: '10',
    msedge: '83',
    opera: '12',
    msie: '12',
  };

  browserDownloadLinks = {
    chrome: 'http://www.google.com/chrome',
    firefox: 'http://www.mozilla.org/en-US/firefox/new/',
    safari: 'http://www.apple.com/safari/',
    msedge: 'https://www.microsoft.com/en-us/edge',
  };

  render() {
    window.browserSupported = browser.isUnsupportedBrowser;
    window.browserdetect = browser;

    if (browser.check(this.minimumSupportedBrowserVersion, window.location.userAgent)) {
      return this.props.children;
    }
    return (
      <div className="container browser-gate">
        <div className="row justify-content-center px-0 mx-0">
          <div className="logo text-center col">
            <img src={wiwLogo} alt="When I Work" />
            <div className="logo-lines" />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Panel>
              <PanelTitle>Please upgrade your browser to use When I Work!</PanelTitle>
              <PanelBody>
                <div className="row">
                  <div className="col text-center">
                    <p>
                      When I Work is built using the latest web technology and your browser doesn’t support some of the
                      awesome features that make When I Work so great!
                    </p>
                    <p>Fortunately, upgrading your browser is easy and free!</p>
                    <p>
                      <strong>Download one of these modern browsers and you’ll be good to go:</strong>
                    </p>
                  </div>
                </div>
                <div className="row align-items-bottom justify-content-center text-center mt-3">
                  <div className="col">
                    <a href={this.browserDownloadLinks.msedge}>
                      <img src={edge} alt="Edge" className="browser-logo mb-2" />
                      <div className="browser-title">Edge</div>
                      <div className="browser-version">Version {this.minimumSupportedBrowserVersion.msedge}+</div>
                    </a>
                  </div>
                  <div className="col">
                    <a href={this.browserDownloadLinks.firefox}>
                      <img src={firefox} alt="Firefox" className="browser-logo mb-2" />
                      <div className="browser-title align-text-bottom">Firefox</div>
                      <div className="browser-version align-text-bottom">
                        Version {this.minimumSupportedBrowserVersion.firefox}+
                      </div>
                    </a>
                  </div>
                  <div className="col">
                    <a href={this.browserDownloadLinks.chrome}>
                      <img src={chrome} alt="Chrome" className="browser-logo mb-2" />
                      <div className="browser-title">Chrome</div>
                      <div className="browser-version">Version {this.minimumSupportedBrowserVersion.chrome}+</div>
                    </a>
                  </div>
                  <div className="col">
                    <a href={this.browserDownloadLinks.safari}>
                      <img src={safari} alt="Safari" className="browser-logo mb-2" />
                      <div className="browser-title">Safari</div>
                      <div className="browser-version">Version {this.minimumSupportedBrowserVersion.safari}+</div>
                    </a>
                  </div>
                </div>
                <div className="row mt-6">
                  <div className="col text-center">
                    <p>
                      <small>
                        Already using a modern browser and still have problems?{' '}
                        <a href="http://support.wheniwork.com">Get in touch with our support team.</a>
                      </small>
                    </p>
                  </div>
                </div>
              </PanelBody>
            </Panel>
          </div>
        </div>
      </div>
    );
  }
}

export default BrowserGate;
