import BaseModel from 'data/BaseModel';

export interface OvertimeLaborCostFields {
  breakTime: number;
  dailyDoubleOt: number;
  dailyOt: number;
  doubleOtRate: number;
  end: any | null; // TODO(types)
  startOfDay: any | null; // TODO(types)
  hourlyRate: number;
  id: number;
  locationId: number;
  otRate: number;
  positionId: number;
  siteId: number;
  regular: number;
  start: any | null; // TODO(types)
  userId: number;
  weeklyOt: number;
}

class OvertimeLaborCost extends BaseModel<OvertimeLaborCostFields>({
  breakTime: 0,
  dailyDoubleOt: 0,
  dailyOt: 0,
  doubleOtRate: 0,
  end: null,
  startOfDay: null,
  hourlyRate: 0,
  id: 0,
  locationId: 0,
  otRate: 0,
  positionId: 0,
  siteId: 0,
  regular: 0,
  start: null,
  userId: 0,
  weeklyOt: 0,
}) {
  makeId() {
    return `${this.id}${this.start}`;
  }
}

export default OvertimeLaborCost;
