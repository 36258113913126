import { DIALOG_CLOSE, NOTICE_ADD, NOTICE_REMOVE, NOTICE_TOAST, ROUTE_SET } from 'store/action-types';

import { combineReducers } from '@reduxjs/toolkit';

export function items(state = [], action) {
  switch (action.type) {
    case NOTICE_ADD:
      return [...state, action.notice];
    case NOTICE_REMOVE:
      return state.filter(notice => notice.noticeId !== action.noticeId);
    case NOTICE_TOAST:
      return state;
    case DIALOG_CLOSE:
      return state.filter(notice => notice.target !== 'dialog');
    case ROUTE_SET:
      return state.filter(notice => {
        const show = notice.isPersistent || notice.displayOnNextPage;
        if (notice.displayOnNextPage) {
          notice.displayOnNextPage = false;
        }
        return show;
      });
  }
  return state;
}

export default combineReducers({
  items,
});
