import { getCustomMetadata } from 'data/reporting/selectors/getCustomMetadata';
import type { CustomMetadataState } from 'data/reporting/state/CustomMetadataState';
import { getReportAccountId } from 'reporting/selectors/getReportAccountId';
import { getReportDataset } from 'reporting/selectors/getReportDataset';

import { createSelector } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';

const getRawReportingPeriodStart = createSelector(
  getCustomMetadata,
  getReportAccountId,
  getReportDataset,
  (metadata, reportAccountId, dataset) => {
    return (metadata.getIn([`${reportAccountId}`, dataset]) as CustomMetadataState)?.reportingPeriodStart;
  },
);

export const getReportingPeriodStart = createSelector(
  [getRawReportingPeriodStart, getReportDataset],
  (reportingPeriodStart: DateTime | null, dataset: string | undefined) => {
    // Initialized here to accommodate account's time zone
    const earliestAllowed = DateTime.local(2022, 1, 1);

    if (!reportingPeriodStart) {
      return null;
    }

    if (dataset === 'shift-history' && reportingPeriodStart < earliestAllowed) {
      return earliestAllowed;
    }

    return reportingPeriodStart;
  },
);
