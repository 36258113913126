import type { DeleteDocumentAction } from 'data/documents/actions/deleteDocument';
import type { FetchDocumentsAction } from 'data/documents/actions/fetchDocuments';
import type { UploadDocumentsAction } from 'data/documents/actions/uploadDocuments';
import { DocumentsModel } from 'data/documents/models/DocumentsModel';
import DocumentState from 'data/documents/state';
import { DELETE_DOCUMENTS_BULK, FETCH_DOCUMENTS, UPLOAD_DOCUMENTS } from 'store/action-types';

export type DocumentsAction = DeleteDocumentAction | FetchDocumentsAction | UploadDocumentsAction;

export default function (state = new DocumentState(), action: DocumentsAction) {
  switch (action.type) {
    case FETCH_DOCUMENTS.FAILURE:
      return state.merge({
        loaded: false,
        loading: false,
        error: action.payload,
      });
    case FETCH_DOCUMENTS.REQUEST:
      return state.merge({
        loaded: false,
        loading: true,
        error: null,
      });
    case FETCH_DOCUMENTS.SUCCESS:
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        items: state.items.clear().add(action.payload.map(document => new DocumentsModel(document))),
      });
    case UPLOAD_DOCUMENTS.SUCCESS:
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        items: state.items.delete(null).add(action.items.map(document => new DocumentsModel(document))),
      });
    case DELETE_DOCUMENTS_BULK.REQUEST:
      return state.merge({
        loaded: false,
        loading: true,
        error: null,
      });
    case DELETE_DOCUMENTS_BULK.SUCCESS:
      return state.merge({
        items: state.items.delete(action.documents.map(doc => doc.id)),
        loaded: false,
        loading: true,
        error: null,
      });
    case DELETE_DOCUMENTS_BULK.FAILURE:
      return state.merge({
        loaded: false,
        loading: true,
        error: action.error,
        items: state.items.delete(action.documents.map(doc => doc.id)),
      });
    default:
      return state;
  }
}
