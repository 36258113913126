import { createSlice } from '@reduxjs/toolkit';
import EntityState from 'data/EntityState';
import { fetchPunchStateV2, fetchPunchStateV3 } from 'data/punchState/actions/fetchPunchState';
import { PunchState } from 'data/punchState/models';
import generateThunkReducers from 'data/util/generateThunkReducers';

const initialState = new EntityState<PunchState>();

export const punchStateSlice = createSlice({
  name: 'punchState',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchPunchStateV2.fulfilled, (state, action) => {
        const userId = action.meta?.arg?.userId ?? action.meta?.userId;
        return state.mergeDeep({
          items: state.items.set(userId, new PunchState(action.payload)),
        });
      })
      .addCase(fetchPunchStateV3.fulfilled, (state, action) => {
        const userId = action.meta?.arg?.userId ?? action.meta?.userId;
        return state.mergeDeep({
          items: state.items.set(userId, new PunchState(action.payload)),
        });
      });
    generateThunkReducers(builder, 'punchState');
  },
});

export default punchStateSlice.reducer;
