import { getUsers } from 'data/user/selectors';
import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getAuthAccount, getAuthUser } from 'shared/auth/selectors';
import FontIcon from 'shared/ui/FontIcon';
import { useWiwDispatch, useWiwSelector } from 'store';
import { popScreen, pushScreen, setConversation } from 'workchat/actions';
import { getConnected } from 'workchat/selectors/getConnected';
import { getConversation } from 'workchat/selectors/getConversation';
import { getFailed } from 'workchat/selectors/getFailed';
import { getFlags } from 'workchat/selectors/getFlags';
import { getMessages } from 'workchat/selectors/getMessages';
import { getParticipants } from 'workchat/selectors/getParticipants';
import { getPending } from 'workchat/selectors/getPending';
import { getSending } from 'workchat/selectors/getSending';

import ConversationTitle from 'workchat/components/ConversationTitle';
import ErrorBanner from 'workchat/components/ErrorBanner';
import Loading from 'workchat/components/Loading';
import MessageInput from 'workchat/components/MessageInput';
import MessageLog from 'workchat/components/MessageLog';
import ParticipantList from 'workchat/components/ParticipantList';
import ChannelDetail from 'workchat/containers/ChannelDetail';
import Header, { type HeaderButton } from 'workchat/containers/Header';
import useTwilioProvider from 'workchat/hooks/useTwilioProvider';
import { getWorkchatUsers } from 'workchat/selectors/getWorkchatUsers';
import type { TwilioUser } from 'workchat/types/TwilioUser';

import 'workchat/styles/ViewConversation.scss';

export type ViewConversationProps = {
  showOpenInMessenger: boolean;
};

export default function ViewConversation({ showOpenInMessenger }: ViewConversationProps) {
  const dispatch = useWiwDispatch();
  const provider = useTwilioProvider();
  const history = useHistory();
  const user = useWiwSelector(getAuthUser);
  const accountUsers = useWiwSelector(getUsers);
  const workchatUsers = useWiwSelector(getWorkchatUsers);

  const account = useWiwSelector(getAuthAccount);
  const conversation = useWiwSelector(getConversation);
  const flags = useWiwSelector(getFlags);
  const connected = useWiwSelector(getConnected);
  const messages = useWiwSelector(getMessages);
  const pending = useWiwSelector(getPending);
  const failed = useWiwSelector(getFailed);
  const sending = useWiwSelector(getSending);
  const participants = useWiwSelector(getParticipants);

  const [error, setError] = useState<string | null>(null);
  const [broadcast, setBroadcast] = useState(false);

  // Decorate TwilioUsers with field(s) from Users (i.e. role)
  const users = useMemo(
    () =>
      workchatUsers.map((user: TwilioUser, key: string) => {
        return { ...user, role: accountUsers.get(+key)?.role };
      }),
    [workchatUsers, accountUsers],
  );

  let errorTimeout: ReturnType<typeof setTimeout>;

  const clearError = () => {
    setError(null);
  };

  const buildButtons = () => {
    const buttons: HeaderButton[] = [];

    if (flags.get('conversations')) {
      buttons.push({
        title: 'Back',
        color: 'secondary',
        action: back,
        label: [<FontIcon className="mr-1" key="back" icon="chevron-left" />, 'Back'],
        side: 'left',
      });
    } else {
      setBroadcast(true);
    }

    if (conversation?.attributes.external_type !== 'account') {
      buttons.push({
        title: 'Details',
        action: showDetails,
        label: <FontIcon icon="gear" />,
        side: 'right',
        circle: true,
        color: 'secondary',
      });
    }

    if (showOpenInMessenger) {
      buttons.push({
        title: 'Open in Messenger',
        action: openWorkchatMessenger,
        label: <FontIcon icon="chat" />,
        side: 'right',
        circle: true,
        color: 'secondary',
      });
    }
    return buttons;
  };

  const back = () => {
    if (conversation) {
      provider?.setConversation(null);
    }

    dispatch(popScreen());
  };

  const showDetails = () => {
    dispatch(pushScreen(ChannelDetail));
  };

  const openWorkchatMessenger = () => {
    history.push('/workchat');
  };

  const sendMessage = (text: string, image: any) => {
    if (!sending && (text || image)) {
      provider?.sendMessage(text, image);
    }
  };

  const updateConversation = () => {
    dispatch(setConversation(conversation));
  };

  const setStateError = (msg: string, life = 10) => {
    setError(msg);
    if (msg) {
      errorTimeout = setTimeout(() => {
        setError(null);
      }, life * 1000);
    } else {
      clearTimeout(errorTimeout);
    }
  };

  if (!conversation) {
    return <Loading />;
  }

  const sendingAllowed = () => {
    if (conversation.attributes.external_type === 'account') {
      const broadcast = account.settings?.workchat?.managedChannelConversationsAllowed;
      return broadcast || user.canManage();
    }

    return account.settings?.workchat?.customChannelsAllowed;
  };

  return (
    <div className="view-conversation">
      <Header showBroadcast={broadcast} buttons={buildButtons()}>
        <ConversationTitle conversation={conversation} inConversation />
      </Header>
      <ErrorBanner error={error} clearError={clearError} />
      <ParticipantList conversation={conversation} users={users} user={user} />
      <MessageLog
        connected={connected}
        conversation={conversation}
        participants={participants}
        messages={messages}
        provider={provider}
        pending={pending}
        failed={failed}
        users={users}
        user={user}
        updateConversation={updateConversation}
      />
      {sendingAllowed() ? (
        <MessageInput sending={sending} sendMessage={sendMessage} setError={setStateError} disabled={!connected} />
      ) : null}
    </div>
  );
}
