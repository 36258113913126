import { READ_RECEIPT, RECEIPT_TYPES } from 'data/metadata/keys';
import { getUserMetadata } from 'data/metadata/selectors/selectors';
import { getOpenShiftRequests } from 'data/openshiftRequests/selectors';

import { createSelector } from '@reduxjs/toolkit';
import { Map } from 'immutable';
import moment from 'moment-timezone';

export const getReadReceipts = createSelector(getUserMetadata, userMetadata =>
  userMetadata.get(READ_RECEIPT, new Map()),
);

export const getOpenshiftReadReceipts = createSelector(getReadReceipts, readReceipts => {
  if (typeof readReceipts === 'string') {
    return JSON.parse(readReceipts)[RECEIPT_TYPES.OPENSHIFT_REQUEST];
  }

  return readReceipts[RECEIPT_TYPES.OPENSHIFT_REQUEST];
});

// given an iterable items[] loop count the number of items with create_at > given lastReadTime
export const getUnreadReadReceiptCount = (items, lastReadTime, itemTimestampKey = 'created_at') => {
  let count = 0;

  for (const item of items) {
    const createdAt = moment(item[itemTimestampKey]);

    if (!lastReadTime || createdAt.isAfter(lastReadTime)) {
      count++;
    }
  }

  return count;
};

// Returns a map of { osaId: countOfUnread || 0 } for all osaRequest.user_requests
export const getUnreadOpenshiftRequestReadReceipts = createSelector(
  [getOpenshiftReadReceipts, getOpenShiftRequests],
  (readReceipts, openshiftRequests) => {
    const osaIdReceipts = {};

    for (const osaRequest of openshiftRequests.values()) {
      const osaRequestReceipt = readReceipts?.[osaRequest.id];
      const lastCheckedTime = osaRequestReceipt && moment.unix(osaRequestReceipt);
      const filtered = osaRequest.user_requests.filter(userRequest => userRequest.deleted_at === null);

      osaIdReceipts[osaRequest.id] = getUnreadReadReceiptCount(filtered, lastCheckedTime);
    }

    return new Map(osaIdReceipts);
  },
);
