import ruleSummary from 'data/scheduleRule/reducers/ruleSummaryReducer';

import shifts from './reducers/shiftConcernReducer';
import shiftDrops from './reducers/shiftDropConcernReducer';
import shiftSwaps from './reducers/shiftSwapConcernReducer';
import users from './reducers/userConcernReducer';
import userSchedulerInfo from './reducers/userSchedulerInfoConcernReducer';

import { combineReducers } from '@reduxjs/toolkit';

export default combineReducers({
  ruleSummary,
  users,
  userSchedulerInfo,
  shifts,
  shiftSwaps,
  shiftDrops,
});
