import { PunchState } from 'data/punchState/models';
import type { WiwState } from 'store';

export const getPunchStateForAuthUser = (state: WiwState) => {
  const punchState = state.data.punchState.items.get(state.auth.userId);
  if (!punchState) {
    return null;
  }

  return new PunchState(punchState);
};
