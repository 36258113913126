import { getUserId } from 'shared/auth/auth';
import { getUserItem, setUserItem } from 'shared/util/userLocalStorage';
import { SET_PAYROLL_USER } from 'store/action-types';

/**
 * The location filter  cannot be set using the persistFilter method because
 * it is part of the key used to store and retrieve the other settings.
 *
 * @param payload
 * @return {function(*): *}
 */
export function setPayrollUser(userId: number) {
  setUserItem('payroll_user', userId);

  return {
    type: SET_PAYROLL_USER,
    payload: userId,
  };
}

export const getInitialPayrollUser = () => {
  return Number.parseInt(getUserItem('payroll_user'), 10) || getUserId();
};
