import { lazy } from 'react';

const TasksDialog = lazy(() => import('./TasksDialog'));
const DeleteTaskListDialog = lazy(() => import('./DeleteTaskListDialog'));
const TasksProgressDialog = lazy(() => import('./TasksProgressDialog'));

export const DELETE_TASKLIST_DIALOG = 'DELETE_TASKLIST_DIALOG';
export const TASKS_DIALOG = 'TASKS_DIALOG';
export const TASKS_PROGRESS_DIALOG = 'TASKS_PROGRESS_DIALOG';

export default {
  [TASKS_DIALOG]: TasksDialog,
  [DELETE_TASKLIST_DIALOG]: DeleteTaskListDialog,
  [TASKS_PROGRESS_DIALOG]: TasksProgressDialog,
};
