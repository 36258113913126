import type BenefitRecord from 'data/payrollBenefits/model';
import type { BenefitItem } from 'data/payrollBenefits/model';
import Payroll from 'shared/api/payroll';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';

import { createAsyncThunk } from '@reduxjs/toolkit';

type UpdateBenefitResponse = {
  benefits: BenefitItem;
};

export type UpdateBenefitArgs = {
  benefit: BenefitRecord;
};

export const updatePayrollBenefit = createAsyncThunk<UpdateBenefitResponse, UpdateBenefitArgs>(
  'benefits/update',
  async (params, { rejectWithValue }) => {
    try {
      return (await Payroll.url('/benefits')
        .query({ benefit_id: params.benefit.id })
        .put(params.benefit.toJSON())) as UpdateBenefitResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
