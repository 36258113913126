import type Account from 'data/account/model';
import type User from 'data/user/model';
import type { Map } from 'immutable';
import { useEffect, useMemo, useRef } from 'react';
import { getAuthAccount, getAuthUser } from 'shared/auth/selectors';
import { useWiwSelector } from 'store';
import { getWorkchatUsers } from 'workchat/selectors/getWorkchatUsers';
import type { TwilioConversation } from 'workchat/types/TwilioConversation';

export type ConversationTitleProps = {
  conversation: TwilioConversation;
  inConversation?: boolean;
};

export function getTitle(
  conversation: TwilioConversation,
  user: User,
  account: Account,
  users: Map<string, User>,
  inConversation = false,
) {
  const { friendlyName, attributes, participants } = conversation;
  const selfId = `${user.id}`;

  // Helper function to get participant names
  const getParticipantNames = () => {
    return participants
      .toList()
      .filter(participant => participant.userId !== selfId)
      .map(participant => users.get(participant.userId)?.first_name || '')
      .filter(firstName => firstName)
      .sort()
      .join(', ');
  };

  if (inConversation) {
    // In conversation view
    if (friendlyName) {
      return friendlyName;
    }
    if (attributes.external_type === 'individual') {
      const otherParticipant = participants.find(p => p.userId !== selfId);
      return otherParticipant ? users.get(otherParticipant.userId)?.fullName || '' : '';
    }
    if (attributes.external_type === 'account') {
      return account.company || 'Company WorkChat';
    }
    return 'Group WorkChat';
  }
  // In conversation list view
  if (attributes.external_type === 'account' && attributes.external_id === account.id) {
    return account.company || 'Company WorkChat';
  }
  if (attributes.external_type !== 'account') {
    if (friendlyName) {
      return friendlyName;
    }
    if (attributes.external_type === 'individual') {
      const otherParticipant = participants.find(p => p.userId !== selfId);
      return otherParticipant ? users.get(otherParticipant.userId)?.fullName || '' : '';
    }
    return getParticipantNames();
  }

  return '';
}

export default function ConversationTitle({ conversation, inConversation }: ConversationTitleProps) {
  const users = useWiwSelector(getWorkchatUsers);
  const authedUser = useWiwSelector(getAuthUser);
  const account = useWiwSelector(getAuthAccount);

  const ref = useRef<HTMLDivElement | null>(null);

  const title = useMemo(
    () => getTitle(conversation, authedUser, account, users, inConversation),
    [conversation, authedUser, account, users, inConversation],
  );

  useEffect(() => {
    const div = ref.current;

    if (!div || !div.scrollWidth || !div.offsetWidth) {
      return;
    }

    const hasScroll = div.offsetWidth < div.scrollWidth;

    // TODO: Fix this hack with actual tooltip
    if (hasScroll) {
      div.classList.add('overflow');
    } else {
      div.classList.remove('overflow');
    }
  }, [ref]);

  return (
    <div ref={ref}>
      {title}
      <div className="title-tip">
        <div>{title}</div>
      </div>
    </div>
  );
}
