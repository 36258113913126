import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { Component } from 'react';
import FontIcon from 'shared/ui/FontIcon';

import 'workchat/styles/ParticipantList.scss';

export default class ParticipantList extends Component {
  static propTypes = {
    conversation: PropTypes.object.isRequired,
    users: PropTypes.instanceOf(Map).isRequired,
    user: PropTypes.object.isRequired,
  };

  static defaultProps = {};

  state = {
    expanded: false,
    names: [],
  };

  componentDidMount() {
    this.updateList(this.props);
  }

  componentWillReceiveProps(props) {
    if (props.conversation.participants !== this.props.conversation.participants || props.users !== this.props.users) {
      this.updateList(props);
    }
  }

  updateList(props) {
    const selfId = `${props.user.id}`;
    const { conversation, users } = props;
    const participants = conversation ? conversation.participants : new Map();
    const { expanded } = this.state;

    let userIds = participants.keySeq().filter(id => id !== selfId);
    if (conversation.attributes.external_type === 'account') {
      userIds = userIds.sortBy(id => (users.get(id) ? users.get(id).fullName : '[Not Found]'));
    } else {
      userIds = userIds.sort();
    }

    let truncated = false;
    const excessUsers = userIds.size - 2;
    if (!expanded && excessUsers > 0) {
      truncated = true;
      userIds = userIds.slice(0, 2);
    }

    const names = userIds.map(id => (users.get(id) ? users.get(id).fullName : '[Not Found]'));

    this.setState({
      truncated,
      excessUsers,
      names,
    });
  }

  toggleExpanded = () => {
    this.setState(
      state => {
        return { expanded: !state.expanded };
      },
      () => {
        this.updateList(this.props);
      },
    );
  };

  render() {
    const { expanded, truncated, excessUsers, names } = this.state;
    let listState = '';
    if (expanded) {
      listState = 'open';
    } else if (truncated) {
      listState = 'closed';
    }

    return (
      <div className={`participant-list ${listState}`} onClick={this.toggleExpanded}>
        <label>To:</label>
        {expanded || truncated ? (
          expanded ? (
            <div className="right">
              <FontIcon icon="chevron-up" />
            </div>
          ) : (
            <div className="right">
              <span>{excessUsers} more</span>
              <FontIcon icon="chevron-down" />
            </div>
          )
        ) : null}
        <div className="list">{names.join(', ')}</div>
      </div>
    );
  }
}
