import { Client } from '@twilio/conversations';
import Emitter from 'component-emitter';
import { List, Map } from 'immutable';
import moment from 'moment-timezone';
import { AccessManager } from 'twilio-common';
import Store from 'workchat/store';

export let providerInstance = null;

export class TwilioProvider {
  static get MESSAGES_PER_PAGE() {
    return 50;
  }

  static get VALID_TYPES() {
    return {
      'image/png': true,
      'image/jpeg': true,
      'image/gif': true,
    };
  }

  static get MAX_IMAGE_SIZE() {
    return 512;
  }

  static get TOKEN_RETRY_SECONDS() {
    return 10;
  }

  static globalInit(wiwToken, user, logDebug) {
    providerInstance = new TwilioProvider(wiwToken, user, logDebug);
  }

  constructor(wiwToken, user, logDebug) {
    this.wiwToken = wiwToken;
    this.user = user;
    this.logDebug = logDebug;

    let session = Store.get('twilio_session');
    if (session && session.user_id !== this.user.id) {
      session = null;
    }

    this.token = session ? session.token : null;
    this.client = null;
    this.channel = null;
    this.ready = false;

    if (this.token) {
      this.newAccessManager();
    }

    this.emitter = new Emitter();
  }

  isReady() {
    return this.ready;
  }

  on(event, func) {
    this.emitter.on(event, func);
  }

  off(event, func) {
    this.emitter.off(event, func);
  }

  once(event, func) {
    this.emitter.once(event, func);
  }

  newAccessManager() {
    this.access = new AccessManager(this.token);
    this.access.on('tokenUpdated', () => {
      if (this.client) {
        this.client.updateToken(this.access.token);
      }
    });
    this.access.on('tokenWillExpire', () => {
      this.authenticate();
    });
  }

  authenticate() {
    if (this.authenticateRequest) {
      return this.authenticateRequest;
    }

    this.authenticateRequest = new Promise((resolve, reject) => {
      setTimeout(() => {
        fetch(`${CONFIG.WORKCHAT_ROOT}/token`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'W-Token': this.wiwToken,
            'W-UserId': this.user.id,
          },
          body: JSON.stringify({ device: 'browser' }),
        })
          .then(
            resp => {
              if (resp.status >= 200 && resp.status < 300) {
                resp.json().then(
                  data => {
                    Store.set('twilio_session', { token: data.token, user_id: this.user.id });
                    this.token = data.token;

                    if (this.access) {
                      this.access.updateToken(this.token);
                    } else {
                      this.newAccessManager();
                    }

                    resolve(data);
                  },
                  err => reject(err),
                );
              } else {
                reject(resp);
              }
            },
            xhr => reject(xhr),
          )
          .finally(() => {
            this.authenticateRequest = null;
          });
      }, this.authDelay());
    });
    return this.authenticateRequest;
  }

  authDelay() {
    if (!this.lastAuth) {
      this.lastAuth = moment();
      return 0;
    }
    const delay = moment.duration(TwilioProvider.TOKEN_RETRY_SECONDS, 'seconds');
    const difference = moment().diff(this.lastAuth);
    this.lastAuth = moment();
    return Math.max(0, delay - difference);
  }

  start() {
    return new Promise((resolve, reject) => {
      this._start(resolve, reject);
    });
  }

  _start(resolve, reject) {
    if (!this.access || this.access.isExpired) {
      this.authenticate().then(
        _data => {
          this._start(resolve, reject);
        },
        xhr => reject(xhr),
      );
      return;
    }

    if (this.client) {
      this.client.shutdown();
      this.client = null;
      this.ready = false;
    }

    this.client = new Client(this.token, this.logDebug ? { logLevel: 'debug' } : {});
    this.client.on('stateChanged', state => {
      if (state === 'initialized') {
        this.ready = true;
        this.emitter.emit('ready');
      }
    });
    this.client.on('connectionStateChanged', state => {
      if (this.ready) {
        if (state === 'denied') {
          this.authenticate();
        } else if (state !== 'connected') {
          this.emitter.emit('disconnected');
        } else {
          this.emitter.emit('reconnected');
        }
      }
    });
    this.client.on('conversationJoined', channel => {
      TwilioProvider.decorateChannel(channel).then(ch => this.emitter.emit('conversation.update', ch));
    });
    this.client.on('conversationLeft', channel => {
      TwilioProvider.decorateChannel(channel).then(ch => this.emitter.emit('conversation.leave', ch));
    });
    this.client.on('conversationRemoved', channel => this.emitter.emit('conversation.leave', channel));
    this.client.on('conversationAdded', () => {});
    this.client.on('conversationUpdated', channel => {
      if (this.channel && this.channel.sid === channel.sid) {
        this.emitter.emit('conversation.update', channel);
      }
      this.emitter.emit('update.read');
    });
    this.client.on('participantJoined', member => {
      this.emitter.emit('member.joined', member);
      //TwilioProvider.updateMembers(member.channel);
    });
    this.client.on('participantLeft', member => {
      this.emitter.emit('member.left', member);
      //TwilioProvider.updateMembers(member.channel);
    });
    this.client.on('participantUpdated', member => {
      this.emitter.emit('member.update', member);
    });
    this.client.on('messageAdded', msg => {
      msg = TwilioProvider.decorateMessage(msg);
      if (this.channel && this.channel.id === msg.conversation) {
        this.channel.messages = this.channel.messages.push(msg);
      }
      this.emitter.emit('message.create', msg);
    });

    this.ready = true;
    resolve();
  }

  stop() {
    if (this.client) {
      this.client.removeAllListeners();
      this.client.shutdown();
      this.client = null;
      this.ready = false;
    }
  }

  static decorateChannel(channel) {
    return new Promise((resolve, _reject) => {
      channel.id = channel.sid;
      channel.name = channel.friendlyName || channel.uniqueName;
      channel.createdAt = moment(channel.dateCreated);
      channel.updatedAt = moment(channel.dateUpdated);
      channel.individual = channel.attributes.external_type === 'individual';
      channel.localReadIndex = channel.lastReadMessageIndex;

      if (!channel.decorated) {
        channel.decorated = true;
        channel.participants = new Map();
        channel.messages = new List();
        channel.pending = new List();
        channel.failed = new List();
        channel.permissions = [];
      }

      if (channel.status === 'joined') {
        Promise.all([
          channel.getMessages(1).then(messages => {
            if (messages.items.length > 0) {
              channel.latestMessage = TwilioProvider.decorateMessage(messages.items[messages.items.length - 1]);
            }
          }),
          TwilioProvider.updateMembers(channel),
        ]).then(() => {
          resolve(channel);
        });
      } else {
        resolve(channel);
      }
    });
  }

  static decorateMessage(msg) {
    msg.conversation = msg.conversation?.sid;
    msg.sentAt = moment(msg.dateUpdated);
    msg.userId = msg.author;

    msg.text = msg.body;
    if (msg.attributes.fullsize) {
      msg.image = {
        full: msg.attributes.fullsize,
        thumb: msg.attributes.thumbnail,
      };
    }

    return msg;
  }

  static updateMembers(channel) {
    return channel.getParticipants().then(members => {
      channel.participants = new Map(
        members.map(m => {
          m.userId = m.identity;
          return [m.userId, m];
        }),
      );

      if (channel.attributes.external_type === 'individual' && members.length > 2) {
        channel.attributes.external_type = 'group';
      }
    });
  }

  getMercuryIdentity() {
    if (window.mercury?.identity) {
      return window.mercury.identity;
    }

    return null;
  }

  getMercuryAttributes() {
    return { mercuryMetadata: this.getMercuryIdentity() };
  }

  addParticipants(participants) {
    const channel = this.getConversation();
    const adds = [];

    participants.forEach(p => {
      adds.push(channel.add(`${p}`, { ...this.getMercuryAttributes() }));
    });

    return Promise.all(adds);
  }

  getPermissions() {
    return new Promise((resolve, reject) => {
      if (!this.channel) {
        resolve();
        return;
      }

      const channel = this.channel;
      fetch(`${CONFIG.WORKCHAT_ROOT}/channels/${channel.sid}/permissions`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
          'W-Token': this.wiwToken,
          'W-UserId': this.user.id,
        },
      }).then(
        resp => {
          resp.json().then(
            data => {
              if (data.permissions) {
                channel.permissions = data.permissions;
              }
              resolve();
            },
            err => reject(err),
          );
        },
        xhr => reject(xhr),
      );
    });
  }

  checkForConversation(participants) {
    return new Promise((resolve, reject) => {
      fetch(`${CONFIG.WORKCHAT_ROOT}/channels`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
          'W-Token': this.wiwToken,
          'W-UserId': this.user.id,
        },
        body: JSON.stringify({
          participants,
        }),
      }).then(
        resp => {
          resp.json().then(
            data => {
              if (resp.status === 409) {
                this.client.getConversationBySid(data.channel.sid).then(channel => {
                  TwilioProvider.decorateChannel(channel).then(c => resolve({ exists: true, channel: c }));
                });
              } else if (resp.ok && data.channel) {
                resolve({ exists: false, channel: data.channel });
              } else {
                reject(resp);
              }
            },
            err => reject(err),
          );
        },
        err => reject(err),
      );
    });
  }

  createConversation(participants, message, conversationName = '', image) {
    return new Promise((resolve, reject) => {
      this.checkForConversation(participants).then(
        result => {
          const { exists, channel } = result;
          if (exists) {
            this.setConversation(channel);
            this.sendMessage(message, image);
            resolve(channel);
          } else {
            let channelAtts = {};
            try {
              channelAtts = JSON.parse(channel.attributes);
            } catch (e) {
              console.error(e);
            }

            const opts = {
              attributes: {
                ...channelAtts,
                ...this.getMercuryAttributes(),
              },
              isPrivate: channel.type === 'private' || !channel.type,
            };
            if (conversationName) {
              opts.friendlyName = conversationName;
            }

            this.client.createConversation(opts).then(channel => {
              channel.join().then(channel => {
                TwilioProvider.decorateChannel(channel).then(c => {
                  this.setConversation(c).then(() => {
                    this.sendMessage(message, image);
                    this.emitter.emit('conversation.created', c, participants);
                    resolve(c);
                  });
                });
              });

              participants.forEach(p => {
                channel.add(`${p}`, { ...this.getMercuryAttributes() });
              });
            });
          }
        },
        err => {
          this.emitter.emit('conversation.failed');
          reject(err);
        },
      );
    });
  }

  renameConversation(name) {
    return new Promise((resolve, reject) => {
      if (this.channel) {
        this.channel.updateFriendlyName(name).then(
          channel => {
            TwilioProvider.decorateChannel(channel).then(c => resolve(c));
          },
          err => reject(err),
        );
      } else {
        reject('No conversation set');
      }
    });
  }

  leaveConversation() {
    return new Promise((resolve, reject) => {
      if (this.channel) {
        this.channel.leave().then(
          _channel => {
            resolve();
          },
          err => reject(err),
        );
      } else {
        reject('No conversation set');
      }
    });
  }

  getConversation() {
    return this.channel;
  }

  setConversation(conv) {
    return new Promise((resolve, _reject) => {
      this.channel = conv;

      if (this.channel) {
        Promise.all([this.loadMessages(), this.getPermissions()]).then(() => {
          this.emitter.emit('conversation.set', this.channel);
          resolve();
        });
      } else {
        this.emitter.emit('conversation.set');
        resolve();
      }
    });
  }

  unread(conv) {
    let object = null;
    let lastIndex = 0;
    if (conv) {
      if (!conv.latestMessage) {
        return lastIndex;
      }
      object = conv;
      lastIndex = conv.latestMessage.index;
    } else {
      object = this.channel;
      const messages = object ? object.messages : null;
      if (messages && messages.size > 0) {
        lastIndex = messages[messages.size - 1].index;
      }
    }
    return object.lastReadMessageIndex === null ? lastIndex + 1 : lastIndex - object.lastReadMessageIndex;
  }

  markConversationRead() {
    if (this.channel && this.channel.messages.size > 0) {
      const messages = this.channel.messages;
      const index = messages.get(messages.size - 1).index;
      this.channel.localReadIndex = Math.max(this.channel.localReadIndex, index);
      this.channel.advanceLastReadMessageIndex(index);
      this.emitter.emit('conversation.update', this.channel);
    }
  }

  getMessages() {
    return this.channel ? this.channel.messages : new List();
  }

  getMembers() {
    return this.channel ? this.channel.participants : new Map();
  }

  _processConversationPage(resolve, promises, channels, page) {
    page.items.forEach(c => {
      promises.push(TwilioProvider.decorateChannel(c).then(c => channels.push(c)));
    });

    if (page.hasNextPage) {
      page.nextPage().then(this._processConversationPage.bind(this, resolve, promises, channels));
    } else {
      Promise.all(promises).then(() => {
        resolve(channels);
      });
    }
  }

  loadConversations() {
    return new Promise((resolve, reject) => {
      this.client
        .getSubscribedConversations()
        .then(this._processConversationPage.bind(this, resolve, [], []), xhr => reject(xhr));
    });
  }

  _handleMessages(resolve, messages) {
    const items = [];
    messages.items.forEach(msg => {
      items.push(TwilioProvider.decorateMessage(msg));
    });
    messages.items = items;

    this.channel.messages = new List(messages.items).concat(this.channel.messages);
    this.channel.more = messages.hasPrevPage;
    this.channel.prevPage = messages.prevPage;
    resolve({
      messages: this.channel.messages,
      perPage: TwilioProvider.MESSAGES_PER_PAGE,
      more: this.channel.more,
    });
  }

  loadMessages(more) {
    return new Promise((resolve, reject) => {
      if (more && this.channel.more) {
        this.channel.prevPage().then(this._handleMessages.bind(this, resolve), err => reject(err));
      } else if (!more) {
        this.channel.getMessages(TwilioProvider.MESSAGES_PER_PAGE).then(
          messages => {
            // Resetting message state for a fresh load
            this.channel.messages = new List();
            this._handleMessages(resolve, messages);
          },
          err => reject(err),
        );
      } else {
        resolve({
          messages: this.channel.messages,
          perPage: TwilioProvider.MESSAGES_PER_PAGE,
          more: this.channel.more,
        });
      }
    });
  }

  sendMessage(text, image) {
    if (image) {
      this._sendImage(image, text);
    } else if (text) {
      this._sendText(text);
    }
  }

  _sendText(text) {
    const msgId = TwilioProvider.generateId();

    this.emitter.emit('message.sending', this.channel, {
      id: msgId,
      text: text,
    });

    this.channel.sendMessage(text, { tempId: msgId, ...this.getMercuryAttributes() }).then(
      id => {
        this.emitter.emit('message.sent', this.channel, { tempId: msgId, id: id });
      },
      () => {
        this.emitter.emit('message.failed', this.channel, { tempId: msgId });
      },
    );
  }

  _sendImage(image, text) {
    const msgId = TwilioProvider.generateId();
    if (!text) {
      text = '';
    }

    this.emitter.emit('message.sending', this.channel, {
      id: msgId,
      text: text,
      image: image.data,
    });

    fetch(`${CONFIG.WORKCHAT_ROOT}/images`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'W-Token': this.wiwToken,
        'W-UserId': this.user.id,
      },
      body: image.buffer,
    })
      .then(resp => resp.json())
      .then(
        resp => {
          if (resp.fullsize && resp.thumbnail) {
            this.channel
              .sendMessage(text, {
                tempId: msgId,
                fullsize: resp.fullsize,
                thumbnail: resp.thumbnail,
                ...this.getMercuryAttributes(),
              })
              .then(
                id => {
                  this.emitter.emit('message.sent', this.channel, { tempId: msgId, id: id });
                },
                () => {
                  this.emitter.emit('message.failed', this.channel, { tempId: msgId });
                },
              );
          } else {
            this.emitter.emit('message.failed', this.channel, { tempId: msgId });
          }
        },
        () => {
          this.emitter.emit('message.failed', this.channel, { tempId: msgId });
        },
      );
  }

  static generateId() {
    return `${new Date().getTime().toString(36)}-${Math.ceil(Math.random() * 10000000)}`;
  }
}

window.WorkchatTwilio = TwilioProvider;

export default TwilioProvider;
