import { lazy } from 'react';

const UpgradeAccountDialog = lazy(() => import('billing/dialogs/UpgradeAccountDialog'));
const CancelAccountDialog = lazy(() => import('billing/dialogs/CancelAccountDialog'));
const DowngradePlanFeedbackDialog = lazy(() => import('billing/dialogs/DowngradePlanFeedbackDialog'));
const HibernateAccountDialog = lazy(() => import('billing/dialogs/HibernateAccountDialog'));
const AddAccountDialog = lazy(() => import('billing/dialogs/AddAccountDialog'));
const EditAccountDialog = lazy(() => import('billing/dialogs/EditAccountDialog'));
const ManageSeatsDialog = lazy(() => import('billing/dialogs/ManageSeatsDialog'));
const ChangePlanDialog = lazy(() => import('billing/dialogs/ChangePlanDialog'));
const StripeChangePlanDialog = lazy(() => import('./StripeChangePlanDialog'));
const UpdateBillingDialog = lazy(() => import('./UpdateBillingInfoDialog'));
const StripeUpdateBillingDialog = lazy(() => import('./StripeUpdateBillingInfoDialog'));
const StripeManageSeatsDialog = lazy(() => import('./StripeManageSeatsDialog'));
const QuickArchiveUsersDialog = lazy(() => import('./QuickArchiveUsersDialog'));
const CancelAccountPayrollDialiog = lazy(() => import('./CancelPayrollAccountDialog'));
const DowngradeDialog = lazy(() => import('billing/dialogs/DowngradeDialog'));
const DowngradeSingleLocationDialog = lazy(
  () => import('billing/dialogs/DowngradeSingleLocationDialog/DowngradeSingleLocationDialog'),
);
const FeaturesDialog = lazy(() => import('billing/dialogs/FeaturesDialog/FeaturesDialog'));

export const UPGRADE_ACCOUNT_DIALOG = 'UPGRADE_ACCOUNT_DIALOG';
export const CANCEL_ACCOUNT_DIALOG = 'CANCEL_ACCOUNT_DIALOG';
export const CANCEL_PAYROLL_ACCOUNT_DIALOG = 'CANCEL_PAYROLL_ACCOUNT_DIALOG';
export const DOWNGRADE_PLAN_FEEDBACK_DIALOG = 'DOWNGRADE_PLAN_FEEDBACK_DIALOG';
export const HIBERNATE_ACCOUNT_DIALOG = 'HIBERNATE_ACCOUNT_DIALOG';
export const ADD_ACCOUNT_DIALOG = 'ADD_ACCOUNT_DIALOG';
export const EDIT_ACCOUNT_DIALOG = 'EDIT_ACCOUNT_DIALOG';
export const MANAGE_SEATS_DIALOG = 'MANAGE_SEATS_DIALOG';
export const CHANGE_PLAN_DIALOG = 'CHANGE_PLAN_DIALOG';
export const UPDATE_BILLING_DIALOG = 'UPDATE_BILLING_DIALOG';
export const STRIPE_CHANGE_PLAN_DIALOG = 'STRIPE_CHANGE_PLAN_DIALOG';
export const STRIPE_UPDATE_BILLING_DIALOG = 'STRIPE_UPDATE_BILLING_DIALOG';
export const STRIPE_MANAGE_SEATS_DIALOG = 'STRIPE_MANAGE_SEATS_DIALOG';
export const QUICK_ARCHIVE_USERS_DIALOG = 'QUICK_ARCHIVE_USERS_DIALOG';
export const DOWNGRADE_DIALOG = 'DOWNGRADE_DIALOG';
export const DOWNGRADE_SINGLE_LOCATION_DIALOG = 'DOWNGRADE_SINGLE_LOCATION_DIALOG';
export const FEATURES_DIALOG = 'FEATURES_DIALOG';

const dialogs = {
  [UPGRADE_ACCOUNT_DIALOG]: UpgradeAccountDialog,
  [CANCEL_ACCOUNT_DIALOG]: CancelAccountDialog,
  [DOWNGRADE_PLAN_FEEDBACK_DIALOG]: DowngradePlanFeedbackDialog,
  [HIBERNATE_ACCOUNT_DIALOG]: HibernateAccountDialog,
  [ADD_ACCOUNT_DIALOG]: AddAccountDialog,
  [EDIT_ACCOUNT_DIALOG]: EditAccountDialog,
  [MANAGE_SEATS_DIALOG]: ManageSeatsDialog,
  [CHANGE_PLAN_DIALOG]: ChangePlanDialog,
  [STRIPE_CHANGE_PLAN_DIALOG]: StripeChangePlanDialog,
  [UPDATE_BILLING_DIALOG]: UpdateBillingDialog,
  [STRIPE_UPDATE_BILLING_DIALOG]: StripeUpdateBillingDialog,
  [STRIPE_MANAGE_SEATS_DIALOG]: StripeManageSeatsDialog,
  [QUICK_ARCHIVE_USERS_DIALOG]: QuickArchiveUsersDialog,
  [CANCEL_PAYROLL_ACCOUNT_DIALOG]: CancelAccountPayrollDialiog,
  [DOWNGRADE_DIALOG]: DowngradeDialog,
  [DOWNGRADE_SINGLE_LOCATION_DIALOG]: DowngradeSingleLocationDialog,
  [FEATURES_DIALOG]: FeaturesDialog,
  // ...
};

export default dialogs;
