import PropTypes from 'prop-types';
import { Component } from 'react';

import classNames from 'classnames';
import { propTypes, withFormsy } from 'formsy-react';

export class SteppableNumberInput extends Component {
  static propTypes = {
    ...propTypes,
    name: PropTypes.string.isRequired,
    label: PropTypes.any,
    value: PropTypes.number,
    onChange: PropTypes.func,
    validatePristine: PropTypes.bool,
    step: PropTypes.number.isRequired,
    min: PropTypes.number,
    max: PropTypes.number,
  };

  static defaultProps = {
    validatePristine: false,
    min: null,
    max: null,
    value: 0,
    onChange: () => {},
  };

  state = {
    value: this.props.value,
  };

  changeValue = change => () => {
    const newValue = this.state.value + change;
    if (this.props.min !== null && newValue < this.props.min) {
      return;
    }

    if (this.props.max !== null && newValue > this.props.max) {
      return;
    }

    this.setState({ value: newValue });
    this.props.onChange(this.props.name, newValue);
  };

  render() {
    const decrementClassnames = classNames(
      { disabled: this.state.value === this.props.min },
      'step-button',
      'step-minus',
    );
    const incrementClassnames = classNames(
      { disabled: this.state.value === this.props.max },
      'step-button',
      'step-plus',
    );

    return (
      <div className="steppable-number-input">
        <input type="hidden" name={this.props.name} value={this.state.value} />
        <button type="button" onClick={this.changeValue(-this.props.step)} className={decrementClassnames}>
          <i className="fa fa-minus" />
        </button>
        <label className="step-label">
          {this.state.value} {this.props.label}
        </label>
        <button type="button" onClick={this.changeValue(this.props.step)} className={incrementClassnames}>
          <i className="fa fa-plus" />
        </button>
      </div>
    );
  }
}
/**
 * @deprecated please migrate to hook-form from shared/form/inputs
 */
export default withFormsy(SteppableNumberInput);
