import { SCHEDULER_LOCATION_FILTER } from 'scheduler/util/cookies';
import { getUserId } from 'shared/auth/auth';

import Cookies from 'js-cookie';

export const persistFilter = (filterName, payload) => {
  const serializedPayload = JSON.stringify(payload);
  return localStorage.setItem(makeStorageKey(filterName), serializedPayload);
};

export const makeStorageKey = filterName => {
  const userId = getUserId();
  const locationId = Number.parseInt(Cookies.get(SCHEDULER_LOCATION_FILTER), 10) || 0;

  return `${filterName}-${userId ? userId : ''}-${locationId ? locationId : ''}`;
};

export const getPersistedFilter = (filterName, notSetValue) => {
  const fromCookie = Cookies.get(filterName) || false;
  const fromKeyedCookie = Cookies.get(makeStorageKey(filterName)) || false;
  const fromStorage = localStorage.getItem(makeStorageKey(filterName)) || false;
  // Use newest storage option
  if (fromStorage) {
    try {
      return JSON.parse(fromStorage);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }
  // Use interim storage option, set new storage
  let unSerializedValue;
  if (fromKeyedCookie) {
    unSerializedValue = typeof notSetValue === 'string' ? fromKeyedCookie : JSON.parse(fromKeyedCookie);
    persistFilter(filterName, unSerializedValue);
    return unSerializedValue;
  }
  // Use oldest storage option, set new storage
  if (fromCookie) {
    unSerializedValue = typeof notSetValue === 'string' ? fromCookie : JSON.parse(fromCookie);
    persistFilter(filterName, unSerializedValue);
    return unSerializedValue;
  }
  // Not found, return default
  return notSetValue;
};
