import { request } from 'shared/auth/request';
import { LIST_LABOR_DATA } from 'store/action-types';

function listLaborDataFailed(error) {
  return {
    type: LIST_LABOR_DATA.FAILURE,
    payload: error,
  };
}

function listLaborDataRequested() {
  return {
    type: LIST_LABOR_DATA.REQUEST,
  };
}

export function listLaborDataSuccess(payload) {
  return {
    type: LIST_LABOR_DATA.SUCCESS,
    payload,
  };
}

export function fetchLaborData(params = {}) {
  return dispatch => {
    dispatch(listLaborDataRequested());
    return request()
      .post('/shifts/labor', params) //Switching to POST here in case query params become too large
      .then(response => dispatch(listLaborDataSuccess(response.data)))
      .catch(error => dispatch(listLaborDataFailed(error.body)));
  };
}
