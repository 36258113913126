import type RequestType from 'data/request-type/model';
import { Body, Dialog, Footer, Header } from 'dialogs';
import Button from 'shared/ui/Button';

import type { DialogDirectProps } from 'dialogs';

export type Props = DialogDirectProps & {
  type: RequestType;
  onConfirm: () => void;
};

export function ConfirmRequestTypeDeleteDialog({
  type,
  onConfirm,

  closeDialog,
}: Props) {
  function onSubmit() {
    closeDialog();
    onConfirm();
  }

  return (
    <Dialog width={500}>
      <Header onClose={closeDialog} titleClassName="text-truncate">
        Delete {type.name}?
      </Header>
      <Body>
        The {type.name} time-off type will be deleted when you save and your staff will no longer be able to select it.
        You will still see records of this type in requests that have already been created.
      </Body>
      <Footer>
        <div className="text-right">
          <Button className="mr-2" color="secondary" onClick={closeDialog}>
            Cancel
          </Button>
          <Button type="submit" onClick={onSubmit}>
            Confirm
          </Button>
        </div>
      </Footer>
    </Dialog>
  );
}

export default ConfirmRequestTypeDeleteDialog;
