import EntityState from 'data/EntityState';
import { DELETE_TASK_LIST_BULK, FETCH_TASK_LISTS, UPDATE_TASK_LISTS } from 'store/action-types';
import { TaskListModel } from './models';

import { Map } from 'immutable';

export default function (state = new EntityState(), action) {
  switch (action.type) {
    case FETCH_TASK_LISTS.FAILURE:
      return state.merge({
        loaded: false,
        loading: false,
        error: action.error,
      });
    case FETCH_TASK_LISTS.REQUEST:
      return state.merge({
        loaded: false,
        loading: true,
        error: null,
      });
    case FETCH_TASK_LISTS.SUCCESS:
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        items: new Map(
          action.payload.map(taskList => {
            return [taskList.id, new TaskListModel(taskList)];
          }),
        ),
      });
    case UPDATE_TASK_LISTS:
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        items: state.items.delete(null).merge(
          action.items.map(taskList => {
            return [taskList.id, new TaskListModel(taskList)];
          }),
        ),
      });
    case DELETE_TASK_LIST_BULK.REQUEST:
      return state.merge({
        loaded: false,
        loading: true,
        error: null,
      });
    case DELETE_TASK_LIST_BULK.SUCCESS:
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        items: state.items.withMutations(map => {
          action.payload.map(taskList => map.delete(taskList.id));
        }),
      });
    case DELETE_TASK_LIST_BULK.FAILURE:
      return state.merge({
        loaded: true,
        loading: false,
        error: action.payload,
        items: state.items.withMutations(map => {
          action.items.map(taskList => map.set(taskList.id, new TaskListModel(taskList)));
        }),
      });
    default:
      return state;
  }
}
