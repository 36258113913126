/* eslint-disable no-dupe-class-members */

import Environment from 'shared/util/environment';
import { type PickByValue, isArray } from 'shared/util/types';

import { type Iterable, List, Map, type OrderedMap, type OrderedSet, type Seq, type Set, type Stack } from 'immutable';
import { isEqual } from 'lodash';

/*
This collection is loosely patterned after OrderedMap in Immutable.js. But it doesn't use any prototype insanity.
https://github.com/immutable-js/immutable-js/blob/59c291a2b37693198a0950637c5d55cd14dd6bc4/src/OrderedMap.js
*/

type IndexableValue = string | number | boolean | undefined | null;
type ValidKey<T> = keyof PickByValue<T, IndexableValue>;
type ValidCompositeKey<T> = readonly [ValidKey<T>, ...ValidKey<T>[]];

type Index<T, PK extends keyof T> = Map<List<any>, Map<T[PK], T>>;
type Indexes<T, PK extends keyof T> = Map<List<ValidKey<T>>, Index<T, PK>>;

type ValueForKey<
  T,
  K extends ValidKey<T> | ValidCompositeKey<T>,
  V = K extends ValidKey<T> ? T[K] : never,
> = K extends ValidKey<T> ? (V extends () => IndexableValue ? ReturnType<V> : V) : { [_ in keyof K]: any }; // composite keys lose type info :( but at least we can preserve tuple length

/**
 * IndexedMap is a drop-in replacement for an Immutable.JS Map, which adds
 * indexing features like you might find in a database. It allows you to
 * group and aggregate records without incurring the cost of a full scan of
 * the data, and without any caching.
 *
 * If you are starting out, you can think of it as providing a more robust
 * groupBy, with better performance and better interaction with React
 * components and caches.
 *
 * An IndexedMap stores one type of item and one type only, similar to a
 * database table. When creating an IndexedMap, you specify the record type,
 * the primary key, and any index keys:
 *
 *   class Shift {
 *     id: number;
 *     user_id: number;
 *     position_id: number;
 *   }
 *   let map = new IndexedMap(Shift, 'id', [
 *     'user_id',
 *     ['user_id', 'position_id'], // composite keys are supported
 *   ]);
 *
 * Items are inserted by calling `add` and deleted by calling `delete`. (The
 * `set` method from Immutable's Map exists for compatibility, but is
 * a no-op.) Items can be fetched by primary key using `get`, as in a normal
 * Immutable Map, or fetched by index key and value using `findWhere`.
 *
 *   const u1p1 = new Shift(101, 10, 20);    // user 1 position 1
 *   const u1p2 = new Shift(102, 10, 21);    // user 1 position 2
 *   const u2p1 = new Shift(103, 11, 20);    // user 2 position 1
 *   map = map.add([u1p1, u1p2, u2p1]);
 *
 *   // gets u1p1
 *   map.get(101)
 *
 *   // gets user 1's shifts
 *   map.findWhere('user_id', 10)
 *
 *   // gets user 1's shifts for position 1
 *   map.findWhere(['user_id', 'position_id'], [10, 20]);
 */
export class IndexedMap<
  T extends Object, // The actual type stored by the map
  PK extends ValidKey<T>, // Primary Key
  IK extends ValidKey<T> | ValidCompositeKey<T>, // Index Key
> {
  readonly size: number;

  readonly _type: new () => T;
  readonly _originalIndexKeys: readonly IK[]; // The original index keys - as arrays, not immutable lists.

  readonly _primaryKey: PK;
  readonly _indexKeys: List<ValidKey<T>>[];

  readonly _mainMap: Map<T[PK], T>;
  readonly _indexMap: Indexes<T, PK>;

  constructor(
    type: new () => T,
    primaryKey: PK,
    indexKeys: readonly IK[],
    _mainMap?: Map<T[PK], T>, // eslint-disable-line @typescript-eslint/naming-convention
    _indexMap?: Indexes<T, PK>, // eslint-disable-line @typescript-eslint/naming-convention
    _indexKeysAsLists?: List<ValidKey<T>>[], // eslint-disable-line @typescript-eslint/naming-convention
  ) {
    if (!type) {
      throw new Error(`Expected a type for IndexedMap but got ${type}!`);
    }

    this.size = _mainMap ? _mainMap.size : 0;
    this._mainMap = _mainMap || Map();
    this._indexMap = _indexMap || Map();
    this._type = type;
    this._originalIndexKeys = indexKeys;
    this._primaryKey = primaryKey;

    if (_indexKeysAsLists) {
      this._indexKeys = _indexKeysAsLists;
    } else {
      this._indexKeys = [];
      for (const keyTuple of indexKeys) {
        this._indexKeys.push(IndexedMap._maybeTupleToList(keyTuple));
      }
    }
  }

  toString(): string {
    // @ts-ignore
    return this._mainMap.__toString('IndexedMap {', '}');
  }

  get<NSV>(key: T[PK], notSetValue: NSV): T | NSV;
  get(key: T[PK]): T | undefined;
  get(key: T[PK], notSetValue?: any) {
    return this._mainMap.get(key, notSetValue);
  }

  getMany(keys: T[PK][]): Map<T[PK], T> {
    return keys.reduce(
      (values: Map<T[PK], T>, key: T[PK]) =>
        this._mainMap.has(key) ? values.set(key, this._mainMap.get(key)!) : values,
      Map(),
    );
  }

  has(key: T[PK]): boolean {
    return this._mainMap.has(key);
  }

  private static _maybeTupleToList<T>(maybeTuple: T | readonly T[]): List<T> {
    if (isArray(maybeTuple)) {
      return List(maybeTuple);
    }
    return List([maybeTuple]);
  }

  private _getItemValuesForKey(item: T, key: List<keyof T>): List<any> {
    const itemIndexValue: any[] = [];
    for (const entry of key) {
      itemIndexValue.push(item[entry]);
    }
    return List(itemIndexValue);
  }

  isValidIndexKey(indexKey: any): boolean {
    return (
      indexKey === this._primaryKey || this._indexKeys.some(key => key.equals(IndexedMap._maybeTupleToList(indexKey)))
    );
  }

  /**
   * Finds the first record matching the given index key and value.
   *
   * Complexity: O(1).
   */
  findOneWhere<K extends IK>(indexKey: K, indexValue: ValueForKey<T, K>): T | undefined {
    if (!this.isValidIndexKey(indexKey)) {
      throw new Error(
        `IndexedCollection: Attempted to find an item where ${JSON.stringify(indexKey)} is ${JSON.stringify(
          indexValue,
        )}, but ${JSON.stringify(indexKey)} is not an index in this collection.`,
      );
    }

    const keyList = IndexedMap._maybeTupleToList(indexKey);
    const valueList = IndexedMap._maybeTupleToList(indexValue);

    const matches = this._indexMap.get(keyList)?.get(valueList) || Map();
    return matches.first();
  }

  /**
   * Finds all records matching the given index key and value.
   *
   * Complexity: O(1).
   */
  findWhere<K extends IK>(indexKey: K, indexValue: ValueForKey<T, K>): Map<T[PK], T> {
    if (!this.isValidIndexKey(indexKey)) {
      throw new Error(
        `IndexedCollection: Attempted to find items where ${JSON.stringify(indexKey)} is ${JSON.stringify(
          indexValue,
        )}, but ${JSON.stringify(indexKey)} is not an index in this collection.`,
      );
    }

    const keyList = IndexedMap._maybeTupleToList(indexKey);
    const valueList = IndexedMap._maybeTupleToList(indexValue);

    const result = this._indexMap.get(keyList)?.get(valueList) || Map();

    return result;
  }

  /**
   * Finds all records matching the given index key, with any of the provided
   * index values. The result is a map of maps, where the outer map is keyed
   * by the specific index value, and the inner map is the same as the result
   * of findWhere.
   *
   * Complexity: O(indexValues.length)
   *
   * Example:
   */
  findWhereIn<K extends IK>(indexKey: K, indexValues: ValueForKey<T, K>[]): Map<ValueForKey<T, K>, Map<T[PK], T>> {
    let resultSet: Map<ValueForKey<T, K>, Map<T[PK], T>> = Map();

    for (const indexValue of indexValues) {
      const keyList = IndexedMap._maybeTupleToList(indexKey);
      const valueList = IndexedMap._maybeTupleToList(indexValue);
      const result = this._indexMap.get(keyList)?.get(valueList) || Map();

      resultSet = resultSet.set(indexValue, result);
    }

    return resultSet;
  }

  set() {
    console.warn('`set` is a no-op on an IndexedMap. Use `add` instead.');
    return this;
  }

  /**
   * Adds one or more items to the primary map and any matching indexes.
   * Irrelevant indexes will not be modified and will have exactly the same
   * reference as before the add.
   *
   * Complexity: O(items.length * num indexes)
   */
  add(items: T | T[]): IndexedMap<T, PK, IK> {
    let itemsToAdd: T[];
    if (isArray(items)) {
      itemsToAdd = items;
    } else {
      itemsToAdd = [items];
    }

    /*
    First delete existing items, so that stuff gets removed correctly from indexes.
    This is simple, but we should keep an eye out in case doing it this way is slow for
    large collections.
    */
    const imap = this.delete(itemsToAdd.map(item => item[this._primaryKey]));

    let newMainMap = imap._mainMap;
    let newIndexMap = imap._indexMap;

    for (const item of itemsToAdd) {
      if (!(item instanceof imap._type)) {
        throw new Error(`Attempted to insert item '${item}' into indexed collection of type '${imap._type}'`);
      }

      // Add to main item collection
      newMainMap = newMainMap.set(item[imap._primaryKey], item);

      // Add to indexes
      for (const compositeKey of imap._indexKeys) {
        const itemIndexValue = this._getItemValuesForKey(item, compositeKey);

        const currentIndex = newIndexMap.get(compositeKey, this._makeIndex(itemIndexValue));
        let currentIndexMap = currentIndex.get(itemIndexValue);
        if (!currentIndexMap) {
          currentIndexMap = Map();
        }

        newIndexMap = newIndexMap.set(
          compositeKey,
          currentIndex.set(itemIndexValue, currentIndexMap.set(item[imap._primaryKey], item)),
        );
      }
    }

    return new IndexedMap(
      imap._type,
      imap._primaryKey,
      imap._originalIndexKeys,
      newMainMap,
      newIndexMap,
      imap._indexKeys,
    );
  }

  /**
   * Deletes one or more items by id from the primary map and all indexes.
   * Irrelevant indexes will not be modified and will have exactly the same
   * reference as before the delete.
   *
   * Complexity: O(keys.length * num indexes)
   */
  delete(keys: T[PK] | T[PK][]): IndexedMap<T, PK, IK> {
    let newMainMap = this._mainMap;
    let newIndexMap = this._indexMap;

    let keysToDelete: T[PK][];
    if (isArray(keys)) {
      keysToDelete = keys;
    } else {
      keysToDelete = [keys];
    }

    for (const key of keysToDelete) {
      const removedItem = this.get(key);
      if (!removedItem) {
        continue;
      }

      // Remove from main map
      newMainMap = newMainMap.delete(key);

      // Remove from indexes
      for (const compositeKey of this._indexKeys) {
        const itemIndexValue = this._getItemValuesForKey(removedItem, compositeKey);

        const currentIndex = newIndexMap.get(compositeKey);
        const currentIndexSet = currentIndex?.get(itemIndexValue);
        if (!currentIndex || !currentIndexSet) {
          continue;
        }

        newIndexMap = newIndexMap.set(
          compositeKey,
          currentIndex.set(itemIndexValue, currentIndexSet.delete(removedItem[this._primaryKey])),
        );
      }
    }

    return new IndexedMap(
      this._type,
      this._primaryKey,
      this._originalIndexKeys,
      newMainMap,
      newIndexMap,
      this._indexKeys,
    );
  }

  /**
   * Alias for delete.
   */
  remove(keys: T[PK] | T[PK][]): IndexedMap<T, PK, IK> {
    return this.delete(keys);
  }

  /**
   * Deletes all items that have the given value for an index.
   *
   * Complexity: O(num matching items * num indexes)
   */
  deleteWhere<K extends IK>(indexKey: K, indexValue: ValueForKey<T, K>): IndexedMap<T, PK, IK> {
    return this.delete(this.findWhere(indexKey, indexValue).keySeq().toArray());
  }

  /**
   * Returns an empty IndexedMap of the same type.
   *
   * Complexity: O(1)
   */
  clear(): IndexedMap<T, PK, IK> {
    return new IndexedMap(this._type, this._primaryKey, this._originalIndexKeys);
  }

  private _makeIndex(keyValue: List<any>): Index<T, PK> {
    return Map([[keyValue, Map<T[PK], T>()]]);
  }

  /**
   * True if this and the other Iterable have value equality, as defined
   * by `Immutable.is()`.
   *
   * Note: This is equivalent to `Immutable.is(this, other)`, but provided to
   * allow for chained expressions.
   */
  equals(other: IndexedMap<any, any, any>): boolean {
    return (
      this._mainMap.equals(other._mainMap) &&
      this._type === other._type &&
      this._primaryKey === other._primaryKey &&
      isEqual(this._indexKeys, other._indexKeys)
    );
  }

  /**
   * Computes and returns the hashed identity for this Iterable.
   *
   * The `hashCode` of an Iterable is used to determine potential equality,
   * and is used when adding this to a `Set` or as a key in a `Map`, enabling
   * lookup via a different instance.
   *
   *     var a = List.of(1, 2, 3);
   *     var b = List.of(1, 2, 3);
   *     assert(a !== b); // different instances
   *     var set = Set.of(a);
   *     assert(set.has(b) === true);
   *
   * If two values have the same `hashCode`, they are [not guaranteed
   * to be equal][Hash Collision]. If two values have different `hashCode`s,
   * they must not be equal.
   *
   * [Hash Collision]: http://en.wikipedia.org/wiki/Collision_(computer_science)
   */
  hashCode(): number {
    return List([this._mainMap, this._type, this._primaryKey, this._indexKeys]).hashCode();
  }

  /*
  We need to explicitly override each collection method we want to use,
  because unfortunately the implementation of these in the superclasses
  calls this class's constructor with only a sequence and none of the type
  information we need to actually build indexes again. So it's not possible
  to have collection methods magically continue to return IndexedMaps.

  Right now the collection methods just return Maps, not IndexedMaps. If
  we want to change that down the line, we'll have to do that ourselves
  in these overrides.

  Make sure to follow the docs exactly to get all inherited functions:
  https://immutable-js.com/docs/v3.8.2/Map/

  Definitions can be copy-pasted and modified from immutable.d.ts.
  */

  // Persistent changes

  /**
   * Returns a new Map having updated the value at this `key` with the return
   * value of calling `updater` with the existing value, or `notSetValue` if
   * the key was not set. If called with only a single argument, `updater` is
   * called with the Map itself.
   *
   * Equivalent to: `map.set(key, updater(map.get(key, notSetValue)))`.
   */
  update(updater: (value: Map<T[PK], T>) => Map<T[PK], T>): Map<T[PK], T>;
  update(key: T[PK], updater: (value: T) => T): Map<T[PK], T>;
  update(key: T[PK], notSetValue: T, updater: (value: T) => T): Map<T[PK], T>;
  update(arg1: any, arg2?: any, arg3?: any) {
    return this._mainMap.update(arg1, arg2, arg3);
  }

  /**
   * Returns a new Map resulting from merging the provided Iterables
   * (or JS objects) into this Map. In other words, this takes each entry of
   * each iterable and sets it on this Map.
   *
   * If any of the values provided to `merge` are not Iterable (would return
   * false for `Immutable.Iterable.isIterable`) then they are deeply converted
   * via `Immutable.fromJS` before being merged. However, if the value is an
   * Iterable but includes non-iterable JS objects or arrays, those nested
   * values will be preserved.
   *
   *     var x = Immutable.Map({a: 10, b: 20, c: 30});
   *     var y = Immutable.Map({b: 40, a: 50, d: 60});
   *     x.merge(y) // { a: 50, b: 40, c: 30, d: 60 }
   *     y.merge(x) // { b: 20, a: 10, d: 60, c: 30 }
   *
   */
  merge(...iterables: Iterable<T[PK], T>[]): Map<T[PK], T>;
  merge(...iterables: { [key: string]: T }[]): Map<string, T>;
  merge(...args: any[]): Map<T[PK], T> | Map<string, T> {
    return this._mainMap.merge(...args);
  }

  /**
   * Like `merge()`, `mergeWith()` returns a new Map resulting from merging
   * the provided Iterables (or JS objects) into this Map, but uses the
   * `merger` function for dealing with conflicts.
   *
   *     var x = Immutable.Map({a: 10, b: 20, c: 30});
   *     var y = Immutable.Map({b: 40, a: 50, d: 60});
   *     x.mergeWith((prev, next) => prev / next, y) // { a: 0.2, b: 0.5, c: 30, d: 60 }
   *     y.mergeWith((prev, next) => prev / next, x) // { b: 2, a: 5, d: 60, c: 30 }
   *
   */
  mergeWith(merger: (previous?: T, next?: T, key?: T[PK]) => T, ...iterables: Iterable<T[PK], T>[]): Map<T[PK], T>;
  mergeWith(merger: (previous?: T, next?: T, key?: T[PK]) => T, ...iterables: { [key: string]: T }[]): Map<string, T>;
  mergeWith(merger: any, ...args: any[]): Map<T[PK], T> | Map<string, T> {
    return this._mainMap.mergeWith(merger, ...args);
  }

  /**
   * Like `merge()`, but when two Iterables conflict, it merges them as well,
   * recursing deeply through the nested data.
   *
   *     var x = Immutable.fromJS({a: { x: 10, y: 10 }, b: { x: 20, y: 50 } });
   *     var y = Immutable.fromJS({a: { x: 2 }, b: { y: 5 }, c: { z: 3 } });
   *     x.mergeDeep(y) // {a: { x: 2, y: 10 }, b: { x: 20, y: 5 }, c: { z: 3 } }
   *
   */
  mergeDeep(...iterables: Iterable<T[PK], T>[]): Map<T[PK], T>;
  mergeDeep(...iterables: { [key: string]: T }[]): Map<string, T>;
  mergeDeep(...args: any[]): Map<T[PK], T> | Map<string, T> {
    return this._mainMap.mergeDeep(...args);
  }

  /**
   * Like `mergeDeep()`, but when two non-Iterables conflict, it uses the
   * `merger` function to determine the resulting value.
   *
   *     var x = Immutable.fromJS({a: { x: 10, y: 10 }, b: { x: 20, y: 50 } });
   *     var y = Immutable.fromJS({a: { x: 2 }, b: { y: 5 }, c: { z: 3 } });
   *     x.mergeDeepWith((prev, next) => prev / next, y)
   *     // {a: { x: 5, y: 10 }, b: { x: 20, y: 10 }, c: { z: 3 } }
   *
   */
  mergeDeepWith(merger: (previous?: T, next?: T, key?: T[PK]) => T, ...iterables: Iterable<T[PK], T>[]): Map<T[PK], T>;
  mergeDeepWith(
    merger: (previous?: T, next?: T, key?: T[PK]) => T,
    ...iterables: { [key: string]: T }[]
  ): Map<string, T>;
  mergeDeepWith(merger: any, ...args: any[]): Map<T[PK], T> | Map<string, T> {
    return this._mainMap.mergeDeepWith(merger, ...args);
  }

  // Deep persistent changes

  /**
   * Returns a new Map having set `value` at this `keyPath`. If any keys in
   * `keyPath` do not exist, a new immutable Map will be created at that key.
   */
  setIn(keyPath: Array<any>, value: any): Map<T[PK], T>;
  setIn(keyPath: Iterable<any, any>, value: any): Map<T[PK], T>;
  setIn(keyPath: any, value: any): Map<T[PK], T> {
    return this._mainMap.setIn(keyPath, value);
  }

  /**
   * Returns a new Map having removed the value at this `keyPath`. If any keys
   * in `keyPath` do not exist, no change will occur.
   *
   * @alias removeIn
   */
  deleteIn(keyPath: Array<any>): Map<T[PK], T>;
  deleteIn(keyPath: Iterable<any, any>): Map<T[PK], T>;
  deleteIn(keyPath: any): Map<T[PK], T> {
    return this._mainMap.deleteIn(keyPath);
  }
  removeIn(keyPath: Array<any>): Map<T[PK], T>;
  removeIn(keyPath: Iterable<any, any>): Map<T[PK], T>;
  removeIn(keyPath: any): Map<T[PK], T> {
    return this._mainMap.removeIn(keyPath);
  }

  /**
   * Returns a new Map having applied the `updater` to the entry found at the
   * keyPath.
   *
   * If any keys in `keyPath` do not exist, new Immutable `Map`s will
   * be created at those keys. If the `keyPath` does not already contain a
   * value, the `updater` function will be called with `notSetValue`, if
   * provided, otherwise `undefined`.
   *
   *     var data = Immutable.fromJS({ a: { b: { c: 10 } } });
   *     data = data.updateIn(['a', 'b', 'c'], val => val * 2);
   *     // { a: { b: { c: 20 } } }
   *
   * If the `updater` function returns the same value it was called with, then
   * no change will occur. This is still true if `notSetValue` is provided.
   *
   *     var data1 = Immutable.fromJS({ a: { b: { c: 10 } } });
   *     data2 = data1.updateIn(['x', 'y', 'z'], 100, val => val);
   *     assert(data2 === data1);
   *
   */
  updateIn(keyPath: Array<any>, updater: (value: any) => any): Map<T[PK], T>;
  updateIn(keyPath: Array<any>, notSetValue: any, updater: (value: any) => any): Map<T[PK], T>;
  updateIn(keyPath: Iterable<any, any>, updater: (value: any) => any): Map<T[PK], T>;
  updateIn(keyPath: Iterable<any, any>, notSetValue: any, updater: (value: any) => any): Map<T[PK], T>;
  updateIn(keyPath: any, arg2: any, arg3?: any): Map<T[PK], T> {
    return this._mainMap.updateIn(keyPath, arg2, arg3);
  }

  /**
   * A combination of `updateIn` and `merge`, returning a new Map, but
   * performing the merge at a point arrived at by following the keyPath.
   * In other words, these two lines are equivalent:
   *
   *     x.updateIn(['a', 'b', 'c'], abc => abc.merge(y));
   *     x.mergeIn(['a', 'b', 'c'], y);
   *
   */
  mergeIn(keyPath: Iterable<any, any>, ...iterables: Iterable<T[PK], T>[]): Map<T[PK], T>;
  mergeIn(keyPath: Array<any>, ...iterables: Iterable<T[PK], T>[]): Map<T[PK], T>;
  mergeIn(keyPath: Array<any>, ...iterables: { [key: string]: T }[]): Map<string, T>;
  mergeIn(keyPath: any, ...args: any[]): Map<T[PK], T> | Map<string, T> {
    return this._mainMap.mergeIn(keyPath, ...args);
  }

  /**
   * A combination of `updateIn` and `mergeDeep`, returning a new Map, but
   * performing the deep merge at a point arrived at by following the keyPath.
   * In other words, these two lines are equivalent:
   *
   *     x.updateIn(['a', 'b', 'c'], abc => abc.mergeDeep(y));
   *     x.mergeDeepIn(['a', 'b', 'c'], y);
   *
   */
  mergeDeepIn(keyPath: Iterable<any, any>, ...iterables: Iterable<T[PK], T>[]): Map<T[PK], T>;
  mergeDeepIn(keyPath: Array<any>, ...iterables: Iterable<T[PK], T>[]): Map<T[PK], T>;
  mergeDeepIn(keyPath: Array<any>, ...iterables: { [key: string]: T }[]): Map<string, T>;
  mergeDeepIn(keyPath: any, ...args: any[]): Map<T[PK], T> | Map<string, T> {
    return this._mainMap.mergeDeepIn(keyPath, ...args);
  }

  // Transient changes

  /**
   * Every time you call one of the above functions, a new immutable Map is
   * created. If a pure function calls a number of these to produce a final
   * return value, then a penalty on performance and memory has been paid by
   * creating all of the intermediate immutable Maps.
   *
   * If you need to apply a series of mutations to produce a new immutable
   * Map, `withMutations()` creates a temporary mutable copy of the Map which
   * can apply mutations in a highly performant manner. In fact, this is
   * exactly how complex mutations like `merge` are done.
   *
   * As an example, this results in the creation of 2, not 4, new Maps:
   *
   *     var map1 = Immutable.Map();
   *     var map2 = map1.withMutations(map => {
   *       map.set('a', 1).set('b', 2).set('c', 3);
   *     });
   *     assert(map1.size === 0);
   *     assert(map2.size === 3);
   *
   * Note: Not all methods can be used on a mutable collection or within
   * `withMutations`! Only `set` and `merge` may be used mutatively.
   *
   */
  withMutations(mutator: (mutable: Map<T[PK], T>) => any): Map<T[PK], T> {
    return this._mainMap.withMutations(mutator);
  }

  /**
   * Another way to avoid creation of intermediate Immutable maps is to create
   * a mutable copy of this collection. Mutable copies *always* return `this`,
   * and thus shouldn't be used for equality. Your function should never return
   * a mutable copy of a collection, only use it internally to create a new
   * collection. If possible, use `withMutations` as it provides an easier to
   * use API.
   *
   * Note: if the collection is already mutable, `asMutable` returns itself.
   *
   * Note: Not all methods can be used on a mutable collection or within
   * `withMutations`! Only `set` and `merge` may be used mutatively.
   */
  asMutable(): Map<T[PK], T> {
    return this._mainMap.asMutable();
  }

  /**
   * The yin to `asMutable`'s yang. Because it applies to mutable collections,
   * this operation is *mutable* and returns itself. Once performed, the mutable
   * copy has become immutable and can be safely returned from a function.
   */
  asImmutable(): Map<T[PK], T> {
    return this._mainMap.asImmutable();
  }

  // Conversion to Seq

  /**
   * Returns Seq.Keyed.
   * @override
   */
  toSeq(): Seq.Keyed<T[PK], T> {
    return this._mainMap.toSeq();
  }

  /**
   * Returns a Seq.Keyed from this Iterable where indices are treated as keys.
   *
   * This is useful if you want to operate on an
   * Iterable.Indexed and preserve the [index, value] pairs.
   *
   * The returned Seq will have identical iteration order as
   * this Iterable.
   *
   * Example:
   *
   *     var indexedSeq = Immutable.Seq.of('A', 'B', 'C');
   *     indexedSeq.filter(v => v === 'B').toString() // Seq [ 'B' ]
   *     var keyedSeq = indexedSeq.toKeyedSeq();
   *     keyedSeq.filter(v => v === 'B').toString() // Seq { 1: 'B' }
   *
   */
  toKeyedSeq(): Seq.Keyed<T[PK], T> {
    return this._mainMap.toKeyedSeq();
  }

  /**
   * Returns an Seq.Indexed of the values of this Iterable, discarding keys.
   */
  toIndexedSeq(): Seq.Indexed<T> {
    return this._mainMap.toIndexedSeq();
  }

  /**
   * Returns a Seq.Set of the values of this Iterable, discarding keys.
   */
  toSetSeq(): Seq.Set<T> {
    return this._mainMap.toSetSeq();
  }

  // Reading values

  /**
   * True if a value exists within this `Iterable`, using `Immutable.is` to determine equality
   * @alias contains
   */
  includes(value: T): boolean {
    return this._mainMap.includes(value);
  }
  contains(value: T): boolean {
    return this.includes(value);
  }

  /**
   * The first value in the Iterable.
   */
  first<NSV = undefined>(notSetValue?: NSV): T | NSV {
    return this._mainMap.first(notSetValue);
  }

  /**
   * The last value in the Iterable.
   */
  last<NSV = undefined>(notSetValue?: NSV): T | NSV {
    return this._mainMap.last(notSetValue);
  }

  // Reading deep values

  /**
   * Returns the value found by following a path of keys or indices through
   * nested Iterables.
   */
  // getIn(searchKeyPath: Array<any>, notSetValue?: any): any;
  // getIn(searchKeyPath: Iterable<any, any>, notSetValue?: any): any;
  getIn(searchKeyPath: Iterable<unknown, unknown>, notSetValue?: unknown): unknown {
    return this._mainMap.getIn(searchKeyPath, notSetValue);
  }

  /**
   * True if the result of following a path of keys or indices through nested
   * Iterables results in a set value.
   */
  // hasIn(searchKeyPath: Array<any>): boolean;
  // hasIn(searchKeyPath: Iterable<any, any>): boolean;
  hasIn(searchKeyPath: Iterable<unknown, unknown>): boolean {
    return this._mainMap.hasIn(searchKeyPath);
  }

  // Conversion to JavaScript types

  /**
   * Deeply converts this Iterable to equivalent JS.
   *
   * `Iterable.Indexeds`, and `Iterable.Sets` become Arrays, while
   * `Iterable.Keyeds` become Objects.
   */
  toJS(): Array<unknown> | { [key: string]: unknown } {
    return this._mainMap.toJS();
  }

  toArray(): Array<T> {
    return this._mainMap.toArray();
  }

  /**
   * Shallowly converts this iterable to an Array, discarding keys.
   */
  toJSON(): Array<T> | { [key: string]: T } {
    return this._mainMap.toJSON();
  }

  /**
   * Shallowly converts this Iterable to an Object.
   *
   * Throws if keys are not strings.
   */
  toObject(): { [key: string]: T } {
    return this._mainMap.toObject();
  }

  // Conversion to Collections

  /**
   * Converts this Iterable to a Map, Throws if keys are not hashable.
   *
   * Note: This is equivalent to `Map(this.toKeyedSeq())`, but provided
   * for convenience and to allow for chained expressions.
   */
  toMap(): Map<T[PK], T> {
    return this._mainMap.toMap();
  }

  /**
   * Converts this Iterable to a Map, maintaining the order of iteration.
   *
   * Note: This is equivalent to `OrderedMap(this.toKeyedSeq())`, but
   * provided for convenience and to allow for chained expressions.
   */
  toOrderedMap(): OrderedMap<T[PK], T> {
    return this._mainMap.toOrderedMap();
  }

  /**
   * Converts this Iterable to a Set, discarding keys. Throws if values
   * are not hashable.
   *
   * Note: This is equivalent to `Set(this)`, but provided to allow for
   * chained expressions.
   */
  toSet(): Set<T> {
    return this._mainMap.toSet();
  }

  /**
   * Converts this Iterable to a Set, maintaining the order of iteration and
   * discarding keys.
   *
   * Note: This is equivalent to `OrderedSet(this.valueSeq())`, but provided
   * for convenience and to allow for chained expressions.
   */
  toOrderedSet(): OrderedSet<T> {
    return this._mainMap.toOrderedSet();
  }

  /**
   * Converts this Iterable to a List, discarding keys.
   *
   * Note: This is equivalent to `List(this)`, but provided to allow
   * for chained expressions.
   */
  toList(): List<T> {
    return this._mainMap.toList();
  }

  /**
   * Converts this Iterable to a Stack, discarding keys. Throws if values
   * are not hashable.
   *
   * Note: This is equivalent to `Stack(this)`, but provided to allow for
   * chained expressions.
   */
  toStack(): Stack<T> {
    return this._mainMap.toStack();
  }

  // Iterators

  /**
   * An iterator of this `Iterable`'s keys.
   *
   * Note: this will return an ES6 iterator which does not support Immutable JS sequence algorithms. Use `keySeq` instead, if this is what you want.
   */
  keys(): IterableIterator<T[PK]> {
    return this._mainMap.keys();
  }

  /**
   * An iterator of this `Iterable`'s values.
   *
   * Note: this will return an ES6 iterator which does not support Immutable JS sequence algorithms. Use `valueSeq` instead, if this is what you want.
   */
  values(): IterableIterator<T> {
    return this._mainMap.values();
  }

  /**
   * An iterator of this `Iterable`'s entries as `[key, value]` tuples.
   *
   * Note: this will return an ES6 iterator which does not support Immutable JS sequence algorithms. Use `entrySeq` instead, if this is what you want.
   */
  entries(): IterableIterator<[T[PK], T]> {
    return this._mainMap.entries();
  }

  // Iterables (Seq)

  /**
   * Returns a new Seq.Indexed of the keys of this Iterable,
   * discarding values.
   */
  keySeq(): Seq.Indexed<T[PK]> {
    return this._mainMap.keySeq();
  }

  /**
   * Returns an Seq.Indexed of the values of this Iterable, discarding keys.
   */
  valueSeq(): Seq.Indexed<T> {
    return this._mainMap.valueSeq();
  }

  /**
   * Returns a new Seq.Indexed of [key, value] tuples.
   */
  entrySeq(): Seq.Indexed</*(K, V)*/ Array<any>> {
    return this._mainMap.entrySeq();
  }

  // Sequence algorithms

  /**
   * Returns a new Iterable of the same type with values passed through a
   * `mapper` function.
   *
   *     Seq({ a: 1, b: 2 }).map(x => 10 * x)
   *     // Seq { a: 10, b: 20 }
   *
   */
  map<M>(mapper: (value: T, key?: T[PK], iter?: Map<T[PK], T>) => M, context?: unknown): Map<T[PK], T> {
    return this._mainMap.map(mapper, context);
  }

  /**
   * Returns a new Iterable of the same type with only the entries for which
   * the `predicate` function returns true.
   *
   *     Seq({a:1,b:2,c:3,d:4}).filter(x => x % 2 === 0)
   *     // Seq { b: 2, d: 4 }
   *
   */
  filter(predicate: (value: T, key: T[PK], iter: Map<T[PK], T>) => unknown, context?: unknown): Map<T[PK], T> {
    return this._mainMap.filter(predicate, context);
  }

  /**
   * Returns a new Iterable of the same type with only the entries for which
   * the `predicate` function returns false.
   *
   *     Seq({a:1,b:2,c:3,d:4}).filterNot(x => x % 2 === 0)
   *     // Seq { a: 1, c: 3 }
   *
   */
  filterNot(
    predicate: (value: T, key?: T[PK], iter?: Map<T[PK], T>) => boolean,
    context?: unknown,
  ): Iterable<T[PK], T> {
    return this._mainMap.filterNot(predicate, context);
  }

  /**
   * Returns a new Iterable of the same type in reverse order.
   */
  reverse(): Iterable<T[PK], T> {
    return this._mainMap.reverse();
  }

  /**
   * Returns a new Iterable of the same type which includes the same entries,
   * stably sorted by using a `comparator`.
   *
   * If a `comparator` is not provided, a default comparator uses `<` and `>`.
   *
   * `comparator(valueA, valueB)`:
   *
   *   * Returns `0` if the elements should not be swapped.
   *   * Returns `-1` (or any negative number) if `valueA` comes before `valueB`
   *   * Returns `1` (or any positive number) if `valueA` comes after `valueB`
   *   * Is pure, i.e. it must always return the same value for the same pair
   *     of values.
   *
   * When sorting collections which have no defined order, their ordered
   * equivalents will be returned. e.g. `map.sort()` returns OrderedMap.
   */
  sort(comparator?: (valueA: T, valueB: T) => number): OrderedMap<T[PK], T> {
    return this._mainMap.sort(comparator);
  }

  /**
   * Like `sort`, but also accepts a `comparatorValueMapper` which allows for
   * sorting by more sophisticated means:
   *
   *     hitters.sortBy(hitter => hitter.avgHits);
   *
   */
  sortBy<C>(
    comparatorValueMapper: (value: T, key?: T[PK], iter?: Map<T[PK], T>) => C,
    comparator?: (valueA: C, valueB: C) => number,
  ): Iterable<T[PK], T> {
    return this._mainMap.sortBy(comparatorValueMapper, comparator);
  }

  /**
   * Returns a `Iterable.Keyed` of `Iterable.Keyeds`, grouped by the return
   * value of the `grouper` function.
   *
   * Note: This is always an eager operation.
   */
  groupBy<G>(
    grouper: (value: T, key?: T[PK], iter?: Map<T[PK], T>) => G,
    context?: any,
  ): Seq.Keyed<G, Iterable<T[PK], T>> {
    if (!Environment.isProduction()) {
      console.warn(
        new Error('Rather than calling groupBy on an IndexedMap, you are encouraged to make an index instead.'),
      );
    }
    return this._mainMap.groupBy(grouper, context);
  }

  // Side effects

  /**
   * The `sideEffect` is executed for every entry in the Iterable.
   *
   * Unlike `Array#forEach`, if any call of `sideEffect` returns
   * `false`, the iteration will stop. Returns the number of entries iterated
   * (including the last iteration which returned false).
   */
  forEach(sideEffect: (value?: T, key?: T[PK], iter?: Iterable<T[PK], T>) => any, context?: any): number {
    return this._mainMap.forEach(sideEffect, context);
  }

  // Creating subsets

  /**
   * Returns a new Iterable of the same type representing a portion of this
   * Iterable from start up to but not including end.
   *
   * If begin is negative, it is offset from the end of the Iterable. e.g.
   * `slice(-2)` returns a Iterable of the last two entries. If it is not
   * provided the new Iterable will begin at the beginning of this Iterable.
   *
   * If end is negative, it is offset from the end of the Iterable. e.g.
   * `slice(0, -1)` returns an Iterable of everything but the last entry. If
   * it is not provided, the new Iterable will continue through the end of
   * this Iterable.
   *
   * If the requested slice is equivalent to the current Iterable, then it
   * will return itself.
   */
  slice(begin?: number, end?: number): Iterable<T[PK], T> {
    return this._mainMap.slice(begin, end);
  }

  /**
   * Returns a new Iterable of the same type containing all entries except
   * the first.
   */
  rest(): Iterable<T[PK], T> {
    return this._mainMap.rest();
  }

  /**
   * Returns a new Iterable of the same type containing all entries except
   * the last.
   */
  butLast(): Iterable<T[PK], T> {
    return this._mainMap.butLast();
  }

  /**
   * Returns a new Iterable of the same type which excludes the first `amount`
   * entries from this Iterable.
   */
  skip(amount: number): Iterable<T[PK], T> {
    return this._mainMap.skip(amount);
  }

  /**
   * Returns a new Iterable of the same type which excludes the last `amount`
   * entries from this Iterable.
   */
  skipLast(amount: number): Iterable<T[PK], T> {
    return this._mainMap.skipLast(amount);
  }

  /**
   * Returns a new Iterable of the same type which includes entries starting
   * from when `predicate` first returns false.
   *
   *     Seq.of('dog','frog','cat','hat','god')
   *       .skipWhile(x => x.match(/g/))
   *     // Seq [ 'cat', 'hat', 'god' ]
   *
   */
  skipWhile(
    predicate: (value?: T, key?: T[PK], iter?: Iterable<T[PK], T>) => boolean,
    context?: any,
  ): Iterable<T[PK], T> {
    return this._mainMap.skipWhile(predicate, context);
  }

  /**
   * Returns a new Iterable of the same type which includes entries starting
   * from when `predicate` first returns true.
   *
   *     Seq.of('dog','frog','cat','hat','god')
   *       .skipUntil(x => x.match(/hat/))
   *     // Seq [ 'hat', 'god' ]
   *
   */
  skipUntil(
    predicate: (value?: T, key?: T[PK], iter?: Iterable<T[PK], T>) => boolean,
    context?: any,
  ): Iterable<T[PK], T> {
    return this._mainMap.skipUntil(predicate, context);
  }

  /**
   * Returns a new Iterable of the same type which includes the first `amount`
   * entries from this Iterable.
   */
  take(amount: number): Iterable<T[PK], T> {
    return this._mainMap.take(amount);
  }

  /**
   * Returns a new Iterable of the same type which includes the last `amount`
   * entries from this Iterable.
   */
  takeLast(amount: number): Iterable<T[PK], T> {
    return this._mainMap.takeLast(amount);
  }

  /**
   * Returns a new Iterable of the same type which includes entries from this
   * Iterable as long as the `predicate` returns true.
   *
   *     Seq.of('dog','frog','cat','hat','god')
   *       .takeWhile(x => x.match(/o/))
   *     // Seq [ 'dog', 'frog' ]
   *
   */
  takeWhile(
    predicate: (value?: T, key?: T[PK], iter?: Iterable<T[PK], T>) => boolean,
    context?: any,
  ): Iterable<T[PK], T> {
    return this._mainMap.takeWhile(predicate, context);
  }

  /**
   * Returns a new Iterable of the same type which includes entries from this
   * Iterable as long as the `predicate` returns false.
   *
   *     Seq.of('dog','frog','cat','hat','god').takeUntil(x => x.match(/at/))
   *     // ['dog', 'frog']
   *
   */
  takeUntil(
    predicate: (value?: T, key?: T[PK], iter?: Iterable<T[PK], T>) => boolean,
    context?: any,
  ): Iterable<T[PK], T> {
    return this._mainMap.takeUntil(predicate, context);
  }

  // Combination

  /**
   * Returns a new Iterable of the same type with other values and
   * iterable-like concatenated to this one.
   *
   * For Seqs, all entries will be present in
   * the resulting iterable, even if they have the same key.
   */
  concat(...valuesOrIterables: /*Array<Iterable<K, V>|V*/ any[]): Iterable<T[PK], T> {
    return this._mainMap.concat(...valuesOrIterables);
  }

  /**
   * Flattens nested Iterables.
   *
   * Will deeply flatten the Iterable by default, returning an Iterable of the
   * same type, but a `depth` can be provided in the form of a number or
   * boolean (where true means to shallowly flatten one level). A depth of 0
   * (or shallow: false) will deeply flatten.
   *
   * Flattens only others Iterable, not Arrays or Objects.
   *
   * Note: `flatten(true)` operates on Iterable<any, Iterable<K, V>> and
   * returns Iterable<K, V>
   */
  flatten(depth?: number): Iterable<any, any>;
  flatten(shallow?: boolean): Iterable<any, any>;
  flatten(arg1?: any): Iterable<any, any> {
    return this._mainMap.flatten(arg1);
  }

  /**
   * Flat-maps the Iterable, returning an Iterable of the same type.
   *
   * Similar to `iter.map(...).flatten(true)`.
   */
  flatMap<MK, MV>(
    mapper: (value: T, key?: T[PK], iter?: Map<T[PK], T>) => Iterable<MK, MV>,
    context?: any,
  ): Map<T[PK], T>;
  flatMap(mapper: (value: T, key?: T[PK], iter?: Map<T[PK], T>) => any, context?: any): Map<T[PK], T> {
    return this._mainMap.flatMap(mapper, context);
  }

  // Reducing a value

  /**
   * Reduces the Iterable to a value by calling the `reducer` for every entry
   * in the Iterable and passing along the reduced value.
   *
   * If `initialReduction` is not provided, or is null, the first item in the
   * Iterable will be used.
   *
   * @see `Array#reduce`.
   */
  reduce<R>(
    reducer: (reduction: R, value: T, key?: T[PK], iter?: Iterable<T[PK], T>) => R,
    initialReduction?: R,
    context?: any,
  ): R {
    return this._mainMap.reduce(reducer, initialReduction, context);
  }

  /**
   * Reduces the Iterable in reverse (from the right side).
   *
   * Note: Similar to this.reverse().reduce(), and provided for parity
   * with `Array#reduceRight`.
   */
  reduceRight<R>(
    reducer: (reduction: R, value: T, key?: T[PK], iter?: Iterable<T[PK], T>) => R,
    initialReduction?: R,
    context?: any,
  ): R {
    return this._mainMap.reduceRight(reducer, initialReduction, context);
  }

  /**
   * True if `predicate` returns true for all entries in the Iterable.
   */
  every(predicate: (value: T, key?: T[PK], iter?: Iterable<T[PK], T>) => boolean, context?: any): boolean {
    return this._mainMap.every(predicate, context);
  }

  /**
   * True if `predicate` returns true for any entry in the Iterable.
   */
  some(predicate: (value: T, key?: T[PK], iter?: Iterable<T[PK], T>) => boolean, context?: any): boolean {
    return this._mainMap.some(predicate, context);
  }

  /**
   * Joins values together as a string, inserting a separator between each.
   * The default separator is `","`.
   */
  join(separator?: string): string {
    return this._mainMap.join(separator);
  }

  /**
   * Returns true if this Iterable includes no values.
   *
   * For some lazy `Seq`, `isEmpty` might need to iterate to determine
   * emptiness. At most one iteration will occur.
   */
  isEmpty(): boolean {
    return this._mainMap.isEmpty();
  }

  /**
   * Returns the size of this Iterable.
   *
   * Regardless of if this Iterable can describe its size lazily (some Seqs
   * cannot), this method will always return the correct size. E.g. it
   * evaluates a lazy `Seq` if necessary.
   *
   * If `predicate` is provided, then this returns the count of entries in the
   * Iterable for which the `predicate` returns true.
   */
  count(): number;
  count(predicate?: (value: T, key?: T[PK], iter?: Iterable<T[PK], T>) => boolean, context?: any): number {
    return this._mainMap.count(predicate, context);
  }

  /**
   * Returns a `Seq.Keyed` of counts, grouped by the return value of
   * the `grouper` function.
   *
   * Note: This is not a lazy operation.
   */
  countBy<G>(grouper: (value: T, key?: T[PK], iter?: Iterable<T[PK], T>) => G, context?: any): Map<G, number> {
    return this._mainMap.countBy(grouper, context);
  }

  // Search for value

  /**
   * Returns the first value for which the `predicate` returns true.
   */
  find<NSV = undefined>(
    predicate: (value: T, key?: T[PK], iter?: Iterable<T[PK], T>) => boolean,
    context?: any,
    notSetValue?: NSV,
  ) {
    return this._mainMap.find(predicate, context, notSetValue);
  }

  /**
   * Returns the last value for which the `predicate` returns true.
   *
   * Note: `predicate` will be called for each entry in reverse.
   */
  findLast(
    predicate: (value: T, key?: T[PK], iter?: Iterable<T[PK], T>) => boolean,
    context?: any,
    notSetValue?: T,
  ): T {
    return this._mainMap.findLast(predicate, context, notSetValue);
  }

  /**
   * Returns the first [key, value] entry for which the `predicate` returns true.
   */
  findEntry(
    predicate: (value: T, key?: T[PK], iter?: Iterable<T[PK], T>) => boolean,
    context?: any,
    notSetValue?: T,
  ): Array<any> {
    return this._mainMap.findEntry(predicate, context, notSetValue);
  }

  /**
   * Returns the last [key, value] entry for which the `predicate`
   * returns true.
   *
   * Note: `predicate` will be called for each entry in reverse.
   */
  findLastEntry(
    predicate: (value: T, key?: T[PK], iter?: Iterable<T[PK], T>) => boolean,
    context?: any,
    notSetValue?: T,
  ): Array<any> {
    return this._mainMap.findLastEntry(predicate, context, notSetValue);
  }

  /**
   * Returns the key for which the `predicate` returns true.
   */
  findKey(predicate: (value: T, key?: T[PK], iter?: Iterable.Keyed<T[PK], T>) => boolean, context?: any): T[PK] {
    return this._mainMap.findKey(predicate, context);
  }

  /**
   * Returns the last key for which the `predicate` returns true.
   *
   * Note: `predicate` will be called for each entry in reverse.
   */
  findLastKey(predicate: (value: T, key?: T[PK], iter?: Iterable.Keyed<T[PK], T>) => boolean, context?: any): T[PK] {
    return this._mainMap.findLastKey(predicate, context);
  }

  /**
   * Returns the key associated with the search value, or undefined.
   */
  keyOf(searchValue: T): T[PK] {
    return this._mainMap.keyOf(searchValue);
  }

  /**
   * Returns the last key associated with the search value, or undefined.
   */
  lastKeyOf(searchValue: T): T[PK] {
    return this._mainMap.lastKeyOf(searchValue);
  }

  /**
   * Returns the maximum value in this collection. If any values are
   * comparatively equivalent, the first one found will be returned.
   *
   * The `comparator` is used in the same way as `Iterable#sort`. If it is not
   * provided, the default comparator is `>`.
   *
   * When two values are considered equivalent, the first encountered will be
   * returned. Otherwise, `max` will operate independent of the order of input
   * as long as the comparator is commutative. The default comparator `>` is
   * commutative *only* when types do not differ.
   *
   * If `comparator` returns 0 and either value is NaN, undefined, or null,
   * that value will be returned.
   */
  max(comparator?: (valueA: T, valueB: T) => number): T {
    return this._mainMap.max(comparator);
  }

  /**
   * Like `max`, but also accepts a `comparatorValueMapper` which allows for
   * comparing by more sophisticated means:
   *
   *     hitters.maxBy(hitter => hitter.avgHits);
   *
   */
  maxBy<C>(
    comparatorValueMapper: (value: T, key?: T[PK], iter?: /*this*/ Iterable<T[PK], T>) => C,
    comparator?: (valueA: C, valueB: C) => number,
  ): T {
    return this._mainMap.maxBy(comparatorValueMapper, comparator);
  }

  /**
   * Returns the minimum value in this collection. If any values are
   * comparatively equivalent, the first one found will be returned.
   *
   * The `comparator` is used in the same way as `Iterable#sort`. If it is not
   * provided, the default comparator is `<`.
   *
   * When two values are considered equivalent, the first encountered will be
   * returned. Otherwise, `min` will operate independent of the order of input
   * as long as the comparator is commutative. The default comparator `<` is
   * commutative *only* when types do not differ.
   *
   * If `comparator` returns 0 and either value is NaN, undefined, or null,
   * that value will be returned.
   */
  min(comparator?: (valueA: T, valueB: T) => number): T {
    return this._mainMap.min(comparator);
  }

  /**
   * Like `min`, but also accepts a `comparatorValueMapper` which allows for
   * comparing by more sophisticated means:
   *
   *     hitters.minBy(hitter => hitter.avgHits);
   *
   */
  minBy<C>(
    comparatorValueMapper: (value: T, key?: T[PK], iter?: /*this*/ Iterable<T[PK], T>) => C,
    comparator?: (valueA: C, valueB: C) => number,
  ): T {
    return this._mainMap.minBy(comparatorValueMapper, comparator);
  }

  // Comparison

  /**
   * True if `iter` includes every value in this Iterable.
   */
  isSubset(iter: Iterable<any, T>): boolean;
  isSubset(iter: Array<T>): boolean;
  isSubset(iter: any): boolean {
    return this._mainMap.isSubset(iter);
  }

  /**
   * True if this Iterable includes every value in `iter`.
   */
  isSuperset(iter: Iterable<any, T>): boolean;
  isSuperset(iter: Array<T>): boolean;
  isSuperset(iter: any): boolean {
    return this._mainMap.isSuperset(iter);
  }

  // Sequence functions

  /**
   * Returns a new Iterable.Keyed of the same type where the keys and values
   * have been flipped.
   *
   *     Seq({ a: 'z', b: 'y' }).flip() // { z: 'a', y: 'b' }
   *
   */
  flip(): Iterable.Keyed<T, T[PK]> {
    return this._mainMap.flip();
  }

  /**
   * Returns a new Iterable.Keyed of the same type with keys passed through
   * a `mapper` function.
   *
   *     Seq({ a: 1, b: 2 })
   *       .mapKeys(x => x.toUpperCase())
   *     // Seq { A: 1, B: 2 }
   *
   */
  mapKeys<M>(mapper: (key: T[PK], value?: T, iter?: Map<T[PK], T>) => M, context?: unknown): Map<T[PK], T> {
    return this._mainMap.mapKeys(mapper, context);
  }

  /**
   * Returns a new Iterable.Keyed of the same type with entries
   * ([key, value] tuples) passed through a `mapper` function.
   *
   *     Seq({ a: 1, b: 2 })
   *       .mapEntries(([k, v]) => [k.toUpperCase(), v * 2])
   *     // Seq { A: 2, B: 4 }
   *
   */
  mapEntries<KM, VM>(
    mapper: (entry: [T[PK], T], index: number, iter?: Map<T[PK], T>) => [KM, VM],
    context?: unknown,
  ): Map<T[PK], T> {
    return this._mainMap.mapEntries(mapper, context);
  }

  // And finally, iterators

  [Symbol.iterator](): IterableIterator<[T[PK], T]> {
    return this._mainMap[Symbol.iterator]();
  }

  __iterate(fn: any, reverse: any) {
    // @ts-ignore
    return this._mainMap.__iterate(fn, reverse);
  }

  __iterator(type: any, reverse: any) {
    // @ts-ignore
    return this._mainMap.__iterator(type, reverse);
  }
}

export default IndexedMap;
