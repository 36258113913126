import { lazy } from 'react';

import ReportDataDoesNotExistDialog from 'reporting/dialogs/ReportDataDoesNotExistDialog';

const BetaFeedbackDialog = lazy(() => import('reporting/dialogs/BetaFeedbackDialog'));
const ChangeReportTypeDialog = lazy(() => import('reporting/dialogs/ChangeReportTypeDialog'));
const DateRangeDialog = lazy(() => import('reporting/dialogs/DateRangeDialog'));
const ExportArchivedUsersDialog = lazy(() => import('reporting/dialogs/ExportArchivedUsersDialog'));
const ExportReportArchetypeDialog = lazy(() => import('reporting/dialogs/ExportReportArchetypeDialog'));
const LoadReportDialog = lazy(() => import('reporting/dialogs/LoadReportDialog'));
const ReportTypeDialog = lazy(() => import('reporting/dialogs/ReportTypeDialog'));
const SelectAccountDialog = lazy(() => import('reporting/dialogs/SelectAccountDialog'));

// Identifiers for each dialog
export const BETA_FEEDBACK_DIALOG = 'BETA_FEEDBACK_DIALOG';
export const CHANGE_REPORT_TYPE_DIALOG = 'CHANGE_REPORT_TYPE_DIALOG';
export const DATE_RANGE_DIALOG = 'DATE_RANGE_DIALOG';
export const EXPORT_ARCHIVED_USERS_DIALOG = 'EXPORT_ARCHIVED_USERS_DIALOG';
export const EXPORT_REPORT_ARCHETYPE_DIALOG = 'EXPORT_REPORT_ARCHETYPE_DIALOG';
export const LOAD_REPORT_DIALOG = 'LOAD_REPORT_DIALOG';
export const REPORT_DATA_DOES_NOT_EXIST_DIALOG = 'REPORT_DATA_DOES_NOT_EXIST_DIALOG';
export const REPORT_TYPE_DIALOG = 'REPORT_TYPE_DIALOG';
export const SELECT_ACCOUNT_DIALOG = 'SELECT_ACCOUNT_DIALOG';

const dialogs = {
  [BETA_FEEDBACK_DIALOG]: BetaFeedbackDialog,
  [CHANGE_REPORT_TYPE_DIALOG]: ChangeReportTypeDialog,
  [DATE_RANGE_DIALOG]: DateRangeDialog,
  [EXPORT_ARCHIVED_USERS_DIALOG]: ExportArchivedUsersDialog,
  [EXPORT_REPORT_ARCHETYPE_DIALOG]: ExportReportArchetypeDialog,
  [LOAD_REPORT_DIALOG]: LoadReportDialog,
  [REPORT_DATA_DOES_NOT_EXIST_DIALOG]: ReportDataDoesNotExistDialog,
  [REPORT_TYPE_DIALOG]: ReportTypeDialog,
  [SELECT_ACCOUNT_DIALOG]: SelectAccountDialog,
  // ...
};

export default dialogs;
