import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment-timezone';

import type { EntityMap } from 'data/types';
import type { WiwState } from 'store';
import type { ReduxConversation } from 'workchat/v2/store/conversations/conversationsReducer';

export const getStateWorkchat = (state: WiwState) => state.workchatv2;

export const getConversationsState = createSelector([getStateWorkchat], workchatState => workchatState.conversations);

export const getConversationEntityMap = createSelector(
  [getConversationsState],
  conversationState => conversationState.items as EntityMap<ReduxConversation>,
);

export const getConversationById = createSelector(
  [getConversationEntityMap, (_state, conversationId) => conversationId],
  (conversations, conversationId) => conversations.get(conversationId),
);

export const getConversationsLoaded = createSelector([getStateWorkchat], state => state.conversations.loaded);

export const getConversationsAsList = createSelector([getConversationEntityMap], conversations => {
  const sortedConversations = conversations
    .sortBy(conversation => -moment(conversation.lastMessage?.dateCreated || conversation.dateCreated))
    .filter(conversation => conversation.attributes.external_type !== 'account')
    .toList();
  const accountConversation = conversations
    .filter(conversation => conversation.attributes.external_type === 'account')
    .toList();

  return accountConversation.concat(sortedConversations);
});

export const getUnreadCountByConversationId = createSelector(
  [getConversationsState],
  conversationState => conversationState.unreadCountByConversationId,
);

export const getUnreadCountForConversationId = createSelector(
  [getUnreadCountByConversationId, (_state, conversationId) => conversationId],
  (unreadCountByConversationId, conversationId) => unreadCountByConversationId.get(conversationId),
);

export const getUnreadCountForAllConversations = createSelector(
  [getUnreadCountByConversationId],
  unreadCountByConversationId =>
    unreadCountByConversationId.valueSeq().reduce((sum, count) => sum + (count ? count : 0), 0),
);

export const getConversationPermissions = createSelector(
  [getConversationsState],
  conversationsState => conversationsState.permissions,
);

export const getConversationPermissionsForConversationId = createSelector(
  [getConversationPermissions, (_state, conversationId) => conversationId],
  (permissionsState, conversationId: string) => permissionsState.get(conversationId),
);

export const getTypingParticipantsForConversationId = createSelector(
  [getConversationsState, (_state, conversationId) => conversationId],
  (conversationState, conversationId: string) =>
    conversationState.getIn(['typingUsersByConversationId', conversationId], []) as number[],
);
