import { DateTime, IANAZone } from 'luxon';
import moment from 'moment-timezone';

// For a given date/moment, check if the user's TZ has a different UTC offset than the account's TZ
export function isTimezoneOffsetDifferent(date, userTzName, accountTzName) {
  if (DateTime.isDateTime(date)) {
    const userZone = new IANAZone(userTzName);
    const accountZone = new IANAZone(accountTzName);

    return userZone.offset(date) !== accountZone.offset(date);
  }
  const userZone = moment.tz.zone(userTzName);
  const accountZone = moment.tz.zone(accountTzName);

  return userZone.utcOffset(date) !== accountZone.utcOffset(date);
}
