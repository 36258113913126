import type { ReportMetadataRequestParams } from 'data/reporting/actions/reportMetadataRequestType';
import { getReportTempAccountId } from 'reporting/selectors/getReportTempAccountId';
import { getReportTempDataset } from 'reporting/selectors/getReportTempDataset';

import { createSelector } from '@reduxjs/toolkit';

export const getMetadataRequestTempParams = createSelector(
  [getReportTempAccountId, getReportTempDataset],
  (accountId, tempDataset) => {
    return {
      accountId: `${accountId}`,
      dataset: tempDataset,
      limit: 1,
    } as ReportMetadataRequestParams;
  },
);
