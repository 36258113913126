import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { errorNotice } from 'notices';
import { useWiwDispatch } from 'store';
import type { CheckHandler } from 'types/custom';

interface Props {
  url: string;
  onEvent: (event: string, data: any) => void;
  onClose?: () => void;
  onboard?: boolean;
}

const useCheckComponentHandler = ({ url, onEvent, onClose, onboard }: Props) => {
  const target = useRef<HTMLDivElement>();
  const dispatch = useWiwDispatch();
  const [toggled, setToggled] = useState(false);
  const createHandler = useMemo(() => {
    const settings = {
      link: url,
      onEvent: onEvent,
      onClose: onClose,
      appearance: {
        fontFamily: '"Source Sans Pro", sans-serif',
        monospaceFontFamily: '"Source Sans Pro", sans-serif',
        primaryColor: '#51a33d',
        includeCloseButton: false,
      },
    };

    if (onboard) {
      return window.Check.create(settings);
    }

    return window.CheckComponent.create(settings);
  }, [url, onEvent]);

  const [handler] = useState<CheckHandler>(createHandler);

  const setTarget = useCallback((node: HTMLDivElement) => {
    target.current = node;
    setToggled(!toggled);
  }, []);

  useEffect(() => {
    if (!handler || !toggled) {
      return;
    }

    try {
      handler.open();

      const componentElement = document.getElementById('check-component-embedded-div');
      if (componentElement && target.current) {
        target.current.appendChild(componentElement);
      }
    } catch (_err) {
      dispatch(errorNotice('An error occurred while loading the dialog.'));
    }

    return () => {
      handler.close();
    };
  }, [handler, toggled]);

  return [setTarget, toggled] as const;
};

export default useCheckComponentHandler;
