import { getStateUsers } from 'data/user/selectors';
import { filterUsersByLocation, getStateLocationFilter } from 'scheduler/selectors/filters';
import { getAuthAccount } from 'shared/auth/selectors';

import { createSelector } from '@reduxjs/toolkit';

export const getSchedulableUsers = createSelector(getStateUsers, items =>
  items.findWhere(['is_active', 'is_deleted'], [true, false]),
);

export const getFilteredLocationUsers = createSelector(
  [getSchedulableUsers, getStateLocationFilter],
  (users, locationId) => {
    return filterUsersByLocation(locationId, users);
  },
);

export const getUsersToSchedule = createSelector(
  [getAuthAccount, getStateLocationFilter, getSchedulableUsers],
  (account, locationId, users) => {
    // return all schedulable users if shared openshifts is enabled
    if (account.getSettings('schedule.shared_openshifts', false)) {
      return users;
    }

    // filter down to schedulable users at the current location
    return filterUsersByLocation(locationId, users);
  },
);
