import type { PersonFields } from 'data/person/model';
import Login from 'shared/api/login';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type FetchPersonResponse = {
  person: PersonFields;
  token: string;
};

export default createWiwAsyncThunk<FetchPersonResponse>('person/fetchPerson', async (_, { rejectWithValue }) => {
  try {
    return (await Login.get('/people/me')) as FetchPersonResponse;
  } catch (error) {
    return rejectWithValue(parseAPIError(error));
  }
});
