import {
  fetchCurrentStateColumnsFailure,
  fetchCurrentStateColumnsRequested,
  fetchCurrentStateColumnsSuccess,
} from 'data/reporting/actions/fetchCurrentStateColumns';
import {
  fetchCurrentStateCountsFailure,
  fetchCurrentStateCountsRequested,
  fetchCurrentStateCountsSuccess,
} from 'data/reporting/actions/fetchCurrentStateCounts';
import {
  fetchCustomReportMetadataFailure,
  fetchCustomReportMetadataRequested,
  fetchCustomReportMetadataSuccess,
} from 'data/reporting/actions/fetchCustomReportMetadata';
import type { ReportMetadataColumnDetail } from 'data/reporting/models/ReportMetadataColumnDetail';
import { ReportMetadataRowsPerDay } from 'data/reporting/models/ReportMetadataRowsPerDay';
import {
  CustomMetadataState,
  type CustomMetadataStateByDatasetByAccountId,
} from 'data/reporting/state/CustomMetadataState';

import type { AnyAction } from '@reduxjs/toolkit';
import { Map, OrderedMap } from 'immutable';
import { DateTime } from 'luxon';

export const customMetadata = (
  state: CustomMetadataStateByDatasetByAccountId = Map(),
  action: AnyAction,
): CustomMetadataStateByDatasetByAccountId => {
  // New (current state) columns fetching
  if (fetchCurrentStateColumnsFailure.match(action)) {
    const previous: CustomMetadataState =
      (state.getIn([action.params.accountId, action.params.dataset]) as CustomMetadataState) ??
      new CustomMetadataState();
    return state.setIn(
      [action.params.accountId, action.params.dataset],
      previous.merge({
        error: action.error,
        loaded: false,
        loading: false,
      }),
    );
  }
  if (fetchCurrentStateColumnsRequested.match(action)) {
    const previous: CustomMetadataState =
      (state.getIn([action.params.accountId, action.params.dataset]) as CustomMetadataState) ??
      new CustomMetadataState();
    return state.setIn(
      [action.params.accountId, action.params.dataset],
      previous.merge({
        error: null,
        loaded: false,
        loading: true,
      }),
    );
  }
  if (fetchCurrentStateColumnsSuccess.match(action)) {
    const previous: CustomMetadataState =
      (state.getIn([action.params.accountId, action.params.dataset]) as CustomMetadataState) ??
      new CustomMetadataState();
    let columns: OrderedMap<string, ReportMetadataColumnDetail> = previous.columns ?? OrderedMap();
    if (action.response) {
      columns = OrderedMap(
        action.response.map(value => {
          return [value.column, value];
        }),
      );
    }

    return state.setIn(
      [action.params.accountId, action.params.dataset],
      previous.merge({
        error: null,
        loaded: true,
        loading: false,
        columns,
        receivedAt: Date.now(),
        reportingPeriodEnd: DateTime.invalid('invalid'),
        reportingPeriodStart: DateTime.invalid('invalid'),
      }),
    );
  }

  // New (current state) counts fetching
  if (fetchCurrentStateCountsFailure.match(action)) {
    const previous: CustomMetadataState =
      (state.getIn([action.params.accountId, action.params.dataset]) as CustomMetadataState) ??
      new CustomMetadataState();
    return state.setIn(
      [action.params.accountId, action.params.dataset],
      previous.merge({
        error: action.error,
        countsLoaded: false,
        countsLoading: false,
      }),
    );
  }
  if (fetchCurrentStateCountsRequested.match(action)) {
    const previous: CustomMetadataState =
      (state.getIn([action.params.accountId, action.params.dataset]) as CustomMetadataState) ??
      new CustomMetadataState();
    return state.setIn(
      [action.params.accountId, action.params.dataset],
      previous.merge({
        error: null,
        countsLoaded: false,
        countsLoading: true,
      }),
    );
  }
  if (fetchCurrentStateCountsSuccess.match(action)) {
    const previous: CustomMetadataState =
      (state.getIn([action.params.accountId, action.params.dataset]) as CustomMetadataState) ??
      new CustomMetadataState();
    let rowsPerDay: Map<string, ReportMetadataRowsPerDay> = Map();
    for (const [key, value] of Object.entries(action.response)) {
      rowsPerDay = rowsPerDay.set(
        key,
        new ReportMetadataRowsPerDay({
          time: DateTime.fromISO(key),
          count: value,
        }),
      );
    }

    return state.setIn(
      [action.params.accountId, action.params.dataset],
      previous.merge({
        error: null,
        countsLoaded: true,
        countsLoading: false,
        countsReceivedAt: Date.now(),
        rowsPerDay,
      }),
    );
  }

  // Old ETL metadata
  if (fetchCustomReportMetadataFailure.match(action)) {
    return state.setIn(
      [action.params.accountId, action.params.dataset],
      new CustomMetadataState({
        error: action.error,
        loaded: false,
        loading: false,
        rowsPerDay: Map(),
        generatedAt: null,
        reportingPeriodEnd: null,
        reportingPeriodStart: null,
      }),
    );
  }
  if (fetchCustomReportMetadataRequested.match(action)) {
    return state.setIn(
      [action.params.accountId, action.params.dataset],
      new CustomMetadataState({
        error: null,
        loaded: false,
        loading: true,
        rowsPerDay: Map(),
        generatedAt: null,
        reportingPeriodEnd: null,
        reportingPeriodStart: null,
      }),
    );
  }
  if (fetchCustomReportMetadataSuccess.match(action)) {
    let columns: OrderedMap<string, ReportMetadataColumnDetail> = OrderedMap();

    if (action.response.data.columns) {
      columns = OrderedMap(
        action.response.data.columns.map(value => {
          return [value.column, value];
        }),
      );
    }

    let rowsPerDay: Map<string, ReportMetadataRowsPerDay> = Map();

    if (action.response.data.rowsPerDay) {
      rowsPerDay = Map(
        Object.keys(action.response.data.rowsPerDay).reduce((acc: Array<[string, ReportMetadataRowsPerDay]>, day) => {
          /**
           * Setting zone to 'utc' here is necessary because the datestamps we receive from python are timezone-less.
           * eg: 2022-02-17T08:00:00
           * We're essentially hinting to the ISO interpretation that the time indicated is intended to be UTC time,
           * only then are we taking that correctly interpreted UTC DateTime and applying the account timezone to it.
           */
          const time = DateTime.fromISO(day, { zone: 'utc' }).toLocal();
          if (!time.isValid) {
            return acc;
          }
          const count = action.response.data.rowsPerDay[day];
          acc.push([day, new ReportMetadataRowsPerDay({ time, count })]);
          return acc;
        }, []),
      );
    }

    return state.setIn(
      [action.params.accountId, action.params.dataset],
      new CustomMetadataState({
        error: null,
        loaded: true,
        loading: false,
        columns,
        rowsPerDay,
        generatedAt: DateTime.fromISO(action.response.data.generatedAt, { zone: 'utc' }).toLocal(),
        receivedAt: Date.now(),
        reportingPeriodEnd: DateTime.fromISO(action.response.data.reportingPeriod.end, { zone: 'utc' }).toLocal(),
        reportingPeriodStart: DateTime.fromISO(action.response.data.reportingPeriod.start, { zone: 'utc' }).toLocal(),
      }),
    );
  }

  return state;
};
