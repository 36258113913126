import type { FormHTMLAttributes } from 'react';

import validators from './validators';

import Formsy, { addValidationRule } from 'formsy-react';
import type { IModel, IResetModel, IUpdateInputsWithError } from 'formsy-react/dist/interfaces';

// i really love having to reexport this crap ugh
declare type FormHTMLAttributesCleaned = Omit<FormHTMLAttributes<HTMLFormElement>, 'onChange' | 'onSubmit'>;

export interface FormsyProps extends FormHTMLAttributesCleaned {
  disabled: boolean;
  getErrorMessage: any;
  getErrorMessages: any;
  getValue: any;
  hasValue: any;
  isFormDisabled: any;
  isFormSubmitted: any;
  isPristine: any;
  isRequired: any;
  isValid: any;
  isValidValue: any;
  mapping: null | ((model: IModel) => IModel);
  onChange: (model: IModel, isChanged: boolean) => void;
  onError: any;
  onInvalid: () => void;
  onInvalidSubmit: any;
  onReset?: () => void;
  onSubmit?: (model: IModel, resetModel: IResetModel, updateInputsWithError: IUpdateInputsWithError) => void;
  onValid: () => void;
  onValidSubmit?: (model: IModel, resetModel: IResetModel, updateInputsWithError: IUpdateInputsWithError) => void;
  preventExternalInvalidation?: boolean;
  resetValue: any;
  setValidations: any;
  setValue: any;
  showError: any;
  showRequired: any;
  validationErrors?: null | object;
}

validators.map((validator, name) => {
  addValidationRule(name, validator);
});

// @deprecated please migrate to hook-form from shared/form
export default Formsy;
