import {
  DELETE_FORECAST_CUSTOM_UNIT,
  FETCH_FORECAST_CUSTOM_UNIT,
  UPDATE_FORECAST_CUSTOM_UNIT,
} from 'store/action-types';
import ForecastCustomUnit from './model';
import ForecastCustomUnitState from './state';

export default function (state = new ForecastCustomUnitState(), action) {
  switch (action.type) {
    case FETCH_FORECAST_CUSTOM_UNIT.FAILURE:
    case UPDATE_FORECAST_CUSTOM_UNIT.FAILURE:
    case DELETE_FORECAST_CUSTOM_UNIT.FAILURE:
      return state.merge({
        loaded: false,
        loading: false,
        error: action.payload,
      });
    case FETCH_FORECAST_CUSTOM_UNIT.REQUEST:
    case UPDATE_FORECAST_CUSTOM_UNIT.REQUEST:
      return state.merge({
        loaded: false,
        loading: true,
        error: null,
      });
    case FETCH_FORECAST_CUSTOM_UNIT.SUCCESS:
    case UPDATE_FORECAST_CUSTOM_UNIT.SUCCESS:
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        receivedAt: Date.now(),
        items: state.items.merge(
          action.payload.map(forecastCustomUnit => [forecastCustomUnit.id, new ForecastCustomUnit(forecastCustomUnit)]),
        ),
      });
    case DELETE_FORECAST_CUSTOM_UNIT.SUCCESS:
      return state.merge({
        items: state.items.delete(action.payload),
      });
    default:
      return state;
  }
}
