import PropTypes from 'prop-types';
import { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { CSSTransition, Transition, TransitionGroup } from 'react-transition-group';
import { List } from 'react-virtualized';

import { clearActiveSection, setActiveSection } from 'data/notifications/actions';
import { activeSectionSelector } from 'data/notifications/selectors';
import { CONTAINER_CLASSNAME } from 'notifications/components/NotifyItem';

import { bindActionCreators } from '@reduxjs/toolkit';
import classnames from 'classnames';

import 'notifications/styles/NotifySection.scss';

//Hard element heights used for animations
export const NOTIFICATION_HEIGHT = 65;
export const NOTIFICATION_HEADER_HEIGHT = 70;

export class NotifySection extends Component {
  listRef = createRef();

  state = {
    collapse: null,
  };

  static propTypes = {
    children: PropTypes.arrayOf(PropTypes.node),
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    label: PropTypes.string,
    section: PropTypes.string.isRequired,
    activeSection: PropTypes.string,
    setActiveSection: PropTypes.func.isRequired,
    clearActiveSection: PropTypes.func.isRequired,
    entries: PropTypes.number.isRequired,
    containerHeight: PropTypes.number,
  };

  static defaultProps = {
    children: [],
    containerHeight: NOTIFICATION_HEADER_HEIGHT + NOTIFICATION_HEIGHT,
  };

  componentDidUpdate() {
    this.updateSectionHeight();
  }

  get isActiveSection() {
    return this.props.activeSection && this.props.activeSection === this.props.section;
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (this.isActiveSection && !nextProps.activeSection) {
      this.setState({ collapse: this.props.children.length });
    }
  }

  numChildrenToDisplay(entries) {
    if (this.isActiveSection) {
      return this.props.children.length;
    }
    if (entries) {
      return Math.min(entries, Math.floor(this.props.containerHeight / NOTIFICATION_HEIGHT));
    }
    return Math.min(this.props.children.length, this.props.entries);
  }

  hasMoreToSee() {
    return this.numChildrenToDisplay() < this.props.children.length;
  }

  updateSection = () => {
    if (this.props.activeSection) {
      return this.props.clearActiveSection();
    }

    return this.props.setActiveSection(this.props.section);
  };

  stopClickFocus = e => {
    e.preventDefault();
  };

  updateSectionHeight() {
    let height;
    if (this.props.activeSection) {
      if (this.isActiveSection) {
        height = this.props.containerHeight;
      } else {
        height = 0;
      }
    } else {
      if (!this.props.children.length) {
        height = 0;
      } else {
        height = NOTIFICATION_HEADER_HEIGHT + NOTIFICATION_HEIGHT * this.numChildrenToDisplay();
      }
    }

    if (this.listRef?.current) {
      this.listRef.current.style.height = `${height}px`;
    }
  }

  getScrollerHeight() {
    let height = 0;
    if (this.props.activeSection && this.isActiveSection) {
      height = this.props.containerHeight - NOTIFICATION_HEADER_HEIGHT;
    } else {
      height = NOTIFICATION_HEIGHT * this.numChildrenToDisplay(this.state.collapse);
    }
    return height < 0 ? 0 : height;
  }

  renderRow = ({ index, key, style }) => {
    const child = this.props.children[index];
    if (child) {
      return (
        <div key={key} style={style}>
          {child}
        </div>
      );
    }
    return null;
  };

  renderIcon = () => {
    const { icon } = this.props;

    if (!icon) {
      return null;
    }

    if (typeof icon === 'string') {
      return <i className={`fa ${this.props.icon}`} />;
    }
    // allow component passthrough such as a <SpriteIcon icon="megaphone" className="mr-2" />
    return icon;
  };

  renderSection(state) {
    const { label } = this.props;
    const sectionClasses = classnames('notify-list', {
      open: this.isActiveSection,
      closed: !this.isActiveSection && state === 'entered',
      static: state !== 'entering' && state !== 'exiting',
    });
    const displayButton = this.isActiveSection || this.hasMoreToSee();
    const buttonClasses = classnames('btn', 'toggle-section');
    const height = this.getScrollerHeight();

    return (
      <div ref={this.listRef} className={sectionClasses}>
        <h3 className="need-action">
          {this.renderIcon()}
          {label}
          <TransitionGroup component={null}>
            {displayButton && (
              <CSSTransition classNames="toggle-section" timeout={{ enter: 250, exit: 250 }}>
                <button
                  type="button"
                  onClick={this.updateSection}
                  onMouseDown={this.stopClickFocus}
                  className={buttonClasses}
                >
                  {this.isActiveSection ? 'Back' : 'See More'}
                </button>
              </CSSTransition>
            )}
          </TransitionGroup>
        </h3>
        <List
          tabIndex={null}
          className={CONTAINER_CLASSNAME}
          overscanRowCount={10}
          estimatedRowSize={height}
          rowCount={this.numChildrenToDisplay(this.state.collapse)}
          rowHeight={NOTIFICATION_HEIGHT}
          rowRenderer={this.renderRow}
          height={height}
          width={500}
        >
          {this.props.children}
        </List>
      </div>
    );
  }

  onExited = () => {
    this.setState({ collapse: null });
  };

  render() {
    return (
      <Transition onExited={this.onExited} in={!!this.props.activeSection} timeout={500}>
        {state => this.renderSection(state)}
      </Transition>
    );
  }
}

export default connect(
  state => ({
    activeSection: activeSectionSelector(state),
  }),
  dispatch =>
    bindActionCreators(
      {
        setActiveSection,
        clearActiveSection,
      },
      dispatch,
    ),
)(NotifySection);
