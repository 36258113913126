import { createSelector } from '@reduxjs/toolkit';
import { List } from 'immutable';
import moment from 'moment-timezone';

import type { EntityMap } from 'data/types';
import type { WiwState } from 'store';
import type { ReduxMessage } from 'workchat/v2/store/messages/messagesReducer';

export const getStateWorkchat = (state: WiwState) => state.workchatv2;

export const getMessagesState = createSelector([getStateWorkchat], workchatState => workchatState.messages);
export const getMessagesLoading = createSelector([getMessagesState], messagesState => messagesState.loading);
export const getMessages = createSelector(
  [getMessagesState],
  messagesState => messagesState.items as EntityMap<ReduxMessage>,
);
export const getMessageById = createSelector([getMessages, (_state, id) => id], (state, id) => state.get(id));
export const getMessagesByConversationId = createSelector([getMessages], messageEntityMap =>
  messageEntityMap.groupBy(message => message.conversation.sid),
);

export const getMessagesForConversation = createSelector(
  [getMessagesByConversationId, (_state, conversationId) => conversationId],
  (messagesByConversationId, conversationId) => {
    const conversationMessages = messagesByConversationId.get(conversationId);
    const messagesSorted = conversationMessages?.sortBy(message => moment(message.dateCreated)) as List<ReduxMessage>;

    if (!messagesSorted || !messagesSorted.size) {
      return List<ReduxMessage>();
    }

    return messagesSorted;
  },
);

export const getMessagePaginatorByConversationId = createSelector(
  [getMessagesState],
  messagesState => messagesState.messagePaginatorByConverationId,
);

export const getMessagePaginatorForConversationId = createSelector(
  [getMessagePaginatorByConversationId, (_state, conversationId) => conversationId],
  (messagePaginatorByConversationId, conversationId) => messagePaginatorByConversationId.get(conversationId),
);
