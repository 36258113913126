import BaseModel from 'data/BaseModel';

export interface FreeTrialFields {
  status: string;
  type: string;
  expiresDate: string | null;
  renewalDate: string | null;
  featureSet: object;
  deniedReason: string | null;
}

export class FreeTrial extends BaseModel<FreeTrialFields>({
  status: 'unavailable',
  type: 'undefined',
  expiresDate: null,
  renewalDate: null,
  featureSet: {},
  deniedReason: null,
}) {
  static STATUS = {
    AVAILABLE: 'available',
    UNAVAILABLE: 'unavailable',
    ACTIVE: 'active',
  } as const;

  isActive() {
    return this.status === FreeTrial.STATUS.ACTIVE;
  }

  isAvailable() {
    return this.status === FreeTrial.STATUS.AVAILABLE;
  }

  isUnavailable() {
    return this.status === FreeTrial.STATUS.UNAVAILABLE;
  }
}

export default FreeTrial;
