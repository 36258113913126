import { createShiftBreak } from 'data/shiftBreak/actions/createShiftBreak';
import { updateShiftBreak } from 'data/shiftBreak/actions/updateShiftBreak';
import type { ShiftBreakFields } from 'data/shiftBreak/models/shiftBreak';

export const persistShiftBreak = (shiftBreak: ShiftBreakFields, userId?: string | number, token?: string) => {
  if (shiftBreak.id) {
    return updateShiftBreak({ shiftBreak, userId, token });
  }
  return createShiftBreak({ shiftBreak, userId, token });
};
