import { openDialog } from 'dialogs';
import { setCurrentDateAndView } from 'scheduler/actions/setCurrentDateAndView';
import { SCHEDULER_CURRENT_RANGE, SCHEDULER_CURRENT_VIEW } from 'scheduler/util/cookies';
import { getNearestViewableDate } from 'scheduler/util/daterangestatus';
import { getPersistedFilter } from 'scheduler/util/persistFilter';
import {
  COVERAGE_VIEW,
  DAY_RANGE,
  EMPLOYEE_VIEW,
  JOB_SITE_VIEW,
  MONTH_RANGE,
  POSITION_VIEW,
  POSITION_VIEW_V2,
  TWO_WEEK_RANGE,
  ViewRange,
  WEEK_VIEW,
} from 'scheduler/util/view';
import { getAuthAccount } from 'shared/auth/selectors';
import Features from 'shared/features';
import { EXTENDED_VIEW_UPGRADE_DIALOG } from 'shared/upsell/actions/dialogs';

export function navigateToRange(payload) {
  return (dispatch, getState) => {
    const state = getState();
    const currentAccount = getAuthAccount(state);
    const currentDate = state.scheduler.get('currentDate');

    const currentView = state.scheduler.get('view');

    if (
      payload === MONTH_RANGE &&
      (!currentAccount.hasFeature(Features.MONTH_VIEW) || currentAccount.isReverseTrial())
    ) {
      return dispatch(openDialog(EXTENDED_VIEW_UPGRADE_DIALOG));
    }

    if (
      (currentView === POSITION_VIEW_V2 || currentView === JOB_SITE_VIEW) &&
      [MONTH_RANGE, DAY_RANGE].includes(payload)
    ) {
      return;
    }

    if (
      payload === TWO_WEEK_RANGE &&
      (!currentAccount.hasFeature(Features.TWO_WEEK_RANGE) || currentAccount.isReverseTrial())
    ) {
      return dispatch(openDialog(EXTENDED_VIEW_UPGRADE_DIALOG));
    }

    if (currentView === COVERAGE_VIEW && payload === DAY_RANGE) {
      return;
    }

    return dispatch(setCurrentDateAndView(getNearestViewableDate(currentAccount, currentDate), undefined, payload));
  };
}

export function navigateToView(payload) {
  return (dispatch, getState) => {
    const state = getState();
    const currentRange = state.scheduler.get('range');

    if ([DAY_RANGE, MONTH_RANGE].includes(currentRange)) {
      return null;
    }

    const currentAccount = getAuthAccount(state);
    const currentDate = state.scheduler.get('currentDate');

    return dispatch(setCurrentDateAndView(getNearestViewableDate(currentAccount, currentDate), payload));
  };
}

export const getKey = (locationId, userId) => `${SCHEDULER_CURRENT_VIEW}-${userId}-${locationId}`;

export const getInitialView = () => {
  const filter = getPersistedFilter(SCHEDULER_CURRENT_VIEW, EMPLOYEE_VIEW);

  switch (filter) {
    case WEEK_VIEW:
      return EMPLOYEE_VIEW;
    case POSITION_VIEW:
      return POSITION_VIEW_V2;
    default:
      return filter;
  }
};
export const getInitialRange = () => getPersistedFilter(SCHEDULER_CURRENT_RANGE, ViewRange.WEEK);
