import { Record } from 'immutable';

export interface LoginFields {
  id: number | null;
  first_name: string | null;
  last_name: string | null;
  email: string | null;
  updated_at: string | null;
  created_at: string | null;
}

class Login extends Record<LoginFields>({
  id: null,
  first_name: null,
  last_name: null,
  email: null,
  updated_at: null,
  created_at: null,
}) {}

export default Login;
