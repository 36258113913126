import EntityState from 'data/EntityState';
import { FETCH_BLACKLIST, REMOVE_FROM_BLACKLIST, UPDATE_BLACKLIST } from 'store/action-types';

export default (state, action) => {
  if (state === undefined) {
    state = new EntityState();
  }

  switch (action.type) {
    case UPDATE_BLACKLIST:
      return state.merge({
        items: state.items.withMutations(map => {
          action.entries.map(entry => map.set(entry, entry));
        }),
      });
    case REMOVE_FROM_BLACKLIST:
      return state.merge({
        items: state.items.delete(action.entry),
      });
    case FETCH_BLACKLIST.REQUEST:
      return state.merge({
        loading: true,
        receivedAt: 0,
        loaded: false,
      });
    case FETCH_BLACKLIST.SUCCESS:
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        receivedAt: Date.now(),
        items: state.items.merge(action.items.map(item => [item.toLowerCase(), item.toLowerCase()])),
      });
    case FETCH_BLACKLIST.FAILURE:
      return state.merge({
        loaded: false,
        loading: false,
        error: action.payload,
      });
    default:
      return state;
  }
};
