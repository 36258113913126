import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import SpriteIcon from 'shared/ui/SpriteIcon';

class CloseToast extends PureComponent {
  static propTypes = {
    closeToast: PropTypes.func,
  };

  static defaultProps = {
    closeToast: () => null,
  };

  render() {
    return (
      <button className="action-close-toast" onClick={this.props.closeToast} aria-label="Dismiss">
        <SpriteIcon icon="close" className="close-toast lg" />
      </button>
    );
  }
}

export default CloseToast;
