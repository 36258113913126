export const WORKCHAT_START = 'WORKCHAT_START';
export const WORKCHAT_CONNECTED = 'WORKCHAT_CONNECTED';
export const WORKCHAT_DISCONNECTED = 'WORKCHAT_DISCONNECTED';
export const WORKCHAT_TOGGLE = 'WORKCHAT_TOGGLE';
export const WORKCHAT_MARK_UNREAD = 'WORKCHAT_MARK_UNREAD';
export const WORKCHAT_ERROR = 'WORKCHAT_ERROR';
export const WORKCHAT_RETRY_ERROR = 'WORKCHAT_RETRY_ERROR';
export const WORKCHAT_CONFIRM_MODAL = 'WORKCHAT_CONFIRM_MODAL';

export const WORKCHAT_SCREEN_PUSH = 'WORKCHAT_SCREEN_PUSH';
export const WORKCHAT_SCREEN_POP = 'WORKCHAT_SCREEN_POP';
// Unwinding pops screens off the stack until the desired screen is reached or there is only one screen left.
export const WORKCHAT_SCREEN_UNWIND = 'WORKCHAT_SCREEN_UNWIND';

export const WORKCHAT_LOAD_CONVERSATIONS = 'WORKCHAT_LOAD_CONVERSATIONS';
export const WORKCHAT_LOADING_CONVERSATIONS = 'WORKCHAT_LOADING_CONVERSATIONS';
export const WORKCHAT_UPDATE_CONVERSATION = 'WORKCHAT_UPDATE_CONVERSATION';
export const WORKCHAT_LEFT_CONVERSATION = 'WORKCHAT_LEFT_CONVERSATION';
export const WORKCHAT_SET_CONVERSATION = 'WORKCHAT_SET_CONVERSATION';
export const WORKCHAT_SET_PENDING_CONVERSATION = 'WORKCHAT_SET_PENDING_CONVERSATION';

export const WORKCHAT_SET_FILTERS = 'WORKCHAT_SET_FILTERS';

export const WORKCHAT_SEND_MESSAGE = 'WORKCHAT_SEND_MESSAGE';
export const WORKCHAT_SENDING_MESSAGE = 'WORKCHAT_SENDING_MESSAGE';
export const WORKCHAT_SENT_MESSAGE = 'WORKCHAT_SENT_MESSAGE';
export const WORKCHAT_FAILED_MESSAGE = 'WORKCHAT_FAILED_MESSAGE';
export const WORKCHAT_RECEIVE_MESSAGE = 'WORKCHAT_RECEIVE_MESSAGE';
export const WORKCHAT_MESSAGE_RECEIPTS = 'WORKCHAT_MESSAGE_RECEIPTS';

export const WORKCHAT_LOAD_USERS = 'WORKCHAT_LOAD_USERS';
export const WORKCHAT_LOADING_USERS = 'WORKCHAT_LOADING_USERS';
