import EntityState from 'data/EntityState';
import {
  DELETE_SCHEDULED_TASK_LIST,
  FETCH_SCHEDULED_TASK_LISTS,
  REPLACE_SHIFT_TASK_LISTS,
  UPDATE_SCHEDULED_TASK,
  UPDATE_SCHEDULED_TASK_LISTS,
} from 'store/action-types';
import { ScheduledTaskListModel } from './models';

import { Map } from 'immutable';

export default function (state = new EntityState(), action) {
  switch (action.type) {
    case FETCH_SCHEDULED_TASK_LISTS.FAILURE:
      return state.merge({
        loaded: false,
        loading: false,
        error: action.payload,
      });
    case FETCH_SCHEDULED_TASK_LISTS.REQUEST:
      return state.merge({
        loaded: false,
        loading: true,
        error: null,
      });
    case FETCH_SCHEDULED_TASK_LISTS.SUCCESS:
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        items: new Map(
          action.payload.map(scheduledTaskList => {
            return [scheduledTaskList.id, new ScheduledTaskListModel(scheduledTaskList)];
          }),
        ),
      });
    case UPDATE_SCHEDULED_TASK:
      return state.merge({
        items: state.items.setIn([action.scheduledTaskListId, 'scheduledTasks', action.taskId], action.item),
      });
    case UPDATE_SCHEDULED_TASK_LISTS:
      return state.merge({
        items: state.items.delete(null).merge(
          action.items.map(scheduledTaskList => {
            return [scheduledTaskList.id, new ScheduledTaskListModel(scheduledTaskList)];
          }),
        ),
      });
    case REPLACE_SHIFT_TASK_LISTS:
      return state.merge({
        items: state.items
          .filter(stl => stl.shiftId !== action.shiftId)
          .merge(
            action.items.map(scheduledTaskList => {
              return [scheduledTaskList.id, new ScheduledTaskListModel(scheduledTaskList)];
            }),
          ),
      });
    case DELETE_SCHEDULED_TASK_LIST.SUCCESS:
      return state.merge({
        items: state.items.delete(action.payload),
      });
    case DELETE_SCHEDULED_TASK_LIST.FAILURE:
      return state.merge({
        error: action.payload,
      });
    default:
      return state;
  }
}
