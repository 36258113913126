import Mercury, { GROWTH_ONBOARDING } from 'shared/util/mercury';

import { pick } from 'lodash';

const generateLogRocketCustomEventString = action => {
  const logRocket = ['growth', 'onboarding'];
  if (action != null) {
    logRocket.push(action);
  }

  return logRocket.join('::');
};

/**
 * growth::onboarding
 * https://catalog.wiwdata.com/#/entry/entries-2240a5f7-053f-4486-a4df-85ef83efdb4f/attributes-8e8bcf57-0d9b-404d-8135-290ae71821b2
 */
export const track = attributes => {
  const { annotations, currentStep, previousStep, ...otherAttributes } = attributes;
  const fields = {
    action: null,
    annotations: annotations ? JSON.stringify(annotations) : null,
    currentStep: currentStep || null,
    experimentId: null,
    previousStep: previousStep && previousStep !== currentStep ? previousStep : null, // hack
    target: null,
    variation: null,
    version: 'Y20Q2W16',
    ...otherAttributes,
  };

  Mercury.track(
    GROWTH_ONBOARDING,
    pick(fields, [
      'action',
      'annotations',
      'currentStep',
      'experimentId',
      'previousStep',
      'target',
      'variation',
      'version',
    ]),
    {},
    true,
    generateLogRocketCustomEventString(fields.action),
  );
};
