// Root reducer for all reducers across the application
import attendance from 'attendance/modules/tracker/reducer';
import billing from 'billing/reducers';
import dashboard from 'dashboard/reducer';
import auth from 'data/auth/reducer';
import data from 'data/reducer';
import dialogs from 'dialogs/reducer';
import integrations from 'integrations/reducer';
import myschedule from 'myschedule/reducer';
import notices from 'notices/reducer';
import onboarding from 'onboarding/reducer';
import reporting from 'reporting/reducer';
import requests from 'requests/reducer';
import scheduler from 'scheduler/reducer';
import timesheets from 'timesheets/reducer';
import workchat from 'workchat/reducer';
import workchatv2 from 'workchat/v2/store/reducer';

import { combineReducers } from '@reduxjs/toolkit';
import { reducer } from 'ld-redux';

export default combineReducers({
  auth,
  attendance,
  billing,
  dashboard,
  data,
  dialogs,
  integrations,
  myschedule,
  notices,
  onboarding,
  reporting,
  requests,
  scheduler,
  LD: reducer,
  timesheets,
  workchat,
  workchatv2,
});
