import PropTypes from 'prop-types';
import { Component, Fragment } from 'react';

import Checkbox from 'shared/ui/inputs/Checkbox';

import classnames from 'classnames';
import { propTypes, withFormsy } from 'formsy-react';
import { UncontrolledTooltip } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';

export class ValidatedCheckbox extends Component {
  targetId = `w${uuidv4()}`;

  static propTypes = {
    ...propTypes,
    label: PropTypes.any,
    labelSmall: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.bool,
    name: PropTypes.string.isRequired,
    width: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.oneOf(['auto'])]),
    classNames: PropTypes.string,
    wrapperClasses: PropTypes.string,
    newStyle: PropTypes.bool,
    onChange: PropTypes.func,
    validatePristine: PropTypes.bool,
    inline: PropTypes.bool,
    tooltip: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.node]),
    tooltipPlacement: PropTypes.string,
    indeterminate: PropTypes.bool,
    labelAddon: PropTypes.any,
  };

  static defaultProps = {
    value: false,
    width: false,
    disabled: false,
    newStyle: false,
    validatePristine: false,
    indeterminate: false,
    inline: false,
    onChange: () => {},
    tooltip: false,
    tooltipPlacement: 'bottom',
  };

  componentDidMount() {
    this.props.setValue(this.props.value);
  }

  modifyValue = e => {
    const value = e.target.checked;
    this.props.onChange(this.props.name, value);
    return this.props.setValue(value);
  };

  render() {
    const invalid =
      (this.props.showError || !this.props.isValid) && (this.props.validatePristine || !this.props.isPristine);

    const className = classnames(this.props.classNames, {
      'has-danger': invalid,
    });

    const wrapperClasses = classnames(this.props.wrapperClasses, {
      col: this.props.width !== null && !this.props.width,
      [`col-${this.props.width}`]: this.props.width,
      disabled: this.props.disabled,
      newStyle: this.props.newStyle,
      'custom-control-inline': this.props.inline,
    });

    return (
      <Fragment>
        <div id={this.targetId} className={wrapperClasses}>
          <Checkbox
            label={this.props.label}
            labelSmall={this.props.labelSmall}
            className={className}
            name={this.props.name}
            checked={this.props.value === true}
            onChange={this.modifyValue}
            disabled={this.props.isFormDisabled || this.props.disabled}
            newStyle={this.props.newStyle}
            width={this.props.width}
            indeterminate={this.props.indeterminate}
            labelAddon={this.props.labelAddon}
          />
        </div>
        {this.props.tooltip ? (
          <UncontrolledTooltip target={this.targetId} placement={this.props.tooltipPlacement}>
            {this.props.tooltip}
          </UncontrolledTooltip>
        ) : null}
      </Fragment>
    );
  }
}

/**
 * @deprecated please migrate to hook-form from shared/form/inputs
 */
export default withFormsy(ValidatedCheckbox);
