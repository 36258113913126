import EntityBase from 'data/EntityBase';

import moment, { type Moment } from 'moment-timezone';

type MyScheduleValues = {
  currentDate: Moment;
  boundaries: { start?: Moment; end?: Moment };
};

export default class MyScheduleState extends EntityBase<undefined, MyScheduleValues>({
  currentDate: moment().startOf('day'),
  boundaries: { start: undefined, end: undefined },
}) {}
