import EntityState from 'data/EntityState';
import TagList from 'data/tag/TagList';
import { fetchShiftTemplateTags } from 'data/tag/actions/shiftTemplates/fetchShiftTemplateTags';
import { updateShiftTemplateTag } from 'data/tag/actions/shiftTemplates/updateShiftTemplateTag';
import generateThunkReducers from 'data/util/generateThunkReducers';
import { optimisticallyUpdateEntity } from 'data/util/optimisticUpdate';
import { mergeEntityItems } from 'data/util/sliceHelpers';

import { createSlice } from '@reduxjs/toolkit';

const initialState = new EntityState();

export const shiftTemplateTagSlice = createSlice({
  name: 'tags/shiftTemplates',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchShiftTemplateTags.fulfilled, (state, action) => {
      return mergeEntityItems(state, action.payload.data, TagList, 'id', Number.parseInt);
    });
    optimisticallyUpdateEntity(builder, updateShiftTemplateTag, TagList, { payloadPath: 'data' });
    generateThunkReducers(builder, 'tags/shiftTemplates');
  },
});

export default shiftTemplateTagSlice.reducer;
