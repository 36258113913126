import { getLocations } from 'data/location/selectors';
import { getStatePositions } from 'data/position/selectors';
import { getAccountId, getAuthUser } from 'shared/auth/selectors';
import { getStateUsers } from './getStateUsers';

import { createSelector } from '@reduxjs/toolkit';
import { intersection } from 'lodash';

export { getStateUsers } from './getStateUsers';

export const getUsersByAccount = createSelector(getStateUsers, users => users.groupBy(user => user.account_id));

const getViewableUsers = createSelector([getStateUsers, getAuthUser], (users, currentUser) => {
  if (currentUser.canManage()) {
    return users;
  }

  return users.filter(user => !!intersection(user.locations, currentUser.locations).length);
});

const filterDeletedUsers = users =>
  users.filter(user => {
    return !user.is_deleted;
  });

export const getNonDeletedUsers = createSelector(getViewableUsers, filterDeletedUsers);

export const getNonPendingUsers = createSelector(getStateUsers, users => users.filter(user => user.is_active));

export const getNonPendingNonDeletedUsers = createSelector(getStateUsers, users =>
  users.filter(user => user.is_active && !user.is_deleted),
);

export const getUsers = createSelector([getStateUsers, getAccountId], (users, accountId) => {
  return users.filter(user => user.account_id === accountId);
});

export const getUserLocationMap = createSelector([getNonDeletedUsers, getLocations], (users, locations) =>
  locations.map(location => {
    return users.filter(user => user.locations.includes(location.id)).toArray();
  }),
);

export const getUserPositionMap = createSelector([getNonDeletedUsers, getStatePositions], (users, positions) =>
  positions.map(position => {
    return users.filter(user => user.positions.includes(position.id)).toArray();
  }),
);

export { getActiveUsers, getVisibleActiveUsers, usersSelector } from './activeUsers';
export { getActiveManagers, getActiveSupervisors } from './activeUsersByRole';
export { getDeletedUsers } from './getDeletedUsers';
export { getUserMergeOptions } from './userMergeOptions';
