import Fuse from 'fuse.js';
import { List, type Map, Set } from 'immutable';
import { useEffect, useRef, useState } from 'react';

import type Account from 'data/account/model';
import type { EntityMap } from 'data/types';
import type User from 'data/user/model';
import FontIcon from 'shared/ui/FontIcon';
import UserPickerFilters from 'workchat/v2/components/UserPickerFilters';
import type { ReduxParticipant } from 'workchat/v2/store/participants/participantsReducer';

import 'workchat/styles/UserPicker.scss';

interface UserPickerProps {
  user: User;
  account: Account;
  users: EntityMap<User>;
  participants?: Map<number, ReduxParticipant>;
  prefill?: any;
  enableFilters?: boolean;
  setUsers?: (users: List<number>) => void;
  updateTrackedFilters?: (locations: Set<number>, positions: Set<number>) => void;
}

export function UserPickerV2({
  user,
  account,
  users,
  participants,
  prefill = null,
  enableFilters = false,
  setUsers,
  updateTrackedFilters,
}: UserPickerProps) {
  const [term, setTerm] = useState('');
  const [added, setAdded] = useState(List<number>());
  const [matches, setMatches] = useState(List<any>());
  const [expanded, setExpanded] = useState(true);
  const [selected, setSelected] = useState(-1);
  const [showFilters, setShowFilters] = useState(false);
  const [filterLocations, setFilterLocations] = useState(Set<number>());
  const [filterPositions, setFilterPositions] = useState(Set<number>());

  const fuse = useRef<Fuse<any>>(new Fuse([]));
  const inputRef = useRef<HTMLInputElement>(null);
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setUsers?.(added);
  }, [added, setUsers]);

  useEffect(() => {
    if (prefill) {
      setAdded(prefill.userIds);
    }
    updateUsers();
  }, [prefill, users]);

  useEffect(() => {
    updateFilter();

    if (updateTrackedFilters) {
      updateTrackedFilters(filterLocations, filterPositions);
    }
  }, [added, filterLocations, filterPositions, term]);

  const updateUsers = () => {
    fuse.current = new Fuse(users.toArray(), {
      keys: ['first_name', 'last_name'],
      shouldSort: true,
      threshold: 0.25,
    });
    updateFilter();
  };

  const updateFilter = () => {
    const selfId = user.id;

    if (term) {
      const results = fuse.current.search<User>(term);

      const matches = List<User>(
        results
          .map(({ item }: { item: User }) => {
            const userEntity: User | undefined = users.get(+item.id);
            if (
              !userEntity ||
              userEntity.id === selfId ||
              userEntity.is_deleted ||
              added.includes(userEntity.id) ||
              participants?.get(userEntity.id)
            ) {
              return null;
            }
            if (
              (filterLocations.size || filterPositions.size) &&
              !filterLocations.intersect(userEntity?.locations).size &&
              !filterPositions.intersect(userEntity?.positions).size
            ) {
              return null;
            }
            return userEntity;
          })
          .filter((match: User | null) => !!match),
      ).sortBy((userEntity: User) => userEntity.fullName.toLowerCase()) as List<User>;

      setMatches(matches);
    } else {
      const matches = users
        .filter(user => {
          if (user.id === selfId || user.is_deleted || added.includes(user.id) || participants?.has(user.id)) {
            return false;
          }
          if (
            (filterLocations.size || filterPositions.size) &&
            !filterLocations.intersect(user.locations).size &&
            !filterPositions.intersect(user.positions).size
          ) {
            return false;
          }
          return true;
        })
        .sortBy(user => user.fullName.toLowerCase())
        .toList();

      setMatches(matches);
    }
  };

  const onToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    setExpanded(!expanded);
  };

  const onKey = (e: React.KeyboardEvent) => {
    const key = e.which || e.keyCode;

    if (key === 37) {
      // left arrow
      if (document.activeElement === inputRef.current) {
        if (inputRef.current?.selectionStart !== 0 && inputRef.current?.selectionEnd !== 0) {
          return;
        }
        if (added.size) {
          divRef.current?.focus();
          setSelected(added.size - 1);
          return false;
        }
      } else if (selected > 0) {
        setSelected(selected - 1);
        return false;
      }
    } else if (key === 39) {
      // right arrow
      if (document.activeElement === inputRef.current) {
        return;
      }
      if (selected < added.size - 1) {
        setSelected(selected + 1);
        return false;
      }
      setSelected(-1);
      inputRef.current?.select();
      return false;
    } else if (key === 8 || key === 46) {
      // backspace or delete
      if (document.activeElement !== inputRef.current && selected >= 0 && selected < added.size) {
        const newAdded = added.splice(selected, 1).toList();
        if (newAdded.size === 0) {
          setAdded(newAdded);
          setSelected(-1);
          inputRef.current?.select();
        } else if (selected >= newAdded.size) {
          setAdded(newAdded);
          setSelected(newAdded.size - 1);
        } else {
          setAdded(newAdded);
        }
        return false;
      }
    } else if (key === 9 || key === 10 || key === 13) {
      // tab or enter (linefeed or carriage return)
      if (matches.size === 1) {
        const newAdded = added.push(matches.get(0).id);
        setAdded(newAdded);
        setTerm('');
      }
      return false;
    }
  };

  const onClickPicked = (_e: React.MouseEvent) => {
    setExpanded(true);
    setSelected(-1);
    inputRef.current?.focus();
  };

  const onClickName = (idx: number, e: React.MouseEvent) => {
    setSelected(idx);
    setExpanded(true);
    setTimeout(() => divRef.current?.focus(), 10);

    e.stopPropagation();
  };

  const onClickInput = () => {
    setExpanded(true);
  };

  const termChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTerm(e.target.value);
  };

  const toggleFilters = (e: React.MouseEvent) => {
    e.preventDefault();
    setShowFilters(!showFilters);
  };

  const addUser = (user: any, e: React.MouseEvent) => {
    e.preventDefault();
    const newAdded = added.push(user.id);
    setAdded(newAdded);
    setTerm('');
  };

  const renderFilters = () => {
    if (!showFilters) {
      return null;
    }

    return (
      <UserPickerFilters
        onPositionFiltersChange={positions => setFilterPositions(positions)}
        onLocationFiltersChange={locations => setFilterLocations(locations)}
      />
    );
  };

  const shown = expanded ? added : added.slice(0, 2);
  const truncated = shown.size !== added.size;
  const numFilters = filterLocations.size + filterPositions.size;
  const userContents: JSX.Element[] = [];
  let letter: string | null = null;

  matches.forEach(user => {
    const initial = user.first_name.charAt(0).toUpperCase();
    if (initial !== letter) {
      letter = initial;
      userContents.push(
        <div key={`letter-${letter}`} className="letter-separator">
          {letter}
        </div>,
      );
    }

    const avatarImgSrc = user.avatar_url ? `${user.avatar_url}/32` : user.getAvatar();

    userContents.push(
      <button type="button" key={`user-${user.id}`} onClick={e => addUser(user, e)}>
        <img src={avatarImgSrc} alt="Avatar" />
        <span>{user.fullName}</span>
      </button>,
    );
  });

  let idx = 0;
  return (
    <div className="user-picker">
      <div className={`picked-box${truncated ? ' truncated' : ''}`} tabIndex={-1} onKeyDown={onKey} ref={divRef}>
        <div className="picked" onClick={onClickPicked}>
          <span className="to">To: </span>
          {added.size > 2 ? (
            <button type="button" onClick={onToggle} aria-label="Toggle Chann">
              {expanded ? (
                <FontIcon icon="chevron-up" srText="Show All Names" />
              ) : (
                [
                  added.size - shown.size,
                  ' more',
                  <FontIcon key="collapseything" icon="chevron-down" srText="Show Less Names" />,
                ]
              )}
            </button>
          ) : null}
          {shown
            .map(uId => {
              const userEntity = users.get(uId)!;
              const currentIndex = idx++;
              return (
                <span className="name" key={`added-${userEntity.id}`} onClick={e => onClickName(currentIndex, e)}>
                  {currentIndex > 0 ? ', ' : null}
                  <span className={selected === currentIndex ? 'selected' : ''}>{userEntity.fullName}</span>
                </span>
              );
            })
            .toArray()}
          {added.size ? ', ' : null}
          <input
            type="text"
            onChange={termChange}
            value={term}
            className={term ? 'has-text' : ''}
            onClick={onClickInput}
            ref={inputRef}
          />
        </div>
        {enableFilters ? (
          <button type="button" onClick={toggleFilters}>
            <FontIcon icon="preferences" srText="Channel Prefrences" className="filter" />
          </button>
        ) : null}
        {numFilters ? <span className="filter-count">{numFilters}</span> : null}
        {renderFilters()}
      </div>
      <div className="user-list">
        {matches.size || !term ? (
          userContents
        ) : (
          <div className="no-results">Sorry, it appears this person is not a part of {account.company}</div>
        )}
      </div>
    </div>
  );
}
