import { SalariedSettingsSchema, type SalariedSettingsType } from 'data/salariedSettings/model';
import { SalariedSettings } from 'data/salariedSettings/model';
import type { Moment } from 'moment-timezone';
import Platform from 'shared/api/platform';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { makeGoDataSchema } from 'shared/zod-schemas';
import { createWiwAsyncThunk } from 'store';

import { ZodError, z } from 'zod';

export function getDefaultSalariedSettings(date: Moment) {
  // Currently all salaried users are assumed to work Monday through Friday, 9:00 am to 5:00pm in the account time zone
  return {
    isClocksIn: false,
    typicalWorkDays: SalariedSettings.MTWHF,
    effectiveStartDate: date.toISOString(),
    typicalWorkStart: date.clone().set({ hour: 9, minute: 0, second: 0, millisecond: 0 }).toISOString(),
    typicalWorkEnd: date.clone().set({ hour: 17, minute: 0, second: 0, millisecond: 0 }).toISOString(),
  };
}

export const createSalariedSettings = createWiwAsyncThunk<{ settings: SalariedSettingsType }, Request>(
  'salariedSettings/create',
  async ({ userId, ...data }, { rejectWithValue }) => {
    try {
      const response: unknown = await Platform.url(`/users/${userId}/salaried/settings`).post(data);
      const parsed = CreateSalariedSettingsResponse.parse(response);
      return parsed.data;
    } catch (error) {
      if (error instanceof ZodError) {
        return rejectWithValue(error);
      }

      return rejectWithValue(parseAPIError(error));
    }
  },
);

type Request = {
  userId: string | number;
  isClocksIn: boolean;
  typicalWorkDays: number;
  typicalWorkStart: string;
  typicalWorkEnd: string;
  effectiveStartDate: string;
};

const CreateSalariedSettingsResponse = makeGoDataSchema(
  z.object({
    settings: SalariedSettingsSchema,
  }),
);
