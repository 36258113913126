import { FETCH_FORECAST_ITEM, UPDATE_FORECAST_ITEM } from 'store/action-types';
import ForecastItem from './model';
import ForecastState from './state';

export default function (state = new ForecastState(), action) {
  switch (action.type) {
    case FETCH_FORECAST_ITEM.FAILURE:
    case UPDATE_FORECAST_ITEM.FAILURE:
      return state.merge({
        loaded: false,
        loading: false,
        error: action.payload,
      });
    case FETCH_FORECAST_ITEM.REQUEST:
    case UPDATE_FORECAST_ITEM.REQUEST:
      return state.merge({
        loaded: false,
        loading: true,
        error: null,
      });
    case FETCH_FORECAST_ITEM.SUCCESS:
    case UPDATE_FORECAST_ITEM.SUCCESS:
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        receivedAt: Date.now(),
        items: state.items.merge(
          action.payload.map(forecastItem => [forecastItem.date, new ForecastItem(forecastItem)]),
        ),
      });
    default:
      return state;
  }
}
