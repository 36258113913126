import BaseModel from 'data/BaseModel';

export interface PersonFields {
  id: number | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phone: string | null;
  mfa: {
    // TODO(types)
    enabled: boolean;
    required: boolean;
    type: any | null;
  };
  created_at: string | null;
  updated_at: string | null;
}

class Person extends BaseModel<PersonFields>({
  id: null,
  firstName: null,
  lastName: null,
  email: null,
  phone: null,
  mfa: {
    enabled: false,
    required: false,
    type: null,
  },
  created_at: null,
  updated_at: null,
}) {}

export default Person;
