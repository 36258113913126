import { type WiwState, useWiwSelector } from 'store';

import { getLdClient } from 'ld-redux';

export function getLDFlag<T = boolean>(state: WiwState, flag: string): T {
  getLdClient()?.variation(flag);
  return (state.LD?.isLDReady && state.LD[flag]) || false;
}

export function useLDFlag<T = boolean>(flag: string): T {
  return useWiwSelector((state: WiwState) => getLDFlag(state, flag));
}

export function useLDFlagValue<T = number>(flag: string): T {
  return useWiwSelector((state: WiwState) => getLDFlag(state, flag));
}

// Returns true if the user is in the treatment group for an experiment
export function useLDExperiment(flag: string): boolean {
  return useWiwSelector((state: WiwState) => {
    return getLDFlag<string>(state, flag) === 'treatment';
  });
}

// Returns true if the current user is a member of an experiment either in 'treatment' or 'control'
export function getLDExperimentMember(state: WiwState, flag: string) {
  const flagEval = getLDFlag<string>(state, flag);
  return !!flagEval && (flagEval === 'treatment' || flagEval === 'control');
}

// Returns true if the current user is a member of an experiment either in 'treatment' or 'control'
export function useLDExperimentMember(flag: string): boolean {
  return useWiwSelector((state: WiwState) => {
    return getLDExperimentMember(state, flag);
  });
}
