import { TIMEZONE_SWITCH, TIMEZONE_USER } from 'store/action-types';

export function timezoneSwitchUpdate(payload) {
  return {
    type: TIMEZONE_SWITCH,
    payload,
  };
}

export function timezoneUserUpdate(payload) {
  return {
    type: TIMEZONE_USER,
    payload,
  };
}
