import { createShift } from 'data/shift/actions/createShift';
import { updateShift } from 'data/shift/actions/updateShift';
import type Shift from 'data/shift/model';
import type { ShiftFields } from 'data/shift/model';
import { fetchShiftTags } from 'data/tag/actions/shiftTags/fetchShiftTags';
import { replaceShiftTaskListsStore } from 'data/tasks/actions/persistScheduledShiftTaskList';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

export function persistShift(shift: Shift, includeRepeatingShiftsTo: string | boolean = false) {
  if (shift.isNew()) {
    return createShift({ shift, includeRepeating: includeRepeatingShiftsTo });
  }
  return updateShift({ shift, includeRepeating: includeRepeatingShiftsTo });
}

type PersistShiftsArgs = {
  shifts: Shift[];
  hasInstancedAssign?: boolean;
};
type PersistShiftsResponse = {
  shifts: ShiftFields[];
};

export const persistShifts = createWiwAsyncThunk<PersistShiftsResponse, PersistShiftsArgs>(
  'shift/multiPersist',
  async ({ shifts, hasInstancedAssign = false }, { rejectWithValue, dispatch }) => {
    try {
      let query = {};

      if (hasInstancedAssign) {
        query = { assign_openshift_instances: true };
      }

      const response = (await Monolith.url('/shifts/bulk')
        .query(query)
        .put(shifts.map(shift => shift.toJSON()))) as PersistShiftsResponse;

      const tagShiftIds = response.shifts
        .map(shift => {
          if (shift.tasks?.length) {
            dispatch(replaceShiftTaskListsStore(shift.tasks, shift.id));
          }

          if (shift.tags?.length) {
            return shift.id;
          }
        })
        .filter(Boolean);

      if (tagShiftIds.length > 0) {
        dispatch(fetchShiftTags(tagShiftIds));
      }

      return response;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
