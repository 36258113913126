import EntityState from 'data/EntityState';
import PayrollData from 'data/payrollData/model';
import { FETCH_PAYROLL_DATA, FETCH_PAYROLL_DATA_FOR_USER } from 'store/action-types';

export default (state = new EntityState(), action) => {
  switch (action.type) {
    case FETCH_PAYROLL_DATA.REQUEST:
      return state.merge({
        loading: true,
        receivedAt: 0,
        loaded: false,
        items: state.items.clear(),
      });
    case FETCH_PAYROLL_DATA.SUCCESS:
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        receivedAt: Date.now(),
        items: state.items.merge(action.payload.data.map(data => [data.user_id, new PayrollData(data)])),
      });
    case FETCH_PAYROLL_DATA.FAILURE:
      return state.merge({
        loaded: false,
        loading: false,
        error: action.payload,
      });
    case FETCH_PAYROLL_DATA_FOR_USER.REQUEST:
      return state.merge({
        loading: true,
        receivedAt: 0,
        loaded: false,
      });
    case FETCH_PAYROLL_DATA_FOR_USER.SUCCESS:
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        receivedAt: Date.now(),
        items: state.items
          .delete(action.userId)
          .merge(action.payload.data.map(data => [data.user_id, new PayrollData(data)])),
      });
    case FETCH_PAYROLL_DATA_FOR_USER.FAILURE:
      return state.merge({
        loaded: false,
        loading: false,
        error: action.payload,
      });
    default:
      return state;
  }
};
