import type Account from 'data/account/model';
import type Plan from 'data/plan/model';
import type User from 'data/user/model';
import CountDown from 'shared/components/nav/BulletinBoard/CountDown';
import Features from 'shared/features';

import classnames from 'classnames';

type TrialCountDownProps = {
  accountPlan: Plan;
  authAccount: Account;
  authUser: User;
};

export function TrialCountDown({ authAccount, authUser, accountPlan }: TrialCountDownProps) {
  const viewingAttendance = /^\/attendance|timesheets(\/)?$/i.test(location.pathname);
  const shouldShowAttendance = viewingAttendance && authAccount.isAttendanceTrial();

  const isTrial = shouldShowAttendance || accountPlan.isTrial();

  if (!isTrial || !authUser.canBilling() || !authAccount.enabled) {
    return null;
  }

  const expires = shouldShowAttendance ? 'wb_expires' : 'plan_expires';
  const feature = shouldShowAttendance ? Features.ATTENDANCE : Features.SCHEDULING;

  return (
    <CountDown
      className={classnames('application-message', { attendance: viewingAttendance, scheduling: !viewingAttendance })}
      expiresAt={authAccount.luxonDate(expires)}
      feature={feature}
      remainingUrgent={1}
      remainingWarn={7}
      to={authAccount.plansUrl(feature)}
    />
  );
}
