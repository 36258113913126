import { useEffect, useRef } from 'react';

import classnames from 'classnames';
import lottie, { type AnimationItem } from 'lottie-web';

interface LottieProps {
  animationData: any;
  loop?: boolean;
  autoplay?: boolean;
  width: number;
  height: number;
  className: string;
}

export default function LottieAnimation({
  animationData,
  width,
  height,
  loop = true,
  autoplay = true,
  className,
}: LottieProps) {
  const element = useRef<HTMLDivElement>(null);
  const lottieInstance = useRef<AnimationItem>();

  useEffect(() => {
    if (element.current) {
      lottieInstance.current = lottie.loadAnimation({
        animationData,
        container: element.current,
        loop,
        autoplay,
      });
    }
  }, [animationData]);

  return (
    <div
      className={classnames('d-inline-block', className)}
      style={{ width, height }}
      ref={element}
      data-testid="lottie-animation"
    />
  );
}
