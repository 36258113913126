import {
  CLEAR_PLAN_SELECTIONS,
  SELECT_PLAN,
  SET_BILLING_TYPE,
  SET_SELECTED_PRODUCT_LINE,
  SET_SHOW_ALL_FEATURES,
} from 'billing/actions';
import {
  defaultAttendanceFeatures,
  defaultAttendanceValueProps,
} from 'billing/modules/plans/constants/attendanceFeatures';
import {
  defaultSchedulerFeatures,
  defaultSchedulerValueProps,
} from 'billing/modules/plans/constants/schedulerFeatures';
import Plan from 'data/plan/model';
import { UPDATE_BILLING_PLAN } from 'store/action-types';

import { Map } from 'immutable';

export const initialState = Map({
  loading: false,
  success: false,
  error: null,

  billingType: null,
  selectedProductLine: null,
  products: Map({
    [Plan.ProductLine.SCHEDULING]: Map({
      features: defaultSchedulerFeatures,
      selectedPlanId: null,
      showAllFeatures: false,
      valueProps: defaultSchedulerValueProps,
    }),
    [Plan.ProductLine.ATTENDANCE]: Map({
      features: defaultAttendanceFeatures,
      selectedPlanId: null,
      showAllFeatures: false,
      valueProps: defaultAttendanceValueProps,
    }),
  }),
});

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_BILLING_PLAN.FAILURE:
      return state.merge({
        loading: false,
        success: false,
        error: action.payload.error,
      });

    case UPDATE_BILLING_PLAN.REQUEST:
      return state.merge({
        loading: true,
        success: false,
        error: null,
      });

    case UPDATE_BILLING_PLAN.SUCCESS:
      return state.mergeDeep({
        loading: false,
        success: true,
        error: null,
      });

    case SET_BILLING_TYPE:
      return state.merge({
        billingType: action.payload,
      });

    case SELECT_PLAN:
      return state.mergeIn(['products', action.productLine], {
        selectedPlanId: action.planId,
      });

    case CLEAR_PLAN_SELECTIONS:
      return state.mergeDeep({
        products: {
          [Plan.ProductLine.SCHEDULING]: {
            selectedPlanId: null,
          },
          [Plan.ProductLine.ATTENDANCE]: {
            selectedPlanId: null,
          },
        },
      });

    case SET_SHOW_ALL_FEATURES:
      return state.mergeIn(['products', action.productLine], {
        showAllFeatures: action.value,
      });

    case SET_SELECTED_PRODUCT_LINE:
      return state.merge({
        selectedProductLine: action.payload,
      });

    default:
      return state;
  }
}
