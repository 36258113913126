import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { DeliveryAmount, MediaCategory, Message, Paginator } from '@twilio/conversations';
import { first } from 'lodash';

import BaseModel from 'data/BaseModel';
import EntityBase from 'data/EntityBase';
import type EntityState from 'data/EntityState';
import generateThunkReducers from 'data/util/generateThunkReducers';
import { mergeEntityItems } from 'data/util/sliceHelpers';
import { Map } from 'immutable';
import { MESSAGE_AUTHOR_SYSTEM_ID } from 'workchat/v2/constants';
import { loadWorkchatMessages } from 'workchat/v2/store/messages/messagesActions';
import { messagePaginatorsMap, messagesSdkMap } from 'workchat/v2/twilio-objects';

export type ReduxMessage = {
  sid: string;
  index: number;
  body: string | null;
  author: number;
  attributes: { [key: string]: any } | null;
  conversation: {
    sid: string;
  };
  participantSid: string | null;
  dateCreated: string | null;
  attachedMedia:
    | { sid: string; filename: string | null; contentType: string; size: number; category: MediaCategory }[]
    | null;
  aggregatedDeliveryReceipt: {
    total: number;
    sent: DeliveryAmount;
    delivered: DeliveryAmount;
    read: DeliveryAmount;
    undelivered: DeliveryAmount;
    failed: DeliveryAmount;
  } | null;
};

class ReduxMessageModel extends BaseModel<ReduxMessage>({
  sid: '',
  index: 0,
  body: null,
  author: 0,
  conversation: { sid: '' },
  attributes: null,
  participantSid: null,
  dateCreated: null,
  attachedMedia: null,
  aggregatedDeliveryReceipt: null,
}) {}

const reduxifyMessage = (message: Message): ReduxMessage => ({
  sid: message.sid,
  index: message.index,
  body: message.body,
  // Cast author into number as this is the user.id, -1 if not found
  author: message.author === 'system' ? MESSAGE_AUTHOR_SYSTEM_ID : +(message.author || -1),
  conversation: {
    sid: message.conversation.sid,
  },
  participantSid: message.participantSid,
  attributes: { ...(message.attributes as { [key: string]: any }) },
  dateCreated: message.dateCreated?.toISOString() || null,
  aggregatedDeliveryReceipt: message.aggregatedDeliveryReceipt
    ? {
        total: message.aggregatedDeliveryReceipt.total,
        sent: message.aggregatedDeliveryReceipt.sent,
        delivered: message.aggregatedDeliveryReceipt.delivered,
        read: message.aggregatedDeliveryReceipt.read,
        undelivered: message.aggregatedDeliveryReceipt.undelivered,
        failed: message.aggregatedDeliveryReceipt.failed,
      }
    : null,
  attachedMedia:
    message.attachedMedia?.map(el => ({
      sid: el.sid,
      filename: el.filename,
      contentType: el.contentType,
      size: el.size,
      category: el.category,
    })) ?? null,
});

export interface WorkchatMessagesState extends EntityState<ReduxMessage> {
  messagePaginatorByConverationId: Map<string, { hasNext: boolean }>;
}

export class WorkchatMessagesInitialState extends EntityBase<
  ReduxMessage,
  { messagePaginatorByConverationId: Map<string, { hasNext: boolean }> }
>({
  messagePaginatorByConverationId: Map<string, { hasNext: boolean }>(),
}) {}

export const workchatMessagesSliceName = 'workchatv2/messages';

/**
 * Working with Message(s):
 *
 * 1. Get messageSid
 * 2. Get sdkObject based on 1 (we can't store complex Objects in Redux)
 * 3. Call Promise method on sdkObject
 * 4. Resolve / Reject
 * 5. Dispatch to update either entity, or store state
 *
 * Client
 *   - Conversation
 *     - Participants
 *     - Messages
 */
export const workchatMessagesSlice = createSlice({
  name: workchatMessagesSliceName,
  initialState: new WorkchatMessagesInitialState(),
  reducers: {
    upsertMessage: (state, action: PayloadAction<Message>) => {
      const message = action.payload;
      messagesSdkMap.set(message.sid, message);

      return mergeEntityItems(state, reduxifyMessage(message), ReduxMessageModel, 'sid');
    },
    deleteMessage: (state, action: PayloadAction<string>) => {
      messagesSdkMap.delete(action.payload);

      return state.set(
        'items',
        state.get('items').filter(message => message.sid !== action.payload),
      );
    },
    removeMessagesForConversation: (state, action: PayloadAction<string>) => {
      messagePaginatorsMap.set(action.payload, null);

      return state.merge({
        items: state.items.filter(messageEntity => messageEntity.conversation.sid !== action.payload),
        messagePaginatorByConverationId: state.messagePaginatorByConverationId.filter(
          (_value, key) => key !== action.payload,
        ),
      });
    },
  },
  extraReducers: builder => {
    builder.addCase(loadWorkchatMessages.fulfilled, (state, action: PayloadAction<Paginator<Message>>) => {
      const conversationId = first(action.payload.items)?.conversation.sid;

      if (!conversationId) {
        return state;
      }

      messagePaginatorsMap.set(conversationId, action.payload);

      const reduxMessage = action.payload.items.map(message => {
        messagesSdkMap.set(message.sid, message);
        return reduxifyMessage(message);
      });

      return mergeEntityItems(state, reduxMessage, ReduxMessageModel, 'sid').set(
        'messagePaginatorByConverationId',
        state.get('messagePaginatorByConverationId').set(conversationId, { hasNext: action.payload.hasPrevPage }),
      );
    });

    generateThunkReducers(builder, workchatMessagesSliceName);
  },
});

export const { upsertMessage, deleteMessage, removeMessagesForConversation } = workchatMessagesSlice.actions;

export default workchatMessagesSlice.reducer;
