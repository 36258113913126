import memoizeOne from 'memoize-one';

export const filterByDate = (start, end, shifts, greedy = false) => {
  if (greedy) {
    return greedyFilter(start, end, shifts);
  }
  /**
   * This filter will match the diagram below:
   *
   * Invalid -> ==========
   *       Valid -> ==========
   *               Valid -> ==========
   *                            Valid -> ==========
   * Boundary Start |------------------------| Boundary End
   */
  return notGreedyFilter(start, end, shifts);
};

export const greedyFilter = memoizeOne((start, end, shifts) =>
  shifts.filter(shift => shift.date('end_time').isAfter(start) && shift.date('start_time').isBefore(end)),
);

const notGreedyFilter = memoizeOne((start, end, shifts) =>
  shifts.filter(shift => shift.date('start_time').isBetween(start, end, null, '[)')),
);
