import PropTypes from 'prop-types';
import { Component } from 'react';

export default class TruncatedList extends Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    width: PropTypes.number.isRequired,
    prefix: PropTypes.string,
    font: PropTypes.string,
    formatOverflow: PropTypes.func,
  };

  static defaultProps = {
    prefix: '',
    font: "12px Arial, 'Source Sans Pro', Helvetica, sans-serif",
    formatOverflow: () => '',
  };

  state = {
    string: '',
    overflow: '',
  };

  componentDidMount() {
    this.updateString(this.props);
  }

  componentWillReceiveProps(props) {
    this.updateString(props);
  }

  updateString(props) {
    const { items, width, prefix, font, formatOverflow } = props;
    const ctx = document.createElement('canvas').getContext('2d');
    ctx.font = font;

    let string = '';
    let overflow = '';
    let i = items.length;
    do {
      string = prefix + items.slice(0, i).join(', ');
      overflow = i < items.length ? formatOverflow(items.length - i) : '';
      const metrics = ctx.measureText(string);
      if (metrics.width > width) {
        i--;
      } else {
        i = 0;
      }
    } while (i >= 2);

    this.setState({ string, overflow });
  }

  render() {
    return (
      <div className="truncated-list">
        {this.state.string}
        {this.state.overflow ? <span>{this.state.overflow}</span> : null}
      </div>
    );
  }
}
