import { SCHEDULER_SHOW_FILTERED_EMPLOYEES } from 'scheduler/util/cookies';
import { TOGGLE_SHOW_FILTERED_EMPLOYEES } from 'store/action-types';

import Cookie from 'js-cookie';

export function toggleShowFilteredEmployees() {
  return (dispatch, getState) => {
    const isShown = getState().scheduler.get('showFilteredEmployees');

    Cookie.set(SCHEDULER_SHOW_FILTERED_EMPLOYEES, !isShown);
    return dispatch({
      type: TOGGLE_SHOW_FILTERED_EMPLOYEES,
    });
  };
}
