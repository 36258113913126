import hourStatsActual from 'data/hourStats/reducers/hourStatsActualReducer';
import hourStatsOpenShifts from 'data/hourStats/reducers/hourStatsOpenShiftsReducer';
import hourStatsRequests from 'data/hourStats/reducers/hourStatsRequestsReducer';
import hourStatsScheduled from 'data/hourStats/reducers/hourStatsScheduledReducer';

import { combineReducers } from '@reduxjs/toolkit';

export default combineReducers({
  actual: hourStatsActual,
  scheduled: hourStatsScheduled,
  requests: hourStatsRequests,
  openshifts: hourStatsOpenShifts,
});
