import type { PolicyResponse } from 'data/timeOffAccrual/types/common';
import type { DataResponse, QueryString } from 'data/types';
import Scheduling from 'shared/api/scheduling';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

interface FetchPolicyArgs {
  policyId: string;
  params: QueryString;
}

export const fetchPolicy = createWiwAsyncThunk<DataResponse<PolicyResponse>, FetchPolicyArgs>(
  'timeOff/policies/fetchPolicy',
  async ({ policyId, params = {} }, { rejectWithValue }) => {
    try {
      return await Scheduling.query(params).get(`/timeoff/policies/${policyId}`);
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
