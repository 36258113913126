import EntityState from 'data/EntityState';
import { fetchAbsence, fetchAbsences } from 'data/absence/actions/fetchAbsence';
import { toEntityMap } from 'shared/util/toEntityMap';
import { CLEAR_SWAPS, CREATE_SWAP, LIST_SWAPS, UPDATE_SWAP } from 'store/action-types';
import Swap from './model';

export default function (state = new EntityState(), action) {
  switch (action.type) {
    case LIST_SWAPS.FAILURE:
      return state.merge({
        loaded: false,
        loading: false,
        error: action.payload,
      });
    case CLEAR_SWAPS:
      return state.merge({
        loaded: false,
        loading: false,
        error: null,
        receivedAt: Date.now(),
        items: state.items.clear(),
      });
    case LIST_SWAPS.REQUEST:
      return state.merge({
        loaded: false,
        loading: true,
        error: null,
      });
    case LIST_SWAPS.SUCCESS_PAGE: {
      return state.merge({
        loaded: false,
        loading: true,
        error: null,
        receivedAt: Date.now(),
        items: state.items.merge(action.payload.map(swap => [swap.id, new Swap(swap)])),
      });
    }
    case LIST_SWAPS.SUCCESS:
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        receivedAt: Date.now(),
      });
    case UPDATE_SWAP.FAILURE:
      return state.merge({
        loaded: false,
        loading: false,
        error: action.payload,
      });
    case UPDATE_SWAP.REQUEST:
      return state.merge({
        loaded: false,
        loading: true,
        error: null,
      });
    case UPDATE_SWAP.SUCCESS:
      return state.merge({
        items: state.items.delete(null).set(action.payload.id, new Swap(action.payload)),
      });
    case CREATE_SWAP.SUCCESS:
      return state.setIn(['items', action.payload.id], new Swap(action.payload));
    case fetchAbsence.fulfilled.type:
    case fetchAbsences.fulfilled.type:
      if (action.payload.data.shiftRequests) {
        return state.merge({
          loaded: false,
          loading: true,
          error: null,
          receivedAt: Date.now(),
          items: state.items.merge(toEntityMap(action.payload.data.shiftRequests, Swap)),
        });
      }
      return state;
    default:
      return state;
  }
}
