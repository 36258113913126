import { authSlice } from 'data/auth/reducer';
import ForecastItem from 'data/forecast/model';
import {
  STEP_ATTENDANCE_ADD_EMPLOYEES,
  STEP_ATTENDANCE_EDIT_TIMESHEET,
  STEP_ATTENDANCE_EXPORT_TIMESHEET,
  STEP_SCHEDULING_ADD_EMPLOYEES,
  STEP_SCHEDULING_CREATE_SHIFT,
  STEP_SCHEDULING_PUBLISH_SCHEDULE,
} from 'onboarding/constants';
import { getInitialSortFilter } from 'scheduler/actions/changeSortFilter';
import { getInitialRange, getInitialView } from 'scheduler/actions/setCurrentView';
import { getInitialLocation } from 'scheduler/actions/updateLocationFilter';
import { getInitialPositionFilter } from 'scheduler/actions/updatePositionFilter';
import { getInitialSitesFilter } from 'scheduler/actions/updateSiteFilter';
import { getInitialTagFilter } from 'scheduler/actions/updateTagFilter';
import RollupShiftConcern from 'scheduler/modals/rollupShiftConcern';
import { STATUS } from 'scheduler/util/constants';
import {
  SCHEDULER_DATE,
  SCHEDULER_HIDE_UNSCHEDULED_EMPLOYEES,
  SCHEDULER_SHIFT_BACKGROUND,
  SCHEDULER_SHOW_FILTERED_EMPLOYEES,
  SHOW_OTHER_SCHEDULES,
  SHOW_UNSCHEDULED_JOBSITES,
  SHOW_UNSCHEDULED_POSITIONS,
} from 'scheduler/util/cookies';
import { FILTER_POSITION, FILTER_SHIFT, FILTER_SITE } from 'scheduler/util/filters';
import { getUserItem } from 'shared/util/userLocalStorage';
import {
  ADD_SELECTED_BULK_EDIT_SHIFTS,
  CHANGE_EMPLOYEE_SORT,
  CHANGE_ONBOARDING_EMPLOYEE_COUNT,
  CHANGE_ONBOARDING_STEP_ID,
  CONFIRM_OPENSHIFT_ASSIGNMENT,
  EXPAND_OPENSHIFT_ROW,
  FETCH_OPENSHIFT_ASSIGNMENT,
  HYDRATE_FILTERS_FROM_METADATA,
  LIST_FORECASTING_PROJECTIONS,
  LIST_LABOR_DATA,
  REMOVE_SELECTED_BULK_EDIT_SHIFTS,
  REVERT_OPENSHIFT_ASSIGNMENT,
  SCHEDULER_FIRST_COMPLETE_RENDER,
  SCHEDULER_INIT_LOADING,
  SET_CURRENT_DATE_AND_VIEW,
  SET_FORECASTING_PROJECTION,
  SET_SCHEDULER_STATUS,
  SET_SCHEDULE_CURRENT_DATE,
  SET_SELECTED_BULK_EDIT_SHIFTS,
  SET_SHIFT_TO_VIEW,
  SET_USERS_WITH_SCHEDULING_CONCERNS,
  TIMEZONE_SWITCH,
  TIMEZONE_USER,
  TOGGLE_BULK_EDIT_MODE,
  TOGGLE_FORECAST_TOOLS,
  TOGGLE_HIDE_UNSCHEDULED_EMPLOYEES,
  TOGGLE_HIGHLIGHT_OPENSHIFTS,
  TOGGLE_HIGHLIGHT_UNCONFIRMED_SHIFTS,
  TOGGLE_OPENSHIFT_ROW,
  TOGGLE_PRINT_MODE,
  TOGGLE_PUBLISH_CONCERNS,
  TOGGLE_SCHEDULER_ONBOARDING_HIGHLIGHT,
  TOGGLE_SCHEDULE_PROJECTIONS,
  TOGGLE_SCHEDULE_SIDEBAR,
  TOGGLE_SHOW_FILTERED_EMPLOYEES,
  TOGGLE_SHOW_OTHER_SCHEDULE_SHIFTS,
  TOGGLE_SHOW_UNSCHEDULED_JOBSITES,
  TOGGLE_SHOW_UNSCHEDULED_POSITIONS,
  TOGGLE_TEMPLATE_OVERLAY,
  UPDATE_DRAG_DATA,
  UPDATE_LOCATION_FILTER,
  UPDATE_POSITION_FILTER,
  UPDATE_ROLLUP_SHIFT_CONCERNS,
  UPDATE_SCHEDULE_FORECAST_OPTIONS,
  UPDATE_SHIFT_BACKGROUND_FILTER,
  UPDATE_SITE_FILTER,
  UPDATE_TAG_FILTER,
  UPDATE_USER_FILTER,
} from 'store/action-types';

import { List, Map, fromJS } from 'immutable';
import Cookies from 'js-cookie';
import moment from 'moment-timezone';

const validFilters = [FILTER_SHIFT, FILTER_POSITION, FILTER_SITE];

const selectedFilter = Cookies.get(SCHEDULER_SHIFT_BACKGROUND);

export const initialState = new Map({
  status: STATUS.INITIAL_LOAD,
  currentDate: moment(),
  view: getInitialView(),
  range: getInitialRange(),
  sidebarVisible: true,
  openShiftsCollapsed: getUserItem('openShiftRowCollapsed', false),
  shiftBackground: validFilters.includes(selectedFilter) ? selectedFilter : FILTER_SHIFT,
  labor: new Map(),
  forecast: new Map(),
  filters: new Map({
    location: getInitialLocation(),
    positions: getInitialPositionFilter(),
    sites: getInitialSitesFilter(),
    user: -1,
    tags: getInitialTagFilter(),
  }),
  showFilteredEmployees: Cookies.getJSON(SCHEDULER_SHOW_FILTERED_EMPLOYEES) === true,
  hideUnscheduledEmployees: Cookies.getJSON(SCHEDULER_HIDE_UNSCHEDULED_EMPLOYEES) === true,
  locationSort: getInitialSortFilter(),
  showOtherScheduleShifts: Cookies.getJSON(SHOW_OTHER_SCHEDULES) === true,
  hideUnscheduledPositions: Cookies.getJSON(SHOW_UNSCHEDULED_POSITIONS) === true,
  hideUnscheduledJobSites: Cookies.getJSON(SHOW_UNSCHEDULED_JOBSITES) === true,
  highlightOpenshifts: false,
  highlightUnconfirmedShifts: false,
  projections: new Map({
    visible: false,
  }),
  forecastTools: new Map({
    visible: false,
    forecastType: ForecastItem.Type.SALES,
    noWageAlertDismissed: false,
  }),
  onboarding: new Map({
    currentStepIndex: 0, // needs to default to 0 (not null) so that onboardingCurrentStepIndex is always valid
    employeeCount: null,
    highlightVisible: new Map({
      [STEP_SCHEDULING_ADD_EMPLOYEES]: false,
      [STEP_SCHEDULING_CREATE_SHIFT]: false,
      [STEP_SCHEDULING_PUBLISH_SCHEDULE]: false,
      [STEP_ATTENDANCE_ADD_EMPLOYEES]: false,
      [STEP_ATTENDANCE_EDIT_TIMESHEET]: false,
      [STEP_ATTENDANCE_EXPORT_TIMESHEET]: false,
    }),
    industryId: null,
    previousStepIndex: null,
  }),
  templateOverlay: new Map({
    visible: false,
    templateID: null,
  }),
  printMode: new Map({
    visible: false,
    userID: null,
  }),
  modals: new Map({
    replacement: new Map({
      visible: false,
      shiftID: null,
    }),
    requests: new Map({
      visible: false,
      requestID: null,
    }),
    userEditor: new Map({
      visible: false,
      userID: null,
    }),
    shiftEditor: new Map({
      visible: false,
      shift: null,
      options: {
        showOffer: false,
        showDelete: false,
        isTemplate: false,
      },
    }),
    copyShifts: new Map({
      visible: false,
      userID: null,
    }),
    clearShifts: new Map({
      visible: false,
      userID: null,
    }),
    createTemplate: new Map({
      visible: false,
    }),
    loadTemplates: new Map({
      visible: false,
    }),
    exportShifts: new Map({
      visible: false,
    }),
    userContact: new Map({
      visible: false,
      userID: null,
    }),
  }),
  idsAwaitingAutoAssign: null,
  inConfirmAutoAssignMode: false,
  preserveRecords: null,
  autoAssignStatus: null,
  autoScheduleLoading: false,
  loadingUpdates: [],
  bulkEdit: new Map({
    enabled: false,
    shiftIds: new List(),
  }),
  dragData: new Map({
    shiftId: null,
    userId: null,
    otBoundaries: { start: moment(), end: moment() },
  }),
  rollupShiftToUserConcerns: new Map({}),
  publishConcernsReview: new Map({ enabled: false }),
  usersWithSchedulingConcerns: new Map(),
  timezoneSwitch: new Map({
    timezone_id: 0,
    timezone_name: '',
  }),
  timezoneUser: new Map(),
  shiftToView: null,
});

export default function (state = initialState, action) {
  const getSchedulerDate = () => {
    if (Cookies.get(SCHEDULER_DATE)) {
      if (!moment(Cookies.get(SCHEDULER_DATE), 'x').isValid()) {
        Cookies.set(SCHEDULER_DATE, moment().format('x'));
      }
      return moment(Cookies.get(SCHEDULER_DATE), 'x');
    }
    return moment();
  };

  switch (action.type) {
    case authSlice.actions.receiveAuth.type:
      return state.set('currentDate', getSchedulerDate());
    case TOGGLE_SCHEDULE_SIDEBAR:
      return state.update('sidebarVisible', value => !value);
    case TOGGLE_OPENSHIFT_ROW:
      return state.update('openShiftsCollapsed', value => !value);
    case EXPAND_OPENSHIFT_ROW:
      return state.update('openShiftsCollapsed', () => false);
    case TOGGLE_SHOW_FILTERED_EMPLOYEES:
      return state.update('showFilteredEmployees', value => !value);
    case TOGGLE_TEMPLATE_OVERLAY:
      return state.update('templateOverlay', value => {
        return new Map({
          visible: !value.get('visible'),
          templateID: action.payload,
        });
      });
    case TOGGLE_PRINT_MODE:
      return state.update('printMode', value => {
        return new Map({
          visible: !value.get('visible'),
          userID: action.userID,
        });
      });
    case SET_SCHEDULE_CURRENT_DATE:
      return state.withMutations(item => {
        item.set('currentDate', action.payload.currentDate);
      });
    case SET_CURRENT_DATE_AND_VIEW:
      return state.withMutations(item => {
        item
          .set('currentDate', action.payload.date)
          .set('view', action.payload.view || item.get('view'))
          .set('range', action.payload.range || item.get('range'));
      });
    case TOGGLE_SCHEDULE_PROJECTIONS:
      return state.updateIn(['projections', 'visible'], value => !value);
    case TOGGLE_FORECAST_TOOLS:
      return state.updateIn(['forecastTools', 'visible'], value => !value);
    case UPDATE_SCHEDULE_FORECAST_OPTIONS:
      return state.mergeIn(['forecastTools'], action.options);
    case LIST_LABOR_DATA.SUCCESS:
      return state.set('labor', fromJS(action.payload));
    case LIST_FORECASTING_PROJECTIONS.SUCCESS:
      return state.set('forecast', new Map(action.payload.map(projection => [projection.date, new Map(projection)])));
    case SET_FORECASTING_PROJECTION.SUCCESS:
      if (!action.payload.estimate) {
        return state.deleteIn(['forecast', action.payload.date]);
      }
      return state.setIn(['forecast', action.payload.date], new Map(action.payload));
    case UPDATE_LOCATION_FILTER:
      return state.setIn(['filters', 'location'], action.payload);
    case UPDATE_USER_FILTER:
      return state.setIn(['filters', 'user'], action.payload);
    case UPDATE_POSITION_FILTER:
      return state.setIn(['filters', 'positions'], action.payload);
    case UPDATE_SITE_FILTER:
      return state.setIn(['filters', 'sites'], action.payload);
    case UPDATE_SHIFT_BACKGROUND_FILTER:
      return state.set('shiftBackground', action.payload);
    case TOGGLE_SCHEDULER_ONBOARDING_HIGHLIGHT:
      return state.updateIn(['onboarding', 'highlightVisible', action.payload.element], value =>
        action.payload.highlight !== null ? action.payload.highlight : !value,
      );
    case TOGGLE_HIDE_UNSCHEDULED_EMPLOYEES:
      return state.update('hideUnscheduledEmployees', value => !value);
    case CHANGE_EMPLOYEE_SORT:
      return state.set('locationSort', action.payload);
    case TOGGLE_SHOW_OTHER_SCHEDULE_SHIFTS:
      return state.set('showOtherScheduleShifts', action.payload);
    case TOGGLE_SHOW_UNSCHEDULED_POSITIONS:
      return state.set('hideUnscheduledPositions', action.payload);
    case TOGGLE_SHOW_UNSCHEDULED_JOBSITES:
      return state.set('hideUnscheduledJobSites', action.payload);
    case FETCH_OPENSHIFT_ASSIGNMENT.REQUEST:
      return state
        .set('idsAwaitingAutoAssign', action.payload)
        .set('preserveRecords', action.openShifts)
        .set('autoScheduleLoading', true)
        .set('autoAssignStatus', null);
    case FETCH_OPENSHIFT_ASSIGNMENT.SUCCESS:
      return state
        .set('inConfirmAutoAssignMode', true)
        .set(
          'idsAwaitingAutoAssign',
          action.items.map(item => item.id),
        )
        .set('autoScheduleLoading', false);
    case FETCH_OPENSHIFT_ASSIGNMENT.FAILURE:
      return state
        .set('idsAwaitingAutoAssign', null)
        .set('inConfirmAutoAssignMode', false)
        .set('autoAssignStatus', action.error)
        .set('autoScheduleLoading', false);
    case REVERT_OPENSHIFT_ASSIGNMENT:
      return state.set('idsAwaitingAutoAssign', null).set('inConfirmAutoAssignMode', false);
    case CONFIRM_OPENSHIFT_ASSIGNMENT.REQUEST:
      return state.set('autoAssignStatus', null);
    case CONFIRM_OPENSHIFT_ASSIGNMENT.SUCCESS:
      return state.set('idsAwaitingAutoAssign', null).set('inConfirmAutoAssignMode', false);
    case CONFIRM_OPENSHIFT_ASSIGNMENT.FAILURE:
      return (
        state
          .set('idsAwaitingAutoAssign', null)
          .set('autoAssignStatus', action.error || {})
          // An error during confirmation might mean the shifts saved,
          // so go to normal mode to prevent multiple saves of shifts
          .set('inConfirmAutoAssignMode', false)
      );
    case TOGGLE_HIGHLIGHT_OPENSHIFTS:
      if (action.payload === undefined) {
        return state.update('highlightOpenshifts', toggle => !toggle);
      }
      return state.set('highlightOpenshifts', action.payload);
    case TOGGLE_HIGHLIGHT_UNCONFIRMED_SHIFTS:
      if (action.payload === undefined) {
        return state.update('highlightUnconfirmedShifts', toggle => !toggle);
      }
      return state.set('highlightUnconfirmedShifts', action.payload);
    case CHANGE_ONBOARDING_STEP_ID:
      return state
        .setIn(['onboarding', 'previousStepIndex'], state.getIn(['onboarding', 'currentStepIndex']))
        .setIn(['onboarding', 'currentStepIndex'], action.payload);
    case CHANGE_ONBOARDING_EMPLOYEE_COUNT:
      return state.setIn(['onboarding', 'employeeCount'], action.payload);
    case UPDATE_TAG_FILTER:
      return state.setIn(['filters', 'tags'], action.payload);
    case HYDRATE_FILTERS_FROM_METADATA:
      return state.merge(action.payload);
    case SET_SCHEDULER_STATUS:
      return state.set('status', action.payload);
    case SCHEDULER_INIT_LOADING:
    case SCHEDULER_FIRST_COMPLETE_RENDER:
      return state.set('loadingUpdates', [...state.get('loadingUpdates', []), { ...action }]);
    case TOGGLE_BULK_EDIT_MODE:
      if (state.getIn(['bulkEdit', 'enabled'])) {
        return state.setIn(['bulkEdit', 'enabled'], false).setIn(['bulkEdit', 'shiftIds'], new List());
      }
      return state.setIn(['bulkEdit', 'enabled'], true);
    case SET_SELECTED_BULK_EDIT_SHIFTS:
      return state.setIn(['bulkEdit', 'shiftIds'], new List(action.shiftIds));
    case ADD_SELECTED_BULK_EDIT_SHIFTS:
      return state.setIn(['bulkEdit', 'shiftIds'], state.getIn(['bulkEdit', 'shiftIds']).push(...action.shiftIds));
    case REMOVE_SELECTED_BULK_EDIT_SHIFTS:
      return state.setIn(
        ['bulkEdit', 'shiftIds'],
        state.getIn(['bulkEdit', 'shiftIds']).filter(shiftId => !action.shiftIds.includes(shiftId)),
      );
    case UPDATE_DRAG_DATA:
      return state.setIn(['dragData'], action.payload);
    case UPDATE_ROLLUP_SHIFT_CONCERNS:
      return state.setIn(
        ['rollupShiftToUserConcerns', action.shiftId],
        new RollupShiftConcern({ userId: action.userId, concern: action.concern }),
      );
    case SET_USERS_WITH_SCHEDULING_CONCERNS:
      return state.setIn(['usersWithSchedulingConcerns'], new Map(action.payload));
    case TOGGLE_PUBLISH_CONCERNS:
      if (state.getIn(['publishConcernsReview', 'enabled'])) {
        return state.setIn(['publishConcernsReview', 'enabled'], false);
      }
      return state.setIn(['publishConcernsReview', 'enabled'], true);
    case TIMEZONE_SWITCH:
      if (action.payload.get('modifyBoundaries')) {
        const currentDate = state.get('currentDate');
        // ensure we clone the date so we are not accidentally updating a reference to the current date, don't ask me
        // why I know that's a problem
        return state
          .set('currentDate', currentDate.clone().tz(action.payload.get('timezone_name'), true))
          .set('timezoneSwitch', action.payload);
      }

      return state.set('timezoneSwitch', action.payload);
    case TIMEZONE_USER:
      return state.set('timezoneUser', action.payload);
    case SET_SHIFT_TO_VIEW:
      return state.set('shiftToView', action.payload);
    default:
      return state;
  }
}
