import useIntl from 'shared/i18n/useIntl';

export default function WithIntl(Component) {
  return function CompWithCtx(props) {
    const { formatTime, formatTimeLong, formatNumber, formatCurrency, thousandsSeparator, decimalSeparator } =
      useIntl();

    return (
      <Component
        {...props}
        formatTime={formatTime}
        formatTimeLong={formatTimeLong}
        formatNumber={formatNumber}
        formatCurrency={formatCurrency}
        thousandsSeparator={thousandsSeparator}
        decimalSeparator={decimalSeparator}
      />
    );
  };
}
