import { Time } from 'data/time/models';
import { TimeState } from 'data/time/states';
import { camelCaseToSnakeCaseObject } from 'shared/util/objectCaseUtilities';
import {
  APPROVE_TIMES,
  CREATE_TIMES,
  FETCH_TIMES,
  REMOVE_TIME,
  UPDATE_TIMES,
  UPDATE_TIMES_STORE,
} from 'store/action-types';

import moment from 'moment-timezone';

export default (state = new TimeState(), action) => {
  switch (action.type) {
    case FETCH_TIMES.REQUEST:
      return state.merge({
        loading: true,
        receivedAt: 0,
        loaded: false,
      });
    case FETCH_TIMES.SUCCESS:
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        receivedAt: Date.now(),
        items: state.items.merge(
          action.items.map(time => {
            time = camelCaseToSnakeCaseObject(time);
            return [time.id, new Time(time)];
          }),
        ),
      });
    case FETCH_TIMES.FAILURE:
      return state.merge({
        loaded: false,
        loading: false,
        error: action.payload,
      });
    case CREATE_TIMES.REQUEST:
      return state.merge({
        loading: true,
        receivedAt: 0,
        loaded: false,
      });
    case CREATE_TIMES.SUCCESS:
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        receivedAt: Date.now(),
        items: state.items.delete(null).set(action.payload.id, new Time(camelCaseToSnakeCaseObject(action.payload))),
      });
    case CREATE_TIMES.FAILURE:
      return state.merge({
        loaded: false,
        loading: false,
        error: action.error,
      });
    case UPDATE_TIMES.REQUEST:
      return state.merge({
        loading: true,
        receivedAt: 0,
        loaded: false,
        items: state.items.set(action.payload.id, new Time(action.payload.set('saving', true))),
      });
    case UPDATE_TIMES.SUCCESS:
      //if the user managed to delete the time before the update response comes back, don't update the items in store
      if (!state.items.has(action.payload.id)) {
        return state.merge({
          loading: false,
          loaded: true,
          receivedAt: Date.now(),
        });
      }
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        receivedAt: Date.now(),
        items: state.items.delete(null).set(action.payload.id, new Time(camelCaseToSnakeCaseObject(action.payload))),
      });
    case UPDATE_TIMES.FAILURE:
      return state.merge({
        loaded: false,
        loading: false,
        error: action.error,
        items: state.items.set(action.payload.id, new Time(action.payload.set('saving', false))),
      });
    case UPDATE_TIMES_STORE:
      return state.merge({
        items: state.items.set(action.payload.id, new Time(camelCaseToSnakeCaseObject(action.payload))),
      });
    case REMOVE_TIME.REQUEST:
      return state.set('loading', true).setIn(['items', action.id, 'deleted_at'], moment().format());
    case REMOVE_TIME.SUCCESS:
      return state.set('loading', false).deleteIn(['items', action.id]);
    case REMOVE_TIME.FAILURE:
      return state.set('loading', false).setIn(['items', action.id, 'deleted_at'], null);
    case APPROVE_TIMES.REQUEST:
      return state.merge({
        loading: true,
        receivedAt: 0,
        loaded: false,
      });
    case APPROVE_TIMES.SUCCESS:
      if (action.payload.time) {
        return state.merge({
          loaded: true,
          loading: false,
          error: null,
          receivedAt: Date.now(),
          items: state.items.set(action.payload.time.id, new Time(action.payload.time)),
        });
      }

      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        receivedAt: Date.now(),
        items: state.items.merge(action.payload.times.map(time => [time.id, new Time(time)])),
      });
    case APPROVE_TIMES.FAILURE:
      return state.merge({
        loaded: false,
        loading: false,
        error: action.payload,
      });
    default:
      return state;
  }
};
