import type { PolicyResponse } from 'data/timeOffAccrual/types/common';
import type { DataResponse } from 'data/types';
import Scheduling from 'shared/api/scheduling';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

export interface UpdatePolicyArgs {
  id: string;
  userIds: string[];
  name: string;
  requestTypeId: string;
  isActive: boolean;
  isArchived: boolean;
}

export const updatePolicy = createWiwAsyncThunk<DataResponse<PolicyResponse>, UpdatePolicyArgs>(
  'timeOff/policies/updatePolicy',
  async ({ id, ...policyData }, { rejectWithValue }) => {
    try {
      return await Scheduling.put(policyData, `/timeoff/policies/${id}`);
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
