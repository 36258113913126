import type { Conversation, Message, Paginator } from '@twilio/conversations';

import workchat from 'shared/api/workchat';
import { default as Mercury, WORKCHAT_INTERACTION, default as mercury } from 'shared/util/mercury';
import { type WiwDispatch, createWiwAsyncThunk } from 'store';
import type { ImageInput } from 'workchat/types/ImageInput';
import { WORKCHAT_ONINTERACTION, WORKCHAT_ONINTERACTION_ACTIONS } from 'workchat/v2/constants';
import { deleteMessage, upsertMessage } from 'workchat/v2/store/messages/messagesReducer';
import { sendError, sendMessage, sentMessage, setError } from 'workchat/v2/store/reducer';
import { getSdkConversationObject, getSdkMessageObject, messagePaginatorsMap } from 'workchat/v2/twilio-objects';

interface WorkchatApiImageResponse {
  fullsize: string;
  thumbnail: string;
}

const getMessages = ({
  conversation,
  pageSize,
  conversationId,
}: { conversation?: Conversation; pageSize?: number; conversationId?: string }) => {
  if (conversationId) {
    conversation = getSdkConversationObject(conversationId);
  }

  return conversation?.getMessages(pageSize || 5) || Promise.reject(new Error('Unable to find Conversation object!'));
};

export const loadWorkchatMessages = createWiwAsyncThunk<
  Paginator<Message>,
  { conversation?: Conversation; pageSize?: number; conversationId?: string }
>('workchatv2/messages/load', async ({ conversation, pageSize, conversationId }, { rejectWithValue }) => {
  return getMessages({ conversation, pageSize, conversationId }).catch(err => rejectWithValue(err));
});

export const loadMoreWorkchatMessages = createWiwAsyncThunk<Paginator<Message>, string>(
  'workchatv2/messages/load',
  async (conversationId, { rejectWithValue }) => {
    const paginator = messagePaginatorsMap.get(conversationId);

    if (!paginator) {
      return getMessages({ conversationId });
    }

    return paginator.prevPage().catch(error => {
      return rejectWithValue(new Error(error));
    });
  },
);

export const updateWorkchatMessage = (messageSid: string, text: string) => {
  return (dispatch: WiwDispatch) => {
    return getSdkMessageObject(messageSid)
      ?.updateBody(text)
      .then(message => {
        dispatch(upsertMessage(message));
      })
      .catch(() => {
        dispatch(setError('There was a problem updating the message.'));
      });
  };
};

export const reactWorkchatMessage = ({
  conversationId,
  messageId,
  reaction,
}: {
  conversationId: string;
  messageId: string;
  reaction: string;
}) => {
  return (dispatch: WiwDispatch) => {
    return workchat
      .url(`/channels/${conversationId}/messages/${messageId}/react`)
      .put({ reaction })
      .catch(() => {
        dispatch(setError('There was a problem reacting to the message.'));
      });
  };
};

export const deleteWorkchatMessage = ({
  conversationId,
  messageId,
  messageUserId,
}: { conversationId: string; messageId: string; messageUserId: number }) => {
  return (dispatch: WiwDispatch) => {
    return workchat
      .url(`/channels/${conversationId}/messages/${messageId}`)
      .delete()
      .then(() => {
        Mercury.track(WORKCHAT_INTERACTION, {
          ...WORKCHAT_ONINTERACTION,
          action: WORKCHAT_ONINTERACTION_ACTIONS.messageDeleteSuccess,
          annotations: JSON.stringify({
            messageSid: messageId,
            messageUserId,
            conversationSid: conversationId,
          }),
        });

        dispatch(deleteMessage(messageId));
      })
      .catch(() => {
        dispatch(setError('There was a problem deleting the message.'));
      });
  };
};

// Uses new prepareMessage as recommended by Twillio docs
const buildMessage = (conversationId: string, text: string, attributes?: { [key: string]: string }) => {
  return getSdkConversationObject(conversationId)
    .prepareMessage()
    .setBody(text)
    .setAttributes({ ...attributes, mercuryMetadata: mercury.identity })
    .build()
    .send();
};

export const sendWorkchatMessageWithImage = (conversationId: string, text: string, image: ImageInput) => {
  return (dispatch: WiwDispatch) => {
    dispatch(sendMessage());

    return workchat
      .url('/images')
      .body(image.buffer)
      .content('text/plain')
      .post()
      .then((resp: WorkchatApiImageResponse) => {
        return buildMessage(conversationId, text, {
          fullsize: resp.fullsize,
          thumbnail: resp.thumbnail,
        });
      })
      .then(() => dispatch(sentMessage()))
      .catch(() => dispatch(sendError('Something went wrong sending your last message.')));
  };
};

export const sendWorkchatMessage = (conversationId: string, text: string) => {
  return (dispatch: WiwDispatch) => {
    dispatch(sendMessage());

    return buildMessage(conversationId, text)
      .then(() => dispatch(sentMessage()))
      .catch(() => dispatch(sendError('Something went wrong sending your last message.')));
  };
};
