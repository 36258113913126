import { getUserItem, setUserItem } from 'shared/util/userLocalStorage';
import { SET_PAYROLL_LOCATION } from 'store/action-types';

/**
 * The location filter  cannot be set using the persistFilter method because
 * it is part of the key used to store and retrieve the other settings.
 *
 * @param payload
 * @return {function(*): *}
 */
export function setPayrollLocation(locationId: number) {
  setUserItem('payroll_location', locationId);

  return {
    type: SET_PAYROLL_LOCATION,
    payload: locationId,
  };
}

export const getInitialLocation = () => {
  const data = getUserItem('payroll_location');
  return data !== null ? Number.parseInt(data, 10) : null;
};
