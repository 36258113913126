import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import bowser from 'bowser';
import classnames from 'classnames';
import { once } from 'lodash';

const LIBRARY_PATH = `${CONFIG.ICON_LIBRARY_HOST}/${CONFIG.ICON_LIBRARY_VERSION}`;

/**
 * @deprecated use FontIcon when possible as it may not work correctly with elev.io at the moment
 *
 * Displays icons from our custom icon library. It does so using an SVG sprite,
 * which means that only one network request is needed to load (ideally) all
 * icons for the application.
 *
 * To see what icons are available, visit https://icons.wheniwork.com. Note that
 * this project may not be using the latest version of the sprite.
 *
 * Icon library configuration can be found in config.yml, not here.
 *
 * This component renders an SVG on the page that automatically uses accessibility
 * metadata from the library. You can override the library's title and description
 * using the title and description props.
 *
 * ----------------
 * COLORS
 * ----------------
 *
 * Icons automatically inherit the color of the surrounding text. This means that
 * you can use SVG icons as a drop-in replacement for font icons.
 *
 * You can further customize the colors of your icons if you want. Every icon in
 * the icon library has both a primary and a secondary color. These can be
 * set individually by using the `color` and `fill` CSS properties, respectively.
 * For example, this CSS:
 *
 * .icon {
 *   color: red;
 *   fill: blue;
 * }
 *
 * would make the icon use red for its primary color and blue for its
 * secondary color.
 */
export default class SpriteIcon extends PureComponent {
  static propTypes = {
    icon: PropTypes.string.isRequired,

    // allows you to override the viewBox horizontal alignment for the SVG element
    minX: PropTypes.number,

    // allows you to override the viewBox vertical alignment for the SVG element
    minY: PropTypes.number,

    // allow fully overriding the SVG element's viewBox (typically not needed)
    viewBox: PropTypes.string,

    // Accessibility props
    title: PropTypes.string,
    description: PropTypes.string,

    className: PropTypes.string,
  };

  static defaultProps = {
    minX: 0,
    minY: 0,
  };

  state = {
    id: this.getUniqueId(),
    iconInfo: null,

    useUseTags: true,
    spriteElement: null,
  };

  static lastId = 0;

  static globalSvg = null;
  static globalInfos = null;

  static init = once(() => {
    // The most reliable way to get and use a sprite is to inline it somewhere in your document.
    // CORS doesn't apply to XML stuff, I guess. It's weird.
    const spriteFetch = fetch(`${LIBRARY_PATH}/svg/sprite/icons.svg`, {
      method: 'GET',
    })
      .then(response => response.text())
      .then(svg => {
        const div = document.createElement('div');
        div.innerHTML = svg;
        SpriteIcon.globalSvg = document.head.appendChild(div.firstChild);
      })
      .catch(err => {
        // There's nothing we can really do here, if we fail to get
        // the SVG element, we cant embed it. The browser will not
        // fail or have problems if a referenced SVG element from
        // the info metadata below does not exist. It simply wont
        // render an icon.
        return err.body;
      });

    // We also download the icon metadata once up front and use it throughout.
    const infoFetch = fetch(`${LIBRARY_PATH}/json/icons.json`, {
      method: 'GET',
    })
      .then(response => response.json())
      .then(info => {
        SpriteIcon.globalInfos = info.icons;
      })
      .catch(err => {
        // This will let us handle the failed response more gracefully
        // below.
        return Promise.reject(err.body);
      });

    return [spriteFetch, infoFetch];
  });

  getUniqueId() {
    SpriteIcon.lastId++;
    return SpriteIcon.lastId;
  }

  constructor(props) {
    super(props);

    const [spriteFetch, infoFetch] = SpriteIcon.init();

    Promise.all([spriteFetch, infoFetch]).catch(e => console.error(e));

    if (bowser.msie) {
      if (SpriteIcon.globalSvg) {
        this.state.useUseTags = false;
        this.state.spriteElement = SpriteIcon.globalSvg;
      } else {
        spriteFetch
          .then(() => {
            this.setState({ useUseTags: false, spriteElement: SpriteIcon.globalSvg });
          })
          .catch(console.error);
      }
    }

    const initIconInfo = (icon, synchronous) => {
      const info = SpriteIcon.globalInfos.find(info => info.name === icon);

      // If we could not find an icon that matches the name specified then
      // fail here gracefully rather than throwing an error below.
      if (!info) {
        return;
      }

      if (synchronous) {
        this.state.iconInfo = info;
      } else {
        this.setState({ iconInfo: info });
      }

      if (info.metadata?.deprecated) {
        let msg = `Icon '${info.name}' is deprecated!`;
        if (typeof info.metadata.deprecated === 'string') {
          msg += ` Use '${info.metadata.deprecated}' instead.`;
        }

        console.warn(msg);
      }
    };

    if (SpriteIcon.globalInfos) {
      initIconInfo(props.icon, true);
    } else {
      infoFetch
        .then(() => {
          initIconInfo(this.props.icon, false);
        })
        .catch(console.error);
    }
  }

  getAccessibility(iconInfo) {
    const title = this.props.title ?? iconInfo.metadata.title;
    const description = this.props.description || iconInfo.metadata.description;

    const titleId = `wiw-icon-title-${this.state.id}`;
    const descriptionId = `wiw-icon-desc-${this.state.id}`;

    const labels = [];
    if (title) {
      labels.push(titleId);
    }
    if (description) {
      labels.push(descriptionId);
    }

    const hasAria = title || description;

    return {
      title: title,
      description: description,
      titleId: titleId,
      descriptionId: descriptionId,
      labelledBy: hasAria ? labels.join(' ') : null,
    };
  }

  /* istanbul ignore next */
  getRawSVGString() {
    // You'd think you could just use innerHTML, but no, IE doesn't support it on SVGs.
    // Creates a new array from the values of a nodeList since the forEach method is not available in ie11 booooo. Then serializes appropriately.
    return [].slice
      .call(this.state.spriteElement.querySelector(`symbol[id="wiw-icon-${this.props.icon}"]`).childNodes)
      .map(item => new XMLSerializer().serializeToString(item))
      .join('');
  }

  render() {
    const info = this.state.iconInfo || {
      width: 0,
      height: 0,
      metadata: {},
    };
    const accessibility = this.getAccessibility(info);

    // minX and minY match svg viewBox nomenclature https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/viewBox
    const { minX, minY, viewBox, ...svgProps } = this.props; // svgProps = all other props (except minX, minY, viewBox)

    const svgViewBox =
      viewBox !== null && viewBox !== undefined ? viewBox : `${minX} ${minY} ${info.width} ${info.height}`;

    return (
      <svg
        {...svgProps} // pass down any other props
        viewBox={svgViewBox}
        className={classnames(`wiw-icon wiw-${this.props.icon}`, this.props.className)}
        aria-labelledby={accessibility.labelledBy}
        focusable="false" // for IE
        // override so we don't pass through to DOM
        icon={null}
        description={null} // eslint-disable-line
      >
        {accessibility.title && <title id={accessibility.titleId}>{accessibility.title}</title>}
        {accessibility.description && <desc id={accessibility.descriptionId}>{accessibility.description}</desc>}

        {this.state.useUseTags ? (
          <use xlinkHref={`#wiw-icon-${this.props.icon}`} />
        ) : (
          <g
            dangerouslySetInnerHTML={{
              __html: this.getRawSVGString(),
            }}
          />
        )}
      </svg>
    );
  }
}
