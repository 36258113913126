import EntityState from 'data/EntityState';
import { cancelAccount } from 'data/account/actions/cancelAccount';
import { createChildAccount } from 'data/account/actions/createChildAccount';
import fetchAccountBySubdomain from 'data/account/actions/fetchAccountBySubdomain';
import { fetchAccounts } from 'data/account/actions/fetchAccounts';
import { fetchCurrentAccount } from 'data/account/actions/fetchCurrentAccount';
import hibernateAccount from 'data/account/actions/hibernateAccount';
import persistAccount from 'data/account/actions/persistAccount';
import { removeAccount } from 'data/account/actions/removeAccount';
import updateAccountSettings from 'data/account/actions/updateAccountSettings';
import Account from 'data/account/model';
import fetchLogin from 'data/login/actions/fetchLogin';
import { hibernateSubscription } from 'data/stripe/subscriptions/actions/hibernateSubscription';
import { updateSubscription } from 'data/stripe/subscriptions/actions/updateSubscription';
import generateThunkReducers from 'data/util/generateThunkReducers';
import { mergeEntityItems } from 'data/util/sliceHelpers';
import { toEntityMap } from 'shared/util/toEntityMap';

import { createSlice } from '@reduxjs/toolkit';

const initialState = new EntityState<Account>();

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    receiveAccounts: (state, action): EntityState<Account> => {
      return state.mergeDeep({
        items: state.items.mergeDeep(toEntityMap(action.payload, Account)),
      });
    },
    updateAccount: (state, action): EntityState<Account> => {
      return state.mergeDeep({
        items: state.items.set(action.payload.id, new Account(action.payload)),
      });
    },
    createAccount: (state, action): EntityState<Account> => {
      return state.mergeDeep({
        items: state.items.set(action.payload.id, new Account(action.payload)),
      });
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchCurrentAccount.fulfilled, (state, action) => {
        return mergeEntityItems(state, action.payload.account, Account);
      })
      .addCase(fetchAccounts.fulfilled, (state, action) => {
        let result = mergeEntityItems(state, action.payload.account, Account);
        if (action.payload.accounts) {
          // if we have child accounts we need to also add those to the redux store
          result = mergeEntityItems(result, action.payload.accounts, Account);
        }
        return result;
      })
      .addCase(fetchAccountBySubdomain.fulfilled, (state, action) => {
        return mergeEntityItems(state, action.payload.account, Account);
      })
      .addCase(fetchLogin.fulfilled, (state, action) => {
        return mergeEntityItems(state, action.payload.accounts, Account);
      })
      .addCase(persistAccount.fulfilled, (state, action) => {
        return mergeEntityItems(state, action.payload.account, Account);
      })
      .addCase(createChildAccount.fulfilled, (state, action) => {
        return mergeEntityItems(state, action.payload.account, Account);
      })
      .addCase(hibernateAccount.fulfilled, (state, action) => {
        return mergeEntityItems(state, action.payload.account, Account);
      })
      .addCase(updateAccountSettings.fulfilled, (state, action) => {
        return mergeEntityItems(state, action.payload, Account);
      })
      .addCase(removeAccount.fulfilled, (state, action) => {
        return state.merge({
          items: state.items.delete(action.meta.arg),
        });
      })
      .addCase(cancelAccount.fulfilled, (state, action) => {
        return state.merge({
          items: state.items.delete(action.payload),
        });
      })
      .addCase(updateSubscription.fulfilled, (state, action) => {
        return state.merge({
          items: state.items.update(action.payload.account.id, account => {
            return account.mergeDeep(action.payload.account);
          }),
        });
      })
      .addCase(hibernateSubscription.fulfilled, (state, action) => {
        return state.merge({
          items: state.items.update(action.payload.account.id, account => {
            return account.mergeDeep(action.payload.account);
          }),
        });
      });
    generateThunkReducers(builder, 'account');
  },
});

export default accountSlice.reducer;
export const { updateAccount } = accountSlice.actions;
