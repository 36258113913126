import {
  BILLING_DIALOG,
  CLEAR_PLAN_SELECTIONS,
  HANDLE_ENTERPRISE_CONTACT_US,
  SELECT_PLAN,
  SET_BILLING_TYPE,
  SET_SELECTED_PRODUCT_LINE,
  SET_SHOW_ALL_FEATURES,
  UPDATE_BILLING_DIALOG,
  UPDATE_PLAN_DIALOG,
} from 'billing/actions/types';
import { closeDialog, openDialog, updateDialog } from 'dialogs/actions';

export * from 'billing/actions/types';

export const openUpdateBillingDialog = openDialog.bind(null, UPDATE_BILLING_DIALOG);
export const closeUpdateBillingDialog = closeDialog.bind(null, UPDATE_BILLING_DIALOG);

export const openUpdatePlanDialog = openDialog.bind(null, UPDATE_PLAN_DIALOG);
export const closeUpdatePlanDialog = closeDialog.bind(null, UPDATE_PLAN_DIALOG);

export const openBillingDialog = openDialog.bind(null, BILLING_DIALOG);
export const updateBillingDialog = updateDialog.bind(null, BILLING_DIALOG);
export const closeBillingDialog = closeDialog.bind(null, BILLING_DIALOG);

export { updateBilling } from 'billing/actions/updateBilling';
export { updatePlan } from 'billing/actions/updatePlan';

export function clearPlanSelections() {
  return {
    type: CLEAR_PLAN_SELECTIONS,
  };
}

export function selectPlan(plan, productLine) {
  return {
    type: SELECT_PLAN,
    planId: plan ? plan.id : null,
    productLine: productLine,
  };
}

export function setBillingType(value) {
  return {
    type: SET_BILLING_TYPE,
    payload: value,
  };
}

export function setShowAllFeatures(value, productLine) {
  return {
    type: SET_SHOW_ALL_FEATURES,
    value: value,
    productLine: productLine,
  };
}

export function setSelectedProductLine(productLine) {
  return {
    type: SET_SELECTED_PRODUCT_LINE,
    payload: productLine,
  };
}

export function handleEnterpriseContactUs(appComponent, target) {
  return {
    type: HANDLE_ENTERPRISE_CONTACT_US,
    payload: {
      appComponent: appComponent ? appComponent : 'Plans',
      target: target ? target : 'pricing2021',
    },
  };
}
