import {
  type FormState,
  type RegisterOptions,
  type UseControllerProps,
  type UseFormRegisterReturn,
  useFormContext,
} from 'react-hook-form';

import useDisabledBy from 'shared/form/hooks/useDisabledBy';
import useMapCustomValidators from 'shared/form/hooks/useMapCustomValidators';
import type { InputProps } from 'shared/form/types/InputProps';
import type { TextareaProps } from 'shared/form/types/TextareaProps';

type ControllerKeysType = keyof { [key: string]: UseControllerProps };
const ControllerKeys: ControllerKeysType[] = ['name', 'defaultValue', 'shouldUnregister'];

export type TransformedOptions = {
  [key: ControllerKeysType]: any;
};

type OptionKeysType = keyof { [key: string]: RegisterOptions };

export const ValidationKeys: OptionKeysType[] = ['required', 'min', 'max', 'minLength', 'maxLength', 'pattern'];
export const OptionKeys: OptionKeysType[] = [
  'valueAsNumber',
  'valueAsDate',
  'setValueAs',
  'disabled',
  'onChange',
  'onBlur',
  'value',
  'defaultValue',
  'shouldUnregister',
  'deps',
  ...ValidationKeys,
];

type UseRegisterReturn = {
  field: UseFormRegisterReturn;
  formState: FormState<any>;
};

export function useInputRegister(props: Record<string, InputProps<any> | TextareaProps<any>>): UseRegisterReturn {
  const formContext = useFormContext();
  const options: TransformedOptions = useDisabledBy(props, {});
  const rules = useMapCustomValidators(props);

  if (props.disabled) {
    options.disabled = true;
  }

  /**
   * if props has a key that is in the OptionKeys array, then add it to the options object
   * if the key is 'validate', iterate over the object and add each key not found in OptionKeys to the validate object
   */
  Object.keys(props).forEach((key: string): void => {
    if (ControllerKeys.includes(key)) {
      options[key] = props[key];
      return;
    }

    if (OptionKeys.includes(key)) {
      options[key] = props[key];
    }
  });

  return {
    field: formContext.register(props.name, {
      ...options,
      ...rules,
    }),
    formState: formContext.formState,
  };
}
