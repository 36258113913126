import BaseModel from 'data/BaseModel';

export interface FilesAvailable {
  paper_checks: boolean;
  cash_requirements_report: boolean;
  current_payroll_to_date: boolean;
  current_year_to_date: boolean;
}

export default class Files extends BaseModel<FilesAvailable>({
  paper_checks: false,
  cash_requirements_report: false,
  current_payroll_to_date: false,
  current_year_to_date: false,
}) {
  isAvailable() {
    return (
      this.paper_checks || this.cash_requirements_report || this.current_payroll_to_date || this.current_year_to_date
    );
  }
}
