import { getAccountPlan } from 'data/account/selectors/accountPlan';
import User from 'data/user/model';
import { getAuthAccount, getAuthUser } from 'shared/auth/selectors';
import Features from 'shared/features';

import { createSelector } from '@reduxjs/toolkit';

const featureRoleAccess = {
  [Features.TASKS]: User.RoleMappings.SUPERVISE,
  [Features.OPENSHIFT_APPROVAL]: User.RoleMappings.SUPERVISE,
};

export const shouldBeUpsold = createSelector(
  [getAuthAccount, getAccountPlan, getAuthUser, (_, feature: string) => feature],
  (account, plan, user, feature) => {
    if (!account || !plan || !user) {
      return false;
    }

    const userHasAccess = user.can(featureRoleAccess[feature]) || user.isAdmin();

    return userHasAccess && !account.hasFeature(feature);
  },
);
