import BaseModel from 'data/BaseModel';
import { type ISODateTime, StringDateTimeSchema } from 'shared/zod-schemas';

import { z } from 'zod';

export class SalariedSettings extends BaseModel<SalariedSettingsType>({
  id: '',
  userId: '',
  accountId: '',
  isClocksIn: false,
  typicalWorkDays: 0,
  typicalWorkStart: '',
  typicalWorkEnd: '',
  effectiveStartDate: '' as ISODateTime,
  effectiveEndDate: null,
  createdAt: '' as ISODateTime,
  createdBy: '',
  updatedAt: '' as ISODateTime,
  updatedBy: '',
}) {
  static SUNDAY = 1 << 6;
  static MONDAY = 1 << 5;
  static TUESDAY = 1 << 4;
  static WEDNESDAY = 1 << 3;
  static THURSDAY = 1 << 2;
  static FRIDAY = 1 << 1;
  static SATURDAY = 1 << 0;

  // This is the most common scenario, so we provide a simple constant.
  static MTWHF =
    SalariedSettings.MONDAY |
    SalariedSettings.TUESDAY |
    SalariedSettings.WEDNESDAY |
    SalariedSettings.THURSDAY |
    SalariedSettings.FRIDAY;

  constructor(data: unknown) {
    super(SalariedSettingsSchema.parse(data));
  }

  setWeekday(this: SalariedSettings, weekdayMask: number) {
    const newWorkDays = this.typicalWorkDays | weekdayMask;
    if (newWorkDays < 0 || newWorkDays > 127) {
      throw new Error(`The provided weekday mask (${weekdayMask}) results in an invalid value for 'typicalWorkDays'.`);
    }

    return this.set('typicalWorkDays', newWorkDays);
  }

  unsetWeekday(this: SalariedSettings, weekdayMask: number) {
    const newWorkDays = this.typicalWorkDays & ~weekdayMask;
    if (newWorkDays < 0 || newWorkDays > 127) {
      throw new Error(`The provided weekday mask (${weekdayMask}) results in an invalid value for 'typicalWorkDays'.`);
    }

    return this.set('typicalWorkDays', newWorkDays);
  }
}

export const SalariedSettingsSchema = z.object({
  id: z.string(),
  userId: z.string(),
  accountId: z.string(),
  isClocksIn: z.boolean(),
  /** typicalWorkDays is a bit mask where each bit represents a weekday (SMTWHFS) */
  typicalWorkDays: z.number().min(0).max(127),
  typicalWorkStart: z.string(),
  typicalWorkEnd: z.string(),
  effectiveStartDate: StringDateTimeSchema,
  effectiveEndDate: StringDateTimeSchema.nullable(),
  createdAt: StringDateTimeSchema,
  createdBy: z.string(),
  updatedAt: StringDateTimeSchema,
  updatedBy: z.string(),
});

export type SalariedSettingsType = z.infer<typeof SalariedSettingsSchema>;
