import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import WithParam from 'billing/util/WithParam';
// Selectors
import { getAccountPlan } from 'data/account/selectors';
import { fetchFreeTrialStatus } from 'data/freetrial/actions/fetchFreeTrialStatus';
import FreeTrial from 'data/freetrial/model';
import getFreeTrialStatus from 'data/freetrial/selectors/getFreeTrialStatus';
import { getAuthAccount, getAuthUser } from 'shared/auth/selectors';
import { Actions } from 'shared/experiment/constants';
// Actions
import { closeUpgradePopover } from 'shared/upsell/actions/dialogs';
import Mercury, { GROWTH_UPSELL } from 'shared/util/mercury';
import { SmartLink } from 'shared/util/redirect';

import { bindActionCreators } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import moment from 'moment-timezone';
import { Popover, PopoverBody } from 'reactstrap';

import 'shared/upsell/assets/styles/upgradePopover.scss';
import calendarImg from 'styles/assets/img/new-schedule.svg';

export class UpgradePopover extends Component {
  static COOKIE = 'navUpsellPopover';

  state = {
    display: false,
  };

  static propTypes = {
    account: PropTypes.object.isRequired,
    accountPlan: PropTypes.object.isRequired,
    closeUpgradePopover: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    freeTrialStatus: PropTypes.object.isRequired,
    fetchFreeTrialStatus: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { account, accountPlan, user } = this.props;
    const createdAt = new moment(account.created_at);
    const now = moment();
    const cookieExpired = this.cookieIsOldOrUnset();

    this.props.fetchFreeTrialStatus().then(() => {
      if (
        accountPlan.isFreemium() &&
        user.canManage() &&
        now.diff(createdAt, 'days') >= 2 &&
        cookieExpired &&
        !this.excludedRoute() &&
        this.props.freeTrialStatus.status === FreeTrial.STATUS.UNAVAILABLE
      ) {
        this.setCookie();
        this.trackUpsell(Actions.MODAL_SHOWN);
        this.setState({ display: true });
      } else {
        // Be sure to always close, otherwise the dialog is registered
        // as open which prevents notices from showing.
        this.props.closeUpgradePopover();
      }
    });
  }

  trackUpsell = (action, attributes) => {
    const fields = {
      action: action,
      featureRequested: WithParam.fromString('navUpsellPopover'),
      ...attributes,
    };

    Mercury.track(GROWTH_UPSELL, fields);
  };

  cookieIsOldOrUnset() {
    const cookie = Cookies.get(UpgradePopover.COOKIE);

    if (cookie !== undefined) {
      const cookieTime = new moment(cookie);
      const now = new moment();
      return cookieTime.isBefore(now);
    }
    return true;
  }

  setCookie() {
    const cookieTime = new moment();

    cookieTime.add('15', 'days');
    Cookies.set(UpgradePopover.COOKIE, cookieTime.toISOString());
  }

  excludedRoute() {
    const { account, location } = this.props;
    const excludedRoutes = [account.billingUrl()];
    return excludedRoutes.includes(location.pathname);
  }

  onUpgradeNow = () => {
    this.trackUpsell(Actions.CLICKED_UPGRADE);
  };

  onNoThanks = () => {
    this.trackUpsell(Actions.CLICKED_CLOSE);
    this.props.closeUpgradePopover();
  };

  render() {
    const { account, user } = this.props;
    return (
      this.state.display && (
        <Popover className="nav-upgrade-popover" placement="bottom-start" target="avatarmenu" delay={0} isOpen>
          <PopoverBody style={{ minWidth: '250px' }}>
            <div>
              <img alt="calendar" src={calendarImg} />
            </div>
            <div>
              <p>Hey {user.first_name}, you're currently on a limited free account.</p>
            </div>
            <div>
              <span>Unlock limitless scheduling and more great features!</span>
            </div>
            <div className="upsell-btn-container">
              <SmartLink to={account.plansUrl()} className="btn btn-primary" onClick={this.onUpgradeNow}>
                UPGRADE NOW
              </SmartLink>
              <button type="button" className="btn btn-secondary" onClick={this.onNoThanks}>
                NO, THANKS
              </button>
            </div>
          </PopoverBody>
        </Popover>
      )
    );
  }
}

export const Connected = connect(
  (state, props) => ({
    account: getAuthAccount(state),
    accountPlan: getAccountPlan(state, props),
    user: getAuthUser(state),
    location: props.location,
    freeTrialStatus: getFreeTrialStatus(state),
  }), // State coming from redux
  dispatch =>
    bindActionCreators(
      {
        closeUpgradePopover,
        fetchFreeTrialStatus,
      },
      dispatch,
    ),
)(UpgradePopover);

export default withRouter(Connected);
