import { lazy } from 'react';

const CreateRequestDialog = lazy(() => import('./CreateRequestDialog'));
const ExportTimeOffDialog = lazy(() => import('./ExportTimeOffDialog'));

// Identifiers for each dialog, MUST be unique among all dialogs
export const CREATE_REQUEST_DIALOG = 'CREATE_REQUEST_DIALOG';
export const CREATE_EXPORT_DIALOG = 'CREATE_EXPORT_DIALOG';

const dialogs = {
  [CREATE_REQUEST_DIALOG]: CreateRequestDialog,
  [CREATE_EXPORT_DIALOG]: ExportTimeOffDialog,
};

export default dialogs;
