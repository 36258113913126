import { Fragment, type PropsWithChildren, memo, useEffect, useMemo } from 'react';

import { mustGetAccountPlan } from 'data/account/selectors';
import { openDialog } from 'dialogs';
import { VERIFY_ACCOUNT_LOCATION_DIALOG } from 'settings/dialogs';
import { getAuthAccount, getAuthUser } from 'shared/auth/selectors';
import {
  SEEN_VERIFY_ACCOUNT_LOCATION_DIALOG_SESSION_KEY,
  getUserItem,
  setUserItem,
} from 'shared/util/userSessionStorage';
import { useWiwDispatch, useWiwSelector } from 'store';

const VerifyAccountLocationGate = memo(function VerifyAccountLocationGate({ children }: PropsWithChildren<any>) {
  const dispatch = useWiwDispatch();
  const account = useWiwSelector(getAuthAccount);
  const accountPlan = useWiwSelector(mustGetAccountPlan);
  const authUser = useWiwSelector(getAuthUser);

  const hasSeenVerifyAccountLocationDialog = useMemo(
    () => !!getUserItem(SEEN_VERIFY_ACCOUNT_LOCATION_DIALOG_SESSION_KEY, false),
    [],
  );

  const shouldShowDialog = useMemo(() => {
    const userCanEditLocation = authUser.isAdmin();
    const hasAddress = !!account.place?.address;
    const accountMustConfirmLocation =
      (account.business_address_confirmed === false || !hasAddress) &&
      account.isEligibleForBillingEngine5Migration(accountPlan);

    return !hasSeenVerifyAccountLocationDialog && userCanEditLocation && accountMustConfirmLocation;
  }, [hasSeenVerifyAccountLocationDialog, authUser, account, accountPlan]);

  useEffect(() => {
    if (shouldShowDialog) {
      dispatch(openDialog(VERIFY_ACCOUNT_LOCATION_DIALOG, { isOpenedOnLogin: true }));
      setUserItem(SEEN_VERIFY_ACCOUNT_LOCATION_DIALOG_SESSION_KEY, true);
    }
  }, [dispatch, shouldShowDialog]);

  return <Fragment>{children}</Fragment>;
});

export default VerifyAccountLocationGate;
