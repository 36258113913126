import type Shift from 'data/shift/model';
import Monolith from 'shared/api/monolith';
import { createWiwAsyncThunk } from 'store';

type DeleteShiftChainArgs = {
  shift: Shift;
  message?: string;
};

export default createWiwAsyncThunk<any, DeleteShiftChainArgs>(
  'shiftChain/delete',
  async (args, { rejectWithValue }) => {
    try {
      return await Monolith.query({ chain: null, message: args.message }).delete(`/shifts/${args.shift.id}`);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
