import { getLocale } from './locale';

import map from 'lodash/map';
import uniqBy from 'lodash/uniqBy';

export const DEFAULT_CURRENCY = 'USD';

export const getNumberFormat = Intl.NumberFormat;

const currencyList = {
  US$: 'USD',
  CA$: 'CAD',
  MX$: 'MXN',
  AU$: 'AUD',
  '£': 'GBP',
  '€': 'EUR',
  '¥': 'JPY',
  ƒ: 'ANG',
  R: 'ZAR',
  kr: 'DKK',
  NOK: 'NOK',
  SEK: 'SEK',
  CHF: 'CHF',
  '₡': 'CRC',
  '₹': 'INR',
  QR: 'QAR',
  Rp: 'IDR',
  SR: 'SAR',
  AED: 'AED',
  PHP: 'PHP',
  CNY: 'CNY',
  // UNSUPPORTED: '¤',
};

const translateCurrency = symbol => currencyList[symbol] || symbol;

export const getCurrency = acct => {
  if (!acct) return DEFAULT_CURRENCY;
  const curr = translateCurrency(acct.getSettings('schedule.currency', DEFAULT_CURRENCY));
  if (isValidCurrencyCode(curr)) {
    return curr;
  }
  return DEFAULT_CURRENCY;
};

export const isValidCurrencyCode = currency => {
  if (currency.length !== 3) return false;
  try {
    const info = getCurrencyInfo(currency);
    return info.code !== info.name;
  } catch (e) {
    console.warn('Invalid currency code:', currency);
    return false;
  }
};

export const getCurrencyFormatter = (intl, params = {}) => {
  const { locale, currency } = intl;
  return getNumberFormat(locale, {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'symbol',
    ...params,
  });
};

export const formatNumber = (number, i18nContext, params = {}) => {
  return getNumberFormat(i18nContext.locale, params).format(number);
};

export const formatCurrency = (amount, i18nContext, options) => {
  return getCurrencyFormatter(i18nContext, options).format(amount);
};

export const getCurrencyInfo = (currency, i18nContext) => {
  const intl = i18nContext || { locale: getLocale(), currency };
  const symbol = getCurrencyFormatter(intl)
    .format(0)
    .replace(/[0-9.,]/g, '')
    .trim();
  const name = getCurrencyFormatter(intl, { currencyDisplay: 'name' })
    .format(0)
    .replace(/[0-9.,]/g, '')
    .trim();
  return {
    symbol,
    name,
    code: currency.toUpperCase(),
  };
};

export const getCurrencyList = additional => {
  let list = currencyList;
  if (additional && isValidCurrencyCode(additional)) {
    list = { ...list, [additional]: additional };
  }
  return uniqBy(
    map(list, (currency, key) => {
      const info = getCurrencyInfo(currency);
      return {
        ...info,
        key,
      };
    }),
    'code',
  );
};
