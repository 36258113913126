import type { ValidationRule } from 'react-hook-form';

import getValueAndMessage from 'shared/form/util/getValueAndMessage';

export default (config: ValidationRule<boolean | string | number>) => (value: any) => {
  //@ts-ignore
  const { value: maxPrecision, message } = getValueAndMessage(config);
  if (!value || !maxPrecision) {
    return true;
  }

  const numParts = value.toString().split('.');
  return numParts.length < 2 || numParts[1].length <= maxPrecision || message;
};
