import { useLDFlag } from 'data/LD/selectors/getLDFlag';
import type { FC } from 'react';
import { Redirect } from 'react-router-dom';
import AutoImportOnboarding from 'scheduler/modules/auto-import-onboarding';

export default function ShouldFocusedOnboarding(ComposedComponent: FC) {
  return function ShouldFocusedOnboarding(props?: any) {
    const cat2493 = useLDFlag('cat-2493-focused-onboarding');
    const cat2545 = useLDFlag('cat-2545-auto-import-v-2');

    if (cat2493) {
      return <ComposedComponent {...props} />;
    }

    if (cat2545) {
      return <AutoImportOnboarding {...props} />;
    }

    return <Redirect to="/scheduler" />;
  };
}
