import type { PolicyResponse } from 'data/timeOffAccrual/types/common';
import type { DataResponse } from 'data/types';
import Scheduling from 'shared/api/scheduling';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

export interface CreatePolicyArgs {
  userIds: string[];
  name: string;
  requestTypeId: string;
  isActive: boolean;
  isArchived: boolean;
}

export const createPolicy = createWiwAsyncThunk<DataResponse<PolicyResponse>, CreatePolicyArgs>(
  'timeOff/policies/createPolicy',
  async (policyArgs, { rejectWithValue }) => {
    try {
      return await Scheduling.post(policyArgs, '/timeoff/policies');
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
