import { deleteShift } from 'data/shift/actions/deleteShift';
import { persistShift } from 'data/shift/actions/persistShift';
import Shift from 'data/shift/model';
import { shiftSlice } from 'data/shift/reducer';
import { getTagsForShift } from 'data/tag/selectors';
import { replaceShiftTaskListsStore } from 'data/tasks/actions/persistScheduledShiftTaskList';
import { errorNotice } from 'notices';
import { getAuthAccount } from 'shared/auth/selectors';
import { posthogCapture } from 'shared/vendor/posthog';
import { getTaskListIdsForShiftId } from 'tasks/selectors/ScheduledTaskListSelectors';

import { Map } from 'immutable';

function changeInstances(shift, change) {
  return shift.set('instances', shift.instances + change);
}

export const handleShiftDrag = (originalShift, newShift, dropEffect, allShifts = new Map()) => {
  return async (dispatch, getState) => {
    const state = getState();
    const currentAccount = getAuthAccount(state);

    if (originalShift.weakEquals(newShift, ['published'])) {
      return Promise.resolve(newShift);
    }

    // This function does several Promisey things, so we want to
    // return a compound promise that will wait on all of them.
    // This just makes that easier.
    const promises = [];
    const dispatchAndSave = action => {
      const promise = dispatch(action);
      promises.push(promise);

      return promise;
    };

    const tagsEnabled = currentAccount.getSettings('schedule.worktags');
    const tasksEnabled = currentAccount.getSettings('tasks.enabled');

    const originalShiftTags = tagsEnabled ? getTagsForShift(state, originalShift.id) : [];
    const originalShiftTasks = tasksEnabled ? getTaskListIdsForShiftId(originalShift.id)(state) : [];

    let openshiftToMergeInto = null;
    if (newShift.user_id === 0) {
      const hash = newShift.hash(originalShiftTags, originalShiftTasks);
      openshiftToMergeInto = allShifts.delete(newShift.id).find(item => {
        const itemTags = tagsEnabled ? getTagsForShift(state, item.id) : [];
        const itemTasks = tasksEnabled ? getTaskListIdsForShiftId(item.id)(state) : [];

        return item.hash(itemTags, itemTasks) === hash && item.user_id === 0;
      });
    }

    if (openshiftToMergeInto) {
      // Dropped onto an OpenShift cell, and there is a shift for us to merge into.

      if (originalShift === openshiftToMergeInto) {
        // Weird case where we try to merge an instance back into ourselves. Handle
        // this separately so we don't have conflicting updates to the same shift.

        if (dropEffect === 'copy') {
          dispatchAndSave(persistShift(changeInstances(originalShift, 1)));
        }
      } else {
        // Increment the instances of the matching shift.
        dispatchAndSave(persistShift(changeInstances(openshiftToMergeInto, 1)));

        if (dropEffect === 'move') {
          // Do something with the original shift if we have to.
          if (originalShift.hasMultipleInstances()) {
            dispatchAndSave(persistShift(changeInstances(originalShift, -1)));
          } else {
            dispatchAndSave(deleteShift({ shift: originalShift }));
          }
        } else if (dropEffect === 'copy') {
          // do nothing
        }
      }
    } else {
      // No merging to do - just move or copy as usual
      // Set tasks/tags for the backend to have in request body
      if (originalShiftTasks.length) {
        newShift = newShift.set('tasks', originalShiftTasks);
      }
      if (originalShiftTags.length) {
        newShift = newShift.set('tags', originalShiftTags);
      }

      dispatchAndSave(shiftSlice.actions.updateShifts({ shifts: [newShift] }));
      dispatchAndSave(persistShift(newShift))
        .unwrap()
        .then(data => {
          if (dropEffect === 'copy') {
            posthogCapture('Create shift', {
              'Created as open shift': newShift.user_id === 0,
              'Set position': !!newShift.position_id,
              'Set tags': !!newShift.tags?.length,
              'Set unpaid break': !!newShift.break_time,
              'Created from shift template': false,
              'Shifts created': newShift.instances,
              'Created via copy/paste': true,
            });
          }
          // If we assigned an openshift instance on the backend, we want to use taken_shift in the response instead
          const shift = data.taken_shift ? new Shift(data.taken_shift) : new Shift(data.shift);
          if (dropEffect === 'copy' || originalShift.hasMultipleInstances()) {
            // if tasks were created and returned, update the store
            if (data.tasks) {
              dispatchAndSave(replaceShiftTaskListsStore(data.tasks, shift.id));
            }
          }
        })
        .catch(error => {
          console.error(error);
          if (error?.message) {
            dispatch(errorNotice(error.message));
          }

          // if updating fails back out our store updates
          dispatch(shiftSlice.actions.updateShifts({ shifts: [originalShift] }));
        });
    }

    return Promise.all(promises);
  };
};
