import type { ShiftBreak } from 'data/shiftBreak/models';
import V3 from 'shared/api/v3';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

export const deleteShiftBreak = createWiwAsyncThunk<null, ShiftBreak>(
  'shiftBreak/delete',
  async (shiftBreak, { rejectWithValue }) => {
    try {
      return await V3.url(`/shift-breaks/${shiftBreak.id}`).delete();
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
