import type { Conversation } from '@twilio/conversations';
import { isNil } from 'lodash';

import workchat from 'shared/api/workchat';
import mercury from 'shared/util/mercury';
import { type WiwDispatch, createWiwAsyncThunk } from 'store';
import {
  deleteConversation,
  incrementUnreadCountForConversation,
  setUnreadCountForConversation,
  upsertConversation,
} from 'workchat/v2/store/conversations/conversationsReducer';
import { type WorkchatState, setConversation } from 'workchat/v2/store/reducer';
import { getSdkConversationObject } from 'workchat/v2/twilio-objects';

export const loadWorkchatConversationUnreadCount = createWiwAsyncThunk<number, Conversation>(
  'workchatv2/conversation/loadUnreadCount',
  async conversation => {
    return conversation.getUnreadMessagesCount().then(async count => {
      // If count is null, it's a new Conversation
      if (isNil(count)) {
        count = await conversation.getMessagesCount();
      }

      return count;
    });
  },
);

export const incrementUnreadCountNonSelectedConversation = createWiwAsyncThunk<void, string, { state: WorkchatState }>(
  'workchatv2/incrementUnreadCount',
  (conversationId, { getState, dispatch }) => {
    const workchatState = getState();
    if (!workchatState.show || workchatState.conversation !== conversationId) {
      // Don't increment for currently selected Conversation unless Workchat isn't opened
      dispatch(incrementUnreadCountForConversation(conversationId));
    }
  },
);

export const advanceLastReadMessageIndex = (conversationId: string, lastReadMessageIndex?: number) => {
  return (dispatch: WiwDispatch) => {
    dispatch(setUnreadCountForConversation({ conversationId, count: 0 }));
    const conversation = getSdkConversationObject(conversationId);

    if (!isNil(lastReadMessageIndex)) {
      conversation.advanceLastReadMessageIndex(lastReadMessageIndex);
    }
  };
};

export const loadConversationPermissions = createWiwAsyncThunk<
  { conversationId: string; permissions: string[] },
  string
>('workchatv2/conversations/permissions/load', async (conversationId, { rejectWithValue }) => {
  return workchat
    .url(`/channels/${conversationId}/permissions`)
    .get()
    .then((permissions: { permissions: string[]; conversation_permissions: string[] }) => {
      // permissions property is the legacy permissions, so we use conversation_permissions instead
      return { conversationId, permissions: permissions.conversation_permissions };
    })
    .catch((error: any) => rejectWithValue(error));
});

export const renameConversation = createWiwAsyncThunk<Conversation, { conversationId: string; friendlyName: string }>(
  'workchatv2/conversations/rename',
  async ({ conversationId, friendlyName }, { dispatch, rejectWithValue }) => {
    return getSdkConversationObject(conversationId)
      .updateFriendlyName(friendlyName)
      .then((conversation: Conversation) => {
        dispatch(upsertConversation(conversation));
        return conversation;
      })
      .catch((error: any) => rejectWithValue(new Error(error)));
  },
);

export const addParticipantsToConversation = createWiwAsyncThunk<
  any,
  { conversationId: string; participants: number[] }
>('workchatv2/conversations/addParticipants', async ({ conversationId, participants }, { rejectWithValue }) => {
  const conversationSdkObj = getSdkConversationObject(conversationId);

  return Promise.all(
    participants.map(userId => conversationSdkObj.add(`${userId}`, { mercuryMetadata: mercury.identity })),
  )
    .then(() => conversationSdkObj.getParticipantsCount())
    .then(count => {
      if (count > 2) {
        return conversationSdkObj
          .updateAttributes({
            ...(conversationSdkObj.attributes as any),
            external_type: 'group',
          })
          .then((conversation: Conversation) => upsertConversation(conversation));
      }
    })
    .catch((error: any) => rejectWithValue(new Error(error)));
});

export const leaveConversation = createWiwAsyncThunk<void, string>(
  'workchatv2/conversations/leave',
  async (conversationId, { dispatch, rejectWithValue }) => {
    return getSdkConversationObject(conversationId)
      .leave()
      .then(() => {
        dispatch(deleteConversation(conversationId));
        dispatch(setConversation(null));
      })
      .catch((error: any) => rejectWithValue(new Error(error)));
  },
);
