import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getAuthAccount, getAuthUser } from 'shared/auth/selectors';

export default function canAccessFullScheduler(ComposedComponent) {
  class canAccessFullScheduler extends Component {
    static propTypes = {
      account: PropTypes.object,
      authUser: PropTypes.object,
      history: PropTypes.shape({
        push: PropTypes.func.isRequired,
      }).isRequired,
    };

    componentDidMount() {
      this.redirectIfNeeded();
    }

    componentDidUpdate() {
      this.redirectIfNeeded();
    }

    redirectIfNeeded = () => {
      const { account, authUser } = this.props;

      const { is_visible } = account.getSettings('schedule');
      if (!authUser.canSupervise() && !is_visible) {
        this.props.history.push('/myschedule');
        return;
      }
    };

    render() {
      return <ComposedComponent {...this.state} {...this.props} />;
    }
  }

  return connect(state => ({
    account: getAuthAccount(state),
    authUser: getAuthUser(state),
  }))(withRouter(canAccessFullScheduler));
}
