import 'styles/loaders/pulseLoader.scss';

const PulseLoader = () => {
  return (
    <div className="wiw-loader">
      <div className="dot" />
      <div className="bars">
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export default PulseLoader;
