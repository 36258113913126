import { ONE_WEEK, type ReportDialogStep } from 'reporting/util';
import {
  CUSTOM_REPORT_BUILDER_LAST_FETCH,
  CUSTOM_REPORT_BUILDER_SET_ACCOUNT_ID,
  CUSTOM_REPORT_BUILDER_SET_ACTIVE_DIALOG,
  CUSTOM_REPORT_BUILDER_SET_DATASET,
  CUSTOM_REPORT_BUILDER_SET_DATE_RANGE,
  CUSTOM_REPORT_BUILDER_SET_DATE_RANGE_NAME,
  CUSTOM_REPORT_BUILDER_SET_INCLUDE_CHILD_ACCOUNTS,
  CUSTOM_REPORT_BUILDER_SET_NEXT_DIALOG,
  CUSTOM_REPORT_BUILDER_SET_REPORT_SIZE,
  CUSTOM_REPORT_BUILDER_SET_SELECTED_DEFINITION_ID,
  CUSTOM_REPORT_BUILDER_SET_TEMP_ACCOUNT_ID,
  CUSTOM_REPORT_BUILDER_SET_TEMP_COLUMNS,
  CUSTOM_REPORT_BUILDER_SET_TEMP_DATASET,
  CUSTOM_REPORT_BUILDER_SET_TEMP_DATE_RANGE,
  CUSTOM_REPORT_BUILDER_SET_TEMP_DATE_RANGE_NAME,
  CUSTOM_REPORT_BUILDER_SET_TEMP_REPORT_SIZE,
  CUSTOM_REPORT_BUILDER_SET_WIZARD_COMPLETED_ONCE,
} from 'store/action-types';

import type { AnyAction } from '@reduxjs/toolkit';
import { List, Record } from 'immutable';
import { DateTime, Interval } from 'luxon';

interface CustomReportBuilderStateFields {
  accountId?: number;
  activeDialog?: ReportDialogStep;
  dataset?: string;
  dateRange: Interval;
  dateRangeName: string;
  generatedAt: DateTime;
  includeChildAccounts: boolean;
  nextDialog?: ReportDialogStep;
  reportSize?: number;
  tempAccountId?: number;
  tempColumns: List<string>;
  tempDataset?: string;
  tempDateRange?: Interval;
  tempDateRangeName?: string;
  tempReportSize?: number;
  wizardCompletedOnce: boolean;
  selectedDefinitionId?: number;
  lastFetch: DateTime;
}

export class CustomReportBuilderState extends Record<CustomReportBuilderStateFields>({
  accountId: undefined,
  activeDialog: undefined,
  dataset: undefined,
  dateRange: Interval.fromDateTimes(DateTime.now().startOf('day').minus({ weeks: 1 }), DateTime.now().endOf('day')),
  dateRangeName: ONE_WEEK,
  generatedAt: DateTime.invalid('undefined'),
  includeChildAccounts: true,
  nextDialog: undefined,
  reportSize: undefined,
  tempAccountId: undefined,
  tempColumns: List<string>(),
  tempDataset: undefined,
  tempDateRange: undefined,
  tempDateRangeName: undefined,
  tempReportSize: undefined,
  wizardCompletedOnce: false,
  selectedDefinitionId: undefined,
  lastFetch: DateTime.invalid('undefined'),
}) {}

export default function (state = new CustomReportBuilderState(), action: AnyAction) {
  switch (action.type) {
    case CUSTOM_REPORT_BUILDER_SET_WIZARD_COMPLETED_ONCE:
      return state.set('wizardCompletedOnce', action.payload);
    case CUSTOM_REPORT_BUILDER_SET_ACCOUNT_ID:
      return state.set('accountId', action.payload);
    case CUSTOM_REPORT_BUILDER_SET_ACTIVE_DIALOG:
      return state.set('activeDialog', action.payload);
    case CUSTOM_REPORT_BUILDER_SET_DATE_RANGE:
      return state.set('dateRange', action.payload);
    case CUSTOM_REPORT_BUILDER_SET_DATE_RANGE_NAME:
      return state.set('dateRangeName', action.payload);
    case CUSTOM_REPORT_BUILDER_SET_DATASET:
      switch (action.payload) {
        case undefined:
          return state.merge({
            dataset: action.payload,
          });
        case 'shifts':
          return state.merge({
            dataset: action.payload,
          });
        case 'times':
          return state.merge({
            dataset: action.payload,
          });
        case 'shift-history':
          return state.merge({
            dataset: action.payload,
          });
        default:
          throw new TypeError('unknown dataset');
      }
    case CUSTOM_REPORT_BUILDER_SET_NEXT_DIALOG:
      return state.set('nextDialog', action.payload);
    case CUSTOM_REPORT_BUILDER_SET_REPORT_SIZE:
      return state.set('reportSize', action.payload);
    case CUSTOM_REPORT_BUILDER_SET_TEMP_ACCOUNT_ID:
      return state.set('tempAccountId', action.payload);
    case CUSTOM_REPORT_BUILDER_SET_TEMP_COLUMNS:
      return state.set('tempColumns', action.payload);
    case CUSTOM_REPORT_BUILDER_SET_TEMP_DATASET:
      switch (action.payload) {
        case undefined:
          return state.merge({
            tempDataset: action.payload,
          });
        case 'shifts':
          return state.merge({
            tempDataset: action.payload,
          });
        case 'times':
          return state.merge({
            tempDataset: action.payload,
          });
        case 'shift-history':
          return state.merge({
            tempDataset: action.payload,
          });
        default:
          throw new TypeError('unknown dataset');
      }
    case CUSTOM_REPORT_BUILDER_SET_TEMP_DATE_RANGE:
      return state.set('tempDateRange', action.payload);
    case CUSTOM_REPORT_BUILDER_SET_TEMP_DATE_RANGE_NAME:
      return state.set('tempDateRangeName', action.payload);
    case CUSTOM_REPORT_BUILDER_SET_TEMP_REPORT_SIZE:
      return state.set('tempReportSize', action.payload);
    case CUSTOM_REPORT_BUILDER_SET_SELECTED_DEFINITION_ID:
      return state.set('selectedDefinitionId', action.payload);
    case CUSTOM_REPORT_BUILDER_LAST_FETCH: // current state reporting
      return state.set('lastFetch', action.payload);
    case CUSTOM_REPORT_BUILDER_SET_INCLUDE_CHILD_ACCOUNTS:
      return state.set('includeChildAccounts', action.payload);
    default:
      return state;
  }
}
