import type { AvailabilityFields } from 'data/availability/model';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type DeleteAvailabilityArgs = number;

type DeleteAvailabilityResponse = {
  availabilityevents: AvailabilityFields[];
};

export const deleteAvailability = createWiwAsyncThunk<DeleteAvailabilityResponse, DeleteAvailabilityArgs>(
  'availability/delete',
  async (availabilityId, { rejectWithValue }) => {
    try {
      return (await Monolith.url(`/availabilityevents/${availabilityId}`).delete()) as DeleteAvailabilityResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
