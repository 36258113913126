import BaseModel from 'data/BaseModel';

export interface TagFields {
  id: string;
  accountId: string | null;
  name: string;
  deletedAt: string | null;
  createdAt: string | null;
  updatedAt: string | null;
}

// Tags can be assigned to a Shift, ShiftTemplate or User
class Tag extends BaseModel<TagFields>({
  id: '',
  accountId: null,
  name: '',
  createdAt: null,
  deletedAt: null,
  updatedAt: null,
}) {}

export default Tag;
