import EntityBase from 'data/EntityBase';
import type StripePlan from 'data/stripe/plans/model';

import { Map } from 'immutable';

export class StripePlansState extends EntityBase({
  items: Map<StripePlan['id'], StripePlan>(),
  loading: false,
  loaded: false,
  receivedAt: 0,
  error: null,
}) {
  // nothing
}
