import type BenefitRecord from 'data/payrollBenefits/model';
import Payroll from 'shared/api/payroll';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';

import { createAsyncThunk } from '@reduxjs/toolkit';

type DeletePayrollBenefitResponse = {
  message: string;
};

export const deletePayrollBenefit = createAsyncThunk<DeletePayrollBenefitResponse, BenefitRecord>(
  'benefits/delete',
  async (benefit, { rejectWithValue }) => {
    try {
      return (await Payroll.url('/benefits')
        .query({ benefit_id: benefit.id })
        .delete()) as DeletePayrollBenefitResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
