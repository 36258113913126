import classnames from 'classnames';
import { type DebouncedFunc, debounce, isNil, xor } from 'lodash';
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { getAuthUser } from 'shared/auth/selectors';
import FontIcon from 'shared/ui/FontIcon';
import { Tooltip } from 'shared/ui/Tooltip';
import { useWiwDispatch, useWiwSelector } from 'store';
import { parseEmojiFromUnicodeHex } from 'workchat/utils';
import { reactWorkchatMessage } from 'workchat/v2/store/messages/messagesActions';
import { getWorkchatUsers } from 'workchat/v2/store/users/usersSelectors';

import 'workchat/v2/components/MessageV2/MessageReactions/MessageReactions.scss';

const REACTORS_LIST_MAX_LENGTH = 35;

export interface MessageReaction {
  unicode: string;
  userIds: number[];
}

interface MessageReactionsProps {
  messageId: string;
  conversationId: string;
  reactions?: MessageReaction[];
  onAddReaction: () => void;
  alignRight?: boolean;
}

export default function MessageReactions({
  messageId,
  conversationId,
  reactions,
  onAddReaction,
  alignRight,
}: MessageReactionsProps) {
  const dispatch = useWiwDispatch();
  const myUser = useWiwSelector(getAuthUser);
  const users = useWiwSelector(getWorkchatUsers);

  const [reactionMask, setReactionMask] = useState<{ [unicodeKey: string]: 0 | 1 }>({});
  const debounceMap = useRef<{
    [unicodeKey: string]: DebouncedFunc<(param: { nativeEmoji: string; changed: boolean }) => void>;
  }>({});

  // Turn the Reactions Array into a Map for easier access by Unicode Key
  const reactionsMapByUnicode = useMemo(
    () => Object.fromEntries(reactions?.map(reaction => [reaction.unicode, reaction]) || []),
    [reactions],
  );

  // maskedReaction is what we actually display as Chips: Reactions[] => apply Masks => maskedReactions[]
  const maskedReactions = useMemo(() => {
    if (!reactions) {
      return [];
    }

    return reactions.reduce((outReactions, { unicode, userIds }) => {
      if (reactionMask[unicode] === 1 && !userIds?.includes(myUser?.id)) {
        userIds = [...userIds, myUser?.id];
      } else if (reactionMask[unicode] === 0 && userIds.includes(myUser?.id)) {
        userIds = userIds.filter(userId => userId !== myUser?.id);
      }

      if (userIds.length) {
        outReactions.push({ unicode, userIds });
      }

      return outReactions;
    }, [] as MessageReaction[]);
  }, [reactions, myUser, reactionMask]);

  // Effect to renconcile Emoji Mask with new incoming Reactions
  useEffect(() => {
    let hasDiff = false;
    const reconciled = { ...reactionMask };

    Object.keys(reactionMask).forEach(unicode => {
      const reaction = reactionsMapByUnicode[unicode];

      if (reactionMask[unicode] === 1 && reaction?.userIds.includes(myUser?.id)) {
        hasDiff = true;
        delete reconciled[unicode];
      } else if (reactionMask[unicode] === 0) {
        if (!reaction || !reaction?.userIds.includes(myUser?.id)) {
          hasDiff = true;
          delete reconciled[unicode];
        }
      }
    });

    if (hasDiff) {
      setReactionMask(reconciled);
    }
  }, [reactionMask, reactionsMapByUnicode]);

  // Effect to store debounced handlers
  useEffect(() => {
    const reactionKeys = reactions?.reduce((keys, { unicode }) => {
      if (debounceMap.current[unicode]) {
        // Handler exists, skip
        keys.push(unicode);
        return keys;
      }

      debounceMap.current[unicode] = debounce(({ nativeEmoji, changed }) => {
        if (!changed) {
          return;
        }

        return dispatch(
          reactWorkchatMessage({
            messageId,
            conversationId,
            reaction: nativeEmoji,
          }),
        ).catch(() =>
          setReactionMask(reactionMask => {
            // Failed, remove the mask
            const { [unicode]: removed, ...newMask } = reactionMask;
            return newMask;
          }),
        );
      }, 250);

      keys.push(unicode);
      return keys;
    }, [] as string[]);

    // Clean up handlers we no longer need
    const toRemove = xor(reactionKeys, Object.keys(debounceMap.current));
    toRemove.forEach(key => {
      delete debounceMap.current[key];
    });
  }, [reactions]);

  const handleReactionClick = (unicode: string, nativeEmoji: string, userIds: number[]) => {
    return setReactionMask(reactionMask => {
      if (isNil(reactionMask[unicode])) {
        debounceMap.current[unicode]?.({ nativeEmoji, changed: true });
        return { ...reactionMask, [unicode]: userIds.includes(myUser?.id) ? 0 : 1 };
      }

      debounceMap.current[unicode]?.({ nativeEmoji, changed: false });
      // Remove the mask
      const { [unicode]: removed, ...newMask } = reactionMask;
      return newMask;
    });
  };

  const renderReactorList = useCallback(({ userIds, myselfReacted }: { userIds: number[]; myselfReacted: boolean }) => {
    let reactorsLabel: string;
    let overflowLength = 0;

    let reactorNames = userIds
      .map(userId => users.get(userId))
      .filter(user => user && user?.id !== myUser.id)
      .sort((userA, userB) => userA!.first_name.localeCompare(userB!.first_name))
      .map(user => user!.fullName);

    if (reactorNames.length > REACTORS_LIST_MAX_LENGTH) {
      overflowLength = reactorNames.length - REACTORS_LIST_MAX_LENGTH;
      reactorNames = reactorNames.slice(0, REACTORS_LIST_MAX_LENGTH);
    }

    if (myselfReacted) {
      reactorNames = ['You (click to remove)', ...reactorNames];
    }

    if (reactorNames.length <= 2) {
      // format: Anna Smith and John Doe
      reactorsLabel = reactorNames.join(' and ');
    } else if (overflowLength > 0) {
      // format: Anna Smith, John Doe, ..., and 31 others
      reactorsLabel = `${reactorNames.join(', ')} and ${overflowLength} others`;
    } else {
      // format: Anna Smith, John Doe, and Zach Ellis
      reactorNames[reactorNames.length - 1] = `and ${reactorNames[reactorNames.length - 1]}`;
      reactorsLabel = reactorNames.join(', ');
    }

    return reactorsLabel;
  }, []);

  const renderReactionChips = () => {
    return maskedReactions.map(({ unicode, userIds }) => {
      if (!unicode) {
        return;
      }
      const nativeEmoji = parseEmojiFromUnicodeHex(unicode);
      const myselfReacted = userIds.includes(myUser?.id);
      const reactorsLabel = renderReactorList({ userIds, myselfReacted });

      return (
        <Tooltip
          key={unicode}
          multiline
          withArrow
          openDelay={300}
          className="reaction-tooltip"
          label={
            <Fragment>
              <h1 className="reaction-tooltip-emoji ml-auto my-0">{nativeEmoji}</h1>
              <div className="reaction-tooltip-namelist ml-auto">{reactorsLabel}</div>
            </Fragment>
          }
        >
          <button
            type="button"
            className={classnames('reaction-btn', { highlighted: myselfReacted })}
            onClick={() => handleReactionClick(unicode, nativeEmoji, userIds)}
            aria-label={`${nativeEmoji}, left by ${reactorsLabel}`}
          >
            <span className="reaction-emoji">{nativeEmoji}</span>
            <span className="reaction-count">{userIds?.length}</span>
          </button>
        </Tooltip>
      );
    });
  };

  if (!maskedReactions.length) {
    return null;
  }

  return (
    <div className={classnames('MessageReactions', { 'right-aligned': alignRight })}>
      <div className="chips-container">
        {renderReactionChips()}
        <button
          id="add-reaction"
          aria-label="Add a Reaction"
          data-testid="add-reaction"
          type="button"
          className="reaction-btn"
          onClick={onAddReaction}
        >
          <span className="reaction-emoji">
            <FontIcon icon="add-smiling-face" />
          </span>
        </button>
      </div>
    </div>
  );
}
