import type { ValidationRule } from 'react-hook-form';

import { isObject } from 'lodash';

export default (validationData?: ValidationRule) =>
  isObject(validationData) && !(validationData instanceof RegExp)
    ? validationData
    : {
        value: validationData,
        message: '',
      };
