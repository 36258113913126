import useInDialog from 'dialogs/hooks/useInDialog';
import { type CSSProperties, type PropsWithChildren, type ReactNode, useEffect } from 'react';

import {
  type CloseDialogFunction,
  type DialogContextProps,
  dialogOnCloseListeners,
  withDialogContext,
} from 'dialogs/context';
import NoticeList from 'notices/components/list';
import ErrorBoundary from 'shared/ui/ErrorBoundary';
import PulseLoader from 'shared/ui/loaders/PulseLoader';

import { Modal, type ModalRootProps } from '@mantine/core';
import classnames from 'classnames';

export type DialogProps = {
  className?: string;
  width?: number;
  style?: CSSProperties;
  children?: ReactNode;
  loading?: boolean;
  loader?: ReactNode;
  onClose?: CloseDialogFunction; // returning false prevents dialog from closing.
  onDismiss?: () => void; // @deprecated use onClose
  onBeforeClose?: CloseDialogFunction; // @deprecated use onClose
  dismissible?: boolean;
  closeDialog: (name: string) => void;
  name?: string;
};

export type Props = DialogContextProps & DialogProps & Omit<ModalRootProps, keyof DialogProps | 'opened'>;

const defaultProps = {
  width: 400,
  style: {},
  loading: false,
  loader: <PulseLoader />,
  dismissible: true,
};

export function Dialog(props: PropsWithChildren<Props>) {
  props = { ...defaultProps, ...props };
  const dialogContext = useInDialog();

  useEffect(() => {
    if (props.onClose) {
      dialogOnCloseListeners.set(dialogContext.name, props.onClose);
    }

    if (props.onDismiss) {
      dialogOnCloseListeners.set(dialogContext.name, props.onDismiss as CloseDialogFunction);
    }

    if (props.onBeforeClose) {
      dialogOnCloseListeners.set(dialogContext.name, props.onBeforeClose);
    }
  }, []);

  const style: CSSProperties = Object.assign({}, { width: `${props.width}px` }, props.style);
  const { className, dismissible, closeDialog, loading, ...restProps } = props;
  const onClose = dismissible ? (closeDialog ?? dialogContext.close) : () => {};

  return (
    <Modal.Root
      opened
      keepMounted
      data-ignore-outside-clicks
      data-testid={dialogContext.name}
      {...restProps}
      onClose={onClose}
    >
      <Modal.Overlay />
      <ErrorBoundary>
        <Modal.Content>
          <div className={classnames('dialog-kit', className)} style={style}>
            <NoticeList dialog />
            {loading ? props.loader : props.children}
          </div>
        </Modal.Content>
      </ErrorBoundary>
    </Modal.Root>
  );
}

export default withDialogContext(Dialog);
