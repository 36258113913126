import type Shift from 'data/shift/model';
import type { ShiftFields } from 'data/shift/model';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type SplitShiftArgs = {
  shift: Shift;
  split: number;
};

// i have no idea wtf this response is.
type SplitShiftResponse = {
  shifts: ShiftFields[];
};

export const splitShift = createWiwAsyncThunk<SplitShiftResponse, SplitShiftArgs>(
  'shift/split',
  async ({ shift, split }, { rejectWithValue }) => {
    try {
      return (await Monolith.url(`/shifts/${shift.id}/split`).post({ split })) as SplitShiftResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
