import { createSelector } from '@reduxjs/toolkit';
import type Account from 'data/account/model';
import type { Moment } from 'moment-timezone';
import { getCurrentRange } from 'scheduler/selectors/getCurrentViewMode';
import { getSchedulerDate } from 'scheduler/selectors/getSchedulerDate';
import { ViewRange } from 'scheduler/util/view';
import { getAuthAccount } from 'shared/auth/selectors';
import { getMomentDate } from 'shared/util/time';

export type Boundary = {
  start: Moment;
  end: Moment;
};

export const getBoundaries = createSelector([getCurrentRange, getSchedulerDate, state => state], (range: ViewRange, schedulerDate, state): Boundary => {
  switch (range) {
    case ViewRange.WEEK:
      return getWeekBoundaries(state);
    case ViewRange.DAY:
      return getDayBoundaries(state);
    case ViewRange.MONTH:
      return getMonthBoundaries(state);
    case ViewRange.TWO_WEEK:
      return getWeekBoundaries(state, schedulerDate, 2);
    default:
      return getWeekBoundaries(state);
  }
});

export const getDayBoundaries = createSelector(
  [(state, date = getSchedulerDate(state)) => date],
  (schedulerDate: Moment): Boundary => {
    const start = getMomentDate(schedulerDate).startOf('day');
    const end = getMomentDate(start).endOf('day');

    return { start, end };
  },
);

export const getWeekBoundaries = createSelector(
  [
    getAuthAccount,
    (state, date = getSchedulerDate(state)) => date as Moment,
    (state, _date = getSchedulerDate(state), weekCount = 1) => weekCount,
  ],
  (authAccount, date, weekCount = 1) => {
    const weekStart = authAccount.getSettings('schedule.start_of_week', 0);

    let start = date.clone().day(weekStart).startOf('day');
    if (start.isAfter(date)) {
      start = start.clone().subtract(1, 'week');
    }

    const end = start.clone().add(weekCount, 'week').subtract(1, 'days').endOf('day');

    return { start, end };
  },
);

export const getMonthBoundaries = createSelector(
  [getAuthAccount, (state, date = getSchedulerDate(state)) => date],
  (authAccount: Account, date) => {
    const weekStart = authAccount.getSettings('schedule.start_of_week', 0);

    let start = date.clone().startOf('month');
    if (start.clone().day(weekStart).isAfter(start)) {
      start = start.clone().subtract(1, 'week').day(weekStart);
    } else {
      start = start.clone().day(weekStart);
    }
    // Advance the end to the last day of the month to include shifts in trailing weekdays.
    // e.g:
    // [---December---][-------------January-------------]
    // [Sun-30][Mon-31][Tue-1][Wed-2][Thu-3][Fri-4][Sat-5]
    // Add additional days to account for change in the start of week
    const end = date.clone().endOf('month').endOf('week').add(weekStart, 'day');

    // If end boundary as moved into a new month and more than a week in, subtract a week
    if (end.month() !== date.month() && end.date() >= 7) {
      end.subtract(7, 'day');
    }

    return { start, end };
  },
);
