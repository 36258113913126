import { useEffect, useState } from 'react';

import { getLatestDeploymentForEnv } from 'shared/dev/api/actions';
import type { LatestDeployment } from 'shared/dev/types/types';
import { useLoading } from 'shared/hooks/useLoading';
import Environment from 'shared/util/environment';
import { Loader } from '@mantine/core';

import cx from 'classnames';
import FontIcon from 'shared/ui/FontIcon';

import 'shared/dev/styles/DeploymentBadge.scss';

type DeploymentBadgeProps = {
  projectId: number;
};

export function DeploymentBadge({ projectId }: DeploymentBadgeProps) {
  const [loadingDeployment, watch] = useLoading();
  const [latestDeployment, setLatestDeployment] = useState<LatestDeployment | null | false>(null);

  useEffect(() => {
    watch(getLatestDeploymentForEnv(projectId))
      .then(res => setLatestDeployment(res))
      .catch(() => setLatestDeployment(false));
  }, [projectId, watch]);

  if (loadingDeployment || (!latestDeployment && latestDeployment !== false)) {
    return <Loader size="xs" type="dots" />;
  }

  if (latestDeployment === false) {
    return (
      <span className="badge badge-danger">
        <FontIcon icon="warning" /> Project misconfigured, no {Environment.get()} environment found!
      </span>
    );
  }

  const isDeploying = ['running'].includes(latestDeployment.status);
  const badgeClass = cx('btn btn-sm deployment-badge badge text-wrap', {
    'badge-success': ['success', 'created'].includes(latestDeployment.status),
    'badge-danger': ['failed', 'blocked', 'canceled'].includes(latestDeployment.status),
    'badge-warning hint hint--top': ['running'].includes(latestDeployment.status),
  });

  return (
    <a href={latestDeployment.deploy.commit.web_url} className={badgeClass} rel="noreferrer" target="_blank">
      <span aria-label={(isDeploying && 'Deployment in progress!') || ''}>
        {isDeploying && <Loader size="xs" type="dots" />}
        {latestDeployment.environment.name}: {latestDeployment.deploy.ref}
        <FontIcon icon="chevron-right" />
      </span>
    </a>
  );
}
