import type { PayPeriodDataFields } from 'data/payPeriodData/model';
import { request } from 'shared/auth/request';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

export interface PayPeriodDataResponse {
  data: PayPeriodDataFields[];
}

export const fetchPayPeriodData = createWiwAsyncThunk<PayPeriodDataResponse, string>(
  'payPeriodData/fetchPayPeriodData',
  async (id, { rejectWithValue }) => {
    try {
      return await request().get(`/payrolls/${id}/data?noUserObjects=true`);
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
