import QuickBooksCheck from 'integrations/modules/qbo/models/check';
import QuickBooksState from 'integrations/modules/qbo/state';
import { CHECK_QUICKBOOKS, SEND_PAYROLL_QUICKBOOKS } from 'store/action-types';

export default (state = new QuickBooksState(), action: any) => {
  switch (action.type) {
    case CHECK_QUICKBOOKS.FAILURE:
      return state.merge({
        error: action.payload,
        loaded: false,
        loading: false,
      });
    case CHECK_QUICKBOOKS.REQUEST:
      return state.merge({
        loaded: false,
        loading: true,
        receivedAt: 0,
      });
    case CHECK_QUICKBOOKS.SUCCESS:
      return state.merge({
        check: new QuickBooksCheck(action.payload), // Overwrite the previous checks.
        error: null,
        loaded: true,
        loading: false,
        receivedAt: Date.now(),
      });

    // Sending payroll.
    case SEND_PAYROLL_QUICKBOOKS.FAILURE:
      return state.merge({
        error: action.payload,
        loaded: false,
        loading: false,
      });
    case SEND_PAYROLL_QUICKBOOKS.REQUEST:
      return state.merge({
        loaded: false,
        loading: true,
      });
    case SEND_PAYROLL_QUICKBOOKS.SUCCESS:
      return state.merge({
        error: null,
        loaded: true,
        loading: false,
      });
    default:
      return state;
  }
};
