import EntityState from 'data/EntityState';
import { fetchPayPeriodById } from 'data/payPeriod/actions/fetchPayPeriods';
import PayPeriod from 'data/payPeriod/model';
import { mergeEntityItems } from 'data/util/sliceHelpers';

import { createSlice } from '@reduxjs/toolkit';

const initialState = new EntityState();

const payPeriodSlice = createSlice({
  name: 'payPeriod',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchPayPeriodById.fulfilled, (state, action) => {
      return mergeEntityItems(state, action.payload.payroll, PayPeriod);
    });
  },
});

export default payPeriodSlice.reducer;
