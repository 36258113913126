import type { Map } from 'immutable';

/**
 * Pick properties of an object according to the types of their values. For example:
 *
 *     type MyObject = {
 *       a: string,
 *       b: number,
 *       c: boolean,
 *     }
 *
 *     type Picked = PickByValue<MyObject, string | number>;
 *     // {
 *     //   a: string,
 *     //   b: number,
 *     // }
 *
 */
export type PickByValue<Type, ValueType> = Pick<
  Type,
  {
    [Key in keyof Type]: Type[Key] extends ValueType ? Key : never;
  }[keyof Type]
>;

/**
 * Use to make TypeScript verify that a value's type is `never`. The expected
 * use case is to ensure that you have exhaustively handled every case in a
 * union - for example:
 *
 *     const test: 'red' | 'green' | 'blue' = ...;
 *     switch (test) {
 *       case 'red':
 *         return '#ff0000';
 *       case 'green':
 *         return '#00ff00';
 *       default:
 *         assertNever(test); // Type "'blue'" is not assignable to type "never"
 *     }
 */
export function assertNever(_: never): never {
  throw new Error('Reached code that was supposed to be unreachable');
}

// Yoinked this one from Sean. Thanks, Sean!
// See https://github.com/microsoft/TypeScript/issues/17002
/**
 * Wrapper for `Array.isArray` with better readonly type handling.
 *
 * @public
 */
export function isArray<TVal>(list: TVal | Array<TVal>): list is Array<TVal>;
/**
 * Wrapper for `Array.isArray` with better readonly type handling.
 *
 * @public
 */
export function isArray<TVal>(list: TVal | ReadonlyArray<TVal>): list is ReadonlyArray<TVal>;
export function isArray<TVal>(list: TVal | ReadonlyArray<TVal>): list is ReadonlyArray<TVal> {
  return Array.isArray(list);
}

export function assertIsNumber(value: unknown): asserts value is number {
  if (typeof value !== 'number') {
    throw new Error(`Expected a number but got ${value}`);
  }
}

export function assertIsString(value: unknown): asserts value is string {
  if (typeof value !== 'string') {
    throw new Error(`Expected a string but got ${value}`);
  }
}

export interface TypedImmutableMap<T> extends Map<keyof T, T[keyof T]> {
  get<K extends keyof T>(name: K, notSetValue?: T[K]): T[K];
}
