import {
  type ForwardedRef,
  type KeyboardEventHandler,
  type ReactElement,
  type ReactNode,
  type Ref,
  forwardRef,
  useEffect,
} from 'react';
import {
  type DefaultValues,
  type FieldValues,
  FormProvider,
  type SubmitErrorHandler,
  type SubmitHandler,
  type UseFormReturn,
} from 'react-hook-form';

import { DevTool } from '@hookform/devtools';

export type FormProps<T extends FieldValues = FieldValues> = {
  onSubmit: SubmitHandler<T>;
  onKeyDown?: KeyboardEventHandler<HTMLFormElement>;
  onError?: SubmitErrorHandler<T>;
  children: ReactNode;
  defaultValues?: DefaultValues<T>;
  debug?: boolean;
  form: UseFormReturn<T>;
  onValid?: () => void;
  onInvalid?: () => void;
  className?: string;
  id?: string;
};

const defaultProps = {
  debug: false,
};

function Form<T extends FieldValues = FieldValues>(props: FormProps<T>, ref: ForwardedRef<HTMLFormElement>) {
  props = { ...defaultProps, ...props };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { children, debug, form, onValid, onInvalid, onError, ...rest } = props;

  useEffect(() => {
    if (props.form.formState.isValid) {
      onValid?.();
    } else {
      onInvalid?.();
    }
  }, [props.form.formState.isValid]);

  return (
    <FormProvider {...form}>
      <form {...rest} ref={ref} onSubmit={form.handleSubmit(props.onSubmit, props.onError)}>
        {children}
      </form>
      {debug && <DevTool control={form.control} />}
    </FormProvider>
  );
}

export default forwardRef(Form) as <T extends FieldValues = FieldValues>(
  p: FormProps<T> & { ref?: Ref<HTMLFormElement> },
) => ReactElement;
