import type Account from 'data/account/model';
import type Login from 'data/login/model';
import type User from 'data/user/model';
import posthog, { type CaptureOptions, type Properties } from 'posthog-js';

export const posthogClient = posthog.init(CONFIG.VENDORS.POSTHOG.TOKEN, {
  api_host: CONFIG.VENDORS.POSTHOG.HOST,
  session_recording: {
    maskTextSelector: '.sentry-mask, [data-sentry-mask], [data-private]',
  },
});

export const posthogCapture = (
  eventName: string,
  properties: Properties | undefined = {},
  options: CaptureOptions | undefined = {},
) => {
  posthog.capture(eventName, properties, options);
};

export const posthogIdentify = (
  identifier: string,
  userPropertiesToSet: Properties = {},
  userPropertiesToSetOnce: Properties = {},
) => {
  posthog.identify(identifier, userPropertiesToSet, userPropertiesToSetOnce);
};

export const posthogRegister = (superProperties: Properties = {}) => {
  posthog.register(superProperties);
};

export const posthogPersonSet = (properties: Properties = {}, once = false) => {
  if (once) {
    posthog.setPersonProperties({}, properties);
  } else {
    posthog.setPersonProperties(properties);
  }
};

export const setPosthogSuperProperties = (user: User, account: Account, login: Login) => {
  posthogRegister({
    'Account created date': account.created_at,
    'Account ID': account.id,
    'Number of employees': account.ref_employees || null,
    'Person created date': login.created_at,
    'User created date': user.created_at,
    'Free plan adopted date': account.free_plan_adopted_at,
  });
};

export const posthogIncrementSuperProperty = (propertyName: string, inc = 1) => {
  const currentCount = posthog.get_property(propertyName) || 0;
  posthogRegister({ [propertyName]: currentCount + inc });
};

export default {
  posthogCapture,
  posthogIdentify,
  posthogRegister,
  posthogPersonSet,
  posthogIncrementSuperProperty,
};
