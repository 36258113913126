import type { CSSObjectWithLabel } from 'react-select';
import Async from 'react-select/async';

import { getBranches } from 'shared/dev/api/actions';
import type { Option } from 'shared/dev/types/types';

type BranchPickerProps = {
  changeBranch: (branch: Option) => void;
  currentBranch: string;
  defaultBranch: string;
  replaceSlashes: boolean;
  projectId: number;
};

export function BranchPickerInput({
  changeBranch,
  currentBranch,
  defaultBranch,
  replaceSlashes,
  projectId,
}: BranchPickerProps) {
  function loadBranches(query: string) {
    return getBranches(projectId, query, replaceSlashes, defaultBranch);
  }

  return (
    <form>
      <Async
        inputId={`branch-picker-${projectId}`}
        defaultOptions
        cacheOptions
        name="branch"
        classNamePrefix="Select"
        isClearable
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base: CSSObjectWithLabel) => ({ ...base, zIndex: 9999 }) }}
        value={{ label: currentBranch, value: currentBranch }}
        loadOptions={loadBranches}
        onChange={changeBranch}
      />
    </form>
  );
}
