import EntityState from 'data/EntityState';
import Integration from 'data/integration/model';
import { requestableIntegrations } from 'data/integration/requestable-integrations';
import { FETCH_INTEGRATIONS, FETCH_INTEGRATIONS_GO } from 'store/action-types';

export default function (state = new EntityState(), action) {
  switch (action.type) {
    case FETCH_INTEGRATIONS.FAILURE:
      return state.merge({
        loading: false,
        loaded: false,
        error: action.payload.error,
      });

    case FETCH_INTEGRATIONS.REQUEST:
      return state.merge({
        loading: true,
        loaded: false,
        error: null,
      });

    case FETCH_INTEGRATIONS.SUCCESS:
      return state.merge({
        loading: false,
        loaded: true,
        error: null,
        items: state.items.merge(
          action.payload.integrations.map(integration => [integration.feature, new Integration(integration)]),
        ),
      });

    case FETCH_INTEGRATIONS_GO.SUCCESS:
      return state.merge({
        items: state.items.merge(
          action.payload.integrations.map(int => {
            const feature = int.info?.feature || int.feature || int.key;
            return [feature, new Integration({ ...int, ...int.info, feature })];
          }),
        ),
      });

    default:
      return state.merge({
        items: state.items.merge(requestableIntegrations),
      });
  }
}
