import {
  type ChangeEvent,
  type FocusEvent,
  Fragment,
  type KeyboardEvent,
  type LegacyRef,
  type ReactNode,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';

import classnames from 'classnames';
import usePlacesAutoComplete, { getGeocode, type GeocodeResult, type Suggestion } from 'use-places-autocomplete';
import { useOnClickOutside } from 'usehooks-ts';

import 'shared/ui/assets/styles/GoogleMapsAutocomplete.scss';
import InputWrapper from 'shared/components/form/wrappers/InputWrapper';
import isCheckAddress from 'shared/util/IsCheckAddress';
import poweredByGoogle from 'styles/assets/img/powered-by-google.png';

type Props = {
  label?: string;
  disabled?: boolean;
  className?: string;
  inputClassName?: string;
  name: string;
  value?: string;
  onChange?: (name: string, value: any | GeocodeResult) => void;
  onSelect?: (name: string, value: GeocodeResult, checkValid: boolean) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  resetOnBlur?: boolean;
  originalAddress?: string;
  width?: number | boolean | 'auto';
  inputOnly?: boolean;
  shortAddress?: boolean;
  limitResults?: string[] | undefined; // see https://developers.google.com/maps/documentation/places/web-service/supported_types#table3
  autoComplete: string;
  validateCheck: boolean;
};

let cachedVal = '';
const acceptedKeys = ['ArrowUp', 'ArrowDown', 'Enter', 'Tab', 'Escape'];

function GoogleMapsAutocomplete(
  {
    label,
    disabled = false,
    className,
    inputClassName,
    name,
    value,
    width = 6,
    limitResults,
    onChange = () => {},
    onSelect = () => {},
    onBlur = () => {},
    originalAddress,
    inputOnly = false,
    shortAddress = false,
    autoComplete = 'on',
    validateCheck = false,
    resetOnBlur,
  }: Props,
  ref: LegacyRef<HTMLInputElement>,
) {
  const el = useRef(null);

  const {
    ready,
    value: suggestValue,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutoComplete({
    requestOptions: {
      types: limitResults,
    },
    debounce: 300,
  });

  const [checkValid, setCheckValid] = useState<boolean>(true);
  const [checkMessage, setCheckMessage] = useState<string>('');
  const [currIndex, setCurrIndex] = useState<number | null>(null);

  const hasSuggestions = status === 'OK' && data.length > 0;

  useEffect(() => {
    if (value) {
      setValue(value, false);
    }
  }, [value]);

  const dismissSuggestions = () => {
    setCurrIndex(null);
    clearSuggestions();
  };

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    onChange(name, e.target.value);
    cachedVal = e.target.value;
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    const value = e.target?.value !== undefined ? e.target.value : '';
    if (resetOnBlur && value === '' && originalAddress !== '' && originalAddress !== undefined) {
      setValue(originalAddress);
    }
    onBlur?.(e);
  };

  const handleSelect =
    ({ description, structured_formatting }: Suggestion) =>
    () => {
      if (shortAddress) {
        setValue(structured_formatting.main_text, false);
      } else {
        setValue(description, false);
      }
      getGeocode({ address: description }).then((results: GeocodeResult[]) => {
        if (validateCheck) {
          isCheckAddress(results[0]).then((result: string) => {
            if (result !== '') {
              setCheckValid(false);
              setCheckMessage(result);
            }
          });
        }
        onSelect(name, results[0], checkValid);
      });
      dismissSuggestions();
    };

  const handleEnter = (idx: number) => () => {
    setCurrIndex(idx);
  };

  const handleLeave = () => {
    setCurrIndex(null);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (!checkValid) {
      setCheckValid(true);
      setCheckMessage('');
    }

    if (!hasSuggestions || !acceptedKeys.includes(e.key)) {
      return;
    }

    if (e.key === 'Escape' || e.key === 'Tab') {
      e.preventDefault();
      // e.stopPropagation();
      dismissSuggestions();
      return;
    }

    if (e.key === 'Enter') {
      e.preventDefault();
      const value =
        currIndex !== null && data[currIndex] ? data[currIndex] : ({ description: cachedVal } as Suggestion);
      handleSelect(value)();
      dismissSuggestions();
    }

    let nextIndex: number | null;

    if (e.key === 'ArrowUp') {
      e.preventDefault();
      nextIndex = currIndex ?? data.length;
      nextIndex = nextIndex > 0 ? nextIndex - 1 : null;
    } else {
      nextIndex = currIndex ?? -1;
      nextIndex = nextIndex < data.length - 1 ? nextIndex + 1 : null;
    }

    setCurrIndex(nextIndex);
  };

  const renderSuggestions = (): ReactNode => {
    const suggestions = data.map((suggestion: Suggestion, idx: number) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li
          key={place_id}
          id={`autosuggest-list-item-${idx}`}
          className={classnames('autosuggest-list-item', {
            'autosuggest-list-item-active': idx === currIndex,
          })}
          onClick={handleSelect(suggestion)}
          onMouseEnter={handleEnter(idx)}
          aria-selected={idx === currIndex}
        >
          <strong>{main_text}</strong>
          <small>{secondary_text}</small>
        </li>
      );
    });

    return (
      <Fragment>
        {suggestions}
        <li className="logo">
          <img src={poweredByGoogle} alt="Powered by Google" />
        </li>
      </Fragment>
    );
  };

  const wrapperClasses = classnames('form-group', className, {
    disabled: disabled,
    col: width === false,
    [`col-${width}`]: width,
  });

  const inputClassname = classnames('form-control', inputClassName);

  useOnClickOutside(el, dismissSuggestions);

  const renderInput = () => {
    return (
      <div className="google-maps-autocomplete-container autocomplete" ref={el}>
        <input
          type="text"
          className={inputClassname}
          id={name}
          name={name}
          value={suggestValue}
          onChange={handleInput}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          disabled={disabled || !ready}
          ref={ref}
          autoComplete={autoComplete}
        />
        {hasSuggestions && (
          <ul className="autosuggest-listbox" onMouseLeave={handleLeave}>
            {renderSuggestions()}
          </ul>
        )}
      </div>
    );
  };

  const renderReturn = () => {
    return (
      <div className={wrapperClasses}>
        <label className="form-control-label" htmlFor={name}>
          {label}
        </label>
        {renderInput()}
      </div>
    );
  };

  if (inputOnly) {
    return renderInput();
  }

  return (
    <InputWrapper
      classNames="home-address-wrapper"
      showError={validateCheck && !checkValid}
      isValid={validateCheck && checkValid}
      errorMessage={checkMessage}
    >
      {renderReturn()}
    </InputWrapper>
  );
}

export default forwardRef(GoogleMapsAutocomplete);
