import type EntityState from 'data/EntityState';
import { toEntityMap } from 'shared/util/toEntityMap';

import type { AnyAction, AsyncThunk } from '@reduxjs/toolkit';

export const isInSlice = (slice: string, action: AnyAction): boolean => {
  const sliceParts = action.type?.split('/');
  if (!sliceParts) {
    return false;
  }
  const slicePrefix = sliceParts.slice(0, -2).join('/');
  return slicePrefix === slice;
};

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;
export type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
export type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;
export type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>;

const payloadToArray = (data: any) => {
  if (!data) {
    return [];
  }

  return Array.isArray(data) ? data : [data];
};

export const deleteEntityItems = (state: EntityState, action: AnyAction, key?: string) => {
  const itemPath = (item: any) => (key ? item[key] : item);
  let data = action.meta.arg;
  if (!Array.isArray(data)) {
    data = [action.meta.arg];
  }
  return state.merge({
    items: state.items.withMutations(map => {
      data.map((item: any) => map.delete(itemPath(item)));
    }),
  });
};

export const mergeEntityItems = <State = EntityState>(
  state: State,
  items: any,
  model: any,
  key = 'id',
  keyMutator?: any,
) => {
  const data = payloadToArray(items);

  if (!data.length) {
    return state;
  }
  // @ts-ignore
  return state.mergeDeep({
    // @ts-ignore
    items: state.items.merge(toEntityMap(data, model, key, keyMutator)),
  });
};

export const replaceEntityItems = <State = EntityState>(
  state: State,
  items: any,
  model: any,
  key = 'id',
  keyMutator?: any,
) => {
  const data = payloadToArray(items);

  if (!data.length) {
    return state;
  }
  // @ts-ignore
  return state.set('items', toEntityMap(data, model, key, keyMutator));
};
