import { getUserItem, setUserItem } from 'shared/util/userLocalStorage';
import type { WiwDispatch } from 'store';
import { SET_CURRENT_PAYROLL } from 'store/action-types';

export function setCurrentPayroll(payrollId: number) {
  return (dispatch: WiwDispatch) => {
    setUserItem('current_payroll', payrollId);
    return dispatch({
      type: SET_CURRENT_PAYROLL,
      payload: payrollId,
    });
  };
}

export const getInitialPayroll = () => {
  return Number.parseInt(getUserItem('current_payroll'), 10) || 0;
};
