import LoadImage, { type MetaData, type Orientation } from 'blueimp-load-image';
import { useLDFlag } from 'data/LD/selectors/getLDFlag';
import { type ChangeEvent, Fragment, type KeyboardEvent, useEffect, useRef, useState } from 'react';
import Form, { Textarea, useForm } from 'shared/form';
import FontIcon from 'shared/ui/FontIcon';
import TwilioProvider from 'workchat/TwilioProvider';
import type { ImageInput } from 'workchat/types/ImageInput';
import type { Emoji } from 'workchat/v2/constants';
import useReactionPicker from 'workchat/v2/hooks/useReactionPickerProvider';

import 'workchat/styles/MessageInput.scss';

export type MessageInputProps = {
  sendMessage: (message: string, image: any) => void;
  sending: boolean;
  canAttach?: boolean;
  disabled?: boolean;
  onTextInputChange?: (value: string) => void;
  setError?: (error: string) => void;
};

const VALID_TYPES: { [key: string]: boolean } = {
  'image/png': true,
  'image/jpeg': true,
  'image/gif': true,
};

export default function MessageInput({
  sendMessage,
  sending,
  canAttach = true,
  disabled = false,
  onTextInputChange = () => {},
  setError,
}: MessageInputProps) {
  const form = useForm();
  const [message, setMessage] = useState('');
  const [image, setImage] = useState<ImageInput | null>(null);
  const [selectedEmoji, setSelectedEmoji] = useState<string | null>(null);

  const inputFileUpload = useRef<HTMLInputElement>(null);
  const fdt876 = useLDFlag('fdt-876-work-chat-reactions-to-messages');
  const reactionPicker = useReactionPicker();

  const textarea = document.querySelector('.message-textarea-input') as HTMLTextAreaElement;

  useEffect(() => {
    if (selectedEmoji) {
      const cursorPos = textarea.selectionStart;
      const textBeforeCursor = textarea.value.substring(0, cursorPos);
      const textAfterCursor = textarea.value.substring(cursorPos);
      setMessage(textBeforeCursor + selectedEmoji + textAfterCursor);
    }
  }, [selectedEmoji]);

  const send = () => {
    sendMessage(message, image);
    setMessage('');
    setImage(null);
  };

  const clearImage = () => {
    setImage(null);
  };

  const onTyping = (_: string, value: string) => {
    setMessage(value);
    onTextInputChange(value);
  };

  const onKey = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (!disabled) {
        send();
      }
    }
  };

  const handleAddPhoto = () => {
    inputFileUpload?.current?.click();
  };

  const onAttach = (e: ChangeEvent<HTMLInputElement>) => {
    const input = e.target;
    if (!input.files || !input.files.length) {
      setImage(null);
      return;
    }

    const file = input.files[0];
    const image: ImageInput = {
      file,
      type: file.type,
      size: file.size,
      buffer: null,
    };

    if (!VALID_TYPES[image.type]) {
      input.value = '';
      setImage(null);
      setError?.('Only PNG, JPG, and GIF images are allowed.');
      return;
    }

    LoadImage.parseMetaData(
      file,
      (data: MetaData) => {
        // @ts-ignore
        const orientation = data.exif ? data.exif.get('Orientation') : 1;

        LoadImage(
          file,
          (canvas: Event | HTMLCanvasElement | HTMLImageElement) => {
            // @ts-ignore
            image.data = canvas.toDataURL();

            const img = new Image();
            img.onload = () => {
              image.element = img;
            };
            img.src = image.data as string;

            const reader = new FileReader();
            reader.onload = () => {
              image.buffer = reader.result;
              setImage(image);
            };
            reader.readAsArrayBuffer(file);
          },
          {
            maxWidth: TwilioProvider.MAX_IMAGE_SIZE,
            maxHeight: TwilioProvider.MAX_IMAGE_SIZE,
            crossOrigin: 'true',
            orientation: orientation as Orientation,
            canvas: true,
          },
        );
      },
      {
        disableImageHead: true,
      },
    );
  };

  function renderActionButtons() {
    if (fdt876) {
      return (
        <div role="group" className="message-input-additions">
          <button type="button" className="btn-add-photo" disabled={disabled || sending} onClick={handleAddPhoto}>
            <FontIcon icon="camera" srText="Add photo" />
          </button>
          <button
            type="button"
            className="btn-reaction-picker"
            disabled={disabled || sending}
            onClick={() => {
              reactionPicker.pickReaction().then((emoji: Emoji | null) => {
                if (emoji) {
                  setSelectedEmoji(emoji.native);
                }
              });
            }}
          >
            <FontIcon icon="add-smiling-face" srText="Add reaction" />
          </button>
        </div>
      );
    }

    return (
      <button type="button" className="btn-add-photo" disabled={disabled || sending} onClick={handleAddPhoto}>
        <FontIcon icon="camera" srText="Add photo" />
      </button>
    );
  }

  return (
    <Form form={form} onSubmit={send} className="message-input">
      <div className="input-row image-textarea">
        {image && (
          <div className="image-preview" style={{ backgroundImage: `url(${image.data})` }}>
            <button type="button" onClick={clearImage}>
              <FontIcon icon="close" />
            </button>
          </div>
        )}
        <Textarea
          name="message"
          minRows={1}
          maxRows={4}
          autosize
          onChange={onTyping}
          onKeyPress={onKey}
          value={message}
          placeholder="Type a message..."
          className="message-textarea"
          classNames={{
            input: `message-textarea-input ${message === selectedEmoji && message !== '' ? 'fs-larger' : ''}`,
          }}
          disabled={sending}
        />
      </div>
      <div className="input-row input-controls">
        {canAttach && (
          <Fragment>
            <input
              type="file"
              id="workchat-file-input"
              ref={inputFileUpload}
              onChange={onAttach}
              disabled={disabled || sending}
            />
            {renderActionButtons()}
          </Fragment>
        )}
        <button type="submit" className="btn-send" disabled={sending || (!message && !image) || disabled}>
          Send
        </button>
      </div>
    </Form>
  );
}
