import { getUserItem, setUserItem } from 'shared/util/userLocalStorage';
import { TOGGLE_OPENSHIFT_ROW } from 'store/action-types';

export function toggleOpenShiftRow() {
  const key = 'openShiftRowCollapsed';
  const isCollapsed = getUserItem('openShiftRowCollapsed', false);
  setUserItem(key, !isCollapsed);
  return {
    type: TOGGLE_OPENSHIFT_ROW,
  };
}
