import type { PostTaxDeduction } from 'data/payrollCheck/models/postTaxDeductions';
import Payroll from 'shared/api/payroll';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';

import { createAsyncThunk } from '@reduxjs/toolkit';

type PostTaxDeductionArgs = {
  user_id: number;
};

type PostTaxDeductionResponse = {
  tax_deductions: PostTaxDeduction[];
};

export const fetchPostTaxDeductions = createAsyncThunk<PostTaxDeductionResponse, PostTaxDeductionArgs>(
  'payrollCheck/fetchPostTaxDeductions',
  async (args, { rejectWithValue }) => {
    try {
      return await Payroll.query(args).get('/tax-deduction');
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
