import BaseModel from 'data/BaseModel';

export interface RequestTypeFields {
  id: number;
  account_id: number;
  name: string;
  built_in: boolean;
  enabled: boolean;
  allow_paid: boolean;
  allow_unpaid: boolean;
  is_deleted: boolean;
  created_at: string | null;
  updated_at: string | null;
}

class RequestType extends BaseModel<RequestTypeFields>({
  id: 0,
  account_id: 0,
  name: '',
  built_in: false,
  enabled: false,
  allow_paid: false,
  allow_unpaid: false,
  is_deleted: false,
  created_at: null,
  updated_at: null,
}) {
  get isActive() {
    return this.enabled && !this.is_deleted;
  }
}

export default RequestType;
