import PropTypes from 'prop-types';
import { PureComponent, createRef } from 'react';
import { connect } from 'react-redux';
import { Slide, ToastContainer } from 'react-toastify';
import { TransitionGroup } from 'react-transition-group';

import { removeNotice } from 'notices/actions';
import CloseToast from './CloseToast';
import Notice from './notice';

import { bindActionCreators } from '@reduxjs/toolkit';
import classnames from 'classnames';
import debounce from 'lodash/debounce';

import 'notices/styles/BannerNotices.scss';
import 'notices/styles/ToastNotices.scss';

export class NoticeList extends PureComponent {
  noticeListRef = createRef();

  state = {
    sticky: false,
  };

  static propTypes = {
    notices: PropTypes.array,
    removeNotice: PropTypes.func,
    dialog: PropTypes.bool,
    dialogOpen: PropTypes.bool.isRequired,
    inline: PropTypes.bool,
    // Maximum number of notices to display at once
    // Set to falsy value to show all available notices
    maxVisible: PropTypes.number,
    defaultDuration: PropTypes.number,
    followWindow: PropTypes.bool,
  };

  static defaultProps = {
    inline: false,
    dialog: false,
    maxVisible: 1, // Show at most one notice at a time.
    defaultDuration: 3,
    followWindow: false,
  };

  componentDidMount() {
    this.relocate();

    if (this.props.followWindow) {
      window.addEventListener('scroll', this.relocate);
    }
  }

  componentDidUpdate() {
    this.relocate();
  }

  componentWillUnmount() {
    if (this.props.followWindow) {
      window.removeEventListener('scroll', this.relocate);
    }
  }

  removeNotice = noticeId => () => {
    const notice = this.props.notices.find(item => item.noticeId === noticeId);
    if (notice?.onClose) {
      notice.onClose();
    }
    return this.props.removeNotice(noticeId);
  };

  relocate = debounce(() => {
    if (!this.noticeListRef.current) {
      return;
    }

    const sticky = this.noticeListRef.current.getBoundingClientRect().top < 0;
    if (this.state.sticky !== sticky) {
      this.setState({ sticky });
    }
  }, 20);

  render() {
    let allowedItems = this.props.notices;
    if (!this.props.inline) {
      allowedItems = this.props.notices.filter(notice => {
        if (this.props.dialog) {
          return !notice.target || notice.target !== 'page';
        }
        return (!this.props.dialogOpen && !notice.target) || (notice.target && notice.target !== 'dialog');
      });
    }

    const maxItems = this.props.maxVisible || allowedItems.length;
    const items = allowedItems.slice(0, maxItems).map(notice => {
      const duration = 'duration' in notice ? notice.duration : this.props.defaultDuration;

      return (
        <Notice
          id={notice.noticeId}
          key={`notice-${notice.noticeId}`}
          status={notice.status}
          duration={duration}
          showDismiss={notice.showDismiss}
          onDismiss={this.removeNotice(notice.noticeId)}
          content={notice.text}
          animation={notice.animation}
        />
      );
    });

    const wrapperClasses = classnames({
      'notice-list': true,
      'dialog-layout': this.props.dialog,
      'inline-layout': this.props.inline,
      'page-layout': !this.props.dialog && !this.props.inline,
    });

    return (
      <div ref={this.noticeListRef} className={wrapperClasses}>
        {!this.props.dialog && !this.props.inline ? (
          <ToastContainer
            className={classnames({ 'toast-sticky': this.state.sticky })}
            transition={Slide}
            hideProgressBar
            closeOnClick={false}
            icon={false}
            closeButton={<CloseToast />}
          />
        ) : (
          <div className={classnames('scroll-wrapper', { sticky: this.state.sticky && !this.props.dialog })}>
            <TransitionGroup component={null}>{items}</TransitionGroup>
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  state => ({
    notices: state.notices.items,
    dialogOpen: state.dialogs.length > 0,
  }),
  dispatch =>
    bindActionCreators(
      {
        removeNotice,
      },
      dispatch,
    ),
)(NoticeList);
