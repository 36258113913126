import { Map } from 'immutable';
import { TwilioProvider, providerInstance as provider } from 'workchat/TwilioProvider';
import {
  WORKCHAT_LEFT_CONVERSATION,
  WORKCHAT_LOADING_CONVERSATIONS,
  WORKCHAT_LOAD_CONVERSATIONS,
  WORKCHAT_SET_CONVERSATION,
  WORKCHAT_SET_PENDING_CONVERSATION,
  WORKCHAT_UPDATE_CONVERSATION,
} from 'workchat/action-types';
import { updateReadStatus } from 'workchat/actions/index';
import { pushScreen, unwindToScreen } from 'workchat/actions/screens';
import { track } from 'workchat/actions/tracking';
import { loadUsers } from 'workchat/actions/users';
import ConversationList from 'workchat/containers/ConversationList';
import ViewConversation from 'workchat/containers/ViewConversation';

export function loadConversations(user) {
  return (dispatch, getState) => {
    if (getState().workchat.get('conversationsLoading') || !provider || !provider.ready) {
      return;
    }

    dispatch({ type: WORKCHAT_LOADING_CONVERSATIONS });

    return provider.loadConversations().then(conversations => {
      const convs = new Map().withMutations(map => {
        conversations.forEach(convo => map.set(convo.sid, convo));
      });
      dispatch({
        type: WORKCHAT_LOAD_CONVERSATIONS,
        conversations: convs,
      });
      dispatch(updateReadStatus());

      if (convs.size === 1 && !user.canManage()) {
        for (const [, convo] of convs) {
          if (convo.attributes.external_type === 'account') {
            dispatch(pushScreen(ConversationList));
            provider.setConversation(convo);
            dispatch(pushScreen(ViewConversation));
            return;
          }
        }
      }

      dispatch(pushScreen(ConversationList));
    });
  };
}

export function markConversationRead() {
  return () => {
    if (provider) {
      provider.markConversationRead();
    }
  };
}

export function addParticipants(userIds) {
  return dispatch => {
    return provider.addParticipants(userIds).then(() => {
      dispatch(track('WorkChat: Added User', { userCount: userIds.length }));
    });
  };
}

export function updateParticipants(conversation, token, userId) {
  return (dispatch, getState) => {
    dispatch(loadUsers(token, userId));
    return TwilioProvider.updateMembers(conversation).then(() => {
      const currentConv = getState().workchat.get('conversation');
      if (currentConv && currentConv.sid === conversation.sid) {
        dispatch(setConversation(conversation));
      }
    });
  };
}

export function createdConversation(conversation, participants) {
  return dispatch => {
    dispatch(track('WorkChat: Channel Created', { userCount: participants.length + 1 }));
    dispatch(setConversation(conversation, true));
  };
}

export function renameConversation(name) {
  return dispatch => {
    return provider.renameConversation(name).then(conv => {
      dispatch(updateConversation(conv));
      return conv;
    });
  };
}

export function leaveConversation() {
  return dispatch => {
    return provider.leaveConversation().then(() => {
      dispatch(unwindToScreen(ConversationList));
      provider.setConversation(null);
    });
  };
}

export function leftConversation(conversation) {
  return {
    type: WORKCHAT_LEFT_CONVERSATION,
    id: conversation.sid,
  };
}

export function updateConversation(conversation) {
  return {
    type: WORKCHAT_UPDATE_CONVERSATION,
    id: conversation.sid,
    conversation,
  };
}

export function setConversation(conversation, markRead = false) {
  return dispatch => {
    dispatch({
      type: WORKCHAT_SET_CONVERSATION,
      conversation,
      participants: conversation ? conversation.participants : null,
      messages: conversation ? conversation.messages : null,
      pending: conversation ? conversation.pending : null,
      failed: conversation ? conversation.failed : null,
    });

    if (markRead) {
      dispatch(markConversationRead());
      dispatch(updateReadStatus());
    }
  };
}

export function setPendingConversation(userIds = null, title = '') {
  let pending = { userIds, title };
  if (!userIds) {
    pending = null;
  }

  return {
    type: WORKCHAT_SET_PENDING_CONVERSATION,
    pending,
  };
}
