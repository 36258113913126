import { isPostalCode as isPostalCodeValidator } from 'validator';

export default function isPostalCode(values, value, country = undefined) {
  if (value === '' || value == null || value === undefined) {
    return false;
  }

  if (country !== null && country !== undefined && country !== '') {
    // If we've got a country to check against, check if our validator supports the iso2 code
    // If it does not, accept the user's input as valid
    if (validatedCountries.includes(country)) {
      return isPostalCodeValidator(value, 'any');
    }
    return true;
  }

  return isPostalCodeValidator(value, 'any');
}

// An array of iso2 country codes that 'validator' supports
const validatedCountries = [
  'AD',
  'AT',
  'AU',
  'AZ',
  'BE',
  'BG',
  'BR',
  'BY',
  'CA',
  'CH',
  'CN',
  'CZ',
  'DE',
  'DK',
  'DO',
  'DZ',
  'EE',
  'ES',
  'FI',
  'FR',
  'GB',
  'GR',
  'HR',
  'HT',
  'HU',
  'ID',
  'IE',
  'IL',
  'IN',
  'IR',
  'IS',
  'IT',
  'JP',
  'KE',
  'LI',
  'LT',
  'LU',
  'LV',
  'MT',
  'MX',
  'MY',
  'NL',
  'NO',
  'NP',
  'NZ',
  'PL',
  'PR',
  'PT',
  'RO',
  'RU',
  'SA',
  'SE',
  'SG',
  'SI',
  'TH',
  'TN',
  'TW',
  'UA',
  'US',
  'ZA',
  'ZM',
];
