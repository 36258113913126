import MetadataState from 'data/metadata/state';
import parseJSON from 'data/parseJSON';
import {
  FETCH_ACCOUNT_METADATA,
  FETCH_USER_METADATA,
  UPDATE_ACCOUNT_METADATA,
  UPDATE_USER_METADATA,
} from 'store/action-types';

import { Map } from 'immutable';

export default (state = new MetadataState(), action) => {
  switch (action.type) {
    case FETCH_ACCOUNT_METADATA.REQUEST:
      return state.merge({
        accountLoading: true,
      });
    case FETCH_ACCOUNT_METADATA.SUCCESS:
      return state.merge({
        accountLoaded: true,
        accountLoading: false,
        error: null,
        receivedAt: Date.now(),
        account: state.account.merge(new Map(action.payload.account).map(value => parseJSON(value))),
      });
    case FETCH_ACCOUNT_METADATA.FAILURE:
      return state.merge({
        accountLoading: false,
        error: action.payload,
      });
    case FETCH_USER_METADATA.REQUEST:
      return state.merge({
        userLoading: true,
      });
    case FETCH_USER_METADATA.SUCCESS:
      return state.merge({
        userLoaded: true,
        userLoading: false,
        error: null,
        receivedAt: Date.now(),
        account: state.account.merge(new Map(action.payload.account).map(value => parseJSON(value))),
        user: state.user.merge(new Map(action.payload.user).map(value => parseJSON(value))),
      });
    case FETCH_USER_METADATA.FAILURE:
      return state.merge({
        userLoading: false,
        error: action.payload,
      });
    case UPDATE_ACCOUNT_METADATA:
      return state.merge({
        account: state.account.merge(new Map(action.payload.account).map(value => parseJSON(value))),
      });
    case UPDATE_USER_METADATA.REQUEST:
      return state.merge({
        userLoading: true,
      });
    case UPDATE_USER_METADATA.SUCCESS:
      return state.merge({
        userLoading: false,
        user: state.user.merge(new Map(action.payload.user).map(value => parseJSON(value))),
      });
    default:
      return state;
  }
};
