class Store {
  constructor() {
    try {
      localStorage.setItem('supportTest', 'supportTest');
      localStorage.removeItem('supportTest');
      this.storage = localStorage;
    } catch (e) {
      console.warn('localStorage not available', e);
      this.storage = Cookies;
    }
  }

  set(key, value) {
    this.storage.setItem(key, JSON.stringify(value));
  }

  get(key) {
    try {
      return JSON.parse(this.storage.getItem(key) || 'null');
    } catch (e) {
      console.warn(e);
      return null;
    }
  }

  remove(key) {
    this.storage.removeItem(key);
  }
}

window.Store = new Store();

export default window.Store;

class Cookies {
  static getItem(key) {
    const prefix = `${key}=`;
    const cookies = document.cookies.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.indexOf(prefix) === 0) {
        return decodeURIComponent(cookie.substring(prefix.length, cookie.length));
      }
    }
    return null;
  }

  static setItem(key, value, expires) {
    if (!expires) {
      expires = new Date(2038, 1, 1, 0, 0, 0, 0);
    }
    document.cookie = `${key}=${encodeURIComponent(value)}; expires=${expires.toUTCString()}; path=/`;
  }

  static removeItem(key) {
    Cookies.setItem(key, '', new Date(2015, 8, 24, 0, 0, 0, 0));
  }
}
