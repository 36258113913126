import type EntityState from 'data/EntityState';

import type { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { get } from 'lodash';

const optimisticallyDeleteItem = (state: EntityState, item: any) => {
  return state.merge({
    items: state.items.delete(item.id),
    prevItems: state.prevItems.set(item.id, item),
  });
};

const optimisticallyRevertDeletion = (state: EntityState, item: any) => {
  const prev = state.prevItems.get(item.id);
  return state.merge({
    items: state.items.set(prev.id, prev),
    prevItems: state.prevItems.delete(item.id),
  });
};

const optimisticallyUpdateItem = (state: EntityState, item: any, Model: any) => {
  const current = item.id ? state.items.get(item.id) : null;
  const newState: { items: any; prevItems?: any } = {
    items: state.items.set(item.id, new Model(item)),
  };
  if (current) {
    newState.prevItems = state.prevItems.set(current.id, current);
  }

  return state.merge(newState);
};

const optimisticallyFinalizeItem = (state: EntityState, item: any, Model: any) => {
  return state.merge({
    items: state.items.delete(0).delete('').delete(null).delete(undefined).set(item.id, new Model(item)),
    prevItems: state.prevItems.delete(item.id),
  });
};

const optimisticallyRevertItem = (state: EntityState, item: any) => {
  const prev = state.prevItems.get(item.id);
  return state.merge({
    items: item.id ? state.items.set(prev.id, prev) : state.items.delete(0),
    prevItems: state.prevItems.delete(item.id),
  });
};

const getActionPayload = (action: PayloadAction, path: string | undefined) => {
  if (path) {
    return get(action.payload, path);
  }
  return action.payload;
};

const getActionMeta = (action: any, path: string | undefined) => {
  if (path) {
    return get(action.meta.arg, path);
  }
  return action.meta.arg;
};

type Options = {
  payloadPath: string;
  parameterPath: string;
};

const defaultOptions: Partial<Options> = {
  payloadPath: '',
  parameterPath: '',
};

/**
 * ONLY FOR ITEMS THAT EXTEND EntityState. Handles updating the redux store for updating and or adding items optimistically
 * @param builder
 * @param actionBase asyncThunkCreator base name i.e. updateSite.fulfilled you would pass updateSite
 */
export const optimisticallyUpdateEntity = (
  builder: ActionReducerMapBuilder<any>,
  actionBase: any,
  model: unknown,
  options = defaultOptions,
) => {
  return builder
    .addCase(actionBase.pending, (state, action) => {
      const parameter = getActionMeta(action, options.parameterPath);
      return optimisticallyUpdateItem(state, parameter, model);
    })
    .addCase(actionBase.fulfilled, (state, action) => {
      const payload = getActionPayload(action, options.payloadPath);
      return optimisticallyFinalizeItem(state, payload, model);
    })
    .addCase(actionBase.rejected, (state, action) => {
      const parameter = getActionMeta(action, options.parameterPath);
      return optimisticallyRevertItem(state, parameter);
    });
};

export const optimisticallyDeleteEntity = (
  builder: ActionReducerMapBuilder<any>,
  actionBase: any,
  options = defaultOptions,
) => {
  return builder
    .addCase(actionBase.pending, (state, action) => {
      const parameter = getActionMeta(action, options.parameterPath);
      return optimisticallyDeleteItem(state, parameter);
    })
    .addCase(actionBase.rejected, (state, action) => {
      const parameter = getActionMeta(action, options.parameterPath);
      return optimisticallyRevertDeletion(state, parameter);
    });
};
