import ScheduleRule from 'data/scheduleRule/ScheduleRule';
import RuleState from 'data/scheduleRule/state';
import { toEntityMap } from 'shared/util/toEntityMap';
import { FETCH_RULE_SUMMARY } from 'store/action-types';

export default (state = new RuleState(), action) => {
  switch (action.type) {
    case FETCH_RULE_SUMMARY.REQUEST:
      return state.merge({
        loading: true,
        receivedAt: 0,
        loaded: false,
      });
    case FETCH_RULE_SUMMARY.SUCCESS:
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        receivedAt: Date.now(),
        items: toEntityMap(action.items.rules, ScheduleRule, 'name'),
      });
    case FETCH_RULE_SUMMARY.FAILURE:
      return state.merge({
        loaded: false,
        loading: false,
        error: action.payload,
      });
    default:
      return state;
  }
};
