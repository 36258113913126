import { TwitterPicker } from 'react-color';

export const colors = {
  // Pastel Colors
  '#d98db3': 'Flamingo',
  '#d98dd9': 'Peony',
  '#b38dd9': 'Lilac',
  '#9a8dd9': 'Lavender',
  '#8da6d9': 'Periwinkle',
  '#8dbfd9': 'Raindrop',
  '#8dd9cc': 'Seafoam',
  '#a6d98d': 'Honeydew',
  '#bfd98d': 'Pistachio',
  '#d9d98d': 'Prairie',
  '#d9bf8d': 'Dune',
  '#d9a68d': 'Clay',
  '#d98d8d': 'Peach',
  // Rich Colors
  '#cc5490': 'Petal',
  '#cc54cc': 'Aster',
  '#9054cc': 'Grape',
  '#6854cc': 'Iris',
  '#547ccc': 'Bluebird',
  '#54a4cc': 'Glacier',
  '#54ccb8': 'Seaglass',
  '#54cc54': 'Lime',
  '#a4cc54': 'Pear',
  '#cccc54': 'Citron',
  '#cca454': 'Honey',
  '#cc7c54': 'Canyon',
  '#cc5353': 'Coral',
  // Normal Colors
  '#a6115b': 'Raspberry',
  '#a611a6': 'Orchid',
  '#7411a6': 'Peacock',
  '#4211a6': 'Concord',
  '#1142a6': 'Blueberry',
  '#1174a6': 'Wave',
  '#11a68d': 'Reef',
  '#42a611': 'Fern',
  '#74a611': 'Avocado',
  '#a6a611': 'Lemongrass',
  '#a67411': 'Walnut',
  '#a64211': 'Badlands',
  '#a61111': 'Cherry',
  // Dull Colors
  '#8a485e': 'Boysenberry',
  '#8a4874': 'Vineyard',
  '#7f488a': 'Amethyst',
  '#5e488a': 'Fig',
  '#485e8a': 'Atlantic',
  '#48748a': 'Fog',
  '#488a7f': 'Mist',
  '#5e8a48': 'Moss',
  '#748a48': 'Crocodile',
  '#8a8a48': 'Olive',
  '#8a7448': 'Chestnut',
  '#8a5e48': 'Redwood',
  '#894747': 'Cattail',
  '#cccccc': 'Default',
};

const WIDTH = 492;

const ColorPicker = props => {
  return <TwitterPicker width={WIDTH} colors={colors} {...props} />;
};

export const positionColorPicker = rect => {
  return {
    top: rect.top + 50,
    left: rect.left - WIDTH + 100,
  };
};

export default ColorPicker;
