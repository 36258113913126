import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import InputWrapper from 'shared/components/form/wrappers/InputWrapper';

export class NativeTextInput extends PureComponent {
  static propTypes = {
    ariaLabel: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    validationError: PropTypes.string,
    value: PropTypes.string,
  };

  render() {
    const { ariaLabel, inputProps, name, onChange, placeholder, value, validationError } = this.props;

    const onChangeCurry = event => onChange(event.target.value);

    return (
      <InputWrapper
        name={name}
        isPristine={false}
        showError={!!validationError}
        errorMessage={validationError}
        isFormDisabled={false}
        isValid={true}
        validatePristine={true}
      >
        <input
          aria-label={ariaLabel}
          className="form-control"
          onChange={onChangeCurry}
          placeholder={placeholder}
          type="text"
          value={value}
          {...inputProps}
        />
      </InputWrapper>
    );
  }
}
/**
 * @deprecated please migrate to hook-form from shared/form/inputs
 */
export default NativeTextInput;
