import Features from 'shared/features';

import moment from 'moment-timezone';

function getFurthestVisiblePastDate(currentAccount, date) {
  date = moment(date);

  const lts = currentAccount.hasFeature(Features.LONG_TERM_SCHEDULING);

  if (lts || currentAccount.isReverseTrial()) {
    return date;
  }

  const weekStart = currentAccount.getSettings('schedule.start_of_week', 0);
  // GROW-1390 “Scope: Only allow free accounts to see last week’s (full) schedule.”
  const lastWeeksSchedule = moment().startOf('week').add(weekStart, 'days').subtract(7, 'days');

  if (date.isBefore(lastWeeksSchedule)) {
    return lastWeeksSchedule;
  }
  return date;
}

function getFurthestVisibleFutureDate(currentAccount, date) {
  date = moment(date);

  // Bail out early if currentAccount is in reverse trial
  if (currentAccount.isReverseTrial()) {
    const currentDate = moment();
    const daysLater = currentDate.startOf('day').add(6, 'days');
    return date.isAfter(daysLater, 'day') ? daysLater : date;
  }

  if (currentAccount.hasFeature(Features.LONG_TERM_SCHEDULING)) {
    return date;
  }

  let numOfDays = 9; // Default to 9 days for LTS 10 (inclusive of the current day)
  if (currentAccount.hasFeature(Features.LONG_TERM_SCHEDULING_14)) {
    numOfDays = 13;
  } else if (currentAccount.hasFeature(Features.LONG_TERM_SCHEDULING_21)) {
    numOfDays = 20;
  }

  const daysLater = moment().startOf('day').add(numOfDays, 'days'); // e.g., 10 days, inclusive of the current day.

  if (date.isAfter(daysLater)) {
    return daysLater;
  }
  return date;
}

/**
 * Check account for long term scheduling feature.
 *
 * Returns OK if current account has 'long-term-scheduling'
 * Returns FUTURE if current date is later than long-term-scheduling days.
 * Return HISTORICAL if current account doesn't have 'long-term-scheduling' and currentDate
 * is 1 full week behind the start of last week.
 *
 * @returns {number}
 */
export function getDateRangeStatus(currentAccount, date) {
  date = moment(date);

  const furthestFuture = getFurthestVisibleFutureDate(currentAccount, date);
  const furthestPast = getFurthestVisiblePastDate(currentAccount, date);

  if (date.isAfter(furthestFuture)) {
    if (currentAccount.isReverseTrial()) {
      return REVERSE_TRIAL;
    }
    return FUTURE;
  }
  if (date.isBefore(furthestPast)) {
    return HISTORICAL;
  }
  return OK;
}

/**
 * Used when going from Week View -> Day View with a partially visible week, avoid landing on a greyed out date.
 */
export function getNearestViewableDate(currentAccount, date) {
  date = moment(date);

  switch (getDateRangeStatus(currentAccount, date)) {
    case OK:
      return date;
    case FUTURE:
    case REVERSE_TRIAL:
      return getFurthestVisibleFutureDate(currentAccount, date);
    case HISTORICAL:
      return getFurthestVisiblePastDate(currentAccount, date);
    default:
      throw new Error(`Unexpected status: ${status}`);
  }
}

export const OK = 0;
export const FUTURE = 1;
export const HISTORICAL = 2;
export const REVERSE_TRIAL = 3;
