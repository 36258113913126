import type PostTaxDeduction from 'data/payrollTaxDeductions/model';
import type { PostTaxDeductionItem } from 'data/payrollTaxDeductions/model';
import Payroll from 'shared/api/payroll';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';

import { createAsyncThunk } from '@reduxjs/toolkit';

type UpdatePayrollDeductionResponse = {
  tax_deductions: PostTaxDeductionItem;
};

export type UpdateDeductionArgs = {
  tax_deduction: PostTaxDeduction;
};

export const updatePayrollDeduction = createAsyncThunk<UpdatePayrollDeductionResponse, UpdateDeductionArgs>(
  'taxDeduction/update',
  async (params, { rejectWithValue }) => {
    try {
      return (await Payroll.url('/tax-deduction')
        .query({ tax_deduction_id: params.tax_deduction.id })
        .put(params.tax_deduction.toJSON())) as UpdatePayrollDeductionResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
