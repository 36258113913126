import { rolloutAssignments } from 'shared/mercury/rolloutAssignments';
import { trackRollout } from 'shared/util/mercury';

// eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
export const trackRolloutAssignments = store => next => action => {
  if (action.type === 'UPSERT_LD_FLAGS' && action.payload) {
    rolloutAssignments.forEach(
      rollout =>
        action.payload[rollout.ldFlag] !== undefined &&
        trackRollout(rollout.jira, rollout.ldFlag, action.payload[rollout.ldFlag].current),
    );
  }

  return next(action);
};
