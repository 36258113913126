import type Position from 'data/position/model';
import type { PositionFields } from 'data/position/model';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type CreatePositionResponse = {
  position: PositionFields;
};

export const createPosition = createWiwAsyncThunk<CreatePositionResponse, Position>(
  'position/create',
  async (position, { rejectWithValue }) => {
    try {
      return (await Monolith.url('/positions').post(position.toJSON())) as CreatePositionResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
