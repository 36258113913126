import flattenAddressComponents from 'shared/util/googleMaps/common';

import { result } from 'lodash';
import type { GeocodeResult } from 'use-places-autocomplete';

export type SimpleGeocodeResult = {
  city: string;
  country: string;
  fullAddress: string;
  latitude: number;
  longitude: number;
  placeId: string;
  placeType: string;
  postalCode: string;
  shortAddress: string;
  stateShort: string;
  stateLong: string;
  streetName: string;
  streetNumber: string;
  subLocality: string | undefined;
};

export function simplifyGeocodeResult(data: GeocodeResult): SimpleGeocodeResult {
  const components = flattenAddressComponents(data);

  const output: Omit<SimpleGeocodeResult, 'shortAddress'> = {
    fullAddress: data.formatted_address,
    streetNumber: result(components.street_number, 'short_name'),
    streetName: result(components.route, 'short_name'),
    city:
      result(components.postal_town, 'long_name') ||
      result(components.locality, 'long_name') ||
      result(components.neighborhood, 'long_name'),
    subLocality: result(components.sublocality, 'short_name'),
    stateShort: result(components.administrative_area_level_1, 'short_name'),
    stateLong: result(components.administrative_area_level_1, 'long_name'),
    postalCode: result(components.postal_code, 'long_name'),
    country: result(components.country, 'short_name'),
    latitude: result(data.geometry, 'location.lat'),
    longitude: result(data.geometry, 'location.lng'),
    placeType: (data.types || []).join(','),
    placeId: data.place_id,
  };

  return {
    shortAddress: shortenAddress(output),
    ...output,
  };
}

export function shortenAddress(data: Omit<SimpleGeocodeResult, 'shortAddress'>) {
  return `${data.streetNumber} ${data.streetName}`;
}
