import type { WiwState } from 'store';
import { getReportDataset } from './getReportDataset';

import { createSelector } from '@reduxjs/toolkit';

export const getCustomReportBuilderTempDataset = (state: WiwState): string | undefined =>
  state.reporting.customReportBuilder.tempDataset;

export const getReportTempDataset = createSelector(
  [getReportDataset, getCustomReportBuilderTempDataset],
  (dataset, tempDataset) => {
    if (tempDataset == null) {
      return dataset;
    }

    return tempDataset;
  },
);
