import type { SavedReport } from 'data/reporting/models/SavedReport';
import { getSavedReports } from 'data/reporting/selectors/getSavedReports';
import { getSelectedDefinitionId } from 'reporting/selectors/getSelectedDefinitionId';

import { createSelector } from '@reduxjs/toolkit';
import type { Map } from 'immutable';

export const getActiveReportDefinition = createSelector(
  [getSavedReports, getSelectedDefinitionId],
  (reports: Map<number, SavedReport>, id: number | undefined) => {
    if (id == null) {
      return null;
    }
    return reports.get(id, null);
  },
);
