import type Absence from 'data/absence/model';
import type { AbsenceFields } from 'data/absence/model';
import Scheduling from 'shared/api/scheduling';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type UpdateAbsenceResponse = {
  data: AbsenceFields;
};

export const updateAbsence = createWiwAsyncThunk<UpdateAbsenceResponse, Absence>(
  'absence/update',
  async (absence, { rejectWithValue }) => {
    try {
      return (await Scheduling.url(`/absences/${absence.id}`).patch(absence.toJSON())) as UpdateAbsenceResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
