import { Body, Dialog, Footer, Header } from 'dialogs';
import Button from 'shared/ui/Button';

import type { DialogDirectProps } from 'dialogs';

export type Props = DialogDirectProps & {
  onConfirm: () => void;
  onCancel?: () => void;
};

export function ConfirmRequestTypeAttendanceDialog({ onConfirm, onCancel, closeDialog }: Props) {
  function onSubmit() {
    onConfirm();
    closeDialog();
  }

  function cancel() {
    closeDialog();
    onCancel?.();
  }

  return (
    <Dialog width={500}>
      <Header onClose={cancel}>Exports will be updated</Header>
      <Body>
        The Attendance timesheet and pay period exports will now have columns reflecting the paid time off types you've
        configured. We recommend updating any macros you've set up.
      </Body>
      <Footer>
        <div className="text-right">
          <Button className="mr-2" color="secondary" onClick={cancel}>
            Cancel
          </Button>
          <Button type="submit" onClick={onSubmit}>
            Confirm
          </Button>
        </div>
      </Footer>
    </Dialog>
  );
}

export default ConfirmRequestTypeAttendanceDialog;
