import { Record } from 'immutable';
import { DateTime } from 'luxon';

export interface ReportMetadataRowsPerDayFields {
  time: DateTime;
  count: number;
}

export class ReportMetadataRowsPerDay extends Record<ReportMetadataRowsPerDayFields>({
  time: DateTime.invalid('undefined'),
  count: 0,
}) {}
