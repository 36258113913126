import EntityState from 'data/EntityState';
import { createPayrollDeduction } from 'data/payrollTaxDeductions/actions/createPayrollDeduction';
import { deletePayrollDeductions } from 'data/payrollTaxDeductions/actions/deletePayrollDeductions';
import { fetchPayrollDeductions } from 'data/payrollTaxDeductions/actions/fetchPayrollDeductions';
import { updatePayrollDeduction } from 'data/payrollTaxDeductions/actions/updatePayrollDeduction';
import PostTaxDeduction from 'data/payrollTaxDeductions/model';
import generateThunkReducers from 'data/util/generateThunkReducers';
import { optimisticallyDeleteEntity } from 'data/util/optimisticUpdate';
import { mergeEntityItems, replaceEntityItems } from 'data/util/sliceHelpers';

import { createSlice } from '@reduxjs/toolkit';

const initialState = new EntityState<PostTaxDeduction>();

export const payrollsDeductions = createSlice({
  name: 'taxDeduction',
  initialState,
  reducers: {
    receiveDeductions: (state, action) => {
      return mergeEntityItems(state, action.payload.tax_deductions, PostTaxDeduction);
    },
    clearDeductions: state => {
      return state.merge({
        items: state.items.clear(),
        prevItems: state.prevItems.clear(),
      });
    },
  },
  extraReducers: builder => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchPayrollDeductions.fulfilled, (state, action) => {
        return replaceEntityItems(state, action.payload.tax_deductions, PostTaxDeduction);
      })
      .addCase(createPayrollDeduction.fulfilled, (state, action) => {
        return state.merge({
          items: state.items
            .delete(0)
            .delete('')
            .set(action.payload.tax_deductions.id, new PostTaxDeduction(action.payload.tax_deductions)),
          prevItems: state.prevItems.delete(action.payload.tax_deductions.id),
        });
      })
      .addCase(updatePayrollDeduction.fulfilled, (state, action) => {
        return state.merge({
          items: state.items
            .delete(0)
            .delete('')
            .set(action.payload.tax_deductions.id, new PostTaxDeduction(action.payload.tax_deductions)),
          prevItems: state.prevItems.delete(action.payload.tax_deductions.id),
        });
      });
    optimisticallyDeleteEntity(builder, deletePayrollDeductions);
    generateThunkReducers(builder, 'tax_deductions');
  },
});

export const { clearDeductions } = payrollsDeductions.actions;
export default payrollsDeductions.reducer;
