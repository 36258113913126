import BaseModel from 'data/BaseModel';
import { type TaskFields, TaskModel } from 'data/tasks/models/TaskModel';

import { List, Map } from 'immutable';
import map from 'lodash/map';

export interface RepeatTaskFields {
  locationId: string;
  recurrenceRuleSet: string[];
}

export interface TaskListFields {
  id: string | null;
  name: string | null;
  tasks: { [taskId: string]: TaskFields } | null;
  type: string | null;
  shiftTemplates: number[];
  repeats: RepeatTaskFields[];
}

export enum TaskListType {
  Team = 0,
  Shift = 1,
}

class TaskListModel extends BaseModel<TaskListFields>({
  id: null,
  name: null,
  tasks: null,
  type: null,
  shiftTemplates: [],
  repeats: [],
}) {
  static Types = {
    TEAM: TaskListType.Team,
    SHIFT: TaskListType.Shift,
  } as const;

  constructor(args: Partial<TaskListFields> = {}) {
    let tasks = Map();
    let shiftTemplates = List<number>();
    if (args.tasks) {
      map(args.tasks, (task, id) => {
        tasks = tasks.set(id, new TaskModel(task));
      });
    }

    if (args.shiftTemplates) {
      // Array of templateShift (shiftBlock) ids
      shiftTemplates = List(args.shiftTemplates);
    }

    super(
      Object.assign({}, args, {
        tasks,
        shiftTemplates,
      }),
    );
  }
}

export default TaskListModel;
