//Legacy Views
// @deprecated use new view types
export const DAY_VIEW = 'day';
// @deprecated use new view types
export const WEEK_VIEW = 'week';
// @deprecated use new view types
export const MONTH_VIEW = 'month';
// @deprecated use new view types
export const POSITION_VIEW = 'position';

//View Types
export const MYSCHEDULE_VIEW = 'myschedule';
export const POSITION_VIEW_V2 = 'positionV2';
export const COVERAGE_VIEW = 'coverage';
export const EMPLOYEE_VIEW = 'employee';
export const JOB_SITE_VIEW = 'jobsite';

//Date Ranges
export const TWO_WEEK_RANGE = 'two-week';
export const WEEK_RANGE = 'week';
export const DAY_RANGE = 'day';
export const MONTH_RANGE = 'month';

export enum ViewType {
  DAY = 'day', // deprecated
  WEEK = 'week', // deprecated
  MONTH = 'month', // deprecated
  POSITION = 'position', // deprecated
  MYSCHEDULE = 'myschedule',
  POSITIONV2 = 'positionV2',
  COVERAGE = 'coverage',
  EMPLOYEE = 'employee',
}

export enum ViewRange {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  TWO_WEEK = 'two-week',
}
