import moment from 'moment-timezone';

import { WORKCHAT_MESSAGE_RECEIPTS } from 'workchat/action-types';
import { markConversationRead, setConversation, updateConversation } from 'workchat/actions/conversations';
import { updateReadStatus } from 'workchat/actions/index';
import { track } from 'workchat/actions/tracking';

export function receiveMessage(msg) {
  return (dispatch, getState) => {
    const conversations = getState().workchat.get('conversations');
    const conv = conversations.get(msg.conversation);
    if (!conv) {
      return;
    }

    const currentConv = getState().workchat.get('conversation');
    const isCurrent = currentConv ? currentConv.id === conv.id : false;

    if (!conv.latestMessage || conv.latestMessage.index < msg.index) {
      conv.latestMessage = msg;
    }

    if (msg.text || msg.image) {
      msg.tempId = msg.attributes.tempId;
      const [pending, removed] = removePending(conv.pending, msg);
      conv.pending = pending;
      if (removed?.image && msg.image) {
        msg.image.dataUrl = removed.image;
      }
    }

    dispatch(updateConversation(conv));

    if (isCurrent) {
      dispatch(setConversation(conv));
      dispatch(markConversationRead());
    }

    dispatch(updateReadStatus());
  };
}

export function sendingMessage(conv, msg) {
  return dispatch => {
    dispatch(track('WorkChat: Message Sent'));

    conv.pending = conv.pending.push(msg);
    dispatch(setConversation(conv));
  };
}

function removePending(pending, msg) {
  let removed = null;
  pending = pending.filter(m => {
    if (m.id !== msg.tempId) {
      return true;
    }
    removed = m;
    return false;
  });
  return [pending, removed];
}

export function sentMessage(_conv, _msg) {
  return () => {};
}

export function failedMessage(conv, msg) {
  const [pending, removed] = removePending(conv.pending, msg);
  conv.pending = pending;

  if (removed) {
    removed.sent = moment();
    conv.failed = conv.failed.push(removed);
  }
  return setConversation(conv);
}

export function setMessageReceipts(readers) {
  return {
    type: WORKCHAT_MESSAGE_RECEIPTS,
    readers,
  };
}
