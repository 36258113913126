import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import { List, Map, Record, Stack } from 'immutable';

import EntityState from 'data/EntityState';
import type User from 'data/user/model';
import { VIEW_CONVERSATION_LIST } from 'workchat/v2/components/ConversationListV2/ConversationListV2';
import {
  WorkchatAuthInitialState,
  type WorkchatAuthState,
  workchatAuthSlice,
} from 'workchat/v2/store/auth/authReducer';
import {
  WorkchatConversationInitialState,
  deleteConversation,
  workchatConversationsSlice,
} from 'workchat/v2/store/conversations/conversationsReducer';
import { WorkchatMessagesInitialState, workchatMessagesSlice } from 'workchat/v2/store/messages/messagesReducer';
import type { WorkchatParticipant } from 'workchat/v2/store/participants/participantsActions';
import { workchatParticipantsSlice } from 'workchat/v2/store/participants/participantsReducer';
import { workchatUsersSlice } from 'workchat/v2/store/users/usersReducer';

export interface WorkchatModel {
  title?: string;
  text?: string;
  confirm?: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export interface WorkchatState {
  auth: WorkchatAuthState;
  flags: Map<string, boolean>;
  connected: boolean;
  conversation?: string | null;
  conversations: WorkchatConversationInitialState;
  messages: WorkchatMessagesInitialState;
  messageReaders: number[] | null;
  pendingConversation: { userIds: number[]; title: string } | null;
  views: Stack<string>;
  sending: boolean;
  show: boolean;
  participants: EntityState<WorkchatParticipant>;
  users: EntityState<User>;
  pending: List<any>;
  failed: List<any>;
  initError: string | null;
  error: string | null;
}

const createInitialViewStack = () => Stack<string>([VIEW_CONVERSATION_LIST]);

export class WorkchatInitialState extends Record<WorkchatState>({
  auth: new WorkchatAuthInitialState(),
  flags: Map(),
  connected: false,
  conversation: null,
  conversations: new WorkchatConversationInitialState(),
  messages: new WorkchatMessagesInitialState(),
  pendingConversation: null,
  views: createInitialViewStack(),
  sending: false,
  show: false,
  participants: new EntityState<WorkchatParticipant>(),
  users: new EntityState<User>(),
  pending: List(),
  failed: List(),
  messageReaders: [],
  initError: null,
  error: null,
}) {}

export const workchatSlice = createSlice({
  name: 'workchatv2',
  initialState: new WorkchatInitialState(),
  reducers: {
    startWorkchat: (state, action: PayloadAction<{ user: User; flags: Map<string, boolean> }>) => {
      return state.withMutations(map => {
        map.set('connected', true);
        map.set('views', createInitialViewStack());
        map.merge({ flags: action.payload.flags });
        map.set('error', null);
      });
    },
    setConnected: state => {
      return state.set('connected', true);
    },
    setDisconnected: state => {
      return state.set('connected', false);
    },
    toggleShow: (state, action: PayloadAction<boolean>) => {
      const show = action.payload !== undefined ? action.payload : !state.get('show');
      return state.set('show', show);
    },
    setError: (state, action: PayloadAction<string>) => {
      return state.set('error', action.payload);
    },
    retryError: state => {
      return state;
    },
    viewStackPush: (state, action: PayloadAction<string>) => {
      return state.set('views', state.views.push(action.payload));
    },
    viewStackPop: state => {
      return state.set('views', state.views.size > 0 ? state.views.pop() : createInitialViewStack());
    },
    viewStackReset: state => {
      return state.set('views', createInitialViewStack());
    },
    setConversation: (state, action: PayloadAction<string | null>) => {
      return state.set('conversation', action.payload);
    },
    setPendingConversation: (state, action: PayloadAction<{ userIds: number[]; title: string } | null>) => {
      if (!action.payload) {
        return state.set('pendingConversation', null);
      }

      const { userIds } = action.payload;
      return state.set('pendingConversation', userIds?.length ? action.payload : null);
    },
    sendMessage: state => {
      return state.set('sending', true);
    },
    sentMessage: state => {
      return state.set('sending', false);
    },
    sendError: (state, action: PayloadAction<string>) => {
      return state.set('sending', false).set('error', action.payload);
    },
    setMessageReaders: (state, action: PayloadAction<number[]>) => {
      return state.set('messageReaders', action.payload || []);
    },
    setInitError: (state, action: PayloadAction<string>) => {
      return state.set('initError', action.payload);
    },
    clearInitError: state => {
      return state.set('initError', null);
    },
    clearError: state => {
      return state.set('error', null);
    },
    reset: state => {
      return new WorkchatInitialState({
        show: state.show,
        views: createInitialViewStack(),
        auth: state.auth,
        initError: null,
      });
    },
  },
  extraReducers: builder => {
    builder.addCase(deleteConversation, (state, action) => {
      let newState = state;

      if (state.conversation === action.payload) {
        newState = newState.set('conversation', null);
      }
      // If we're not in the Conversation List, reset view to Conversation List
      if (state.views.first() !== VIEW_CONVERSATION_LIST) {
        newState = newState.set('views', createInitialViewStack());
      }
      return newState;
    });

    builder.addMatcher(
      action => action.type.startsWith(workchatAuthSlice.name),
      (state, action) => state.set('auth', workchatAuthSlice.reducer(state.auth as WorkchatAuthInitialState, action)),
    );
    builder.addMatcher(
      action => action.type.startsWith(workchatConversationsSlice.name),
      (state, action) => state.set('conversations', workchatConversationsSlice.reducer(state.conversations, action)),
    );
    builder.addMatcher(
      action => action.type.startsWith(workchatParticipantsSlice.name),
      (state, action) => state.set('participants', workchatParticipantsSlice.reducer(state.participants, action)),
    );
    builder.addMatcher(
      action => action.type.startsWith(workchatMessagesSlice.name),
      (state, action) => state.set('messages', workchatMessagesSlice.reducer(state.messages, action)),
    );
    builder.addMatcher(
      action => action.type.startsWith(workchatUsersSlice.name),
      (state, action) => state.set('users', workchatUsersSlice.reducer(state.users, action)),
    );
  },
});

export const {
  startWorkchat,
  setConnected,
  setDisconnected,
  toggleShow,
  setError,
  retryError,
  viewStackPush,
  viewStackPop,
  viewStackReset,
  setConversation,
  setPendingConversation,
  sendMessage,
  sentMessage,
  sendError,
  clearError,
  setInitError,
  clearInitError,
  setMessageReaders,
  reset,
} = workchatSlice.actions;

export default workchatSlice.reducer;
