// istanbul ignore next
export const addVendorScript = (src, options = {}) => {
  options = {
    async: false,
    tag: 'script',
    type: 'text/javascript',
    parentNode: null,
    ...options,
  };
  options.parentNode = options.parentNode || document.getElementsByTagName(options.tag)[0]?.parentNode || document.body;

  return new Promise((resolve, reject) => {
    const element = document.createElement(options.tag);
    let done = false;

    element.src = src;
    element.async = !!options.async;
    element.type = options.type;

    if (options.id) {
      element.id = options.id;
    }

    // Attach handlers for all browsers.
    // References:
    // https://github.com/MiguelCastillo/load-js/blob/master/src/load-js.js
    // http://stackoverflow.com/questions/4845762/onload-handler-for-script-tag-in-internet-explorer
    element.onload = element.onreadystatechange = () => {
      if (!done && (!element.readyState || element.readyState === 'loaded' || element.readyState === 'complete')) {
        done = true;
        // Handle memory leak in IE
        element.onload = element.onreadystatechange = null;
        resolve(element);
      }
    };
    element.onerror = reject;

    options.parentNode.appendChild(element);
  });
};

export default addVendorScript;
