import PropTypes from 'prop-types';
import { Component, createRef } from 'react';

import InputWrapper from 'shared/components/form/wrappers/InputWrapper';
import { withIntl } from 'shared/i18n';
import Select from 'shared/ui/inputs/Select';

import * as chrono from 'chrono-node';
import classnames from 'classnames';
import { propTypes, withFormsy } from 'formsy-react';
import moment from 'moment-timezone';

export class TimeInput extends Component {
  today = moment();

  //refs
  start = createRef();
  end = createRef();

  state = {
    start: '9:00AM',
    end: '5:00PM',
  };

  static propTypes = {
    ...propTypes,
    label: PropTypes.any,
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.oneOf(['auto'])]),
    value: PropTypes.shape({
      start: PropTypes.any,
      end: PropTypes.any,
    }),
    type: PropTypes.string,
    mask: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    inputProps: PropTypes.object,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    newStyle: PropTypes.bool,
    noCrossMidnight: PropTypes.bool,
    divider: PropTypes.node,
    formatTimeLong: PropTypes.func,
    onChange: PropTypes.func,
    validatePristine: PropTypes.bool,
    validations: PropTypes.object,
    validationErrors: PropTypes.object,
  };

  static defaultProps = {
    type: 'text',
    width: false,
    disabled: false,
    required: false,
    newStyle: false,
    divider: String.fromCharCode(8211), // &ndash;
    noCrossMidnight: false,
    validatePristine: false,
    onChange: () => {},
  };

  UNSAFE_componentWillMount() {
    this.parseDateProps(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.parseDateProps(nextProps);
  }

  parseDateProps = props => {
    if (Object.keys(props.value).length) {
      const { start, end } = props.value;
      const startString = this.getTimeValue(start);
      const endString = this.getTimeValue(end);
      if (start !== startString || end !== endString) {
        const times = {
          start: this.getTimeValue(start),
          end: this.getTimeValue(end),
        };
        this.setState(times);
        props.setValue(times);
      }
    }
  };

  getTimeValue = val => {
    if (val instanceof moment) {
      val = val.toDate();
    }
    if (val instanceof Date) {
      return this.props.formatTimeLong(val);
    }
    return val;
  };

  handleChange = position => option => {
    if (!option) {
      return;
    }

    this.setState({ [position]: option.value });
    this.props.onChange(position, option.value);
    this.props.setValue({ ...this.props.value, [position]: option.value });

    if (this.end.current) {
      this.end.current.focus();
    }
  };

  cleanInput(str) {
    return str.replace(/[^0-9:apm ]+/i, '');
  }

  isOverNight(time) {
    const startTime = moment(chrono.parseDate(this.state.start, this.today));
    return time.isSameOrBefore(startTime, 'minutes');
  }

  getHours(field) {
    const times = [];
    const today = moment();
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 4; minute++) {
        today.set({ hour, minute: minute * 15 });
        const formattedTime = this.props.formatTimeLong(today);
        const disableTime = this.props.noCrossMidnight && field === 'end' && this.isOverNight(today);
        times.push({ label: formattedTime, value: formattedTime, isDisabled: disableTime });
      }
    }

    // add fake 11:59 option so end value isnt blank in availability for all day events
    if (field === 'end' && this.props.value[field] === '11:59PM') {
      times.push({ label: '11:59PM', value: '11:59PM' });
    }

    return times;
  }

  filter = (option, input) => {
    const term = this.cleanInput(input);
    let item = option.label.toLowerCase();
    if (input.match(':')) {
      return item.search(`0?${term}`) === 0;
    }
    if (input.match(/[ap]m?$/)) {
      item = item.replace(':00', '');
    }
    return item.replace(':', '').search(`0?${term}`) === 0;
  };

  buildSelect(position, invalid) {
    const inputClassname = classnames('col', this.props.className, {
      'form-control-danger': invalid,
      newStyle: this.props.newStyle,
    });

    return (
      <Select
        name={`${this.props.name}_${position}`}
        selectRef={this[position]}
        className={inputClassname}
        isClearable={false}
        placeholder="2:00PM"
        components={{ IndicatorsContainer: () => null }}
        value={this.state[position] || ''}
        isDisabled={this.props.isFormDisabled || this.props.disabled}
        required={this.props.required}
        options={this.getHours(position)}
        filterOption={this.filter}
        onChange={this.handleChange(position)}
        validations={this.props.validations}
        validationErrors={this.props.validationErrors}
      />
    );
  }

  renderInput(invalid) {
    return (
      <div className="row no-gutters time-input-container">
        {this.buildSelect('start', invalid)}
        <span className="divider">{this.props.divider}</span>
        {this.buildSelect('end', invalid)}
      </div>
    );
  }

  render() {
    const invalid =
      (this.props.showError || !this.props.isValid) && (this.props.validatePristine || !this.props.isPristine);

    if (this.props.type === 'hidden') {
      return this.renderInput(invalid);
    }

    return (
      <InputWrapper {...this.props} classNames={classnames(this.props.className, 'time-input')}>
        {this.renderInput(invalid)}
      </InputWrapper>
    );
  }
}
/**
 * @deprecated please migrate to hook-form from shared/form/inputs
 */
export default withFormsy(withIntl(TimeInput));
