import { type FieldValues, type UseFormProps, type UseFormReturn, useForm as useHookForm } from 'react-hook-form';

const configDefault: UseFormProps = {
  mode: 'onTouched',
};

export const useForm = <T extends FieldValues = FieldValues>(config?: UseFormProps<T>): UseFormReturn<T> => {
  return useHookForm<T>({
    ...(configDefault as UseFormProps<T>),
    ...config,
  });
};
