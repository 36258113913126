import type { StripePaymentMethod } from 'data/stripe/types/paymentMethod';
import Billing from 'shared/api/billing';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type DetachPaymentMethodArgs = string;

export type DetachPaymentMethodResponse = {
  data: StripePaymentMethod;
};

export const detachPaymentMethod = createWiwAsyncThunk<DetachPaymentMethodResponse, DetachPaymentMethodArgs>(
  'stripe/paymentMethods/detach',
  async (payment_method_id, { rejectWithValue }) => {
    try {
      return (await Billing.url(`/payment-methods/${payment_method_id}`).delete()) as DetachPaymentMethodResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
