import type { FC } from 'react';
import { Redirect } from 'react-router-dom';

import type Account from 'data/account/model';
import { getAuthAccount } from 'shared/auth/selectors';
import NotAllowed from 'shared/components/NotAllowed';
import { useWiwSelector } from 'store';

export default function ShouldPayrollOnboard(ComposedComponent: FC) {
  return function ShouldPayrollOnboard(props?: any) {
    const account = useWiwSelector<Account>(getAuthAccount);

    if (account.isPayrollOnboarded()) {
      return <Redirect to="/payroll/overview" />;
    }

    if (!account.isPayrollRequested()) {
      return <NotAllowed />;
    }

    return <ComposedComponent {...props} />;
  };
}
