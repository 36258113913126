import Shift from 'data/shift/model';
import { UPDATE_SWAP_SHIFT } from 'store/action-types';
import SwapShiftState from './state';

export default (state = new SwapShiftState(), action) => {
  switch (action.type) {
    case UPDATE_SWAP_SHIFT:
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        receivedAt: Date.now(),
        items: state.items.delete(null).merge(action.shifts.map(shift => [shift.id, new Shift(shift)])),
      });
    default:
      return state;
  }
};
