import { Set } from 'immutable';
import { useEffect, useState } from 'react';

import { fetchLocations } from 'data/location/actions/fetchLocations';
import { getLocations, locationsLoaded } from 'data/location/selectors';
import { fetchPositions } from 'data/position/actions/fetchPositions';
import { getPositions, positionsLoaded } from 'data/position/selectors';
import SpinningLoader from 'shared/ui/loaders/SpinningLoader';
import { useWiwDispatch, useWiwSelector } from 'store';

interface UserPickerFiltersProps {
  onPositionFiltersChange: (filters: Set<number>) => void;
  onLocationFiltersChange: (filters: Set<number>) => void;
}

export default function UserPickerFilters({
  onPositionFiltersChange,
  onLocationFiltersChange,
}: UserPickerFiltersProps) {
  const dispatch = useWiwDispatch();

  const locations = useWiwSelector(getLocations);
  const isLocationsLoaded = useWiwSelector(locationsLoaded);

  const positions = useWiwSelector(getPositions);
  const isPositionsLoaded = useWiwSelector(positionsLoaded);

  const [filterLocations, setFilterLocations] = useState(Set<number>());
  const [filterPositions, setFilterPositions] = useState(Set<number>());
  const [error, setError] = useState<string>();

  useEffect(() => {
    Promise.all([dispatch(fetchLocations()).unwrap(), dispatch(fetchPositions()).unwrap()]).catch(() => {
      setError('Unable to load filters. Try again.');
    });
  }, []);

  const clearFilters = (e: React.MouseEvent) => {
    e.preventDefault();

    const emptyLocations = Set<number>();
    setFilterLocations(emptyLocations);
    onLocationFiltersChange(emptyLocations);

    const emptyPositions = Set<number>();
    setFilterPositions(emptyPositions);
    onPositionFiltersChange(emptyPositions);
  };

  const togglePosition = (id: number, _e: React.MouseEvent) => {
    const newFilterPositions = filterPositions.has(id) ? filterPositions.delete(id) : filterPositions.add(id);
    setFilterPositions(newFilterPositions);
    onPositionFiltersChange(newFilterPositions);
  };

  const toggleLocation = (id: number, _e: React.MouseEvent) => {
    const newFilterLocations = filterLocations.has(id) ? filterLocations.delete(id) : filterLocations.add(id);
    setFilterLocations(newFilterLocations);
    onLocationFiltersChange(newFilterLocations);
  };

  const renderSpinner = () => (
    <div className="spinner">
      <SpinningLoader width={14} height={17} />
    </div>
  );

  const renderPositionFilters = () => {
    return (
      <>
        <h4 className="positions">Positions</h4>
        {!isPositionsLoaded
          ? renderSpinner()
          : positions
              ?.map(position => {
                if (position.is_deleted) {
                  return null;
                }

                return (
                  <button
                    key={`filter-pos-${position.id}`}
                    type="button"
                    className={filterPositions.has(position.id) ? 'selected' : ''}
                    onClick={e => togglePosition(position.id, e)}
                  >
                    <span style={{ borderColor: `#${position.color}` }} />
                    {position.name}
                  </button>
                );
              })
              .valueSeq()}
      </>
    );
  };

  const renderScheduleFilters = () => {
    return (
      <>
        <h4 className="locations">Schedules</h4>
        {!isLocationsLoaded
          ? renderSpinner()
          : locations
              ?.map(location => {
                if (location.is_deleted) {
                  return null;
                }

                return (
                  <button
                    key={`filter-loc-${location.id}`}
                    type="button"
                    className={filterLocations.has(location.id) ? 'selected' : ''}
                    onClick={e => toggleLocation(location.id, e)}
                  >
                    <span />
                    {location.name}
                  </button>
                );
              })
              .valueSeq()}
      </>
    );
  };

  const renderFilters = () => {
    if (error) {
      return (
        <div className="px-5 py-5 text-center error-text">
          <h5>{error}</h5>
        </div>
      );
    }

    return (
      <>
        {renderPositionFilters()}
        {renderScheduleFilters()}
      </>
    );
  };

  return (
    <div className="filters">
      <div className="arrow" />
      <h3>
        <button type="button" onClick={clearFilters}>
          Clear All
        </button>
        Filters
      </h3>
      {renderFilters()}
    </div>
  );
}
