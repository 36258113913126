export default function isUnique(values, value, options) {
  if (!value.length) {
    return true;
  }
  const { name, regex } = options;
  let unique = true;
  Object.entries(values).forEach(([key, otherValue]) => {
    if (regex.test(key) && key !== name && otherValue === value) {
      unique = false;
    }
  });
  return unique;
}
