import PropTypes from 'prop-types';
import { Component, createRef } from 'react';

import InputWrapper from 'shared/components/form/wrappers/InputWrapper';

import classnames from 'classnames';
import { propTypes, withFormsy } from 'formsy-react';

/**
 * @example <FileInput name="test">
 { (openFilePicker, invalid, errorMessages) => {
   return (
     <label onClick={ openFilePicker }>hi{errorMessages}</label>
   );
 } }
 </FileInput>
 * @example <FileInput name="test" label="Gimme your files" />
 */
export class FileInput extends Component {
  fileInput = createRef();

  static propTypes = {
    ...propTypes,
    label: PropTypes.any,
    labelAddon: PropTypes.any,
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.oneOf(['auto'])]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    inputProps: PropTypes.object,
    disabled: PropTypes.bool,
    labelSmall: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    onChange: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    accept: PropTypes.string,
    validatePristine: PropTypes.bool,
  };

  static defaultProps = {
    width: false,
    disabled: false,
    labelAddon: false,
    labelSmall: false,
    children: false,
    validatePristine: false,
    onChange: () => {},
  };

  state = {
    focus: false,
    mockValue: '',
  };

  modifyValue = e => {
    const value = e.target.files[0];

    this.setState({ mockValue: e.target.value });
    this.props.onChange(this.props.name, value);
    return this.props.setValue(value);
  };

  renderInput(invalid) {
    const inputClassname = classnames('form-control', this.props.className, {
      'form-control-danger': invalid,
      'd-none': this.props.children,
    });

    return (
      <input
        type="file"
        ref={this.fileInput}
        name={this.props.name}
        className={inputClassname}
        value={this.state.mockValue}
        accept={this.props.accept}
        disabled={this.props.isFormDisabled || this.props.disabled}
        onFocus={() => this.setState({ focus: true })}
        onBlur={e => {
          this.modifyValue(e);
          this.setState({ focus: false });
        }}
        onChange={this.modifyValue}
        {...this.props.inputProps}
      />
    );
  }

  render() {
    const invalid =
      (this.props.showError || !this.props.isValid) && (this.props.validatePristine || !this.props.isPristine);
    const openFilePicker = () => this.fileInput.current.click();
    if (this.props.children) {
      return (
        <div>
          {this.renderInput(invalid)}
          {this.props.children(openFilePicker, invalid, this.props.errorMessage)}
        </div>
      );
    }

    return (
      <InputWrapper focus={this.state.focus} {...this.props}>
        {this.renderInput(invalid)}
      </InputWrapper>
    );
  }
}
/**
 * @deprecated please migrate to hook-form from shared/form/inputs
 */
export default withFormsy(FileInput);
