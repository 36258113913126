import type { MouseEventHandler } from 'react';
import Button from 'shared/ui/Button';
import FontIcon from 'shared/ui/FontIcon';
import 'payrollCheck/modules/onboarding/assets/styles/PayrollOnboardCard.scss';

export enum CardState {
  none = 0,
  started = 1,
  completed = 2,
}

interface Props {
  title: string;
  state: CardState;
  target: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  editable?: boolean;
  loading?: boolean;
}

export default function PayrollOnboardCard(props: Props) {
  const { loading = false } = props;
  const buttonOrCompleted = () => {
    const elements: JSX.Element[] = [];

    if (props.state === CardState.completed) {
      elements.push(
        <div className="completed" key="card_completed">
          <FontIcon icon="checkmark-circled" /> Complete
        </div>,
      );
    }

    if (props.state === CardState.none || props.state === CardState.started) {
      elements.push(
        <Button
          className={props.disabled ? 'hint hint--left' : ''}
          size="md"
          onClick={props.target}
          aria-label="Disabled due to error"
          key="card_button"
          disabled={props.disabled}
          loading={loading}
        >
          START
        </Button>,
      );
    }

    if (props.state === CardState.completed && props.editable) {
      elements.push(
        <Button
          className="edit-button"
          color="secondary"
          size="md"
          onClick={props.target}
          key="card_button"
          loading={loading}
        >
          EDIT
        </Button>,
      );
    }

    return elements;
  };

  return (
    <div className="onboard-card" id="onboard-card">
      <div className="card-title" key="card_title">
        <p>{props.title}</p>
      </div>
      <div className="card-state" key="card_state">
        {buttonOrCompleted()}
      </div>
    </div>
  );
}
