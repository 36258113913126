import BaseModel from 'data/BaseModel';

import { Map } from 'immutable';
import { isEqual } from 'lodash';

interface ForecastItemDataFields {
  target: number;
  estimate?: number;
}

export interface ForecastItemFields {
  accountId: string | null;
  data: Map<string, ForecastItemDataFields>;
  date: string | null;
  locationId: string | null;
}

type ForecastItemFieldsApi = Override<ForecastItemFields, { data: object }>;

export enum ForecastItemType {
  Sales = 'sales',
  Hours = 'hours',
}

export enum ForecastItemDataKeys {
  Estimate = 'estimate',
  Target = 'target',
}

export default class ForecastItem extends BaseModel<ForecastItemFields>({
  accountId: null,
  data: Map(),
  date: null,
  locationId: null,
}) {
  static Type = {
    SALES: ForecastItemType.Sales,
    HOURS: ForecastItemType.Hours,
  } as const;

  static DataKeys = {
    ESTIMATE: ForecastItemDataKeys.Estimate,
    TARGET: ForecastItemDataKeys.Target,
  } as const;

  static MaxValue = 9_999_999_999 as const;

  /**
   * this.data = {
   *   [string | ForecastItemType.Sales | ForecastItemType.Hours]: {
   *     [ForecastItem.DataKeys.TARGET, ForecastItem.DataKeys.ESTIMATE]: (int value)
   *   }
   * }
   * @param args
   */
  constructor(args: Partial<ForecastItemFieldsApi | ForecastItemFields> = {}) {
    let data: ForecastItemFields['data'] = Map({});
    if (args.data) {
      data = Map(args.data);
    }

    super({ ...args, data });
  }

  // TODO(types)
  // This will take some doing to fix for TypeScript because it doesn't like that
  // this is a getter overriding a method.
  // @ts-ignore
  get date() {
    return this.get('date');
  }

  setEstimate(type: ForecastItemType, value: number) {
    return this.setItem(ForecastItemDataKeys.Estimate, type, value);
  }

  // ESLint doesn't understand TypeScript enums I guess? It just says `Sales` is not
  // defined when it clearly is.
  // eslint-disable-next-line
  getEstimate(type: ForecastItemType = ForecastItemType.Sales) {
    return this.getItem(ForecastItemDataKeys.Estimate, type);
  }

  setTarget(type: ForecastItemType | string, value: number) {
    return this.setItem(ForecastItemDataKeys.Target, type, value);
  }

  // eslint-disable-next-line
  getTarget(type: string | ForecastItemType = ForecastItemType.Sales, apiValue = false) {
    return this.getItem(ForecastItemDataKeys.Target, type, apiValue);
  }

  setItem(dataKey: ForecastItemDataKeys, type: string | ForecastItemType, value: number) {
    const object = this.getIn(['data', type], {});
    return this.setIn(
      ['data', type],
      Map({
        ...(object as object),
        [dataKey]: value,
      }),
    );
  }

  getItem(dataKey: ForecastItemDataKeys, type: string | ForecastItemType, apiValue = false) {
    const data = this.get('data');
    const object = data.get<Partial<ForecastItemDataFields>>(type, {});

    let value;
    if (Map.isMap(object)) {
      value = (object as Map<ForecastItemDataKeys, ForecastItemDataFields>).get(dataKey);
    } else {
      value = object[dataKey];
    }

    if (apiValue) {
      return value;
    }

    return value || 0;
  }

  isValid() {
    // This covers custom units, which have a custom type (UUID string)
    // Loop through items in the data array and determine if we've got any estimates or targets set
    const data = this.get('data');
    const estimatesOrTargets = data.some((value, key) => {
      if (value) {
        if (key === ForecastItemType.Sales) {
          // Typescript doesn't think value has a .get function here, but it does if the isMap check passes
          // @ts-ignore
          return Map.isMap(value) ? !!value.get(ForecastItemDataKeys.Estimate) : !!value[ForecastItemDataKeys.Estimate];
        }
        // @ts-ignore
        return Map.isMap(value) ? !!value.get(ForecastItemDataKeys.Target) : !!value[ForecastItemDataKeys.Target];
      }

      return false;
    });

    return !!this.date && estimatesOrTargets;
  }

  equals(other: ForecastItem) {
    return this.date === other.date && isEqual(this.data.toJS(), other.data.toJS());
  }

  getTypes() {
    return this.data.keySeq().toArray();
  }
}
