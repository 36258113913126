import type { AccountFields } from 'data/account/model';
import fetchPlans from 'data/plan/actions/fetchPlans';
import type { StripeSubscription } from 'data/stripe/types/subscriptions';
import Billing from 'shared/api/billing';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

export enum UpdateSubscriptionActionValues {
  CHANGE_PLAN = 'changePlan',
  MANAGE_SEATS = 'manageSeats',
  RENEW_PLAN = 'renewPlan',
  REACTIVATE = 'reactivate',
}

export type UpdateSubscriptionAction = UpdateSubscriptionActionValues[keyof UpdateSubscriptionActionValues];

export type UpdateSubscriptionArgs = {
  quantity: number;
  items: ({ price_id: string } | { id: string })[];
  action?: UpdateSubscriptionAction;
  proration_date: number | null;
  pricing_group: string | null;
  // Include plan_id and billing_type as we want to move away from sending prices. We're doing
  // extra work on the backend to determine the plan and interval from the provided prices.
  plan_id?: number;
  billing_type?: number;
  promo_code: string | null;
};

type UpdateSubscriptionResponse = {
  subscription: StripeSubscription;
  account: AtLeast<AccountFields, 'id'>;
};

export const updateSubscription = createWiwAsyncThunk<UpdateSubscriptionResponse, UpdateSubscriptionArgs>(
  'stripe/subscriptions/update',
  async (updatePayload, { rejectWithValue, dispatch }) => {
    try {
      const subscriptionRaw = (await Billing.url('/subscriptions').put(updatePayload)) as {
        data: UpdateSubscriptionResponse;
      };
      await dispatch(fetchPlans());
      return subscriptionRaw.data;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
