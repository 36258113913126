import BaseModel from 'data/BaseModel';
import type { StripeCustomer as StripeCustomerI } from 'data/stripe/types/customer';
import { centsToDollars } from 'data/stripe/utils/converters';

import type { PaymentMethod } from '@stripe/stripe-js';
import type Stripe from 'stripe';

export interface StripeCustomerFields {
  id: StripeCustomerI['id'];
  object: StripeCustomerI['object'];
  address: StripeCustomerI['address'];
  balance: StripeCustomerI['balance'];
  created: StripeCustomerI['created'];
  currency: StripeCustomerI['currency'];
  default_source: StripeCustomerI['default_source'];
  delinquent: StripeCustomerI['delinquent'];
  description: StripeCustomerI['description'];
  discount: StripeCustomerI['discount'];
  email: StripeCustomerI['email'];
  invoice_prefix: StripeCustomerI['invoice_prefix'];
  livemode: StripeCustomerI['livemode'];
  metadata: StripeCustomerI['metadata'];
  name: StripeCustomerI['name'];
  next_invoice_sequence: StripeCustomerI['next_invoice_sequence'];
  phone: StripeCustomerI['phone'];
  preferred_locales: StripeCustomerI['preferred_locales'];
  shipping: StripeCustomerI['shipping'];
  tax_exempt: StripeCustomerI['tax_exempt'];
  test_clock: StripeCustomerI['test_clock'];
  billing_details: {
    id: string | null;
    card?: PaymentMethod.Card | Stripe.Card;
    bank?: PaymentMethod.UsBankAccount;
    address: PaymentMethod.BillingDetails['address'];
    name: string | null;
    phone: string | null;
    email: string | null;
  };
}

export default class StripeCustomer extends BaseModel<StripeCustomerFields>({
  id: '',
  object: 'customer',
  address: null,
  balance: 0,
  created: 0,
  currency: '',
  default_source: null,
  delinquent: false,
  description: '',
  discount: null,
  email: null,
  invoice_prefix: '',
  livemode: false,
  metadata: {},
  name: '',
  next_invoice_sequence: 0,
  phone: null,
  preferred_locales: [],
  shipping: null,
  tax_exempt: null,
  test_clock: null,
  billing_details: {} as StripeCustomerFields['billing_details'],
}) {
  constructor(args: StripeCustomerI) {
    const paymentMethod = args.invoice_settings?.default_payment_method as PaymentMethod;
    const defaultSource = args.default_source as Stripe.CustomerSource;
    if (!paymentMethod && !defaultSource) {
      super(args);
      return;
    }

    let billingDetails = {} as StripeCustomerFields['billing_details'];

    if (paymentMethod) {
      billingDetails = {
        id: paymentMethod.id,
        card: paymentMethod.card,
        bank: paymentMethod.us_bank_account,
        address: paymentMethod.billing_details.address,
        name: paymentMethod.billing_details.name,
        phone: paymentMethod.billing_details.phone,
        email: paymentMethod.billing_details.email,
      };
      if (billingDetails.address && paymentMethod.card) {
        billingDetails.address.country = paymentMethod.billing_details.address?.country || paymentMethod.card.country;
      }
    } else if (defaultSource) {
      // We only support card when default_source is set from migrated customers
      if (defaultSource.object !== 'card') {
        super(args);
        return;
      }

      billingDetails = {
        id: defaultSource.id,
        card: defaultSource,
        address: {
          city: defaultSource.address_city,
          country: defaultSource.address_country || defaultSource.country,
          line1: defaultSource.address_line1,
          line2: defaultSource.address_line2,
          postal_code: defaultSource.address_zip,
          state: defaultSource.address_state,
        },
        name: defaultSource.name,
        phone: null,
        email: null,
      };
    }
    super({ ...args, ...{ billing_details: billingDetails } });
  }

  hasBillingInfo() {
    return !!this?.billing_details?.address;
  }

  paymentMethod() {
    let paymentMethod = '';
    if (this.billing_details.card) {
      paymentMethod = 'card';
    }
    if (this.billing_details.bank) {
      paymentMethod = 'bank';
    }
    return paymentMethod;
  }

  billingName() {
    return this?.billing_details?.name || '';
  }

  billingPhone() {
    return this?.billing_details?.phone || '';
  }

  billingEmail() {
    return this?.billing_details?.email || '';
  }

  billingAddress() {
    return (
      this?.billing_details?.address || {
        line1: '',
        line2: null,
        city: '',
        state: '',
        postal_code: '',
        country: '',
      }
    );
  }

  getAccountBalance() {
    return centsToDollars(this.balance);
  }
}
