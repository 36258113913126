import PropTypes from 'prop-types';
import { Component } from 'react';

import classnames from 'classnames';

export class ActionButton extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    icon: PropTypes.string,
    title: PropTypes.string.isRequired,
    click: PropTypes.func,
  };

  actionAttributes = () => {
    if (this.props.click) {
      return { onClick: this.props.click };
    }
  };

  stopClickFocus = e => {
    e.preventDefault();
  };

  render() {
    return (
      <button
        type="button"
        onMouseDown={this.stopClickFocus}
        className={classnames('btn', 'action-button', this.props.type)}
        {...this.actionAttributes()}
      >
        {this.props.icon && <i className={`fa ${this.props.icon}`} />}
        {this.props.title}
      </button>
    );
  }
}
