import UserConcernsList from 'data/scheduleRule/UserConcernsList';
import RuleState from 'data/scheduleRule/state';
import { toEntityMap } from 'shared/util/toEntityMap';
import { FETCH_USER_RULES } from 'store/action-types';

export default (state = new RuleState(), action) => {
  switch (action.type) {
    case FETCH_USER_RULES.REQUEST:
      return state.merge({
        loading: true,
        receivedAt: 0,
        loaded: false,
      });
    case FETCH_USER_RULES.SUCCESS:
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        receivedAt: Date.now(),
        items: state.items.mergeDeep(toEntityMap(action.items.users, UserConcernsList, 'userId')), //maybe use mergeDeepWith to merge the rules?
      });
    case FETCH_USER_RULES.FAILURE:
      return state.merge({
        loaded: false,
        loading: false,
        error: action.payload,
      });
    default:
      return state;
  }
};
