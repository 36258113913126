import { HttpErrorCodes } from 'shared/redux/helpers/HttpErrorCodes';

import type { WretchError } from 'wretch';

export type GoErrorSingle = {
  code: string;
  message: string;
  data?: {};
};

export type GoErrorMulti = {
  errors: GoErrorSingle[];
};

export type MonolithError = {
  error: string;
  code: number;
};

type MonolithErrorMulti = MonolithError[];

type V3MonolithError = {
  error: {
    title: string;
    code: string;
  };
};

export type NormalizedApiError = {
  message: string;
  code: number;
  original: Error | Error[];
  data: {};
};

type ErrorStack = {
  message: string[];
  code: number[];
  errors: Error[];
  data?: {};
};

function isV3MonolithError(error: ApiError): error is V3MonolithError {
  return 'error' in error && typeof error.error === 'object' && !('code' in error);
}

function isMonolithMultiError(error: ApiError): error is MonolithErrorMulti {
  return Array.isArray(error);
}

function isGoErrorMulti(error: ApiError): error is GoErrorMulti {
  return 'errors' in error;
}

function isGoSingleError(error: ApiError): error is GoErrorSingle {
  return 'message' in error;
}

const normalizedApiError = (msg: string, code: number, error: Error | Error[], data = {}): NormalizedApiError => {
  return {
    message: msg,
    code: Number.isNaN(code) ? 0 : code,
    original: error,
    data,
  };
};

type ApiError = V3MonolithError | MonolithError | MonolithErrorMulti | GoErrorSingle | GoErrorMulti;

export const parseAPIError = (error: WretchError | any) => {
  try {
    const errorBody: ApiError = error.json ?? error;

    if (isV3MonolithError(errorBody)) {
      const v3Error = errorBody.error;
      return normalizedApiError(v3Error.title, Number.parseInt(v3Error.code, 10), error);
    }

    if (isMonolithMultiError(errorBody)) {
      const { message, code, errors } = errorBody.reduce(
        (stack: ErrorStack, err) => {
          if (stack.code.includes(err.code)) {
            // Drop duplicate errors
            return stack;
          }
          stack.message.push(err.error);
          stack.code.push(err.code);
          stack.errors.push(new Error(err.error));
          return stack;
        },
        { message: [], code: [], errors: [] },
      );

      return normalizedApiError(message.join('\n'), code[0], errors);
    }

    if (isGoErrorMulti(errorBody)) {
      const { message, code, errors, data } = errorBody.errors.reduce(
        (stack: ErrorStack, err: GoErrorSingle) => {
          const intCode = err.code in HttpErrorCodes ? HttpErrorCodes[err.code] : Number.parseInt(err.code || '0', 10);
          if (stack.code.includes(intCode)) {
            // Drop duplicate errors
            return stack;
          }
          stack.message.push(err.message);
          stack.code.push(intCode);
          stack.errors.push(new Error(err.message));
          stack.data = err.data;
          return stack;
        },
        { message: [], code: [], errors: [] },
      );

      return normalizedApiError(message.join('\n'), code[0], errors, data);
    }

    if (isGoSingleError(errorBody)) {
      const code =
        errorBody.code in HttpErrorCodes ? HttpErrorCodes[errorBody.code] : Number.parseInt(errorBody.code, 10);
      return normalizedApiError(errorBody.message, code, error);
    }

    // if all else fails we know it's a MonolithError
    return normalizedApiError(errorBody.error, errorBody.code, error);
  } catch (error) {
    return normalizedApiError('Something went wrong', 0, Error('Something went wrong'));
  }
};
