import { lazy } from 'react';

const ShiftReleaseDialog = lazy(() => import('./ShiftReleaseDialog'));
const TakeShiftConfirmationDialog = lazy(() => import('./TakeShiftConfirmationDialog'));
const TosAcknowledgementDialog = lazy(() => import('./TosAcknowledgementDialog'));

export const CONFIRM_SHIFT_RELEASE_DIALOG = 'CONFIRM_SHIFT_RELEASE_DIALOG';
export const TAKE_SHIFT_CONFIRMATION_DIALOG = 'TAKE_SHIFT_CONFIRMATION_DIALOG';

export const TOS_ACKNOWLEDGEMENT_DIALOG = 'TOS_ACKNOWLEDGEMENT_DIALOG';

export default {
  [CONFIRM_SHIFT_RELEASE_DIALOG]: ShiftReleaseDialog,
  [TAKE_SHIFT_CONFIRMATION_DIALOG]: TakeShiftConfirmationDialog,
  [TOS_ACKNOWLEDGEMENT_DIALOG]: TosAcknowledgementDialog,
};
