import type { Moment } from 'moment-timezone';
import { useContext, useMemo } from 'react';
import { IntlContext } from 'shared/i18n/Provider';
import { formatCurrency, formatNumber, getCurrencyInfo } from './utils/numbers';
import { formatDate, formatTime, formatTimeLong, formatTimeMedium } from './utils/time';

type TimeType = number | Date | Moment;
type NumberType = number | bigint | string;

export default function useIntl() {
  const i18n = useContext(IntlContext);
  const { thousandsSeparator, decimalSeparator } = useMemo(() => {
    const parts = new Intl.NumberFormat(i18n.locale).formatToParts(12345.6);
    return {
      thousandsSeparator: parts.find(part => part.type === 'group')?.value ?? ',',
      decimalSeparator: parts.find(part => part.type === 'decimal')?.value ?? '.',
    };
  }, [i18n.locale]);

  const methods = useMemo(
    () => ({
      formatDate: (date?: TimeType, options?: Intl.DateTimeFormatOptions) => formatDate(i18n, date, options),
      formatTime: (time?: TimeType) => formatTime(time, i18n),
      formatTimeLong: (time?: TimeType) => formatTimeLong(time, i18n),
      formatTimeMedium: (time?: TimeType) => formatTimeMedium(time, i18n),
      formatNumber: (number: NumberType, options?: Intl.NumberFormatOptions) => formatNumber(number, i18n, options),
      formatCurrency: (amount: NumberType, options?: Intl.NumberFormatOptions) => formatCurrency(amount, i18n, options),
      getCurrencyInfo: () => getCurrencyInfo(i18n.currency, i18n),
    }),
    [i18n],
  );

  return {
    i18n,
    ...methods,
    thousandsSeparator,
    decimalSeparator,
  };
}
