import BaseModel from 'data/BaseModel';

export interface HourStatsRequestsFields {
  start: string;
  end: string;
  startOfDay: string;
  hourlyRate: number;
  hours: number;
  userId: number;
  id: number;
  type: number;
  typeId: number;
  paid: boolean;
}

class HourStatsRequests extends BaseModel<HourStatsRequestsFields>({
  start: '',
  end: '',
  startOfDay: '',
  hourlyRate: 0,
  hours: 0,
  userId: 0,
  id: 0,
  type: 0,
  typeId: 0,
  paid: false,
}) {}

export default HourStatsRequests;
