import EntityState from 'data/EntityState';
import PayrollNotice from 'data/payrollNotice/model';
import { CLEAR_PAYROLL_NOTICES, FETCH_PAYROLL_NOTICES, REMOVE_PAYROLL_NOTICE } from 'store/action-types';

import { Map } from 'immutable';

export default (state = new EntityState(), action) => {
  switch (action.type) {
    case FETCH_PAYROLL_NOTICES.REQUEST:
      return state.merge({
        loading: true,
        loaded: false,
        receivedAt: 0,
      });
    case FETCH_PAYROLL_NOTICES.SUCCESS:
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        items: new Map(
          action.payload.notices.map(notice => {
            const noticeModel = new PayrollNotice(notice);
            return [noticeModel.id, noticeModel];
          }),
        ),
        receivedAt: Date.now(),
      });
    case FETCH_PAYROLL_NOTICES.FAILURE:
      return state.merge({
        loaded: false,
        loading: false,
        error: action.payload,
      });
    case CLEAR_PAYROLL_NOTICES.SUCCESS:
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        items: new Map(),
        receivedAt: Date.now(),
      });
    case REMOVE_PAYROLL_NOTICE:
      return state.merge({
        items: state.items.delete(action.noticeId),
      });
    default:
      return state;
  }
};
