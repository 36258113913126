import EntityState from 'data/EntityState';
import fetchPerson from 'data/person/actions/fetchPerson';
import Person from 'data/person/model';
import generateThunkReducers from 'data/util/generateThunkReducers';
import { mergeEntityItems } from 'data/util/sliceHelpers';
import { toEntityMap } from 'shared/util/toEntityMap';

import { createSlice } from '@reduxjs/toolkit';

const initialState = new EntityState();

export const personSlice = createSlice({
  name: 'person',
  initialState,
  reducers: {
    receivePerson: (state, action) => {
      return state.merge({
        receivedAt: Date.now(),
        items: toEntityMap(action.payload, Person),
        loaded: true,
        loading: false,
      });
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchPerson.fulfilled, (state, action) => {
      return mergeEntityItems(state, [action.payload.person], Person);
    });
    generateThunkReducers(builder, 'person');
  },
});

export const { receivePerson } = personSlice.actions;
export default personSlice.reducer;
