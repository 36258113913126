export const SCHEDULER_LOCATION_FILTER = 'scheduler_location';
export const SCHEDULER_SITES_FILTER = 'scheduler_sites';
export const SCHEDULER_USER_FILTER = 'scheduler_user';
export const SCHEDULER_POSITIONS_FILTER = 'scheduler_positions';
export const SCHEDULER_CURRENT_VIEW = 'scheduler_view';
export const SCHEDULER_CURRENT_RANGE = 'scheduler_range';
export const SCHEDULER_SHIFT_BACKGROUND = 'scheduler_shift_background';
export const SCHEDULER_DATE = 'scheduler_date';
export const SCHEDULER_ONBOARDING_LEGACY_CHECKLIST_DISMISSED = 'hybridOnboarding-skipped';
export const SCHEDULER_SHOW_FILTERED_EMPLOYEES = 'scheduler_show_filtered_employees';
export const SCHEDULER_HIDE_UNSCHEDULED_EMPLOYEES = 'scheduler_hide_unscheduled_employees';
export const SCHEDULER_TAG_FILTER = 'scheduler_tags';
export const LOCATION_SORT_FILTER = 'location_sort_filter';
export const SHOW_OTHER_SCHEDULES = 'show_other_schedules';
export const SHOW_UNSCHEDULED_POSITIONS = 'show_unscheduled_positions';
export const SHOW_UNSCHEDULED_JOBSITES = 'show_unscheduled_jobsites';
export const GUIDED_SCHEDULE_SETUP_REENTRY_CLOSED = 'guided_schedule_setup_reentry_closed';
export const FOCUSED_ONBOARDING_SETUP_REENTRY_CLOSED = 'focused_onboarding_setup_reentry_closed';
