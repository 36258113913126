import { normalizeProjection } from 'scheduler/util/forecast';
import { request } from 'shared/auth/request';
import { track } from 'shared/util/mercury';
import { SET_FORECASTING_PROJECTION } from 'store/action-types';

function setForecastProjectionFailure(error) {
  return {
    type: SET_FORECASTING_PROJECTION.FAILURE,
    payload: error,
  };
}

function setForecastProjectionRequested() {
  return {
    type: SET_FORECASTING_PROJECTION.REQUEST,
  };
}

function setForecastProjectionSuccess(payload) {
  return {
    type: SET_FORECASTING_PROJECTION.SUCCESS,
    payload,
  };
}

export function setForecastProjection(body) {
  const field = ![null, undefined].includes(body.target_percentage) ? 'targetLaborPercentage' : 'salesEstimate';
  const value = ![null, undefined].includes(body.target_percentage) ? body.target_percentage : body.estimate;
  track('scheduler::laborForecastingEntry', {
    field,
    value: value.toString(),
    forecastDate: body.day,
  });
  if (body.id) {
    return updateForecastProjection(body);
  }
  return createForecastProjection(body);
}

const getProjectionResponse = (response, body) => {
  if (response.empty) {
    return normalizeProjection({
      day: body.day,
      estimate: 0,
      target_percentage: 0,
    });
  }
  return normalizeProjection(response.projectedsale);
};

function createForecastProjection(body) {
  return dispatch => {
    dispatch(setForecastProjectionRequested());
    return request()
      .post('/forecasting/projected', body)
      .then(response => dispatch(setForecastProjectionSuccess(getProjectionResponse(response, body))))
      .catch(error => dispatch(setForecastProjectionFailure(error.body)));
  };
}

function updateForecastProjection(body) {
  return dispatch => {
    dispatch(setForecastProjectionRequested());
    return request()
      .put(`/forecasting/projected/${body.id}`, body)
      .then(response => dispatch(setForecastProjectionSuccess(getProjectionResponse(response, body))))
      .catch(error => dispatch(setForecastProjectionFailure(error.body)));
  };
}
