import { Fragment, type ReactNode, type Ref, forwardRef } from 'react';
import { type FieldValues, type UseControllerProps, useController } from 'react-hook-form';
import type { ActionMeta, GroupBase, SelectComponentsConfig } from 'react-select';

import getFieldError from 'shared/form/helpers/getFieldError';
import useMapCustomValidators from 'shared/form/hooks/useMapCustomValidators';
import type { InputProps } from 'shared/form/types';
import UISelect from 'shared/ui/inputs/Select';

import { Input } from '@mantine/core';
import type { TextInputProps as MantineTextInputProps } from '@mantine/core';
import { useMergedRef } from '@mantine/hooks';

export interface SelectProps<
  T extends FieldValues,
  Option = any,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
> extends InputProps<UseControllerProps<T>>,
    Omit<MantineTextInputProps, 'name' | 'value' | 'defaultValue' | 'onChange'> {
  type?: 'select' | 'creatable' | 'async';
  isMulti?: boolean;
  options: any[];
  clearable?: boolean;
  leftSection?: ReactNode;
  components?: SelectComponentsConfig<Option, IsMulti, Group>;
  menuIsOpen?: boolean;
  openMenuOnFocus?: boolean;
  isSearchable?: boolean;
  onCreateOption?: (inputValue: string) => void;
  menuPlacement?: 'auto' | 'top' | 'bottom';
  'data-testid'?: string;
}

export default forwardRef(function Select<T extends FieldValues>(
  { name, control, defaultValue, shouldUnregister, onChange, ...props }: SelectProps<T>,
  ref: Ref<HTMLInputElement>,
) {
  const rules = useMapCustomValidators(props, true);

  const {
    field: { value, onChange: fieldOnChange, ref: insideRef, ...field },
    fieldState,
  } = useController<T>({
    name,
    control,
    defaultValue,
    rules,
    shouldUnregister,
  });

  const mergedRef = useMergedRef(insideRef, ref);

  function renderLabel() {
    if (props.label || props.labelAddon) {
      return (
        <Fragment>
          {props.label}
          {props.labelAddon}
        </Fragment>
      );
    }
    return null;
  }

  return (
    <Input.Wrapper
      // @ts-ignore
      classNames={props?.classNames}
      label={renderLabel()}
      id={props.inputId}
      error={getFieldError(fieldState)}
      data-testid={props['data-testid']}
      withAsterisk={props.required}
    >
      {props.leftSection && <div className="section-left">{props.leftSection}</div>}
      <UISelect
        // @ts-ignore
        error={getFieldError(fieldState)}
        ref={mergedRef}
        value={value}
        onChange={(option: any, actionMeta: ActionMeta<typeof props.options>) => {
          // option is null if the user clears the value
          let value = option?.value;
          if (Array.isArray(option)) {
            value = option.map(item => item.value);
          }
          fieldOnChange(value, actionMeta);
          onChange?.(name, value);
        }}
        isClearable={props.clearable}
        isDisabled={props.disabled}
        {...field}
        {...props}
      />
    </Input.Wrapper>
  );
});
