import type { LocationFields } from 'data/location/model';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { type WiwDispatch, type WiwGetState, type WiwState, createWiwAsyncThunk } from 'store';

import moment from 'moment-timezone';

export type FetchLocationsResponse = {
  locations: LocationFields[];
};

export type FetchLocationParams =
  | {
      only_unconfirmed?: boolean;
    }
  | undefined;

export const fetchLocations = createWiwAsyncThunk<FetchLocationsResponse, FetchLocationParams>(
  'location/fetch',
  async (params = {}, { rejectWithValue }) => {
    try {
      return (await Monolith.url('/locations').query(params).get()) as FetchLocationsResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);

function shouldFetchLocations(state: WiwState) {
  if (state.data.location.get('loading')) {
    return false;
  }

  const receivedAt = state.data.location.get('receivedAt');
  if (!receivedAt) {
    return true;
  }

  return moment(receivedAt).isBefore(moment().subtract(30, 'minutes'));
}

export function fetchLocationsIfNeeded() {
  return (dispatch: WiwDispatch, getState: WiwGetState) => {
    if (shouldFetchLocations(getState())) {
      dispatch(fetchLocations());
    }
  };
}
