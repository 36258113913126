import EntityBase from 'data/EntityBase';
import { createPolicy } from 'data/timeOffAccrual/actions/createPolicy';
import { deletePolicy } from 'data/timeOffAccrual/actions/deletePolicy';
import { deletePolicyRate } from 'data/timeOffAccrual/actions/deletePolicyRate';
import { fetchPolicies } from 'data/timeOffAccrual/actions/fetchPolicies';
import { fetchPoliciesForUser } from 'data/timeOffAccrual/actions/fetchPoliciesForUser';
import { fetchPolicy } from 'data/timeOffAccrual/actions/fetchPolicy';
import { updatePolicy } from 'data/timeOffAccrual/actions/updatePolicy';
import { updatePolicyRates } from 'data/timeOffAccrual/actions/updatePolicyRates';
import { updateUserPolicies } from 'data/timeOffAccrual/actions/updateUserPolicies';
import TimeOffPolicy from 'data/timeOffAccrual/models/timeOffPolicy';
import generateThunkReducers from 'data/util/generateThunkReducers';
import { mergeEntityItems } from 'data/util/sliceHelpers';

import { createSlice } from '@reduxjs/toolkit';
import { Map } from 'immutable';

const defaultItems = Map<TimeOffPolicy['id'], TimeOffPolicy>();

export class TimeOffPolicyState extends EntityBase({
  items: defaultItems,
}) {
  declare readonly items: typeof defaultItems;
}

const initialState = new TimeOffPolicyState();

export const timeOffPolicies = createSlice({
  name: 'timeOff/policies',
  initialState,
  reducers: {},
  extraReducers: builder => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchPolicies.fulfilled, (state, action) => {
        return mergeEntityItems(state, action.payload.data.policies, TimeOffPolicy);
      })
      .addCase(fetchPoliciesForUser.fulfilled, (state, action) => {
        return mergeEntityItems(state, action.payload.data.policies, TimeOffPolicy);
      })
      .addCase(fetchPolicy.fulfilled, (state, action) => {
        return mergeEntityItems(state, action.payload.data.policy, TimeOffPolicy);
      })
      .addCase(createPolicy.fulfilled, (state, action) => {
        return mergeEntityItems(state, action.payload.data.policy, TimeOffPolicy);
      })
      .addCase(updatePolicy.fulfilled, (state, action) => {
        return mergeEntityItems(state, action.payload.data.policy, TimeOffPolicy);
      })
      .addCase(updatePolicyRates.fulfilled, (state, action) => {
        return mergeEntityItems(state, action.payload.data.policy, TimeOffPolicy);
      })
      .addCase(updateUserPolicies.fulfilled, (state, action) => {
        return mergeEntityItems(state, action.payload.data.policies, TimeOffPolicy);
      })
      .addCase(deletePolicy.fulfilled, (state, action) => {
        return state.merge({
          items: state.items.delete(action.meta.arg),
        });
      })
      .addCase(deletePolicyRate.fulfilled, (state, action) => {
        return mergeEntityItems(state, action.payload.data.policy, TimeOffPolicy);
      });
    generateThunkReducers(builder, 'timeOff/policies');
  },
});

export default timeOffPolicies.reducer;
