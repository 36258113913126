import {
  CHANGE_STRATEGY,
  CREATE_TEMPLATE,
  DELETE_TEMPLATE,
  LIST_TEMPLATES,
  LIST_TEMPLATE_SHIFTS,
} from 'store/action-types';
import Template from './model';
import TemplateState from './state';

import { Map } from 'immutable';

export default function (state = new TemplateState(), action) {
  switch (action.type) {
    case LIST_TEMPLATES.FAILURE:
      return state.merge({
        loaded: false,
        loading: false,
        error: action.payload,
      });
    case LIST_TEMPLATES.REQUEST:
      return state.merge({
        loaded: false,
        loading: true,
        error: null,
      });
    case LIST_TEMPLATES.SUCCESS:
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        items: new Map(action.payload.map(template => [template.id, new Template(template)])),
      });
    case CREATE_TEMPLATE.SUCCESS:
      return state.setIn(['items', action.payload.id], new Template(action.payload));
    case DELETE_TEMPLATE.REQUEST:
      return state.deleteIn(['items', action.id]);
    case DELETE_TEMPLATE.FAILURE:
      return state.setIn(['items', action.payload.id], new Template(action.payload));
    case CHANGE_STRATEGY:
      return state.merge({
        shifts: action.payload.shifts,
        strategy: action.payload.strategy,
      });
    case LIST_TEMPLATE_SHIFTS.SUCCESS:
      return state.merge({
        items: state.items.set(action.payload.id, new Template(action.payload)),
      });
    case LIST_TEMPLATE_SHIFTS.FAILURE:
      return state.merge({
        error: action.error,
      });
    default:
      return state;
  }
}
