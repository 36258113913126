import BaseModel from 'data/BaseModel';

export interface ShiftHistoryFields {
  actor: any | null; // TODO(types)
  at: any | null; // TODO(types)
  end: any | null; // TODO(types)
  shiftId: number;
  start: any | null; // TODO(types)
  type: any | null; // TODO(types)
  user: any | null; // TODO(types)
  position: any | null; // TODO(types)
  site: any | null; // TODO(types)
  break: number | null;
  reason: string | null;
}

/**
 * This entity is completely derived by the endpoint,
 * The attributes are mutated/transformed at runtime
 */
class ShiftHistory extends BaseModel<ShiftHistoryFields>({
  actor: null,
  at: null,
  end: null,
  shiftId: 0,
  start: null,
  type: null,
  user: null,
  position: null,
  site: null,
  break: null,
  reason: null,
}) {
  /**
   * Get break time in minutes
   */
  getBreakTimeMinutes(): number | string {
    if (!this.break) {
      return 0;
    }

    return Math.round(this.break * 60);
  }
}

export default ShiftHistory;
