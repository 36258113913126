import Bowser from 'bowser';

export const getDateTimeFormat = Intl.DateTimeFormat;

/* istanbul ignore next */
export const getTimeFormatter = intl => {
  const { locale, timezone, use24hour } = intl;
  // [WIWJS-2589] midnight displaying as 00 in Chrome 80+, should be 12
  // Bug filed with Chrome, but the international ECMA script nerds are arguing https://bugs.chromium.org/p/chromium/issues/detail?id=1045791#c19
  // and it's unclear if this is a bug that will be fixed, or a bug that has *only* been fixed by chrome 80
  /* istanbul ignore next */
  const isChrome80 = Bowser.name === 'Chrome' && Number.parseInt(Bowser.version, 10) >= 80;
  // ECMA script format rules https://ecma-international.org/ecma-402/#sec-initializedatetimeformat
  // hour12 overwrites hourCycle, and is wrong for midnight format, so use hourCycle NOT hour12 in chrome 80
  /* istanbul ignore next */
  const timeFormatKey = isChrome80 ? 'hourCycle' : 'hour12';
  // When account time format is 12hour: 00:00 displays as 12a
  // When account time format is 24hour: 00:00 displays as 0:00
  /* istanbul ignore next */
  const timeFormatValue = isChrome80 ? (use24hour ? 'h23' : 'h12') : !use24hour;
  const getFormatter = timeZone =>
    getDateTimeFormat(locale, {
      hour: 'numeric',
      minute: '2-digit',
      [timeFormatKey]: timeFormatValue,
      timeZone,
    });

  try {
    return getFormatter(timezone);
  } catch {
    return getFormatter();
  }
};

export const use24hour = acct => {
  return acct && Boolean(acct.getSettings('schedule.24hour', 0));
};

/**
 * @param i18n {IntlContextType}
 * @param date {import('moment-timezone').Moment|Date|number}
 * @param options {Intl.DateTimeFormatOptions}
 * @returns {string}
 */
export const formatDate = (i18n, date = new Date(), options = {}) => {
  const formatter = Intl.DateTimeFormat(i18n.locale, {
    timeZone: i18n.timezone,
    ...options,
  });

  return formatter.format(date);
};

export const formatTime = (time, i18nContext) => {
  return getTimeFormatter(i18nContext)
    .format(time)
    .toLowerCase()
    .replace(/(:00\s?(.*)m|m| )+/g, '$2');
};

export const formatTimeShortPeriod = (time, i18nContext) => {
  return getTimeFormatter(i18nContext)
    .format(time)
    .toLowerCase()
    .replace(/\s?([ap])m+/g, '$1');
};

export const formatTimeMedium = (time, i18nContext) => {
  return getTimeFormatter(i18nContext)
    .format(time)
    .toLowerCase()
    .replace(/\s?([ap])m+/g, '$1');
};

export const formatTimeLong = (time, i18nContext) => {
  return getTimeFormatter(i18nContext).format(time).replace(/\s/, '');
};
