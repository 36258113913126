import BaseModel from 'data/BaseModel';

type DeviceType = 'android' | 'ios';

export interface DeviceFields {
  id: number;
  account_id: number;
  type: DeviceType;
}

class Device extends BaseModel<DeviceFields>({
  id: 0,
  account_id: 0,
  type: 'ios',
}) {}

export default Device;
