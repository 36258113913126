import Integration from 'data/integration/model';

export const cloverPOS = new Integration({
  name: 'Clover POS',
  feature: 'requestable-clover-pos',
  logo_class: 'logo clover',
  status: Integration.STATUS.REQUESTABLE,
  description: 'Sync users, shifts, sales, and time entries from your Clover POS.',
  details_url: '/settings/integrations/clover',
});

export const goDaddyPoyntPOS = new Integration({
  name: 'GoDaddy Poynt POS',
  feature: 'requestable-godaddy-poynt-pos',
  logo_class: 'logo godaddy',
  status: Integration.STATUS.REQUESTABLE,
  description: 'Sync users and sales data from your GoDaddy Poynt POS.',
  details_url: '/settings/integrations/godaddy-poynt',
});

export const lightSpeedRestaurantKSeriesPOS = new Integration({
  name: 'Lightspeed Restaurant K-Series POS',
  feature: 'requestable-lightspeed-restaurant-k-series-pos',
  logo_class: 'logo lightspeed-restaurant-pos',
  status: Integration.STATUS.REQUESTABLE,
  description: 'Sync users and sales data from your LightSpeed K-Series POS.',
  details_url: '/settings/integrations/lightspeed-restaurant-k-series',
});

export const lightSpeedRetailRSeriesPOS = new Integration({
  name: 'Lightspeed Retail R-Series POS',
  feature: 'requestable-lightspeed-retail-r-series-pos',
  logo_class: 'logo lightspeed-retail-pos',
  status: Integration.STATUS.REQUESTABLE,
  description: 'Sync users, sales, and time entries from your LightSpeed R-Series POS.',
  details_url: '/settings/integrations/lightspeed-retail-r-series',
});

export const lightSpeedRetailXSeriesPOS = new Integration({
  name: 'Lightspeed Retail X-Series POS',
  feature: 'requestable-lightspeed-retail-x-series-pos',
  logo_class: 'logo lightspeed-retail-pos',
  status: Integration.STATUS.REQUESTABLE,
  description: 'Sync users, shifts, and sales data from your LightSpeed X-Series POS.',
  details_url: '/settings/integrations/lightspeed-retail-x-series',
});

export const mindBody = new Integration({
  name: 'Mindbody',
  feature: 'requestable-mindbody',
  logo_class: 'logo mindbody',
  status: Integration.STATUS.REQUESTABLE,
  description: 'Capture class information from Mindbody to When I Work, and sync users.',
  details_url: '/settings/integrations/mindbody',
});

export const squarePOS = new Integration({
  name: 'Square POS',
  feature: 'requestable-square-pos',
  logo_class: 'logo square',
  status: Integration.STATUS.REQUESTABLE,
  description: 'Sync users, shifts, and time entries from your Square POS.',
  details_url: '/settings/integrations/square-pos',
});

export const workDay = new Integration({
  name: 'Workday',
  feature: 'requestable-workday',
  logo_class: 'logo workday',
  status: Integration.STATUS.REQUESTABLE,
  description: 'Sync users and time-off data from WorkDay.',
  details_url: '/settings/integrations/workday',
});

export const requestableIntegrations = [
  [cloverPOS.feature, cloverPOS],
  // [goDaddyPoyntPOS.feature, goDaddyPoyntPOS],
  // [lightSpeedRestaurantKSeriesPOS.feature, lightSpeedRestaurantKSeriesPOS],
  // [lightSpeedRetailRSeriesPOS.feature, lightSpeedRetailRSeriesPOS],
  // [lightSpeedRetailXSeriesPOS.feature, lightSpeedRetailXSeriesPOS],
  // [mindBody.feature, mindBody],
  [squarePOS.feature, squarePOS],
  // [workDay.feature, workDay],
];
