import { fetchCurrentAccount } from 'data/account/actions/fetchCurrentAccount';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type BulkDeleteUsersParams = {
  userIds: number[];
  options?: {
    deleteShifts?: boolean;
  };
};

type RawDeleteUserResponse = {
  modified_shifts?: number[];
  deleted_shifts?: number[];
  success?: boolean;
};

type DeleteUserResponse = {
  id: number;
} & RawDeleteUserResponse;

export const bulkDeleteUsers = createWiwAsyncThunk<DeleteUserResponse[], BulkDeleteUsersParams>(
  'user/bulkDeleteUsers',
  async ({ userIds, options = { deleteShifts: false } }, { dispatch, rejectWithValue }) => {
    try {
      const response = (
        await Monolith.query({
          delete_shifts: options.deleteShifts,
          ids: userIds,
        }).delete('/users')
      ).map((resItem: RawDeleteUserResponse, idx: number) => ({
        id: userIds[idx],
        ...resItem,
      })) as DeleteUserResponse[];
      await dispatch(fetchCurrentAccount()); // refresh user count
      return response;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
