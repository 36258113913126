import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { Body, Dialog, Footer, Header, closeDialog, openDialog } from 'dialogs';
import Form from 'shared/components/form';
import { ValidatedTextArea } from 'shared/components/form/inputs/index';
import Button from 'shared/ui/Button';

export const FEEDBACK_PROMPT_DIALOG = 'FEEDBACK_PROMPT_DIALOG';

export const openFeedbackPromptDialog = openDialog.bind(null, FEEDBACK_PROMPT_DIALOG);
export const closeFeedbackPromptDialog = closeDialog.bind(null, FEEDBACK_PROMPT_DIALOG);

export default class FeedbackDialog extends PureComponent {
  static propTypes = {
    closeDialog: PropTypes.func.isRequired,
    nextAction: PropTypes.func.isRequired,
    sendFeedback: PropTypes.func.isRequired,
  };

  state = {
    response: '',
    saving: false,
  };

  onChange = (name, value) => {
    this.setState({ response: value });
  };

  handleSubmit = () => {
    this.setState({ saving: true });
    this.props.sendFeedback(this.state.response).then(() => {
      this.props.closeDialog();
      this.props.nextAction();
    });
  };

  render() {
    return (
      <Dialog className="feedback-prompt-dialog" width={500}>
        <Form onValidSubmit={this.handleSubmit}>
          <Header onClose={this.props.closeDialog}>How can we make When I Work better?</Header>
          <Body>
            <div className="row">
              <ValidatedTextArea
                name="response"
                onChange={this.onChange}
                value={this.state.response}
                inputProps={{ autoFocus: true }}
                rows={4}
              />
            </div>
          </Body>
          <Footer>
            <div className="row">
              <div className="col">
                <Button
                  className="pull-right"
                  color="primary"
                  loading={this.state.saving}
                  disabled={this.state.response === ''}
                  type="submit"
                >
                  Send
                </Button>
                <Button
                  className="pull-right"
                  color="secondary"
                  disabled={this.state.saving}
                  onClick={this.props.closeDialog}
                >
                  Skip
                </Button>
              </div>
            </div>
          </Footer>
        </Form>
      </Dialog>
    );
  }
}
