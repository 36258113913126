import { preserveReferences } from 'shared/util/memo';

import { createSelector } from '@reduxjs/toolkit';

export const getCurrentView = state => state.scheduler.get('view');
export const getCurrentRange = state => state.scheduler.get('range');

export const getCurrentViewMode = createSelector(
  [getCurrentView, getCurrentRange],
  preserveReferences((view, range) => ({
    view: view,
    range: range,
  })),
);
