import { accountsSelector } from 'data/account/selectors/childAccounts';

import { createSelector } from '@reduxjs/toolkit';

const getAccountSubdomain = (state, subdomain) => subdomain;

export const getAccountBySubdomain = createSelector([accountsSelector, getAccountSubdomain], (accounts, subdomain) => {
  let foundAccount = null;
  accounts.items.forEach(acct => {
    if (acct.subdomain === subdomain) {
      foundAccount = acct;
      return false;
    }
  });
  return foundAccount;
});
