import type Account from 'data/account/model';
import type User from 'data/user/model';
import PayrollCheckOnboarding from './PayrollCheckOnboarding';

export function canOnboardPayroll(authAccount: Account, authUser: User) {
  const canOnboard =
    authUser.isAdmin() ||
    (authUser.canPayroll() && authAccount.getSettings('role_permissions.managers.can_manage_account_settings', true));

  return canOnboard;
}

export default function PayrollCheckOnboardingToggle() {
  return <PayrollCheckOnboarding />;
}
