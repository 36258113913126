import BaseModel, { type C } from 'data/BaseModel';
import type { PositionFields } from 'data/position/model';
import type Shift from 'data/shift/model';
import type { ScheduledBreak } from 'data/shift/model';
import { getMomentDate, setYearMonthDay } from 'shared/util/time';

import moment, { type Moment } from 'moment-timezone';

export interface ShiftTemplateFields {
  id: number;
  account_id: number;
  location_id: number;
  position_id: PositionFields['id'];
  notes: string | null;
  break_time: number;
  color: string;
  start_time: Moment | string | null;
  end_time: Moment | string | null;
  created_at: string | null;
  updated_at: string | null;
  requires_openshift_approval: number | boolean;
  is_shared: number | boolean;
  breaks: ScheduledBreak[];
}

class ShiftTemplate extends BaseModel<ShiftTemplateFields>({
  id: 0,
  account_id: 0,
  location_id: 0,
  position_id: 0,
  notes: null,
  break_time: 0,
  color: 'cccccc',
  start_time: null,
  end_time: null,
  created_at: null,
  updated_at: null,
  requires_openshift_approval: 0,
  is_shared: 0,
  breaks: [],
}) {
  formattedColor() {
    if (!this.color) {
      return '#cccccc';
    }

    return `#${this.color}`;
  }

  isNew() {
    return this.id === 0;
  }

  duration() {
    const { start, end } = this.getBoundaries(moment());

    return Number.parseFloat((end.diff(start, 'minutes', true) / 60).toFixed(2));
  }

  getBoundaries(startDate = getMomentDate(undefined)) {
    const templateStart = moment(this.start_time, 'HH:mm');
    const templateEnd = moment(this.end_time, 'HH:mm');
    const endDate = templateEnd.isSameOrBefore(templateStart) ? startDate.clone().add(1, 'day') : startDate;
    const start = setYearMonthDay(templateStart, startDate);
    const end = setYearMonthDay(templateEnd, endDate);

    return {
      start: start,
      end: end,
    };
  }

  /* istanbul ignore next */
  /**
   * Overriding BaseModel.date(prop) because shift templates do not have an associated date, which was causing strange
   * behavior when trying to parse time strings with chrono-node.
   */
  /* @ts-ignore */ /* eslint-disable-next-line */
  date<K extends keyof (C & ShiftTemplateFields)>(prop: K): Moment | (C & ShiftTemplateFields)[K] {
    // TODO(types)
    // We should really find a way to get rid of/totally rework this date method because it is very complicated with
    // TypeScript
    const date = getMomentDate(moment(this.get(prop) as any, 'HH:mm'));
    if (date.isValid()) {
      return date;
    }

    return this.get(prop);
  }

  /** Get break time in minutes */
  getBreakTimeMinutes(): number | string {
    if (!this.break_time) {
      return '';
    }

    return Math.round(this.break_time * 60);
  }

  static fromShift(shift: Shift) {
    return new ShiftTemplate({
      location_id: shift.location_id,
      position_id: shift.position_id,
      notes: shift.notes,
      break_time: shift.break_time,
      color: shift.color,
      start_time: shift.start_time,
      end_time: shift.end_time,
      requires_openshift_approval: !!shift.requires_openshift_approval,
      is_shared: !!shift.is_shared,
      breaks: shift?.breaks?.length !== 0 ? shift.breaks : [],
    });
  }

  static fromShiftForPersistance(shift: Shift) {
    return this.fromShift(shift).withMutations(block => {
      // The monolith API does the wrong thing if we provide a timezone
      // offset, so we can't just use an ISO string as usual.
      const format = 'HH:mm:ss';

      const breaks = (shift?.breaks?.length ? shift.breaks : []).map(brk => {
        const start_time = !brk.start_time ? null : getMomentDate(brk.start_time).format(format);
        const id = null;
        return { ...brk, id, start_time };
      });

      return block
        .set('start_time', getMomentDate(block.start_time).format(format))
        .set('end_time', getMomentDate(block.end_time).format(format))
        .set('breaks', breaks);
    });
  }

  getTempShiftId(date = getMomentDate(undefined)): string {
    return `template_${date.format('YYYYMMDD')}_${this.id}`;
  }
}

export default ShiftTemplate;
