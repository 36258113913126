import { useEffect } from 'react';

import { useLDFlag } from 'data/LD/selectors/getLDFlag';
import { fetchStepUpAuthMethod } from 'data/auth/actions/fetchStepUpAuthMethod';
import { fetchStepUpRefreshToken } from 'data/auth/actions/fetchStepUpRefreshToken';
import { setIsStepUpAuthIdle, setStepUpAuthMethod } from 'data/auth/reducer';
import useNotice from 'notices/hooks/useNotice';
import { getStepUpTokenInfo, removeStepUpToken } from 'shared/auth/auth';
import { getAuthData } from 'shared/auth/selectors';
import { useWiwDispatch, useWiwSelector } from 'store';

import { useIdle } from '@mantine/hooks';

const REFRESH_INTERVAL = 1000 * 60 * 5; // 5 minutes
const STEP_UP_IDLE_TIME = 1000 * 60 * 30; // 30 minutes

export function useStepUpAuthInitialize() {
  const pay536StepUpAuthentication = useLDFlag('pay-536-step-up-authentication');
  const dispatch = useWiwDispatch();
  const { errorNotice } = useNotice();
  const { isStepUpAuthActive, isStepUpAuthIdle } = useWiwSelector(getAuthData);
  const isIdle = useIdle(STEP_UP_IDLE_TIME, { initialState: false, events: ['click', 'keypress'] });

  // If user steps up or user becomes idle, we want this useEffect to trigger a check if we should start or cancel refreshing the token
  useEffect(() => {
    if (!pay536StepUpAuthentication) {
      return;
    }

    const stepUpToken = getStepUpTokenInfo();
    const refreshTokenInterval = stepUpToken?.isValidStepUpToken()
      ? setInterval(fetchStepUpRefreshToken, REFRESH_INTERVAL)
      : undefined;
    return () => clearInterval(refreshTokenInterval);
  }, [isStepUpAuthActive, isStepUpAuthIdle, pay536StepUpAuthentication]);

  useEffect(() => {
    if (!pay536StepUpAuthentication) {
      return;
    }

    const stepUpToken = getStepUpTokenInfo();
    if (isIdle && stepUpToken?.isValidStepUpToken()) {
      dispatch(setIsStepUpAuthIdle(true));
      removeStepUpToken();
      return;
    }
    dispatch(setIsStepUpAuthIdle(false));
  }, [isIdle, pay536StepUpAuthentication]);

  useEffect(() => {
    if (!pay536StepUpAuthentication) {
      return;
    }

    const loadAuthMethod = async () => {
      try {
        const { method } = await fetchStepUpAuthMethod();
        dispatch(setStepUpAuthMethod(method));
      } catch (error: any) {
        errorNotice(error?.message);
      }
    };

    loadAuthMethod();
  }, [pay536StepUpAuthentication]);
}
