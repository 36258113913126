import type PayPeriod from 'data/payPeriod/model';
import { request } from 'shared/auth/request';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

export interface PayPeriodResposne {
  payroll: PayPeriod;
}

export const fetchPayPeriodById = createWiwAsyncThunk<PayPeriodResposne, string>(
  'payPeriod/fetchPayPeriods',
  async (id, { rejectWithValue }) => {
    try {
      return await request().get(`/payrolls/${id}`);
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
