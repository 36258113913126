import type PostTaxDeduction from 'data/payrollTaxDeductions/model';
import Payroll from 'shared/api/payroll';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';

import { createAsyncThunk } from '@reduxjs/toolkit';

type FetchPayrollDeductionsRequest = {
  user_id: number;
};

type PayrollDeductionsResponse = {
  tax_deductions: PostTaxDeduction[];
};

export const fetchPayrollDeductions = createAsyncThunk<PayrollDeductionsResponse, FetchPayrollDeductionsRequest>(
  'taxDeduction/fetch',
  async (params, { rejectWithValue }) => {
    try {
      return (await Payroll.query(params).get('/tax-deduction')) as PayrollDeductionsResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
