import type SalariedWageRecord from 'data/salariedWages/models';
import Platform from 'shared/api/platform';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

export interface SalariedWageResponse {
  rate: SalariedWageRecord;
}

interface Params {
  userId: string;
  effectiveOn: string;
}

export const fetchSalariedWages = createWiwAsyncThunk<SalariedWageResponse, Params>(
  'salariedWages',
  async ({ userId, effectiveOn }: Params, { rejectWithValue }) => {
    try {
      return await Platform.get(`/users/${userId}/salaried/rates?effectiveOn=${effectiveOn}`);
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
