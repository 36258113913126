import type User from 'data/user/model';

export const getWorkchatUserShortName = (user: User): string => {
  return `${user.first_name} ${user.last_name.charAt(0)?.toUpperCase()}`;
};

export const parseEmojiFromUnicodeHex = (input: string) => {
  const hexCodes = input.split(' ').map(code => code.replace('U+', ''));
  return hexCodes.map(code => String.fromCodePoint(Number.parseInt(code, 16))).join('');
};

/**
 * Converts EmojiMart's Unified emoji code to Unicode Hex
 * @param input
 */
export const emojimartUnifiedToUnicodeHex = (input: string) => {
  return input
    ?.toUpperCase()
    .split('-')
    .map(part => `U+${part}`)
    .join(' ');
};
