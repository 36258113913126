import { FETCH_SHIFT_HISTORY } from 'store/action-types';
import ShiftHistory from './model';
import ShiftHistoryState from './state';

import { Map } from 'immutable';

export default (state = new ShiftHistoryState(), action) => {
  switch (action.type) {
    case FETCH_SHIFT_HISTORY.REQUEST:
      return state.merge({
        loading: true,
        receivedAt: 0,
        loaded: false,
      });
    case FETCH_SHIFT_HISTORY.FAILURE:
      return state.merge({
        loaded: false,
        loading: false,
        error: action.payload,
      });
    case FETCH_SHIFT_HISTORY.SUCCESS:
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        receivedAt: Date.now(),
        items: new Map([
          [
            action.shift.id,
            action.history.map(
              shiftHistory =>
                new ShiftHistory({
                  ...shiftHistory.attributes,
                  shiftID: action.shift.id,
                  type: shiftHistory.type,
                }),
            ),
          ],
        ]),
      });
    default:
      return state;
  }
};
