import Worktags from 'shared/api/worktags';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type UpdateShiftTagsResponse = {};

export const updateTag = createWiwAsyncThunk<UpdateShiftTagsResponse, any>(
  'tags/update',
  async (tag, { rejectWithValue }) => {
    try {
      return (await Worktags.url(`/tags/${tag.id}`).put(tag.toJSON())) as UpdateShiftTagsResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
