import { getLDFlag } from 'data/LD/selectors/getLDFlag';
import type { Dataset } from 'data/reporting/models/Dataset';
import type { ReportArchetype } from 'data/reporting/models/ReportArchetype';
import { getColumnDetailsForDataset } from 'reporting/selectors/getColumnDetails';
import type { WiwState } from 'store';

import { createSelector } from '@reduxjs/toolkit';

export const getReportArchetypes = createSelector(
  [
    getReportArchetype('shifts'),
    getReportArchetype('times'),
    getReportArchetype('shift-history'),
    (state: WiwState) => getLDFlag(state, 'shos-93-revised-exports'),
  ],
  (shifts, times, shiftHistory, revisedReports) => {
    const reports = revisedReports ? [shifts, shiftHistory, times] : [shifts, times, shiftHistory];
    const archetypes: ReportArchetype[] = reports.filter((archetype): archetype is ReportArchetype => !!archetype);

    return archetypes;
  },
);

function getReportArchetype(dataset: Dataset) {
  return createSelector(getColumnDetailsForDataset(dataset), columns => {
    if (!columns) {
      return undefined;
    }
    return {
      name: dataset,
      displayName: datasetDisplayName(dataset),
      description: datasetDescription(dataset),
      columns: columns,
      iconName: datasetIconName(dataset),
    } as ReportArchetype;
  });
}

const datasetDisplayName = (dataset: Dataset) => {
  switch (dataset) {
    case 'shifts':
      return 'Shifts';
    case 'times':
      return 'Times';
    case 'shift-history':
      return 'Shift History';
  }
};

const datasetIconName = (dataset: Dataset) => {
  switch (dataset) {
    case 'shifts':
      return 'shifts';
    case 'times':
      return 'time-clock';
    case 'shift-history':
      return 'currently-clocked-in';
  }
};

const datasetDescription = (dataset: Dataset) => {
  switch (dataset) {
    case 'shifts':
      return 'Start with all of the data we have available about the shifts you schedule, then customize the columns and save your own version.';
    case 'times':
      return 'Start with all of the data we have available about timesheet entries, then customize the columns and save your own version.';
    case 'shift-history':
      return 'Start with all of the data we have available about the history of each shift, then customize the columns and save your own version.';
  }
};
