import { useCallback, useState } from 'react';

import { List, Set } from 'immutable';
import { useWiwDispatch, useWiwSelector } from 'store';

import type { EntityMap } from 'data/types';
import type User from 'data/user/model';
import { getAuthAccount, getAuthUser } from 'shared/auth/selectors';
import FontIcon from 'shared/ui/FontIcon';
import HeaderV2, { type HeaderButton } from 'workchat/v2/components/HeaderV2';
import { VIEW_LOADING } from 'workchat/v2/components/LoadingV2';
import { UserPickerV2 } from 'workchat/v2/components/UserPickerV2';
import { trackFilterUse } from 'workchat/v2/store/actions';
import { addParticipantsToConversation } from 'workchat/v2/store/conversations/conversationsActions';
import { getParticipantsForSelectedConversation } from 'workchat/v2/store/participants/participantsSelectors';
import { viewStackPop, viewStackPush } from 'workchat/v2/store/reducer';
import { getWorkchatSelectedConversation } from 'workchat/v2/store/selectors';
import { getWorkchatUsers } from 'workchat/v2/store/users/usersSelectors';

import 'workchat/styles/NewConversation.scss';

export const VIEW_ADD_PARTICIPANTS = 'VIEW_ADD_PARTICIPANTS';

export default function AddParticipantsV2() {
  const dispatch = useWiwDispatch();
  const account = useWiwSelector(getAuthAccount);
  const user = useWiwSelector(getAuthUser);
  const users: EntityMap<User> = useWiwSelector(getWorkchatUsers);
  const conversation = useWiwSelector(getWorkchatSelectedConversation);
  const participants = useWiwSelector(getParticipantsForSelectedConversation);
  const participantsMappedByUserId = participants?.toMap().mapKeys((_, participant) => participant.userId);

  const [selectedUsers, setSelectedUsers] = useState(List<number>());
  const [locationFilters, setLocationFilters] = useState(Set<number>());
  const [positionFilters, setPositionFilters] = useState(Set<number>());

  const handleAddParticipants = useCallback(() => {
    if (!selectedUsers.size || !conversation) {
      return;
    }

    dispatch(viewStackPop());
    dispatch(viewStackPush(VIEW_LOADING));
    dispatch(
      trackFilterUse({
        channelCreated: false,
        workflow: 'AddParticipant',
        locations: locationFilters,
        positions: positionFilters,
      }),
    );

    dispatch(
      addParticipantsToConversation({ conversationId: conversation?.sid, participants: selectedUsers.toArray() }),
    )
      .unwrap()
      .then(() => dispatch(viewStackPop()));
  }, [dispatch, selectedUsers]);

  const handleBack = useCallback(() => {
    dispatch(viewStackPop());
    dispatch(
      trackFilterUse({
        channelCreated: false,
        workflow: 'AddParticipant',
        locations: locationFilters,
        positions: positionFilters,
      }),
    );
  }, [dispatch]);

  const buttons: HeaderButton[] = [
    {
      title: 'Back',
      action: handleBack,
      label: [<FontIcon className="mr-1" key="back" icon="chevron-left" />, 'Back'],
      side: 'left',
      color: 'secondary',
    },
    {
      className: 'green big',
      action: handleAddParticipants,
      label: 'Done',
      disabled: !selectedUsers.size,
      side: 'right',
    },
  ];

  return (
    <div className="new-conversation">
      <HeaderV2 buttons={buttons}>Add Coworkers</HeaderV2>
      <UserPickerV2
        user={user}
        account={account}
        users={users.filter(user => user.is_active)}
        participants={participantsMappedByUserId}
        setUsers={setSelectedUsers}
        enableFilters={false}
        updateTrackedFilters={(locations, positions) => {
          setLocationFilters(locations);
          setPositionFilters(positions);
        }}
      />
    </div>
  );
}
