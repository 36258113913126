import { getActionableOpenShiftRequests } from 'data/openshiftRequests/selectors';
import Request from 'data/request/model';
import Swap from 'data/swap/model';
import { getCachedNewNotifications, setCachedNewNotifications } from 'notifications/util';
import { getAuthUser } from 'shared/auth/selectors';
import {
  absencesSelector,
  loadedSelector,
  notificationsSelector,
  requestsSelector,
  shiftsSelector,
  swapsSelector,
  usersSelector,
} from './storeSelectors';
import { getLocationCallout } from './verifyLocation';

import { createSelector } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';

const displayInteractiveRequest = (request, notifications) => {
  if (request.status !== Request.Status.PENDING) {
    return false;
  }
  return notifications.some(notification => notification.request_id === request.id);
};

const displayInteractiveSwap = (swap, currentUser) => {
  if (swap.status !== Swap.Status.PENDING && swap.status !== Swap.Status.APPROVED) {
    return false;
  }
  if (swap.user_id !== currentUser.id) {
    if (!currentUser.canSupervise() || swap.status === Swap.Status.APPROVED) {
      let pending = false;
      swap.statuses.map(stat => {
        if (stat.status === Swap.Status.PENDING && stat.user_id === currentUser.id) {
          pending = true;
        }
      });
      if (!pending) {
        return false;
      }
    }
  }
  return true;
};

const displayUserApprovals = (user, currentUser) => {
  if (currentUser.canManage() && user.activated === false && user.is_deleted === false) {
    return true;
  }
  return false;
};

const displayInteractiveAbsences = (absence, shifts) => {
  const shift = shifts.get(absence.asInt('shiftId'));

  if (!shift || absence.asInt('userId') !== shift.user_id || absence.mustLuxonDate('shiftEndTime') <= DateTime.now()) {
    return false;
  }

  return true;
};

const filterInteractives = (currentUser, requests, swaps, users, notifications, absences, shifts) => {
  return requests
    .filter(request => displayInteractiveRequest(request, notifications))
    .toList()
    .concat(swaps.filter(swap => displayInteractiveSwap(swap, currentUser)).toList())
    .concat(users.filter(user => displayUserApprovals(user, currentUser)).toList())
    .concat(absences.filter(absence => displayInteractiveAbsences(absence, shifts)).toList())
    .filter(entry => (entry.creator_id ? users.has(entry.creator_id) : true))
    .sortBy(entry => (entry.created_at ? -entry.date('created_at').unix() : -entry.date('createdAt').unix()));
};

const filterActions = (currentUser, entries, location, openshiftRequests) => {
  let actions = entries.filter(entry => entry.user_id !== currentUser.id);

  if (openshiftRequests.size > 0 && currentUser.canSupervise()) {
    actions = actions.concat(openshiftRequests.toArray());
  }

  if (location) {
    actions = actions.unshift(location);
  }
  return actions;
};

const filterWaiting = (currentUser, entries) => {
  return entries.filter(entry => entry.user_id === currentUser.id);
};

const filterCount = (user, loaded, actions) => {
  if (!loaded) {
    return getCachedNewNotifications(user.id);
  }
  const count = actions.size;
  setCachedNewNotifications(user.id, count);
  return count;
};

export const getInteractiveEntries = createSelector(
  [
    getAuthUser,
    requestsSelector,
    swapsSelector,
    usersSelector,
    notificationsSelector,
    absencesSelector,
    shiftsSelector,
  ],
  filterInteractives,
);

export const getActionNotifications = createSelector(
  [getAuthUser, getInteractiveEntries, getLocationCallout, getActionableOpenShiftRequests],
  filterActions,
);

export const getWaitingNotifications = createSelector([getAuthUser, getInteractiveEntries], filterWaiting);

export const getNotificationsCount = createSelector([getAuthUser, loadedSelector, getActionNotifications], filterCount);
