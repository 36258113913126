import { List } from 'immutable';
import { useEffect } from 'react';

import { popScreen, track } from 'workchat/actions';
import BigParticipantList from 'workchat/components/BigParticipantList';

import FontIcon from 'shared/ui/FontIcon';
import { useWiwDispatch, useWiwSelector } from 'store';
import Header, { type HeaderButton } from 'workchat/containers/Header';
import { getMessageReaders } from 'workchat/selectors/getMessageReaders';

import 'workchat/styles/ViewReceipts.scss';

export default function ViewReceipts() {
  const readers = useWiwSelector(getMessageReaders);
  const dispatch = useWiwDispatch();

  useEffect(() => {
    dispatch(track('WorkChat: Viewed Read Receipts'));
  }, []);

  const back = () => {
    dispatch(popScreen());
  };

  const buttons: HeaderButton[] = [
    {
      title: 'Back',
      action: back,
      label: [<FontIcon className="mr-1" key="back" icon="chevron-left" />, 'Back'],
      side: 'left',
      color: 'secondary',
    },
  ];

  return (
    <div className="view-receipts">
      <Header buttons={buttons}>Read By</Header>
      <div className="row no-gutters participants-list">
        <BigParticipantList users={List(readers)} />
      </div>
    </div>
  );
}
