import PropTypes from 'prop-types';
import { Component } from 'react';

import classnames from 'classnames';
import { round } from 'lodash';

class Currency extends Component {
  static propTypes = {
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.string,
    currency: PropTypes.string, // Currency symbol to use
    fractionalDigits: PropTypes.number, // Number of fractional digits to show after decimal point
    groupDelimiter: PropTypes.string, // Character to display between groups; i.e. 1000 is displayed as 1,000
    radixCharacter: PropTypes.string, // Character to display between dollars and cents
    showFractional: PropTypes.bool, // Should fractional part be displayed if equal to `.00`
  };

  static defaultProps = {
    currency: '$',
    showFractional: false,
    fractionalDigits: 2,
    radixCharacter: '.',
    groupDelimiter: ',',
  };

  toDollarsAndCents(amount, fractionalDigits) {
    // use lodash round because it is more consistent than js toFixed,
    // toFixed is used to add fractional zeros and convert to string
    return round(Math.abs(amount), fractionalDigits).toFixed(fractionalDigits).split('.');
  }

  formatDollars(dollars, groupDelimiter) {
    return dollars.toString().replace(/\B(?=(\d{3})+(?!\d))/g, groupDelimiter);
  }

  render() {
    const { amount, className, currency, showFractional, fractionalDigits, radixCharacter, groupDelimiter } =
      this.props;
    const negative = amount < 0;
    const [dollars, cents] = this.toDollarsAndCents(amount, fractionalDigits);

    return (
      <span
        className={classnames('currency', className, {
          'is-negative': negative,
        })}
      >
        {negative && '-'}
        <span className="sign">{currency}</span>
        <span className="amount">
          <span className="dollars">{this.formatDollars(dollars, groupDelimiter)}</span>
          {(cents > 0 || showFractional) && (
            <span className="cents">
              {radixCharacter}
              {cents}
            </span>
          )}
        </span>
      </span>
    );
  }
}

export default Currency;
