import PropTypes from 'prop-types';
import { Component } from 'react';

import { withDialogContext } from 'dialogs/context';
import InputWrapper from 'shared/components/form/wrappers/InputWrapper';
import Select from 'shared/ui/inputs/Select';

import classnames from 'classnames';
import { propTypes, withFormsy } from 'formsy-react';
import { omit } from 'lodash';

export class ValidatedSelect extends Component {
  state = {
    focus: false,
  };

  static propTypes = {
    ...propTypes,
    label: PropTypes.any,
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    clearable: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.oneOf(['auto']), PropTypes.string]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.array]),
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    multi: PropTypes.bool,
    type: PropTypes.oneOf(['select', 'creatable', 'async']),
    validatePristine: PropTypes.bool,
    autoFocus: PropTypes.bool,
    controlled: PropTypes.bool,
  };

  static defaultProps = {
    width: false,
    clearable: false,
    validatePristine: false,
    autoFocus: false,
    onChange: () => {},
    inputProps: {},
    controlled: false,
  };

  handleChange = option => {
    if (this.props.isMulti) {
      if (!this.props.controlled) {
        this.props.setValue(option);
      }

      this.props.onChange(this.props.name, option);
    } else {
      // eslint-disable-next-line no-prototype-builtins
      const value = option?.hasOwnProperty('value') ? option.value : null;
      if (!this.props.controlled) {
        this.props.setValue(value);
      }

      this.props.onChange(this.props.name, value);
    }
  };

  onBlur = () => this.setState({ focus: false });

  onFocus = () => this.setState({ focus: true });

  render() {
    const invalid =
      (this.props.showError || !this.props.isValid) && (this.props.validatePristine || !this.props.isPristine);

    const inputClassname = classnames(this.props.className, 'Select', {
      'form-control-danger': invalid,
    });

    const selectProps = omit(this.props, ['label', 'name', 'onChange', 'value', 'creatable', 'required']);

    return (
      <InputWrapper focus={this.state.focus} {...this.props} data-autofocus>
        <Select
          {...selectProps}
          name={this.props.name}
          className={inputClassname}
          clearable={this.props.clearable}
          value={this.props.value}
          options={this.props.options}
          isDisabled={this.props.isFormDisabled || this.props.disabled || this.props.isDisabled}
          onChange={this.handleChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          {...this.props.inputProps}
        />
      </InputWrapper>
    );
  }
}
/**
 * @deprecated please migrate to hook-form from shared/form/inputs
 */
export default withFormsy(withDialogContext(ValidatedSelect));
