import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchAccountMetadataIfNeeded, fetchUserMetadataIfNeeded } from 'data/metadata/actions';
import { getOnboardingSchedulingFullScreenClosed } from 'onboarding/selectors';

export default function shouldSchedulerOnboard(ComposedComponent) {
  class shouldSchedulerOnboard extends Component {
    static propTypes = {
      fetchAccountMetadataIfNeeded: PropTypes.func.isRequired,
      fetchUserMetadataIfNeeded: PropTypes.func.isRequired,
      history: PropTypes.shape({
        push: PropTypes.func.isRequired,
      }).isRequired,
      onboardingSchedulingFullScreenClosed: PropTypes.bool.isRequired,
      location: PropTypes.object.isRequired,
    };

    _isMounted = false;

    componentWillUnmount() {
      this._isMounted = false;
    }

    componentDidMount() {
      const { fetchAccountMetadataIfNeeded, fetchUserMetadataIfNeeded } = this.props;

      this._isMounted = true;

      this._redirectPromise = Promise.all([fetchUserMetadataIfNeeded(), fetchAccountMetadataIfNeeded()]).then(() => {
        this.redirectIfNeeded();
      });
    }

    componentDidUpdate() {
      this.redirectIfNeeded();
    }

    shouldRedirect() {
      const { onboardingSchedulingFullScreenClosed, location } = this.props;

      if (!this._isMounted) {
        return false;
      }

      return !onboardingSchedulingFullScreenClosed && !location.pathname.includes('scheduler/onboarding');
    }

    redirectIfNeeded() {
      const { history } = this.props;

      if (this.shouldRedirect()) {
        history.push('/scheduler/onboarding');
      }
    }

    render() {
      if (this.shouldRedirect()) {
        return null;
      }
      // draw the scheduler
      return <ComposedComponent {...this.state} {...this.props} />;
    }
  }

  return connect(
    state => ({
      onboardingSchedulingFullScreenClosed: getOnboardingSchedulingFullScreenClosed(state),
    }),
    {
      fetchAccountMetadataIfNeeded,
      fetchUserMetadataIfNeeded,
    },
  )(withRouter(shouldSchedulerOnboard));
}
