import { MenuItem } from '../MenuItem';

export default function DownloadApp() {
  return (
    <MenuItem
      className="menu-icon hint--bottom hint--rounded hint--no-animate download-menu-item"
      label="Get the mobile app"
      hideLabel="desktop"
      to="https://play.google.com/store/apps/details?id=com.thisclicks.wiw"
      showPlayStore={true}
    />
  );
}
