import { updateAccountMetadata } from 'data/metadata/actions';
import type { WiwDispatch, WiwState } from 'store';

//
// Free trial status endpoint
// https://docs.wheniwork.net/_static/monolith/master/apidocs.html#tag/Subscriptions/paths/~12~1subscriptions~1freetrial/get
//

type FreeTrialStatusAvailable = {
  status: 'available';
  featureSet: { [feature: string]: boolean };
};

type FreeTrialStatusUnavailable = {
  status: 'active' | 'unavailable';
  featureSet: null;
};

export type FreeTrialStatus = (FreeTrialStatusAvailable | FreeTrialStatusUnavailable) & {
  type: 'small-business' | 'enterprise' | 'undefined' | null;
  expiresDate: string | null;
  renewalDate: string | null;
  deniedReason: string | null; // there are many concrete values for this but none we care about in code
};

//
// Metadata
//

export interface ThirtyDayFreeTrialMetadata {
  userDeclinedDate?: string | null;
  startedTrialDate?: string | null;
  expiresDate?: string | null;
  sawFinalDialog?: boolean;
}

const METADATA_KEY = 'thirtyDayFreeTrial';

export function getTrialMetadata(state: WiwState): ThirtyDayFreeTrialMetadata | undefined {
  const metadata = state.data.metadata.account;
  const trialJSON: string | undefined = metadata.get(METADATA_KEY);

  return trialJSON && JSON.parse(trialJSON);
}

export function updateTrialMetadata(metadata: ThirtyDayFreeTrialMetadata): (dispatch: WiwDispatch) => void {
  return dispatch => {
    dispatch(
      updateAccountMetadata({
        [METADATA_KEY]: JSON.stringify(metadata),
      }),
    );
  };
}
