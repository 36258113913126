export const FILTER_SHIFT = 'shift';
export const FILTER_POSITION = 'position';
export const FILTER_SITE = 'site';
export const FILTER_UNSCHEDULED = 'filter_unscheduled';
export const FILTER_PROJECTIONS = 'filter_projections';
export const FILTER_OTHER_SCHEDULES = 'filter_other_schedules';
export const FILTER_UNSCHEDULED_POSITIONS = 'filter_uncheduled_positions';
export const FILTER_UNSCHEDULED_JOBSITES = 'filter_uncheduled_jobsites';
export const HIGHLIGHT_OPENSHIFTS = 'highlight_openshifts';
export const HIGHLIGHT_UNCONFIRMED_SHIFTS = 'highlight_unconfirmed_shifts';
export const HIDE_FILTERED_USERS = 'hide_filtered_users';
