import type { StripeCustomer } from 'data/stripe/types/customer';
import type { StripeInvoice } from 'data/stripe/types/invoice';
import type { StripePlan } from 'data/stripe/types/plans';
import type { StripePrice } from 'data/stripe/types/prices';
import type { StripeSubscription } from 'data/stripe/types/subscriptions';
import Billing from 'shared/api/billing';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type FetchAccountBillingSummaryArgs = undefined;

type FetchAccountBillingSummaryResponse = {
  plans: {
    plans: StripePlan[];
    prices: Map<StripePrice['id'], StripePrice>;
  };
  subscriptions: StripeSubscription[];
  upcomingInvoices: StripeInvoice[];
  customer?: StripeCustomer;
};

export const fetchAccountBillingSummary = createWiwAsyncThunk<
  FetchAccountBillingSummaryResponse,
  FetchAccountBillingSummaryArgs
>('stripe/fetchAccountBillingSummary', async (_, { rejectWithValue }) => {
  try {
    const raw = await Billing.url('/account/summary').get();
    return raw.data as FetchAccountBillingSummaryResponse;
  } catch (error) {
    return rejectWithValue(parseAPIError(error));
  }
});
