import Token, { type TokenClaims } from 'data/auth/model';

import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';

export function getToken(): string | undefined {
  return Cookies.get('W-Token');
}

export function setToken(token: string, options = {}) {
  const params = {
    domain: CONFIG.COOKIE_DOMAIN,
    ...options,
  };

  return Cookies.set('W-Token', token, params);
}

export function removeToken() {
  return Cookies.remove('W-Token', { domain: CONFIG.COOKIE_DOMAIN });
}

export function removeUserId() {
  return Cookies.remove('W-UserId', { domain: CONFIG.COOKIE_DOMAIN });
}

export function getTokenInfo() {
  const token = getToken();

  const claims = token ? jwtDecode<TokenClaims>(token) : ({} as TokenClaims);
  return new Token(claims);
}

export function getUserId() {
  const cookieValue = Cookies.get('W-UserId');
  const userId = cookieValue ? Number.parseInt(cookieValue, 10) : Number.NaN;

  return userId;
}

export function getStepUpToken(): string | undefined {
  return Cookies.get('W-StepUpToken');
}

export function setStepUpToken(token: string, options = {}) {
  const params = {
    domain: CONFIG.COOKIE_DOMAIN,
    ...options,
  };

  return Cookies.set('W-StepUpToken', token, params);
}

export function removeStepUpToken() {
  return Cookies.remove('W-StepUpToken', { domain: CONFIG.COOKIE_DOMAIN });
}

export function getStepUpTokenInfo() {
  const token = getStepUpToken();
  if (!token) {
    return undefined;
  }

  const claims = token ? jwtDecode<TokenClaims>(token) : ({} as TokenClaims);
  return new Token(claims);
}
