import { Suspense } from 'react';

import { Body, type CloseDialogFunction, Dialog, type DialogStateItem, type DialogType, Footer, Header } from 'dialogs';
import { closeDialog as reduxCloseDialog } from 'dialogs/actions';
import { type CloseDialogOpts, DialogContext, dialogCallbacks, dialogOnCloseListeners } from 'dialogs/context';
import combinedDialogs from 'dialogs/dialogs';
import { Loading } from 'shared/components/Loading';
import { useWiwDispatch, useWiwSelector } from 'store';

export default function DialogContainer() {
  const dispatch = useWiwDispatch();

  const dialogs = useWiwSelector(state => state.dialogs);

  const closeDialog =
    (name: DialogType): CloseDialogFunction =>
    (opts?: CloseDialogOpts) => {
      if (dialogOnCloseListeners.has(name)) {
        if (dialogOnCloseListeners.get(name)?.(opts) === false) {
          return;
        }
      }
      dialogCallbacks.delete(name);
      dispatch(reduxCloseDialog(name));
      return;
    };

  const renderLoadingDialog = (closeDialogFn: CloseDialogFunction) => {
    return (
      <Dialog width={400} name="LOADING_DIALOG">
        <Header onClose={closeDialogFn} />
        <Body>
          <div style={{ height: 100, position: 'relative' }}>
            <Loading className="small" />
          </div>
        </Body>
        <Footer />
      </Dialog>
    );
  };

  const renderDialog = (dialog: DialogStateItem, isOnTop: boolean) => {
    const name = dialog.dialog;

    // Find the Dialog Component
    // If missing, clear from redux and warn.
    const DialogNode = combinedDialogs[name];
    const closeDialogFn = closeDialog(name);
    if (!DialogNode) {
      console.warn(`Cannot find dialog with key ${name}`);
      closeDialogFn();
      return null;
    }
    // Wrap any dialog style in the Context Provider
    return (
      <DialogContext.Provider
        key={name}
        value={{
          closeDialog: closeDialogFn,
          name: name,
          inDialog: true,
        }}
        data-testid={name}
      >
        <Suspense fallback={renderLoadingDialog(closeDialogFn)}>
          <DialogNode name={dialog.dialog} {...dialog.payload} closeDialog={closeDialogFn} isOnTop={isOnTop} />
        </Suspense>
      </DialogContext.Provider>
    );
  };

  const dialogList = [];

  if (dialogs.length) {
    // Iterate through all dialogs
    for (let i = 0; i < dialogs.length; i++) {
      const dialog = dialogs[i];
      // Attempt to render the current dialog
      const rendered = renderDialog(dialog, i === 0);
      if (rendered) {
        // Add the rendered dialog to the beginning of the list
        // This ensures the most recent dialog appears on top
        dialogList.unshift(rendered);
      }
      // If the current dialog doesn't allow overlay,
      // stop rendering additional dialogs
      if (!dialog.options.overlay) {
        break;
      }
    }
  }

  return <div className="dialogs--holder">{dialogList}</div>;
}
