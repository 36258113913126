import Workchat from 'shared/api/workchat';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

// Define the type for a Participant
export interface WorkchatAPIUser {
  id: number;
  first_name: string;
  last_name: string;
  avatar_url: string;
  is_deleted: number;
  positions: string[];
  locations: string[];
}

export const loadWorkchatUsers = createWiwAsyncThunk<WorkchatAPIUser[], void>(
  'workchatv2/users/load',
  async (_params, { rejectWithValue }) => {
    try {
      const resp = await Workchat.url('/accounts/participants').get();
      return resp.participants;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
