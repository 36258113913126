import { z } from 'zod';

const dateOnlyRegex = /^\d{4}-\d{2}-\d{2}$/;
export const StringDateSchema = z.string().regex(dateOnlyRegex).brand<'ISODateOnly'>();
export type ISODateOnly = z.infer<typeof StringDateSchema>;

export const StringDateTimeSchema = z.string().datetime({ offset: true }).brand<'ISODateTime'>();
export type ISODateTime = z.infer<typeof StringDateTimeSchema>;

export const UnknownArray = z.array(z.unknown());

/**
 * A simple wrapper for parsing our standard Go data response type.
 */
export function makeGoDataSchema<T extends z.ZodTypeAny>(schema: T) {
  return z.object({ data: schema });
}
