import BaseModel from 'data/BaseModel';

import moment from 'moment-timezone';
import type { Stringifiable } from 'query-string';

export type CalculationMethod = 'annual' | 'per-period';

export interface SalariedWage {
  id: number;
  accountId: number;
  effectiveStartDate: string;
  effectiveEndDate: string | null;
  deletedAt: string | null;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  createdAt: Stringifiable;
  amount: number | null;
  userId: string;
  calculationMethod: CalculationMethod;
}

export default class SalariedWageRecord extends BaseModel<SalariedWage>({
  id: 0,
  accountId: 0,
  effectiveStartDate: '',
  effectiveEndDate: null,
  deletedAt: null,
  updatedAt: '',
  updatedBy: '',
  createdAt: '',
  createdBy: '',
  amount: 0,
  userId: '0',
  calculationMethod: 'annual',
}) {
  static getEffectiveStart(tz: string) {
    return moment().tz(tz).add(1, 'day').startOf('day').format('YYYY-MM-DDTHH:mm:ssZ');
  }
}
