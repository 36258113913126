import { Modal, type ModalBodyProps } from '@mantine/core';
import cx from 'classnames';

export default function Body({ className, children, ...props }: ModalBodyProps) {
  return (
    <Modal.Body className={cx('dialog-body', className)} {...props}>
      {children}
    </Modal.Body>
  );
}
