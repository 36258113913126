import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';

import type Account from 'data/account/model';
import type User from 'data/user/model';
import { markSplashDisplayed, shouldDisplaySplash } from 'workchat/actions/splash';
import welcomeImg from 'workchat/assets/welcome.svg';

import 'workchat/styles/Splash.scss';

type SplashProps = {
  user: User;
  account: Account;
};

export default function Splash({ user, account }: SplashProps) {
  const [showSplash, setShowSplash] = useState(false);
  const token = Cookies.get('W-Token');

  useEffect(() => {
    shouldDisplaySplash(user, account, token).then(display => {
      if (display) {
        setShowSplash(true);
      }
    });
  }, []);

  if (!showSplash) {
    return null;
  }

  function closeSplash() {
    markSplashDisplayed(user, account, token);
    setShowSplash(false);
  }

  return (
    <div className="workchat-splash-cover">
      <div className="workchat-splash">
        <img alt="Welcome to WorkChat!" src={welcomeImg} />
        <h3>Welcome to WorkChat!</h3>
        <p>
          Use WorkChat to connect your team, without sharing phone numbers. Create group or one-on-one messages to keep
          your workplace communication all in one place!
        </p>
        <button type="button" onClick={() => closeSplash()}>
          Let's get started
        </button>
      </div>
    </div>
  );
}
