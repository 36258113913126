import type { DialogType } from 'dialogs/dialogs';
import { DIALOG_CLOSE, DIALOG_CLOSE_ALL, DIALOG_OPEN, DIALOG_UPDATE } from 'store/action-types';
import type { DialogAction } from './actions';

export type DialogOptions = {
  style?: string; // the only useful value I can find is "modal".
  overlay?: boolean;
  overlayHidden?: boolean;
  isDismissable?: boolean;
};
export interface DialogStateItem {
  dialog: DialogType;
  options: DialogOptions;
  payload: Record<string, unknown>;
}
export type DialogState = DialogStateItem[];

export default function (state: DialogState = [], action: DialogAction): DialogState {
  switch (action.type) {
    case DIALOG_OPEN:
      return [
        {
          dialog: action.dialog,
          options: action.options,
          payload: action.payload,
        },
        ...state,
      ];
    case DIALOG_UPDATE:
      return state.map(item => {
        if (item.dialog === action.dialog) {
          return {
            ...item,
            payload: action.payload,
            options: action.options,
          };
        }
        return item;
      });
    case DIALOG_CLOSE:
      return state.filter(dialog => dialog.dialog !== action.dialog);
    case DIALOG_CLOSE_ALL:
      return [];
    default:
      return state;
  }
}
