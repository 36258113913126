import absenceDialogs from 'requests/modules/absence/dialogs';
import shiftDialogs from 'requests/modules/shifts/dialogs';
import timeOffDialogs from 'requests/modules/timeoff/dialogs';

const dialogs = {
  ...shiftDialogs,
  ...timeOffDialogs,
  ...absenceDialogs,
};

export default dialogs;
