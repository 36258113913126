import BaseModel from 'data/BaseModel';

import { Map } from 'immutable';

export interface TemplateFields {
  id: number | null;
  account_id: number;
  /** @deprecated */
  location_id: number;
  name: string;
  description: string;
  start_date: string | null;
  end_date: string | null;
  shifts: Map<any, any>;
  created_at: string | null;
  updated_at: string | null;
}

export enum TemplateStrategies {
  AvoidConflicts = 'avoid_conflicts',
  AllowDuplicates = 'allow_duplicates',
  Overwrite = 'overwrite',
  LoadToOpenshift = 'load_to_openshift',
}

class Template extends BaseModel<TemplateFields>({
  id: null,
  account_id: 0,
  location_id: 0,
  name: '',
  description: '',
  start_date: null,
  end_date: null,
  shifts: Map(),
  created_at: null,
  updated_at: null,
}) {
  static strategies = {
    AVOID_CONFLICTS: TemplateStrategies.AvoidConflicts,
    ALLOW_DUPLICATES: TemplateStrategies.AllowDuplicates,
    OVERWRITE: TemplateStrategies.Overwrite,
    LOAD_TO_OPENSHIFT: TemplateStrategies.LoadToOpenshift,
  };
}

export default Template;
