import Cookies from 'js-cookie';

const serviceHeaders = ['branch-attendance', 'branch-billing', 'branch-payroll', 'branch-platform'];

export function addBranchSwitchingHeaders(headers: Record<string, string>) {
  const branchCookie = Cookies.get('branch');
  if (branchCookie) {
    headers.branch = branchCookie;
  }

  for (const header of serviceHeaders) {
    const value = Cookies.get(header);
    if (value) {
      // service headers must be lowercase for matching to work
      headers[header] = value.toLocaleLowerCase();
    }
  }
}
