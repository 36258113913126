export function getCachedNewNotifications(userId) {
  const cacheKey = getNewNotificationsCacheKey(userId);
  if (cacheKey) {
    const count = sessionStorage.getItem(cacheKey);
    if (count) {
      return JSON.parse(count);
    }
  }
  return null;
}

export function setCachedNewNotifications(userId, value) {
  const cacheKey = getNewNotificationsCacheKey(userId);
  if (cacheKey && Number.isInteger(value)) {
    sessionStorage.setItem(cacheKey, JSON.stringify(value));
  }
}

export function clearCachedNewNotifications(userId) {
  const cacheKey = getNewNotificationsCacheKey(userId);
  if (cacheKey) {
    sessionStorage.removeItem(cacheKey);
  }
}

function getNewNotificationsCacheKey(userId) {
  if (userId) {
    return `notification-counter-${userId}`;
  }
}
