import { closeDialog, openDialog } from 'dialogs/actions';

const CREATE_REQUEST_DIALOG = 'CREATE_REQUEST_DIALOG';
const CREATE_EXPORT_DIALOG = 'CREATE_EXPORT_DIALOG';

export const openCreateRequestDialog = (payload = {}, options = {}) =>
  openDialog(CREATE_REQUEST_DIALOG, payload, { overlay: true, ...options });
export const closeCreateRequestDialog = closeDialog.bind(null, CREATE_REQUEST_DIALOG);

export const openExportRequestDialog = openDialog.bind(null, CREATE_EXPORT_DIALOG);
export const closeExportRequestDialog = closeDialog.bind(null, CREATE_EXPORT_DIALOG);
