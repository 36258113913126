import { Record } from 'immutable';

export default class GustoCheck extends Record({
  ready: false,
  unmatched: {
    // If there are any users in the current pay period that have hours but are not
    // mapped to a user in Gusto then that user will be listed here.
    users: [],

    // The payrolls object does not represent a when i work payroll, instead it
    // represents a payroll/period in Gusto that we can send payroll data for. The
    // user must select which pay period they would like to send payroll data to
    // from this list.
    payrolls: [],
  },

  // Payroll will either be a Gusto payroll model or it will be false. If it is
  // false then there are multiple pay periods that data could be sent to, if
  // if it is not false then the payroll can be sent to this pay period.
  payroll: {
    version: '',
    skip_users: [],
  },
}) {}
