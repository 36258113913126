import Punch from 'data/punch/model';
import PunchDataState from 'data/punch/states/punchDataState';
import { camelCaseToSnakeCaseObject } from 'shared/util/objectCaseUtilities';
import { FETCH_PUNCHES } from 'store/action-types';

export default (state = new PunchDataState(), action) => {
  switch (action.type) {
    case FETCH_PUNCHES.REQUEST:
      return state.merge({
        loading: true,
        receivedAt: 0,
        loaded: false,
      });
    case FETCH_PUNCHES.SUCCESS:
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        receivedAt: Date.now(),
        items: state.items.merge(action.items.map(punch => [punch.id, new Punch(camelCaseToSnakeCaseObject(punch))])),
      });
    case FETCH_PUNCHES.FAILURE:
      return state.merge({
        loaded: false,
        loading: false,
        error: action.payload,
      });
    default:
      return state;
  }
};
