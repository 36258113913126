import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import LocationCallout from 'data/notifications/callouts/location';
import { openDialog } from 'dialogs/actions';
import { ActionButton } from 'notifications/components/ActionButton';
import { NotifyItem } from 'notifications/components/NotifyItem';
import { VERIFY_ACCOUNT_LOCATION_DIALOG, VERIFY_SCHEDULE_LOCATION_DIALOG } from 'settings/dialogs';

import { bindActionCreators } from '@reduxjs/toolkit';

import LocationAvatarImage from 'notifications/assets/img/location-avatar.png';

export class VerifyLocation extends Component {
  static propTypes = {
    type: PropTypes.oneOf([LocationCallout.Types.VERIFY_LOCATION, LocationCallout.Types.VERIFY_SITE_LOCATION])
      .isRequired,
    reference: PropTypes.object,
    openDialog: PropTypes.func.isRequired,
  };

  getActions = () => {
    return [<ActionButton key="verify-location" title="Verify Now" type="green" click={this.verifyLocation} />];
  };

  verifyLocation = () => {
    switch (this.props.type) {
      case LocationCallout.Types.VERIFY_SITE_LOCATION:
        return this.props.openDialog(VERIFY_SCHEDULE_LOCATION_DIALOG, { notificationLocation: this.props.reference });
      case LocationCallout.Types.VERIFY_LOCATION:
        return this.props.openDialog(VERIFY_ACCOUNT_LOCATION_DIALOG);
    }
  };

  getStatus = () => {
    return { name: 'Pending', style: '' };
  };

  getTitle = () => {
    switch (this.props.type) {
      case LocationCallout.Types.VERIFY_SITE_LOCATION:
        return 'Verify your Schedule address so your users can find you.';
      default:
        return 'Verify your Workplace address so your users can find you.';
    }
  };

  getAvatar = () => {
    return <img src={LocationAvatarImage} alt="location avatar image" />;
  };

  render() {
    return (
      <NotifyItem
        title={this.getTitle()}
        tag={this.getStatus()}
        avatar={this.getAvatar()}
        actions={this.getActions()}
      />
    );
  }
}

export default connect(null, dispatch =>
  bindActionCreators(
    {
      openDialog,
    },
    dispatch,
  ),
)(VerifyLocation);
