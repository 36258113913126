import { getCustomMetadata } from 'data/reporting/selectors/getCustomMetadata';
import type { CustomMetadataStateByDatasetByAccountId } from 'data/reporting/state/CustomMetadataState';
import { getReportAccountId } from 'reporting/selectors/getReportAccountId';

import { createSelector } from '@reduxjs/toolkit';

// If the total rows per day for all datasets is zero, there might be a report generation issue, or just no data
// on the account.
// This selector is used to display an information dialog for that scenario in the ReportDialogFlow.
export const getTotalRowsPerDayAllDatasets = createSelector(
  getCustomMetadata,
  getReportAccountId,
  (customMetadata: CustomMetadataStateByDatasetByAccountId, reportAccountId) => {
    let total = 0;
    const metadata = customMetadata.get(`${reportAccountId}`);
    if (!metadata) {
      return total;
    }
    for (const [_, customMetadataState] of metadata) {
      const { rowsPerDay } = customMetadataState;
      for (const [_, value] of rowsPerDay) {
        total += value.count;
      }
    }
    return total;
  },
);
