import { bindActionCreators } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { modal } from 'workchat/actions';

import 'workchat/styles/ConfirmModal.scss';

class ConfirmModal extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    confirm: PropTypes.string,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,

    modal: PropTypes.func.isRequired,
  };

  static defaultProps = {
    confirm: 'Confirm',
  };

  cancel = _e => {
    const { modal, onCancel } = this.props;
    if (onCancel) {
      onCancel();
    }
    modal();
  };

  confirm = _e => {
    const { modal, onConfirm } = this.props;
    if (onConfirm) {
      onConfirm();
    }
    modal();
  };

  render() {
    const { title, text, confirm } = this.props;

    return (
      <div className="workchat-background">
        <div className="workchat-modal">
          <button type="button" className="close" onClick={this.cancel}>
            <i className="fa fa-times" />
          </button>
          <h3>{title}</h3>
          <p>{text}</p>
          <button type="button" className="confirm" onClick={this.confirm}>
            {confirm ? confirm : 'Confirm'}
          </button>
          <button type="button" className="cancel" onClick={this.cancel}>
            Cancel
          </button>
        </div>
      </div>
    );
  }
}

export default connect(
  // State to send to the Component as props
  _state => {
    return {};
  },
  // Dispatch functions to send to the Component as props
  dispatch =>
    bindActionCreators(
      {
        modal,
      },
      dispatch,
    ),
)(ConfirmModal);
