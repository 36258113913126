import BaseModel from 'data/BaseModel';

import { uniqueId } from 'lodash';

export interface PayrollNoticeFields {
  id: string | null;
  time_id: number;
  user_id: number;
  shift_id: number;
  punch_id: number;
  absence_id: number;
  code: number;
  title: string;
  created_at: string | null;
}

export enum PayrollNoticeTypes {
  WrongLocation = 103,
  ForgotClockOut = 107,
  NotScheduled = 110,
  NoShow = 120,
  Absent = 121,
  ClockInEarly = 131,
  ClockInLate = 132,
  ClockOutEarly = 133,
  ClockOutLate = 134,
  MissedClockIn = 135,
}

class PayrollNotice extends BaseModel<PayrollNoticeFields>({
  id: null,
  time_id: 0,
  user_id: 0,
  shift_id: 0,
  punch_id: 0,
  absence_id: 0,
  code: 0,
  title: '',
  created_at: null,
}) {
  static Types = {
    WRONG_LOCATION: PayrollNoticeTypes.WrongLocation,
    FORGOT_CLOCK_OUT: PayrollNoticeTypes.ForgotClockOut,
    NOT_SCHEDULED: PayrollNoticeTypes.NotScheduled,
    NO_SHOW: PayrollNoticeTypes.NoShow,
    ABSENT: PayrollNoticeTypes.Absent,
    CLOCK_IN_EARLY: PayrollNoticeTypes.ClockInEarly,
    CLOCK_IN_LATE: PayrollNoticeTypes.ClockInLate,
    CLOCK_OUT_EARLY: PayrollNoticeTypes.ClockOutEarly,
    CLOCK_OUT_LATE: PayrollNoticeTypes.ClockOutLate,
    MISSED_CLOCK_IN: PayrollNoticeTypes.MissedClockIn,
  } as const;

  constructor(args: Partial<PayrollNoticeFields> = {}) {
    super({
      ...args,
      id: uniqueId(),
    });
  }
}

export default PayrollNotice;
