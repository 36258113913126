export default function isRequiredTimePeriod(values, value) {
  if (value.start === undefined || value.end === undefined) {
    return true;
  }

  if (value.start === '' && value.end === '') {
    return true;
  }

  return false;
}
