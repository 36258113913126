import { lazy } from 'react';

const ConfirmNoCustomRequestTypesIntegrationDialog = lazy(
  () => import('integrations/dialogs/ConfirmNoCustomRequestTypesIntegrationDialog'),
);
const ManagedByProviderDialog = lazy(() => import('integrations/dialogs/ManagedByProviderDialog'));
const RequestIntegrationDialog = lazy(() => import('integrations/dialogs/RequestIntegrationDialog'));

export const CONFIRM_NO_CUSTOM_REQUEST_TYPES_INTEGRATION_DIALOG = 'CONFIRM_NO_CUSTOM_REQUEST_TYPES_INTEGRATION_DIALOG';
export const MANAGED_BY_PROVIDER_DIALOG = 'MANAGED_BY_PROVIDER_DIALOG';
export const REQUEST_INTEGRATION_DIALOG = 'REQUEST_INTEGRATION_DIALOG';

const dialogs = {
  [CONFIRM_NO_CUSTOM_REQUEST_TYPES_INTEGRATION_DIALOG]: ConfirmNoCustomRequestTypesIntegrationDialog,
  [MANAGED_BY_PROVIDER_DIALOG]: ManagedByProviderDialog,
  [REQUEST_INTEGRATION_DIALOG]: RequestIntegrationDialog,
} as const;

export default dialogs;
