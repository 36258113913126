import { useIdle, useInterval } from '@mantine/hooks';
import { type PropsWithChildren, useEffect, useState } from 'react';
import useWhiteBg from 'shared/hooks/useWhiteBg';

import './assets/styles/MaintenancePage.scss';
import logo from 'styles/assets/img/wiw-logo.svg';
import wretch from 'wretch';

export default function MaintenancePage(props: PropsWithChildren<any>) {
  const [underMaintenance, setUnderMaintenance] = useState(false);

  const isIdle = useIdle(30000);
  const interval = useInterval(() => {
    fetchStatus();
  }, 60000);

  function fetchStatus() {
    return wretch('https://dc3iqqrhxngovesweoq6haogyy0fvriq.lambda-url.us-east-1.on.aws')
      .get()
      .json(json => {
        if (json.data.length !== 0 && !underMaintenance) {
          setUnderMaintenance(true);
          useWhiteBg();
          return;
        }
        setUnderMaintenance(false);
      });
  }

  useEffect(() => {
    fetchStatus();
    interval.start();
    return interval.stop;
  }, []);

  useEffect(() => {
    if (isIdle) {
      interval.stop;
      return;
    }
    interval.start();
  }, [isIdle]);

  if (!underMaintenance) {
    return props.children;
  }

  return (
    <div className="container content" id="wheniwork-is-awesome content">
      <div className="row maintenance-page justify-content-center">
        <div className="col">
          <div className="row mb-4">
            <div className="col">
              <img src={logo} alt="When I Work" />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col">
              <h1>Down for maintenance</h1>
            </div>
          </div>
          <div className="row mb-4 content">
            <div className="col-sm-7">
              <p>
                When I Work is performing scheduled maintenance today. You can keep an eye on the status page to see
                when we'll be back up and running.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md">
              <a
                className="btn btn-md btn-primary"
                href="https://status.wheniwork.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                View Status Page
              </a>
            </div>
          </div>

          <div className="row pad-top">
            <div className="col">
              <p>
                Contact <a href="mailto:support@wheniwork.com">support@wheniwork.com</a> with questions or concerns.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
