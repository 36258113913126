import { lazy } from 'react';

const MultiplePositionsDialog = lazy(() => import('./MultiplePositionsDialog'));
const PositionDialog = lazy(() => import('./PositionDialog'));

// Identifiers for each dialog
export const MULTIPLE_POSITIONS_DIALOG = 'MULTIPLE_POSITIONS_DIALOG';
export const POSITION_DIALOG = 'POSITION_DIALOG';

const dialogs = {
  [MULTIPLE_POSITIONS_DIALOG]: MultiplePositionsDialog,
  [POSITION_DIALOG]: PositionDialog,
  // ...
};

export default dialogs;

/* To open a dialog, do this
import { openDialog, closeDialog } from 'dialogs';
import { SWAP_DROP_DIALOG } from 'scheduler/dialogs';

// Bind these with redux
openDialog(SWAP_DROP_DIALOG, { swap, otherProp, payload });
closeDialog(SWAP_DROP_DIALOG);
*/
