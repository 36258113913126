import {
  CLOSE_DETAILS_MENU_POPOVER,
  CLOSE_NOTES_POPOVER,
  OPEN_DETAILS_MENU_POPOVER,
  OPEN_NOTES_POPOVER,
} from 'store/action-types';
import type { DetailsMenuPopoverData } from 'timesheets/containers/DetailsMenuPopover';
import type { NotesPopoverData } from 'timesheets/containers/NotesPopover';

export { closeAddItemPopover, openAddItemPopover } from './addItemPopover';
export { getInitialPayroll, setCurrentPayroll } from './setCurrentPayroll';
export { getInitialLocation, setPayrollLocation } from './setPayrollLocation';
export { getInitialPayrollUser, setPayrollUser } from './setPayrollUser';
export { default as setTimesheetView } from './setTimesheetView';
export { default as toggleFlagUsers } from './toggleFlagUsers';
export { default as toggleShowShifts } from './toggleShowShifts';

interface OpenDetailsMenuPopoverAction {
  type: typeof OPEN_DETAILS_MENU_POPOVER;
  target: string; // DOM element ID
  data: DetailsMenuPopoverData;
}

interface CloseDetailsMenuPopoverAction {
  type: typeof CLOSE_DETAILS_MENU_POPOVER;
}

interface OpenNotesPopoverAction {
  type: typeof OPEN_NOTES_POPOVER;
  target: string; // DOM element ID
  data: NotesPopoverData;
}

interface CloseNotesPopoverAction {
  type: typeof CLOSE_NOTES_POPOVER;
}

type TimesheetAction =
  | OpenDetailsMenuPopoverAction
  | CloseDetailsMenuPopoverAction
  | OpenNotesPopoverAction
  | CloseNotesPopoverAction;
// TODO(types): This is incomplete. Add all the others when we actually convert the reducer to TypeScript.

export function openDetailsMenuPopover(target: string, data: DetailsMenuPopoverData): TimesheetAction {
  return {
    type: OPEN_DETAILS_MENU_POPOVER,
    target: target,
    data: data,
  };
}

export function closeDetailsMenuPopover(): TimesheetAction {
  return {
    type: CLOSE_DETAILS_MENU_POPOVER,
  };
}

export function openNotesPopover(target: string, data: NotesPopoverData): TimesheetAction {
  return {
    type: OPEN_NOTES_POPOVER,
    target: target,
    data: data,
  };
}

export function closeNotesPopover(): TimesheetAction {
  return {
    type: CLOSE_NOTES_POPOVER,
  };
}
