import MyScheduleState from 'myschedule/state';

import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { Moment } from 'moment-timezone';

type MyScheduleBoundaryPayload = {
  currentDate: Moment;
  start: Moment;
  end: Moment;
};

export const initialState = new MyScheduleState();

export const myScheduleSlice = createSlice({
  name: 'mySchedule',
  initialState,
  reducers: {
    setMyScheduleBounds(state, action: PayloadAction<MyScheduleBoundaryPayload>) {
      return state.withMutations(item => {
        item.set('currentDate', action.payload.currentDate).set('boundaries', {
          start: action.payload.start,
          end: action.payload.end,
        });
      });
    },
    setMyScheduleShiftsLoaded(state, action: PayloadAction<boolean>) {
      return state.withMutations(item => {
        item.set('loaded', action.payload);
      });
    },
  },
});

export const { setMyScheduleBounds, setMyScheduleShiftsLoaded } = myScheduleSlice.actions;

export default myScheduleSlice.reducer;
