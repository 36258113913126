import { fetchCompanyStatus } from 'data/payrollCheck/actions/fetchCompanyStatus';
import { fetchEnrollmentComponent } from 'data/payrollCheck/actions/fetchEnrollmentComponent';
import type CompanyStatus from 'data/payrollCheck/models/companyStatus';
import type { ComponentResponse } from 'data/payrollCheck/models/components';
import { openDialog } from 'dialogs';
import payrollLogo from 'payrollCheck/modules/onboarding/assets/payroll.png';
import {
  CHECK_COMPANY_ONBOARD_DIALOG,
  CHECK_PREVIOUS_PROVIDER_DIALOG,
  UPGRADE_YOUR_PLAN_DIALOG,
} from 'payrollCheck/modules/onboarding/dialogs';
import { Fragment, useEffect, useState } from 'react';
import { getAuthAccount } from 'shared/auth/selectors';
import Panel from 'shared/ui/Panel';
import { openElevioCustomerCare } from 'shared/vendor/util/elevio';
import { useWiwDispatch, useWiwSelector } from 'store';
import PayrollOnboardCard, { CardState } from './components/PayrollOnboardCard';

import 'payrollCheck/modules/onboarding/assets/styles/PayrollCheckOnboarding.scss';
import { patchCompanyOnboardStatus } from 'data/payrollCheck/actions/patchCompanyOnboardingStatus';
import moment from 'moment-timezone';

import useNotice from 'notices/hooks/useNotice';
import { useHistory } from 'react-router-dom';
import useWhiteBg from 'shared/hooks/useWhiteBg';
import type { NormalizedApiError } from 'shared/redux/helpers/parseApiError';

export enum OnboardComponent {
  onboard = 0,
  previous_provider = 1,
}

export default function PayrollCheckOnboarding() {
  const [attendanceState, setAttendanceState] = useState<CardState>(CardState.none);
  const [companyOnboardState, setCompanyOnboardState] = useState<CardState>(CardState.none);
  const [onboardLoading, setOnboardLoading] = useState<boolean>(false);
  const [previousProviderState, setPreviousProviderState] = useState<CardState>(CardState.none);
  const [companyStatus, setCompanyStatus] = useState<CompanyStatus>();
  const authAccount = useWiwSelector(getAuthAccount);
  const dispatch = useWiwDispatch();
  const history = useHistory();
  const { errorNotice } = useNotice();

  useWhiteBg();

  useEffect(() => {
    getCompanyStatus();
    getAttendanceState();
  }, []);

  useEffect(() => {
    getCompanyOnboardState();
    getPreviousProviderState();
  }, [companyStatus]);

  const getCompanyStatus = () => {
    dispatch(fetchCompanyStatus())
      .unwrap()
      .then((response: CompanyStatus) => {
        setCompanyStatus(response);
      })
      .catch((err: NormalizedApiError) => {
        errorNotice(
          <Fragment>
            Error: {err.message}.{' '}
            {/* biome-ignore lint/a11y/useValidAnchor: this actually should be a link for readers */}
            <a role="button" onClick={openElevioCustomerCare}>
              Contact our Customer Care team
            </a>{' '}
            to resolve the issue.
          </Fragment>,
          { duration: 0 },
        );
      });
  };

  const getAttendanceState = () => {
    if (!authAccount.getSettings('payroll.is_onboarded', false)) {
      setAttendanceState(CardState.none);
      return;
    }

    setAttendanceState(CardState.completed);
  };

  const getCompanyOnboardState = () => {
    if (companyStatus === undefined) {
      return;
    }

    if (companyStatus?.onboarding_started_date !== null && companyStatus?.onboarding_completed_date !== null) {
      setCompanyOnboardState(CardState.completed);
      return;
    }

    if (companyStatus?.onboarding_started_date !== null) {
      setCompanyOnboardState(CardState.started);
      return;
    }

    setCompanyOnboardState(CardState.none);
  };

  const onboardCallback = (state: CardState) => {
    const now = moment().utc().format('YYYY-MM-DD');

    if (companyOnboardState !== CardState.completed) {
      setCompanyOnboardState(state);
    }
    setOnboardLoading(false);
    switch (state) {
      case CardState.started: {
        if (companyStatus?.onboarding_started_date === null && companyStatus?.onboarding_completed_date === null) {
          dispatch(
            patchCompanyOnboardStatus({
              onboarding_started: now,
            }),
          );
        }
        break;
      }
      case CardState.completed: {
        if (companyStatus?.onboarding_completed_date === null) {
          dispatch(
            patchCompanyOnboardStatus({
              onboarding_completed: now,
            }),
          );
        }
        break;
      }
    }
  };

  const providerCallback = (state: CardState) => {
    const now = moment().utc().format('YYYY-MM-DD');
    if (state === CardState.completed) {
      setPreviousProviderState(state);
    }
    switch (state) {
      case CardState.started: {
        if (companyStatus?.previous_provider_access_started_date === null) {
          dispatch(
            patchCompanyOnboardStatus({
              previous_provider_access_started: now,
            }),
          );
        }
        break;
      }
      case CardState.completed: {
        if (companyStatus?.previous_provider_access_completed_date === null) {
          dispatch(
            patchCompanyOnboardStatus({
              previous_provider_access_completed: now,
            }),
          );
        }
        break;
      }
    }
  };

  const getPreviousProviderState = () => {
    if (companyStatus === undefined) {
      return;
    }

    if (
      companyStatus?.previous_provider_access_started_date !== null &&
      companyStatus?.previous_provider_access_completed_date !== null
    ) {
      setPreviousProviderState(CardState.completed);
      return;
    }

    if (companyStatus?.previous_provider_access_started_date !== null) {
      setPreviousProviderState(CardState.started);
      return;
    }

    setPreviousProviderState(CardState.none);
  };

  const attendanceButton = () => {
    if (authAccount.hasAttendance()) {
      history.push('/attendance/setup');
      return;
    }

    dispatch(openDialog(UPGRADE_YOUR_PLAN_DIALOG));
  };

  const openCompanyDetailsModal = () => {
    setOnboardLoading(true);
    dispatch(
      fetchEnrollmentComponent({
        type: 'onboard',
      }),
    )
      .unwrap()
      .then((response: ComponentResponse) => {
        dispatch(
          openDialog(CHECK_COMPANY_ONBOARD_DIALOG, {
            url: response.url,
            callback: onboardCallback,
          }),
        );
      })
      .catch((err: NormalizedApiError) => {
        errorNotice(
          <Fragment>
            Something went wrong trying load your company details form.{' '}
            {/* biome-ignore lint/a11y/useValidAnchor: this actually should be a link for readers */}
            <a role="button" onClick={openElevioCustomerCare}>
              Contact our Customer Care team
            </a>{' '}
            to resolve the issue.
            <br />
            <br />
            Error: {err.message}
          </Fragment>,
          { duration: 0 },
        );
      });
  };

  const openPreviousProviderModal = () => {
    dispatch(
      fetchEnrollmentComponent({
        type: 'previous_provider_access',
      }),
    )
      .unwrap()
      .then((response: ComponentResponse) => {
        dispatch(
          openDialog(CHECK_PREVIOUS_PROVIDER_DIALOG, {
            url: response.url,
            callback: providerCallback,
          }),
        );
      })
      .catch(() => {
        errorNotice(
          <Fragment>
            Something went wrong trying load your company details form.{' '}
            {/* biome-ignore lint/a11y/useValidAnchor: this actually should be a link for readers */}
            <a role="button" onClick={openElevioCustomerCare}>
              Contact our Customer Care team
            </a>{' '}
            to resolve the issue.
          </Fragment>,
          { duration: 0 },
        );
      });
  };

  const componentList = () => {
    return (
      <div className="onboard-cards">
        <PayrollOnboardCard title="Set up Time & Attendance" state={attendanceState} target={attendanceButton} />
        <PayrollOnboardCard
          title="Provide additional company details"
          state={companyOnboardState}
          target={openCompanyDetailsModal}
          disabled={companyStatus === undefined}
          editable={true}
          loading={onboardLoading}
        />
        <PayrollOnboardCard
          title="Previous payroll provider access"
          state={previousProviderState}
          target={openPreviousProviderModal}
          disabled={companyStatus === undefined}
          editable={false}
        />
      </div>
    );
  };

  return (
    <div className="onboard-layout container-fluid fluid-max">
      <Fragment>
        <Panel className="onboard-panel">
          <div className="onboard-header">
            <h1>You're ready to get started with Payroll!</h1>
          </div>
          {componentList()}
          <div className="onboard-footer mt-5">
            <p>
              When you complete these steps, or if you have any questions completing them,{' '}
              {/* biome-ignore lint/a11y/useValidAnchor: this actually should be a link for readers */}
              <a href="#" onClick={openElevioCustomerCare}>
                contact our Care team
              </a>{' '}
              so we can finish your set-up.
            </p>
          </div>
          <img alt="Payroll" src={payrollLogo} />
        </Panel>
      </Fragment>
    </div>
  );
}
