import EntityBase from 'data/EntityBase';
import { SendPayRates } from 'integrations/modules/wfn/consts';
import WorkforceNow from 'integrations/modules/wfn/models/workforceNow';

import { Map } from 'immutable';

export default class WorkforceNowState extends EntityBase({
  schedules: new Map(),
  schedulesLoaded: false,
  positions: new Map(),
  positionsLoaded: false,
  hourTypes: new Map(),
  hourTypesLoaded: false,
  employees: new Map(),
  employeesLoaded: false,
  children: new Map(),
  childrenLoaded: false,
  settings: new Map({
    [SendPayRates]: false,
    sendPayRatesOption: 0,
  }),
  settingsLoaded: false,
  integration: new WorkforceNow(),
  integrationLoaded: false,
  showDeletedMatchedUsers: false,
}) {}
