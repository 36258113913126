import type User from 'data/user/model';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type BulkUpdateUsersParams = {
  users: User[];
  options?: {
    invite?: boolean;
  };
};

export type BulkUpdateUsersResponse = {
  success: boolean;
  validRows: { row: number; user: User }[];
  invalidRows: { row: number; error: string; errorCode: string }[];
};

export const bulkUpdateUsers = createWiwAsyncThunk<BulkUpdateUsersResponse, BulkUpdateUsersParams>(
  'user/bulkUpdateUsers',
  async ({ users, options }, { rejectWithValue }) => {
    try {
      const postBody = users.map(user => ({ ...user.toJSON(), ...options }));
      return await Monolith.url('/users/bulkupdate').post({ users: postBody });
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
