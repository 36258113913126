import type { TagFields } from 'data/tag/Tag';
import type { QueryString } from 'data/types';
import Worktags from 'shared/api/worktags';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type FetchTagsArgs = QueryString;

type FetchTagsResponse = {
  data: TagFields[];
};

export const fetchTags = createWiwAsyncThunk<FetchTagsResponse, FetchTagsArgs>(
  'tags/fetch',
  async (query = {}, { rejectWithValue }) => {
    try {
      return (await Worktags.url('/tags').query(query).get()) as FetchTagsResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
