import { Suspense, useState } from 'react';
import { useForm } from 'react-hook-form';

import Loading from 'shared/components/Loading';
import Form from 'shared/form/Form';
import AccountStep from './components/AccountStep';

import wiwLogo from 'styles/assets/img/wiw-logo.svg';
import './styles/auto-import-onboarding-wrapper.scss';

export enum AutoImportOnboardingStep {
  ACCOUNT = 0,
  FILE_UPLOAD = 1,
  POSITIONS_REVIEW = 2,
  SHIFT_TEMPLATES_REVIEW = 3,
  USERS_REVIEW = 4,
  MOST_COMMON_SHIFT = 5,
}

interface AutoImportFormData {
  // Account step
  startOfWeek: number;
  industry: number | null;
  // File upload step
  uploadedFile?: File | null;
  // Positions step
  positions?: any[];
  // Shift Templates step
  shiftTemplates?: any[];
  // Users step
  users?: any[];
}

const AutoImportOnboarding = () => {
  const [step, setStep] = useState(AutoImportOnboardingStep.ACCOUNT);

  // positions, shiftTemplates, users will be stored similar to GuidedScheduleImportDialog state
  const methods = useForm<AutoImportFormData>({
    defaultValues: {
      startOfWeek: 0,
      industry: null,
      uploadedFile: null,
      positions: [],
      shiftTemplates: [],
      users: [],
    },
    mode: 'all',
  });

  const handleFormSubmit = (data: AutoImportFormData) => {
    console.log('Form Submitted:', data);
  };

  const goToStep = (step: AutoImportOnboardingStep) => {
    setStep(step);
  };

  // biome-ignore lint: shut up biome
  const skipImport = () => {
    setStep(AutoImportOnboardingStep.MOST_COMMON_SHIFT);
    // TODO: Implement skip logic to go to Most Common Shift Step
  };

  const renderStepContent = () => {
    switch (step) {
      case AutoImportOnboardingStep.ACCOUNT:
        return <AccountStep goToStep={goToStep} />;
      case AutoImportOnboardingStep.FILE_UPLOAD:
        return <div>File Upload Step Content (Form Part 2)</div>;
      case AutoImportOnboardingStep.POSITIONS_REVIEW:
        return <div>Positions Review Step Content (Form Part 3)</div>;
      case AutoImportOnboardingStep.SHIFT_TEMPLATES_REVIEW:
        return <div>Shift Templates Review Step Content (Form Part 4)</div>;
      case AutoImportOnboardingStep.USERS_REVIEW:
        return <div>Users Review Step Content (Form Part 5)</div>;
      case AutoImportOnboardingStep.MOST_COMMON_SHIFT:
        return <div>Most Common Shift Step Content (Skip step)</div>;
    }
  };

  return (
    <Suspense fallback={<Loading />}>
      <div className="auto-import-onboarding-wrapper">
        <header className="auto-import-onboarding-header">
          <div className="container container-fluid justify-content-center">
            <img src={wiwLogo} alt="When I Work" className="logo" />
          </div>
        </header>
        <Form form={methods} onSubmit={handleFormSubmit}>
          {renderStepContent()}
        </Form>
      </div>
    </Suspense>
  );
};

export default AutoImportOnboarding;
