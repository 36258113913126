import BaseModel from 'data/BaseModel';
import { NO_SCHEDULED_SHIFT } from 'data/punchState/constants';
import type { PunchStateSchema, ScheduledBreakSchema, ShiftBreakSchema } from 'data/punchState/schema';

import type { z } from 'zod';

export type PunchStateFields = z.infer<typeof PunchStateSchema>;

export type PunchStateScheduledBreaksFields = z.infer<typeof ScheduledBreakSchema>;

export type PunchStateShiftBreaksFields = z.infer<typeof ShiftBreakSchema>;

export default class PunchState extends BaseModel<PunchStateFields>({
  id: undefined,
  canClockIn: true,
  canClockOut: false,
  canStartBreak: false,
  canEndBreak: false,
  needsUnpaidBreakConfirmation: false,
  recordingUnpaidBreaksAllowed: false,
  recordingPaidBreaksAllowed: false,

  // we still need to define the possibly undefined fields because that's how Immutable.js Records work
  needsBreakConfirmation: undefined,
  punchStartTime: undefined,
  punchTimeId: undefined,
  schedules: undefined,
  positions: undefined,
  jobSites: undefined,
  shift: undefined,
  break: undefined,
  availableShifts: undefined,
  errorCode: undefined,
  scheduledBreaks: undefined,
  unscheduledBreaks: undefined,
}) {
  get isClockedIn() {
    return this.punchStartTime !== undefined;
  }

  get hasError() {
    // If the error code is "No Scheduled Shift" but we have available shifts, we don't want to consider it an error.
    // What circumstance would allow this? I don't know
    if (this.errorCode === NO_SCHEDULED_SHIFT && this.availableShifts?.length) {
      return false;
    }

    return !!this.errorCode;
  }

  get hasScheduledBreaks() {
    return !!this.scheduledBreaks && this.scheduledBreaks.length > 0;
  }
  get hasUnscheduledBreaks() {
    return !!this.unscheduledBreaks && this.unscheduledBreaks.length > 0;
  }

  get needsPaidBreakConfirmation() {
    return !!this.needsBreakConfirmation;
  }
}
