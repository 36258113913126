import { providerInstance as provider } from 'workchat/TwilioProvider';
import {
  WORKCHAT_CONFIRM_MODAL,
  WORKCHAT_CONNECTED,
  WORKCHAT_DISCONNECTED,
  WORKCHAT_MARK_UNREAD,
  WORKCHAT_TOGGLE,
} from 'workchat/action-types';

export * from 'workchat/actions/conversations';
export * from 'workchat/actions/error';
export * from 'workchat/actions/filters';
export * from 'workchat/actions/messages';
export * from 'workchat/actions/screens';
export * from 'workchat/actions/start';
export * from 'workchat/actions/tracking';
export * from 'workchat/actions/users';

export function hideWorkchat() {
  return {
    type: WORKCHAT_TOGGLE,
    show: false,
  };
}

export function connected() {
  return {
    type: WORKCHAT_CONNECTED,
  };
}

export function disconnected() {
  return {
    type: WORKCHAT_DISCONNECTED,
  };
}

export function markUnread(unread = 0) {
  return {
    type: WORKCHAT_MARK_UNREAD,
    unread,
  };
}

export function updateReadStatus() {
  return (dispatch, getState) => {
    const conversations = getState().workchat.get('conversations') || new Map();
    const unread = conversations.reduce((total, conversation) => {
      return total + provider.unread(conversation);
    }, 0);

    dispatch(markUnread(unread));
  };
}

// modal = { title: 'Modal Title', text: 'Text to be shown as the prompt', confirm: 'Confirm button text', onConfirm: function, onCancel: function }
export function modal(modal = false) {
  return {
    type: WORKCHAT_CONFIRM_MODAL,
    modal,
  };
}
