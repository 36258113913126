import type Account from 'data/account/model';
import type { AccountFields } from 'data/account/model';
import { fetchUsers } from 'data/user/actions/fetchAllUsers';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { setGlobalTimezone } from 'shared/util/time';
import { createWiwAsyncThunk } from 'store';

type UpdateAccountSettingsPayload = {
  data: Account;
  refreshUsers?: boolean;
};

export default createWiwAsyncThunk<AccountFields, UpdateAccountSettingsPayload>(
  'account/updateAccountSettings',
  async ({ data, refreshUsers }, { rejectWithValue, dispatch }) => {
    try {
      const serializedData = data?.toJSON?.() || data;
      const response = (await Monolith.url('/account/settings').post(serializedData)) as AccountFields;

      setGlobalTimezone(response.timezone_name);

      // Updating the users timezone when they use the account's timezone... There needs to be a better way to do this
      if (data.timezone_id !== response.timezone_id || refreshUsers) {
        dispatch(fetchUsers());
      }

      return response;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
