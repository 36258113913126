import { Component } from 'react';

import spinner from 'notifications/styles/assets/wiw-spinner-white';
import LottieAnimation from 'shared/ui/LottieAnimation';

import 'notifications/styles/Loading.scss';

export class Loading extends Component {
  render() {
    const lottieOptions = {
      loop: true,
      autoplay: true,
      animationData: spinner,
    };

    return (
      <div className="loading-notifications">
        <div className="loading-spinner">
          <LottieAnimation {...lottieOptions} />
        </div>
        <div className="loading-text placeholder">Loading...</div>
      </div>
    );
  }
}
