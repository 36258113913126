import type Account from 'data/account/model';
import type { EntityMap } from 'data/types';
import { mustGetAccountId } from 'shared/auth/selectors';
import type { WiwState } from 'store';

import { createSelector } from '@reduxjs/toolkit';

export const accountsSelector = (state: WiwState) => state.data.account;

export const getChildAccounts = createSelector(
  [accountsSelector, mustGetAccountId],
  (accounts, activeAccountId: number): EntityMap<Account> =>
    accounts.items.filter(account => {
      return account.master_id === activeAccountId;
    }),
);
