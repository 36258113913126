import { closeDialog, openDialog } from 'dialogs/actions';

export const DROP_SHIFT_DIALOG = 'DROP_SHIFT_DIALOG';
export const SWAP_SHIFT_DIALOG = 'SWAP_SHIFT_DIALOG';
export const SHIFT_CONFIRMATION_DIALOG = 'SHIFT_CONFIRMATION_DIALOG';
export const SHIFT_VIEW_DIALOG = 'SHIFT_VIEW_DIALOG';

export const openDropShiftDialog = openDialog.bind(null, DROP_SHIFT_DIALOG);
export const closeDropShiftDialog = closeDialog.bind(null, DROP_SHIFT_DIALOG);

export const openSwapShiftDialog = openDialog.bind(null, SWAP_SHIFT_DIALOG);
export const closeSwapShiftDialog = closeDialog.bind(null, SWAP_SHIFT_DIALOG);

export const openShiftConfirmationDialog = openDialog.bind(null, SHIFT_CONFIRMATION_DIALOG);
export const closeShiftConfirmationDialog = closeDialog.bind(null, SHIFT_CONFIRMATION_DIALOG);

export const openShiftViewDialog = openDialog.bind(null, SHIFT_VIEW_DIALOG);
export const closeShiftViewDialog = closeDialog.bind(null, SHIFT_VIEW_DIALOG);
