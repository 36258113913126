import { fetchPayPeriodById } from 'data/payPeriod/actions/fetchPayPeriods';
import { fetchPayPeriodData } from 'data/payPeriodData/actions/fetchPayPeriodData';
import type PayrollRecordFields from 'data/payrollCheck/models/payrollRecord';
import Payroll from 'shared/api/payroll';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';
import { setCurrentPayroll } from 'timesheets/actions';

export const fetchPayrollDetails = createWiwAsyncThunk<PayrollRecordFields, string>(
  'payrollCheck/fetchPayrollDetails',
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const details = await Payroll.get(`/payrolls/${id}/details`);

      // Id for fetchPayPeriodData must be the WIW pay period id'
      // off-cycle payrolls from check won't have a mapping to a WIW pay period
      if (!id.includes('pay_')) {
        await Promise.all([dispatch(fetchPayPeriodData(id)), dispatch(fetchPayPeriodById(id))]);

        //@ts-ignore
        dispatch(setCurrentPayroll(Number.parseInt(id, 10)));
      }
      return details;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
