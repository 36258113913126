import EntityState from 'data/EntityState';
import { DELETE_OPENSHIFT_USER_REQUEST, FETCH_OPENSHIFT_REQUESTS, RESET_SCHEDULER_STATE } from 'store/action-types';
import OpenShiftRequests from './model';

import { DateTime } from 'luxon';

export default function (state = new EntityState(), action) {
  switch (action.type) {
    case FETCH_OPENSHIFT_REQUESTS.FAILURE:
      return state.merge({
        loaded: false,
        loading: false,
        error: action.payload,
      });
    case FETCH_OPENSHIFT_REQUESTS.REQUEST:
      return state.merge({
        loaded: false,
        loading: true,
        error: null,
      });
    case FETCH_OPENSHIFT_REQUESTS.SUCCESS_PAGE:
      return state.merge({
        loaded: false,
        loading: true,
        error: null,
        receivedAt: Date.now(),
        items: state.items.merge(action.payload.map(osRequest => [osRequest.id, new OpenShiftRequests(osRequest)])),
      });
    case FETCH_OPENSHIFT_REQUESTS.SUCCESS:
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        receivedAt: Date.now(),
      });
    case DELETE_OPENSHIFT_USER_REQUEST.REQUEST:
      return state.merge({
        loaded: false,
        loading: true,
        error: null,
      });
    case DELETE_OPENSHIFT_USER_REQUEST.SUCCESS:
      return state.mergeDeep({
        items: state.items.map(osaRequest => {
          if (osaRequest.id === action.payload.id) {
            const request = osaRequest.set('status', OpenShiftRequests.Status.CANCELED);
            return request.set(
              'user_requests',
              request.user_requests.map(userRequest => {
                if (userRequest.user_id === action.payload.actorId) {
                  userRequest.deleted_at = DateTime.now().toISO();
                }
                return userRequest;
              }),
            );
          }
          return osaRequest;
        }),
        loaded: true,
        loading: false,
        error: null,
        receivedAt: Date.now(),
      });
    case DELETE_OPENSHIFT_USER_REQUEST.FAILURE:
      return state.merge({
        loaded: false,
        loading: false,
        error: action.payload,
      });
    case RESET_SCHEDULER_STATE:
      return new EntityState();
    default:
      return state;
  }
}
