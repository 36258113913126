import BaseModel from 'data/BaseModel';

export interface DocumentsFields {
  id: string | null;
  accountId: string | null;
  userId: string | null;
  filename: string | null;
  mimeType: string | null;
  fileSize: number | null;
  uploadedAt: string | null;
  deletedAt: string | null;
}

export class DocumentsModel extends BaseModel<DocumentsFields>({
  id: null,
  accountId: null,
  userId: null,
  filename: null,
  mimeType: null,
  fileSize: null,
  uploadedAt: null,
  deletedAt: null,
}) {}
