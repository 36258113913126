import { fetchScheduledTaskListsFailed } from 'data/tasks/actions/fetchScheduledTaskLists';
import { request } from 'shared/auth/request';
import { REPLACE_SHIFT_TASK_LISTS, UPDATE_SCHEDULED_TASK_LISTS } from 'store/action-types';

export function updateScheduledTaskListsStore(data) {
  return {
    type: UPDATE_SCHEDULED_TASK_LISTS,
    items: data,
  };
}

export function replaceShiftTaskListsStore(data, shiftId) {
  shiftId = `${shiftId}`;
  return {
    type: REPLACE_SHIFT_TASK_LISTS,
    shiftId,
    items: data,
  };
}

export const persistScheduledShiftTaskList = (shift, taskListId) => {
  return dispatch => {
    return request(CONFIG.TASKS_API)
      .post('/scheduled-task-lists', {
        shiftId: `${shift.id}`,
        locationId: `${shift.location_id}`,
        dueDate: shift.date('start_time').format(),
        taskListId,
      })
      .then(response => dispatch(updateScheduledTaskListsStore([response.data])))
      .catch(error => {
        dispatch(fetchScheduledTaskListsFailed(error));
        throw error;
      });
  };
};
