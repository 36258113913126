import type PayrollRecordFields from 'data/payrollCheck/models/payrollRecord';
import Payroll from 'shared/api/payroll';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

export const fetchPayroll = createWiwAsyncThunk<PayrollRecordFields, string>(
  'payrollCheck/fetchPayroll',
  async (id: string, { rejectWithValue }) => {
    try {
      return await Payroll.get(`/payrolls/${id}`);
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
