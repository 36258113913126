import { Fragment } from 'react';

import useIntl from 'shared/i18n/useIntl';

import { DateTime } from 'luxon';
import moment, { type Moment } from 'moment-timezone';

type Datelike = Moment | DateTime | Date;

function getDate(date: Datelike) {
  if (moment.isMoment(date)) {
    return date.toDate();
  }

  if (DateTime.isDateTime(date)) {
    return date.toJSDate();
  }

  return date;
}

type TimeProps = {
  /** will default to `now` if date is `null` */
  date: Datelike | null;
  long?: boolean;
  medium?: boolean;
  displayWeekDay?: boolean;
};

export default function Time({ date, long = false, medium = false, displayWeekDay = false }: TimeProps) {
  const { formatTime, formatTimeLong, formatTimeMedium } = useIntl();

  function toString(date: Datelike, long: boolean, medium: boolean, displayWeekDay: boolean): string {
    if (long) {
      return formatTimeLong(getDate(date));
    }

    if (medium) {
      return formatTimeMedium(getDate(date));
    }

    if (displayWeekDay) {
      if (DateTime.isDateTime(date)) {
        return date.toFormat('ccc');
      }

      if (moment.isMoment(date)) {
        return date.format('ddd');
      }

      return DateTime.fromJSDate(date).toFormat('ccc');
    }

    return formatTime(getDate(date));
  }

  return <Fragment>{toString(date ?? new Date(), long, medium, displayWeekDay)}</Fragment>;
}
