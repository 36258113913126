import wretchAdapter from 'shared/api/adapters/wretch';
import defaultRequestConfig from 'shared/api/adapters/wretch/defaultRequestConfig';

const getWorkchatRequestConfig = () => {
  // For whatever odd reason, WC API doesn't like it when we send these headers
  const requestConfig = defaultRequestConfig();
  delete requestConfig.headers['W-Date-Format'];

  if (requestConfig.headers.Authorization) {
    delete requestConfig.headers.Authorization;
  }

  return requestConfig;
};

export default wretchAdapter(CONFIG.WORKCHAT_ROOT, getWorkchatRequestConfig());
