import Payroll from 'data/payroll/model';

import { List, Record } from 'immutable';

class QuickBooksCheck extends Record({
  ready: false,
  unmatched: {
    users: List(), // List of Users
  },
  payroll: new Payroll(),
}) {}

export default QuickBooksCheck;
