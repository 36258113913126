import EntityState from 'data/EntityState';
import { fetchPayPeriodData } from 'data/payPeriodData/actions/fetchPayPeriodData';
import PayPeriodData from 'data/payPeriodData/model';
import { mergeEntityItems } from 'data/util/sliceHelpers';

import { createSlice } from '@reduxjs/toolkit';

const initialState = new EntityState();

const payPeriodDataSlice = createSlice({
  name: 'payPeriodData',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchPayPeriodData.fulfilled, (state, action) => {
      return mergeEntityItems(state, action.payload.data, PayPeriodData, 'user_id');
    });
  },
});

export default payPeriodDataSlice.reducer;
