import { UPDATE_ROLLUP_SHIFT_CONCERNS } from 'store/action-types';

function dispatchUpdateRollupShiftConcerns(userId, shiftId, concern, payload) {
  return {
    type: UPDATE_ROLLUP_SHIFT_CONCERNS,
    userId,
    shiftId,
    concern,
    payload,
  };
}

export function updateRollupShiftConcerns(userId, shiftId, concern, payload) {
  return dispatch => dispatch(dispatchUpdateRollupShiftConcerns(userId, shiftId, concern, payload));
}
