import type PayrollRecordSummaryFields from 'data/payrollCheck/models/payrollRecord';
import Payroll from 'shared/api/payroll';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type FetchPayrollSummaryResponse = {
  items: PayrollRecordSummaryFields[];
};

type FetchPayrollSummaryArgs = {
  state?: string;
};

export const fetchPayrollsSummaries = createWiwAsyncThunk<FetchPayrollSummaryResponse, FetchPayrollSummaryArgs>(
  'payrollCheck/fetchSummaries',
  async (args, { rejectWithValue }) => {
    try {
      return await Payroll.query(args).get('/payrolls');
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
