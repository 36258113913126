import LoadImage, { type Orientation, type MetaData } from 'blueimp-load-image';

import { type ChangeEvent, Fragment, type KeyboardEvent, useRef, useState } from 'react';
import Form, { Textarea, useForm } from 'shared/form';
import FontIcon from 'shared/ui/FontIcon';
import TwilioProvider from 'workchat/TwilioProvider';
import type { ImageInput } from 'workchat/types/ImageInput';

import 'workchat/styles/MessageInput.scss';

export type MessageInputProps = {
  sendMessage: (message: string, image: any) => void;
  sending: boolean;
  canAttach?: boolean;
  disabled?: boolean;
  setError: (error: string) => void;
};

const VALID_TYPES: { [key: string]: boolean } = {
  'image/png': true,
  'image/jpeg': true,
  'image/gif': true,
};

export default function MessageInput({
  sendMessage,
  sending,
  canAttach = true,
  disabled = false,
  setError,
}: MessageInputProps) {
  const form = useForm();
  const [message, setMessage] = useState('');
  const [image, setImage] = useState<ImageInput | null>(null);
  const inputFileUpload = useRef<HTMLInputElement>(null);

  const send = () => {
    sendMessage(message, image);
    setMessage('');
    setImage(null);
  };

  const clearImage = () => {
    setImage(null);
  };

  const handleClick = () => {
    inputFileUpload?.current?.click();
  };

  const onTyping = (_: string, value: string) => {
    setMessage(value);
  };

  const onKey = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (!disabled) {
        send();
      }
    }
  };

  const onAttach = (e: ChangeEvent<HTMLInputElement>) => {
    const input = e.target;
    if (!input.files || !input.files.length) {
      setImage(null);
      return;
    }

    const file = input.files[0];
    const image: ImageInput = {
      file,
      type: file.type,
      size: file.size,
      buffer: null,
    };

    if (!VALID_TYPES[image.type]) {
      input.value = '';
      setImage(null);
      setError('Only PNG, JPG, and GIF images are allowed.');
      return;
    }

    LoadImage.parseMetaData(
      file,
      (data: MetaData) => {
        // @ts-ignore
        const orientation = data.exif ? data.exif.get('Orientation') : 1;

        LoadImage(
          file,
          (canvas: Event | HTMLCanvasElement | HTMLImageElement) => {
            // @ts-ignore
            image.data = canvas.toDataURL();

            const img = new Image();
            img.onload = () => {
              image.element = img;
            };
            img.src = image.data as string;

            const reader = new FileReader();
            reader.onload = () => {
              image.buffer = reader.result;
              setImage(image);
            };
            reader.readAsArrayBuffer(file);
          },
          {
            maxWidth: TwilioProvider.MAX_IMAGE_SIZE,
            maxHeight: TwilioProvider.MAX_IMAGE_SIZE,
            crossOrigin: 'true',
            orientation: orientation as Orientation,
            canvas: true,
          },
        );
      },
      {
        disableImageHead: true,
      },
    );
  };

  return (
    <Form form={form} onSubmit={send} className="message-input">
      <div className="input-row">
        {image && (
          <div className="image-preview" style={{ backgroundImage: `url(${image.data})` }}>
            <button type="button" onClick={clearImage}>
              <i className="fa fa-times" />
            </button>
          </div>
        )}
        <Textarea
          name="message"
          minRows={1}
          maxRows={4}
          autosize
          onChange={onTyping}
          onKeyPress={onKey}
          value={message}
          placeholder="Type a message..."
          className="message-textarea"
          classNames={{
            input: 'message-textarea-input',
          }}
          disabled={sending}
        />
      </div>
      <div className="input-row">
        {canAttach && (
          <Fragment>
            <input
              type="file"
              id="workchat-file-input"
              ref={inputFileUpload}
              onChange={onAttach}
              disabled={disabled || sending}
            />
            <button type="button" className="btn-add-photo" disabled={disabled || sending} onClick={handleClick}>
              <FontIcon icon="camera" />
              Add Photo
            </button>
          </Fragment>
        )}
        <button type="submit" className="send" disabled={sending || (!message && !image) || disabled}>
          Send
        </button>
      </div>
    </Form>
  );
}
