import BaseModel from 'data/BaseModel';
import StripeCoupon from 'data/stripe/coupons/model';
import type { StripePromotionCode as StripePromotionCodeI } from 'data/stripe/types/promotion_code';

export interface StripePromoCodeFields extends Partial<Omit<StripePromotionCodeI, 'coupon'>> {
  coupon: StripeCoupon;
}

export default class StripePromoCode extends BaseModel<StripePromoCodeFields>({
  active: false,
  code: '',
  coupon: new StripeCoupon(),
  id: '',
  object: 'promotion_code',
}) {
  constructor(args: Partial<StripePromoCodeFields>) {
    super({ ...args, coupon: new StripeCoupon(args.coupon!) });
  }
}
