export const experimentAssignments = [
  //  To track an experiment assignment (the user is in the audience in control or treatment), add an object in this format
  // {
  //   jira: 'JIRA-1234', // the ID of the experiment in experiment::assignments
  //   ldFlag: 'jira-1234-ld-flag-string-here', // the LD flag string to use
  //   eventNames: [], // other miscellaneous strings to track (leave empty if none), see experiment::assignments table for details
  // },
  {
    jira: 'CAT-2440',
    ldFlag: 'cat-2440-ai-import-web',
    eventNames: [],
  },
];
