import type { Conversation } from '@twilio/conversations';
import { type ChangeEvent, useEffect, useState } from 'react';

import { CONFIRMATION_DIALOG, openDialog } from 'dialogs';
import { getAuthUser } from 'shared/auth/selectors';
import FontIcon from 'shared/ui/FontIcon';
import { useWiwDispatch, useWiwSelector } from 'store';
import { VIEW_ADD_PARTICIPANTS } from 'workchat/v2/components/AddParticipantsV2';
import BigParticipantListV2 from 'workchat/v2/components/BigParticipantListV2';
import HeaderV2, { type HeaderButton } from 'workchat/v2/components/HeaderV2';
import { leaveConversation, renameConversation } from 'workchat/v2/store/conversations/conversationsActions';
import { getConversationPermissionsForConversationId } from 'workchat/v2/store/conversations/conversationsSelectors';
import { getUserParticipantsForConversationId } from 'workchat/v2/store/participants/participantsSelectors';
import { viewStackPop, viewStackPush, viewStackReset } from 'workchat/v2/store/reducer';
import { getWorkchatConnected, getWorkchatSelectedConversation } from 'workchat/v2/store/selectors';

import 'workchat/styles/ChannelDetail.scss';

export const VIEW_CONVERSATION_DETAIL = 'VIEW_CONVERSATION_DETAIL';

export default function ConversationDetailV2() {
  const dispatch = useWiwDispatch();
  const selfUser = useWiwSelector(getAuthUser);
  const connected = useWiwSelector(getWorkchatConnected);
  const conversation = useWiwSelector(getWorkchatSelectedConversation);
  const usersParticipants = useWiwSelector(state => getUserParticipantsForConversationId(state, conversation?.sid));
  const permissions = useWiwSelector(state => getConversationPermissionsForConversationId(state, conversation?.sid));

  const [title, setTitle] = useState('');
  const [titleChanged, setTitleChanged] = useState(false);
  const [titleFocused, setTitleFocused] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (conversation?.friendlyName !== title && !titleChanged) {
      setTitle(conversation?.friendlyName || '');
    }
  }, [conversation, titleChanged]);

  const back = () => {
    dispatch(viewStackPop());
  };

  const changeTitle = (e: ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
    setTitleChanged(true);
  };

  const focusTitle = () => {
    setTitleFocused(true);
  };

  const resetTitle = () => {
    setTitle(conversation?.friendlyName || '');
    setTitleChanged(false);
    setTitleFocused(false);
  };

  const saveTitle = () => {
    if (!titleChanged || !conversation) {
      setTitleFocused(false);
      return;
    }

    setSaving(true);

    dispatch(renameConversation({ conversationId: conversation?.sid, friendlyName: title }))
      .unwrap()
      .then((conversation: Conversation) => {
        setSaving(false);
        setTitle(conversation.friendlyName || '');
        setTitleChanged(false);
        setTitleFocused(false);
      });
  };

  const addCoworkers = () => {
    dispatch(viewStackPush(VIEW_ADD_PARTICIPANTS));
  };

  const doLeave = () => {
    if (conversation) {
      dispatch(leaveConversation(conversation?.sid))
        .unwrap()
        .then(() => dispatch(viewStackReset()));
    }
  };

  const leave = () => {
    return dispatch(
      openDialog(CONFIRMATION_DIALOG, {
        title: 'Leave Conversation',
        body: <p>Are you sure you want to leave the conversation?</p>,
        confirmClass: 'btn-danger',
        confirmLabel: 'Leave',
        onConfirm: doLeave,
        width: 350,
      }),
    );
  };

  const getTitle = () => {
    if (title) {
      return title;
    }

    return 'Name this channel';
  };

  const defaultTitle = getTitle();
  const filteredParticipants = usersParticipants?.filter(participants => participants.id !== selfUser.id);

  const buttons: HeaderButton[] = [
    {
      title: 'Back',
      action: back,
      label: [<FontIcon className="mr-1" key="back" icon="chevron-left" />, 'Back'],
      side: 'left',
      color: 'secondary',
    },
  ];

  const canEdit = permissions?.includes('editConversationName');
  const canSave = titleChanged || titleFocused;
  const canAdd = permissions?.includes('addParticipant');
  const canLeave = permissions?.includes('leaveConversation');

  return (
    <div>
      <HeaderV2 showBroadcast={false} buttons={buttons}>
        Details
      </HeaderV2>
      <div className="channel-details">
        {canEdit ? (
          <div className="name-container">
            <label htmlFor="channelTitle" className="sr-only">
              Channel name
            </label>
            <input
              type="text"
              name="channelTitle"
              className={canSave ? 'changed' : ''}
              onChange={changeTitle}
              value={title}
              onFocus={focusTitle}
              placeholder={defaultTitle}
            />
            {canSave ? (
              <div className="actions">
                <button type="button" className="cancel" onClick={resetTitle}>
                  Cancel
                </button>
                <button type="button" className="save" disabled={saving || !connected} onClick={saveTitle}>
                  Save
                </button>
              </div>
            ) : null}
          </div>
        ) : null}
        <div className="channel-members">
          <h3>Channel Members</h3>
          {canAdd ? (
            <button type="button" className="add" onClick={addCoworkers} disabled={saving || !connected}>
              Add
            </button>
          ) : null}
        </div>
        <BigParticipantListV2 users={filteredParticipants} />
        {canLeave ? (
          <div className="leave-channel">
            <button type="button" className="leave" onClick={leave} disabled={saving || !connected}>
              Leave Channel
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
}
