import type ShiftTemplate from 'data/shiftTemplate/model';
import type { ShiftTemplateFields } from 'data/shiftTemplate/model';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type UpdateShiftTemplateArgs = ShiftTemplate;

type UpdateShiftTemplateResponse = {
  block: ShiftTemplateFields;
};

export const updateShiftTemplate = createWiwAsyncThunk<UpdateShiftTemplateResponse, UpdateShiftTemplateArgs>(
  'shiftTemplate/update',
  async (shiftTemplate, { rejectWithValue }) => {
    try {
      return (await Monolith.url(`/blocks/${shiftTemplate.id}`).put(
        shiftTemplate.toJSON(),
      )) as UpdateShiftTemplateResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
