import type { ReactNode } from 'react';

import {
  EMPLOYEE_ALERT_PREFERENCES_COMPLETED,
  EMPLOYEE_AVAILABILITY_COMPLETED,
  EMPLOYEE_ONBOARDING_STATUS_ALERT_PREFERENCES,
  EMPLOYEE_ONBOARDING_STATUS_AVAILABILITY,
  EMPLOYEE_ONBOARDING_STATUS_IDLE,
  EMPLOYEE_ONBOARDING_STATUS_TIME_OFF,
  EMPLOYEE_TIME_OFF_COMPLETED,
  SCHEDULER_FOCUSED,
  SCHEDULER_FOCUSED_PULSE,
  SCHEDULER_FOCUSED_SHIFT_CONFIRMATION,
  SCHEDULER_FOCUSED_SIDEBAR_REENTRY,
  SCHEDULER_FOCUSED_TRUSTED_TEAMMATE,
  SCHEDULER_FOCUSED_VIEW,
  SCHEDULER_GUIDE,
  SCHEDULER_GUIDE_ADD_USER,
  SCHEDULER_GUIDE_CREATE_SHIFT,
  SCHEDULER_GUIDE_DRAG_COPY,
  SCHEDULER_GUIDE_PUBLISH,
  SCHEDULER_GUIDE_PUBLISH_CELEBRATE,
  SCHEDULER_GUIDE_PUBLISH_PREVIEW,
  SCHEDULER_GUIDE_UNPUBLISHED_SHIFT,
  SCHEDULER_GUIDE_WELCOME,
} from 'data/metadata/keys';
import { INVITE_TRUSTED_USER_DIALOG, SCHEDULER_GUIDE_WELCOME_DIALOG } from 'onboarding/dialogs';
import { SHIFT_CONFIRMATION_DIALOG } from 'shared/modal/actions';

export const STEP_ATTENDANCE_ADD_EMPLOYEES = 'addEmployee';
export const STEP_ATTENDANCE_EDIT_TIMESHEET = 'editTimesheet';
export const STEP_ATTENDANCE_EXPORT_TIMESHEET = 'exportTimesheet';

export const STEP_SCHEDULING_ADD_EMPLOYEES = 'addEmployee';
export const STEP_SCHEDULING_CREATE_SHIFT = 'createShift';
export const STEP_SCHEDULING_PUBLISH_SCHEDULE = 'publishSchedule';

export const STEP_PRELOADED_CREATE_SHIFT = 'createShift';
export const STEP_PRELOADED_REASSIGN_SHIFT = 'reassignShift';
export const STEP_PRELOADED_PUBLISH_SCHEDULE = 'publishSchedule';

export const ATTENDANCE_CHECKLIST_STEPS: AttendanceChecklistSteps = {
  [STEP_ATTENDANCE_ADD_EMPLOYEES]: {
    title: 'Add users',
    description: 'Use the add button to add your users.',
  },
  [STEP_ATTENDANCE_EDIT_TIMESHEET]: {
    title: 'Edit timesheet',
    description: 'Add clock in/out times to the timesheet.',
  },
  [STEP_ATTENDANCE_EXPORT_TIMESHEET]: {
    title: 'Export timesheets',
    description: 'Export an overview of hours worked.',
  },
};

export const SCHEDULING_CHECKLIST_STEPS: SchedulingChecklistSteps = {
  [STEP_SCHEDULING_ADD_EMPLOYEES]: {
    title: 'Add a user',
    description: 'Use the add button at the bottom of the schedule to add your users.',
  },
  [STEP_SCHEDULING_CREATE_SHIFT]: {
    title: 'Create a shift',
    description: 'Click in any cell on the schedule to create a new shift.',
  },
  [STEP_SCHEDULING_PUBLISH_SCHEDULE]: {
    title: 'Publish your schedule',
    description:
      "When your schedule's ready, publish it so users can see it. Once published, shifts will be a solid color.",
  },
};

export const PRELOADED_CHECKLIST_STEPS: PreloadedChecklistSteps = {
  [STEP_PRELOADED_CREATE_SHIFT]: {
    title: 'Create a shift',
    description:
      'Click in any cell on the schedule to create a new shift. Any shifts with the striped pattern are unpublished, which means that they can’t be seen by anyone with the “Employee” role.',
  },
  [STEP_PRELOADED_REASSIGN_SHIFT]: {
    title: 'Reassign a shift',
    description:
      'Click and drag a shift to reassign it to someone else. Look for the thumbs-up or thumbs-down to show you if that user is eligible to take the shift. Try dragging the OpenShift on Saturday to a user below.',
  },
  [STEP_PRELOADED_PUBLISH_SCHEDULE]: {
    title: 'Publish the schedule',
    description:
      'Shifts with the striped pattern are unpublished, so employees can’t see them. Click the “Publish and Notify” button to publish these shifts and make them visible to your team.',
  },
};

export type SchedulingChecklistSteps = {
  [STEP_SCHEDULING_ADD_EMPLOYEES]: {
    title: string;
    description: string;
  };
  [STEP_SCHEDULING_CREATE_SHIFT]: {
    title: string;
    description: string;
  };
  [STEP_SCHEDULING_PUBLISH_SCHEDULE]: {
    title: string;
    description: string;
  };
};
export type AttendanceChecklistSteps = {
  [STEP_ATTENDANCE_ADD_EMPLOYEES]: {
    title: string;
    description: string;
  };
  [STEP_ATTENDANCE_EDIT_TIMESHEET]: {
    title: string;
    description: string;
  };
  [STEP_ATTENDANCE_EXPORT_TIMESHEET]: {
    title: string;
    description: string;
  };
};
export type PreloadedChecklistSteps = {
  [STEP_PRELOADED_CREATE_SHIFT]: {
    title: string;
    description: string;
  };
  [STEP_PRELOADED_REASSIGN_SHIFT]: {
    title: string;
    description: string | ReactNode;
  };
  [STEP_PRELOADED_PUBLISH_SCHEDULE]: {
    title: string;
    description: string;
  };
};

export enum ListType {
  Scheduler = 'scheduler',
  Attendance = 'attendance',
  PreloadData = 'preload-data',
}

export enum SchedulerOnboardingItems {
  // biome-ignore lint/style/useLiteralEnumMembers: this is defined in app/data/metadata/keys.tsx
  Publish = SCHEDULER_GUIDE_PUBLISH,
  // biome-ignore lint/style/useLiteralEnumMembers: this is defined in app/data/metadata/keys.tsx
  PublishCelebrate = SCHEDULER_GUIDE_PUBLISH_CELEBRATE,
  // biome-ignore lint/style/useLiteralEnumMembers: this is defined in app/data/metadata/keys.tsx
  Welcome = SCHEDULER_GUIDE_WELCOME,
  // biome-ignore lint/style/useLiteralEnumMembers: this is defined in app/data/metadata/keys.tsx
  CreateShift = SCHEDULER_GUIDE_CREATE_SHIFT,
  // biome-ignore lint/style/useLiteralEnumMembers: this is defined in app/data/metadata/keys.tsx
  AddUser = SCHEDULER_GUIDE_ADD_USER,
  // biome-ignore lint/style/useLiteralEnumMembers: this is defined in app/data/metadata/keys.tsx
  DragCopy = SCHEDULER_GUIDE_DRAG_COPY,
  // biome-ignore lint/style/useLiteralEnumMembers: this is defined in app/data/metadata/keys.tsx
  UnpublishedShift = SCHEDULER_GUIDE_UNPUBLISHED_SHIFT,
  // biome-ignore lint/style/useLiteralEnumMembers: this is defined in app/data/metadata/keys.tsx
  PublishPreview = SCHEDULER_GUIDE_PUBLISH_PREVIEW,
}

export enum FocusedOnboardingItems {
  // biome-ignore lint/style/useLiteralEnumMembers: this is defined in app/data/metadata/keys.tsx
  FocusedView = SCHEDULER_FOCUSED_VIEW,
  // biome-ignore lint/style/useLiteralEnumMembers: this is defined in app/data/metadata/keys.tsx
  ShiftConfirmation = SCHEDULER_FOCUSED_SHIFT_CONFIRMATION,
  // biome-ignore lint/style/useLiteralEnumMembers: this is defined in app/data/metadata/keys.tsx
  ShiftConfirmationPulse = SCHEDULER_FOCUSED_PULSE,
  // biome-ignore lint/style/useLiteralEnumMembers: this is defined in app/data/metadata/keys.tsx
  TrustedTeammate = SCHEDULER_FOCUSED_TRUSTED_TEAMMATE,
  // biome-ignore lint/style/useLiteralEnumMembers: this is defined in app/data/metadata/keys.tsx
  SidebarReentry = SCHEDULER_FOCUSED_SIDEBAR_REENTRY,
}

export enum GuideTypes {
  // biome-ignore lint/style/useLiteralEnumMembers: this is defined in app/data/metadata/keys.tsx
  Scheduler = SCHEDULER_GUIDE,
  // biome-ignore lint/style/useLiteralEnumMembers: this is defined in app/data/metadata/keys.tsx
  FocusedOnboarding = SCHEDULER_FOCUSED,
}

export type OnboardingGuide = {
  completed: Array<SchedulerOnboardingItems | FocusedOnboardingItems>;
};

export const SchedulerGuidePath = [
  SchedulerOnboardingItems.Welcome,
  SchedulerOnboardingItems.AddUser,
  SchedulerOnboardingItems.CreateShift,
  SchedulerOnboardingItems.UnpublishedShift,
  SchedulerOnboardingItems.Publish,
  SchedulerOnboardingItems.PublishCelebrate,
  SchedulerOnboardingItems.DragCopy,
];

export const FocusedOnboardingGuidePath = [
  FocusedOnboardingItems.FocusedView,
  FocusedOnboardingItems.ShiftConfirmation,
  FocusedOnboardingItems.ShiftConfirmationPulse,
  FocusedOnboardingItems.SidebarReentry,
];

export const GifGuidedDialogs = {
  [`${SchedulerOnboardingItems.Welcome as string}`]: SCHEDULER_GUIDE_WELCOME_DIALOG,
};

export const FocusedOnboardingDialogs = {
  [`${FocusedOnboardingItems.ShiftConfirmation as string}`]: SHIFT_CONFIRMATION_DIALOG,
  [`${FocusedOnboardingItems.TrustedTeammate as string}`]: INVITE_TRUSTED_USER_DIALOG,
};

export enum EmployeeOnboardingItems {
  // biome-ignore lint/style/useLiteralEnumMembers: this is defined in app/data/metadata/keys.tsx
  AvailabilityCompleted = EMPLOYEE_AVAILABILITY_COMPLETED,
  // biome-ignore lint/style/useLiteralEnumMembers: this is defined in app/data/metadata/keys.tsx
  AlertPreferencesCompleted = EMPLOYEE_ALERT_PREFERENCES_COMPLETED,
  // biome-ignore lint/style/useLiteralEnumMembers: this is defined in app/data/metadata/keys.tsx
  TimeOffCompleted = EMPLOYEE_TIME_OFF_COMPLETED,
}

export type EmployeeOnboardingSteps = {
  [key in EmployeeOnboardingItems]: boolean;
};

export enum EmployeeOnboardingStatus {
  // biome-ignore lint/style/useLiteralEnumMembers: this is defined in app/data/metadata/keys.tsx
  Idle = EMPLOYEE_ONBOARDING_STATUS_IDLE,
  // biome-ignore lint/style/useLiteralEnumMembers: this is defined in app/data/metadata/keys.tsx
  Availability = EMPLOYEE_ONBOARDING_STATUS_AVAILABILITY,
  // biome-ignore lint/style/useLiteralEnumMembers: this is defined in app/data/metadata/keys.tsx
  AlertPreferences = EMPLOYEE_ONBOARDING_STATUS_ALERT_PREFERENCES,
  // biome-ignore lint/style/useLiteralEnumMembers: this is defined in app/data/metadata/keys.tsx
  TimeOff = EMPLOYEE_ONBOARDING_STATUS_TIME_OFF,
}
