import { Fragment } from 'react';

import { useLDFlag } from 'data/LD/selectors/getLDFlag';
import { mustGetAccountPlan } from 'data/account/selectors/accountPlan';
import { getAuthAccount, getAuthUser } from 'shared/auth/selectors';
import { MenuItem } from 'shared/components/nav/MenuItem';
import MenuItems from 'shared/components/nav/MenuItems';
import ClockInMenuItem from 'shared/components/nav/menu-items/ClockInMenuItem';
import { useWiwSelector } from 'store';

export default function AttendanceMenuItem() {
  const authAccount = useWiwSelector(getAuthAccount);
  const authUser = useWiwSelector(getAuthUser);
  const isSetup = authAccount.getSettings('payroll.is_onboarded', false) && authAccount.hasAttendance();
  const accountPlan = useWiwSelector(mustGetAccountPlan);
  const pay903PayingContractorsPayStubs = useLDFlag('pay-903-paying-contractors-pay-stubs');

  const isTrial = accountPlan.isTrial() || !authAccount.hasAttendance() || authAccount.isReverseTrial();
  const isPayStubsVisible =
    authAccount.getSettings('payroll_check.is_onboarded', false) &&
    authUser.isEmployee() &&
    (!authUser.isContractor() || pay903PayingContractorsPayStubs) &&
    !authUser.exclude_from_payrolls;

  // Only Admin and Managers can see nav item until completed
  if (!isSetup && (authUser.isEmployee() || authUser.isSupervisor())) {
    return null;
  }

  // If on a trial and setup is not complete, send to splash page
  if (isTrial && !isSetup) {
    return (
      <MenuItem
        className="upsell"
        icon="attendance"
        label="Attendance"
        hideLabel="tablet"
        show={true}
        to="/attendance/get-started"
      />
    );
  }

  // If setup is not complete, send to the onboarding page
  if (!isSetup) {
    return (
      <MenuItem
        className="upsell"
        icon="attendance"
        label="Attendance"
        hideLabel="tablet"
        show={true}
        to="/attendance/setup"
      />
    );
  }

  return (
    <Fragment>
      <MenuItem
        icon="attendance"
        isMainNav
        label={isPayStubsVisible ? 'Hours & Pay' : 'Attendance'}
        hideLabel="tablet"
        className="dropdown-starts-left"
        dropdown
      >
        <MenuItems dropdownMenu>
          {isPayStubsVisible && <ClockInMenuItem key="clock-in-menu" />}
          <MenuItem icon="timesheet" key="timesheets" label="Timesheets" show to="/timesheets" />

          {isPayStubsVisible && <MenuItem icon="payroll" label="Paystubs" to="/paystubs" />}

          {authUser.canSupervise() && (
            <MenuItem icon="tracker" key="timetracker" label="Time Tracker" show to="/attendance" />
          )}

          {!isPayStubsVisible && <ClockInMenuItem key="clock-in-menu" />}

          {authUser.canSupervise() && <MenuItem icon="time-clock" label="Lock as Terminal" show to="/timeclock" />}
        </MenuItems>
      </MenuItem>
    </Fragment>
  );
}
