import { READ_RECEIPT } from 'data/metadata/keys';
import { getUserMetadata } from 'data/metadata/selectors/selectors';
import { syncFiltersFromMetadata } from 'scheduler/util/syncMetadataFilters';
import { request } from 'shared/auth/request';
import {
  FETCH_ACCOUNT_METADATA,
  FETCH_USER_METADATA,
  UPDATE_ACCOUNT_METADATA,
  UPDATE_USER_METADATA,
} from 'store/action-types';

import { Map } from 'immutable';

let lastAccountMetadataFetch;
let lastUserMetadataFetch;
const genericMetadataError = 'Metadata service unavailable';

function getLastAccountMetadataFetch() {
  return lastAccountMetadataFetch
    ? lastAccountMetadataFetch
    : Promise.reject(new Error('Data never fetched, call fetchAccountMetadataIfNeeded first.'));
}

function getLastUserMetadataFetch() {
  return lastUserMetadataFetch
    ? lastUserMetadataFetch
    : Promise.reject(new Error('Data never fetched, call fetchUserMetadataIfNeeded first.'));
}

// Helper for tests to fake that metadata were fetched
export function overridePromises(value) {
  if (value != null) {
    lastAccountMetadataFetch = value;
    lastUserMetadataFetch = value;
  }
}

function fetchAccountMetadataRequested() {
  return {
    type: FETCH_ACCOUNT_METADATA.REQUEST,
  };
}

function fetchAccountMetadataSuccess(payload) {
  return {
    type: FETCH_ACCOUNT_METADATA.SUCCESS,
    payload,
  };
}

function fetchAccountMetadataFailed(error) {
  return {
    type: FETCH_ACCOUNT_METADATA.FAILURE,
    payload: error || genericMetadataError,
  };
}

function updateAccountMetadataStore(payload) {
  return {
    type: UPDATE_ACCOUNT_METADATA,
    payload,
  };
}

function fetchUserMetadataRequested() {
  return {
    type: FETCH_USER_METADATA.REQUEST,
  };
}

function fetchUserMetadataSuccess(payload) {
  return {
    type: FETCH_USER_METADATA.SUCCESS,
    payload,
  };
}

let failureCount = 0;
function fetchUserMetadataFailed(error) {
  failureCount += 1;
  return {
    type: FETCH_USER_METADATA.FAILURE,
    payload: error || genericMetadataError,
  };
}

function updateUserMetadataStore(payload) {
  return {
    type: UPDATE_USER_METADATA.SUCCESS,
    payload,
  };
}

function updateUserMetadataRequest() {
  return {
    type: UPDATE_USER_METADATA.REQUEST,
  };
}

export function fetchAccountMetadata() {
  return dispatch => {
    dispatch(fetchAccountMetadataRequested());
    lastAccountMetadataFetch = request(CONFIG.PLATFORM_SERVICE)
      .get('/meta/account')
      .then(response => dispatch(fetchAccountMetadataSuccess(response.data)))
      .catch(error => {
        dispatch(fetchAccountMetadataFailed(error.body));
      });
    return getLastAccountMetadataFetch();
  };
}

function shouldFetchAccountMetadata(state) {
  return !state.data.metadata.get('accountLoading') && !state.data.metadata.get('accountLoaded');
}

export function fetchAccountMetadataIfNeeded() {
  return (dispatch, getState) => {
    const state = getState();

    if (shouldFetchAccountMetadata(state)) {
      return dispatch(fetchAccountMetadata());
    }

    return getLastAccountMetadataFetch();
  };
}

export function updateAccountMetadata(metadata) {
  return dispatch => {
    return dispatch(fetchAccountMetadataIfNeeded()) // make sure we've fetched successfully so we don't clobber
      .then(() =>
        request(CONFIG.PLATFORM_SERVICE).put(
          '/meta/account',
          new Map(metadata).map(value => JSON.stringify(value)).toJSON(),
          {
            headers: { 'Content-Type': 'application/json' },
          },
        ),
      )
      .then(response => dispatch(updateAccountMetadataStore(response.data)))
      .catch(error => {
        dispatch(fetchAccountMetadataFailed(error.body));
      });
  };
}

export function fetchUserMetadata() {
  return dispatch => {
    dispatch(fetchUserMetadataRequested());
    lastUserMetadataFetch = request(CONFIG.PLATFORM_SERVICE)
      .get('/meta/user')
      .then(response => {
        dispatch(syncFiltersFromMetadata(response.data));
        dispatch(fetchUserMetadataSuccess(response.data));
      })
      .catch(error => {
        dispatch(fetchUserMetadataFailed(error.body));
      });

    return getLastUserMetadataFetch();
  };
}

function shouldFetchUserMetadata(state) {
  return !state.data.metadata.get('userLoading') && !state.data.metadata.get('userLoaded');
}

export function fetchUserMetadataIfNeeded() {
  return (dispatch, getState) => {
    const state = getState();

    if (shouldFetchUserMetadata(state)) {
      return dispatch(fetchUserMetadata());
    }

    return getLastUserMetadataFetch();
  };
}

export function updateUserMetadata(metadata) {
  return dispatch => {
    if (failureCount >= 3) {
      return dispatch(fetchUserMetadataFailed('maximum retries reached'));
    }
    return dispatch(fetchUserMetadataIfNeeded()) // make sure we've fetched successfully so we don't clobber
      .then(() => {
        dispatch(updateUserMetadataRequest());
        return request(CONFIG.PLATFORM_SERVICE).put(
          '/meta/user',
          new Map(metadata).map(value => JSON.stringify(value)).toJSON(),
          {
            headers: { 'Content-Type': 'application/json' },
          },
        );
      })
      .then(response => dispatch(updateUserMetadataStore(response.data)))
      .catch(error => {
        dispatch(fetchUserMetadataFailed(error.body));
      });
  };
}

export function updateReadReceipts(key, value) {
  return (dispatch, getState) => {
    const userMetadata = new Map(getUserMetadata(getState()));
    let readReceipts = userMetadata.get(READ_RECEIPT, '{}');
    if (typeof readReceipts === 'string') {
      readReceipts = JSON.parse(readReceipts);
    }

    const update = {
      [READ_RECEIPT]: {
        ...readReceipts,
        [key]: {
          ...readReceipts[key],
          ...value,
        },
      },
    };

    dispatch(updateUserMetadata(update));
  };
}

export default {};
