import { isInSlice } from 'data/util/sliceHelpers';

export default function generateThunkReducers(builder, sliceName) {
  return builder
    .addMatcher(
      action => isInSlice(sliceName, action) && action.type.endsWith('/pending'),
      state => {
        return state.merge({
          loading: true,
          error: null,
          receivedAt: 0,
        });
      },
    )
    .addMatcher(
      action => isInSlice(sliceName, action) && action.type.endsWith('/rejected'),
      (state, action) => {
        return state.merge({
          loading: false,
          error: action.payload,
          receivedAt: Date.now(),
        });
      },
    )
    .addMatcher(
      action => isInSlice(sliceName, action) && action.type.endsWith('/fulfilled'),
      state => {
        return state.merge({
          loading: false,
          loaded: true,
          error: null,
          receivedAt: Date.now(),
        });
      },
    )
    .addDefaultCase(state => {
      return state;
    });
}
