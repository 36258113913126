import { updateAccount } from 'data/account/reducer';
import fetchPlans from 'data/plan/actions';
import { addTransaction } from 'data/transaction/reducer';
import { request } from 'shared/auth/request';
import { UPDATE_BILLING_PLAN } from 'store/action-types';

import Cookies from 'js-cookie';

function updateBillingPlanFailed(error) {
  return {
    type: UPDATE_BILLING_PLAN.FAILURE,
    payload: error,
  };
}

function updateBillingPlanRequested() {
  return {
    type: UPDATE_BILLING_PLAN.REQUEST,
  };
}

function updateBillingPlanSuccess(json) {
  return {
    type: UPDATE_BILLING_PLAN.SUCCESS,
    payload: json,
  };
}

export function updatePlan(data) {
  return dispatch => {
    dispatch(updateBillingPlanRequested());

    //add query param to get two months free on upgrade
    if (Cookies.get('upgrade-one-month') === 'true') {
      data.tfm = true;
    }

    return request()
      .post('/billing/plan', data)
      .then(
        response => {
          dispatch(fetchPlans());
          dispatch(updateBillingPlanSuccess(response));
          if (response.transactions) {
            dispatch(addTransaction(response.transactions[0]));
          }
          dispatch(updateAccount(response.account));
        },
        error => {
          dispatch(updateBillingPlanFailed(error.body));
          throw error;
        },
      );
  };
}
