import BaseModel from 'data/BaseModel';

export interface ScheduledTaskFields {
  completedAt: string | null;
  completedByUserId: string | null;
  shiftId: string | null;
}

export class ScheduledTaskModel extends BaseModel<ScheduledTaskFields>({
  completedAt: null,
  completedByUserId: null,
  shiftId: null,
}) {}
