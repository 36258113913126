import BaseModel from 'data/BaseModel';
import type Shift from 'data/shift/model';
import type { IntlContextType } from 'shared/i18n/Provider';
import { formatDate } from 'shared/i18n/utils/time';

import { DateTime } from 'luxon';
import moment from 'moment-timezone';
import { rrulestr } from 'rrule';

// Main parameter type for generateShiftAndChainData
type ShiftAndChainDataParams = {
  shift: Shift;
  repeats: boolean;
  chain_week: number;
  repeat_until: Date;
  repeat_ends: string;
  repeat_every_custom: number;
  saveForAll: boolean;
  sat153RepeatingShiftsEveryDay: boolean;
  shift_start: DateTime;
  shift_end: DateTime;
  timezone_id: string;
  repeatDaysBitMask: number;
  original_chain: ShiftChain;
};

// Parameter type for generateNonRepeatingChainData
type NonRepeatingChainDataParams = {
  sat153RepeatingShiftsEveryDay: boolean;
  shift_end: DateTime;
  timezone_id: string;
};

// Parameter type for generateRepeatingChainData
type RepeatingChainDataParams = {
  chain_week: number;
  repeat_until: Date;
  sat153RepeatingShiftsEveryDay: boolean;
  repeat_every_custom: number;
  shift_start: DateTime;
  repeat_ends: string;
  repeatDaysBitMask: number;
  timezone_id: string;
};

// Return type for all methods
type ShiftAndChainData = {
  chain: boolean | Partial<ShiftChainFields> | null;
  shiftchain_key: string | boolean | null;
  repeats: boolean;
};

export interface ShiftChainFields {
  key: string | boolean | null;
  rrule: string;
  weekdays: number;
  week: number;
  start_date?: string;
  until: string;
  timezone_id: string;
  count: number;
  created_at: string | null;
  updated_at: string | null;
  account_id: number;
}

class ShiftChain extends BaseModel<ShiftChainFields>({
  key: '',
  rrule: '',
  weekdays: 0,
  week: 1,
  start_date: '',
  until: '',
  timezone_id: '',
  count: 0,
  created_at: null,
  updated_at: null,
  account_id: 0,
}) {
  /**
   * A structure to convert RRule weekday values to strings.
   */
  static DAY_MAP = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  /**
   * A simple version of the rrule library's `toText` method which, unfortunately, is not very flexible.
   */
  toText(i18n: IntlContextType) {
    // we could maybe derive some stuff from the chain properties, but everything really should have an rrule now...
    if (!this.rrule) {
      return '';
    }

    const parsed = rrulestr(this.rrule);

    let interval = 'weekly';
    if (parsed.options.interval > 1) {
      interval = `every ${parsed.options.interval} weeks`;
    }

    const mapped = parsed.options.byweekday.map(day => ShiftChain.DAY_MAP[day]);
    let days = mapped.join(' and ');
    if (mapped.length !== 2) {
      if (mapped.length >= 3) {
        mapped[mapped.length - 1] = `and ${mapped[mapped.length - 1]}`;
      }

      days = mapped.join(', ');
    }

    const until = formatDate(i18n, parsed.options.until ?? undefined);

    return `${interval} on ${days} until ${until}`;
  }

  calculateRepeatEndDate(shiftStart: DateTime, repeatLength: string, customEndDate: Date) {
    let untilDate = DateTime.fromJSDate(customEndDate);

    if (repeatLength !== '' && repeatLength !== 'on_date') {
      let repeatDuration = {};

      switch (repeatLength) {
        case 'one_year':
          repeatDuration = { year: 1 };
          break;
        case 'six_months':
          repeatDuration = { month: 6 };
          break;
        case 'one_month':
          repeatDuration = { month: 1 };
          break;
      }

      untilDate = shiftStart.plus(repeatDuration);
    }

    return untilDate.set({
      hour: 23,
      minute: 59,
      second: 59,
    });
  }

  generateShiftAndChainData({
    shift,
    repeats,
    chain_week,
    repeat_until,
    repeat_ends,
    repeat_every_custom,
    saveForAll,
    sat153RepeatingShiftsEveryDay,
    shift_start,
    shift_end,
    timezone_id,
    repeatDaysBitMask,
    original_chain,
  }: ShiftAndChainDataParams): ShiftAndChainData {
    // Case 1: Previously repeating shift, now non-repeating, not saving for all
    if (shift.isRepeating() && !repeats && !saveForAll) {
      return {
        chain: false,
        shiftchain_key: false,
        repeats: false,
      };
    }

    // Case 2: Previously repeating shift, now non-repeating, saving for all
    if (shift.isRepeating() && !repeats && saveForAll) {
      return this.generateNonRepeatingChainData({
        sat153RepeatingShiftsEveryDay,
        shift_end,
        timezone_id,
      });
    }

    // Case 3: New repeating shift, saving for all
    if (repeats && saveForAll) {
      return this.generateRepeatingChainData({
        chain_week,
        repeat_until,
        sat153RepeatingShiftsEveryDay,
        repeat_every_custom,
        shift_start,
        repeat_ends,
        repeatDaysBitMask,
        timezone_id,
      });
    }

    // Default case: No changes to repetition or not saving for all
    return {
      chain: original_chain,
      shiftchain_key: this.key,
      repeats: repeats,
    };
  }

  private generateNonRepeatingChainData({
    sat153RepeatingShiftsEveryDay,
    shift_end,
    timezone_id,
  }: NonRepeatingChainDataParams): ShiftAndChainData {
    let chainData: Partial<ShiftChainFields> = {
      week: this.week,
      until: shift_end.toFormat('YYYY-MM-DD'),
    };

    if (sat153RepeatingShiftsEveryDay) {
      const endOfDay = shift_end.endOf('day');
      chainData = {
        ...chainData,
        until: endOfDay.toUTC().toISO(),
        weekdays: this.weekdays,
        timezone_id: timezone_id,
      };
    }

    return {
      chain: chainData,
      shiftchain_key: this.key,
      repeats: false,
    };
  }

  private generateRepeatingChainData({
    chain_week,
    repeat_until,
    sat153RepeatingShiftsEveryDay,
    repeat_every_custom,
    shift_start,
    repeat_ends,
    repeatDaysBitMask,
    timezone_id,
  }: RepeatingChainDataParams): ShiftAndChainData {
    let chainData: Partial<ShiftChainFields> = {
      week: chain_week,
      until: moment(repeat_until).toISOString(),
    };

    if (sat153RepeatingShiftsEveryDay) {
      const repeatInterval = chain_week || repeat_every_custom || 1;
      chainData = {
        week: repeatInterval,
        until: this.calculateRepeatEndDate(shift_start, repeat_ends, repeat_until).toUTC().toISO(),
        weekdays: repeatDaysBitMask,
        timezone_id: timezone_id,
      };
    }

    return {
      chain: chainData,
      shiftchain_key: this.key,
      repeats: true,
    };
  }
}

export default ShiftChain;
