import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { getAuthUser } from 'shared/auth/selectors';
import { useWiwSelector } from 'store';

import nerdry from 'shared/styles/assets/img/nedry.gif';

import 'shared/styles/notAllowed.scss';

export default function NotAllowed() {
  const authUser = useWiwSelector(getAuthUser);

  const to = authUser.isEmployee() ? '/myschedule' : '/';
  const linkText = authUser.isEmployee() ? 'Go To My Schedule' : 'Go To The Dashboard';

  useEffect(() => {
    if (!document.body.classList.contains('bg-white')) {
      // Change the single non-full width page background to white
      document.body.classList.add('bg-white');
    }

    return () => {
      // Ensure we remove the class when we leave the page so the rest of the app isn't wonky
      document.body.classList.remove('bg-white');
    };
  }, []);

  return (
    <div className="row justify-content-center align-content-center not-allowed">
      <div className="col text-center">
        <img src={nerdry} alt="You didn't say the magic word" />
        <h1>You didn't say the magic word.</h1>
        <p>
          It seems that you don't have permission to access this page. <br />
          If you need it,{' '}
          <a href="https://help.wheniwork.com" target="_blank" rel="noreferrer">
            we're here to help
          </a>
        </p>

        <div className="button-wrapper mt-2">
          <Link className="btn btn-primary" to={to}>
            {linkText}
          </Link>
        </div>
      </div>
    </div>
  );
}
