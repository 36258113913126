import BaseModel from 'data/BaseModel';
import type { ConcernsFields } from './Concerns';

export interface UserConcernsListFields {
  userId: string | null;
  rules: ConcernsFields[];
}

class UserConcernsList extends BaseModel<UserConcernsListFields>({
  userId: null,
  rules: [],
}) {}

export default UserConcernsList;
