import { useEffect, useMemo, useRef, useState } from 'react';

import type Account from 'data/account/model';
import type User from 'data/user/model';
import { getAuthAccount, getAuthUser } from 'shared/auth/selectors';
import Tooltip from 'shared/ui/Tooltip';
import { useWiwSelector } from 'store';
import { CONVERSATION_TYPE } from 'workchat/v2/constants';
import type { ReduxConversation } from 'workchat/v2/store/conversations/conversationsReducer';
import { getUserParticipantsForConversationId } from 'workchat/v2/store/participants/participantsSelectors';

export type ConversationTitleProps = {
  conversation: ReduxConversation;
  inConversation?: boolean;
};

export function getTitle(
  conversation: ReduxConversation,
  authUser: User,
  account: Account,
  inConversation = false,
  users: User[],
) {
  const { friendlyName, attributes } = conversation;

  // Helper function to get participant names
  const getParticipantNames = () => {
    return users
      .filter(user => user.id !== authUser.id && user.first_name)
      .map(user => user.first_name)
      .sort()
      .join(', ');
  };

  const getOtherParticipant = () => users.find(user => user.id !== authUser.id);

  if (friendlyName) {
    return friendlyName;
  }

  switch (attributes.external_type) {
    case CONVERSATION_TYPE.ACCOUNT:
      return account.company || 'Company WorkChat';
    case CONVERSATION_TYPE.INDIVIDUAL:
      return getOtherParticipant()?.fullName || '';
    default:
      return inConversation ? 'Group WorkChat' : getParticipantNames();
  }
}

export default function ConversationTitleV2({ conversation, inConversation }: ConversationTitleProps) {
  const authUser = useWiwSelector(getAuthUser);
  const account = useWiwSelector(getAuthAccount);
  const participants = useWiwSelector(state => getUserParticipantsForConversationId(state, conversation.sid)) || [];

  const [hasTooltip, setHasTooltip] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  const title = useMemo(
    () => getTitle(conversation, authUser, account, inConversation, participants),
    [conversation, authUser, account, inConversation, participants],
  );

  useEffect(() => {
    const div = ref.current;

    if (!div || !div.scrollWidth || !div.offsetWidth) {
      return;
    }

    const hasScroll = div.offsetWidth < div.scrollWidth;
    setHasTooltip(hasScroll);
  }, [ref]);

  const renderConversationTitle = () => {
    if (inConversation && hasTooltip) {
      return (
        <Tooltip label={title} position="bottom">
          <div ref={ref}>{title}</div>
        </Tooltip>
      );
    }
    return <div ref={ref}>{title}</div>;
  };

  return renderConversationTitle();
}
