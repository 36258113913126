import type Site from 'data/site/model';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

export const deleteSites = createWiwAsyncThunk<any, Site['id'][]>(
  'site/deleteMany',
  async (siteIds, { rejectWithValue }) => {
    try {
      return await Monolith.url('/sites')
        .query({
          ids: siteIds,
        })
        .delete();
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);

export const deleteSite = createWiwAsyncThunk<any, Site>('site/delete', async (site, { rejectWithValue }) => {
  try {
    return await Monolith.url(`/sites/${site.id}`).delete();
  } catch (error) {
    return rejectWithValue(error);
  }
});
