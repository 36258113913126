import PropTypes from 'prop-types';
import { Component } from 'react';

import classnames from 'classnames';

class PanelBody extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    empty: PropTypes.bool,
  };

  static defaultProps = {};

  render() {
    const { children, className, empty, ...other } = this.props;

    return (
      <div className={classnames('panel-body', className, { 'empty-state': empty })} {...other}>
        {children}
      </div>
    );
  }
}

export default PanelBody;
