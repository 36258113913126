import { getLDFlag } from 'data/LD/selectors/getLDFlag';
import LocationCallout from 'data/notifications/callouts/location';
import { getAuthAccount, getAuthUser } from 'shared/auth/selectors';
import Features from 'shared/features';
import { locationsSelector } from './storeSelectors';

import { createSelector } from '@reduxjs/toolkit';

const getVerifyLocation = (locations, account, currentUser, cat2260) => {
  if (cat2260) {
    // suppress location notifications for users in FRE experience
    return;
  }

  if (!account.isActive() || !account.enabled || account.is_demo) {
    return;
  }

  if (!currentUser.canManage()) {
    return;
  }

  if (!account.place?.id || !account.place_confirmed) {
    return new LocationCallout({
      type: LocationCallout.Types.VERIFY_LOCATION,
    });
  }

  if (account.hasFeature(Features.LOCATIONS)) {
    const location = locations.find(location => !location.place_confirmed);
    if (location) {
      return new LocationCallout({
        type: LocationCallout.Types.VERIFY_SITE_LOCATION,
        model: location,
      });
    }
  }
  return;
};

export const getLocationCallout = createSelector(
  [locationsSelector, getAuthAccount, getAuthUser, state => getLDFlag(state, 'cat-2260-fre-2024-boolean')],
  getVerifyLocation,
);
