import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { fetchCurrentAccount } from 'data/account/actions/fetchCurrentAccount';
import type Account from 'data/account/model';
import { fetchFreeTrialStatus } from 'data/freetrial/actions/fetchFreeTrialStatus';
import getFreeTrialLoaded from 'data/freetrial/selectors/getFreeTrialLoaded';
import getFreeTrialStatus from 'data/freetrial/selectors/getFreeTrialStatus';
import { fetchAccountMetadataIfNeeded } from 'data/metadata/actions';
import fetchPlans from 'data/plan/actions';
import type User from 'data/user/model';
import { getAuthAccount, getAuthUser } from 'shared/auth/selectors';
import CountDown from 'shared/components/nav/BulletinBoard/CountDown';
import { usePrevious } from 'shared/hooks/usePrevious';
import Button from 'shared/ui/Button';
import { openThirtyDayFreeTrialDialog } from 'shared/upsell/actions/dialogs';
import { getTrialMetadata, updateTrialMetadata } from 'shared/upsell/dialogs/ThirtyDayFreeTrialDialog/types';
import { posthogCapture } from 'shared/vendor/posthog';
import { useWiwDispatch, useWiwSelector } from 'store';

import { DateTime } from 'luxon';

export const ThirtyDayFreeTrial = () => {
  const dispatch = useWiwDispatch();
  const location = useLocation();

  const account: Account | undefined = useWiwSelector(getAuthAccount);
  const user: User | undefined = useWiwSelector(getAuthUser);
  const trialMetadata = useWiwSelector(getTrialMetadata);
  const trialLoaded = useWiwSelector(getFreeTrialLoaded);
  const trialStatus = useWiwSelector(getFreeTrialStatus);

  const previousPlanId = usePrevious(account?.plan_id);

  const [didOpenFinalDialog, setDidOpenFinalDialog] = useState(false);

  function fetchTrialStatus() {
    dispatch(fetchFreeTrialStatus()).catch(err => {
      console.warn('Failed to fetch free trial data', err);
    });
  }

  const userDeclinedTrial = useCallback((): boolean => {
    const DECLINE_DURATION_DAYS = 14; // don't show this trial for 14 days after the user dismisses it

    const userDeclinedDate = trialMetadata?.userDeclinedDate;
    if (!userDeclinedDate) {
      return false;
    }

    const today = DateTime.local();
    const daysSinceDecline = today.diff(DateTime.fromISO(userDeclinedDate), ['days']).days;

    return daysSinceDecline < DECLINE_DURATION_DAYS;
  }, [trialMetadata?.userDeclinedDate]);

  const shouldShowButton = useMemo((): boolean => {
    if (!account || !user) {
      return false;
    }

    if (userDeclinedTrial()) {
      return false;
    }

    // Everything else should already be handled by the backend endpoint.
    return true;
  }, [account, user, userDeclinedTrial]);

  const openDialog = useCallback(
    (ended: boolean) => {
      dispatch(
        openThirtyDayFreeTrialDialog({
          trialStatus: trialStatus,
          ended: ended,
          subscriptionChanged: () => {
            fetchTrialStatus();
            dispatch(fetchCurrentAccount());
            dispatch(fetchPlans());
          },
        }),
      );
    },
    [dispatch, trialStatus],
  );

  // Initial load
  useEffect(() => {
    fetchTrialStatus();
    dispatch(fetchAccountMetadataIfNeeded());
  }, [dispatch]);

  // Reload trial status if plan ID changes
  useEffect(() => {
    if (!account || !previousPlanId) {
      return;
    }

    if (previousPlanId !== account.plan_id) {
      fetchTrialStatus();
    }
  }, [account.plan_id]);

  useEffect(() => {
    if (!shouldShowButton) {
      return;
    }

    if (location.hash === '#30-day-free-trial') {
      window.location.hash = '';
      openDialog(false);
    }
  }, [shouldShowButton, location.hash, openDialog]);

  // Try to clean up trials that have already started - show an upsell dialog or clean
  // up metadata after an upsell.
  useEffect(() => {
    if (didOpenFinalDialog) {
      return;
    }

    if (!trialStatus || !trialMetadata || !trialLoaded) {
      return;
    }

    if (trialStatus.status === 'unavailable') {
      let trialExpired = false;
      if (trialMetadata.expiresDate) {
        trialExpired = DateTime.fromISO(trialMetadata.expiresDate) < DateTime.local();
      }

      // If the trial expires, show them the upsell dialog once. If the trial is
      // cancelled before the expiration date, then pretend we saw the final dialog
      // because it's no longer going to be relevant to them. (Usually because they
      // upsold, but possibly for other unknown future reasons.)
      //
      // Make sure here that your logic does not result in calls to the metadata
      // service on every React update.

      if (trialExpired && !trialMetadata.sawFinalDialog) {
        openDialog(true);
        setDidOpenFinalDialog(true);
      } else if (!trialExpired && !trialMetadata.sawFinalDialog) {
        dispatch(
          updateTrialMetadata({
            ...trialMetadata,
            sawFinalDialog: true,
          }),
        );
      }
    }
  }, [didOpenFinalDialog, openDialog, trialMetadata?.sawFinalDialog, trialMetadata?.expiresDate, trialStatus]);

  const handleTrialButtonClick = () => {
    openDialog(false);
    posthogCapture('Click', { 'Click target': '30d trial offer' });
  };

  function renderTrialButton() {
    return (
      <div className="thirty-day-trial no-border no-button-styles">
        <Button color="primary-gold" onClick={handleTrialButtonClick} />
      </div>
    );
  }

  function renderCountdown(expiresAt: DateTime) {
    return (
      <CountDown
        className="application-message scheduling"
        expiresAt={expiresAt}
        feature={null}
        remainingUrgent={1}
        remainingWarn={7}
        to="/billing/plans"
        onClick={null}
      />
    );
  }

  switch (trialStatus?.status) {
    case 'available':
      {
        if (shouldShowButton) {
          return renderTrialButton();
        }
      }
      break;
    case 'active':
      {
        if (trialStatus.expiresDate) {
          const expiresDate = DateTime.fromISO(trialStatus.expiresDate);
          return renderCountdown(expiresDate);
        }
      }
      break;
    // unavailable is handled in the effect above, since it needs to launch a dialog
  }

  return null;
};
