import BaseModel from 'data/BaseModel';
import IntegrationMap from 'integrations/modules/go/store/models/map';

import { Map } from 'immutable';

export default class WorkforceNow extends BaseModel({
  // General integration data
  key: null,
  name: '',
  description: '',
  feature: null,

  // Information specific to this account
  accountId: null,
  kind: '',
  keyId: '',
  data: new Map(),
  status: 0,
  createdAt: null,
  updatedAt: null,
  deletedAt: null,

  actions: [],

  maps: new Map(),
}) {
  constructor(props = {}) {
    // This will convert the array of map objects into a dictionary that is keyed by the type of object being mapped.
    // Like user, position, etc... And then by the localId for that object. This will make it so there will not be any
    // conflicts and looking up maps for specific objects should be much easier.
    const maps = Map({}).withMutations(map => {
      (props.maps || []).map(item => {
        const companyCode = item.companyId ? item.companyId : '*';
        map.setIn([item.type, companyCode, item.localId], new IntegrationMap(item));
      });
    });

    super({ ...props, maps: new Map(maps) });
  }
}
