import WorkforceNowState from 'integrations/modules/wfn/state';
import {
  FETCH_REMOTE_WFN_EMPLOYEES,
  FETCH_REMOTE_WFN_HOUR_TYPES,
  FETCH_REMOTE_WFN_POSITIONS,
  FETCH_REMOTE_WFN_SCHEDULES,
  FETCH_WFN,
  FETCH_WFN_CHILD_ACCOUNTS,
  FETCH_WFN_SETTINGS,
  REMOVE_WFN_MAPPING,
  UPDATE_WFN_CHILD_ACCOUNT,
  UPDATE_WFN_MAPPING,
  UPDATE_WFN_SETTINGS,
  UPDATE_WFN_SHOW_DELETED_MATCHED_USERS,
} from 'store/action-types';

export default (state = new WorkforceNowState(), action) => {
  switch (action.type) {
    case FETCH_WFN.REQUEST:
      return state.merge({
        loaded: false,
        loading: true,
        receivedAt: 0,
      });
    case FETCH_WFN.FAILURE:
      return state.merge({
        error: action.payload,
        loaded: false,
        loading: false,
      });
    case FETCH_WFN.SUCCESS:
      return state.merge({
        integration: action.payload,
        integrationLoaded: true,
        error: null,
        loaded: true,
        loading: false,
        receivedAt: Date.now(),
      });

    /*
    FETCH/UPDATE WORKFORCE NOW SETTINGS
    */

    case UPDATE_WFN_SETTINGS.REQUEST:
    case FETCH_WFN_SETTINGS.REQUEST:
      return state.merge({
        loaded: false,
        loading: true,
        receivedAt: 0,
        settingsLoaded: false,
      });
    case UPDATE_WFN_SETTINGS.FAILURE:
    case FETCH_WFN_SETTINGS.FAILURE:
      return state.merge({
        error: action.payload,
        loaded: false,
        loading: false,
        settingsLoaded: false,
      });
    case UPDATE_WFN_SETTINGS.SUCCESS:
    case FETCH_WFN_SETTINGS.SUCCESS:
      return state.merge({
        settings: action.payload,
        settingsLoaded: true,
        error: null,
        loaded: true,
        loading: false,
        receivedAt: Date.now(),
      });

    /*
    FETCH CHILD ACCOUNTS
    */

    case FETCH_WFN_CHILD_ACCOUNTS.REQUEST:
      return state.merge({
        loaded: false,
        loading: true,
        receivedAt: 0,
        childrenLoaded: false,
      });
    case FETCH_WFN_CHILD_ACCOUNTS.FAILURE:
      return state.merge({
        error: action.payload,
        loaded: false,
        loading: false,
        childrenLoaded: false,
      });
    case FETCH_WFN_CHILD_ACCOUNTS.SUCCESS:
      return state.merge({
        children: action.payload,
        childrenLoaded: true,
        error: null,
        loaded: true,
        loading: false,
        receivedAt: Date.now(),
      });

    case UPDATE_WFN_CHILD_ACCOUNT.REQUEST:
      return state;
    case UPDATE_WFN_CHILD_ACCOUNT.FAILURE:
      return state.merge({
        error: action.payload,
      });
    case UPDATE_WFN_CHILD_ACCOUNT.SUCCESS:
      return state.setIn(['children', action.payload.accountId, 'enabled'], action.payload.enabled);

    case UPDATE_WFN_MAPPING.REQUEST:
      return state.merge({
        loaded: false,
        loading: true,
        receivedAt: 0,
      });
    case UPDATE_WFN_MAPPING.FAILURE:
      return state.merge({
        error: action.payload,
        loaded: false,
        loading: false,
      });
    case UPDATE_WFN_MAPPING.SUCCESS:
      return state.merge({
        integration: state.integration.set('maps', state.integration.maps.mergeDeep(action.payload)),
        error: null,
        loaded: true,
        loading: false,
        receivedAt: Date.now(),
      });

    /*
    REMOVING MAPPINGS
     */

    case REMOVE_WFN_MAPPING.REQUEST:
      return state.merge({
        loaded: false,
        loading: true,
        receivedAt: 0,
      });
    case REMOVE_WFN_MAPPING.FAILURE:
      return state.merge({
        error: action.payload,
        loaded: false,
        loading: false,
      });
    case REMOVE_WFN_MAPPING.SUCCESS:
      return state.merge({
        // Remove all the map items returned from the current map.
        integration: state.integration.set(
          'maps',
          state.integration.maps.withMutations(map => {
            action.payload.forEach(item => {
              const companyCode = item.companyId ? item.companyId : '*';
              map = map.deleteIn([item.type, companyCode, item.localId]);
            });
          }),
        ),
        error: null,
        loaded: true,
        loading: false,
        receivedAt: Date.now(),
      });

    // Retrieving remote schedules from the INTGO service.
    case FETCH_REMOTE_WFN_SCHEDULES.REQUEST:
      return state.merge({
        loaded: false,
        loading: true,
        schedulesLoaded: false,
        receivedAt: 0,
      });
    case FETCH_REMOTE_WFN_SCHEDULES.FAILURE:
      return state.merge({
        error: action.payload,
        loaded: false,
        loading: false,
      });
    case FETCH_REMOTE_WFN_SCHEDULES.SUCCESS:
      return state.merge({
        schedules: action.payload,
        schedulesLoaded: true,
        error: null,
        loaded: true,
        loading: false,
        receivedAt: Date.now(),
      });

    // Retrieving remote positions from the INTGO service.
    case FETCH_REMOTE_WFN_POSITIONS.REQUEST:
      return state.merge({
        loaded: false,
        loading: true,
        positionsLoaded: false,
        receivedAt: 0,
      });
    case FETCH_REMOTE_WFN_POSITIONS.FAILURE:
      return state.merge({
        error: action.payload,
        loaded: false,
        loading: false,
      });
    case FETCH_REMOTE_WFN_POSITIONS.SUCCESS:
      return state.merge({
        positions: action.payload,
        positionsLoaded: true,
        error: null,
        loaded: true,
        loading: false,
        receivedAt: Date.now(),
      });

    // Retrieving remote hour types from the INTGO service.
    case FETCH_REMOTE_WFN_HOUR_TYPES.REQUEST:
      return state.merge({
        loaded: false,
        loading: true,
        positionsLoaded: false,
        receivedAt: 0,
      });
    case FETCH_REMOTE_WFN_HOUR_TYPES.FAILURE:
      return state.merge({
        error: action.payload,
        loaded: false,
        loading: false,
      });
    case FETCH_REMOTE_WFN_HOUR_TYPES.SUCCESS:
      return state.merge({
        hourTypes: action.payload,
        hourTypesLoaded: true,
        error: null,
        loaded: true,
        loading: false,
        receivedAt: Date.now(),
      });

    // Retrieving remote employees from the INTGO service.
    case FETCH_REMOTE_WFN_EMPLOYEES.REQUEST:
      return state.merge({
        loaded: false,
        loading: true,
        employeesLoaded: false,
        receivedAt: 0,
      });
    case FETCH_REMOTE_WFN_EMPLOYEES.FAILURE:
      return state.merge({
        error: action.payload,
        loaded: false,
        loading: false,
      });
    case FETCH_REMOTE_WFN_EMPLOYEES.SUCCESS:
      return state.merge({
        employees: action.payload,
        employeesLoaded: true,
        error: null,
        loaded: true,
        loading: false,
        receivedAt: Date.now(),
      });

    case UPDATE_WFN_SHOW_DELETED_MATCHED_USERS:
      return state.merge({
        showDeletedMatchedUsers: action.payload,
      });

    default:
      return state;
  }
};
