import BaseModel from 'data/BaseModel';
import type { ReportDataField } from 'data/reporting/models/ReportDataField';
import type { ReportType } from 'data/reporting/models/ReportType';

export interface ReportFields {
  hash: string;
  id: string;
  accountId: number;
  userId: number;
  specKey: string;
  type: ReportType;
  title: string;
  data: ReportDataField;
  status: number;
  progress: number;
  file: string;
  createdAt: string | null;
  updatedAt: string | null;
  deletedAt: string | null;
}

export class Report extends BaseModel<ReportFields>({
  hash: '',
  id: '',
  accountId: 0,
  userId: 0,
  specKey: '',
  type: 'export',
  title: '',
  data: {
    count: '',
    periodEnd: '',
    periodStart: '',
  },
  status: 0,
  progress: 0.0,
  file: '',
  createdAt: null,
  updatedAt: null,
  deletedAt: null,
}) {}
