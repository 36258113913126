import Currency from 'billing/components/Currency';
import BaseModel from 'data/BaseModel';
import type { StripeCoupon as StripeCouponI } from 'data/stripe/types/coupon';
import { centsToDollars } from 'data/stripe/utils/converters';

export interface StripeCouponFields extends Partial<StripeCouponI> {}

export default class StripeCoupon extends BaseModel<StripeCouponFields>({
  amount_off: 0,
  created: 0,
  currency: '',
  duration: '' as StripeCouponI['duration'],
  duration_in_months: 0,
  id: '',
  livemode: false,
  max_redemptions: 0,
  metadata: {},
  name: '',
  object: 'coupon',
  percent_off: 0,
  redeem_by: 0,
  times_redeemed: 0,
  valid: false,
}) {
  isFixedPrice() {
    return this.amount_off !== 0;
  }

  isPercentage() {
    return this.percent_off !== 0;
  }

  getCouponAmount(amount: number) {
    if (this.isPercentage()) {
      return amount * Number.parseFloat(`${this.percent_off}`) * 0.01;
    }
    return this.amount_off!;
  }

  displayCouponAmount() {
    if (!this.isFixedPrice()) {
      return `${this.percent_off}% Off`;
    }

    return <Currency amount={-centsToDollars(this.amount_off!)} showFractional />;
  }
}
