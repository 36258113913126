import BaseModel from 'data/BaseModel';
import type Account from 'data/account/model';
import { AccountSchedulerSortSettings } from 'data/account/model';
import type Position from 'data/position/model';
import type Shift from 'data/shift/model';
import type Site from 'data/site/model';
import { request } from 'shared/auth/request';

import * as chrono from 'chrono-node';
import type { Map } from 'immutable';
import { difference, get, intersection } from 'lodash';
import { DateTime } from 'luxon';
import moment, { type Moment } from 'moment-timezone';

const SLEEP_PREFERENCE_DISPLAY_FORMAT_12H = 'h:mmA';
const SLEEP_PREFERENCE_DISPLAY_FORMAT_24H = 'HH:mm';
const SLEEP_PREFERENCE_INTERNAL_FORMAT = 'HH:mm:ss';

export type StringOrMoment = string | Moment;

export type AvatarSize = 'micro' | 'mini' | 'small' | 'medium' | 'large' | 'original';

type UserDeleteReason = 'GDPR_DELETE' | 'USER_MERGED' | 'USER_ARCHIVED' | 'FUTURE_ARCHIVED_USER' | 'USER_DENIED';

// The monolith currently drives these values from the users API
export interface Avatar {
  url: string; // potentially just link to the default avatar (which is just another file in the S3 bucket) if user never had an avatar
  size: string;
  cacheUrl?: string; // undefined if the user's avatar was deleted (or never uploaded to the platform-go avatar service)
}

export interface UserFields {
  id: number;
  uuid: string | null;
  account_id: number;
  login_id: number;
  role: UserRole;
  first_name: string;
  middle_name: string;
  last_name: string;
  activated: boolean;
  alert_settings: AlertSettings;
  avatar: Avatar;
  created_at: StringOrMoment | null;
  deleted_at: StringOrMoment | null;
  delete_reason: UserDeleteReason | null;
  email: string;
  hourly_rate: number;
  hours_preferred: number;
  hours_max: number;
  invited_at: StringOrMoment | null;
  is_active: boolean;
  is_hidden: boolean;
  is_deleted: boolean;
  reactivate: boolean;
  is_onboarded: boolean;
  is_payroll: boolean; // Edit Employee > Advanced Details > "Can view wages" setting
  is_private: boolean;
  suppress_alerts: boolean; // Edit Employee > Advanced Details > "Suppress attendance alerts"
  edit_personal_timesheet: boolean; // Edit Employee > Advanced Details > "Can edit their own timesheets"
  last_login: StringOrMoment | null;
  my_positions: number[];
  locations: number[];
  positions: number[]; //includes deleted positions
  position_quality: number[];
  position_rates: {
    [position_id: string]: number;
  };
  phone_number: string;
  reminder_time: number | null;
  sleep_end: string | null;
  sleep_start: string | null;
  timezone_id: number;
  timezone_name: string;

  /** @deprecated use `employment_type` instead */
  type: UserType;
  employment_type: UserEmploymentType;
  notes: string;
  employee_code: string;
  updated_at: StringOrMoment | null;
  dismissed_at: StringOrMoment | null;
  sort: {
    [location_id: string]: number;
  };
  hired_on: string | null;
  country_code: string;
  adpwfn_id: string | null;
  start_date: StringOrMoment | null;
  calculationMethod: string | null;
  amount: number | null;
  terminated_at: StringOrMoment | null;
  exclude_from_payrolls: boolean;
}

export enum UserRole {
  Admin = 1,
  Manager = 2,
  Supervisor = 5,
  Employee = 3,
}

export enum UserType {
  Exempt = 5,
  Regular = 1,
}

// will support more employment types in the future
export type UserEmploymentType =
  | 'hourly'
  | 'hourly_exempt'
  | 'salaried'
  | 'salaried_exempt'
  | 'shareholder'
  | 'owners_draw'
  | 'officer'
  | 'contractor';

export const EmploymentType = {
  // will support more employment types in the future
  HOURLY: 'hourly',
  HOURLY_EXEMPT: 'hourly_exempt',
  SALARIED: 'salaried',
  SALARIED_EXEMPT: 'salaried_exempt',
  SHAREHOLDER: 'shareholder',
  OWNERS_DRAW: 'owners_draw',
  OFFICER: 'officer',
  CONTRACTOR: 'contractor',
} as const;

export interface AlertSetting {
  sms: boolean;
  email: boolean;
}

export interface AlertSettings {
  timeoff?: AlertSetting;
  swaps?: AlertSetting;
  schedule?: AlertSetting;
  reminders?: AlertSetting;
  availability?: AlertSetting;
  new_employee?: AlertSetting;
  attendance?: AlertSetting;
  payroll?: AlertSetting;
  workchat?: AlertSetting;
  reporting?: AlertSetting;
  'shift-bidding'?: AlertSetting;
  workplace_alerts?: AlertSetting;
  ot_alerts?: AlertSetting;
}

class User extends BaseModel<UserFields>({
  id: 0,
  uuid: null,
  account_id: 0,
  login_id: 0,
  role: UserRole.Employee,
  first_name: '',
  middle_name: '',
  last_name: '',
  activated: false,
  alert_settings: {},
  avatar: {} as Avatar,
  created_at: null,
  deleted_at: null,
  delete_reason: null,
  email: '',
  hourly_rate: 0,
  hours_preferred: 0,
  hours_max: 0,
  invited_at: null,
  is_active: false,
  is_hidden: false,
  is_deleted: false,
  reactivate: false,
  is_onboarded: false,
  is_payroll: false,
  is_private: false,
  suppress_alerts: false,
  edit_personal_timesheet: false,
  last_login: null,
  my_positions: [],
  locations: [],
  positions: [],
  position_quality: [],
  position_rates: {},
  phone_number: '',
  reminder_time: null,
  sleep_end: null,
  sleep_start: null,
  timezone_id: 0,
  timezone_name: '',
  type: UserType.Regular,
  employment_type: EmploymentType.HOURLY,
  notes: '',
  employee_code: '',
  updated_at: null,
  dismissed_at: null,
  sort: {},
  hired_on: null,
  country_code: 'US',
  adpwfn_id: null,
  start_date: null,
  calculationMethod: null,
  amount: null,
  terminated_at: null,
  exclude_from_payrolls: false,
}) {
  static Role = {
    ADMIN: UserRole.Admin,
    MANAGER: UserRole.Manager,
    SUPERVISOR: UserRole.Supervisor,
    EMPLOYEE: UserRole.Employee,
  } as const;

  static Type = {
    EXEMPT: UserType.Exempt,
    REGULAR: UserType.Regular,
  } as const;

  static RoleMappings = {
    ADMIN: [User.Role.ADMIN],
    MANAGE: [User.Role.ADMIN, User.Role.MANAGER],
    SUPERVISE: [User.Role.ADMIN, User.Role.MANAGER, User.Role.SUPERVISOR],
  } as const;

  get fullName() {
    if (!this.last_name) {
      return this.first_name;
    }
    return [this.first_name, this.last_name].join(' ');
  }

  get shortName() {
    const endString = this.id === 0 ? '' : '.';
    return `${this.first_name} ${this.last_name.substr(0, 1)}${endString}`;
  }

  get initials() {
    return `${this.first_name.charAt(0)}${this.last_name.charAt(0)}`;
  }

  can(roleMapping: readonly UserRole[]) {
    return roleMapping.includes(this.role);
  }

  is(role: UserRole) {
    return role === this.role;
  }

  canManage() {
    return this.can(User.RoleMappings.MANAGE);
  }

  canBilling(account?: Account) {
    return (!account || (account && !account.isChild())) && this.isAdmin();
  }

  canPayroll() {
    return this.isAdmin() || (this.canManage() && this.is_payroll);
  }

  canOverTime() {
    return this.isAdmin() || this.canManage();
  }

  canSubmitPayroll() {
    return this.isAdmin() || this.canManage();
  }

  canWages() {
    return this.canManage() || (this.canSupervise() && this.is_payroll);
  }

  canSupervise(location: number[] | number | null = null, strict = false) {
    const locations = Array.isArray(location) ? location : location && [location];
    const role: UserRole = this.role;

    if (role === UserRole.Admin || role === UserRole.Manager) {
      return true;
    }

    if (role === User.Role.SUPERVISOR) {
      if (locations) {
        if (strict) {
          return difference(locations, this.locations).length === 0;
        }
        return intersection(this.locations, locations).length > 0;
      }
      return true;
    }
    return false;
  }

  isAdmin() {
    return this.is(User.Role.ADMIN);
  }

  isManager() {
    return this.is(User.Role.MANAGER);
  }

  isSupervisor() {
    return this.is(User.Role.SUPERVISOR);
  }

  isEmployee() {
    return this.is(User.Role.EMPLOYEE);
  }

  isSalariedNoOvertime() {
    const allowedTypes = ['salaried_exempt', 'shareholder'];
    return allowedTypes.includes(this.employment_type);
  }

  isSalariedType() {
    const allowedTypes = ['salaried', 'salaried_exempt', 'shareholder'];
    return allowedTypes.includes(this.employment_type);
  }

  isContractor() {
    return this.employment_type === EmploymentType.CONTRACTOR;
  }

  isExemptType() {
    const exemptTypes = ['hourly_exempt', 'salaried_exempt', 'shareholder', 'contractor'];
    return exemptTypes.includes(this.employment_type);
  }

  isHourlyType() {
    const hourlyTypes = ['hourly', 'hourly_exempt'];

    return hourlyTypes.includes(this.employment_type);
  }

  isStringOrMomentNull(time: StringOrMoment) {
    return time === '' || time === null || time === '0000-00-00';
  }

  canEditUser(user: User) {
    // account admins can edit anyone
    if (this.isAdmin()) {
      return true;
    }

    const hasSharedLocations = intersection(this.locations, user.locations).length > 0;

    // manager can edit anyone below account admin
    if (this.isManager()) {
      return !user.isAdmin();
    }

    // supervisor can edit employees belonging to one of their locations
    if (this.isSupervisor()) {
      return this.id === user.id || (user.isEmployee() && hasSharedLocations);
    }

    return false;
  }

  canViewUserPayroll(user: User) {
    // account holder and manager can view any payroll, any user can view their own payroll
    if (this.isAdmin() || this.isManager() || this.id === user.id) {
      return true;
    }

    const hasSharedLocations = intersection(this.locations, user.locations).length > 0;

    // supervisors can view any user or supervisor that belongs to one of their locations
    if (this.isSupervisor()) {
      return this.id === user.id || ((user.isEmployee() || user.isSupervisor()) && hasSharedLocations);
    }

    return false;
  }

  canDeleteUser(user: User) {
    //Users cannot delete themselves
    if (this.id === user.id) {
      return false;
    }

    //AH can delete anyone
    if (this.isAdmin()) {
      return true;
    }

    //Managers can delete anyone other than an admin
    if (this.isManager()) {
      return !user.isAdmin();
    }

    //Supervisors can delete employees who belong only to locations that the Supervisor is associated with
    if (this.isSupervisor()) {
      const sharesAllLocations = intersection(this.locations, user.locations).length === user.locations.length;

      if (user.isEmployee() && sharesAllLocations) {
        return true;
      }
    }
  }

  canManageSchedules(account: Account): boolean {
    if (this.isAdmin()) {
      return true;
    }
    if (this.isManager()) {
      return account.getSettings('role_permissions.managers.can_manage_workplace_objects', true);
    }

    return false;
  }

  canManagePositions(account: Account): boolean {
    if (this.isAdmin()) {
      return true;
    }
    if (this.isManager()) {
      return account.getSettings('role_permissions.managers.can_manage_workplace_objects', true);
    }

    return false;
  }

  canManageTags(account: Account): boolean {
    if (this.isAdmin()) {
      return true;
    }
    if (this.isManager()) {
      return account.getSettings('role_permissions.managers.can_manage_workplace_objects', true);
    }
    if (this.isSupervisor()) {
      return account.getSettings('role_permissions.supervisors.can_manage_workplace_objects', true);
    }

    return false;
  }

  canManageSites(account: Account): boolean {
    if (this.isAdmin()) {
      return true;
    }
    if (this.isManager()) {
      return account.getSettings('role_permissions.managers.can_manage_workplace_objects', true);
    }
    if (this.isSupervisor()) {
      return account.getSettings('role_permissions.supervisors.can_manage_workplace_objects', true);
    }

    return false;
  }

  canViewSite(site: Site): boolean {
    if (this.canManage()) {
      return true;
    }
    return site.location_id === 0 || this.locations.includes(site.location_id);
  }

  canEditSite(site: Site, account: Account): boolean {
    // Reject anyone without the ability to manage workplace objects
    if (!this.canManageSites(account)) {
      return false;
    }
    // From here on out, they do in theory have permission to "edit sites", but maybe not
    // all sites.

    if (this.canManage()) {
      // Admins and managers can edit all sites
      return true;
    }
    // Supervisors can only edit sites on schedules they are assigned to (and cannot edit sites
    // attached to All Schedules).
    return site.location_id !== 0 && this.canSupervise(site.location_id);
  }

  canManageShiftTemplates(account: Account): boolean {
    if (this.isAdmin()) {
      return true;
    }
    if (this.isManager()) {
      return account.getSettings('role_permissions.managers.can_manage_workplace_objects', true);
    }
    if (this.isSupervisor()) {
      return account.getSettings('role_permissions.supervisors.can_manage_workplace_objects', true);
    }

    return false;
  }

  canEditAllShiftDetails(account: Account): boolean {
    if (this.isAdmin()) {
      return true;
    }
    if (this.isManager()) {
      return true;
    }
    if (this.isSupervisor()) {
      return account.getSettings('role_permissions.supervisors.can_edit_all_shift_details', true);
    }

    return false;
  }

  getNoticesDismissedAt() {
    const dismissedAt = DateTime.fromISO(this.dismissed_at as string);
    if (!dismissedAt?.isValid) {
      // If we don't have a time value, initialize one to 7 days ago so no Attendance Notices aren't marked
      // as cleared erroneously. (Only the last 7 days of notices are fetched).
      return DateTime.now().minus({ days: 7 });
    }
    return dismissedAt;
  }

  /**
   * As of 2022-10-20, we now include a cacheUrl field in the user avatar. If cacheUrl is
   * present, you can fetch the avatar image directly from a CDN rather than having the avatar service redirect you to
   * the ultimate URL location. The cacheUrl will look similar to the url, but you have to provide a defined size,
   * one of micro, mini, small, medium, large, or original (but not a numeric value).
   *
   * The getAvatarUrl method still supports passing numerical and strings parameters for sizes.
   */
  getAvatar(size: AvatarSize = 'small') {
    if (this.avatar.cacheUrl) {
      return this.avatar.cacheUrl.replace('%s', size);
    }
    return this.getAvatarUrl(size);
  }

  /**
   * The original way of fetching avatar images which doesn't use the edge cache provider.
   */
  getAvatarUrl(size: number | AvatarSize = 'small') {
    return (this.avatar.url || '').replace('%s', `${size}`);
  }

  getPositionRate(positionId: number) {
    return this.hourly_rate + (this.position_rates[positionId] || 0);
  }

  hasContactInformation() {
    return (this.email || '').length > 0 || (this.phone_number || '').length > 0;
  }

  isDummy() {
    return this.first_name === '' && this.id === 0 && this.created_at === null;
  }

  isNew() {
    return this.id === 0;
  }

  // ES Lint can't handle having a default object with properties I guess
  // eslint-disable-next-line
  save(
    options: Partial<{
      invite: boolean;
    }> = { invite: true },
  ) {
    const params = this.toJSON();
    if (params.id) {
      return request().put(`/users/${params.id}`, params);
    }
    return request().post('/users/', { ...params, ...options });
  }

  saveProfile(
    options: Partial<{
      password: string;
    }> = {},
  ) {
    const params = this.toJSON();
    return request().post('/users/profile', { ...params, ...options });
  }

  uploadAvatar(file: File) {
    return request().upload(`/users/${this.id}/avatar`, file);
  }

  canAlertMobile(name: keyof AlertSettings) {
    return get(this.alert_settings, `${name}.sms`, false);
  }

  canAlertEmail(name: keyof AlertSettings) {
    return get(this.alert_settings, `${name}.email`, true);
  }

  parseSleepPreferences(forInput = false, format24h?: boolean) {
    const DISPLAY_HOURS_FORMAT = format24h ? SLEEP_PREFERENCE_DISPLAY_FORMAT_24H : SLEEP_PREFERENCE_DISPLAY_FORMAT_12H;
    const chronoStart = chrono.parseDate(this.sleep_start!);
    const chronoEnd = chrono.parseDate(this.sleep_end!);
    const startTime = moment(chronoStart).isValid()
      ? moment(chronoStart)
      : moment(this.sleep_start!, DISPLAY_HOURS_FORMAT);
    const endTime = moment(chronoEnd).isValid() ? moment(chronoEnd) : moment(this.sleep_end!, DISPLAY_HOURS_FORMAT);

    return forInput
      ? {
          start: startTime.format('HH:mm:ss.SSS'),
          end: endTime.format('HH:mm:ss.SSS'),
        }
      : { startTime, endTime };
  }

  updateSleepPreferences(start: string, end: string, format24h?: boolean) {
    const DISPLAY_HOURS_FORMAT = format24h ? SLEEP_PREFERENCE_DISPLAY_FORMAT_24H : SLEEP_PREFERENCE_DISPLAY_FORMAT_12H;
    const chronoStart = chrono.parseDate(start);
    const chronoEnd = chrono.parseDate(end);
    const startTime = moment(chronoStart).isValid() ? moment(chronoStart) : moment(start, DISPLAY_HOURS_FORMAT);
    const endTime = moment(chronoEnd).isValid() ? moment(chronoEnd) : moment(end, DISPLAY_HOURS_FORMAT);

    return this.set('sleep_start', startTime.format(SLEEP_PREFERENCE_INTERNAL_FORMAT)).set(
      'sleep_end',
      endTime.format(SLEEP_PREFERENCE_INTERNAL_FORMAT),
    );
  }

  get roleName() {
    return User.roleNameFor(this.role);
  }

  static roleNameFor(role: UserRole) {
    switch (role) {
      case UserRole.Admin:
        return 'Admin';
      case UserRole.Manager:
        return 'Manager';
      case UserRole.Supervisor:
        return 'Supervisor';
      case UserRole.Employee:
        return 'Employee';
      default:
        return 'Unknown';
    }
  }

  mercuryRoleName() {
    return `${this.roleName.charAt(0).toLowerCase()}${this.roleName.slice(1).replace(' ', '')}`;
  }

  getActivePositions(positions: Map<number, Position>) {
    return this.positions.filter(position => positions.has(position));
  }

  static compare(userA: User, userB: User, locationID: number | null, accountSort: AccountSchedulerSortSettings) {
    let aSort = -1;
    let bSort = -1;

    if (locationID) {
      // Sometimes we are sorting users that aren't assigned to the location so they do not have a sort value
      aSort = userA.sort[locationID] || -1;
      bSort = userB.sort[locationID] || -1;
    }

    if (aSort === bSort) {
      let aName = userA.first_name.toLowerCase();
      let bName = userB.first_name.toLowerCase();

      if (
        accountSort === AccountSchedulerSortSettings.ScheduleSortEmployeesLastName ||
        aName.localeCompare(bName) === 0 // if first names are the same sort by last name
      ) {
        if (userA.last_name.toLowerCase().localeCompare(userB.last_name.toLowerCase()) !== 0) {
          aName = userA.last_name.toLowerCase();
          bName = userB.last_name.toLowerCase();
        }
      }

      return aName.localeCompare(bName);
    }
    return aSort - bSort;
  }

  static fullNameCompare(userA: User, userB: User) {
    return userA.fullName.localeCompare(userB.fullName);
  }

  static makeDummy() {
    return new User({
      id: 0,
      first_name: '',
      created_at: null,
    });
  }

  //Determine if this is a shared user at the given location
  isSharedUser(locationId: number, shifts: Map<number, Shift> | null = null): boolean {
    const notAtThisLocation = this.id !== 0 && !this.locations.includes(locationId);

    // If shifts are provided check that one of them is actually a shared shift
    if (shifts !== null && notAtThisLocation) {
      return shifts.some(shift => shift.user_id === this.id);
    }

    return notAtThisLocation;
  }
}

export default User;
