import { createSelector } from '@reduxjs/toolkit';
import { getStateShifts } from 'data/shift/selectors/getStateShifts';
import Tag from 'data/tag/Tag';
import TagList from 'data/tag/TagList';
import type { EntityMap } from 'data/types';
import { getStateUsers } from 'data/user/selectors';
import { getEnabledTagFilter } from 'scheduler/selectors/filters';
import type { WiwState } from 'store';

export const getStateTags = (state: WiwState) => state.data.tag.tags.items;
export const getStateUserTags = (state: WiwState) => state.data.tag.users.items;
export const getStateShiftTags = (state: WiwState) => state.data.tag.shifts.items;
export const getStateShiftTemplateTags = (state: WiwState) => state.data.tag.shiftTemplates.items;

export const getTags = getStateTags;
export const getUserTags = getStateUserTags;
export const getShiftTags = getStateShiftTags;
export const getShiftTemplateTags = getStateShiftTemplateTags;

export const areTagsLoaded = (state: WiwState) => state.data.tag.tags.loaded;
export const areTagsLoading = (state: WiwState) => state.data.tag.tags.loading;
export const areShiftTagsLoading = (state: WiwState) => state.data.tag.shifts.loading;
export const areShiftTemplateTagsLoaded = (state: WiwState) => state.data.tag.shiftTemplates.loaded;
export const areShiftTemplateTagsLoading = (state: WiwState) => state.data.tag.shiftTemplates.loading;

export const getSortedTags = createSelector([getTags], (tags: EntityMap<Tag>) =>
  tags.sortBy(item => item.name.toLowerCase()),
);

export const getTagsForUser = createSelector([getUserTags, (_, userId) => userId], (userTags, userId) => {
  return userTags.get(userId, new TagList()).tags;
});

export const getTagsForShift = createSelector([getShiftTags, (_, shiftId) => shiftId], (shiftTags, shiftId) => {
  return shiftTags.get(shiftId, new TagList()).tags;
});

export const getTagNamesForShift = createSelector(
  [getTags, (state, shiftId) => getTagsForShift(state, shiftId)],
  (tags: EntityMap<Tag>, shiftTags): string[] => {
    return shiftTags
      .map((tagId: string) => tags.get(tagId, new Tag()).name)
      .filter((tagName: string) => tagName !== '');
  },
);

export const getTagsForShiftTemplate = createSelector(
  [getShiftTemplateTags, (_, templateId) => templateId],
  (templateTags, templateId) => {
    return templateTags.get(templateId, new TagList()).tags;
  },
);

export const getTagNamesForShiftTemplate = createSelector(
  [getTags, (state, templateId) => getTagsForShiftTemplate(state, templateId)],
  (tags, templateTags) => {
    return templateTags
      .map((tagId: string) => tags.get(tagId, new Tag()).name)
      .filter((tagName: string) => tagName !== '');
  },
);

export const getShiftsMatchingTagFilter = createSelector(
  [getStateShifts, getStateShiftTags, getEnabledTagFilter, state => state.data.tag?.shifts?.error],
  (shifts, shiftTags, tagFilter, tagServiceError) => {
    if (tagServiceError || tagFilter.length === 0) {
      return shifts;
    }

    return shifts.filter(shift => {
      return tagFilter.every((tag: Tag) => shiftTags.get(shift.id, new TagList()).tags.includes(tag));
    });
  },
);

export const getUsersMatchingTagFilter = createSelector(
  [getStateUsers, getStateUserTags, getEnabledTagFilter, state => state.data.tag?.shifts?.error],
  (users, userTags, tagFilter, tagServiceError) => {
    if (tagServiceError || tagFilter.length === 0) {
      return users;
    }

    return users.filter(user => {
      return tagFilter.every((tag: Tag) => userTags.get(user.id, new TagList()).tags.includes(tag));
    });
  },
);

// Deprecated: use getShiftsMatchingTagFilter instead.
export const doShiftTagsMatchFilter = (shiftId: number) => (state: WiwState) => {
  return getShiftsMatchingTagFilter(state).has(shiftId);
};

// Deprecated: use getUsersMatchingTagFilter instead.
export const doUserTagsMatchFilter = (userId: number) => (state: WiwState) => {
  return getUsersMatchingTagFilter(state).has(userId);
};
