import { absenceNotifications } from 'data/absence/reducer';
import { request } from 'shared/auth/request';
import {
  DELETE_NOTIFICATION_USER,
  FETCH_NOTIFICATIONS,
  NOTIFICATIONS_DRAWER,
  SEARCH_NOTIFICATIONS,
  SET_NOTIFICATION_SECTION,
  UPDATE_NOTIFICATION_LOCATION,
  UPDATE_NOTIFICATION_NEW_COUNT,
  UPDATE_NOTIFICATION_REQUEST,
  UPDATE_NOTIFICATION_USER,
} from 'store/action-types';

export function displayNotificationsDrawer() {
  return dispatch => {
    return dispatch(setNotificationsDisplay(true));
  };
}

export function hideNotificationsDrawer() {
  return dispatch => {
    return dispatch(setNotificationsDisplay(false));
  };
}

export function setActiveSection(name) {
  return dispatch => {
    return dispatch(updateActiveSection(name));
  };
}

export function clearActiveSection() {
  return dispatch => {
    return dispatch(updateActiveSection(''));
  };
}

export function setSearchTerm(search) {
  return dispatch => {
    return dispatch(updateSearchTerm(search));
  };
}

export function fetchNotifications(notificationParams, requestParams, swapParams, locationParams) {
  return dispatch => {
    dispatch(requestNotifications());

    return Promise.all([
      notificationParams ? request().get('/notifications', { query: notificationParams }) : false,
      requestParams ? request().get('/requests', { query: requestParams }) : false,
      swapParams ? request().get('/swaps', { query: swapParams }) : false,
      locationParams ? request().get('/locations', { query: locationParams }) : false,
    ])
      .then(data => {
        if (data[0]?.absences) {
          dispatch(absenceNotifications(data[0].absences));
        }

        const results = {
          notifications: data[0],
          requests: data[1],
          swaps: data[2],
          locations: data[3],
        };

        return dispatch(receiveNotifications(results));
      })
      .catch(err => dispatch(receiveNotificationsFailed(err.body)));
  };
}

export function updateUserNotifiedAt(time) {
  return dispatch => {
    return request()
      .post('/users/profile', { notified_at: time })
      .then(data => {
        dispatch(updateNotificationNewCount(0));
        return data;
      });
  };
}

export function updateNotificationRequest(request) {
  return dispatch => {
    dispatch(updateNotificationRequestStore(request));
  };
}

export function updateNotificationUser(user) {
  return dispatch => {
    dispatch(updateNotificationUserStore(user));
  };
}

export function deleteNotificationUser(user) {
  return dispatch => {
    dispatch(deleteNotificationUserStore(user));
  };
}

export function updateNotificationLocation(location) {
  return dispatch => {
    dispatch(updateNotificationLocationStore(location));
  };
}

export function receiveNotifications(payload = {}) {
  const notifications = payload.notifications || {};
  const requests = payload.requests || {};
  const swaps = payload.swaps || {};
  const locations = payload.locations || {};

  return {
    type: FETCH_NOTIFICATIONS.SUCCESS,
    items: notifications.notifications || [],
    users: (notifications.users || []).concat(requests.users || []).concat(swaps.users || []),
    requests: (notifications.requests || []).concat(requests.requests || []),
    swaps: (notifications.swaps || []).concat(swaps.swaps || []),
    shifts: (notifications.shifts || []).concat(swaps.shifts || []),
    messages: (notifications.messages || []).concat(requests.messages || []).concat(swaps.messages || []),
    absences: notifications?.absences || [],
    locations: locations.locations || [],
    new: notifications.new || 0,
  };
}

function updateNotificationRequestStore(item) {
  return {
    type: UPDATE_NOTIFICATION_REQUEST,
    item,
  };
}

function updateNotificationUserStore(item) {
  return {
    type: UPDATE_NOTIFICATION_USER,
    item,
  };
}

function deleteNotificationUserStore(item) {
  return {
    type: DELETE_NOTIFICATION_USER,
    item,
  };
}

function updateNotificationLocationStore(item) {
  return {
    type: UPDATE_NOTIFICATION_LOCATION,
    item,
  };
}

function receiveNotificationsFailed(err) {
  return {
    type: FETCH_NOTIFICATIONS.FAILURE,
    payload: err,
  };
}

function requestNotifications() {
  return {
    type: FETCH_NOTIFICATIONS.REQUEST,
  };
}

function updateNotificationNewCount(count) {
  return {
    type: UPDATE_NOTIFICATION_NEW_COUNT,
    new: count,
  };
}

function setNotificationsDisplay(show) {
  const action = {
    type: NOTIFICATIONS_DRAWER,
    show,
  };
  return action;
}

function updateActiveSection(section) {
  const action = {
    type: SET_NOTIFICATION_SECTION,
    section,
  };
  return action;
}

function updateSearchTerm(search) {
  const action = {
    type: SEARCH_NOTIFICATIONS,
    search,
  };
  return action;
}
