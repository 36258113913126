import { useEffect, useRef } from 'react';

/**
 * Store the previous version of any value in a hook component. Useful for
 * doing checks like prevProps or prevState.
 *
 *     function MyComponent({ myProp }) {
 *       const previousMyProp = usePrevious(myProp);
 *
 *       useEffect(() => {
 *         if (myProps !== previousMyProp) {
 *           // do some thing
 *         }
 *       });
 *     }
 *
 * A dependency array is only intended as a performance optimization, not as a
 * shortcut for props.foo !== prevProps.foo. For more explanation, please read
 * the docs for useEffect:
 *
 * https://reactjs.org/docs/hooks-reference.html#useeffect
 * https://reactjs.org/docs/hooks-faq.html#is-it-safe-to-omit-functions-from-the-list-of-dependencies
 *
 * The implementation is stolen directly from the React docs:
 * https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
 */
export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}
