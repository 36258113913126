import type { AccountFields } from 'data/account/model';
import type { StripeSubscription } from 'data/stripe/types/subscriptions';
import Billing from 'shared/api/billing';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

export type HibernateSubscriptionResponse = {
  subscription: StripeSubscription;
  account: AtLeast<AccountFields, 'id'>;
};

export const hibernateSubscription = createWiwAsyncThunk<HibernateSubscriptionResponse, number>(
  'stripe/subscriptions/hibernate',
  async (deactivated_reason_id = 0, { rejectWithValue }) => {
    try {
      const subscriptionRaw = (await Billing.url('/subscriptions/hibernate').post({ deactivated_reason_id })) as {
        data: HibernateSubscriptionResponse;
      };
      return subscriptionRaw.data;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
