import BaseModel from 'data/BaseModel';

interface Concerns {
  shift: boolean;
  unavail: boolean;
  request: boolean;
}

interface ShiftConcernFields {
  userId: string | null;
  concern: Concerns;
}

class RollupShiftConcern extends BaseModel<ShiftConcernFields>({
  userId: null,
  concern: {
    shift: false,
    unavail: false,
    request: false,
  } as Concerns,
}) {}

export default RollupShiftConcern;
