import { request } from 'shared/auth/request';
import { FETCH_SCHEDULED_TASK_LISTS } from 'store/action-types';

function fetchScheduledTaskListsPending() {
  return {
    type: FETCH_SCHEDULED_TASK_LISTS.REQUEST,
  };
}

export function fetchScheduledTaskListsFailed(error) {
  return {
    type: FETCH_SCHEDULED_TASK_LISTS.FAILURE,
    payload: error.body,
    error: error.response,
  };
}

function fetchScheduledTaskListsFulfilled(json) {
  return {
    type: FETCH_SCHEDULED_TASK_LISTS.SUCCESS,
    payload: json,
  };
}

export function fetchScheduledTaskLists(startDate, endDate, locationId = null, includeShiftTasks = true) {
  return dispatch => {
    const query = {
      startDate,
      endDate,
      includeShiftTasks,
    };

    if (locationId) {
      query.locationId = locationId;
    }

    dispatch(fetchScheduledTaskListsPending());
    return request(CONFIG.TASKS_API)
      .get('/scheduled-task-lists', { query })
      .then(response => dispatch(fetchScheduledTaskListsFulfilled(response.data)))
      .catch(error => dispatch(fetchScheduledTaskListsFailed(error)));
  };
}

export function fetchScheduledTaskListsByTaskList(taskListId) {
  return dispatch => {
    dispatch(fetchScheduledTaskListsPending());
    return request(CONFIG.TASKS_API)
      .get(`/task-lists/${taskListId}/scheduled-task-lists`)
      .then(response => dispatch(fetchScheduledTaskListsFulfilled(response.data)))
      .catch(error => dispatch(fetchScheduledTaskListsFailed(error)));
  };
}
