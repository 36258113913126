/**
 * To avoid circular import problems, this file's imports should be kept very
 * lean. Do not put any code in here to initialize the store; that should be
 * kept in configureStore and set up elsewhere on bootstrap.
 */

// eslint-disable-next-line no-restricted-imports
import { type TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import type { WiwDispatch, WiwStore } from 'store/configureStore';
import type rootReducer from 'store/rootReducer';

import { createAsyncThunk } from '@reduxjs/toolkit';

export type WiwState = ReturnType<typeof rootReducer>;
export type WiwGetState = () => WiwState;
export type RootState = WiwState;

export type { WiwDispatch } from 'store/configureStore';

export const useWiwDispatch: () => WiwDispatch = useDispatch;
export const useWiwSelector: TypedUseSelectorHook<WiwState> = useSelector;
export const createWiwAsyncThunk = createAsyncThunk.withTypes<{
  state: WiwState;
  dispatch: WiwDispatch;
}>();

let storeInstance: WiwStore;

export const globalizeStore = (store: WiwStore) => {
  storeInstance = store;
};

export const getState = () => {
  return storeInstance.getState();
};

export const getStore = () => {
  return storeInstance;
};
