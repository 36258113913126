import { SHOW_OTHER_SCHEDULES } from 'scheduler/util/cookies';
import { TOGGLE_SHOW_OTHER_SCHEDULE_SHIFTS } from 'store/action-types';

import Cookie from 'js-cookie';

export const toggleShowOtherScheduleShifts = value => {
  Cookie.set(SHOW_OTHER_SCHEDULES, value);

  return {
    type: TOGGLE_SHOW_OTHER_SCHEDULE_SHIFTS,
    payload: value,
  };
};
