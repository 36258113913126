import { type CSSProperties, type ReactNode, useCallback, useState } from 'react';

import DialogFooter from 'dialogs/components/Footer';
import { type DialogContextProps, withDialogContext } from 'dialogs/context';
import Form from 'shared/components/form';
import Button, { type ButtonProps } from 'shared/ui/Button';
import DialogBody from './Body';
import Dialog from './Dialog';
import DialogHeader from './Header';

import classnames from 'classnames';

export const CONFIRMATION_DIALOG = 'CONFIRMATION_DIALOG';

export interface Props extends DialogContextProps {
  className?: string;
  onConfirm: () => void;
  onCancel?: () => void;
  title?: string;
  body?: string | ReactNode;
  confirmClass?: string;
  confirmLabel?: string | null;
  cancelLabel?: string;
  buttonSize?: ButtonProps['size'];
  waitToClose?: boolean;
  width?: number;
  setFocus?: boolean;
  buttonColor?: ButtonProps['color'];
  style?: CSSProperties;
}

export function ConfirmationDialog({
  className,
  closeDialog,
  onConfirm,
  onCancel,
  title,
  body = 'Are you sure?',
  confirmClass,
  confirmLabel = 'Okay',
  cancelLabel = 'Cancel',
  buttonSize = 'md',
  waitToClose = false,
  width,
  setFocus = false,
  buttonColor,
  style,
}: Props) {
  const [loading, setLoading] = useState(false);

  const onDismiss = useCallback(() => {
    onCancel?.();
  }, [onCancel]);

  const handleConfirm = useCallback(() => {
    if (waitToClose) {
      setLoading(true);
      return Promise.resolve(onConfirm()).then(() => {
        setLoading(false);
        closeDialog({ force: true });
      });
    }

    onConfirm();
    closeDialog({ force: true });
  }, [closeDialog, onConfirm, waitToClose]);

  return (
    <Dialog className={className} onDismiss={onDismiss} style={style} width={width}>
      <Form onValidSubmit={handleConfirm}>
        <DialogHeader onClose={closeDialog}>{title}</DialogHeader>
        <DialogBody>
          <div>{body}</div>
        </DialogBody>
        <DialogFooter className="justify-content-end">
          <Button size={buttonSize} color="secondary" className="ml-2 cancel" onClick={closeDialog}>
            {cancelLabel}
          </Button>
          {confirmLabel && (
            <Button
              type="submit"
              size={buttonSize}
              className={classnames('ml-2', confirmClass)}
              loading={loading}
              setFocus={setFocus}
              color={buttonColor || 'primary'}
            >
              {confirmLabel}
            </Button>
          )}
        </DialogFooter>
      </Form>
    </Dialog>
  );
}

export default withDialogContext(ConfirmationDialog);
