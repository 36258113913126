import { lazy } from 'react';

const CompareWorkchatDialog = lazy(() => import('workchat/dialogs/CompareWorkchatDialog'));
const DeleteMessageDialog = lazy(() => import('workchat/v2/dialogs/DeleteMessageDialog/DeleteMessageDialog'));

export const COMPARE_WORKCHAT_DIALOG = 'COMPARE_WORKCHAT_DIALOG';
export const DELETE_MESSAGE_DIALOG = 'DELETE_MESSAGE_DIALOG';

const dialogs = {
  [COMPARE_WORKCHAT_DIALOG]: CompareWorkchatDialog,
  [DELETE_MESSAGE_DIALOG]: DeleteMessageDialog,
};

export default dialogs;
