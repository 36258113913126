import moment from 'moment-timezone';

export default function isTimePeriod(values, value) {
  if (value.start === undefined || value.end === undefined) {
    return true;
  }
  if (value.start === '' && value.end === '') {
    return true;
  }
  return moment(value.start, 'hha').isValid() && moment(value.end, 'hha').isValid();
}
