import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { mustGetAccountPlan } from 'data/account/selectors';
import { getAuthAccount, getAuthUser } from 'shared/auth/selectors';
import { useWiwSelector } from 'store';

// A whitelist of regular expressions matching paths that are acceptable after a trial has expired.
// These regexes will be tested against location.pathname which excludes any querystring params.
// "If there is no path, pathname will be empty: otherwise, pathname contains an initial '/' followed
// by the path of the URL, not including the query string or fragment."
//  - https://developer.mozilla.org/en-US/docs/Web/API/Location/pathname
const whitelist: RegExp[] = [
  /^\/billing/i, // starts with "/billing"
  /^\/payroll\/?$/i, // must be "/payroll" or "/payroll/"
];

export default function BillingStatusGuard() {
  const location = useLocation();
  const history = useHistory();

  const currentUser = useWiwSelector(getAuthUser);
  const account = useWiwSelector(getAuthAccount);
  const accountPlan = useWiwSelector(mustGetAccountPlan);

  useEffect(() => {
    if (!account || !currentUser) {
      return;
    }

    if (account.isActive()) {
      return;
    }

    // this will only return if this user is Admin and at least one whitelist match is found.
    if (currentUser.isAdmin() && whitelist.find(exp => exp.test(location.pathname))) {
      return;
    }

    if (currentUser.isAdmin()) {
      const redirect = accountPlan.isTrial() ? '/billing/plans' : '/billing';
      history.replace(redirect);
      return;
    }
    history.replace('/deactivated');
  }, [currentUser, account, location.pathname]);

  return null;
}
