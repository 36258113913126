import { type InputHTMLAttributes, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import useIntl from 'shared/i18n/useIntl';
import { parseTextToTime } from 'shared/util/time';

import classNames from 'classnames';
import type { Moment } from 'moment-timezone';

interface TimeTextInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
  name: string;
}

const TimeTextInput = ({ name, className, ...props }: TimeTextInputProps) => {
  const { register, setValue, getValues } = useFormContext();
  const { formatTimeLong } = useIntl();

  useEffect(() => {
    setValue(name, parseInputToTime(getValues()[name]));
  }, [name]);

  const parseInputToTime = (value?: string) => {
    if (!value) {
      return null;
    }
    const date = parseTextToTime(value);

    // biome-ignore lint/complexity/useOptionalChain: This is a simple if statement
    if (date && date.isValid()) {
      return formatTime(date);
    }

    return value;
  };

  // check for times with a(m) or p(m) in any case with optional m's
  const meridianRegex = RegExp(/([AaPp][Mm]?)/);

  const formatTime = (date: Date | Moment) => {
    const formattedTime = formatTimeLong(date).toLowerCase();
    if (!meridianRegex.test(formattedTime)) {
      return formattedTime;
    }
    return formattedTime.slice(0, -1);
  };

  const { onBlur, ...registerProps } = register(name);

  return (
    <input
      {...props}
      {...registerProps}
      type="text"
      placeholder={`i.e. ${formatTime(new Date('2010-04-12T17:30:00'))}`}
      className={classNames('form-control', className)}
      data-testid={name}
      onBlur={e => {
        setValue(name, parseInputToTime(e.target.value));
        onBlur(e);
      }}
    />
  );
};

export default TimeTextInput;
