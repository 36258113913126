import { getUserId } from 'shared/auth/auth';

export function getUserItem(key: string, defaultValue: any = null) {
  const itemName = `${getUserId()}:${key}`;
  // JSON.parsing the string 'null' produces null, which satisfies the type checker
  // (Even though JSON.parse(null) actually also produces null, but the type checker doesn't like that)
  const data = JSON.parse(sessionStorage.getItem(itemName) || 'null');
  return data !== null ? data : defaultValue;
}

export function setUserItem(key: string, value: any) {
  const itemName = `${getUserId()}:${key}`;
  return sessionStorage.setItem(itemName, JSON.stringify(value));
}

export function removeUserItem(key: string) {
  const itemName = `${getUserId()}:${key}`;
  return sessionStorage.removeItem(itemName);
}

export const SEEN_VERIFY_ACCOUNT_LOCATION_DIALOG_SESSION_KEY = 'seenVerifyAccountLocationDialog';
