import { WORKCHAT_ERROR } from 'workchat/action-types';

export function setError(error) {
  return {
    type: WORKCHAT_ERROR,
    error,
  };
}

export function clearError() {
  return {
    type: WORKCHAT_ERROR,
    error: null,
  };
}
