import { Record } from 'immutable';

export default class PaychexEarnings extends Record({
  regular: null,
  overtime: null,
  doubleOvertime: null,
  paidTimeOff: null,
  sickDays: null,
  holiday: null,
}) {}
