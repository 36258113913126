import { getCustomMetadata } from 'data/reporting/selectors/getCustomMetadata';
import type {
  CustomMetadataState,
  CustomMetadataStateByDatasetByAccountId,
} from 'data/reporting/state/CustomMetadataState';
import { getReportAccountId } from 'reporting/selectors/getReportAccountId';

import { createSelector } from '@reduxjs/toolkit';
import { Map } from 'immutable';

// Convenience selector to return a map of the total rows per day by dataset (for the current getReportAccountId).
// Used on the ReportTypeDialog to show hints to the user when there are no rows available for a given dataset.
export const getTotalRowsPerDayByDataset = createSelector(
  getCustomMetadata,
  getReportAccountId,
  (customMetadata: CustomMetadataStateByDatasetByAccountId, reportAccountId) => {
    const metadata = customMetadata.get(`${reportAccountId}`);
    if (!metadata) {
      return Map<string, number>();
    }
    const totals = (metadata as Map<string, CustomMetadataState>).map((customMetadataState: CustomMetadataState) => {
      const { rowsPerDay } = customMetadataState;
      return rowsPerDay.reduce((acc, reportMetadataRowPerDay) => acc + reportMetadataRowPerDay.count, 0);
    });

    return totals as unknown as Map<string, number>;
  },
);
