import type { FetchAbsencesResponse } from 'data/absence/actions/fetchAbsence';
import type Absence from 'data/absence/model';
import Scheduling from 'shared/api/scheduling';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

export const deleteAbsence = createWiwAsyncThunk<FetchAbsencesResponse, Absence>(
  'absence/delete',
  async (absence, { rejectWithValue }) => {
    try {
      return (await Scheduling.url(`/absences/${absence.id}`).delete()) as FetchAbsencesResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
