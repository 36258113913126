export const ANNUAL_RENEWAL_WINDOW = 30;
export const DO_RENEW = 1;
export const DO_NOT_RENEW = 0;
export const MIN_UNIT_BY = 10;

export const CANCEL_REASON_OTHER = 28;
export const CANCEL_REASON_SEASONAL = 27;

export const PERIOD_COPY = {
  ANNUALLY: 'Annually',
  MONTHLY: 'Monthly',
  PER_MONTH: 'Per Month',
  PER_SEAT_PER_MONTH: ' / per seat / per month',
  PER_SEAT_PER_YEAR: ' / per seat / per year',
  PER_USER_PER_MONTH: ' / per user / per month',
  SOLD_IN_UNITS: 'Sold in groups of',
};

export const COUPON_CODE_NOTICE_ID = 'error-invalid-coupon';
