import type { ShiftFields } from 'data/shift/model';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type UnpublishShiftsArgs = ShiftFields['id'];
type UnpublishShiftsResponse = {};

export const unpublishShifts = createWiwAsyncThunk<UnpublishShiftsResponse, UnpublishShiftsArgs>(
  'shift/unpublish',
  async (shiftIds, { rejectWithValue }) => {
    try {
      return (await Monolith.url('/shifts/unpublish').post({ ids: shiftIds })) as UnpublishShiftsResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
