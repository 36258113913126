import type { TagListFields } from 'data/tag/TagList';
import Worktags from 'shared/api/worktags';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type FetchShiftTemplateTagsArgs =
  | {
      [key: string]: any;
    }
  | undefined;

type FetchShiftTemplateTagsResponse = {
  data: TagListFields[];
};

export const fetchShiftTemplateTags = createWiwAsyncThunk<FetchShiftTemplateTagsResponse, FetchShiftTemplateTagsArgs>(
  'tags/shiftTemplates/fetch',
  async (params = {}, { rejectWithValue }) => {
    try {
      return (await Worktags.url('/templates').query(params).get()) as FetchShiftTemplateTagsResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
