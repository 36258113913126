import { formatQueryString } from 'shared/util/mercury';

import { List, Map } from 'immutable';

/**
 * The fields as defined by https://catalog.wiwdata.com/#/entry/entries-f3de4e76-13db-4e42-8908-dd1348b4d520
 * Provides default values or null so that all required fields will be present in validation
 *
 * Callable function so that fields like `window.location.pathname` are retrieved at execution time.
 */
export function getDashboardInteractionDefaults() {
  return {
    action: null,
    annotations: null,
    cardComponent: null,
    dataType: null,
    dateRange: null,
    endDate: null,
    filterBy: null,
    filterEmpty: null,
    hash: window.location.hash,
    hostName: window.location.host,
    path: window.location.pathname,
    queryParams: formatQueryString(window.location.search),
    sortAsc: null,
    startDate: null,
    startDateDelta: null,
    userRole: null,
  };
}

export enum DashboardCard {
  TodaySchedule = 'todaySchedule',
  AttendanceNotices = 'attendanceNotices',
  TodaySnapshot = 'todaySnapshot',
  ShiftCoverage = 'shiftCoverage',
  LaborCost = 'laborCost',
  PayPeriodComplete = 'payPeriodComplete',
  PayPeriodForecast = 'payPeriodForecast',
  ImportantUpcomingDates = 'upcomingDates',
}

export function getDashboardCardDisplayName(card: DashboardCard, hasAttendance: boolean) {
  switch (card) {
    case DashboardCard.TodaySchedule:
      return "Today's Schedule";
    case DashboardCard.AttendanceNotices:
      return 'Attendance Notices';
    case DashboardCard.TodaySnapshot:
      return "Today's Snapshot";
    case DashboardCard.ShiftCoverage:
      return 'OpenShifts / Assigned Shifts';
    case DashboardCard.LaborCost:
      return hasAttendance ? 'Labor Cost' : 'Scheduled Labor Cost';
    case DashboardCard.PayPeriodForecast:
      return hasAttendance ? 'Pay Period Forecast' : 'Labor Trend';
    case DashboardCard.PayPeriodComplete:
      return 'Pay Period Complete';
    case DashboardCard.ImportantUpcomingDates:
      return 'Important Upcoming Dates';
  }
}

export type DashboardCardOrder = List<DashboardCard>;
export const DEFAULT_DASHBOARD_CARD_ORDER: DashboardCardOrder = List([
  DashboardCard.TodaySchedule,
  DashboardCard.AttendanceNotices,
  DashboardCard.TodaySnapshot,
  DashboardCard.ShiftCoverage,
  DashboardCard.LaborCost,
  DashboardCard.PayPeriodComplete,
  DashboardCard.PayPeriodForecast,
  DashboardCard.ImportantUpcomingDates,
]);

export type DashboardCardsEnabled = Map<DashboardCard, boolean>;
export const DEFAULT_DASHBOARD_CARDS_ENABLED: DashboardCardsEnabled = Map<DashboardCard, boolean>({
  [DashboardCard.TodaySchedule]: true,
  [DashboardCard.AttendanceNotices]: true,
  [DashboardCard.TodaySnapshot]: true,
  [DashboardCard.ShiftCoverage]: true,
  [DashboardCard.LaborCost]: true,
  [DashboardCard.PayPeriodForecast]: true,
  [DashboardCard.PayPeriodComplete]: true,
  [DashboardCard.ImportantUpcomingDates]: true,
});
