import EntityBase from 'data/EntityBase';
import { EmployeeOnboardingStatus } from 'onboarding/constants';
import type { FocusedOnboardingItems, SchedulerOnboardingItems } from 'onboarding/constants';

type OnboardingValues = {
  openGuideItem: SchedulerOnboardingItems | FocusedOnboardingItems | undefined;
  newlyCreatedShiftId: number | undefined;
  forceGuideItem: SchedulerOnboardingItems | FocusedOnboardingItems | undefined;
  employeeOnboardingStatus: EmployeeOnboardingStatus | undefined;
};

export default class OnboardingState extends EntityBase<undefined, OnboardingValues>({
  openGuideItem: undefined,
  newlyCreatedShiftId: undefined,
  forceGuideItem: undefined,
  employeeOnboardingStatus: EmployeeOnboardingStatus.Idle,
}) {}
