import PropTypes from 'prop-types';
import { PureComponent, createRef } from 'react';

import classnames from 'classnames';

export class Checkbox extends PureComponent {
  el = createRef();

  static propTypes = {
    checked: PropTypes.bool,
    label: PropTypes.any,
    labelSmall: PropTypes.string,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.oneOf(['auto'])]),
    name: PropTypes.string,
    onChange: PropTypes.func,
    newStyle: PropTypes.bool,
    indeterminate: PropTypes.bool,
    uncontrolled: PropTypes.bool,
    setFocus: PropTypes.bool,
    inline: PropTypes.bool,
    labelAddon: PropTypes.any,
  };

  static defaultProps = {
    checked: false,
    disabled: false,
    newStyle: false,
    width: false,
    onChange: () => {},
    indeterminate: false,
    setFocus: false,
  };

  componentDidMount() {
    this.setCheckboxRef(this.el.current);
    if (this.props.setFocus && this.el.current) {
      this.el.current.focus();
    }
  }

  componentDidUpdate() {
    this.setCheckboxRef(this.el.current);
  }

  setCheckboxRef = checkbox => {
    if (checkbox) {
      checkbox.indeterminate = this.props.indeterminate;
    }
  };

  render() {
    const className = classnames('form-group', 'custom-checkbox', 'custom-control', this.props.className, {
      disabled: this.props.disabled,
      newStyle: this.props.newStyle,
      'custom-control-inline': this.props.inline,
    });

    const inputClassname = classnames('form-check-input', 'custom-control-input', this.props.inputClassName);

    const checkedProps = {};
    if (this.props.uncontrolled) {
      checkedProps.defaultChecked = this.props.checked;
    } else {
      checkedProps.checked = this.props.checked;
    }

    return (
      <div className={className}>
        <input
          type="checkbox"
          className={inputClassname}
          id={this.props.name}
          data-testid={this.props.name}
          name={this.props.name}
          {...checkedProps}
          onChange={this.props.onChange}
          disabled={this.props.disabled}
          ref={this.el}
        />
        <label className="custom-control-label" htmlFor={this.props.name}>
          <div className="label-text">
            {this.props.label}
            {this.props.labelAddon}
          </div>
        </label>
        {this.props.labelSmall && <small className="form-text text-muted">{this.props.labelSmall}</small>}
      </div>
    );
  }
}

export default Checkbox;
