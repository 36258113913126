import type { AbsenceFields } from 'data/absence/model';
import type { LocationFields } from 'data/location/model';
import type { PositionFields } from 'data/position/model';
import type { ShiftFields } from 'data/shift/model';
import type { SiteFields } from 'data/site/model';
import type { SwapFields } from 'data/swap/model';
import Scheduling from 'shared/api/scheduling';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type FetchAbsencesArgs = {
  start: string;
  end: string;
  limit?: number;
  page?: number;
  includeRelatedData?: boolean;
};
export type FetchAbsencesResponse = {
  data: {
    absences: AbsenceFields[];
    shifts?: ShiftFields[];
    locations?: LocationFields[];
    positions?: PositionFields[];
    sites?: SiteFields[];
    swaps?: SwapFields[];
    more?: boolean;
    page?: number;
  };
};

export const fetchAbsences = createWiwAsyncThunk<FetchAbsencesResponse, FetchAbsencesArgs>(
  'absence/fetch',
  async (params, { rejectWithValue }) => {
    try {
      return (await Scheduling.query(params).get('/absences')) as FetchAbsencesResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);

export const fetchAbsence = createWiwAsyncThunk<FetchAbsencesResponse, number>(
  'absence/fetchById',
  async (absenceId, { rejectWithValue }) => {
    try {
      return (await Scheduling.url(`/absences/${absenceId}`).post()) as FetchAbsencesResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);

export const fetchAbsencesByShiftId = createWiwAsyncThunk<FetchAbsencesResponse, string[]>(
  'absence/fetchByShiftId',
  async (shiftIds, { rejectWithValue }) => {
    try {
      return (await Scheduling.url('/absences/shifts').post({ shiftIds })) as FetchAbsencesResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);

export const replaceAbsencesByUserId = createWiwAsyncThunk<FetchAbsencesResponse, string[]>(
  'absence/replaceAbsencesByUserId',
  async (userIds, { rejectWithValue }) => {
    try {
      return (await Scheduling.url('/absences/users').post(userIds)) as FetchAbsencesResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
