import * as Sentry from '@sentry/react';
import { Client, type Conversation, type Message } from '@twilio/conversations';
import { fromJS } from 'immutable';
import { type PropsWithChildren, createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';

import { useLDFlag } from 'data/LD/selectors/getLDFlag';
import { getAccountSettings } from 'data/account/selectors/accountSettings';
import { debounce } from 'lodash';
import { getAuthUser } from 'shared/auth/selectors';
import { usePrevious } from 'shared/hooks/usePrevious';
import { useWiwDispatch, useWiwSelector } from 'store';
import type LegacyTwilioProvider from 'workchat/TwilioProvider';
import { providerInstance as legacyProvider } from 'workchat/TwilioProvider';
import { WORKCHAT_ERRORS } from 'workchat/v2/constants';
import { authenticateWorkchat } from 'workchat/v2/store/auth/authActions';
import {
  incrementUnreadCountNonSelectedConversation,
  loadWorkchatConversationUnreadCount,
} from 'workchat/v2/store/conversations/conversationsActions';
import {
  conversationsLoadCompleted,
  decrementUnreadCountForConversationMessageDelete,
  deleteConversation,
  setUnreadCountForConversation,
  upsertConversation,
} from 'workchat/v2/store/conversations/conversationsReducer';
import { getConversationsAsList, getConversationsLoaded } from 'workchat/v2/store/conversations/conversationsSelectors';
import { loadWorkchatMessages } from 'workchat/v2/store/messages/messagesActions';
import { deleteMessage, upsertMessage } from 'workchat/v2/store/messages/messagesReducer';
import { loadWorkchatParticipantsForConversation } from 'workchat/v2/store/participants/participantsActions';
import { deleteParticipant, upsertParticipant } from 'workchat/v2/store/participants/participantsReducer';
import {
  clearInitError,
  reset,
  setConnected,
  setDisconnected,
  setInitError,
  startWorkchat,
} from 'workchat/v2/store/reducer';
import { getWorkchatAuthToken, getWorkchatInitError, getWorkchatShow } from 'workchat/v2/store/selectors';
import { loadWorkchatUsers } from 'workchat/v2/store/users/usersActions';
import { getSdkConversationObject } from 'workchat/v2/twilio-objects';

type MessageContextApi = {
  client?: Client;
  init: () => void;
};

const TwilioContext = createContext<MessageContextApi>({
  init: () => {},
});

const AUTH_RETRY_DELAY = 5000;
const CONVERSATIONS_PRELOAD_LIMIT = 20;
const MAX_INIT_ATTEMPTS = 3;

export const TwilioProvider = ({ children }: PropsWithChildren<any>) => {
  const dispatch = useWiwDispatch();
  const user = useWiwSelector(getAuthUser);
  const accountSettings = useWiwSelector(getAccountSettings);
  const token = useWiwSelector(getWorkchatAuthToken);
  const fdt837 = useLDFlag('fdt-837-work-chat-delete');
  const fdt837debug = useLDFlag('fdt-837-1-work-chat-info-logging');
  const conversations = useWiwSelector(getConversationsAsList);
  const conversationsLoaded = useWiwSelector(getConversationsLoaded);
  const initError = useWiwSelector(getWorkchatInitError);
  const [conversationsPreloaded, setConversationsPreloaded] = useState(false);

  const currWorkchatShow = useWiwSelector(getWorkchatShow);
  const prevWorkchatShow = usePrevious(currWorkchatShow);

  const [twilioClient, setTwilioClient] = useState<Client>();
  const [initRetryCount, setInitRetryCount] = useState<number>(0);

  const enableWorkchat = accountSettings.workchat.enabled && user.id > 0 && fdt837;

  const authPromiseRef = useRef<Promise<any>>();
  const timeoutRef = useRef<number | undefined>();

  /**
   * Method: Callback to signal initial conversations have been loaded.
   *
   * client.on('conversationJoined') spews all the Conversations out as soon as the client connects, once we've received them, then we can process.
   */
  const conversationsLoadedCallback = useCallback(
    debounce(() => {
      dispatch(conversationsLoadCompleted());
    }, 500),
    [],
  );

  /**
   * Method: Handle initialisation errors via Auth or Twilio Client initialisation.
   */
  const handleInitError = (err: any, errorTag: WORKCHAT_ERRORS) => {
    Sentry.captureException(err, {
      tags: {
        workchatVersion: 2,
        workchatError: errorTag,
      },
    });

    // Retry for up to 3 times, if it fails on the 3rd attempt, set initError
    if ((initRetryCount + 1) % MAX_INIT_ATTEMPTS === 0) {
      return dispatch(setInitError('Client was unable to init!'));
    }

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = window.setTimeout(
      () => setInitRetryCount(count => count + 1),
      // Retry auth every 10 seconds
      AUTH_RETRY_DELAY,
    );
  };

  /**
   * Method: Authenticate to get a new Twilio token from WC API
   */
  const authenticate = () => {
    dispatch(clearInitError());

    if (authPromiseRef.current) {
      return;
    }

    authPromiseRef.current = dispatch(authenticateWorkchat())
      .unwrap()
      .then(resp => {
        if (resp.token) {
          localStorage.setItem('twilio_session', JSON.stringify({ token: resp.token, user_id: user.id }));
        }
      })
      .catch(err => handleInitError(err, WORKCHAT_ERRORS.failedToAuthenticate))
      .finally(() => {
        authPromiseRef.current = undefined;
      });
  };

  /**
   * Method: Loads Conversation Data: Messages, Participants, Unread Counts
   */
  const loadConversationData = (conversation: Conversation) => {
    return Promise.all([
      dispatch(loadWorkchatMessages({ conversation })).unwrap(),
      dispatch(loadWorkchatParticipantsForConversation(conversation)).unwrap(),
    ])
      .then(() => {
        return dispatch(loadWorkchatConversationUnreadCount(conversation))
          .unwrap()
          .then(async count => {
            dispatch(setUnreadCountForConversation({ conversationId: conversation.sid, count }));
          })
          .catch(err => {
            dispatch(setUnreadCountForConversation({ conversationId: conversation.sid, count: null }));
            return Promise.reject(err);
          });
      })
      .catch(err => {
        Sentry.captureException(err, {
          tags: {
            workchatVersion: 2,
            workchatError: WORKCHAT_ERRORS.failedToPreloadConversations,
          },
        });
      });
  };

  /**
   * Effect: Cleanup on dismount.
   */
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      if (authPromiseRef.current) {
        authPromiseRef.current = undefined;
      }
    };
  }, []);

  /**
   * Effect: Preload Conversations data: Messages, Participants, Unread Counts
   */
  useEffect(() => {
    if (conversationsLoaded && conversations.size > 0 && !conversationsPreloaded) {
      // Preload Conversation Data on load
      const preloadSize = Math.min(CONVERSATIONS_PRELOAD_LIMIT, conversations.size);
      const conversationObjects = [];

      for (let i = 0; i < preloadSize; i++) {
        const conversationSid = conversations.get(i)?.sid;

        if (conversationSid) {
          const conversation = getSdkConversationObject(conversationSid);
          conversationObjects.push(conversation);
        }
      }

      conversationObjects
        .reduce(
          (promiseChain, conversation) => promiseChain.then(() => loadConversationData(conversation)),
          Promise.resolve(),
        )
        .finally(() => setConversationsPreloaded(true));
    }
  }, [conversations, conversationsLoaded, conversationsPreloaded]);

  /**
   * Effect: Bootstrap WorkChat initialization process with authentication.
   */
  useEffect(() => {
    if (!enableWorkchat) {
      return;
    }

    if (!token || initRetryCount > 0) {
      authenticate();
    }
  }, [initRetryCount, token, enableWorkchat]);

  /**
   * Effect: Handle retry on show when there's been a Init error.
   */
  useEffect(() => {
    if (initError && !prevWorkchatShow && currWorkchatShow) {
      setInitRetryCount(count => count + 1);
    }
  }, [initError, currWorkchatShow, prevWorkchatShow]);

  /**
   * Effect: Handle Twilio Client initialization.
   */
  useEffect(() => {
    let client: Client;

    const cleanupFn = () => {
      client?.removeAllListeners();
      client?.shutdown();
      setTwilioClient(undefined);
    };

    if (!user || !enableWorkchat) {
      dispatch(reset());
      return cleanupFn;
    }

    if (legacyProvider) {
      (legacyProvider as LegacyTwilioProvider).stop();
    }

    if (token && user) {
      client = new Client(token, fdt837debug ? { logLevel: 'debug' } : {});

      // Initialization Events
      client.on('tokenAboutToExpire', () => authenticate());
      client.on('tokenExpired', () => authenticate());

      client.on('initialized', () => {
        setInitRetryCount(0);

        dispatch(
          startWorkchat({
            flags: fromJS({
              conversations: true,
              broadcast: user.canSupervise(),
            }),
            user,
          }),
        );

        dispatch(loadWorkchatUsers());
      });

      client.on('initFailed', err => {
        // Exclude Twilsock disconnection errors
        if (err.error?.message !== 'Twilsock has disconnected.') {
          Sentry.captureException(err, {
            tags: {
              workchatVersion: 2,
              workchatError: WORKCHAT_ERRORS.failedToInitClient,
            },
          });
        }

        dispatch(setDisconnected());
        dispatch(reset());

        client?.removeAllListeners();
        client?.shutdown();
        setConversationsPreloaded(false);

        authenticate();
      });

      client.on('connectionStateChanged', state => {
        switch (state) {
          case 'denied':
            return authenticate();
          case 'connected':
            return dispatch(setConnected());
          case 'disconnected':
          case 'connecting':
            dispatch(setDisconnected());
        }
      });

      // Conversation Events
      client.on('conversationJoined', conversation => {
        if (conversation.status === 'joined') {
          dispatch(upsertConversation(conversation));
          conversationsLoadedCallback();
        }
      });
      client.on('conversationLeft', conversation => {
        dispatch(deleteConversation(conversation.sid));
      });
      client.on('conversationRemoved', conversation => {
        dispatch(deleteConversation(conversation.sid));
      });
      client.on('conversationUpdated', update => {
        dispatch(upsertConversation(update.conversation));
      });

      // Participant Events
      client.on('participantJoined', participant => {
        dispatch(upsertParticipant(participant));
      });
      client.on('participantLeft', participant => {
        dispatch(deleteParticipant(participant));
      });
      client.on('participantUpdated', update => {
        dispatch(upsertParticipant(update.participant));
      });

      // Message Events
      client.on('messageAdded', (message: Message) => {
        dispatch(incrementUnreadCountNonSelectedConversation(message.conversation.sid));
        dispatch(upsertMessage(message));
      });
      client.on('messageUpdated', ({ message }: { message: Message }) => {
        dispatch(upsertMessage(message));
      });
      client.on('messageRemoved', message => {
        dispatch(deleteMessage(message.sid));
        dispatch(decrementUnreadCountForConversationMessageDelete(message));
      });

      setTwilioClient(client);
    }

    return cleanupFn;
  }, [token, user, enableWorkchat, fdt837debug]);

  return (
    <TwilioContext.Provider value={{ client: twilioClient, init: () => setInitRetryCount(count => count + 1) }}>
      {children}
    </TwilioContext.Provider>
  );
};

export const useTwilioProvider = () => {
  const { client, init } = useContext(TwilioContext);

  return { client, init };
};
