import {
  Button,
  type CSSVariablesResolver,
  ColorInput,
  Input,
  Menu,
  Modal,
  NumberInput,
  Overlay,
  Popover,
  Portal,
  Select,
  Tooltip,
  createTheme,
  mergeThemeOverrides,
} from '@mantine/core';
import { CalendarHeader, DatePicker, TimeInput } from '@mantine/dates';

export const getTheme = (themeName: string) => {
  switch (themeName) {
    case 'high-contrast':
      return mergeThemeOverrides(theme, highContrastTheme);
    default:
      return theme;
  }
};

export const theme = createTheme({
  primaryColor: 'primary',
  defaultRadius: 4,
  fontFamily: 'Source Sans Pro, sans-serif',
  black: '#333333',
  cursorType: 'pointer',
  colors: {
    primary: [
      '#ecf9e8',
      '#e0f3da',
      '#c0e4b7',
      '#9dd590',
      '#80c86e',
      '#6ec059',
      '#51a33d',
      '#52a53e',
      '#479335',
      '#397f28',
    ],
    red: ['#ffebee', '#fcd7da', '#eeaeb3', '#e1828a', '#d75e66', '#d14650', '#cf3945', '#b82a36', '#a4232f', '#911926'],
    orange: [
      '#fff8e6',
      '#f8efd4',
      '#efdcac',
      '#e5c97f',
      '#ddb95a',
      '#d8af41',
      '#d6aa34',
      '#bd9426',
      '#a8831d',
      '#927110',
    ],
    gold: [
      '#fffae0',
      '#fff3ca',
      '#ffe699',
      '#ffd862',
      '#ffcd36',
      '#ffc518',
      '#ffc202',
      '#e3aa00',
      '#ca9700',
      '#af8200',
    ],
  },
  other: {
    danger: '#d0404b',
    warning: '#c59a27',
    main_brand: '#51a33d',
    main_brand_alt1: '#7FDB65',
    main_brand_alt2: '#9FF487',
    main_brand_alt3: '#C9F9BB',
    background_alt1: '#F5F5F5',
    background_alt2: '#EEFDEA',
    main_border_color: '#DDDDDD',
    main_border_width: '1px',
  },
  components: {
    Button: Button.extend({
      defaultProps: {
        variant: 'filled',
        size: 'sm',
        autoContrast: true,
      },
    }),
    Tooltip: Tooltip.extend({
      defaultProps: {
        withArrow: true,
        arrowSize: 8,
        zIndex: 9999,
        openDelay: 500,
      },
    }),
    Select: Select.extend({
      defaultProps: {
        searchable: true,
        maxDropdownHeight: 200,
        selectFirstOptionOnChange: true,
        withCheckIcon: false,
      },
    }),
    Portal: Portal.extend({
      defaultProps: {
        reuseTargetNode: true,
      },
    }),
    Popover: Popover.extend({
      defaultProps: {
        zIndex: 1002,
        withArrow: true,
        arrowSize: 14,
      },
    }),
    Overlay: Overlay.extend({
      defaultProps: {
        backgroundOpacity: 0.6,
        color: '#000',
        zIndex: 1001,
      },
    }),
    InputWrapper: Input.Wrapper.extend({
      defaultProps: {
        inputWrapperOrder: ['label', 'input', 'description', 'error'],
        mb: 'md',
      },
    }),
    Modal: Modal.extend({
      defaultProps: {
        size: 'auto',
        centered: true,
        yOffset: '20px',
        zIndex: 1001,
        keepMounted: true,
      },
    }),
    ModalRoot: Modal.Root.extend({
      defaultProps: {
        size: 'auto',
        centered: true,
        yOffset: '20px',
        zIndex: 1001,
      },
    }),
    ModalCloseButton: Modal.CloseButton.extend({
      defaultProps: {
        'aria-label': 'close',
      },
    }),
    DatePicker: DatePicker.extend({
      defaultProps: {
        'data-testid': 'date-picker-calendar',
        ariaLabels: {
          nextDecade: 'Next decade',
          previousDecade: 'Previous decade',
          nextYear: 'Next year',
          previousYear: 'Previous year',
          nextMonth: 'Next month',
          previousMonth: 'Previous month',
          yearLevelControl: 'Change to decade view',
          monthLevelControl: 'Change to year view',
        },
      },
    }),
    CalendarHeader: CalendarHeader.extend({
      defaultProps: {
        'data-testid': 'date-picker-header',
      },
    }),
    NumberInput: NumberInput.extend({
      defaultProps: {
        hideControls: true,
        allowNegative: false,
        clampBehavior: 'strict',
      },
    }),
    Menu: Menu.extend({
      defaultProps: {
        withArrow: true,
        arrowSize: 14,
        position: 'bottom-end',
        radius: 'md',
        zIndex: 8000,
      },
      classNames: {
        dropdown: 'dark',
      },
    }),
    ColorInput: ColorInput.extend({
      defaultProps: {
        withEyeDropper: false,
        disallowInput: true,
        swatchesPerRow: 10,
      },
    }),
    TimeInput: TimeInput.extend({
      defaultProps: {
        withSeconds: false,
      },
    }),
  },
});

const highContrastTheme = createTheme({
  other: {
    main_border_color: '#000000',
    main_border_width: '2px',
  },
});

export const cssVariablesResolver: CSSVariablesResolver = theme => ({
  variables: {
    '--mantine-color-error': theme.other.danger,
    '--input-height': '38px',
    '--main-brand-color': theme.other.main_brand,
    '--main-brand-color-alt1': theme.other.brand_alt1,
    '--main-brand-color-alt2': theme.other.brand_alt2,
    '--main-brand-color-alt3': theme.other.brand_alt3,
    '--main-border-color': theme.other.main_border_color,
    '--main-border-width': theme.other.main_border_width,
    '--main-border': `${theme.other.main_border_width} solid ${theme.other.main_border_color}`,
    '--background-alt1': theme.other.background_alt1,
  },
  light: {
    '--mantine-color-primary-filled': '#2A8712',
    '--mantine-color-primary-filled-hover': '#3A752C',
    '--input-asterisk-color': '#33333',
    '--mantine-color-placeholder': '#757575',

    // Status Colors
    '--status-color-success': '#2A8712',
    '--status-color-alert': '#C59A27',
    '--status-color-promotion': '#FFCC33',
    '--status-color-warning': '#D0404B',
    '--status-color-warning-bg': '#F3CFD2',

    // Icon colors
    '--icon-default-color': '#999999',
    '--icon-color-reversed': '#FFFFFF',
    '--icon-color-alt1': '#333333',
    '--icon-color-alt2': '#cccccc',
    '--icon-color-green': theme.other.main_brand,
    '--icon-color-orange': '#C59A27',
    '--icon-color-red': '#D0404B',

    // Menu colors
    '--menu-item-color': '#c5c5c5',
    '--menu-item-hover-color': '#ffffff',
  },
  dark: {
    '--mantine-color-body': '#333333',
    '--mantine-color-primary-filled': '#89EC71',
    '--mantine-color-primary-filled-hover': '#AEFF9A',

    // Status Colors
    '--status-color-success': '#89EC71',
    '--status-color-alert': '#FFCE4B',
    '--status-color-promotion': '#FFFB77',
    '--status-color-warning': '#FF7373',
    '--status-color-warning-bg': '#86141',

    // Icon colors
    '--icon-default-color': '#BBBBBB',
    '--icon-color-reversed': '#333333',
    '--icon-color-alt1': '#FFFFFF',
    '--icon-color-alt2': '#777777',
    '--icon-color-green': '#89EC71',
    '--icon-color-orange': '#FFCEB4',
    '--icon-color-red': '#FF7373',
  },
});
