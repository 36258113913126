import { lazy } from 'react';

import { closeDialog, openDialog } from 'dialogs/actions';

const UserDeleteDialog = lazy(() => import('./UserDelete'));
const UserEditorDialog = lazy(() => import('./UserEditor'));
const UserApproveDialog = lazy(() => import('./UserApprove'));
const UserImportDialog = lazy(() => import('./UserImport'));
const UserTerminationDialog = lazy(() => import('./UserTerminationDialog'));
const UserRestoreDialog = lazy(() => import('./UserRestoreDialog'));
const UnarchiveAdminRoleChangerDialog = lazy(() => import('./UnarchiveAdminRoleChangerDialog'));
const ExistingEarningsDialog = lazy(() => import('./ExistingEarnings/ExistingEarningsDialog'));

// Identifiers for each dialog
export const USER_DELETE_DIALOG = 'USER_DELETE_DIALOG';
export const USER_EDIT_DIALOG = 'USER_EDIT_DIALOG';
export const USER_MERGE_DIALOG = 'USER_MERGE_DIALOG';
export const USER_IMPORT_DIALOG = 'USER_IMPORT_DIALOG';
export const USER_TERMINATION_DIALOG = 'USER_TERMINATION_DIALOG';
export const USER_RESTORE_DIALOG = 'USER_RESTORE_DIALOG';
export const UNARCHIVE_ADMIN_ROLE_CHANGER_DIALOG = 'UNARCHIVE_ADMIN_ROLE_CHANGER_DIALOG';
export const EXISITNG_EARNINGS_DIALOG = 'EXISTING_EARNINGS_DIALOG';

// Actions for opening dialogs
export const openUserDeleteDialog = openDialog.bind(null, USER_DELETE_DIALOG);
export const closeUserDeleteDialog = closeDialog.bind(null, USER_DELETE_DIALOG);
export const openUserEditDialog = openDialog.bind(null, USER_EDIT_DIALOG);
export const openUserTerminationDialog = openDialog.bind(null, USER_TERMINATION_DIALOG);
export const openUserRestoreDialog = openDialog.bind(null, USER_RESTORE_DIALOG);

export default {
  [USER_DELETE_DIALOG]: UserDeleteDialog,
  [USER_EDIT_DIALOG]: UserEditorDialog,
  [USER_MERGE_DIALOG]: UserApproveDialog,
  [USER_IMPORT_DIALOG]: UserImportDialog,
  [UNARCHIVE_ADMIN_ROLE_CHANGER_DIALOG]: UnarchiveAdminRoleChangerDialog,
  [USER_TERMINATION_DIALOG]: UserTerminationDialog,
  [USER_RESTORE_DIALOG]: UserRestoreDialog,
  [EXISITNG_EARNINGS_DIALOG]: ExistingEarningsDialog,
};
