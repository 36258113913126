import { lazy } from 'react';

const CalendarSyncDialog = lazy(() => import('./CalendarSyncDialog'));
const ShiftInfoDialog = lazy(() => import('./ShiftInfoDialog'));
const SplitShitDialog = lazy(() => import('./SplitShiftDialog'));

// Identifiers for each dialog
export const CALENDAR_SYNC_DIALOG = 'CALENDAR_SYNC_DIALOG';
export const SHIFT_INFO_DIALOG = 'SHIFT_INFO_DIALOG';
export const SPLIT_SHIFT_DIALOG = 'SPLIT_SHIFT_DIALOG';

const dialogs = {
  [CALENDAR_SYNC_DIALOG]: CalendarSyncDialog,
  [SHIFT_INFO_DIALOG]: ShiftInfoDialog,
  [SPLIT_SHIFT_DIALOG]: SplitShitDialog,
  // Add more here ...
};

export default dialogs;
