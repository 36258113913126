import { useEffect } from 'react';

import type User from 'data/user/model';
import FontIcon from 'shared/ui/FontIcon';
import { useWiwDispatch, useWiwSelector } from 'store';
import { track } from 'workchat/actions';
import BigParticipantListV2 from 'workchat/v2/components/BigParticipantListV2';
import HeaderV2, { type HeaderButton } from 'workchat/v2/components/HeaderV2';
import { setMessageReaders, viewStackPop } from 'workchat/v2/store/reducer';
import { getMessageReaders } from 'workchat/v2/store/selectors';
import { getWorkchatUsers } from 'workchat/v2/store/users/usersSelectors';

import 'workchat/styles/ViewReceipts.scss';

export const VIEW_RECEIPTS = 'VIEW_RECEIPTS';

export default function ViewReceiptsV2() {
  const dispatch = useWiwDispatch();
  const users = useWiwSelector(getWorkchatUsers);
  const readersById = useWiwSelector(getMessageReaders);
  const readersAsUsers = readersById?.map(readerId => users.get(readerId)).filter(user => !!user) as User[];

  useEffect(() => {
    dispatch(track('WorkChat: Viewed Read Receipts'));
  }, []);

  const buttons: HeaderButton[] = [
    {
      title: 'Back',
      action: () => {
        dispatch(setMessageReaders([]));
        dispatch(viewStackPop());
      },
      label: [<FontIcon className="mr-1" key="back" icon="chevron-left" />, 'Back'],
      side: 'left',
      color: 'secondary',
    },
  ];

  return (
    <div className="view-receipts">
      <HeaderV2 buttons={buttons}>Read By</HeaderV2>
      <div className="row no-gutters participants-list">
        <BigParticipantListV2 users={readersAsUsers} />
      </div>
    </div>
  );
}
