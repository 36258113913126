import type { BenefitItem } from 'data/payrollBenefits/model';
import Payroll from 'shared/api/payroll';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';

import { createAsyncThunk } from '@reduxjs/toolkit';

type fetchPayrollBenefitsRequest = {
  user_id: number;
};

type PayrollBenefitResponse = {
  benefits: BenefitItem[];
};

export const fetchPayrollBenefits = createAsyncThunk<PayrollBenefitResponse, fetchPayrollBenefitsRequest>(
  'benefits/fetch',
  async (params, { rejectWithValue }) => {
    try {
      return (await Payroll.query(params).get('/benefits')) as PayrollBenefitResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
