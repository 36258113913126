import { updateUserMetadata } from 'data/metadata/actions';
import { SCHEDULER_FILTER_METADATA } from 'data/metadata/keys';
import parseJSON from 'data/parseJSON';
import { FILTER_SHIFT } from 'scheduler/util/filters';
import { EMPLOYEE_VIEW, WEEK_RANGE } from 'scheduler/util/view';
import areValuesDifferent from 'shared/util/areValuesDifferent';
import { HYDRATE_FILTERS_FROM_METADATA } from 'store/action-types';

import { Map } from 'immutable';
import { omit, pick } from 'lodash';

export const SCHEDULER_FILTER_DEFAULTS = {
  hideUnscheduledEmployees: false,
  openShiftsCollapsed: false,
  range: WEEK_RANGE,
  shiftBackground: FILTER_SHIFT,
  showFilteredEmployees: false,
  sidebarVisible: true,
  filters: {
    location: 0,
    positions: [],
    sites: [],
    tags: [],
    user: -1,
  },
  view: EMPLOYEE_VIEW,
  locationSort: {},
  highlightUnconfirmedShifts: false,
};

export function syncFiltersToMetadata() {
  return (dispatch, getState) => {
    const state = getState();
    const schedulerLocalData = state.scheduler;
    const schedulerMetaData = state.data.metadata.getIn(['user', 'scheduler'], {});

    const dataToPersist = pick(schedulerLocalData.toJS(), [
      'range',
      'shiftBackground',
      'sidebarVisible',
      'filters',
      'view',
      'locationSort',
      'highlightUnconfirmedShifts',
      'forecastTools',
    ]);

    if (areValuesDifferent(dataToPersist, schedulerMetaData, ['updatedAt'])) {
      return dispatch(updateUserMetadata({ [SCHEDULER_FILTER_METADATA]: dataToPersist }));
    }
  };
}

export const syncFiltersFromMetadata = metadata => {
  return (dispatch, getState) => {
    const userData = metadata.user;
    const schedulerLocalData = pick(getState().scheduler?.toJS(), [
      'range',
      'shiftBackground',
      'sidebarVisible',
      'filters',
      'view',
      'locationSort',
      'highlightUnconfirmedShifts',
      'forecastTools',
    ]);

    const action = {
      type: HYDRATE_FILTERS_FROM_METADATA,
      payload: null,
    };

    const schedulerMetadata = parseJSON(userData[SCHEDULER_FILTER_METADATA]);
    //we are no longer using filter.user from meta data service.
    if (schedulerMetadata?.filters) {
      schedulerMetadata.filters = omit(schedulerMetadata.filters, ['user']);
    }

    if (
      !userData ||
      !schedulerMetadata ||
      typeof schedulerMetadata !== 'object' ||
      Object.keys(schedulerMetadata).length === 0
    ) {
      return dispatch(action);
    }

    // const filters = getMomentDate(filtersUpdatedAt);
    if (areValuesDifferent(schedulerLocalData, schedulerMetadata, ['updatedAt'])) {
      action.payload = schedulerMetadata;
      // This avoids undefined errors further down the line
      action.payload.filters = new Map({
        ...SCHEDULER_FILTER_DEFAULTS.filters,
        ...action.payload.filters,
      });
    }

    return dispatch(action);
  };
};
