import PropTypes from 'prop-types';
import { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { updateNotificationRequest } from 'data/notifications/actions';
import Notification from 'data/notifications/model';
import Request from 'data/request/model';
import User from 'data/user/model';
import { errorNotice, successNotice } from 'notices/actions';
import { ActionButton } from 'notifications/components/ActionButton';
import { NotifyItem } from 'notifications/components/NotifyItem';
import { formatUserTime } from 'scheduler/util/formats';
import { request } from 'shared/auth/request';
import { getAuthUser } from 'shared/auth/selectors';
import AvatarImg from 'shared/ui/AvatarImg';

import { bindActionCreators } from '@reduxjs/toolkit';

export class TimeoffRequest extends Component {
  state = {
    deleted: null,
  };

  static propTypes = {
    request: PropTypes.instanceOf(Request).isRequired,
    notification: PropTypes.instanceOf(Notification),
    currentUser: PropTypes.instanceOf(User).isRequired,
    user: PropTypes.instanceOf(User),
    creator: PropTypes.instanceOf(User),
    errorNotice: PropTypes.func.isRequired,
    successNotice: PropTypes.func.isRequired,
    updateNotificationRequest: PropTypes.func.isRequired,
  };

  approveRequest = e => {
    e.stopPropagation();

    const entry = this.props.request.set('user_status', Request.Status.ACCEPTED);
    return request()
      .put(`/requests/${entry.id}`, entry.toJSON())
      .then(data => {
        this.props.successNotice('Request has been approved successfully.');
        this.setState({ deleted: data.request });
        return data.request;
      })
      .catch(err => {
        this.props.errorNotice(
          'There was an error approving the request. Please make sure you have permission to do this.',
        );
        return err;
      });
  };

  cancelRequest = e => {
    e.stopPropagation();

    const entry = this.props.request.set('user_status', Request.Status.CANCELED);
    //request() is not an action to allow animations to finish before the store is updated.
    //Changes to the store are handled inelegantly by the react-virtulized Grid.
    return request()
      .put(`/requests/${entry.id}`, entry.toJSON())
      .then(data => {
        this.props.successNotice('Request has been denied successfully.');
        this.setState({ deleted: data.request });
        return data.request;
      })
      .catch(err => {
        this.props.errorNotice(
          'There was an error denying the request. Please make sure you have permission to do this.',
        );
        return err;
      });
  };

  getActions() {
    const user = this.props.user;
    const currentUser = this.props.currentUser;

    if (user && user.id !== currentUser.id && (!user.isSupervisor() || currentUser.canManage())) {
      return [
        <ActionButton key="approve" title="Approve" icon="fa-check-circle" type="green" click={this.approveRequest} />,
        <ActionButton key="deny" title="Deny" icon="fa-times-circle" type="red" click={this.cancelRequest} />,
      ];
    }
  }

  getDescription() {
    const request = this.props.request;
    const start = request.date('start_time');
    const end = request.date('end_time');
    if (request.allDay()) {
      //For full day requests we are going to round to noon
      const noonStart = request.noonStart.format('ddd, MMMM D');
      const noonEnd = request.noonEnd.format('ddd, MMMM D');

      if (request.isMultiDay) {
        return (
          <span>
            {' '}
            requested <strong>Time Off</strong> from {noonStart} to {noonEnd}
          </span>
        );
      }
      return (
        <span>
          {' '}
          requested <strong>Time Off</strong> for {noonStart}
        </span>
      );
    }
    return (
      <span>
        {' '}
        requested <strong>Time Off</strong> on {start.format('ddd, MMMM D')} from {formatUserTime(start, false)} to{' '}
        {formatUserTime(end, false)}
      </span>
    );
  }

  getMessageDate() {
    const request = this.props.request;
    const start = request.date('start_time');
    const end = request.date('end_time');
    const noonStart = request.noonStart.format('MMMM D');
    const noonEnd = request.noonEnd.format('MMMM D');

    if (request.allDay()) {
      if (request.isMultiDay) {
        return (
          <Fragment>
            {' '}
            from <strong>{noonStart}</strong> to <strong>{noonEnd}</strong>
          </Fragment>
        );
      }
      return (
        <Fragment>
          {' '}
          for <strong>{noonStart}</strong>
        </Fragment>
      );
    }
    return (
      <Fragment>
        from <strong>{formatUserTime(start, false)}</strong>
        {' to '}
        <strong>{formatUserTime(end, false)}</strong>
        {' on '}
        <strong>{start.format('MMMM D')}</strong>
      </Fragment>
    );
  }

  static getActionName(action) {
    switch (action) {
      case Notification.Actions.CANCEL:
        return 'canceled';
      case Notification.Actions.APPROVE:
        return 'approved';
      default:
        return '';
    }
  }

  getMessage() {
    const user = this.props.user;
    const notification = this.props.notification;
    const request = this.props.request;
    const isOwner = this.props.currentUser.id === request.user_id;
    switch (notification.action) {
      case Notification.Actions.CREATE:
        if (!isOwner && user) {
          return (
            <span>
              {' '}
              created time off for {user.first_name} {this.getMessageDate()}
            </span>
          );
        }
        return <span> requested time off {this.getMessageDate()}</span>;
      case Notification.Actions.CANCEL:
      case Notification.Actions.APPROVE:
        if (isOwner) {
          return (
            <span>
              {' '}
              {TimeoffRequest.getActionName(notification.action)} your time off request {this.getMessageDate()}
            </span>
          );
        }
        return (
          <span>
            {' '}
            {TimeoffRequest.getActionName(notification.action)} {user ? `${user.first_name}'s` : 'a'} time off request{' '}
            {this.getMessageDate()}
          </span>
        );
    }
  }

  getStatus() {
    const request = this.props.request;
    switch (request.status) {
      case Request.Status.PENDING:
        return { name: 'Pending', style: '' };
      case Request.Status.CANCELED:
        return { name: request.canceled_by === request.user_id ? 'Canceled' : 'Denied', style: 'error' };
      case Request.Status.ACCEPTED:
        return { name: 'Accepted', style: 'success' };
      case Request.Status.EXPIRED:
        return { name: 'Expired', style: 'error' };
      default:
        return {};
    }
  }

  getAvatar() {
    if (this.props.notification && this.props.creator) {
      return <AvatarImg user={this.props.creator} size={Notification.AVATAR_SIZE} />;
    }
    if (!this.props.notification && this.props.user) {
      return <AvatarImg user={this.props.user} size={Notification.AVATAR_SIZE} />;
    }
  }

  getTitle() {
    if (this.props.notification && this.props.creator) {
      return this.props.creator.id === this.props.currentUser.id ? 'You' : this.props.creator.shortName;
    }
    if (!this.props.notification && this.props.user) {
      return this.props.user.shortName;
    }
  }

  redirect = () => {
    window.location.assign(`/requests/time-off/${this.props.request.id}`);
  };

  removeRequest = () => {
    if (this.state.deleted) {
      this.props.updateNotificationRequest(this.state.deleted);
    }
  };

  render() {
    return (
      <NotifyItem
        title={this.getTitle()}
        description={this.props.notification ? this.getMessage() : this.getDescription()}
        tag={this.getStatus()}
        avatar={this.getAvatar()}
        click={this.redirect}
        {...(this.props.notification
          ? {}
          : {
              actions: this.getActions(),
            })}
        deleted={!!this.state.deleted}
        remove={this.removeRequest}
      />
    );
  }
}

export default connect(
  state => ({
    currentUser: getAuthUser(state),
  }),
  dispatch =>
    bindActionCreators(
      {
        errorNotice,
        successNotice,
        updateNotificationRequest,
      },
      dispatch,
    ),
)(TimeoffRequest);
