import { SHOW_UNSCHEDULED_JOBSITES } from 'scheduler/util/cookies';
import { TOGGLE_SHOW_UNSCHEDULED_JOBSITES } from 'store/action-types';

import Cookie from 'js-cookie';

export const toggleHideUnscheduledJobSites = value => {
  Cookie.set(SHOW_UNSCHEDULED_JOBSITES, value);

  return {
    type: TOGGLE_SHOW_UNSCHEDULED_JOBSITES,
    payload: value,
  };
};
