import BaseModel from 'data/BaseModel';

export interface ShiftBreakFields {
  id: number | null;
  timeId: number | null;
  creatorId: number | null;
  type: 1 | 2 | null;
  length: number;
  lengthStartDay: number;
  lengthEndDay: number;
  isPunched: boolean | null;
  start: string | null;
  end: string | null;
  autoDeducted: boolean;
  roundedLength: number;
  isOrphaned: boolean;
  scheduledBreakId?: number;
}

export class ShiftBreak extends BaseModel<ShiftBreakFields>({
  id: null,
  timeId: null,
  creatorId: null,
  type: null,
  length: 0,
  lengthStartDay: 0,
  lengthEndDay: 0,
  isPunched: null,
  start: null,
  end: null,
  autoDeducted: false,
  roundedLength: 0,
  isOrphaned: false,
  scheduledBreakId: undefined,
}) {
  static Type = {
    UNPAID: 2,
    PAID: 1,
  } as const;

  isIncomplete() {
    return this.isPunched && !this.end;
  }

  isSplit() {
    return this.lengthStartDay || this.lengthEndDay;
  }
}

export type ShiftBreakType = (typeof ShiftBreak.Type)[keyof typeof ShiftBreak.Type];
