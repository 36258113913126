import type Tag from 'data/tag/Tag';
import Worktags from 'shared/api/worktags';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type DeleteTagResponse = {};

export const deleteTag = createWiwAsyncThunk<DeleteTagResponse, Tag>(
  'tags/delete',
  async (tag, { rejectWithValue }) => {
    try {
      return (await Worktags.url(`/tags/${tag.id}`).delete()) as DeleteTagResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);

type DeleteTagsResponse = {};

export const deleteTags = createWiwAsyncThunk<DeleteTagsResponse, Tag[]>(
  'tags/deleteMulti',
  async (tags, { rejectWithValue }) => {
    try {
      const ids = tags.map(tag => tag.id).join(',');
      return (await Worktags.url(`/tags/${ids}`).delete()) as DeleteTagsResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
