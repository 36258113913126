import Login from 'shared/api/login';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';

export type Method = 'email-code' | 'password' | 'authorized-impersonator' | 'google-sso' | 'apple-sso';

interface Scope {
  expires: number; // seconds
  maxLifetime: number; // seconds
  scope: string;
}

interface StatusResponse {
  methods: Method[];
  scopes: Scope[];
}

export async function fetchStepUpAuthStatus(): Promise<StatusResponse> {
  try {
    return await Login.url('/step-up/status').get();
  } catch (error) {
    throw parseAPIError(error);
  }
}
