import { getCustomMetadata } from 'data/reporting/selectors/getCustomMetadata';
import type { CustomMetadataState } from 'data/reporting/state/CustomMetadataState';
import { getReportAccountId } from 'reporting/selectors/getReportAccountId';
import { getReportDataset } from 'reporting/selectors/getReportDataset';

import { createSelector } from '@reduxjs/toolkit';

export const getReportMetadataLoading = createSelector(
  getCustomMetadata,
  getReportAccountId,
  getReportDataset,
  (metadata, reportAccountId, dataset) => {
    return !!(metadata.getIn([reportAccountId, dataset]) as CustomMetadataState)?.loading;
  },
);
