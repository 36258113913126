import { lazy } from 'react';

const AddGustoEmployees = lazy(() => import('./AddEmployees'));
const ExportGusto = lazy(() => import('./ExportGusto'));
const MatchEmployees = lazy(() => import('./MatchEmployees'));

export const ADD_GUSTO_EMPLOYEES = 'ADD_GUSTO_EMPLOYEES';
export const EXPORT_GUSTO_DIALOG = 'EXPORT_GUSTO_DIALOG';
export const MATCH_GUSTO_EMPLOYEES = 'MATCH_GUSTO_EMPLOYEES';
export const POSITION_HOUR_DIALOG = 'POSITION_HOUR_DIALOG';

const dialogs = {
  [ADD_GUSTO_EMPLOYEES]: AddGustoEmployees,
  [EXPORT_GUSTO_DIALOG]: ExportGusto,
  [MATCH_GUSTO_EMPLOYEES]: MatchEmployees,
};

export default dialogs;
