import moment from 'moment-timezone';

export default function isDate(values, value, formatString) {
  if (!value) {
    return true;
  }

  const date = moment(value, formatString, true);

  return date.isValid();
}
