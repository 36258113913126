import { getOpenShiftRequests } from 'data/openshiftRequests/selectors';
import { request } from 'shared/auth/request';
import { FETCH_OPENSHIFT_REQUESTS } from 'store/action-types';

export function fetchOpenShiftRequestFailed(error) {
  return {
    type: FETCH_OPENSHIFT_REQUESTS.FAILURE,
    payload: error,
  };
}

function fetchOpenShiftRequestRequested() {
  return {
    type: FETCH_OPENSHIFT_REQUESTS.REQUEST,
  };
}

export function fetchOpenShiftRequestFulfilled(data, page) {
  return {
    type: FETCH_OPENSHIFT_REQUESTS.SUCCESS_PAGE,
    payload: data.openshiftapprovalrequests,
    page: page,
  };
}

function fetchOpenShiftRequestSuccess() {
  return {
    type: FETCH_OPENSHIFT_REQUESTS.SUCCESS,
  };
}

function fetchOpenShiftRequestsPage(params = {}, page = 0) {
  return dispatch => {
    return request()
      .get('/openshiftapprovalrequests', { query: { ...params, page: page } })
      .then(response => {
        dispatch(fetchOpenShiftRequestFulfilled(response, page));
        if (response.more) {
          return dispatch(fetchOpenShiftRequestsPage(params, page + 1));
        }
      });
  };
}

export function fetchOpenShiftRequests(params = {}) {
  return dispatch => {
    dispatch(fetchOpenShiftRequestRequested());
    return dispatch(fetchOpenShiftRequestsPage(params))
      .then(() => dispatch(fetchOpenShiftRequestSuccess()))
      .catch(error => dispatch(fetchOpenShiftRequestFailed(error.body)));
  };
}

function shouldFetchOpenShifts(state) {
  if (!state.data.openShiftRequests.loaded) {
    return true;
  }

  const openshifts = getOpenShiftRequests(state);
  if (!openshifts.size) {
    return true;
  }

  return false;
}

export function fetchOpenShiftsIfNeeded(params = {}) {
  return (dispatch, getState) => {
    if (shouldFetchOpenShifts(getState())) {
      return dispatch(fetchOpenShiftRequests(params));
    }

    return Promise.resolve();
  };
}
