import type { BalancesResponse } from 'data/timeOffAccrual/types/common';
import type { DataResponse, QueryString } from 'data/types';
import Scheduling from 'shared/api/scheduling';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

export const fetchBalances = createWiwAsyncThunk<DataResponse<BalancesResponse>, QueryString>(
  'timeOff/balances/fetchBalances',
  async (params = {}, { rejectWithValue }) => {
    try {
      return await Scheduling.query(params).get('/timeoff/balances');
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
