import type { PayloadAction } from '@reduxjs/toolkit';
import { get } from 'lodash';

export default <T>(action: PayloadAction<T>, field: keyof T, alias?: string): PayloadAction<any> => {
  const newAction = { ...action };
  if (alias) {
    // @ts-ignore
    newAction.payload[alias] = get(action.payload, field);
  } else {
    newAction.payload = get(action.payload, field);
  }
  return newAction;
};
