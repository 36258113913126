import { lazy } from 'react';

const ExportQBODialog = lazy(() => import('./ExportQBODialog'));

export const EXPORT_QBO_DIALOG = 'EXPORT_QBO_DIALOG';

const dialogs = {
  [EXPORT_QBO_DIALOG]: ExportQBODialog,
  // Add more here ...
};

export default dialogs;
