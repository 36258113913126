import PropTypes from 'prop-types';
import { Component } from 'react';
import ToggleSwitch from 'react-toggle';

import classnames from 'classnames';
import { propTypes, withFormsy } from 'formsy-react';

export class ValidatedToggle extends Component {
  static propTypes = {
    ...propTypes,
    ariaLabel: PropTypes.string, // if present, overrides label prop
    className: PropTypes.string, // input class name
    classNames: PropTypes.string, // fieldset class name
    controlledToggle: PropTypes.bool,
    defaultChecked: PropTypes.bool,
    disabled: PropTypes.bool,
    handleChange: PropTypes.func,
    icons: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    label: PropTypes.any,
    name: PropTypes.string.isRequired,
    newStyle: PropTypes.bool,
    onChange: PropTypes.func,
    setValue: PropTypes.func,
    validatePristine: PropTypes.bool,
    value: PropTypes.any,
    width: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.oneOf(['auto'])]),
    tooltipText: PropTypes.string,
    tooltipPosition: PropTypes.string,
  };

  static defaultProps = {
    controlledToggle: false,
    defaultChecked: false,
    handleChange: null,
    icons: false,
    newStyle: false,
    onChange: () => {},
    validatePristine: false,
    value: false,
    width: false,
    tooltipText: '',
    tooltipPosition: 'top',
  };

  handleChange = e => {
    if (!this.props.controlledToggle) {
      this.props.setValue(e.target.checked);
    }
    this.props.onChange(e.target.name, e.target.checked);
  };

  renderSwitch() {
    const checked = !!(this.props.value || this.props.defaultChecked);

    return (
      <ToggleSwitch
        className={classnames(this.props.className)}
        name={this.props.name}
        checked={checked}
        icons={
          this.props.icons ||
          (this.props.newStyle && {
            checked: 'ON',
            unchecked: 'OFF',
          })
        }
        aria-label={this.props.ariaLabel || typeof this.props.label === 'string' ? this.props.label : ''}
        onChange={this.props.handleChange || this.handleChange}
        disabled={this.props.isFormDisabled || this.props.disabled}
        // makes it easy to "click" a toggle in tests
        data-testid={this.props.name}
      />
    );
  }

  switchTooltipWrapper(switchComponent) {
    if (this.props.tooltipText === '') {
      return switchComponent;
    }
    return (
      <div className={`hint hint--${this.props.tooltipPosition}`} aria-label={this.props.tooltipText}>
        {switchComponent}
      </div>
    );
  }

  render() {
    const className = classnames('form-group', 'validated-toggle', this.props.classNames, {
      col: this.props.width !== null && !this.props.width,
      [`col-${this.props.width}`]: this.props.width,
      'has-danger': this.props.showError && (this.props.validatePristine || !this.props.isPristine),
      newStyle: this.props.newStyle,
    });
    return (
      <fieldset className={className}>
        <label className="d-flex align-items-center unstyled mb-0">
          {this.switchTooltipWrapper(this.renderSwitch())}
          {this.props.label && <div className="ml-2">{this.props.label}</div>}
        </label>
      </fieldset>
    );
  }
}
/**
 * @deprecated please migrate to hook-form from shared/form/inputs
 */
export default withFormsy(ValidatedToggle);
