import { getAuthUser } from 'shared/auth/selectors';
import type { WiwState } from 'store';

import { createSelector } from '@reduxjs/toolkit';
import { intersection } from 'lodash';

export const usersSelector = (state: WiwState) => state.data.user;

export const getActiveUsers = createSelector([usersSelector], users =>
  users.items.filter(user => {
    return user.is_active && !user.is_deleted;
  }),
);

export const getVisibleActiveUsers = createSelector([getActiveUsers, getAuthUser], (activeUsers, currentUser) => {
  if (currentUser.canManage()) {
    return activeUsers;
  }

  return activeUsers.filter(user => !!intersection(user.locations, currentUser.locations).length);
});
