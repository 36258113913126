import qs from 'qs';
import type { WretchAddon } from 'wretch';
import type { QueryStringAddon } from 'wretch/addons';

const appendQueryParams = (wretch: WretchAddon<any>['wretch'], qp: object | string, replace: boolean) => {
  const url = wretch!._url;
  const split = url.split('?');
  let stringifyPayload = {};
  if (typeof qp === 'string') {
    stringifyPayload = { qp, ...wretch._options?.query };
  } else {
    stringifyPayload = { ...qp, ...wretch._options?.query };
  }
  const queryString = qs.stringify(stringifyPayload, { arrayFormat: 'comma' });

  if (!queryString) return replace ? split[0] : url;

  if (replace || split.length < 2) return `${split[0]}?${queryString}`;

  return `${url}&${queryString}`;
};

const queryString: WretchAddon<QueryStringAddon> = {
  wretch: {
    query(qp, replace = false) {
      return { ...this, _url: appendQueryParams(this, qp, replace) };
    },
  },
};

export default queryString;
