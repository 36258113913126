import type Location from 'data/location/model';
import type { LocationFields } from 'data/location/model';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type CreateLocationResponse = {
  location: LocationFields;
};

export const createLocation = createWiwAsyncThunk<CreateLocationResponse, Location>(
  'location/create',
  async (location, { rejectWithValue }) => {
    try {
      return (await Monolith.url('/locations').post(location.toJSON())) as CreateLocationResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
