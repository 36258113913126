import wretchAdapter from 'shared/api/adapters/wretch';
import { getAnonymousUserId } from 'shared/auth/identifiers';

import { setStepUpHeaders } from './util/setStepUpHeaders';

import type { FetchLike, WretchOptions } from 'wretch';

export const setAnonymousHeader = (next: FetchLike) => (url: string, opts: WretchOptions) => {
  if (getAnonymousUserId()) {
    // @ts-ignore
    opts.headers['W-LDAnonymousId'] = getAnonymousUserId();
  }
  return next(url, opts);
};
export default wretchAdapter(CONFIG.API_ROOT).middlewares([setAnonymousHeader, setStepUpHeaders]);
