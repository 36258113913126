import EntityBase from 'data/EntityBase';
import type { SavedReport } from 'data/reporting/models/SavedReport';

import { Map } from 'immutable';

export class SavedReportsState extends EntityBase({
  items: Map<SavedReport['id'], SavedReport>(),
  loading: false,
  loaded: false,
  receivedAt: 0,
  error: null,
}) {
  // nothing
}
