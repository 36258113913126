import { updateAccountMetadata } from 'data/metadata/actions';
import type Shift from 'data/shift/model';
import { STEP_SCHEDULING_PUBLISH_SCHEDULE } from 'onboarding/constants';
import { ONBOARDING_SCHEDULE_PUBLISHED } from 'onboarding/metadataConstants';
import { getOnboardingSchedulePublishedAccordingToMetadata } from 'onboarding/selectors';
import { track as trackOnboarding } from 'onboarding/utils/tracking';
import Monolith from 'shared/api/monolith';
import { Actions } from 'shared/experiment/constants';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { type WiwState, createWiwAsyncThunk } from 'store';

type PublishShiftResponse = {
  success: boolean;
};

export const publishShifts = createWiwAsyncThunk<PublishShiftResponse, Shift['id'][]>(
  'shift/publish',
  async (shiftIds, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = (await Monolith.url('/shifts/publish').post({ ids: shiftIds })) as PublishShiftResponse;

      if (!getOnboardingSchedulePublishedAccordingToMetadata(getState() as WiwState)) {
        dispatch(
          updateAccountMetadata({
            [ONBOARDING_SCHEDULE_PUBLISHED]: true,
          }),
        );
      }

      trackOnboarding({
        action: Actions.COMPLETED,
        currentStep: STEP_SCHEDULING_PUBLISH_SCHEDULE,
        target: 'schedulerChecklist',
      });

      return response;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
