import * as Sentry from '@sentry/react';
import type { Participant } from '@twilio/conversations';
import classnames from 'classnames';
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { getAuthAccount, getAuthUser } from 'shared/auth/selectors';
import FontIcon from 'shared/ui/FontIcon';
import { useWiwDispatch, useWiwSelector } from 'store';
import { getFailed } from 'workchat/selectors/getFailed';
import { getPending } from 'workchat/selectors/getPending';
import { getSending } from 'workchat/selectors/getSending';
import { clearError, setConversation, viewStackPop, viewStackPush } from 'workchat/v2/store/reducer';

import ErrorBanner from 'workchat/components/ErrorBanner';
import type { HeaderButton } from 'workchat/containers/Header';
import MessageLogV2 from 'workchat/v2/components/MessageLogV2';

import { useLDFlag } from 'data/LD/selectors/getLDFlag';
import Loading from 'workchat/components/Loading';
import type { ImageInput } from 'workchat/types/ImageInput';
import { VIEW_CONVERSATION_DETAIL } from 'workchat/v2/components/ConversationDetailV2';
import ConversationTitleV2 from 'workchat/v2/components/ConversationTitleV2';
import HeaderV2 from 'workchat/v2/components/HeaderV2';
import MessageInputV2 from 'workchat/v2/components/MessageInputV2';
import ParticipantsListV2 from 'workchat/v2/components/ParticipantListV2';
import ReactionPicker from 'workchat/v2/components/ReactionPicker';
import { WORKCHAT_ERRORS } from 'workchat/v2/constants';
import useReactionPicker from 'workchat/v2/hooks/useReactionPickerProvider';
import {
  advanceLastReadMessageIndex,
  loadConversationPermissions,
} from 'workchat/v2/store/conversations/conversationsActions';
import { userEndTyping, userStartTyping } from 'workchat/v2/store/conversations/conversationsReducer';
import {
  getConversationById,
  getConversationPermissionsForConversationId,
  getTypingParticipantsForConversationId,
} from 'workchat/v2/store/conversations/conversationsSelectors';
import { getMessagesForConversation } from 'workchat/v2/store/messages/messageSelectors';
import {
  loadWorkchatMessages,
  sendWorkchatMessage,
  sendWorkchatMessageWithImage,
} from 'workchat/v2/store/messages/messagesActions';
import { loadWorkchatParticipantsForConversation } from 'workchat/v2/store/participants/participantsActions';
import { getUserParticipantsForConversationId } from 'workchat/v2/store/participants/participantsSelectors';
import {
  getWorkchatConnected,
  getWorkchatError,
  getWorkchatFlags,
  getWorkchatSelectedConversationId,
} from 'workchat/v2/store/selectors';
import { conversationsSdkMap, getSdkConversationObject } from 'workchat/v2/twilio-objects';

import 'workchat/styles/ViewConversation.scss';

export type ViewConversationProps = {
  showOpenInMessenger: boolean;
};

export const VIEW_CONVERSATION = 'VIEW_CONVERSATION';

export default function ViewConversationV2({ showOpenInMessenger }: ViewConversationProps) {
  const dispatch = useWiwDispatch();
  const history = useHistory();
  const user = useWiwSelector(getAuthUser);
  const connected = useWiwSelector(getWorkchatConnected);

  const selectedConversationId = useWiwSelector(getWorkchatSelectedConversationId);
  const messages = useWiwSelector(state => getMessagesForConversation(state, selectedConversationId));
  const conversation = useWiwSelector(state => getConversationById(state, selectedConversationId));
  const users = useWiwSelector(state => getUserParticipantsForConversationId(state, conversation?.sid)) || [];
  const typingUsers = useWiwSelector(state => getTypingParticipantsForConversationId(state, selectedConversationId));
  const permissions = useWiwSelector(state =>
    getConversationPermissionsForConversationId(state, selectedConversationId),
  );
  const sendError = useWiwSelector(getWorkchatError);

  const account = useWiwSelector(getAuthAccount);
  const flags = useWiwSelector(getWorkchatFlags);
  const pending = useWiwSelector(getPending);
  const failed = useWiwSelector(getFailed);
  const sending = useWiwSelector(getSending);

  const fdt818 = useLDFlag('fdt-818-work-chat-typing-indicators');
  const reactionPicker = useReactionPicker();

  const [error, setError] = useState<string | null>(null);

  const [loaded, setLoaded] = useState(false);
  const [loadError, setLoadError] = useState(null);

  const timeoutRef = useRef<any>();
  const conversationSdkObj = conversation ? conversationsSdkMap.get(conversation.sid) : null;
  const showTypingIndicator = typingUsers.length > 0;

  const loadConversationData = useCallback(
    (conversationId: string) => {
      setLoadError(null);

      if (!loaded) {
        const promiseChain: Promise<any>[] = [
          dispatch(loadWorkchatMessages({ conversationId, pageSize: 50 })).unwrap(),
        ];

        if (!permissions) {
          promiseChain.push(dispatch(loadConversationPermissions(conversationId)).unwrap());
        }

        if (!users || users.length === 0) {
          const conversationSdkObj = getSdkConversationObject(conversationId);

          if (conversationSdkObj) {
            promiseChain.push(dispatch(loadWorkchatParticipantsForConversation(conversationSdkObj)).unwrap());
          }
        }

        Promise.all(promiseChain)
          .then(() => setLoaded(true))
          .catch(error => {
            Sentry.captureException(error, {
              tags: {
                workchatVersion: 2,
                workchatError: WORKCHAT_ERRORS.failedToViewConversation,
              },
            });
            setLoadError(error);
          });
      }
    },
    [loaded, users, permissions],
  );

  // Effect to load Conversation Permissions
  useEffect(() => {
    if (conversation?.sid) {
      loadConversationData(conversation.sid);
    }
  }, [conversation?.sid]);

  // Effect to update Last Read Message Index when necessary
  useEffect(() => {
    if (messages && conversation) {
      const lastMessage = messages.last();

      if (conversation.lastReadMessageIndex !== lastMessage?.index) {
        dispatch(advanceLastReadMessageIndex(conversation.sid, lastMessage?.index));
      }
    }
  }, [conversation?.sid, messages]);

  // Effect to dispatch Typing Indicator events
  useEffect(() => {
    let typingStartedListener: (participant: Participant) => void;
    let typingEndedListener: (participant: Participant) => void;

    if (conversationSdkObj && user) {
      // Typing Events
      if (fdt818) {
        typingStartedListener = (participant: Participant) => {
          // participant.identity is the stringified User Id
          if (participant.identity && user.id !== +participant.identity) {
            // Don't dispatch for when the current user is the participant typing
            dispatch(userStartTyping({ conversationId: participant.conversation.sid, userId: +participant.identity }));
          }
        };

        typingEndedListener = (participant: Participant) => {
          if (participant.identity && user.id !== +participant.identity) {
            // Don't dispatch for when the current user is the participant typing
            dispatch(userEndTyping({ conversationId: participant.conversation.sid, userId: +participant.identity }));
          }
        };

        conversationSdkObj.on('typingStarted', typingStartedListener);
        conversationSdkObj.on('typingEnded', typingEndedListener);
      }
    }

    return () => {
      if (conversationSdkObj && user && typingStartedListener && typingEndedListener) {
        conversationSdkObj.off('typingStarted', typingStartedListener);
        conversationSdkObj.off('typingEnded', typingEndedListener);
      }
    };
  }, [conversationSdkObj, user]);

  const handleClearError = () => {
    dispatch(clearError());
    setError(null);
  };

  const buttons = useMemo(() => {
    const buttons: HeaderButton[] = [];

    if (flags.get('conversations')) {
      buttons.push({
        title: 'Back',
        color: 'secondary',
        action: () => {
          if (conversation) {
            dispatch(setConversation(null));
          }
          dispatch(viewStackPop());
        },
        label: [<FontIcon className="mr-1" key="back" icon="chevron-left" />, 'Back'],
        side: 'left',
      });
    }

    if (conversation?.attributes.external_type !== 'account') {
      buttons.push({
        title: 'Details',
        action: () => dispatch(viewStackPush(VIEW_CONVERSATION_DETAIL)),
        label: <FontIcon icon="gear" srText={'Conversation Details'} />,
        side: 'right',
        circle: true,
        color: 'secondary',
      });
    }

    if (showOpenInMessenger) {
      buttons.push({
        title: 'Open in Messenger',
        action: () => history.push('/workchat'),
        label: <FontIcon icon="chat" srText={'Open in WorkChat Messenger'} />,
        side: 'right',
        circle: true,
        color: 'secondary',
      });
    }

    return buttons;
  }, [flags, conversation, showOpenInMessenger]);

  const sendMessage = (text: string, image: ImageInput) => {
    if (sending || !conversation) {
      return;
    }

    if (!image) {
      dispatch(sendWorkchatMessage(conversation.sid, text));
    } else {
      dispatch(sendWorkchatMessageWithImage(conversation.sid, text, image));
    }
  };

  const setStateError = (msg: string, life = 10) => {
    setError(msg);
    if (msg) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        setError(null);
      }, life * 1000);
    } else if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  const sendingAllowed = () => {
    if (conversation?.attributes.external_type === 'account') {
      const broadcast = account.settings?.workchat?.managedChannelConversationsAllowed;
      return broadcast || user.canManage();
    }

    return account.settings?.workchat?.customChannelsAllowed;
  };

  const handleTextInputChange = (value: string) => {
    if (fdt818 && value && value.length > 0) {
      conversationSdkObj?.typing();
    }
  };

  const getTypingIndicatorLabel = () => {
    if (typingUsers.length) {
      if (typingUsers.length === 1) {
        const user = users.find(user => user.id === typingUsers[0]);
        return user ? `${user.first_name} is typing...` : null;
      }
      return 'Several people are typing...';
    }
  };

  const renderConversationBody = () => {
    if (!loaded || loadError) {
      return (
        <Loading
          hideHeader
          errorLabel="Something went wrong loading the conversation"
          hasError={!!loadError}
          onErrorRetry={() => {
            if (conversation) {
              setLoadError(null);
              loadConversationData(conversation.sid);
            }
          }}
        />
      );
    }

    return (
      <Fragment>
        <ParticipantsListV2 users={users} user={user} />
        {conversation && (
          <MessageLogV2
            connected={connected}
            conversation={conversation}
            messages={messages}
            pending={pending}
            failed={failed}
            user={user}
          />
        )}
        <ReactionPicker ref={reactionPicker.bindRef} />
        <div className="conversation-footer">
          <div
            className={classnames('typing-indicator', { show: showTypingIndicator })}
            tabIndex={showTypingIndicator ? 0 : -1}
          >
            {getTypingIndicatorLabel()}
          </div>
          {sendingAllowed() ? (
            <MessageInputV2
              sending={sending}
              sendMessage={sendMessage}
              setError={setStateError}
              disabled={!connected}
              onTextInputChange={handleTextInputChange}
            />
          ) : null}
        </div>
      </Fragment>
    );
  };

  return (
    <div className="view-conversation">
      <HeaderV2 showBroadcast={false} buttons={buttons}>
        {conversation && <ConversationTitleV2 conversation={conversation} inConversation />}
      </HeaderV2>
      <ErrorBanner error={error || sendError} clearError={handleClearError} />
      {renderConversationBody()}
    </div>
  );
}
