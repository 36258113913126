import { type FloatingAxesOffsets, type FloatingPosition, Overlay, Popover, Portal } from '@mantine/core';
import classNames from 'classnames';
import { Fragment, type ReactNode, useEffect, useRef, useState } from 'react';

import 'onboarding/modules/guides/assets/styles/guide-overlay.scss';
import 'onboarding/modules/guides/assets/styles/guide-highlight.scss';

type GuideHighlightProps = {
  children: ReactNode;
  isActive: boolean;
  hideOverlay?: boolean;
  highlightId?: string;
  onHighlightClick?: () => void;
  // Popover props
  showPopover?: boolean;
  popoverTitle?: string;
  popoverText?: string;
  popoverPosition?: FloatingPosition;
  popoverOffset?: FloatingAxesOffsets;
  dismissOnOutsideClick?: boolean;
  onDismiss?: () => void;
};

const GuideHighlight = ({
  children,
  isActive,
  hideOverlay = false,
  highlightId,
  onHighlightClick,
  showPopover = false,
  popoverTitle,
  popoverText,
  popoverPosition = 'top',
  popoverOffset = { mainAxis: 10, crossAxis: 0 },
  dismissOnOutsideClick = false,
  onDismiss,
}: GuideHighlightProps) => {
  const targetRef = useRef<HTMLDivElement>(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  useEffect(() => {
    if (isActive) {
      const timer = setTimeout(() => {
        targetRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        if (showPopover) {
          setIsPopoverOpen(true);
        }
      }, 100);
      return () => clearTimeout(timer);
    }

    setIsPopoverOpen(false);
  }, [isActive, showPopover]);

  useEffect(() => {
    if (isActive && dismissOnOutsideClick) {
      const handleOutsideClick = (event: MouseEvent) => {
        if (targetRef.current && !targetRef.current.contains(event.target as Node)) {
          onDismiss?.();
          setIsPopoverOpen(false);
        }
      };

      document.addEventListener('mousedown', handleOutsideClick);
      return () => {
        document.removeEventListener('mousedown', handleOutsideClick);
      };
    }
  }, [isActive, dismissOnOutsideClick, onDismiss]);

  if (!isActive) {
    return <Fragment>{children}</Fragment>;
  }

  const handleTargetClick = () => {
    onHighlightClick?.();
    if (showPopover) {
      setIsPopoverOpen(false);
    }
  };

  const handlePopoverClose = () => {
    setIsPopoverOpen(false);
  };

  return (
    <Fragment>
      {isActive && !hideOverlay && (
        <Portal>
          <Overlay />
        </Portal>
      )}
      {showPopover ? (
        <Popover
          opened={isPopoverOpen}
          onClose={handlePopoverClose}
          position={popoverPosition}
          withArrow
          arrowSize={12}
          arrowOffset={15}
          width={350}
          shadow="md"
          offset={popoverOffset}
        >
          <Popover.Target>
            <div
              ref={targetRef}
              className={classNames('guide-highlight-target', {
                active: isActive,
                [highlightId || '']: !!highlightId,
              })}
              onClick={handleTargetClick}
            >
              {children}
            </div>
          </Popover.Target>
          <Popover.Dropdown
            className={`guide-overlay ${highlightId}-popover-dropdown highlight-popover-dropdown`}
            onClick={e => e.stopPropagation()}
          >
            {popoverTitle && <h2>{popoverTitle}</h2>}
            {popoverText && <p>{popoverText}</p>}
          </Popover.Dropdown>
        </Popover>
      ) : (
        <div
          ref={targetRef}
          className={classNames('guide-highlight-target', {
            active: isActive,
            [highlightId || '']: !!highlightId,
          })}
          onClick={handleTargetClick}
        >
          {children}
        </div>
      )}
    </Fragment>
  );
};

export default GuideHighlight;
