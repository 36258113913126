import BaseModel from 'data/BaseModel';

export const BenefitTypes = new Map<string, string>([
  ['125_medical', 'Medical'],
  ['125_vision', 'Vision'],
  ['125_dental', 'Dental'],
  ['125_disability', 'Disability'],
  ['125_accident', 'Accident'],
  ['125_cancer', 'Cancer'],
  ['125_critical_illness', 'Critical Illness'],
  ['125_hospital', 'Hospital'],
  ['125_life', 'Life'],
  ['125_medical_other', 'Medical (Other)'],
  ['401k', '401k'],
  ['403b', '403b'],
  ['457', '457'],
  ['roth_401k', 'Roth 401k'],
  ['roth_403b', 'Roth 403b'],
  ['roth_457', 'Roth 457'],
  ['fsa_medical', 'FSA Medical'],
  ['fsa_dependent_care', 'FSA Dependent Care'],
  ['hsa', 'HSA'],
  ['simple_ira', 'Simple IRA'],
]);

export interface BenefitItem {
  id: string;
  employee: string;
  company_benefit: string;
  benefit: string;
  source: string;
  period: string | null;
  description: string | null;
  effective_start: string | null;
  effective_end: string | null;
  company_contribution_amount: string | null;
  company_contribution_percent: number | null;
  company_period_amount: string | null;
  employee_contribution_amount: string | null;
  employee_contribution_percent: number | null;
  employee_period_amount: string | null;
  hsa_contribution_limit: string | null;
}

export default class BenefitRecord extends BaseModel<BenefitItem>({
  id: '',
  employee: '',
  company_benefit: '',
  benefit: '',
  source: '',
  period: null,
  description: null,
  effective_start: null,
  effective_end: null,
  company_contribution_amount: null,
  company_contribution_percent: null,
  company_period_amount: null,
  employee_contribution_amount: null,
  employee_contribution_percent: null,
  employee_period_amount: null,
  hsa_contribution_limit: null,
}) {
  companyAmount() {
    if (this.company_period_amount) {
      return `$${this.company_period_amount}`;
    }
    if (this.company_contribution_percent) {
      return `${this.company_contribution_percent}%`;
    }
    return `$${this.company_contribution_amount}`;
  }

  employeeAmount() {
    if (this.employee_period_amount) {
      return `$${this.employee_period_amount}`;
    }
    if (this.employee_contribution_percent) {
      return `${this.employee_contribution_percent}%`;
    }
    return `$${this.employee_contribution_amount}`;
  }
}
