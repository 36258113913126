import { Fragment } from 'react';

import type Account from 'data/account/model';
import type Plan from 'data/plan/model';
import type User from 'data/user/model';
import MissingEmployees from 'shared/components/nav/BulletinBoard/MissingEmployees';
import { ThirtyDayFreeTrial } from 'shared/components/nav/BulletinBoard/ThirtyDayFreeTrial';
import { TrialCountDown } from 'shared/components/nav/BulletinBoard/TrialCountDown';
import TrialReactivation from 'shared/components/nav/BulletinBoard/TrialReactivation';

type AccountNotificationsProps = {
  accountPlan: Plan;
  authAccount: Account;
  authUser: User;
};

export function AccountNotifications({ accountPlan, authAccount, authUser }: AccountNotificationsProps) {
  return (
    <Fragment>
      <TrialCountDown authUser={authUser} accountPlan={accountPlan} authAccount={authAccount} />
      <ThirtyDayFreeTrial />
      <TrialReactivation />
      <MissingEmployees
        isTrial={accountPlan.isTrial()}
        planExpires={authAccount.luxonDate('plan_expires')}
        user={authUser}
      />
    </Fragment>
  );
}
