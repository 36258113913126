import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type ConfirmShiftsArgs = {
  ids: number[];
  before: any;
};

export const confirmShifts = createWiwAsyncThunk<any, ConfirmShiftsArgs>(
  'shift/confirm',
  async ({ ids, before }, { rejectWithValue }) => {
    try {
      return await Monolith.url('/shifts/acknowledge').post({ ids, before });
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);

export function confirmShift(id: number, before: any) {
  return confirmShifts({ ids: [id], before });
}
