import { lazy } from 'react';

const RequestChangeDialog = lazy(() => import('./RequestChangeDialog'));
const OneTimeEarningsDialog = lazy(() => import('./OneTimeEarningsDialog'));
const TaxesDialog = lazy(() => import('./TaxesDialog'));
const DeductionsDialog = lazy(() => import('./DeductionsDialog'));
const ContractorEarningsDialog = lazy(() => import('./ContractorEarningsDialog'));

export const REQUEST_CHANGE_DIALOG = 'REQUEST_CHANGE_DIALOG';
export const ONE_TIME_EARNINGS_DIALOG = 'ONE_TIME_EARNINGS_DIALOG';
export const CONTRACTOR_EARNINGS_DIALOG = 'CONTRACTOR_EARNINGS_DIALOG';
export const TAXES_DIALOG = 'TAXES_DIALOG';
export const DEDUCTIONS_DIALOG = 'DEDUCTIONS_DIALOG';

const dialogs = {
  [REQUEST_CHANGE_DIALOG]: RequestChangeDialog,
  [ONE_TIME_EARNINGS_DIALOG]: OneTimeEarningsDialog,
  [TAXES_DIALOG]: TaxesDialog,
  [DEDUCTIONS_DIALOG]: DeductionsDialog,
  [CONTRACTOR_EARNINGS_DIALOG]: ContractorEarningsDialog,
};

export default dialogs;
