import { type ComponentType, type JSX, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { moveShiftTimezones } from 'data/shift/reducer';
import User from 'data/user/model';
import { updateUser } from 'data/user/reducer';
import { setCurrentDate } from 'scheduler/actions';
import { timezoneSwitchUpdate, timezoneUserUpdate } from 'scheduler/actions/timezoneSwitch';
import { getAuthUser } from 'shared/auth/selectors';
import { setGlobalTimezone } from 'shared/util/time';
import { useWiwDispatch, useWiwSelector } from 'store';

import { Map } from 'immutable';
import type { Moment } from 'moment-timezone';

/**
 * This is meant to be used at top-level layout components like MainLayout and FullPageLayout to make sure that we fix
 * up any timezone shenanigans when moving away from scheduler.
 */
export function useTimezoneSwitcherCleanup() {
  const location = useLocation();
  const dispatch = useWiwDispatch();
  const authUser = useWiwSelector(getAuthUser);
  const timezoneUser = useWiwSelector<Map<never, never> | User>(state => state.scheduler.get('timezoneUser'));
  const schedulerDate = useWiwSelector<Moment>(state => state.scheduler.get('currentDate'));

  useEffect(() => {
    // The timezone switcher is only meant to work on the scheduler. If we navigate to a non-scheduler page, we need to
    // clean up this hideous mess. If the user never switched timezones, updates to the default timezone, user, and
    // shifts should not have any adverse effects ... famous last words.
    if (!location.pathname.startsWith('/scheduler') && timezoneUser instanceof User) {
      setGlobalTimezone(timezoneUser.timezone_name);

      dispatch(
        updateUser(
          authUser.merge({ timezone_id: timezoneUser.timezone_id, timezone_name: timezoneUser.timezone_name }),
        ),
      );

      dispatch(setCurrentDate(schedulerDate.clone().tz(timezoneUser.timezone_name, true)));
      dispatch(moveShiftTimezones(timezoneUser.timezone_name));

      dispatch(timezoneSwitchUpdate(Map({ timezone_id: 0, timezone_name: '' })));
      dispatch(timezoneUserUpdate(Map()));
    }
  }, [location]);
}

/**
 * @deprecated Functionalize your component and use the hook instead.
 *
 * This is meant to be used at top-level layout components like MainLayout and FullPageLayout to make sure that we fix
 * up any timezone shenanigans when moving away from scheduler.
 */
export function withTimezoneSwitcherCleanup<TProps extends JSX.IntrinsicAttributes = any>(
  WrappedComponent: ComponentType<TProps>,
) {
  return (props: TProps) => {
    useTimezoneSwitcherCleanup();
    return <WrappedComponent {...props} />;
  };
}
