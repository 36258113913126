import { lazy } from 'react';

const LocationDialog = lazy(() => import('./LocationDialog'));
const ConfirmLocationDeleteDialog = lazy(() => import('./ConfirmLocationDeleteDialog'));

// Identifiers for each dialog
export const LOCATION_DIALOG = 'LOCATION_DIALOG';
export const LOCATION_DELETE_DIALOG = 'LOCATION_DELETE_DIALOG';

const dialogs = {
  [LOCATION_DIALOG]: LocationDialog,
  [LOCATION_DELETE_DIALOG]: ConfirmLocationDeleteDialog,
};

export default dialogs;
