import { getReportDateRange } from 'reporting/selectors/getReportDateRange';
import type { WiwState } from 'store';

import { createSelector } from '@reduxjs/toolkit';
import type { Interval } from 'luxon';

export const getCustomReportBuilderTempDateRange = (state: WiwState): Interval | undefined =>
  state.reporting.customReportBuilder.tempDateRange;

export const getReportTempDateRange = createSelector(
  [getReportDateRange, getCustomReportBuilderTempDateRange],
  (dateRange, tempDateRange) => {
    if (tempDateRange == null) {
      return dateRange;
    }

    return tempDateRange;
  },
);
