import type Position from 'data/position/model';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type DeletePositionResponse = {
  success: boolean;
};

export const deletePosition = createWiwAsyncThunk<DeletePositionResponse, Position>(
  'position/delete',
  async (position, { rejectWithValue }) => {
    try {
      return (await Monolith.url(`/positions/${position.id}`).delete()) as DeletePositionResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);

export const deletePositions = createWiwAsyncThunk<DeletePositionResponse, Position[]>(
  'location/deleteMulti',
  async (positions, { rejectWithValue }) => {
    try {
      return await Monolith.url('/positions')
        .query({
          ids: positions.map(position => position.id),
        })
        .delete();
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
