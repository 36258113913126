import BaseModel from 'data/BaseModel';

interface Avatar {
  url: string;
  size: string | number;
}

export interface NotificationFields {
  id: number | null;
  account_id: number;
  creator_id: number;
  title: string;
  message: string;
  action: string;
  user_id: number;
  request_id: number;
  swap_id: number;
  shift_id: number;
  message_id: number;
  absence_id: number;
  created_at: string | null;
  updated_at: string | null;
  object_id: number;
  avatar: Avatar;
  owner: string;
  object_type: string;
}

class Notification extends BaseModel<NotificationFields>({
  id: null,
  account_id: 0,
  creator_id: 0,
  title: '',
  message: '',
  action: '',
  user_id: 0,
  request_id: 0,
  swap_id: 0,
  shift_id: 0,
  message_id: 0,
  absence_id: 0,
  created_at: null,
  updated_at: null,
  object_id: 0,
  avatar: {} as Avatar,
  owner: '',
  object_type: '',
}) {
  static AVATAR_SIZE = 'mini' as const;

  static Actions = {
    ACCEPT: 'accept',
    APPROVE: 'approve',
    AVAILABILITY: 'availability',
    CANCEL: 'cancel',
    COMMENT: 'comment',
    CREATE: 'create',
    DECLINE: 'decline',
    DELETE: 'delete',
    UPDATE: 'update',
    WORKPROP_GAVE: 'workprops.gave',
  } as const;
}

export default Notification;
