import { lazy } from 'react';

/* istanbul ignore next */
const ReportingUpgradeDialog = lazy(() => import('shared/upsell/dialogs/ReportingUpgradeDialog'));
/* istanbul ignore next */
const ReportingUpgradePopover = lazy(() => import('shared/upsell/dialogs/ReportingUpgradePopover'));
/* istanbul ignore next */
const TimesheetAnnouncementDialog = lazy(() => import('shared/upsell/dialogs/TimesheetAnnouncementDialog'));
/* istanbul ignore next */
const OvertimeVisibilityDialog = lazy(() => import('shared/upsell/dialogs/OvertimeVisibilityDialog'));
/* istanbul ignore next */
const ForecastToolsDialog = lazy(() => import('shared/upsell/dialogs/ForecastToolsDialog'));
/* istanbul ignore next */
const ThirtyDayFreeTrialDialog = lazy(() => import('shared/upsell/dialogs/ThirtyDayFreeTrialDialog'));
/* istanbul ignore next */
const ExportsUpsellDialog = lazy(() => import('shared/upsell/dialogs/ExportsUpsellDialog'));
/* istanbul ignore next */
const WorkchatUpgradeDialog = lazy(() => import('shared/upsell/dialogs/WorkchatUpgradeDialog'));
/* istanbul ignore next */
const LimitlessSchedulingUpsellDialog = lazy(() => import('shared/upsell/dialogs/LimitlessSchedulingUpsellDialog'));

const AttendanceUpsellDialog = lazy(() => import('shared/upsell/dialogs/AttendanceUpsellDialog'));
const IntegrationsUpgradeDialog = lazy(() => import('shared/upsell/dialogs/IntegrationsUpgradeDialog'));

// Identifiers for each dialog
export const REPORTING_UPGRADE_DIALOG = 'REPORTING_UPGRADE_DIALOG';
export const REPORTING_UPGRADE_POPOVER = 'REPORTING_UPGRADE_POPOVER';
export const TIMESHEET_ANNOUNCEMENT_DIALOG = 'TIMESHEET_ANNOUNCEMENT_DIALOG';
export const OVERTIME_ANNOUNCEMENT_DIALOG = 'OVERTIME_ANNOUNCEMENT_DIALOG';
export const FORECAST_TOOLS_DIALOG = 'FORECAST_TOOLS_DIALOG';
export const THIRTY_DAY_FREE_TRIAL_DIALOG = 'THIRTY_DAY_FREE_TRIAL_DIALOG';
export const EXPORTS_UPSELL_DIALOG = 'EXPORTS_UPSELL_DIALOG';
export const WORKCHAT_UPGRADE_DIALOG = 'WORKCHAT_UPGRADE_DIALOG';
export const SCHEDULING_UPSELL_DIALOG = 'SCHEDULING_UPSELL_DIALOG';
export const ATTENDANCE_UPSELL_DIALOG = 'ATTENDANCE_UPSELL_DIALOG';

export const INTEGRATIONS_UPGRADE_DIALOG = 'INTEGRATIONS_UPGRADE_DIALOG';

const dialogs = {
  [REPORTING_UPGRADE_DIALOG]: ReportingUpgradeDialog,
  [REPORTING_UPGRADE_POPOVER]: ReportingUpgradePopover,
  [TIMESHEET_ANNOUNCEMENT_DIALOG]: TimesheetAnnouncementDialog,
  [OVERTIME_ANNOUNCEMENT_DIALOG]: OvertimeVisibilityDialog,
  [FORECAST_TOOLS_DIALOG]: ForecastToolsDialog,
  [THIRTY_DAY_FREE_TRIAL_DIALOG]: ThirtyDayFreeTrialDialog,
  [EXPORTS_UPSELL_DIALOG]: ExportsUpsellDialog,
  [WORKCHAT_UPGRADE_DIALOG]: WorkchatUpgradeDialog,
  [INTEGRATIONS_UPGRADE_DIALOG]: IntegrationsUpgradeDialog,
  [SCHEDULING_UPSELL_DIALOG]: LimitlessSchedulingUpsellDialog,
  [ATTENDANCE_UPSELL_DIALOG]: AttendanceUpsellDialog,
  // Add more here ...
};

export default dialogs;
