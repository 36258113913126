import WarningBanner from 'shared/ui/WarningBanner';
import { useWiwSelector } from 'store';
import { getConnected } from 'workchat/selectors/getConnected';

export default function DisconnectedBanner() {
  const connected = useWiwSelector(getConnected);

  if (connected) {
    return null;
  }

  return (
    <WarningBanner icon="warning" level="error" wrapperClass="disconnected-banner">
      Service unavailable, trying to reconnect...
    </WarningBanner>
  );
}
