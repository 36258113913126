import {
  type ComponentProps,
  type FocusEvent,
  Fragment,
  type ReactNode,
  type RefObject,
  createRef,
  forwardRef,
  useEffect,
  useState,
} from 'react';
import { PatternFormat } from 'react-number-format';
import type { PatternFormatProps } from 'react-number-format/types/types';

import InputWrapper from 'shared/components/form/wrappers/InputWrapper';

import classnames from 'classnames';
import { type FormsyInjectedProps, withFormsy } from 'formsy-react';
import { omit } from 'lodash';
import { UncontrolledTooltip } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';

interface Props extends FormsyInjectedProps {
  label?: any;
  labelAddon: any;
  name: string;
  className?: string;

  /**
   * These classes get applied to the `fieldset` element that wraps the input.
   */
  classNames?: string;
  width: false | number | 'auto';
  value?: string | number | any[];
  mask?: string | ((value: string) => string);
  inputmode?: 'none' | 'text' | 'decimal' | 'numeric' | 'tel' | 'search' | 'email' | 'url';
  placeholder?: string;
  inputProps?: ComponentProps<'input'> | object;
  inputRef?: RefObject<HTMLInputElement>;
  disabled: boolean;
  newStyle: boolean;
  labelSmall: false | string;
  onChange: (name: any, value: any) => void;
  onFocus: (name: any, value: any) => void;
  onBlur: (name: any, value: any) => void;
  validateOnlyOnBlur: boolean;
  validatePristine: boolean;
  tooltip: false | string;
  setFocus: boolean;
  selectOnFocus?: boolean;
  isValid: boolean;
  raw: boolean;
  patternProps: PatternFormatProps;

  errorMessage: any;
  afterAddon: ReactNode;
  options: { [key: string]: any };
}

interface DefaultProps {
  width: boolean | number | 'auto';
  disabled: boolean;
  newStyle: boolean;
  labelAddon: boolean;
  labelSmall: boolean | string;
  validateOnlyOnBlur: boolean;
  validatePristine: boolean;
  tooltip: boolean;
  setFocus: boolean;
  raw: boolean;
}

const defaultProps: DefaultProps = {
  width: false,
  disabled: false,
  newStyle: false,
  labelAddon: false,
  labelSmall: false,
  validateOnlyOnBlur: false,
  validatePristine: false,
  tooltip: false,
  setFocus: false,
  raw: false,
};

type NewProps = Omit<Props, keyof DefaultProps> & Partial<DefaultProps>;

const Input = forwardRef(function Input(props: any, ref: any) {
  const inputProps = omit(props, ['inputFocus', 'inputBlur']);
  const onFocus = (e: any) => {
    props.inputFocus?.(e);
    props.onFocus?.(e);
  };

  const onBlur = (e: any) => {
    props.inputBlur?.(e);
    props.onBlur?.(e);
  };

  return <input {...inputProps} onFocus={onFocus} onBlur={onBlur} ref={ref} />;
});

function ValidatedPatternInput(props: NewProps) {
  props = { ...defaultProps, ...props } as NewProps;
  const innerRef = createRef<HTMLInputElement | null>();
  const [focus, setFocus] = useState(false);
  const targetId = `w${uuidv4()}`;

  const getInputRef = () => {
    return props.inputRef || innerRef;
  };

  useEffect(() => {
    if (props.setFocus && innerRef.current) {
      // @ts-ignore
      innerRef.current.focus();
    }
  }, []);

  const onChange = (value: string | number | any[]) => {
    props.onChange(props.name, value);

    if (props.validateOnlyOnBlur) {
      return props.setValue(value, false);
    }

    return props.setValue(value);
  };

  const onFocus = (e: FocusEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFocus(true);
    props.onFocus?.(props.name, value);
  };

  const onBlur = (e: FocusEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFocus(false);
    props.onBlur?.(props.name, value);
  };

  const getInputId = () => {
    const { inputProps, name, label } = props;

    if (inputProps && typeof inputProps === 'object' && 'id' in inputProps) {
      return inputProps.id;
    }

    if (label) {
      //inputs with a label match for=id using name
      return name;
    }

    return undefined;
  };

  const renderInput = (invalid: boolean) => {
    const inputClassname = classnames('form-control', props.className, {
      'is-invalid': invalid,
      newStyle: props.newStyle,
    });

    let value = props.value;
    if (value === null || typeof value === 'undefined') {
      value = '';
    }

    return (
      // @ts-ignore
      <PatternFormat
        {...props.patternProps}
        className={inputClassname}
        onFocus={onFocus}
        onBlur={onBlur}
        // @ts-ignore
        value={value}
        getInputRef={getInputRef()}
        onValueChange={values => {
          onChange(values.value);
        }}
        customInput={Input}
        disabled={props.isFormDisabled || props.disabled}
        placeholder={props.placeholder}
        {...props.inputProps}
      />
    );
  };

  const invalid = props.showError || (!props.isValid && (props.validatePristine || !props.isPristine));

  return (
    <Fragment>
      <InputWrapper focus={focus} {...props} id={targetId} inputId={getInputId()}>
        {renderInput(invalid)}
      </InputWrapper>
      {props.tooltip ? (
        <UncontrolledTooltip target={targetId} placement="bottom">
          {props.tooltip}
        </UncontrolledTooltip>
      ) : null}
    </Fragment>
  );
}
/**
 * @deprecated please migrate to hook-form from shared/form/inputs
 */
export default withFormsy(ValidatedPatternInput);
