import EntityBase from 'data/EntityBase';
import type { Report } from 'data/reporting/models/Report';

import { Map } from 'immutable';

export class ReportsState extends EntityBase({
  items: Map<Report['id'], Report>(),
  loading: false,
  loaded: false,
  receivedAt: 0,
  error: null,
}) {
  // nothing
}
