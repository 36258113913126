import { getLocations } from 'data/location/selectors';
import { DEFAULT_SORT } from 'scheduler/util/constants';
import { LOCATION_SORT_FILTER, SCHEDULER_LOCATION_FILTER } from 'scheduler/util/cookies';
import { getPersistedFilter, persistFilter } from 'scheduler/util/persistFilter';
import { getUserId } from 'shared/auth/auth';
import { CHANGE_EMPLOYEE_SORT } from 'store/action-types';

import { Map } from 'immutable';
import Cookies from 'js-cookie';

export function changeSortFilter(locationId, sortOption) {
  return (dispatch, getState) => {
    const state = getState();
    const { scheduler } = state;
    const locations = getLocations(state);
    let locationSort = scheduler.get('locationSort');

    if (Map.isMap(locationSort)) {
      locationSort = locationSort.toJS();
    }

    const currentSort = locationSort[locationId]?.sort ?? DEFAULT_SORT;

    const updatedLocationSort = {};

    // WIWJS-7834 some user's metadata became malformed where the keys of the locationSort were being set to
    // immutable js's internals. This should undo that by cleansing scheduler.locationSort in the store which is then
    // used to update the user's metadata
    for (const location in locationSort) {
      if (locations.has(Number.parseInt(location, 10))) {
        updatedLocationSort[location] = locationSort[location];
      }
    }

    // Update the sort for the provided location and set the history to the old sort
    updatedLocationSort[locationId] = { sort: sortOption, sortHistory: currentSort };

    persistFilter(LOCATION_SORT_FILTER, updatedLocationSort);

    return dispatch({
      type: CHANGE_EMPLOYEE_SORT,
      payload: updatedLocationSort,
    });
  };
}

export const getKey = (locationId, userId) => `${LOCATION_SORT_FILTER}-${locationId}-${userId}`;

export const getInitialSortFilter = () => {
  const fromStorage = getPersistedFilter(LOCATION_SORT_FILTER, {});
  if (fromStorage) {
    return fromStorage;
  }
  const userId = getUserId();
  const locationId = Number.parseInt(Cookies.get(SCHEDULER_LOCATION_FILTER)) || 0;
  // Attempt to load the userId and locationId from cookie. If that exists default to
  // using that setting. Then check for the more personalized localStorage setting, if
  // that exists use that. Setting the state here should prevent some jaggy reloading
  const newCookie = Cookies.getJSON(getKey(locationId, userId));
  if (userId && locationId && newCookie) {
    return newCookie;
  }

  return {};
};
