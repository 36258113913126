import type Request from 'data/request/model';
import type { RequestFields } from 'data/request/model';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type UpdateRequestArgs = Request;

type UpdateRequestResponse = { request: RequestFields };

export const updateRequest = createWiwAsyncThunk<UpdateRequestResponse, UpdateRequestArgs>(
  'request/update',
  async (request, { rejectWithValue }) => {
    try {
      return (await Monolith.url(`/requests/${request.id}`).put(request.toJSON())) as UpdateRequestResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
