import type { StripeCustomer } from 'data/stripe/types/customer';
import Billing from 'shared/api/billing';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type LinkPaymentMethodArgs = {};

type LinkPaymentMethodResponse = {
  data: StripeCustomer;
};

export const linkPaymentMethod = createWiwAsyncThunk<LinkPaymentMethodResponse, LinkPaymentMethodArgs>(
  'stripe/customers/linkPaymentMethod',
  async (paymentMethodId, { rejectWithValue }) => {
    try {
      return (await Billing.url('/customer/payment-method').post({
        payment_method_id: paymentMethodId,
      })) as LinkPaymentMethodResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
