import PropTypes from 'prop-types';
import { Component, createRef } from 'react';
import Textarea from 'react-textarea-autosize';

import InputWrapper from 'shared/components/form/wrappers/InputWrapper';

import classnames from 'classnames';
import { propTypes, withFormsy } from 'formsy-react';

export class ValidatedTextArea extends Component {
  inputRef = createRef();

  static propTypes = {
    ...propTypes,
    label: PropTypes.any,
    name: PropTypes.string,
    rows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    cols: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    maxRows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    className: PropTypes.string,
    disabled: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.oneOf(['auto'])]),
    isValid: PropTypes.bool,
    newStyle: PropTypes.bool,
    maxLength: PropTypes.string,
    maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    counter: PropTypes.bool,
    countdown: PropTypes.bool,
    validatePristine: PropTypes.bool,
    setFocus: PropTypes.bool,
    inputProps: PropTypes.object,
  };

  static defaultProps = {
    rows: 3,
    cols: 0, // React doesnt render col if 0
    width: false,
    newStyle: false,
    onChange: () => {},
    counter: false,
    validatePristine: false,
    setFocus: false,
  };

  state = {
    focus: false,
  };

  componentDidMount() {
    if (this.props.setFocus && this.inputRef.current) {
      this.inputRef.current.focus();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.setFocus && this.props.setFocus && this.inputRef.current) {
      this.inputRef.current.focus();
    }
  }

  modifyValue = e => {
    const value = e.target.value;

    this.props.onChange(this.props.name, value);
    return this.props.setValue(value);
  };

  onBlur = e => {
    this.modifyValue(e);
    this.setState({ focus: false });

    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  };

  onFocus = e => {
    this.setState({ focus: true });

    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  };

  displayCounter() {
    if (this.props.counter) {
      return <small className="textarea-counter pull-right">{(this.props.value || '').length}</small>;
    }
    if (this.props.countdown && this.props.maxLength) {
      return (
        <small className="textarea-counter pull-right">{this.props.maxLength - (this.props.value || '').length}</small>
      );
    }
    return null;
  }

  render() {
    const invalid =
      (this.props.showError || !this.props.isValid) && (this.props.validatePristine || !this.props.isPristine);

    const inputClassname = classnames('form-control', this.props.className, {
      'form-control-danger': invalid,
    });

    return (
      <InputWrapper focus={this.state.focus} {...this.props} prepend={this.displayCounter()}>
        <Textarea
          ref={this.inputRef}
          name={this.props.name}
          cols={this.props.cols}
          minRows={this.props.rows}
          maxRows={this.props.maxRows}
          maxLength={this.props.maxLength}
          className={inputClassname}
          onChange={this.modifyValue}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          value={this.props.value || ''}
          disabled={this.props.isFormDisabled || this.props.disabled}
          placeholder={this.props.placeholder}
          {...this.props.inputProps}
        />
      </InputWrapper>
    );
  }
}
/**
 * @deprecated please migrate to hook-form from shared/form/inputs
 */
export default withFormsy(ValidatedTextArea);
