import type PayrollRecordFields from 'data/payrollCheck/models/payrollRecord';
import Payroll from 'shared/api/payroll';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

interface Params {
  id: string;
  missing_entries: number[];
  close_pay_period: boolean;
}

interface ResponseError {
  code: number;
  error: string;
}

interface SubmitResponse {
  payroll: PayrollRecordFields;
  errors?: {
    pay_period?: ResponseError[];
    entries?: ResponseError[];
  };
}

export const submitPayroll = createWiwAsyncThunk<SubmitResponse, Params>(
  'payrollCheck/submitPayroll',
  async (params, { rejectWithValue }) => {
    try {
      return await Payroll.url(`/payrolls/${params.id}/approve?close_pay_period=${params.close_pay_period}`).post({
        missing_entries: params.missing_entries,
      });
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
