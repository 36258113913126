import type Availability from 'data/availability/model';
import type { AvailabilityFields } from 'data/availability/model';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type UpdateAvailabilityArgs = Availability;

type UpdateAvailabilityResponse = {
  availabilityevents: AvailabilityFields[];
};

export const updateAvailability = createWiwAsyncThunk<UpdateAvailabilityResponse, UpdateAvailabilityArgs>(
  'availability/update',
  async (availability, { rejectWithValue }) => {
    try {
      return (await Monolith.url(`/availabilityevents/${availability.id}`).post(
        availability.toJSON(),
      )) as UpdateAvailabilityResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
