import EntityBase from 'data/EntityBase';
import { IndexedMap } from 'data/IndexedMap';
import RequestType from 'data/request-type/model';

export const defaultRequestTypeItems = new IndexedMap(RequestType, 'id', [
  'enabled',
  'is_deleted',
  'built_in',
  'isActive',
  ['isActive', 'built_in'],
]);

const stateDefaults = {
  items: defaultRequestTypeItems,
};
export default class RequestTypeState extends EntityBase<RequestType, typeof stateDefaults>(stateDefaults) {
  declare readonly items: (typeof stateDefaults)['items'];
}
