import type Shift from 'data/shift/model';
import type { TagFields } from 'data/tag/Tag';
import type { TagListFields } from 'data/tag/TagList';
import { updateShiftTagsSessionStore } from 'data/tag/actions/shiftTags/shiftTagSessionStorage';
import Worktags from 'shared/api/worktags';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

type UpdateShiftTagsArgs = {
  shift: Shift;
  tagIds: TagFields['id'][];
};

type UpdateShiftTagsResponse = {
  data: TagListFields[];
};

export const updateShiftTag = createWiwAsyncThunk<UpdateShiftTagsResponse, UpdateShiftTagsArgs>(
  'tags/shiftTags/update',
  async ({ shift, tagIds }, { rejectWithValue }) => {
    try {
      const response = await Worktags.url(`/shifts/${shift.id}`).put({
        accountId: `${shift.account_id}`,
        tags: tagIds,
        startDate: shift.date('start_time')!.toISOString(),
      });

      // Query caching in front of Dynamo can cause the tags returned by the worktags service to be inaccurate for a
      // short time following an update to a shift's tags, so add shift tag updates to session storage with an expiration
      updateShiftTagsSessionStore(`${shift.id}`, response.data);

      return response.data.id ? { data: [response.data] } : ({ data: response.data } as UpdateShiftTagsResponse);
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
