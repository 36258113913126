import type { ShiftFields } from 'data/shift/model';
import Monolith from 'shared/api/monolith';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

export type CopyShiftsResponse = {
  shifts: ShiftFields[];
  deleted: ShiftFields['id'][];
  original: { [key: number]: string };
  warning?: string;
  confirmation: string;
};

export const copyShifts = createWiwAsyncThunk<CopyShiftsResponse, any>(
  'shift/copy',
  async (body, { rejectWithValue }) => {
    try {
      return (await Monolith.url('/shifts/copy').post(body)) as CopyShiftsResponse;
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
