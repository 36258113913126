import type { List } from 'immutable';
import { isNil } from 'lodash';
import moment from 'moment-timezone';
import { Fragment } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import type User from 'data/user/model';
import SpinningLoader from 'shared/ui/loaders/SpinningLoader';
import { useWiwDispatch, useWiwSelector } from 'store';
import { DateFormat } from 'workchat/formats';
import type { ImageType } from 'workchat/v2/components/MessageV2/MessageImage';
import MessageV2 from 'workchat/v2/components/MessageV2/MessageV2';
import { CONVERSATION_TYPE, MESSAGE_AUTHOR_SYSTEM_ID } from 'workchat/v2/constants';
import { MessageProvider } from 'workchat/v2/hooks/useMessageProvider';
import type { ReduxConversation } from 'workchat/v2/store/conversations/conversationsReducer';
import { getMessagePaginatorForConversationId, getMessagesLoading } from 'workchat/v2/store/messages/messageSelectors';
import { loadMoreWorkchatMessages } from 'workchat/v2/store/messages/messagesActions';
import type { ReduxMessage } from 'workchat/v2/store/messages/messagesReducer';
import type { ReduxParticipant } from 'workchat/v2/store/participants/participantsReducer';
import { getParticipantsForConversationIdAsList } from 'workchat/v2/store/participants/participantsSelectors';
import { getWorkchatUsers } from 'workchat/v2/store/users/usersSelectors';

import 'workchat/styles/MessageLog.scss';

interface MessageLogV2Props {
  connected: boolean;
  messages: List<ReduxMessage>;
  conversation: ReduxConversation;
  pending: List<any>;
  failed: List<any>;
  user: User;
}

export default function MessageLogV2({ connected, messages, conversation, user }: MessageLogV2Props) {
  const dispatch = useWiwDispatch();
  const users = useWiwSelector(getWorkchatUsers);
  const messageCursor = useWiwSelector(state => getMessagePaginatorForConversationId(state, conversation.sid));
  const participants = useWiwSelector(state => getParticipantsForConversationIdAsList(state, conversation.sid));
  const loading = useWiwSelector(getMessagesLoading);

  const individual = conversation?.attributes.external_type === CONVERSATION_TYPE.INDIVIDUAL;

  const renderMessages = () => {
    const lastIndex = messages.size - 1;
    let lastDate: string | null;

    return messages.toArray().map((message, index) => {
      const date = message.dateCreated ? moment(message.dateCreated)?.calendar(null, DateFormat) : null;
      const userObj = message.author ? users.get(message.author) : null;
      const messageAttributes = message.attributes as { [key: string]: any };

      const image =
        messageAttributes.fullsize &&
        messageAttributes.thumbnail &&
        ({
          full: messageAttributes.fullsize,
          thumb: messageAttributes.thumbnail,
        } as ImageType);

      let readByAll = false;
      let readers: ReduxParticipant[] | null = null;

      function renderDateSeparator() {
        if (date !== lastDate) {
          lastDate = date;
          return (
            <div key={`msg-date-${message.dateCreated ? +message.dateCreated : index}`} className="date-line">
              <hr />
              <span>
                <time dateTime={message.dateCreated || undefined}>{date}</time>
              </span>
            </div>
          );
        }
      }

      if (index === lastIndex) {
        let unread = 0;
        readers = [];

        participants?.forEach(participant => {
          if (
            participant.userId !== message.author &&
            !isNil(participant?.lastReadMessageIndex) &&
            participant.lastReadMessageIndex >= message.index
          ) {
            readers?.push(participant);
          } else if (participant.userId !== message.author) {
            unread++;
          }
        });

        if (readers.length === 1 && readers[0].userId === user.id) {
          readers = null;
        } else {
          readers = readers.sort((prevReader, currReader) => {
            if (prevReader.lastReadTimestamp && currReader.lastReadTimestamp) {
              return moment(prevReader.lastReadTimestamp).unix() - moment(currReader.lastReadTimestamp).unix();
            }
            return 0;
          });
        }
        readByAll = unread === 0;
      }

      const readersAsUsers: User[] | undefined = readers
        ?.map(participant => (participant.userId ? users.get(participant.userId) : null))
        .filter(user => !!user) as User[];

      return (
        <Fragment key={message.sid}>
          {renderDateSeparator()}
          <MessageV2
            id={message.sid}
            conversationId={message.conversation.sid}
            key={message.sid}
            individual={individual}
            user={userObj}
            text={message.body}
            image={image}
            sentAt={message.dateCreated}
            reactions={message.attributes?.reactions}
            readers={readersAsUsers}
            readByAll={readByAll}
            isSystemMessage={message.author === MESSAGE_AUTHOR_SYSTEM_ID}
          />
        </Fragment>
      );
    });
  };

  const loadMore = () => {
    dispatch(loadMoreWorkchatMessages(conversation.sid));
  };

  return (
    <div id="scrollable" className="message-log">
      <MessageProvider>
        <InfiniteScroll
          inverse
          dataLength={messages.size}
          hasMore={!loading && !!messageCursor?.hasNext && connected}
          next={loadMore}
          scrollableTarget="scrollable"
          scrollThreshold="20px"
          loader={
            <div className="text-center my-2">
              <SpinningLoader color="gray" height={30} width={30} />
            </div>
          }
        >
          <div className="messages">{renderMessages()}</div>
        </InfiniteScroll>
      </MessageProvider>
    </div>
  );
}
