import { useEffect, useRef } from 'react';

const DEFAULT_EVENTS = ['mousedown', 'touchstart'];

/** This is a fork of Mantine's useClickOutside to allow for more control over ignorable targets.
 * Source: https://github.com/mantinedev/mantine/blob/master/packages/@mantine/hooks/src/use-click-outside/use-click-outside.ts
 */

export function useClickOutside<T extends HTMLElement = any>(
  handler: () => void,
  events?: string[] | null,
  nodes?: (HTMLElement | null)[],
) {
  const ref = useRef<T>();

  useEffect(() => {
    const listener = (event: any) => {
      const { target } = event ?? {};

      if (Array.isArray(nodes)) {
        const eventComposedPath = event.composedPath();
        const shouldIgnore =
          (eventComposedPath.length &&
            eventComposedPath.some((node: HTMLElement) => node.hasAttribute?.('data-ignore-outside-clicks'))) ||
          (!document.body.contains(target) && target.tagName !== 'HTML');
        const shouldTrigger = nodes.every(node => !!node && !event.composedPath().includes(node));
        shouldTrigger && !shouldIgnore && handler();
      } else if (ref.current && !ref.current.contains(target)) {
        handler();
      }
    };

    (events || DEFAULT_EVENTS).forEach(fn => document.addEventListener(fn, listener));

    return () => {
      (events || DEFAULT_EVENTS).forEach(fn => document.removeEventListener(fn, listener));
    };
  }, [ref, handler, nodes]);

  return ref;
}
