import { useEffect, useState } from 'react';

import { getMasterAccount } from 'data/account/selectors';
import { errorNotice } from 'notices';
import { request } from 'shared/auth/request';
import { getAuthAccount } from 'shared/auth/selectors';
import { Panel, PanelBody, PanelTitle } from 'shared/ui/Panel';
import { useWiwDispatch, useWiwSelector } from 'store';

import { sortBy } from 'lodash';

export default function NoAccess() {
  const dispatch = useWiwDispatch();

  const account = useWiwSelector(getAuthAccount);
  const parentAccount = useWiwSelector(getMasterAccount);

  const [admins, setAdmins] = useState([]);

  useEffect(() => {
    request()
      .get(`/account/${account.master_id}/admins`)
      .then(res => setAdmins(res.admins))
      .catch(err => dispatch(errorNotice(err.message)));
  }, [account, dispatch]);

  let links = null;
  if (admins.length > 0) {
    links = sortBy(admins, ['first_name', 'last_name'])
      .map(admin => (
        <a key={admin.id} href={`mailto:${admin.email}`}>
          {admin.first_name} {admin.last_name}
        </a>
      ))
      .reduce((prev, curr) => [prev, ', ', curr]);
  }

  let panelBody = (
    <PanelBody>
      Please contact the parent account admin to make any changes:{' '}
      <a href={`mailto:${parentAccount?.owner.email}`}>{parentAccount?.owner.name}</a>
    </PanelBody>
  );

  if (admins.length === 0) {
    panelBody = <PanelBody>Please contact a parent account admin to make any changes.</PanelBody>;
  } else if (admins.length === 1) {
    panelBody = (
      <PanelBody data-testid="single-admin">
        Please contact the parent account admin to make any changes: {links}
      </PanelBody>
    );
  } else if (admins.length > 1) {
    panelBody = (
      <PanelBody data-testid="multiple-admin">
        Please contact a parent account admin to make any changes: {links}
      </PanelBody>
    );
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <Panel>
            <PanelTitle bottomBorder>You do not have billing permission.</PanelTitle>

            {panelBody}
          </Panel>
        </div>
      </div>
    </div>
  );
}
