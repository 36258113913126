import EntityState from 'data/EntityState';
import fetchDevices from 'data/devices/actions/fetchDevices';
import Device from 'data/devices/model';

import { createSlice } from '@reduxjs/toolkit';
import { toEntityMap } from 'shared/util/toEntityMap';

const initialState = new EntityState<Device[]>();

const devicesSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchDevices.fulfilled, (state, action) => {
      return state.mergeDeep({
        loaded: true,
        items: toEntityMap(action.payload, Device),
      });
    });
  },
});

export default devicesSlice.reducer;
