export const activeSectionSelector = state => state.data.notification.activeSection;
export const errorSelector = state => state.data.notification.error;
export const loadedSelector = state => state.data.notification.loaded;
export const loadingSelector = state => state.data.notification.loading;
export const locationsSelector = state => state.data.notification.locations;
export const messagesSelector = state => state.data.notification.messages;
export const newSelector = state => state.data.notification.new;
export const notificationsSelector = state => state.data.notification.items;
export const requestsSelector = state => state.data.notification.requests;
export const absencesSelector = state => state.data.notification.absences;
export const searchSelector = state => state.data.notification.search;
export const shiftsSelector = state => state.data.notification.shifts;
export const showSelector = state => state.data.notification.show;
export const swapsSelector = state => state.data.notification.swaps;
export const usersSelector = state => state.data.notification.users;
