import type Account from 'data/account/model';
import type Plan from 'data/plan/model';
import { getPlans } from 'data/plan/selectors/plans';
import { getAuthAccount } from 'shared/auth/selectors';
import type { WiwState } from 'store';

import { createSelector } from '@reduxjs/toolkit';

type Props = {
  account?: Account;
  plans?: Plan[];
};

const mapSubscriptionPlans = (plans: WiwState['data']['plan']['items'], account: Account) => {
  if (plans && account) {
    return account.subscriptions.map(sub => plans.get(sub.plan.id));
  }
  return [];
};

const getPropsOrStatePlans = (state: WiwState, props: Props) => {
  return props?.plans ? props.plans : getPlans(state, props);
};

const getPropsOrStateAccount = (state: WiwState, props: Props) => {
  return props?.account ? props.account : getAuthAccount(state);
};

export const getSubscriptionPlans = createSelector(
  [getPropsOrStatePlans, getPropsOrStateAccount],
  mapSubscriptionPlans,
);

export const getNonFreeTrialSubscriptionPlans = createSelector(
  [getPropsOrStatePlans, getPropsOrStateAccount],
  (plans, account): Plan[] => {
    if (plans && account) {
      return account.subscriptions.filter(sub => sub.type !== 'free-trial').map(sub => plans.get(sub.plan.id));
    }
    return [] as Plan[];
  },
);
