const getInfo = () => {
  // istanbul ignore next
  if (!CONFIG || !CONFIG.DEPLOY) {
    return {
      sha: null,
      tag: null,
    };
  }

  return {
    sha: CONFIG.DEPLOY.SHA || null,
    tag: CONFIG.DEPLOY.TAG || null,
  };
};

export default getInfo();
