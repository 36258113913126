import type { EntityMap } from 'data/types';

import { immerable } from 'immer';
import { Map, Record } from 'immutable';

export interface EntityBaseProps<T> {
  loading: boolean;
  loaded: boolean;
  error: any;
  receivedAt: number;
  items: EntityMap<T>;
  prevItems: EntityMap<T>;
}

type Field<Item, ExtraValues, Name extends keyof EntityBaseProps<Item>> = Name extends keyof ExtraValues
  ? ExtraValues[Name]
  : EntityBaseProps<Item>[Name];

function EntityBase<Item, ExtraValues>(defaultValues: ExtraValues) {
  const defaultBaseProps: EntityBaseProps<Item> = {
    loading: false,
    loaded: false,
    error: null,
    receivedAt: 0,
    items: Map<any, Item>(),
    prevItems: Map<any, Item>(),
  };

  return class EntityBase extends Record({
    ...(defaultBaseProps as Omit<typeof defaultBaseProps, keyof ExtraValues>),
    ...defaultValues,
  }) {
    declare readonly loading: Field<Item, ExtraValues, 'loading'>;
    declare readonly loaded: Field<Item, ExtraValues, 'loaded'>;
    declare readonly error: Field<Item, ExtraValues, 'error'>;
    declare readonly receivedAt: Field<Item, ExtraValues, 'receivedAt'>;
    declare readonly items: Field<Item, ExtraValues, 'items'>;
    declare readonly prevItems: Field<Item, ExtraValues, 'prevItems'>;

    constructor(args?: any) {
      super(args);
      // @ts-ignore
      this[immerable] = true;
    }
  };
}

export default EntityBase;
