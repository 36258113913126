import { lazy } from 'react';

/* istanbul ignore file */

const RollupDialog = lazy(() => import('./RollupDialog'));
const ApproveTimeDialog = lazy(() => import('./ApproveTimeDialog'));
const EditTimeDialog = lazy(() => import('./EditTimeDialog'));
const CreateShiftDialog = lazy(() => import('./CreateShiftDialog'));
const LaborReportExportDialog = lazy(() => import('./LaborReportExportDialog'));
const ConfigureDashboard = lazy(() => import('./ConfigureDashboard'));

/*
Add your dialogs below. Import, add a const, then add
to the dialogs list.

To open a dialog, do this
import { openDialog, closeDialog } from 'dialogs';
import { REQUEST_VIEW_DIALOG } from 'timesheets/dialogs';

// Bind these with redux
openDialog(REQUEST_VIEW_DIALOG, { request, otherProp, payload });
closeDialog(REQUEST_VIEW_DIALOG);
*/
// Identifiers for each dialog
export const ROLLUP_DIALOG = 'ROLLUP_DIALOG';
export const APPROVE_TIME_DIALOG = 'APPROVE_TIME_DIALOG';
export const EDIT_TIME_DIALOG = 'EDIT_TIME_DIALOG';
export const CREATE_SHIFT_DIALOG = 'CREATE_SHIFT_DIALOG';
export const LABOR_REPORT_EXPORT = 'LABOR_REPORT_EXPORT';
export const CONFIGURE_DASHBOARD = 'CONFIGURE_DASHBOARD';

const dialogs = {
  [ROLLUP_DIALOG]: RollupDialog,
  [APPROVE_TIME_DIALOG]: ApproveTimeDialog,
  [EDIT_TIME_DIALOG]: EditTimeDialog,
  [CREATE_SHIFT_DIALOG]: CreateShiftDialog,
  [LABOR_REPORT_EXPORT]: LaborReportExportDialog,
  [CONFIGURE_DASHBOARD]: ConfigureDashboard,
  // Add more here ...
};

export default dialogs;
