import BaseModel from 'data/BaseModel';

import moment from 'moment-timezone';

export const ptoTypes = ['pto', 'sick', 'paid_holiday', 'rest_and_recovery', 'non_productive'];
export const hourlyTypes = ['hourly', 'salaried', 'overtime', 'double_overtime', 'piece', ...ptoTypes];
export const nonHourlyTypes = [
  'bonus',
  'commission',
  'severance',
  'cash_tips',
  'paycheck_tips',
  'group_term_life',
  'other_imputed',
  'non_hourly_regular',
];

export const reimbursementType = 'reimbursement';
export const earningTypesOptions = [
  { label: 'Bonus', value: 'bonus' },
  { label: 'Cash Tips', value: 'cash_tips' },
  { label: 'Commission', value: 'commission' },
  { label: 'Group Term Life', value: 'group_term_life' },
  { label: 'Non-Hourly Regular', value: 'non_hourly_regular' },
  { label: 'Paycheck Tips', value: 'paycheck_tips' },
  { label: 'Severance', value: 'severance' },
  { label: 'Other Imputed Income', value: 'other_imputed' },
  { label: 'Reimbursement', value: reimbursementType },
];
export type PaymentMethodType = 'direct_deposit' | 'manual';

export interface PayrollRecordSummaryFields {
  id: string;
  pay_period_id: number | null;
  period_start: string;
  period_end: string;
  approval_deadline: string;
  payday: string;
  status: string;
  is_void: boolean;
  totals: Totals;
  can_submit: boolean;
  is_historic: boolean;
  blockers: Blockers;
}

export interface PayrollRecordFields extends PayrollRecordSummaryFields {
  company: string;
  approved_at: string;
  managed: boolean;
  type: string;
  pay_frequency: string;
  pay_schedule: null;
  funding_payment_method: string;
  processing_period: string;
  off_cycle_options: null;
  items: PayrollItem[];
  contractor_payments: ContractorPayment[];
  metadata: Metadata;
  preview_errors?: string[];
}

export interface PayrollItem {
  id: number; // user id
  employee_id: string;
  full_name: string;
  payroll: string;
  status: string;
  void_of: null;
  voided_by: null;
  payment_method: PaymentMethodType;
  supplemental_tax_calc_method: string;
  pto_balance_hours: null;
  sick_balance_hours: null;
  state_covid_sick_balance_hours: null;
  net_pay: string;
  net_pay_split: string;
  earnings: Earning[];
  earnings_by_rate: Earning[];
  reimbursements: Reimbursement[];
  taxes: Tax[];
  deductions: Deduction[];
  benefits: Deduction[];
  benefit_overrides: any[];
  post_tax_deductions: any[];
  post_tax_deduction_overrides: any[];
  warnings: any[];
  paper_check_number: null;
  paystub_info: Metadata;
  metadata: Metadata;
  total_hours: string;
  total_earnings: string;
  total_deductions: string;
  total_taxes: string;
  can_direct_deposit: boolean;
  pto_totals: Earning[];
}

export interface ContractorPayment {
  id: number; // user id
  employee_id: string;
  payment_method: PaymentMethodType;
  amount: string;
  reimbursements: Reimbursement[];
  reimbursements_total: string;
  net_pay: string;
  total_hours: string;
  can_direct_deposit: boolean;
  status: string;
}

export interface Reimbursement {
  amount: string;
  description?: string;
  code?: string;
}

export interface Earning {
  id: number;
  type: string;
  amount: string;
  piece_units?: null;
  hours?: number;
  rate?: string;
  code?: null;
  description?: null;
  earning_code?: null;
  earning_rate?: null;
  workplace?: string;
  tip_credit_amount?: null;
  metadata?: Metadata;
}

export interface Deduction {
  id: string;
  name: string;
  type: string;
  employee?: string;
  benefit?: string;
  company_benefit?: string;
  source?: string | null;
  period?: string | null;
  description?: string;
  effective_start?: string;
  effective_end?: string;
  company_contribution_amount: string;
  company_contribution_percent?: number;
  company_period_amount?: string;
  employee_contribution_amount: string;
  employee_contribution_percent?: number;
  employee_period_amount?: string;
  hsa_contribution_limit?: 'single' | 'family';
  metadata?: Metadata;
}

/* biome-ignore lint/complexity/noBannedTypes: Type */
export type Metadata = {};

export interface Tax {
  tax: string;
  description: string;
  amount: string;
  payer: string;
  remittable: boolean;
}

export interface Totals {
  employee_gross: string;
  employee_reimbursements: string;
  employee_net: string;
  employee_taxes: string;
  employee_benefits: string;
  post_tax_deductions: string;
  contractor_gross: string;
  contractor_reimbursements: string;
  contractor_net: string;
  company_taxes: string;
  company_benefits: string;
  liability: string;
  cash_requirement: string;
  total_hours: string;
  total_contractor_hours: string;
}

export interface Blockers {
  in_good_standing: boolean;
  approval_deadline_past: boolean;
  current: boolean;
  planned: boolean;
  ended: boolean;
  employees_blocked: string[];
  company_status_blocked: boolean;
}

export default class PayrollRecord extends BaseModel<PayrollRecordFields>({
  id: '',
  pay_period_id: null,
  company: '',
  period_start: '',
  period_end: '',
  approval_deadline: '',
  approved_at: '',
  payday: '',
  status: '',
  managed: false,
  type: '',
  pay_frequency: '',
  pay_schedule: null,
  funding_payment_method: '',
  processing_period: '',
  off_cycle_options: null,
  totals: {} as Totals,
  items: [] as PayrollItem[],
  contractor_payments: [] as ContractorPayment[],
  is_void: false,
  metadata: {} as Metadata,
  can_submit: false,
  is_historic: false,
  blockers: {} as Blockers,
  preview_errors: [],
}) {
  isSummary = true;

  static Status = {
    DRAFT: 'draft',
    APPROVED: 'approved',
    PENDING: 'pending',
    PROCESSING: 'processing',
    FAILED: 'failed',
    PAID: 'paid',
    PARTIALLY_PAID: 'partially paid',
  };

  isPartial() {
    return this.status === PayrollRecord.Status.PARTIALLY_PAID;
  }

  isFailed() {
    return this.status === PayrollRecord.Status.FAILED;
  }

  isDraft() {
    return this.status === PayrollRecord.Status.DRAFT;
  }

  isPending() {
    return this.status === PayrollRecord.Status.PENDING;
  }

  isEmpty() {
    return this.id === '';
  }

  isOffCycle() {
    return this.type === 'off_cycle' || this.type === 'amendment' || this.type === 'third_party_sick_pay';
  }

  isSubmitted() {
    return this.status !== 'draft' && this.id !== '';
  }

  isRegular() {
    return this.type === 'regular';
  }

  isPayrollEnded(wds = '00:00') {
    const now = moment();
    return now.isAfter(this.mustDateWithWDS('period_end', wds)) && !this.isSubmitted();
  }

  isPastDeadline() {
    const now = moment();
    return now.isAfter(this.mustDate('approval_deadline')) && !this.isSubmitted();
  }

  isEditable() {
    return !this.isSubmitted() && !this.isPastDeadline();
  }

  mustDateWithWDS(key: 'period_end' | 'period_start', workDayStart = '00:00') {
    const [wdsHour, wdsMinute] = workDayStart.split(':').map(num => Number.parseInt(num));
    const date = this.mustDate(key).hour(wdsHour).minute(wdsMinute);

    if (workDayStart !== '00:00') {
      return key === 'period_end' ? date.subtract(1, 'second') : date;
    }

    return key === 'period_end' ? date.add(1, 'day').subtract(1, 'second') : date;
  }
}
