import EntityState from 'data/EntityState';
import { createPayrollBenefit } from 'data/payrollBenefits/actions/createPayrollBenefit';
import { deletePayrollBenefit } from 'data/payrollBenefits/actions/deletePayrollBenefit';
import { fetchPayrollBenefits } from 'data/payrollBenefits/actions/fetchPayrollBenefits';
import { updatePayrollBenefit } from 'data/payrollBenefits/actions/updatePayrollBenefit';
import BenefitRecord from 'data/payrollBenefits/model';
import generateThunkReducers from 'data/util/generateThunkReducers';
import { optimisticallyDeleteEntity } from 'data/util/optimisticUpdate';
import { mergeEntityItems, replaceEntityItems } from 'data/util/sliceHelpers';

import { createSlice } from '@reduxjs/toolkit';

const initialState = new EntityState<BenefitRecord>();

export const payrollsBenefits = createSlice({
  name: 'benefits',
  initialState,
  reducers: {
    receiveBenefits: (state, action) => {
      return mergeEntityItems(state, action.payload.benefits, BenefitRecord);
    },
    clearBenefits: state => {
      return state.merge({
        items: state.items.clear(),
        prevItems: state.prevItems.clear(),
      });
    },
  },
  extraReducers: builder => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchPayrollBenefits.fulfilled, (state, action) => {
        return replaceEntityItems(state, action.payload.benefits, BenefitRecord);
      })
      .addCase(createPayrollBenefit.fulfilled, (state, action) => {
        return state.merge({
          items: state.items
            .delete(0)
            .delete('')
            .set(action.payload.benefits.id, new BenefitRecord(action.payload.benefits)),
          prevItems: state.prevItems.delete(action.payload.benefits.id),
        });
      })
      .addCase(updatePayrollBenefit.fulfilled, (state, action) => {
        return state.merge({
          items: state.items
            .delete(0)
            .delete('')
            .set(action.payload.benefits.id, new BenefitRecord(action.payload.benefits)),
          prevItems: state.prevItems.delete(action.payload.benefits.id),
        });
      });
    optimisticallyDeleteEntity(builder, deletePayrollBenefit);
    generateThunkReducers(builder, 'benefits');
  },
});

export const { clearBenefits } = payrollsBenefits.actions;
export default payrollsBenefits.reducer;
