import { Fragment } from 'react';

import { getAuthAccount } from 'shared/auth/selectors';
import { MenuItem } from 'shared/components/nav/MenuItem';
import { useWiwSelector } from 'store';

type EmployeeMenuProps = {
  trackDocumentsNav: () => void;
  canViewDocStorage: boolean;
};

const EmployeeMenuItem = ({ trackDocumentsNav, canViewDocStorage }: EmployeeMenuProps) => {
  const account = useWiwSelector(getAuthAccount);

  return (
    <Fragment>
      <MenuItem
        className="menu-icon hint--bottom hint--rounded hint--no-animate menu-item-coworkers"
        hintTooltipLabel="Coworkers"
        label="Coworkers"
        hideLabel="desktop"
        icon="employees"
        to="/coworkers"
      />

      {canViewDocStorage && !account.isReverseTrial() && (
        <MenuItem
          className="menu-icon hint--bottom hint--rounded hint--no-animate menu-item-document"
          hintTooltipLabel="Documents"
          icon="document"
          hideLabel="desktop"
          label="Documents"
          onClick={trackDocumentsNav}
          to="/documents"
        />
      )}
    </Fragment>
  );
};
export default EmployeeMenuItem;
