import type { QueryString } from 'data/types';
import Platform from 'shared/api/platform';
import { parseAPIError } from 'shared/redux/helpers/parseApiError';
import { createWiwAsyncThunk } from 'store';

export interface FetchTosAckRequiredResponse {
  data: {
    tosAckRequired: boolean;
  };
}

export const fetchTosAckRequired = createWiwAsyncThunk<FetchTosAckRequiredResponse, QueryString>(
  'termsOfService/fetchTosAckRequired',
  async (params = {}, { rejectWithValue }) => {
    try {
      return await Platform.query(params).get('/termsofservice');
    } catch (error) {
      return rejectWithValue(parseAPIError(error));
    }
  },
);
