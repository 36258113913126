import { getReportAccountId } from 'reporting/selectors/getReportAccountId';
import type { WiwState } from 'store';

import { createSelector } from '@reduxjs/toolkit';

export const getCustomReportBuilderTempAccountId = (state: WiwState): number | undefined =>
  state.reporting.customReportBuilder.tempAccountId;

export const getReportTempAccountId = createSelector(
  [getReportAccountId, getCustomReportBuilderTempAccountId],
  (accountId, tempAccountId) => {
    if (tempAccountId == null) {
      return accountId;
    }

    return tempAccountId;
  },
);
