import isAlphanumericWhitespace from './isAlphanumericWhitespace';
import isBreakTimeShorterThanOrEqualToShiftDuration from './isBreakTimeShorterThanOrEqualToShiftDuration';
import isDate from './isDate';
import isFirstLastName from './isFirstLastName';
import isGreaterOrEqual from './isGreaterOrEqual';
import { isGt, isGte, isLt, isLte, maxPrecision } from './isNumber';
import isPhone from './isPhone';
import isPhoneOrEmail from './isPhoneOrEmail';
import isPostalCode from './isPostalCode';
import isRequired from './isRequired';
import isRequiredTimePeriod from './isRequiredTimePeriod';
import isStringWithSpecial from './isStringWithSpecial';
import isTime from './isTime';
import isTimePeriod from './isTimePeriod';
import isUnique from './isUnique';
import requiredIf from './requiredIf';

import { Map } from 'immutable';

const validators = Map({
  isAlphanumericWhitespace,
  isDate,
  isFirstLastName,
  isGreaterOrEqual,
  isGt,
  isGte,
  isLt,
  isLte,
  isPhone,
  isPhoneOrEmail,
  isRequired,
  isStringWithSpecial,
  isTimePeriod,
  isRequiredTimePeriod,
  isUnique,
  maxPrecision,
  requiredIf,
  isBreakTimeShorterThanOrEqualToShiftDuration,
  isTime,
  isPostalCode,
});

export default validators;
