import Absence from 'data/absence/model';
import Location from 'data/location/model';
import Message from 'data/message/model';
import Notification from 'data/notifications/model';
import NotificationState from 'data/notifications/state';
import Request from 'data/request/model';
import Shift from 'data/shift/model';
import Swap from 'data/swap/model';
import User from 'data/user/model';
import {
  DELETE_NOTIFICATION_USER,
  FETCH_NOTIFICATIONS,
  NOTIFICATIONS_DRAWER,
  SEARCH_NOTIFICATIONS,
  SET_NOTIFICATION_SECTION,
  UPDATE_NOTIFICATION_LOCATION,
  UPDATE_NOTIFICATION_NEW_COUNT,
  UPDATE_NOTIFICATION_REQUEST,
  UPDATE_NOTIFICATION_USER,
} from 'store/action-types';

import { Map, OrderedMap } from 'immutable';

export default (state = new NotificationState(), action) => {
  switch (action.type) {
    case NOTIFICATIONS_DRAWER:
      return state.merge({
        show: action.show,
      });
    case FETCH_NOTIFICATIONS.REQUEST:
      return state.merge({
        loading: true,
        receivedAt: 0,
        loaded: false,
      });
    case FETCH_NOTIFICATIONS.SUCCESS:
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        receivedAt: Date.now(),
        items: new OrderedMap(action.items.map(notification => [notification.id, new Notification(notification)])),
        locations: new Map(action.locations.map(location => [location.id, new Location(location)])),
        messages: new Map(action.messages.map(message => [message.id, new Message(message)])),
        requests: new Map(action.requests.map(request => [request.id, new Request(request)])),
        shifts: new Map(action.shifts.map(shift => [shift.id, new Shift(shift)])),
        swaps: new Map(action.swaps.map(swap => [swap.id, new Swap(swap)])),
        users: new Map(action.users.map(user => [user.id, new User(user)])),
        absences: new Map(action.absences.map(absence => [absence.id, new Absence(absence)])),
        new: action.new,
      });
    case FETCH_NOTIFICATIONS.FAILURE:
      return state.merge({
        loaded: false,
        loading: false,
        error: action.payload,
      });
    case UPDATE_NOTIFICATION_REQUEST:
      return state.merge({
        requests: state.requests.set(action.item.id, new Request(action.item)),
      });
    case UPDATE_NOTIFICATION_USER:
      return state.merge({
        users: state.users.set(action.item.id, new User(action.item)),
      });
    case DELETE_NOTIFICATION_USER:
      return state.merge({
        users: state.users.delete(action.item.id),
      });
    case UPDATE_NOTIFICATION_LOCATION:
      return state.merge({
        locations: state.locations.set(action.item.id, new Location(action.item)),
      });
    case SET_NOTIFICATION_SECTION:
      return state.merge({
        activeSection: action.section,
      });
    case SEARCH_NOTIFICATIONS:
      return state.merge({
        search: action.search,
      });
    case UPDATE_NOTIFICATION_NEW_COUNT:
      return state.merge({
        new: action.new,
      });
    default:
      return state;
  }
};
