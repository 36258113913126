import { useLocation } from 'react-router-dom';
import { Panel } from 'shared/ui/Panel';

const Footer = () => {
  const location = useLocation();

  // DEPRECATED: When removing the <Footer /> from a page, please pass
  // `hideFooter` to the `<MainLayout />` component in `routes.js` instead
  // of placing it here.
  if (
    [
      '/scheduler',
      '/timesheets',
      '/documents',
      '/schedules',
      '/positions',
      '/users',
      '/users/archived',
      '/tags',
      '/sites',
      '/shift-templates',
      '/tasks',
      '/reporting/custom',
      '/reporting/legacy',
      '/reporting/ag-grid',
      '/sites',
    ].includes(location.pathname) ||
    location.pathname.includes('request')
  ) {
    return null;
  }

  if (location.pathname.startsWith('/reporting')) {
    return null;
  }

  return (
    <footer id="primary-footer" className="container footer mt-3 d-print-none">
      <Panel>
        <div className="row">
          <div className="col-12 footer-rows">
            <div className="footer-legal pull-left">
              <span className="copyright">Copyright &copy; {new Date().getFullYear()} When I Work, Inc.</span>
              <span className="divider">|</span>
              All Rights Reserved
              <span className="divider">|</span>
              <a href="//wheniwork.com/terms" target="_blank" rel="noopener noreferrer">
                Terms of Service
              </a>
              <span className="divider">|</span>
              <a href="//wheniwork.com/privacy" target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </a>
            </div>
            <div className="footer-support pull-right">
              <a href="//dev.wheniwork.com/" target="_blank" rel="noopener noreferrer">
                Developers
              </a>
              <span className="divider">|</span>
              <a href="//help.wheniwork.com/submit-a-ticket/" target="_blank" rel="noopener noreferrer">
                Found a Bug?
              </a>
              <span className="divider d-md-none">|</span>
              <a href="//help.wheniwork.com" target="_blank" rel="noopener noreferrer" className="need-help">
                Need Help?
              </a>
            </div>
          </div>
        </div>
      </Panel>
    </footer>
  );
};

export default Footer;
