import BaseModel from 'data/BaseModel';
import type Account from 'data/account/model';
import Shift from 'data/shift/model';
import type User from 'data/user/model';
import { UserType } from 'data/user/model';
import { rangesOverlap, setYearMonthDay } from 'shared/util/time';

import { Map } from 'immutable';
import { map, round } from 'lodash';
import moment, { type Moment } from 'moment-timezone';

type ShiftHoursOverMidnight = {
  [key: string]: {
    shift: Shift;
    hours: number;
    move: boolean;
  };
};

export interface OvertimeHours {
  newDailyDoubleOT: number;
  maxHours: number;
  maxHoursNew: number;
  maxHoursTotal: number;
  totalDailyDoubleOT: number;
  weeklyDoubleOT: number;
  newDailyOT: number;
  currentWeeklyOT: number;
  newWeeklyOT: number;
  totalRegularOT: number;
  totalDailyOT: number;
  netNew: number;
  totalHours: number;
}

interface RawHoursData {
  regular: number;
  double: number;
  type: 'actual' | 'scheduled';
  filtered: boolean;
}

interface HoursData {
  doubleOvertimeHours: number;
  overtimeHours: number;
  regularHours: number;
  totalHours: number;
  weeklyOvertimeHours: number;
}

export type DayHours = {
  scheduled?: HoursData;
  normalized: HoursData;
  actual?: HoursData;
  rawhours?: RawHoursData[];
};

type WeekHours = Pick<DayHours, 'normalized'>;

export interface OvertimeFields {
  id: number | null;
  maxHours: number | null;
  days: { [key: string]: DayHours };
  weeks: { [key: string]: WeekHours };

  /** I don't think this property is ever used, but I'm afraid to take it out. */
  actual: unknown; // TODO(types)

  /** I don't think this property is ever used, but I'm afraid to take it out. */
  scheduled: unknown; // TODO(types)
}

class Overtime extends BaseModel<OvertimeFields>({
  id: null,
  actual: {},
  days: {},
  maxHours: null,
  scheduled: {},
  weeks: {},
}) {
  countOverMidnightHours(shift: Shift, shiftDuration: number, account: Account): ShiftHoursOverMidnight | undefined {
    //Check if shift extends across week boundary
    const startOfWeek = Object.keys(this.weeks);

    const workDayStart = moment(account.getSettings('payroll.work_day_start'), 'H:mm');
    //set the adjusted shift start and end times cutting days correctly
    const adjustedShiftStart = setYearMonthDay(workDayStart, shift.mustDate('start_time'));
    const adjustedShiftEnd = setYearMonthDay(workDayStart, shift.mustDate('end_time'));

    // if the start and end dates cross DST, apply the offset to the end time to reflect the actual selected end time.
    // e.g. 12am-8am on DST becomes 12am-9am after applying the new offset, the difference between CST Jan 1 (-300)
    // and CST April 1 (-240) is -60 (minutes) which reverts 9am to 8am
    if (shift.date('start_time')!.utcOffset() !== shift.date('end_time')!.utcOffset()) {
      const offsetDiff = shift.date('start_time')!.utcOffset() - shift.date('end_time')!.utcOffset();

      if (account.getSettings('payroll.work_day_start') !== '00:00') {
        workDayStart.add(offsetDiff, 'minutes');
      }

      adjustedShiftEnd.add(offsetDiff, 'minutes');
    }

    let adjustedShift = shift;

    if (startOfWeek.length > 0) {
      //check if shift end is over midnight
      if (
        rangesOverlap(
          adjustedShiftStart,
          adjustedShiftStart,
          adjustedShift.date('start_time')!,
          adjustedShift.date('end_time')!,
        ) ||
        rangesOverlap(
          adjustedShiftEnd,
          adjustedShiftEnd,
          adjustedShift.date('start_time')!,
          adjustedShift.date('end_time')!,
        )
      ) {
        //duration from midnight
        const startOfDay = adjustedShift
          .date('end_time')
          ?.clone()
          .hour(adjustedShiftStart.hours())
          .minutes(adjustedShiftStart.minutes())
          .seconds(adjustedShiftStart.seconds());

        let tomorrowHours = Math.max(
          Number.parseFloat((shift.date('end_time')!.diff(startOfDay, 'minutes', true) / 60).toFixed(3)),
          0,
        );
        let todayHours = Math.max(shiftDuration - tomorrowHours, 0);

        const splitShift = new Shift({
          start_time: startOfDay,
          end_time: shift.date('end_time'),
        });

        // split non midnight cutoff correctly
        // should not be calculated on the same day
        if (
          tomorrowHours > 0 &&
          shift.date('start_time')?.isSame(splitShift.date('end_time'), 'day') &&
          account.getSettings('payroll.work_day_start') !== '00:00'
        ) {
          // move the original shift back a day
          adjustedShift = new Shift({
            ...shift,
            start_time: adjustedShift?.date('start_time')?.subtract(1, 'days'),
            end_time: adjustedShift?.date('end_time')?.subtract(1, 'days'),
          });
        }

        //set break times correctly
        //https://wheniwork.atlassian.net/browse/WIWJS-161
        if (shift.break_time > 0) {
          if (todayHours >= 4) {
            if (todayHours < adjustedShift.break_time) {
              //fix ot calculations if a break is longer then today time we need to remove tomorrowHours first
              tomorrowHours = tomorrowHours - (adjustedShift.break_time - todayHours);
            }

            todayHours = Math.max(todayHours - adjustedShift.break_time, 0);
          } else {
            if (tomorrowHours >= adjustedShift.break_time) {
              tomorrowHours = tomorrowHours - adjustedShift.break_time;
            } else {
              //remove all tomorrow's time
              todayHours = todayHours - (adjustedShift.break_time - tomorrowHours);
              tomorrowHours = 0;
            }
          }
        }

        //if we have hours into tomorrow
        return {
          today: {
            shift: adjustedShift,
            hours: todayHours,
            move: true,
          },
          tomorrow: {
            shift: splitShift,
            hours: tomorrowHours,
            move: false,
          },
        };
      }

      // is the shift is before the non-midnight cutoff we need to move it around
      // only if it's not split we move the whole shift back a day
      if (
        adjustedShift?.date('end_time')?.isBefore(adjustedShiftStart) &&
        account.getSettings('payroll.work_day_start') !== '00:00'
      ) {
        adjustedShift = new Shift({
          ...shift,
          start_time: adjustedShift?.date('start_time')?.subtract(1, 'days'),
          end_time: adjustedShift?.date('end_time')?.subtract(1, 'days'),
        });
      }

      //only hours for today
      return {
        today: {
          shift: adjustedShift,
          hours: shiftDuration - adjustedShift.break_time,
          move: false,
        },
      };
    }
  }

  getDayTotalHours(requestedDay: Moment) {
    //get day hours from api if there are any
    const correctDay = Object.keys(this.days).filter(day => {
      if (requestedDay.isSame(day, 'day')) {
        return true;
      }
      return false;
    });

    if (this.days[correctDay[0]]) {
      return this.days[correctDay[0]];
    }

    return null;
  }

  getWeekTotalHours(shift: Shift) {
    for (const weekKey in this.weeks) {
      const overtimeWeek = moment(weekKey);

      // the `date` method returns a Moment or null ... even though these should always be Moments
      if (
        rangesOverlap(
          shift.date('start_time')!,
          shift.date('end_time')!,
          overtimeWeek.clone().startOf('day'),
          overtimeWeek.clone().startOf('day').add(7, 'days').subtract(1, 'seconds'),
          true,
        )
      ) {
        return { weekHours: this.weeks[weekKey], currentWeekDate: weekKey };
      }
    }

    return { weekHours: null, currentWeekDate: null };
  }

  getOvertimeAmounts(
    shift: Shift,
    user: User,
    account: Account,
    pristineShift: Shift | null,
    removeHours: (Map<string, Map<string | null | undefined, DayHours | WeekHours>> | null)[] | null = null,
  ) {
    let scheduledHours = shift.duration();

    const overtimeHours: (OvertimeHours | null)[][] = [];
    let pristineShiftHours: ShiftHoursOverMidnight | undefined | null = null;
    let accumulateWeekHours: { totalWorkedHours: number | null; weekDate: string | null } = {
      totalWorkedHours: null,
      weekDate: null,
    };

    //duration can come back negative adding 24 fixes it
    scheduledHours = scheduledHours > 0 ? scheduledHours : scheduledHours + 24;

    //get hours after today ot and remove it from the calculations
    const splitShifts = this.countOverMidnightHours(shift, scheduledHours, account);

    //pristine shift should only come into play if the current shift is in a swap
    if (pristineShift) {
      pristineShiftHours = this.countOverMidnightHours(pristineShift, pristineShift.duration(), account);
    }

    overtimeHours.push(
      map(splitShifts, (splitShift, day) => {
        //get the day the shift is scheduled - for non-midnight cut off we may move the day
        //forward or back a day if the shift hours are cut
        let dayHours = this.getDayTotalHours(splitShift.shift.date('start_time')!);
        let { weekHours, currentWeekDate } = this.getWeekTotalHours(splitShift.shift);

        if (!dayHours || !weekHours) {
          return null;
        }

        //check if there are hours to be removed
        if (removeHours) {
          //check if days match and replace dayHours with corrected hours
          for (const correctedDayHours of removeHours) {
            //if days match swap out hours
            if (correctedDayHours?.get('dayHours')?.get(splitShift.shift.date('start_time')?.format('DD-MM-YYYY'))) {
              dayHours = correctedDayHours
                .get('dayHours')
                ?.get(splitShift.shift.date('start_time')?.format('DD-MM-YYYY')) as DayHours;
            }
            //get corrected week hours
            if (correctedDayHours?.get('weekHours')?.get(currentWeekDate)) {
              weekHours = correctedDayHours.get('weekHours')?.get(currentWeekDate) as WeekHours;
            }
          }
        }

        //current shift hours
        let correctedHours = splitShift.hours;
        let correctedBreakTime = 0;

        //add hours to calc maxHours correctly when day crosses over boundary
        if (accumulateWeekHours.weekDate !== currentWeekDate) {
          //new week hours are reset
          accumulateWeekHours = {
            totalWorkedHours: weekHours!.normalized.totalHours,
            weekDate: currentWeekDate,
          };
        }

        const correctMaxHours = round(user.hours_max - (accumulateWeekHours.totalWorkedHours ?? 0), 2);

        //corrected if a current shift exists
        if (pristineShiftHours?.[day]) {
          // If we have a pristine shift, use the difference between the length of the pristine original and the
          // updated shift. When we're adding hours to get totals, this way we're only adding the new hours
          correctedHours = Math.max(correctedHours - pristineShiftHours[day].hours, 0);

          if (
            weekHours!.normalized.totalHours > account.settings.payroll.hours_max ||
            dayHours!.normalized.weeklyOvertimeHours > 0
          ) {
            correctedBreakTime = pristineShiftHours[day].shift.break_time;
          }
        }

        const overtime: OvertimeHours = {
          newDailyDoubleOT: 0,
          maxHours: 0,
          maxHoursNew: 0,
          maxHoursTotal: 0,
          totalDailyDoubleOT: 0,
          weeklyDoubleOT: 0,
          newDailyOT: 0,
          currentWeeklyOT: 0,
          newWeeklyOT: 0,
          totalRegularOT: 0,
          totalDailyOT: 0,
          netNew: 0,
          totalHours: dayHours!.normalized.totalHours + correctedHours + correctedBreakTime,
        };

        //calc max hours correctly
        overtime.maxHours = correctMaxHours;

        //new max hours calculated
        overtime.maxHoursNew = Math.max(Math.max(correctedHours - correctedBreakTime, 0) - overtime.maxHours, 0);
        if (overtime.maxHours < 0) {
          overtime.maxHoursNew = Math.max(correctedHours - correctedBreakTime, 0);
        }

        //total max hours
        overtime.maxHoursTotal = overtime.maxHours - correctedHours + correctedBreakTime;
        if (overtime.maxHoursTotal < 0) {
          overtime.maxHoursTotal = Math.abs(overtime.maxHoursTotal);
        } else {
          overtime.maxHoursTotal = 0;
        }

        accumulateWeekHours.totalWorkedHours = (accumulateWeekHours.totalWorkedHours ?? 0) + correctedHours;

        // for exempt we only want to calculate max hours
        if (user.type === UserType.Exempt || !account.settings.payroll.use_sow_for_ot) {
          return overtime;
        }

        //Total Daily Double OT (DDOT)
        overtime.totalDailyDoubleOT = round(
          Math.max(overtime.totalHours - account.settings.payroll.hours_dot_daily, 0),
          2,
        );

        //New Daily Double OT (DDOT)
        overtime.newDailyDoubleOT = round(overtime.totalDailyDoubleOT - dayHours!.normalized.doubleOvertimeHours, 2);

        //Weekly Double OT
        overtime.weeklyDoubleOT = round(overtime.newDailyDoubleOT + weekHours!.normalized.doubleOvertimeHours, 2);

        //Daily OT (DOT)
        overtime.totalDailyOT = Math.max(
          overtime.totalHours - overtime.totalDailyDoubleOT - account.settings.payroll.hours_max_daily,
          0,
        );
        //New Daily Overtime
        overtime.newDailyOT = overtime.totalDailyOT - dayHours!.normalized.overtimeHours;

        //Net new daily ot
        const hoursLeftInWeek = Math.min(weekHours!.normalized.regularHours - account.settings.payroll.hours_max, 0);
        overtime.netNew = Math.max(correctedHours - overtime.newDailyOT - overtime.newDailyDoubleOT, 0);

        if (hoursLeftInWeek < 0) {
          overtime.netNew = Math.max(
            hoursLeftInWeek + (correctedHours - overtime.newDailyOT - overtime.newDailyDoubleOT),
            0,
          );
        }

        //Weekly OT
        //should be ( totalHours + scheduledHours ) - DDOT - DOT = WOT
        overtime.currentWeeklyOT = weekHours!.normalized.weeklyOvertimeHours + weekHours!.normalized.overtimeHours;

        //Weekly OT
        if (overtime.currentWeeklyOT < 0) {
          overtime.newWeeklyOT = Math.max(overtime.currentWeeklyOT + correctedHours, 0);
        } else {
          overtime.newWeeklyOT = correctedHours;
        }

        if (overtime.newWeeklyOT >= overtime.newDailyDoubleOT + overtime.newDailyOT) {
          overtime.newWeeklyOT -= overtime.newDailyDoubleOT + overtime.newDailyOT;
        }

        //setting the display values
        overtime.totalRegularOT += round(
          Math.max(overtime.newDailyOT + overtime.netNew + overtime.currentWeeklyOT, 0),
          2,
        );

        overtime.newDailyOT = round(overtime.newDailyOT + overtime.netNew, 2);

        return overtime;
      }),
    );

    if (overtimeHours[0].length === 2) {
      const overtimeSet1 = overtimeHours[0].shift();
      const overtimeSet2 = overtimeHours[0].shift();

      if (overtimeSet1 == null || overtimeSet2 == null) {
        //should never happen but it could
        if (overtimeSet1 == null && overtimeSet2 == null) {
          return { maxHoursTotal: 0, maxHoursNew: 0, newDailyOT: 0, newDailyDoubleOT: 0 } as OvertimeHours;
        }

        return overtimeSet1 === null ? overtimeSet2 : overtimeSet1;
      }

      //correctly add data
      if (overtimeSet2.newDailyOT > 0) {
        overtimeSet1.newDailyOT += overtimeSet2.newDailyOT;
        overtimeSet1.totalRegularOT += overtimeSet2.newDailyOT;
      }

      if (overtimeSet2.maxHoursNew > 0) {
        overtimeSet1.maxHoursNew += overtimeSet2.maxHoursNew;
        overtimeSet1.maxHoursTotal += overtimeSet2.maxHoursNew;
      }

      if (overtimeSet2.newDailyDoubleOT > 0) {
        overtimeSet1.newDailyDoubleOT += overtimeSet2.newDailyDoubleOT;
        overtimeSet1.weeklyDoubleOT += overtimeSet2.newDailyDoubleOT;
      }

      return overtimeSet1;
    }

    const sumOt = (...objs: (OvertimeHours | null)[]) => {
      return objs.reduce((a, b) => {
        for (const key in b) {
          // biome-ignore lint/suspicious/noPrototypeBuiltins:
          if (b.hasOwnProperty(key)) {
            switch (key) {
              case 'newWeeklyOT':
                a![key] = b[key];
                break;
              case 'maxHoursTotal':
                a![key] = b[key];
                break;
              default:
                a![key as keyof OvertimeHours] = (a![key as keyof OvertimeHours] || 0) + b[key as keyof OvertimeHours];
            }
          }
        }
        return a;
      }, {} as OvertimeHours);
    };

    return sumOt(...overtimeHours[0]);
  }

  getCurrentDayAndWeekHours(shift: Shift, swapShift: Shift, account: Account) {
    //check if day goes over end of day
    const existingHours: (Map<string, Map<string | null | undefined, DayHours | WeekHours>> | null)[][] = [];
    let correctWeekHours = Map<string | null, WeekHours>();
    let correctedDayHours = Map<string | undefined, DayHours>();
    const scheduledHours = shift.duration();
    //swap hours to be removed from shift
    const swapScheduledHours = swapShift.duration();

    //original shift
    const hoursToBeRemoved = this.countOverMidnightHours(shift, scheduledHours, account);
    //new shift
    const swapHours = this.countOverMidnightHours(swapShift, swapScheduledHours, account);

    existingHours.push(
      map(hoursToBeRemoved, removeHours => {
        const dayKey = removeHours.shift.date('start_time')?.format('DD-MM-YYYY');
        const day = this.getDayTotalHours(removeHours.shift.date('start_time')!);

        const { weekHours, currentWeekDate } = this.getWeekTotalHours(removeHours.shift);

        if (!weekHours && !day) {
          return null;
        }

        //if a shift is split we need to check if it's within the same week
        //then remove the remaining hours from the week totals
        if (correctWeekHours.get(currentWeekDate)) {
          //reset the correctWeekHours
          const modifyHours = correctWeekHours.get(currentWeekDate);
          correctWeekHours = correctWeekHours.set(currentWeekDate, {
            normalized: {
              doubleOvertimeHours: modifyHours!.normalized.doubleOvertimeHours - day!.normalized.doubleOvertimeHours,
              overtimeHours: modifyHours!.normalized.overtimeHours - day!.normalized.overtimeHours,
              regularHours: modifyHours!.normalized.regularHours - day!.normalized.regularHours,
              totalHours: modifyHours!.normalized.totalHours - day!.normalized.totalHours,
              weeklyOvertimeHours: modifyHours!.normalized.weeklyOvertimeHours - day!.normalized.weeklyOvertimeHours,
            },
          });
        } else {
          //set new week totals with shift hours taken away
          correctWeekHours = correctWeekHours.set(currentWeekDate, {
            normalized: {
              doubleOvertimeHours: weekHours!.normalized.doubleOvertimeHours - day!.normalized.doubleOvertimeHours,
              overtimeHours: weekHours!.normalized.overtimeHours - day!.normalized.overtimeHours,
              regularHours: weekHours!.normalized.regularHours - day!.normalized.regularHours,
              totalHours: weekHours!.normalized.totalHours - day!.normalized.totalHours,
              weeklyOvertimeHours: weekHours!.normalized.weeklyOvertimeHours - day!.normalized.weeklyOvertimeHours,
            },
          });
        }

        //if days for swap and shift collide hours need to remove from the day
        //else the days dont match then hours can stay
        if (
          swapHours?.today.shift.date('start_time')?.isSame(removeHours.shift.date('start_time'), 'day') ||
          swapHours?.tomorrow?.shift.date('start_time')?.isSame(removeHours.shift.date('start_time'), 'day')
        ) {
          //to remove hours correctly start remove start at double ot, ot, reg hours
          const doubleOtHours = day!.normalized.doubleOvertimeHours - removeHours.hours;
          let otHours = day!.normalized.doubleOvertimeHours;
          let regularHours = day!.normalized.regularHours;

          //negative hours, remove from next set
          if (doubleOtHours < 0) {
            otHours = otHours + day!.normalized.doubleOvertimeHours - removeHours.hours;
          }

          //negative hours, remove from next set
          if (otHours < 0) {
            regularHours =
              day!.normalized.doubleOvertimeHours +
              day!.normalized.overtimeHours +
              day!.normalized.regularHours -
              removeHours.hours;
          }

          correctedDayHours = correctedDayHours.set(dayKey, {
            normalized: {
              doubleOvertimeHours: Math.max(doubleOtHours, 0),
              overtimeHours: Math.max(otHours, 0),
              regularHours: Math.max(regularHours, 0),
              totalHours: Math.max(day!.normalized.totalHours - removeHours.hours, 0),
              weeklyOvertimeHours: 0,
            },
          });
        } else {
          correctedDayHours = correctedDayHours.set(dayKey, {
            normalized: {
              doubleOvertimeHours: day!.normalized.doubleOvertimeHours,
              overtimeHours: day!.normalized.overtimeHours,
              regularHours: day!.normalized.regularHours,
              totalHours: day!.normalized.totalHours,
              weeklyOvertimeHours: day!.normalized.weeklyOvertimeHours,
            },
          });
        }

        return Map({
          dayHours: correctedDayHours,
          weekHours: correctWeekHours,
        });
      }),
    );

    return existingHours;
  }

  getSwapOvertimeAmounts(originalShift: Shift, shift: Shift, user: User, account: Account) {
    //modify week / day hours for requested user
    //remove all the hours from the original shift
    const correctedHoursData = this.getCurrentDayAndWeekHours(shift, originalShift, account);
    const removeOldHoursData = this.getCurrentDayAndWeekHours(shift, shift, account);

    if (!correctedHoursData) {
      return null;
    }

    //we end up with two elements and the first one is wrong
    const correctedHours = correctedHoursData.pop()!;
    if (correctedHours.length > 1) {
      correctedHours.shift();
    }

    const removeOldHours = removeOldHoursData.pop()!;
    if (removeOldHours.length > 1) {
      removeOldHours.shift();
    }

    const originalOvertimeHours = this.getOvertimeAmounts(shift, user, account, null, removeOldHours);
    let newOvertimeAmounts = this.getOvertimeAmounts(originalShift, user, account, null, correctedHours);

    //remove all the original OT from the new OT
    newOvertimeAmounts = {
      newDailyDoubleOT: Math.max(
        (newOvertimeAmounts?.newDailyDoubleOT ?? 0) - (originalOvertimeHours?.newDailyDoubleOT ?? 0),
        0,
      ),
      weeklyDoubleOT: Math.max(newOvertimeAmounts?.weeklyDoubleOT ?? 0, 0),
      totalDailyDoubleOT: Math.max(newOvertimeAmounts?.totalDailyDoubleOT ?? 0, 0),

      newDailyOT: Math.max((newOvertimeAmounts?.newDailyOT ?? 0) - (originalOvertimeHours?.newDailyOT ?? 0), 0),
      newWeeklyOT: Math.max((newOvertimeAmounts?.newWeeklyOT ?? 0) - (originalOvertimeHours?.newWeeklyOT ?? 0), 0),
      totalRegularOT: Math.max(newOvertimeAmounts?.totalRegularOT ?? 0, 0),
      currentWeeklyOT: Math.max(
        (newOvertimeAmounts?.currentWeeklyOT ?? 0) - (originalOvertimeHours?.currentWeeklyOT ?? 0),
        0,
      ),
      totalDailyOT: Math.max(newOvertimeAmounts?.totalDailyOT ?? 0, 0),

      totalHours: Math.max((newOvertimeAmounts?.totalHours ?? 0) - (originalOvertimeHours?.totalHours ?? 0), 0),
      netNew: originalOvertimeHours?.netNew ?? 0,

      maxHours: originalOvertimeHours?.maxHours ?? 0,
      maxHoursNew:
        user.hours_max === 0
          ? 0
          : Math.max((newOvertimeAmounts?.maxHoursNew ?? 0) - (originalOvertimeHours?.maxHoursNew ?? 0), 0),
      maxHoursTotal: Math.max(newOvertimeAmounts?.maxHoursTotal ?? 0, 0),
    };

    return newOvertimeAmounts;
  }
}

export default Overtime;
