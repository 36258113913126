import BaseModel from 'data/BaseModel';

export interface ScheduleRuleFields {
  name: string;
  users: number;
  shifts: number;
}

// Tags can be assigned to a Shift, ShiftTemplate or User
class ScheduleRule extends BaseModel<ScheduleRuleFields>({
  name: '',
  users: 0,
  shifts: 0,
}) {}

export default ScheduleRule;
