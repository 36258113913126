// Copy pasted from https://phryneas.de/redux-typescript-no-discriminating-union

// "...and if you are not using redux toolkit, you could for example use this helper function that attaches a
// .match(action) function and a .type property to every action creator you pass into it: "
import type { AnyAction } from '@reduxjs/toolkit';

type Matchable<AC extends () => AnyAction> = AC & {
  type: ReturnType<AC>['type'];
  match(action: AnyAction): action is ReturnType<AC>;
};

export function withMatcher<AC extends () => AnyAction>(actionCreator: AC): Matchable<AC>;
// eslint-disable-next-line no-redeclare
export function withMatcher<AC extends (...args: any[]) => AnyAction & { type: string }>(
  actionCreator: AC,
): Matchable<AC>;
// eslint-disable-next-line no-redeclare
export function withMatcher<AC extends (...args: any[]) => AnyAction>(
  actionCreator: AC,
  type: ReturnType<AC>['type'],
): Matchable<AC>;
// eslint-disable-next-line no-redeclare
export function withMatcher(actionCreator: Function & { type?: string }, _type?: string) {
  const type = _type ?? actionCreator.type ?? actionCreator().type;
  return Object.assign(actionCreator, {
    type,
    match(action: AnyAction) {
      return action.type === type;
    },
  });
}
