import { useLDFlag } from 'data/LD/selectors/getLDFlag';
import type Account from 'data/account/model';
import type User from 'data/user/model';
import { setEmployeeOnboardingItemCompleted } from 'onboarding/actions/setEmployeeOnboardingItemCompleted';
import { EmployeeOnboardingItems, EmployeeOnboardingStatus } from 'onboarding/constants';
import GuideHighlight from 'onboarding/modules/guides/components/GuideHighlight';
import { setEmployeeOnboardingStatus } from 'onboarding/reducer';
import { getEmployeeOnboardingStatus } from 'onboarding/selectors';
import { logout, switchWorkplace } from 'shared/auth/actions';
import { MenuItem } from 'shared/components/nav/MenuItem';
import MenuItems from 'shared/components/nav/MenuItems';
import AvatarImg from 'shared/ui/AvatarImg';
import { posthogCapture } from 'shared/vendor/posthog';
import { useWiwDispatch, useWiwSelector } from 'store';

type AvatarMenuProps = {
  authUser: User;
  authAccount: Account;
};

const AvatarMenuItem = ({ authUser, authAccount }: AvatarMenuProps) => {
  const pay903PayingContractorsPayStubs = useLDFlag('pay-903-paying-contractors-pay-stubs');

  const isPayStubsVisible =
    authAccount.getSettings('payroll_check.is_onboarded', false) &&
    (!authUser.isContractor() || pay903PayingContractorsPayStubs) &&
    !authUser.exclude_from_payrolls &&
    (authUser.canSupervise() || authUser.canManage() || authUser.isAdmin());

  const dispatch = useWiwDispatch();
  const employeeOnboardingStatus = useWiwSelector(getEmployeeOnboardingStatus);
  const isAlertGuidedStep = employeeOnboardingStatus === EmployeeOnboardingStatus.AlertPreferences;

  const dismissGuide = () => {
    dispatch(setEmployeeOnboardingStatus(EmployeeOnboardingStatus.Idle));
  };

  const completeGuide = () => {
    dispatch(setEmployeeOnboardingStatus(EmployeeOnboardingStatus.Idle));
    dispatch(setEmployeeOnboardingItemCompleted(EmployeeOnboardingItems.AlertPreferencesCompleted));
    posthogCapture('employee_onboarding.notification_preferences.click.profile');
  };

  return (
    <MenuItem
      className="avatar menu-icon"
      hideLabel="tablet"
      id="avatarmenu"
      dropdown
      forceActive={isAlertGuidedStep}
      label={
        <abbr>
          <AvatarImg alt="menu-avatar" className="menu-avatar" user={authUser} width={24} height={24} />
          <span className="nav-item-label">{authUser.first_name}</span>
        </abbr>
      }
    >
      <MenuItems dropdownMenu>
        <GuideHighlight
          isActive={isAlertGuidedStep}
          highlightId="alerts-guide-highlight"
          onHighlightClick={completeGuide}
          showPopover
          popoverText="Go to “Profile & Settings” to adjust your notification preferences."
          popoverPosition="left-start"
          popoverOffset={{ mainAxis: 10, crossAxis: 10 }}
          hideOverlay
          dismissOnOutsideClick
          onDismiss={dismissGuide}
        >
          <MenuItem
            icon="my-profile"
            label="Profile & Settings"
            to="/profile"
            data-testid={isAlertGuidedStep ? 'eo-set-alerts-select' : undefined}
          />
        </GuideHighlight>

        {authUser.canSupervise() && (
          <MenuItem
            icon="my-schedule"
            label="My Schedule"
            show={authAccount.hasScheduling()}
            to="/myschedule"
            disabled={isAlertGuidedStep}
          />
        )}

        <MenuItem
          icon="my-availability"
          label="My Availability"
          show={
            authAccount.hasScheduling() &&
            (authAccount.getSettings('availability.enabled', false) || authUser.canManage())
          }
          to="/availability"
          disabled={isAlertGuidedStep}
        />

        <MenuItem
          icon="payroll"
          label="Paystubs"
          show={isPayStubsVisible}
          to="/paystubs"
          disabled={isAlertGuidedStep}
        />

        <MenuItem
          icon="workplace"
          label="Switch Workplaces"
          onClick={() => switchWorkplace()}
          disabled={isAlertGuidedStep}
        />

        <MenuItem icon="logout" label="Logout" onClick={() => logout(false)} disabled={isAlertGuidedStep} />
      </MenuItems>
    </MenuItem>
  );
};

export default AvatarMenuItem;
