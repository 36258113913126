import type Account from 'data/account/model';
import type Plan from 'data/plan/model';
import type User from 'data/user/model';
import { MenuItem } from 'shared/components/nav/MenuItem';
import MenuItems from 'shared/components/nav/MenuItems';
import Features from 'shared/features';

type WorkplaceMenuProps = {
  authUser: User;
  authAccount: Account;
  accountPlan: Plan;
  trackDocumentsNav: () => void;
  pt121WorkplaceObjects: boolean;
  upsellMultipleSchedules: (e: Event) => void;
  canViewDocStorage: boolean;
};

const WorkplaceMenuItem = ({
  authUser,
  authAccount,
  accountPlan,
  trackDocumentsNav,
  pt121WorkplaceObjects,
  upsellMultipleSchedules,
  canViewDocStorage,
}: WorkplaceMenuProps) => (
  <MenuItem
    className="workplace menu-icon"
    icon="workplace"
    label="Workplace"
    id="app-settings-menu"
    hideLabel="desktop"
    dropdown
  >
    <MenuItems dropdownMenu>
      {pt121WorkplaceObjects ? (
        <MenuItem icon="person" label="Users" to="/users" />
      ) : (
        <MenuItem icon="person" label="Users" to="/employees" />
      )}

      <MenuItem icon="positions" label="Positions" to="/positions" />

      {authAccount.getSettings('schedule.worktags') && <MenuItem icon="tags" label="Tags" to="/tags" />}

      <MenuItem
        icon="schedules"
        label="Schedules"
        show={
          (authAccount.hasFeature(Features.LOCATIONS) ||
            authAccount.isReverseTrial() ||
            accountPlan.isSingleLocation2025() ||
            accountPlan.isSingleLocation2025V2()) &&
          authUser.canManage()
        }
        to="/locations"
      />

      {/* Menu item for UPGRADES, reverse trial uses the regular schedules button */}
      <MenuItem
        className="disable upsell"
        icon="schedules"
        label="Schedules"
        onClick={upsellMultipleSchedules}
        show={
          !authAccount.hasFeature(Features.LOCATIONS) &&
          !authAccount.isReverseTrial() &&
          (!accountPlan.isSingleLocation2025() || !accountPlan.isSingleLocation2025V2()) &&
          authUser.canManage()
        }
      />

      {(accountPlan.isSingleLocation2025() || accountPlan.isSingleLocation2025V2()) && (
        <MenuItem icon="job-site" label="Job Sites" to="/sites" postIcon="upgrade" postIconColor="#C59A27" />
      )}

      <MenuItem
        icon="job-site"
        label="Job Sites"
        show={authAccount.hasFeature(Features.SITES) || authAccount.isReverseTrial()}
        to="/sites"
      />

      <MenuItem icon="shift-template" label="Shift Templates" show={authAccount.hasScheduling()} to="/blocks" />

      {authAccount.getSettings('tasks.enabled') && <MenuItem icon="tasks" label="Task Lists" to="/tasks" />}

      {canViewDocStorage ? (
        <MenuItem icon="document" label="Documents" onClick={trackDocumentsNav} to="/documents" />
      ) : (
        <MenuItem icon="forms" label="Forms" target="_blank" to="https://wheniwork.com/hr" />
      )}
    </MenuItems>
  </MenuItem>
);

export default WorkplaceMenuItem;
