import EntityState from 'data/EntityState';
import { FETCH_OVERTIME_SCHEDULED } from 'store/action-types';
import OvertimeScheduled from './model';

export default function (state = new EntityState(), action) {
  switch (action.type) {
    case FETCH_OVERTIME_SCHEDULED.FAILURE:
      return state.merge({
        loaded: false,
        loading: false,
        error: action.payload,
      });
    case FETCH_OVERTIME_SCHEDULED.REQUEST:
      return state.merge({
        loaded: false,
        loading: true,
        error: null,
      });
    case FETCH_OVERTIME_SCHEDULED.SUCCESS:
      return state.merge({
        loaded: true,
        loading: false,
        error: null,
        receivedAt: Date.now(),
        items: state.items.merge(action.payload.map(ot => [ot.id, new OvertimeScheduled(ot)])),
      });
    default:
      return state;
  }
}
